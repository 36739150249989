import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "../chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

const data = [
  {
    year: "2016",
    europe: 2.5,
    namerica: 2.5,
    asia: 2.1,
    lamerica: 0.3,
    meast: 0.2,
    africa: 0.1,
  },
  {
    year: "2017",
    europe: 2.6,
    namerica: 2.7,
    asia: 2.2,
    lamerica: 0.3,
    meast: 0.3,
    africa: 0.1,
  },
  {
    year: "2018",
    europe: 2.8,
    namerica: 2.9,
    asia: 2.4,
    lamerica: 0.3,
    meast: 0.3,
    africa: 0.1,
  },
];

export default function NtaiTestTooltip() {
  const theme = useTheme();

  const chart = useRef(null);

  React.useEffect(() => {
    if (!chart.current)
      chart.current = am4core.create("bar-horizontal1", am4charts.XYChart);

    chart.current.data = data;
    chart.current.cursor = new am4charts.XYCursor();
    chart.current.responsive.enabled = true;

    let categoryAxis = chart.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name) {
      // Set up series
      let series = chart.current.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }

    createSeries("europe", "Europe");
    createSeries("namerica", "North America");
    createSeries("asia", "Asia-Pacific");
    createSeries("lamerica", "Latin America");
    createSeries("meast", "Middle-East");
    createSeries("africa", "Africa");

    return () => {
      chart.current.dispose();
    };
  }, []);

  useEffect(() => {
    if (chart.current) {
      chart.current.data = data;
    }
  }, [data]);

  React.useEffect(() => {
    return () => {
      chart.current && chart.current.dispose();
    };
  }, []);

  return (
    <>
      {Array.isArray(data) && data.length > 0 && (
        <Box
          id={"bar-horizontal1"}
          sx={{ width: "100%", height: "100%" }}
        ></Box>
      )}

      {Array.isArray(data) && data.length === 0 && <NtaiChartEmptyMessage />}
    </>
  );
}
