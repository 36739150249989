import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordAdjudications = createAsyncThunk(
  "searchSourceRecordAdjudications/getSearchSourceRecordAdjudications",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.put(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordAdjudication = createAsyncThunk(
  "searchSourceRecordAdjudications/getSearchSourceRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/adjudications/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecordAdjudication = createAsyncThunk(
  "searchSourceRecordAdjudications/createSearchSourceRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecordAdjudication = createAsyncThunk(
  "searchSourceRecordAdjudications/updateSearchSourceRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId, formData } =
        values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/adjudications/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceRecordAdjudication = createAsyncThunk(
  "searchSourceRecordAdjudications/deleteSearchSourceRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = server.delete(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/adjudications/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordAdjsSlice = createSlice({
  name: "searchSourceRecordAdjudications",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceRecordAdjudications.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "adjudication.uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordAdjudications",
      };
    },

    [getSearchSourceRecordAdjudications.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordAdjudications",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.adjudication.uuId]: action.payload,
      };

      state.status = {
        result: "success",
        method: "getSearchSourceRecordAdjudication",
      };
    },
    [getSearchSourceRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordAdjudication",
        message: action.payload.message,
      };
    },
    [createSearchSourceRecordAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.adjudication.uuId]: action.payload,
      };

      state.status = {
        result: "success",
        method: "createSearchSourceRecordAdjudication",
      };
      state.activeId = action.payload["adjudication"]["uuId"];
    },
    [createSearchSourceRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecordAdjudication",
        message: action.payload.message,
      };
    },
    [createSearchSourceRecordAdjudication.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "createSearchSourceRecordAdjudication",
      };
    },
    [updateSearchSourceRecordAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.adjudication.uuId]: action.payload,
      };

      state.activeId = action.payload["adjudication"]["uuId"];

      state.status = {
        result: "success",
        method: "updateSearchSourceRecordAdjudication",
      };
    },
    [updateSearchSourceRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecordAdjudication",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecordAdjudication.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateSearchSourceRecordAdjudication",
      };
    },
    [deleteSearchSourceRecordAdjudication.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.adjudication.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceRecordAdjudication",
      };
    },
    [deleteSearchSourceRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceRecordAdjudication",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchSourceRecordAdjsSlice.actions;

export default searchSourceRecordAdjsSlice.reducer;
