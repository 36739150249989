import React from "react";
import { Box, Divider, List, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import SearchSummarySourceRecordListTemplate from "./SearchSummarySourceRecordListTemplate";
const jp = require("jsonpath");
const _ = require("lodash");

function getListTemplates(templatesData) {
  const result = {};
  if (_.isArray(templatesData)) {
    templatesData.forEach((t) => {
      if (t.typeCode === 2) {
        result[t.sourceUuId] = {
          ..._.mapKeys(t.sourceRecordListingFields, "placementCode"),
        };
      }
    });
  }

  return result;
}

export default function SearchSummarySourceRecordList() {
  const theme = useTheme();
  const dataMap = useSelector(
    (state) => state.search.searchSummarySourceRecordsSlice.data
  );

  const data = _.sortBy(Object.values(dataMap), ["sourceMin.abbrev"]);

  const templatesDataMap = useSelector(
    (state) =>
      state.search.searchSummarySourceRecordsSlice.sourceRecordListingsData
  );

  const templatesData = Object.values(templatesDataMap);

  const listTemplates = getListTemplates(templatesData);


  function fetchField(sourceUuId, placementCode, recordData) {
    const field = _.get(listTemplates[sourceUuId], placementCode);

    const fieldValue =
      _.has(field, "sourceDerivedFieldFg") &&
      field["sourceDerivedFieldFg"] === 1
        ? _.get(recordData["fields"], field["sourceFieldName"])
        : jp.query(
            recordData["minData"],
            `$..${_.get(field, "sourceField.path")}`
          );

    const lookupLinkUuId =
      _.isArray(_.get(field, "sourceField.lookupLinks")) &&
      _.get(field, "sourceField.lookupLinks").length > 0
        ? _.get(_.get(field, "sourceField.lookupLinks")[0], "uuId")
        : null;

    const returnValue = {
      value: fieldValue,
      lookupLinkUuId: lookupLinkUuId,
      recordId: _.get(recordData["minData"], "id"),
    };

    return returnValue;
  }

  function handleRecordSelect(id) {}

  function handleLookup(e, lookupLinkUuId, value, recordId) {}

  return (
    <Scrollbar>
      <Box>
        <List sx={{ p: 0 }}>
          {_.isArray(data) &&
            data.map((rd, i) => (
              <SearchSummarySourceRecordListTemplate
                key={`search-summary-source-record-${i}`}
                sourceAbbrev={rd.sourceAbbrev}
                id={_.get(rd["minData"], "id")}
                assessmentFg={rd["assessmentFg"]}
                numOfActions={rd["numOfActions"]}
                numOfNotes={rd["numOfNotes"]}
                handleClick={handleRecordSelect}
                primaryText={fetchField(rd.sourceUuId, 1, rd)}
                secondaryText={fetchField(rd.sourceUuId, 2, rd)}
                // secondaryText={_.get(record, fetchField(2))}
                tertiaryText={fetchField(rd.sourceUuId, 3, rd)}
                label={
                  rd["labels"] && _.isArray(rd["labels"]) && rd["labels"][0]
                }
                handleLookup={handleLookup}
                // imgUrl={_.get(record, fetchField(4))}
              />
            ))}
        </List>
      </Box>
    </Scrollbar>
  );
}
