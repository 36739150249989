import NtaiDashboardMetric2Widget from "@ntai/components/widgets/dashboard/templates/NtaiDashboardMetric2Widget";
import React from "react";

export default function SearchSummaryWidget({ data }) {
  return (
    <NtaiDashboardMetric2Widget
      id="search-summary"
      data={data}
      footer="Search summary displaying # of total, favorite and watched items"
    />
  );
}
