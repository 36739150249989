import { combineReducers } from "@reduxjs/toolkit";
import dashboardSlice from "./dashboardSlice";
import dashboardWidgetsSlice from "../widgets/store/dashboardWidgetsSlice";
import dashboardUsersSlice from "./dashboardUsersSlice";

const dashboardReducer = combineReducers({
  dashboardSlice,
  dashboardWidgetsSlice,
  dashboardUsersSlice,
});

export default dashboardReducer;
