import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReports = createAsyncThunk(
  "reports/getReports",
  async (groupId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/reports/groups/${groupId}/reports`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReport = createAsyncThunk(
  "reports/getReport",
  async (values, { rejectWithValue }) => {
    try {
      const { groupId, uuId } = values;
      const response = await server.get(
        `/reports/groups/${groupId}/reports/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const previewReport = createAsyncThunk(
  "reports/previewReport",
  async (values, { rejectWithValue }) => {
    try {
      const { groupId, uuId, formData } = values;
      const response = await server.put(
        `/reports/groups/${groupId}/reports/${uuId}/preview`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateReport = createAsyncThunk(
  "reports/generateReport",
  async (values, { rejectWithValue }) => {
    try {
      const { groupId, uuId, formData } = values;
      const response = await server.post(
        `/reports/groups/${groupId}/reports/${uuId}/preview`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const runReport = createAsyncThunk(
  "reports/runReport",
  async (values, { rejectWithValue }) => {
    try {
      const { groupId, uuId, formData } = values;
      const response = await server.post(
        `/reports/groups/${groupId}/reports/${uuId}/run`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createReport = createAsyncThunk(
  "reports/createReport",
  async (values, { rejectWithValue }) => {
    try {
      const { groupId, formData } = values;
      const response = await server.post(
        `/reports/groups/${groupId}/reports`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReport = createAsyncThunk(
  "reports/updateReport",
  async (values, { rejectWithValue }) => {
    try {
      const { groupId, uuId, formData } = values;
      const response = await server.patch(
        `/reports/groups/${groupId}/reports/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReport = createAsyncThunk(
  "reports/deleteReport",
  async (values, { rejectWithValue }) => {
    try {
      const { groupId, uuId } = values;
      const response = await server.delete(
        `/reports/groups/${groupId}/reports/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReports.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getReports" };
    },
    [getReports.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReports",
        message: action.payload.message,
      };
    },
    [getReport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getReport" };
    },
    [getReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReport",
        message: action.payload.message,
      };
    },
    [getReport.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getReport",
      };
    },

    [generateReport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "generateReport" };
      state.activeId = action.payload.uuId;
    },
    [generateReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "generateReport",
        message: action.payload.message,
      };
    },
    [generateReport.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "generateReport",
      };
    },
    [createReport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createReport" };
      state.activeId = action.payload.uuId;
    },
    [createReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createReport",
        message: action.payload.message,
      };
    },

    [updateReport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateReport" };
    },
    [updateReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateReport",
        message: action.payload.message,
      };
    },

    [runReport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "runReport" };
      state.activeId = action.payload.uuId;
    },
    [runReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "runReport",
        message: action.payload.message,
      };
    },
    [runReport.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "runReport",
      };
    },

    [deleteReport.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteReport" };
    },
    [deleteReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteReport",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = reportsSlice.actions;

export default reportsSlice.reducer;
