import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceOutDatasets = createAsyncThunk(
  "sourceDatasets/getSourceOutDatasets",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceOutDataset = createAsyncThunk(
  "sourceDatasets/getSourceOutDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceOutDataset = createAsyncThunk(
  "sourceOutDatasets/createSourceOutDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceOutDataset = createAsyncThunk(
  "sourceOutDatasets/updateSourceOutDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId, uuId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceOutDataset = createAsyncThunk(
  "sourceOutDatasets/deleteSourceOutDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceOutDatasetsSlice = createSlice({
  name: "sourceOutDatasets",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceOutDatasets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceOutDatasets" };
    },
    [getSourceOutDatasets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutDatasets",
        message: action.payload.message,
      };
    },

    [getSourceOutDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceOutDataset" };
    },
    [getSourceOutDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutDataset",
        message: action.payload.message,
      };
    },

    [createSourceOutDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceOutDataset" };
      state.activeId = action.payload.uuId;
    },
    [createSourceOutDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceOutDataset",
        message: action.payload.message,
      };
    },

    [updateSourceOutDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceOutDataset" };
    },
    [updateSourceOutDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceOutDataset",
        message: action.payload.message,
      };
    },

    [deleteSourceOutDataset.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.formatCode);
      state.status = { result: "success", method: "deleteSourceOutDataset" };
    },
    [deleteSourceOutDataset.rejected]: (state, action) => {
      state.status = { result: "error", method: "deleteSourceOutDataset" };
    },
  },
});

export const { clearStatus, setActiveId } = sourceOutDatasetsSlice.actions;

export default sourceOutDatasetsSlice.reducer;
