import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import DashboardViewForm from "./DashboardViewForm";
import { createDashboardView, clearStatus } from "./store/dashboardViewsSlice";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DashboardViewCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const dataMap = useSelector((state) => state.core.dashboardViewsSlice.data);
  const activeId = useSelector(
    (state) => state.core.dashboardViewsSlice.activeId
  );
  const status = useSelector((state) => state.core.dashboardViewsSlice.status);

  function handleFormSubmit(formData) {
    dispatch(createDashboardView(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createDashboardView" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Dashboard view "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/admin/dashboards/views/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Dashboards / Add Dashboard" back="/admin/dashboards/views">
      <NtaiForm>
        <DashboardViewForm action="add" handleFormSubmit={handleFormSubmit} />
      </NtaiForm>
    </NtaiPage>
  );
}
