import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cron from "react-cron-generator";
import "./cron-builder.css";
import NtaiUtils from "@ntai/utils/NtaiUtils";

export default function NtaiCronScheduler({ value, onChange, showResultText }) {
  const theme = useTheme();
  const [cronValue, setCronValue] = useState(value);

  function handleOnChange(val) {
    onChange(val);
  }
  useEffect(() => {
    setCronValue(value);
  }, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "auto",
        width: "100%",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
        <Cron
          className="cron-builder"
          onChange={(v, res) => handleOnChange(v)}
          value={cronValue}
          showResultText={false}
          showResultCron={false}
        />
      </Box>
      {showResultText && (
        <Box sx={{ display: "flex", height: "auto", width: "100%" }}>
          <Typography variant="subtitle1" color="inherit">
            {NtaiUtils.cronExpressionToString(value)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
