import { Box, Typography, useTheme } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import React from "react";

export default function NtaiWidgetMetric({ data, secondaryText, metadata }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        gap: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
        pb: theme.spacing(1),
      }}
    >
      <Typography fontSize="40px" fontWeight="500">
        {data[0]["value"]}
      </Typography>
      {metadata && metadata.widget.secondaryText && (
        <Typography variant="subtitle2" fontWeight="500">
          {metadata.widget.secondaryText}
        </Typography>
      )}
    </Box>
  );
}
