import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import {
  clearStatus,
  setActiveId,
  updateSourceCustomField,
} from "./store/sourceCustomFieldsSlice";
import SourceCustomFieldForm from "./SourceCustomFieldForm";
const _ = require("lodash");

export default function SourceCustomFieldEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceCustomFieldsSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceCustomFieldsSlice.data
  );
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(sanitizedFormData) {
    dispatch(
      updateSourceCustomField({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: sanitizedFormData,
      })
    );
  }

  function handleCancel() {
    history.push(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/custom-fields`
    );
  }
  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status.method === "updateSourceCustomField" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Custom field ${formData["name"]} updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/custom-fields`}
    >
      <NtaiForm>
        <SourceCustomFieldForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
