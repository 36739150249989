import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import {
  clearStatus,
  createWorkflowDefVersion,
} from "./store/workflowDefVersionsSlice";
import WorkflowDefVersionForm from "./WorkflowDefVersionForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function WorkflowDefVersionCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector(
    (state) => state.core.workflowDefVersionsSlice.status
  );

  const workflowDefId = props.match.params.id;

  const activeId = useSelector(
    (state) => state.core.workflowDefVersionsSlice.activeId
  );

  function handleSave(formData, config) {
    dispatch(
      createWorkflowDefVersion({
        workflowDefId: workflowDefId,
        formData: formData,
        config: config,
      })
    );
  }

  function handleCancel() {
    history.push(`/admin/workflows/${workflowDefId}/versions`);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createWorkflowDefVersion" &&
      status.result === "success"
    ) {
      enqueueSnackbar(`Workflow definition version saved successfully.`, {
        variant: "success",
      });

      history.push(
        `/admin/workflows/${workflowDefId}/versions/${activeId}/edit`
      );
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "createWorkflowDefVersion" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Add Workflow Version"
      back={`/admin/workflows/${workflowDefId}/versions`}
    >
      <NtaiForm>
        <WorkflowDefVersionForm
          action="add"
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
