import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjectDashboards = createAsyncThunk(
  "objectDashboards/getObjectDashboards",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/dashboards`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectDashboard = createAsyncThunk(
  "objectDashboards/getObjectDashboard",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;

      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/dashboards/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllSourceViewsForObject = createAsyncThunk(
  "objectDashboards/getAllSourceViewsForObject",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;

      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/all-source-views`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectDashboard = createAsyncThunk(
  "objectDashboards/createObjectDashboard",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/dashboards`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectDashboard = createAsyncThunk(
  "objectDashboards/updateObjectDashboard",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/dashboards/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectDashboard = createAsyncThunk(
  "objectDashboards/deleteObjectDashboard",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/dashboards/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectDashboardsSlice = createSlice({
  name: "objectDashboards",
  initialState: {
    data: {},
    allSourceViews: {},
    sourceWidgets: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectDashboards.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjectDashboards" };
    },
    [getObjectDashboards.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectDashboards",
        message: action.payload.message,
      };
    },

    [getObjectDashboard.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getObjectDashboard" };
    },
    [getObjectDashboard.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectDashboard",
        message: action.payload.message,
      };
    },
    [createObjectDashboard.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createObjectDashboard" };
      state.activeId = action.payload.uuId;
    },
    [createObjectDashboard.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectDashboard",
        message: action.payload.message,
      };
    },
    [updateObjectDashboard.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "updateObjectDashboard" };
    },
    [updateObjectDashboard.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectDashboard",
        message: action.payload.message,
      };
    },
    [deleteObjectDashboard.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteObjectDashboard" };
    },
    [deleteObjectDashboard.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectDashboard",
        message: action.payload.message,
      };
    },

    [getAllSourceViewsForObject.fulfilled]: (state, action) => {
      state.allSourceViews = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getAllSourceViewsForObject",
      };
    },
    [getAllSourceViewsForObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAllSourceViewsForObject",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = objectDashboardsSlice.actions;

export default objectDashboardsSlice.reducer;
