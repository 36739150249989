import { Box, FormControl, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { default as Select } from "react-select";
import NtaiInputErrorMessage from "./NtaiInputErrorMessage";
const _ = require("lodash");

const NtaiSelect = (props) => {
  const { options, onChange, name, label, value } = props;

  const [values, setValues] = useState();

  const {
    formState: { errors },
  } = useFormContext();

  function handleChange(e) {
    console.log(2);
  }

  const handleSelectionChange = (topicOptions) => {
    setValues(topicOptions);
  };

  useEffect(() => {
    if (Array.isArray(value)) {
      setValues([...value]);
    }
  }, [value]);

  return (
    <Box sx={{ display: "flex", width: props.width ? props.width : "100%" }}>
      <FormControl fullWidth>
        {label && (
          <Typography variant="subtitle1" color="inherit">
            {label}
          </Typography>
        )}

        <Select
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          value={values}
          options={options}
          // onChange={(val) => onChange(val.map((c) => c.value))}
          isClearable
          isMulti={true}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          // menuPortalTarget={document.body}
          // menuPosition={isFixed ? "fixed" : "absolute"}
          // menuPlacement={portalPlacement}
          menuShouldScrollIntoView={false}
          {...props}
        />

        <NtaiInputErrorMessage name={name} errors={errors} />
      </FormControl>
    </Box>
  );
};

function NtaiMultiSelectField(props) {
  const { name, options, label } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <React.Fragment>
      <Controller
        control={control}
        defaultValue={options.map((c) => c.value)}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <Box
            sx={{ display: "flex", width: props.width ? props.width : "100%" }}
          >
            <FormControl fullWidth>
              {label && <Typography variant="subtitle1">{label}</Typography>}
              <Select
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                inputRef={ref}
                value={options.filter((c) => value?.includes(c.value))}
                onChange={(val) => onChange(val.map((c) => c.value))}
                options={options}
                isMulti
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
              <NtaiInputErrorMessage name={name} errors={errors} />
            </FormControl>
          </Box>
        )}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiMultiSelectField;
