import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getTopics = createAsyncThunk(
  "topics/getTopics",
  async (values, { rejectWithValue }) => {
    try {
      const { params } = values;
      const response = await server.get(`/library/topics`, {
        params: params ? params : {},
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTopic = createAsyncThunk(
  "topics/getTopic",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/library/topics/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createTopic = createAsyncThunk(
  "topics/createTopic",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post(`/library/topics`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTopic = createAsyncThunk(
  "topics/updateTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/library/topics/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTopic = createAsyncThunk(
  "topics/deleteTopic",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/library/topics/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const topicsSlice = createSlice({
  name: "topics",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getTopics.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getTopics" };
    },
    [getTopics.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getTopics",
        message: action.payload.message,
      };
    },
    [getTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getTopic" };
    },
    [getTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getTopic",
        message: action.payload.message,
      };
    },
    [createTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createTopic" };
    },
    [createTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createTopic",
        message: action.payload.message,
      };
    },
    [updateTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateTopic" };
    },
    [updateTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateTopic",
        message: action.payload.message,
      };
    },
    [deleteTopic.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteTopic" };
    },
    [deleteTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteTopic",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = topicsSlice.actions;

export default topicsSlice.reducer;
