import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import NtaiCircularProgress from "../progress/NtaiCircularProgress";
import server from "app/http/ntaiServer";
import NtaiErrorMessage from "../errors/NtaiErrorMessage";

export default function NtaiPDFReportViewer(props) {
  const { height, url, params, type } = props;
  const [loaded, setLoaded] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState();
  const [message, setMessage] = React.useState();
  const [error, setError] = React.useState(false);

  function getIndividualReport() {
    server
      .get(url, {
        // params: params ? params : null,
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setFileUrl(fileURL);
        //Open the URL on new Window
        // window.open(fileURL);
        setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setLoaded(true);
        setMessage(error.message);
      });
  }

  function getAggregateReport() {
    server
      .post(
        url,
        {
          searchSourceWidgetFilters: params,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setFileUrl(fileURL);
        //Open the URL on new Window
        setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setLoaded(true);
        setMessage(error.message);
      });
  }

  useEffect(() => {
    type === 1 ? getAggregateReport(url) : getIndividualReport(url);
  }, [url, type]);

  return (
    <Box
      sx={{
        display: "flex",
        height: height ? height : "420px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loaded && !error && (
        <object data={fileUrl} width="100%" height="100%"></object>
      )}
      {!loaded && <NtaiCircularProgress />}
      {error && (
        <NtaiErrorMessage title="Error generating report" message={message} />
      )}
    </Box>
  );
}
