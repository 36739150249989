import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiInputErrorMessage from "./NtaiInputErrorMessage";
import { FormControl, TextareaAutosize } from "@mui/material";

const NtaiMuiTextArea = (props) => {
  const { name } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl>
      <TextareaAutosize id={name} {...props} />
      <NtaiInputErrorMessage errors={errors} name={name} />
    </FormControl>
  );
};

function NtaiTextArea(props) {
  const { control } = useFormContext();
  const { name, label, type } = props;
  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <NtaiMuiTextArea
            value={value || ""}
            onChange={(e) =>
              type !== null && type === "number"
                ? onChange(parseInt(e.target.value, 10))
                : onChange(e.target.value)
            }
            {...props}
          />
        )}
        control={control}
        name={name}
        label={label}
        defaultValue=""
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiTextArea;
