import React, { useState } from "react";
import NtaiDecoMenu from "./NtaiDecoMenu";
import {
  Avatar,
  Box,
  Button,
  Icon,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { faStaff } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiFlatMenu from "./NtaiFlatMenu";
import NtaiMenu from "./NtaiMenu";

export default function NtaiTestMenus() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open = Boolean(anchorEl);
  const openSimple = Boolean(anchorEl1);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClick = (event) => {
    console.log("event current target: ", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  function handleMenuItemSelect(val) {
    console.log("Selected menu: ", val);
  }

  return (
    <>
      <Box
        sx={{
          padding: theme.spacing(2),
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: theme.spacing(4),
          justifyContent: "space-around",
        }}
      >
        <Button size="small" onClick={handleClick1}>
          OPEN SIMPLE MENU
        </Button>

        <Button size="small" onClick={handleClick}>
          OPEN DECO MENU
        </Button>
      </Box>
      <NtaiDecoMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        header={
          <Box sx={{ display: "flex" }}>
            <Typography>Signed as Sharad</Typography>
          </Box>
        }
        items={[
          {
            icon: <FontAwesomeIcon icon={faStaff} />,
            label: "Archive",
            value: "archive",
          },
        ]}
        handleMenuItemSelect={handleMenuItemSelect}
      />

      <NtaiMenu
        open={openSimple}
        anchorEl={anchorEl1}
        handleClose={handleClose1}
        items={[
          {
            label: "Edit",
            value: "edit",
          },
          {
            label: "Delete",
            value: "delete",
          },
        ]}
      />
    </>
  );
}
