import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Toolbar, Typography, useTheme } from "@mui/material";
import history from "@ntai/@history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add, MoreVert, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import NtaiIcons from "utils/NtaiIcons";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
const _ = require("lodash");

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "44px !important",
    maxHeight: "44px !important",
    marginBottom: theme.spacing(0.2),
    padding: "0px !important",
  },
  highlight: {
    minHeight: "44px !important",
    maxHeight: "44px !important",
    display: "flex",
    itemsAlign: "center",
    marginBottom: theme.spacing(0.2),
    // backgroundColor: theme.colors.alpha.black[10],
  },
  title: {
    flex: "1 1 100%",
  },
  iconButton: {
    paddingRight: "8px",
  },
}));

export default function NtaiReadOnlyTableSearchbar(props) {
  const classes = useToolbarStyles();
  const theme = useTheme();
  const { title, handleSearch } = props;

  return (
    <Toolbar>
      <Box
        sx={{
          display: "flex",
          itemsAlign: "center",
          gap: theme.spacing(1),
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexBasis: "50%" }}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box>
          <NtaiSimpleSearchBar handleChange={handleSearch} />
        </Box>
      </Box>
    </Toolbar>
  );
}
