import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordTagColorRules = createAsyncThunk(
  "sourceRecordTagColorRules/getSourceRecordTagColorRules",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordTagId } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/tags/${sourceRecordTagId}/color-rules`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceRecordTagColorRule = createAsyncThunk(
  "sourceRecordTagColorRules/getSourceRecordTagColorRule",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordTagId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/tags/${sourceRecordTagId}/color-rules/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordTagColorRule = createAsyncThunk(
  "sourceRecordTagColorRules/createSourceRecordTagColorRule",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordTagId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/tags/${sourceRecordTagId}/color-rules`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordTagColorRule = createAsyncThunk(
  "sourceRecordTagColorRules/updateSourceRecordTagColorRule",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, sourceRecordTagId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/tags/${sourceRecordTagId}/color-rules/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordTagColorRule = createAsyncThunk(
  "SourceRecordTagColorRules/deleteSourceRecordTagColorRule",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordTagId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/tags/${sourceRecordTagId}/color-rules/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordTagColorRulesSlice = createSlice({
  name: "sourceRecordTagColorRules",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordTagColorRules.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceRecordTagColorRules",
      };
    },
    [getSourceRecordTagColorRules.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordTagColorRules",
        message: action.payload.message,
      };
    },
    [getSourceRecordTagColorRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceRecordTagColorRule",
      };
    },
    [getSourceRecordTagColorRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordTagColorRule",
        message: action.payload.message,
      };
    },
    [createSourceRecordTagColorRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordTagColorRule",
      };
    },
    [createSourceRecordTagColorRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordTagColorRule",
        message: action.payload.message,
      };
    },
    [updateSourceRecordTagColorRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordTagColorRule",
      };
    },
    [updateSourceRecordTagColorRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordTagColorRule",
        message: action.payload.message,
      };
    },
    [deleteSourceRecordTagColorRule.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordTagColorRule",
      };
    },
    [deleteSourceRecordTagColorRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordTagColorRule",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceRecordTagColorRulesSlice.actions;

export default sourceRecordTagColorRulesSlice.reducer;
