import { Box } from "@mui/material";
import React from "react";
import { OversightContext } from "../Oversight";
import OversightSummary from "./root/OversightSummary";
import { OVERSIGHT_SECTIONS } from "../constants/OversightConstants";
import OversightEntity from "./entity/OversightEntity";
import OversightObject from "./object/OversightObject";
import NtaiForm from "@ntai/components/forms/NtaiForm";

export default function OversightMain() {
  const { section } = React.useContext(OversightContext);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {section === OVERSIGHT_SECTIONS.ROOT && <OversightSummary />}

      {section === OVERSIGHT_SECTIONS.OBJECT && <OversightObject />}

      {section === OVERSIGHT_SECTIONS.ENTITY && (
        <NtaiForm>
          <OversightEntity />
        </NtaiForm>
      )}
    </Box>
  );
}
