import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceDerivedFields = createAsyncThunk(
  "sourceDerivedFields/getSourceDerivedFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceDerivedField = createAsyncThunk(
  "sourceDerivedFields/getSourceDerivedField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceDerivedField = createAsyncThunk(
  "sourceDerivedFields/createSourceDerivedField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceDerivedField = createAsyncThunk(
  "sourceDerivedFields/updateSourceDerivedField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceDerivedField = createAsyncThunk(
  "sourceDerivedFields/deleteSourceDerivedField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}`
      );
      return { uuId: sourceDerivedFieldId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceDerivedFieldsSlice = createSlice({
  name: "sourceDerivedFields",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceDerivedFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceDerivedFields" };
    },
    [getSourceDerivedFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDerivedFields",
        message: action.payload.message,
      };
    },
    [getSourceDerivedField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceDerivedField" };
    },
    [getSourceDerivedField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDerivedField",
        message: action.payload.message,
      };
    },
    [createSourceDerivedField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceDerivedField" };
    },
    [createSourceDerivedField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceDerivedField",
        message: action.payload.message,
      };
    },
    [updateSourceDerivedField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceDerivedField" };
    },
    [updateSourceDerivedField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceDerivedField",
        message: action.payload.message,
      };
    },
    [deleteSourceDerivedField.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceDerivedField" };
    },
    [deleteSourceDerivedField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceDerivedField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceDerivedFieldsSlice.actions;

export default sourceDerivedFieldsSlice.reducer;
