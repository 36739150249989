import { Box, useTheme } from "@mui/material";
import React from "react";
import ReportDefinitionSourceFilterFieldSelection from "./ReportDefinitionSourceFilterFieldSelection";
import ReportDefinitionSourceFilterFieldForm from "./ReportDefinitionSourceFilterFieldForm";
import ReportDefinitionSourceFilterFields from "./ReportDefinitionSourceFilterFields";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import {
  clearStatus,
  createOrDeleteReportDefinitionParams,
  getReportDefinitionParams,
} from "../store/reportDefinitionParamsSlice";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

export const ReportDefinitionSourceFilterContext = React.createContext();

export default function ReportDefinitionSourceFilterWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [sourceFieldsLoaded, setSourceFieldsLoaded] = useState(false);
  const [sourceFields, setSourceFields] = useState();
  const [selectedField, setSelectedField] = useState();
  const [loaded, setLoaded] = useState(false);

  const reportDefinitionId = useSelector(
    (state) => state.reports.reportDefinitionsSlice.activeId
  );

  const reportDefinitionDataMap = useSelector(
    (state) => state.reports.reportDefinitionsSlice.data
  );

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionParamsSlice.data
  );

  const status = useSelector(
    (state) => state.reports.reportDefinitionParamsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const sourceId = _.get(
    _.get(reportDefinitionDataMap, reportDefinitionId),
    "sourceUuId"
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceFieldsData = !_.isEmpty(sourceFieldsDataMap)
    ? Object.values(sourceFieldsDataMap)
    : [];

  const sourcesSliceStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  useEffect(() => {
    const tmpDataMapSourceFieldUuIds = data.map((d) => d.sourceFieldUuId);

    if (
      !_.isEqual(
        tmpDataMapSourceFieldUuIds.sort(),
        [...selectedFields].sort()
      ) &&
      sourceFieldsLoaded
    ) {
      dispatch(
        createOrDeleteReportDefinitionParams({
          reportDefinitionId: reportDefinitionId,
          formData: {
            sourceFieldUuIds: [...selectedFields],
          },
        })
      );
    }
  }, [selectedFields, sourceFieldsLoaded]);

  useEffect(() => {
    const tmpSelectedFieldUuIds = data.map((d) => d.sourceFieldUuId);

    setAvailableFields([
      ..._.filter(sourceFieldsData, function (sf) {
        if (tmpSelectedFieldUuIds.includes(sf.uuId)) return false;
        else return true;
      }),
    ]);

    setSelectedFields([...tmpSelectedFieldUuIds]);
  }, [loaded, sourceFieldsLoaded]);

  useEffect(() => {
    if (
      sourcesSliceStatus &&
      sourcesSliceStatus.method === "getSourceFields" &&
      sourcesSliceStatus.result === "success"
    ) {
      setSourceFields({ ...sourceFieldsDataMap });
      setSourceFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
    }
  }, [sourcesSliceStatus]);

  useEffect(() => {
    if (
      status &&
      status.method === "getReportDefinitionParams" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getReportDefinitionParams(reportDefinitionId));
  }, [reportDefinitionId]);

  useEffect(() => {
    dispatch(
      getSourceFields({
        sourceDefId: "*",
        sourceId: sourceId,
      })
    );

    dispatch(
      getCodes(CODELIST_CODES.RPT_DEFINITION_PARAM_SOURCE_FILTER_FIELD_UI_CODE)
    );
  }, []);

  return (
    <ReportDefinitionSourceFilterContext.Provider
      value={{
        sourceFields,
        availableFields,
        setAvailableFields,
        selectedFields,
        setSelectedFields,
        selectedField,
        setSelectedField,
      }}
    >
      <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
        {loaded && sourceFieldsLoaded && (
          <React.Fragment>
            <ReportDefinitionSourceFilterFieldSelection />
            <ReportDefinitionSourceFilterFields />
            <ReportDefinitionSourceFilterFieldForm />
          </React.Fragment>
        )}
      </Box>
    </ReportDefinitionSourceFilterContext.Provider>
  );
}
