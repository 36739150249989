import { Box } from "@mui/material";
import React from "react";
import NtaiHistogramChart from "../charts/amcharts/NtaiHistogramChart";
import NtaiSourceWidget from "../widgets/source/NtaiSourceWidget";
import NtaiResizableGrid from "./NtaiResizableGrid";

const data = [
  {
    name: "USA",
    count: 12,
  },
  {
    name: "IN",
    count: 1,
  },
  {
    name: "FR",
    count: 34,
  },
];
export default function NtaiTestResizable() {
  function handleClick(t1, t2) {
    console.log("hello");
  }
  function generateItems() {
    let result = [];

    for (let i = 0; i < 10; i++) {
      result.push(
        <Box
          key={i}
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <NtaiSourceWidget title="Something">
            <NtaiHistogramChart
              widgetId="123"
              data={data}
              valueField="count"
              categoryField="name"
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        </Box>
      );
    }

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
