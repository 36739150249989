import { Box, useTheme } from "@mui/material";
import NtaiPackedCircleChart from "@ntai/components/charts/amcharts/NtaiPackedCircleChart";
import NtaiSankeyChart from "@ntai/components/charts/amcharts/NtaiSankeyChart";
import NtaiWordCloudChart from "@ntai/components/charts/amcharts/v2/NtaiWordCloudChart";
import NtaiHorizontalBarEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarEChart";
import NtaiHorizontalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarSeriesEChart";
import NtaiHorizontalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarStackedEChart";
import NtaiVerticalBarEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarEChart";
import NtaiVerticalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarSeriesEChart";
import NtaiBubbleEChart from "@ntai/components/charts/echarts/scatter/NtaiBubbleEChartOld";
import NtaiScatterEChart from "@ntai/components/charts/echarts/scatter/NtaiScatterEChart";
import NtaiDateHistogramEChart from "@ntai/components/charts/echarts/date-hist/NtaiDateHistogramEChart";
import NtaiCircularDependencyEChart from "@ntai/components/charts/echarts/force-graph/NtaiCircularDependencyEChart";
import NtaiForceDirectedEChart from "@ntai/components/charts/echarts/force-graph/NtaiForceDirectedEChart";
import NtaiHeatmapEChart from "@ntai/components/charts/echarts/heatmap/NtaiHeatmapEChart";
import NtaiDonutEChart from "@ntai/components/charts/echarts/pie/NtaiDonutEChart";
import NtaiPieEChart from "@ntai/components/charts/echarts/pie/NtaiPieEChart";
import NtaiTimeSeriesEChart from "@ntai/components/charts/echarts/time-series/NtaiTimeSeriesEChart";
import NtaiLinearTreeEChart from "@ntai/components/charts/echarts/tree/NtaiLinearTreeEChart";
import NtaiRadialTreeEChart from "@ntai/components/charts/echarts/tree/NtaiRadialTreeEChart";
import NtaiTreeMapEChart from "@ntai/components/charts/echarts/treemap/NtaiTreeMapEChart";
import NtaiMuiDataGridWidget from "@ntai/components/grid/mui/NtaiMuiDataGridWidget";
import NtaiDataGrid from "@ntai/components/tables/aggrid/NtaiDataGrid";
import NtaiXTab from "@ntai/components/tables/xtab/NtaiXTab";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useMeasure } from "react-use";
import NtaiGeoMap from "@ntai/components/charts/geo/NtaiGeoMap";
import NtaiVerticalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarStackedEChart";
import SearchSourceDataTableIIWidget from "app/main/pages/modules/search/edit/source/view/widgets/shared/SearchSourceDataTableIIWidget";
import SearchSourceSummaryTabWidget from "app/main/pages/modules/search/edit/source/view/widgets/shared/SearchSourceSummaryTabWidget";
import NtaiWidgetMetric from "@ntai/components/charts/metrics/NtaiWidgetMetric";
import NtaiBarLineEChart from "@ntai/components/charts/echarts/bar/NtaiBarLineEChart";
import NtaiWidgetMetricList from "@ntai/components/charts/metrics/NtaiWidgetMetricList";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function DashboardSourceWidget({ widget }) {
  const theme = useTheme();
  const metadata = _.get(widget, "sourceWidgetMetadata");
  const data = _.get(widget, "sourceWidgetData");
  const [barRef, { width: barWidth, height: barHeight }] = useMeasure();
  const [geoRef, { width: geoWidth, height: geoHeight }] = useMeasure();
  const [treeRef, { width: treeWidth, height: treeHeight }] = useMeasure();

  // function handleClick(p1, p2) {}

  function getFieldTypesFromMetadata() {
    let result = {};
    const catFields = _.filter(Object.values(metadata["fields"]), function (f) {
      if (f.field.dataTypeCode === 1) return true;
      else return false;
    });

    result = {
      categoryFields: catFields.length,
    };

    return result;
  }

  function handleClick(searchSourceWidgetFilterFields) {
    // handleFilter(
    //   SEARCH_SOURCE_WIDGET_MENU_ACTIONS.SELECT,
    //   _.get(widget, "widgetUuId"),
    //   searchSourceWidgetFilterFields
    // );
  }

  function handleContextMenu(actionCode, pSearchSourceWidgetFilterFields) {
    //handleFilter(_.get(widget, "widgetUuId"), searchSourceWidgetFilterFields);
    // if (actionCode === SEARCH_SOURCE_WIDGET_MENU_ACTIONS.NOTES) {
    //   setSearchSourceWidgetFilterFields(pSearchSourceWidgetFilterFields);
    //   handleNoteCreateDialogOpen();
    // } else {
    //   handleFilter(
    //     actionCode,
    //     _.get(widget, "widgetUuId"),
    //     pSearchSourceWidgetFilterFields
    //   );
    // }
  }

  function handleLinkClick(
    linkedSourceUuId,
    linkUuId,
    linkValue,
    sourceRecordId
  ) {
    // dispatch(
    //   handleLinkClickForLinkedSearchSource({
    //     searchId: searchId,
    //     parentSearchSourceId: searchSourceId,
    //     linkedSourceSourceUuId: linkedSourceUuId,
    //     linkUuId: linkUuId,
    //     linkValue: linkValue,
    //     sourceRecordId: sourceRecordId,
    //   })
    // );
  }

  return (
    <React.Fragment>
      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.BAR_HORIZONTAL && (
          <NtaiHorizontalBarEChart
            widgetId={widget.uuId}
            data={data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={metadata}
          />
        )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.BAR_HORIZONTAL_SERIES && (
          <NtaiHorizontalBarSeriesEChart
            widgetId={widget.uuId}
            data={data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={metadata}
          />
        )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.BAR_HORIZONTAL_STACKED && (
          <NtaiHorizontalBarStackedEChart
            widgetId={widget.uuId}
            data={data}
            // categoryField={_.get(widgetMetadata, "categoryField")}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={metadata}
          />
        )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.BAR_VERTICAL && (
        <NtaiVerticalBarEChart
          widgetId={widget.uuId}
          data={data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={metadata}
        />
      )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.BAR_VERTICAL_SERIES && (
          <NtaiVerticalBarSeriesEChart
            widgetId={widget.uuId}
            data={data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={metadata}
          />
        )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.BAR_VERTICAL_STACKED && (
          <NtaiVerticalBarStackedEChart
            widgetId={widget.uuId}
            data={data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={metadata}
          />
        )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.PIE && (
        <NtaiPieEChart
          widgetId={widget.uuId}
          data={data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={metadata}
        />
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.DONUT && (
        <NtaiDonutEChart
          widgetId={widget.uuId}
          data={data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={metadata}
        />
      )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.DATE_HISTOGRAM && (
          <NtaiDateHistogramEChart
            widgetId={widget.uuId}
            data={data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={metadata}
          />
        )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.TIME_SERIES && (
        <NtaiTimeSeriesEChart
          widgetId={widget.uuId}
          data={data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={metadata}
        />
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.CHORD && (
        <NtaiCircularDependencyEChart
          widgetId={widget.uuId}
          data={data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={metadata}
        />
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.GEO && (
        <Box
          sx={{ display: "flex", width: "100%", height: "100%" }}
          ref={geoRef}
        >
          <NtaiGeoMap
            widgetId={widget.uuId}
            data={data}
            // handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            handleClick={handleClick}
            metadata={metadata}
            width={geoWidth}
            height={geoHeight}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.DATA_GRID_I && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiDataGrid
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            handleClick={handleClick}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.DATA_GRID_II && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <SearchSourceDataTableIIWidget
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            // handleLinkClick={handleLinkClick}
          />
        </Box>
      )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.SUMMARY_TABULATION && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <SearchSourceSummaryTabWidget
              widgetId={widget.uuId}
              data={data}
              metadata={metadata}
              handleClick={handleClick}
            />
          </Box>
        )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.PACKED_CIRCLE && (
        <NtaiPackedCircleChart
          widgetId={widget.uuId}
          data={data}
          metadata={metadata}
          handleClick={handleClick}
        />
      )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.FORCE_DIRECTED && (
          <NtaiForceDirectedEChart
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            handleClick={handleClick}
          />
        )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.TREEMAP && (
        <NtaiTreeMapEChart
          widgetId={widget.uuId}
          data={data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={metadata}
        />
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.PIVOT_GRID && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            pb: theme.spacing(1),
          }}
        >
          <NtaiXTab
            widgetId={widget.uuId}
            rows={data}
            metadata={metadata}
            handleClick={handleClick}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.SCATTER && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiScatterEChart
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.BUBBLE && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiBubbleEChart
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.SANKEY_PLOT && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiSankeyChart
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            fromField="from"
            toField="to"
            valueField="value"
            handleClick={handleClick}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.HEATMAP && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiHeatmapEChart
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            handleClick={handleClick}
            handleConetxtMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.WORD_CLOUD && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiWordCloudChart
            widgetId={widget.uuId}
            data={data}
            categoryField={_.get(metadata, "categoryField")}
            valueField={_.get(metadata, "valueField")}
            metadata={metadata}
            handleClick={handleClick}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.TREE_LINEAR && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiLinearTreeEChart
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.TREE_RADIAL && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiRadialTreeEChart
            widgetId={widget.uuId}
            data={data}
            metadata={metadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.SCRIPTED_METRIC && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiWidgetMetric
              data={
                _.isArray(data) &&
                _.has(data[0], "value") &&
                _.isNumber(data[0]["value"])
                  ? NtaiUtils.abbrevNumber(data["value"])
                  : 0
              }
              metadata={metadata}
            />
          </Box>
        )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.AGGREGATE_METRIC &&
        _.isArray(data) &&
        data.length === 1 && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiWidgetMetric data={data} metadata={metadata} />
          </Box>
        )}

      {widget &&
        widget.sourceWidgetChartCode === CHART_CODES.AGGREGATE_METRIC &&
        _.isArray(data) &&
        data.length > 1 && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiWidgetMetricList data={data} metadata={metadata} />
          </Box>
        )}

      {widget && widget.sourceWidgetChartCode === CHART_CODES.BAR_LINE && (
        <NtaiBarLineEChart
          widgetId={widget.uuId}
          data={data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={metadata}
        />
      )}
    </React.Fragment>
  );
}
