import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  setActiveId,
  updateDashboardWidget,
  clearStatus,
} from "./store/dashboardWidgetsSlice";
import DashboardQueryWidgetForm from "./query-widget/DashboardQueryWidgetForm";
import DashboardSourceWidgetForm from "./source-widget/DashboardSourceWidgetForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import DashboardStdWidgetForm from "./std-widget/DashboardStdWidgetForm";
const _ = require("lodash");

export default function DashboardWidgetEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const status = useSelector(
    (state) => state.core.dashboardWidgetsSlice.status
  );
  const dataMap = useSelector((state) => state.core.dashboardWidgetsSlice.data);
  const activeId = useSelector(
    (state) => state.core.dashboardWidgetsSlice.activeId
  );
  const id = props.match.params.id;

  const formData = _.get(dataMap, id);

  const widgetType =
    _.get(formData, "stdWidgetFg") === 1
      ? "std-widget"
      : _.get(formData, "sourceUuId") &&
        _.get(formData, "sourceUuId").length > 0
      ? "source-widget"
      : "query-widget";

  function handleFormSubmit(formData) {
    dispatch(updateDashboardWidget({ uuId: id, formData: formData }));
  }

  function handleCancel() {
    history.push("/admin/dashboards/widgets");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateDashboardWidget" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Dashboard widget "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Dashboard Widgets / ${_.get(dataMap[id], "name")}`}
      back="/admin/dashboards/widgets"
    >
      {widgetType === "std-widget" && (
        <NtaiForm>
          <DashboardStdWidgetForm
            action="edit"
            formData={formData}
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}

      {widgetType === "query-widget" && (
        <NtaiForm>
          <DashboardQueryWidgetForm
            action="edit"
            formData={formData}
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}

      {widgetType === "source-widget" && (
        <NtaiForm>
          <DashboardSourceWidgetForm
            action="edit"
            formData={formData}
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
    </NtaiPage>
  );
}
