import { Box, useTheme } from "@mui/material";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  clearStatus,
  deleteDistributionGroup,
  getDistributionGroups,
} from "./store/distributionGroupsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },

  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "numOfObjects",
    numeric: false,
    disablePadding: false,
    label: "# Contacts",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function DistributionGroupList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector(
    (state) => state.core.distributionGroupsSlice.data
  );
  const status = useSelector(
    (state) => state.core.distributionGroupsSlice.status
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteDistributionGroup(id));
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
    setCurrentId(null);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getDistributionGroups" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteDistributionGroup" &&
      status.result === "success"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Distribution group(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getDistributionGroups());
  }, []);

  return (
    <React.Fragment>
      <NtaiPage title="Distribution Groups" padding={theme.spacing(2)}>
        {loaded && (
          <NtaiCrudTable
            helpText="Distribution group library enables you to create lists of contacts, contact groups and users to distribute reports and notifications to multiple recipients simultaneously. Ideal for group based report distribution and notifications."
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/library/distribution-groups`}
            handleToolbarAction={handleToolbarAction}
            title="Distribution Group"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
