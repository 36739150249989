import React from "react";
import JsPDF from "jspdf";
import { Box, Button } from "@mui/material";

export default function NtaiPdfReport() {
  const generatePDF = () => {
    const report = new JsPDF("portrait", "pt", "a4");
    report.rect(20, 20, 10, 10);
    report.html(document.querySelector("#report")).then(() => {
      report.save("report.pdf");
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Button onClick={() => generatePDF()}>GENERATE</Button>
      </Box>
      <Box id="report">My report</Box>
    </Box>
  );
}
