import CoreConfig from "./pages/core/CoreConfig";
import DashboardConfig from "./pages/dashboard/DashboardConfig";
import SourceConfig from "./pages/core/CoreConfig";
import ModulesConfig from "./pages/modules/ModulesConfig";
import TestsConfig from "./pages/tests/TestsConfig";
import LoginConfig from "./pages/login/LoginConfig";
import NtaiDocsConfig from "./pages/docs/NtaiDocsConfig";

const pagesConfigs = [
  LoginConfig,
  CoreConfig,
  DashboardConfig,
  SourceConfig,
  ModulesConfig,
  TestsConfig,
  NtaiDocsConfig,
];

export default pagesConfigs;
