import { Typography, useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useState } from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function ReportScheduledReportView(props) {
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);

  const reportDefinitionId = props.match.params.reportdefinitionid;
  const reportId = props.match.params.reportid;
  const reportScheduleId = props.match.params.reportscheduleid;
  const reportDocumentId = props.match.params.id;

  const reportDataMap = useSelector((state) => state.reports.reportsSlice.data);
  const reportData =
    reportDataMap && !_.isEmpty(reportDataMap) ? reportDataMap[reportId] : null;

  const dataMap = useSelector(
    (state) => state.reports.reportScheduledReportsSlice.data
  );
  const status = useSelector(
    (state) => state.reports.reportScheduledReportsSlice.status
  );

  const report = dataMap[reportDocumentId];

  return (
    <NtaiPage
      title={`${reportData["name"]} - Scheduled Reports - ${report["reportDocumentName"]}`}
      back={`/reports/${reportDefinitionId}/reports/${reportId}`}
      headerActions={
        <Typography variant="body1" fontWeight="500">
          Date Generated: {report["dateCreated"]}
        </Typography>
      }
    >
      {report["reportDocument"] && report["reportDocument"].length > 0 && (
        <embed
          id="report"
          src={`data:application/pdf;base64,${report["reportDocument"]}`}
          width="100%"
          height="100%"
        />
      )}
    </NtaiPage>
  );
}
