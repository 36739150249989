import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceSeriesList = createAsyncThunk(
  "sourceSeries/getSourceSeriesList",
  async (thunkApi, { rejectWithValue }) => {
    try {
      const response = await server.get("/library/source-series");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceSeriesBySourceId = createAsyncThunk(
  "sourceSeries/getSourceSeriesBySourceId",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.put("/library/source-series", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceSeries = createAsyncThunk(
  "sourceSeries/getSourceSeries",
  async (sourceSeriesUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/library/source-series/${sourceSeriesUuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceSeries = createAsyncThunk(
  "sourceSeries/createSourceSeries",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/library/source-series", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceSeries = createAsyncThunk(
  "sourceSeries/updateSourceSeries",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/library/source-series/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceSeries = createAsyncThunk(
  "sourceSeries/deleteSourceSeries",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/library/source-series/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceSeriesSlice = createSlice({
  name: "sourceSeries",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceSeriesList.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceSeriesList" };
    },
    [getSourceSeriesList.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceSeriesList",
        mesage: action.payload.mesage,
      };
    },

    [getSourceSeriesBySourceId.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceSeriesBySourceId" };
    },
    [getSourceSeriesBySourceId.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceSeriesBySourceId",
        mesage: action.payload.mesage,
      };
    },
    [getSourceSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceSeries" };
    },
    [getSourceSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceSeries",
        mesage: action.payload.mesage,
      };
    },
    [createSourceSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceSeries" };
      state.activeId = action.payload.uuId;
    },
    [createSourceSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceSeries",
        mesage: action.payload.mesage,
      };
    },
    [updateSourceSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceSeries" };
    },
    [updateSourceSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceSeries",
        mesage: action.payload.mesage,
      };
    },
    [deleteSourceSeries.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceSeries" };
    },
    [deleteSourceSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceSeries",
        mesage: action.payload.mesage,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceSeriesSlice.actions;

export default sourceSeriesSlice.reducer;
