import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  getSourceAdjudications,
  createSourceAdjudication,
  updateSourceAdjudication,
  deleteSourceAdjudication,
  clearStatus,
  setActiveId,
} from "./store/sourceAdjudicationsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourceAdjudicationForm from "./SourceAdjudicationForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "adjObjectShortName",
    numeric: false,
    disablePadding: false,
    label: "Short name",
    fontWeight: 700,
  },
  {
    id: "adjObjectName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "relationTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Relation",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

export default function SourceAdjudicationList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const dataMap = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.data
  );
  const activeId = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.activeId
  );
  const status = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(
          deleteSourceAdjudication({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(
        selected,
        dataMap,
        "adjObjectShortName"
      ),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceAdjudications" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceAdjudication"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Source assessment form(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceAdjudications({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <>
      <NtaiPage
        title={`${_.get(sourceDefData, "name")} / Assessment Forms`}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        <NtaiPanel
          width="75%"
          header="Assessment Forms"
          subheader="Source assessment forms are user defined forms that can be associated with a source to record findings and assessments pertaining to individual source records."
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              selectedIds={selectedIds}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              path={`/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`}
              handleDialogAddEdit={handleDialogAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Assessment Form"
            />
          )}
          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      </NtaiPage>

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "adjObjectShortName"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
