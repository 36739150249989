import React from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import NtaiList from "@ntai/components/lists/NtaiList";
import { blue, green, grey, red } from "@mui/material/colors";
import { ThemeContext } from "app/theme/NtaiThemeProvider";

const themes = [
  {
    id: "NtaiDarkTheme",
    avatar: <Avatar sx={{ bgcolor: grey[900] }}>D</Avatar>,
    primary: (
      <Typography variant="subtitle1" color="inherit" fontWeight="700">
        Theme Standard
      </Typography>
    ),
    secondary: "NextTrial AI Default Theme",
  },
  {
    id: "NtaiBlueTheme",
    avatar: <Avatar sx={{ bgcolor: blue[500] }}>B</Avatar>,
    primary: (
      <Typography variant="subtitle1" color="inherit" fontWeight="700">
        Theme Sapphire
      </Typography>
    ),
    secondary: "NextTrial AI Brand Blue",
  },
  {
    id: "NtaiRedTheme",
    avatar: <Avatar sx={{ bgcolor: red[500] }}>R</Avatar>,
    primary: (
      <Typography variant="subtitle1" color="inherit" fontWeight="700">
        Theme Crimson
      </Typography>
    ),
    secondary: "NextTrial AI Brand Red",
  },
  {
    id: "NtaiGreenTheme",
    avatar: <Avatar sx={{ bgcolor: green[500] }}>G</Avatar>,
    primary: (
      <Typography variant="subtitle1" color="inherit" fontWeight="700">
        Theme Jade
      </Typography>
    ),
    secondary: "NextTrial AI Brand Green",
  },
  {
    id: "NtaiMixedTheme1",
    avatar: <Avatar sx={{ bgcolor: green[500] }}>G</Avatar>,
    primary: (
      <Typography variant="subtitle1" color="inherit" fontWeight="700">
        Theme Mixed 1
      </Typography>
    ),
    secondary: "NextTrial AI Mixed Theme 1",
  },
];

export default function ThemeSelector({ handleDialogClose }) {
  const theme = useTheme();
  const { setThemeName } = React.useContext(ThemeContext);

  function handleSelect(item) {
    setThemeName(item);
    handleDialogClose();
  }

  return <NtaiList handleSelect={handleSelect} items={themes} />;
}
