import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "../chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

export default function NtaiBarHorizontalSeriesChart(props) {
  const { widgetId, data, categoryField, valueField, handleClick, metadata } =
    props;
  const theme = useTheme();

  React.useEffect(() => {
    let chart = am4core.create(
      "bar-horizontal".concat("-", widgetId),
      am4charts.XYChart
    );

    chart.data = data;
    // chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = 20;
    chart.cursor.behavior = "zoomY";
    // chart.cursor.zoomY = true; // new am4charts.XYCursor();
    chart.responsive.enabled = true;

    // zoom icon
    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#ffffff");
    chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#eeeeee");

    chart.zoomOutButton.icon.disabled = true;
    let zoomImage = chart.zoomOutButton.createChild(am4core.Image);
    zoomImage.href = "/images/zoom-icon.png";
    zoomImage.width = 15;
    zoomImage.height = 15;
    zoomImage.interactionsEnabled = false;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = categoryField;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0.1;
    categoryAxis.renderer.grid.template.stroke = "#fff";
    categoryAxis.title.fontSize = 11;
    categoryAxis.renderer.minGridDistance = 2;
    categoryAxis.interpolationDuration = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    // label.rotation = 270;
    // label.maxWidth = 120;
    // label.horizontalCenter = "right";
    // label.verticalCenter = "middle";
    label.fontSize = 10;
    label.truncate = true;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;
    let valueAxisLabel = valueAxis.renderer.labels.template;
    valueAxisLabel.fontSize = 10;

    let axisTooltip = categoryAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 8;
    // categoryAxis.cursorTooltipEnabled = false;
    // categoryAxis.sortBySeries = series;

    let valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#767676");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    // valueAxisTooltip.rotation = 270;
    // valueAxisTooltip.dy = 5;
    valueAxisTooltip.dx = -20;
    valueAxisTooltip.label.fill = am4core.color("#fff");
    valueAxisTooltip.label.fontSize = 11;

    // Create series
    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = categoryField;
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueX}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;
      series.columns.template.stroke = lighten(
        theme.colors.primary.lighter,
        0.9
      );

      const colRand = NtaiUtils.getRandomFloat(0.1, 0.7);

      series.columns.template.fill = am4core.color(
        lighten(theme.colors.chart.lighter, colRand)
      ); // main: FFD366, orange: FDC65C green: 73EB90, blue: 1E90FF, red: FF7276 // green: 65fe08 // brand: FFD366

      //   let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      //   valueLabel.label.text = "{valueX}";
      //   valueLabel.label.horizontalCenter = "left";
      //   valueLabel.label.dx = 10;
      //   valueLabel.label.hideOversized = false;
      //   valueLabel.label.truncate = false;

      // let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
      // categoryLabel.label.text = "{name}";
      // categoryLabel.label.horizontalCenter = "right";
      // categoryLabel.label.dx = -10;
      // categoryLabel.label.hideOversized = false;
      // categoryLabel.label.truncate = false;
      // categoryLabel.label.fontSize = 10;

      // tool tip
      chart.tooltip.getFillFromObject = false;

      // tool tip
      //   series.tooltip.getFillFromObject = false;
      //   series.tooltip.autoTextColor = false;
      //   series.tooltip.background.cornerRadius =
      //     tooltipConfig.backgroundCornerRadius;
      //   series.tooltip.background.fill = am4core.color(
      //     tooltipConfig.backgroundFill
      //   );
      //   series.tooltip.background.fillOpacity =
      //     tooltipConfig.backgroundFillOpacity;
      //   series.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
      series.tooltip.label.fontSize = tooltipConfig.labelFontSize;

      series.columns.template.events.on(
        "hit",
        function (ev) {
          const sourceWidgetFilterFields = [
            {
              sourceFieldUuId: _.get(
                _.filter(Object.values(_.get(metadata, "fields")), {
                  fieldOrder: 1,
                })[0],
                "field.uuId"
              ),
              fieldOperatorCode: 1,
              fieldValue: ev.target.dataItem.categories.categoryY,
            },
            {
              sourceFieldUuId: _.get(
                _.filter(Object.values(_.get(metadata, "fields")), {
                  fieldOrder: 2,
                })[0],
                "field.uuId"
              ),
              fieldOperatorCode: 1,
              fieldValue: ev.target.dataItem.component.dataFields.valueX,
            },
          ];

          handleClick(sourceWidgetFilterFields, ev);
        },
        this
      );
    }

    metadata["subCategoryValues"].forEach((smd) => {
      createSeries(smd.value, smd.label);
    });

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      {Array.isArray(data) && data.length > 0 && (
        <Box
          id={"bar-horizontal".concat("-", widgetId)}
          sx={{ width: "100%", height: "100%" }}
        ></Box>
      )}

      {Array.isArray(data) && data.length === 0 && <NtaiChartEmptyMessage />}
    </>
  );
}
