import {
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  dialogTitle: {
    borderBottom: "1px solid #eee",
  },
  dialogHeader: {
    marging: "1px",
    padding: "10px",
    border: "1px solid rounded #eee",
    borderRadius: "10px",
    backgroundColor: colors.cyan[50],
  },
}));

export default function NtaiConfirmActionDialog({
  action,
  items,
  content,
  open,
  size,
  handleConfirmAction,
  handleCancelAction,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState(size ? size : "sm");
  const [openDialog, setOpenDialog] = useState(true);

  function handleDialogOpen() {}

  function handleAction() {
    handleConfirmAction();
    setOpenDialog(false);
  }

  function handleCancel() {
    handleCancelAction();
    setOpenDialog(false);
  }

  function generateItems() {
    let listItems = [];
    items.forEach((item, index) => {
      listItems.push(
        <Box key={index} component="li">
          {item}
        </Box>
      );
    });
    return <Box component="ul">{listItems}</Box>;
  }

  return (
    <Dialog
      open={open}
      onClose={(e) => handleCancel()}
      aria-labelledby={`confirm-action`}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      style={{ overflow: "visible" }}
    >
      <DialogTitle id="confirm-action" className={classes.dialogTitle}>
        {`Confirm ${action}`}
      </DialogTitle>

      <DialogContent style={{ overflow: "visible" }}>
        {content ? (
          content
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              paddingTop: 1,
            }}
          >
            <Typography variant="body2">
              {`Are you sure you want to ${action} following items?`}
            </Typography>
            {items && generateItems()}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(2) }}>
        <Button size="small" onClick={(e) => handleCancel()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={(e) => handleAction()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
