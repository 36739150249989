import PythonFileCreate from "./PythonFileCreate";
import PythonFileEdit from "./PythonFileEdit";
import PythonFileList from "./PythonFileList";

const PythonFileConfig = {
  routes: [
    {
      path: "/models/python-files",
      exact: true,
      component: PythonFileList,
    },
    {
      path: "/models/python-files/create",
      exact: true,
      component: PythonFileCreate,
    },
    {
      path: "/models/python-files/:id/edit",
      exact: true,
      component: PythonFileEdit,
    },
  ],
};

export default PythonFileConfig;
