import React, { useEffect, useMemo, useState } from "react";
import { Box, lighten, useTheme } from "@mui/material";
import {
  tooltipConfig,
  xAxisLabelNoRotationConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBarEChartUtil, {
  barGridConfig,
  hYAxisLabelConfig,
  hYAxisLineConfig,
} from "./util/NtaiBarEChartUtil";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: {
    containLabel: true,
    left: 20,
    top: 20,
    right: 40,
    bottom: 30,
  },
  tooltip: {
    ...tooltipConfig,
    formatter: function (params) {
      return NtaiBarEChartUtil.getTooltipSingleSeries(params);
    },
  },
  xAxis: {
    type: "category",
    type: "value",
    axisLabel: {
      ...xAxisLabelNoRotationConfig,
      formatter: (val) => `${NtaiUtils.abbrevNumber(val)}`,
    },
  },
  yAxis: {
    type: "category",
    data: null,
    axisLine: hYAxisLineConfig,
    axisLabel: hYAxisLabelConfig,
  },
  series: [
    {
      cursor: "default",
      data: null,
      type: "bar",
    },
  ],
};

export default function NtaiHorizontalBarEChart({
  widgetId,
  data,
  metadata,
  handleClick,
  handleContextMenu,
}) {
  const theme = useTheme();
  const [option, setOption] = useState(DEFAULT_OPTION);
  const [height, setHeight] = useState();
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(action, params) {
    return metadataRef.current && !_.isEmpty(metadataRef.current)
      ? NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
          [1],
          params,
          metadataRef.current,
          action
        )
      : [];
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params));
  }

  function onHandleContextMenu(action, params) {
    //console.log("Clicked right: ", action, params);
    handleContextMenu(
      action,
      getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params)
    );
  }

  useEffect(() => {
    const sortedData = _.orderBy(data, ["total", "name"], ["dsc", "asc"]);
    const tmpCategories = sortedData.map((d, i) => d["name"]);
    const colors = NtaiEChartUtil.getColorPalette(theme, 1);

    const revisedData = sortedData.map((d, i) => {
      return {
        value: d["total"],
        _color: colors[0],
        _actual: metadata["filterFg"] ? d["value"] : d["total"],
        _total: d["total"],
        _filterFg: metadata["filterFg"],
        itemStyle: NtaiBarEChartUtil.getItemStyle(
          d,
          metadata,
          d.hasOwnProperty("colorValue")
            ? NtaiEChartUtil.getItemStyleColor(
                "bg",
                d["colorValue"] && d["colorValue"].length > 0
                  ? d["colorValue"]
                  : d["value"],
                colors[0],
                metadata
              )
            : colors[0],
          "h"
        ),
      };
    });

    const copyOption = _.cloneDeep(option);
    copyOption["yAxis"]["data"] = tmpCategories;
    copyOption["series"][0]["data"] = revisedData;
    copyOption["series"][0]["label"] = {
      show: true,
      position: "right",
      formatter: NtaiBarEChartUtil.getHBarLabelFormatter,
    };

    setOption(copyOption);
    setHeight(NtaiBarEChartUtil.getHorizontalBarChartHeight(data, false));
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        height={height}
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box
      id={widgetId}
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        pb: theme.spacing(1),
      }}
    >
      {data && data.length > 0 && (
        <Scrollbar autoHide={true}>
          {option["series"][0]["data"] && chart}
        </Scrollbar>
      )}
      {data.length === 0 && <NtaiChartEmptyMessage />}
    </Box>
  );
}
