import React from "react";
import NtaiEmptyMessage from "../micros/NtaiEmptyMessage";

export default function NtaiChartEmptyMessage() {
  return (
    <NtaiEmptyMessage
      header="No data available"
      headerVariant="subtitle1"
      vAlign="center"
    />
  );
}
