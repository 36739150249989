import UserGroupCreate from "./UserGroupCreate";
import UserGroupEdit from "./UserGroupEdit";
import UserGroupList from "./UserGroupList";

const UserGroupConfig = {
  routes: [
    {
      path: "/admin/user-groups",
      exact: true,
      component: UserGroupList,
    },
    {
      path: "/admin/user-groups/create",
      exact: true,
      component: UserGroupCreate,
    },
    {
      path: "/admin/user-groups/:id/edit",
      exact: true,
      component: UserGroupEdit,
    },
  ],
};

export default UserGroupConfig;
