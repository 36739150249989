import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSource, clearStatus } from "./store/sourcesSlice";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { Box, Button } from "@mui/material";
import NtaiInfoPanel2 from "@ntai/components/micros/NtaiInfoPanel2";
const _ = require("lodash");

export default function SourceCreate() {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.sources.sourcesSlice.status);
  const activeId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  function handleCreateSource(action) {
    dispatch(createSource({ sourceDefId: sourceDefId, formData: {} }));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSource" &&
      status.result === "success"
    ) {
      history.push(`/sourcedefs/${sourceDefId}/sources/${activeId}/manage`);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title={`${_.get(sourceDefData, "name")} > Add new version`}
      back={`/sourcedefs`}
    >
      <NtaiInfoPanel2
        header="No version found"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
        actions={[{ value: "create", label: "Create new version" }]}
        handleAction={handleCreateSource}
      />
    </NtaiPage>
  );
}
