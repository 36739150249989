import { Box, useTheme } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiReadOnlyTable from "@ntai/components/tables/read-only/NtaiReadOnlyTable";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils";
import React from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "dbColName",
    numeric: false,
    disablePadding: false,
    label: "Column",
    fontWeight: 700,
  },

  {
    id: ["dbColDataTypeDecode", "dbColLength", "dbColScale", "dbColPrecision"],
    numeric: false,
    disablePadding: false,
    label: "Date Type",
    transformFunc: function (o) {
      return NtaiUtils.getPostgresDataTypeText(
        o[0].colValue,
        o[1].colValue,
        o[2].colValue,
        o[3].colValue
      );
    },
  },
];

export default function SourceOutDbFieldMapping() {
  const theme = useTheme();

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourceFieldsSlice.data
  );

  const data =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? _.filter(
          _.orderBy(Object.values(sourceFieldsDataMap), ["dbColName"], ["asc"]),
          function (o) {
            return o["dbColName"].startsWith("sys_") ? false : true;
          }
        )
      : [];

  function handleRowClick(v) {}

  return (
    <Box sx={{ width: "520px" }}>
      <NtaiPanel
        header="Fields Mapping"
        subheader="Displays database field mapping"
        width="100%"
      >
        <NtaiReadOnlyTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          title={`Fields (${data.length})`}
          handleRowClick={handleRowClick}
          pageSize={10}
        />
      </NtaiPanel>
    </Box>
  );
}
