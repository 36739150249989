import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import React from "react";

export default function NtaiAddRecordCard(props) {
  const { width, height, children, border, handleClick } = props;

  const theme = useTheme();
  return (
    <Card
      elevation={0}
      onClick={() => handleClick()}
      sx={{
        // marginTop: "8px",
        background: theme.colors.secondary.lighter,
        width: width ? width : null,
        height: height ? height : null,
        border: border && `1px solid ${theme.colors.alpha.black[20]}`,
        "&:hover": {
          background: theme.colors.secondary,
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
        },
      }}
    >
      <CardContent
        sx={{ display: "flex", height: "100%", justifyContent: "center" }}
      >
        <Box
          sx={{
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="2x" />
          <Typography variant="h6">{children}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
