import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceOutJobs = createAsyncThunk(
  "sourceOutJobs/getSourceOutJobs",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceOutJob = createAsyncThunk(
  "sourceOutJobs/getSourceOutJob",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceOutJob = createAsyncThunk(
  "sourceOutJobs/createSourceOutJob",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceOutJob = createAsyncThunk(
  "sourceOutJobs/updateSourceOutJob",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceOutJob = createAsyncThunk(
  "sourceOutJobs/deleteSourceOutJob",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitSourceOutJob = createAsyncThunk(
  "sourceOutJobs/submitSourceOutJob",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs/${uuId}/submit`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceOutJobsSlice = createSlice({
  name: "sourceOutJobs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceOutJobs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceOutJobs" };
    },
    [getSourceOutJobs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutJobs",
        message: action.payload.message,
      };
    },
    [getSourceOutJob.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceOutJob" };
    },
    [getSourceOutJob.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutJob",
        message: action.payload.message,
      };
    },
    [createSourceOutJob.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceOutJob" };
    },
    [createSourceOutJob.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceOutJob",
        message: action.payload.message,
      };
    },
    [updateSourceOutJob.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceOutJob" };
    },
    [updateSourceOutJob.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceOutJob",
        message: action.payload.message,
      };
    },
    [deleteSourceOutJob.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceOutJob" };
    },
    [deleteSourceOutJob.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceOutJob",
        message: action.payload.message,
      };
    },

    [submitSourceOutJob.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "submitSourceOutJob" };
    },
    [submitSourceOutJob.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "submitSourceOutJob",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceOutJobsSlice.actions;

export default sourceOutJobsSlice.reducer;
