import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceHierarchy = createAsyncThunk(
  "searchSourceHierarchy/getSearchSourceHierarchy",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/hierarchy`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const selectSearchSourceHierarchyNode = createAsyncThunk(
  "searchSourceHierarchy/selectSearchSourceHierarchyNode",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/hierarchy/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceHierarchyNode = createAsyncThunk(
  "searchSourceHierarchy/deleteSearchSourceHierarchyNode",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;

      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/hierarchy/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceHierarchySlice = createSlice({
  name: "searchSourceHierarchy",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceHierarchy.fulfilled]: (state, action) => {
      state.data = { ...state.data, ..._.mapKeys([action.payload], "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceHierarchy",
      };
    },
    [getSearchSourceHierarchy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceHierarchy",
        message: action.payload.message,
      };
    },

    [selectSearchSourceHierarchyNode.fulfilled]: (state, action) => {
      state.data = { ...state.data, ..._.mapKeys([action.payload], "uuId") };
      state.status = {
        result: "success",
        method: "selectSearchSourceHierarchyNode",
      };
    },
    [selectSearchSourceHierarchyNode.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "selectSearchSourceHierarchyNode",
        message: action.payload.message,
      };
    },

    [deleteSearchSourceHierarchyNode.fulfilled]: (state, action) => {
      state.data = { ...state.data, ..._.mapKeys([action.payload], "uuId") };
      state.status = {
        result: "success",
        method: "deleteSearchSourceHierarchyNode",
      };
    },
    [deleteSearchSourceHierarchyNode.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceHierarchyNode",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchSourceHierarchySlice.actions;

export default searchSourceHierarchySlice.reducer;
