import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../SearchEdit";
import { SearchSourceContext } from "../SearchSource";
import {
  getSearchSourceView,
  clearStatus,
  updateSearchSourceView,
} from "./store/searchSourceViewsSlice";
import SearchSourceWidget from "./widgets/SearchSourceWidget";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useMeasure } from "react-use";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiResizableGrid1 from "@ntai/components/resizable/NtaiResizableGrid1";
import SearchSourceWidgetDrill from "./widgets/drill-mode/SearchSourceWidgetDrill";
const _ = require("lodash");

function processWidgetFilterUuIds(uuIds) {
  const uniqueIds = new Set();
  uuIds.forEach((item) => {
    const widgetUuIds = _.get(item, "sourceWidgetUuIds");
    widgetUuIds.forEach((item) => uniqueIds.add(item));
  });

  return uniqueIds;
}

export const SearchSourceWidgetDrillContext = React.createContext();

export default function SearchSourceView({
  handleFilter,
  handleLinkClick,
  searchDashboardSourceViewId,
}) {
  const theme = useTheme();
  const [refreshView, setRefreshView] = useState(false);
  const [drillModeWidgetUuId, setDrillModeWidgetUuId] = useState();
  const [drillModeOn, setDrillModeOn] = useState(false);
  const [drillWidgetFieldIds, setDrillWidgetFieldIds] = useState([]);
  const [drillWidgetChartId, setDrillWidgetChartId] = useState([]);

  const [ref, { width, height }] = useMeasure();
  const dispatch = useDispatch();
  const {
    searchSourceData,
    reload,
    setReload,
    selected,
    changeView,
    setChangeView,
    showAdvSearchFilterPanel,
    setChartIds,
  } = React.useContext(SearchSourceContext);

  const { searchData } = React.useContext(SearchContext);

  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  // const sourceViewUuId = _.get(searchSourceData, "selectedSourceViewUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceViewUuId =
    searchData["typeCode"] !== 3
      ? _.get(selectedSearchSourceData, "selectedSourceViewUuId")
      : searchDashboardSourceViewId;

  const widgetFilters = _.get(
    selectedSearchSourceData,
    "searchSourceWidgetFilters"
  );

  const widgetFilterUuIds = processWidgetFilterUuIds(
    _.map(widgetFilters, (obj) => _.pick(obj, ["sourceWidgetUuIds"]))
  );

  const dataMap = useSelector(
    (state) => state.search.searchSourceViewSlice.data
  );

  const data = _.get(dataMap, sourceViewUuId);

  const status = useSelector(
    (state) => state.search.searchSourceViewSlice.status
  );

  // const widgetsDataMap = useSelector(
  //   (state) => state.search.searchSourceViewWidgetsSlice.data
  // );

  function handleDrillMode(dmWidgetUuId) {
    setDrillModeWidgetUuId(dmWidgetUuId);
  }

  function generateWidgets() {
    let returnValue = [];

    const sourceViewWidgets = _.get(data, "sourceViewWidgets");

    let p = 0;

    if (Array.isArray(sourceViewWidgets) && sourceViewWidgets.length > 0) {
      _.orderBy(sourceViewWidgets, ["dateCreated"], ["asc"]).forEach(
        (sourceViewWidget, index) => {
          returnValue.push(
            <Box
              key={p}
              sx={{ display: "flex", width: "100%", height: "100%" }}
            >
              <SearchSourceWidget
                filterPresent={
                  widgetFilterUuIds &&
                  widgetFilterUuIds.has(
                    _.get(sourceViewWidget, "sourceViewWidgetUuId")
                  )
                    ? true
                    : false
                }
                searchSourceId={searchSourceId}
                sourceViewUuId={sourceViewUuId}
                widgetInitData={sourceViewWidget}
                handleFilter={handleFilter}
                handleLinkClick={handleLinkClick}
                drillModeOn={drillModeOn}
                drillModeWidgetUuId={drillModeWidgetUuId}
                handleDrillMode={handleDrillMode}
              />
            </Box>
          );
          p = p + 1;
        }
      );
    }

    return returnValue;
  }

  const debouncedOnHandleLayoutChange = _.debounce(handleLayoutChange, 500);

  function handleLayoutChange(layout) {
    const tmpLayoutCopy = layout.map((l) =>
      _.pick(l, ["i", "x", "y", "h", "w", "moved", "static"])
    );

    if (!_.isEqual(_.get(data, "sourceViewLayout"), tmpLayoutCopy)) {
      dispatch(
        updateSearchSourceView({
          searchId: searchId,
          searchSourceId: searchSourceId,
          sourceViewUuId: sourceViewUuId,
          formData: {
            sourceViewUuId: sourceViewUuId,
            sourceViewLayout: layout,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const tmpSourceViewWidgets = _.get(data, "sourceViewWidgets");
      const chartSourceWidgetUuIds = tmpSourceViewWidgets.map((m) => {
        return _.get(m, "sourceViewWidgetUuId");
      });

      setChartIds([...chartSourceWidgetUuIds]);
    }
  }, [data]);

  useEffect(() => {
    if (drillModeWidgetUuId && drillModeWidgetUuId.length > 0) {
      setDrillModeOn(true);
    } else {
      setDrillModeOn(false);
    }
  }, [drillModeWidgetUuId]);

  useEffect(() => {
    setRefreshView(false);
    dispatch(
      getSearchSourceView({
        searchId: searchId,
        searchSourceId: searchSourceId,
        sourceViewUuId: sourceViewUuId,
      })
    );

    if (changeView) setChangeView(false);
  }, [sourceViewUuId, changeView]); // removed reload: 30.mar.23

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceView"
    ) {
      setRefreshView(true);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <Box
      sx={{
        height: `calc(100% - 44px)`,
        display: "flex",
        width: "100%",
        background: grey[50],
        boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      {sourceViewUuId && selected && !drillModeOn && (
        <Scrollbar>
          <Box
            id="source-view"
            ref={ref}
            sx={{
              pr: theme.spacing(1),
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {/* refresh removed from view below - sp: 16.nov.22. Allow widgets to */}
            {refreshView && (
              <NtaiResizableGrid1
                rowHeight={50}
                cols={12}
                layout={
                  _.get(data, "sourceViewLayout")
                    ? _.get(data, "sourceViewLayout")
                    : null
                }
                items={generateWidgets()}
                handleLayoutChange={debouncedOnHandleLayoutChange}
                width={width}
              />
            )}
            {!refreshView && <NtaiCircularProgress size={20} />}
            {refreshView &&
              data &&
              _.get(data, "sourceViewWidgets").length === 0 && (
                <NtaiEmptyMessage
                  header="No widgets available"
                  subheader="Check source view to see if widgets have been added"
                  vAlign="center"
                />
              )}
          </Box>
        </Scrollbar>
      )}
      {sourceViewUuId && selected && drillModeOn && drillModeWidgetUuId && (
        <SearchSourceWidgetDrillContext.Provider
          value={{
            drillModeWidgetUuId,
            drillWidgetFieldIds,
            setDrillWidgetFieldIds,
            drillWidgetChartId,
            setDrillWidgetChartId,
          }}
        >
          <SearchSourceWidgetDrill>
            <SearchSourceWidget
              searchSourceId={searchSourceId}
              sourceViewUuId={sourceViewUuId}
              widgetInitData={
                _.filter(_.get(data, "sourceViewWidgets"), {
                  sourceViewWidgetUuId: drillModeWidgetUuId,
                })[0]
              }
              // widgetInitData={widgetsDataMap[drillModeWidgetUuId]}
              handleFilter={handleFilter}
              filterPresent={
                widgetFilterUuIds &&
                _.has(widgetFilterUuIds, drillModeWidgetUuId)
                  ? true
                  : false
              }
              handleLinkClick={handleLinkClick}
              handleDrillMode={() => handleDrillMode(null)}
              drillModeOn={drillModeOn}
              drillModeWidgetUuId={drillModeWidgetUuId}
            />
          </SearchSourceWidgetDrill>
        </SearchSourceWidgetDrillContext.Provider>
      )}
      {!sourceViewUuId && selected && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiEmptyMessage
            header="View not available or assigned"
            subheader="Configure source views in the Admin - Source - Views section"
            vAlign="center"
          />
        </Box>
      )}
    </Box>
  );
}
