import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  clearStatus,
  getOversightTopicSources,
} from "./store/oversightTopicSourcesSlice";
import { OversightSourceRecordsContext } from "../OversightSourceRecordsWrapper";
import { OversightContext } from "../../../../../../Oversight";
const _ = require("lodash");

export default function OversightTopicSources() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { section, navNode } = React.useContext(OversightContext);
  const {
    sourcesLoaded,
    setSourcesLoaded,
    sourcesData,
    setSourcesData,
    source,
    setSource,
  } = React.useContext(OversightSourceRecordsContext);

  const statusTopicSourcesSlice = useSelector(
    (state) => state.oversight.oversightTopicSourcesSlice.status
  );

  const sourcesDataMap = useSelector(
    (state) => state.oversight.oversightTopicSourcesSlice.data
  );

  const data = Object.values(sourcesDataMap);

  useEffect(() => {
    if (
      statusTopicSourcesSlice &&
      statusTopicSourcesSlice.result === "success" &&
      statusTopicSourcesSlice.method === "getOversightTopicSources"
    ) {
      setSourcesLoaded(true);
      setSourcesData(data);
      setSource(data && _.isArray(data) ? data[0] : null);
      dispatch(clearStatus());
    }
  }, [statusTopicSourcesSlice]);

  useEffect(() => {
    if (section === "source_records") {
      if (
        _.get(navNode, "uuId") &&
        _.get(navNode, "parentUuId") &&
        _.get(navNode, "type") &&
        ["OBJECT_TOPIC", "DATA_TOPIC"].includes(_.get(navNode, "type"))
      ) {
        dispatch(
          getOversightTopicSources({
            dataUuId: _.get(navNode, "parentUuId"),
            topicUuId: _.get(navNode, "uuId"),
            topicType: _.get(navNode, "type"),
          })
        );
      }
    }
  }, [section, navNode]);

  return (
    <>
      {sourcesData && sourcesData.length > 0 && (
        <Box
          sx={{
            dispaly: "flex",
            flexBasis: "25%",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          <>
            <Box
              sx={{
                p: theme.spacing(1),
                height: "44px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                Sources ({sourcesData.length})
              </Typography>
            </Box>
            <Box sx={{ height: `calc(100% - 44px)`, display: "flex" }}>
              <Scrollbar>
                <MenuList sx={{ p: 0, width: "100%" }}>
                  {_.sortBy(sourcesData, "sourceMin.name").map((c, index) => {
                    return (
                      <MenuItem
                        key={`oversight-source-menu-${index}`}
                        onClick={() => setSource(c)}
                        selected={c.sourceUuId === source.sourceUuId}
                      >
                        <ListItemText>
                          <Typography
                            variant="subtitle2"
                            fontWeight={
                              c.sourceUuId === source.sourceUuId ? "700" : null
                            }
                          >
                            {c.sourceMin.abbrev}
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Scrollbar>
            </Box>
          </>
        </Box>
      )}
    </>
  );
}
