import Settings from "./Settings";

const SettingConfig = {
  routes: [
    {
      path: "/admin/settings",
      exact: true,
      component: Settings,
    },
  ],
};

export default SettingConfig;
