import React, { useState } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCheckSquare,
  faGlobe,
  faSquare,
  faLock,
} from "@fortawesome/pro-regular-svg-icons";

import { faDatabase } from "@fortawesome/pro-light-svg-icons";

import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSparkRadialBarChart from "@ntai/components/charts/apexcharts/NtaiSparkRadialBarChart";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function SourceBox(props) {
  const { searchInitSource, handleSelect } = props;

  const theme = useTheme();
  const [selected, setSelected] = useState(false);

  function onHandleSelect() {
    setSelected(!selected);
    handleSelect(_.get(searchInitSource, "searchSourceDefinition.sourceUuId"));
  }

  return (
    <Box
      onClick={() => onHandleSelect()}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "240px",
        height: "160px",
        background: "white",
        border: selected ? theme.general.border2p : theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "70px",
          display: "flex",
          padding: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {_.has(searchInitSource, "totalHits") &&
          _.get(searchInitSource, "totalHits") > 0 ? (
            <NtaiSparkRadialBarChart
              label={NtaiUtils.abbrevNumber(
                _.get(searchInitSource, "totalHits")
              )}
              series={75}
            />
          ) : (
            // <FontAwesomeIcon size="2x" icon={faDatabase} />
            <NtaiSparkRadialBarChart label="1.56k" series={34} />
          )}
        </Box>
        <Box
          sx={{
            width: "150px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2" fontWeight="700">
            {_.get(searchInitSource, "searchSourceDefinition.abbrev")}
          </Typography>
          <Typography variant="caption">
            {`Version: ${_.get(
              searchInitSource,
              "searchSourceDefinition.sourceMajorVersion"
            )}.${_.get(
              searchInitSource,
              "searchSourceDefinition.sourceMinorVersion"
            )}`}
          </Typography>
          <Typography variant="caption">
            {`Updated:  ${NtaiUtils.localDate(
              _.get(
                searchInitSource,
                "searchSourceDefinition.sourceDateModified"
              )
            )}`}
            {/* {_.get(
              searchInitSource,
              "searchSourceDefinition.sourceDateModified"
            )} */}
          </Typography>
        </Box>
        <Box sx={{ width: "20px" }}>
          <FontAwesomeIcon
            size="lg"
            color="primary"
            icon={selected ? faCheckSquare : faSquare}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          padding: theme.spacing(1),
          py: "0px",
          px: "10px",
        }}
      >
        <Typography variant="caption" sx={{ color: grey[700] }}>
          {NtaiUtils.trunc(
            _.get(searchInitSource, "searchSourceDefinition.description"),
            120
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "30px",
          display: "flex",
          alignItems: "center",
          borderTop: theme.general.border1,
          background: grey[50],
          borderRadius: theme.general.borderRadiusSm,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingLeft: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <FontAwesomeIcon size="2xs" icon={faBuilding} />
            <Typography variant="caption" fontWeight="500" fontSize="10px">
              {_.get(searchInitSource, "searchSourceDefinition.owner")}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              paddingRight: theme.spacing(1),
            }}
          >
            <Typography variant="caption" fontWeight="500" fontSize="10px">
              {_.get(searchInitSource, "searchSourceDefinition.categoryDecode")}
            </Typography>
            <FontAwesomeIcon
              size="2xs"
              icon={
                _.get(searchInitSource, "searchSourceDefinition.privacyFg") ===
                1
                  ? faLock
                  : faGlobe
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
