import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceInFileUploadForm from "../shared/SourceInFileUploadForm";
import {
  getSourceInStoreFiles,
  updateSourceInStoreFile,
  uploadSourceInStoreFiles,
  clearStatus,
  setActiveId,
} from "../store/sourceInStoreFilesSlice";
import SourceInXmlDatasetList from "./datasets/SourceInXmlDatasetList";
import SourceInXmlEditForm from "./SourceInXmlEditForm";

function getFileSize(cols) {
  return NtaiUtils.formatBytes(cols[0].colValue);
}

const headCells = [
  {
    id: "uuId",
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "fileName",
    disablePadding: true,
    label: "File Name",
  },
  {
    id: ["fileSize"],
    disablePadding: true,
    label: "Size",
    transformFunc: getFileSize,
  },

  {
    id: "dateCreated",
    dataType: "date",
    disablePadding: true,
    label: "Date Started",
    width: "15%",
  },

  {
    id: "dateModified",
    dataType: "date",
    disablePadding: true,
    label: "Date Completed",
    width: "15%",
  },
];

export default function SourceInXmlList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [uploadData, setUploadData] = useState();
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [manageDatasetsDialogOpen, setManageDatasetsDialogOpen] =
    useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceInId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceInId: sourceInId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreFilesSlice.data
  );
  const data = Object.values(dataMap);

  const status = useSelector(
    (state) => state.sources.sourceInStoreFilesSlice.status
  );

  function handleFileUploadDialogOpen() {
    setFileUploadDialogOpen(true);
  }

  function handleFileUploadDialogClose() {
    setFileUploadDialogOpen(false);
  }

  function handleEditDialogOpen() {
    setEditDialogOpen(true);
  }

  function handleEditDialogClose() {
    setEditDialogOpen(false);
  }

  function handleManageDatasetsDialogOpen() {
    setManageDatasetsDialogOpen(true);
  }

  function handleManageDatasetsDialogClose() {
    setManageDatasetsDialogOpen(false);
  }

  function handleToolbarAction(actionName, ids) {
    handleFileUploadDialogOpen();
  }

  function handleEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
      handleEditDialogOpen();
    }
  }

  function handleFormSubmit(formData) {
    dispatch(
      updateSourceInStoreFile({
        ...params,
        uuId: currentId,
        formData: formData,
      })
    );
  }

  function onHandleFileUpload(formData, config) {
    setUploadData({ formData: formData, config: config });
  }

  function handleRowAction(action, selectedId) {
    dispatch(setActiveId(selectedId));

    if (action === "manage") handleManageDatasetsDialogOpen();
  }

  function handleFileUpload() {
    dispatch(
      uploadSourceInStoreFiles({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
        ...uploadData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "uploadSourceInStoreFiles" &&
      status.result === "success"
    ) {
      handleFileUploadDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "updateSourceInStoreFile" &&
      status.result === "success"
    ) {
      handleEditDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceInStoreFiles({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <NtaiSimpleTable
        rows={data}
        emptyRowsContent={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography>Upload files to enable source</Typography>
            <Button
              size="small"
              variant="contained"
              onClick={(e) => handleFileUploadDialogOpen()}
            >
              Add Record
            </Button>
          </Box>
        }
        headCells={headCells}
        keyColumn="uuId"
        dialog={true}
        toolbarStaticActions={[
          { value: "upload", label: "Upload", icon: "upload" },
        ]}
        rowActions={[{ value: "manage", label: "Manage Datasets" }]}
        handleRowAction={handleRowAction}
        handleToolbarAction={handleToolbarAction}
        handleDialogAddEdit={handleEdit}
      />

      <NtaiDialog
        title="File Upload"
        open={fileUploadDialogOpen}
        handleDialogClose={handleFileUploadDialogClose}
        size="sm"
      >
        <NtaiForm>
          <SourceInFileUploadForm
            handleCancel={handleFileUploadDialogClose}
            handleFileUpload={onHandleFileUpload}
            handleFormSubmit={handleFileUpload}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiDialog
        title="File Edit"
        open={editDialogOpen}
        handleDialogClose={handleEditDialogClose}
        size="sm"
      >
        <NtaiForm>
          <SourceInXmlEditForm
            action={action}
            formData={action === "edit" ? dataMap[currentId] : {}}
            handleFormSubmit={handleFormSubmit}
            handleDialogClose={handleEditDialogClose}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiDialog
        title="Manage Datasets"
        open={manageDatasetsDialogOpen}
        handleDialogClose={handleManageDatasetsDialogClose}
        size="sm"
      >
        <SourceInXmlDatasetList />
      </NtaiDialog>
    </React.Fragment>
  );
}
