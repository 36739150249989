import SourcePlConfig from "./pl/SourcePlConfig";
import SourceDerivedFieldConfig from "./derivedfield/SourceDerivedFieldConfig";
import SourceInConfig from "./in/SourceInConfig";
import SourceOutStoreConfig from "./out/SourceOutStoreConfig";
import SourceLinkedSourceConfig from "./linkedsource/SourceLinkedSourceConfig";
import SourceObjectConfig from "./object/SourceObjectConfig";
import SourceCreate from "./SourceCreate";
import SourceList from "./SourceList";
import SourceManager from "./SourceManager";
import SourceManagerWrapper from "./SourceManagerWrapper";
import SourceTemplateConfig from "./template/SourceTemplateConfig";
import SourceThesaurusConfig from "./thesaurus/SourceThesaurusConfig";
import SourceViewConfig from "./view/SourceViewConfig";
import SourceOutJobConfig from "./job/SourceOutJobConfig";
import SourceUserWidgetConfig from "./user-widgets/SourceUserWidgetConfig";
import SourceCustomFieldConfig from "./customfields/SourceCustomFieldConfig";
import SourceAdjudicationConfig from "./adjudication/SourceAdjudicationConfig";
import SourcePolicyWrapperConfig from "./policy/SourcePolicyWrapperConfig";

const SourceConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/smwrapper",
      exact: true,
      component: SourceManagerWrapper,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/manage",
      exact: true,
      component: SourceManager,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources",
      exact: true,
      component: SourceList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/create",
      exact: true,
      component: SourceCreate,
    },
    ...SourcePlConfig.routes,
    ...SourceInConfig.routes,
    ...SourceOutStoreConfig.routes,
    ...SourceOutJobConfig.routes,
    ...SourceLinkedSourceConfig.routes,
    ...SourceViewConfig.routes,
    ...SourceAdjudicationConfig.routes,
    ...SourceUserWidgetConfig.routes,
    ...SourceTemplateConfig.routes,
    ...SourceObjectConfig.routes,
    ...SourceThesaurusConfig.routes,
    ...SourceDerivedFieldConfig.routes,
    ...SourceCustomFieldConfig.routes,
    ...SourcePolicyWrapperConfig.routes,
  ],
};

export default SourceConfig;
