import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSourceLinkedSourceField } from "./store/sourceLinkedSourceFieldsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import SourceLinkedSourceLink from "./link/SourceLinkedSourceLink";
const _ = require("lodash");

export default function SourceLinkedSourceFieldForm(props) {
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const { handleFormSubmit, action, formData, handleCancel } = props;

  const [sourceFields, setSourceFields] = useState([]);

  const [linkedSourceFields, setLinkedSourceFields] = useState([]);
  const dispatch = useDispatch();
  const { getValues, reset, watch, setValue } = useFormContext();
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const linkedSourceId = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.activeId
  );

  const linkedSourceDataMap = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.data
  );

  const codesDataMap = useSelector((state) => state.core.codelistsSlice.codes);

  const linkedSourceTypeOptions = _.get(
    codesDataMap,
    CODELIST_CODES.SRC_LINKED_SOURCE_TYPE_CODE
  );

  const linkedSourceData = _.get(linkedSourceDataMap, linkedSourceId);

  const linkedSourceFieldsDataMap = useSelector(
    (state) => state.sources.sourceLinkedSourceFieldsSlice.linkedSourceFields
  );

  const status = useSelector(
    (state) => state.sources.sourceLinkedSourceFieldsSlice.status
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const watchLinkedTypeCode = watch("linkedSourceFieldForm.linkedTypeCode");

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("linkedSourceFieldForm")
    );

    dispatch(
      createSourceLinkedSourceField({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        linkedSourceId: linkedSourceId,
        formData: sanitizedFormData,
      })
    );
  }

  useEffect(() => {
    !_.isEmpty(sourceFieldsDataMap) &&
      setSourceFields(
        NtaiUtils.getSelectOptions(
          Object.values(sourceFieldsDataMap),
          "uuId",
          "name"
        )
      );
  }, [sourceFieldsDataMap]);

  useEffect(() => {
    if (!_.isEmpty(linkedSourceFieldsDataMap)) {
      setLinkedSourceFields(
        NtaiUtils.getSelectOptions(
          Object.values(linkedSourceFieldsDataMap),
          "uuId",
          "name"
        )
      );
    }
  }, []);

  useEffect(() => {
    setValue(
      "linkedSourceFieldForm.sourceFieldUuId",
      action === "edit" ? _.get(formData, "sourceFieldUuId") : null
    );
    setValue(
      "linkedSourceFieldForm.linkedSourceFieldUuId",
      action === "edit" ? _.get(formData, "linkedSourceFieldUuId") : null
    );
    setValue(
      "linkedSourceFieldForm.linkedTypeCode",
      action === "edit" ? _.get(formData, "linkedTypeCode") : null
    );
    setLoaded(true);
  }, [formData]);

  return (
    <NtaiTabs>
      <Box label="GENERAL" sx={{ display: "flex", flexDirection: "column" }}>
        {loaded && (
          <NtaiFormPanel
            handleSave={handleSave}
            handleCancel={handleCancel}
            header={action === "add" ? "Add Field" : `Edit Field`}
            subheader="Map source field to target linked source fields. Select Linked type to expand query. For example, use thesaurus to expand query for the target linked source"
          >
            <NtaiSelectField
              label="Source Field*"
              name="linkedSourceFieldForm.sourceFieldUuId"
              options={sourceFields}
              rules={{ required: "Source field is required" }}
            />
            <NtaiSelectField
              label="Linked Source Field*"
              name="linkedSourceFieldForm.linkedSourceFieldUuId"
              options={linkedSourceFields}
              rules={{ required: "Linked source field is required" }}
            />
            <NtaiSelectField
              label="Link Type*"
              name="linkedSourceFieldForm.linkedTypeCode"
              options={linkedSourceTypeOptions}
              rules={{ required: "Link type is required" }}
            />
          </NtaiFormPanel>
        )}
      </Box>
      <Box label="LINKS">
        <SourceLinkedSourceLink />
      </Box>
    </NtaiTabs>
  );
}
