import React from "react";
import { SourceOutJobMigrationContext } from "../SourceOutJobMigration";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function SourceMigrationOutJobLoadStep() {
  const theme = useTheme();
  const { displayStore, migrationData } = React.useContext(
    SourceOutJobMigrationContext
  );

  const migrationStoreData = _.filter(migrationData["migrationOutStores"], {
    uuId: displayStore,
  })[0];

  return (
    <React.Fragment>
      {migrationStoreData["migrationDatasets"].map((o, i) => (
        <React.Fragment key={`ntai-migration-dataset-load-1-${i}`}>
          <Box
            sx={{
              p: theme.spacing(2),
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h3">{o.sourceDatasetName}</Typography>
            {_.has(o, "migrationOutStoreDatasetLoad") &&
              _.isArray(o["migrationOutStoreDatasetLoad"]) &&
              o["migrationOutStoreDatasetLoad"].map((p, j) => (
                <Box
                  key={`ntai-migration-dataset-load-2-${j}`}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: theme.spacing(1),
                  }}
                >
                  <NtaiLabelValue1
                    label="# Total Records"
                    value={p["mergedRecordsCount"]}
                  />
                  <NtaiLabelValue1
                    label="Total Records Status"
                    value={p["mergedRecordsStatusDecode"]}
                  />
                  <NtaiLabelValue1
                    label="Total Records Date Started"
                    value={NtaiUtils.formatDateTimeCol(
                      p["mergedRecordsDateStarted"]
                    )}
                  />
                  <NtaiLabelValue1
                    label="Total Records Date Completed"
                    value={NtaiUtils.formatDateTimeCol(
                      p["mergedRecordsDateCompleted"]
                    )}
                  />

                  <NtaiLabelValue1
                    label="# New Records"
                    value={p["newRecordsCount"]}
                  />
                  <NtaiLabelValue1
                    label="New Records Status"
                    value={p["newRecordsStatusDecode"]}
                  />
                  <NtaiLabelValue1
                    label="New Records Date Started"
                    value={NtaiUtils.formatDateTimeCol(
                      p["newRecordsDateStarted"]
                    )}
                  />
                  <NtaiLabelValue1
                    label="New Records Date Completed"
                    value={NtaiUtils.formatDateTimeCol(
                      p["newRecordsDateCompleted"]
                    )}
                  />

                  <NtaiLabelValue1
                    label="# Follow-up Versions"
                    value={p["fuVersionsCount"]}
                  />
                  <NtaiLabelValue1
                    label="Follow-up Versions Status"
                    value={p["fuVersionsStatusDecode"]}
                  />
                  <NtaiLabelValue1
                    label="Follow-up Versions Date Started"
                    value={NtaiUtils.formatDateTimeCol(
                      p["fuVersionsDateStarted"]
                    )}
                  />
                  <NtaiLabelValue1
                    label="Follow-up Versions Date Completed"
                    value={NtaiUtils.formatDateTimeCol(
                      p["fuVersionsDateCompleted"]
                    )}
                  />

                  <NtaiLabelValue1
                    label="# Old Versions"
                    value={p["oldVersionsCount"]}
                  />
                  <NtaiLabelValue1
                    label="Old Versions Status"
                    value={p["oldVersionsStatusDecode"]}
                  />
                  <NtaiLabelValue1
                    label="Old Versions Date Started"
                    value={NtaiUtils.formatDateTimeCol(
                      p["oldVersionsDateStarted"]
                    )}
                  />
                  <NtaiLabelValue1
                    label="Old Versions Date Completed"
                    value={NtaiUtils.formatDateTimeCol(
                      p["oldVersionsDateCompleted"]
                    )}
                  />

                  <NtaiLabelValue1
                    label="# Deleted Records"
                    value={p["deletedRecordsCount"]}
                  />
                  <NtaiLabelValue1
                    label="Deleted Records Status"
                    value={p["deletedRecordsStatusDecode"]}
                  />
                  <NtaiLabelValue1
                    label="Deleted Records Date Started"
                    value={NtaiUtils.formatDateTimeCol(
                      p["deletedRecordsDateStarted"]
                    )}
                  />
                  <NtaiLabelValue1
                    label="Deleted Records Date Completed"
                    value={NtaiUtils.formatDateTimeCol(
                      p["deletedRecordsDateCompleted"]
                    )}
                  />
                </Box>
              ))}

            {(!_.has(o, "migrationOutStoreDatasetLoad") ||
              !_.isArray(o["migrationOutStoreDatasetLoad"])) && (
              <NtaiEmptyMessage header="Load data not available" />
            )}
          </Box>
          <Divider />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
