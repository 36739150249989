import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceOutDatasetNkFields = createAsyncThunk(
  "sourceDatasets/getSourceOutDatasetNkFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId, sourceOutDatasetId } =
        values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/nks`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceOutDatasetNkField = createAsyncThunk(
  "sourceOutDatasetNkFields/createSourceOutDatasetNkField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/nks`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceOutDatasetNkField = createAsyncThunk(
  "sourceOutDatasetNkFields/deleteSourceOutDatasetNkField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        uuId,
      } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/nks/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceOutDatasetNkFields = createAsyncThunk(
  "sourceOutDatasetNkFields/updateSourceOutDatasetNkFields",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/nks`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceOutDatasetNkFieldsSlice = createSlice({
  name: "sourceOutDatasetNkFields",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceOutDatasetNkFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceOutDatasetNkFields",
      };
    },
    [getSourceOutDatasetNkFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutDatasetNkFields",
        message: action.payload.message,
      };
    },

    [createSourceOutDatasetNkField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceOutDatasetNkField",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceOutDatasetNkField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceOutDatasetNkField",
        message: action.payload.message,
      };
    },

    [updateSourceOutDatasetNkFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "updateSourceOutDatasetNkFields",
      };
    },
    [updateSourceOutDatasetNkFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceOutDatasetNkFields",
        message: action.payload.message,
      };
    },

    [deleteSourceOutDatasetNkField.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "deleteSourceOutDatasetNkField",
      };
    },
    [deleteSourceOutDatasetNkField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceOutDatasetNkField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceOutDatasetNkFieldsSlice.actions;

export default sourceOutDatasetNkFieldsSlice.reducer;
