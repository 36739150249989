import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiBpmnModeler from "./NtaiBpmnModeler";

export default function TestBPMNModeler() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        p: theme.spacing(2),
      }}
    >
      <NtaiBpmnModeler />
    </Box>
  );
}
