import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceDatasetFields = createAsyncThunk(
  "sourceFields/getSourceDatasetFields",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        sourceDatasetId,
      } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${sourceDatasetId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceDatasetField = createAsyncThunk(
  "sourceFields/getSourceDatasetField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        sourceDatasetId,
        uuId,
      } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${sourceDatasetId}/fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceDatasetField = createAsyncThunk(
  "sourceFields/updateSourceDatasetField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        sourceDatasetId,
        uuId,
        formData,
      } = values;

      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${sourceDatasetId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceDatasetFields = createAsyncThunk(
  "sourceFields/updateSourceDatasetFields",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        sourceDatasetId,
        formData,
      } = values;

      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${sourceDatasetId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceFieldsSlice = createSlice({
  name: "sourceFields",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
      state.message = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceDatasetFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceDatasetFields" };
    },

    [getSourceDatasetFields.rejected]: (state, action) => {
      state.status = {
        result: "success",
        method: "getSourceDatasetFields",
        message: action.payload.message,
      };
    },

    [getSourceDatasetField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceDatasetField" };
    },
    [getSourceDatasetField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDatasetField",
        message: action.payload.message,
      };
    },

    [updateSourceDatasetField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceDatasetField" };
    },
    [updateSourceDatasetField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceDatasetField",
        message: action.payload.message,
      };
    },

    [updateSourceDatasetFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "updateSourceDatasetFields" };
    },
    [updateSourceDatasetFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceDatasetFields",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceFieldsSlice.actions;

export default sourceFieldsSlice.reducer;
