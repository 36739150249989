import {
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { green, grey, yellow } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import NtaiHistogramChart from "../charts/amcharts/NtaiHistogramChart";
import NtaiPieChart from "../charts/amcharts/NtaiPieChart";
import NtaiSourceWidget from "../widgets/source/NtaiSourceWidget";
import NtaiResizableGrid from "../resizable/NtaiResizableGrid";
import NtaiBarChart from "./amcharts/NtaiBarChart";
import NtaiDateHistogramChart from "./amcharts/NtaiDateHistogramChart";
import NtaiPackedCircleChart from "./amcharts/NtaiPackedCircleChart";
import NtaiTreemapChart from "./amcharts/NtaiTreemapChart";
import NtaiChordChart from "./amcharts/NtaiChordChart";
import useDimensions from "react-cool-dimensions";
import NtaiDecoMenu from "../menus/NtaiDecoMenu";
import { useFormContext } from "react-hook-form";
import NtaiTextField from "../inputs/NtaiTextField";
import { useDispatch, useSelector } from "react-redux";
import { applyFilter } from "./amcharts/store/testAmchartsSlice";
import NtaiLineChart from "./amcharts/NtaiLineChart";
import NtaiDumbellChart from "./amcharts/NtaiDumbellChart";
import NtaiPieChart1 from "./amcharts/NtaiPieChart1";
import NtaiRadiusNestedChart from "./amcharts/NtaiRadiusNestedChart";
import NtaiSparkRadialBarChart from "./apexcharts/NtaiSparkRadialBarChart";
import NtaiAgGridTable3 from "../tables/aggrid/NtaiAgGridTable3";
import NtaiScatterChart from "./amcharts/NtaiScatterChart";
import NtaiWidgetTable from "../tables/widgets/NtaiWidgetTable";
import NtaiSankeyChart from "./amcharts/NtaiSankeyChart";
import NtaiPieChart3 from "./amcharts/NtaiPieChart3";
import NtaiDonutChart from "./amcharts/v2/NtaiDonutChart";
const _ = require("lodash");

const sankeyChartData = [
  { from: "A", to: "D", value: 10 },
  { from: "B", to: "D", value: 8 },
  { from: "B", to: "E", value: 4 },
  { from: "C", to: "E", value: 3 },
  { from: "D", to: "G", value: 5 },
  { from: "D", to: "I", value: 2 },
  { from: "D", to: "H", value: 3 },
  { from: "E", to: "H", value: 6 },
  { from: "G", to: "J", value: 5 },
  { from: "I", to: "J", value: 1 },
  { from: "H", to: "J", value: 9 },
  { from: "H", to: "A", value: 9 },
];

const scatterChartData = [
  {
    x: 1,
    y: 2,
  },
  {
    x: 12,
    y: 5,
  },
  {
    x: 4,
    y: 2,
  },
  {
    x: 1,
    y: 5,
  },
  {
    x: 7,
    y: 7,
  },
  {
    x: 3,
    y: 9,
  },
  {
    x: 1,
    y: 1,
  },
  {
    x: 9,
    y: 5,
  },
];
const mapdata2 = [
  {
    query: "Mumbai, India",
    limit: 1,
  },
  {
    query: "New Delhi, India",
    limit: 1,
  },
  {
    query: "US",
    limit: 1,
  },
];

const radiusData = [
  {
    source: "ct",
    count: 12,
  },
];
const histdata = [
  {
    name: "USA",
    count: 12,
    active: true,
  },
  {
    name: "GER",
    count: 16,
  },

  {
    name: "FR7",
    count: 4,
  },
  {
    name: "TY",
    count: 6,
  },
  {
    name: "FGH",
    count: 8,
  },
  {
    name: "kl",
    count: 1,
  },
  {
    name: "Rt",
    count: 5,
  },
];

const datehistdata = [
  {
    date: new Date(2010, 1, 10),
    count: 1,
  },
  {
    date: new Date(2011, 1, 10),
    count: 12,
  },
  {
    date: new Date(2012, 1, 10),
    count: 20,
  },
  {
    date: new Date(2013, 1, 10),
    count: 9,
  },
];

const linechartdata = [
  {
    date: "2014-01-01",
    count: 2,
  },
  {
    date: "2014-02-01",
    count: 5,
  },
  {
    date: "2014-03-01",
    count: 2,
  },
];

const treemapData1 = [
  {
    children: [
      {
        name: "N/A",
        value: 4980,
      },
      {
        name: "Phase 2",
        value: 4553,
      },
      {
        name: "Phase 1",
        value: 1902,
      },
      {
        name: "Phase 3",
        value: 1619,
      },
      {
        name: "Phase 1/Phase 2",
        value: 851,
      },
      {
        name: "Phase 4",
        value: 299,
      },
      {
        name: "Phase 2/Phase 3",
        value: 160,
      },
      {
        name: "Early Phase 1",
        value: 125,
      },
    ],
    name: "Completed",
    value: 14489,
  },
  {
    children: [
      {
        name: "N/A",
        value: 3005,
      },
      {
        name: "Phase 2",
        value: 1581,
      },
      {
        name: "Phase 3",
        value: 594,
      },
      {
        name: "Phase 1",
        value: 549,
      },
      {
        name: "Phase 1/Phase 2",
        value: 430,
      },
      {
        name: "Early Phase 1",
        value: 102,
      },
      {
        name: "Phase 4",
        value: 87,
      },
      {
        name: "Phase 2/Phase 3",
        value: 84,
      },
    ],
    name: "Recruiting",
    value: 6432,
  },
  {
    children: [
      {
        name: "N/A",
        value: 1458,
      },
      {
        name: "Phase 2",
        value: 1000,
      },
      {
        name: "Phase 3",
        value: 445,
      },
      {
        name: "Phase 1",
        value: 184,
      },
      {
        name: "Phase 1/Phase 2",
        value: 177,
      },
      {
        name: "Phase 4",
        value: 107,
      },
      {
        name: "Phase 2/Phase 3",
        value: 86,
      },
      {
        name: "Early Phase 1",
        value: 37,
      },
    ],
    name: "Unknown status",
    value: 3494,
  },
  {
    children: [
      {
        name: "N/A",
        value: 1172,
      },
      {
        name: "Phase 2",
        value: 844,
      },
      {
        name: "Phase 3",
        value: 435,
      },
      {
        name: "Phase 1",
        value: 348,
      },
      {
        name: "Phase 1/Phase 2",
        value: 208,
      },
      {
        name: "Phase 2/Phase 3",
        value: 47,
      },
      {
        name: "Early Phase 1",
        value: 43,
      },
      {
        name: "Phase 4",
        value: 34,
      },
    ],
    name: "Active, not recruiting",
    value: 3131,
  },
  {
    children: [
      {
        name: "Phase 2",
        value: 1234,
      },
      {
        name: "N/A",
        value: 645,
      },
      {
        name: "Phase 1",
        value: 409,
      },
      {
        name: "Phase 3",
        value: 325,
      },
      {
        name: "Phase 1/Phase 2",
        value: 271,
      },
      {
        name: "Phase 4",
        value: 59,
      },
      {
        name: "Phase 2/Phase 3",
        value: 43,
      },
      {
        name: "Early Phase 1",
        value: 35,
      },
    ],
    name: "Terminated",
    value: 3021,
  },
  {
    children: [
      {
        name: "N/A",
        value: 710,
      },
      {
        name: "Phase 2",
        value: 390,
      },
      {
        name: "Phase 3",
        value: 149,
      },
      {
        name: "Phase 1",
        value: 107,
      },
      {
        name: "Phase 1/Phase 2",
        value: 87,
      },
      {
        name: "Early Phase 1",
        value: 35,
      },
      {
        name: "Phase 4",
        value: 33,
      },
      {
        name: "Phase 2/Phase 3",
        value: 22,
      },
    ],
    name: "Not yet recruiting",
    value: 1533,
  },
  {
    children: [
      {
        name: "N/A",
        value: 360,
      },
      {
        name: "Phase 2",
        value: 349,
      },
      {
        name: "Phase 1",
        value: 131,
      },
      {
        name: "Phase 1/Phase 2",
        value: 98,
      },
      {
        name: "Phase 3",
        value: 63,
      },
      {
        name: "Early Phase 1",
        value: 21,
      },
      {
        name: "Phase 4",
        value: 16,
      },
      {
        name: "Phase 2/Phase 3",
        value: 14,
      },
    ],
    name: "Withdrawn",
    value: 1052,
  },
  {
    children: [
      {
        name: "N/A",
        value: 95,
      },
      {
        name: "Phase 2",
        value: 66,
      },
      {
        name: "Phase 1",
        value: 34,
      },
      {
        name: "Phase 1/Phase 2",
        value: 29,
      },
      {
        name: "Phase 3",
        value: 20,
      },
      {
        name: "Early Phase 1",
        value: 6,
      },
      {
        name: "Phase 4",
        value: 5,
      },
      {
        name: "Phase 2/Phase 3",
        value: 4,
      },
    ],
    name: "Suspended",
    value: 259,
  },
  {
    children: [
      {
        name: "N/A",
        value: 140,
      },
      {
        name: "Phase 2",
        value: 19,
      },
      {
        name: "Phase 3",
        value: 17,
      },
      {
        name: "Phase 2/Phase 3",
        value: 6,
      },
      {
        name: "Early Phase 1",
        value: 3,
      },
      {
        name: "Phase 4",
        value: 3,
      },
      {
        name: "Phase 1/Phase 2",
        value: 2,
      },
      {
        name: "Phase 1",
        value: 1,
      },
    ],
    name: "Enrolling by invitation",
    value: 191,
  },
  {
    children: [
      {
        name: "N/A",
        value: 34,
      },
    ],
    name: "No longer available",
    value: 34,
  },
  {
    children: [
      {
        name: "N/A",
        value: 24,
      },
    ],
    name: "Available",
    value: 24,
  },
  {
    children: [
      {
        name: "N/A",
        value: 18,
      },
    ],
    name: "Approved for marketing",
    value: 18,
  },
  {
    children: [
      {
        name: "N/A",
        value: 1,
      },
    ],
    name: "Temporarily not available",
    value: 1,
  },
];

const treemapData = [
  {
    name: "India",
    children: [
      {
        name: "KA",
        value: 3,
      },
      {
        name: "IN",
        value: 4,
      },
    ],
  },
  {
    name: "France",
    children: [
      {
        name: "FG",
        value: 4,
      },
      {
        name: "YU",
        value: 2,
      },
    ],
  },
];

const packedCircleData = [
  {
    name: "Flora",
    children: [
      {
        name: "Black Tea",
        value: 1,
      },
      {
        name: "Floral",
        children: [
          {
            name: "Chamomile",
            value: 1,
          },
          {
            name: "Rose",
            value: 1,
          },
          {
            name: "Jasmine",
            value: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Fruity",
    children: [
      {
        name: "Berry",
        children: [
          {
            name: "Blackberry",
            value: 1,
          },
          {
            name: "Raspberry",
            value: 1,
          },
          {
            name: "Blueberry",
            value: 1,
          },
          {
            name: "Strawberry",
            value: 1,
          },
        ],
      },
      {
        name: "Dried Fruit",
        children: [
          {
            name: "Raisin",
            value: 1,
          },
          {
            name: "Prune",
            value: 1,
          },
        ],
      },
      {
        name: "Other Fruit",
        children: [
          {
            name: "Coconut",
            value: 1,
          },
          {
            name: "Cherry",
            value: 1,
          },
          {
            name: "Pomegranate",
            value: 1,
          },
          {
            name: "Pineapple",
            value: 1,
          },
          {
            name: "Grape",
            value: 1,
          },
          {
            name: "Apple",
            value: 1,
          },
          {
            name: "Peach",
            value: 1,
          },
          {
            name: "Pear",
            value: 1,
          },
        ],
      },
      {
        name: "Citrus Fruit",
        children: [
          {
            name: "Grapefruit",
            value: 1,
          },
          {
            name: "Orange",
            value: 1,
          },
          {
            name: "Lemon",
            value: 1,
          },
          {
            name: "Lime",
            value: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Sour/Fermented",
    children: [
      {
        name: "Sour",
        children: [
          {
            name: "Sour Aromatics",
            value: 1,
          },
          {
            name: "Acetic Acid",
            value: 1,
          },
          {
            name: "Butyric Acid",
            value: 1,
          },
          {
            name: "Isovaleric Acid",
            value: 1,
          },
          {
            name: "Citric Acid",
            value: 1,
          },
          {
            name: "Malic Acid",
            value: 1,
          },
        ],
      },
      {
        name: "Alcohol/Fremented",
        children: [
          {
            name: "Winey",
            value: 1,
          },
          {
            name: "Whiskey",
            value: 1,
          },
          {
            name: "Fremented",
            value: 1,
          },
          {
            name: "Overripe",
            value: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Green/Vegetative",
    children: [
      {
        name: "Olive Oil",
        value: 1,
      },
      {
        name: "Raw",
        value: 1,
      },
      {
        name: "Green/Vegetative",
        children: [
          {
            name: "Under-ripe",
            value: 1,
          },
          {
            name: "Peapod",
            value: 1,
          },
          {
            name: "Fresh",
            value: 1,
          },
          {
            name: "Dark Green",
            value: 1,
          },
          {
            name: "Vegetative",
            value: 1,
          },
          {
            name: "Hay-like",
            value: 1,
          },
          {
            name: "Herb-like",
            value: 1,
          },
        ],
      },
      {
        name: "Beany",
        value: 1,
      },
    ],
  },
  {
    name: "Other",
    children: [
      {
        name: "Papery/Musty",
        children: [
          {
            name: "Stale",
            value: 1,
          },
          {
            name: "Cardboard",
            value: 1,
          },
          {
            name: "Papery",
            value: 1,
          },
          {
            name: "Woody",
            value: 1,
          },
          {
            name: "Moldy/Damp",
            value: 1,
          },
          {
            name: "Musty/Dusty",
            value: 1,
          },
          {
            name: "Musty/Earthy",
            value: 1,
          },
          {
            name: "Animalic",
            value: 1,
          },
          {
            name: "Meaty Brothy",
            value: 1,
          },
          {
            name: "Phenolic",
            value: 1,
          },
        ],
      },
      {
        name: "Chemical",
        children: [
          {
            name: "Bitter",
            value: 1,
          },
          {
            name: "Salty",
            value: 1,
          },
          {
            name: "Medicinal",
            value: 1,
          },
          {
            name: "Petroleum",
            value: 1,
          },
          {
            name: "Skunky",
            value: 1,
          },
          {
            name: "Rubber",
            value: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Roasted",
    children: [
      {
        name: "Pipe Tobacco",
        value: 1,
      },
      {
        name: "Tobacco",
        value: 1,
      },
      {
        name: "Burnt",
        children: [
          {
            name: "Acrid",
            value: 1,
          },
          {
            name: "Ashy",
            value: 1,
          },
          {
            name: "Smoky",
            value: 1,
          },
          {
            name: "Brown, Roast",
            value: 1,
          },
        ],
      },
      {
        name: "Cereal",
        children: [
          {
            name: "Grain",
            value: 1,
          },
          {
            name: "Malt",
            value: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Spices",
    children: [
      {
        name: "Pungent",
        value: 1,
      },
      {
        name: "Pepper",
        value: 1,
      },
      {
        name: "Brown Spice",
        children: [
          {
            name: "Anise",
            value: 1,
          },
          {
            name: "Nutmeg",
            value: 1,
          },
          {
            name: "Cinnamon",
            value: 1,
          },
          {
            name: "Clove",
            value: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Nutty/Cocoa",
    children: [
      {
        name: "Nutty",
        children: [
          {
            name: "Peanuts",
            value: 1,
          },
          {
            name: "Hazelnut",
            value: 1,
          },
          {
            name: "Almond",
            value: 1,
          },
        ],
      },
      {
        name: "Cocoa",
        children: [
          {
            name: "Chocolate",
            value: 1,
          },
          {
            name: "Dark Chocolate",
            value: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Sweet",
    children: [
      {
        name: "Brown Sugar",
        children: [
          {
            name: "Molasses",
            value: 1,
          },
          {
            name: "Maple Syrup",
            value: 1,
          },
          {
            name: "Caramelized",
            value: 1,
          },
          {
            name: "Honey",
            value: 1,
          },
        ],
      },
      {
        name: "Vanilla",
        value: 1,
      },
      {
        name: "Vanillin",
        value: 1,
      },
      {
        name: "Overall Sweet",
        value: 1,
      },
      {
        name: "Sweet Aromatics",
        value: 1,
      },
    ],
  },
];

const chordData = [
  { from: "A", to: "D", value: 10 },
  { from: "B", to: "D", value: 8 },
  { from: "B", to: "E", value: 4 },
  { from: "B", to: "C", value: 2 },
  { from: "C", to: "E", value: 14 },
  { from: "E", to: "D", value: 8 },
  { from: "C", to: "A", value: 4 },
  { from: "G", to: "A", value: 7 },
  { from: "D", to: "B", value: 1 },
];

const dumbellchartdata = [
  {
    name: "France",
    low: 1,
    high: 5,
  },
  {
    name: "UK",
    low: 4,
    high: 7,
  },
  {
    name: "Germany",
    low: 3,
    high: 6,
  },
  {
    name: "India",
    low: 1,
    high: 7,
  },
];

const mapChartData = [
  {
    name: "New York",
    latitude: 40.7128,
    longitude: -74.006,
    risk: "low",
  },
  {
    name: "London",
    latitude: 51.5072,
    longitude: -0.076132,
    risk: "medium",
  },

  {
    name: "Chicago",
    latitude: 41.8781,
    longitude: -87.6298,
    risk: "high",
  },
  {
    name: "New Delhi",
    latitude: 28.7041,
    longitude: 77.1025,
    risk: "high",
  },

  {
    name: "San Francisco",
    latitude: 37.7749,
    longitude: -122.4194,
    risk: "high",
  },
  {
    name: "Phoenix",
    latitude: 33.4484,
    longitude: 112.074,
    risk: "high",
  },

  {
    name: "Berlin",
    latitude: 52.52,
    longitude: 13.405,
    risk: "medium",
  },
  {
    name: "Montreal",
    latitude: 45.5017,
    longitude: -73.5673,
    risk: "medium",
  },
  {
    name: "Atlanta",
    latitude: 33.749,
    longitude: -84.388,
    risk: "medium",
  },

  {
    name: "Madrid",
    latitude: 40.4168,
    longitude: -3.7038,
    risk: "high",
  },
];

export default function NtaiTestCharts() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [popoverCoords, setPopoverCoords] = React.useState();

  const filters = useSelector((state) => state.core.testAmchartsSlice.filters);

  function handleClick(widgetId, ev, val) {
    dispatch(applyFilter(val));
  }

  const dimsMap = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  const mapObserve = _.get(dimsMap, "observe");
  const mapUnobserve = _.get(dimsMap, "unobserve");
  const mapWidth = _.get(dimsMap, "width");
  const mapHeight = _.get(dimsMap, "height");

  const dims1 = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  const treeObserve = _.get(dims1, "observe");
  const treeUnobserve = _.get(dims1, "unobserve");
  const treeWidth = _.get(dims1, "width");
  const treeHeight = _.get(dims1, "height");

  const dims2 = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  const barObserve = _.get(dims2, "observe");
  const barUnobserve = _.get(dims2, "unobserve");
  const barWidth = _.get(dims2, "width");
  const barHeight = _.get(dims2, "height");

  function handleClick(widget, et, context) {
    console.log("Clicked: ", widget, et, context, et["field"]);
    // const component = document.getElementById(et.targetObj);
    // console.log("target object: ", et.targetObj);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuItemSelect(val) {
    console.log("Selected menu: ", val);
  }

  function handleOpenPopup(sourceWidgetFilterFields, ev) {
    console.log("EV popup: ", ev);
    setPopoverOpen(true);
    console.log("Top and left: ", ev.svgPoint.y, ev.svgPoint.x);
    setPopoverCoords({ left: ev.svgPoint.x + 15, top: ev.svgPoint.y + 15 });
  }

  function generateWidget(i) {
    switch (i) {
      case 0:
        return (
          <NtaiSourceWidget
            title="Histogram Chart"
            filterCount={_.isArray(filters) && filters.length}
          >
            <NtaiHistogramChart
              widgetId={`histogram-${i}`}
              data={histdata}
              valueField="count"
              categoryField="name"
              handleClick={handleClick}
              filters={filters}
            />
          </NtaiSourceWidget>
        );

      case 1:
        return (
          <NtaiSourceWidget title="Pie Chart">
            <NtaiPieChart
              widgetId={`piechart-${i}`}
              data={histdata}
              valueField="count"
              categoryField="name"
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        );

      case 2:
        return (
          <NtaiSourceWidget title="Bar Chart">
            <Box
              ref={barObserve}
              sx={{ display: "flex", width: "100%", height: "100%" }}
            >
              <NtaiBarChart
                widgetId={`barchart-${i}`}
                data={histdata}
                valueField="count"
                categoryField="name"
                handleClick={handleClick}
                width={barWidth}
                height={barHeight}
              />
            </Box>
          </NtaiSourceWidget>
        );

      case 3:
        return (
          <NtaiSourceWidget title="Date Histogram">
            <NtaiDateHistogramChart
              widgetId={`datehist-${i}`}
              data={datehistdata}
              valueField="count"
              categoryField="date"
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        );

      case 4:
        return (
          <NtaiSourceWidget title="Date Histogram">
            <NtaiPackedCircleChart
              widgetId={`packedcircle-${i}`}
              data={packedCircleData}
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        );

      case 5:
        return (
          <NtaiSourceWidget title="Treemap Chart">
            <Box
              ref={treeObserve}
              sx={{ display: "flex", width: "100%", height: "100%" }}
            >
              <NtaiTreemapChart
                widgetId={`treemap-${i}`}
                data={treemapData1}
                levels={3}
                handleClick={handleClick}
                width={treeWidth}
                height={treeHeight}
              />
            </Box>
          </NtaiSourceWidget>
        );

      case 6:
        return (
          <NtaiSourceWidget title="Chord Chart">
            <NtaiChordChart
              widgetId={`chordchart-${i}`}
              data={chordData}
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        );

      case 7:
        return (
          <NtaiSourceWidget title="Line Chart">
            <NtaiLineChart
              widgetId={`linechart-${i}`}
              data={linechartdata}
              dateFormat="yyyy-MM-dd"
              handleClick={handleClick}
              categoryField="date"
              valueField="count"
            />
          </NtaiSourceWidget>
        );

      case 8:
        return (
          <NtaiSourceWidget title="Dumbell Chart">
            <NtaiDumbellChart
              widgetId={`dumbellchart-${i}`}
              data={dumbellchartdata}
              lowField="low"
              highField="high"
              categoryField="name"
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        );

      case 10:
        return (
          <NtaiSourceWidget title="Pie Chart">
            <NtaiPieChart1
              widgetId={`piechart-${i}`}
              data={histdata}
              valueField="count"
              categoryField={{ value: "name", id: "123" }}
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        );

      case 11:
        return (
          <NtaiSourceWidget title="Radius">
            <NtaiSparkRadialBarChart series="Source" label={23} />
          </NtaiSourceWidget>
        );

      case 12:
        return (
          <NtaiSourceWidget title="Grid">
            <NtaiAgGridTable3 />
          </NtaiSourceWidget>
        );

      case 14:
        return (
          <NtaiSourceWidget title="Scatter">
            <NtaiScatterChart data={scatterChartData} xField="x" yField="y" />
          </NtaiSourceWidget>
        );

      case 15:
        return (
          <NtaiSourceWidget title="Sankey">
            <NtaiSankeyChart data={sankeyChartData} widgetId="123" />
          </NtaiSourceWidget>
        );

      case 16:
        return (
          <NtaiSourceWidget title="Pie Chart">
            <NtaiPieChart3
              widgetId={`piechart-${i}`}
              metadata={{ fields: { field123: "123" } }}
              data={histdata}
              valueField="count"
              categoryField="name"
              handleClick={handleOpenPopup}
            />
          </NtaiSourceWidget>
        );

      case 17:
        return (
          <NtaiSourceWidget title="Donut Chart">
            <NtaiDonutChart
              widgetId={`donutchart-${i}`}
              data={histdata}
              grouperThreshold={4}
              innerRadius={50}
              valueField="count"
              categoryField="name"
              handleClick={handleClick}
            />
          </NtaiSourceWidget>
        );
    }
  }

  function generateItems() {
    let result = [];

    for (let i = 0; i < 18; i++) {
      result.push(
        <Box key={i} sx={{ display: "flex", width: "100%", height: "100%" }}>
          {generateWidget(i)}
        </Box>
      );
    }

    return result;
  }

  return (
    <>
      <Box sx={{ p: theme.spacing(2) }}>
        {/* <Button onClick={() => setRefresh(true)}>Apply Data</Button> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          background: grey[50],
        }}
      >
        <NtaiResizableGrid items={generateItems()} />
      </Box>
      <NtaiDecoMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        header={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pb: theme.spacing(1),
            }}
          >
            <Typography variant="subtitle2" fontWeight="700">
              Set Filter
            </Typography>
            <Typography variant="caption" fontWeight="700">
              Set Filter
            </Typography>
          </Box>
        }
        items={[
          {
            icon: "clock",
            label: "Include this data point",
            value: "include",
          },
          {
            icon: "database",
            label: "Exclude this data point",
            value: "exclude",
          },
        ]}
        handleMenuItemSelect={handleMenuItemSelect}
      />

      <Popover
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 600, left: 400 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        The content of the Popover.
      </Popover>
    </>
  );
}
