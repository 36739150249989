import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function NtaiLabelValue({ label, value, direction }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: direction === "row" ? "center" : "start",
        flexDirection: direction ? direction : "column",
        gap: theme.spacing(direction === "row" ? 1 : 0),
      }}
    >
      <Typography variant="subtitle1" color="textPrimary" fontWeight="700">
        {label}
      </Typography>
      <Typography variant="body2">
        {direction === "row" ? `(${value})` : value}
      </Typography>
    </Box>
  );
}
