import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import NtaiHistogramStackedChart from "./NtaiHistogramStackedChart";
import NtaiPieSeriesChart from "./NtaiPieSeriesChart";
import NtaiHorizontalStackedChart from "./NtaiHorizontalStackedChat";
import NtaiPieSeriesChart1 from "./NtaiPieSeriesChart1";
import NtaiHistogramChart from "./NtaiHistogramChart";

const data = [
  {
    year: "2016",
    USA_FULL: 6,
  },

  {
    year: "2017",
    USA_FULL: 10,
  },
];

const data1 = [
  {
    year: "2016",
    USA_FULL: 4,
    USA_FILTERED: 2,
  },

  {
    year: "2017",
    USA_FULL: 6,
    USA_FILTERED: 4,
  },
];

const pieData = [
  {
    country: "USA",
    value1: 10,
    value2: 2,
  },
  {
    country: "EU",
    value1: 20,
    value2: 4,
  },
];

const pieData1 = [
  {
    country: "USA",
    filtered: 10,
    value: 50,
  },
  {
    country: "EU",
    filtered: 15,
    value: 90,
  },
];

const barData1 = [
  {
    category: "Research",
    active: 4,
  },
  {
    category: "Marketing",
    active: 8,
  },
  {
    category: "Development",
    active: 15,
  },
];

const barData2 = [
  {
    category: "Research",
    inactive: 2,
    active: 2,
  },
  {
    category: "Marketing",
    inactive: 3,
    active: 5,
  },
  {
    category: "Development",
    inactive: 10,
    active: 5,
  },
];

const histodata = [
  {
    country: "USA",
    visits: 12,
  },
  {
    country: "GER",
    visits: 16,
  },
];

export default function TestNtaiAmchartsFiltered() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <NtaiHistogramChart data={histodata} />
      {/* <NtaiHistogramStackedChart
          seriesData={fg ? ["USA_FULL"] : ["USA_FILTERED", "USA_FULL"]}
          data={fg ? data : data1}
        />

        <NtaiPieSeriesChart
          // seriesData={fg ? ["full"] : ["filtered", "full"]}
          data={pieData1}
        />

        <NtaiPieSeriesChart1 seriesData={["value1", "value2"]} data={pieData} />

        <NtaiHorizontalStackedChart
          seriesData={fg ? ["active"] : ["active", "inactive"]}
          data={fg ? barData1 : barData2}
        /> */}
    </Box>
  );
}
