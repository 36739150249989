import {
  faCaretDown,
  faGlobe,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NtaiIcons from "utils/NtaiIcons";
import { getDomains } from "../../cdo/domain/store/domainsSlice";
import { clearStatus, getObjects } from "../../cdo/object/store/objectsSlice";
import {
  getDefaultDomain,
  getDefaultDomainRootObject,
} from "./store/entityMenusSlice";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function EntityMenu() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [defaultsLoaded, setDefaultsLoaded] = React.useState(false);
  const [selectedObjectId, setSelectedObjectId] = React.useState();

  const status = useSelector((state) => state.core.entityMenusSlice.status);
  const statusDomainsSlice = useSelector(
    (state) => state.core.domainsSlice.status
  );
  const statusObjectsSlice = useSelector(
    (state) => state.core.objectsSlice.status
  );

  const domainsDataMap = useSelector((state) => state.core.domainsSlice.data);
  const domainsData = Object.values(domainsDataMap);
  const defaultDomain = _.filter(domainsData, { defaultFg: "1" })[0];

  const objectsDataMap = useSelector((state) => state.core.objectsSlice.data);
  const objectsDataAll = objectsDataMap && Object.values(objectsDataMap);
  const objectsData = _.filter(objectsDataAll, function (o) {
    return o.categoryCode !== 3;
  });

  useEffect(() => {
    if (
      statusObjectsSlice &&
      statusObjectsSlice.result === "success" &&
      statusObjectsSlice.method === "getObjects"
    ) {
      setDefaultsLoaded(true);
      dispatch(clearStatus());
    }
  }, [statusObjectsSlice]);

  useEffect(() => {
    if (
      statusDomainsSlice &&
      statusDomainsSlice.result === "success" &&
      statusDomainsSlice.method === "getDomains"
    ) {
      dispatch(
        getObjects({
          domainId: _.get(defaultDomain, "uuId"),
          params: {
            domainUuId: _.get(defaultDomain, "uuId"),
          },
        })
      );
    }
  }, [statusDomainsSlice]);

  useEffect(() => {
    dispatch(getDomains());
  }, []);

  useEffect(() => {
    if (objectsData && _.isArray(objectsData) && objectsData.length > 0) {
      setSelectedObjectId(objectsData[0]["uuId"]);
    }
  }, [objectsData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      {!defaultsLoaded && <NtaiCircularProgress />}
      {defaultsLoaded && objectsData.length === 0 && (
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <NtaiEmptyMessage
            vAlign="center"
            header="No entities found"
            subheader="Create objects and then entities"
          />
        </Box>
      )}
      {defaultsLoaded && objectsData.length > 0 && (
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            {Array.isArray(domainsData) && domainsData.length > 1 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: theme.general.border1,
                  p: theme.spacing(1),
                }}
              >
                <Button
                  startIcon={<FontAwesomeIcon icon={faGlobe} />}
                  variant="outlined"
                  size="small"
                  endIcon={<FontAwesomeIcon icon={faCaretDown} />}
                >
                  {_.get(defaultDomain, "name")}
                </Button>
              </Box>
            )}

            {_.get(defaultDomain, "uuId") &&
              objectsData.map((obj, index) => {
                return (
                  <Box
                    key={`ntai-obj-${index}`}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      key={`ntai-obj-${index}`}
                      to={`/entities-library/domains/${obj.domainUuId}/objects/${obj.uuId}/entities`}
                      // onClick={() => handleSelect(item.uuId)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          padding: "8px",
                          gap: theme.spacing(1),
                          background:
                            selectedObjectId === obj.uuId
                              ? theme.colors.alpha.black[10]
                              : null,
                          "&:hover": {
                            background: theme.colors.alpha.black[10],
                          },
                        }}
                        key={index}
                      >
                        <Box sx={{ paddingLeft: "8px", flexBasis: "20%" }}>
                          <FontAwesomeIcon icon={NtaiIcons["shapes"]} />
                        </Box>

                        <Box sx={{ flexBasis: "80%" }}>
                          <Typography
                            variant="subtitle1"
                            fontWeight={
                              selectedObjectId === obj.uuId ? 700 : 500
                            }
                          >
                            {obj.label}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                    <Divider />
                  </Box>
                );
              })}
          </Box>
        </Scrollbar>
      )}
    </Box>
  );
}
