import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getThesaurusSources = createAsyncThunk(
  "thesaurusSource/getThesaurusSources",
  async (thesaurusId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/sourcedefs`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createThesaurusSources = createAsyncThunk(
  "thesaurusSource/createThesaurusSources",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, formData } = values;
      const response = await server.post(
        `/library/thesauruses/${thesaurusId}/sourcedefs`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteThesaurusSource = createAsyncThunk(
  "thesaurusSource/deleteThesaurusSource",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, uuId } = values;
      const response = await server.delete(
        `/library/thesauruses/${thesaurusId}/sourcedefs/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const thesaurusSourcesSlice = createSlice({
  name: "thesaurusSources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getThesaurusSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getThesaurusSources",
      };
    },
    [getThesaurusSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusSources",
        message: action.payload.message,
      };
    },
    [createThesaurusSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };

      state.status = {
        result: "success",
        method: "createThesaurusSources",
      };
    },
    [createThesaurusSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createThesaurusSources",
        message: action.payload.message,
      };
    },

    [deleteThesaurusSource.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "deleteThesaurusSource",
      };
    },
    [deleteThesaurusSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteThesaurusSource",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = thesaurusSourcesSlice.actions;

export default thesaurusSourcesSlice.reducer;
