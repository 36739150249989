import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchThesaurusLookup = createAsyncThunk(
  "searchThesaurusLookups/getSearchThesaurusLookup",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post("/thesaurus-lookup", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchThesaurusLookupSlice = createSlice({
  name: "searchThesaurusLookups",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchThesaurusLookup.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "conceptId") };
      state.status = {
        result: "success",
        method: "getSearchThesaurusLookup",
      };
    },
    [getSearchThesaurusLookup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchThesaurusLookup",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchThesaurusLookupSlice.actions;

export default searchThesaurusLookupSlice.reducer;
