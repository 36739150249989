import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import { getObject } from "app/main/pages/core/cdo/object/store/objectsSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiHelpText from "@ntai/components/helptext/NtaiHelpText";
import { useSnackbar } from "notistack";
import { SearchSourceRecordListContext } from "../SearchSourceRecordListPanel";
import { SearchSourceContext } from "../../SearchSource";
import useSearchSourceWidgetTempFilters from "../../hooks/useSearchSourceWidgetTempFilters";
const _ = require("lodash");

export default function SearchSourceRecordsBatchAssessment({
  adjObject,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRecords, setSelectedRecords } = React.useContext(
    SearchSourceRecordListContext
  );

  const { searchSourceData } = React.useContext(SearchSourceContext);
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const status = useSelector(
    (state) => state.search.searchSourceRecordsBatchAssessmentsSlice.status
  );

  function onHandleSubmit() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchSourceBatchAssessmentForm")
    );
    const revisedAdjFormData = ObjectFormUtil.generateFormSubmissionData(
      sanitizedFormData,
      adjObject
    );

    const adjFormData = {
      moduleCode: 1,
      bulkAdjFg: 1,
      clientData: {
        objectUuId: _.get(adjObject, "uuId"),
        ...revisedAdjFormData,
      },
    };

    const finalFormData = {
      recordIds: [...selectedRecords],
      selectAllFg: selectedRecords.length === 0 ? 1 : 0,
      searchSourceWidgetFilters: searchSourceWidgetTempFilters,
      adjudication: adjFormData,
    };

    handleSave(finalFormData);
  }

  return (
    <NtaiDialogFormPanel
      handleSave={onHandleSubmit}
      handleCancel={handleCancel}
      handleSaveLabel="Submit"
      disabledSave={status && status.result === "pending" ? true : false}
    >
      <NtaiHelpText header="Total records selected: " direction="row">
        {selectedRecords.length === 0 ? "All" : selectedRecords.length}
      </NtaiHelpText>
      <NtaiHelpText direction="row" header="Selected Records:">
        {selectedRecords.join(", ")}
      </NtaiHelpText>

      <Box
        sx={{
          display: "flex",
          height: "220px",
          width: "100%",
          flexDirection: "column",
          // border: theme.general.border1,
          // borderRadius: theme.general.borderRadius,
        }}
      >
        {adjObject && !_.isEmpty(adjObject["objectForm"]) && (
          <Scrollbar autoHide={false}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(2),
                pr: theme.spacing(2),
              }}
            >
              {ObjectFormUtil.generateForm(
                "searchSourceBatchAssessmentForm",
                adjObject["objectForm"],
                true,
                theme
              )}
            </Box>
          </Scrollbar>
        )}
        {(!adjObject || !adjObject["objectForm"]) && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <NtaiEmptyMessage
              header="Assessment form not available"
              subheader="No assessment form has been configured"
            />
          </Box>
        )}
      </Box>
    </NtaiDialogFormPanel>
  );
}
