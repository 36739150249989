import NtaiUtils from "@ntai/utils/NtaiUtils";
import { ReportContext } from "../ReportMain";
import server from "app/http/ntaiServer";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useDispatch } from "react-redux";
import { getReport } from "../../store/reportsSlice";
const _ = require("lodash");

export default function ReportViewer(props) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState();
  const [url, setUrl] = React.useState();

  const { getValues } = useFormContext();

  const { action, reportId, generateReportCounter, reportFormData } =
    React.useContext(ReportContext);

  function handleGenerateReport() {
    server
      .post(url, reportFormData, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setFileUrl(fileURL);
        //Open the URL on new Window
        // window.open(fileURL);
        setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const tmpUrl = "/reports/groups/*/reports/".concat(
      action === "add" ? "*" : reportId,
      "/preview"
    );

    setUrl(tmpUrl);

    if (reportFormData && !_.isEmpty(reportFormData)) {
      handleGenerateReport();
    }
  }, [generateReportCounter, reportFormData]);

  useEffect(() => {
    if (action === "edit") {
      dispatch(
        getReport({
          groupId: "*",
          uuId: reportId,
        })
      );
    }
  }, [reportId]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      {loaded && <object data={fileUrl} width="100%" height="100%"></object>}
      {!loaded && <NtaiEmptyMessage header="Report data not available" />}
    </Box>
  );
}
