import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";

import {
  clearStatus,
  setActiveId,
  updateSourceSeriesImportCsvScheme,
} from "./store/sourceSeriesImportCsvSchemesSlice";
import SourceSeriesImportCsvSchemeForm from "./SourceSeriesImportCsvSchemeForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SourceSeriesImportCsvSchemeEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { id, handleCancel } = props;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceSeriesImportCsvSchemesSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceSeriesImportCsvSchemesSlice.data
  );

  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceSeriesImportCsvScheme({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceSeriesImportCsvScheme" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Source series scheme "${formData["name"]}" updated successfully`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiForm>
      <SourceSeriesImportCsvSchemeForm
        action="edit"
        formData={formData}
        handleFormSubmit={handleSave}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
