import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getEntitySources = createAsyncThunk(
  "entitySources/getEntitySources",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/sources`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEntitySource = createAsyncThunk(
  "entitySources/getEntitySource",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/sources/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createEntitySource = createAsyncThunk(
  "entitySources/createEntitySource",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/sources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEntitySource = createAsyncThunk(
  "entitySources/updateEntitySource",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/sources/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEntitySource = createAsyncThunk(
  "entitySources/deleteEntitySource",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/sources/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const entitySourcesSlice = createSlice({
  name: "entitySources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getEntitySources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getEntitySources" };
    },
    [getEntitySources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getEntitySources",
        message: action.payload.message,
      };
    },
    [getEntitySource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getEntitySource" };
    },
    [getEntitySource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getEntitySource",
        message: action.payload.message,
      };
    },
    [createEntitySource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createEntitySource" };
    },
    [createEntitySource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createEntitySource",
        message: action.payload.message,
      };
    },
    [updateEntitySource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateEntitySource" };
    },
    [updateEntitySource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateEntitySource",
        message: action.payload.message,
      };
    },
    [deleteEntitySource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteEntitySource" };
    },
    [deleteEntitySource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteEntitySource",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = entitySourcesSlice.actions;

export default entitySourcesSlice.reducer;
