import React, { useState } from "react";
import { faEllipsisV, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";

import "./ntai-dashboard-widget.css";
const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    background: (props) => (props.bg ? props.bg : null),
  },
  header: {
    display: "flex",
    height: (props) => (props.headerAbsentFg ? "4px" : "42px"),
    // flexBasis: "10%",
    alignItems: "center",

    padding: (props) => (props.headerAbsentFg ? "0px" : "8px"),
    // borderBottom: "1px solid #eee",
    "&:hover": {
      cursor: "grab",
    },
  },
  headerTitle: {
    display: "flex",
    flexBasis: "80%",
    paddingLeft: "4px",
  },
  headerActions: {
    display: "flex",
    flexBasis: "20%",
    paddingRight: "4px",
    justifyContent: "end",
    alignItems: "center",
    gap: "4px",
  },
  main: {
    display: "flex",
    // flexBasis: "80%",
    paddingTop: (props) =>
      props.headerAbsentFg === 1 ? theme.spacing(1) : null,
    height: (props) =>
      props.footer
        ? `calc(100% - 60px)`
        : props.headerAbsentFg === 1
        ? "100%"
        : `calc(100% - 52px)`,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    position: "relative",
    zIndex: 0,
  },
}));

export default function NtaiDashboardWidget(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const { loading, headerAbsentFg, title, bg, fg, children, footer } = props;
  const classes = useStyles(props);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          border: theme.general.border1,
          borderRadius: theme.general.borderRadiusSm,
          background: "white",
          "&:hover": { boxShadow: theme.colors.shadows.cardSm },
        }}
      >
        <Box className={classes.widgetContainer}>
          <Box className="widget-header-wrapper">
            <Box id="title-action" className={classes.header}>
              {!headerAbsentFg && (
                <Box className={classes.headerTitle}>
                  <Typography
                    variant="subtitle1"
                    color={fg ? fg : null}
                    fontWeight="600"
                  >
                    {title}
                  </Typography>
                </Box>
              )}
              {!headerAbsentFg && (
                <Box className={classes.headerActions}>
                  <IconButton size="small">
                    <FontAwesomeIcon
                      size="2xs"
                      color={fg ? fg : null}
                      icon={faRefresh}
                    />
                  </IconButton>
                  <IconButton size="small">
                    <FontAwesomeIcon
                      size="2xs"
                      color={fg ? fg : null}
                      icon={faEllipsisV}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>

          <Box id="main" className={classes.main}>
            {children}
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: "absolute",
                backgroundColor: "transparent",
                opacity: 0.5,
              }}
              open={loading}
            >
              <CircularProgress size={20} />
            </Backdrop>
          </Box>
          {footer && (
            <Box
              sx={{
                height: "18px",
                display: "flex",
                py: "6px",
                pl: "8px",
                alignItems: "center",
              }}
            >
              {footer}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
