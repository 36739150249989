import React, { useEffect } from "react";
import NtaiPopperPanel from "@ntai/components/poppers/NtaiPopperPanel";
import { SearchSourceContext } from "../../../SearchSource";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../../SearchEdit";
import {
  clearStatus,
  deleteWidgetFilter,
  deleteWidgetIEFilter,
} from "../../../store/searchSourcesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  faFilter,
  faClose,
  faInfoCircle,
  faPlus,
  faMinus,
  faStar,
  faEquals,
  faNotEqual,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { grey } from "@mui/material/colors";
import { deleteWidgetTempFilter } from "../../../view/widgets/store/searchSourceViewWidgetsSlice";
import useSearchSourceWidgetTempFilters from "../../../hooks/useSearchSourceWidgetTempFilters";
const _ = require("lodash");

const FILTER_ICONS = {
  wf: {
    0: faEquals,
    1: faNotEqual,
  },
  ief: {
    0: faPlus,
    1: faMinus,
  },
  twf: {
    1: faStar,
  },
};
export default function SearchSourceWidgetFilters({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData, reload, setReload, setRefresh } =
    React.useContext(SearchSourceContext);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const searchSourceWidgetFilters = _.has(
    searchSourceData,
    "searchSourceWidgetFilters"
  )
    ? _.get(searchSourceData, "searchSourceWidgetFilters")
    : [];

  const searchSourceWidgetIEFilters = _.has(
    searchSourceData,
    "searchSourceWidgetIEs"
  )
    ? _.get(searchSourceData, "searchSourceWidgetIEs")
    : [];

  const status = useSelector((state) => state.search.searchSourcesSlice.status);

  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const searchSourceTempFilters = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.tempFilters
  );

  const searchSourceWidgetTempFiltersStatus = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.status
  );

  function getFilterIcon(filter) {
    let filterIcon = faEquals;

    if (filter["type"] === "wf") {
      filterIcon = FILTER_ICONS["wf"][filter["notEqualsFg"]];
    } else if (filter["type"] === "ief") {
      filterIcon = FILTER_ICONS["ief"][filter["excludeFg"]];
    } else if (filter["type"] === "twf") {
      filterIcon = FILTER_ICONS["twf"][1];
    }

    return filterIcon;
  }

  function getSearchSourceWidgetTempFilters() {
    let result = [];
    const widgetTempFilters = _.has(searchSourceTempFilters, searchSourceId)
      ? searchSourceTempFilters[searchSourceId]
      : {};

    if (!_.isEmpty(widgetTempFilters)) {
      Object.keys(widgetTempFilters).forEach((wUuId, i) => {
        if (_.isArray(widgetTempFilters[wUuId])) {
          widgetTempFilters[wUuId].forEach((wfilter, j) => {
            result.push({
              primarySourceWidgetUuId: wUuId,
              uuId: wUuId,
              type: "twf",
              searchSourceWidgetFilterFields: [...wfilter],
            });
          });
        }
      });
    }

    return result;
  }

  function generateWidgetFilters() {
    let result = [];

    const revisedFilters = _.orderBy(
      [
        ...searchSourceWidgetFilters.map((f, i) => {
          return {
            ...f,
            type: "wf",
          };
        }),
        ...searchSourceWidgetIEFilters.map((f, i) => {
          return {
            ...f,
            type: "ief",
          };
        }),
        ...getSearchSourceWidgetTempFilters(),
      ],
      ["dateModified"],
      ["desc"]
    );

    revisedFilters.forEach((widgetFilter, index) => {
      const searchSourceWidgetFilterFields = _.get(
        widgetFilter,
        "searchSourceWidgetFilterFields"
      );

      result.push(
        <React.Fragment key={`search-source-wfilter-${index}`}>
          <ListItem
            sx={{ pl: theme.spacing(1) }}
            secondaryAction={
              <IconButton
                sx={{ mr: theme.spacing(0.5) }}
                size="small"
                edge="end"
                aria-label="widget-filter-delete"
                onClick={() =>
                  widgetFilter["type"] === "twf"
                    ? handleWidgetTempFilterDelete(widgetFilter)
                    : handleDelete(widgetFilter["type"], widgetFilter.uuId)
                }
              >
                <FontAwesomeIcon size="xs" icon={faClose} />
              </IconButton>
            }
          >
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center",
              }}
            >
              <NtaiBadge
                title={
                  <FontAwesomeIcon
                    size="xs"
                    icon={getFilterIcon(widgetFilter)}
                  />
                }
                fg={grey[700]}
                bg={grey[100]}
              />
              <ListItemText
                sx={{ pl: "4px" }}
                primary={searchSourceWidgetFilterFields
                  .map((f, i) => `${f.sourceFieldLabel}: ${f.fieldValue}`)
                  .join(" AND ")}
              />
            </Box>
          </ListItem>
          <Divider />
        </React.Fragment>
      );
    });

    if (result.length > 0) {
      return <List sx={{ p: 0, width: "100%" }}>{result}</List>;
    }

    return result;
  }

  function handleDelete(type, uuId) {
    if (["wf", "ief"].includes(type)) {
      dispatch(
        type === "wf"
          ? deleteWidgetFilter({
              searchId: _.get(searchData, "uuId"),
              searchSourceId: searchSourceId,
              uuId: uuId,
            })
          : deleteWidgetIEFilter({
              searchId: _.get(searchData, "uuId"),
              searchSourceId: searchSourceId,
              uuId: uuId,
            })
      );
    }
  }

  function handleWidgetTempFilterDelete(widgetFilter) {
    dispatch(
      deleteWidgetTempFilter({
        searchSourceId: searchSourceId,
        widgetUuId: widgetFilter["uuId"],
        widgetTempFilter: widgetFilter["searchSourceWidgetFilterFields"],
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      ["deleteWidgetFilter", "deleteWidgetIEFilter"].includes(status.method) &&
      status.result === "success"
    ) {
      setReload(reload + 1);
      setRefresh(true);
      dispatch(clearStatus());
    }

    if (
      searchSourceWidgetFilters.length === 0 &&
      searchSourceWidgetIEFilters.length === 0 &&
      (_.isEmpty(searchSourceWidgetTempFilters) ||
        searchSourceWidgetTempFilters === undefined)
    )
      handleClose();
  }, [status, searchSourceWidgetTempFiltersStatus]);

  return (
    <Box
      sx={{
        height: "320px",
        width: "420px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faFilter} />
        <Typography variant="h5" fontWeight="500" color={grey[700]}>
          Filters
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              p: theme.spacing(0),
              display: "flex",
              alignItems: "start",
              flexWrap: "wrap",
              width: "100%",
              height: "100%",
              gap: theme.spacing(1),
              alignContent: "start",
            }}
          >
            {generateWidgetFilters()}
            {searchSourceWidgetFilters &&
              _.isArray(searchSourceWidgetFilters) &&
              searchSourceWidgetFilters.length === 0 &&
              searchSourceWidgetIEFilters &&
              _.isArray(searchSourceWidgetIEFilters) &&
              searchSourceWidgetIEFilters.length === 0 &&
              _.isArray(searchSourceWidgetTempFilters) &&
              searchSourceWidgetTempFilters.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NtaiEmptyMessage
                    header="No filters found"
                    subheader="Filter records through available widgets"
                    vAlign="center"
                    hAlign="center"
                  />
                </Box>
              )}
          </Box>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
