import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import D3Circle from "./tests/D3Circle";
import NtaiD31 from "./tests/NtaiD31";
import NtaiD3Chart1 from "./tests/test2/NtaiD3Chart1";
import NtaiTestD3Chart from "./tests/test3/NtaiTestD3Chart";

export default function NtaiTestD3() {
  const theme = useTheme();
  return (
    <NtaiTabs>
      <Box label="General" sx={{ display: "flex", p: theme.spacing(2) }}>
        <NtaiD31 />
      </Box>
      <Box label="Circle" sx={{ display: "flex", p: theme.spacing(2) }}>
        <D3Circle />
      </Box>
      <Box label="D3 Approach" sx={{ display: "flex", p: theme.spacing(2) }}>
        <NtaiTestD3Chart />
      </Box>
    </NtaiTabs>
  );
}
