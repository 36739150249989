import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceDatasetFields,
  updateSourceDatasetField,
  clearStatus,
} from "../../../pl/stage/dataset/store/sourceFieldsSlice";
import SourceOutElasticFieldForm from "./SourceOutElasticFieldForm";
import { getSourceFieldLookups } from "../lookup/store/sourceFieldLookupsSlice";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import SourceOutElasticFieldMapping from "./SourceOutElasticFieldMapping";
const _ = require("lodash");

export default function SourceOutElasticFields({
  sourceDatasetId,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [currentId, setCurrentId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [mappingDrawerOpen, setMappingDrawerOpen] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = useSelector(
    (state) => state.sources.sourceOutStoresSlice.activeId
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourceFieldsSlice.data
  );

  const status = useSelector((state) => state.sources.sourceFieldsSlice.status);

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? _.orderBy(
          _.filter(Object.values(sourceFieldsDataMap), function (o) {
            return o["name"].startsWith("sys_") ? false : true;
          }),
          ["label"],
          ["asc"]
        )
      : [];

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: "*",
    sourceStageId: "*",
    sourceDatasetId: sourceDatasetId,
  };

  function handleFieldSelect(sourceFieldUuId) {
    setCurrentId(sourceFieldUuId);
  }

  function handleSave(formData) {
    dispatch(
      updateSourceDatasetField({
        ...params,
        uuId: currentId,
        formData: formData,
      })
    );
  }

  function handleMappingDrawerOpen() {
    setMappingDrawerOpen(true);
  }

  function handleMappingDrawerClose() {
    setMappingDrawerOpen(false);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceDatasetFields" &&
      status.result === "success"
    ) {
      if (_.isArray(sourceFieldsData)) {
        setCurrentId(_.get(sourceFieldsData[0], "uuId"));
      }
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceFieldLookups({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getSourceDatasetFields(params));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FIELD_ES_DATA_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FIELD_STRATEGY_CODE));
  }, [sourceId]);

  return (
    <React.Fragment>
      <NtaiPanel
        width="75%"
        header="Store Fields"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
        actionsJustifyContent="end"
      >
        {currentId &&
          sourceFieldsData &&
          _.isArray(sourceFieldsData) &&
          sourceFieldsData.length > 0 &&
          _.has(sourceFieldsDataMap, currentId) && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">
                  Available Fields (
                  {sourceFieldsData && sourceFieldsData.length})
                </Typography>
                <Button size="small" onClick={() => handleMappingDrawerOpen()}>
                  View Mapping
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "460px",
                  width: "100%",
                  border: theme.general.border1,
                  borderRadius: theme.general.borderRadius,
                }}
              >
                <Box
                  sx={{
                    flexBasis: "40%",
                    display: "flex",
                    borderRight: theme.general.border1,
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      borderBottom: theme.general.border1,
                      p: theme.spacing(1),
                      height: "54px",
                    }}
                  >
                    <NtaiSimpleSearchBar
                      height="36px"
                      placeholder="Search Fields.."
                    />
                  </Box>
                  <Box sx={{ height: `calc(100% - 54px)` }}>
                    {sourceFieldsData &&
                      _.isArray(sourceFieldsData) &&
                      sourceFieldsData.length > 0 && (
                        <Scrollbar>
                          <List sx={{ p: 0 }}>
                            {_.orderBy(sourceFieldsData, ["name"], ["asc"]).map(
                              (sf, i) => (
                                <React.Fragment key={`source-field-${i}`}>
                                  <ListItem sx={{ p: 0 }}>
                                    <ListItemButton
                                      onClick={(e) =>
                                        handleFieldSelect(sf.uuId)
                                      }
                                    >
                                      <Box sx={{ pr: theme.spacing(2) }}>
                                        <Avatar
                                          background="white"
                                          color="black"
                                          sx={{
                                            background: grey[200],
                                            color: grey[900],
                                            fontWeight: 500,
                                            height: 32,
                                            width: 32,
                                          }}
                                        >
                                          {_.get(sf, "esDataTypeDecode") &&
                                            _.get(sf, "esDataTypeDecode")
                                              .substring(0, 1)
                                              .toLowerCase()}
                                        </Avatar>
                                        {/* <NtaiBadge
                                          title={
                                            _.get(sf, "esDataTypeDecode") &&
                                            _.get(sf, "esDataTypeDecode")
                                              .substring(0, 1)
                                              .toLowerCase()
                                          }
                                          fg={grey[700]}
                                          bg={grey[100]}
                                        /> */}
                                      </Box>

                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="subtitle1"
                                            color="inherit"
                                            fontWeight="700"
                                          >
                                            {sf.name}
                                          </Typography>
                                        }
                                        secondary={
                                          <Typography
                                            sx={{ wordBreak: "break-word" }}
                                            variant="subtitle2"
                                          >
                                            {`${sf.path} | ${sf.label}`}
                                          </Typography>
                                        }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                  <Divider />
                                </React.Fragment>
                              )
                            )}
                          </List>
                        </Scrollbar>
                      )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "100%",
                    flexBasis: "60%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      borderBottom: theme.general.border1,
                      p: theme.spacing(2),
                      height: "54px",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      {sourceFieldsDataMap &&
                        currentId &&
                        sourceFieldsDataMap[currentId]["label"]}
                    </Typography>
                    <Typography variant="subtitle2">
                      {sourceFieldsDataMap &&
                        currentId &&
                        sourceFieldsDataMap[currentId]["name"]}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      height: `calc(100% - 54px)`,
                      display: "flex",
                    }}
                  >
                    {sourceFieldsDataMap && currentId && (
                      <SourceOutElasticFieldForm
                        sourceFieldId={currentId}
                        dataMap={sourceFieldsDataMap}
                        handleSave={handleSave}
                      />
                    )}
                    {!sourceFieldsDataMap && <NtaiCircularProgress />}
                  </Box>
                </Box>
              </Box>
            </>
          )}
      </NtaiPanel>
      <Drawer
        open={mappingDrawerOpen}
        onClose={handleMappingDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        <SourceOutElasticFieldMapping />
      </Drawer>
    </React.Fragment>
  );
}
