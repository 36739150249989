import React, { useEffect } from "react";
import DataAdapterFileUpload from "./DataAdapterFileUpload";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPythonFiles } from "../../models/pythonfile/store/pythonFilesSlice";
import NtaiListTable from "@ntai/components/tables/list/NtaiListTable";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function DataAdapterPythonFiles({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const pythonFilesDatamap = useSelector(
    (state) => state.models.pythonFilesSlice.data
  );

  const pythonFilesData = pythonFilesDatamap
    ? Object.values(pythonFilesDatamap)
    : [];

  function handleAction(listAction, selected) {
    if (listAction === "save") {
      //   console.log("py file submit data: ", {
      //     ...dataMap[activeId],
      //     pyFileUuIds: selected && _.isArray(selected) ? selected : [],
      //   });

      handleSave({ ...formData, pyFileUuIds: selected });
    } else {
      handleCancel();
    }
  }

  useEffect(() => {
    dispatch(getPythonFiles());
  }, []);

  return (
    <React.Fragment>
      {action === "edit" && formData["execTypeCode"] === 1 && (
        <DataAdapterFileUpload formData={formData} />
      )}
      {action === "edit" && formData["execTypeCode"] === 2 && (
        <NtaiPanel
          width="50%"
          header="Select Python files"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
        >
          {pythonFilesData &&
            _.isArray(pythonFilesData) &&
            pythonFilesData.length > 0 && (
              <NtaiListTable
                handleAction={handleAction}
                actions={[
                  { value: "save", label: "Save", variant: "contained" },
                  { value: "cancel", label: "Cancel" },
                ]}
                keyField="uuId"
                primaryLabelField="fileName"
                secondaryLabelField="description"
                items={pythonFilesData}
                selectedItems={_.get(formData, "pyFileUuIds")}
              />
            )}
        </NtaiPanel>
      )}
    </React.Fragment>
  );
}
