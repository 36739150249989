import SourceRecordListingCreate from "./listing/SourceRecordListingCreate";
import SourceRecordListingEdit from "./listing/SourceRecordListingEdit";
import SourceRecordListingList from "./listing/SourceRecordListingList";
import SourceTemplateWrapper from "./SourceTemplateWrapper";

const SourceTemplateConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/templates",
      exact: true,
      component: SourceTemplateWrapper,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/records/listings",
      exact: true,
      component: SourceRecordListingList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/records/listings/create",
      exact: true,
      component: SourceRecordListingCreate,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/records/listings/:id/edit",
      exact: true,
      component: SourceRecordListingEdit,
    },
  ],
};

export default SourceTemplateConfig;
