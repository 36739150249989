import React from "react";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import history from "@ntai/@history";
import { useDispatch, useSelector } from "react-redux";
import SearchDashboardEntitySearch from "./SearchDashboardEntitySearch";
import SearchDashboardEntities from "./SearchDashboardEntities";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { SearchDashboardInitContext } from "../SearchDashboardInit";
import SearchDashboardEntityFilters from "./SearchDashboardEntityFilters";
import {
  faChartTreeMap,
  faEllipsisV,
  faUserChart,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clearStatus,
  getSearchDashboardEntities,
} from "../store/searchDashboardInitSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function SearchDashboardEntityWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { setCurrentStep, selectedEntities, selectedEntityDetails } =
    React.useContext(SearchDashboardInitContext);

  const entitiesDataMap = useSelector(
    (state) => state.search.searchDashboardInitSlice.entities
  );

  const entitiesData =
    entitiesDataMap && !_.isEmpty(entitiesDataMap)
      ? Object.values(entitiesDataMap)
      : [];

  const status = useSelector(
    (state) => state.search.searchDashboardInitSlice.status
  );

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchDashboardEntities" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSearchDashboardEntities());
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: theme.spacing(2),
          flexBasis: "10%",
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          background: grey[50],
        }}
      >
        <NtaiButton
          onClick={() => history.push("/search")}
          size="small"
          icon="back"
        >
          Back to Listing
        </NtaiButton>
      </Box>

      {/* <SearchInitSourcesWrapper mode="adv" /> */}
      {loaded && _.isArray(entitiesData) && entitiesData.length > 0 && (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: theme.general.border1,
              background: grey[50],
              pb: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "60%",
                flexDirection: "column",
                justifyContent: "start",
                gap: theme.spacing(1),
              }}
            >
              <Typography variant="h1" fontWeight="300">
                Select Dashboard Entity
              </Typography>
              <Typography variant="body1" fontWeight="300">
                Find desired entity for dashboard using search bar below
              </Typography>
              <NtaiForm>
                <SearchDashboardEntitySearch />
              </NtaiForm>
            </Box>
          </Box>
          <Box
            sx={{ height: `calc(100% - 88px)`, display: "flex", width: "100%" }}
          >
            <SearchDashboardEntityFilters />
            <SearchDashboardEntities />
          </Box>

          <Box sx={{ height: "88px", borderTop: theme.general.border1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                p: theme.spacing(2),
                gap: theme.spacing(1),
              }}
            >
              {selectedEntityDetails && (
                <React.Fragment>
                  <Typography variant="h6">{`Selected Entity:`}</Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {selectedEntityDetails["title"]}
                  </Typography>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </React.Fragment>
              )}

              <Button
                startIcon={<FontAwesomeIcon icon={faChartTreeMap} />}
                variant="contained"
                disabled={selectedEntities.size > 0 ? false : true}
                onClick={() => setCurrentStep("select-views")}
              >
                Select Dashboards
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      )}

      {loaded && _.isArray(entitiesData) && entitiesData.length === 0 && (
        <Box sx={{ flexBasis: "100%", display: "flex", width: "100%" }}>
          <NtaiEmptyMessage
            vAlign="center"
            header="No entities available"
            subheader="You do not have necessary permissions to view entities"
          />
        </Box>
      )}
      {!loaded && <NtaiCircularProgress />}
    </React.Fragment>
  );
}
