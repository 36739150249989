import { Box, Link, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export default function SearchSourceWidgetInfoDialog(props) {
  const theme = useTheme();
  const { open, handleDialogClose, metadata } = props;

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const fields = metadata["fields"];

  useEffect(() => {}, [metadata]);

  return (
    <NtaiDialog
      size="sm"
      open={open}
      handleDialogClose={handleDialogClose}
      title="Field Information"
    >
      <Box
        sx={{ py: theme.spacing(1), display: "flex", flexDirection: "column" }}
      >
        <Typography variant="subtitle1" fontWeight="700">
          Attrition Model Information
        </Typography>
        <Typography variant="subtitle2">
          The attrition model field is computed using underlying
          <span style={{ fontWeight: "700" }}> Logistic regression </span>and
          <span style={{ fontWeight: "700" }}> Naive bayes </span> machine
          learning models that further use Job satisfaction, Environment
          satisfaction, Work life balance, Distance from home and Demographics
          related parameters to compute the employee's attrition score.
        </Typography>
      </Box>
    </NtaiDialog>
  );
}
