import { FormControl, Slider, TextField, Typography } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiFilterBox from "./NtaiFilterBox";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { DatePicker } from "@mui/x-date-pickers";
import NtaiInputErrorMessage from "../NtaiInputErrorMessage";
const _ = require("lodash");

const NtaiMuiDateField = (props) => {
  const { label, name, field } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl>
      <DatePicker
        label={label}
        renderInput={(props1) => (
          <TextField size="small" {...props1} {...props} label={null} />
        )}
        {...props}
      />
      <NtaiInputErrorMessage name={name} errors={errors} />
    </FormControl>
  );
};

function NtaiFilterDatePicker(props) {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const { label } = props;

  return (
    <NtaiFilterBox>
      <Typography variant="subtitle2" fontWeight="700">
        {label}
      </Typography>
      <Controller
        render={({ ref, field: { onChange, value, name } }) => (
          <NtaiMuiDateField
            name={name}
            value={value}
            onChange={(date) => setValue(name, date)}
            {...props}
          />
        )}
        control={control}
        defaultValue={null}
        setValue={setValue}
        {...props}
      />
    </NtaiFilterBox>
  );
}

export default NtaiFilterDatePicker;
