import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiDashboardMetric1Widget from "./NtaiDashboardMetric1Widget";
import NtaiDashboardList1Widget from "./NtaiDashboardList1Widget";
import NtaiDashboardMetric2Widget from "./NtaiDashboardMetric2Widget";
import NtaiDashboardMetric3Widget from "./NtaiDashboardMetric3Widget";
import NtaiDashboardTableWidget from "./NtaiDashboardTableWidget";

const listData = [
  {
    primary: "Search 12GH",
    secondary: "More more information",
    avatar: "M",
  },
  {
    primary: "Search 13GH",
    secondary: "More and information",
    avatar: "D",
  },
];

const tableData = [
  {
    id: 1,
    name: "Shaard",
  },
];

export default function TestDashboardWidgets() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        flexWrap: "wrap",
        gap: theme.spacing(1),
        width: "100%",
      }}
    >
      <NtaiDashboardMetric2Widget
        data={[{ header: "New", subheader: "345" }]}
        footer="Some users"
        bg={theme.colors.secondary.main}
        fg="white"
      />

      <NtaiDashboardList1Widget data={listData} />

      <NtaiDashboardTableWidget data={tableData} />
    </Box>
  );
}
