import { Box, lighten, useTheme } from "@mui/material";
import NtaiDashboardMetric1Widget from "@ntai/components/widgets/dashboard/templates/NtaiDashboardMetric1Widget";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getOversightSummaryAll,
} from "./store/oversightSummaryAllSlice";
import { useEffect, useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import OversightSummaryEntityList from "./OversightSummaryEntityList";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

export default function OversightSummary() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const data = useSelector(
    (state) => state.oversight.oversightSummaryAllSlice.data
  );
  const status = useSelector(
    (state) => state.oversight.oversightSummaryAllSlice.status
  );

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getOversightSummaryAll"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getOversightSummaryAll());
  }, []);

  return (
    <NtaiPanel
      width="80%"
      header="Summary"
      subheader="This section summarizes key details about configured entities and associated topics. User can navigate this section using entitiy-topics hierarchy in the left panel. Use 'Create Topic' option on the top to create new Topic for the selected entity"
    >
      {loaded && (
        <React.Fragment>
          <Box
            sx={{
              pt: theme.spacing(1),
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              gap: theme.spacing(2),
              justifyContent: "space-around",
            }}
          >
            <NtaiDashboardMetric1Widget
              header="Entities"
              subheader={data.numOfEntities}
              footer="Available Entities"
              bg={lighten(theme.colors.secondary.main, 0.2)}
              fg="secondary.contrastText"
            />
            <NtaiDashboardMetric1Widget
              header="Topics"
              subheader={data.numOfTopics}
              footer="Topics Published"
              bg={lighten(theme.colors.primary.main, 0.6)}
              fg="secondary.contrastText"
            />
            <NtaiDashboardMetric1Widget
              header="Assessments"
              subheader={data.numOfAssessments}
              footer="Total Assessments"
              bg={lighten(theme.colors.primary.main, 0.5)}
              fg="secondary.contrastText"
            />
            <NtaiDashboardMetric1Widget
              header="Actions"
              subheader={data.numOfActions}
              footer="Total Actions"
              bg={lighten(theme.colors.secondary.main, 0.2)}
              fg="secondary.contrastText"
            />
            <NtaiDashboardMetric1Widget
              header="Notes"
              subheader={data.numOfNotes}
              footer="Notes Exchanged"
              bg={lighten(theme.colors.secondary.main, 0.4)}
              fg="secondary.contrastText"
            />
          </Box>
          <OversightSummaryEntityList data={data.entities} />
        </React.Fragment>
      )}
      {!loaded && <NtaiCircularProgress vAlign="center" />}
    </NtaiPanel>
  );
}
