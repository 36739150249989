import { grey } from "@mui/material/colors";
import { tooltipConfig } from "../../chartConfig";
import NtaiEChartUtil from "../../util/NtaiEChartUtil";
import { colors, lighten } from "@mui/material";
import { darken } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";

const _ = require("lodash");

const ITEM_STYLE_V_DEFAULT = {
  color: {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [],
  },
};

const ITEM_STYLE_H_DEFAULT = {
  color: {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [],
  },
};

export const barGridConfig = {
  containLabel: true,
  left: 20,
  top: 30,
  right: 20,
  bottom: 20,
};

export const hYAxisLineConfig = {
  lineStyle: {
    color: "#ccc",
  },
};

export const hYAxisLabelConfig = {
  overflow: "truncate",
  width: 150,
  ellipsis: "...",
  show: true,
  fontSize: 12,
  color: "#333",
};

export const vXAxisLineConfig = {
  lineStyle: {
    color: "#ccc",
  },
};

export const vXAxisLabelConfig = {
  overflow: "truncate",
  width: 150,
  ellipsis: "...",
  show: true,
  interval: 0,
  rotate: 90,
  fontSize: 12,
  color: "#333",
};

export const hXAxisLabelConfig = {
  overflow: "truncate",
  width: 150,
  ellipsis: "...",
  show: true,
  interval: 0,
  fontSize: 12,
  color: "#333",
};

class NtaiBarEChartUtil {
  static isBarAlreadyChecked(params, option) {
    const checked = _.filter(option["series"][0]["data"], function (v, i) {
      if (v["isChecked"] && i === params["dataIndex"]) return true;
      else return false;
    });

    if (checked.length > 0) return true;
    else return false;
  }

  static getHorizontalBarChartHeight(data, seriesFg) {
    if (seriesFg)
      return (
        (data["category"]["data"].length + data["series"]["data"].length) * 20 +
        100
      );
    else return data.length * 20 + 100;
  }

  static getItemStyle(data, metadata, color, type) {
    let itemStyle = _.cloneDeep(
      type === "h" ? ITEM_STYLE_H_DEFAULT : ITEM_STYLE_V_DEFAULT
    );

    if (metadata["filterFg"]) {
      if (data["offset"] > 0 && data["offset"] < 1) {
        itemStyle["color"]["colorStops"] = [
          {
            offset: 0,
            color: type === "h" ? color : lighten(color, 0.5),
          },
          {
            offset: type === "h" ? data["offset"] : 1 - data["offset"],
            color: type === "h" ? color : lighten(color, 0.5), // color at offset
          },
          {
            offset: type === "h" ? data["offset"] : 1 - data["offset"],
            color: type === "h" ? lighten(color, 0.5) : color,
          },
          {
            offset: 1,
            color: type === "h" ? lighten(color, 0.5) : color, // color at 100%
          },
        ];
      } else if (data["offset"] === 0) {
        itemStyle["color"]["colorStops"] = [
          {
            offset: 0,
            color: lighten(color, 0.5),
          },
          {
            offset: 1,
            color: lighten(color, 0.5), // color at offset
          },
        ];
      } else {
        itemStyle["color"]["colorStops"] = [
          {
            offset: 0,
            color: color,
          },
          {
            offset: 1,
            color: color, // color at offset
          },
        ];
      }
    } else {
      itemStyle["color"]["colorStops"] = [
        {
          offset: 0,
          color: color,
        },
        {
          offset: 1,
          color: color, // color at offset
        },
      ];
    }

    return itemStyle;
  }

  static getTooltipSingleSeries(params) {
    return NtaiEChartUtil.getTooltipContent({
      values: [
        {
          key: NtaiEChartUtil.getMarker(params.data._color) + " " + params.name,
          value: params.data._filterFg
            ? params.data._actual + ` (${params.data.value})`
            : params.data._actual,
        },
      ],
    });
  }

  static getTooltipMultiSeries(params) {
    return NtaiEChartUtil.getTooltipContent({
      title: {
        marker: params.data._color,
        text: params.name,
      },
      values: [
        {
          key: params.seriesName,
          value: params.data._filterFg
            ? params.data._actual + ` (${params.data.value})`
            : params.data._actual,
        },
      ],
    });
  }

  static getHBarLabelFormatter(p) {
    return p["data"]["_filterFg"]
      ? NtaiUtils.abbrevNumber(p["data"]["_actual"]).toString() +
          " " +
          "(" +
          NtaiUtils.abbrevNumber(p["data"]["value"]).toString() +
          ")"
      : NtaiUtils.abbrevNumber(p["data"]["value"]).toString();
  }

  static getVBarLabelFormatter(p) {
    return NtaiUtils.abbrevNumber(p["data"]["_actual"]).toString();
  }
}

export default NtaiBarEChartUtil;
