import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceFields,
  clearStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { setActiveId } from "./store/alertsSlice";
import AlertEditHeader from "./header/AlertEditHeader";
import AlertRecordList from "./record/AlertRecordList";
import {
  getSourceRecordListings,
  clearStatus as clearSourceRecordListingsStatus,
} from "app/main/pages/core/sourcedefinition/source/template/listing/store/sourceRecordListingsSlice";
import AlertRecordList1 from "./record/AlertRecordList1";
const _ = require("lodash");

export const AlertEditContext = React.createContext();

export default function AlertEdit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const gUuId = props.match.params.guuid;

  const [sourceFieldsRetrieved, setSourceFieldsRetrieved] = useState(false);
  const [sourceListingsRetrieved, setSourceListingsRetrieved] = useState(false);
  const [expandSidePanel, setExpandSidePanel] = useState(false);
  const [sidePanelContext, setSidePanelContext] = useState();
  const [selectedRecordId, setSelectedRecordId] = useState();
  const [alertData, setAlertData] = useState();
  const [alertId, setAlertId] = useState();
  const [alertMasterId, setAlertMasterId] = useState();
  const [activityId, setActivityId] = useState();
  const [sourceId, setSourceId] = useState();
  const [sourceFields, setSourceFields] = useState();
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [refreshAllRecords, setRefreshAllRecords] = useState(0);
  const [refreshSelectedRecord, setRefreshSelectedRecord] = useState(0);

  const alertsDataMap = useSelector((state) => state.monitor.alertsSlice.data);

  const sourceFieldsData = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceRecordListingsStatus = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.status
  );

  useEffect(() => {
    if (
      sourceRecordListingsStatus &&
      sourceRecordListingsStatus.method === "getSourceRecordListings" &&
      sourceRecordListingsStatus.result === "success"
    ) {
      setSourceListingsRetrieved(true);
      dispatch(clearSourceRecordListingsStatus());
    }
  }, [sourceRecordListingsStatus]);

  useEffect(() => {
    if (
      sourceStatus &&
      sourceStatus.method === "getSourceFields" &&
      sourceStatus.result === "success"
    ) {
      setSourceFields({ ...sourceFieldsData });
      setSourceFieldsRetrieved(true);
      dispatch(clearStatus());
    }
  }, [sourceStatus]);

  useEffect(() => {
    const alertData = _.get(alertsDataMap, gUuId);
    const alertId = _.get(alertData, "alertUuId");
    const alertMasterId = _.get(alertData, "alertMasterUuId");
    const activityId = _.get(alertData, "activityUuId");
    const sourceId = _.get(alertData, "sourceUuId");

    setAlertData(alertData);
    setAlertId(alertId);
    setAlertMasterId(alertMasterId);
    setActivityId(activityId);
    setSourceId(sourceId);
    dispatch(setActiveId(gUuId));
    dispatch(getSourceFields({ sourceDefId: "undefined", sourceId: sourceId }));
    dispatch(
      getSourceRecordListings({
        sourceDefId: "*",
        sourceId: sourceId,
      })
    );
  }, [gUuId]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      {alertId &&
        sourceFieldsRetrieved &&
        sourceListingsRetrieved &&
        !_.isEmpty(sourceFields) && (
          <AlertEditContext.Provider
            value={{
              gUuId,
              alertId,
              alertMasterId,
              activityId,
              expandSidePanel,
              setExpandSidePanel,
              sidePanelContext,
              setSidePanelContext,
              selectedRecordId,
              setSelectedRecordId,
              sourceFields,
              alertData,
              selectedRecords,
              setSelectedRecords,
              refreshSelectedRecord,
              setRefreshSelectedRecord,
              refreshAllRecords,
              setRefreshAllRecords,
            }}
          >
            <AlertEditHeader />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                // height: `calc(100% - 54px)`,
              }}
            >
              <AlertRecordList1 />
              {/* <AlertRecordPanel /> */}
            </Box>
          </AlertEditContext.Provider>
        )}
      {(!alertId || !sourceFieldsRetrieved) && (
        <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
          <NtaiCircularProgress size={36} />
        </Box>
      )}
    </Box>
  );
}
