import SourceCustomFieldCreate from "./SourceCustomFieldCreate";
import SourceCustomFieldEdit from "./SourceCustomFieldEdit";
import SourceCustomFieldList from "./SourceCustomFieldList";

const SourceCustomFieldConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/custom-fields",
      exact: true,
      component: SourceCustomFieldList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/custom-fields/create",
      exact: true,
      component: SourceCustomFieldCreate,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/custom-fields/:id/edit",
      exact: true,
      component: SourceCustomFieldEdit,
    },
  ],
};

export default SourceCustomFieldConfig;
