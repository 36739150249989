import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

function generateColors(color) {
  let colors = [];

  for (let i = 0; i < 100; i++) {
    const colRand = NtaiUtils.getRandomFloat(0, 0.5);
    colors.push(am4core.color(color).lighten(colRand));
  }

  return colors;
}

export default function NtaiSankeyChart(props) {
  const {
    widgetId,
    data,
    fromField,
    toField,
    valueField,
    handleClick,
    metadata,
  } = props;
  const theme = useTheme();

  useLayoutEffect(() => {
    let chart = am4core.create(
      "sankeychart".concat("-", widgetId),
      am4charts.SankeyDiagram
    );
    chart.data = data;

    chart.dataFields.fromName = fromField ? fromField : "from";
    chart.dataFields.toName = toField ? toField : "to";
    chart.dataFields.value = valueField ? valueField : "value";

    // for right-most label to fit
    chart.paddingRight = 100;

    // make nodes draggable
    let nodeTemplate = chart.nodes.template;
    nodeTemplate.draggable = true;
    nodeTemplate.inert = true;
    nodeTemplate.readerTitle = "Drag to rearrange or click to show&hide";
    nodeTemplate.showSystemTooltip = true;
    nodeTemplate.width = 8;
    nodeTemplate.fontSize = 11;

    // const colorSet = new am4core.ColorSet();
    // colorSet.list = generateColors(am4core.color(theme.palette.primary.main));
    // nodeTemplate.colorSet = colorSet;

    let linkTemplate = chart.links.template;
    linkTemplate.fillOpacity = 0;
    linkTemplate.middleLine.strokeOpacity = 0.3;
    linkTemplate.middleLine.stroke = am4core.color("#555");
    linkTemplate.middleLine.strokeWidth = 1;

    let defaultState = nodeTemplate.background.defaultState;
    let defaultDropShadow = defaultState.filters.push(
      new am4core.DropShadowFilter()
    );
    defaultDropShadow.opacity = 0;

    let hoverState = nodeTemplate.background.states.create("hover");
    let hoverDropShadow = hoverState.filters.push(
      new am4core.DropShadowFilter()
    );
    hoverDropShadow.dy = 0;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"sankeychart".concat("-", widgetId)}
      sx={{ width: "100%", height: "100%", px: theme.spacing(1) }}
    ></Box>
  );
}
