import { Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import React from "react";
import TestLoginForm from "./TestLoginForm";

export default function Tests() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "50%",
          justifyContent: "space-between",
          width: "70%",
          alignItems: "center",
          gap: theme.spacing(10),
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexBasis: "60%",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography fontSize="44px">Welcome to NextTrial AI</Typography>
              <Divider />
              <Typography
                sx={{ pt: theme.spacing(1) }}
                fontSize="20px"
              ></Typography>
              <Typography fontSize="14px">
                NextTrial AI offers a collaborative, AI-powered, cloud-based
                smart data platform with the goal of accelerating clinical trial
                research and bringing innovative medicines to patients faster.
                It achieves this by putting relevant, process-oriented,
                insight-rich data in the hands of sponsors, CROs, research
                sites, physicians and other stakeholders for critical scientific
                research, improving operational efficiency, informed decision
                making and gaining complete trial oversight from inception to
                commercialization.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
              }}
            >
              <Chip color="primary" label="Collaborative" />
              <Chip color="primary" variant="outlined" label="Cloud-based" />
              <Chip color="secondary" variant="outlined" label="AI powered" />
              <Chip label="Go Next" />
            </Box>
          </Box>
        </Box>
        <Box sx={{ flexBasis: "40%" }}>
          <NtaiForm>
            <TestLoginForm />
          </NtaiForm>
        </Box>
      </Box>
    </Box>
  );
}
