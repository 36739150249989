import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
const _ = require("lodash");

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      zIndex: 0,
    },
  },
};

const MuiMultipleSelect = (props) => {
  const theme = useTheme();
  const [itemName, setItemName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItemName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const { label, name, options } = props;

  const optionsObj = _.mapKeys(options, "value");

  return (
    <FormControl>
      <InputLabel size="small" htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        id={name}
        multiple
        size="small"
        // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                sx={{ fontSize: 12 }}
                size="small"
                key={value}
                label={_.get(_.get(optionsObj, value), "label")}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        {...props}
      >
        {options.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

function NtaiMuiMultipleSelectField(props) {
  const { control } = useFormContext();
  const { name, label, options } = props;

  function generateValue(val) {
    console.log("Val: ", val);
    return val;
  }
  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MuiMultipleSelect
            onChange={onChange}
            {...props}
            value={Array.isArray(value) ? value : []}
          />
        )}
        control={control}
        name={name}
        label={label}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiMuiMultipleSelectField;
