import NtaiUtils from "@ntai/utils/NtaiUtils";
import { ReportContext } from "../ReportMain";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, getReport, runReport } from "../../store/reportsSlice";
import { useState } from "react";
const _ = require("lodash");

export default function ReportViewer2(props) {
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const [loaded, setLoaded] = useState(false);
  const [pending, setPending] = useState(false);
  const [base64ReportString, setBase64ReportString] = useState();

  const { action, reportId, generateReportCounter, reportFormData } =
    React.useContext(ReportContext);

  const dataMap = useSelector((state) => state.reports.reportsSlice.data);

  const status = useSelector((state) => state.reports.reportsSlice.status);

  useEffect(() => {
    if (status && ["getReport", "runReport"].includes(status.method)) {
      if (status.result === "success") {
        const tmpReportId = action === "add" ? "*" : reportId;
        setBase64ReportString(
          _.get(dataMap[tmpReportId], "base64ReportDocument")
        );
        setLoaded(true);
        setPending(false);
        dispatch(clearStatus());
      }
    }
  }, [status]);

  // useEffect(() => {
  //   const tmpReportId = action === "add" ? "*" : reportId;
  //   setBase64ReportString(_.get(dataMap[tmpReportId], "base64ReportDocument"));
  //   setLoaded(true);
  // }, [reportId]);

  useEffect(() => {
    if (
      generateReportCounter !== 0 &&
      reportFormData &&
      !_.isEmpty(reportFormData)
    ) {
      dispatch(
        runReport({
          groupId: "*",
          uuId: action === "add" ? "*" : reportId,
          formData: reportFormData,
        })
      );
    }
  }, [generateReportCounter, reportFormData]);

  useEffect(() => {
    if (action === "edit") {
      dispatch(
        getReport({
          groupId: "*",
          uuId: reportId,
        })
      );
    }
  }, [reportId]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {status &&
        status.result !== "pending" &&
        loaded &&
        base64ReportString &&
        base64ReportString.length > 0 && (
          <embed
            src={`data:application/pdf;base64,${base64ReportString}`}
            width="100%"
            height="100%"
          />
        )}
      {!loaded &&
        status &&
        status.result !== "pending" &&
        ((base64ReportString && base64ReportString.length === 0) ||
          base64ReportString === undefined) && (
          <NtaiEmptyMessage
            vAlign="center"
            header="Enter report criteria"
            subheader="Enter or select report criteria to generate report"
          />
        )}
      {!loaded && action === "edit" && <NtaiCircularProgress size={24} />}
      {status.method === "runReport" && status.result === "pending" && (
        <NtaiCircularProgress size={24} />
      )}
      {status && status.method === "runReport" && status.result === "error" && (
        <NtaiEmptyMessage
          header="Error generating report"
          subheader={status.message}
        />
      )}
    </Box>
  );
}
