import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getEntityTopics = createAsyncThunk(
  "entityTopics/getEntityTopics",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, params } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/topics`,
        params
          ? {
              params: params,
            }
          : null
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEntityTopic = createAsyncThunk(
  "entityTopics/getEntityTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/topics/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createEntityTopic = createAsyncThunk(
  "entityTopics/createEntityTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/topics`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEntityTopic = createAsyncThunk(
  "entityTopics/updateEntityTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/topics/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEntityTopic = createAsyncThunk(
  "entityTopics/deleteEntityTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, dataId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${dataId}/topics/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const entityTopicsSlice = createSlice({
  name: "entityTopics",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getEntityTopics.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getEntityTopics" };
    },
    [getEntityTopics.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getEntityTopics",
        message: action.payload.message,
      };
    },
    [getEntityTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getEntityTopic" };
    },
    [getEntityTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getEntityTopic",
        message: action.payload.message,
      };
    },
    [createEntityTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createEntityTopic" };
    },
    [createEntityTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createEntityTopic",
        message: action.payload.message,
      };
    },
    [updateEntityTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateEntityTopic" };
    },
    [updateEntityTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateEntityTopic",
        message: action.payload.message,
      };
    },
    [deleteEntityTopic.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteEntityTopic" };
    },
    [deleteEntityTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteEntityTopic",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = entityTopicsSlice.actions;

export default entityTopicsSlice.reducer;
