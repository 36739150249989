import {
  faBarsProgress,
  faChartGantt,
  faChartLine,
  faChartMixed,
  faChartNetwork,
  faChartPie,
  faChartScatter,
  faChartScatterBubble,
  faChartSimple,
  faChartSimpleHorizontal as faChartSimpleHorizontalLight,
  faChartTreeMap,
  faCircleNodes,
  faCloudWord,
  faDiagramSankey,
  faGlobe,
  faHashtag,
  faInputNumeric,
  faInputText,
  faMap,
  faPieChart,
  faSitemap,
  faTable,
  faTablePivot,
} from "@fortawesome/pro-light-svg-icons";
import { faChartSimpleHorizontal as faChartSimpleHorizontalSolid } from "@fortawesome/pro-solid-svg-icons";

const NtaiChartIcons = {
  "bar horizontal": faChartSimpleHorizontalLight,
  "bar vertical": faChartSimple,
  "bar horizontal stacked": faBarsProgress,
  "bar vertical stacked": faBarsProgress,
  "bar horizontal series": faChartSimpleHorizontalLight,
  "bar vertical series": faChartSimple,
  "line series": faChartLine,
  "time series": faChartLine,
  pie: faChartPie,
  dounut: faChartPie,
  treemap: faChartTreeMap,
  heatmap: faChartTreeMap,
  "force directed": faChartNetwork,
  gantt: faChartGantt,
  geo: faGlobe,
  "linear tree": faSitemap,
  bubble: faChartScatterBubble,
  "data table i": faTable,
  "data table ii": faTable,
  "packed circle": faChartScatterBubble,
  "radial tree": faSitemap,
  "pivot table": faTablePivot,
  chord: faCircleNodes,
  "scatter plot": faChartScatter,
  "word cloud": faCloudWord,
  "sankey plot": faDiagramSankey,
  donut: faPieChart,
  "summary tabulation": faTable,
  "bar with line": faChartMixed,
  "date with line": faChartMixed,
  "metric (aggregate)": faHashtag,
  "metric (scripted)": faHashtag,
  "date histogram": faChartMixed,
};

export default NtaiChartIcons;
