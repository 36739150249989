import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableRow,
  Button,
  useTheme,
  Typography,
  IconButton,
} from "@mui/material";

import history from "@ntai/@history";
import { makeStyles } from "@mui/styles";
import NtaiSimpleTableHelpText from "./NtaiReadOnlyTableHelpText";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { MoreVert } from "@mui/icons-material";
import NtaiMenu from "@ntai/components/menus/NtaiDecoMenu";
import NtaiReadOnlyTableSearchbar from "./NtaiReadOnlyTableSearchbar";
import NtaiReadOnlyTableHead from "./NtaiReadOnlyTableHead";

const _ = require("lodash");

// add comment for demo
const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function NtaiReadOnlyTable(props) {
  const classes = useStyles();
  const {
    helpText,
    rows,
    emptyRowsContent,
    headCells,
    keyColumn,
    title,
    toolbarStaticActions,
    toolbarActions,
    handleToolbarAction,
    toolbarOtherActions,
    handleToolbarOtherAction,
    rowActions,
    handleRowAction,
    rowOtherActions,
    handleRowOtherAction,
    handleCellAction,
    selectedIds,
    handleRowClick,
    path,
    dialog,
    pageSize,
  } = props;

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openRowOtherActionsMenu = Boolean(anchorEl);
  const [rowOtherActionId, setRowOtherActionId] = useState(null);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSize ? pageSize : 5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function handleSearch(txt) {}

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function onHandleDialogAddEdit(e, name) {
    if (!["checkbox", "button", "menu-button"].includes(e.target.id)) {
      props.handleDialogAddEdit(name);
    }
  }

  function formatCellData(row, col) {
    if (
      _.has(col, "transformFunc") &&
      typeof _.get(col, "transformFunc") === "function"
    ) {
      // let colValues = [];
      // col.id.forEach((colId) => {
      //   colValues.push(col[colId]);
      // });

      if (Array.isArray(_.get(col, "id"))) {
        let colValues = [];
        _.get(col, "id").forEach((colId) => {
          colValues.push({ colId: colId, colValue: row[colId] });
        });

        return col.transformFunc(colValues);
      } else {
        return col.transformFunc(_.get(row, col.id));
      }
    } else {
      let cellData = _.get(row, col.id);
      if (_.has(col, "dataType") && col.dataType === "date")
        cellData = NtaiUtils.localDate(cellData);
      return cellData;
    }
  }

  const buildTableData = (row) => {
    let cols = [];
    let count = 0;
    headCells.forEach((col, index) => {
      if (col.ignore === true) return;
      count++;
      cols.push(
        <TableCell
          id="tableCell"
          key={col.id}
          sx={{
            fontWeight: col.fontWeight ? col.fontWeight : null,
            p: "8px",
            verticalAlign: "top",
          }}
        >
          {/* {row[col.id]} */}
          {formatCellData(row, col)}
        </TableCell>
      );
    });

    return cols;
  };

  function onHandleToolbarAction(actionValue) {
    handleToolbarAction(actionValue, selected);
  }

  function onHandleToolbarOtherAction(actionValue) {
    handleToolbarOtherAction(actionValue);
  }

  return (
    <>
      {rows && rows.length == 0 && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(1),
            paddingTop: theme.spacing(2),
          }}
        >
          {emptyRowsContent}
        </Box>
      )}
      {rows && rows.length > 0 && (
        <Box
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {helpText && <NtaiSimpleTableHelpText content={helpText} />}
          <NtaiReadOnlyTableSearchbar
            title={title}
            toolbarStaticActions={toolbarStaticActions}
            actions={toolbarActions}
            otherActions={toolbarOtherActions}
            onHandleToolbarAction={onHandleToolbarAction}
            onHandleToolbarOtherAction={onHandleToolbarOtherAction}
            handleSearch={handleSearch}
          />
          <TableContainer>
            <Table
              sx={{
                border: theme.general.border1,
              }}
            >
              <NtaiReadOnlyTableHead
                numSelected={
                  Array.isArray(selected) && selected.length > 0
                    ? selected.length
                    : 0
                }
                order={order}
                classes={classes}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
                rowActions={rowActions}
                rowOtherActions={rowOtherActions}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row[keyColumn]);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onMouseEnter={(e) => handleHoverOn(row[keyColumn])}
                        // onMouseLeave={(e) => handleHoverOff(row[keyColumn])}
                        onClick={(e) => handleRowClick(row[keyColumn])}
                        key={row[keyColumn]}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer", vAlign: "top" }}
                      >
                        {buildTableData(row)}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows && rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </>
  );
}
