import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchSourceRecordLookups } from "./store/searchSourceRecordLookupsSlice";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";

export const SearchSourceRecordLookupContext = React.createContext();

export default function SearchSourceRecordLookup({ lookupIds }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { lookupLinkId, lookupValue, lookupRecordId } = lookupIds;

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordLookupsSlice.data
  );

  useEffect(() => {
    dispatch(
      getSearchSourceRecordLookups({
        searchId: "*",
        searchSourceId: "*",
        recordId: lookupRecordId,
        lookupLinkId: lookupLinkId,
        params: {
          from: 0,
          size: 25,
          lookupValue: lookupValue,
        },
      })
    );
  }, []);

  return (
    <SearchSourceRecordLookupContext.Provider value={{}}>
      <Box
        sx={{
          display: "flex",
          height: "460px",
        }}
      >
        <Box
          sx={{
            flexBasis: "25%",
            display: "flex",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          {/* <SearchSummaryNavigation /> */}
          nav
        </Box>
        <Box
          sx={{ flexBasis: "75%", display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              p: theme.spacing(1),
              display: "flex",
              height: "48px",
              borderBottom: theme.general.border1,
              alignItems: "center",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h6" fontWeight="700">
              All | Assessed Records (6)
            </Typography>
            <NtaiBadge title="Records (3)" />
            <NtaiBadge title="Annotations (3)" />
          </Box>

          <Box sx={{ height: `calc(100% - 48px)`, display: "flex" }}>
            Records
          </Box>
        </Box>
      </Box>
    </SearchSourceRecordLookupContext.Provider>
  );
}
