import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import ObjectFormBuilderTabs from "./tab/ObjectFormBuilderTabs";
import ObjectFormBuilderPanels from "./panel/ObjectFormBuilderPanels";
import ObjectFormBuilderFields from "./panel/ObjectFormBuilderFields";

export const ObjectFormBuilderContext = React.createContext();

export default function ObjectFormBuilder() {
  const theme = useTheme();
  const [selectedTabId, setSelectedTabId] = useState();
  const [selectedPanelId, setSelectedPanelId] = useState();
  const [refreshPanels, setRefreshPanels] = useState(false);

  return (
    <ObjectFormBuilderContext.Provider
      value={{
        selectedTabId,
        setSelectedTabId,
        selectedPanelId,
        setSelectedPanelId,
        refreshPanels,
        setRefreshPanels,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "480px",
          p: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "25%",
              display: "flex",
              height: "100%",
              borderRight: theme.general.border1,
              flexDirection: "column",
            }}
          >
            <ObjectFormBuilderTabs />
          </Box>
          <Box
            sx={{
              flexBasis: "25%",
              display: "flex",
              height: "100%",
              borderRight: theme.general.border1,
            }}
          >
            <ObjectFormBuilderPanels />
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              borderRight: theme.general.border1,
            }}
          >
            <ObjectFormBuilderFields />
          </Box>
        </Box>
      </Box>
    </ObjectFormBuilderContext.Provider>
  );
}
