import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

export default function NtaiBarChart(props) {
  const theme = useTheme();
  const {
    widgetId,
    data,
    metadata,
    categoryField,
    valueField,
    handleClick,
    height,
    width,
  } = props;

  console.log(
    "BAR Width and height: ",
    categoryField,
    valueField,
    data,
    metadata,
    width,
    height
  );
  useLayoutEffect(() => {
    let chart = am4core.create(
      "barchart".concat("-", widgetId),
      am4charts.XYChart
    );

    chart.data = data;
    chart.maskBullets = false;
    chart.paddingLeft = 50;
    chart.numberFormatter.numberFormat = "#.0a";

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = categoryField;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0.5;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.line.strokeOpacity = 0;
    categoryAxis.renderer.line.strokeWidth = 0.1;
    categoryAxis.renderer.line.stroke = am4core.color("#eee");

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeWidth = 0;
    valueAxis.renderer.labels.template.disabled = true;

    let series = chart.series.push(new am4charts.ColumnSeries());

    series.dataFields.valueX = valueField;
    series.dataFields.categoryY = categoryField;
    series.stroke = am4core.color("#CDA2AB");

    categoryAxis.sortBySeries = series;

    // tooltip
    series.tooltip.getFillFromObject = false;
    series.tooltip.autoTextColor = false;
    series.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    series.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    series.tooltip.background.fillOpacity = tooltipConfig.backgroundFillOpacity;
    series.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    series.tooltip.label.fontSize = tooltipConfig.labelFontSize;
    series.columns.template.tooltipText = "{categoryY}\n{valueX}";

    let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
    categoryLabel.label.text = `{${categoryField}}`;
    categoryLabel.label.fontSize = 11;
    categoryLabel.valign = "middle";
    categoryLabel.label.horizontalCenter = "left";
    categoryLabel.label.dx = 8;
    categoryLabel.locationX = 1;
    categoryLabel.label.fill = am4core.color("#404040");
    categoryLabel.label.truncate = false;
    categoryLabel.label.hideOversized = false;

    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = `{${valueField}}`;
    valueLabel.label.fontSize = 11;
    valueLabel.label.horizontalCenter = "right";
    valueLabel.valign = "middle";
    valueLabel.label.dx = -10;
    valueLabel.locationX = 1;
    valueLabel.label.fill = am4core.color("#808080");

    series.columns.template.fill = am4core.color(
      lighten(theme.colors.chart.light, 0.4)
    ); //am4core.color("#92DFF3"); //gradient; // FF7276 // blue: 92DFF3
    series.columns.template.width = am4core.percent(10);
    series.columns.template.strokeWidth = 0;
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    let hoverState = series.columns.template.states.create("hover");
    hoverState.properties.fill = lighten(theme.colors.chart.lighter, 0.2);
    hoverState.properties.fillOpacity = 0.8;

    let cellSize = 30;
    chart.events.on("datavalidated", function (ev) {
      // Get objects of interest
      let chart = ev.target;
      let categoryAxis = chart.yAxes.getIndex(0);

      // Calculate how we need to adjust chart height
      let adjustHeight =
        chart.data.length * cellSize - categoryAxis.pixelHeight;

      // get current chart height
      let targetHeight = chart.pixelHeight + adjustHeight;

      // Set it on chart's container
      chart.svgContainer.htmlElement.style.height = targetHeight + "px";
    });

    series.columns.template.events.on(
      "hit",
      function (ev) {
        const sourceWidgetFilterFields = [
          {
            sourceFieldUuId: Object.keys(_.get(metadata, "fields"))[0],
            fieldOperatorCode: 1,
            fieldValue: _.get(ev.target.dataItem.dataContext, categoryField),
          },
        ];
        //console.log("sourceWidgetFilterFields: ", sourceWidgetFilterFields);
        handleClick(sourceWidgetFilterFields, ev);
      },
      this
    );

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Scrollbar autoHide={true}>
      <Box
        id={"barchart".concat("-", widgetId)}
        sx={{ display: "flex", width: width, height: height }}
      ></Box>
    </Scrollbar>
  );
}
