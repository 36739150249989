import React from "react";
import NtaiActionsPanel from "./NtaiActionsPanel";
import { Avatar, Box, Button, useTheme } from "@mui/material";
import NtaiEmptyPanel from "./NtaiEmptyPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import NtaiPanel from "./NtaiPanel";

export default function NtaiTestPanel() {
  const theme = useTheme();

  function handleHeaderAction(action, id) {
    console.log("action and id: ", action, id);
  }

  function handleAction(action, id) {
    console.log("action and id: ", action, id);
  }

  return (
    <Box
      sx={{
        margin: "8px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "4px",
      }}
    >
      <NtaiEmptyPanel border width="320px" height="auto">
        <Box
          sx={{
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="2x" />I am EmptyPanel
        </Box>
      </NtaiEmptyPanel>
      <NtaiPanel
        title="Manage Users"
        subheader="Enter user details for many"
        border
        actions
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          I am NtaiPanel
        </Box>
      </NtaiPanel>
      <NtaiPanel
        title="Another Panel"
        subheader="Enter user details for many"
        border
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          I am NtaiPanel without actions
        </Box>
      </NtaiPanel>

      <NtaiActionsPanel
        id="123"
        title="Another Panel"
        subheader="Some description for me"
        border
        width="320px"
        headerActions={[
          { label: "Archive", value: "archive", icon: "archive" },
        ]}
        handleHeaderAction={handleHeaderAction}
        actions={[
          { label: "Manage Configuration", value: "manage" },
          { label: "Delete", value: "manage" },
        ]}
        handleAction={handleAction}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          I am NtaiActionsPanel
        </Box>
      </NtaiActionsPanel>

      <Box
        sx={{
          p: theme.spacing(2),
          height: "100px",
          width: "100px",
          border: theme.general.border1,
          boxShadow: "inset 1px 2px 4px 0 rgb(0 0 0 / 5%)",
        }}
      >
        Lg Shadow in me
      </Box>
    </Box>
  );
}
