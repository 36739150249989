import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordNotes = createAsyncThunk(
  "searchSourceRecordNotes/getSearchSourceRecordNotes",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.put(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/notes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordNote = createAsyncThunk(
  "searchSourceRecordNotes/getSearchSourceRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/notes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecordNote = createAsyncThunk(
  "searchSourceRecordNotes/createSearchSourceRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/notes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecordNote = createAsyncThunk(
  "searchSourceRecordNotes/updateSearchSourceRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId, formData } =
        values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/notes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceRecordNote = createAsyncThunk(
  "searchSourceRecordNotes/deleteSearchSourceRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/notes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordNotesSlice = createSlice({
  name: "searchSourceRecordNotes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, note) => {
      state.status = {};
    },
    setActiveId: (state, note) => {
      state.activeId = note.payload;
    },
  },
  extraReducers: {
    [getSearchSourceRecordNotes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "note.uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordNotes",
      };
    },
    [getSearchSourceRecordNotes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordNotes",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordNote.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.note.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordNote",
      };
    },
    [getSearchSourceRecordNote.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordNote",
        message: action.payload,
      };
    },
    [createSearchSourceRecordNote.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.note.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceRecordNote",
      };
    },
    [createSearchSourceRecordNote.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecordNote",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecordNote.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.note.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceRecordNote",
      };
    },
    [updateSearchSourceRecordNote.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecordNote",
        message: action.payload.message,
      };
    },
    [deleteSearchSourceRecordNote.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceRecordNote",
      };
    },
    [deleteSearchSourceRecordNote.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceRecordNote",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceRecordNotesSlice.actions;

export default searchSourceRecordNotesSlice.reducer;
