import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import WorkflowDefVersionActivityList from "./activities/WorkflowDefVersionActivityList";
import { useSnackbar } from "notistack";
import WorkflowDefVersionVis from "./activities/bpmn-vis/WorkflowDefVersionVis";
import WorkflowDefVersionUpload from "./WorkflowDefVersionUpload";
import { getObjects } from "../../../cdo/object/store/objectsSlice";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiMultiSelectField from "@ntai/components/inputs/NtaiMultiSelectField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function WorkflowDefVersionForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [processDefinitionFileFormData, setProcessDefinitionFileFormData] =
    useState();

  const assessmentFormDataMap = useSelector(
    (state) => state.core.objectsSlice.data
  );

  const assessmentFormData =
    assessmentFormDataMap && !_.isEmpty(assessmentFormDataMap)
      ? Object.values(assessmentFormDataMap)
      : [];

  const assessmentFormOptions = NtaiUtils.getSelectOptions(
    assessmentFormData,
    "uuId",
    "label"
  );

  function handleFileUpload(uploadFormData, config) {
    setProcessDefinitionFileFormData({
      formData: uploadFormData,
      config: config,
    });
  }

  function onHandleSave() {
    const workflowDefVersionFormData = NtaiUtils.sanitizeFormData(
      getValues("workflowDefVersionForm")
    );

    let formError = false;

    if (
      isNaN(_.get(workflowDefVersionFormData, "totalDuration")) ||
      workflowDefVersionFormData.totalDuration === "undefined"
    ) {
      formError = true;
      enqueueSnackbar("Total duration is required", {
        variant: "error",
      });
    }

    if (action === "add" && !processDefinitionFileFormData) {
      formError = true;
      enqueueSnackbar(
        "Process definiton file is required. Please upload JBPMN v2.0 compliant XML file.",
        {
          variant: "error",
        }
      );
    }

    let revisedFormData = {};

    if (!formError && _.isEmpty(errors)) {
      let config = null;

      const minData = {
        totalDuration: workflowDefVersionFormData.totalDuration,
        activeFg: workflowDefVersionFormData.activeFg,
        adjObjectUuIds: workflowDefVersionFormData.adjObjectUuIds,
      };

      if (processDefinitionFileFormData) {
        revisedFormData["config"] = processDefinitionFileFormData["config"];
        revisedFormData = _.cloneDeep(processDefinitionFileFormData);
        revisedFormData["formData"].append(
          "versionDetails",
          JSON.stringify(minData)
        );
      } else {
        let fd = new FormData();
        fd.append("versionDetails", JSON.stringify(minData));
        revisedFormData["formData"] = fd;
        revisedFormData["config"] = config;

        // Display the key/value pairs
        // for (var pair of fd.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
      }

      handleSave(revisedFormData["formData"], revisedFormData["config"]);
    }
  }

  function displayUploadedFile() {
    if (formData && _.get(formData, "fileName"))
      return (
        <NtaiLabelValue
          label="Uploaded File"
          value={_.get(formData, "fileName")}
        />
      );
  }

  useEffect(() => {
    dispatch(
      getObjects({
        domainId: "*",
        params: {
          categoryCode: 3,
        },
      })
    );

    reset({ workflowDefVersionForm: action === "edit" ? formData : null });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          height: "100%",
        }}
        label="GENERAL"
      >
        <Box
          sx={{
            display: "flex",
            flexBasis: "50%",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            borderLeft: theme.general.border1,
          }}
        >
          <NtaiFormPanel
            width="75%"
            header="Step 1: Enter general details"
            subheader="Enter general details about workflow version"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(2),
              }}
            >
              <NtaiTextField
                name="workflowDefVersionForm.totalDuration"
                label="Total Duration (Days)"
                type="number"
              />

              <NtaiMultiSelectField
                name="workflowDefVersionForm.adjObjectUuIds"
                label="Assessment Forms"
                options={
                  _.isArray(assessmentFormOptions) ? assessmentFormOptions : []
                }
              />
              <NtaiSwitchField
                name="workflowDefVersionForm.activeFg"
                label="Active?"
              />
            </Box>
          </NtaiFormPanel>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexBasis: "50%",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <NtaiPanel
            width="100%"
            header="Step 2: Upload process definition file"
            subheader="Upload JBPMN v2.0 compliant process definition XMl file"
          >
            <NtaiFileUpload handleFileUpload={handleFileUpload} />
            {displayUploadedFile()}
          </NtaiPanel>
        </Box>
      </Box>
      <Box disabled={action === "add" ? true : false} label="ACTIVITIES">
        <WorkflowDefVersionActivityList />
      </Box>
      <Box disabled={action === "add" ? true : false} label="MODELER">
        <WorkflowDefVersionVis />
      </Box>
    </NtaiTabs>
  );
}
