import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiTextArea from "@ntai/components/inputs/NtaiTextArea";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function SourceInStoreOcrFieldEdit({
  open,
  formData,
  handleFormSubmit,
  handleDialogClose,
}) {
  const { getValues, reset, setValue } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = getValues("sourceInStoreOcrFieldForm");
    handleFormSubmit(formData["uuId"], sanitizedFormData);
  }

  useEffect(() => {
    reset({ sourceInStoreOcrFieldForm: formData });
  }, []);

  return (
    <NtaiDialog
      title="Field Dialog"
      size="sm"
      open={open}
      handleDialogClose={handleDialogClose}
    >
      <NtaiDialogFormPanel
        subheader="Edit the field form details"
        handleSave={onHandleSave}
        handleCancel={handleDialogClose}
      >
        <NtaiTextField
          name="sourceInStoreOcrFieldForm.fieldName"
          label="Name"
        />
        <NtaiTextField
          name="sourceInStoreOcrFieldForm.description"
          label="Description"
          multiline
          minRows={3}
          maxRows={3}
        />
      </NtaiDialogFormPanel>
    </NtaiDialog>
  );
}
