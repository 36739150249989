import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDatasources = createAsyncThunk(
  "datasources/getDatasources",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/datasources");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDatasource = createAsyncThunk(
  "datasources/getDatasource",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/datasources/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDatasource = createAsyncThunk(
  "datasources/createDatasource",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/datasources", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDatasource = createAsyncThunk(
  "datasources/updateDatasource",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/datasources/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDatasource = createAsyncThunk(
  "datasources/deleteDatasource",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/datasources/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const testDatasourceConnection = createAsyncThunk(
  "datasources/testDatasourceConnection",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/datasources/${uuId}/test`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const datasourcesSlice = createSlice({
  name: "datasources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDatasources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getDatasources" };
    },
    [getDatasources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDatasources",
        message: action.payload.message,
      };
    },
    [getDatasource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getDatasource" };
    },
    [getDatasource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDatasource",
        message: action.payload.message,
      };
    },
    [createDatasource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createDatasource" };
    },
    [createDatasource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createDatasource",
        message: action.payload.message,
      };
    },
    [updateDatasource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateDatasource" };
    },
    [updateDatasource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateDatasource",
        message: action.payload.message,
      };
    },
    [deleteDatasource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteDatasource" };
    },
    [deleteDatasource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteDatasource",
        message: action.payload.message,
      };
    },

    [testDatasourceConnection.pending]: (state, action) => {
      state.status = { result: "pending", method: "testDatasourceConnection" };
    },

    [testDatasourceConnection.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "testDatasourceConnection" };
    },
    [testDatasourceConnection.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "testDatasourceConnection",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = datasourcesSlice.actions;

export default datasourcesSlice.reducer;
