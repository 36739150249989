import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

export default function AlertTrackerInfo(props) {
  const dispatch = useDispatch();

  const alertId = props.match.params.id;

  const dataMap = useSelector((state) => state.monitor.alertTrackerSlice.data);
  const data = dataMap && !_.isEmpty(dataMap) ? dataMap[alertId] : null;

  return (
    <NtaiPage
      title={`Alert Tracker / ${data["name"]}`}
      back="/monitor/tracker"
    >
      <NtaiPanel
        header={data["name"]}
        subheader={data["description"]}
        width="100%"
      >
        <NtaiLabelValue1
          label="Date Created"
          value={NtaiUtils.formatDateTimeCol(data["dateCreated"])}
        />
        <NtaiLabelValue1
          label="Date Modified"
          value={NtaiUtils.formatDateTimeCol(data["dateModified"])}
        />
        <NtaiLabelValue1
          label="Alert Generation Status"
          value={data["runStatusDecode"]}
        />
        <NtaiLabelValue1 label="Message" value={data["message"]} />

        <NtaiLabelValue1
          label="# Entities"
          value={data["alertMasters"].length}
        />

        <NtaiLabelValue1
          label="Topics"
          value={[
            ...new Set(
              data["alertMasters"].map((o) => {
                return o;
              })
            ),
          ].join(", ")}
        />

        {/* <NtaiLabelValue1
          label="# Records"
          value={_.sum(
            data["alertMasters"].map((o) => {
              return o["alertRecords"].length;
            })
          )}
        /> */}
      </NtaiPanel>
    </NtaiPage>
  );
}
