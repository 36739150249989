import { useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getSourceDerivedFields } from "../../../derivedfield/store/sourceDerivedFieldsSlice";

export default function SourceRecordLabelSecondaryForm(props) {
  const theme = useTheme();
  const { action, formData, handleFormSubmit, handleDialogClose } = props;
  const dispatch = useDispatch();

  const { getValues, reset } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const data = Object.values(dataMap);

  const fieldOptions = NtaiUtils.getSelectOptions(data, "uuId", "name");

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceRecordSecondaryLabelForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({
      sourceRecordSecondaryLabelForm: action === "edit" ? formData : {},
    });
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <NtaiSelectField
        name="sourceRecordSecondaryLabelForm.sourceDerivedFieldUuId"
        label="Select Field"
        options={fieldOptions}
        rules={{ required: "Field is required" }}
      />

      <NtaiTextField
        type="number"
        name="sourceRecordSecondaryLabelForm.maxLength"
        label="Maximum Length"
        placeholder="Maximum Length"
      />

      <NtaiSwitchField
        name="sourceRecordSecondaryLabelForm.includeFieldLabelFg"
        label="Include Field Label?"
      />
    </NtaiDialogFormPanel>
  );
}
