import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FunctionEditor from "./FunctionEditor";
import { useSnackbar } from "notistack";

import {
  updateFunction,
  setActiveId,
  runFunction,
  clearStatus,
} from "./store/functionsSlice";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import FunctionAwsSessionDialog from "./session/FunctionAwsSessionDialog";

const _ = require("lodash");

export const FunctionEditorContext = React.createContext();

export default function FunctionEdit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [functionFormDialogOpen, setFunctionFormDialogOpen] = useState(false);
  const [functionRunInProgress, setFunctionRunInProgress] = useState(false);
  const [functionSaveInProgress, setFunctionSaveInProgress] = useState(false);

  const status = useSelector((state) => state.models.functionsSlice.status);
  const dataMap = useSelector((state) => state.models.functionsSlice.data);
  const activeId = useSelector((state) => state.models.functionsSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);
  const [showSettingsPanel, setShowSettingsPanel] = React.useState(true);

  const [functionCode, setFunctionCode] = React.useState(
    _.get(formData, "body")
  );

  const [queryCode, setQueryCode] = React.useState(_.get(formData, "query"));

  const awsEnv = formData["awsEnv"];
  const awsSession = formData["activeSession"];

  const action = "edit";

  function handleFunctionFormDialogOpen() {
    setFunctionFormDialogOpen(true);
  }

  function handleFunctionFormDialogClose() {
    setFunctionFormDialogOpen(false);
  }

  function handleSave(sanitizedFormData) {
    dispatch(updateFunction({ uuId: id, formData: sanitizedFormData }));
  }

  function handleRun(sanitizedFormData) {
    dispatch(runFunction({ uuId: id, formData: sanitizedFormData }));
  }

  useEffect(() => {
    if (
      status &&
      ["runFunction"].includes(status.method) &&
      status.result === "pending"
    ) {
      setFunctionRunInProgress(true);
    }
    if (
      status &&
      status.method === "runFunction" &&
      status.result !== "pending"
    ) {
      setFunctionRunInProgress(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      [
        "updateFunction",
        "updateFunctionReturnType",
        "updateFunctionCode",
        "updateFunctionDataset",
      ].includes(status.method) &&
      status.result === "pending"
    ) {
      setFunctionSaveInProgress(true);
    }

    if (
      status &&
      [
        "updateFunction",
        "updateFunctionReturnType",
        "updateFunctionCode",
        "updateFunctionDataset",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Function "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );

      setFunctionSaveInProgress(false);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <FunctionEditorContext.Provider
      value={{
        id,
        action,
        functionFormDialogOpen,
        handleFunctionFormDialogClose,
        formData,
        handleSave,
        handleRun,
        functionCode,
        queryCode,
        setFunctionCode,
        setQueryCode,
        showSettingsPanel,
        awsEnv,
        awsSession,
        functionRunInProgress,
        setFunctionRunInProgress,
        functionSaveInProgress,
        setFunctionSaveInProgress,
      }}
    >
      <NtaiPage
        title={`Functions / Edit ${_.get(formData, "name")}`}
        back="/models/functions"
        titleExtras={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <IconButton
              size="small"
              onClick={() => handleFunctionFormDialogOpen()}
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
            {awsEnv && formData["s3PathExistsFg"] === 0 && (
              <Chip
                size="small"
                label="Function out of sync. Click save to sync it."
                color="error"
              />
            )}
          </Box>
        }
        headerActions={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
              justifyContent: "end",
              width: "100%",
            }}
          >
            <FunctionAwsSessionDialog />
            <Button
              sx={{ fontSize: "11px" }}
              size="small"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faCog} />}
              onClick={() => setShowSettingsPanel(!showSettingsPanel)}
            >
              {showSettingsPanel ? "HIDE" : "SHOW"} PANEL
            </Button>
            {/* <Button
              sx={{ fontSize: "11px" }}
              size="small"
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            >
              DEPLOY
            </Button> */}
          </Box>
        }
      >
        <NtaiForm>
          <FunctionEditor />
        </NtaiForm>
      </NtaiPage>
    </FunctionEditorContext.Provider>
  );
}
