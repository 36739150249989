import React, { useEffect, useRef, useState } from "react";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiPackedCircleChart from "@ntai/components/charts/amcharts/NtaiPackedCircleChart";
import useDimensions from "react-cool-dimensions";
import { Box, useTheme } from "@mui/material";
import NtaiDataGrid from "@ntai/components/tables/aggrid/NtaiDataGrid";
import NtaiMapChart3 from "@ntai/components/charts/maps/NtaiMapChart3";
import NtaiSankeyChart from "@ntai/components/charts/amcharts/NtaiSankeyChart";
import NtaiXTab from "@ntai/components/tables/xtab/NtaiXTab";
import NtaiWordCloudChart from "@ntai/components/charts/amcharts/v2/NtaiWordCloudChart";
import NtaiMuiDataGridWidget from "@ntai/components/grid/mui/NtaiMuiDataGridWidget";
import NtaiHorizontalBarEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarEChart";
import NtaiHorizontalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarSeriesEChart";
import NtaiHorizontalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarStackedEChart";
import NtaiVerticalBarEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarEChart";
import NtaiVerticalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarSeriesEChart";
import NtaiVerticalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarStackedEChart";
import NtaiPieEChart from "@ntai/components/charts/echarts/pie/NtaiPieEChart";
import NtaiDonutEChart from "@ntai/components/charts/echarts/pie/NtaiDonutEChart";
import NtaiDateHistogramEChart from "@ntai/components/charts/echarts/date-hist/NtaiDateHistogramEChart";
import NtaiTimeSeriesEChart from "@ntai/components/charts/echarts/time-series/NtaiTimeSeriesEChart";
import NtaiTreeMapEChart from "@ntai/components/charts/echarts/treemap/NtaiTreeMapEChart";
import NtaiLinearTreeEChart from "@ntai/components/charts/echarts/tree/NtaiLinearTreeEChart";
import NtaiRadialTreeEChart from "@ntai/components/charts/echarts/tree/NtaiRadialTreeEChart";
import NtaiBubbleEChart from "@ntai/components/charts/echarts/scatter/NtaiBubbleEChart";
import NtaiHeatmapEChart from "@ntai/components/charts/echarts/heatmap/NtaiHeatmapEChart";
import NtaiCircularDependencyEChart from "@ntai/components/charts/echarts/force-graph/NtaiCircularDependencyEChart";
import NtaiForceDirectedEChart from "@ntai/components/charts/echarts/force-graph/NtaiForceDirectedEChart";
import { useMeasure } from "react-use";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiGanttChart from "@ntai/components/charts/amcharts/v2/NtaiGanttChart";
import NtaiWidgetMetric from "@ntai/components/charts/metrics/NtaiWidgetMetric";
import NtaiMaterialReactGridWidget from "@ntai/components/grid/material-react/NtaiMaterialReactGridWidget";
import SearchSourceDataTableIIWidget from "./shared/SearchSourceDataTableIIWidget";
import { SearchSourceContext } from "../../SearchSource";
import NtaiGeoMap from "@ntai/components/charts/geo/NtaiGeoMap";
import SearchSourceSummaryTabWidget from "./shared/SearchSourceSummaryTabWidget";
import NtaiWidgetMetricList from "@ntai/components/charts/metrics/NtaiWidgetMetricList";
import NtaiScatterEChart from "@ntai/components/charts/echarts/scatter/NtaiScatterEChart";
import NtaiBarLineEChart from "@ntai/components/charts/echarts/bar/NtaiBarLineEChart";
const _ = require("lodash");

export default function SearchSourceWidgetChart(props) {
  const theme = useTheme();
  const {
    widget,
    widgetMetadata,
    handleClick,
    handleContextMenu,
    handleLinkClick,
  } = props;

  const [ref, { width, height }] = useMeasure();

  // console.log("Widget data and metadata: ", widget, widgetMetadata);
  const { setSelectedWidgetRecordId } = React.useContext(SearchSourceContext);

  function onHandleDisplayRecord(e, id) {
    setSelectedWidgetRecordId(id);
  }
  // function getFieldTypesFromMetadata() {
  //   let result = {};
  //   const catFields = _.filter(
  //     Object.values(widgetMetadata["fields"]),
  //     function (f) {
  //       if ((f.field.esDataTypeCode === 4) | (f.field.dataTypeCode === 1))
  //         return true;
  //       else return false;
  //     }
  //   );

  //   result = {
  //     categoryFields: catFields.length,
  //   };

  //   return result;
  // }

  return (
    <React.Fragment>
      {widget && widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL && (
        <NtaiHorizontalBarEChart
          widgetId={_.get(widget, "widgetUuId")}
          data={_.get(widget, "data")}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget &&
        widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL_SERIES && (
          <NtaiHorizontalBarSeriesEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

      {widget &&
        widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL_STACKED && (
          <NtaiHorizontalBarStackedEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

      {widget && widget.widgetChartCode === CHART_CODES.BAR_VERTICAL && (
        <NtaiVerticalBarEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.BAR_VERTICAL_SERIES && (
        <NtaiVerticalBarSeriesEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget &&
        widget.widgetChartCode === CHART_CODES.BAR_VERTICAL_STACKED && (
          <NtaiVerticalBarStackedEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

      {widget && widget.widgetChartCode === CHART_CODES.PIE && (
        <NtaiPieEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.DONUT && (
        <NtaiDonutEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.DATE_HISTOGRAM && (
        <NtaiDateHistogramEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.TIME_SERIES && (
        <NtaiTimeSeriesEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.CHORD && (
        <NtaiCircularDependencyEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.GEO && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }} ref={ref}>
          <NtaiGeoMap
            widgetId={widget.widgetUuId}
            data={widget.data}
            // handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            handleClick={handleClick}
            metadata={widgetMetadata}
            width={width}
            height={height}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.DATA_GRID_I && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiDataGrid
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
          />
        </Box>
      )}

      {/* {widget && widget.widgetChartCode === CHART_CODES.DATA_GRID_II && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiMuiDataGridWidget
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleLinkClick={handleLinkClick}
          />
        </Box>
      )} */}

      {widget && widget.widgetChartCode === CHART_CODES.DATA_GRID_II && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <SearchSourceDataTableIIWidget
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            // handleR={handleLinkClick}
            handleDisplayRecord={onHandleDisplayRecord}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.SUMMARY_TABULATION && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <SearchSourceSummaryTabWidget
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.PACKED_CIRCLE && (
        <NtaiPackedCircleChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          metadata={widgetMetadata}
          handleClick={handleClick}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.FORCE_DIRECTED && (
        <NtaiForceDirectedEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          metadata={widgetMetadata}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
        />
      )}

      {widget && widget.widgetChartCode === CHART_CODES.TREEMAP && (
        <NtaiTreeMapEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}
      {widget && widget.widgetChartCode === CHART_CODES.PIVOT_GRID && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            pb: theme.spacing(1),
          }}
        >
          <NtaiXTab
            widgetId={widget.widgetUuId}
            rows={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.SCATTER && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiScatterEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {/* TODO:  Temporary bubble chart for custom widget */}
      {widget && widget.widgetChartCode === CHART_CODES.BUBBLE && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiBubbleEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.SANKEY_PLOT && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiSankeyChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            fromField="from"
            toField="to"
            valueField="value"
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.HEATMAP && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiHeatmapEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
            handleConetxtMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.WORD_CLOUD && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiWordCloudChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            categoryField={"intervention"}
            valueField={"value"}
            metadata={widgetMetadata}
            handleClick={handleClick}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.TREE_LINEAR && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiLinearTreeEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.TREE_RADIAL && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiRadialTreeEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.GANTT && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiGanttChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </Box>
      )}

      {widget && widget.widgetChartCode === CHART_CODES.SCRIPTED_METRIC && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiWidgetMetric
            data={
              _.isArray(widget["data"]) &&
              _.has(widget["data"][0], "value") &&
              _.isNumber(widget["data"][0]["value"])
                ? NtaiUtils.abbrevNumber(widget.data[0]["value"])
                : 0
            }
            metadata={widgetMetadata}
          />
        </Box>
      )}

      {widget &&
        widget.widgetChartCode === CHART_CODES.AGGREGATE_METRIC &&
        _.isArray(widget["data"]) &&
        widget["data"].length === 1 && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiWidgetMetric data={widget["data"]} metadata={widgetMetadata} />
          </Box>
        )}

      {widget &&
        widget.widgetChartCode === CHART_CODES.AGGREGATE_METRIC &&
        _.isArray(widget["data"]) &&
        widget["data"].length > 1 && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiWidgetMetricList
              data={widget["data"]}
              metadata={widgetMetadata}
            />
          </Box>
        )}

      {widget && widget.widgetChartCode === CHART_CODES.BAR_LINE && (
        <NtaiBarLineEChart
          widgetId={widget.widgetUuId}
          data={widget.data}
          handleClick={handleClick}
          handleContextMenu={handleContextMenu}
          metadata={widgetMetadata}
        />
      )}

      {/* {widget &&
          (!_.has(widget, "data") ||
            !widget["data"] ||
            _.isEmpty(widget["data"]) ||
            (_.isArray(widget["data"]) && widget["data"].length === 0)) && (
            <NtaiEmptyMessage header="No data found" />
          )} */}
    </React.Fragment>
  );
}
