import {
  faCircle,
  faPlay,
  faSaveCircleArrowRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReportContext } from "../ReportMain";
import ReportSourceFilters from "./source-criteria/ReportSourceFilters";
import ReportSourceQbe from "./source-criteria/ReportSourceQbe";
import ReportParams from "./std-criteria/ReportParams";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import Scrollbar from "@ntai/components/Scrollbar";
import { useFormContext } from "react-hook-form";
import ReportSeriesCriteria from "./series/ReportSeriesCriteria";
import History from "@ntai/@history";
import ReportDatasetCriteria from "./dataset/ReportDatasetCriteria";
const _ = require("lodash");

export default function ReportCriteria() {
  const theme = useTheme();

  const { getValues } = useFormContext();

  const {
    action,
    reportId,
    reportDefinition,
    generateReportCounter,
    setGenerateReportCounter,
    setReportFormData,
    sourceCriteria,
    esCriteria,
    selectedSourceSeriesId,
    selectedSourceDatasetId,
  } = React.useContext(ReportContext);

  function getCriteriaFormData() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("reportSaveForm")
    );

    let finalFormData = { ...sanitizedFormData };
    let reportParameters = [];

    if (
      reportDefinition.typeCode === 2 ||
      (reportDefinition.typeCode === 1 &&
        reportDefinition.criteriaTypeCode === 1)
    ) {
      // Source filters
      const sourceFilterCriteria = NtaiUtils.sanitizeFormData(
        getValues("reportParamForm")
      );

      if (sourceFilterCriteria && !_.isEmpty(sourceFilterCriteria)) {
        Object.keys(sourceFilterCriteria).forEach((paramId, i) => {
          const tmpValues = _.get(sourceFilterCriteria, paramId);
          const sourceFilterCriteriaField = _.filter(
            reportDefinition["reportDefinitionParameters"],
            { uuId: paramId }
          )[0];
          let revisedValues = [];

          if (_.isArray(tmpValues)) {
            revisedValues = tmpValues.map((v, i) => {
              return {
                valueOrder: i + 1,
                value:
                  sourceFilterCriteriaField["typeCode"] !== 1
                    ? v
                    : _.get(v, "value"),
              };
            });
          } else if (tmpValues !== undefined) {
            revisedValues.push({
              valueOrder: 1,
              value: tmpValues,
            });
          }

          reportParameters.push({
            reportDefinitionParameterUuId: paramId,
            reportParameterValues: revisedValues,
          });
        });

        finalFormData["reportParameters"] = reportParameters;
      } else {
        finalFormData["reportParameters"] = [];
      }
    } else if (reportDefinition.typeCode === 3) {
      finalFormData["sourceSeriesUuId"] = selectedSourceSeriesId;
    } else if (reportDefinition.typeCode === 4) {
      finalFormData["sourceDomainUuId"] = selectedSourceDatasetId;
    } else {
      // QBE
      // console.log("Source and es criteria: ", sourceCriteria, esCriteria);
      finalFormData["sourceQbeCriteria"] = sourceCriteria;
      finalFormData["sourceQbeEsCriteria"] = esCriteria;
    }

    finalFormData["reportDefinitionUuId"] = _.get(reportDefinition, "uuId");
    return finalFormData;
  }

  function runReport() {
    setGenerateReportCounter(generateReportCounter + 1);
    setReportFormData({ ...getCriteriaFormData() });
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 44px)`,
        }}
      >
        {reportDefinition.typeCode === 1 &&
          reportDefinition.criteriaTypeCode === 1 && <ReportSourceFilters />}
        {reportDefinition.typeCode === 1 &&
          reportDefinition.criteriaTypeCode === 2 && <ReportSourceQbe />}
        {reportDefinition.typeCode === 2 && <ReportParams />}
        {reportDefinition.typeCode === 3 && <ReportSeriesCriteria />}
        {reportDefinition.typeCode === 4 && <ReportDatasetCriteria />}
      </Box>
      <Box
        sx={{
          borderTop: theme.general.border1,
          p: theme.spacing(2),
          height: "54px",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: theme.spacing(1),
        }}
      >
        <Button
          sx={{
            fontSize: "11px",
            fontWeight: 700,
            gap: theme.spacing(1),
          }}
          onClick={() => History.push("/reports")}
        >
          CANCEL
        </Button>
        {action !== "edit" && (
          <Button
            sx={{
              fontSize: "11px",
              fontWeight: 700,
              gap: theme.spacing(1),
            }}
            variant="contained"
            onClick={() => runReport()}
            disabled={action === "edit" ? true : false}
          >
            <FontAwesomeIcon size="xl" icon={faPlay} />
            RUN
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
