import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiPdfReport from "./NtaiPdfReport";

export default function NtaiTestPdfReport() {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", p: theme.spacing(4) }}>
      <NtaiPdfReport />
    </Box>
  );
}
