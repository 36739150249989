import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightTopicNotes = createAsyncThunk(
  "oversightTopicNotes/getOversightTopicNotes",
  async (oversightTopicId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/oversight/topics/${oversightTopicId}/notes`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOversightTopicNote = createAsyncThunk(
  "oversightTopicNotes/getOversightTopicNote",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId } = values;
      const response = await server.get(
        `/oversight/topics/${oversightTopicId}/notes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOversightTopicNote = createAsyncThunk(
  "oversightTopicNotes/createOversightTopicNote",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, formData } = values;
      const response = await server.post(
        `/oversight/topics/${oversightTopicId}/notes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateOversightTopicNote = createAsyncThunk(
  "oversightTopicNotes/updateOversightTopicNote",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId, formData } = values;
      const response = await server.patch(
        `/oversight/topics/${oversightTopicId}/notes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOversightTopicNote = createAsyncThunk(
  "oversightTopicNotes/deleteOversightTopicNote",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId } = values;
      const response = await server.delete(
        `/oversight/topics/${oversightTopicId}/notes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightTopicNotesSlice = createSlice({
  name: "oversightTopicNotes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, note) => {
      state.status = {};
    },
    setActiveId: (state, note) => {
      state.activeId = note.payload;
    },
  },
  extraReducers: {
    [getOversightTopicNotes.fulfilled]: (state, note) => {
      state.data = { ..._.mapKeys(note.payload, "uuId") };
      state.status = { result: "success", method: "getOversightTopicNotes" };
    },
    [getOversightTopicNotes.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "getOversightTopicNotes",
        message: note.payload.message,
      };
    },
    [getOversightTopicNote.fulfilled]: (state, note) => {
      state.data = {
        ...state.data,
        [note.payload.uuId]: note.payload,
      };
      state.status = { result: "success", method: "getOversightTopicNote" };
    },
    [getOversightTopicNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "getOversightTopicNote",
        message: note.payload.message,
      };
    },
    [createOversightTopicNote.fulfilled]: (state, note) => {
      state.data = {
        ...state.data,
        [note.payload.uuId]: note.payload,
      };
      state.activeId = note.payload.uuId;
      state.status = {
        result: "success",
        method: "createOversightTopicNote",
      };
    },
    [createOversightTopicNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "createOversightTopicNote",
        message: note.payload.message,
      };
    },
    [updateOversightTopicNote.fulfilled]: (state, note) => {
      state.data = {
        ...state.data,
        [note.payload.uuId]: note.payload,
      };
      state.activeId = note.payload.uuId;
      state.status = {
        result: "success",
        method: "updateOversightTopicNote",
      };
    },
    [updateOversightTopicNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "updateOversightTopicNote",
        message: note.payload.message,
      };
    },
    [deleteOversightTopicNote.fulfilled]: (state, note) => {
      state.data = _.omit(state.data, note.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteOversightTopicNote",
      };
    },
    [deleteOversightTopicNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "deleteOversightTopicNote",
        message: note.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = oversightTopicNotesSlice.actions;

export default oversightTopicNotesSlice.reducer;
