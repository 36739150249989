import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSources = createAsyncThunk(
  "searchSources/getSearchSources",
  async (searchId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/search/${searchId}/sources`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSource = createAsyncThunk(
  "searchSources/getSearchSource",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrRetrieveLinkedSearchSource = createAsyncThunk(
  "searchSources/createOrRetrieveLinkedSearchSource",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, parentSearchSourceId, linkedSourceSourceUuId } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${parentSearchSourceId}/linkedsources/${linkedSourceSourceUuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleLinkClickForLinkedSearchSource = createAsyncThunk(
  "searchSources/handleLinkClickForLinkedSearchSource",
  async (values, { rejectWithValue }) => {
    try {
      const {
        searchId,
        parentSearchSourceId,
        linkedSourceSourceUuId,
        linkUuId,
        linkValue,
        sourceRecordId,
      } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${parentSearchSourceId}/linkedsources/${linkedSourceSourceUuId}/links/${linkUuId}`,
        { value: linkValue, sourceRecordId: sourceRecordId }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSource = createAsyncThunk(
  "searchSources/deleteSearchSource",
  async (values, { rejectWithValue }) => {
    const { searchId, searchSourceId } = values;
    try {
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}`
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateFilterScheme = createAsyncThunk(
  "searchSources/createOrUpdateFilterScheme",
  async (values, { rejectWithValue }) => {
    const { searchId, searchSourceId, formData } = values;
    try {
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/afilters`,
        formData
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrDeleteWidgetFilter = createAsyncThunk(
  "searchSources/createOrDeleteWidgetFilter",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/wfilters`,
        formData
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteWidgetFilter = createAsyncThunk(
  "searchSources/deleteWidgetFilter",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/wfilters/${uuId}`
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteWidgetIEFilter = createAsyncThunk(
  "searchSources/deleteWidgetIEFilter",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/wiefilters/${uuId}`
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceQueryBuilderFilter = createAsyncThunk(
  "searchSources/getSearchSourceQueryBuilderFilter",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/qbfilters`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSearchSourceQueryBuilderFilter = createAsyncThunk(
  "searchSources/createSearchSourceQueryBuilderFilter",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/qbfilters`,
        formData
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceQueryBuilderFilter = createAsyncThunk(
  "searchSources/deleteSearchSourceQueryBuilderFilter",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/qbfilters`
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const selectSearchSourceView = createAsyncThunk(
  "searchSources/selectSearchSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/select-view`,
        formData
      );
      return { uuId: searchSourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loadSearchSourceRecordSeries = createAsyncThunk(
  "searchSourceRecordSeriess/loadSearchSourceRecordSeries",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/series/${uuId}/load`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const unloadSearchSourceRecordSeries = createAsyncThunk(
  "searchSourceRecordSeriess/unloadSearchSourceRecordSeries",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/series/${uuId}/unload`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourcesSlice = createSlice({
  name: "searchSources",
  initialState: {
    data: {},
    activeId: null,
    searchSourceFilterSchemeActiveId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setSearchSourceFilterSchemeActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSearchSources" };
    },
    [getSearchSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSources",
        message: action.payload.message,
      };
    },
    [getSearchSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSearchSource" };
    },
    [getSearchSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSource",
        message: action.payload.message,
      };
    },

    [createOrRetrieveLinkedSearchSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createOrRetrieveLinkedSearchSource",
      };
    },
    [createOrRetrieveLinkedSearchSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrRetrieveLinkedSearchSource",
        message: action.payload.message,
      };
    },

    [handleLinkClickForLinkedSearchSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "handleLinkClickForLinkedSearchSource",
      };
    },
    [handleLinkClickForLinkedSearchSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "handleLinkClickForLinkedSearchSource",
        message: action.payload.message,
      };
    },

    [deleteSearchSource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSearchSource" };
    },
    [deleteSearchSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSource",
        message: action.payload.message,
      };
    },

    [createOrUpdateFilterScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "createOrUpdateFilterScheme",
      };
    },

    [createOrDeleteWidgetFilter.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "createOrDeleteWidgetFilter",
      };
    },

    [createOrDeleteWidgetFilter.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrDeleteWidgetFilter",
        message: action.payload.message,
      };
    },

    [deleteWidgetFilter.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "deleteWidgetFilter",
      };
    },

    [deleteWidgetFilter.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteWidgetFilter",
        message: action.payload.message,
      };
    },

    [deleteWidgetIEFilter.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "deleteWidgetIEFilter",
      };
    },

    [deleteWidgetIEFilter.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteWidgetIEFilter",
        message: action.payload.message,
      };
    },

    [createOrUpdateSearchSourceQueryBuilderFilter.fulfilled]: (
      state,
      action
    ) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "createOrUpdateSearchSourceQueryBuilderFilter",
      };
    },
    [createOrUpdateSearchSourceQueryBuilderFilter.rejected]: (
      state,
      action
    ) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSearchSourceQueryBuilderFilter",
        message: action.payload.message,
      };
    },

    [deleteSearchSourceQueryBuilderFilter.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "deleteSearchSourceQueryBuilderFilter",
      };
    },
    [deleteSearchSourceQueryBuilderFilter.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceQueryBuilderFilter",
        message: action.payload.message,
      };
    },

    [selectSearchSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "selectSearchSourceView",
      };
    },
    [selectSearchSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "selectSearchSourceView",
        message: action.payload.message,
      };
    },

    [loadSearchSourceRecordSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "loadSearchSourceRecordSeries",
      };
    },
    [loadSearchSourceRecordSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "loadSearchSourceRecordSeries",
        message: action.payload.message,
      };
    },

    [unloadSearchSourceRecordSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "unloadSearchSourceRecordSeries",
      };
    },
    [unloadSearchSourceRecordSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "unloadSearchSourceRecordSeries",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, setSearchSourceFilterSchemeActiveId } =
  searchSourcesSlice.actions;

export default searchSourcesSlice.reducer;
