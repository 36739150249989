import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { useSelector, useDispatch } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { useSnackbar } from "notistack";
import {
  clearStatus,
  createOversightTopicAction,
  deleteOversightTopicAction,
  getOversightTopicActions,
  updateOversightTopicAction,
} from "./store/oversightTopicActionsSlice";
import { OversightContext } from "../../../../../Oversight";
import OversightTopicActionForm from "./OversightTopicActionForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "action.title",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "action.statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action.owner",
    numeric: false,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "action.plannedEndDate",
    numeric: false,
    disablePadding: false,
    label: "Due",
  },
];

export default function OversightTopicActionList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);

  const {
    navNode,
    oversightTopicId,
    setOversightTopicId,
    setReloadOversightTopicSummary,
  } = React.useContext(OversightContext);

  const formDataParams = navNode
    ? {
        dataUuId: navNode["parentUuId"],
        objectTopicUuId:
          navNode["type"] === "OBJECT_TOPIC" ? navNode["uuId"] : null,
        dataTopicUuId:
          navNode["type"] === "DATA_TOPIC" ? navNode["uuId"] : null,
      }
    : null;

  const dataMap = useSelector(
    (state) => state.oversight.oversightTopicActionsSlice.data
  );

  const activeId = useSelector(
    (state) => state.oversight.oversightTopicActionsSlice.activeId
  );

  const data = Object.values(dataMap);

  const status = useSelector(
    (state) => state.oversight.oversightTopicActionsSlice.status
  );

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleSave(formData) {
    if (action === "add")
      dispatch(
        createOversightTopicAction({
          oversightTopicId: oversightTopicId,
          formData: { ...formDataParams, action: formData },
        })
      );
    else if (action === "edit")
      dispatch(
        updateOversightTopicAction({
          uuId: currentId,
          oversightTopicId: oversightTopicId,
          formData: { ...formDataParams, action: formData },
        })
      );
  }

  function handleToolbarAction(action, selected) {
    if (action === "delete") {
      selected.forEach((item) => {
        dispatch(
          deleteOversightTopicAction({
            oversightTopicId: oversightTopicId,
            uuId: item,
          })
        );
      });
    }
  }

  useEffect(() => {
    if (
      status.result === "success" &&
      (status.method === "createOversightTopicAction" ||
        status.method === "updateOversightTopicAction")
    ) {
      if (oversightTopicId === "*") {
        setOversightTopicId(
          _.get(_.get(dataMap, activeId), "oversightTopicUuId")
        );
      }

      enqueueSnackbar("Record successfully created", {
        variant: "success",
      });
      dispatch(clearStatus());
      handleCancel();
      setReloadOversightTopicSummary(true);
    }

    if (
      status.result === "success" &&
      selectedIds.length === 0 &&
      status.method === "deleteOversightTopicAction"
    ) {
      enqueueSnackbar("Record deleted successfully.", {
        variant: "success",
      });
      dispatch(clearStatus());
      setReloadOversightTopicSummary(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getOversightTopicActions(oversightTopicId));
    dispatch(getCodes(CODELIST_CODES.COM_ACTION_CATEGORY_CODE));
    dispatch(getCodes(CODELIST_CODES.COM_ACTION_STATUS_CODE));
    dispatch(getCodes(CODELIST_CODES.COM_ACTION_PRIORITY_CODE));
  }, []);

  return (
    <NtaiPanel
      width="75%"
      header={`Actions (${data.length})`}
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi"
    >
      <>
        {action === "list" && (
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            keyColumn="uuId"
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            toolbarActions={[{ value: "delete", label: "Delete" }]}
            handleToolbarAction={handleToolbarAction}
            title="Action"
          />
        )}
        {(action === "add" || action === "edit") && (
          <NtaiForm>
            <OversightTopicActionForm
              action={action}
              formData={
                action === "edit"
                  ? _.get(_.get(dataMap, currentId), "action")
                  : {}
              }
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </NtaiForm>
        )}
      </>
    </NtaiPanel>
  );
}
