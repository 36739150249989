import DistributionGroupCreate from "./DistributionGroupCreate";
import DistributionGroupEdit from "./DistributionGroupEdit";
import DistributionGroupList from "./DistributionGroupList";

const DistributionGroupConfig = {
  routes: [
    {
      path: "/library/distribution-groups",
      exact: true,
      component: DistributionGroupList,
    },
    {
      path: "/library/distribution-groups/create",
      exact: true,
      component: DistributionGroupCreate,
    },
    {
      path: "/library/distribution-groups/:id/edit",
      exact: true,
      component: DistributionGroupEdit,
    },
  ],
};

export default DistributionGroupConfig;
