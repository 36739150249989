import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateSchedule,
  clearStatus,
} from "../store/alertDefinitionsSlice";
import NtaiCronScheduler from "@ntai/components/cron/NtaiCronScheduler";
import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AlertDefSchedule({ handleCancel }) {
  // const { getValues, setValue } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [localCronValue, setLocalCronValue] = React.useState();
  const [value, setValue] = React.useState();

  const definitionDataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );

  const status = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );

  const definitionId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  const definitionData = _.get(definitionDataMap, definitionId);

  function onChange(val) {
    setLocalCronValue(val);
  }

  function handleSave() {
    dispatch(
      createOrUpdateSchedule({
        definitionId: definitionId,
        formData: { alertDefSchedule: { cronExpression: localCronValue } },
      })
    );
  }

  useEffect(() => {
    if (_.has(definitionData, "alertDefSchedule.uuId")) {
      setValue(_.get(definitionData, "alertDefSchedule.cronExpression"));
    } else setValue(null);
  }, [definitionData]);

  useEffect(() => {
    if (
      status &&
      status.method === "createOrUpdateSchedule" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(
        `Alert schedule updated for "${definitionData["name"]}".`,
        {
          variant: "success",
        }
      );
    }
  }, [status]);

  return (
    <NtaiFormPanel
      width="50%"
      header="Schedule Alert"
      subheader="Specify scheduling frequency for the alert. Alerts will be created and routed as per the defined schedule"
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <NtaiCronScheduler
        value={value}
        onChange={onChange}
        showResultText={true}
      />
    </NtaiFormPanel>
  );
}
