import { useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceViewCriteria from "./SourceViewCriteria";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "../../store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "../../derivedfield/store/sourceDerivedFieldsSlice";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { updateSourceView } from "../store/sourceViewsSlice";
const _ = require("lodash");

export default function SourceViewCriteriaWrapper(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [qbeFields, setQbeFields] = useState();
  const [fields, setFields] = useState();

  const sourceViewId = props.match.params.id;

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceViewDataMap = useSelector(
    (state) => state.sources.sourceViewsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceViewData =
    sourceViewDataMap && !_.isEmpty(sourceViewDataMap)
      ? sourceViewDataMap[sourceViewId]
      : null;

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  function handleSave(criteriaFormData) {
    const viewDataCopy = _.cloneDeep(sourceViewData);
    const revisedViewDataCopy = { ...viewDataCopy, ...criteriaFormData };

    dispatch(
      updateSourceView({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: sourceViewId,
        formData: revisedViewDataCopy,
      })
    );
  }

  function handleCancel() {
    history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/views`);
  }

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefId,
        sourceId,
        mergedFields
      );

      setQbeFields({ ...tmpFields });
      setFields({ ...mergedFields });
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <NtaiPage
      title={`${_.get(sourceDefData, "name")} / ${
        sourceViewData["name"]
      } / Criteria`}
      back={`/sourcedefs/${sourceDefId}/smwrapper`}
    >
      {fieldsLoaded && (
        <NtaiForm>
          <SourceViewCriteria
            sourceViewId={sourceViewId}
            fields={fields}
            qbeFields={qbeFields}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
      {!fieldsLoaded && <NtaiCircularProgress size={24} />}
    </NtaiPage>
  );
}
