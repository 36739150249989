import React from "react";
import { OversightContext } from "../../../Oversight";
import { useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faClipboard,
  faNote,
  faPodcast,
  faTasks,
} from "@fortawesome/pro-light-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
import OversightEntityTopicCard from "./OversightEntityTopicCard";
const _ = require("lodash");

export default function OversightEntityTopics({ handleTopicSelect }) {
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const {
    navNode,
    setNavNode,
    oversightTopicId,
    section,
    setSection,
    setReloadOversightTopicSummary,
  } = React.useContext(OversightContext);

  const dataUuId =
    _.has(navNode, "type") && _.get(navNode, "type") === "ENTITY"
      ? _.get(navNode, "uuId")
      : null;

  const data = useSelector(
    (state) => state.oversight.oversightEntitiesSlice.data
  );
  const status = useSelector(
    (state) => state.oversight.oversightEntitiesSlice.status
  );

  function onHandleTopicSelect(topicUuId) {
    if (_.has(navNode, "children") && _.isArray(navNode["children"])) {
      const selectedTopicNodes = _.filter(navNode["children"], {
        uuId: topicUuId,
      });
      if (selectedTopicNodes.length > 0) {
        setNavNode(selectedTopicNodes[0]);
        handleTopicSelect(selectedTopicNodes[0]);
      }
    }
  }

  // console.log("Nav node in topic summary: ", navNode, data);

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            px: theme.spacing(2),
            height: "36px",

            display: "flex",
            alignItems: "center",
            borderBottom: theme.general.border1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="body1">
              All Topics ({data["topics"].length}) |{" "}
            </Typography>
            <FontAwesomeIcon size="2xs" icon={faTasks} />
            <Typography variant="subtitle2">Actions: 4</Typography>
            <FontAwesomeIcon size="2xs" icon={faNote} />
            <Typography variant="subtitle2">Notes: 10</Typography>
            <FontAwesomeIcon size="2xs" icon={faClipboard} />
            <Typography variant="subtitle2">Documents: 4</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button
              sx={{
                fontSize: "10px",
                fontWeight: 500,
                gap: theme.spacing(1),
              }}
              size="small"
              color="inherit"
            >
              <FontAwesomeIcon size="xl" icon={faChartSimple} />
              OVERVIEW
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: `calc(100% - 36px)`,
          }}
        >
          {data["topics"].length > 0 && (
            <Scrollbar>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  p: theme.spacing(2),
                  gap: theme.spacing(2),
                }}
              >
                {_.orderBy(data["topics"], ["topicName"], ["asc"]).map(
                  (d, i) => (
                    <OversightEntityTopicCard
                      height="150px"
                      id={`oversight-entity-topic-card-${i}`}
                      avatar={<FontAwesomeIcon icon={faPodcast} />}
                      title={d.topicName}
                      hits="23k"
                      description={d.topicDescription}
                      subTitles={[
                        { icon: "usersLight", name: "Members", value: 12 },
                      ]}
                      handleClick={() => onHandleTopicSelect(d.uuId)}
                    />
                  )
                )}
              </Box>
            </Scrollbar>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}
