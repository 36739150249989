import { Box, Typography, useTheme } from "@mui/material";
import NtaiDashboardWidget from "@ntai/components/widgets/dashboard/NtaiDashboardWidget";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardWidget, clearStatus } from "./store/dashboardWidgetsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiMuiCalendar from "@ntai/components/calendar/NtaiMuiCalendar";
import SearchSummaryWidget from "./standard/SearchSummaryWidget";
import SearchListWidget from "./standard/SearchListWidget";
import SourceSummaryWidget from "./standard/SourceSummaryWidget";
import SourceListWidget from "./standard/SourceListWidget";
import SourceMigrationListWidget from "./standard/SourceMigrationListWidget";
import SourceMigrationSummaryWidget from "./standard/SourceMigrationSummaryWidget";
import MonitorListWidget from "./standard/MonitorListWidget";
import ModelsSummaryWidget from "./standard/ModelsSummaryWidget";
import DashboardSourceWidget from "./source/DashboardSourceWidget";
const _ = require("lodash");

export default function DashboardWidget({ initData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const dashboardId = useSelector(
    (state) => state.dashboard.dashboardSlice.activeId
  );

  const status = useSelector(
    (state) => state.dashboard.dashboardWidgetsSlice.status
  );

  const widgetDataMap = useSelector(
    (state) => state.dashboard.dashboardWidgetsSlice.data
  );

  const widget = _.get(widgetDataMap, initData.uuId);

  const fields = _.get(_.get(widget, "metadata"), "fields");
  const params = _.get(_.get(widget, "metadata"), "params");

  function generateWidget() {
    // console.log("Widget data:== ", widgetData);
    if (widget.stdWidgetFg === 1) {
      if (widget.stdWidgetCode === "SRH-001") {
        return <SearchSummaryWidget data={widget.data} />;
      }

      if (widget.stdWidgetCode === "SRH-002") {
        return <SearchListWidget data={widget.data} />;
      }
      if (widget.stdWidgetCode === "SRC-001") {
        return <SourceSummaryWidget data={widget.data} />;
      }
      if (widget.stdWidgetCode === "SRC-002") {
        return <SourceListWidget data={widget.data} />;
      }

      if (widget.stdWidgetCode === "SRC-003") {
        return <SourceMigrationSummaryWidget data={widget.data} />;
      }

      if (widget.stdWidgetCode === "SRC-004") {
        return <SourceMigrationListWidget data={widget.data} />;
      }

      if (widget.stdWidgetCode === "COR-001") {
        return <NtaiMuiCalendar />;
      }
      if (widget.stdWidgetCode === "MON-002") {
        return <MonitorListWidget data={widget.data} />;
      }
      if (widget.stdWidgetCode === "MON-001") {
        return <ModelsSummaryWidget data={widget.data} />;
      }
      if (widget.stdWidgetCode === "MON-003") {
        return <ModelsSummaryWidget data={widget.data} />;
      }
      if (widget.stdWidgetCode === "MON-004") {
        return <ModelsSummaryWidget data={widget.data} />;
      }
     
    } else if (widget.sourceWidgetFg === 1) {
      return <DashboardSourceWidget widget={widget} />;
    }

    // else {
    //   switch (widget.displayTypeCode) {
    //     case DASHBOARD_WIDGET_DISPLAY_CODES.HIST:
    //       return (
    //         <NtaiHistogramChart
    //           widgetId="123"
    //           data={widget.data}
    //           categoryField={_.get(fields[0], "id")}
    //           valueField={_.get(fields[1], "id")}
    //           handleClick={null}
    //           metadata={null}
    //         />
    //       );

    //     case DASHBOARD_WIDGET_DISPLAY_CODES.PIE:
    //       return (
    //         <NtaiPieChart1
    //           widgetId="1234"
    //           data={widget.data}
    //           categoryField={_.get(fields[0], "id")}
    //           valueField={_.get(fields[1], "id")}
    //           radius={50}
    //           handleClick={null}
    //           metadata={null}
    //         />
    //       );

    //     default:
    //       return <Box>Not available</Box>;
    //   }
    // }
  }

  useEffect(() => {
    if (
      status &&
      _.has(status, initData.uuId) &&
      _.get(_.get(status, initData.uuId), "result") === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus(initData.uuId));
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getDashboardWidget({
        dashboardId: dashboardId,
        uuId: initData.uuId,
        formData: {
          start: 0,
          limit: 10,
          stdWidgetFg: _.get(initData, "stdWidgetFg"),
        },
      })
    );
  }, [initData.uuId]);

  return (
    <NtaiDashboardWidget
      title={initData.label}
      headerAbsentFg={initData.headerAbsentFg}
      loading={!loaded}
      footer={
        initData.sourceMin ? (
          <Typography variant="caption">
            Source: {NtaiUtils.trunc(initData.sourceMin.name, "50")}
          </Typography>
        ) : null
      }
    >
      {widget && _.has(widget, "uuId") && generateWidget()}
    </NtaiDashboardWidget>
  );
}
