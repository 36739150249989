import {
  Box,
  Button,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SourceWidgetFieldColorSchemes from "./SourceWidgetFieldColorSchemes";

const _ = require("lodash");

const colorFormatOptions = [
  { value: 1, label: "Exact Value" },
  { value: 2, label: "Rule" },
];

export default function SourceWidgetFieldMultiColorFormat({
  widget,
  handleWidgetSave,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { setValue, reset, watch } = useFormContext();
  const [colorFormatCode, setColorFormatCode] = useState();
  const [currentId, setCurrentId] = useState();
  const [action, setAction] = useState();
  const [loaded, setLoaded] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const viewId = useSelector(
    (state) => state.sources.sourceViewsSlice.activeId
  );

  const widgetFields = widget ? widget["sourceWidgetFields"] : [];

  const [selectedField, setSelectedField] = useState(
    _.isArray(widgetFields) && widgetFields.length > 0
      ? _.get(widgetFields[0], "uuId")
      : null
  );
  const [selectedFieldUuId, setSelectedFieldUuId] = useState();

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    viewId: viewId,
    sourceWidgetId: widget["uuId"],
    sourceWidgetFieldId: selectedField ? selectedField["uuId"] : null,
  };

  const watchColorFieldUuId = watch("widgetForm.colorFieldUuId");

  const watchColorFormatCode = watch(
    "sourceWidgetFieldColorSchemeForm.colorFormatCode"
  );

  function selectField(widgetField) {
    setSelectedField({ ...widgetField });
  }

  function handleReset() {
    const widgetDataCopy = _.cloneDeep(widget);

    const selectedFieldIndex = _.findIndex(
      widgetDataCopy["sourceWidgetFields"],
      function (o) {
        if (o["uuId"] === selectedField["uuId"]) return true;
      }
    );

    const widgetFieldDataCopy =
      widgetDataCopy["sourceWidgetFields"][selectedFieldIndex];
    widgetFieldDataCopy["colorFormatCode"] = null;
    widgetDataCopy["sourceWidgetFields"][selectedFieldIndex] =
      widgetFieldDataCopy;
    handleWidgetSave(widgetDataCopy);
  }

  function handleCancel() {
    setOpen(false);
    setCurrentId(null);
  }

  useEffect(() => {
    if (
      watchColorFormatCode &&
      !_.isEmpty(watchColorFormatCode) &&
      _.has(watchColorFormatCode, "value") &&
      selectedField &&
      !_.isEmpty(selectedField)
    ) {
      setColorFormatCode(watchColorFormatCode["value"]);

      if (selectedField["colorFormatCode"] !== watchColorFormatCode["value"]) {
        const widgetDataCopy = _.cloneDeep(widget);
        const widgetFieldDataCopy = _.cloneDeep(selectedField);
        widgetFieldDataCopy["colorFormatCode"] = watchColorFormatCode["value"];
        const selectedFieldIndex = _.findIndex(
          widget["sourceWidgetFields"],
          function (o) {
            if (o["uuId"] === widgetFieldDataCopy["uuId"]) return true;
          }
        );

        widgetDataCopy["sourceWidgetFields"][selectedFieldIndex] =
          widgetFieldDataCopy;

        handleWidgetSave(widgetDataCopy);
      }
    }
  }, [watchColorFormatCode]);

  useEffect(() => {
    if (
      watchColorFieldUuId &&
      !_.isEmpty(watchColorFieldUuId) &&
      _.has(watchColorFieldUuId, "value")
    ) {
      setSelectedField(
        _.filter(widgetFields, { uuId: watchColorFieldUuId["value"] })[0]
      );
    }
  }, [watchColorFieldUuId]);

  useEffect(() => {
    if (widgetFields && _.isArray(widgetFields)) {
      const colorField = _.filter(widgetFields, function (o) {
        return [1, 2].includes(o["colorFormatCode"]) ? true : false;
      });
      if (colorField.length > 0) {
        setValue("widgetForm.colorFieldUuId", colorField[0]["uuId"]);
        setSelectedField(colorField[0]);
      }
    }
  }, [widget]);

  useEffect(() => {
    if (selectedField && !_.isEmpty(selectedField)) {
      setValue("widgetForm.colorFormatCode", selectedField["colorFormatCode"]);
      setColorFormatCode(selectedField["colorFormatCode"]);
    }
  }, [selectedField]);

  return (
    <NtaiPanel width="100%" subheader="Specify color formatter">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          border: theme.general.border1,
        }}
      >
        {selectedField &&
          widgetFields &&
          _.isArray(widgetFields) &&
          widgetFields.length > 0 && (
            <>
              <Box
                sx={{
                  p: theme.spacing(1),
                  flexBasis: "40%",
                  display: "flex",
                }}
              >
                <List
                  sx={{
                    border: theme.general.border1,
                    p: 0,
                    width: "100%",
                  }}
                >
                  {widgetFields.map((wf, i) => (
                    <React.Fragment>
                      <ListItemButton
                        sx={{ borderRadius: 0 }}
                        selected={
                          wf.uuId === selectedField["uuId"] ? true : false
                        }
                        onClick={() => selectField(wf)}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontWeight="700"
                            >
                              {wf.fieldName}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Box>
              <Box
                sx={{
                  pt: theme.spacing(1),
                  flexBasis: "60%",
                  display: "flex",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    px: theme.spacing(1),
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(1),
                  }}
                >
                  <Typography variant="h6" fontWeight="700">
                    {selectedField ? _.get(selectedField, "fieldName") : null}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <NtaiSelectField
                        isDisabled={
                          selectedField["colorFormatCode"] ? true : false
                        }
                        options={colorFormatOptions}
                        name="sourceWidgetFieldColorSchemeForm.colorFormatCode"
                        label="Select color formatter rule"
                      />
                    </Box>

                    {selectedField && !_.isEmpty(selectedField) && (
                      <SourceWidgetFieldColorSchemes
                        selectedField={selectedField}
                        sourceWidgetId={widget["uuId"]}
                        colorFormatCode={colorFormatCode}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
      </Box>
    </NtaiPanel>
  );
}
