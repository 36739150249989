// eslint-disable-next-line max-classes-per-file
import _ from "../@lodash/@lodash";
import * as colors from "@mui/material/colors";
import { value } from "jsonpath";
import { DateTime } from "luxon";
import cronstrue from "cronstrue";
import dayjs from "dayjs";

const { format, parse } = require("date-fns");

class EventEmitter {
  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === "undefined") {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  on(eventName, fn) {
    this._getEventListByName(eventName).add(fn);
  }

  once(eventName, fn) {
    const self = this;

    const onceFn = (...args) => {
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName, ...args) {
    this._getEventListByName(eventName).forEach(
      // eslint-disable-next-line func-names
      function (fn) {
        fn.apply(this, args);
      }.bind(this)
    );
  }

  removeListener(eventName, fn) {
    this._getEventListByName(eventName).delete(fn);
  }
}

class NtaiUtils {
  static filterArrayByString(mainArr, searchText) {
    if (searchText === "") {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
  }

  static searchInObj(itemObj, searchText) {
    if (!itemObj) {
      return false;
    }

    const propArray = Object.keys(itemObj);

    for (let i = 0; i < propArray.length; i += 1) {
      const prop = propArray[i];
      const value = itemObj[prop];

      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
    return false;
  }

  static searchInArray(arr, searchText) {
    arr.forEach((value) => {
      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
      return false;
    });
    return false;
  }

  static searchInString(value, searchText) {
    return value.toLowerCase().includes(searchText);
  }

  static sortObjByValue(obj, valuePath) {
    return Object.entries(obj)
      .sort(
        ([k1, v1], [k2, v2]) =>
          _.get(v1, `[${valuePath}]`) - _.get(v2, `[${valuePath}]`)
      )
      .map(([x, y]) => ({ [x]: y }));
  }

  static generateGUID() {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  static toggleInArray(item, array) {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  static handleize(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/\W+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  static setRoutes(config, defaultAuth) {
    let routes = [...config.routes];

    routes = routes.map((route) => {
      let auth =
        config.auth || config.auth === null ? config.auth : defaultAuth || null;
      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = _.merge({}, config.settings, route.settings);

      return {
        ...route,
        settings,
        auth,
      };
    });

    return [...routes];
  }

  static generateRoutesFromConfigs(configs, defaultAuth) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config, defaultAuth)];
    });
    return allRoutes;
  }

  static findById(obj, id) {
    let i;
    let childObj;
    let result;

    if (id === obj.id) {
      return obj;
    }

    for (i = 0; i < Object.keys(obj).length; i += 1) {
      childObj = obj[Object.keys(obj)[i]];

      if (typeof childObj === "object") {
        result = this.findById(childObj, id);
        if (result) {
          return result;
        }
      }
    }
    return false;
  }

  static getFlatNavigation(navigationItems, flatNavigation = []) {
    for (let i = 0; i < navigationItems.length; i += 1) {
      const navItem = navigationItems[i];

      if (navItem.type === "item") {
        flatNavigation.push({
          id: navItem.id,
          title: navItem.title,
          type: navItem.type,
          icon: navItem.icon || false,
          url: navItem.url,
          auth: navItem.auth || null,
        });
      }

      if (navItem.type === "collapse" || navItem.type === "group") {
        if (navItem.children) {
          this.getFlatNavigation(navItem.children, flatNavigation);
        }
      }
    }
    return flatNavigation;
  }

  static padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  }

  static invertColor(hex) {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    const val = "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    return val;
  }

  static randomMatColor(hue) {
    hue = hue || "400";
    const mainColors = [
      "red",
      "pink",
      "purple",
      "deepPurple",
      "indigo",
      "blue",
      "lightBlue",
      "cyan",
      "teal",
      "green",
      "lightGreen",
      "lime",
      "yellow",
      "amber",
      "orange",
      "deepOrange",
    ];
    const randomColor =
      mainColors[Math.floor(Math.random() * mainColors.length)];
    return colors[randomColor][hue];
  }

  static difference(object, base) {
    function changes(_object, _base) {
      return _.transform(_object, (result, value, key) => {
        if (!_.isEqual(value, _base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(_base[key])
              ? changes(value, _base[key])
              : value;
        }
      });
    }

    return changes(object, base);
  }

  static EventEmitter = EventEmitter;

  static updateNavItem(nav, id, item) {
    return nav.map((_item) => {
      if (_item.id === id) {
        return _.merge({}, _item, item);
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.updateNavItem(_item.children, id, item),
        });
      }

      return _.merge({}, _item);
    });
  }

  static removeNavItem(nav, id) {
    return nav
      .map((_item) => {
        if (_item.id === id) {
          return null;
        }

        if (_item.children) {
          return _.merge({}, _.omit(_item, ["children"]), {
            children: this.removeNavItem(_item.children, id),
          });
        }

        return _.merge({}, _item);
      })
      .filter((s) => s);
  }

  static prependNavItem(nav, item, parentId) {
    if (!parentId) {
      return [item, ...nav];
    }

    return nav.map((_item) => {
      if (_item.id === parentId && _item.children) {
        return {
          _item,
          children: [item, ..._item.children],
        };
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.prependNavItem(_item.children, item, parentId),
        });
      }

      return _.merge({}, _item);
    });
  }

  static appendNavItem(nav, item, parentId) {
    if (!parentId) {
      return [...nav, item];
    }

    return nav.map((_item) => {
      if (_item.id === parentId && _item.children) {
        return {
          ..._item,
          children: [..._item.children, item],
        };
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.appendNavItem(_item.children, item, parentId),
        });
      }

      return _.merge({}, _item);
    });
  }

  static hasPermission(authArr, userRole) {
    /**
     * If auth array is not defined
     * Pass and allow
     */
    if (authArr === null || authArr === undefined) {
      // console.info("auth is null || undefined:", authArr);
      return true;
    }
    if (authArr.length === 0) {
      /**
       * if auth array is empty means,
       * allow only user role is guest (null or empty[])
       */
      // console.info("auth is empty[]:", authArr);
      return !userRole || userRole.length === 0;
    }
    /**
     * Check if user has grants
     */
    // console.info("auth arr:", authArr);
    /*
            Check if user role is array,
            */
    if (userRole && Array.isArray(userRole)) {
      return authArr.some((r) => userRole.indexOf(r) >= 0);
    }

    /*
            Check if user role is string,
            */
    return authArr.includes(userRole);
  }

  static filterRecursive(data, predicate) {
    // if no data is sent in, return null, otherwise transform the data
    return !data
      ? null
      : data.reduce((list, entry) => {
          let clone = null;
          if (predicate(entry)) {
            // if the object matches the filter, clone it as it is
            clone = { ...entry };
          }
          if (entry.children != null) {
            // if the object has childrens, filter the list of children
            const children = this.filterRecursive(entry.children, predicate);
            if (children.length > 0) {
              // if any of the children matches, clone the parent object, overwrite
              // the children list with the filtered list
              clone = { ...entry, children };
            }
          }

          // if there's a cloned object, push it to the output list
          clone && list.push(clone);
          return list;
        }, []);
  }

  static sanitizeFormData(obj) {
    let newObj = {};
    _.forOwn(obj, function (value, key) {
      if (value !== null || value !== undefined) {
        if (
          typeof value === "object" &&
          !_.isDate(value) &&
          value !== null &&
          !_.isArray(value)
        ) {
          // single select - dropdown or date
          if (dayjs.isDayjs(value)) {
            newObj[key] = dayjs(value.$d).format("YYYY-MM-DD HH:mm:ss");
            if (value.$d) {
              let timeSuffix = "";
              if (value.$H > 0 || value.$m > 0 || value.$s > 0)
                timeSuffix = " HH:mm:ss";

              newObj[key] = dayjs(value.$d).format("YYYY-MM-DD" + timeSuffix);
            }
          } else newObj[key] = value.value;
        } else if (typeof value === "boolean") {
          // switch
          newObj[key] = value ? 1 : 0;
        } else if (typeof value === "number") {
          // number
          newObj[key] = value;
        } else if (_.isDate(value)) {
          // typeof date
          newObj[key] = value;
        } else if (
          typeof value === "object" &&
          _.isArray(value) &&
          value.length > 0 &&
          typeof value[0] !== "object"
        ) {
          // multi select - drop down or checkbox group
          // if (value && value !== undefined && value.length > 1) {
          //   const arrVal = value.map((v) => {
          //     return v.toString();
          //   });

          //   newObj[key] = arrVal.join(",");
          // } else {
          //   // single selected
          //   newObj[key] = value;
          // }
          // FOR NOW TREAT MULLTI SELECT SANITIZED VALUES AS ARRAY
          newObj[key] = value;
        } else {
          // text
          if (value && typeof value !== undefined && value.length > 0) {
            newObj[key] = value;
          }
        }
      }
    });

    if (_.isEmpty(newObj)) {
      return null;
    }
    return newObj;
  }

  static getSelectOptions(objArr, valueField, labelField) {
    let options = [];

    objArr.forEach((obj) => {
      options.push({
        value: _.get(obj, valueField),
        label: _.get(obj, labelField),
      });
    });

    return options;
  }

  static getKeyValueFromObj(obj, valueField) {
    let newObj = {};
    Object.keys(obj).forEach((objKey) => {
      newObj[objKey] = _.get(_.get(obj, objKey), valueField);
    });

    return newObj;
  }

  static getObjArrFromArr(arr, field) {
    let newArr = [];
    let newObj = {};
    arr.forEach((item) => {
      newObj[field] = item;
      newArr.push(newObj);
    });

    return newArr;
  }

  static getSwitchObjFromObject(obj, field) {
    let newObj = {};
    Object.keys(obj).forEach((item) => {
      newObj[_.get(_.get(obj, item), field)] = 1;
    });

    return newObj;
  }

  static getSwitchObjFromStringArr(arr) {
    let newObj = {};
    arr.forEach((item) => {
      newObj[item] = 1;
    });

    return newObj;
  }

  static not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  static intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  static trunc(text, len) {
    return _.truncate(text, { length: len ? len : 200 });
    return;
  }

  static abbrevNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "k", "m", "b", "t"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  static getRandomFloat(min, max) {
    return Math.random() * (max - min) + min;
  }

  static getFieldArrayFromObject(inputArray, parentObject, field) {
    let outputArray = [];

    inputArray.forEach((item, index) => {
      outputArray.push(_.get(_.get(parentObject, item), field));
    });

    return outputArray;
  }

  static getFieldArrayFromObjectArray(inputArray, fieldName) {
    let outputArray = [];

    inputArray.forEach((item) => {
      outputArray.push(item[fieldName]);
    });

    return outputArray;
  }

  static bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static mapKeysDeep(obj, id) {
    Object.entries(obj).forEach((entry) => {
      const [key, value] = entry;
      if (Array.isArray(obj[key])) {
        obj[key] = { ..._.mapKeys(obj[key], id) };
      }
      if (value && typeof value === "object") {
        this.mapKeysDeep(value, id);
      }
    });
  }

  static findInArr(fieldName, fieldValue, array) {
    var result;
    array.some(
      (o) =>
        (o[fieldName] === fieldValue && (result = o)) ||
        (result = this.findInArr(fieldValue, o.children || []))
    );
    return result;
  }

  static dateToStr(date, dateFormat) {
    return format(date, dateFormat);
  }

  static strToDate(dateStr, dateFormat) {
    return parse(dateStr, dateFormat, new Date());
  }

  static localDate(dateStr) {
    const localDate = DateTime.fromISO(dateStr).toLocaleString();
    if (localDate != null) return localDate;
    else return "NA";
  }

  static getDateYearFromMs(ms) {
    const date = new Date(ms);
    return NtaiUtils.getDateYear(date);
  }

  static getDateYear(date) {
    return date.getFullYear();
  }

  static getDateQuarterStringFromMs(ms) {
    const date = new Date(ms);
    return (
      "Q" +
      NtaiUtils.getDateQuarter(date) +
      "-" +
      NtaiUtils.getDateYearFromMs(ms)
    );
  }

  static getDateQuarterFromMs(ms) {
    const date = new Date(ms);
    return NtaiUtils.getDateQuarter(date);
  }

  static getDateQuarter(date) {
    return Math.floor((date.getMonth() + 3) / 3);
  }

  static getDateMonthStringFromMs(ms) {
    const date = new Date(ms);
    return NtaiUtils.getDateMonth(date) + "-" + NtaiUtils.getDateYearFromMs(ms);
  }

  static getDateMonthFromMs(ms) {
    const date = new Date(ms);
    return NtaiUtils.getDateMonth(date);
  }

  static getDateMonth(date) {
    return date.toLocaleString("default", { month: "short" });
  }

  static getDateWeekStringFromMs(ms) {
    const date = new Date(ms);
    return (
      "W" +
      NtaiUtils.getDateWeek(date) +
      " (" +
      NtaiUtils.getDateMonthStringFromMs(ms) +
      ")"
    );
  }

  static getDateWeekFromMs(ms) {
    const date = new Date(ms);
    return NtaiUtils.getDateWeek(date);
  }

  static getDateWeek(date) {
    const currentDate = typeof date === "object" ? date : new Date();
    const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
    const daysToNextMonday =
      januaryFirst.getDay() === 1 ? 0 : (7 - januaryFirst.getDay()) % 7;
    const nextMonday = new Date(
      currentDate.getFullYear(),
      0,
      januaryFirst.getDate() + daysToNextMonday
    );

    return currentDate < nextMonday
      ? 52
      : currentDate > nextMonday
      ? Math.ceil((currentDate - nextMonday) / (24 * 3600 * 1000) / 7)
      : 1;
  }

  static millisecondsToDate(ms) {
    return new Date(ms).toISOString().slice(0, 10);
  }

  static formatDateYYYYMONDD(date) {
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    return formattedDate;
  }

  static formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  static containsSpecialCharacters(str) {
    const regex = /[ !@#$%^&*()+\=\[\]{};':"\\|,<>\/?]/g;
    return regex.test(str);
  }

  // static formatDateCol(col) {
  //   console.log("date str: ", _.get(col[0], "colValue"));
  //   if (_.get(col[0], "colValue") !== null) {
  //     return NtaiUtils.localDate(_.get(col[0], "colValue"));
  //   } else return null;
  // }

  static pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
  // Get timezone offset in ISO format (+hh:mm or -hh:mm)

  static getTimezoneOffset = (date) => {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? "+" : "-";
    return diff + this.pad(tzOffset / 60) + ":" + this.pad(tzOffset % 60);
  };

  static toISOStringWithTimezone = (date) => {
    return (
      date.getFullYear() +
      "-" +
      this.pad(date.getMonth() + 1) +
      "-" +
      this.pad(date.getDate()) +
      "T" +
      this.pad(date.getHours()) +
      ":" +
      this.pad(date.getMinutes()) +
      ":" +
      this.pad(date.getSeconds()) +
      this.getTimezoneOffset(date)
    );
  };

  static isDateISOString = (val) => {
    const d = new Date(val);
    return !Number.isNaN(d.valueOf()) && d.toISOString() === val;
  };

  static isDateISOStringWithTimezone = (val) => {
    const d = new Date(val);
    return (
      !Number.isNaN(d.valueOf()) && this.toISOStringWithTimezone(d) === val
    );
  };

  static toGmtString(dateStr) {
    const date = new Date(dateStr);
    if (date != null) return date.toGMTString();
    else return "NA";
  }

  static toLocaleString(dateStr) {
    const date = new Date(dateStr);
    if (date != null) return date.toLocaleString();
    else return "NA";
  }

  static toLocaleDateString(dateStr) {
    const date = new Date(dateStr);
    if (date != null) return date.toLocaleDateString();
    else return "NA";
  }

  static toIntlDateString(dateStr) {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "short", day: "2-digit" };
    if (date != null)
      return Intl.DateTimeFormat("en-GB", options)
        .format(date)
        .replace(/ /g, "-");
    else return "NA";
  }

  static toISOString(dateStr) {
    const date = new Date(dateStr);
    if (date != null) return date.toISOString();
    else return "NA";
  }

  static formatDateCol(dateValue) {
    return dateValue ? NtaiUtils.toIntlDateString(dateValue) : "";
  }

  static formatDateTimeCol(dateValue) {
    return dateValue ? NtaiUtils.toLocaleString(dateValue) : "";
  }

  static isArraysEquals(x, y) {
    return _.isEmpty(_.xorWith(x, y, _.isEqual));
  }

  static roundDecimal(value, numOfDecimalPlaces) {
    if (_.isNumber(value)) return Number(value).toFixed(numOfDecimalPlaces);
    else return value;
  }

  static sortObjectOnKeys(obj) {
    let ordered = {};
    _(obj)
      .keys()
      .sort()
      .each(function (key) {
        ordered[key] = obj[key];
      });

    return ordered;
  }

  static cronExpressionToString(cronExpression) {
    if (cronExpression && cronExpression.length > 0)
      return cronstrue.toString(cronExpression);
    else return "Once";
  }

  static isValidSparkDatasetName(name) {
    // Regular expression to validate Spark dataset name
    const regex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

    // Check if name is valid and has a reasonable length (let's say up to 255 characters)
    if (typeof name === "string" && regex.test(name) && name.length <= 255) {
      return true;
    } else {
      return "Not a valid dataset name";
    }
  }

  static isValidColumnName(columnName) {
    // Check if the input is a non-empty string
    if (typeof columnName !== "string" || columnName.trim() === "") {
      return "Not a valid name";
    }

    // Define the validation regex:
    // - Start with a letter or underscore
    // - Followed by letters, numbers, or underscores
    // - Maximum length: 128 characters
    const regex = /^[a-zA-Z][a-zA-Z0-9_]{0,127}$/;

    const check = regex.test(columnName);

    if (!check) return "Not a valid name";

    return check;
  }

  static getColorFromColorSchemes(fgbg, value, defaultColor, colorSchemes) {
    let result = defaultColor;

    if (_.isArray(colorSchemes) && colorSchemes.length > 0) {
      colorSchemes.forEach((cs) => {
        if (
          (cs["exactValue"] && cs["exactValue"].length > 0) ||
          cs["typeCode"] === 1
        ) {
          if (_.isString(value)) {
            if (value.toLowerCase() === cs["exactValue"].toLowerCase()) {
              result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
              return;
            }
          } else if (_.isNumber(value)) {
            if (value === cs["exactValue"] * 1) {
              result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
              return;
            }
          }
        } else {
          if (
            _.isNumber(parseFloat(cs["ruleGtValue"])) &&
            _.isNumber(parseFloat(cs["ruleLtValue"])) &&
            _.isNumber(value)
          ) {
            if (
              parseFloat(cs["ruleGtValue"]) < value &&
              parseFloat(cs["ruleLtValue"]) > value
            ) {
              result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
              return;
            }
          } else if (
            _.isString(cs["ruleGtValue"]) &&
            _.isString(cs["ruleGtValue"]) &&
            _.isString(value)
          ) {
            if (cs["ruleGtValue"] < value && cs["ruleLtValue"] > value) {
              result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
              return;
            }
          }
        }
      });
    }

    return result;
  }

  // Function to validate a database column name
  static validateDBColumnName(columnName) {
    // List of reserved SQL keywords (you may want to expand this list depending on the SQL dialect)
    const reservedKeywords = [
      "SELECT",
      "INSERT",
      "DELETE",
      "UPDATE",
      "FROM",
      "WHERE",
      "JOIN",
      "TABLE",
      "COLUMN",
      "CREATE",
      "ALTER",
      "DROP",
      "INDEX",
      "VIEW",
      "DISTINCT",
      "COUNT",
      "GROUP",
      "BY",
      "ORDER",
      "PRIMARY",
      "KEY",
      "FOREIGN",
      "NOT",
      "NULL",
      "AND",
      "OR",
      "IN",
      "LIKE",
    ];

    // Ensure the column name is a string
    if (typeof columnName !== "string") {
      return { isValid: false, message: "Column name must be a string" };
    }

    // Check length constraints (e.g., max 64 characters for MySQL)
    if (columnName.length === 0 || columnName.length > 64) {
      return {
        isValid: false,
        message: "Column name must be between 1 and 64 characters",
      };
    }

    // Check that the column name starts with a letter
    if (!/^[a-zA-Z]/.test(columnName)) {
      return {
        isValid: false,
        message: "Column name must start with a letter",
      };
    }

    // Ensure the column name only contains valid characters (letters, numbers, and underscores)
    if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(columnName)) {
      return {
        isValid: false,
        message:
          "Column name can only contain letters, numbers, and underscores",
      };
    }

    // Check if the column name is a reserved SQL keyword
    if (reservedKeywords.includes(columnName.toUpperCase())) {
      return {
        isValid: false,
        message: `Column name cannot be a reserved keyword: ${columnName}`,
      };
    }

    if (columnName.toUpperCase().startsWith("sys_")) {
      return {
        isValid: false,
        message: `Column name cannot start with sys_: ${columnName}`,
      };
    }

    // If all checks pass, return valid
    return { isValid: true, message: "Valid column name" };
  }

  static validateDBColumnNameForRHF(columnName) {
    const val = NtaiUtils.validateDBColumnName(columnName);
    if (val.isValid) return true;
    else return val.message;
  }

  static getUserInitials(firstName, lastName) {
    if (!firstName || !lastName) {
      return "??";
    }

    const firstInitial = firstName.trim().charAt(0).toUpperCase();
    const lastInitial = lastName.trim().charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }

  static getPostgresDataTypeText(
    type,
    length = null,
    precision = null,
    scale = null
  ) {
    console.log("Db col type: ", type, length, precision, scale);
    switch (type.trim().toLowerCase()) {
      case "varchar":
      case "char":
      case "character varying":
      case "character":
      case "text":
        return length ? `${type}(${length})` : type;

      case "decimal":
      case "numeric":
        if (precision !== null && scale !== null) {
          return `${type}(${precision},${scale})`;
        } else if (precision !== null) {
          return `${type}(${precision})`;
        }
        return type;

      case "integer":
      case "bigint":
      case "smallint":
      case "serial":
      case "bigserial":
      case "real":
      case "double precision":
      case "boolean":
      case "bytea":
      case "json":
      case "jsonb":
      case "uuid":
      case "inet":
        return type;

      case "date":
      case "time":
      case "timestamp":
        if (precision !== null) {
          return `${type}(${precision})`;
        }
        return type;

      case "timestamp with time zone":
      case "timestamp without time zone":
      case "time with time zone":
      case "time without time zone":
        if (precision !== null) {
          return `${type}(${precision})`;
        }
        return type;

      case "interval":
        return precision !== null ? `${type}(${precision})` : type;

      case "bit":
      case "bit varying":
      case "varbit":
        return length ? `${type}(${length})` : type;

      case "array":
        return `${type}[]`;

      default:
        return type;
    }
  }
}

export default NtaiUtils;
