import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceFunctionPackages = createAsyncThunk(
  "sourceFunctionPackages/getSourceFunctionPackages",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/packages`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceFunctionPackage = createAsyncThunk(
  "sourceFunctionPackages/createSourceFunctionPackage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/packages`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceFunctionPackage = createAsyncThunk(
  "sourceFunctionPackages/deleteSourceFunctionPackage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/packages/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceFunctionPackagesSlice = createSlice({
  name: "sourceFunctionPackages",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceFunctionPackages.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceFunctionPackages" };
    },
    [getSourceFunctionPackages.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceFunctionPackages",
        message: action.payload.message,
      };
    },

    [createSourceFunctionPackage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceFunctionPackage",
      };
    },
    [createSourceFunctionPackage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceFunctionPackage",
        message: action.payload.message,
      };
    },

    [deleteSourceFunctionPackage.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceFunctionPackage",
      };
    },
    [deleteSourceFunctionPackage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceFunctionPackage",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceFunctionPackagesSlice.actions;

export default sourceFunctionPackagesSlice.reducer;
