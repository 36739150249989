import SourceDerivedFieldList from "./SourceDerivedFieldList";

const SourceDerivedFieldConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/derived-fields",
      exact: true,
      component: SourceDerivedFieldList,
    },
  ],
};

export default SourceDerivedFieldConfig;
