import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import NtaiDnd3 from "@ntai/components/dnd3/NtaiDnd3";
import { faClose, faFillDrip, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSourceFields from "../../hooks/useSourceFields";
import T from "app/main/pages/dashboard/T";
import { text } from "@fortawesome/fontawesome-svg-core";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

function transformSelectedFields(selectedFields, allFields) {
  let result = [];
  if (_.isArray(selectedFields)) {
    selectedFields.forEach((f, i) => {
      const field = _.filter(allFields, { uuId: f });
      result.push({
        uuId: f,
        text: field[0]["label"],
      });
    });
  }

  return result;
}

function transformAvailableFields(selectedFields, allFields) {
  const revisedFields = _.filter(
    _.orderBy(allFields, ["label"], ["asc"]),
    function (f) {
      return selectedFields.includes(f["uuId"]) ? false : true;
    }
  );

  return revisedFields;
}

export default function SourceAdjudicationImportCsvSchemeFields(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset } = useFormContext();
  const { handleFieldsUpdate } = props;
  const [currentId, setCurrentId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [sourceFieldsLoaded, setSourceFieldsLoaded] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceAdjudicationImportCsvSchemeId = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.activeId
  );

  const sourceAdjudicationImportCsvSchemeDataMap = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.data
  );

  const sourceAdjudicationImportCsvSchemeData =
    sourceAdjudicationImportCsvSchemeDataMap &&
    !_.isEmpty(sourceAdjudicationImportCsvSchemeDataMap)
      ? sourceAdjudicationImportCsvSchemeDataMap[
          sourceAdjudicationImportCsvSchemeId
        ]
      : null;

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    // uuId: listingId,
  };

  const mergedSourceFields = useSourceFields({
    sourceDefId: sourceDefId,
    sourceId: sourceId,
  });

  function handleReorder(items) {
    handleFieldsUpdate(_.map(items, (o) => o["uuId"]));
  }

  function handleAddRemove(action, fieldUuId) {
    let fields = [];
    const existingFieldsCopy =
      sourceAdjudicationImportCsvSchemeData &&
      _.has(sourceAdjudicationImportCsvSchemeData, "sourceFieldUuIds")
        ? sourceAdjudicationImportCsvSchemeData["sourceFieldUuIds"]
        : [];
    if (action === "add") {
      fields = [...existingFieldsCopy, fieldUuId];
    } else if (action === "remove") {
      fields = _.filter(existingFieldsCopy, function (f) {
        if (f !== fieldUuId) return true;
      });
    }

    handleFieldsUpdate(fields);
  }

  function handleSecondaryAction(action, fieldUuId) {
    if (action === "remove") handleAddRemove("remove", fieldUuId);
  }

  function handleRemoveAll() {
    handleFieldsUpdate([]);
  }

  //   function handleUpdate(sourceRecordListingFields) {
  //     dispatch(
  //       updateSourceRecordListing({
  //         sourceDefId: sourceDefId,
  //         sourceId: sourceId,
  //         uuId: listingId,
  //         formData: {
  //           typeCode: 1,
  //           updateTypeFg: 2,
  //           sourceRecordListingFields: sourceRecordListingFields,
  //         },
  //       })
  //     );
  //   }

  //   useEffect(() => {
  //     const tmpSelectedFields = transformSelectedFields(
  //       _.get(listingData, "sourceRecordListingFields")
  //     );

  //     setSelectedFields([...tmpSelectedFields]);
  //   }, [listingData]);

  useEffect(() => {
    if (sourceFieldsLoaded) {
      const tmpAvailableFields = transformAvailableFields(
        sourceAdjudicationImportCsvSchemeData &&
          _.has(sourceAdjudicationImportCsvSchemeData, "sourceFieldUuIds")
          ? sourceAdjudicationImportCsvSchemeData["sourceFieldUuIds"]
          : [],
        mergedSourceFields
      );

      const tmpSelectedFields = transformSelectedFields(
        sourceAdjudicationImportCsvSchemeData &&
          _.has(sourceAdjudicationImportCsvSchemeData, "sourceFieldUuIds")
          ? sourceAdjudicationImportCsvSchemeData["sourceFieldUuIds"]
          : [],
        mergedSourceFields
      );

      setAvailableFields([...tmpAvailableFields]);
      setSelectedFields([...tmpSelectedFields]);
      setLoaded(true);
    }
  }, [sourceFieldsLoaded, sourceAdjudicationImportCsvSchemeData]);

  useEffect(() => {
    if (_.isArray(mergedSourceFields) && mergedSourceFields.length > 0) {
      setSourceFieldsLoaded(true);
    }
  }, [mergedSourceFields]);

  return (
    <React.Fragment>
      {loaded && (
        <Box
          sx={{
            display: "flex",
            height: "270px",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "54px",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h6">
              Selected Fields ({selectedFields.length})
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <Button
                sx={{ fontSize: "12px" }}
                size="small"
                onClick={() => handleRemoveAll()}
              >
                REMOVE ALL
              </Button>
              {/* <Button sx={{ fontSize: "12px" }} variant="contained" size="small">
              ADD ALL
            </Button> */}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: `calc(100% - 54px)`,
              border: theme.general.border1,
            }}
          >
            <Box
              sx={{
                flexBasis: "40%",
                display: "flex",
                flexDirection: "column",
                borderRight: theme.general.border1,
              }}
            >
              {_.isArray(availableFields) && availableFields.length > 0 && (
                <Scrollbar>
                  <List sx={{ p: 0 }}>
                    {_.orderBy(availableFields, ["label"], ["asc"]).map(
                      (fd, i) => (
                        <React.Fragment key={`field-${i}`}>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: theme.spacing(1),
                              borderRadius: 0,
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                  color="inherit"
                                >
                                  {fd.label}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                  }}
                                  variant="subtitle2"
                                >
                                  {fd.name}
                                </Typography>
                              }
                            />
                            <Button
                              sx={{ fontSize: "12px" }}
                              size="small"
                              startIcon={
                                <FontAwesomeIcon size="2xs" icon={faPlus} />
                              }
                              onClick={() => handleAddRemove("add", fd.uuId)}
                            >
                              ADD
                            </Button>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                  </List>
                </Scrollbar>
              )}
            </Box>
            <Box
              sx={{
                flexBasis: "60%",
                display: "flex",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  p: theme.spacing(2),
                }}
              >
                {selectedFields &&
                  _.isArray(selectedFields) &&
                  selectedFields.length > 0 && (
                    <NtaiDnd3
                      id="uuId"
                      label="text"
                      items={selectedFields}
                      handleReorder={handleReorder}
                      direction="horizontal"
                      secondaryActions={[{ value: "remove", icon: faClose }]}
                      handleSecondaryAction={handleSecondaryAction}
                    />
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {!loaded && <NtaiCircularProgress />}
    </React.Fragment>
  );
}
