import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../../../../../../../../http/ntaiServer";
const _ = require("lodash");

export const getAlertActivityUsers = createAsyncThunk(
  "alertActivityUsers/getAlertActivityUsers",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, activityId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/activities/${activityId}/users`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertActivityUsersSlice = createSlice({
  name: "alertActivityUsers",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertActivityUsers.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAlertActivityUsers" };
    },
    [getAlertActivityUsers.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertActivityUsers",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = alertActivityUsersSlice.actions;

export default alertActivityUsersSlice.reducer;
