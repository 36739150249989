import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getResources = createAsyncThunk(
  "resources/getResources",
  async (params, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/resources`,
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resourcesSlice = createSlice({
  name: "resources",
  initialState: {
    data: {},
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
  },
  extraReducers: {
    [getResources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getResources",
      };
    },
    [getResources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getResources",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus } = resourcesSlice.actions;

export default resourcesSlice.reducer;
