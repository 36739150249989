import { Box, IconButton, ListItem, Typography } from "@mui/material";
import { Divider, List, ListItemText, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "@ntai/@history";
import { enableDisableNotificationDef } from "./store/notificationDefsSlice";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const _ = require("lodash");

export default function NotificationDefList() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const dataMap = useSelector((state) => state.core.notificationDefsSlice.data);
  const status = useSelector((state) => state.core.notificationDefsSlice.status);
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleEnableDisable(uuId) {
    dispatch(enableDisableNotificationDef(uuId));
  }

  function handleEdit(uuId) {
    history.push(`/admin/notification-defs/${uuId}/edit`);
  }

  return (
    <List sx={{ border: theme.general.border1, p: 0 }}>
      {data &&
        _.isArray(data) &&
        _.orderBy(data, ["name"], ["asc"]).map((o, i) => (
          <React.Fragment key={`ntai-notification-${i}`}>
            <ListItem
              sx={{
                "&:hover": {
                  background: grey[100],
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: theme.spacing(1),
                  justifyContent: "space-between",
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      fontWeight="700"
                      color="inherit"
                    >
                      {o.name}
                    </Typography>
                  }
                  secondary={o["description"]}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(2),
                  }}
                >
                  <IconButton onClick={() => handleEdit(o["uuId"])}>
                    <FontAwesomeIcon size="2xs" icon={faEdit} />
                  </IconButton>
                  <NtaiSwitchField
                    checked={o["enabledFg"] === 1 ? true : false}
                    id="switch"
                    name={`notificationDefForm.notifications.${o.uuId}`}
                    onClick={(e) => handleEnableDisable(o.uuId)}
                  />
                </Box>
              </Box>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
    </List>
  );
}
