import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchDashboardEntities = createAsyncThunk(
  "searchDashboardInit/getSearchDashboardEntities",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/search-dashboard-init/entities`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchDashboardGroups = createAsyncThunk(
  "searchDashboardInit/getSearchDashboardGroups",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/dashboards`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchDashboardSourceUuIds = createAsyncThunk(
  "searchDashboardInit/getSearchDashboardSourceUuIds",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.put(
        `/search-dashboard-init/sources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchDashboardInitSlice = createSlice({
  name: "reports",
  initialState: {
    entities: {},
    dashboardGroups: {},
    sourceUuIds: null,
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchDashboardEntities.fulfilled]: (state, action) => {
      state.entities = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchDashboardEntities",
      };
    },
    [getSearchDashboardEntities.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchDashboardEntities",
        message: action.payload.message,
      };
    },
    [getSearchDashboardGroups.fulfilled]: (state, action) => {
      state.dashboardGroups = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSearchDashboardGroups" };
    },
    [getSearchDashboardGroups.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchDashboardGroups",
        message: action.payload.message,
      };
    },

    [getSearchDashboardSourceUuIds.fulfilled]: (state, action) => {
      state.sourceUuIds = action.payload;
      state.status = {
        result: "success",
        method: "getSearchDashboardSourceUuIds",
      };
    },
    [getSearchDashboardSourceUuIds.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchDashboardSourceUuIds",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchDashboardInitSlice.actions;

export default searchDashboardInitSlice.reducer;
