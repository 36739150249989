import { Box } from "@mui/material";
import React from "react";

export default function NtaiSplashLogo() {
  const logoIcon =
    process.env.REACT_APP_NTAI_WEB_BASE_URL + "/images/ntai_logo_splash.png";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <img height="auto" width="120px" src={logoIcon} />
    </Box>
  );
}
