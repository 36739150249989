import { Box, Typography, useTheme } from "@mui/material";
import NtaiDnd3 from "@ntai/components/dnd3/NtaiDnd3";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";
const _ = require("lodash");

export default function SourceWidgetReorderFields({ data, handleCallback }) {
  const theme = useTheme();

  function handleReorder(items) {
    let fields = [];

    items.forEach((item, index) => {
      fields.push({
        uuId: _.get(item, "uuId"),
        fieldOrder: index + 1,
        fieldName: _.get(item, "fieldName"),
      });
    });

    handleCallback(fields);
  }

  return (
    <NtaiPanel
      width="100%"
      subheader="Order series of categorical fields in hierarchical order to preserve grouping."
    >
      <NtaiDnd3
        items={data}
        direction="horizontal"
        id="uuId"
        label="fieldName"
        handleReorder={handleReorder}
      />
    </NtaiPanel>
  );
}
