import { faChartSimple, faCodeBranch } from "@fortawesome/pro-light-svg-icons";
import { faClose, faShapes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSourceDefinition } from "../../../store/sourceDefinitionsSlice";
const _ = require("lodash");

export default function SourceVersonSelectorMenu({
  handleSelect,
  handleClose,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? dataMap[sourceDefId] : null;

  function onHandleSelect(pSourceUuId) {
    if (pSourceUuId !== sourceId) {
      handleSelect(pSourceUuId);
    } else handleClose();
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceDefinition" &&
      status.result === "success"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceDefinition(sourceDefId));
  }, [sourceDefId]);

  return (
    <Box
      sx={{
        height: "240px",
        width: "200px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faCodeBranch} />
        <Typography variant="body2" fontWeight="700">
          Select Version
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {loaded && (
              <MenuList dense sx={{ p: "2px", width: "100%" }}>
                {data &&
                  Array.isArray(data["sourceMins"]) &&
                  data["sourceMins"].length > 0 &&
                  _.orderBy(
                    data["sourceMins"],
                    ["majorVersion", "minorVersion"],
                    ["asc", "asc"]
                  ).map((o, i) => {
                    return (
                      <MenuItem
                        key={o.uuId}
                        value={o.uuId}
                        divider={true}
                        onClick={() => onHandleSelect(o.uuId)}
                      >
                        <ListItemText>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "500" }}
                          >
                            {`Version ${
                              o["majorVersion"] + "." + o["minorVersion"]
                            }`}
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    );
                  })}
              </MenuList>
            )}
            {!loaded && <NtaiCircularProgress size={20} />}
          </Box>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
