import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiFilterBox from "./NtaiFilterBox";

const NtaiMuiRadioGroup = (props) => {
  const { label, name, value } = props;

  console.log("R Value: ", value);

  function generateRadios(radioOptions) {
    let radioFields = [];
    radioOptions.forEach((radio, index) => {
      radioFields.push(
        <FormControlLabel
          key={index}
          value={radio.value}
          control={
            <Radio
              size="small"
              sx={{ py: 0 }}
              checked={value === radio.value && true}
            />
          }
          label={
            <Typography variant="subtitle2" color="inherit">
              {radio.label}
            </Typography>
          }
        />
      );
    });

    return radioFields;
  }

  return (
    <NtaiFilterBox>
      <FormControl>
        <Typography variant="subtitle2" fontWeight="700">
          {label}
        </Typography>

        <RadioGroup
          sx={{ display: "flex", flexDirection: "column" }}
          row={props.row}
          name={props.name}
          {...props}
        >
          {generateRadios(props.options)}
        </RadioGroup>
      </FormControl>
    </NtaiFilterBox>
  );
};

export default function NtaiFilterRadioGroup(props) {
  const { control } = useFormContext();
  const { name } = props;
  return (
    <React.Fragment>
      <Controller
        defaultValue={false}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <NtaiMuiRadioGroup
            value={value ? value : undefined}
            onChange={(e, v) => onChange(v)}
            {...props}
          />
        )}
        control={control}
        {...props}
      />
    </React.Fragment>
  );
}
