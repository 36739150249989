import { faArchive, faReorder } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { ReportDefinitionSourceFilterContext } from "./ReportDefinitionSourceFilterWrapper";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function ReportDefinitionSourceFilterFields() {
  const theme = useTheme();

  const {
    sourceFields,
    availableFields,
    setAvailableFields,
    selectedFields,
    setSelectedFields,
    setSelectedField,
  } = React.useContext(ReportDefinitionSourceFilterContext);

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionParamsSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleSelect(sourceFieldUuId) {
    const tmpSelectedField = _.filter(data, {
      sourceFieldUuId: sourceFieldUuId,
    })[0];

    setSelectedField({ ...tmpSelectedField });
  }

  function handleRemove(sourceFieldUuId) {
    if (selectedFields.includes(sourceFieldUuId))
      setSelectedFields(
        _.filter(selectedFields, function (d) {
          return d !== sourceFieldUuId;
        })
      );

    setAvailableFields([...availableFields, sourceFields[sourceFieldUuId]]);
  }

  function handleRemoveAll() {
    setSelectedFields([]);
    setAvailableFields([...Object.values(sourceFields)]);
  }

  return (
    <Box
      sx={{
        flexBasis: "25%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRight: theme.general.border1,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(2),
          height: "54px",
          display: "flex",
          borderBottom: theme.general.border1,
          alignItems: "center",
        }}
      >
        <Box sx={{ flexBasis: "70%", display: "flex" }}>
          <Typography variant="h6">Selected Fields</Typography>
        </Box>
        <Box
          sx={{
            flexBasis: "30%",
            display: "flex",
            gap: theme.spacing(1),
            justifyContent: "end",
          }}
        >
          <IconButton disabled={selectedFields.length > 0 ? false : true}>
            <FontAwesomeIcon size="2xs" icon={faReorder} />
          </IconButton>
          <IconButton
            onClick={() => handleRemoveAll()}
            disabled={selectedFields.length > 0 ? false : true}
          >
            <FontAwesomeIcon size="2xs" icon={faArchive} />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: `calc(100% - 54px)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Scrollbar>
          {selectedFields.length > 0 && (
            <List sx={{ p: 0 }}>
              {selectedFields.map((sf, i) => (
                <React.Fragment key={`source-selected-field-${i}`}>
                  <ListItem
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        background: grey[100],
                      },
                    }}
                    secondaryAction={
                      <IconButton onClick={() => handleRemove(sf)}>
                        <FontAwesomeIcon size="2xs" icon={faArchive} />
                      </IconButton>
                    }
                    onClick={() => handleSelect(sf)}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          fontWeight="700"
                        >
                          {_.get(sourceFields[sf], "label")}
                        </Typography>
                      }
                      secondary={_.get(sourceFields[sf], "name")}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
          {selectedFields.length === 0 && (
            <NtaiEmptyMessage
              header="No fields selected"
              subheader="Add fields from left panel to build report parameters"
            />
          )}
        </Scrollbar>
      </Box>
    </Box>
  );
}
