import { combineReducers } from "@reduxjs/toolkit";
import reportsSlice from "../reports/store/reportsSlice";
import reportDefinitionsSlice from "../admin/definition/store/reportDefinitionsSlice";
import reportDefinitionParamsSlice from "../admin/definition/param/store/reportDefinitionParamsSlice";
import reportDefinitionCategoriesSlice from "../admin/category/store/reportDefinitionCategoriesSlice";
import reportSourceFilterFieldsSlice from "../reports/main/criteria/source-criteria/store/reportSourceFilterFieldsSlice";
import reportFoldersSlice from "../admin/folder/store/reportFoldersSlice";
import reportScheduleSlice from "../reports/main/schedule/store/reportScheduleSlice";
import reportScheduledReportsSlice from "../reports/main/scheduled-reports/store/reportScheduledReportsSlice";

const reportsReducer = combineReducers({
  reportsSlice,
  reportDefinitionsSlice,
  reportDefinitionParamsSlice,
  reportDefinitionCategoriesSlice,
  reportSourceFilterFieldsSlice,
  reportFoldersSlice,
  reportScheduleSlice,
  reportScheduledReportsSlice,
});

export default reportsReducer;
