import React, { useContext, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import NtaiFlatMenu from "@ntai/components/menus/NtaiFlatMenu";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchInitSourceContext } from "./SearchInitSourcesWrapper";
const _ = require("lodash");

export default function SearchInitFilters() {
  const theme = useTheme();
  const [filters, setFilters] = useState("All");

  const { selectedFilter, setSelectedFilter } = React.useContext(
    SearchInitSourceContext
  );

  const searchInitData = useSelector(
    (state) => state.search.searchInitSlice.data
  );

  function handleSelect(item) {
    setSelectedFilter(item);
  }

  useEffect(() => {
    setFilters({
      All: _.sum(
        Object.values(
          _.countBy(
            searchInitData.searchInitSources,
            "searchSourceDefinition.categoryDecode"
          )
        )
      ),
      ...NtaiUtils.sortObjectOnKeys(
        _.countBy(
          searchInitData.searchInitSources,
          "searchSourceDefinition.categoryDecode"
        )
      ),
    });
  }, [searchInitData]);

  return (
    <Box sx={{ flexBasis: "15%", padding: theme.spacing(2) }}>
      <NtaiFlatMenu
        label="FILTER BY"
        data={filters}
        selected={selectedFilter}
        handleSelect={handleSelect}
      />
    </Box>
  );
}
