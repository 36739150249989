import React from "react";
import { Box, useTheme } from "@mui/material";
import NtaiJsonToTable from "./NtaiJsonToTable";
import NtaiJsonToTable1 from "./NtaiJsonToTable1";
import ReactJson from "react-json-view";
import NtaiTabs from "../tabs/NtaiTabs";
import NtaiJsonToHtml from "./NtaiJsonToHtml";
import TestSourceJsonToTable from "./source-record-viewer/TestSourceJsonToTable";

const parse = require("html-react-parser");

const json2 = {
  type: "struct",
  fields: [
    {
      name: "nct_id",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "date_started",
      type: "date",
      nullable: true,
      metadata: {},
    },
    {
      name: "brief_title",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "official_title",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "phase",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "status",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "enrollment",
      type: "integer",
      nullable: true,
      metadata: {},
    },
    {
      name: "country",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "facility_name",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "facility_city_country",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "condition",
      type: "string",
      nullable: true,
      metadata: {},
    },
    {
      name: "intervention",
      type: "string",
      nullable: true,
      metadata: {},
    },
  ],
};
const json = {
  "AER No": "VMMV100039",
  Country: "United States",
  Gender: "M",
  "Received Date": "12 Dec, 2009",
  Drugs: [
    {
      Product: "Advil",
      Form: "Tablet",
      ActiveSubstances: [
        {
          Name: "Acetaminophen",
          Esther: "None",
        },
        {
          Name: "Something",
          Esther: "None",
        },
      ],
    },
    {
      Product: "Tylenol23",
      Form: "Capsule",
      ActiveSubstances: [
        {
          Name: "Acetaminophen",
          Esther: "None",
        },
        {
          Name: "Something",
          Esther: "None",
        },
      ],
    },
  ],
};

export default function TestNtaiJsonToHtml() {
  const theme = useTheme();
  return (
    <NtaiTabs>
      {/* <Box
        label="JSON TO TABLE"
        sx={{
          display: "flex",
          h: "100%",
          w: "100%",
          p: theme.spacing(4),
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(2),
        }}
      >
        <NtaiJsonToTable1 json={json} />
      </Box>
      <Box
        label="REACT JSON VIEW"
        sx={{
          display: "flex",
          h: "100%",
          w: "100%",
          p: theme.spacing(4),
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(2),
        }}
      >
        <ReactJson src={json} />
      </Box>
      <Box
        label="RAW HTML"
        sx={{
          display: "flex",
          h: "100%",
          w: "100%",
          p: theme.spacing(4),
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(2),
        }}
      >
        {parse(
          '<div>many texts will come here but let\'s just highlight important ones <span style="background-color:lightblue; padding: 4px">Myocardial infarction <b>ENTITY</b></span></div>'
        )}
      </Box> */}

      <Box
        label="JSON TO HTML"
        sx={{
          display: "flex",
          h: "100%",
          w: "100%",
          p: theme.spacing(4),
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(2),
        }}
      >
        <TestSourceJsonToTable />
      </Box>

      <Box
        label="REACT JSON VIEW"
        sx={{
          display: "flex",
          h: "100%",
          w: "100%",
          p: theme.spacing(4),
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(2),
        }}
      >
        <ReactJson src={json2} />
      </Box>
    </NtaiTabs>
  );
}
