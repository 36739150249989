import Tests from "./logins/Tests";

const TestsConfig = {
  routes: [
    {
      path: "/tests",
      exact: true,
      component: Tests,
    },
    {
      path: "/tests/l",
      exact: true,
      component: Tests,
    },
  ],
};

export default TestsConfig;
