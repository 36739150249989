import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceWidgetFieldFilterForm from "../filter/SourceWidgetFieldFilterForm";
import SourceWidgetFormPanel from "./SourceWidgetFormPanel";
import SourceWidgetFieldsForm from "../fields/SourceWidgetFieldsForm";
import SourceWidgetUtil from "../../util/SourceWidgetUtil";
import SourceWidgetFieldColorFormat from "../color-scheme/SourceWidgetFieldColorFormat";
import SourceWidgetReorderFields from "../reorder/SourceWidgetReorderFields";
import SourceWidgetTitle from "./SourceWidgetTitle";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

const BUBBLE_CHART = 1;
const SCATTER_CHART = 2;

function getChartCode(widget) {
  if ([CHART_CODES.BUBBLE].includes(widget["widget"]["contentCode"]))
    return BUBBLE_CHART;
  else if ([CHART_CODES.SCATTER].includes(widget["widget"]["contentCode"]))
    return SCATTER_CHART;
}

export default function SourceWidgetScatterChartForm({
  widget,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const [widgetFields, setWidgetFields] = useState(
    widget["sourceWidgetFields"]
  );

  const chartCode = getChartCode(widget);

  const measureFields = SourceWidgetUtil.getMeasureWidgetFields(widgetFields);

  const measureFieldOptions =
    measureFields && _.isArray(measureFields) && measureFields.length > 0
      ? NtaiUtils.getSelectOptions(measureFields, "uuId", "fieldName")
      : [];

  function handleReorderFieldsCallback(fields) {
    if (!_.isEqual(fields, widgetFields)) setWidgetFields([...fields]);
  }

  function onHandleSave() {
    const widgetFormData = getValues("widgetForm");

    const sanitizedFormData = NtaiUtils.sanitizeFormData(widgetFormData);

    let revisedSourceWidgetFields = _.cloneDeep(widget["sourceWidgetFields"]);
    const sanitizedFormFieldsData = sanitizedFormData["sourceWidgetFields"];

    revisedSourceWidgetFields.forEach((f, i) => {
      const sanitizedFormFieldData = NtaiUtils.sanitizeFormData(
        _.filter(sanitizedFormFieldsData, {
          uuId: f["uuId"],
        })[0]
      );

      f["headerName"] = sanitizedFormFieldData["headerName"];

      if (
        chartCode === BUBBLE_CHART &&
        sanitizedFormData["nonXYChartSizeFieldUuId"] &&
        sanitizedFormData["nonXYChartSizeFieldUuId"].length > 1 &&
        f["uuId"] === sanitizedFormData["nonXYChartSizeFieldUuId"]
      )
        f["sizeFg"] = 1;
      else f["sizeFg"] = null;

      if (SourceWidgetUtil.isWidgetFieldMeasure(f)) {
        f["metricCode"] = sanitizedFormFieldData["metricCode"];
        f["esAggFilterScript"] = sanitizedFormFieldData["esAggFilterScript"];
      }

      if (SourceWidgetUtil.isWidgetFieldDimension(f)) {
        let sourceWidgetFieldFiltersResult = [];
        if (_.isArray(sanitizedFormFieldData["sourceWidgetFieldFilters"])) {
          const sourceWidgetFieldFilters = _.get(
            sanitizedFormFieldData,
            "sourceWidgetFieldFilters"
          );

          if (
            _.isArray(sourceWidgetFieldFilters) &&
            sourceWidgetFieldFilters.length > 0
          ) {
            sourceWidgetFieldFilters.forEach((swf) => {
              if (swf["typeCode"] !== undefined && swf["typeCode"]["value"]) {
                sourceWidgetFieldFiltersResult.push({
                  value: swf["value"],
                  typeCode: swf["typeCode"]["value"],
                });
              }
            });
            f["sourceWidgetFieldFilters"] = sourceWidgetFieldFiltersResult;
          }
        }
      }
    });

    sanitizedFormData["sourceWidgetFields"] = revisedSourceWidgetFields;

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    let widgetCopy = _.cloneDeep(widget);
    reset({ widgetForm: widgetCopy });
  }, [widget]);

  return (
    <SourceWidgetFormPanel
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <NtaiTabs>
        <Box label="TITLE">
          <SourceWidgetTitle>
            <NtaiSwitchField
              name="widgetForm.nonAggregateResultFg"
              label="Do not aggregate results?"
            />
          </SourceWidgetTitle>
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FIELDS">
          <SourceWidgetFieldsForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="AXIS">
          <NtaiPanel
            height="100%"
            subheader="Select fields for X-Axis, Y-Axis and Bubble radius"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              {measureFieldOptions && measureFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={measureFieldOptions}
                  name="widgetForm.xAxisFieldUuId"
                  label="X-Axis Field"
                />
              )}

              {measureFieldOptions && measureFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={measureFieldOptions}
                  name="widgetForm.yAxisFieldUuId"
                  label="Y-Axis Field"
                />
              )}
            </Box>

            {chartCode === BUBBLE_CHART &&
              measureFieldOptions &&
              measureFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={measureFieldOptions}
                  name="widgetForm.nonXYChartSizeFieldUuId"
                  label="Bubble Radius Field"
                />
              )}
          </NtaiPanel>
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="ORDER">
          <SourceWidgetReorderFields
            data={_.orderBy(
              SourceWidgetUtil.getDimensionWidgetFields(widgetFields),
              ["fieldOrder"],
              ["asc"]
            )}
            widget={widget}
            handleCallback={handleReorderFieldsCallback}
          />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FILTERS">
          <SourceWidgetFieldFilterForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="COLOR">
          <SourceWidgetFieldColorFormat
            handleWidgetSave={handleSave}
            widget={widget}
          />
        </Box>
      </NtaiTabs>
    </SourceWidgetFormPanel>
  );
}
