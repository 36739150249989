import axios from "axios";

let instance = axios.create({
  baseURL: process.env.REACT_APP_NTAI_REST_URL,
  headers: {
    common: {
      "Content-Type": "application/json",
      "X-TenantID": localStorage.getItem("TENANT_ID"),
    },
  },
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

// Add a response interceptor
// instance.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       try {
//         const refreshToken = localStorage.getItem("refreshToken");

//         console.log("Retry...: ", refreshToken);
//         JwtService.refreshToken();
//         const accessToken = localStorage.getItem("accessToken");
//         originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//         return axios(originalRequest);
//       } catch (error) {
//         // Handle refresh token error or redirect to login
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   (response) => {
//     // Configure your response here
//     // use 'params' where ever you need. You can use refreshToken
//     // here to get new accessToken if previous accessToken expired.
//     // Also you can dispatch events to Redux from here about new accessToken.
//     // Whenever Redux will update with new token, whole component will re-render
//     // where ever we extracted data from Redux. In that case, Our Axios instance
//     // will be configured with the new token. So, No need to configure header here.
//   },
//   (error) => {
//     console.log("Rejected...");
//     return Promise.reject(error);
//   }
// );

export default instance;
