import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { resetIdCounter } from "downshift";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import SourceWidgetUtil from "../../util/SourceWidgetUtil";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { useDispatch, useSelector } from "react-redux";
import SourceWidgetFormPanel from "../widget-forms/SourceWidgetFormPanel";
const _ = require("lodash");

export default function SourceWidgetFieldFilterForm({ widget }) {
  const theme = useTheme();
  const { setValue, reset } = useFormContext();
  const dispatch = useDispatch();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const ieOptions = _.has(
    codes,
    CODELIST_CODES.SRC_SOURCE_WIDGET_FILTER_INCLUDE_EXCLUDE_CODE
  )
    ? _.get(codes, CODELIST_CODES.SRC_SOURCE_WIDGET_FILTER_INCLUDE_EXCLUDE_CODE)
    : [];

  const widgetFields = widget ? widget["sourceWidgetFields"] : [];

  const [selectedField, setSelectedField] = useState(
    _.isArray(widgetFields) ? _.get(widgetFields[0], "uuId") : null
  );

  function selectField(widgetField) {
    setSelectedField({ ...widgetField });
  }

  useEffect(() => {
    if (widgetFields && _.isArray(widgetFields)) {
      setSelectedField({ ...widgetFields[0] });
    }
  }, [widget]);

  useEffect(() => {
    dispatch(
      getCodes(CODELIST_CODES.SRC_SOURCE_WIDGET_FILTER_INCLUDE_EXCLUDE_CODE)
    );
  }, []);

  return (
    <NtaiPanel
      subheader="Use filter option to filter field values shown on the chart"
      width="100%"
    >
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
        {selectedField &&
          widgetFields &&
          _.isArray(widgetFields) &&
          widgetFields.length > 0 && (
            <>
              <Box
                sx={{ pt: theme.spacing(1), flexBasis: "40%", display: "flex" }}
              >
                <List
                  sx={{
                    border: theme.general.border1,
                    p: 0,
                    width: "100%",
                  }}
                >
                  {widgetFields.map((wf, i) => (
                    <React.Fragment>
                      <ListItemButton
                        key={`source-widget-field-filter-${i}`}
                        sx={{ borderRadius: 0 }}
                        selected={
                          wf.uuId === selectedField["uuId"] ? true : false
                        }
                        onClick={() => selectField(wf)}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontWeight="700"
                            >
                              {wf.fieldName}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Box>
              <Box
                sx={{ pt: theme.spacing(1), flexBasis: "60%", display: "flex" }}
              >
                <Box
                  sx={{
                    px: theme.spacing(1),
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(1),
                  }}
                >
                  <Typography variant="h6" fontWeight="700">
                    {selectedField ? _.get(selectedField, "fieldName") : null}
                  </Typography>

                  {widgetFields.map((wf, i) => (
                    <Box
                      key={`source-widget-field-filter-${i}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(1),
                      }}
                    >
                      {wf.uuId === selectedField["uuId"] && (
                        <React.Fragment>
                          {SourceWidgetUtil.isWidgetFieldDimension(wf) && (
                            <NtaiSelectField
                              key={`source-widget-field-filter-select-${i}`}
                              name={`widgetForm.sourceWidgetFields[${i}].sourceWidgetFieldFilters[0].typeCode`}
                              options={ieOptions}
                              label="Include/Exclude"
                            />
                          )}
                          {SourceWidgetUtil.isWidgetFieldDimension(wf) && (
                            <NtaiTextField
                              key={`source-widget-field-textfield-${i}`}
                              name={`widgetForm.sourceWidgetFields[${i}].sourceWidgetFieldFilters[0].value`}
                              multiline
                              minRows={4}
                              maxRows={4}
                              placeholder="Enter values to be included/excluded"
                            />
                          )}

                          {(SourceWidgetUtil.isWidgetFieldMeasure(wf) ||
                            SourceWidgetUtil.isWidgetFieldDate(wf)) && (
                            <NtaiTextField
                              key={`source-widget-field-textfield-filter-${i}`}
                              name={`widgetForm.sourceWidgetFields[${i}].esAggFilterScript`}
                              multiline
                              minRows={4}
                              maxRows={4}
                              placeholder="Enter aggregate field filter script"
                            />
                          )}
                        </React.Fragment>
                      )}

                      {/* {wf.uuId === selectedField["uuId"] &&
                        SourceWidgetUtil.isWidgetFieldMeasure(wf) && (
                          <React.Fragment>
                            <NtaiTextField
                              key={`source-widget-field-textfield-filter-${i}`}
                              name={`widgetForm.sourceWidgetFields[${i}].esAggFilterScript`}
                              multiline
                              minRows={2}
                              maxRows={2}
                              placeholder="Enter aggregate field filter script"
                            />
                          </React.Fragment>
                        )} */}
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}
      </Box>
    </NtaiPanel>
  );
}
