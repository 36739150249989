import AlertTrackerInfo from "./AlertTrackerInfo";
import AlertTrackerList from "./AlertTrackerList";

const AlertTrackerConfig = {
  routes: [
    {
      path: "/monitor/tracker",
      exact: true,
      component: AlertTrackerList,
    },
    {
      path: "/monitor/tracker/alerts/:id/edit",
      exact: true,
      component: AlertTrackerInfo,
    },
  ],
};

export default AlertTrackerConfig;
