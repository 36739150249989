import SearchConfig from "./search/SearchConfig";
import MonitorConfig from "./monitor/MonitorConfig";
import OversightConfig from "./oversight/OversightConfig";
import ReportsConfig from "./reports/ReportsConfig";
import ToolsConfig from "./tools/ToolsConfig";

const ModulesConfig = {
  routes: [
    ...SearchConfig.routes,
    ...MonitorConfig.routes,
    ...OversightConfig.routes,
    ...ReportsConfig.routes,
    ...ToolsConfig.routes,
  ],
};

export default ModulesConfig;
