import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodelists } from "app/main/pages/core/admin/codelist/store/codelistsSlice";

const _ = require("lodash");

export default function ReportDefinitionParamForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset, watch, setValue } = useFormContext();
  const dispatch = useDispatch();
  const [displayCodelistField, setDisplayCodelistField] = useState(false);

  const codelistCodesData = useSelector(
    (state) => state.core.codelistsSlice.codes
  );

  const paramFieldUIOptions = _.get(
    codelistCodesData,
    CODELIST_CODES.RPT_DEFINITION_PARAM_FIELD_UI_CODE
  );

  const codelistsDataMap = useSelector(
    (state) => state.core.codelistsSlice.data
  );

  const codelistsData =
    codelistsDataMap && !_.isEmpty(codelistsDataMap)
      ? Object.values(codelistsDataMap)
      : [];

  const codelistOptions = NtaiUtils.getSelectOptions(
    codelistsData,
    "uuId",
    "name"
  );

  const watchTypeCode = watch("reportDefinitionParamForm.typeCode");

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("reportDefinitionParamForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    if (
      (watchTypeCode &&
        watchTypeCode !== undefined &&
        typeof watchTypeCode === "object" &&
        [4, 5, 6, 7].includes(_.get(watchTypeCode, "value"))) ||
      [4, 5, 6, 7].includes(watchTypeCode)
    )
      setDisplayCodelistField(true);
    else {
      setValue("reportDefinitionParamForm.codelistUuId", null);
      setDisplayCodelistField(false);
    }
  }, [watchTypeCode]);

  useEffect(() => {
    reset({ reportDefinitionParamForm: action === "edit" ? formData : {} });

    if (
      formData &&
      !_.isEmpty(formData) &&
      [4, 5, 6, 7].includes(_.get(formData, "typeCode"))
    ) {
      setDisplayCodelistField(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getCodelists());
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Enter parameter name, description and UI type code"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="reportDefinitionParamForm.name"
          label="Name"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="reportDefinitionParamForm.extParamName"
          label="External Report Parameter Name*"
          rules={{ required: "External report parameter is required" }}
        />

        <NtaiTextField
          name="reportDefinitionParamForm.description"
          label="Description"
          multiline
          minRows={2}
          maxRows={2}
        />
        <NtaiSelectField
          name="reportDefinitionParamForm.typeCode"
          options={paramFieldUIOptions}
          label="Parameter UI Type*"
          rules={{ required: "Parameter UI type is required" }}
        />

        {displayCodelistField && (
          <NtaiSelectField
            name="reportDefinitionParamForm.codelistUuId"
            options={codelistOptions}
            label="Codelist"
          />
        )}
      </Box>
    </NtaiDialogFormPanel>
  );
}
