import ReportAdmin from "./ReportAdmin";
import ReportDefinitionCategoryConfig from "./category/ReportDefinitionCategoryConfig";
import ReportDefinitionConfig from "./definition/ReportDefinitionConfig";
import ReportFolderConfig from "./folder/ReportFolderConfig";

const ReportAdminConfig = {
  routes: [
    ...ReportDefinitionCategoryConfig.routes,
    ...ReportDefinitionConfig.routes,
    ...ReportFolderConfig.routes,
    {
      path: "/reports/admin",
      exact: true,
      component: ReportAdmin,
    },
  ],
};

export default ReportAdminConfig;
