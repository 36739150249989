import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiDateHistogramEChart from "./NtaiDateHistogramEChart";

const data = {
  series: ["date_started"],
  data: [
    {
      name: "date-started",
      data: [
        {
          time: "-2208988800000",
          value: {
            value: 6,
            total: 6,
            offset: 1,
          },
        },
        {
          time: "631152000000",
          value: {
            value: 1,
            total: 1,
            offset: 1,
          },
        },
        {
          time: "757382400000",
          value: {
            value: 1,
            total: 1,
            offset: 1,
          },
        },
        {
          time: "788918400000",
          value: {
            value: 1,
            total: 1,
            offset: 1,
          },
        },
        {
          time: "946684800000",
          value: {
            value: 4,
            total: 4,
            offset: 1,
          },
        },
        {
          time: "978307200000",
          value: {
            value: 1,
            total: 1,
            offset: 1,
          },
        },
        {
          time: "1009843200000",
          value: {
            value: 2,
            total: 2,
            offset: 1,
          },
        },
        {
          time: "1072915200000",
          value: {
            value: 3,
            total: 3,
            offset: 1,
          },
        },
        {
          time: "1104537600000",
          value: {
            value: 3,
            total: 3,
            offset: 1,
          },
        },
        {
          time: "1136073600000",
          value: {
            value: 1,
            total: 1,
            offset: 1,
          },
        },
        {
          time: "1167609600000",
          value: {
            value: 2,
            total: 2,
            offset: 1,
          },
        },
        {
          time: "1199145600000",
          value: {
            value: 3,
            total: 3,
            offset: 1,
          },
        },
        {
          time: "1230768000000",
          value: {
            value: 9,
            total: 9,
            offset: 1,
          },
        },
        {
          time: "1262304000000",
          value: {
            value: 16,
            total: 16,
            offset: 1,
          },
        },
        {
          time: "1293840000000",
          value: {
            value: 16,
            total: 16,
            offset: 1,
          },
        },
        {
          time: "1325376000000",
          value: {
            value: 26,
            total: 26,
            offset: 1,
          },
        },
        {
          time: "1356998400000",
          value: {
            value: 48,
            total: 48,
            offset: 1,
          },
        },
        {
          time: "1388534400000",
          value: {
            value: 52,
            total: 52,
            offset: 1,
          },
        },
        {
          time: "1420070400000",
          value: {
            value: 110,
            total: 110,
            offset: 1,
          },
        },
        {
          time: "1451606400000",
          value: {
            value: 718,
            total: 718,
            offset: 1,
          },
        },
        {
          time: "1483228800000",
          value: {
            value: 123,
            total: 123,
            offset: 1,
          },
        },
        {
          time: "1514764800000",
          value: {
            value: 25,
            total: 25,
            offset: 1,
          },
        },
        {
          time: "1546300800000",
          value: {
            value: 11,
            total: 11,
            offset: 1,
          },
        },
        {
          time: "1577836800000",
          value: {
            value: 6,
            total: 6,
            offset: 1,
          },
        },
      ],
    },
  ],
};

const seriesData = {
  series: ["Series 1", "Series 2"],
  data: [
    {
      name: "Series 1",
      data: [
        {
          time: 946702800000,
          value: {
            total: 24,
            value: 12,
            offset: 0.5,
          },
        },
        {
          time: 1325394000000,
          value: {
            total: 10,
            value: 2,
            offset: 0.2,
          },
        },
      ],
    },
    {
      name: "Series 2",
      data: [
        {
          time: 946702800000,
          value: {
            total: 36,
            value: 8,
            offset: 0.25,
          },
        },
        {
          time: 1325394000000,
          value: {
            total: 10,
            value: 2,
            offset: 0.2,
          },
        },
      ],
    },
  ],
};

export default function NtaiTestDateHistogram() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Date Hist">
          <NtaiDateHistogramEChart
            widgetId="date-hist-chart"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId1: {
                  name: "date",
                  fieldOrder: 1,
                  field: { uuId: "dateUuId" },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    // result.push(
    //   <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
    //     <NtaiSourceWidget title="Date Hist Series">
    //       <NtaiDateHistogramEChart
    //         widgetId="date-hist-chart"
    //         data={seriesData}
    //         metadata={{
    //           filterFg: filtered,
    //           fields: {
    //             fieldUuId1: {
    //               name: "date",
    //               fieldOrder: 1,
    //               field: { uuId: "dateUuId" },
    //             },
    //             fieldUuId2: {
    //               name: "phase",
    //               fieldOrder: 2,
    //               field: { uuId: "phaseUuId" },
    //             },
    //           },
    //         }}
    //         handleClick={handleClick}
    //         handleContextMenu={handleContextMenu}
    //       />
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
