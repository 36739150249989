import React from "react";
import {
  faCommentAltEdit,
  faList,
  faNote,
  faTasks,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, useTheme } from "@mui/material";
import { SearchSourceContext } from "../SearchSource";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import {
  clearStatus,
  getSearchSourceRecordsSummary,
} from "./store/searchSourceRecordsSummarySlice";
import { SearchContext } from "../../SearchEdit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function SearchSourceBottomToolbar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loadedAll, setLoadedAll] = useState(false);
  const [loadedAssessment, setLoadedAssessment] = useState(false);
  const [loadedAction, setLoadedAction] = useState(false);
  const [loadedNote, setLoadedNote] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filterSelected, setFilterSelected] = useState(null);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordsSummarySlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordsSummarySlice.status
  );

  function filterSearchSourceRecords(filter) {
    setFilterSelected(filter === filterSelected ? "DEFAULT" : filter);
  }

  useEffect(() => {
    if (_.has(status, 1)) {
      const statusAssessment = _.get(status, 1);
      if (
        statusAssessment &&
        statusAssessment.result === "success" &&
        statusAssessment.method === "getSearchSourceRecordsSummary"
      ) {
        setLoadedAssessment(true);
        dispatch(clearStatus(1));
      }
    }

    if (_.has(status, 2)) {
      const statusAction = _.get(status, 2);
      if (
        statusAction &&
        statusAction.result === "success" &&
        statusAction.method === "getSearchSourceRecordsSummary"
      ) {
        setLoadedAction(true);
        dispatch(clearStatus(2));
      }
    }

    if (_.has(status, 3)) {
      const statusNote = _.get(status, 3);
      if (
        statusNote &&
        statusNote.result === "success" &&
        statusNote.method === "getSearchSourceRecordsSummary"
      ) {
        setLoadedNote(true);
        dispatch(clearStatus(3));
      }
    }
  }, [status]);

  useEffect(() => {
    if (loadedAssessment && loadedAction && loadedNote) {
      setTotalCount(dataMap[1] + dataMap[2] + dataMap[3]);
    }
  }, [loadedAssessment, loadedAction, loadedNote]);

  useEffect(() => {
    [1, 2, 3].forEach((summaryType, i) => {
      dispatch(
        getSearchSourceRecordsSummary({
          searchId: searchId,
          searchSourceId: searchSourceId,
          params: {
            summaryType: summaryType,
          },
        })
      );
    });
  }, []);

  return (
    <Box
      sx={{
        py: theme.spacing(1),
        pl: theme.spacing(1),
        pr: theme.spacing(2),
        display: "flex",
        width: "100%",
        borderTop: theme.general.border1,
        height: "44px",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: theme.colors.shadows.card,
      }}
    >
      <NtaiMuiBadge
        badgeContent={totalCount && totalCount > 0 ? totalCount : null}
        right={2}
        // top={4}
        color="secondary"
        // bg={theme.colors.secondary.lighter}
      >
        <IconButton
          sx={{
            width: "24px",
            height: "24px",
            background:
              filterSelected === "FILTER_USER_EVENT_ALL" ? grey[300] : null,
          }}
          onClick={() => filterSearchSourceRecords("FILTER_USER_EVENT_ALL")}
        >
          <FontAwesomeIcon size="2xs" icon={faList} />
        </IconButton>
      </NtaiMuiBadge>

      {loadedAssessment && (
        <NtaiMuiBadge
          badgeContent={_.has(dataMap, 1) && dataMap[1] > 0 ? dataMap[1] : null}
          right={2}
          // top={4}
          color="secondary"
        >
          <IconButton
            sx={{
              width: "24px",
              height: "24px",
              background:
                filterSelected === "FILTER_USER_EVENT_ADJS" ? grey[300] : null,
            }}
            onClick={() => filterSearchSourceRecords("FILTER_USER_EVENT_ADJS")}
          >
            <FontAwesomeIcon size="2xs" icon={faCommentAltEdit} />
          </IconButton>
        </NtaiMuiBadge>
      )}

      {!loadedAssessment && <NtaiCircularProgress size={14} />}

      {loadedAction && (
        <NtaiMuiBadge
          badgeContent={_.has(dataMap, 2) && dataMap[2] > 0 ? dataMap[2] : null}
          right={2}
          // top={4}
          color="secondary"
          // bg={theme.colors.secondary.lighter}
        >
          <IconButton
            sx={{
              width: "24px",
              height: "24px",
              background:
                filterSelected === "FILTER_USER_EVENT_ACTIONS"
                  ? grey[300]
                  : null,
            }}
            onClick={() =>
              filterSearchSourceRecords("FILTER_USER_EVENT_ACTIONS")
            }
          >
            <FontAwesomeIcon size="2xs" icon={faTasks} />
          </IconButton>
        </NtaiMuiBadge>
      )}

      {!loadedAction && <NtaiCircularProgress size={14} />}

      {loadedNote && (
        <NtaiMuiBadge
          badgeContent={_.has(dataMap, 3) && dataMap[3] > 0 ? dataMap[3] : null}
          right={2}
          // top={4}
          color="secondary"
          // bg={theme.colors.secondary.lighter}
        >
          <IconButton
            sx={{
              width: "24px",
              height: "24px",
              background:
                filterSelected === "FILTER_USER_EVENT_NOTES" ? grey[300] : null,
            }}
            onClick={() => filterSearchSourceRecords("FILTER_USER_EVENT_NOTES")}
          >
            <FontAwesomeIcon size="2xs" icon={faNote} />
          </IconButton>
        </NtaiMuiBadge>
      )}
      {!loadedNote && <NtaiCircularProgress size={14} />}
    </Box>
  );
}
