import { Button, Divider } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { AlertEditContext } from "../../../../AlertEdit";

export default function AlertRecordAssignment({ handleCancel }) {
  const { selectedRecordId } = useContext(AlertEditContext);
  const recordsDataMap = useSelector(
    (state) => state.monitor.alertRecordsSlice.data
  );

  const recordData = recordsDataMap[selectedRecordId];

  function onHandleSave() {}
  return (
    <NtaiDialogFormPanel
      title={recordData["recordId"]}
      subheader="You can unassign item to yourself or re-assign item to another user"
      handleSave={onHandleSave}
      handleSaveLabel={"Confirm"}
      handleCancel={handleCancel}
    >
      <Button variant="outlined" size="small">
        UNASSIGN
      </Button>
      <Divider orientation="horixontal" flexItem>
        OR
      </Divider>
      <NtaiSelectField name="dd" options={[]} label="Assign to another user" />
    </NtaiDialogFormPanel>
  );
}
