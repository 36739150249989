import { useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useFormContext } from "react-hook-form";
import { getSourceFields } from "../../../../store/sourcesSlice";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { updateSourceRecordListing } from "../../store/sourceRecordListingsSlice";
import { getSourceRecordLabels } from "../../../label/store/sourceRecordLabelsSlice";
import { getSourceDerivedFields } from "../../../../derivedfield/store/sourceDerivedFieldsSlice";
const _ = require("lodash");

const PLACEMENT_OPTIONS = [
  { value: "header1", label: "Header 1" },
  { value: "header2", label: "Header 2" },
  { value: "header3", label: "Header 3" },
];

const PLACEMENT_CODES = {
  header1: 1,
  header2: 2,
  header3: 3,
};

export default function SourceTemplateListingListFields({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const listingId = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    uuId: listingId,
  };

  const listingDataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const listingData = _.get(listingDataMap, listingId);

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const statusSourceFields = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const statusDerivedFields = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsOptions = NtaiUtils.getSelectOptions(
    _.orderBy(Object.values(sourceFieldsDataMap), ["label"], ["asc"]),
    "uuId",
    "name"
  );

  const sourceDerivedFieldsOptions = NtaiUtils.getSelectOptions(
    Object.values(sourceDerivedFieldsDataMap).map((df) => {
      return {
        uuId: df.uuId,
        name: "(d)" + " " + df.name,
      };
    }),
    "uuId",
    "name"
  );

  const fieldOptions = [...sourceFieldsOptions, ...sourceDerivedFieldsOptions];

  const sourceRecordLabelsDataMap = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.data
  );

  const sourceRecordLabelOptions = NtaiUtils.getSelectOptions(
    Object.values(sourceRecordLabelsDataMap),
    "uuId",
    "name"
  );

  function generateFieldForm() {
    let returnValue = [];
    PLACEMENT_OPTIONS.forEach((placement, index) => {
      returnValue.push(
        <NtaiSelectField
          key={placement.value}
          name={`sourceRecordListingFieldsForm.${placement.value}`}
          label={NtaiUtils.toTitleCase(placement.label)}
          options={fieldOptions}
        />
      );
    });

    return returnValue;
  }

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceRecordListingFieldsForm")
    );

    const sanitizedFormLabelData = NtaiUtils.sanitizeFormData(
      getValues("sourceRecordListingLabelForm")
    );

    if (sanitizedFormData && !_.isEmpty(sanitizedFormData)) {
      let result = [];
      _.sortBy(Object.keys(sanitizedFormData)).forEach((h, i) => {
        result.push({
          fieldOrder: i + 1,
          placementCode: PLACEMENT_CODES[h],
          sourceFieldUuId: sanitizedFormData[h],
        });
      });

      let revisedFormData = _.cloneDeep(listingData);
      revisedFormData["sourceRecordListingFields"] = result;
      revisedFormData["updateTypeFg"] = 2;
      dispatch(
        updateSourceRecordListing({
          ...pathVariables,

          formData: {
            ...revisedFormData,
            sourceRecordLabelUuId:
              _.has(sanitizedFormLabelData, "sourceRecordLabelUuId") &&
              _.get(sanitizedFormLabelData, "sourceRecordLabelUuId")
                ? sanitizedFormLabelData.sourceRecordLabelUuId
                : null,
          },
        })
      );
    }
  }

  useEffect(() => {
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );

    const listingFields = _.get(listingData, "sourceRecordListingFields");

    if (Array.isArray(listingFields) && listingFields.length > 0) {
      let sourceFieldUuIds = [];
      [1, 2, 3].forEach((pc) => {
        sourceFieldUuIds.push(
          _.get(
            _.filter(listingFields, { placementCode: pc })[0],
            "sourceFieldUuId"
          )
        );
      });

      PLACEMENT_OPTIONS.forEach((pc, index) => {
        setValue(
          `sourceRecordListingFieldsForm.${pc.value}`,
          sourceFieldUuIds[index]
        );
      });
    } else {
      reset({ sourceRecordListingFieldsForm: {} });
    }
  }, []);

  useEffect(() => {
    dispatch(
      getSourceRecordLabels({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <NtaiFormPanel
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      {generateFieldForm()}

      <NtaiSelectField
        name="sourceRecordListingLabelForm.sourceRecordLabelUuId"
        label="Label"
        options={sourceRecordLabelOptions}
      />
    </NtaiFormPanel>
  );
}
