import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import {
  getSourceAdjudications,
  clearStatus as clearSourceAdjsSliceStatus,
} from "../../../adjudication/store/sourceAdjudicationsSlice";
import {
  getSourcePolicyDefs,
  clearStatus as clearSourcePolicyDefsSliceStatus,
} from "../../definition/store/sourcePolicyDefsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

function filterSourceAdjs(action, sourceAdjDataMap, sourcePolicyDefDataMap) {
  let result = [];
  if (action === "add") {
    if (!_.isEmpty(sourceAdjDataMap) && sourcePolicyDefDataMap) {
      result = _.filter(Object.values(sourceAdjDataMap), function (o) {
        const present = _.filter(
          Object.values(sourcePolicyDefDataMap),
          function (p) {
            if (o["uuId"] === p["sourceAdjudicationUuId"]) return true;
            else return false;
          }
        );
        return present.length > 0 ? false : true;
      });
    }
  } else {
    result = Object.values(sourceAdjDataMap);
  }

  return NtaiUtils.getSelectOptions(result, "uuId", "adjObjectName");
}

export default function SourcePolicyAssessmentForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const [loaded, setLoaded] = useState(true);
  const [sourceAdjOptions, setSourceAdjOptions] = useState([]);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = action === "add" ? null : formData["uuId"];

  const dataMap = useSelector(
    (state) => state.sources.sourcePolicyAssessmentsSlice.data
  );

  const sourceAdjDataMap = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.data
  );

  const sourceAdjStatus = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.status
  );

  const sourcePolicyDefDataMap = useSelector(
    (state) => state.sources.sourcePolicyDefsSlice.data
  );

  const sourcePolicyDefStatus = useSelector(
    (state) => state.sources.sourcePolicyDefsSlice.status
  );

  const sourcePolicyDefOptions =
    sourcePolicyDefDataMap && !_.isEmpty(sourcePolicyDefDataMap)
      ? NtaiUtils.getSelectOptions(
          Object.values(sourcePolicyDefDataMap),
          "uuId",
          "name"
        )
      : [];

  const data = _.get(dataMap, activeId);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourcePolicyAssessmentForm")
    );
    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    if (
      sourceAdjStatus &&
      sourceAdjStatus.method === "getSourceAdjudications" &&
      sourceAdjStatus.result === "success" &&
      sourcePolicyDefStatus &&
      sourcePolicyDefStatus.method === "getSourcePolicyDefs" &&
      sourcePolicyDefStatus.result === "success"
    ) {
      setLoaded(true);
      setSourceAdjOptions(filterSourceAdjs(action, sourceAdjDataMap, dataMap));
      dispatch(clearSourcePolicyDefsSliceStatus());
      dispatch(clearSourceAdjsSliceStatus());
    }
  }, [sourceAdjStatus, sourcePolicyDefStatus]);

  useEffect(() => {
    dispatch(
      getSourceAdjudications({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );

    dispatch(
      getSourcePolicyDefs({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );

    reset({
      sourcePolicyAssessmentForm: action === "edit" ? formData : null,
    });
  }, [formData]);

  return (
    <React.Fragment>
      <NtaiFormPanel
        padding="0px"
        width="40%"
        handleSave={onHandleSave}
        saveDisabled={loaded && sourceAdjOptions.length > 0 ? false : true}
        handleCancel={handleCancel}
      >
        {loaded && sourceAdjOptions.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h6">
              {action === "add"
                ? "Add Assessment Policy"
                : `Edit ${formData["sourceAdjudicationObjectName"]}`}
            </Typography>
            <NtaiSelectField
              isDisabled={action === "edit" ? true : false}
              name="sourcePolicyAssessmentForm.sourceAdjudicationUuId"
              label="Assessment Form*"
              options={sourceAdjOptions}
              rules={{ required: "Assessment form is required" }}
            />

            <NtaiSelectField
              name="sourcePolicyAssessmentForm.sourcePolicyDefinitionUuId"
              label="Policy Definition*"
              options={sourcePolicyDefOptions}
              rules={{ required: "Policy definition is required" }}
            />
            <NtaiSwitchField
              label="Allow user to override policy?"
              name="sourcePolicyAssessmentForm.allowOverrideFg"
            />
          </Box>
        )}

        {loaded && sourceAdjOptions.length === 0 && (
          <NtaiEmptyMessage
            header="No assessment form available."
            subheader="Either all assessment forms have been assigned or no forms have been created"
          />
        )}
      </NtaiFormPanel>

      {!loaded && <NtaiCircularProgress />}
    </React.Fragment>
  );
}
