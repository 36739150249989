import React, { useState } from "react";
import { faCaretDown, faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import NtaiTooltip from "@ntai/components/tooltip/NtaiTooltip";
import SearchSourceRecordReportMenu from "./SearchSourceRecordReportMenu";
import { SearchSourceContext } from "../../../SearchSource";

export default function SearchSourceRecordReportButton() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleReportMenuOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleReportMenuClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const clickAwayHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Reports">
          <IconButton onClick={(e) => handleReportMenuOpen(e)}>
            <FontAwesomeIcon size="2xs" icon={faFilePdf} />
          </IconButton>
        </Tooltip>
      </Box>
      {open && (
        <NtaiPopover
          open={open}
          anchorEl={anchorEl}
          handleClose={handleReportMenuClose}
          clickAwayHandler={clickAwayHandler}
        >
          <SearchSourceRecordReportMenu handleClose={handleReportMenuClose} />
        </NtaiPopover>
      )}
    </>
  );
}
