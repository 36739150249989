import SourceOutJobMigration from "./migration/SourceOutJobMigration";
import SourceOutJobMigrationList from "./migration/SourceOutJobMigrationList";
import SourceOutJobList from "./SourceOutJobList";

const SourceOutJobConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/jobs",
      exact: true,
      component: SourceOutJobList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/jobs/:id/migrations",
      exact: true,
      component: SourceOutJobMigrationList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/jobs/:jobid/migrations/:id/edit",
      exact: true,
      component: SourceOutJobMigration,
    },
  ],
};

export default SourceOutJobConfig;
