import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { dispatch } from "d3";
import {
  serveOversightEntityOverviewView,
  updateOversightEntityOverviewView,
} from "./store/oversightEntityOverviewViewsSlice";
import { OversightContext } from "../../../Oversight";
import useDimensions from "react-cool-dimensions";
import { OversightEntityOverviewContext } from "./OversightEntityOverviewWrapper";
import { useState } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import OversightEntityOverviewGrid from "./resizable/OversightEntityOverviewGrid";
import OversightEntityOverviewViewWidget from "./OversightEntityOverviewViewWidget";
import NtaiResizableGrid1 from "@ntai/components/resizable/NtaiResizableGrid1";
import { useMeasure } from "react-use";
import { Height } from "@mui/icons-material";
const _ = require("lodash");

export default function OversightEntityOverview() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [ref, { width, height }] = useMeasure();
  const [reload, setReload] = useState(false);

  const { viewId } = React.useContext(OversightEntityOverviewContext);
  const { navNode } = React.useContext(OversightContext);

  const dataUuId =
    _.has(navNode, "type") && _.get(navNode, "type") === "ENTITY"
      ? _.get(navNode, "uuId")
      : null;

  const dataMap = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewsSlice.data
  );

  const status = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewsSlice.status
  );

  const activeId = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewsSlice.activeId
  );

  const data = dataMap && !_.isEmpty(dataMap) ? dataMap[viewId] : null;

  function handleLayoutChange(layout) {
    dispatch(
      updateOversightEntityOverviewView({
        objectUuId: _.get(navNode, "parentUuId"),
        dataUuId: dataUuId,
        uuId: viewId,
        formData: {
          layout: layout,
        },
      })
    );
  }

  function generateWidgets() {
    let result = [];

    data.widgets.forEach((widget, index) => {
      result.push(
        <Box
          key={index + 1}
          sx={{ display: "flex", width: "100%", height: "100%" }}
        >
          <OversightEntityOverviewViewWidget
            viewId={_.get(data, "uuId")}
            initData={widget}
          />
        </Box>
      );
    });

    return result;
  }

  useEffect(() => {
    setReload(true);
  }, [viewId]);

  return (
    <Scrollbar>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          p: theme.spacing(1),
          display: "flex",
          background: grey[50],
        }}
        ref={ref}
      >
        {reload &&
          data &&
          !_.isEmpty(data) &&
          _.has(data, "widgets") &&
          _.isArray(_.get(data, "widgets")) && (
            <NtaiResizableGrid1
              rowHeight={50}
              cols={12}
              layout={_.get(data, "layout") ? _.get(data, "layout") : null}
              items={generateWidgets()}
              handleLayoutChange={handleLayoutChange}
              height={height}
              width={width}
            />

            // <OversightEntityOverviewGrid
            //   layout={_.get(data, "layout") ? _.get(data, "layout") : null}
            //   height={layoutHeight}
            //   width={layoutWidth}
            //   items={generateWidgets()}
            //   handleLayoutChange={handleLayoutChange}
            // />
          )}
        {reload && !data && (
          <NtaiEmptyMessage
            header="No data available"
            subheader="Ensure you have source views configured for the object"
          />
        )}
      </Box>
    </Scrollbar>
  );
}
