import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import React from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function SourceInStoreOcrFieldList({
  handleEdit,
  handleDelete,
}) {
  const theme = useTheme();

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreOcrFieldsSlice.data
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const status = useSelector(
    (state) => state.sources.sourceInStoreOcrFieldsSlice.status
  );

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          height: "44px",
          width: "100%",
          justifyContent: "space-between",
          py: theme.spacing(1),
          pl: theme.spacing(1),
          pr: theme.spacing(2),
          alignItems: "center",
          borderBottom: theme.general.border1,
        }}
      >
        <Typography variant="h6">Fields ({data.length})</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: `calc(100% - 44px)`,
        }}
      >
        {data && _.isArray(data) && data.length > 0 && (
          <List sx={{ p: 0, width: "100%" }}>
            {data.map((field, i) => (
              <React.Fragment>
                <ListItemButton
                  sx={{ pl: 0, pr: theme.spacing(2), py: 0, borderRadius: 0 }}
                  onClick={() => handleEdit(null, field.uuId)}
                >
                  <ListItem
                    secondaryAction={
                      <IconButton
                        onClick={() => handleDelete(field.uuId)}
                        edge="end"
                        aria-label="delete"
                      >
                        <FontAwesomeIcon size="2xs" icon={faArchive} />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 700 }}
                        >
                          {field.fieldName || "FIELD_NAME_EMPTY"}
                        </Typography>
                      }
                      secondary={field.description}
                    />
                  </ListItem>
                </ListItemButton>

                <Divider />
              </React.Fragment>
            ))}
          </List>
        )}
        {data && _.isArray(data) && data.length === 0 && (
          <NtaiEmptyMessage
            header="No fields available"
            subheader="Create OCR regions by dragging mouse "
          />
        )}
      </Box>
    </React.Fragment>
  );
}
