import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjects = createAsyncThunk(
  "objects/getObjects",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, params } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects`,
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObject = createAsyncThunk(
  "objects/getObject",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObject = createAsyncThunk(
  "objects/createObject",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects`,
        formData
      );
      return { domainId: domainId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObject = createAsyncThunk(
  "objects/updateObject",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObject = createAsyncThunk(
  "objects/deleteObject",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectsSlice = createSlice({
  name: "objects",
  initialState: {
    data: {},
    activeId: null,
    status: {},
    activeDomainId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setActiveDomainId: (state, action) => {
      state.activeDomainId = action.payload;
    },
  },
  extraReducers: {
    [getObjects.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjects" };
    },
    [getObjects.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjects",
        message: action.payload.message,
      };
    },
    [getObject.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getObject" };
    },
    [getObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObject",
        message: action.payload.message,
      };
    },
    [createObject.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.data.uuId]: action.payload.data,
      };
      state.status = { result: "success", method: "createObject" };
      state.activeId = action.payload.data.uuId;
      state.activeDomainId = action.payload.domainId;
    },
    [createObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObject",
        message: action.payload.message,
      };
    },
    [updateObject.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateObject" };
    },
    [updateObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObject",
        message: action.payload.message,
      };
    },
    [deleteObject.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteObject" };
    },
    [deleteObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObject",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, setActiveDomainId } =
  objectsSlice.actions;

export default objectsSlice.reducer;
