import React, { useEffect } from "react";
import NtaiFormPanel from "../panels/NtaiFormPanel";
import { useTheme } from "@mui/material";
import NtaiTextField from "./NtaiTextField";
import { useFormContext } from "react-hook-form";
import NtaiValidatorUtil from "@ntai/validator";
import NtaiValidator from "@ntai/validator/NtaiValidator";
const _ = require("lodash");

export default function NtaiTestFormValidations() {
  const theme = useTheme();

  const {
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext();

  function onHandleSave() {
    console.log("Submit form: ");
  }

  function onHandleCancel() {}

  useEffect(() => {
    reset({ valdiationForm: {} });
  }, []);

  function testPass(val, values) {
    console.log("Val and values: ", val, values);
  }

  return (
    <NtaiFormPanel
      title="Form Validations"
      subheader="Form validations using multiple components"
      handleSave={onHandleSave}
      handleCancel={onHandleCancel}
    >
      <NtaiTextField
        name="validationForm.userName"
        label="Name"
        rules={{
          required: "User name is required",
          validate: NtaiValidator.isUserName,
        }}
      />
      <NtaiTextField
        name="validationForm.password"
        label="Password"
        type="password"
        rules={{ required: "Password is required" }}
      />
      <NtaiTextField
        name="validationForm.email"
        label="Email"
        type="email"
        rules={{ required: "Email is required" }}
      />
      <NtaiTextField
        name="validationForm.pythonFileName"
        label="Python File Name"
        rules={{ validate: NtaiValidatorUtil.isPythonFileName }}
      />
    </NtaiFormPanel>
  );
}
