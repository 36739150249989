import { Box, Typography, useTheme } from "@mui/material";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportContext } from "../../ReportMain";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

export default function ReportSourceQbe() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [fields, setFields] = useState();

  const { reportDefinition, setSourceCriteria, setEsCriteria } =
    React.useContext(ReportContext);

  const queryValue = null;

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  function buildQuery(tree, config, jsonTree, formats) {
    setSourceCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        "*",
        _.get(reportDefinition, "sourceUuId"),
        mergedFields
      );

      setFields({ ...tmpFields });
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    dispatch(
      getSourceFields({
        sourceDefId: "*",
        sourceId: _.get(reportDefinition, "sourceUuId"),
      })
    );
    dispatch(
      getSourceDerivedFields({
        sourceDefId: "*",
        sourceId: _.get(reportDefinition, "sourceUuId"),
      })
    );
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <Box
        sx={{
          p: theme.spacing(2),
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
        }}
      >
        <Typography variant="h6">Report Criteria</Typography>
      </Box>
      <Scrollbar autoHide={true}>
        {fieldsLoaded && !_.isEmpty(fields) && (
          <NtaiQueryBuilder
            fields={fields}
            buildQuery={buildQuery}
            value={queryValue}
          />
        )}
        {!fieldsLoaded && <NtaiCircularProgress size={24} />}
      </Scrollbar>
    </Box>
  );
}
