import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SourceDatasetLookup from "./SourceDatasetLookup";
import DatasetFileUpload from "./upload/DatasetFileUpload";
import NtaiHeaderSubheader from "@ntai/components/micros/NtaiHeaderSubheader";
import { useSnackbar } from "notistack";
import { useState } from "react";

const _ = require("lodash");

export default function DatasetForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleDatasetFileUpload } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();

  const [fileUploadData, setFileUploadData] = useState();
  const [sourceDatasetDialogOpen, setSourceDatasetDialogOpen] = useState(false);
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);
  const activeId = useSelector((state) => state.models.datasetsSlice.activeId);
  const status = useSelector((state) => state.models.datasetsSlice.status);

  const privacyOptions = !_.isEmpty(codelistData)
    ? _.get(codelistData, CODELIST_CODES.GL_ACCESS_TYPE_CODE)
    : [];

  function handleSourceDatasetDialogOpen() {
    setSourceDatasetDialogOpen(true);
  }

  function handleSourceDatasetDialogClose() {
    setSourceDatasetDialogOpen(false);
  }

  function handleFileUploadDialogOpen() {
    setFileUploadDialogOpen(true);
  }

  function handleFileUploadDialogClose() {
    setFileUploadDialogOpen(false);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("datasetForm")
    );
    handleSave(sanitizedFormData);
  }

  function onHandleCancel() {
    history.push("/models/datasets");
  }

  // function handleFileUpload(formData, config) {
  //   console.log(formData, config);
  //   dispatch(
  //     uploadDatasetFile({ uuId: activeId, formData: formData, config: config })
  //   );
  // }

  function onHandleFileUploadSave() {
    handleDatasetFileUpload({
      uuId: activeId,
      ...fileUploadData,
    });
  }

  function handleFileUpload(formData, config) {
    const fileFormatData = NtaiUtils.sanitizeFormData(getValues("datasetForm"));

    let formError = false;

    if (
      fileFormatData.fileFormatCode === null ||
      fileFormatData.fileFormatCode === "undefined"
    ) {
      formError = true;
      enqueueSnackbar("File format is required", {
        variant: "error",
      });
    }

    if (fileFormatData.fileFormatCode && fileFormatData.fileFormatCode === 1) {
      if (!fileFormatData.fileDelimiter) {
        formError = true;
        enqueueSnackbar("Delimiter is required", {
          variant: "error",
        });
      }
    }

    if (!formError) {
      formData.append(
        "metadata",
        JSON.stringify({
          fileFormatCode: fileFormatData.fileFormatCode,
          fileDelimiter: fileFormatData.fileDelimiter,
          uuId: activeId,
        })
      );

      setFileUploadData({ formData: formData, config: config });
    }
  }

  useEffect(() => {
    reset({ datasetForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
        <Box sx={{ flexBasis: "50%", display: "flex", height: "100%" }}>
          <NtaiFormPanel
            header="General Details"
            subheader="Enter general details about dataset including alias, name, description and access policy"
            width="75%"
            handleSave={onHandleSave}
            handleCancel={onHandleCancel}
          >
            <NtaiTextField
              name="datasetForm.alias"
              placeholder="Alias"
              label="Alias*"
              rules={{ required: "Alias is required" }}
            />
            <NtaiTextField
              name="datasetForm.name"
              placeholder="Name"
              label="Name*"
              rules={{ required: "Name is required" }}
            />
            <NtaiTextField
              name="datasetForm.description"
              multiline
              minRows={2}
              maxRows={2}
              placeholder="Description"
              label="Description"
            />
            <NtaiSelectField
              name="datasetForm.privacyCode"
              options={privacyOptions}
              label="Privacy"
            />
          </NtaiFormPanel>
        </Box>

        {action === "edit" && (
          <Box
            sx={{
              flexBasis: "50%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <NtaiFormPanel
              width="75%"
              header="Add dataset"
              subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
            >
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(2),
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleSourceDatasetDialogOpen()}
                >
                  Lookup Dataset
                </Button>
                <Typography variant="body1" fontWeight="700">
                  OR
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleFileUploadDialogOpen()}
                >
                  Upload File
                </Button>
              </Box>

              {(formData.sourceDatasetUuId || formData.sourceMin) && (
                <NtaiHeaderSubheader
                  header={
                    formData.sourceDatasetUuId
                      ? "Source dataset assigned"
                      : "Uploaded File"
                  }
                  subheader={
                    formData.sourceDatasetUuId
                      ? formData.sourceMin.displayName +
                        " - " +
                        formData.sourceDatasetName
                      : formData.fileName +
                        " (" +
                        NtaiUtils.bytesToSize(formData.fileSize) +
                        ")"
                  }
                />
              )}
            </NtaiFormPanel>
          </Box>
        )}
      </Box>
      <NtaiDialog
        open={sourceDatasetDialogOpen}
        handleDialogClose={handleSourceDatasetDialogClose}
        title="Lookup Source Dataset"
        size="sm"
      >
        <SourceDatasetLookup
          handleSave={onHandleSave}
          handleCancel={handleSourceDatasetDialogClose}
        />
      </NtaiDialog>
      <NtaiDialog
        open={fileUploadDialogOpen}
        handleDialogClose={handleFileUploadDialogClose}
        title="Upload file"
        size="sm"
      >
        <DatasetFileUpload
          formData={formData}
          handleFileUpload={handleFileUpload}
          handleSave={onHandleFileUploadSave}
          handleCancel={handleFileUploadDialogClose}
        />
      </NtaiDialog>
    </>
  );
}
