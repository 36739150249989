import {
  faBarsProgress,
  faSave,
  faSpinner,
  faStop,
} from "@fortawesome/pro-light-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography, useTheme } from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import React, { useEffect, useState } from "react";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { useDispatch, useSelector } from "react-redux";
import {
  runSourceInStoreDataset,
  setActiveId,
  updateSourceInStoreDataset,
} from "./../store/sourceInStoreDatasetsSlice";
const _ = require("lodash");

export default function SourceInStoreDatasetQueryEditor(props) {
  const theme = useTheme();
  const { formData, handleSave, handleRun } = props;
  const [code, setCode] = useState();
  const status = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.status
  );

  const running =
    status &&
    status.result === "pending" &&
    status.method === "runSourceInStoreDataset"
      ? true
      : false;

  const debouncedOnHandleCodeChange = _.debounce(onChangeCode, 500);

  function onChangeCode(newValue) {
    setCode(newValue);
  }

  function onHandleSave() {
    let formDataCopy = _.cloneDeep(formData);
    formDataCopy["query"] = code;
    handleSave(formDataCopy);
  }

  function onHandleRun() {
    let formDataCopy = _.cloneDeep(formData);
    formDataCopy["query"] = code;
    handleRun(formDataCopy);
  }

  useEffect(() => {
    setCode(formData["query"]);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexBasis: "50%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
          justifyContent: "space-between",
          height: "44px",
        }}
      >
        <Box
          sx={{
            pl: theme.spacing(1),
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <Typography fontWeight="700">Query Editor</Typography>
          <NtaiSwitchField name="r1" label="Limit 100" />
          <NtaiSwitchField name="r2" label="Explain" />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ fontSize: "12px", borderRadius: 0 }}
            color="inherit"
            startIcon={<FontAwesomeIcon size="xs" icon={faSave} />}
            onClick={() => onHandleSave()}
          >
            Save
          </Button>

          <Button
            sx={{ fontSize: "12px", borderRadius: 0 }}
            startIcon={
              <FontAwesomeIcon
                size="xs"
                icon={running ? faSpinner : faPlay}
                spin={running ? true : false}
              />
            }
            variant="contained"
            disabled={running ? true : false}
            onClick={() => onHandleRun()}
          >
            Run
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          height: `calc(100% - 44px)`,
          borderBottom: theme.general.border2,
        }}
      >
        <AceEditor
          theme="github"
          mode="sql"
          onChange={(v) => debouncedOnHandleCodeChange(v)}
          name="functionCode"
          value={code}
          width="100%"
          height="100%"
          fontSize="14px"
          // editorProps={{ $blockScrolling: Infinity }}
          setOptions={{
            enableBasicAutocompletion: true,
          }}
        />
      </Box>
    </Box>
  );
}
