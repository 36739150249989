import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  updateUserQuery,
} from "./store/userQueriesSlice";
import UserQueryForm from "./UserQueryForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function UserQueryEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.userQueriesSlice.status);
  const dataMap = useSelector((state) => state.core.userQueriesSlice.data);
  const activeId = useSelector((state) => state.core.userQueriesSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateUserQuery({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (status.method === "updateUserQuery" && status.result === "success") {
      enqueueSnackbar(
        `Rule "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/library/queries`);
      dispatch(clearStatus());
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back="/library/queries"
    >
      <NtaiForm>
        <UserQueryForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
