import { Box, useTheme } from "@mui/material";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteReport,
  getReports,
  setActiveId,
} from "./store/reportsSlice";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import History from "@ntai/@history/@history";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

function getScheduledFg(col) {
  return col && col === 1 ? <FontAwesomeIcon icon={faClock} /> : null;
}
const headCells = [
  {
    id: "uuId",
    numeric: false,
    disablePadding: true,
    ignore: true,
    label: "UUID",
  },
  {
    id: "scheduledFg",
    numeric: false,
    disablePadding: false,
    label: <FontAwesomeIcon icon={faClock} />,
    fontWeight: "700",
    width: "2%",
    transformFunc: getScheduledFg,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "21%",
  },
  {
    id: "reportDefinitionName",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "18%",
  },
  {
    id: "reportDefinitionGroupName",
    numeric: false,
    disablePadding: false,
    label: "Category",
    width: "20%",
  },
  {
    id: "reportScheduleCronExpression",
    numeric: false,
    disablePadding: false,
    label: "Frequency",
    width: "15%",
    transformFunc: NtaiUtils.cronExpressionToString,
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Generated",
    width: "12%",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "User",
    width: "12%",
  },
];

export default function ReportList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  // console.log("Report LS: ", localStorage);

  const dataMap = useSelector((state) => state.reports.reportsSlice.data);
  const status = useSelector((state) => state.reports.reportsSlice.status);

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleToolbarAction(action, selectedIds) {
    setSelectedIds([...selectedIds]);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteReport({ groupId: "*", uuId: id }));
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleEdit(id) {
    const reportDefinitionId = _.get(dataMap[id], "reportDefinitionUuId");
    dispatch(setActiveId(id));
    History.push(`/reports/${reportDefinitionId}/reports/${id}`);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getReports" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
    if (
      status &&
      status.method === "deleteReport" &&
      status.result === "success"
    ) {
      setOpenConfirmDeleteDialog(false);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getReports("*"));
  }, []);

  return (
    <>
      <Box
        sx={{
          p: theme.spacing(2),
          flexBasis: "85%",
          display: "flex",
        }}
      >
        {loaded && data.length > 0 && (
          <NtaiSimpleTable
            rows={data}
            headCells={headCells}
            keyColumn="uuId"
            dialog={true}
            toolbarActions={[{ value: "delete", label: "Delete" }]}
            handleToolbarAction={handleToolbarAction}
            handleDialogAddEdit={handleEdit}
            title={`Reports (${data.length})`}
          />
        )}
        {loaded && data.length === 0 && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <NtaiEmptyMessage
              header="No reports found"
              subheader="Click on + NEW REPORT to generate new report"
            />
          </Box>
        )}
        {!loaded && <NtaiCircularProgress size={24} />}
      </Box>

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
