import { faEllipsisVAlt } from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import AlertDetails from "./details/AlertDetails";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import AlertEditSearchDistRequest from "./actions/AlertEditSearchDistRequest";
import { AlertEditContext } from "../AlertEdit";
const _ = require("lodash");

export default function AlertEditHeader() {
  const theme = useTheme();

  const { alertData } = React.useContext(AlertEditContext);

  

  return (
    <Box
      sx={{
        // height: "54px",
        display: "flex",
        alignItems: "center",
        // borderBottom: theme.general.border1,
        justifyContent: "space-between",
        boxShadow: theme.colors.shadows.cardSm,
      }}
    >
      <AlertDetails />

      <Box
        sx={{
          pl: theme.spacing(2),
          pr: theme.spacing(2),
          display: "flex",
          justifyContent: "end",
          gap: theme.spacing(1),
          alignItems: "center",
        }}
      >
        {alertData["alertDefInitAdjUuId"] &&
          alertData["alertDefInitAdjUuId"].length > 0 && (
            <AlertEditSearchDistRequest />
          )}

        <IconButton>
          <FontAwesomeIcon color="inherit" icon={faEllipsisVAlt} />
        </IconButton>
      </Box>
    </Box>
  );
}
