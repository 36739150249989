import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchContext } from "../../../../../SearchEdit";
import { SearchSourceContext } from "../../../../SearchSource";
import { SearchSourceRecordListContext } from "../../../SearchSourceRecordListPanel";
import { getSearchSourceRecordSourceDoc } from "./store/searchSourceRecordSourceDocsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useMeasure } from "react-use";
const _ = require("lodash");

export default function SearchSourceRecordSourceDoc({ sourceFieldUuId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [docData, setDocData] = useState();
  const [ref, { width, height }] = useMeasure();

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);

  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const recordId = selectedRecord ? selectedRecord["recordId"] : null;

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordSourceDocsSlice.data
  );
  const status = useSelector(
    (state) => state.search.searchSourceRecordSourceDocsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? dataMap[sourceFieldUuId] : null;

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchSourceRecordSourceDoc" &&
      status.result === "success"
    ) {
      setDocData(_.get(data, "data"));
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSourceRecordSourceDoc({
        searchId: searchId,
        searchSourceId: searchSourceId,
        recordId: recordId,
        uuId: sourceFieldUuId,
      })
    );
  }, [sourceFieldUuId]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
      ref={ref}
    >
      {loaded && docData && docData.length > 0 && (
        <embed
          src={`data:application/pdf;base64,${docData}`}
          width="100%"
          height="100%"
        />
      )}

      {!loaded && <NtaiCircularProgress size={24} />}
    </Box>
  );
}
