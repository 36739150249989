import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  setDomainUuId,
  setObjectUuId,
  updateEntity,
} from "./store/entitiesSlice";
import EntityForm from "./EntityForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useSnackbar } from "notistack";
import EntityUtils from "./util/EntityUtils";
import history from "@ntai/@history";
const _ = require("lodash");

export default function EntityEdit(props) {
  const dispatch = useDispatch();

  const domainId = props.match.params.domainId;
  const objectId = props.match.params.objectId;
  const entityId = props.match.params.entityId;

  const status = useSelector((state) => state.core.entitiesSlice.status);
  const dataMap = useSelector((state) => state.core.entitiesSlice.data);
  const objectData = _.get(dataMap, objectId);

  const formData = _.get(objectData, entityId);

  const entityObjects = useSelector(
    (state) => state.core.entitiesSlice.entityObjects
  );
  const entityObject = _.get(entityObjects, objectId);
  const { enqueueSnackbar } = useSnackbar();

  function handleSave(formData) {
    dispatch(
      updateEntity({
        domainId: domainId,
        objectId: objectId,
        uuId: entityId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setDomainUuId(domainId));
    dispatch(setObjectUuId(objectId));
    dispatch(setActiveId(entityId));
  }, []);

  useEffect(() => {
    if (status.method === "updateEntity" && status.result === "success") {
      dispatch(clearStatus());

      enqueueSnackbar(
        `Entity "${EntityUtils.getPrimaryFieldValue(
          _.get(dataMap[objectId], entityId)
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );

      history.push(
        `/entities-library/domains/${domainId}/objects/${objectId}/entities`
      );
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title={`${formData["objectName"]} / ${EntityUtils.getPrimaryFieldValue(
        formData
      )}`}
      back={`/entities-library/domains/${domainId}/objects/${objectId}/entities`}
    >
      <NtaiForm>
        <EntityForm
          action="edit"
          formData={formData}
          object={entityObject}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
