import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@mui/material";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import Scrollbar from "@ntai/components/Scrollbar";
import { resetIdCounter } from "downshift";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getFunctions } from "../../function/store/functionsSlice";
import { MODEL_FUNCTION_TYPE_CODE } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

export default function PackageFunctionsLookup({ handleSave, handleCancel }) {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext();

  const status = useSelector((state) => state.models.packagesSlice.status);

  const packageActiveId = useSelector(
    (state) => state.models.packagesSlice.activeId
  );

  const packagesDataMap = useSelector(
    (state) => state.models.packagesSlice.data
  );

  const packageData = _.get(packagesDataMap, packageActiveId);

  const functionsDataMap = useSelector(
    (state) => state.models.functionsSlice.data
  );

  // Show only functions of type src pipeline pre store
  const functionsData = _.filter(Object.values(functionsDataMap), {
    typeCode: MODEL_FUNCTION_TYPE_CODE.SRC_SOURCE_PIPELINE_PRE_STORE,
  });

  function onHandleSave() {
    const functionUuIds = getValues("packageForm.functionUuIds");

    const selectedFunctionUuIds = _.pickBy(functionUuIds, (v, k) => v);

    handleSave(
      !_.isEmpty(selectedFunctionUuIds)
        ? Object.keys(selectedFunctionUuIds)
        : []
    );
  }
  useEffect(() => {
    dispatch(getFunctions());
    const functionUuIds = {};

    packageData.functionUuIds.forEach((functionUuId, i) => {
      functionUuIds[functionUuId] = true;
    });

    setValue("packageForm.functionUuIds", functionUuIds);
  }, []);

  return (
    <NtaiDialogFormPanel
      header="Assign Functions"
      subheader=""
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{ height: "220px", display: "flex", border: theme.general.border1 }}
      >
        <Scrollbar>
          <Box sx={{ display: "flex", height: "100%" }}>
            <List sx={{ width: "100%", p: 0 }}>
              {functionsData.map((fd, i) => (
                <React.Fragment key={`package-function-list-item-${i}`}>
                  <ListItem key={`package-fd-${i}`} sx={{ p: 0 }}>
                    <ListItemButton>
                      <ListItemText
                        primary={fd.name}
                        secondary={fd.description}
                      />
                      <ListItemSecondaryAction sx={{ pr: theme.spacing(1) }}>
                        <NtaiCheckboxField
                          name={`packageForm.functionUuIds.${fd.uuId}`}
                          edge="end"
                        />
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Scrollbar>
      </Box>
    </NtaiDialogFormPanel>
  );
}
