import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import {
  clearStatus,
  uploadReportTemplate,
} from "./store/reportTemplatesSlice";
import { useState } from "react";
import NtaiMultiSelectField from "@ntai/components/inputs/NtaiMultiSelectField";
import { getSourceDefinitions } from "../../sourcedefinition/store/sourceDefinitionsSlice";
import { getDatasources } from "../../admin/datasource/store/datasourcesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function retrieveSources(sourceDefArr) {
  const result = [];
  if (Array.isArray(sourceDefArr) && sourceDefArr.length > 0) {
    _.orderBy(sourceDefArr, ["name"], ["asc"]).forEach((sourceDef) => {
      if (Array.isArray(sourceDef.sources) && sourceDef.sources.length > 0) {
        _.get(sourceDef, "sources").forEach((source) => {
          result.push({
            value: source["uuId"],
            label: sourceDef["name"].concat(
              " (",
              source["majorVersion"],
              ".",
              source["minorVersion"],
              ")"
            ),
          });
        });
      }
    });
  }

  return result;
}

export default function ReportTemplateForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleFileUpload } = props;

  const dispatch = useDispatch();
  const { getValues, reset, watch } = useFormContext();
  const [pending, setPending] = useState(false);
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);
  const status = useSelector((state) => state.core.reportTemplatesSlice.status);

  const typeOptions = _.has(
    codelistData,
    CODELIST_CODES.COR_TEMPLATES_REPORT_TYPE_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.COR_TEMPLATES_REPORT_TYPE_CODE)
    : [];

  const categoryOptions = _.has(
    codelistData,
    CODELIST_CODES.COR_TEMPLATES_REPORT_CATEGORY_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.COR_TEMPLATES_REPORT_CATEGORY_CODE)
    : [];

  const reportDocFormatOptions = _.has(
    codelistData,
    CODELIST_CODES.GL_REPORT_DOCUMENT_FORMAT_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.GL_REPORT_DOCUMENT_FORMAT_CODE)
    : [];

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceOptions =
    sourceDefDataMap && !_.isEmpty(sourceDefDataMap)
      ? retrieveSources(Object.values(sourceDefDataMap))
      : [];

  const datasourcesDataMap = useSelector(
    (state) => state.core.datasourcesSlice.data
  );

  const datasourceOptions =
    datasourcesDataMap && !_.isEmpty(datasourcesDataMap)
      ? NtaiUtils.getSelectOptions(
          Object.values(datasourcesDataMap),
          "uuId",
          "name"
        )
      : [];

  const watchSourceDatasourceType = watch(
    "reportTemplateForm.sourceDatasourceTypeCode"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("reportTemplateForm")
    );

    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push("/admin/templates/reports");
  }

  function displayUploadedFile() {
    return (
      <NtaiLabelValue
        label="Uploaded File"
        value={_.get(formData, "templateFileName")}
      />
    );
  }

  useEffect(() => {
    if (
      watchSourceDatasourceType &&
      ((!_.isEmpty(watchSourceDatasourceType) &&
        _.has(watchSourceDatasourceType, "value") &&
        watchSourceDatasourceType["value"] === 2) ||
        (watchSourceDatasourceType &&
          _.isNumber(
            watchSourceDatasourceType && watchSourceDatasourceType === 2
          )))
    ) {
      // setSourceDatasource
    }
  }, [watchSourceDatasourceType]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.COR_TEMPLATES_REPORT_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.COR_TEMPLATES_REPORT_CATEGORY_CODE));
    dispatch(getCodes(CODELIST_CODES.GL_REPORT_DOCUMENT_FORMAT_CODE));
  }, []);

  useEffect(() => {
    if (status && status.method === "uploadReportTemplate") {
      if (status.result === "pending") setPending(true);
      if (status.result === "success") {
        setPending(false);
        dispatch(clearStatus());
      }
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceDefinitions({ activeFg: 1 }));
    dispatch(getDatasources());
    if (action === "add") reset({ reportTemplateForm: null });
    else if (action === "edit") reset({ reportTemplateForm: formData });
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexBasis: "50%",
          width: "100%",
        }}
      >
        <NtaiFormPanel
          width="100%"
          header="Report Template Information"
          subheader="Enter report name, description, type, format and data sources."
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
            }}
          >
            <NtaiTextField
              name="reportTemplateForm.name"
              placeholder="Name"
              label="Name*"
              rules={{ required: "Name is required" }}
            />
            <NtaiSwitchField
              name="reportTemplateForm.activeFg"
              label="Active?"
            />
          </Box>

          <NtaiTextField
            name="reportTemplateForm.description"
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Description"
            label="Description"
          />

          <NtaiMultiSelectField
            name="reportTemplateForm.sourceUuIds"
            options={sourceOptions}
            label="Sources*"
            rules={{ required: "Atleast one source must be selected" }}
          />

          <NtaiSelectField
            name="reportTemplateForm.sourceDatasourceTypeCode"
            options={[
              { value: 1, label: "Source Document (JSON)" },
              { value: 2, label: "External Datasource (RDBMS)" },
            ]}
            label="Source Format*"
            rules={{ required: "Source format is required" }}
          />

          {_.get(
            getValues("reportTemplateForm.sourceDatasourceTypeCode"),
            "value"
          ) === 2 && (
            <NtaiSelectField
              name="reportTemplateForm.datasourceUuId"
              options={datasourceOptions}
              label="Datasource"
            />
          )}

          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
            }}
          >
            <NtaiSelectField
              isDisabled={action === "edit" ? true : false}
              name="reportTemplateForm.typeCode"
              options={typeOptions}
              label="Report Type*"
              rules={{ required: "Report type is required" }}
            />
            <NtaiSelectField
              isDisabled={action === "edit" ? true : false}
              name="reportTemplateForm.documentFormatCode"
              options={reportDocFormatOptions}
              label="Report Output Format*"
              rules={{ required: "Report output format is required" }}
            />
          </Box>

          <NtaiTextField
            name="reportTemplateForm.sourceReportParam"
            placeholder="Param"
            label="Report Param Name*"
            rules={{ required: "Report param name is required" }}
          />
        </NtaiFormPanel>
      </Box>
      {action === "edit" && (
        <Box
          sx={{
            display: "flex",
            flexBasis: "50%",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <NtaiPanel
            width="100%"
            header="Upload File"
            subheader="Upload JRXML report template file designed using Jasper Report Studio"
          >
            <NtaiFileUpload
              pending={pending}
              handleFileUpload={handleFileUpload}
            />
            {displayUploadedFile()}
          </NtaiPanel>
        </Box>
      )}
    </Box>
  );
}
