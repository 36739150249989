import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../../../../../http/ntaiServer";
import _ from "lodash";

export const getCodelists = createAsyncThunk(
  "codelists/getCodelists",
  async (values) => {
    const { params } = values ? values : {};
    const response = await server.get(
      "/codelists",
      params && {
        params: params,
      }
    );
    return response.data;
  }
);

export const getCodelist = createAsyncThunk(
  "codelists/getCodelist",
  async (code) => {
    const response = await server.get(`/codelists/${code}`);
    return response.data;
  }
);

export const getCodes = createAsyncThunk("codelists/getCodes", async (code) => {
  const response = await server.get(`/codes/${code}`);
  return { code: code, codes: response.data };
});

export const createCodelist = createAsyncThunk(
  "codelists/createCodelist",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/codelists", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCodelist = createAsyncThunk(
  "codelists/updateCodelist",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/codelists/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCodelist = createAsyncThunk(
  "codelists/deleteCodelist",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/codelists/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const codelistsSlice = createSlice({
  name: "codelists",
  initialState: {
    data: {},
    codes: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getCodelists.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getCodelists" };
    },
    [getCodelist.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getCodelist" };
    },
    [createCodelist.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createCodelist" };
    },
    [createCodelist.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createCodelist",
        message: action.payload.message,
      };
    },
    [updateCodelist.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateCodelist" };
    },
    [deleteCodelist.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteCodelist" };
    },
    [deleteCodelist.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteCodelist",
        message: action.payload.message,
      };
    },
    [getCodes.fulfilled]: (state, action) => {
      state.codes = {
        ...state.codes,
        [action.payload.code]: action.payload.codes,
      };
      state.status = { result: "success", method: "getCodes" };
    },
  },
});

export const { clearStatus, setActiveId } = codelistsSlice.actions;

export default codelistsSlice.reducer;
