import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlertRecordNotes = createAsyncThunk(
  "alertRecordNotes/getAlertRecordNotes",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/notes`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertRecordNote = createAsyncThunk(
  "alertRecordNotes/getAlertRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/notes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAlertRecordNote = createAsyncThunk(
  "alertRecordNotes/createAlertRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/notes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAlertRecordNote = createAsyncThunk(
  "alertRecordNotes/updateAlertRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId, formData } = values;
      const response = await server.patch(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/notes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAlertRecordNote = createAsyncThunk(
  "alertRecordNotes/deleteAlertRecordNote",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId } = values;
      const response = await server.delete(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/notes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertRecordNotesSlice = createSlice({
  name: "alertRecordNotes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, note) => {
      state.status = {};
    },
    setActiveId: (state, note) => {
      state.activeId = note.payload;
    },
  },
  extraReducers: {
    [getAlertRecordNotes.fulfilled]: (state, note) => {
      state.data = { ..._.mapKeys(note.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getAlertRecordNotes",
      };
    },
    [getAlertRecordNotes.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "getAlertRecordNotes",
        message: note.payload.message,
      };
    },
    [getAlertRecordNote.fulfilled]: (state, note) => {
      state.data = {
        ...state.data,
        [note.payload.uuId]: note.payload,
      };
      state.status = {
        result: "success",
        method: "getAlertRecordNote",
      };
    },
    [getAlertRecordNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "getAlertRecordNote",
        message: note.payload,
      };
    },
    [createAlertRecordNote.fulfilled]: (state, note) => {
      state.data = {
        ...state.data,
        [note.payload.uuId]: note.payload,
      };
      state.status = {
        result: "success",
        method: "createAlertRecordNote",
      };
    },
    [createAlertRecordNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "createAlertRecordNote",
        message: note.payload.message,
      };
    },
    [updateAlertRecordNote.fulfilled]: (state, note) => {
      state.data = {
        ...state.data,
        [note.payload.uuId]: note.payload,
      };
      state.status = {
        result: "success",
        method: "updateAlertRecordNote",
      };
    },
    [updateAlertRecordNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "updateAlertRecordNote",
        message: note.payload.message,
      };
    },
    [deleteAlertRecordNote.fulfilled]: (state, note) => {
      state.data = _.omit(state.data, note.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteAlertRecordNote",
      };
    },
    [deleteAlertRecordNote.rejected]: (state, note) => {
      state.status = {
        result: "error",
        method: "deleteAlertRecordNote",
        message: note.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = alertRecordNotesSlice.actions;

export default alertRecordNotesSlice.reducer;
