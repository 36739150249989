import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiGanttEChart from "./NtaiGanttEChart";
import NtaiGanttEChart1 from "./NtaiGanttEChart1";

// const data = {
//   categories: ["categoryA", "categoryB", "categoryC"],
//   types: [
//     { name: "JS Heap", color: "#7b9ce1" },
//     { name: "Documents", color: "#bd6d6c" },
//     { name: "Nodes", color: "#75d874" },
//     { name: "Listeners", color: "#e0bc78" },
//     { name: "GPU Memory", color: "#dc77dc" },
//     { name: "GPU", color: "#72b362" },
//   ],
// };

const data = {
  categories: ["Category A", "Category B"],
  values: [
    {
      name: "Category A",
      types: [
        {
          name: "A",
          start: new Date(2010, 1, 2),
          end: new Date(2011, 1, 1),
        },
        {
          name: "B",
          start: new Date(2011, 1, 2),
          end: new Date(2012, 1, 1),
        },
      ],
    },
    {
      name: "Category B",
      types: [
        {
          name: "A",
          start: new Date(2010, 1, 2),
          end: new Date(2011, 1, 1),
        },
        {
          name: "B",
          start: new Date(2011, 1, 2),
          end: new Date(2012, 1, 1),
        },
      ],
    },
  ],
};

export default function NtaiTestGantt() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Gantt chart">
          <NtaiGanttEChart1 />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>

      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
