import {
  Box,
  Button,
  Divider,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToolsAssessmentImportContext } from "./ToolsAssessmentImportCreate";
import { getObject } from "app/main/pages/core/cdo/object/store/objectsSlice";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ToolsAssessmentImportForm2(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [displayFile, setDisplayFile] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const { getValues } = useFormContext();
  const { localFormData, setLocalFormData, setAdjFormData, setSubmitFormData } =
    React.useContext(ToolsAssessmentImportContext);

  const { handleValidate, handleBack, handleCancel } = props;

  const objectsSliceStatus = useSelector(
    (state) => state.core.objectsSlice.status
  );

  const objectsDataMap = useSelector((state) => state.core.objectsSlice.data);

  const adjObject =
    !_.isEmpty(objectsDataMap) &&
    _.has(objectsDataMap, localFormData["adjObjectUuId"])
      ? objectsDataMap[localFormData["adjObjectUuId"]]
      : null;

  const adjForm =
    !_.isEmpty(objectsDataMap) &&
    _.has(objectsDataMap, localFormData["adjObjectUuId"])
      ? objectsDataMap[localFormData["adjObjectUuId"]]["objectForm"]
      : null;

  console.log("adjForm: ", adjForm);

  function handleCapture(e) {
    setSelectedFile(e.target.files[0]);
  }

  function onHandleContinue() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("toolsAssesmentImportForm2")
    );

    const sanitizedFormAdjData = NtaiUtils.sanitizeFormData(
      getValues("toolsAssesmentImportAdjForm")
    );
    if (sanitizedFormData) {
      const revisedFormData = ObjectFormUtil.generateFormSubmissionData(
        sanitizedFormAdjData,
        adjObject
      );

      const finalFormData = {
        name: localFormData["name"],
        description: localFormData["description"],
        sourceAdjudicationSchemeUuId:
          localFormData["sourceAdjudicationSchemeUuId"],
        objectUuId: localFormData["adjObjectUuId"],
        headerPresentFg: sanitizedFormData["headerPresentFg"] === 1 ? 1 : null,
        csvData: sanitizedFormData["csvData"],
        adjudication: {
          moduleCode: 6,
          clientData: {
            uuId: null,
            objectUuId: _.get(adjObject, "uuId"),
            ...revisedFormData,
          },
        },
      };

      const formData = new FormData();
      formData.append("files", selectedFile ? selectedFile : []);
      formData.append("data", JSON.stringify(finalFormData));
      console.log("finalFormData: ", finalFormData);
      setSubmitFormData(formData);
      setLocalFormData(finalFormData);
      // setLocalFormData(getValues("searchSeriesForm"));
      // setInitialFormData(formData);
      setAdjFormData(adjForm);
      handleValidate(formData);
    }
  }

  useEffect(() => {
    console.log("Import data to use: ", localFormData);

    if (
      _.has(localFormData, "adjObjectUuId") &&
      localFormData["adjObjectUuId"].length > 0
    ) {
      dispatch(
        getObject({ domainId: "*", uuId: localFormData["adjObjectUuId"] })
      );
    }
  }, [localFormData]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 54px)",
          bottom: "54px",
          width: "100%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexBasis: "50%",
            height: "100%",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          {adjForm && (
            <Scrollbar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  gap: theme.spacing(2),
                  p: theme.spacing(2),
                }}
              >
                {ObjectFormUtil.generateForm(
                  "toolsAssesmentImportAdjForm",
                  adjForm,
                  true,
                  theme
                )}
              </Box>
            </Scrollbar>
          )}
          {!adjForm && <NtaiCircularProgress />}
        </Box>
        <Box sx={{ display: "flex", flexBasis: "50%", p: theme.spacing(2) }}>
          <NtaiFormPanel
            padding="0px"
            width="100%"
            header="Upload CSV File or Paste Data"
            subheader="Upload CSV file or paste data following the structure as specified in delected import scheme for the source"
          >
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                component="label"
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
              >
                Upload File
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleCapture(e)}
                />
              </Button>
              {displayFile && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  }}
                >
                  <Typography variant="subtitle1">
                    {displayFile["name"]}
                  </Typography>
                  <Typography variant="subtitle1">
                    ({NtaiUtils.bytesToSize(displayFile["size"])})
                  </Typography>
                </Box>
              )}
            </Box>
            <Divider flexItem>OR</Divider>
            <NtaiSwitchField
              name="toolsAssesmentImportForm2.headerPresentFg"
              label="Header Present?"
            />
            <NtaiTextField
              name="toolsAssesmentImportForm2.csvData"
              label="CSV Data"
              multiline
              minRows={10}
              maxRows={10}
            />
          </NtaiFormPanel>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: theme.general.border1,
          px: theme.spacing(2),
          display: "flex",
          height: "54px",
          alignItems: "center",
          justifyContent: "end",
          gap: theme.spacing(1),
        }}
      >
        <Button size="small" onClick={() => handleCancel()}>
          CANCEL
        </Button>
        <Button size="small" onClick={() => handleBack(1)}>
          BACK
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => onHandleContinue()}
        >
          CONTINUE
        </Button>
      </Box>
    </Box>
  );
}
