import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import {
  clearStatus,
  setActiveId,
  testDatasourceConnection,
  updateDatasource,
} from "./store/datasourcesSlice";
import DatasourceForm from "./DatasourceForm";
import { Box, Typography, useTheme } from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function DatasourceEdit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [displayTestConnectionMessage, setDisplayTestConnectionMessage] =
    useState(false);
  const status = useSelector((state) => state.core.datasourcesSlice.status);
  const dataMap = useSelector((state) => state.core.datasourcesSlice.data);
  const activeId = useSelector((state) => state.core.datasourcesSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateDatasource({ uuId: id, formData: formData }));
  }

  function handleTestConnection() {
    dispatch(testDatasourceConnection(formData["uuId"]));
  }

  function handleCancel() {
    history.push("/admin/datasources");
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (status.method === "updateDatasource" && status.result === "success") {
      enqueueSnackbar(
        `Datasource "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );

      // history.push(`/admin/datasources`);
      dispatch(clearStatus());
    }

    if (
      status.method === "testDatasourceConnection" &&
      status.result === "success"
    ) {
      setDisplayTestConnectionMessage(true);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back="/admin/datasources"
    >
      {displayTestConnectionMessage && (
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            px: theme.spacing(2),
            py: theme.spacing(1),
            background: formData["statusCode"] === 2 ? "lightgreen" : "red",
            justifyContent: "start",
          }}
        >
          <Typography
            variant="success"
            fontWeight="700"
            color={formData["statusCode"] === 2 ? "black" : "white"}
          >
            {formData["message"]}
          </Typography>
        </Box>
      )}
      <NtaiForm>
        <DatasourceForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleTestConnection={handleTestConnection}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
