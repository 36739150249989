import React from "react";
import { Box, Checkbox, Link, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import SearchSourceWidgetNtaiTable from "./SearchSourceWidgetNtaiTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

function handleFilter(t1, t2) {
  // console.log("T1 and t2: ", t1, t2);
}

export default function SearchSourceDataTableIIWidget({
  widgetId,
  data,
  metadata,
  handleDisplayRecord,
}) {
  const theme = useTheme();

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;
  const fieldArray = _.orderBy(
    Object.values(_.get(metadata, "fields")),
    ["fieldOrder"],
    ["asc"]
  );

  function generateColumnDefs(fieldArray) {
    const colDefs = [];

    fieldArray.forEach((widgetField, i) => {
      const dateFieldFg =
        [7, 8].includes(widgetField["field"]["dataTypeCode"]) ||
        widgetField["esDataTypeCode"] === 3
          ? 1
          : 0;

      colDefs.push({
        size: 2,
        minSize: 2,
        accessorKey: widgetField.field.path,
        header: widgetField.headerName || widgetField.field.label,
        primary: i === 0 ? true : false,
        colorSchemes: widgetField["colorSchemes"],
        Cell: ({ cell, row }) =>
          i === 0 ? (
            <Link
              sx={{
                fontWeight: "500",
                color: blue[700],
                textDecoration: "underline",
                "&:hover": {
                  color: blue[700],
                  cursor: "pointer",
                },
              }}
              underline="hover"
              onClick={(e) =>
                handleDisplayRecord(e, row.original["context"]["_id"])
              }
            >
              {dateFieldFg !== 1
                ? cell.getValue()
                : NtaiUtils.formatDateYYYYMONDD(
                    new Date(_.toNumber(cell.getValue()))
                  )}
            </Link>
          ) : (
            <React.Fragment>
              {dateFieldFg !== 1
                ? cell.getValue()
                : NtaiUtils.formatDateYYYYMONDD(
                    new Date(_.toNumber(cell.getValue()))
                  )}
            </React.Fragment>
          ),
      });
    });

    return colDefs;
  }

  const columns = generateColumnDefs(fieldArray);

  function onHandleRowClick(id) {}

  return (
    <SearchSourceWidgetNtaiTable
      enableRowClick={false}
      data={data}
      columns={columns}
      handleRowClick={onHandleRowClick}
    />
  );
}
