import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SearchSummarySourceRecordListWrapper from "./records/SearchSummarySourceRecordListWrapper";
import SearchSummaryNavigation from "./SearchSummaryNavigation";
import { SearchContext } from "../../../SearchEdit";
const _ = require("lodash");

export const SearchSummaryContext = React.createContext();

export default function SearchSummary() {
  const theme = useTheme();
  const [navSearchSourceUuId, setNavSearchSourceUuId] = React.useState("0");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { searchData } = React.useContext(SearchContext);

  return (
    <SearchSummaryContext.Provider
      value={{ navSearchSourceUuId, setNavSearchSourceUuId }}
    >
      <Box
        sx={{
          mt: theme.spacing(2),
          display: "flex",
          height: "420px",
          border: theme.general.border1,
          borderRadius: theme.general.borderRadius,
        }}
      >
        <Box
          sx={{
            flexBasis: "25%",
            display: "flex",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          <SearchSummaryNavigation />
        </Box>
        <Box
          sx={{ flexBasis: "75%", display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              p: theme.spacing(1),
              display: "flex",
              height: "48px",
              borderBottom: theme.general.border1,
              alignItems: "center",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h6" fontWeight="700">
              All | Records ({searchData["searchSummaryRecordsCount"]})
            </Typography>
          </Box>

          <Box sx={{ height: `calc(100% - 48px)`, display: "flex" }}>
            <NtaiTabs>
              <Box label="RECORDS" sx={{ height: "100%", display: "flex" }}>
                <SearchSummarySourceRecordListWrapper />
              </Box>
              {/* <Box label="ANNOTATIONS" sx={{ height: "100%", display: "flex" }}>
                <SearchSummaryAnnotationList />
              </Box> */}
              {/* <Box label="SERIES" sx={{ height: "100%", display: "flex" }}>
                <SearchSummarySeriesList />
              </Box> */}
            </NtaiTabs>
          </Box>
        </Box>
      </Box>
    </SearchSummaryContext.Provider>
  );
}
