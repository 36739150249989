import DatasetCreate from "./dataset/DatasetCreate";
import DatasetEdit from "./dataset/DatasetEdit";
import DatasetList from "./dataset/DatasetList";
import FunctionCreate from "./function/FunctionCreate";
import FunctionEdit from "./function/FunctionEdit";
import FunctionList from "./function/FunctionList";
import ModelCreate from "./models/ModelCreate";
import ModelEdit from "./models/ModelEdit";
import ModelList from "./models/ModelList";
import PackageConfig from "./package/PackageConfig";
import PythonFileConfig from "./pythonfile/PythonFileConfig";
import ReturnTypeList from "./returntype/ReturnTypeList";

const ModelsConfig = {
  routes: [
    ...PackageConfig.routes,
    ...PythonFileConfig.routes,
    {
      path: "/models/datasets",
      exact: true,
      component: DatasetList,
    },
    {
      path: "/models/datasets/create",
      exact: true,
      component: DatasetCreate,
    },
    {
      path: "/models/datasets/:id/edit",
      exact: true,
      component: DatasetEdit,
    },
    {
      path: "/models/models",
      exact: true,
      component: ModelList,
    },
    {
      path: "/models/models/create",
      exact: true,
      component: ModelCreate,
    },
    {
      path: "/models/models/:id/edit",
      exact: true,
      component: ModelEdit,
    },
    {
      path: "/models/functions",
      exact: true,
      component: FunctionList,
    },
    {
      path: "/models/functions/create",
      exact: true,
      component: FunctionCreate,
    },
    {
      path: "/models/functions/:id/edit",
      exact: true,
      component: FunctionEdit,
    },

    {
      path: "/models/function-return-types",
      exact: true,
      component: ReturnTypeList,
    },
  ],
};

export default ModelsConfig;
