import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";

const _ = require("lodash");

export const getSearchNotes = createAsyncThunk(
  "searchNotes/getSearchNotes",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, formData } = values;
      const response = await server.put(
        `/searches/${searchId}/notes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getSearchNote = createAsyncThunk(
  "searchNotes/getSearchNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, uuId } = values;
      const response = await server.get(`/searches/${searchId}/notes/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createSearchNote = createAsyncThunk(
  "searchNotes/createSearchNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, formData } = values;
      const response = await server.post(
        `/searches/${searchId}/notes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchNote = createAsyncThunk(
  "searchNotes/updateSearchNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, uuId, formData } = values;
      const response = await server.patch(
        `/searches/${searchId}/notes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchNote = createAsyncThunk(
  "searchNotes/deleteSearchNote",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, uuId } = values;
      const response = await server.delete(
        `/searches/${searchId}/notes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchNoteSlice = createSlice({
  name: "searchNotes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchNotes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSearchNotes" };
    },
    [getSearchNotes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchNotes",
        message: action.payload.message,
      };
    },

    [getSearchNote.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSearchNote" };
    },
    [getSearchNote.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSearchNote",
      };
    },
    [getSearchNote.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchNote",
        message: action.payload.message,
      };
    },

    [createSearchNote.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSearchNote" };
    },
    [createSearchNote.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchNote",
        message: action.payload.message,
      };
    },
    [updateSearchNote.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { method: "updateSearchNote", result: "success" };
    },
    [updateSearchNote.rejected]: (state, action) => {
      state.status = "error";
      state.method = "updateSearchNote";
      state.message = action.payload.message;
    },
    [deleteSearchNote.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSearchNote" };
    },
    [deleteSearchNote.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchNote",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchNoteSlice.actions;

export default searchNoteSlice.reducer;
