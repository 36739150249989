import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceOutDatasetScdFields = createAsyncThunk(
  "sourceDatasets/getSourceOutDatasetScdFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId, sourceOutDatasetId } =
        values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/scd-fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceOutDatasetScdField = createAsyncThunk(
  "sourceOutDatasetScdFields/createSourceOutDatasetScdField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/scd-fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceOutDatasetScdField = createAsyncThunk(
  "sourceOutDatasetScdFields/deleteSourceOutDatasetScdField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        uuId,
      } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/scd-fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceOutDatasetScdFields = createAsyncThunk(
  "sourceOutDatasetScdFields/updateSourceOutDatasetScdFields",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/scd-fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceOutDatasetScdFieldsSlice = createSlice({
  name: "sourceOutDatasetScdFields",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceOutDatasetScdFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceOutDatasetScdFields",
      };
    },
    [getSourceOutDatasetScdFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutDatasetScdFields",
        message: action.payload.message,
      };
    },

    [createSourceOutDatasetScdField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceOutDatasetScdField",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceOutDatasetScdField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceOutDatasetScdField",
        message: action.payload.message,
      };
    },

    [updateSourceOutDatasetScdFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "updateSourceOutDatasetScdFields",
      };
    },
    [updateSourceOutDatasetScdFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceOutDatasetScdFields",
        message: action.payload.message,
      };
    },

    [deleteSourceOutDatasetScdField.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "deleteSourceOutDatasetScdField",
      };
    },
    [deleteSourceOutDatasetScdField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceOutDatasetScdField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceOutDatasetScdFieldsSlice.actions;

export default sourceOutDatasetScdFieldsSlice.reducer;
