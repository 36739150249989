import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import SourceRecordLabelSecondaryList from "./secondary/SourceRecordLabelSecondaryList";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceRecordLabelTagList from "./tag/SourceRecordLabelTagList";
const _ = require("lodash");

export default function SourceRecordLabelForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourcesSlice.activeId
  );
  const sourceId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceDerivedFieldOptions = NtaiUtils.getSelectOptions(
    Object.values(sourceDerivedFieldsDataMap),
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceRecordLabelForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ sourceRecordLabelForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ flexBasis: "60%", height: "100%", display: "flex" }}>
        <NtaiFormPanel
          width="520px"
          header="Primary Label"
          subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <NtaiTextField
                name="sourceRecordLabelForm.name"
                label="Name*"
                rules={{ required: "Name is required" }}
              />
              <NtaiSwitchField
                name="sourceRecordLabelForm.defaultFg"
                label="Default?"
              />
            </Box>

            <NtaiTextField
              name="sourceRecordLabelForm.description"
              multiline
              minRows={2}
              maxRows={2}
              placeholder="Description"
              label="Description"
            />
            <NtaiSelectField
              name="sourceRecordLabelForm.sourceDerivedFieldUuId"
              label="Source Field"
              options={sourceDerivedFieldOptions}
            />

            <NtaiTextField
              name="sourceRecordLabelForm.maxLength"
              placeholder="Maximum Length"
              label="Maximum Length"
              type="number"
            />
          </Box>
        </NtaiFormPanel>
      </Box>
      {action === "edit" && (
        <Box
          sx={{
            flexBasis: "40%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            borderLeft: theme.general.border1,
          }}
        >
          <NtaiFormPanel
            height="14%"
            header="Secondary Labels and Tags"
            subheader="Secondary labels and tags are used to display additional information pertaining to record. These details appear below the primary label."
          />
          <Box sx={{ display: "flex", height: `calc(100%-14%)` }}>
            <NtaiTabs>
              <Box
                sx={{ p: theme.spacing(2) }}
                label="Secondary Labels"
                chip={
                  _.isArray(_.get(formData, "secondaryLabels")) &&
                  _.get(formData, "secondaryLabels").length > 0 &&
                  _.get(formData, "secondaryLabels").length
                }
              >
                <SourceRecordLabelSecondaryList />
              </Box>
              <Box
                sx={{ p: theme.spacing(2) }}
                label="Tags"
                chip={
                  _.isArray(_.get(formData, "sourceRecordLabelTags")) &&
                  _.get(formData, "sourceRecordLabelTags").length > 0 &&
                  _.get(formData, "sourceRecordLabelTags").length
                }
              >
                <SourceRecordLabelTagList />
              </Box>
            </NtaiTabs>
          </Box>
        </Box>
      )}
    </Box>
  );
}
