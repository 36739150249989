import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
const _ = require("lodash");

const data = {
  metadata: {
    header: {
      1: [
        {
          label: "Drug",
          rowSpan: 3,
          colSpan: 1,
        },
        {
          label: "Event",
          rowSpan: 3,
          colSpan: 1,
        },
        {
          label: "Arm 1",
          rowSpan: 1,
          colSpan: 2,
          textAlign: "center",
        },
        {
          label: "Arm 2",
          rowSpan: 1,
          colSpan: 2,
          textAlign: "center",
        },
      ],
      2: [
        {
          label: "Serious",
          textAlign: "center",
          colSpan: 2,
        },
        {
          label: "Serious",
          textAlign: "center",
          colSpan: 2,
        },
      ],
      3: [
        {
          label: "Yes",
          textAlign: "center",
        },
        {
          label: "No",
          textAlign: "center",
        },
        {
          label: "Yes",
          textAlign: "center",
        },
        {
          label: "No",
          textAlign: "center",
        },
      ],
    },
    linkFields: [
      {
        uuId: "f1",
        esFieldPath: "f1.path",
      },
      {
        uuId: "f2",
        esFieldPath: "f2.path",
      },
      {
        uuId: "f3",
        esFieldPath: "f3.path",
      },
      {
        uuId: "f4",
        esFieldPath: "f4.path",
      },
    ],
  },
  rows: {
    1: [
      {
        value: "Aspirin",
      },
      {
        value: "Fibrosis",
      },
      {
        value: 73,
        linkParams: ["a", "b", "c", "d"],
        textAlign: "center",
      },
      {
        value: 67,
        linkParams: ["a", "b", "c", "d"],
        textAlign: "center",
      },
      {
        value: 9,
        linkParams: ["a", "b", "c", "d"],
        textAlign: "center",
      },
      {
        value: 126,
        linkParams: ["a", "b", "c", "d"],
        textAlign: "center",
      },
    ],
  },
};

function createSortHandler(field) {
  console.log("Sort on: ", field);
}

export default function NtaiXTabParallel() {
  const theme = useTheme();

  function handleCellClick(params) {
    console.log("Take to ", params);
  }

  function buildTableHeadRows() {
    return Object.values(data.metadata.header).map((tr, i) => (
      <TableRow key={`th-row-${i}`}>
        {Object.values(tr).map((trc, j) => (
          <TableCell
            key={`th-cell-${i}-${j}`}
            rowSpan={trc.rowSpan}
            colSpan={trc.colSpan}
            sx={{ textAlign: trc.textAlign }}
          >
            {/* <TableSortLabel onClick={(e) => createSortHandler(trc)}> */}
            {trc.label}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  function buildTableBody() {
    return Object.values(data.rows).map((tcols, i) => (
      <TableRow key={`tb-row-${i}`}>
        {Object.values(tcols).map((tcol, j) => (
          <TableCell
            key={`tb-cell-${i}-${j}`}
            sx={{ textAlign: tcol.textAlign }}
          >
            {_.has(tcol, "linkParams") ? (
              <Link
                color={blue[700]}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
                onClick={(e) => handleCellClick(tcol.linkParams)}
              >
                {tcol.value}
              </Link>
            ) : (
              tcol.value
            )}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  function callMe() {
    console.log("Clciked...");
  }

  return (
    <Box sx={{ p: theme.spacing(4) }}>
      <Table sx={{ border: theme.general.border1 }} size="small">
        <TableHead>{buildTableHeadRows()}</TableHead>
        <TableBody>{buildTableBody()}</TableBody>
      </Table>
    </Box>
  );
}
