import { Box, Chip, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createObjectFormBuilderPanel,
  deleteObjectFormBuilderPanel,
  getObjectFormBuilderPanels,
  updateObjectFormBuilderPanel,
  clearStatus,
} from "./store/objectFormBuilderPanelsSlice";
import ObjectFormBuilderPanelList from "./ObjectFormBuilderPanelList";
import ObjectFormBuilderPanelForm from "./ObjectFormBuilderPanelForm";
import { ObjectFormBuilderContext } from "../ObjectFormBuilder";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import ObjectFormBuilderPanelReorder from "./ObjectFormBuilderPanelReorder";
const _ = require("lodash");

export default function ObjectFormBuilderPanels() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();

  const {
    selectedTabId,
    selectedPanelId,
    setSelectedPanelId,
    refreshPanels,
    setRefreshPanels,
  } = React.useContext(ObjectFormBuilderContext);

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);
  const objectFormId = useSelector(
    (state) => state.core.objectFormsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.objectFormBuilderPanelsSlice.data
  );
  const status = useSelector(
    (state) => state.core.objectFormBuilderPanelsSlice.status
  );

  const pathVariables = {
    domainId: domainId,
    objectId: objectId,
    objectFormId: objectFormId,
    objectFormTabId: selectedTabId,
  };

  function onHandleSelectPanel(e, id) {
    if (e && !e.target.id.includes("delete"))
      setSelectedPanelId(selectedPanelId === id ? null : id);
  }

  function onHandleAdd() {
    setAction("add");
    setCurrentId(null);
  }

  function onHandleEdit(id) {
    setAction("edit");
    setCurrentId(id);
    setSelectedPanelId(selectedPanelId === id ? null : id);
  }

  function onHandleDelete(e, id) {
    dispatch(
      deleteObjectFormBuilderPanel({
        ...pathVariables,
        uuId: id,
      })
    );
  }

  function handleCancel() {
    setAction("list");
    setCurrentId(null);
  }

  function handleSave(formData) {
    if (action === "add") {
      dispatch(
        createObjectFormBuilderPanel({
          ...pathVariables,
          formData,
        })
      );
    } else {
      dispatch(
        updateObjectFormBuilderPanel({
          ...pathVariables,
          uuId: currentId,
          formData: formData,
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      ["getObjectFormBuilderPanels"].includes(status.method) &&
      status.result === "success"
    ) {
      setRefreshPanels(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createObjectFormBuilderPanel", "updateObjectFormBuilderPanel"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setAction("list");
      setCurrentId(null);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["deleteObjectFormBuilderPanel"].includes(status.method) &&
      status.result === "success"
    ) {
      setSelectedPanelId(null);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (selectedTabId || refreshPanels)
      dispatch(getObjectFormBuilderPanels(pathVariables));

    if (!selectedTabId) setSelectedPanelId(null);
  }, [selectedTabId, refreshPanels]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "44px",
          width: "100%",
          justifyContent: "space-between",
          px: theme.spacing(1),
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <Typography variant="h6" fontWeight="700">
            Panels
          </Typography>
          {selectedTabId &&
            selectedPanelId &&
            dataMap &&
            !_.isEmpty(dataMap) && (
              <Chip
                variant="outlined"
                size="small"
                label={
                  <Typography variant="subtitle2" color="inherit">
                    {NtaiUtils.trunc(dataMap[selectedPanelId]["name"], 15)}
                  </Typography>
                }
              />
            )}
        </Box>

        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <ObjectFormBuilderPanelReorder
            disabled={selectedTabId && !_.isEmpty(dataMap) ? false : true}
          />
          <NtaiIconButton
            icon={faPlus}
            handleClick={onHandleAdd}
            disabled={selectedTabId ? false : true}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", height: `calc(100% - 44px)`, width: "100%" }}>
        <Scrollbar>
          {action === "list" && (
            <ObjectFormBuilderPanelList
              onHandleSelectPanel={onHandleSelectPanel}
              onHandleEdit={onHandleEdit}
              onHandleDelete={onHandleDelete}
            />
          )}
          {("add" === action || (currentId && action === "edit")) && (
            <ObjectFormBuilderPanelForm
              action={action}
              formData={action === "edit" ? dataMap[currentId] : null}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )}
        </Scrollbar>
      </Box>
    </Box>
  );
}
