import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";

import {
  getReportDefinitionParams,
  createReportDefinitionParam,
  updateReportDefinitionParam,
  deleteReportDefinitionParam,
  clearStatus,
} from "./store/reportDefinitionParamsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import ReportDefinitionParamForm from "./ReportDefinitionParamForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { Box, useTheme } from "@mui/material";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function ReportDefinitionParamList() {
  const theme = useTheme();
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const reportDefinitionId = useSelector(
    (state) => state.reports.reportDefinitionsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionParamsSlice.data
  );
  const status = useSelector(
    (state) => state.reports.reportDefinitionParamsSlice.status
  );
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(deleteReportDefinitionParam(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateReportDefinitionParam({
            reportDefinitionId: reportDefinitionId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createReportDefinitionParam({
            reportDefinitionId: reportDefinitionId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    dispatch(getReportDefinitionParams(reportDefinitionId));
    //dispatch(getRoles());

    dispatch(getCodes(CODELIST_CODES.RPT_DEFINITION_PARAM_FIELD_UI_CODE));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createReportDefinitionParam" ||
        status.method === "updateReportDefinitionParam")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteReportDefinitionParam"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <Box sx={{ p: theme.spacing(2) }}>
        <NtaiCrudTable
          helpText="There is always something about domains"
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          title="Report Parameter"
        />
      </Box>

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Parameter"
        size="sm"
      >
        <NtaiForm>
          <ReportDefinitionParamForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
