import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";

const _ = require("lodash");

am4core.useTheme(am4themes_animated);

function processData(date_hist_data, categoryField, valueField) {
  let revised_data = [];

  // TODO: Fix date value without 'y' as prefix in milliseconds
  date_hist_data.forEach((dat) => {
    let obj = {};
    obj[categoryField] = new Date(Number(_.get(dat, categoryField)))
      // .getFullYear()
      .toISOString()
      .slice(0, 10);
    obj["count"] = _.get(dat, valueField);
    // console.log("Date obj: ", obj);
    revised_data.push(obj);
  });

  console.log("Revised data: ", revised_data);

  return revised_data;
}

// function processData(lineChartData, categoryField, valueField, dateFormat) {
//   let revised_data = [];
//   let c = 0;
//   lineChartData.forEach((dat) => {
//     let obj = {};
//     console.log("dat: ", dat, dateFormat);
//     obj[categoryField] = NtaiUtils.strToDate(dat[categoryField], dateFormat);
//     obj[valueField] = _.get(dat, valueField);
//     revised_data.push(obj);
//   });

//   console.log("Revised date hist: ", revised_data);
//   return revised_data;
// }

export default function NtaiLineChart(props) {
  const theme = useTheme();
  const {
    widgetId,
    data,
    categoryField,
    valueField,
    dateFormat,
    scrollBar,
    handleClick,
    metadata,
  } = props;

  useLayoutEffect(() => {
    let chart = am4core.create(
      "linechart".concat("-", widgetId),
      am4charts.XYChart
    );
    chart.paddingRight = 20;

    chart.data = processData(data, categoryField, valueField);

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    categoryAxis.renderer.minGridDistance = 60;
    let categoryAxisLabel = categoryAxis.renderer.labels.template;
    categoryAxisLabel.fontSize = 10;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.baseValue = 0;
    let valueAxisLabel = valueAxis.renderer.labels.template;
    valueAxisLabel.fontSize = 10;

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = valueField;
    series.dataFields.dateX = categoryField;
    series.strokeWidth = 3;
    series.tensionX = 0.77;
    series.stroke = am4core.color(lighten(theme.colors.chart.lighter, 0.5));

    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#fff");
    bullet.circle.strokeWidth = 2;

    chart.cursor = new am4charts.XYCursor();

    let axisTooltip = categoryAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 8;
    categoryAxis.cursorTooltipEnabled = true;
    categoryAxis.sortBySeries = series;

    let valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#767676");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    valueAxisTooltip.rotation = 270;
    valueAxisTooltip.dy = 5;
    valueAxisTooltip.dx = -20;
    valueAxisTooltip.label.fill = am4core.color("#fff");
    valueAxisTooltip.label.fontSize = 11;

    bullet.events.on("hit", function (ev) {
      // console.log(
      //   "Clicked on " +
      //     ev.target.dataItem.dateX +
      //     ": " +
      //     ev.target.dataItem.valueY
      // );

      const sourceWidgetFilterFields = [
        {
          sourceFieldUuId: _.filter(Object.values(_.get(metadata, "fields")), {
            fieldOrder: 1,
          })[0].field.uuId,
          fieldOperatorCode: 1,
          fieldValue: new Date(ev.target.dataItem.dateX)
            .toISOString()
            .slice(0, 10),
        },
      ];

      // console.log("sourceWidgetFilterFields: ", sourceWidgetFilterFields);
      handleClick(sourceWidgetFilterFields, ev);
    });

    if (scrollBar) {
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;
    }

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"linechart".concat("-", widgetId)}
      style={{ width: "100%", height: "100%" }}
    ></Box>
  );
}
