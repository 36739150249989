import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import React from "react";
import NtaiIcons from "utils/NtaiIcons";

export default function NtaiMenu(props) {
  const theme = useTheme();
  const {
    open,
    anchorEl,
    items,
    handleClose,
    handleMenuItemSelect,
    vertical,
    horizontal,
  } = props;

  function generateMenuItems() {
    let menuItems = [];
    items &&
      Array.isArray(items) &&
      items.length > 0 &&
      items.forEach((item, index) => {
        menuItems.push(
          <MenuItem
            sx={{ display: "flex", gap: theme.spacing(1) }}
            key={index}
            onClick={() => handleMenuItemSelect(item.value)}
          >
            {item.hasOwnProperty("icon") && (
              <FontAwesomeIcon icon={NtaiIcons[item.icon]} />
            )}

            {item.label}
          </MenuItem>
        );
      });

    return menuItems;
  }
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: vertical ? vertical : "bottom",
        horizontal: horizontal ? horizontal : "left",
      }}
    >
      {generateMenuItems()}
    </Menu>
  );
}
