import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordSeriesList = createAsyncThunk(
  "searchSourceRecordSeries/getSearchSourceRecordSeriesList",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId } = values;

      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/series`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordSeries = createAsyncThunk(
  "searchSourceRecordSeriess/getSearchSourceRecordSeries",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/series/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecordSeries = createAsyncThunk(
  "searchSourceRecordSeriess/createSearchSourceRecordSeries",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;

      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/series`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecordSeries = createAsyncThunk(
  "searchSourceRecordSeriess/updateSearchSourceRecordSeries",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/series/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceRecordSeries = createAsyncThunk(
  "searchSourceRecordSeriess/deleteSearchSourceRecordSeries",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/series/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordSeriesSlice = createSlice({
  name: "searchSourceRecordSeries",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceRecordSeriesList.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordSeriesList",
      };
    },
    [getSearchSourceRecordSeriesList.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordSeriesList",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordSeries",
      };
    },
    [getSearchSourceRecordSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordSeries",
        message: action.payload.message,
      };
    },
    [createSearchSourceRecordSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceRecordSeries",
      };
    },
    [createSearchSourceRecordSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecordSeries",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecordSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceRecordSeries",
      };
    },
    [updateSearchSourceRecordSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecordSeries",
        message: action.payload.message,
      };
    },
    [deleteSearchSourceRecordSeries.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceRecordSeries",
      };
    },
    [deleteSearchSourceRecordSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceRecordSeries",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceRecordSeriesSlice.actions;

export default searchSourceRecordSeriesSlice.reducer;
