import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordTemplates = createAsyncThunk(
  "sourceRecordTemplates/getSourceRecordTemplates",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/record-templates`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOtherSourceRecordTemplates = createAsyncThunk(
  "sourceRecordTemplates/getOtherSourceRecordTemplates",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/record-templates`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceRecordTemplate = createAsyncThunk(
  "sourceRecordTemplates/getSourceRecordTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/record-templates/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordTemplate = createAsyncThunk(
  "sourceRecordTemplates/createSourceRecordTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/record-templates`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordTemplate = createAsyncThunk(
  "sourceRecordTemplates/updateSourceRecordTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/record-templates/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordTemplateFields = createAsyncThunk(
  "sourceRecordTemplates/updateSourceRecordTemplateFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/record-templates/${uuId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordTemplate = createAsyncThunk(
  "sourceRecordTemplates/deleteSourceRecordTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/record-templates/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordTemplatesSlice = createSlice({
  name: "sourceRecordTemplates",
  initialState: {
    data: {},
    otherSourceTemplatesData: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordTemplates.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceRecordTemplates" };
    },
    [getSourceRecordTemplates.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordTemplates",
        message: action.payload.message,
      };
    },
    [getOtherSourceRecordTemplates.fulfilled]: (state, action) => {
      state.otherSourceTemplatesData = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getOtherSourceRecordTemplates",
      };
    },
    [getOtherSourceRecordTemplates.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOtherSourceRecordTemplates",
        message: action.payload.message,
      };
    },
    [getSourceRecordTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceRecordTemplate" };
    },
    [getSourceRecordTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordTemplate",
        message: action.payload.message,
      };
    },
    [createSourceRecordTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordTemplate",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceRecordTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordTemplate",
        message: action.payload.message,
      };
    },
    [updateSourceRecordTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordTemplate",
      };
    },
    [updateSourceRecordTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordTemplate",
        message: action.payload.message,
      };
    },

    [updateSourceRecordTemplateFields.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordTemplateFields",
      };
    },
    [updateSourceRecordTemplateFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordTemplateFields",
        message: action.payload.message,
      };
    },

    [deleteSourceRecordTemplate.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordTemplate",
      };
    },
    [deleteSourceRecordTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordTemplate",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceRecordTemplatesSlice.actions;

export default sourceRecordTemplatesSlice.reducer;
