import React, { useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
const _ = require("lodash");

export default function SearchSourceRecordNoteForm({
  action,
  handleSave,
  handleCancel,
  formData,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(getValues("noteForm"));
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ noteForm: action === "edit" ? formData : {} });
  }, []);
  return (
    <NtaiFormPanel
      width="80%"
      header="Notes"
      subheader="Manage notes and remarks pertaining to source record"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField name="noteForm.title" label="Title" placeholder="Title" />

      <NtaiTextField
        name="noteForm.body"
        label="Description"
        minRows={10}
        maxRows={10}
        multiline
        placeholder="Your notes"
      />
    </NtaiFormPanel>
  );
}
