import { Box, useTheme } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateFunction } from "../../store/functionsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { FunctionEditorContext } from "../../FunctionEdit";
import NtaiFilterMultiSelectList from "@ntai/components/inputs/filters/NtaiFilterMultiSelectList";
import NtaiFilterCheckboxGroup from "@ntai/components/inputs/filters/NtaiFilterCheckboxGroup";
import { getPythonFiles } from "../../../pythonfile/store/pythonFilesSlice";

const _ = require("lodash");

export default function FunctionPythonFileSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset, watch } = useFormContext();

  const { action, formData } = useContext(FunctionEditorContext);

  const pythonFilesDataMap = useSelector(
    (state) => state.models.pythonFilesSlice.data
  );

  const activeId = useSelector((state) => state.models.functionsSlice.activeId);
  const status = useSelector((state) => state.models.functionsSlice.status);

  const watchPythonFileSelection = watch(
    "functionForm.pythonFileUuIds",
    _.get(formData, "pythonFileUuIds")
  );

  const pythonFileOptions = NtaiUtils.getSelectOptions(
    Object.values(pythonFilesDataMap),
    "uuId",
    "fileName"
  );

  function updatePythonFile() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("functionForm")
    );

    if (
      _.has(sanitizedFormData, "pythonFileUuIds") &&
      !_.isEqual(
        _.get(sanitizedFormData, "pythonFileUuIds"),
        _.get(formData, "pythonFileUuIds")
      )
    ) {
      dispatch(
        updateFunction({
          uuId: activeId,
          formData: sanitizedFormData,
        })
      );
    }
  }

  useEffect(() => {
    updatePythonFile();
  }, [watchPythonFileSelection]);

  useEffect(() => {
    dispatch(getPythonFiles());
  }, []);

  return (
    <NtaiAccordion title="Python Files" titleIcon="table">
      <Box sx={{ display: "flex", p: 0, flexDirection: "column" }}>
        {Array.isArray(pythonFileOptions) && pythonFileOptions.length > 0 && (
          <NtaiFilterMultiSelectList
            name="functionForm.pythonFileUuIds"
            options={pythonFileOptions}
          />
        )}
        {!pythonFileOptions ||
          (Array.isArray(pythonFileOptions) &&
            pythonFileOptions.length === 0 && (
              <Box>No python files available</Box>
            ))}
      </Box>
    </NtaiAccordion>
  );
}
