import NtaiPopperPanel from "@ntai/components/poppers/NtaiPopperPanel";
import React from "react";
import { useEffect } from "react";
import {
  clearStatus,
  deleteSearchSourceHierarchyNode,
  getSearchSourceHierarchy,
  selectSearchSourceHierarchyNode,
} from "./store/searchSourceHierarchySlice";

import { SearchContext } from "../../../SearchEdit";
import { SearchSourceContext } from "../../SearchSource";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiSourceHierarchyTree from "./NtaiSourceHierarchyTree";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import Scrollbar from "@ntai/components/Scrollbar";
import { faSitemap, faTree } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function SearchSourceHierarchy({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "uuId");

  const dataMap = useSelector(
    (state) => state.search.searchSourceHierarchySlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSourceHierarchySlice.status
  );

  const data = dataMap[searchSourceId];


  function handleDelete(uuId) {
    dispatch(
      deleteSearchSourceHierarchyNode({
        searchId: searchId,
        searchSourceId: searchSourceId,
        uuId: uuId,
      })
    );
  }

  function handleSelect(uuId) {
    dispatch(
      selectSearchSourceHierarchyNode({
        searchId: searchId,
        searchSourceId: searchSourceId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchSourceHierarchy" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "selectSearchSourceHierarchyNode" &&
      status.result === "success"
    ) {
      handleClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSourceHierarchy({
        searchId: searchId,
        searchSourceId: searchSourceId,
      })
    );
  }, [searchId, searchSourceId]);

  return (
    <Box
      sx={{
        height: "320px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faSitemap} />
        <Typography variant="h5" fontWeight="500" color={grey[700]}>
          Source Navigation
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        {loaded &&
          data &&
          _.isArray(_.get(data, "children")) &&
          _.get(data, "children").length > 0 && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                border: theme.general.border1,
                boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
              }}
            >
              <Scrollbar>
                <NtaiSourceHierarchyTree
                  idField="uuId"
                  nameField="sourceName"
                  data={data}
                  handleSelect={handleSelect}
                  handleDelete={handleDelete}
                />
              </Scrollbar>
            </Box>
          )}
        {loaded &&
          data &&
          _.isArray(_.get(data, "children")) &&
          _.get(data, "children").length === 0 && (
            <NtaiEmptyMessage
              vAlign="center"
              header="No child sources present"
              subheader="Child sources are created when user navigates from parent source to one or more child sources"
            />
          )}
        {!loaded && <NtaiCircularProgress />}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
