import { Box, Button, Divider, IconButton } from "@mui/material";
import React, { useState } from "react";
import NtaiSimpleSearchBar1 from "./simple/NtaiSimpleSearchBar1";
import NtaiForm from "../forms/NtaiForm";
import { useFormContext } from "react-hook-form";
import { grey } from "@mui/material/colors";
import NtaiSmartSearchBar from "./smart/NtaiSmartSearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faExpand, faSearch } from "@fortawesome/pro-light-svg-icons";

export default function TestNtaiSearchBar() {
  const { getValues } = useFormContext();
  const [options, setOptions] = useState([]);

  function onHandleSubmit(val) {
    console.log("Value = ", val);
  }

  function handleInputChange(str) {
    // setInputValue(str);
  }

  function handleChange(qTerms) {
    // const revisedSearchQueryTerms = [];
    // qTerms.forEach((qt, i) => {
    //   if (typeof qt === "object" && !Array.isArray(qt) && qt !== null) {
    //     const st = _.filter(searchQueryTerms, function (s) {
    //       if (
    //         s["term"] === qt["term"] &&
    //         s["conceptId"] === qt["conceptId"] &&
    //         s["thesaurusUuId"] === qt["thesaurusUuId"]
    //       )
    //         return true;
    //     });
    //     if (st.length > 0) {
    //       revisedSearchQueryTerms.push(st[0]);
    //     } else {
    //       revisedSearchQueryTerms.push(qt);
    //     }
    //   } else {
    //     revisedSearchQueryTerms.push(qt);
    //   }
    // });
    // setSearchQueryTerms([...revisedSearchQueryTerms]);
  }

  function submitSearch() {
    // handleSearch(getValues("searchInitForm.query"));
  }

  function handleTagClick(index, tag) {
    // setThesaurusDialogTermIndex(index);
  }

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: "8px", p: "12px" }}
    >
      <Button onClick={() => onHandleSubmit()}>GET VALUES</Button>
      <NtaiSimpleSearchBar1
        background={grey[100]}
        height="24px"
        width="120px"
        name="searchname"
        onSearchSubmit={onHandleSubmit}
      />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <NtaiSmartSearchBar
          options={options}
          placeholder="Search for anything..."
          name="searchInitForm.query"
          optionLabelField="term"
          defaultValue={[]}
          label="Search"
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleTagClick={handleTagClick}
          terms={[]}
          endAdornments={
            <>
              <>
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faExpand} />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faBars} />
                </IconButton>
              </>
            </>
          }
        />

        <Button
          onClick={() => submitSearch()}
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faSearch} />}
          icon="search"
        >
          Search
        </Button>
      </Box>
    </Box>
  );
}
