import React, { useState } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSparkRadialBarChart from "@ntai/components/charts/apexcharts/NtaiSparkRadialBarChart";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function SourceBoxMin(props) {
  const { source, handleSelect } = props;

  const theme = useTheme();
  const [selected, setSelected] = useState(false);

  function onHandleSelect() {
    setSelected(!selected);
    handleSelect(_.get(source, "uuId"));
  }

  return (
    <Box
      onClick={() => onHandleSelect()}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "105px",
        height: "48px",
        background: "white",
        border: theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexBasis: "40%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <NtaiSparkRadialBarChart
            fontSize="10px"
            fontWeight="bold"
            label={NtaiUtils.abbrevNumber(_.get(source, "hits"))}
            series={100}
          />
        </Box>
        <Box
          sx={{
            flexBasis: "60%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <Typography
            // variant="caption"
            sx={{
              color: grey[700],
              fontWeight: "700",
              fontSize: "11px",
              "&:hover": {
                color: "black",
              },
            }}
          >
            {source.abbrev}
          </Typography>
          <Typography sx={{ fontSize: "6px" }}>{source.name}</Typography>
        </Box>
      </Box>

      {/* <Box
        sx={{
          width: "100%",
          height: "70px",
          display: "flex",
          padding: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {_.has(source, "hits") && _.get(source, "hits") > 0 ? (
            <NtaiSparkRadialBarChart
              label={NtaiUtils.abbrevNumber(_.get(source, "hits"))}
              series={75}
            />
          ) : (
            // <FontAwesomeIcon size="2x" icon={faDatabase} />
            <NtaiSparkRadialBarChart label="1.56k" series={34} />
          )}
        </Box>
        <Box
          sx={{
            width: "150px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2" fontWeight="700">
            {_.get(source, "abbrev")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          padding: theme.spacing(1),
          py: "0px",
          px: "10px",
        }}
      >
        <Typography variant="caption" sx={{ color: grey[700] }}>
          {NtaiUtils.trunc(_.get(source, "name"), 100)}
        </Typography>
      </Box> */}
    </Box>
  );
}
