import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDashboardViews = createAsyncThunk(
  "dashboardViews/getDashboardViews",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/dashboards");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDashboardView = createAsyncThunk(
  "dashboardViews/getDashboardView",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/dashboards/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDashboardView = createAsyncThunk(
  "dashboardViews/createDashboardView",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/dashboards", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDashboardView = createAsyncThunk(
  "dashboardViews/updateDashboardView",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/dashboards/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDashboardView = createAsyncThunk(
  "dashboardViews/deleteDashboardView",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/dashboards/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const dashboardViewsSlice = createSlice({
  name: "dashboardViews",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDashboardViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getDashboardViews" };
    },
    [getDashboardViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboardViews",
        message: action.payload.message,
      };
    },
    [getDashboardView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getDashboardView" };
    },
    [getDashboardView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboardView",
        message: action.payload.message,
      };
    },
    [createDashboardView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createDashboardView" };
      state.activeId = action.payload.uuId;
    },
    [createDashboardView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createDashboardView",
        message: action.payload.message,
      };
    },
    [updateDashboardView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateDashboardView" };
    },
    [updateDashboardView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateDashboardView",
        message: action.payload.message,
      };
    },
    [deleteDashboardView.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteDashboardView" };
    },
    [deleteDashboardView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteDashboardView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = dashboardViewsSlice.actions;

export default dashboardViewsSlice.reducer;
