import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";

const _ = require("lodash");

export default function ThesaurusConceptForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const semTypesDataMap = useSelector(
    (state) => state.core.thesaurusSemTypesSlice.data
  );

  const semTypesData = Object.values(semTypesDataMap);

  const semTypeOptions = NtaiUtils.getSelectOptions(
    semTypesData,
    "semTypeId",
    "name"
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("thesaurusConceptForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    reset({ thesaurusConceptForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
      subheader="Enter general details about concept including concept Id, Name, Description and associated Semantic type"
    >
      <NtaiTextField
        name="thesaurusConceptForm.conceptId"
        label="Concept Id*"
        rules={{ required: "Concept Id is required" }}
        disabled={action === "edit" ? true : false}
      />

      <NtaiTextField
        name="thesaurusConceptForm.name"
        label="Concept Name*"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="thesaurusConceptForm.description"
        label="Description"
        multiline
        minRows={2}
        maxRows={2}
      />

      <NtaiSelectField
        name="thesaurusConceptForm.semTypeId"
        options={semTypeOptions}
        label="Sematic Type*"
        rules={{ required: "Semantic type is required" }}
      />
    </NtaiDialogFormPanel>
  );
}
