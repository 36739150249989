import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useEffect } from "react";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { useDispatch, useSelector } from "react-redux";
import { getDatasources } from "../../../admin/datasource/store/datasourcesSlice";
const _ = require("lodash");

export default function SourceOutStoreForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const { getValues, watch, reset } = useFormContext();
  const dispatch = useDispatch();
  const [datasourcesVisible, setDatasourcesVisible] = useState(false);

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);
  const datasourcesDataMap = useSelector(
    (state) => state.core.datasourcesSlice.data
  );

  const storeFormatOptions =
    codelistCodes &&
    _.has(codelistCodes, CODELIST_CODES.SRC_SOURCE_STORE_FORMAT_CODE)
      ? _.get(codelistCodes, CODELIST_CODES.SRC_SOURCE_STORE_FORMAT_CODE)
      : [];

  const datasourceOptions =
    datasourcesDataMap && !_.isEmpty(datasourcesDataMap)
      ? NtaiUtils.getSelectOptions(
          Object.values(datasourcesDataMap),
          "uuId",
          "name"
        )
      : [];

  const watchStoreFormatCode = watch("sourceOutStoreForm.formatCode");

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOutStoreForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    if (
      watchStoreFormatCode &&
      _.has(watchStoreFormatCode, "value") &&
      watchStoreFormatCode["value"] > 1
    ) {
      setDatasourcesVisible(true);
    } else {
      setDatasourcesVisible(false);
    }
  }, [watchStoreFormatCode]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_STORE_FORMAT_CODE));
    dispatch(getDatasources());
    reset({ sourceOutStoreForm: action === "edit" ? formData : null });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Select target store type and assign data source if applicable"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiSelectField
        name="sourceOutStoreForm.formatCode"
        options={storeFormatOptions}
        label="Target Store*"
        rules={{ required: "Target store is required" }}
      />

      {datasourcesVisible && (
        <NtaiSelectField
          name="sourceOutStoreForm.datasourceUuId"
          options={datasourceOptions}
          label="Select Data source*"
          rules={{ required: "Data source is required" }}
        />
      )}
    </NtaiDialogFormPanel>
  );
}
