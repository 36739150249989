import { Box, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSourceInStoreOcrFields,
  updateSourceInStoreOcrField,
} from "./store/sourceInStoreOcrFieldsSlice";
import { AirlineSeatLegroomExtraRounded } from "@mui/icons-material";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiImageArea from "@ntai/components/img-area/NtaiImageArea";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import SourceInStoreOcrFieldEdit from "./SourceInStoreOcrFieldEdit";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourceInStoreOcrFieldList from "./SourceInStoreOcrFieldList";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

export default function SourceInStoreOcrImageFields({
  sourceInId,
  sourceInStoreOcrPageUuId,
  handleImageAreasCallback,
  handleImageAreaEdit,
  handleImageAreaDelete,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const ocrPageDataMap = useSelector(
    (state) => state.sources.sourceInStoreOcrPagesSlice.data
  );
  const ocrPageData = ocrPageDataMap[sourceInStoreOcrPageUuId];

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreOcrFieldsSlice.data
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const status = useSelector(
    (state) => state.sources.sourceInStoreOcrFieldsSlice.status
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceInId: sourceInId,
    sourceInStoreOcrPageId: sourceInStoreOcrPageUuId,
  };

  //   function handleCallback(f) {}

  //   function handleDelete(d) {}

  //   function handleEdit(e, r) {}

  function getImageAreaFields(fields) {
    const result = [];
    if (fields && _.isArray(fields) && fields.length > 0) {
      fields.forEach((field, i) => {
        result.push({
          uuId: field.uuId,
          x: field.x,
          y: field.y,
          height: field.height,
          width: field.width,
          fieldName: field.fieldName,
          isChanging: false,
          isNew: false,
          unit: "px",
        });
      });
    }

    return result;
  }

  function handleEditDialogOpen() {
    setEditDialogOpen(true);
  }

  function handleEditDialogClose() {
    setCurrentId(null);
    setEditDialogOpen(false);
  }

  function onHandleEdit(areaFieldNumber, areaFieldUuId) {
    if (areaFieldUuId) {
      setCurrentId(areaFieldUuId);
      handleEditDialogOpen();
    }
  }

  useEffect(() => {
    if (
      status &&
      [
        "getSourceInStoreOcrFields",
        "updateSourceInStoreOcrField",
        "deleteSourceInStoreOcrField",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "updateSourceInStoreOcrField" &&
      status.result === "success"
    ) {
      handleEditDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceInStoreOcrFields(pathVariables));
  }, [sourceInStoreOcrPageUuId]);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexBasis: "75%",
          display: "flex",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              width: "100%",
            }}
          >
            {loaded && (
              <Box
                sx={{
                  flexBasis: "100%",
                  height: "100%",
                  display: "flex",
                }}
              >
                {loaded && (
                  <Box
                    sx={{
                      p: theme.spacing(2),
                      height: "100%",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {loaded &&
                      ocrPageData &&
                      _.get(ocrPageData, "base64Image") && (
                        <NtaiImageArea
                          imgSrc={`data:image/png;base64,${ocrPageData.base64Image}`}
                          imgHeight={ocrPageData.imageHeight}
                          imgWidth={ocrPageData.imageWidth}
                          imgName={ocrPageData.imageName}
                          imgAreas={getImageAreaFields(data)}
                          handleCallback={handleImageAreasCallback}
                          handleEdit={onHandleEdit}
                          handleDelete={handleImageAreaDelete}
                        />
                      )}
                    {!_.has(ocrPageData, "base64Image") && (
                      <NtaiCircularProgress vAlign="center" />
                    )}

                    {loaded &&
                      ocrPageData &&
                      sourceInStoreOcrPageUuId &&
                      !_.get(ocrPageData, "base64Image") && (
                        <NtaiEmptyMessage
                          header="Page image not available"
                          subheader="You can add the page image through Add Image option"
                        />
                      )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Scrollbar>
      </Box>

      <Box
        sx={{
          flexBasis: "25%",
          display: "flex",
          flexDirection: "column",
          borderLeft: theme.general.border1,
        }}
      >
        <SourceInStoreOcrFieldList
          handleEdit={onHandleEdit}
          handleDelete={handleImageAreaDelete}
        />
      </Box>

      {editDialogOpen && currentId && (
        <NtaiForm>
          <SourceInStoreOcrFieldEdit
            formData={dataMap[currentId]}
            open={editDialogOpen}
            handleDialogClose={handleEditDialogClose}
            handleFormSubmit={handleImageAreaEdit}
          />
        </NtaiForm>
      )}
    </React.Fragment>
  );
}
