import { faEdit, faSave, faStop } from "@fortawesome/pro-light-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  runSourceInStoreDataset,
  setActiveId,
  updateSourceInStoreDataset,
} from "./store/sourceInStoreDatasetsSlice";
import SourceInStoreDatasetQueryForm from "./SourceInStoreDatasetQueryForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SourceInStoreDatasetForm from "./SourceInStoreDatasetForm";
const _ = require("lodash");

export default function SourceInStoreDatasetEdit(props) {
  const theme = useTheme();
  const [code, setCode] = useState();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceInStoreId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );

  const uuId = props.match.params.id;

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.status
  );

  const formData = dataMap && !_.isEmpty(dataMap) ? dataMap[uuId] : null;

  function handleEditDialogOpen() {
    setEditDialogOpen(true);
  }

  function handleEditDialogClose() {
    setEditDialogOpen(false);
  }

  function handleSave(pFormData) {
    dispatch(
      updateSourceInStoreDataset({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInStoreId: sourceInStoreId,
        uuId: uuId,
        formData: pFormData,
      })
    );
  }

  function handleRun(pFormData) {
    dispatch(
      runSourceInStoreDataset({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInStoreId: sourceInStoreId,
        uuId: uuId,
        formData: pFormData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceInStoreDataset" &&
      status.result === "success"
    ) {
      handleEditDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(uuId));
  }, []);

  return (
    <React.Fragment>
      <NtaiPage
        title={`Datasets / ${formData["name"]}`}
        titleExtras={
          <IconButton size="small" onClick={() => handleEditDialogOpen()}>
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        }
        back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets`}
      >
        <NtaiForm>
          <SourceInStoreDatasetQueryForm
            formData={formData}
            handleSave={handleSave}
            handleRun={handleRun}
          />
        </NtaiForm>
      </NtaiPage>
      <NtaiDialog
        title={`Edit ${formData["name"]}`}
        size="sm"
        open={editDialogOpen}
        handleDialogClose={handleEditDialogClose}
      >
        <NtaiForm>
          <SourceInStoreDatasetForm
            action="edit"
            formData={formData}
            handleSave={handleSave}
            handleCancel={handleEditDialogClose}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
