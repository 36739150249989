import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlertRecordActions = createAsyncThunk(
  "alertRecordActions/getAlertRecordActions",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/actions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertRecordAction = createAsyncThunk(
  "alertRecordActions/getAlertRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/actions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAlertRecordAction = createAsyncThunk(
  "alertRecordActions/createAlertRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/actions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAlertRecordAction = createAsyncThunk(
  "alertRecordActions/updateAlertRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId, formData } = values;
      const response = await server.patch(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/actions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAlertRecordAction = createAsyncThunk(
  "alertRecordActions/deleteAlertRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId } = values;
      const response = await server.delete(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/actions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertRecordActionsSlice = createSlice({
  name: "alertRecordActions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertRecordActions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAlertRecordActions" };
    },
    [getAlertRecordActions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordActions",
        message: action.payload.message,
      };
    },
    [getAlertRecordAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getAlertRecordAction" };
    },
    [getAlertRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordAction",
        message: action.payload.message,
      };
    },
    [createAlertRecordAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createAlertRecordAction" };
    },
    [createAlertRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createAlertRecordAction",
        message: action.payload.message,
      };
    },
    [createAlertRecordAction.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "createAlertRecordAction",
      };
    },
    [updateAlertRecordAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateAlertRecordAction" };
    },
    [updateAlertRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateAlertRecordAction",
        message: action.payload.message,
      };
    },
    [updateAlertRecordAction.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateAlertRecordAction",
      };
    },
    [deleteAlertRecordAction.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteAlertRecordAction" };
    },
    [deleteAlertRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAlertRecordAction",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = alertRecordActionsSlice.actions;

export default alertRecordActionsSlice.reducer;
