import { Badge } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: (props) => (props.right ? props.right : -3),
    top: (props) => (props.top ? props.top : 8),
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    fontSize: "10px",
    fontWeight: 700,
    background: theme.colors.primary,
  },
}));

export default function NtaiMuiBadge(props) {
  return <StyledBadge {...props}>{props.children}</StyledBadge>;
}
