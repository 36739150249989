import React, { useEffect } from "react";
import {
  clearStatus,
  getLatestSource,
  setActiveId,
} from "./store/sourcesSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import history from "@ntai/@history";

export default function SourceManagerWrapper(props) {
  const dispatch = useDispatch();
  const sourceDefId = props.match.params.sourcedefid;
  const status = useSelector((state) => state.sources.sourcesSlice.status);
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getLatestSource"
    ) {
      dispatch(clearStatus());
      if (sourceId) {
        history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/manage`);
      } else {
        history.push(`/sourcedefs/${sourceDefId}/sources/create`);
      }
    }
  }, [sourceId, status]);

  useEffect(() => {
    dispatch(getLatestSource(sourceDefId));
  }, [sourceDefId]);

  return <NtaiCircularProgress />;
}
