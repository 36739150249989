import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordLabelTagList = createAsyncThunk(
  "sourceRecordLabelTag/getSourceRecordLabelTagList",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/tags`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordLabelTag = createAsyncThunk(
  "sourceRecordLabelTag/createSourceRecordLabelTag",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/tags`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordLabelTag = createAsyncThunk(
  "sourceRecordLabelTag/updateSourceRecordLabelTag",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/tags/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordLabelTag = createAsyncThunk(
  "sourceRecordLabelTag/deleteSourceRecordLabelTag",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/tags/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordLabelTagsSlice = createSlice({
  name: "sourceRecordLabelTag",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordLabelTagList.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceRecordLabelTagList",
      };
    },
    [getSourceRecordLabelTagList.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordLabelTagList",
        message: action.payload.message,
      };
    },

    [createSourceRecordLabelTag.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordLabelTag",
      };
    },
    [createSourceRecordLabelTag.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordLabelTag",
        message: action.payload.message,
      };
    },

    [updateSourceRecordLabelTag.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordLabelTag",
      };
    },
    [updateSourceRecordLabelTag.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordLabelTag",
        message: action.payload.message,
      };
    },

    [deleteSourceRecordLabelTag.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordLabelTag",
      };
    },
    [deleteSourceRecordLabelTag.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordLabelTag",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceRecordLabelTagsSlice.actions;

export default sourceRecordLabelTagsSlice.reducer;
