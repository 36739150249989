import React from "react";
import NtaiReactDataTable from "./NtaiReactDataTable";
import { Box } from "@mui/material";

const columns = [
  {
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: "Year",
    selector: (row) => row.year,
    sortable: true,
  },
];

const data = [
  {
    id: 1,
    title: "Beetlejuice",
    year: "1988",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
  {
    id: 3,
    title: "Ghostbusters",
    year: "1984",
  },
  {
    id: 4,
    title: "Ghostbusters",
    year: "1984",
  },
];

export default function TestNtaiDataTable() {
  return (
    <Box sx={{ display: "flex", p: "12px" }}>
      <NtaiReactDataTable columns={columns} data={data} />
    </Box>
  );
}
