import React from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
// import classes from "./NtaiResizableGrid.module.css";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { Resizable } from "react-resizable";
import { Box } from "@mui/material";

const ReactGridLayout = WidthProvider(RGL);

export default class NtaiResizableGrid extends React.PureComponent {
  static defaultProps = {
    // className: "layout",
    className: "flex w-full h-full",
    items: 20,
    rowHeight: 100,
    // draggableHandle: ".header",
    // onLayoutChange: function (layout) {
    //   console.log("Layout changed..", layout);
    //   // this.onLayoutChange(layout);
    // },
    cols: 12,
  };

  constructor(props) {
    super(props);

    console.log("PROPS RG: ", props);
    console.log("layout in props: ", props.layout);

    let layout = props.layout ? props.layout : this.generateLayout();
    console.log("layout after setting : ", layout);
    this.state = { layout: layout };
  }

  generateDOM() {
    return this.props.items.map(
      (item, i) => item
      // <div className="flex w-full h-full">{item}</div>
    );
  }

  generateLayout() {
    console.log("General layout gettting called..");
    const p = this.props;
    return _.map(new Array(this.props.items.length), function (item, i) {
      const y = _.result(p, "y") || Math.ceil(Math.random() * 2) + 1;
      const coords = {
        x: (i * 2) % 12,
        y: Math.floor(i / 6) * y,
        w: 2,
        h: y,
        i: i.toString(),
        minW: 2,
        minH: 2,
      };
      // console.log("ITem coords: ", coords);
      return coords;
    });
  }

  onLayoutChange(layout) {
    // this.props.onLayoutChange(layout);
    console.log("New layout: ", layout);
  }

  render() {
    return (
      <ReactGridLayout
        // cols={12}
        // width={1137}
        // width="100%"
        // autosize={true}
        // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        // cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        layout={this.state.layout}
        draggableHandle=".widget-header-wrapper"
        onLayoutChange={this.props.handleLayoutChange}
        {...this.props}
      >
        {this.generateDOM()}
      </ReactGridLayout>
    );
  }
}
