import { Box, IconButton, useTheme } from "@mui/material";
import React from "react";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { DragSource, DropTarget } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiIcons from "utils/NtaiIcons";

const style = {
  border: "1px dashed gray",
  padding: "0.5em 2rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  borderRadius: "4px",
  cursor: "move",
};

const NtaiDndCard = forwardRef(function NtaiDndCard(
  {
    id,
    text,
    isDragging,
    connectDragSource,
    connectDropTarget,
    handleClick,
    secondaryAction,
    handleSecondaryAction,
  },
  ref
) {
  const elementRef = useRef(null);
  const theme = useTheme();
  connectDragSource(elementRef);
  connectDropTarget(elementRef);
  const opacity = isDragging ? 0 : 1;
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));
  return (
    <Box
      onClick={() => handleClick(id)}
      ref={elementRef}
      sx={{
        ...style,
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing(1),
        opacity,
      }}
    >
      {text}
      {secondaryAction && (
        <IconButton size="small" onClick={() => handleSecondaryAction(id)}>
          <FontAwesomeIcon size="2xs" icon={NtaiIcons[secondaryAction]} />
        </IconButton>
      )}
    </Box>
  );
});
export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(NtaiDndCard)
);
