import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../codelist/store/codelistsSlice";
const _ = require("lodash");

export default function DatasourceElasticForm() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

 
  useEffect(() => {
   
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            flexBasis: "60%",
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <NtaiTextField
            name="datasourceForm.elastic.host"
            label="Elastic Host*"
            placeholder="localhost"
            rules={{ required: "Elastic host is required" }}
          />
        </Box>

        <Box
          sx={{
            flexBasis: "40%",
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <NtaiTextField
            type="number"
            name="datasourceForm.elastic.port"
            label="Elastic Port*"
            placeholder="9200"
            defaultValue="9200"
            rules={{ required: "Elastic port is required" }}
          />
        </Box>
      </Box>

      <NtaiTextField
          name="datasourceForm.elastic.cloudId"
          label="Cloud ID"
          placeholder="Cloud ID"
        />

      <NtaiTextField
        name="datasourceForm.elastic.userName"
        label="Database user*"
        placeholder="databaseuser"
        defaultValue=""
      />
      <NtaiTextField
        type="password"
        name="datasourceForm.elastic.password"
        label="Database password*"
        placeholder="*********"
        defaultValue=""
      />
    <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
      {<NtaiSwitchField name="datasourceForm.elastic.sslFg" label="SSL?" />}
      {<NtaiSwitchField name="datasourceForm.elastic.cloudFg" label="Cloud?" />}
      {<NtaiSwitchField name="datasourceForm.elastic.nodesWanOnlyFg" label="Nodes WAN Only?" />}
      </Box>
    </Box>
  );
}
