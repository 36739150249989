import { useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React from "react";
import { useFormContext } from "react-hook-form";

export default function ReportSeriesFilter({ handleCancel }) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  function onHandleSubmit() {}
  return (
    <NtaiFormPanel
      header="Apply Filters"
      subheader="You can apply filters to refine current series list"
      width="250px"
      handleSave={onHandleSubmit}
      handleCancel={handleCancel}
      saveLabel="Apply"
    >
      <NtaiTextField name="search1" label="Tags" placeholder="Tags" />
      <NtaiTextField name="search1" label="Tags" placeholder="Tags" />
      <NtaiTextField name="search1" label="Tags" placeholder="Tags" />
    </NtaiFormPanel>
  );
}
