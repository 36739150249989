import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { faClose, faShapes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOversightEntityOverviewView,
  setActiveId,
} from "./store/oversightEntityOverviewViewsSlice";
import { OversightEntityOverviewContext } from "./OversightEntityOverviewWrapper";
const _ = require("lodash");

export default function OversightEntityOverviewViewMenu({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { setViewId } = React.useContext(OversightEntityOverviewContext);

  const dataMap = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewsSlice.data
  );

  const data = Object.values(dataMap);

  function selectView(objectSourceViewId) {
    dispatch(setActiveId(objectSourceViewId));
    setViewId(objectSourceViewId);
    handleClose();
  }

  return (
    <Box
      sx={{
        height: "240px",
        width: "200px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faChartSimple} />
        <Typography variant="body2" fontWeight="700">
          Select View
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <MenuList dense sx={{ p: "2px", width: "100%" }}>
              {data &&
                Array.isArray(data) &&
                data.length > 0 &&
                data.map((item) => {
                  return (
                    <MenuItem
                      key={item.uuId}
                      value={item.uuId}
                      divider={true}
                      onClick={() => selectView(item.uuId)}
                    >
                      <ListItemText>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "500" }}
                        >
                          {item.name}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Box>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
