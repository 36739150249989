import { faInputText } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import React, { useEffect, useState } from "react";
import { SearchSourceContext } from "../../../SearchSource";
import { dispatch } from "d3";
import { getSourceFields } from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { SearchSourceWidgetDrillContext } from "../../SearchSourceView";
import { getSourceDerivedFields } from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
const _ = require("lodash");

export default function SearchSourceWidgetDrillFields() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");

  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { drillWidgetFieldIds, setDrillWidgetFieldIds } = React.useContext(
    SearchSourceWidgetDrillContext
  );

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const sourceDefId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.uuId"
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourcesSliceStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceDerivedFieldsSliceStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFields =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? _.filter(Object.values(sourceFieldsDataMap), function (o) {
          if (searchText && searchText.length > 0) {
            if (
              o["name"].toLowerCase().includes(searchText.toLowerCase()) ||
              o["label"].toLowerCase().includes(searchText.toLowerCase())
            )
              return true;
            else return false;
          } else {
            return true;
          }
        })
      : [];

  const sourceDerivedFields =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? _.filter(Object.values(sourceDerivedFieldsDataMap), function (o) {
          if (searchText && searchText.length > 0) {
            if (
              o["name"].toLowerCase().includes(searchText.toLowerCase()) ||
              o["label"].toLowerCase().includes(searchText.toLowerCase())
            )
              return true;
            else return false;
          } else {
            return true;
          }
        })
      : [];

  function handleAddRemoveSourceField(fieldTypeUuId) {
    let selectedDrillFieldsCopy = _.cloneDeep(drillWidgetFieldIds);
    const fieldParts = fieldTypeUuId.split(",");
    const fieldType = fieldParts[0];
    const fieldUuId = fieldParts[1];

    if (selectedDrillFieldsCopy.includes(fieldTypeUuId)) {
      selectedDrillFieldsCopy = selectedDrillFieldsCopy.filter(
        (f) => f !== fieldTypeUuId
      );
    } else selectedDrillFieldsCopy.push(fieldTypeUuId);

    setDrillWidgetFieldIds([...selectedDrillFieldsCopy]);
  }

  function handleSearch(text) {
    setSearchText(text);
  }

  useEffect(() => {
    dispatch(
      getSourceFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );

    dispatch(
      getSourceDerivedFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [sourceId]);

  return (
    <Box
      sx={{
        flexBasis: "50%",
        display: "flex",
        flexDirection: "column",
        borderBottom: theme.general.border1,
      }}
    >
      <Box sx={{ display: "flex", height: "28px", backgroundColor: grey[100] }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
            p: theme.spacing(1),
          }}
        >
          <FontAwesomeIcon icon={faInputText} />

          <Typography variant="subtitle2" color="inherit" fontWeight="700">
            {`Select Fields (${drillWidgetFieldIds.length})`}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: theme.spacing(1),
          py: theme.spacing(0.5),
          height: "42px",
        }}
      >
        <NtaiSimpleSearchBar
          placeholder="Search Fields..."
          fontSize="11px"
          handleChange={handleSearch}
        />
      </Box>

      <Box
        sx={{ display: "flex", height: "100%", border: theme.general.border1 }}
      >
        {_.isArray(sourceFields) && sourceFields.length > 0 && (
          <Scrollbar>
            <List sx={{ p: 0 }}>
              {_.orderBy(sourceFields, ["label"], ["asc"]).map(
                (field, index) => (
                  <React.Fragment key={`source-field-${index}`}>
                    <ListItemButton
                      sx={{
                        px: theme.spacing(1),
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        borderRadius: 0,
                        cursor: "default",
                      }}
                      selected={
                        drillWidgetFieldIds.includes(1 + "," + field["uuId"])
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleAddRemoveSourceField(1 + "," + field["uuId"])
                      }
                    >
                      <NtaiBadge
                        title={
                          _.get(field, "esDataTypeDecode") &&
                          _.get(field, "esDataTypeDecode")
                            .substring(0, 1)
                            .toLowerCase()
                        }
                        fg="grey"
                        bg={grey[100]}
                      />

                      <ListItemText primary={field["label"]} />
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                )
              )}

              {_.orderBy(sourceDerivedFields, ["label"], ["asc"]).map(
                (field, index) => (
                  <React.Fragment key={`source-derived-field-${index}`}>
                    <ListItemButton
                      sx={{
                        px: theme.spacing(1),
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        borderRadius: 0,
                        cursor: "default",
                      }}
                      selected={
                        drillWidgetFieldIds.includes(2 + "," + field["uuId"])
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleAddRemoveSourceField(2 + "," + field["uuId"])
                      }
                    >
                      <NtaiBadge
                        title={
                          _.get(field, "fieldTypeDecode") &&
                          _.get(field, "fieldTypeDecode")
                            .substring(0, 1)
                            .toLowerCase()
                        }
                        fg="grey"
                        bg={grey[100]}
                      />

                      <ListItemText primary={field["label"]} />
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                )
              )}
            </List>
          </Scrollbar>
        )}
      </Box>
    </Box>
  );
}
