import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { getAlerts } from "./store/alertsSlice";
import { Box, Button, useTheme } from "@mui/material";
import history from "@ntai/@history";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { AlertListContainerContext } from "./AlertContainer";
import { faArrowRotateRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const _ = require("lodash");

function getTopic(cols) {
  if (cols[0].colValue) return cols[0].colValue;
  else return cols[1].colValue;
}

const headCells = [
  {
    id: "gUuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "alertName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "20%",
  },

  {
    id: "entityName",
    numeric: false,
    disablePadding: false,
    label: "Entity",
    width: "15%",
  },

  {
    id: ["dataTopicName", "objectTopicName"],
    numeric: false,
    disablePadding: false,
    label: "Topic",
    transformFunc: getTopic,
    width: "15%",
  },

  {
    id: "activityName",
    numeric: false,
    disablePadding: false,
    label: "Activity",
    width: "15%",
  },

  {
    id: "numOfRecords",
    numeric: false,
    disablePadding: false,
    label: "# Records",
    width: "10%",
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Last Refreshed",
    transformFunc: NtaiUtils.formatDateCol,
    width: "13%",
  },
];

export default function AlertList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { navNode } = React.useContext(AlertListContainerContext);

  const dataMap = useSelector((state) => state.monitor.alertsSlice.data);
  const status = useSelector((state) => state.monitor.alertsSlice.status);
  const data = Object.values(dataMap);

  function handleEdit(id) {
    if (id) {
      history.push(`/monitor/alerts/${id}`);
    }
  }

  function handleToolbarAction(action, selected) {}

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getAlerts"
    ) {
      setLoaded(true);
      setRefresh(false);
    }
  }, [status]);

  useEffect(() => {
    let tmpObjectUuId = null;
    let tmpObjectTopicFg = null;
    if (navNode["objectId"] && navNode["objectId"].length > 0)
      tmpObjectUuId = navNode["objectId"];
    if (navNode["objectTopicFg"] && navNode["objectTopicFg"].length > 0)
      tmpObjectTopicFg = navNode["objectTopicFg"];

    const params = {};
    if (tmpObjectUuId && tmpObjectUuId.length > 0)
      params["objectUuId"] = tmpObjectUuId;
    if (tmpObjectTopicFg && tmpObjectTopicFg.length > 0)
      params["objectTopicFg"] = tmpObjectTopicFg;

    dispatch(
      getAlerts({
        masterId: navNode["masterId"],
        topicId: navNode["topicId"],
        params: params,
      })
    );
  }, [navNode, refresh]);

  return (
    <NtaiPage
      title="Inbox"
      padding={2}
      headerActions={
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <Button
            sx={{ fontSize: "11px", fontWeight: 300 }}
            size="small"
            color="inherit"
            startIcon={
              <FontAwesomeIcon
                spin={refresh ? true : false}
                icon={faArrowRotateRight}
              />
            }
            onClick={(e) => setRefresh(true)}
            disabled={refresh ? true : false}
          >
            REFRESH
          </Button>
          {/* <Button
            sx={{ fontSize: "10px", fontWeight: 300 }}
            size="small"
            color="inherit"
            startIcon={<FontAwesomeIcon icon={faShareAlt} />}
          >
            SHARE
          </Button>
          <Button
            sx={{ fontSize: "10px", fontWeight: 300 }}
            size="small"
            color="inherit"
            startIcon={<FontAwesomeIcon icon={faDownload} />}
          >
            EXPORT
          </Button>
          <Button
            sx={{ fontSize: "10px", fontWeight: 300 }}
            size="small"
            color="inherit"
            startIcon={<FontAwesomeIcon icon={faClose} />}
          >
            TERMINATE
          </Button> */}
        </Box>
      }
    >
      {loaded && data && Array.isArray(data) && data.length > 0 && (
        <NtaiSimpleTable
          rows={data}
          headCells={headCells}
          keyColumn="gUuId"
          isDensed={true}
          dialog={true}
          handleDialogAddEdit={handleEdit}
          handleToolbarAction={handleToolbarAction}
          toolbarActions={[{ value: "archive", label: "Archive" }]}
          title={`Pending Reviews (${data.length})`}
        />
      )}
      {loaded && data && Array.isArray(data) && data.length === 0 && (
        <NtaiEmptyMessage
          header="No records found"
          subheader="Alerts created using alert definition or routed using search distribution appear here"
          vAlign="center"
        />
      )}

      {!loaded && <NtaiCircularProgress size={36} />}
    </NtaiPage>
  );
}
