import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  getObjectRels,
  createObjectRel,
  updateObjectRel,
  deleteObjectRel,
  clearStatus,
} from "./store/objectRelsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useTheme } from "@mui/material";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import ObjectRelForm from "./ObjectRelForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "relObjectName",
    numeric: false,
    disablePadding: false,
    label: "Relation Object Name",
  },
  {
    id: "relTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Relation",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function ObjectRelList() {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const dataMap = useSelector((state) => state.core.objectRelsSlice.data);
  const status = useSelector((state) => state.core.objectRelsSlice.status);
  const objectUuId = useSelector((state) => state.core.objectsSlice.activeId);
  const data = Object.values(dataMap);
  const theme = useTheme();

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete(ids) {
    setSelectedIds(ids);
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(
          deleteObjectRel({
            objectId: objectUuId,
            uuId: id,
          })
        );
      });
    }

    setSelectedIds([]);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateObjectRel({
            objectId: objectUuId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createObjectRel({
            objectId: objectUuId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    if (toolbarAction === "remove") confirmDelete(selectedItems);
  }

  useEffect(() => {
    dispatch(getObjectRels(objectUuId));
    dispatch(getCodes(CODELIST_CODES.COR_META_OBJECT_REL_TYPE_CODE));
    //dispatch(getRoles());
  }, []);

  useEffect(() => {
    if (
      status.result === "success" &&
      (status.method === "createObjectRel" ||
        status.method === "updateObjectRel")
    ) {
      handleDialogClose();
      clearStatus();
    }

    if (
      status.result === "success" &&
      selectedIds.length === 0 &&
      status.method === "deleteObjectRel"
    ) {
      setOpenConfirmDeleteDialog(false);
      clearStatus();
    }
  }, [status]);

  return (
    <NtaiPanel
      width="100%"
      header="Object Relations"
      subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
    >
      <NtaiCrudTable
        rows={data}
        headCells={headCells}
        toolbarActions={toolbarActions}
        keyColumn="uuId"
        dialog={true}
        handleDialogAddEdit={handleDialogAddEdit}
        handleDelete={confirmDelete}
        handleToolbarAction={handleToolbarAction}
        title="Relations"
      />

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Relation"
        size="sm"
      >
        <NtaiForm>
          <ObjectRelForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </NtaiPanel>
  );
}
