import React, { useEffect, useState } from "react";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createThesaurusConcept,
  deleteThesaurusConcept,
  getThesaurusConcepts,
  setActiveId,
  updateThesaurusConcept,
} from "./store/thesaurusConceptsSlice";
import ThesaurusConceptForm from "./ThesaurusConceptForm";
import { Box, Button, Drawer, useTheme } from "@mui/material";
import ThesaurusConceptManagement from "./ThesaurusConceptManagement";
import { getThesaurusSemTypes } from "../semtype/store/thesaurusSemTypesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { useSnackbar } from "notistack";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "20%",
    fontWeight: 700,
  },
  {
    id: "conceptId",
    numeric: false,
    disablePadding: true,
    label: "Concept Id",
    width: "15%",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "20%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
    width: "15%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function ThesaurusConceptList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const thesaurusId = useSelector(
    (state) => state.core.thesaurusesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.thesaurusConceptsSlice.data
  );

  const activeId = useSelector(
    (state) => state.core.thesaurusConceptsSlice.activeId
  );

  const status = useSelector(
    (state) => state.core.thesaurusConceptsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }

    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        const semTypeId = dataMap[id]["semTypeId"];
        dispatch(
          deleteThesaurusConcept({
            thesaurusId: thesaurusId,
            conceptId: id,
            params: {
              semTypeId: semTypeId,
            },
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateThesaurusConcept({
            thesaurusId: thesaurusId,
            conceptId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createThesaurusConcept({
            thesaurusId: thesaurusId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "remove") confirmDelete();
  }

  function manageConcept(actionName, id) {
    setOpen(!open);
    setCurrentId(id);
    dispatch(setActiveId(id));
  }

  function handleDrawerClose() {
    setOpen(false);
    setCurrentId(null);
    dispatch(setActiveId(null));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getThesaurusConcepts" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createThesaurusConcept" ||
        status.method === "updateThesaurusConcept")
    ) {

      handleDialogClose();
      dispatch(clearStatus());
      setCurrentId(null);
      dispatch(setActiveId(null));
  

      enqueueSnackbar(
        `Concept "${
          dataMap[action === "add" ? activeId : currentId]["name"]
        }" saved successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteThesaurusConcept"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Concept(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getThesaurusConcepts({
        thesaurusId: thesaurusId,
        params: { thesaurusId: thesaurusId },
      })
    );
    dispatch(getThesaurusSemTypes(thesaurusId));
  }, [thesaurusId]);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
        <Box sx={{ flexBasis: "100%" }}>
          <NtaiPanel
            width="100%"
            header="Concepts"
            subheader="Thesaurus is organized by concept (For example, Medicinal product or Disease name),  which is associated with specific semantic type. Each concept can further have relationships with other concepts and can contain one ore more synonyms."
          >
            {loaded && (
              <NtaiCrudTable
                rows={data}
                headCells={headCells}
                selectedIds={selectedIds}
                toolbarActions={toolbarActions}
                keyColumn="conceptId"
                dialog={true}
                handleDialogAddEdit={handleDialogAddEdit}
                handleToolbarAction={handleToolbarAction}
                title="Concept"
                rowActions={[{ value: "manage", label: "MANAGE" }]}
                handleRowAction={manageConcept}
              />
            )}
            {!loaded && <NtaiCircularProgress />}
          </NtaiPanel>
        </Box>
      </Box>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Concept"
        size="sm"
      >
        <NtaiForm>
          <ThesaurusConceptForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <Drawer
        PaperProps={{
          style: {
            top: "48px",
          },
        }}
        open={open}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
      >
        {currentId && currentId.length > 0 && (
          <ThesaurusConceptManagement
            conceptId={currentId}
            handleDrawerClose={handleDrawerClose}
          />
        )}
      </Drawer>
    </>
  );
}
