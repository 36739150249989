import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { getUsers } from "app/main/pages/core/admin/user/store/usersSlice";

const _ = require("lodash");

export default function ReportFolderForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const usersDataMap = useSelector((state) => state.core.usersSlice.data);
  const usersData =
    usersDataMap && !_.isEmpty(usersDataMap) ? Object.values(usersDataMap) : [];
  const userOptions = NtaiUtils.getSelectOptions(usersData, "uuId", "userName");

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("reportFolderForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ reportFolderForm: action === "edit" ? formData : {} });
    dispatch(getUsers());
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="reportFolderForm.name"
          label="Name*"
          placeholder="Name"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="reportFolderForm.description"
          label="Description"
          placeholder="Description"
          multiline
          minRows={2}
          maxRows={2}
        />
        <NtaiSelectField
          name="reportFolderForm.ownerUuId"
          options={userOptions}
          label="Owner*"
          rules={{ required: "Owner is required" }}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
