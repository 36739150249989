import { Box, Divider, Typography } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import NtaiObjectFormSelectField from "./NtaiObjectFormSelectField";
import NtaiObjectFormRadioGroupField from "./NtaiObjectFormRadioGroupField";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiObjectFormReadOnlyField from "./NtaiObjectFormReadOnlyField";
import NtaiObjectFormMultiSelectField from "./NtaiObjectFormMultiSelectField";
import { OBJECT_FORM_UI_FIELD_TYPE_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiObjectFormCheckboxGroupField from "./NtaiObjectFormCheckboxGroupField";
import { grey } from "@mui/material/colors";
import NtaiObjectFormSwitchField from "./NtaiObjectFormSwitchField";

const _ = require("lodash");

class ObjectFormUtil {
  static generateForm(formName, formObj, editMode, theme) {
    let result = [];
    const tabs =
      _.has(formObj, "tabs") &&
      _.isArray(formObj["tabs"]) &&
      formObj["tabs"].length > 0
        ? formObj["tabs"]
        : null;

    if (tabs) {
      tabs.forEach((o, i) => {
        result.push(
          this.generateTab(o, formName, editMode, formObj["hideTabsFg"], theme)
        );
      });

      return (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            pb: theme.spacing(1),
          }}
        >
          {this.generateFormContent(result, formObj)}

          {/* {formObj["hideFormLabelsFg"] && formObj["hideFormLabelsFg"] === 1 && (
            <React.Fragment>
              {formObj["hideTabsFg"] === 1 && <Box>{result}</Box>}
              {(!formObj["hideTabsFg"] || formObj["hideTabsFg"] === 0) && (
                <NtaiTabs>{result}</NtaiTabs>
              )}
            </React.Fragment>
          )}

          {(!formObj["hideFormLabelsFg"] ||
            formObj["hideFormLabelsFg"] === 0) &&
            this.generateFormContentWithLabels(result, formObj)} */}
        </Box>
      );
    }
  }

  static generateFormContent(result, formObj) {
    if (formObj["hideFormLabelsFg"] && formObj["hideFormLabelsFg"] === 1) {
      if (formObj["hideTabsFg"] === 1) {
        return (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            {result}
          </Box>
        );
      } else {
        return <NtaiTabs>{result}</NtaiTabs>;
      }
    } else if (
      !formObj["hideFormLabelsFg"] ||
      formObj["hideFormLabelsFg"] === 0
    ) {
      return this.generateFormContentWithLabels(result, formObj);
    }
  }

  static generateFormContentWithLabels(result, formObj) {
    return (
      <NtaiPanel
        padding="0px"
        width="100%"
        header={formObj["label"]}
        subheader={formObj["description"]}
      >
        {formObj["hideTabsFg"] === 1 && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            {result}
          </Box>
        )}
        {(!formObj["hideTabsFg"] || formObj["hideTabsFg"] === 0) && (
          <NtaiTabs>{result}</NtaiTabs>
        )}
      </NtaiPanel>
    );
  }

  static generateTab(tabObj, formName, editMode, hideTabsFg, theme) {
    let result = [];
    let panels =
      _.has(tabObj, "panels") &&
      _.isArray(tabObj["panels"]) &&
      tabObj["panels"].length > 0
        ? tabObj["panels"]
        : null;

    if (panels) {
      panels.forEach((o, i) => {
        result.push(this.generatePanel(i, o, formName, editMode, theme));
      });
    }

    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          px: hideTabsFg && hideTabsFg === 1 ? "0px" : theme.spacing(2),
        }}
        label={tabObj["label"]}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            pt: hideTabsFg && hideTabsFg === 1 ? "0px" : theme.spacing(1.5),
          }}
        >
          {result}
        </Box>
      </Box>
    );
  }

  static generatePanel(index, panelObj, formName, editMode, theme) {
    let result = [];
    let fields =
      _.has(panelObj, "fields") &&
      _.isArray(panelObj["fields"]) &&
      panelObj["fields"].length > 0
        ? panelObj["fields"]
        : null;

    if (fields) {
      fields.forEach((o, i) => {
        result.push(
          <Box key={`form-field-${i}`}>
            {this.generateField(i, o["field"], formName, editMode, theme)}
          </Box>
        );
      });
    }

    return (
      <Box
        key={`${formName}-panel-${index}`}
        sx={{
          py: theme.spacing(0.5),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1.5),
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5" fontWeight="500">
              {panelObj["label"]}
            </Typography>
            <Typography variant="subtitle2" fontWeight="300">
              {panelObj["helpText"]}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: theme.spacing(0.5),
              gap: theme.spacing(1.5),
            }}
          >
            {result}
          </Box>
        </Box>
      </Box>
    );
  }

  static generateField(index, fieldObj, formName, editMode, theme) {
    if (editMode) {
      const labelMandatoryAsterisk =
        _.get(fieldObj, "fieldMandatoryFg") === 1 ? "*" : "";
      if (
        fieldObj["fieldUiCode"] === OBJECT_FORM_UI_FIELD_TYPE_CODES.TEXT_FIELD
      ) {
        return (
          <NtaiTextField
            sx={{
              width:
                fieldObj["fieldUiWidth"] && fieldObj["fieldUiWidth"] > 0
                  ? "" + fieldObj["fieldUiWidth"].toString() + "%"
                  : "100%",
            }}
            name={`${formName}.${fieldObj["fieldName"]}`}
            label={fieldObj["fieldLabel"] + labelMandatoryAsterisk}
            placeholder={fieldObj["fieldLabel"]}
            rules={
              _.get(fieldObj, "fieldMandatoryFg") === 1
                ? { required: `${fieldObj["fieldLabel"]} is required` }
                : {}
            }
          />
        );
      } else if (
        fieldObj["fieldUiCode"] === OBJECT_FORM_UI_FIELD_TYPE_CODES.TEXT_AREA
      ) {
        return (
          <NtaiTextField
            sx={{
              width:
                fieldObj["fieldUiWidth"] && fieldObj["fieldUiWidth"] > 0
                  ? "" + fieldObj["fieldUiWidth"].toString() + "%"
                  : "100%",
            }}
            name={`${formName}.${fieldObj["fieldName"]}`}
            label={fieldObj["fieldLabel"] + labelMandatoryAsterisk}
            multiline
            minRows={3}
            maxRows={3}
            rules={
              _.get(fieldObj, "fieldMandatoryFg") === 1
                ? { required: `${fieldObj["fieldLabel"]} is required` }
                : {}
            }
          />
        );
      } else if (
        fieldObj["fieldUiCode"] === OBJECT_FORM_UI_FIELD_TYPE_CODES.SWITCH
      ) {
        return (
          <NtaiObjectFormSwitchField
            index={index}
            formName={formName}
            fieldObj={fieldObj}
            mandatory={labelMandatoryAsterisk}
          />
        );
      } else if (
        fieldObj["fieldUiCode"] === OBJECT_FORM_UI_FIELD_TYPE_CODES.DROP_DOWN &&
        fieldObj["fieldCodelistCode"]
      ) {
        return (
          <NtaiObjectFormSelectField
            index={index}
            formName={formName}
            fieldObj={fieldObj}
            mandatory={labelMandatoryAsterisk}
          />
        );
      } else if (
        fieldObj["fieldUiCode"] ===
          OBJECT_FORM_UI_FIELD_TYPE_CODES.RADIO_GROUP &&
        fieldObj["fieldCodelistCode"]
      ) {
        return (
          <NtaiObjectFormRadioGroupField
            index={index}
            formName={formName}
            fieldObj={fieldObj}
            mandatory={labelMandatoryAsterisk}
          />
        );
      } else if (
        fieldObj["fieldUiCode"] ===
          OBJECT_FORM_UI_FIELD_TYPE_CODES.MULTI_SELECT &&
        fieldObj["fieldCodelistCode"]
      ) {
        return (
          <NtaiObjectFormMultiSelectField
            index={index}
            formName={formName}
            fieldObj={fieldObj}
          />
        );
      } else if (
        fieldObj["fieldUiCode"] ===
          OBJECT_FORM_UI_FIELD_TYPE_CODES.CHECKBOX_GROUP &&
        fieldObj["fieldCodelistCode"]
      ) {
        return (
          <NtaiObjectFormCheckboxGroupField
            index={index}
            formName={formName}
            fieldObj={fieldObj}
          />
        );
      } else if (
        fieldObj["fieldUiCode"] === OBJECT_FORM_UI_FIELD_TYPE_CODES.DATE
      ) {
        return (
          <NtaiDateField
            sx={{
              width:
                fieldObj["fieldUiWidth"] && fieldObj["fieldUiWidth"] > 0
                  ? "" + fieldObj["fieldUiWidth"].toString() + "%"
                  : "100%",
            }}
            name={`${formName}.${fieldObj["fieldName"]}`}
            label={fieldObj["fieldLabel"] + labelMandatoryAsterisk}
            rules={
              _.get(fieldObj, "fieldMandatoryFg") === 1
                ? { required: `${fieldObj["fieldLabel"]} is required` }
                : {}
            }
          />
        );
      }
    } else {
      return (
        <NtaiObjectFormReadOnlyField formName={formName} fieldObj={fieldObj} />
      );
    }
  }

  static generateFieldNameNumObj(obj) {
    let fieldNumNameObj = {};
    const fields = _.get(obj, "objectFields");
    fields.forEach((field, index) => {
      fieldNumNameObj[_.get(field, "fieldName")] = _.get(field, "fieldNum");
    });

    return fieldNumNameObj;
  }

  static generateFormSubmissionData(sanitizedFormData, obj) {
    console.log("sanitizedFormData: ", sanitizedFormData);
    let objectData = {};
    let objectDataFields = [];
    const fieldNameNumObj = this.generateFieldNameNumObj(obj);
    Object.keys(sanitizedFormData).forEach((fieldName, index) => {
      objectDataFields.push({
        fieldName: fieldName,
        fieldValue: sanitizedFormData[fieldName],
        fieldNum: _.get(fieldNameNumObj, fieldName),
      });
    });
    objectData["fields"] = objectDataFields;
    return objectData;
  }

  static populateFormData(formData) {
    let finalFormData = {};
    const fields = _.get(formData, "fields");
    fields.forEach((field, index) => {
      if (
        ![
          OBJECT_FORM_UI_FIELD_TYPE_CODES.MULTI_SELECT,
          OBJECT_FORM_UI_FIELD_TYPE_CODES.RADIO_GROUP,
        ].includes(field["fieldUiCode"])
      ) {
        finalFormData[_.get(field, "fieldName")] = _.get(field, "fieldValue");
      } else {
        const arr = field["fieldValue"].split(",").map((v) => {
          return v * 1;
        });

        finalFormData[_.get(field, "fieldName")] = arr;
      }
    });
    return finalFormData;
  }
}

export default ObjectFormUtil;
