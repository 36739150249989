import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createCodelistCode,
  updateCodelistCode,
} from "./store/codelistCodesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

export default function CodelistCodeForm(props) {
  const { action, handleDialogClose, handleSave, formData } = props;

  const codelistDataMap = useSelector(
    (state) => state.core.codelistsSlice.data
  );

  const codelistActiveId = useSelector(
    (state) => state.core.codelistsSlice.activeId
  );

  const codelistData = codelistDataMap[codelistActiveId];

  const status = useSelector((state) => state.core.codelistCodesSlice.status);
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();
  const theme = useTheme();

  function handleCancel() {
    handleDialogClose();
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("codelistCodeForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ codelistCodeForm: action === "edit" ? formData : {} });
  }, []);

  useEffect(() => {
    if (
      status.result === "success" &&
      (status.method === "createCodelistCode" ||
        status.method === "updateCodelistCode")
    ) {
      dispatch(clearStatus());
      handleDialogClose();
    }
  }, [status]);

  return (
    <NtaiDialogFormPanel
      actions
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          disabled={codelistData["systemFg"] === 1 ? true : false}
          name="codelistCodeForm.code"
          label="Code"
        />
        <NtaiTextField name="codelistCodeForm.name" label="Name" />
        <NtaiTextField
          name="codelistCodeForm.abbrev"
          label="Abbrev"
          disabled={codelistData["systemFg"] === 1 ? true : false}
        />
        <NtaiTextField
          type="number"
          name="codelistCodeForm.codeOrder"
          label="Order"
          disabled={codelistData["systemFg"] === 1 ? true : false}
        />
        {codelistData["systemFg"] !== 1 && (
          <NtaiSwitchField name="codelistCodeForm.disableFg" label="Disable" />
        )}
      </Box>
    </NtaiDialogFormPanel>
  );
}
