import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiDnd3 from "./NtaiDnd3";
import {
  faArchive,
  faDeleteLeft,
  faEdit,
} from "@fortawesome/pro-light-svg-icons";

export default function NtaiTestDnd3() {
  const theme = useTheme();

  const items = [
    {
      uuId: "1",
      body: "Source Field",
    },
    {
      uuId: "2",
      body: "Phase",
    },
    {
      uuId: "3",
      body: "Status",
    },
    {
      uuId: "4",
      body: "Reporter country",
    },
  ];

  function handleReorder(dat) {}

  function handleSecondaryAction(action, id) {}

  return (
    <Box sx={{ display: "flex", p: theme.spacing(2) }}>
      <NtaiDnd3
        id="uuId"
        label="body"
        items={items}
        direction="horizontal"
        handleReorder={handleReorder}
        secondaryActions={[
          { value: "edit", icon: faEdit },
          { value: "delete", icon: faArchive },
        ]}
        handleSecondaryAction={handleSecondaryAction}
      />
    </Box>
  );
}
