import { Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 7px",
    fontSize: 11,
    fontWeight: 500,
    height: 20,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: (props) => (props.textAlign ? props.textAlign : "start"),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

function NtaiBadge(props) {
  const { title, variant, fg, bg, textAlign } = props;
  const theme = useTheme();
  const classes = useStyles(props);
  const { className, badge } = props;

  return (
    <div
      className={clsx(classes.root, className, "item-badge")}
      style={{
        backgroundColor: bg ? bg : theme.colors.secondary,
        color: fg ? fg : theme.colors.secondary.contrastText,
      }}
    >
      <Typography variant={variant ? variant : null}>{title}</Typography>
    </div>
  );
}

NtaiBadge.propTypes = {
  badge: PropTypes.shape({
    title: PropTypes.node,
    bg: PropTypes.string,
    fg: PropTypes.string,
  }),
};
NtaiBadge.defaultProps = {};

export default memo(NtaiBadge);
