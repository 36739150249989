export const tooltipConfig = {
  // backgroundColor: "rgba(34, 34, 34, 0.8)",
  trigger: "item",
  borderWidth: 1,
  borderColor: "#ccc",
  padding: 8,
  textStyle: {
    // color: "#fff",
    fontSize: 12,
  },
  // axisPointer: { type: "cross", crossStyle: { type: "solid" } },
};

export const yAxisLabelConfig = {
  overflow: "truncate",
  width: 100,
  ellipsis: "...",
  show: true,
  interval: 0,
  fontSize: 12,
};

export const xAxisLabelConfig = {
  overflow: "truncate",
  width: 100,
  ellipsis: "...",
  show: true,
  interval: 0,
  rotate: 90,
  fontSize: 12,
  color: "#333",
};

export const xAxisLabelNoRotationConfig = {
  overflow: "truncate",
  width: 100,
  ellipsis: "...",
  show: true,
  interval: 0,
  fontSize: 12,
  color: "#333",
};

export const xAxisLineConfig = {
  lineStyle: {
    color: "#ccc",
  },
};

export const legendConfig = {
  type: "scroll",
  icon: "circle",
  orient: "horizontal",
  align: "auto",
  top: "top",
  left: "center",
  textStyle: {
    fontSize: 12,
  },
};
