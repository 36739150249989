import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Box, Checkbox, Link, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import NtaiMRHookTable from "@ntai/components/tables/material-react/NtaiMRHookTable";
import NtaiTable from "@ntai/components/tables/main/NtaiTable";
const _ = require("lodash");

// const rows = [
//   { id: 1, col1: "Hello", col2: "World" },
//   { id: 2, col1: "DataGridPro", col2: "is Awesome" },
//   { id: 3, col1: "MUI", col2: "is Amazing" },
// ];

// const columns = [
//   { field: "col1", headerName: "Column 1", width: 150 },
//   { field: "col2", headerName: "Column 2", width: 150 },
// ];

function handleFilter(t1, t2) {
  console.log("T1 and t2: ", t1, t2);
}

export default function NtaiMaterialReactGridWidget({
  widgetId,
  data,
  metadata,
}) {
  const theme = useTheme();

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;
  const fieldArray = _.orderBy(
    Object.values(_.get(metadata, "fields")),
    ["fieldOrder"],
    ["asc"]
  );

  function generateColumnDefs(fieldArray) {
    const colDefs = [];

    fieldArray.forEach((widgetField, i) => {
      colDefs.push({
        accessorKey: widgetField.field.path,
        header: widgetField.field.label,
        primary: i === 0 ? true : false,
        Cell: ({ cell, row }) =>
          i === 0 ? (
            <Link
              sx={{
                fontWeight: "500",
                color: blue[700],
                textDecoration: "underline",
                "&:hover": {
                  color: blue[700],
                  cursor: "pointer",
                },
              }}
              underline="hover"
              // onClick={(e) => handleDisplayRecord(e, row.original["_id"])}
            >
              {cell.getValue()}
            </Link>
          ) : (
            <React.Fragment>{cell.getValue()} </React.Fragment>
          ),
      });
    });

    return colDefs;
  }

  const columns = generateColumnDefs(fieldArray);

  //   function onHandleLinkClick(
  //     linkedSourceUuId,
  //     linkUuId,
  //     linkValue,
  //     sourceRecordId
  //   ) {
  //     handleLinkClick(linkedSourceUuId, linkUuId, linkValue, sourceRecordId);
  //   }

  return <NtaiTable data={data} columns={columns} />;
}
