import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import NtaiIcons from "utils/NtaiIcons";

export default function NtaiEmptyMessage(props) {
  const theme = useTheme();
  const {
    icon,
    header,
    headerVariant,
    headerFontWeight,
    subheader,
    subheaderVariant,
    vAlign,
  } = props;
  return (
    <Box
      sx={{
        p: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: vAlign ? vAlign : "start",
      }}
    >
      {icon && <FontAwesomeIcon icon={NtaiIcons[icon]} size="xl" />}
      <Typography
        variant={headerVariant ? headerVariant : "h6"}
        fontWeight={headerFontWeight ? headerFontWeight : null}
      >
        {header}
      </Typography>

      <Typography
        sx={{ textAlign: "center" }}
        variant={subheaderVariant ? subheaderVariant : "subtitle1"}
      >
        {subheader}
      </Typography>
    </Box>
  );
}
