import { Box, Button, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import CodeEditor from "./editor/CodeEditor";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  cancelSourceDatasetScript,
  clearStatus,
  createSourceDataset,
  deleteSourceDataset,
  getSourceDatasets,
  runSourceDatasetScript,
  updateSourceDataset,
} from "./store/sourceDatasetsSlice";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { getSourceStage } from "../store/sourceStagesSlice";
const _ = require("lodash");

export const SourceDatasetsContext = React.createContext();

export default function SourceDatasetList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeDatasetId, setActiveDatasetId] = useState();
  const [deleteDatasetId, setDeleteDatasetId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const { getValues, reset } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceStageId = useSelector(
    (state) => state.sources.sourceStagesSlice.activeId
  );
  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: sourcePipelineId,
    sourceStageId: sourceStageId,
  };

  const stageSlicePathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: sourcePipelineId,
    uuId: sourceStageId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceDatasetsSlice.data
  );

  const data = dataMap
    ? _.orderBy(Object.values(dataMap), ["datasetOrder"], ["asc"])
    : null;


  const status = useSelector(
    (state) => state.sources.sourceDatasetsSlice.status
  );

  function renderEmptyDatasetListPage() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          contentAlign: "center",
        }}
      >
        <Typography>No datasets found</Typography>
        <Button variant="outlined" onClick={() => handleCreateDataset()}>
          Click here to create one.
        </Button>
      </Box>
    );
  }

  function handleCreateDataset() {
    dispatch(
      createSourceDataset({
        ...params,
        formData: { datasetOrder: data ? data.length : 0 },
      })
    );
  }

  function handleUpdateDataset(id, formData) {
    dispatch(
      updateSourceDataset({
        ...params,
        uuId: id,
        formData: formData,
      })
    );
  }

  function onHandleDeleteDataset(datasetId) {
    setDeleteDatasetId(datasetId);
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDeleteDataset() {
    setDeleteDatasetId(null);
    setOpenConfirmDeleteDialog(false);
  }

  function handleDeleteDataset() {
    let reorderedDatasets = [];
    let c = 0;
    const deletedDatasetIndex = _.get(dataMap, deleteDatasetId)["datasetOrder"];

    _.orderBy(data, ["datasetOrder"], ["asc"]).forEach((dataset) => {
      if (dataset["datasetOrder"] > deletedDatasetIndex) {
        reorderedDatasets.push({
          uuId: dataset["uuId"],
          datasetOrder: dataset["datasetOrder"] - 1,
        });
      }
    });

    dispatch(
      deleteSourceDataset({
        ...params,
        datasetId: deleteDatasetId,
        toBeDeletedDataset: {
          uuId: deleteDatasetId,
          reorderedDatasets: [...reorderedDatasets],
        },
      })
    );
  }

  function insertAfter(index) {
    let reorderedDatasets = [];
    let c = 0;

    _.orderBy(data, ["datasetOrder"], ["asc"]).forEach((dataset) => {
      let newIndex = dataset["datasetOrder"];
      if (dataset["datasetOrder"] > index) {
        reorderedDatasets.push({
          uuId: dataset["uuId"],
          datasetOrder: dataset["datasetOrder"] + 1,
        });
      }
    });

    dispatch(
      createSourceDataset({
        ...params,
        formData: {
          datasetOrder: index + 1,
          reorderedDatasets: [...reorderedDatasets],
        },
      })
    );
  }

  function handleRunScript(datasetId, formData) {
    dispatch(
      runSourceDatasetScript({
        ...params,
        uuId: datasetId,
        formData: formData,
        requestMode: "single",
      })
    );
  }

  function handleCancelScript(datasetId) {
    dispatch(
      cancelSourceDatasetScript({
        ...params,
        uuId: datasetId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceDatasets" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteSourceDataset" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      cancelDeleteDataset();
      dispatch(getSourceStage(stageSlicePathVariables));
    }

    if (
      status &&
      [
        "createSourceDataset",
        "updateSourceDataset",
        "runSourceDatasetScript",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      dispatch(getSourceStage(stageSlicePathVariables));
      dispatch(getSourceDatasets(params));
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceDatasets(params));
  }, []);

  return (
    <SourceDatasetsContext.Provider
      value={{ activeDatasetId, setActiveDatasetId }}
    >
      <Scrollbar>
        <Box
          sx={{
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          {loaded &&
            data &&
            data.length > 0 &&
            _.orderBy(data, ["datasetOrder"], ["asc"]).map((ds, index) => {
              return (
                <React.Fragment key={ds.uuId}>
                  <CodeEditor
                    stageId={sourceStageId}
                    dsScript={ds.datasetScript}
                    datasetId={ds.uuId}
                    handleDeleteDataset={onHandleDeleteDataset}
                    handleUpdateDataset={handleUpdateDataset}
                    handleRunScript={handleRunScript}
                    handleCancelScript={handleCancelScript}
                    insertAfter={insertAfter}
                    wordCompleterList={_.map(data, function (o) {
                      return {
                        label: o["name"],
                        value: o["name"],
                        meta: o["stageName"],
                      };
                    })}
                  />
                  {index === data.length - 1 && (
                    <Box sx={{ height: "120px" }}> </Box>
                  )}
                </React.Fragment>
              );
            })}

          {loaded &&
            Array.isArray(data) &&
            data.length === 0 &&
            renderEmptyDatasetListPage()}

          {!loaded && (
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <NtaiCircularProgress />
            </Box>
          )}
        </Box>
      </Scrollbar>
      <NtaiConfirmDeleteDialog
        items={
          deleteDatasetId &&
          deleteDatasetId.length > 0 && [
            _.get(dataMap[deleteDatasetId], "name"),
          ]
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDeleteDataset}
        handleCancelDelete={cancelDeleteDataset}
      />
    </SourceDatasetsContext.Provider>
  );
}
