import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";

const _ = require("lodash");

export const getSearchNotesIcon = createAsyncThunk(
  "searchNotesIcon/getSearchNotesIcon",
  async (values, { rejectWithValue }) => {
    const { searchId, key, formData } = values;
    try {
      const response = await server.put(
        `/searches/${searchId}/notes-count`,
        formData
      );
      return { key: key, data: response.data };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const searchNotesIconSlice = createSlice({
  name: "searchNotesIcon",
  initialState: {
    data: {},
    status: {},
    reloadKey: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      if (!_.isEmpty(state.status)) delete state.status[action.payload];
    },
    setReloadKey: (state, action) => {
      state.reloadKey = action.payload;
    },
    clearReloadKey: (state, action) => {
      state.reloadKey = null;
    },
  },
  extraReducers: {
    [getSearchNotesIcon.pending]: (state, action) => {
      // console.log("Action pen ==== ", action);
    },

    [getSearchNotesIcon.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.key]: action.payload.data,
      };

      state.status = {
        ...state.status,
        [action.payload.key]: {
          result: "success",
          method: "getSearchNotesIcon",
        },
      };
    },
    [getSearchNotesIcon.rejected]: (state, action) => {
      // state.status = {
      //   ...state.status,
      //   [action.payload.key]: {
      //     result: "error",
      //     method: "getSearchNotesIcon",
      //     message: action.payload.message,
      //   },
      // };
    },
  },
});

export const { clearStatus, setReloadKey, clearReloadKey } =
  searchNotesIconSlice.actions;

export default searchNotesIconSlice.reducer;
