import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  clearStatus,
  createRoleResource,
  deleteRoleResource,
  getRoleResources,
} from "../store/roleResourcesSlice";
import {
  getWorkflowDefVersionActivities,
  clearStatus as clearWorkflowActivitiesSliceStatus,
} from "../../../workflow/versions/activities/store/workflowDefVersionActivitiesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary:
          allObjectsDataMap[f.resourceDataUuId]["workflowDefName"] +
          " - " +
          allObjectsDataMap[f.resourceDataUuId]["name"],
        secondary:
          "Version: " +
          allObjectsDataMap[f.resourceDataUuId]["workflowDefVersion"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(
      allObjects,
      ["workflowDefName", "workflowDefVersion", "name"],
      ["asc", "asc", "asc"]
    ),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.resourceDataUuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function RoleWorkflowActivities() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const roleId = useSelector((state) => state.core.rolesSlice.activeId);

  const workflowActivitiesDataMap = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.data
  );

  const workflowActivitiesSliceStatus = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.status
  );

  const dataMap = useSelector((state) => state.core.roleResourcesSlice.data);

  const status = useSelector((state) => state.core.roleResourcesSlice.status);

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const workflowActivitiesData =
    workflowActivitiesDataMap && !_.isEmpty(workflowActivitiesDataMap)
      ? Object.values(workflowActivitiesDataMap)
      : [];

  function handleAdd(uuIds) {
    dispatch(
      createRoleResource({
        roleId: roleId,
        formData: {
          typeCode: 4,
          resourceDataUuIds: [uuIds],
        },
      })
    );
  }

  function handleRemove(uuId) {
    dispatch(
      deleteRoleResource({
        roleId: roleId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      ["getRoleResources", "createRoleResource", "deleteRoleResource"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setAvailableObjects([
        ...transformAvailableObjects(data, workflowActivitiesData),
      ]);
      setSelectedObjects([
        ...transformSelectedObjects(data, workflowActivitiesDataMap),
      ]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      workflowActivitiesSliceStatus &&
      workflowActivitiesSliceStatus.result === "success" &&
      workflowActivitiesSliceStatus.method === "getWorkflowDefVersionActivities"
    ) {
      dispatch(
        getRoleResources({
          roleId: roleId,
          params: {
            typeCode: 4,
          },
        })
      );
      dispatch(clearWorkflowActivitiesSliceStatus());
    }
  }, [workflowActivitiesSliceStatus]);

  useEffect(() => {
    dispatch(
      getWorkflowDefVersionActivities({
        workflowDefId: "*",
        workflowDefVersionId: "*",
      })
    );
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Workflow Permissions"
      subheader="Define which workflow activities user has access to for a given role"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Workflow Activities Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(
                    availableObjects,
                    ["workflowDefName", "name"],
                    ["asc", "asc"]
                  ).map((fd, i) => (
                    <React.Fragment key={`available-objects-${i}`}>
                      <ListItem
                        sx={{ borderRadius: 0 }}
                        secondaryAction={
                          <Button
                            sx={{ fontSize: "11px" }}
                            variant="outlined"
                            size="small"
                            onClick={() => handleAdd(fd.uuId)}
                          >
                            ADD
                          </Button>
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ fontWeight: 700 }}
                              variant="subtitle1"
                            >
                              {`${fd.workflowDefName} - ${fd.name}`}
                            </Typography>
                          }
                          secondary={`Version: ${fd.workflowDefVersion}`}
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No workflow activities available"
                subheader="Contact admin for wf activities configuration"
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No wf activities assigned"
                subheader="Add wf activities from left panel to the role"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
