import ReportTemplateCreate from "./ReportTemplateCreate";
import ReportTemplateEdit from "./ReportTemplateEdit";
import ReportTemplateList from "./ReportTemplateList";

const ReportTemplateConfig = {
  routes: [
    {
      path: "/admin/templates/reports",
      exact: true,
      component: ReportTemplateList,
    },
    {
      path: "/admin/templates/reports/create",
      exact: true,
      component: ReportTemplateCreate,
    },
    {
      path: "/admin/templates/reports/:id/edit",
      exact: true,
      component: ReportTemplateEdit,
    },
  ],
};

export default ReportTemplateConfig;
