import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import {
  clearStatus,
  createAlertDefinition,
} from "./store/alertDefinitionsSlice";
import AlertDefinitionForm from "./AlertDefinitionForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AlertDefinitionCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );
  const status = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );
  const activeId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  function handleSave(formData) {
    dispatch(createAlertDefinition(formData));
  }

  useEffect(() => {
    if (
      status.method === "createAlertDefinition" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Alert definition "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/monitor/definitions/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Definition">
      <NtaiForm>
        <AlertDefinitionForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
