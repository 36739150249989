import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceFieldSemTypeForm from "./SourceFieldSemTypeForm";
import {
  createSourceFieldSemType,
  updateSourceFieldSemType,
  deleteSourceFieldSemType,
  clearStatus,
  getSourceFieldSemTypes,
} from "./store/sourceFieldSemTypesSlice";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "thesaurusName",
    numeric: false,
    disablePadding: false,
    label: "Thesaurus",
  },
  {
    id: "thesaurusSemTypeName",
    numeric: false,
    disablePadding: false,
    label: "Semantic Type",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function SourceFieldSemTypeList({ fieldId }) {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector(
    (state) => state.sources.sourceFieldSemTypesSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceFieldSemTypesSlice.status
  );

  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteSourceFieldSemType({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            fieldId: fieldId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceFieldSemType({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            fieldId: fieldId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceFieldSemType({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            fieldId: fieldId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    dispatch(
      getSourceFieldSemTypes({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        fieldId: fieldId,
      })
    );
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceFieldSemType" ||
        status.method === "updateSourceFieldSemType")
    ) {
      handleDialogClose();
      clearStatus();
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceFieldSemType"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      clearStatus();
    }
  }, [status]);

  return (
    <>
      <NtaiCrudTable
        rows={data}
        headCells={headCells}
        toolbarActions={toolbarActions}
        keyColumn="uuId"
        dialog={true}
        handleDialogAddEdit={handleDialogAddEdit}
        handleToolbarAction={handleToolbarAction}
        title="Field Semantic Type"
      />

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Source Field Semantic Type"
        size="sm"
      >
        <NtaiForm>
          <SourceFieldSemTypeForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
