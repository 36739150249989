import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getModels } from "../../../models/store/modelsSlice";
import {
  updateFunction,
  updateFunctionModel,
} from "../../store/functionsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { FunctionEditorContext } from "../../FunctionEdit";
import NtaiFilterMultiSelectList from "@ntai/components/inputs/filters/NtaiFilterMultiSelectList";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";

const _ = require("lodash");

export default function FunctionModelSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { action, formData } = useContext(FunctionEditorContext);
  const { getValues, setValue, reset, watch } = useFormContext();

  const modelDataMap = useSelector((state) => state.models.modelsSlice.data);

  const activeId = useSelector((state) => state.models.functionsSlice.activeId);

  const watchModelSelection = watch(
    "functionForm.modelUuIds",
    _.get(formData, "modelUuIds")
  );

  const modelOptions = NtaiUtils.getSelectOptions(
    Object.values(modelDataMap),
    "uuId",
    "name"
  );

  function updateModel() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("functionForm")
    );

    if (
      _.has(sanitizedFormData, "modelUuIds") &&
      !_.isEqual(
        _.get(sanitizedFormData, "modelUuIds"),
        _.get(formData, "modelUuIds")
      )
    ) {
      dispatch(
        updateFunction({
          uuId: activeId,
          formData: sanitizedFormData,
        })
      );
    }
  }

  useEffect(() => {
    updateModel();
  }, [watchModelSelection]);

  // useEffect(() => {
  //   setValue("functionForm.modelUuIds", _.get(formData, "modelUuIds"));
  // }, [formData]);

  useEffect(() => {
    dispatch(getModels());
  }, []);

  return (
    <NtaiAccordion title="Models" titleIcon="shapes">
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {Array.isArray(modelOptions) && modelOptions.length > 0 && (
          <NtaiFilterMultiSelectList
            name="functionForm.modelUuIds"
            options={modelOptions}
          />
        )}
        {!modelOptions ||
          (Array.isArray(modelOptions) && modelOptions.length === 0 && (
            <Box sx={{ p: theme.spacing(2) }}>
              <NtaiEmptyMessage header="No models found" />
            </Box>
          ))}
      </Box>
    </NtaiAccordion>
  );
}
