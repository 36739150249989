import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";

const _ = require("lodash");

export default function SourceStageForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceStageForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ sourceStageForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="sourceStageForm.name"
          label="Name*"
          defaultValue="Stage 1"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="sourceStageForm.description"
          multiline
          minRows={3}
          maxRows={3}
          defaultValue="Stage 1 description"
          label="Description"
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
