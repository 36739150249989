import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import React from "react";
import RightSidebarCollapsed from "./RightSidebarCollapsed";
import RightSidebarExpanded from "./RightSidebarExpanded";
import LeftSidebarCollapsed from "./LeftSidebarCollapsed";
import LeftSidebarExpanded from "./LeftSidebarExpanded";
import LeftSidebar from "./LeftSidebar";

export default function NtaiTestMouseDrawer() {
  const theme = useTheme();
  const [leftExpanded, setLeftExpanded] = React.useState(false);
  const [rightExpanded, setRightExpanded] = React.useState(false);

  function handleLeftMouseEnter() {
    setLeftExpanded(true);
  }

  function handleLeftMouseLeave() {
    setLeftExpanded(false);
  }

  function handleRightMouseEnter() {
    setRightExpanded(true);
  }

  function handleRightMouseLeave() {
    setRightExpanded(false);
  }

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box
        onMouseEnter={handleLeftMouseEnter}
        onMouseLeave={handleLeftMouseLeave}
        sx={{
          width: leftExpanded ? "200px" : "62px",
          display: "flex",
          height: "100%",
          borderRight: theme.general.border1,
          boxShadow: theme.colors.shadows.card,
        }}
      >
        <LeftSidebar />
      </Box>
      <Box
        sx={{
          p: theme.spacing(1),
          flexBasis: "95%",
          display: "flex",
          height: "100%",
        }}
      >
        Center content
      </Box>
    </Box>
  );
}
