import { Box } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import WorkflowDefVersionActivityGeneralForm from "./WorkflowDefVersionActivityGeneralForm";
import WorkflowDefVersionActivityRules from "./rules/WorkflowDefVersionActivityRules";
import { useSelector } from "react-redux";

export default function WorkflowDefVersionActivityEdit({
  handleSave,
  handleCancel,
}) {
  const dataMap = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.data
  );
  const status = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.status
  );

  const activityId = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.activeId
  );

  const formData = activityId && dataMap ? dataMap[activityId] : {};

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <NtaiPanel
          width="100%"
          header={formData["name"]}
          subheader={`Activity Id: ${formData["activityId"]} | Duration: ${
            formData["duration"] || "Not available"
          } | Type: ${formData["type"]}`}
        />

        <NtaiTabs>
          <Box label="GENERAL">
            <WorkflowDefVersionActivityGeneralForm
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </Box>
          <Box label="RULES">
            <WorkflowDefVersionActivityRules />
          </Box>
        </NtaiTabs>
      </Box>
    </React.Fragment>
  );
}
