import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { getSourceDefinitions } from "../../sourcedefinition/store/sourceDefinitionsSlice";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import UserQueryCriteria from "./UserQueryCriteria";
const _ = require("lodash");

function getSourceOptions(sourceDefData) {
  const result = [];
  sourceDefData.forEach((sd, i) => {
    sd.sourceMins.forEach((sdsm, j) => {
      result.push({
        value: sdsm.uuId,
        label: sdsm.displayName,
      });
    });
  });

  return result;
}

export default function UserQueryForm({ action, formData, handleSave }) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();
  const codes = useSelector((state) => state.core.codelistsSlice.codes);

  const queryTypeOptions =
    codes && _.has(codes, CODELIST_CODES.LIB_QUERY_TYPE)
      ? _.get(codes, CODELIST_CODES.LIB_QUERY_TYPE)
      : [];

  const sourceDefinitionsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionsSliceStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const sourceDefData = Object.values(sourceDefinitionsDataMap);

  const sourceOptions =
    sourceDefData && _.isArray(sourceDefData) && sourceDefData.length > 0
      ? getSourceOptions(sourceDefData)
      : [];

  const activeId = formData && !_.isEmpty(formData) ? formData["uuId"] : null;

  const userQueryDataMap = useSelector(
    (state) => state.core.userQueriesSlice.data
  );

  const userQueryData = Object.values(userQueryDataMap);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("userQueryForm")
    );

    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/library/queries`);
  }

  useEffect(() => {
    dispatch(getSourceDefinitions());
    dispatch(getCodes(CODELIST_CODES.LIB_QUERY_TYPE));
    reset({ userQueryForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Details"
          subheader="Enter rule name, description, associated source and criteria mode"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="userQueryForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />

          <NtaiTextField
            name="userQueryForm.description"
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Description"
            label="Description"
          />

          <NtaiSelectField
            label="Source*"
            name="userQueryForm.sourceUuId"
            options={sourceOptions}
            isDisabled={action === "add" ? false : true}
            rules={{ required: "Source is required" }}
          />
          <NtaiSelectField
            label="Criteria Mode*"
            name="userQueryForm.queryModeFg"
            options={[
              { value: 1, label: "Query Builder" },
              { value: 2, label: "Native Query" },
            ]}
            isDisabled={action === "add" ? false : true}
            rules={{ required: "Criteria mode is required" }}
          />
          {/* <NtaiSelectField
            label="Type"
            name="userQueryForm.typeCode"
            options={queryTypeOptions}
          /> */}
        </NtaiFormPanel>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="CRITERIA"
        disabled={action === "add" ? true : false}
      >
        {action === "edit" &&
        userQueryDataMap[activeId].queryModeFg &&
        [1, 2].includes(userQueryDataMap[activeId].queryModeFg) ? (
          <UserQueryCriteria
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        ) : null}
      </Box>
    </NtaiTabs>
  );
}
