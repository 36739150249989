import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import React from "react";
import SourceWidgetResizableGridLayout from "./SourceWidgetResizableGridLayout";
import { useMeasure } from "react-use";

export default function SourceWidgetLayout({
  layout,
  generateWidgetList,
  handleLayoutChange,
}) {
  const theme = useTheme();
  const [ref, { width, height }] = useMeasure();

  return (
    <Scrollbar autoHide={true}>
      <Box
        ref={ref}
        sx={{
          p: theme.spacing(1),
          height: "100%",
          width: "100%",
          display: "flex",
        }}
      >
        <SourceWidgetResizableGridLayout
          width={width}
          rowHeight={40}
          cols={12}
          layout={layout ? layout : {}}
          items={generateWidgetList()}
          handleLayoutChange={handleLayoutChange}
        />
      </Box>
    </Scrollbar>
  );
}
