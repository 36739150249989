import { combineReducers } from "@reduxjs/toolkit";
import searchSlice from "./searchSlice";
import searchNotesSlice from "../edit/notes/store/searchNotesSlice";
import searchNotesIconSlice from "../edit/notes/notes-icon/store/searchNotesIconSlice";
import searchInitSlice from "../init/store/searchInitSlice";
import searchDashboardInitSlice from "../init/dashboard/store/searchDashboardInitSlice";
import searchSourcesSlice from "../edit/source/store/searchSourcesSlice";
import searchSourceViewSlice from "../edit/source/view/store/searchSourceViewsSlice";
import searchSourceViewWidgetsSlice from "../edit/source/view/widgets/store/searchSourceViewWidgetsSlice";
import searchSourceRecordsSlice from "../edit/source/records/store/searchSourceRecordsSlice";
import searchSourceRecordActionsSlice from "../edit/source/records/recordpanel/action/store/searchSourceRecordActionsSlice";
import searchSourceRecordNotesSlice from "../edit/source/records/recordpanel/note/store/searchSourceRecordNotesSlice";
import searchSourceRecordAdjsSlice from "../edit/source/records/recordpanel/adjudication/store/searchSourceRecordAdjsSlice";
import searchActionShareSlice from "../edit/header/actionpanel/share/store/searchActionShareSlice";
import searchSourceLinkedSourcePreviewsSlice from "../edit/source/toolbar/linkedsource/store/searchSourceLinkedSourcePreviewsSlice";
import searchTopicsSlice from "../edit/header/actionpanel/topic/store/searchTopicsSlice";
import searchSourceFilterSchemesSlice from "../edit/source/advanced/filter/store/searchSourceFilterSchemesSlice";
import searchSourceRecordViewsSlice from "../edit/source/records/recordpanel/view/store/searchSourceRecordViewsSlice";
import searchSourceRecordViewWidgetsSlice from "../edit/source/records/recordpanel/view/source-view/store/searchSourceRecordViewWidgetsSlice";
import searchSourceHierarchySlice from "../edit/source/toolbar/hierarchy/store/searchSourceHierarchySlice";
import searchSourceWidgetDataPointsSlice from "../edit/source/view/widgets/datapoint/store/searchSourceWidgetDataPointsSlice";
import searchSourceRecordsSummarySlice from "../edit/source/bottomtoolbar/store/searchSourceRecordsSummarySlice";
import searchSummarySourceRecordsSlice from "../edit/header/actionpanel/summary/records/store/searchSummarySourceRecordsSlice";
import searchSourceRecordDocumentsSlice from "../edit/source/records/recordpanel/document/store/searchSourceRecordDocumentsSlice";
import searchSourceRecordLookupsSlice from "../edit/source/records/lookup/store/searchSourceRecordLookupsSlice";
import searchSourceWidgetManagerSlice from "../edit/source/toolbar/viewmenu/widgets/store/searchSourceWidgetManagerSlice";
import searchSourceRecordSourceDocsSlice from "../edit/source/records/recordpanel/view/source-doc/store/searchSourceRecordSourceDocsSlice";
import searchSourceRecordsCountSlice from "../edit/source/records/store/searchSourceRecordsCountSlice";
import searchThesaurusLookupSlice from "../init/basic/lookup/store/searchThesaurusLookupSlice";
import searchSourceRecordSeriesSlice from "../edit/source/records/series/store/searchSourceRecordSeriesSlice";

import searchSourceRecordDistributionsSlice from "../edit/source/records/distribute/store/searchSourceRecordDistributionsSlice";
import searchSourceReportTemplatesSlice from "../edit/source/records/reports/store/searchSourceReportTemplatesSlice";

import searchDateRangeSlice from "../edit/header/actionpanel/daterange/store/searchDateRangeSlice";
import searchSourceRecordsBatchAssessmentsSlice from "../edit/source/records/batch-assessment/store/searchSourceRecordsBatchAssessmentsSlice";
import searchSeriesSlice from "../init/series/store/searchSeriesSlice";
import searchSourceRecordBulkDocumentsSlice from "../edit/source/records/document/store/searchSourceRecordBulkDocumentsSlice";
import searchReportInitSlice from "../init/report/store/searchReportInitSlice";

const searchReducer = combineReducers({
  searchSlice,
  searchSeriesSlice,
  searchDateRangeSlice,
  searchInitSlice,
  searchNotesSlice,
  searchNotesIconSlice,
  searchDashboardInitSlice,
  searchReportInitSlice,
  searchActionShareSlice,
  searchSourcesSlice,
  searchSourceViewSlice,
  searchSourceViewWidgetsSlice,
  searchSourceRecordsSlice,
  searchSourceRecordsCountSlice,
  searchSourceRecordActionsSlice,
  searchSourceRecordDocumentsSlice,
  searchSourceRecordNotesSlice,
  searchSourceRecordAdjsSlice,
  searchSourceRecordBulkDocumentsSlice,
  searchSourceLinkedSourcePreviewsSlice,
  searchSourceRecordViewsSlice,
  searchSourceRecordViewWidgetsSlice,
  searchTopicsSlice,
  searchSourceReportTemplatesSlice,
  searchSourceFilterSchemesSlice,
  searchSourceHierarchySlice,
  searchSourceWidgetDataPointsSlice,
  searchSourceRecordsSummarySlice,
  searchSummarySourceRecordsSlice,
  searchSourceRecordLookupsSlice,
  searchSourceWidgetManagerSlice,
  searchSourceRecordSourceDocsSlice,
  searchThesaurusLookupSlice,
  searchSourceRecordSeriesSlice,
  searchSourceRecordDistributionsSlice,
  searchSourceRecordsBatchAssessmentsSlice,
});

export default searchReducer;
