import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDistributionGroups = createAsyncThunk(
  "distributionGroup/getDistributionGroups",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/library/distribution-groups");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDistributionGroup = createAsyncThunk(
  "distributionGroup/getDistributionGroup",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/library/distribution-groups/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDistributionGroup = createAsyncThunk(
  "distributionGroup/createDistributionGroup",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(
        "/library/distribution-groups",
        values
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDistributionGroup = createAsyncThunk(
  "distributionGroup/updateDistributionGroup",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/library/distribution-groups/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDistributionGroup = createAsyncThunk(
  "distributionGroup/deleteDistributionGroup",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(
        `/library/distribution-groups/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const distributionGroupsSlice = createSlice({
  name: "distributionGroups",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDistributionGroups.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getDistributionGroups" };
    },
    [getDistributionGroups.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDistributionGroups",
        message: action.payload.message,
      };
    },
    [getDistributionGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getDistributionGroup" };
    },
    [getDistributionGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDistributionGroup",
        message: action.payload.message,
      };
    },
    [createDistributionGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createDistributionGroup" };
    },
    [createDistributionGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createDistributionGroup",
        message: action.payload.message,
      };
    },
    [updateDistributionGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateDistributionGroup" };
    },
    [updateDistributionGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateDistributionGroup",
        message: action.payload.message,
      };
    },
    [deleteDistributionGroup.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteDistributionGroup" };
    },
    [deleteDistributionGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteDistributionGroup",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = distributionGroupsSlice.actions;

export default distributionGroupsSlice.reducer;
