import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Drawer,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceDatasetList from "./dataset/SourceDatasetList";
import SourceDatasetSidePanel from "./dataset/sidepanel/SourceDatasetSidePanel";
import SourceDatasetBottomDrawer from "./dataset/sidepanel/bottom-drawer/SourceDatasetBottomDrawer";
import {
  faForward,
  faPlay,
  faSquare,
  faStaff,
  faStepBackward,
} from "@fortawesome/pro-light-svg-icons";
import {
  faHashtag,
  faArrowProgress,
  faArrowLeft,
  faEllipsisVertical,
  faTable,
} from "@fortawesome/pro-regular-svg-icons";

import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import {
  clearStatus,
  runSourceDatasetScript,
} from "./dataset/store/sourceDatasetsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

export const SourceDatasetManagerContext = React.createContext();

export default function SourceDatasetManager({ toggleView }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [groupsFetched, setGroupsFetched] = useState(false);
  const [sidePanelMenuParam, setSidePanelMenuParam] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [runAllDatasetStatuses, setRunAllDatasetStatuses] = useState([]);
  const [runAllDatasetCurrentId, setRunAllDatasetCurrentId] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const sourceStageId = useSelector(
    (state) => state.sources.sourceStagesSlice.activeId
  );

  const stageDataMap = useSelector(
    (state) => state.sources.sourceStagesSlice.data
  );

  const stageData = _.get(stageDataMap, sourceStageId);

  const status = useSelector(
    (state) => state.sources.sourceDatasetsSlice.status
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: sourcePipelineId,
    sourceStageId: sourceStageId,
  };

  function handleDrawerClose() {
    setOpenDrawer(false);
  }

  function onHandleRunAllDatasets() {
    if (
      stageData["datasets"] &&
      _.isArray(stageData["datasets"]) &&
      stageData["datasets"].length > 0
    ) {
      const tmpRunAllDatasetStatuses = _.orderBy(
        stageData["datasets"],
        ["datasetOrder"],
        ["asc"]
      ).map((o, i) => {
        return {
          uuId: o["uuId"],
          status: "pending",
          message: "",
        };
      });

      setRunAllDatasetStatuses(tmpRunAllDatasetStatuses);
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "runSourceDatasetScript" &&
      ["success", "error"].includes(status.result) &&
      status.requestMode === "bulk"
    ) {
      const runAllDatasetStatusesCopy = [];
      runAllDatasetStatuses.forEach((o, i) => {
        if (o["uuId"] === status["uuId"]) {
          runAllDatasetStatusesCopy.push({
            ...o,
            status: status.result,
            message: "",
          });
        } else {
          runAllDatasetStatusesCopy.push(o);
        }
      });

      dispatch(clearStatus());
      setRunAllDatasetStatuses([...runAllDatasetStatusesCopy]);
    }
  }, [status]);

  useEffect(() => {
    if (_.isArray(runAllDatasetStatuses) && runAllDatasetStatuses.length > 0) {
      const firstPendingArr = _.filter(runAllDatasetStatuses, {
        status: "pending",
      });
      if (firstPendingArr.length > 0) {
        setRunAllDatasetCurrentId(firstPendingArr[0]["uuId"]);
        dispatch(
          runSourceDatasetScript({
            ...pathVariables,
            uuId: firstPendingArr[0]["uuId"],
            formData: _.filter(stageData["datasets"], {
              uuId: firstPendingArr[0]["uuId"],
            })[0],
            requestMode: "bulk",
          })
        );
      }
    }
  }, [runAllDatasetStatuses]);

  return (
    <SourceDatasetManagerContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
        sidePanelMenuParam,
        setSidePanelMenuParam,
        runAllDatasetCurrentId,
      }}
    >
      <React.Fragment>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexBasis: "80%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #eee",
                height: "44px",
                alignItems: "center",
                paddingLeft: theme.spacing(2),
                gap: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  flexBasis: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
              >
                {/* <Button
                  size="small"
                  startIcon={<FontAwesomeIcon size="xs" icon={faArrowLeft} />}
                  onClick={() => toggleView("list")}
                >
                  Back to stages
                </Button> */}
                <Chip
                  size="small"
                  sx={{ px: theme.spacing(1) }}
                  icon={
                    <FontAwesomeIcon
                      style={{ fontSize: "12px" }}
                      icon={faArrowLeft}
                    />
                  }
                  label="Back to stages"
                  variant="outlined"
                  onClick={() => toggleView("list")}
                />
                <FontAwesomeIcon icon={faEllipsisVertical} />
                <Chip
                  sx={{ px: theme.spacing(1) }}
                  icon={
                    <FontAwesomeIcon
                      style={{ fontSize: "12px" }}
                      icon={faArrowProgress}
                    />
                  }
                  size="small"
                  label={
                    <Typography
                      variant="subtitle2"
                      fontWeight={700}
                      color="inherit"
                    >
                      {_.get(stageData, "name")}
                    </Typography>
                  }
                />
                <FontAwesomeIcon icon={faEllipsisVertical} />

                <Chip
                  sx={{ px: theme.spacing(1) }}
                  variant="outlined"
                  icon={
                    <FontAwesomeIcon
                      style={{ fontSize: "12px" }}
                      icon={faTable}
                    />
                  }
                  size="small"
                  label={`Datasets: ${stageData["datasets"].length}`}
                />
                {/* <Typography variant="body1" fontWeight={700}>
                  {_.get(stageData, "name")}
                </Typography> */}
              </Box>
              <Box
                sx={{
                  flexBasis: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: theme.spacing(1),
                  paddingRight: theme.spacing(2),
                }}
              >
                {runAllDatasetStatuses.length > 0 && (
                  <Chip
                    variant="outlined"
                    color="secondary"
                    label={
                      <Typography
                        variant="subtitle2"
                        fontWeight="700"
                        color="inherit"
                      >
                        {`Run all status: Total: ${
                          runAllDatasetStatuses.length
                        }, Pending: ${
                          _.filter(runAllDatasetStatuses, { status: "pending" })
                            .length
                        }, Completed: ${
                          _.filter(runAllDatasetStatuses, function (o) {
                            return o["status"] !== "pending" ? true : false;
                          }).length
                        } `}
                      </Typography>
                    }
                    onDelete={() => setRunAllDatasetStatuses([])}
                  />
                )}

                <Tooltip title="Run All Datasets">
                  <Button
                    color="inherit"
                    size="small"
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                    }}
                    disabled={
                      status &&
                      status.result === "pending" &&
                      status.method === "runSourceDatasetScript"
                        ? true
                        : false
                    }
                    onClick={() => onHandleRunAllDatasets()}
                  >
                    {status &&
                    status.result === "pending" &&
                    status.method === "runSourceDatasetScript" &&
                    status.requestMode === "bulk" ? (
                      // <FontAwesomeIcon size="lg" icon={faSpinner} spin />
                      <NtaiCircularProgress size={14} />
                    ) : (
                      <FontAwesomeIcon icon={faForward} />
                    )}
                    Run All
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: `calc(100% - 44px)`,
              }}
            >
              <SourceDatasetList />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexBasis: "20%",
              flexDirection: "column",
              borderLeft: "1px solid #eee",
            }}
          >
            <SourceDatasetSidePanel />
          </Box>
        </Box>

        <Drawer
          PaperProps={{
            style: {
              position: "absolute",
              height: `280px`,
            },
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openDrawer}
          onClose={handleDrawerClose}
          anchor="bottom"
          BackdropProps={{ invisible: true }}
        >
          <SourceDatasetBottomDrawer handleClose={handleDrawerClose} />
        </Drawer>
      </React.Fragment>
    </SourceDatasetManagerContext.Provider>
  );
}
