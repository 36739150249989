import { Box, useTheme, Typography } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import NtaiDecoTree3 from "@ntai/components/treeview/NtaiDecoTree3";
import NtaiTreeView1 from "@ntai/components/treeview/NtaiTreeView1";
import {
  getDatasourceJdbcMetadata,
  clearStatus,
} from "app/main/pages/core/admin/datasource/metadata/store/datasourceJdbcMetadataSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

const data1 = {
  id: "root",
  name: "SCHEMA",
  icon: "table",
  children: [
    {
      id: "1",
      name: "FDA AERS",
      icon: "database",
    },
    {
      id: "2",
      name: "PMDA Japan",
      icon: "database",
    },
    {
      id: "3",
      name: "CTGOV",
      icon: "table",
      children: [
        {
          id: "4",
          name: "LSSRM",
          icon: "table",
          counts: 6,
          children: [
            {
              id: "5",
              icon: "table",
              name: "LSMV - BMS 0",
              counts: 9,
              children: [
                {
                  id: "1",
                  name: "column1",
                  counts: 10,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

function getColumnIcon(datType) {
  if (["varchar", "varchar2", "text", "char"].includes(datType)) return "text";
  if (["int2", "int4", "int", "serial", "bigint", "smallint"].includes(datType))
    return "numeric";
  if (["date", "timestamp"].includes(datType)) return "date";
  if (["bytea"].includes(datType)) return "image";

  return "text";
}

function process(metadata) {
  let result = null;
  if (
    _.has(metadata, "schemas") &&
    _.isArray(metadata["schemas"]) &&
    metadata["schemas"].length > 0
  ) {
    result = {
      id: "root",
      name: "SCHEMAS",
      label: "SCHEMAS",
      icon: "database",
      children: [],
    };

    metadata["schemas"].forEach((s, i) => {
      let schema = {
        id: i.toString(),
        name: s["name"],
        icon: "database",
        children: [],
      };
      if (
        _.has(s, "tables") &&
        _.isArray(s["tables"]) &&
        s["tables"].length > 0
      ) {
        s["tables"].forEach((t, j) => {
          let table = {
            id: i.toString() + j.toString(),
            name: t["name"],
            icon: "table",
            children: [],
          };
          if (
            _.has(t, "columns") &&
            _.isArray(t["columns"]) &&
            t["columns"].length > 0
          ) {
            t["columns"].forEach((c, k) => {
              let column = {
                id: i.toString() + j.toString() + k.toString(),
                name: c["name"],
                icon: getColumnIcon(c["dataType"]),
              };
              table["children"].push(column);
            });

            schema["children"].push(table);
          }
        });
      }
      result["children"].push(schema);
    });
  }

  return result;
}

export default function SourceInStoreDatasetDBBrowser() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);

  const sourceInStoreId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );

  const sourceInStoreDataMap = useSelector(
    (state) => state.sources.sourceInsSlice.data
  );

  const datasourceJdbcMetadataSliceStatus = useSelector(
    (state) => state.core.datasourceJdbcMetadataSlice.status
  );

  const datasourceJdbcMetadata = useSelector(
    (state) => state.core.datasourceJdbcMetadataSlice.data
  );

  const sourceInStoreData = sourceInStoreDataMap[sourceInStoreId];

  const tmpMetadata =
    datasourceJdbcMetadata[sourceInStoreData["datasourceUuId"]];

  const metadata = process(tmpMetadata);

  function handleClick(node) {}

  useEffect(() => {
    if (
      datasourceJdbcMetadataSliceStatus &&
      datasourceJdbcMetadataSliceStatus.method ===
        "getDatasourceJdbcMetadata" &&
      datasourceJdbcMetadataSliceStatus.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [datasourceJdbcMetadataSliceStatus]);

  useEffect(() => {
    dispatch(getDatasourceJdbcMetadata(sourceInStoreData["datasourceUuId"]));
  }, [sourceInStoreData]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          p: theme.spacing(2),
          display: "flex",
          height: "44px",
          alignItems: "center",
          borderBottom: theme.general.border1,
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight="700">Database Browser</Typography>
        <NtaiSimpleSearchBar
          height="32px"
          width="auto"
          placeholder="Filter..."
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 44px)`,
          width: "100%",
          flexDirection: "column",
        }}
      >
        {loaded && (
          <Scrollbar autoHide={false}>
            <Box sx={{ display: "flex", p: theme.spacing(2) }}>
              <NtaiDecoTree3
                // handleClick={handleClick}
                idField="id"
                nameField="name"
                labelField="name"
                badgeField="counts"
                iconField="icon"
                data={metadata}
                handleClick={handleClick}
              />
            </Box>
          </Scrollbar>
        )}

        {!loaded && <NtaiCircularProgress size={24} />}
      </Box>
    </Box>
  );
}
