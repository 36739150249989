import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { tooltipConfig } from "../chartConfig";
import { Box, lighten } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

const DEFAULT_OPTION = {
  tooltip: {
    ...tooltipConfig,
    formatter: function (params) {
      return NtaiEChartUtil.getTooltipContent({
        values: [
          {
            key:
              NtaiEChartUtil.getMarker(params.data._color) + " " + params.name,
            value: params.data._filterFg
              ? params.data._actual +
                ` (${params.data._total}) (${params["percent"]}%)`
              : params.data._actual + ` (${params["percent"]}%)`,
          },
        ],
      });
    },
  },
  series: [
    {
      type: "pie",
      radius: ["20%", "40%"],
      data: null,
      label: {
        fontSize: 12,
        formatter: function (params) {
          return params["data"]["name"] + " (" + params["percent"] + "%)";
        },
      },
      cursor: "default",
      roseType: null,
      animationType: "scale",
      animationEasing: "elasticOut",
      animationDelay: function (idx) {
        return Math.random() * 200;
      },
    },
  ],
};

function processData(dat, metadata, theme) {
  const colors = NtaiEChartUtil.getColorPalette(theme, dat.length);

  let result = [];
  dat.forEach((d, i) => {
    const revisedColor = d.hasOwnProperty("colorValue")
      ? NtaiEChartUtil.getItemStyleColor(
          "bg",
          dat["colorValue"] && dat["colorValue"].length > 0
            ? dat["colorValue"]
            : dat["value"],
          colors[i],
          metadata
        )
      : colors[i];

    result.push({
      name: d["name"],
      value: d["total"],
      _color: revisedColor,
      _offset: d["offset"],
      _actual: metadata["filterFg"] ? d["value"] : d["total"],
      _total: d["total"],
      _filterFg: metadata["filterFg"],
      itemStyle: {
        color: d["value"] === 0 ? lighten(revisedColor, 0.5) : revisedColor,
      },
    });
  });

  return result;
}

export default function NtaiDonutEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  console.log("Donut data: ", data);

  function getSourceWidgetFilterFields(action, params) {
    return metadataRef.current && !_.isEmpty(metadataRef.current)
      ? NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
          [1],
          params,
          metadataRef.current,
          action
        )
      : [];
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params));
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(action, getSourceWidgetFilterFields(action, params));
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);
    if (data && _.isArray(data) && data.length > 0) {
      optionCopy["series"][0]["data"] = processData(data, metadata, theme);
      optionCopy["series"][0]["roseType"] = metadata["filterFg"]
        ? "radius"
        : null;
    }

    setOption(optionCopy);
  }, [data, metadata]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {_.isArray(data) &&
        data.length > 0 &&
        option["series"][0]["data"] &&
        option["series"][0]["data"].length > 0 &&
        chart}
      {_.isArray(data) && data.length === 0 && <NtaiChartEmptyMessage />}
    </Box>
  );
}
