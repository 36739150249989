import React from "react";
import NtaiSourceWidgetSearchBar from "./NtaiSourceWidgetSearchbar";
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import NtaiSimpleSearchBar1 from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar1";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    // flexBasis: "10%",
    height: "32px",
    position: "absolute",
    // borderTop: "1px solid #eee",
    alignItems: "center",
    paddingLeft: "8px",
    paddingRight: "12px",
    width: "100%",
  },
  footerLeft: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexBasis: "50%",
  },
  footerRight: {
    pr: theme.spacing(1),
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    flexBasis: "50%",
    gap: "2px",
  },
}));

export default function NtaiSourceWidgetFooterWrapper({
  id,
  handlePagination,
  pagination,
  onSearchSubmit,
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box id="footer" className={classes.footer}>
      <Box className={classes.footerLeft}>
        <NtaiSimpleSearchBar1
          height="24px"
          background={grey[50]}
          name={`widget-search-${id}`}
          onSearchSubmit={onSearchSubmit}
          placeholder="Search..."
        />
      </Box>
      {handlePagination && pagination && (
        <Box className={classes.footerRight}>
          <IconButton
            size="small"
            sx={{ height: "18px", width: "18px", cursor: "default" }}
            disabled={pagination["currentPage"] === 1}
            onClick={() => handlePagination(-1)}
          >
            <FontAwesomeIcon size="xs" icon={faCaretLeft} />
          </IconButton>
          <Typography variant="caption" color="inherit">
            {pagination["currentPage"]} of {pagination["totalPages"]}
          </Typography>
          <IconButton
            size="small"
            sx={{ height: "18px", width: "18px", cursor: "default" }}
            disabled={
              pagination["currentPage"] === pagination["totalPages"]
                ? true
                : false
            }
            onClick={() => handlePagination(1)}
          >
            <FontAwesomeIcon size="xs" icon={faCaretRight} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
