import { Box, Button, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import History from "@ntai/@history";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  clearStatus,
  createSourceOutDataset,
  deleteSourceOutDataset,
  getSourceOutDatasets,
  updateSourceOutDataset,
} from "../store/sourceOutDatasetsSlice";
import { useEffect } from "react";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import {
  CODELIST_CODES,
  SRC_SOURCE_STORE_FORMAT,
} from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiUtils from "@ntai/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCode } from "@fortawesome/pro-light-svg-icons";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceOutStoreDbScripts from "./scripts/SourceOutStoreDbScripts";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "datasetName",
    numeric: false,
    disablePadding: false,
    label: "Dataset",
    fontWeight: 700,
  },
  {
    id: "dbTableName",
    numeric: false,
    disablePadding: false,
    label: "Table",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function SourceOutStoreDbDatasetList(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = props.match.params.id;

  const sourceOutStoreDataMap = useSelector(
    (state) => state.sources.sourceOutStoresSlice.data
  );

  const dbOutStore = _.get(
    sourceOutStoreDataMap,
    SRC_SOURCE_STORE_FORMAT.RDBMS
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const data = Object.values(dataMap);

  const status = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.status
  );

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(deleteSourceOutDataset(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_STORE_DB_COL_DATA_TYPE));
    dispatch(
      getSourceOutDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceOutStoreId: sourceOutStoreId,
      })
    );
  }, []);

  return (
    <NtaiPage
      padding={2}
      title={`${dbOutStore["formatDecode"]} [${dbOutStore["datasource"]["name"]}]`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`}
    >
      <NtaiTabs>
        <Box label="TABLES">
          <Box sx={{ pt: theme.spacing(2) }}>
            <NtaiCrudTable
              helpText="Map dataset to table and add definition for table column"
              rows={data}
              selectedItems={
                Array.isArray(selectedIds) && selectedIds.length > 0
                  ? selectedIds
                  : []
              }
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              handleDelete={confirmDelete}
              handleToolbarAction={handleToolbarAction}
              path={`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/dbdatasets`}
              title="Tables"
            />
          </Box>
        </Box>
        <Box label="SCRIPTS">
          <NtaiForm>
            <SourceOutStoreDbScripts />
          </NtaiForm>
        </Box>
      </NtaiTabs>
    </NtaiPage>
  );
}
