import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React, { useEffect } from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { getWorkflowDefs } from "app/main/pages/core/admin/workflow/store/workflowDefsSlice";
import {
  createOrUpdateWorkflow,
  clearStatus,
} from "../store/alertDefinitionsSlice";
import { useSnackbar } from "notistack";
const _ = require("lodash");

function getWfDefOptions(sourceDefId, wfDefsData) {
  const filteredWfDefData = _.filter(wfDefsData, function (o) {
    return o["sourceDefUuIds"].includes(sourceDefId) ? true : false;
  });

  return NtaiUtils.getSelectOptions(filteredWfDefData, "uuId", "name");
}

export default function AlertDefWorkflow({ handleCancel }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { getValues, setValue } = useFormContext();

  const definitionDataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );

  const status = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );

  const definitionId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  const definitionData = _.get(definitionDataMap, definitionId);

  const sourceId = _.get(definitionData, "alertDefSource.sourceUuId");
  const sourceDefId = _.get(definitionData, "alertDefSource.sourceDefUuId");

  const wfDefsDataMap = useSelector(
    (state) => state.core.workflowDefsSlice.data
  );
  const wfDefsData =
    wfDefsDataMap && !_.isEmpty(wfDefsDataMap)
      ? Object.values(wfDefsDataMap)
      : [];

  const wfDefsOptions = getWfDefOptions(sourceDefId, wfDefsData);

  function handleSave() {
    const workflowDefUuIdOption = getValues(
      "alertDefinitionForm.workflowDefinitionUuId"
    );

    if (
      !_.isEmpty(workflowDefUuIdOption) &&
      _.has(workflowDefUuIdOption, "value")
    ) {
      console.log("wf uuid: ", workflowDefUuIdOption["value"]);

      dispatch(
        createOrUpdateWorkflow({
          definitionId: definitionId,
          formData: {
            alertDefWorkflow: {
              workflowDefinitionUuId: workflowDefUuIdOption["value"],
            },
          },
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createOrUpdateWorkflow" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Workflow definition for alert "${definitionData["name"]}" saved successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    setValue(
      "alertDefinitionForm.workflowDefinitionUuId",
      _.has(definitionData, "alertDefWorkflow.uuId")
        ? _.get(definitionData, "alertDefWorkflow.workflowDefinitionUuId")
        : null
    );
  }, []);

  useEffect(() => {
    dispatch(getWorkflowDefs());
  }, []);

  return (
    <NtaiFormPanel
      width="50%"
      header="Workflow"
      subheader="Select workflow for the alert source. Alerts will be created and routed into selected workflow definition for the source"
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <NtaiSelectField
        name="alertDefinitionForm.workflowDefinitionUuId"
        label="Alert Workflow*"
        options={wfDefsOptions}
        rules={{ required: "Alert workflow is required" }}
      />
    </NtaiFormPanel>
  );
}
