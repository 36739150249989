import React, { useState } from "react";
import NtaiGeoMap from "./NtaiGeoMap";
import { Box, Button, useTheme } from "@mui/material";
import NtaiGeoMap1 from "./NtaiGeoMap1";
import NtaiMapChart3 from "../maps/NtaiMapChart3";
import NtaiGeoCoding from "./NtaiGeoCoding";
import NtaiGeoCoding2 from "./NtaiGeoCoding2";

const mapdata2 = [
  {
    query: "Mumbai, India",
    count: 100,
    total: 200,
    color: 85,
    limit: 1,
  },
  {
    query: "New Delhi",
    count: 200,
    total: 400,
    color: 80,
    limit: 1,
  },
  {
    query: "France",
    count: 500,
    total: 1000,
    color: 95,
    limit: 1,
  },
  {
    query: "New york",
    count: 200,
    total: 200,
    color: 90,
    limit: 1,
  },
  {
    query: "UK",
    count: 1000,
    total: 100,
    color: 75,
    limit: 1,
  },
  {
    query: "Germany",
    count: 700,
    total: 700,
    color: 99,
    limit: 1,
  },
];

export default function NtaiTestGeoMap() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);
  console.log("Filtered: ", filtered);
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        p: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex" }}>
          <Button onClick={() => setFiltered(!filtered)}>TOGGLE FILTER</Button>
        </Box>

        <NtaiGeoMap
          width="100%"
          height="100%"
          data={mapdata2}
          metadata={{
            filterFg: filtered,
            fields: {
              fieldUuId1: {
                name: "field1",
                fieldOrder: 1,
                field: { uuId: "fieldUuId1", label: "Country", geo_loc_fg: 1 },
              },
              fieldUuId2: {
                name: "field2",
                fieldOrder: 2,
                field: { uuId: "fieldUuId2", label: "Volume", geo_siz_fg: 1 },
              },
              fieldUuId3: {
                name: "field3",
                fieldOrder: 3,
                field: {
                  uuId: "fieldUuId3",
                  label: "Compliance %",
                  color_fg: 1,
                },
              },
            },
          }}
        />
        {/* <NtaiGeoCoding2 data={mapdata2} /> */}
      </Box>
    </Box>
  );
}
