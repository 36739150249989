import { faArchive, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import { treeItemClasses } from "@mui/lab/TreeItem";
import { Box, IconButton, Typography, styled, useTheme } from "@mui/material";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import React from "react";
const _ = require("lodash");

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      //   fontWeight: 700,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
      fontWeight: 700,
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

export default function NtaiSourceHierarchyTree(props) {
  const { data, idField, nameField, handleSelect, handleDelete } = props;
  const theme = useTheme();

  function generateLabel(node) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: theme.spacing(0.5),
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight={node.selectedFg === 1 ? 700 : 500}
        >
          {node[nameField]}
        </Typography>

        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <IconButton size="small" onClick={() => handleSelect(node[idField])}>
            <FontAwesomeIcon
              size="xs"
              color={node.selectedFg === 1 ? "orange" : null}
              icon={node.selectedFg === 1 ? faStar : faCheck}
            />
          </IconButton>
          <IconButton size="small" onClick={() => handleDelete(node[idField])}>
            <FontAwesomeIcon size="xs" icon={faArchive} />
          </IconButton>
        </Box>
      </Box>
    );
  }

  const renderTree = (nodes) => (
    <StyledTreeItemRoot
      key={nodes[idField]}
      nodeId={nodes[idField]}
      label={generateLabel(nodes)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItemRoot>
  );

  return (
    <Box
      sx={{
        p: theme.spacing(1),
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <TreeView
        aria-label="tree view"
        defaultCollapseIcon={<ExpandMore />}
        defaultExpanded={[data[idField]]}
        defaultExpandIcon={<ChevronRight />}
        sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
      >
        {renderTree(data)}
      </TreeView>
    </Box>
  );
}
