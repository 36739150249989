import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createWidget, clearStatus } from "./store/widgetsSlice";
import WidgetForm from "./WidgetForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";

export default function WidgetCreate() {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.core.widgetsSlice.status);
  const activeId = useSelector((state) => state.core.widgetsSlice.activeId);

  function handleSave(formData) {
    dispatch(createWidget(formData));
  }

  useEffect(() => {
    if (status.method === "createWidget" && status.result === "success") {
      history.push(`/admin/widgets`);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Widget">
      <NtaiForm>
        <WidgetForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
