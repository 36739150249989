import { Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAwsClusters } from "app/main/pages/core/admin/aws/cluster/store/awsClustersSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
// import {
//   clearStatus,
//   startThesaurusAwsSession,
// } from "./store/thesaurusAwsSessionsSlice";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  clearStatus,
  getThesaurus,
  startOrTerminateThesaurusAwsSession,
} from "../../store/thesaurusesSlice";
const _ = require("lodash");

export default function ThesaurusAwsSession() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sessionCreationPending, setSessionCreationPending] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const thesaurusId = useSelector(
    (state) => state.core.thesaurusesSlice.activeId
  );

  const thesaurusDatamap = useSelector(
    (state) => state.core.thesaurusesSlice.data
  );

  const thesaurusData =
    thesaurusDatamap && !_.isEmpty(thesaurusDatamap)
      ? thesaurusDatamap[thesaurusId]
      : null;

  const awsEnv = thesaurusData["awsEnv"];
  const awsLivySession = thesaurusData["activeSession"];

  const status = useSelector((state) => state.core.thesaurusesSlice.status);

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  function handleFormSubmit() {
    dispatch(
      startOrTerminateThesaurusAwsSession({
        thesaurusId: thesaurusId,
        formData: { actionCode: awsEnv && !awsLivySession ? 1 : 2 },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "error" &&
      status.method === "startOrTerminateThesaurusAwsSession"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      setSessionCreationPending(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "startOrTerminateThesaurusAwsSession"
    ) {
      dispatch(getThesaurus(thesaurusId));
      handleDialogClose();
      setSessionCreationPending(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "pending" &&
      status.method === "startOrTerminateThesaurusAwsSession"
    ) {
      setSessionCreationPending(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getAwsClusters());
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: theme.spacing(1),
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {awsEnv && (
          <Button
            sx={{ fontSize: "12px" }}
            disabled={sessionCreationPending ? true : false}
            startIcon={
              sessionCreationPending && <NtaiCircularProgress size={18} />
            }
            size="small"
            onClick={() => handleFormSubmit()}
          >
            {sessionCreationPending
              ? "SESSION UPDATING"
              : awsEnv && !awsLivySession
              ? "START SESSION"
              : "TERMINATE SESSION"}
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
