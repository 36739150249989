import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

export default function WorkflowDefVersionActivityGeneralForm({
  handleSave,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const dataMap = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.data
  );
  const status = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.status
  );

  const activityId = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.activeId
  );

  const formData = activityId && dataMap ? dataMap[activityId] : {};

  useEffect(() => {
    reset({ workflowDefVersionActivityGeneralForm: formData });
  }, [activityId]);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("workflowDefVersionActivityGeneralForm")
    );

    //console.log("sanitizedFormData: ", sanitizedFormData);
    handleSave(sanitizedFormData);
  }
  return (
    <NtaiFormPanel
      header="General"
      subheader="Edit general information for workflow activity"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="workflowDefVersionActivityGeneralForm.name"
        label="Name"
        rules={{ required: "Name is required" }}
      />

      <NtaiTextField
        name="workflowDefVersionActivityGeneralForm.comments"
        label="Description"
        multiline
        minRows={3}
        maxRows={3}
      />

      <NtaiTextField
        name="workflowDefVersionActivityGeneralForm.durationDays"
        type="number"
        label="Duration (days)"
        rules={{ required: "Duration is required" }}
      />
    </NtaiFormPanel>
  );
}
