import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceAdjudications = createAsyncThunk(
  "sourceAdjudications/getSourceAdjudications",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceAdjudication = createAsyncThunk(
  "sourceAdjudications/getSourceAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceAdjudication = createAsyncThunk(
  "sourceAdjudications/createSourceAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceAdjudication = createAsyncThunk(
  "sourceAdjudications/updateSourceAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceAdjudication = createAsyncThunk(
  "sourceAdjudications/deleteSourceAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceAdjudicationsSlice = createSlice({
  name: "sourceAdjudications",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceAdjudications.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceAdjudications" };
    },
    [getSourceAdjudications.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceAdjudications",
        message: action.payload.message,
      };
    },
    [getSourceAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceAdjudication" };
    },
    [getSourceAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceAdjudication",
        message: action.payload.message,
      };
    },
    [createSourceAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSourceAdjudication" };
    },
    [createSourceAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceAdjudication",
        message: action.payload.message,
      };
    },
    [updateSourceAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceAdjudication" };
    },
    [updateSourceAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceAdjudication",
        message: action.payload.message,
      };
    },
    [deleteSourceAdjudication.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceAdjudication" };
    },
    [deleteSourceAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceAdjudication",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceAdjudicationsSlice.actions;

export default sourceAdjudicationsSlice.reducer;
