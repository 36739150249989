import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  updateSourceLinkedSource,
} from "./store/sourceLinkedSourcesSlice";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import SourceLinkedSourceForm from "./SourceLinkedSourceForm";
import { getLinkedSourceFields } from "./field/store/sourceLinkedSourceFieldsSlice";
const _ = require("lodash");

export default function SourceLinkedSourceEdit(props) {
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.data
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceLinkedSource({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: formData,
      })
    );
  }

  function handleCancel() {
    history.push(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
    dispatch(
      getLinkedSourceFields({
        sourceDefId: _.get(formData, "linkedSourceDefUuId"),
        sourceId: _.get(formData, "linkedSourceUuId"),
      })
    );
  }, []);

  useEffect(() => {
    if (
      status &&
      status.method === "getLinkedSourceFields" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
    }

    if (
      status.method === "updateSourceLinkedSource" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit Linked Source`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`}
    >
      <NtaiForm>
        <SourceLinkedSourceForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
