import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { gridConfig, tooltipConfig } from "../chartConfig";
import { Box } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: {
    containLabel: true,
  },
  legend: [],
  tooltip: {
    ...tooltipConfig,
  },
  labelLayout: {
    hideOverlap: true,
  },
  series: [
    {
      type: "graph",
      layout: "circular",
      circular: {
        rotateLabel: true,
      },
      cursor: "default",
      animation: false,
      label: {
        show: true,
        position: "right",
        formatter: "{b}",
        fontSize: 10,
      },
      lineStyle: {
        color: "source",
        curveness: 0.3,
      },
      data: [],
      categories: [],
      links: [],
    },
  ],
};

function interpolate(old_value, old_min, old_max, new_min, new_max) {
  return (
    ((old_value - old_min) / (old_max - old_min)) * (new_max - new_min) +
    new_min
  );
}

export default function NtaiCircularDependencyEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(params) {
    let sourceWidgetFilterFields = [];
    if (params["dataType"] === "node") {
      sourceWidgetFilterFields.push({
        sourceFieldUuId: NtaiEChartUtil.getSourceFieldUuId(
          metadata,
          params["data"]["category"] + 1
        ),
        fieldOperatorCode: 1,
        fieldValue: params["data"]["name"],
      });
    } else {
      sourceWidgetFilterFields.push({
        sourceFieldUuId: NtaiEChartUtil.getSourceFieldUuId(
          metadata,
          parseInt(params["data"]["source"]) + 1
        ),
        fieldOperatorCode: 1,
        fieldValue: params["data"]["name"],
      });
      sourceWidgetFilterFields.push({
        sourceFieldUuId: NtaiEChartUtil.getSourceFieldUuId(
          metadata,
          parseInt(params["data"]["source"]) + 1
        ),
        fieldOperatorCode: 1,
        fieldValue: params["data"]["name"],
      });
    }
  }

  function onChartClick(params) {
    console.log("Params: ", metadataRef.current, params);
    //handleClick(getSourceWidgetFilterFields(params));
  }

  function onHandleContextMenu(action, params) {
    console.log("R params: ", metadataRef.current, params);
    //handleContextMenu(action, getSourceWidgetFilterFields(params));
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);

    const colors = NtaiEChartUtil.getColorPalette(
      theme,
      data["categories"].length
    );

    optionCopy["color"] = colors;
    optionCopy["legend"] = [
      {
        type: "scroll",
        icon: "circle",
        orient: "vertical",
        align: "right",
        top: "center",
        right: "right",
        textStyle: {
          fontSize: 10,
        },
        data: data.categories.map(function (a) {
          return a.name;
        }),
      },
    ];
    optionCopy["series"][0]["data"] = data["nodes"].map((node, i) => {
      return {
        ...node,
        itemStyle: {
          color: colors[node["id"]],
        },
        symbolSize: interpolate(
          node["value"],
          data["node_ranges"][0],
          data["node_ranges"][1],
          5,
          20
        ),
      };
    });
    optionCopy["series"][0]["categories"] = data["categories"];
    optionCopy["series"][0]["edges"] = data["links"];

    setOption(optionCopy);
  }, [data, metadata]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"][0]["data"] && chart}
    </Box>
  );
}
