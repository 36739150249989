import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createSourcePolicyDef,
  deleteSourcePolicyDef,
  getSourcePolicyDefs,
  updateSourcePolicyDef,
} from "./store/sourcePolicyDefsSlice";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import SourcePolicyDefForm from "./SourcePolicyDefForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "30%",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "25%",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "15%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    width: "15%",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourcePolicyDefList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState("list");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [open, setOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = sourceDefDataMap[sourceDefId];

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourcePolicyDefsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourcePolicyDefsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteSourcePolicyDef({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    if (action === "add")
      dispatch(
        createSourcePolicyDef({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateSourcePolicyDef({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  function handleCancel() {
    setAction("list");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourcePolicyDefs" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSourcePolicyDef", "updateSourcePolicyDef"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(`Successfully saved source policy definition`, {
        variant: "success",
      });
    }

    if (
      status &&
      status.method === "deleteSourcePolicyDef" &&
      status.result === "success"
    ) {
      setOpenConfirmDeleteDialog(false);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourcePolicyDefs({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiPanel
          width="100%"
          header="Policy Definitions"
          subheader="Define and manage source assessment policy definitions. Each policy definition has set of fields based on which source records are grouped."
        >
          {action === "list" && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              selectedIds={selectedIds}
              handleDialogAddEdit={handleAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Policy Definition"
            />
          )}
          {["add", "edit"].includes(action) && (
            <NtaiForm>
              {(action === "add" || (action === "edit" && currentId)) && (
                <SourcePolicyDefForm
                  action={action}
                  formData={action === "edit" ? dataMap[currentId] : {}}
                  handleFormSubmit={handleFormSubmit}
                  handleCancel={handleCancel}
                />
              )}
            </NtaiForm>
          )}
        </NtaiPanel>
      )}
      {!loaded && <NtaiCircularProgress />}

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
