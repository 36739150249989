import MigrationList from "./MigrationList";

const MigrationConfig = {
  routes: [
    {
      path: "/migrations",
      exact: true,
      component: MigrationList,
    },
  ],
};

export default MigrationConfig;
