import { Box, Button, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import {
  getObject,
  clearStatus as clearObjectsSliceStatus,
} from "app/main/pages/core/cdo/object/store/objectsSlice";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faHistory,
  faLock,
  faMemo,
  faPlus,
  faSave,
} from "@fortawesome/pro-regular-svg-icons";
import {
  createSearchSourceRecordAdjudication,
  getSearchSourceRecordAdjudications,
  updateSearchSourceRecordAdjudication,
  clearStatus,
  setActiveId,
} from "./store/searchSourceRecordAdjsSlice";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import { SearchSourceContext } from "../../../SearchSource";
import { useSnackbar } from "notistack";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { getSearchSourceRecordsSummary } from "../../store/searchSourceRecordsSlice";
import { SRC_SOURCE_RECORD_SUMMARY } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

export default function SearchSourceRecordAdj({ adjObjectUuId, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [adjObjectLoaded, setAdjObjectLoaded] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const searchDataMap = useSelector((state) => state.search.searchSlice.data);
  const searchId = useSelector((state) => state.search.searchSlice.activeId);
  const searchData = _.get(searchDataMap, searchId);

  const { selectedRecord, setRefreshSelectedRecord } = React.useContext(
    SearchSourceRecordListContext
  );
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const recordId = _.get(selectedRecord, "recordId");

  const selectedSearchSourceRecordUuId =
    selectedRecord &&
    selectedRecord["uuId"] &&
    selectedRecord["uuId"].length > 0
      ? selectedRecord["uuId"]
      : "*";

  const objectDataMap = useSelector((state) => state.core.objectsSlice.data);
  const objectsSliceStatus = useSelector(
    (state) => state.core.objectsSlice.status
  );

  const adjObject =
    !_.isEmpty(objectDataMap) && adjObjectUuId && adjObjectUuId.length > 0
      ? _.get(objectDataMap, adjObjectUuId)
      : null;

  const searchSourceRecordAdjDataMap = useSelector(
    (state) => state.search.searchSourceRecordAdjsSlice.data
  );

  const searchSourceRecordAdjData =
    searchSourceRecordAdjDataMap && !_.isEmpty(searchSourceRecordAdjDataMap)
      ? Object.values(searchSourceRecordAdjDataMap)[0]
      : null;

  console.log("searchSourceRecordAdjData: ", searchSourceRecordAdjData);

  const searchSourceRecordAdjPresentFg =
    _.has(searchSourceRecordAdjData, "adjudication.uuId") &&
    searchSourceRecordAdjData["adjudication"]["uuId"] &&
    searchSourceRecordAdjData["adjudication"]["uuId"].length > 0
      ? true
      : false;

  const status = useSelector(
    (state) => state.search.searchSourceRecordAdjsSlice.status
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(getValues("adjForm"));
    const revisedFormData = ObjectFormUtil.generateFormSubmissionData(
      sanitizedFormData,
      adjObject
    );

    const parentProps = {
      searchId: searchId,
      searchSourceId: searchSourceId,
      searchSourceRecordId: selectedSearchSourceRecordUuId,
    };

    const finalFormData = {
      adjVersion: 1,
      approvedFg: "Y",
      recordId: recordId,
      adjudication: {
        moduleCode: 1,
        clientData: {
          uuId:
            searchSourceRecordAdjData &&
            searchSourceRecordAdjData["adjudication"]["clientData"] &&
            _.has(
              searchSourceRecordAdjData["adjudication"]["clientData"],
              "uuId"
            ) &&
            searchSourceRecordAdjData["adjudication"]["clientData"]["uuId"]
              .length > 0
              ? _.get(searchSourceRecordAdjData, "adjudication.clientData.uuId")
              : null,
          objectUuId: adjObjectUuId,
          ...revisedFormData,
        },
      },
    };

    if (searchSourceRecordAdjPresentFg) {
      finalFormData["adjudication"]["uuId"] =
        searchSourceRecordAdjData["adjudication"]["uuId"];

      dispatch(
        updateSearchSourceRecordAdjudication({
          ...parentProps,
          uuId: searchSourceRecordAdjData["uuId"],
          formData: finalFormData,
        })
      );
    } else {
      dispatch(
        createSearchSourceRecordAdjudication({
          ...parentProps,
          formData: finalFormData,
        })
      );
    }
  }

  useEffect(() => {
    if (
      recordId &&
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordAdjudications"
    ) {
      if (searchSourceRecordAdjData) {
        reset({
          adjForm: ObjectFormUtil.populateFormData(
            _.get(searchSourceRecordAdjData, "adjudication.clientData")
          ),
        });
      } else {
        reset({ adjForm: {} });
      }

      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createSearchSourceRecordAdjudication" ||
        status.method === "updateSearchSourceRecordAdjudication")
    ) {
      enqueueSnackbar("Record updated successfully.", {
        variant: "success",
      });

      reset({
        adjForm: _.get(searchSourceRecordAdjData, "adjudication.clientData")
          ? ObjectFormUtil.populateFormData(
              _.get(searchSourceRecordAdjData, "adjudication.clientData")
            )
          : {},
      });

      dispatch(clearStatus());
      setEditMode(false);
      setRefreshSelectedRecord({
        summaryType: SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_ADJS,
        recordIds: [recordId],
        adjObjectUuId: adjObjectUuId,
      });
    }
  }, [status]);

  useEffect(() => {
    if (recordId) {
      dispatch(
        getSearchSourceRecordAdjudications({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          formData: {
            moduleCode: 1,
            globalFg: 1,
            recordId: recordId,
            adjudication: {
              clientData: {
                objectUuId: adjObjectUuId,
              },
            },
          },
        })
      );
    }
  }, [recordId]);

  useEffect(() => {
    if (
      objectsSliceStatus &&
      objectsSliceStatus.result === "success" &&
      objectsSliceStatus.method === "getObject"
    ) {
      setAdjObjectLoaded(true);
      dispatch(clearObjectsSliceStatus());
    }
  }, [objectsSliceStatus]);

  useEffect(() => {
    dispatch(
      getObject({
        domainId: "*",
        uuId: adjObjectUuId,
      })
    );
  }, [adjObjectUuId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "1px",
          width: "100%",
          justifyContent: "end",
          alignItems: "center",
          paddingRight: theme.spacing(2),
          gap: theme.spacing(1),
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: `calc(100% - 3px)`,
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {adjObject && !_.isEmpty(adjObject["objectForm"]) && loaded && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {(searchSourceRecordAdjPresentFg || editMode) && (
              <Scrollbar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: theme.spacing(2),
                    p: theme.spacing(2),
                  }}
                >
                  {ObjectFormUtil.generateForm(
                    "adjForm",
                    adjObject["objectForm"],
                    editMode,
                    theme
                  )}
                </Box>
              </Scrollbar>
            )}

            {!searchSourceRecordAdjPresentFg && !editMode && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <NtaiEmptyMessage
                  vAlign="center"
                  header="Assessment data not available"
                  subheader="Click on + CREATE button to add a new assessment"
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                height: "58px",
                width: "100%",
                alignItems: "center",
                paddingRight: theme.spacing(2),
                gap: theme.spacing(1),
                justifyContent: "end",
                borderTop: theme.general.border1,
              }}
            >
              {editMode && (
                <Button size="small" onClick={() => setEditMode(false)}>
                  CANCEL
                </Button>
              )}

              {!editMode && (
                <Button size="small" onClick={handleCancel}>
                  CANCEL
                </Button>
              )}

              <Button
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                disabled={
                  !loaded ||
                  (status && status.result === "pending") ||
                  (searchData["lockedFg"] && searchData["lockedFg"] === 1)
                    ? true
                    : false
                }
                variant={
                  status && status.result === "pending"
                    ? "outlined"
                    : "contained"
                }
                size="small"
                onClick={() => (editMode ? handleSave() : setEditMode(true))}
              >
                {status && status.result === "pending" ? (
                  <NtaiCircularProgress size={16} />
                ) : (
                  <FontAwesomeIcon
                    icon={
                      searchData["lockedFg"] && searchData["lockedFg"] === 1
                        ? faLock
                        : editMode
                        ? faSave
                        : searchSourceRecordAdjPresentFg
                        ? faEdit
                        : faPlus
                    }
                  />
                )}
                {editMode
                  ? "SAVE"
                  : searchSourceRecordAdjPresentFg
                  ? "EDIT"
                  : "CREATE"}
              </Button>
            </Box>
          </Box>
        )}
        {(!loaded || !adjObjectLoaded) && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NtaiCircularProgress size={24} />
          </Box>
        )}

        {adjObjectLoaded && (!adjObject || !adjObject["objectForm"]) && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <NtaiEmptyMessage
              vAlign="center"
              header="Assessment form not available"
              subheader="No assessment form has been configured for the selected source"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
