import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import NtaiDashboardWidget from "../NtaiDashboardWidget";

export default function NtaiDashboardList1Widget({ data }) {
  const theme = useTheme();

  function generateItems(data) {
    const result = [];

    data.forEach((d, i) => {
      result.push(
        <React.Fragment key={i}>
          <ListItemButton>
            <ListItemAvatar>
              <Avatar sx={{ width: 32, height: 32 }}>{d.avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle1" fontWeight="700">
                  {d.primary}
                </Typography>
              }
              secondary={d.secondary}
            />
          </ListItemButton>
          <Divider />
        </React.Fragment>
      );
    });

    return result;
  }

  return (
    <Scrollbar>
      <List
        sx={{
          p: 0,
          border: theme.general.border1,
        }}
      >
        {generateItems(data)}
      </List>
    </Scrollbar>
  );
}
