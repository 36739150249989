import SourceObjectList from "./SourceObjectList";

const SourceObjectConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/objects",
      exact: true,
      component: SourceObjectList,
    },
  ],
};

export default SourceObjectConfig;
