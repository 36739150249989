import React from "react";
import { useFormContext, Controller, useForm } from "react-hook-form";
import NtaiLabelValue from "../micros/NtaiLabelValue";
const _ = require("lodash");

function fetchValue(value, options) {
  if (value) {
    if (_.isArray(options) && options.length > 0) {
      if (_.isString(value) && value.length > 0) {
        // multi select
        console.log("Object form: field value: ", value);
        if (value.startsWith("[") && value.endsWith("[")) {
          console.log("Yes it does..");
          value = _.trim(_.trim(value, "["), "]");
          console.log("value: ", value);
        }

        console.log("Value: ", value);

        const optionArrString = value.split(",");
        console.log("Object form: field optionArrString: ", optionArrString);
        if (_.isArray(optionArrString) && optionArrString.length > 0) {
          const optionArr = optionArrString.map((s, i) => {
            return 1 * s;
          });

          if (_.isArray(optionArr) && optionArr.length > 0) {
            const selectedLabels = optionArr.map((o, i) => {
              const selectedOption = _.filter(options, { value: o });
              if (selectedOption.length > 0) return selectedOption[0]["label"];
            });

            if (selectedLabels.length > 0) {
              return selectedLabels.join(", ");
            }
          }
        }
      } else if (_.isNumber(value)) {
        // single select
        const selectedOption = _.filter(options, { value: value });
        if (selectedOption.length > 0) return selectedOption[0]["label"];
      }
    } else {
      return value;
    }
  }
}
const NtaiLabel = (props) => {
  const { name, label, value, options } = props;

  return <NtaiLabelValue label={label} value={fetchValue(value, options)} />;
};

function NtaiFormLabel(props) {
  const { control } = useFormContext();

  return (
    <React.Fragment>
      <Controller
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => <NtaiLabel value={value || ""} {...props} />}
        control={control}
        defaultValue=""
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiFormLabel;
