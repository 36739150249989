import { faStaff } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiDecoMenu from "@ntai/components/menus/NtaiDecoMenu";
import React from "react";
import SearchPublish from "./publish/SearchPublish";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";

export default function SearchActionMore() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItem, setMenuItem] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  function handleMenuOpen(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleMenuItemSelect(menuItemValue) {
    setMenuItem(menuItemValue);
    handleDialogOpen();
  }

  function handleDialogOpen(e) {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  return (
    <React.Fragment>
      <IconButton onClick={(e) => handleMenuOpen(e)}>
        <FontAwesomeIcon size="2xs" icon={faEllipsisV} />
      </IconButton>
      <NtaiDecoMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleMenuClose}
        items={[
          {
            label: "Publish",
            value: "publish",
          },
          {
            label: "Messages",
            value: "messages",
          },
          {
            label: "Print",
            value: "print",
          },
          {
            label: "Logs",
            value: "logs",
          },
        ]}
        handleMenuItemSelect={handleMenuItemSelect}
      />
      {menuItem === "publish" && (
        <NtaiDialog
          title="Publish Search"
          open={dialogOpen}
          handleDialogClose={handleDialogClose}
          size="sm"
        >
          <NtaiForm>
            <SearchPublish />
          </NtaiForm>
        </NtaiDialog>
      )}
    </React.Fragment>
  );
}
