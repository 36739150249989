import {
  Box,
  Button,
  Divider,
  IconButton,
  useTheme,
  Typography,
} from "@mui/material";
import { getCodelists } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiMuiAutoSuggestTF1 from "./NtaiMuiAutoSuggestTF1";
import axios from "axios";
import NtaiMuiAutoSuggestIB from "./NtaiMuiAutoSuggestIB";
import NtaiAutoSuggestIB from "../inputs/NtaiAutoSuggestIB";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faExpand } from "@fortawesome/pro-regular-svg-icons";
import NtaiPopover from "../popover/NtaiPopover";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiAutoSuggestTF from "../inputs/NtaiAutoSuggestTF";
import AutoSuggestTest from "../inputs/AutoSuggestTest";
import NtaiAutoSuggestTest2 from "../inputs/NtaiAutoSuggestTest2";
import server from "../../../app/http/ntaiServer";

const _ = require("lodash");

const items1 = [
  {
    id: 0,
    name: "Cobol",
  },
  {
    id: 1,
    name: "JavaScript",
  },
  {
    id: 2,
    name: "Basic",
  },
  {
    id: 3,
    name: "PHP",
  },
  {
    id: 4,
    name: "Java",
  },
];

const top100Films = [
  { name: "The Shawshank Redemption", id: 1 },
  { name: "The Godfather", id: 2 },
  { name: "The Godfather: Part II", id: 3 },
];

export default function TestNtaiAutoSuggest() {
  const theme = useTheme();
  const [openSourceMenu, setOpenSourceMenu] = useState(false);
  const [anchorElSourceMenu, setAnchorElSourceMenu] = useState(null);

  const handleSourceMenu = (event) => {
    setOpenSourceMenu(true);
    setAnchorElSourceMenu(event.currentTarget);
  };
  const handleSourceMenuClose = () => {
    setOpenSourceMenu(false);
    setAnchorElSourceMenu(null);
  };

  const clickAwayHandler = () => {
    setOpenSourceMenu(false);
  };

  //   const [items, setItems] = useState(items1);
  const [inputValue, setInputValue] = useState("RTY");
  const [options, setOptions] = useState(top100Films);
  const dispatch = useDispatch();

  const { getValues } = useFormContext();

  console.log("options: ", options);
  const codelistDataMap = useSelector(
    (state) => state.core.codelistsSlice.data
  );

  const data = Object.values(codelistDataMap);

  // const items =
  //   Array.isArray(data) && data.length > 0
  //     ? _.map(data, function (o) {
  //         return { id: o.uuId, name: o.name };
  //       })
  //     : [];

  const getOptionsAsync = (query) => {
    console.log("query: ", query);
    return new Promise((resolve) => {
      setTimeout(() => {
        server
          .get("/codelists", { params: { name: query } })
          .then((response) => {
            resolve(response.data);
          });
      }, 300);
    });
  };

  const getOptionsDelayed = useCallback(
    _.debounce((text, callback) => {
      setOptions([]);
      getOptionsAsync(text).then(callback);
    }, 300),
    []
  );

  function handleSearch(string, results) {
    dispatch(getCodelists({ name: string }));
  }
  function handleSelect(item) {}

  function handleInputChange(str) {
    setInputValue(str);
  }

  function handleChange(t2) {}

  function getAutoSugestValue() {
    console.log("Auto suggest value: ", getValues("ntaiautosuggest"));
  }

  // useEffect(() => {
  //   // setItems(
  //   //   _.map(data, function (o) {
  //   //     return { id: o.uuId, name: o.name };
  //   //   })
  //   // );
  // }, [data]);

  useEffect(() => {
    console.log("Input value: ", inputValue);
    if (inputValue && inputValue.length > 0) {
      getOptionsDelayed(inputValue, (filteredOptions) => {
        setOptions(filteredOptions);
        console.log("Fire for ", inputValue);
        //console.log("Filtered options: ", filteredOptions);
      });
    } else {
      setOptions([]);
    }
  }, [inputValue, getOptionsDelayed]);

  return (
    <>
      <Box
        sx={{
          p: theme.spacing(2),
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiAutoSuggestTest2 />
        <Box sx={{ width: "50%" }}>
          <AutoSuggestTest
            options={options}
            name="autoSuggestForm.i0"
            optionLabelField="name"
            defaultValue={[{ name: "Option1", id: 1 }]}
            label="Search"
            handleInputChange={handleInputChange}
            handleChange={handleChange}
          />
        </Box>

        <Box sx={{ width: "50%" }}>
          THIS IS IT
          <NtaiAutoSuggestIB
            options={options}
            name="autoSuggestForm.i0"
            optionLabelField="name"
            defaultValue={[{ name: "Option1", id: 1 }]}
            label="Search"
            handleInputChange={handleInputChange}
            handleChange={handleChange}
            endAdornments={
              <>
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faExpand} />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={(e) => handleSourceMenu(e)}>
                  <FontAwesomeIcon size="2xs" icon={faBars} />
                </IconButton>
              </>
            }
          />
        </Box>

        <Box sx={{ width: "50%" }}>
          <NtaiAutoSuggestTF
            multiline={false}
            options={options}
            name="autoSuggestForm.i0"
            optionLabelField="name"
            defaultValue={[{ name: "Option1", id: 1 }]}
            label="Search"
            handleInputChange={handleInputChange}
            handleChange={handleChange}
            endAdornments={
              <>
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faExpand} />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={(e) => handleSourceMenu(e)}>
                  <FontAwesomeIcon size="2xs" icon={faBars} />
                </IconButton>
              </>
            }
          />
        </Box>

        <NtaiMuiAutoSuggestIB
          options={options}
          name="autoSuggestForm.i1"
          optionLabelField="name"
          defaultValue={[{ name: "Option1", id: 1 }]}
          label="Search"
          handleInputChange={handleInputChange}
          handleChange={handleChange}
        />
        <NtaiMuiAutoSuggestTF1
          options={options}
          name="autoSuggestForm.i2"
          label="Search"
          optionLabelField="name"
          handleInputChange={handleInputChange}
          handleChange={handleChange}
        />
        <Button onClick={() => getAutoSugestValue()}>Get Value</Button>
      </Box>

      {openSourceMenu && (
        <NtaiPopover
          open={openSourceMenu}
          anchorEl={anchorElSourceMenu}
          handleClose={handleSourceMenuClose}
          clickAwayHandler={clickAwayHandler}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: theme.spacing(2),
              gap: theme.spacing(1),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <NtaiCheckboxField name="check1" label="Clinical Trials" />
              <NtaiCheckboxField name="check2" label="Genes" />
              <NtaiCheckboxField name="check3" label="FDA FAERS" />
              <NtaiCheckboxField name="check4" label="PubMed" />
            </Box>
            <Box
              sx={{
                pt: theme.spacing(1),
                display: "flex",
                justifyContent: "end",
                gap: theme.spacing(1),
                borderTop: theme.general.border1,
              }}
            >
              <Button size="small">Close</Button>
              <Button size="small" variant="contained">
                Apply
              </Button>
            </Box>
          </Box>
        </NtaiPopover>
      )}
    </>
  );
}
