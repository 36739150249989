import React from "react";
import {
  Avatar,
  Divider,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { faStaff } from "@fortawesome/pro-regular-svg-icons";
import NtaiIcons from "utils/NtaiIcons";

export default function NtaiDecoMenu(props) {
  const theme = useTheme();
  const { open, anchorEl, handleClose, header, items, handleMenuItemSelect } =
    props;

  function generateMenuItems() {
    let menuItems = [];
    items &&
      Array.isArray(items) &&
      items.length > 0 &&
      items.forEach((item, index) => {
        menuItems.push(
          <MenuItem
            sx={{
              px: theme.spacing(2),
            }}
            key={`ntai-menu-item-${index}`}
            onClick={() => handleMenuItemSelect(item.value)}
          >
            {item.icon && (
              <Avatar>
                <FontAwesomeIcon icon={NtaiIcons[item.icon]} />
              </Avatar>
            )}
            {item.label}
          </MenuItem>
        );
      });

    return menuItems;
  }

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        id="ntai-deco-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            p: theme.spacing(1),
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {header ? header : null}
        {generateMenuItems()}
      </Menu>
    </React.Fragment>
  );
}
