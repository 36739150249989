import React, { useEffect, useMemo, useState } from "react";
import { Box, lighten, useTheme } from "@mui/material";
import {
  gridConfig,
  legendConfig,
  tooltipConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import { grey } from "@mui/material/colors";
import { xAxisLabelConfig, xAxisLineConfig } from "./barEChartConfig";
import NtaiBarEChartUtil, {
  barGridConfig,
  vXAxisLabelConfig,
  vXAxisLineConfig,
} from "./util/NtaiBarEChartUtil";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: {
    containLabel: true,
    left: 20,
    top: 40,
    right: 20,
    bottom: 20,
  },
  legend: legendConfig,
  tooltip: {
    ...tooltipConfig,
    formatter: function (params) {
      return NtaiBarEChartUtil.getTooltipMultiSeries(params);
    },
  },
  xAxis: {
    type: "category",
    data: null,
    axisLine: vXAxisLineConfig,
    axisLabel: vXAxisLabelConfig,
  },
  yAxis: {
    type: "value",
    axisLabel: yAxisLabelConfig,
  },
  series: null,
};

export default function NtaiVerticalBarSeriesEChart({
  widgetId,
  data,
  metadata,
  handleClick,
  handleContextMenu,
}) {
  const theme = useTheme();
  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(action, params) {
    return metadataRef.current && !_.isEmpty(metadataRef.current)
      ? NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
          [1, 2],
          params,
          metadataRef.current,
          action
        )
      : [];
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params));
  }

  function onHandleContextMenu(action, params) {
    //console.log("Clicked right: ", action, params);
    handleContextMenu(action, getSourceWidgetFilterFields(action, params));
  }

  function processValues(data, color) {
    let result = {};
    result["_filterFg"] = metadata["filterFg"];
    result["value"] = data["total"];
    result["_actual"] = metadata["filterFg"] ? data["value"] : data["total"];

    const revisedColor = data.hasOwnProperty("colorValue")
      ? NtaiEChartUtil.getItemStyleColor(
          "bg",
          data["colorValue"] && data["colorValue"].length > 0
            ? data["colorValue"]
            : data["value"],
          color,
          metadata
        )
      : color;

    result["_color"] = revisedColor;
    result["itemStyle"] = NtaiBarEChartUtil.getItemStyle(
      data,
      metadata,
      revisedColor
    );

    return result;
  }

  useEffect(() => {
    if (_.has(data, "series") && data["series"]["data"].length > 0) {
      const colors = NtaiEChartUtil.getColorPalette(
        theme,
        data["series"]["data"].length
      );
      const tmpCategories = data["category"]["name"];
      const tmpSubCategories = data["series"]["data"].map((d, i) => {
        let subcats = [];
        data["data"].map((d1, j) => {
          const subcats1 = _.filter(d1["series"], function (v) {
            return v.name === d;
          });
          subcats.push(processValues(subcats1[0]["data"], colors[i], "v"));
        });

        return {
          name: d,
          type: "bar",
          data: subcats,
          cursor: "default",
          label: {
            show: true,
            position: "top",
            formatter: NtaiBarEChartUtil.getVBarLabelFormatter,
          },
        };
      });

      const copyOption = _.cloneDeep(option);
      copyOption["color"] = colors;
      copyOption["xAxis"]["data"] = tmpCategories;
      copyOption["series"] = tmpSubCategories;
      setOption(copyOption);
    }
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box
      id={widgetId}
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      {option["series"] && !_.isEmpty(data) && chart}
      {(!data || data.length === 0) && <NtaiChartEmptyMessage />}
    </Box>
  );
}
