import { configureStore } from "@reduxjs/toolkit";
import createReducer from "./rootReducer";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// import storage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "root",
  storage,
  // stateReconciler: autoMergeLevel2,
};

const middlewares = [];

const persistedReducer = persistReducer(persistConfig, createReducer());

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const nextRootReducer = require("./rootReducer").default;
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}

if (process.env.NODE_ENV === "development") {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });

  middlewares.push(logger);
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

store.subscribe(() => {
  // console.log("State updated:", store.getState());
});

store.asyncReducers = {};

// Function to inject reducers dynamically
export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false; // Reducer already exists
  }
  store.asyncReducers[key] = reducer;

  // Recreate the root reducer with the new async reducer, wrapping with persistReducer
  const nextReducer = createReducer(store.asyncReducers);
  store.replaceReducer(persistReducer(persistConfig, nextReducer));

  return store;
};

export const persistor = persistStore(store);
