import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext, Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faExpand } from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

const defaultKeywords = ["and", "or", "not"];

const isKeyword = (o, keywords) => {
  return keywords.includes(o.toLowerCase().trim());
};
function NtaiMuiAutoSuggestIB(props) {
  const theme = useTheme();
  const [inputClicked, setInputClicked] = useState(false);
  const { control } = useFormContext();

  const {
    name,
    label,
    options,
    optionLabelField,
    handleInputChange,
    handleChange,
    keywords,
  } = props;

  const optionKeywords =
    keywords && Array.isArray(keywords) && keywords.length > 0
      ? keywords
      : defaultKeywords;

  const {
    transform,
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Controller
        control={control}
        name="item"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            onChange={(event, item) => {
              onChange(item);
              handleChange(item);
            }}
            multiline={false}
            multiple
            value={value}
            freeSolo
            disableClearable
            // value={value}

            options={options}
            onInputChange={(e, newInputValue) =>
              handleInputChange(newInputValue)
            }
            getOptionLabel={(item) =>
              item[optionLabelField] ? item[optionLabelField] : ""
            }
            // isOptionEqualToValue={(option, value) =>
            //   value === undefined || value === "" || option.id === value.id
            // }
            getOptionSelected={(option, value) =>
              value === undefined || value === "" || option.id === value.id
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                if (typeof option === "string" && ["(", ")"].includes(option)) {
                  return (
                    <Typography
                      sx={{ px: "4px" }}
                      variant="h3"
                      color="secondary"
                    >
                      {option}
                    </Typography>
                  );
                } else {
                  return (
                    <Chip
                      sx={{ fontWeight: "700" }}
                      variant={
                        typeof option !== "object" &&
                        isKeyword(option, optionKeywords)
                          ? "outlined"
                          : "contained"
                      }
                      size="small"
                      label={
                        typeof option === "object" &&
                        _.has(option, optionLabelField)
                          ? option[optionLabelField]
                          : isKeyword(option, optionKeywords)
                          ? option.toUpperCase()
                          : option
                      }
                      clickable={true}
                      {...getTagProps({ index })}
                    />
                  );
                }
              })
            }
            renderInput={(params) => {
              const { InputProps, ...rest } = params;

              const endAdornment = (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                      //   pr: "6px",
                    }}
                  >
                    <IconButton size="small">
                      <FontAwesomeIcon size="xs" icon={faExpand} />
                    </IconButton>
                    <Divider orientation="vertical" flexItem />
                    <IconButton size="small">
                      <FontAwesomeIcon size="xs" icon={faBars} />
                    </IconButton>
                  </Box>
                </InputAdornment>
              );

              const revisedInputProps = {
                ...InputProps,
                endAdornment: endAdornment,
              };

              return (
                <InputBase
                  {...params}
                  sx={{
                    py: "6px",
                    pl: "12px",
                    pr: "12px",
                    border: theme.general.border1,
                    borderRadius: "24px",
                    alignItems: "center",
                    "&:hover": {
                      boxShadow: theme.colors.shadows.cardSm,
                    },
                  }}
                  {...revisedInputProps}
                />
              );
            }}
          />
        )}
        {...props}
      />
    </Box>
  );
}

export default NtaiMuiAutoSuggestIB;
