import { useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AlertEditContext } from "../../AlertEdit";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getAlertRecordWorklflowActivityTransitions } from "../store/alertRecordsSlice";

export default function AlertRecordCompleteActivityDialog(props) {
  const { handleCompleteActivity, handleDialogClose } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  const { activityId } = useContext(AlertEditContext);

  const wfTransitionsDataMap = useSelector(
    (state) => state.monitor.alertRecordsSlice.wfTransitionsData
  );

  const wfTransitionOptions = NtaiUtils.getSelectOptions(
    Object.values(wfTransitionsDataMap),
    "uuId",
    "destinationActivityName"
  );

  useEffect(() => {
    dispatch(getAlertRecordWorklflowActivityTransitions(activityId));
  }, []);

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("completeActivityForm")
    );

    handleCompleteActivity(sanitizedFormData);
  }

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
      handleSaveLabel="Complete"
    >
      <NtaiSelectField
        name="completeActivityForm.wfActTransitionUuId"
        placeholder="Select Target Activity"
        label="Select activity to transition to"
        options={wfTransitionOptions || []}
        rules={{ required: "Activity to transition to is required" }}
      />
      <NtaiTextField
        name="completeActivityForm.remarks"
        placeholder="Remarks"
        label="Remarks"
        multiline
        minRows={3}
        maxRows={3}
      />
    </NtaiDialogFormPanel>
  );
}
