import * as React from "react";
import { JSONObjectType } from "./SourceJsonToTableUtils";
import JSONToTableUtils from "./SourceJsonToTableUtils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Icon,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { blue, grey } from "@mui/material/colors";
const _ = require("lodash");
const parse = require("html-react-parser");

export default function SourceJsonToTable(props) {
  const theme = useTheme();
  const { fields, data } = props;

  function fetchLabel(fieldName) {
    const field = _.filter(fields, { name: fieldName })[0];

    if (field) {
      return field.label;
    } else {
      return fieldName;
    }
  }

  function fetchLookupLinks(fieldName, fieldValue) {
    return fieldValue;
    //const field = _.filter(Object.values(fields), { name: fieldName })[0];

    // if (field && _.get(field, "lookupLinks").length > 0) {
    //   return (
    //     <Link
    //       color={blue[700]}
    //       sx={{
    //         py: "4px",
    //         borderRadius: "4px",
    //         textDecoration: "underline",
    //         "&:hover": {
    //           cursor: "pointer",
    //           color: blue[900],
    //         },
    //       }}
    //     >
    //       {fieldValue}
    //     </Link>
    //   );
    // } else {
    //   return fieldValue;
    // }
  }

  const renderObject = (obj, header, idx) => {
    const phrase = [];
    let tmp;
    const objType = JSONToTableUtils.getObjectType(obj);

    if (header && objType !== JSONObjectType.Array) {
      phrase.push(renderRowHeader(header));
    }

    switch (objType) {
      case JSONObjectType.ObjectWithNonNumericKeys:
        tmp = header ? (
          <React.Fragment>
            <Table key={`__j2t_tableObj${idx}`}>
              <TableBody key={`__j2t_bObj${idx}`}>{renderRows(obj)}</TableBody>
            </Table>
            <Divider />
          </React.Fragment>
        ) : (
          renderRows(obj)
        );
        break;
      case JSONObjectType.Array:
        tmp = header ? (
          <Accordion
            key={`__ntai_accordion_${idx}`}
            sx={{
              p: 0,
              // border: theme.general.border1,
            }}
          >
            <AccordionSummary
              sx={{
                py: 0,
                pl: "6px",
                pr: theme.spacing(2),
                background: grey[50],
              }}
              expandIcon={<ExpandMoreSharp />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "default", fontWeight: 700 }}
              >
                {_.capitalize(header)} ({obj.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table key={`__j2t_accord_details_tableArr${idx}`}>
                <TableBody key={`__j2t_accord_details_bArr${idx}`}>
                  {parseAccordArray(obj)}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ) : (
          parseArray(obj)
        );
        break;
    }
    phrase.push(tmp);
    const retval = phrase.map((p) => p);
    return header ? (
      <TableRow key={`__j2t_trObj${idx}`}>
        {renderCell({ content: retval, colspan: 2 })}
      </TableRow>
    ) : (
      retval
    );
  };

  const getCellValue = (content) => {
    const valueDisplay =
      content === true || content === false ? content.toString() : content;
    return valueDisplay;
  };

  const renderCell = (params) => {
    const { content, colspan, isHeader } = params;
    const valueDisplay = isHeader ? (
      <strong>
        <div style={{ "word-break": "break-word" }}>
          {getCellValue(content)}
        </div>
      </strong>
    ) : (
      getCellValue(content)
    );
    return (
      <TableCell
        colSpan={colspan ? colspan : 0}
        key={`__j2t_trObj${valueDisplay}`}
      >
        {valueDisplay}
      </TableCell>
    );
  };

  const renderHeader = (labels) => {
    return (
      <TableRow sx={{ width: "100%" }} key={`__j2t_trHeader`}>
        {labels.map((v) => {
          return renderCell({ content: v, isHeader: true });
        })}
      </TableRow>
    );
  };

  const renderValues = (values, width) => {
    const result = [];
    values.forEach((v, i) => {
      result.push(
        <TableRow key={`__j2t_trArrString_${i}`}>
          <TableCell sx={{ width: width ? width : "40%" }}></TableCell>
          {renderCell({ content: v })}
        </TableRow>
      );
    });

    return result;
  };

  const renderRowValues = (anArray, labels) => {
    return anArray.map((item, idx) => {
      return (
        <TableRow key={`__j2t_Arr${idx.toString()}`}>
          {labels.map((k) => {
            const isValuePrimitive =
              JSONToTableUtils.getObjectType(item[k]) ===
              JSONObjectType.Primitive;
            return isValuePrimitive
              ? renderCell({ content: item[k] })
              : renderObject(item[k], k, idx);
          })}
        </TableRow>
      );
    });
  };

  const parseArray = (anArray) => {
    const phrase = [];
    const labels = JSONToTableUtils.getUniqueObjectKeys(anArray);

    if (JSONToTableUtils.checkLabelTypes(labels.labels) !== "number") {
      phrase.push(renderHeader(labels.labels));
      phrase.push(renderRowValues(anArray, labels.labels));
    } else {
      phrase.push(renderValues(anArray, "40%"));
    }
    return phrase;
  };

  const parseAccordArray = (anArray) => {
    let result = [];

    if (anArray.length > 0) {
      let elem = anArray[0];

      if (typeof elem === "object") {
        anArray.forEach((a, i) => {
          result.push(renderObject(a));
        });
      } else {
        result.push(renderValues(anArray, "25%"));
      }
    }

    return result;
  };

  const renderRow = (k, v, idx) => {
    if (k !== "id")
      return (
        <TableRow key={`__j2t_tr${idx}`}>
          <TableCell
            sx={{ p: "8px", width: "25%", verticalAlign: "top" }}
            key={`__j2t_tdk${idx}`}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
              {fetchLabel(k)}
            </Typography>
          </TableCell>
          <TableCell sx={{ p: "8px", width: "75%" }} key={`__j2t_tdv${idx}`}>
            {/* <Typography variant="body2">{fetchLookupLinks(k, v)}</Typography> */}
            <Typography variant="body2">
              {typeof fetchLookupLinks(k, v) === "string" ||
              fetchLookupLinks(k, v) instanceof String
                ? parse(fetchLookupLinks(k, v))
                : fetchLookupLinks(k, v)}
            </Typography>
          </TableCell>
        </TableRow>
      );
  };

  const renderRowHeader = (label) => {
    return (
      <Box key={`__j2t_rw${label}`}>
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
    );
  };

  const renderRows = (obj, labelKey) => {
    return Object.keys(obj).map((k, idx) => {
      const value = obj[k];
      const isValuePrimitive =
        JSONToTableUtils.getObjectType(value) === JSONObjectType.Primitive;
      // render row when value is primitive otherwise inspect the value and make the key as header
      const retval = isValuePrimitive
        ? renderRow(k, value, idx)
        : renderObject(value, k, idx);

      return retval;
    });
  };

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <Table key={`__j2t_root_table`}>
        <TableBody key={`__j2t_root_tbody`}>
          {renderObject(data, undefined, 0)}
        </TableBody>
      </Table>
    </Box>
  );
}
