import React, { useContext, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import NtaiFlatMenu from "@ntai/components/menus/NtaiFlatMenu";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchReportInitContext } from "./SearchReportInitWrapper";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

function getRevisedData(dataMap, categoryCodeOptions) {
  const result = {};
  if (!_.isEmpty(dataMap)) {
    Object.keys(dataMap).forEach((k) => {
      if (
        dataMap[k]["categoryCode"] &&
        _.isNumber(dataMap[k]["categoryCode"])
      ) {
        result[k] = {
          categoryDecode: _.filter(categoryCodeOptions, {
            value: dataMap[k]["categoryCode"],
          })[0]["label"],
        };
      } else {
        result[k] = {
          categoryDecode: "None",
        };
      }
    });
  }

  return result;
}

export default function SearchReportInitFilters() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState("All");

  const { selectedFilter, setSelectedFilter } = React.useContext(
    SearchReportInitContext
  );

  const dataMap = useSelector(
    (state) => state.search.searchReportInitSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);

  const categoryCodeOptions = _.get(
    codelistCodes,
    CODELIST_CODES.SRH_SEARCH_CATEGORY_CODE
  );

  function handleSelect(item) {
    setSelectedFilter(item);
  }

  useEffect(() => {
    if (_.isArray(categoryCodeOptions) && categoryCodeOptions.length > 0) {
      setFilters({
        All: _.sum(Object.values(_.countBy(data, "categoryCode"))),
        ...NtaiUtils.sortObjectOnKeys(
          _.countBy(
            getRevisedData(dataMap, categoryCodeOptions),
            "categoryDecode"
          )
        ),
      });
    }
  }, [dataMap, categoryCodeOptions]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRH_SEARCH_CATEGORY_CODE));
  }, []);

  return (
    <Box sx={{ flexBasis: "15%", padding: theme.spacing(2) }}>
      <NtaiFlatMenu
        label="FILTER BY"
        data={filters}
        selected={selectedFilter}
        handleSelect={handleSelect}
      />
    </Box>
  );
}
