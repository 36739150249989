import {
  Backdrop,
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  useTheme,
} from "@mui/material";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import React from "react";
import NtaiPieChart3 from "./NtaiPieChart3";
import { useEffect } from "react";
const _ = require("lodash");

const histdata = [
  {
    name: "USA",
    count: 12,
    active: true,
  },
  {
    name: "GER",
    count: 16,
  },

  {
    name: "FR7",
    count: 4,
  },
  {
    name: "TY",
    count: 6,
  },
  {
    name: "FGH",
    count: 8,
  },
  {
    name: "kl",
    count: 1,
  },
  {
    name: "Rt",
    count: 5,
  },
];

const menuItems = [
  {
    value: "include",
    label: "Include",
  },
  {
    value: "exclude",
    label: "Exclude",
  },
  {
    value: "drill-through",
    label: "Drill Through",
  },
  {
    value: "drill-up",
    label: "Drill Up",
  },
  {
    value: "comments",
    label: "Comments",
  },
  {
    value: "series",
    label: "Create Series",
  },
];

export default function NtaiChartPopup() {
  const theme = useTheme();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [popoverCoords, setPopoverCoords] = React.useState();

  function handleOpenPopup(sourceWidgetFields, ev) {
    console.log("EV popup: ", ev);
    // setPopoverOpen(true);
    console.log("Top and left: ", ev.svgPoint.y, ev.svgPoint.x);
    setPopoverCoords({ left: ev.point.x + 15, top: ev.point.y + 15 });
  }

  function handleClose() {
    setPopoverOpen(false);
    setPopoverCoords(null);
  }

  React.useEffect(() => {
    console.log("Changed popoverCoords", popoverCoords);
    setPopoverOpen(true);
  }, [popoverCoords]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          p: theme.spacing(4),
        }}
      >
        <NtaiSourceWidget title="Pie Chart">
          <NtaiPieChart3
            widgetId={`piechart-1`}
            metadata={{ fields: { field123: "123" } }}
            data={histdata}
            valueField="count"
            categoryField="name"
            handleClick={handleOpenPopup}
          />
        </NtaiSourceWidget>
      </Box>

      {popoverCoords && popoverOpen && (
        <Popover
          open={popoverOpen}
          onClose={() => handleClose()}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: popoverCoords["top"],
            left: popoverCoords["left"],
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              border: theme.general.border1,
            }}
          >
            <MenuList sx={{ p: 0, width: "100%" }}>
              {_.sortBy(menuItems, "label").map((c, index) => {
                return (
                  <MenuItem
                    sx={{ py: "8px", px: theme.spacing(4) }}
                    key={`test-component-menu-${index}`}
                  >
                    <ListItemText>{c.label}</ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Box>
        </Popover>
      )}
    </>
  );
}
