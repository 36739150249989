import React from "react";
import { useEffect } from "react";
import * as d3 from "d3";
import { useRef } from "react";

const Circle = () => {
  return (
    <svg>
      <circle cx="150" cy="77" r="40" />
    </svg>
  );
};

export default function D3Circle() {
  return <Circle />;
}
