import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import WorkflowDefVersionForm from "./WorkflowDefVersionForm";
import {
  clearStatus,
  deployWorkflowDefVersion,
  setActiveId,
  updateWorkflowDefVersion,
} from "./store/workflowDefVersionsSlice";
import { Box, Button, Chip, Typography, useTheme } from "@mui/material";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
const _ = require("lodash");

export default function WorkflowDefVersionEdit(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const workflowDefId = props.match.params.workflowdefid;
  const workflowDefVersionId = props.match.params.id;

  const workflowDefDataMap = useSelector(
    (state) => state.core.workflowDefsSlice.data
  );

  const workflowDefData = _.get(workflowDefDataMap, workflowDefId);

  const status = useSelector(
    (state) => state.core.workflowDefVersionsSlice.status
  );
  const dataMap = useSelector(
    (state) => state.core.workflowDefVersionsSlice.data
  );

  const formData = _.get(dataMap, workflowDefVersionId);

  function handleSave(formData, config) {
    dispatch(
      updateWorkflowDefVersion({
        workflowDefId: workflowDefId,
        uuId: workflowDefVersionId,
        formData: formData,
        config: config,
      })
    );
  }

  function handleCancel() {
    history.push(`/admin/workflows/${workflowDefId}/versions`);
  }

  function handleDeploy() {
    dispatch(
      deployWorkflowDefVersion({
        workflowDefId: workflowDefId,
        uuId: workflowDefVersionId,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(workflowDefVersionId));
  }, [workflowDefVersionId]);

  useEffect(() => {
    if (
      status.method === "updateWorkflowDefVersion" &&
      status.result === "success"
    ) {
      enqueueSnackbar(`Workflow definition version saved successfully.`, {
        variant: "success",
      });

      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(workflowDefData, "name")} (${
        _.get(dataMap[workflowDefVersionId], "actVersion") ||
        "Version Not Available"
      })`}
      back={`/admin/workflows/${workflowDefId}/versions`}
      headerActions={
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          {_.get(dataMap[workflowDefVersionId], "statusCode") === 1 && (
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleDeploy()}
            >
              DEPLOY THIS VERSION
            </Button>
          )}
        </Box>
      }
      titleExtras={
        <NtaiBadge
          title={_.get(dataMap[workflowDefVersionId], "statusDecode")}
          bg="secondary"
          fg={theme.colors.secondary.contrastText}
        />
      }
    >
      <NtaiForm>
        <WorkflowDefVersionForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
