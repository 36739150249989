import React, { useState } from "react";
import {
  Backdrop,
  Box,
  IconButton,
  Typography,
  useTheme,
  Chip,
} from "@mui/material";
import SearchSourceRecordView from "./view/SearchSourceRecordView";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SearchSourceRecordActionList from "./action/SearchSourceRecordActionList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faUpRightFromSquare,
} from "@fortawesome/pro-regular-svg-icons";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import SearchSourceRecordListContext from "../SearchSourceRecordListPanel";
import SearchSourceRecordAdj from "./adjudication/SearchSourceRecordAdj";
import SearchSourceRecordNoteList from "./note/SearchSourceRecordNoteList";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import { getSourceFields } from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import SearchSourceRecordDistributionList from "./distribution/SearchSourceRecordDistributionList";
import { SearchSourceContext } from "../../SearchSource";
import SearchSourceRecordDocumentList from "./document/SearchSourceRecordDocumentList";
const _ = require("lodash");

function generateSecondaryLabels(secLabels) {
  let secondaryArr = [];
  secLabels.forEach((secLabel) => {
    secondaryArr.push(secLabel.label.concat(": ").concat(secLabel.value));
  });
  return secondaryArr.join(" | ");
}

function generateTags(tags) {
  let tagsArr = [];
  tags.forEach((tag, i) => {
    if (tag.value && tag.value.length > 0)
      tagsArr.push(
        <NtaiBadge
          key={`record-label-tag-${i}`}
          title={tag.value}
          fg={tag.fgColor}
          bg={tag.bgColor}
        />
      );
  });
  return tagsArr;
}

export default function SearchSourceRecordPanel(props) {
  const theme = useTheme();
  const [maxDialogOpen, setMaxDialogOpen] = React.useState(false);

  const {
    open,
    handleClose,
    labels,
    assessmentFg,
    numOfActions,
    numOfNotes,
    numOfDistributions,
    sourceFields,
  } = props;

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const primaryLabel = _.filter(labels, { defaultFg: 1 })[0];

  const secondaryLabels =
    primaryLabel && Array.isArray(_.get(primaryLabel, "secondaryLabels"))
      ? _.get(primaryLabel, "secondaryLabels")
      : [];

  const tags =
    primaryLabel && Array.isArray(_.get(primaryLabel, "tags"))
      ? _.get(primaryLabel, "tags")
      : [];

  function handleMaximize() {
    setMaxDialogOpen(true);
  }

  function generateContent() {
    const tabs = [];
    tabs.push(
      <Box
        key={`search-source-record-default`}
        label="RECORD"
        sx={{ height: "100%" }}
      >
        <SearchSourceRecordView sourceFields={sourceFields} />
      </Box>
    );

    if (
      _.isArray(
        searchSourceData["searchSourceDefinition"]["sourceAdjudications"]
      ) &&
      searchSourceData["searchSourceDefinition"]["sourceAdjudications"].length >
        0
    ) {
      searchSourceData["searchSourceDefinition"]["sourceAdjudications"].forEach(
        (sourceAdj, i) => {
          tabs.push(
            <Box
              key={`search-source-record-adj-${i}`}
              label={sourceAdj["adjObjectFormLabel"].toUpperCase()}
              sx={{ height: "100%" }}
            >
              <SearchSourceRecordAdj
                handleCancel={handleClose}
                adjObjectUuId={sourceAdj["adjObjectUuId"]}
              />
            </Box>
          );
        }
      );
    }

    tabs.push(
      <Box
        key={`search-source-record-actions`}
        label="ACTIONS"
        chip={numOfActions > 0 ? numOfActions : null}
        sx={{ height: "100%" }}
      >
        <SearchSourceRecordActionList />
      </Box>
    );

    tabs.push(
      <Box
        key={`search-source-record-docs`}
        sx={{ height: "100%" }}
        label="DOCUMENTS"
      >
        <SearchSourceRecordDocumentList />
      </Box>
    );

    tabs.push(
      <Box
        key={`search-source-record-notes`}
        sx={{ height: "100%" }}
        label="NOTES"
        chip={numOfNotes > 0 ? numOfNotes : null}
      >
        <SearchSourceRecordNoteList />
      </Box>
    );

    tabs.push(
      <Box
        key={`search-source-record-dists`}
        sx={{ height: "100%" }}
        label="DISTRIBUTIONS"
        chip={numOfDistributions > 0 ? numOfDistributions : null}
      >
        <SearchSourceRecordDistributionList />
      </Box>
    );

    return <NtaiTabs>{tabs}</NtaiTabs>;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "820px",
          // borderTop: theme.general.border2p,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: theme.spacing(2),
            borderBottom: theme.general.border1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(0.5),
            }}
          >
            <Typography
              sx={{
                wordBreak: "break-word",
              }}
              variant="h2"
              color="secondary.dark"
              fontWeight="300"
            >
              {primaryLabel ? primaryLabel.value : "Not Available"}
            </Typography>
            <Typography variant="subtitle2">
              {generateSecondaryLabels(secondaryLabels)}
            </Typography>
            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              {generateTags(tags)}
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            {/* <IconButton onClick={handleMaximize}>
              <FontAwesomeIcon size="2xs" icon={faUpRightFromSquare} />
            </IconButton> */}
            <IconButton onClick={handleClose}>
              <FontAwesomeIcon size="xs" icon={faClose} />
            </IconButton>
          </Box>
        </Box>

        {generateContent()}
      </Box>
      <NtaiDialog
        title="Full View"
        size="lg"
        open={maxDialogOpen}
        handleDialogClose={() => setMaxDialogOpen(false)}
      >
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
          {generateContent()}
        </Box>
      </NtaiDialog>
    </React.Fragment>
  );
}
