import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDomains = createAsyncThunk(
  "domains/getDomains",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/cdo/domains");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDomain = createAsyncThunk(
  "domains/getDomain",
  async (domainUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/cdo/domains/${domainUuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDomain = createAsyncThunk(
  "domains/createDomain",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/cdo/domains", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDomain = createAsyncThunk(
  "domains/updateDomain",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/cdo/domains/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDomain = createAsyncThunk(
  "domains/deleteDomain",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/cdo/domains/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const domainsSlice = createSlice({
  name: "domains",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDomains.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getDomains" };
    },
    [getDomains.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDomains",
        message: action.payload.message,
      };
    },
    [getDomain.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getDomain" };
    },
    [getDomain.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDomain",
        message: action.payload.message,
      };
    },
    [createDomain.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createDomain" };
    },
    [createDomain.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createDomain",
        message: action.payload.message,
      };
    },
    [updateDomain.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateDomain" };
    },
    [updateDomain.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateDomain",
        message: action.payload.message,
      };
    },
    [deleteDomain.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteDomain" };
    },
    [deleteDomain.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteDomain",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = domainsSlice.actions;

export default domainsSlice.reducer;
