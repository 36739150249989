import { Box, Button, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSearchDashboardGroups,
  getSearchDashboardSourceUuIds,
} from "../store/searchDashboardInitSlice";
import { SearchDashboardInitContext } from "../SearchDashboardInit";
import {
  faGrid,
  faGridHorizontal,
  faStop,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchDashboardCompactBox from "../SearchDashboardCompactBox";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

function getFilteredDashboards(dashboards, dashboardsQueryText) {
  return _.filter(dashboards, function (o) {
    if (dashboardsQueryText && dashboardsQueryText.length > 0) {
      if (
        o["title"].toLowerCase().includes(dashboardsQueryText.toLowerCase())
      ) {
        return true;
      } else return false;
    } else if (!dashboardsQueryText || dashboardsQueryText.length === 0) {
      return true;
    }
  });
}

export default function SearchDashboardViewGroups() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [denseLayout, setDenseLayout] = useState(false);
  const [views, setViews] = useState([]);
  const {
    selectedEntities,
    selectedViews,
    setSelectedViews,
    dashboardsQueryText,
  } = React.useContext(SearchDashboardInitContext);

  const entitiesDataMap = useSelector(
    (state) => state.search.searchDashboardInitSlice.entities
  );

  const dashboardGroupsDataMap = useSelector(
    (state) => state.search.searchDashboardInitSlice.dashboardGroups
  );

  const dashboardGroupsData =
    dashboardGroupsDataMap && !_.isEmpty(dashboardGroupsDataMap)
      ? Object.values(dashboardGroupsDataMap)
      : [];

  const status = useSelector(
    (state) => state.search.searchDashboardInitSlice.status
  );

  function handleSelectView(viewUuId) {
    const tmpSelected = new Set(selectedViews);
    if (selectedViews && selectedViews.has(viewUuId))
      tmpSelected.delete(viewUuId);
    else tmpSelected.add(viewUuId);
    setSelectedViews(tmpSelected);
  }

  function clearSelectedViews() {
    const tmpSelected = new Set(selectedViews);
    tmpSelected.clear();
    setSelectedViews(tmpSelected);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchDashboardGroups" &&
      status.result === "success"
    ) {
      let revisedViews = [];
      if (dashboardGroupsDataMap && !_.isEmpty(dashboardGroupsDataMap)) {
        revisedViews = Object.values(dashboardGroupsDataMap).map((e, i) => {
          return {
            uuId: e.uuId,
            title: e.name,
            description: e.description,
          };
        });
      }

      setLoaded(true);
      setViews(revisedViews);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (selectedEntities.size > 0) {
      const objectId = _.get(
        entitiesDataMap[[...selectedEntities][0]],
        "objectUuId"
      );
      dispatch(getSearchDashboardGroups({ domainId: "*", objectId: objectId }));
    }
  }, [selectedEntities]);

  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        flexBasis: "85%",
        justifyContent: "start",
        gap: theme.spacing(1),
      }}
    >
      {loaded && (
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              pl: theme.spacing(2),
              justifyContent: "space-between",
              paddingTop: theme.spacing(2),
              width: "86%",
            }}
          >
            <Typography variant="h6">{`Dashboards (${
              getFilteredDashboards(views, dashboardsQueryText).length
            })`}</Typography>

            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                justifyContent: "end",
              }}
            >
              <Button
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  gap: theme.spacing(1),
                }}
                size="small"
                color="inherit"
                // onClick={() => setDenseLayout(!denseLayout)}
              >
                <FontAwesomeIcon
                  size="xl"
                  icon={denseLayout ? faGridHorizontal : faGrid}
                />
                {denseLayout ? "STANDARD" : "DENSE"}
              </Button>
              <Button
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  gap: theme.spacing(1),
                }}
                size="small"
                color="inherit"
                onClick={() => clearSelectedViews()}
              >
                <FontAwesomeIcon size="xl" icon={faStop} />
                CLEAR ALL
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              p: theme.spacing(2),
              gap: theme.spacing(2),
              flexWrap: "wrap",
            }}
          >
            {getFilteredDashboards(views, dashboardsQueryText).length > 0 &&
              getFilteredDashboards(views, dashboardsQueryText).map((e, i) => (
                <SearchDashboardCompactBox
                  key={`search-db-view=${i}`}
                  id={e["uuId"]}
                  title={e["title"]}
                  description={e["description"]}
                  handleSelect={handleSelectView}
                  selectedItems={selectedViews}
                />
              ))}
            {getFilteredDashboards(views, dashboardsQueryText).length === 0 && (
              <NtaiEmptyMessage
                header="No dashboards available"
                subheader="You can configure entity dashboards under Controls - Objects section"
              />
            )}
          </Box>
        </Scrollbar>
      )}
      {!loaded && <NtaiCircularProgress />}
    </Box>
  );
}
