import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { faClose, faShapes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import {
  getSourceViews,
  clearStatus,
  getSourceUserViews,
} from "app/main/pages/core/sourcedefinition/source/view/store/sourceViewsSlice";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../SearchEdit";
import { SearchSourceContext } from "../../SearchSource";
import {
  selectSearchSourceView,
  clearStatus as searchSourcesSliceClearStatus,
} from "../../store/searchSourcesSlice";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function SearchSourceViewMenuItems({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [viewsLoaded, setViewsLoaded] = useState(false);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData, setChangeView } =
    React.useContext(SearchSourceContext);

  const searchSourcesSliceStatus = useSelector(
    (state) => state.search.searchSourcesSlice.status
  );

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "uuId");

  // Dataset views are of type lib dataset - view. Rest are type 1 (Search source view)
  const sourceViewType = searchData["typeCode"] === 4 ? 6 : 1;

  const sourceDefId = _.get(searchSourceData, "searchSourceDefinition.uuId");
  const sourceId = _.get(searchSourceData, "searchSourceDefinition.sourceUuId");

  const dataMap = useSelector(
    (state) => state.sources.sourceViewsSlice.userData
  );
  const sourceViewsSliceStatus = useSelector(
    (state) => state.sources.sourceViewsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function selectView(sourceViewUuId) {
    dispatch(
      selectSearchSourceView({
        searchId: searchId,
        searchSourceId: searchSourceId,
        formData: {
          userName: "sprakash", // TODO: user name hardcoded - pick for the user logged in on server from token
          sourceViewUuId: sourceViewUuId,
        },
      })
    );
  }

  useEffect(() => {
    if (
      searchSourcesSliceStatus &&
      searchSourcesSliceStatus.result === "success" &&
      searchSourcesSliceStatus.method === "selectSearchSourceView"
    ) {
      handleClose();
      setChangeView(true);
      dispatch(searchSourcesSliceClearStatus());
    }
  }, [searchSourcesSliceStatus]);

  useEffect(() => {
    if (
      sourceViewsSliceStatus &&
      sourceViewsSliceStatus.result === "success" &&
      sourceViewsSliceStatus.method === "getSourceUserViews"
    ) {
      dispatch(clearStatus());
      setViewsLoaded(true);
    }
  }, [sourceViewsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceUserViews({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        params: {
          sourceUuId: sourceId,
          activeFg: 1,
          fetchMin: true,
          typeCode: sourceViewType,
        },
      })
    );
  }, []);

  return (
    <Box
      sx={{
        height: "340px",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faChartSimple} />
        {/* <Typography variant="body2" fontWeight="700"> */}
        <Typography variant="h5" fontWeight="500" color={grey[700]}>
          Select View
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {viewsLoaded && (
              <List sx={{ p: "2px", width: "100%" }}>
                {data &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((item) => {
                    return (
                      <ListItem
                        key={item.uuId}
                        value={item.uuId}
                        divider={true}
                        onClick={() => selectView(item.uuId)}
                        sx={{
                          "&:hover": {
                            background: grey[100],
                            cursor: "pointer",
                          },
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.name}
                            </Typography>
                          }
                          secondary={
                            <Typography variant="subtitle2" color="default">
                              {item.description}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
              </List>
            )}
            {!viewsLoaded && <NtaiCircularProgress size={20} />}
          </Box>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
