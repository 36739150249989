import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjectFormBuilderPanels = createAsyncThunk(
  "objectFormBuilderPanels/getObjectFormBuilderPanels",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, objectFormTabId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectFormBuilderPanel = createAsyncThunk(
  "objectFormBuilderPanels/getObjectFormBuilderPanel",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, objectFormTabId, uuId } =
        values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectFormBuilderPanel = createAsyncThunk(
  "objectFormBuilderPanels/createObjectFormBuilderPanel",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, objectFormTabId, formData } =
        values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectFormBuilderPanel = createAsyncThunk(
  "objectFormBuilderPanels/updateObjectFormBuilderPanel",
  async (values, { rejectWithValue }) => {
    try {
      const {
        domainId,
        objectId,
        objectFormId,
        objectFormTabId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reorderObjectFormBuilderPanels = createAsyncThunk(
  "objectFormBuilderPanels/reorderObjectFormBuilderPanels",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, objectFormTabId, formData } =
        values;
      const response = await server.put(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectFormBuilderPanelFields = createAsyncThunk(
  "objectFormBuilderPanels/updateObjectFormBuilderPanelFields",
  async (values, { rejectWithValue }) => {
    try {
      const {
        domainId,
        objectId,
        objectFormId,
        objectFormTabId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels/${uuId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reorderObjectFormBuilderPanelFields = createAsyncThunk(
  "objectFormBuilderPanels/reorderObjectFormBuilderPanelFields",
  async (values, { rejectWithValue }) => {
    try {
      const {
        domainId,
        objectId,
        objectFormId,
        objectFormTabId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels/${uuId}/reorder-fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectFormBuilderPanel = createAsyncThunk(
  "objectFormBuilderPanels/deleteObjectFormBuilderPanel",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, objectFormTabId, uuId } =
        values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${objectFormTabId}/panels/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectFormBuilderPanelsSlice = createSlice({
  name: "objectFormBuilderPanels",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectFormBuilderPanels.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getObjectFormBuilderPanels",
      };
    },
    [getObjectFormBuilderPanels.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectFormBuilderPanels",
        message: action.payload.message,
      };
    },
    [getObjectFormBuilderPanel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "getObjectFormBuilderPanel",
      };
    },
    [getObjectFormBuilderPanel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectFormBuilderPanel",
        message: action.payload.message,
      };
    },
    [createObjectFormBuilderPanel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createObjectFormBuilderPanel",
      };
      state.activeId = action.payload.uuId;
    },
    [createObjectFormBuilderPanel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectFormBuilderPanel",
        message: action.payload.message,
      };
    },
    [updateObjectFormBuilderPanel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateObjectFormBuilderPanel",
      };
    },
    [updateObjectFormBuilderPanel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectFormBuilderPanel",
        message: action.payload.message,
      };
    },

    [reorderObjectFormBuilderPanels.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "reorderObjectFormBuilderPanels",
      };
    },
    [reorderObjectFormBuilderPanels.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "reorderObjectFormBuilderPanels",
        message: action.payload.message,
      };
    },

    [updateObjectFormBuilderPanelFields.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateObjectFormBuilderPanelFields",
      };
    },
    [updateObjectFormBuilderPanelFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectFormBuilderPanelFields",
        message: action.payload.message,
      };
    },

    [reorderObjectFormBuilderPanelFields.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "reorderObjectFormBuilderPanelFields",
      };
    },
    [reorderObjectFormBuilderPanelFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "reorderObjectFormBuilderPanelFields",
        message: action.payload.message,
      };
    },

    [deleteObjectFormBuilderPanel.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteObjectFormBuilderPanel",
      };
    },
    [deleteObjectFormBuilderPanel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectFormBuilderPanel",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  objectFormBuilderPanelsSlice.actions;

export default objectFormBuilderPanelsSlice.reducer;
