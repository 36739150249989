import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import AlertDefinitionForm from "./AlertDefinitionForm";
import {
  clearStatus,
  getAlertDefinition,
  setActiveId,
  updateAlertDefinition,
} from "./store/alertDefinitionsSlice";
import { Chip, useTheme } from "@mui/material";

import AlertDefinitionActions from "./actions/AlertDefinitionActions";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function AlertDefinitionEdit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const status = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );
  const dataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );
  const activeId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateAlertDefinition({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getAlertDefinition" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status.method === "updateAlertDefinition" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Alert definition "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );

      // history.push(`/monitor/definitions`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
    dispatch(getAlertDefinition(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Alert Definitions / Edit ${_.get(dataMap[id], "name")}`}
      titleExtras={
        <Chip
          sx={{ fontSize: "10px", fontWeight: 700 }}
          size="small"
          variant="outlined"
          label={_.get(formData, "statusDecode").toUpperCase()}
        />
      }
      back="/monitor/definitions"
      headerActions={<AlertDefinitionActions id={id} />}
    >
      {loaded && (
        <NtaiForm>
          <AlertDefinitionForm
            action="edit"
            formData={formData}
            handleSave={handleSave}
          />
        </NtaiForm>
      )}
      {!loaded && <NtaiCircularProgress />}
    </NtaiPage>
  );
}
