import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiChartEmptyMessage from "@ntai/components/charts/NtaiChartEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
const _ = require("lodash");

function createSortHandler(field) {
  console.log("Sort on: ", field);
}

export default function NtaiXTab(props) {
  const theme = useTheme();
  const { metadata, rows, handleClick } = props;

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function handleCellClick(params, e) {
    const sourceWidgetFilterFields = [];
    _.orderBy(
      Object.values(metadata["fields"]),
      ["fieldOrder"],
      ["asc"]
    ).forEach((f, i) => {
      sourceWidgetFilterFields.push({
        sourceWidgetFieldUuId: f.uuId,
        fieldOperatorCode: 1,
        fieldValue: params[i],
      });
    });

    handleClick(sourceWidgetFilterFields, e);
  }

  function buildTableHeadRows() {
    return Object.values(metadata.header).map((tr, i) => (
      <TableRow key={`th-row-${i}`}>
        {Object.values(tr).map((trc, j) => (
          <TableCell
            key={`th-cell-${i}-${j}`}
            rowSpan={trc.rowSpan}
            colSpan={trc.colSpan}
            sx={{
              textAlign: trc.textAlign,
              px: "8px",
              fontSize: "12px",
            }}
          >
            {/* <TableSortLabel onClick={(e) => createSortHandler(trc)}> */}
            {trc.label}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  function buildTableBody() {
    return Object.values(rows).map((tcols, i) => (
      <TableRow key={`tb-row-${i}`}>
        {Object.values(tcols).map((tcol, j) => (
          <TableCell
            key={`tb-cell-${i}-${j}`}
            sx={{
              textAlign: tcol.textAlign,
              px: "12px",
              fontSize: "12px",
            }}
          >
            {_.has(tcol, "linkParams") ? (
              <Link
                color={blue[900]}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
                onClick={(e) => handleCellClick(tcol.linkParams, e)}
              >
                {tcol.filterFg
                  ? _.isNumber(tcol.total) &&
                    tcol.total.toString().indexOf(".") !== -1
                    ? NtaiUtils.roundDecimal(tcol.value, 2).toString() +
                      " - " +
                      NtaiUtils.roundDecimal(tcol.total, 2).toString()
                    : tcol.value.toString() + " (" + tcol.total.toString() + ")"
                  : _.isNumber(tcol.value) &&
                    tcol.value.toString().indexOf(".") !== -1
                  ? NtaiUtils.roundDecimal(tcol.value, 2)
                  : tcol.value}
              </Link>
            ) : tcol.filterFg ? (
              _.isNumber(tcol.total) &&
              tcol.total.toString().indexOf(".") !== -1 ? (
                NtaiUtils.roundDecimal(tcol.value, 2).toString() +
                " (" +
                NtaiUtils.roundDecimal(tcol.total, 2).toString() +
                ")"
              ) : (
                tcol.total.toString() + " (" + tcol.total.toString() + ")"
              )
            ) : _.isNumber(tcol.value) &&
              tcol.value.toString().indexOf(".") !== -1 ? (
              NtaiUtils.roundDecimal(tcol.value, 2)
            ) : (
              tcol.value
            )}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  function callMe() {
    console.log("Clicked...");
  }

  return (
    <React.Fragment>
      {rows && !_.isEmpty(rows) && (
        <Scrollbar autoHide={true}>
          <Box sx={{ px: theme.spacing(0.5) }}>
            <Table sx={{ border: theme.general.border1 }} size="small">
              <TableHead>{buildTableHeadRows()}</TableHead>
              <TableBody>{buildTableBody()}</TableBody>
            </Table>
          </Box>
        </Scrollbar>
      )}
      {!rows && <NtaiChartEmptyMessage />}
    </React.Fragment>
  );
}
