import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUserQuery, clearStatus } from "./store/userQueriesSlice";
import UserQueryForm from "./UserQueryForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function UserQueryCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.userQueriesSlice.status);
  const dataMap = useSelector((state) => state.core.userQueriesSlice.data);
  const activeId = useSelector((state) => state.core.userQueriesSlice.activeId);

  function handleSave(formData) {
    dispatch(createUserQuery(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createUserQuery" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Rule(s) "${_.get(dataMap[activeId], "name")}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/library/queries/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Rule" back="/library/queries">
      <NtaiForm>
        <UserQueryForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
