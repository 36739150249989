import { Box, Button, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import NtaiPopover from "../popover/NtaiPopover";
import NtaiPopoverNew from "../popover/NtaiPopoverNew";
import NtaiDecoPopper from "./NtaiDecoPopper";
import NtaiPopper from "./NtaiPopper";
import NtaiPopperNew from "./NtaiPopperNew";

export default function NtaiTestPopper() {
  const theme = useTheme();
  const [anchorElPopper, setAnchorElPopper] = React.useState(null);
  const [anchorElPopperNew, setAnchorElPopperNew] = React.useState(null);
  const [anchorElDecoPopper, setAnchorElDecoPopper] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openPopperNew, setOpenPopperNew] = React.useState(false);
  const [openDecoPopper, setOpenDecoPopper] = React.useState(false);

  const handlePopperClick = (event) => {
    setOpenPopper(true);
    setAnchorElPopper(event.currentTarget);
  };

  const handlePopperNewClick = (event) => {
    setOpenPopperNew(true);
    setAnchorElPopperNew(event.currentTarget);
  };

  const handleDecoPopperClick = (event) => {
    setOpenDecoPopper(true);
    setAnchorElDecoPopper(event.currentTarget);
  };

  const handlePopperClose = () => {
    setOpenPopper(false);
    setAnchorElPopper(null);
  };

  const handlePopperNewClose = () => {
    setOpenPopperNew(false);
    setAnchorElPopperNew(null);
  };

  const handleDecoPopperClose = () => {
    setOpenDecoPopper(false);
    setAnchorElDecoPopper(null);
  };

  const clickAwayHandlerPopper = () => {
    setOpenPopper(false);
  };

  const clickAwayHandlerPopperNew = () => {
    setOpenPopperNew(false);
  };

  const clickAwayHandlerDecoPopper = () => {
    setOpenDecoPopper(false);
  };

  return (
    <Box
      sx={{
        p: theme.spacing(12),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Button size="small" onClick={(e) => handlePopperClick(e)}>
        OPEN POPPER
      </Button>

      <Button
        size="small"
        variant="contained"
        onClick={(e) => handlePopperNewClick(e)}
      >
        OPEN NEW POPPER
      </Button>

      <Button size="small" onClick={(e) => handleDecoPopperClick(e)}>
        OPEN DECO POPPER
      </Button>

      {openPopper && (
        <NtaiPopper
          open={openPopper}
          anchorEl={anchorElPopper}
          handleClose={handlePopperClose}
          clickAwayHandler={clickAwayHandlerPopper}
        >
          Content
        </NtaiPopper>
      )}

      {openPopperNew && (
        <NtaiPopoverNew
          open={openPopperNew}
          anchorEl={anchorElPopperNew}
          handleClose={handlePopperNewClose}
          clickAwayHandler={clickAwayHandlerPopperNew}
          anchorOriginV="top"
          anchorOriginH="right"
          transformOriginV="center"
          transformOriginH="left"
        >
          <Box
            sx={{
              display: "flex",
              width: "120px",
              height: "100px",
              border: theme.general.border1,
            }}
          >
            Test my skills
          </Box>
        </NtaiPopoverNew>
      )}

      {openDecoPopper && (
        <NtaiPopover
          open={openDecoPopper}
          anchorEl={anchorElDecoPopper}
          handleClose={handleDecoPopperClose}
          anchorOriginV="bottom"
          anchorOriginH="right"
          transformOriginH="center"
          transformOriginV="top"
        >
          <Box sx={{ display: "flex", width: "120px", height: "100px" }}>
            Test my skills
          </Box>
        </NtaiPopover>
      )}
    </Box>
  );
}
