import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceInStoreFilePreview = createAsyncThunk(
  "sourceInStoreFilePreview/getSourceInStoreFilePreview",
  async (values) => {
    const { sourceDefId, sourceId, sourceInStoreId, sourceInStoreFileId } =
      values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/files/${sourceInStoreFileId}/preview`
    );
    return response.data;
  }
);

export const sourceInStoreFilePreviewSlice = createSlice({
  name: "sourceInStoreFilePreview",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceInStoreFilePreview.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceInStoreFilePreview",
      };
    },
    [getSourceInStoreFilePreview.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceInStoreFilePreview",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceInStoreFilePreviewSlice.actions;

export default sourceInStoreFilePreviewSlice.reducer;
