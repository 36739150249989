import { Box, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useSelector } from "react-redux";
import SourcePolicyDefList from "./definition/SourcePolicyDefList";
import SourcePolicyList from "./policy/SourcePolicyList";

const _ = require("lodash");

export default function SourcePolicyWrapper(props) {
  const theme = useTheme();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = props.match.params.id;

  return (
    <NtaiPage
      title={`${_.get(sourceDefData, "name")} / Policy Management`}
      back={`/sourcedefs/${sourceDefId}/smwrapper`}
    >
      <NtaiTabs>
        <Box sx={{ height: "100%" }} label="POLICIES">
          {/* <SourceTemplateListingList /> */}
          <SourcePolicyList />
        </Box>
        <Box sx={{ height: "100%" }} label="POLICY DEFINITIONS">
          <SourcePolicyDefList />
        </Box>
      </NtaiTabs>
    </NtaiPage>
  );
}
