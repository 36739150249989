import SourceThesaurusList from "./SourceThesaurusList";

const SourceThesaurusConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/thesauruses",
      exact: true,
      component: SourceThesaurusList,
    },
  ],
};

export default SourceThesaurusConfig;
