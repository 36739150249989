import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import React from "react";
import history from "@ntai/@history/@history";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import ReportSelectionCategories from "./ReportSelectionCategories";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getReportDefinitions,
} from "../../admin/definition/store/reportDefinitionsSlice";
import { useEffect } from "react";
import { useState } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

function fetchReportDefinitionType(type) {
  if (type === 1) return "Source";
  if (type === 2) return "Custom";
  if (type === 3) return "Series";
  if (type === 4) return "Dataset";
}

const headCells = [
  {
    id: "uuId",
    numeric: false,
    disablePadding: true,
    ignore: true,
    label: "UUID",
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Report Name",
    fontWeight: 700,
  },
  {
    id: "reportDefinitionGroupObject.reportDefinitionGroupName",
    numeric: false,
    disablePadding: false,
    label: "Report Group",
  },
  {
    id: "typeCode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    transformFunc: fetchReportDefinitionType,
  },

  // {
  //   id: "description",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Description",
  // },
];

export default function ReportSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionsSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const status = useSelector(
    (state) => state.reports.reportDefinitionsSlice.status
  );

  function redirectToMain(id) {
    history.push(`/reports/${id}`);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getReportDefinitions" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getReportDefinitions({
        groupId: "*",
      })
    );
  }, []);

  return (
    <Box sx={{ height: "420px", display: "flex" }}>
      <ReportSelectionCategories />
      <Box sx={{ flexBasis: "75%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            p: theme.spacing(2),
            height: `calc(100% - 54px)`,
            display: "flex",
          }}
        >
          {loaded && data.length > 0 && (
            <Scrollbar>
              <Box sx={{ pr: theme.spacing(1) }}>
                <NtaiSimpleTable
                  title={`Report Types (${data.length})`}
                  rows={data}
                  headCells={headCells}
                  keyColumn="uuId"
                  dialog={true}
                  handleDialogAddEdit={redirectToMain}
                />
              </Box>
            </Scrollbar>
          )}
          {loaded && data.length === 0 && (
            <NtaiEmptyMessage header="No report definitions available" />
          )}
          {!loaded && <NtaiCircularProgress size={24} />}
        </Box>
      </Box>
    </Box>
  );
}
