import PackageCreate from "./PackageCreate";
import PackageEdit from "./PackageEdit";
import PackageList from "./PackageList";

const PackageConfig = {
  routes: [
    {
      path: "/models/packages",
      exact: true,
      component: PackageList,
    },
    {
      path: "/models/packages/create",
      exact: true,
      component: PackageCreate,
    },
    {
      path: "/models/packages/:id/edit",
      exact: true,
      component: PackageEdit,
    },
  ],
};

export default PackageConfig;
