import { NtaiRedTheme } from "./schemes/NtaiRedTheme";
import { NtaiOrangeTheme } from "./schemes/NtaiOrangeTheme";
import { NtaiBlueTheme } from "./schemes/NtaiBlueTheme";
import { NtaiGreenTheme } from "./schemes/NtaiGreenTheme";
import { NtaiDarkTheme } from "./schemes/NtaiDarkTheme";
import { NtaiLightTheme } from "./schemes/NtaiLightTheme";
import { NtaiMixedTheme1 } from "./schemes/NtaiMixedTheme1";

const themeMap = {
  NtaiRedTheme,
  NtaiBlueTheme,
  NtaiGreenTheme,
  NtaiDarkTheme,
  NtaiLightTheme,
  NtaiOrangeTheme,
  NtaiMixedTheme1,
};

export function themeCreator(theme) {
  return themeMap[theme];
}
