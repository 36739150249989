import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

export default function SourcePipelineForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset, watch } = useFormContext();

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourcePipelineForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    reset({ sourcePipelineForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="sourcePipelineForm.name"
          label="Name*"
          defaultValue="Pipeline 1"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="sourcePipelineForm.description"
          label="Description"
          defaultValue="Pipeline 1 description"
          multiline
          minRows={3}
          maxRows={3}
        />
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <NtaiSwitchField
            label="Approve"
            name="sourcePipelineForm.approvedFg"
          />
          <NtaiSwitchField label="Lock" name="sourcePipelineForm.lockedFg" />
        </Box>
      </Box>
    </NtaiDialogFormPanel>
  );
}
