import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import fileUploadServer from "app/http/ntaiFileUploadServer";
const _ = require("lodash");

export const getSourceInStoreOcrFields = createAsyncThunk(
  "sourceInStoreOcrFields/getSourceInStoreOcrFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, sourceInStoreOcrPageId } =
        values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${sourceInStoreOcrPageId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceInStoreOcrField = createAsyncThunk(
  "sourceInStoreOcrFields/getSourceInStoreOcrField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceInId,
        sourceInStoreOcrPageId,
        uuId,
      } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${sourceInStoreOcrPageId}/fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceInStoreOcrField = createAsyncThunk(
  "sourceInStoreOcrFields/createSourceInStoreOcrField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceInId,
        sourceInStoreOcrPageId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${sourceInStoreOcrPageId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceInStoreOcrField = createAsyncThunk(
  "sourceInStoreOcrFields/updateSourceInStoreOcrField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceInId,
        sourceInStoreOcrPageId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${sourceInStoreOcrPageId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceInStoreOcrField = createAsyncThunk(
  "sourceInStoreOcrFields/deleteSourceInStoreOcrField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceInId,
        sourceInStoreOcrPageId,
        uuId,
      } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${sourceInStoreOcrPageId}/fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceInStoreOcrFieldsSlice = createSlice({
  name: "sourceInStoreOcrFields",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceInStoreOcrFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceInStoreOcrFields",
      };
    },
    [getSourceInStoreOcrFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceInStoreOcrFields",
        message: action.payload.message,
      };
    },
    [getSourceInStoreOcrFields.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSourceInStoreOcrFields",
      };
    },
    [getSourceInStoreOcrField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceInStoreOcrField",
      };
    },
    [getSourceInStoreOcrField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceInStoreOcrField",
        message: action.payload.message,
      };
    },
    [createSourceInStoreOcrField.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createSourceInStoreOcrField",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceInStoreOcrField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceInStoreOcrField",
        message: action.payload.message,
      };
    },
    [updateSourceInStoreOcrField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "updateSourceInStoreOcrField",
      };
    },
    [updateSourceInStoreOcrField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceInStoreOcrField",
        message: action.payload.message,
      };
    },
    [deleteSourceInStoreOcrField.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "deleteSourceInStoreOcrField",
      };
    },
    [deleteSourceInStoreOcrField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceInStoreOcrField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceInStoreOcrFieldsSlice.actions;

export default sourceInStoreOcrFieldsSlice.reducer;
