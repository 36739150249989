import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjectSourceViews = createAsyncThunk(
  "objectSourceViews/getObjectSourceViews",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/source-views`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectAllSourceWidgets = createAsyncThunk(
  "objectSourceViews/getObjectAllSourceWidgets",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(
        "/sourcedefs/*/sources/*/views/*/widgets"
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectSourceView = createAsyncThunk(
  "objectSourceViews/getObjectSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;

      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/source-views/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectSourceView = createAsyncThunk(
  "objectSourceViews/createObjectSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/source-views`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectSourceView = createAsyncThunk(
  "objectSourceViews/updateObjectSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/source-views/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectSourceView = createAsyncThunk(
  "objectSourceViews/deleteObjectSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/source-views/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectSourceViewsSlice = createSlice({
  name: "objectSourceViews",
  initialState: {
    data: {},
    sourceWidgets: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectSourceViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjectSourceViews" };
    },
    [getObjectSourceViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectSourceViews",
        message: action.payload.message,
      };
    },
    [getObjectAllSourceWidgets.fulfilled]: (state, action) => {
      state.sourceWidgets = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjectAllSourceWidgets" };
    },
    [getObjectAllSourceWidgets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectAllSourceWidgets",
        message: action.payload.message,
      };
    },
    [getObjectSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getObjectSourceView" };
    },
    [getObjectSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectSourceView",
        message: action.payload.message,
      };
    },
    [createObjectSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createObjectSourceView" };
      state.activeId = action.payload.uuId;
    },
    [createObjectSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectSourceView",
        message: action.payload.message,
      };
    },
    [updateObjectSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "updateObjectSourceView" };
    },
    [updateObjectSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectSourceView",
        message: action.payload.message,
      };
    },
    [deleteObjectSourceView.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteObjectSourceView" };
    },
    [deleteObjectSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectSourceView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = objectSourceViewsSlice.actions;

export default objectSourceViewsSlice.reducer;
