import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlertRecordBatchDocuments = createAsyncThunk(
  "alertRecordBatchDocuments/getAlertRecordBatchDocuments",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/bulk-documents`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertRecordBatchDocument = createAsyncThunk(
  "alertRecordBatchDocuments/getAlertRecordBatchDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, uuId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/bulk-documents/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAlertRecordBatchDocument = createAsyncThunk(
  "alertRecordBatchDocuments/createAlertRecordBatchDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/bulk-documents`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAlertRecordBatchDocument = createAsyncThunk(
  "alertRecordBatchDocuments/updateAlertRecordBatchDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, uuId, formData } = values;
      const response = await server.patch(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/bulk-documents/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAlertRecordBatchDocument = createAsyncThunk(
  "alertRecordBatchDocuments/deleteAlertRecordBatchDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, uuId } = values;
      const response = await server.delete(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/bulk-documents/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadAlertRecordBatchDocument = createAsyncThunk(
  "alertRecordBatchDocuments/downloadAlertRecordBatchDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, fileName, uuId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/bulk-documents/${uuId}/download`
      );
      return { fileName: fileName, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertRecordBatchDocumentsSlice = createSlice({
  name: "alertRecordBatchDocuments",
  initialState: {
    data: {},
    activeId: null,
    downloadData: null,
    downloadFileName: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    clearDownloadData: (state, action) => {
      state.downloadData = null;
      state.downloadFileName = null;
    },
  },
  extraReducers: {
    [getAlertRecordBatchDocuments.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "document.uuId") };
      state.status = {
        result: "success",
        method: "getAlertRecordBatchDocuments",
      };
    },
    [getAlertRecordBatchDocuments.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordBatchDocuments",
        message: action.payload.message,
      };
    },
    [getAlertRecordBatchDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getAlertRecordBatchDocument",
      };
    },
    [getAlertRecordBatchDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordBatchDocument",
        message: action.payload.message,
      };
    },
    [createAlertRecordBatchDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.document.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createAlertRecordBatchDocument",
      };
    },
    [createAlertRecordBatchDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createAlertRecordBatchDocument",
        message: action.payload.message,
      };
    },
    [updateAlertRecordBatchDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.document.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateAlertRecordBatchDocument",
      };
    },
    [updateAlertRecordBatchDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateAlertRecordBatchDocument",
        message: action.payload.message,
      };
    },
    [deleteAlertRecordBatchDocument.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteAlertRecordBatchDocument",
      };
    },
    [deleteAlertRecordBatchDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAlertRecordBatchDocument",
        message: action.payload.message,
      };
    },

    [downloadAlertRecordBatchDocument.fulfilled]: (state, action) => {
      state.downloadData = action.payload.data;
      state.downloadFileName = action.payload.fileName;
      state.status = {
        result: "success",
        method: "downloadAlertRecordBatchDocument",
      };
    },
    [downloadAlertRecordBatchDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "downloadAlertRecordBatchDocument",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, clearDownloadData } =
  alertRecordBatchDocumentsSlice.actions;

export default alertRecordBatchDocumentsSlice.reducer;
