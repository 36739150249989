import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  getAwsClusterTemplateSources,
  createAwsClusterTemplateSource,
  updateAwsClusterTemplateSource,
  deleteAwsClusterTemplateSource,
  clearStatus,
} from "./store/awsClusterTemplateSourcesSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import AwsClusterTemplateSourceForm from "./AwsClusterTemplateSourceForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "sourceDefName",
    numeric: false,
    disablePadding: false,
    label: "Source",
    fontWeight: 700,
  },

  {
    id: "uiOrScheduleExecDecode",
    numeric: false,
    disablePadding: false,
    label: "Pipeline",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function AwsClusterTemplateSourceList() {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const awsClusterTemplateUuId = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.awsClusterTemplateSourcesSlice.data
  );
  const status = useSelector(
    (state) => state.core.awsClusterTemplateSourcesSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteAwsClusterTemplateSource({
            awsClusterTemplateUuId: awsClusterTemplateUuId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateAwsClusterTemplateSource({
            awsClusterTemplateUuId: awsClusterTemplateUuId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createAwsClusterTemplateSource({
            awsClusterTemplateUuId: awsClusterTemplateUuId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    dispatch(getAwsClusterTemplateSources(awsClusterTemplateUuId));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createAwsClusterTemplateSource" ||
        status.method === "updateAwsClusterTemplateSource")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteAwsClusterTemplateSource"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <NtaiPanel
        width="75%"
        header="Manage Sources"
        subheader="Manage sources for the aws cluster template"
      >
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          title="Aws Cluster Template Source"
        />
      </NtaiPanel>

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Aws Cluster Template Source"
        size="sm"
      >
        <NtaiForm>
          <AwsClusterTemplateSourceForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "sourceDefName"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
