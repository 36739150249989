import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";

export default function NtaiInfoPanel2(props) {
  const { header, subheader, actions, handleAction } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          p: theme.spacing(2),
          display: "flex",
          flexDirection: "column",
          width: "75%",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="h2" fontWeight="500">
            {header}
          </Typography>
          <Typography variant="subtitle1" textAlign="center" color="inherit">
            {subheader}
          </Typography>
        </Box>

        {actions && (
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              justifyContent: "center",
            }}
          >
            {actions.map((action, i) => (
              <Button
                variant="contained"
                onClick={(e) => handleAction(action.value)}
                size="small"
              >
                {action.label}
              </Button>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
