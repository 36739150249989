import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { clearStatus, createUser } from "./store/usersSlice";
import UserForm from "./UserForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function UserCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.core.usersSlice.data);
  const status = useSelector((state) => state.core.usersSlice.status);
  const activeId = useSelector((state) => state.core.usersSlice.activeId);

  function handleSave(formData) {
    dispatch(createUser(formData));
  }

  useEffect(() => {
    if (status.method === "createUser" && status.result === "success") {
      enqueueSnackbar(
        `User "${_.get(dataMap[activeId], "userName")}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/admin/users/${activeId}/edit`);

      dispatch(clearStatus());
    }

    if (status && status.method === "createUser" && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add User" back="/admin/users">
      <NtaiForm>
        <UserForm action="add" handleFormSubmit={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
