import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  createSourceOutDatasetScdPolicy,
  deleteSourceOutDatasetScdPolicy,
  getSourceOutDatasetScdPolicies,
  updateSourceOutDatasetScdPolicy,
  setActiveId,
  clearStatus,
} from "./store/sourceOutDatasetScdPoliciesSlice";
import SourceOutDatasetScdPolicyForm from "./SourceOutDatasetScdPolicyForm";
import { useSnackbar } from "notistack";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },

  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "25%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified by",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.localDate,
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourceOutDatasetScdPolicies({ sourceOutDatasetUuId }) {
  const [action, setAction] = useState();
  const [view, setView] = useState("list");
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = useSelector(
    (state) => state.sources.sourceOutStoresSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceOutStoreId: sourceOutStoreId,
    sourceOutStoreDatasetId: sourceOutDatasetUuId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceOutDatasetScdPoliciesSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceOutDatasetScdPoliciesSlice.status
  );
  const data = Object.values(dataMap);

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") confirmDelete();
  }

  function handleAddEdit(id) {
    if (id) {
      setView("edit");
      setCurrentId(id);
      dispatch(setActiveId(id));
    } else {
      setView("add");
      setCurrentId(null);
      dispatch(setActiveId(null));
    }
  }

  function handleSave(formData) {
    if (view === "add") {
      dispatch(
        createSourceOutDatasetScdPolicy({
          ...pathVariables,
          formData: formData,
        })
      );
    } else if (view === "edit") {
      dispatch(
        updateSourceOutDatasetScdPolicy({
          ...pathVariables,
          uuId: currentId,
          formData: formData,
        })
      );
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteSourceOutDatasetScdPolicy({
          ...pathVariables,
          uuId: id,
        })
      );
    });
    setCurrentId(null);
  }

  function handleCancel() {
    setView("list");
    setCurrentId(null);
    dispatch(setActiveId(null));
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  useEffect(() => {
    if (
      status &&
      [
        "createSourceOutDatasetScdPolicy",
        "updateSourceOutDatasetScdPolicy",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setView("list");
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteSourceOutDatasetScdPolicy" &&
      status.result === "success"
    ) {
      cancelDelete();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "error" &&
      (status.method === "createSourceOutDatasetScdPolicy" ||
        status.method === "updateSourceOutDatasetScdPolicy")
    ) {
      enqueueSnackbar("Error creating/updating record: " + status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceOutDatasetScdPolicies(pathVariables));
  }, [sourceOutDatasetUuId]);

  return (
    <React.Fragment>
      {view === "list" && (
        <NtaiPanel
          width="75%"
          header="SCD Policy"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleAddEdit}
            handleToolbarAction={handleToolbarAction}
            title="SCD Policy"
          />
        </NtaiPanel>
      )}

      {(view === "add" || (view === "edit" && currentId)) && (
        <SourceOutDatasetScdPolicyForm
          action={view}
          sourceOutDatasetId={sourceOutDatasetUuId}
          formData={view === "edit" ? dataMap[currentId] : null}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      )}

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
