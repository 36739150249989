import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";

const _ = require("lodash");

export default function DomainForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("domainForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ domainForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
      subheader="Enter domain name and description"
    >
      <NtaiTextField
        name="domainForm.name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />

      <NtaiTextField
        name="domainForm.description"
        label="Description*"
        multiline
        minRows={3}
        maxRows={3}
        rules={{ required: "Description is required" }}
      />
      <NtaiSwitchField name="domainForm.defaultFg" label="Default Domain?" />
    </NtaiDialogFormPanel>
  );
}
