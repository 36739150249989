import { Box, useTheme } from "@mui/material";
import NtaiFilterCheckboxGroup from "@ntai/components/inputs/filters/NtaiFilterCheckboxGroup";
import NtaiFilterDateRangeSlider from "@ntai/components/inputs/filters/NtaiFilterDateRangeSlider";
import NtaiFilterMultiSelectList from "@ntai/components/inputs/filters/NtaiFilterMultiSelectList";
import NtaiFilterRadioGroup from "@ntai/components/inputs/filters/NtaiFilterRadioGroup";
import NtaiFilterRangeSlider from "@ntai/components/inputs/filters/NtaiFilterRangeSlider";
import NtaiFilterSlider from "@ntai/components/inputs/filters/NtaiFilterSlider";
import SourceDerivedFieldForm from "app/main/pages/core/sourcedefinition/source/derivedfield/SourceDerivedFieldForm";
import {
  getSourceFilterSchemeField,
  clearStatus,
} from "app/main/pages/core/sourcedefinition/source/template/filter/fields/store/sourceFilterSchemeFieldsSlice";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SearchSourceContext } from "../../SearchSource";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiFilterServerSelect from "@ntai/components/inputs/filters/NtaiFilterServerSelect";
import server from "app/http/ntaiServer";
import NtaiFilterDate from "@ntai/components/inputs/filters/NtaiFilterDatePicker";
import NtaiFilterDatePicker from "@ntai/components/inputs/filters/NtaiFilterDatePicker";
import NtaiFilterDateRangePicker from "@ntai/components/inputs/filters/NtaiFilterDateRangePicker";
const _ = require("lodash");

export default function SearchFilterSchemeField(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [defaultOptionValuesLoaded, setDefaultOptionValuesLoaded] =
    useState(false);
  const [defaultOptionValues, setDefaultOptionValues] = useState([]);
  const [optionValues, setOptionValues] = useState([]);
  const [optionValuesLoaded, setOptionValuesLoaded] = useState(false);
  const { filterSchemeId, field, onFilterFieldChange } = props;

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const sourceDefId = _.get(searchSourceData, "searchSourceDefinition.uuId");
  const sourceId = _.get(searchSourceData, "searchSourceDefinition.sourceUuId");

  const filterSchemeFieldsDataMap = useSelector(
    (state) => state.sources.sourceFilterSchemeFieldsSlice.data
  );

  const filterSchemeFieldsStatus = useSelector(
    (state) => state.sources.sourceFilterSchemeFieldsSlice.status
  );

  const fieldData =
    filterSchemeFieldsDataMap && !_.isEmpty(filterSchemeFieldsDataMap)
      ? _.get(filterSchemeFieldsDataMap, field.uuId)
      : null;

  function generateOptionValues() {
    const result = [];

    if (fieldData && Array.isArray(fieldData.filterValues)) {
      fieldData.filterValues.forEach((fv, i) => {
        result.push({ value: fv, label: fv });
      });
    }

    return result;
  }

  function getFieldName(field) {
    return "filterForm." + field.sourceFieldUuId;
  }

  const loadOptions = (inputValue, callback) => {
    const toSearch = _.isEmpty(inputValue) ? "null" : inputValue;
    server
      .get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields/${_.get(
          field,
          "sourceFieldUuId"
        )}/suggest`,
        {
          params: {
            text: toSearch,
            size: 10,
            prefixFg: 1,
            derivedFieldFg:
              field["derivedFieldFg"] && field["derivedFieldFg"] === 1 ? 1 : 0,
          },
        }
      )
      .then((response) => {
        const options = [];
        response.data.forEach((text) => {
          options.push({
            label: text,
            value: text,
          });
        });
        callback(options);
      });
  };

  const loadDefaultOptions = () => {
    server
      .get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields/${_.get(
          field,
          "sourceFieldUuId"
        )}/suggest`,
        {
          params: {
            text: "",
            size: 10,
            prefixFg: 1,
            derivedFieldFg:
              field["derivedFieldFg"] && field["derivedFieldFg"] === 1 ? 1 : 0,
          },
        }
      )
      .then((response) => {
        const options = [];
        response.data.forEach((text) => {
          options.push({
            label: text,
            value: text,
          });
        });
        setDefaultOptionValues([..._.orderBy(options, ["label"], ["asc"])]);
      });
  };

  useEffect(() => {
    if (
      filterSchemeFieldsStatus &&
      filterSchemeFieldsStatus.result === "success" &&
      filterSchemeFieldsStatus.method === "getSourceFilterSchemeField"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      const tmpValues = generateOptionValues();
      setOptionValues([...tmpValues]);
    }
  }, [filterSchemeFieldsStatus]);

  useEffect(() => {
    if (_.isArray(optionValues) && optionValues.length > 0) {
      setOptionValuesLoaded(true);
    }
  }, [optionValues]);

  useEffect(() => {
    if (defaultOptionValues.length > 0) {
      setDefaultOptionValuesLoaded(true);
    }
  }, [defaultOptionValues]);

  useEffect(() => {
    if (field.fieldUiTypeCode === 1) {
      loadDefaultOptions();
    }
  }, []);

  useEffect(() => {
    dispatch(
      getSourceFilterSchemeField({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        filterSchemeId: filterSchemeId,
        uuId: _.get(field, "uuId"),
      })
    );
  }, []);

  return (
    <>
      {loaded && fieldData && !_.isEmpty(fieldData) && (
        <>
          {/* {field.fieldUiTypeCode === 1 && optionValuesLoaded && (
            <NtaiFilterMultiSelectList
              name={getFieldName(field)}
              label={_.get(field, "fieldUiLabel")}
              options={optionValues}
            />
          )} */}

          {field.fieldUiTypeCode === 1 && defaultOptionValuesLoaded && (
            <NtaiFilterServerSelect
              label={_.get(field, "fieldUiLabel")}
              name={getFieldName(field)}
              handleCallback={loadOptions}
              defaultOptions={[...defaultOptionValues]}
              placeholder="Search..."
            />
          )}

          {field.fieldUiTypeCode === 2 && optionValuesLoaded && (
            <NtaiFilterCheckboxGroup
              name={getFieldName(field)}
              label={_.get(field, "fieldUiLabel")}
              options={optionValues}
            />
          )}

          {field.fieldUiTypeCode === 3 && optionValuesLoaded && (
            <NtaiFilterRadioGroup
              label={_.get(field, "fieldUiLabel")}
              name={getFieldName(field)}
              row
              options={optionValues}
            />
          )}

          {field.fieldUiTypeCode === 4 && (
            <NtaiFilterSlider
              name={getFieldName(field)}
              label={_.get(field, "fieldUiLabel")}
              min={fieldData.filterValues[0]}
              max={fieldData.filterValues[1]}
              size="small"
            />
          )}

          {field.fieldUiTypeCode === 5 && _.has(fieldData, "uuId") && (
            <NtaiFilterRangeSlider
              name={getFieldName(field)}
              label={_.get(field, "fieldUiLabel")}
              min={fieldData.filterValues[0]}
              max={fieldData.filterValues[1]}
              size="small"
            />
          )}

          {field.fieldUiTypeCode === 6 && (
            <NtaiFilterDateRangeSlider
              label={_.get(field, "fieldUiLabel")}
              name={getFieldName(field)}
              min={fieldData.filterValues[0]}
              max={fieldData.filterValues[1]}
            />
          )}

          {/* {field.fieldUiTypeCode === 7 && (
            <NtaiFilterDatePicker
              label={_.get(field, "fieldUiLabel")}
              name={getFieldName(field)}
            />
          )} */}

          {field.fieldUiTypeCode === 7 && (
            <NtaiFilterDateRangePicker
              label={_.get(field, "fieldUiLabel")}
              name={getFieldName(field)}
              // from={fieldData.filterValues[0]}
              // to={fieldData.filterValues[1]}
            />
          )}
        </>
      )}
      {!loaded && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            py: theme.spacing(1),
          }}
        >
          <NtaiCircularProgress size={16} />
        </Box>
      )}
    </>
  );
}
