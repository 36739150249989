import React, { useEffect, useState } from "react";
import server from "app/http/ntaiServer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip, useTheme } from "@mui/material";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import { getSourceDefinitions } from "../../store/sourceDefinitionsSlice";
import { useSnackbar } from "notistack";
import {
  clearStatus,
  importSourceConfig,
} from "./store/sourceExportImportSlice";
import {
  faArrowDownToBracket,
  faArrowUpFromBracket,
  faChartMixed,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiForm from "@ntai/components/forms/NtaiForm";

export default function SourceExportImport({ type, sourceDefId, sourceId }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [fileUrl, setFileUrl] = useState();
  const dispatch = useDispatch();
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [importObject, setImportObject] = useState({});

  const status = useSelector(
    (state) => state.sources.sourceExportImportSlice.status
  );

  function handleImportDialogOpen() {
    setImportDialogOpen(true);
  }

  function handleImportDialogClose() {
    setImportDialogOpen(false);
  }

  function onHandleFileUpload(formData, config) {
    setImportObject({
      formData: formData,
      config: config,
    });
  }

  function handleExport() {
    server
      .get(`/sourcedefs/${sourceDefId}/sources/${sourceId}/export`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/binary",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        setFileUrl(fileURL);
        //Open the URL on new Window
        window.open(fileURL);
        // setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleImport() {
    dispatch(
      importSourceConfig({
        formData: importObject["formData"],
        config: importObject["config"],
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "importSourceConfig" &&
      status.result === "success"
    ) {
      handleImportDialogClose();
      enqueueSnackbar("Successfully imported source", {
        variant: "success",
      });
      dispatch(clearStatus());
      dispatch(getSourceDefinitions());
    }
  }, [status]);

  return (
    <>
      <Tooltip title="Export Configuration">
        <Button
          size="small"
          sx={{ fontSize: "11px", fontWeight: 700 }}
          color="inherit"
          startIcon={
            <FontAwesomeIcon
              icon={
                type === "export" ? faArrowUpFromBracket : faArrowDownToBracket
              }
            />
          }
          onClick={() =>
            type === "export" ? handleExport() : handleImportDialogOpen()
          }
        >
          {type === "export" ? "EXPORT" : "IMPORT"}
        </Button>
      </Tooltip>

      <NtaiDialog
        title="Upload Source Json"
        open={importDialogOpen}
        handleDialogClose={handleImportDialogClose}
        size="sm"
      >
        <NtaiForm>
          <NtaiDialogFormPanel
            title="Upload File"
            subheader="Upload exported json file to import source"
            handleSave={handleImport}
            handleCancel={handleImportDialogClose}
          >
            <NtaiFileUpload
              pending={false}
              handleFileUpload={onHandleFileUpload}
            />
          </NtaiDialogFormPanel>
        </NtaiForm>
      </NtaiDialog>
    </>
  );
}
