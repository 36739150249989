import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import * as qs from "qs";
import _ from "lodash";

export const getSourceDefinitions = createAsyncThunk(
  "sourceDefinitions/getSourceDefinitions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await server.get(
        "/sourcedefs",
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceDefinition = createAsyncThunk(
  "sourceDefinitions/getSourceDefinition",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/sourcedefs/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceDefinition = createAsyncThunk(
  "sourceDefinitions/createSourceDefinition",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/sourcedefs", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceDefinition = createAsyncThunk(
  "sourceDefinitions/updateSourceDefinition",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/sourcedefs/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceDefinition = createAsyncThunk(
  "sourceDefinitions/deleteSourceDefinition",
  async (sourceDefinitionId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/sourcedefs/${sourceDefinitionId}`);
      return { sourceDefinitionId: sourceDefinitionId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceDefinitionsSlice = createSlice({
  name: "sourceDefinitions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceDefinitions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceDefinitions" };
    },
    [getSourceDefinitions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDefinitions",
        message: action.payload.message,
      };
    },

    [getSourceDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceDefinition" };
    },
    [getSourceDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDefinition",
        message: action.payload.message,
      };
    },

    [createSourceDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceDefinition" };
    },
    [createSourceDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceDefinition",
        message: action.payload.message,
      };
    },
    [updateSourceDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceDefinition" };
    },
    [updateSourceDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceDefinition",
        message: action.payload.message,
      };
    },
    [deleteSourceDefinition.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.sourceDefinitionId);
      state.status = { result: "success", method: "deleteSourceDefinition" };
    },
    [deleteSourceDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceDefinition",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceDefinitionsSlice.actions;

export default sourceDefinitionsSlice.reducer;
