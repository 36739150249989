import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUserDatasets = createAsyncThunk(
  "userDatasets/getUserDatasets",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/library/datasets`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserDataset = createAsyncThunk(
  "userDatasets/getUserDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId } = values;
      const response = await server.get(`/library/datasets/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserDatasetBySourceId = createAsyncThunk(
  "userDatasets/getUserDatasetBySourceId",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.put("/library/datasets", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserDataset = createAsyncThunk(
  "userDatasets/createUserDataset",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(`/library/datasets`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserDataset = createAsyncThunk(
  "userDatasets/updateUserDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/library/datasets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserDataset = createAsyncThunk(
  "userDatasets/deleteUserDataset",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/library/datasets/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userDatasetsSlice = createSlice({
  name: "userDatasets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserDatasets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getUserDatasets" };
    },
    [getUserDatasets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDatasets",
        message: action.payload.message,
      };
    },
    [getUserDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getUserDataset" };
    },
    [getUserDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDataset",
        message: action.payload.message,
      };
    },

    [getUserDatasetBySourceId.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getUserDatasetBySourceId" };
    },
    [getUserDatasetBySourceId.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDatasetBySourceId",
        mesage: action.payload.mesage,
      };
    },

    [createUserDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createUserDataset" };
      state.activeId = action.payload.uuId;
    },
    [createUserDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserDataset",
        message: action.payload.message,
      };
    },
    [updateUserDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateUserDataset" };
    },
    [updateUserDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUserDataset",
        message: action.payload.message,
      };
    },
    [deleteUserDataset.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteUserDataset" };
    },
    [deleteUserDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUserDataset",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = userDatasetsSlice.actions;

export default userDatasetsSlice.reducer;
