import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import history from "@ntai/@history";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function DatasourceSelect({ action, handleSave, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [type, setType] = useState();
  const [datasourceFormDialogOpen, setDatasourceFormDialogOpen] =
    useState(false);

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const datasourceOptions = _.has(
    codes,
    CODELIST_CODES.COR_DATASOURCE_TYPE_CODE
  )
    ? _.get(codes, CODELIST_CODES.COR_DATASOURCE_TYPE_CODE)
    : [];

  function handleSelect(pType) {
    history.push(`/admin/datasources/${pType}/create`);
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.COR_DATASOURCE_TYPE_CODE));
  }, []);

  return (
    <React.Fragment>
      <NtaiPanel
        width="100%"
        title="Select data source"
        subheader="Select the data source type from the option below to proceed further"
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: theme.spacing(1),
          }}
        >
          {datasourceOptions.length > 0 &&
            datasourceOptions.map((o, i) => (
              <Button size="small" onClick={() => handleSelect(o.value)}>
                {o.label}
              </Button>
            ))}
        </Box>
      </NtaiPanel>
    </React.Fragment>
  );
}
