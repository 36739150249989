import { Box, Button, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlertDefinitionInitAdj,
  clearStatus,
} from "../../../definition/init-adj/store/alertDefinitionInitAdjsSlice";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import {
  getObject,
  clearStatus as clearObjectsSliceStatus,
} from "app/main/pages/core/cdo/object/store/objectsSlice";
import { useFormContext } from "react-hook-form";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

export default function AlertEditSearchDistRequestForm({
  initData,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { reset } = useFormContext();
  const [adjDataLoaded, setAdjDataLoaded] = useState(false);
  const [adjFormLoaded, setAdjFormLoaded] = useState(false);
  const [objectsLoaded, setObjectsLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const alertDefId = _.has(initData, "alertDefId")
    ? initData["alertDefId"]
    : null;

  const adjObjectId = _.has(initData, "adjObjectUuId")
    ? initData["adjObjectUuId"]
    : null;

  const data = useSelector(
    (state) => state.monitor.alertDefinitionInitAdjsSlice.data
  );

  const status = useSelector(
    (state) => state.monitor.alertDefinitionInitAdjsSlice.status
  );

  const adjObjectDataMap = useSelector((state) => state.core.objectsSlice.data);
  const adjObject =
    adjObjectDataMap && !_.isEmpty(adjObjectDataMap)
      ? adjObjectDataMap[adjObjectId]
      : null;

  const adjObjectSliceStatus = useSelector(
    (state) => state.core.objectsSlice.status
  );

  //   console.log("init data  object: ", initData);
  //   console.log("Adj object: ", adjObject);
  //   console.log("data: ", data);

  useEffect(() => {
    if (adjFormLoaded && adjDataLoaded) {
      reset({
        initAdjRequestForm: ObjectFormUtil.populateFormData(
          _.get(data, "adjudication.clientData")
        ),
      });
      setLoaded(true);
    }
  }, [adjFormLoaded, adjDataLoaded]);

  useEffect(() => {
    if (
      adjObjectSliceStatus &&
      adjObjectSliceStatus.method === "getObject" &&
      adjObjectSliceStatus.result === "success"
    ) {
      setAdjFormLoaded(true);
      dispatch(clearObjectsSliceStatus());
    }
  }, [adjObjectSliceStatus]);

  useEffect(() => {
    if (
      status &&
      status.method === "getAlertDefinitionInitAdj" &&
      status.result === "success"
    ) {
      setAdjDataLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getAlertDefinitionInitAdj(alertDefId));
  }, [alertDefId]);

  useEffect(() => {
    dispatch(getObject({ domainId: "*", uuId: adjObjectId }));
  }, [adjObjectId]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "320px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", width: "100%", height: `calc(100% - 54px)` }}>
        <Scrollbar autoHide={true}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: `100%`,
              mt: theme.spacing(1),
            }}
          >
            {loaded &&
              adjObject &&
              adjObject["objectForm"] &&
              ObjectFormUtil.generateForm(
                "initAdjRequestForm",
                adjObject["objectForm"],
                false,
                theme
              )}

            {!loaded && <NtaiCircularProgress size={24} />}
          </Box>
        </Scrollbar>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "54px",
          width: "100%",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Button size="small" onClick={handleCancel}>
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
