import { useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";

export default function SourceInStoreDatasetForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceInStoreDatasetForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    if (action === "edit") {
      reset({ sourceInStoreDatasetForm: formData });
    }
  }, [formData]);

  return (
    <NtaiFormPanel
      width={action === "edit" && "100%"}
      header={action === "add" && "Add Dataset"}
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="sourceInStoreDatasetForm.name"
        label="Name*"
        placeholder="Name"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="sourceInStoreDatasetForm.description"
        label="Description"
        placeholder="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
      <NtaiTextField
        name="sourceInStoreDatasetForm.incMigCondition"
        multiline
        minRows={3}
        maxRows={3}
        label="Incremental Migration Condition"
        placeholder="date_modified >= $F{last_migration_date}"
        helpText="Last run time = $LAST_RUN_TIME"
      />
    </NtaiFormPanel>
  );
}
