import { Box } from "@mui/material";
import React from "react";

export default function MarkerPin(props) {
  return (
    <Box
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <img height="12" width="12" src="/images/round_dot.jpeg" />
    </Box>
  );
}
