import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import history from "@ntai/@history/@history";
import { useSelector } from "react-redux";
import NtaiCompactCard from "@ntai/components/cards/NtaiCompactCard";
import NtaiIcons from "utils/NtaiIcons";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const sourcePanels = [
  {
    id: "ins",
    icon: "inboxIn",
    label: "Inbound Stores",
    description:
      "Define input stores to ingest data in pre-defined formats. For example, CSV, SAS, JSON, XML, PDF-Scanned etc.",
  },
  {
    id: "pipelines",
    icon: "timeline",
    label: "Data Pipelines",
    description:
      "Manage data pipelines for end to end ETL tasks using embedded spark environment.",
  },
  {
    id: "outstores",
    icon: "inboxOut",
    label: "Target Stores",
    description:
      "Define target stores to store data in specified formats. For example, RDBMS, Hive, Elastic.",
  },
  {
    id: "migrations",
    icon: "clock",
    label: "Migrations",
    description:
      "Define schedule for output stores. You can associate one or more output stores with a job.",
  },

  {
    id: "derivedfield",
    icon: "inputText",
    label: "Derived Fields",
    description: "Define derived fields using painless script.",
  },
  {
    id: "customfields",
    icon: "inputText",
    label: "Custom Fields",
    description: "Define custom fields using uder defined functions",
  },
  {
    id: "view",
    icon: "views",
    label: "Views",
    description:
      "Create and manage user specific views using fields and supported widgets",
  },
  {
    id: "adjudication",
    icon: "adjudication",
    label: "Assessment Forms",
    description:
      "Manage assessment forms to capture data related to source record",
  },
  {
    id: "policies",
    icon: "settings",
    label: "Policies",
    description: "Manage policies related to display and assessments",
  },
  {
    id: "user-widgets",
    icon: "chartSimple",
    label: "User Widgets",
    description: "Create and manage user widgets using custom code",
  },
  {
    id: "template",
    icon: "fileCode",
    label: "Templates",
    description:
      "Define and manage source templates for various tasks including Listing, Assessment etc.",
  },
  {
    id: "object",
    icon: "shapes",
    label: "Objects",
    description:
      "Associate one or more business domain objects with the source",
  },
  {
    id: "linkedsource",
    icon: "link",
    label: "Linked Sources",
    description: "Define and manage linked sources for a given source",
  },
  // {
  //   id: "thesaurus",
  //   icon: "thesaurus",
  //   label: "Thesauruses",
  //   description:
  //     "Assign controlled vocabularies, dictionaries and thesaruses to source",
  // },
];

export default function SourceHome({ sourceDefId, sourceId }) {
  const theme = useTheme();

  //   const sourceDefId = props.match.params.sourcedefid;
  //   const sourceId = props.match.params.id;
  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionData = _.get(sourceDefDataMap, sourceDefId);

  const dataMap = useSelector((state) => state.sources.sourcesSlice.data);
  const status = useSelector((state) => state.sources.sourcesSlice.status);
  const source = _.get(dataMap, sourceId);

  function handleClick(action) {
    if (action === "ins")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins`);

    if (action === "pipelines")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines`);

    if (action === "pipeline")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/pipeline`);

    if (action === "outstores")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`);

    if (action === "migrations")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs`);

    if (action === "derivedfield")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields`
      );
    if (action === "customfields")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/custom-fields`
      );
    if (action === "view")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/views`);
    if (action === "adjudication")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`
      );
    if (action === "policies")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policy-home`
      );
    if (action === "user-widgets")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`
      );
    if (action === "template")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/templates`);
    if (action === "linkedsource")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`
      );
    if (action === "object")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/objects`);

    if (action === "thesaurus")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/thesauruses`
      );
  }

  return (
    <Scrollbar>
      <NtaiPanel
        width="100%"
        header={`Version ${_.get(source, "majorVersion")}.${_.get(
          source,
          "minorVersion"
        )}`}
        subheader="Manage versions for sources for each source definitions"
        // headerActions={
        //   <Box
        //     sx={{
        //       display: "flex",
        //       justifyContent: "end",
        //       gap: theme.spacing(1),
        //     }}
        //   >
        //     <Button size="small" startIcon={<FontAwesomeIcon icon={faGrid} />}>
        //       3 Versions
        //     </Button>
        //     <Button
        //       size="small"
        //       startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
        //       variant="contained"
        //     >
        //       {`Approve ${_.get(source, "majorVersion")}.${_.get(
        //         source,
        //         "minorVersion"
        //       )}`}
        //     </Button>
        //   </Box>
        // }
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            justifyContent: "center",
            p: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: theme.spacing(2),
            }}
          >
            {sourcePanels.map((panel, index) => {
              return (
                <NtaiCompactCard
                  key={`source-manage-action-${index}`}
                  id={panel.id}
                  avatar={<FontAwesomeIcon icon={NtaiIcons[panel.icon]} />}
                  title={panel.label}
                  hits=""
                  handleClick={() => handleClick(panel.id)}
                >
                  {panel.description}
                </NtaiCompactCard>

                // <NtaiActionsPanel
                //   width="320px"
                //   height="190px"
                //   key={`source-panel-${index}`}
                //   id={panel.id}
                //   avatar={
                //     <Avatar sx={{ bgcolor: "red" }}>
                //       <FontAwesomeIcon icon={faClose} />
                //     </Avatar>
                //   }
                //   title={panel.label}
                //   subheader="Modify templates"
                //   actions={[{ value: panel.id, label: "Edit configuration" }]}
                //   handleAction={handleAction}
                // >
                //   <Typography variant="subtitle1">
                //     {panel.description}
                //   </Typography>
                // </NtaiActionsPanel>
              );
            })}
          </Box>
        </Box>
      </NtaiPanel>
    </Scrollbar>
  );
}
