export const JSONObjectType = {
  Array: 1,
  ObjectWithNonNumericKeys: 2,
  Object: 3,
  Primitive: 4,
};

export default class JsonToTableUtils {
  /**
   * Get object type
   */
  static getObjectType(obj) {
    if (obj !== null && typeof obj === "object") {
      if (Array.isArray(obj)) {
        return JSONObjectType.Array;
      } else {
        if (Object.keys(obj).length) {
          return JSONObjectType.ObjectWithNonNumericKeys;
        } else {
          return JSONObjectType.Object;
        }
      }
    } else {
      return JSONObjectType.Primitive;
    }
  }

  static checkLabelTypes(labels) {
    const reduced = labels.reduce(
      (accumulator, value) =>
        accumulator + (isNaN(Number(value)) ? value : Number(value)),
      0
    );
    return typeof reduced === "number" ? "number" : "string";
  }

  static getUniqueObjectKeys(anArray) {
    let labels = [];
    const objectType = JSONObjectType.Object;
    anArray.forEach((item) => {
      labels = labels.concat(Object.keys(item)).filter((elem, pos, arr) => {
        return arr.indexOf(elem) === pos;
      });
    });

    return { labels, type: objectType };
  }
}
