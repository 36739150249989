import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, IconButton, Typography, lighten, useTheme } from "@mui/material";
import { faArchive, faDeleteLeft } from "@fortawesome/pro-light-svg-icons";
import NtaiIconButton from "../buttons/NtaiIconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NtaiDnd3(props) {
  const theme = useTheme();

  const {
    id,
    label,
    items,
    direction,
    handleReorder,
    secondaryActions,
    handleSecondaryAction,
  } = props;
  const [dndItems, setDndItems] = useState([]);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    handleReorder(result);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: theme.spacing(1),
    margin: `0 0 ${grid}px 0`,
    border: "1.5px dashed gray",
    borderRadius: theme.general.borderRadius,
    // change background colour if dragging
    background: isDragging ? lighten(theme.colors.secondary.light, 0.6) : null,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    display: "flex",
    gap: theme.spacing(1),
    width: "100%",
    flexWrap: direction === "horizontal" ? "wrap" : null,
    flexDirection: props.hasOwnProperty("direction")
      ? direction === "horizontal"
        ? "row"
        : "column"
      : "column",
  });

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tmpItems = reorder(
      dndItems,
      result.source.index,
      result.destination.index
    );

    setDndItems([...tmpItems]);
  }

  useEffect(() => {
    // const tmpItems = Array.from({ length: 10 }, (v, k) => k).map((k) => ({
    //   id: `item-${k}`,
    //   content: `item ${k}`,
    // }));

    setDndItems([...items]);
  }, [items]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        direction={props.hasOwnProperty("direction") ? direction : "vertical"}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {dndItems.map((item, index) => (
              <Draggable key={item[id]} draggableId={item[id]} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: theme.spacing(1),
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="inherit"
                        fontWeight="700"
                      >
                        {props.hasOwnProperty("label")
                          ? item[label]
                          : "Label not available"}
                      </Typography>
                      {props.hasOwnProperty("secondaryActions") &&
                        props.hasOwnProperty("handleSecondaryAction") && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: theme.spacing(0.5),
                            }}
                          >
                            {secondaryActions.map((sa, i) => (
                              <IconButton
                                key={`ntai-dnd-secondary-action-${i}`}
                                sx={{
                                  height: "24px",
                                  width: "24px",
                                  borderRadius: "6px",
                                  cursor: "default",
                                }}
                                onClick={(e) =>
                                  handleSecondaryAction(sa["value"], item[id])
                                }
                                disabled={
                                  sa.hasOwnProperty("disabled")
                                    ? sa["disabled"]
                                    : false
                                }
                              >
                                <FontAwesomeIcon size="2xs" icon={sa["icon"]} />
                              </IconButton>
                            ))}
                          </Box>
                        )}
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
