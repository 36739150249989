import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReportContext } from "../../ReportMain";
import ReportSourceFilterField from "./ReportSourceFilterField";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
const _ = require("lodash");

function getFilterValues(rp) {
  const resultValues = [];
  if (
    _.isArray(rp["reportParameterValues"]) &&
    rp["reportParameterValues"].length > 0
  ) {
    _.orderBy(rp["reportParameterValues"], ["valueOrder"], ["asc"]).forEach(
      (rpv, i) => {
        resultValues.push(rpv["value"]);
      }
    );
  }

  return resultValues.join(", ");
}

export default function ReportSourceFilters() {
  const theme = useTheme();

  const { action, reportDefinition, reportFormData } =
    React.useContext(ReportContext);

  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <Box
        sx={{
          p: theme.spacing(2),
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
        }}
      >
        <Typography variant="h6">Report Criteria</Typography>
      </Box>

      {action !== "edit" && (
        <Scrollbar autoHide={true}>
          {_.orderBy(
            _.get(reportDefinition, "reportDefinitionParameters"),
            ["paramOrder"],
            ["asc"]
          ).map((filterField, i) => (
            <React.Fragment key={`report-filter-field-${i}`}>
              <ReportSourceFilterField field={filterField} />
              <Divider />
            </React.Fragment>
          ))}
        </Scrollbar>
      )}
      {action === "edit" && (
        <Scrollbar autoHide={true}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {_.orderBy(
              _.get(reportFormData, "reportParameters"),
              ["reportDefinitionParameter.paramOrder"],
              ["asc"]
            ).map((rp, i) => (
              <Box
                key={`report-param-${i}`}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box sx={{ px: theme.spacing(2), py: theme.spacing(1) }}>
                  <NtaiLabelValue1
                    label={rp["reportDefinitionParameter"]["name"]}
                    value={getFilterValues(rp)}
                  />
                </Box>

                <Divider />
              </Box>
            ))}
          </Box>
        </Scrollbar>
      )}
    </Box>
  );
}
