import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUserDatasetRecords = createAsyncThunk(
  "userDatasetRecords/getUserDatasetRecords",
  async (values, { rejectWithValue }) => {
    try {
      const { datasetId, params } = values;
      const response = await server.get(
        `/library/datasets/${datasetId}/records`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getUserDatasetRecord = createAsyncThunk(
//   "userDatasetRecords/getUserDatasetRecord",
//   async (values, { rejectWithValue }) => {
//     try {
//       const { datasetId, uuId } = values;
//       const response = await server.get(
//         `/library/datasets/${datasetId}/records/${uuId}`
//       );
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const userDatasetRecordsSlice = createSlice({
  name: "userDatasetRecords",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserDatasetRecords.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "recordId") };
      state.status = { result: "success", method: "getUserDatasetRecords" };
    },
    [getUserDatasetRecords.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDatasetRecords",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = userDatasetRecordsSlice.actions;

export default userDatasetRecordsSlice.reducer;
