import ToolsAssessmentImportCreate from "./ToolsAssessmentImportCreate";
import ToolsAssessmentImportList from "./ToolsAssessmentImportList";

const ToolsAssessmentImportConfig = {
  routes: [
    {
      path: "/tools/assessment-import",
      exact: true,
      component: ToolsAssessmentImportList,
    },
    {
      path: "/tools/assessment-import/create",
      exact: true,
      component: ToolsAssessmentImportCreate,
    },
    {
        path: "/tools/assessment-import/create",
        exact: true,
        component: ToolsAssessmentImportCreate,
      },
    // {
    //   path: "/admin/users/:id/edit",
    //   exact: true,
    //   component: UserEdit,
    // },
  ],
};

export default ToolsAssessmentImportConfig;
