import {
  faArchive,
  faEdit,
  faMinus,
  faRefresh,
} from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import NtaiColorBox from "@ntai/components/color-picker/NtaiColorBox";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import SourceWidgetFieldColorDialog from "./SourceWidgetFieldColorSchemeForm";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createSourceWidgetFieldColor,
  deleteSourceWidgetFieldColor,
  getSourceWidgetFieldColors,
  updateSourceWidgetFieldColor,
} from "./store/sourceWidgetFieldColorsSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

function getColorValueRule(val) {
  let result = "";
  if (val) {
    if (val["exactValue"] && val["exactValue"].length > 0) {
      result = val["exactValue"];
    } else {
      result = [
        val["ruleGtValue"].toString(),
        val["ruleGtOperatorCode"] === 1 ? "<" : "<=",
        "value",
        val["ruleLtOperatorCode"] === 1 ? "<" : "<=",
        val["ruleLtValue"].toString(),
      ].join(" ");
    }
  }

  return result;
}

export default function SourceWidgetFieldColorSchemes({
  selectedField,
  colorFormatCode,
  sourceWidgetId,
  handleReset,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { setValue, reset, watch } = useFormContext();
  const [currentId, setCurrentId] = useState();
  const [action, setAction] = useState();
  const [loaded, setLoaded] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const viewId = useSelector(
    (state) => state.sources.sourceViewsSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    viewId: viewId,
    sourceWidgetId: sourceWidgetId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceWidgetFieldColorsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceWidgetFieldColorsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : null;

  function handleSave(formData) {
    if (action === "add") {
      dispatch(
        createSourceWidgetFieldColor({
          ...pathVariables,
          sourceWidgetFieldId: selectedField["uuId"],
          formData: formData,
        })
      );
    } else {
      dispatch(
        updateSourceWidgetFieldColor({
          ...pathVariables,
          sourceWidgetFieldId: selectedField["uuId"],
          uuId: formData["uuId"],
          formData: formData,
        })
      );
    }
  }

  function handleCancel() {
    setOpen(false);
    setCurrentId(null);
  }

  function handleDelete(id) {
    dispatch(
      deleteSourceWidgetFieldColor({
        ...pathVariables,
        sourceWidgetFieldId: selectedField["uuId"],
        uuId: id,
      })
    );
  }

  function onHandleAddOrEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    setOpen(true);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      ["createSourceWidgetFieldColor", "updateSourceWidgetFieldColor"].includes(
        status.method
      )
    ) {
      dispatch(clearStatus());
      handleCancel();
    }

    if (
      status &&
      status.result === "success" &&
      ["getSourceWidgetFieldColors"].includes(status.method)
    ) {
      dispatch(clearStatus());
      setLoaded(true);
    }

    if (
      status &&
      status.result === "success" &&
      ["deleteSourceWidgetFieldColor"].includes(status.method)
    ) {
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceWidgetFieldColors({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        viewId: viewId,
        sourceWidgetId: sourceWidgetId,
        sourceWidgetFieldId: !_.isEmpty(selectedField)
          ? selectedField["uuId"]
          : "*",
      })
    );
  }, [selectedField]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "36px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1">{`Color Rules (${
              data ? data.length : 0
            })`}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              size="small"
              // disabled={colorFormatCode ? false : true}
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center",
                fontSize: "11px",
              }}
              onClick={() => onHandleAddOrEdit(null)}
            >
              <FontAwesomeIcon icon={faPlus} />
              ADD
            </Button>
            <Button
              size="small"
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center",
                fontSize: "11px",
              }}
              onClick={() => handleReset()}
            >
              <FontAwesomeIcon icon={faMinus} />
              RESET
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "calc(100% - 36px)",
            width: "100%",
          }}
        >
          {data && _.isArray(data) && data.length > 0 && (
            <Scrollbar>
              <List sx={{ p: 0, border: theme.general.border1 }}>
                {data.map((d, i) => (
                  <React.Fragment>
                    <ListItem key={`source-widget-field-color-listitem-${i}`}>
                      <ListItemText
                        primary={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: theme.spacing(1),
                            }}
                          >
                            <NtaiColorBox
                              bg={d["hexBgCode"]}
                              fg={d["hexFgCode"]}
                            />
                            <Typography variant="subtitle1" color="inherit">
                              {getColorValueRule(d)}
                            </Typography>
                          </Box>
                        }
                      />
                      <Box
                        sx={{
                          display: "flex",
                          gap: theme.spacing(1),
                        }}
                      >
                        <NtaiIconButton
                          icon={faEdit}
                          handleClick={() => onHandleAddOrEdit(d["uuId"])}
                        />
                        <NtaiIconButton
                          icon={faArchive}
                          handleClick={() => handleDelete(d["uuId"])}
                        />
                      </Box>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Scrollbar>
          )}
          {selectedField &&
            loaded &&
            data &&
            _.isArray(data) &&
            data.length === 0 && (
              <NtaiEmptyMessage header="No schemes available" />
            )}
        </Box>
      </Box>
      <NtaiDialog
        title="Select Color"
        open={open}
        size="sm"
        handleDialogClose={() => setOpen(false)}
      >
        {(action === "add" || (action === "edit" && currentId)) && (
          <SourceWidgetFieldColorDialog
            widgetId={sourceWidgetId}
            widgetFieldId={selectedField["uuId"]}
            action={action}
            formData={action === "edit" ? dataMap[currentId] : null}
            colorFormatCode={colorFormatCode}
            handleSave={handleSave}
            handleCancel={() => setOpen(false)}
          />
        )}
      </NtaiDialog>
    </>
  );
}
