import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import {
  clearStatus,
  createSourceOutDataset,
} from "../store/sourceOutDatasetsSlice";
import SourceOutStoreDbDatasetForm from "./SourceOutStoreDbDatasetForm";

export default function SourceOutStoreDbDatasetCreate(props) {
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.status
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = props.match.params.id;

  const activeId = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.activeId
  );

  function handleFormSubmit(formData) {
    dispatch(
      createSourceOutDataset({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceOutStoreId: sourceOutStoreId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceOutDataset" &&
      status.result === "success"
    ) {
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/dbdatasets/${activeId}/edit`
      );
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Add Dataset"
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/dbdatasets`}
    >
      <NtaiForm>
        <SourceOutStoreDbDatasetForm
          action="add"
          handleFormSubmit={handleFormSubmit}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
