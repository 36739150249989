import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import History from "@ntai/@history/@history";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const widgetTypes = [
  {
    id: "query-widget",
    avatar: "Q",
    label: "Query Widget",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    id: "source-widget",
    avatar: "S",
    label: "Source Widget",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

export default function DashboardWidgetSelection() {
  const theme = useTheme();

  function selectWidgetType(widgetTypeId) {
    History.push(`/admin/dashboards/widgets/create-2/${widgetTypeId}`);
  }

  return (
    <NtaiPage title="Widget Selection">
      <NtaiPanel
        width="75%"
        header="Make Selection"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      >
        <Box
          sx={{
            mt: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "50%",
              alignItems: "center",
              border: theme.general.border1,
              borderRadius: theme.general.borderRadius,
            }}
          >
            <List>
              {widgetTypes.map((widgetType, i) => (
                <React.Fragment key={`widget-type-${widgetType.id}`}>
                  <ListItemButton
                    onClick={() => selectWidgetType(widgetType.id)}
                  >
                    <ListItemAvatar>
                      <Avatar>{widgetType.avatar}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" fontWeight="700">
                          {widgetType.label}
                        </Typography>
                      }
                      secondary={widgetType.description}
                    />
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Box>
      </NtaiPanel>
    </NtaiPage>
  );
}
