import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";

import {
  clearStatus,
  setActiveId,
  updateSourceAdjudicationImportCsvScheme,
} from "./store/sourceAdjudicationImportCsvSchemesSlice";
import SourceAdjudicationImportCsvSchemeForm from "./SourceAdjudicationImportCsvSchemeForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SourceAdjudicationImportCsvSchemeEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { id, handleCancel } = props;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceAdjudicationId = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.activeId
  );

  const status = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.data
  );

  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceAdjudicationImportCsvScheme({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceAdjudicationId: sourceAdjudicationId,
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceAdjudicationImportCsvScheme" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Source adjudication scheme "${formData["name"]}" updated successfully`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiForm>
      <SourceAdjudicationImportCsvSchemeForm
        action="edit"
        formData={formData}
        handleFormSubmit={handleSave}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
