import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setActiveId } from "./store/objectDashboardsSlice";
import ObjectDashboardSourceViews from "./ObjectDashboardSourceViews";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

export const ObjectDashboardContext = React.createContext();

export default function ObjectDashboardForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [selectedDashboardViews, setSelectedDashboardViews] = useState();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const typeOptions =
    codelistData &&
    !_.isEmpty(codelistData) &&
    _.has(codelistData, CODELIST_CODES.COR_OBJECT_DASHBOARD_TYPE_CODE)
      ? _.get(codelistData, CODELIST_CODES.COR_OBJECT_DASHBOARD_TYPE_CODE)
      : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectDashboardForm")
    );
    const revisedFormData = {
      ...sanitizedFormData,
      objectDashboardViews:
        action === "edit" ? formData["objectDashboardViews"] : [],
    };

    handleSave(revisedFormData);
  }

  function handleUpdateViews(views) {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectDashboardForm")
    );
    const revisedFormData = {
      ...sanitizedFormData,
      objectDashboardViews: views,
    };

    handleSave(revisedFormData);
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.COR_OBJECT_DASHBOARD_TYPE_CODE));
    reset({ objectDashboardForm: formData });
  }, [formData]);

  return (
    <ObjectDashboardContext.Provider
      value={{
        selectedDashboardViews,
        setSelectedDashboardViews,
        onHandleSave,
      }}
    >
      <NtaiTabs>
        <Box label="GENERAL">
          <NtaiFormPanel
            width="50%"
            subheader="Lorem ipsum more text to follow Lorem ipsum more text to follow Lorem ipsum more text to follow"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <NtaiTextField
                name="objectDashboardForm.name"
                label="Name*"
                placeholder="Name"
                rules={{ required: "Name is required" }}
              />
              <NtaiTextField
                name="objectDashboardForm.description"
                label="Description"
                placeholder="Description"
                multiline
                minRows={3}
                maxRows={3}
              />
              <NtaiSelectField
                name="objectDashboardForm.typeCode"
                label="Type"
                options={typeOptions}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
              >
                <NtaiSwitchField
                  name="objectDashboardForm.displayRecordsFg"
                  label="Display Records?"
                />
                <NtaiSwitchField
                  name="objectDashboardForm.displayFiltersFg"
                  label="Display Filters?"
                />
              </Box>
            </Box>
          </NtaiFormPanel>
        </Box>
        <Box label="SOURCE VIEWS" visible={action === "add" ? false : true}>
          <ObjectDashboardSourceViews
            formData={formData}
            handleUpdate={handleUpdateViews}
          />
        </Box>
      </NtaiTabs>
    </ObjectDashboardContext.Provider>
  );
}
