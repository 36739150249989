import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsStatus,
} from "../../store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearDerivedFieldsStatus,
} from "../store/sourceDerivedFieldsSlice";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";

const _ = require("lodash");

export default function SourceDerivedFieldElemForm(props) {
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const {
    sourceDerivedFieldId,
    action,
    handleDialogClose,
    formData,
    handleFormSubmit,
  } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const statusSourceFields = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const statusDerivedFields = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFields =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap).map((f) => {
          return {
            uuId: f.uuId,
            name: f.name,
            label: f.label,
            type: 1,
          };
        })
      : [];

  const sourceDerivedFields =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap).map((f) => {
          return {
            uuId: f.uuId,
            name: f.name,
            label: f.label + " (d)",
            type: 2,
          };
        })
      : [];

  const allFieldsData = [...sourceFields, ...sourceDerivedFields];

  const allFieldsDataOptions =
    _.isArray(allFieldsData) && allFieldsData.length > 0
      ? _.orderBy(
          _.filter(
            NtaiUtils.getSelectOptions(allFieldsData, "uuId", "label"),
            function (o) {
              return o["value"] !== sourceDerivedFieldId ? true : false;
            }
          ),
          ["label"],
          ["asc"]
        )
      : [];

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const metricCodeOptions = _.get(
    codelistData,
    CODELIST_CODES.SRC_SOURCE_VIEW_WIDGET_METRIC_CODELIST
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceDerivedFieldElemForm")
    );
    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    if (
      statusSourceFields &&
      //   statusDerivedFields &&
      statusSourceFields.result === "success" &&
      statusSourceFields.method === "getSourceFields"
      //   statusDerivedFields.result === "success" &&
      //   statusDerivedFields.method === "getSourceDerivedFields"
    ) {
      dispatch(clearSourceFieldsStatus());
      //   dispatch(clearDerivedFieldsStatus());
      setLoaded(true);
    }
  }, [statusSourceFields]);

  useEffect(() => {
    reset({ sourceDerivedFieldElemForm: action === "edit" ? formData : {} });
  }, [formData]);

  useEffect(() => {
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_VIEW_WIDGET_METRIC_CODELIST));
  }, []);

  return (
    <>
      {loaded && (
        <NtaiDialogFormPanel
          handleSave={handleSave}
          handleCancel={handleDialogClose}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <NtaiSelectField
              name="sourceDerivedFieldElemForm.elemFieldUuId"
              options={allFieldsDataOptions}
              label="Field*"
              rules={{ required: "Field is required" }}
            />
            <NtaiSelectField
              name="sourceDerivedFieldElemForm.metricCode"
              options={metricCodeOptions}
              label="Aggregation Metric*"
              rules={{ required: "Aggregation metric is required" }}
            />
          </Box>
        </NtaiDialogFormPanel>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </>
  );
}
