import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiMultiSelectField from "@ntai/components/inputs/NtaiMultiSelectField";
import { getSourceDefinitions } from "../../sourcedefinition/store/sourceDefinitionsSlice";
const _ = require("lodash");

export default function WorkflowDefForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();

  const sourceDefintionsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );
  const sourceDefinitionsSliceStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const sourceDefinitionOptions = NtaiUtils.getSelectOptions(
    sourceDefintionsDataMap && !_.isEmpty(sourceDefintionsDataMap)
      ? Object.values(sourceDefintionsDataMap)
      : [],
    "uuId",
    "name"
  );

  function handleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("workflowDefForm")
      );

      if (!_.isEmpty(sanitizedFormData))
        handleFormSubmit({ ...sanitizedFormData });
    }
  }

  useEffect(() => {
    dispatch(getSourceDefinitions());
    reset({ workflowDefForm: action === "edit" ? formData : null });
  }, [formData, action]);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="workflowDefForm.name"
          label="Name*"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="workflowDefForm.description"
          label="Description"
          multiline
          minRows={3}
          maxRows={3}
        />
        <NtaiMultiSelectField
          name="workflowDefForm.sourceDefUuIds"
          label="Sources*"
          options={sourceDefinitionOptions}
          rules={{ required: "Select atleast one source" }}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
