import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import {
  clearStatus,
  setActiveId,
  updateSourceOutDataset,
} from "../store/sourceOutDatasetsSlice";
import SourceOutStoreDbDatasetForm from "./SourceOutStoreDbDatasetForm";
const _ = require("lodash");

export default function SourceOutStoreDbDatasetEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = props.match.params.outstoreid;

  const id = props.match.params.id;

  const status = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceOutDataset({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceOutStoreId: sourceOutStoreId,
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceOutDataset" &&
      status.result === "success"
    ) {
      //   history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(formData, "datasetName")}`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/dbdatasets`}
    >
      <NtaiForm>
        <SourceOutStoreDbDatasetForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
