import React, { useEffect, useState } from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NtaiSourceViewWidget from "@ntai/components/widgets/source-view/NtaiSourceViewWidget";
const _ = require("lodash");

export default function DashboardWidgetBox(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { widget } = props;

  return (
    <>
      <NtaiSourceViewWidget
        id={widget.uuId}
        title={NtaiUtils.trunc(widget.name, 10)}
      >
        <Box
          sx={{
            gap: theme.spacing(0),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {widget.name}
          </Box>
        </Box>
      </NtaiSourceViewWidget>
    </>
  );
}
