import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";

import {
  getTopics,
  createTopic,
  updateTopic,
  deleteTopic,
  clearStatus,
  setActiveId,
} from "./store/topicsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import TopicForm from "./TopicForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  getSetting,
  clearStatus as clearSettingStatus,
} from "../../admin/setting/store/settingsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function TopicList(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [defaultDomainUuId, setDefaultDomainUuId] = useState();

  const settingsDataMap = useSelector((state) => state.core.settingsSlice.data);
  const settingsStatus = useSelector(
    (state) => state.core.settingsSlice.status
  );
  const settings =
    settingsDataMap && !_.isEmpty(settingsDataMap)
      ? Object.values(settingsDataMap)
      : [];

  const dataMap = useSelector((state) => state.core.topicsSlice.data);
  const status = useSelector((state) => state.core.topicsSlice.status);
  const activeId = useSelector((state) => state.core.topicsSlice.activeId);
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteTopic(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    formData["domainId"] = defaultDomainUuId;
    action === "edit"
      ? dispatch(
          updateTopic({
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(createTopic(formData));
  }

  useEffect(() => {
    if (
      settingsStatus &&
      settingsStatus.method === "getSetting" &&
      settingsStatus.result === "success"
    ) {
      if (settings && _.isArray(settings)) {
        const defaultDomain = _.filter(settings, { name: "defaultDomainUuId" });
        if (defaultDomain.length > 0) {
          const defaultDomainId = defaultDomain[0]["value"];
          if (defaultDomainId != null && defaultDomainId.length > 0) {
            dispatch(getTopics({ domainId: defaultDomainId }));
            setDefaultDomainUuId(defaultDomainId);
          } else {
            setDefaultDomainUuId(null);
          }
        }
      }

      dispatch(clearSettingStatus());
    }
  }, [settingsStatus]);

  useEffect(() => {
    dispatch(getSetting("defaultDomainUuId"));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.method === "getTopics" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createTopic" || status.method === "updateTopic")
    ) {


      handleDialogClose();
      dispatch(clearStatus());
      dispatch(setActiveId(null));
      setCurrentId(null);

      enqueueSnackbar(
        `Topic "${
          dataMap[action === "add" ? activeId : currentId]["name"]
        }" saved successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteTopic"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Topic(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <NtaiPage padding={2} title="Topics">
        {loaded && (
          <NtaiCrudTable
            helpText="The topic of interest library helps you categorize and organize information on specific topics or subjects of interests. Use topics to group related search, workflow and report items pertaining to objects or entities."
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleToolbarAction={handleToolbarAction}
            title="Topic"
          />
        )}

        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Topic"
        size="sm"
      >
        <NtaiForm>
          <TopicForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
