import {
  Box,
  Button,
  Chip,
  Drawer,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "@ntai/@history";
import SourceHome from "./home/SourceHome";
import SourceExportImport from "./export-import/SourceExportImport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faPowerOff,
  faShareFromSquare,
  faUserCheck,
} from "@fortawesome/pro-light-svg-icons";
import SourceVersionSelectorWrapper from "./version/selector/SourceVersionSelectorWrapper";
import SourceVersionCreateWrapper from "./version/create/SourceVersionCreateWrapper";
import SourceVersionEdit from "./version/edit/SourceVersionEdit";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  faBadgeCheck,
  faLock,
  faCheckCircle,
  faFilePowerpoint,
} from "@fortawesome/pro-solid-svg-icons";
import { blue } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceAwsLivySessionDialog from "./session/SourceAwsLivySessionDialog";
const _ = require("lodash");

// const drawerWidth = 180;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

// const Drawer1 = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

const sourcePanels = [
  {
    id: "ins",
    label: "Input Stores",
    description: "lorem disomddd Build ETL pipeline fro AI/ML tasks",
  },
  {
    id: "pipelines",
    label: "Pipelines",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
  },
  // {
  //   id: "pipeline",
  //   label: "Pipeline",
  //   description:
  //     "Build ETL pipeline from data ingestion to target stores for various analytics and AI/ML tasks",
  // },
  {
    id: "outstores",
    label: "Target Stores",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
  },
  {
    id: "jobs",
    label: "Jobs",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Build ETL pipeline from data ingestion to target store",
  },

  {
    id: "derivedfield",
    label: "Derived Fields",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Assign one or more thesauruses to a given source to ",
  },
  {
    id: "view",
    label: "Views",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Manage user defined views for source. Views can",
  },
  {
    id: "adjudication",
    label: "Assessment Forms",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Manage user defined views for source. Views can",
  },
  {
    id: "template",
    label: "Templates",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Manage listing (ex. card, list etc) and Views can",
  },
  {
    id: "object",
    label: "Objects",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Assign custom data objects for monitoring and other user defined tasks",
  },
  {
    id: "linkedsource",
    label: "Linked Sources",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Link related sources to source using standard and u",
  },
  {
    id: "thesaurus",
    label: "Thesauruses",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    // description: "Link related sources to source using standard and u",
  },
];

export const SourceContext = React.createContext();

export default function SourceManager(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);

  // const [leftExpanded, setLeftExpanded] = React.useState(false);
  // const [rightExpanded, setRightExpanded] = React.useState(false);
  // const [open, setOpen] = React.useState(false);

  const sourceDefId = props.match.params.sourcedefid;
  const sourceId = props.match.params.id;
  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionData = _.get(sourceDefDataMap, sourceDefId);

  const dataMap = useSelector((state) => state.sources.sourcesSlice.data);
  const status = useSelector((state) => state.sources.sourcesSlice.status);
  const source = _.get(dataMap, sourceId);

  // function handleExport() {
  //   dispatch(
  //     exportSourceConfig({
  //       sourceDefId: sourceDefId,
  //       sourceId: sourceId,
  //     })
  //   );
  // }

  // function handleClose() {
  //   setOpen(false);
  // }

  // function handleLeftMouseEnter() {
  //   setLeftExpanded(true);
  // }

  // function handleLeftMouseLeave() {
  //   setLeftExpanded(false);
  // }

  function handleAction(action, actionId) {
    if (action === "ins")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins`);

    if (action === "pipelines")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines`);

    if (action === "pipeline")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/pipeline`);

    if (action === "outstores")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`);

    if (action === "jobs")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs`);

    if (action === "derivedfield")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields`
      );
    if (action === "view")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/views`);
    if (action === "template")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/templates`);
    if (action === "linkedsource")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`
      );
    if (action === "object")
      history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/objects`);
  }

  // function handleSelect(srcAction) {
  //   setSourceAction(srcAction);
  // }

  function handleEditDrawerOpen() {
    setEditDrawerOpen(true);
  }

  function handleEditDrawerClose() {
    setEditDrawerOpen(false);
  }

  return (
    <SourceContext.Provider value={{ sourceDefinitionData }}>
      <NtaiPage
        title={`Manage ${NtaiUtils.trunc(
          _.get(sourceDefinitionData, "name"),
          50
        )}`}
        titleExtras={
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              gap: theme.spacing(2),
            }}
          >
            <Tooltip title="Edit Version">
              <Chip
                sx={{ px: theme.spacing(1) }}
                label={
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    fontWeight="700"
                  >
                    {`Version ${
                      _.get(source, "majorVersion") +
                      "." +
                      _.get(source, "minorVersion")
                    }`}
                  </Typography>
                }
                size="small"
                onClick={() => handleEditDrawerOpen()}
              />
            </Tooltip>

            {source["publishedFg"] === 1 && (
              <Tooltip title="Published">
                <Box>
                  <FontAwesomeIcon size="lg" icon={faUserCheck} />
                </Box>
              </Tooltip>
            )}
            {source["activeFg"] === 1 && (
              <Tooltip title="Active">
                <Box>
                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    size="lg"
                    color={blue[300]}
                  />
                </Box>
              </Tooltip>
            )}
            {source["lockedFg"] === 1 && (
              <Tooltip title="Locked">
                <Box>
                  <FontAwesomeIcon icon={faLock} size="lg" color="red" />
                </Box>
              </Tooltip>
            )}
          </Box>
        }
        back={`/sourcedefs`}
        headerActions={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <SourceExportImport
              type="export"
              sourceDefId={sourceDefId}
              sourceId={sourceId}
            />
            <SourceAwsLivySessionDialog />
            <SourceVersionSelectorWrapper />
            <SourceVersionCreateWrapper />
          </Box>
        }
      >
        <SourceHome sourceDefId={sourceDefId} sourceId={sourceId} />
      </NtaiPage>
      <Drawer
        open={editDrawerOpen}
        onClose={handleEditDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            width: "420px",
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        <NtaiForm>
          <SourceVersionEdit handleCancel={handleEditDrawerClose} />
        </NtaiForm>
      </Drawer>
    </SourceContext.Provider>
  );
}
