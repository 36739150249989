import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faAdd, faArchive } from "@fortawesome/pro-light-svg-icons";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getObjectFields,
  clearStatus as clearObjectFieldsStatus,
} from "../../field/store/objectFieldsSlice";
import { ObjectFormBuilderContext } from "../ObjectFormBuilder";
import { updateObjectFormBuilderPanelFields } from "./store/objectFormBuilderPanelsSlice";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import ObjectFormBuilderReorderFields from "./reorder-fields/ObjectFormBuilderReorderFields";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects) && !_.isEmpty(allObjectsDataMap)) {
    _.orderBy(selectedObjects, ["fieldOrder"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f,
        fieldOrder: f["fieldOrder"],
        primary: allObjectsDataMap[f]["fieldLabel"],
        secondary: allObjectsDataMap[f]["fieldName"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  let result = [];
  if (_.isArray(allObjects)) {
    result = _.filter(
      _.orderBy(allObjects, ["fieldLabel"], ["asc"]),
      function (f) {
        if (
          _.filter(selectedObjects, function (g) {
            if (g === f.uuId) return true;
            else return false;
          }).length === 0
        )
          return true;
      }
    );
  }

  return result;
}

export default function ObjectFormBuilderFields() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset } = useFormContext();
  const { selectedPanelId } = React.useContext(ObjectFormBuilderContext);
  const [objectFieldsLoaded, setObjectFieldsLoaded] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);
  const objectFormId = useSelector(
    (state) => state.core.objectFormsSlice.activeId
  );
  const objectFormTabId = useSelector(
    (state) => state.core.objectFormBuilderTabsSlice.activeId
  );

  const pathVariables = {
    domainId: domainId,
    objectId: objectId,
    objectFormId: objectFormId,
    objectFormTabId: objectFormTabId,
    uuId: selectedPanelId,
  };

  const objectFieldsDataMap = useSelector(
    (state) => state.core.objectFieldsSlice.data
  );

  const objectFieldsSliceStatus = useSelector(
    (state) => state.core.objectFieldsSlice.status
  );

  const objectTabPanelsDataMap = useSelector(
    (state) => state.core.objectFormBuilderPanelsSlice.data
  );

  const objectTabPanelData = selectedPanelId
    ? objectTabPanelsDataMap[selectedPanelId]
    : null;

  const status = useSelector(
    (state) => state.core.objectFormBuilderPanelsSlice.status
  );

  const data =
    objectTabPanelData && !_.isEmpty(objectTabPanelData)
      ? objectTabPanelData["fieldUuIds"]
      : [];

  const objectFieldsData =
    objectFieldsDataMap && !_.isEmpty(objectFieldsDataMap)
      ? Object.values(objectFieldsDataMap)
      : [];

  function handleAdd(uuId) {
    const panelDataCopy = _.cloneDeep(objectTabPanelData);

    if (!panelDataCopy["fieldUuIds"].includes(uuId)) {
      panelDataCopy["fieldUuIds"] = [...panelDataCopy["fieldUuIds"], uuId];
      dispatch(
        updateObjectFormBuilderPanelFields({
          ...pathVariables,
          formData: panelDataCopy,
        })
      );
    }
  }

  function handleRemove(uuId) {
    const panelDataCopy = _.cloneDeep(objectTabPanelData);

    if (panelDataCopy["fieldUuIds"].includes(uuId)) {
      const revisedFieldUuIds = _.filter(
        panelDataCopy["fieldUuIds"],
        function (o) {
          return o !== uuId ? true : false;
        }
      );

      panelDataCopy["fieldUuIds"] = revisedFieldUuIds;
      dispatch(
        updateObjectFormBuilderPanelFields({
          ...pathVariables,
          formData: panelDataCopy,
        })
      );
    }
  }

  useEffect(() => {
    if (
      (status &&
        ["updateObjectFormBuilderPanelFields"].includes(status.method) &&
        status.result === "success") ||
      objectFieldsLoaded
    ) {
      setAvailableObjects([
        ...transformAvailableObjects(data, objectFieldsData),
      ]);
      setSelectedObjects([
        ...transformSelectedObjects(data, objectFieldsDataMap),
      ]);
    }
  }, [status, objectFieldsLoaded, selectedPanelId]);

  useEffect(() => {
    if (
      objectFieldsSliceStatus &&
      ["getObjectFields"].includes(objectFieldsSliceStatus.method) &&
      objectFieldsSliceStatus.result === "success"
    ) {
      setObjectFieldsLoaded(true);
      dispatch(clearObjectFieldsStatus());
    }
  }, [objectFieldsSliceStatus]);

  useEffect(() => {
    dispatch(getObjectFields({ domainId: domainId, objectId: objectId }));
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "44px",
          gap: theme.spacing(1),
          justifyContent: "space-between",
          px: theme.spacing(1),
        }}
      >
        <Typography variant="h6" fontWeight="700">
          Panel Fields (
          {_.isArray(selectedObjects) ? selectedObjects.length : 0})
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <ObjectFormBuilderReorderFields />
          {/* <Button sx={{ fontSize: "12px" }} size="small">
            REMOVE ALL
          </Button>
          <Button sx={{ fontSize: "12px" }} size="small">
            ADD ALL
          </Button> */}
        </Box>
      </Box>
      {selectedPanelId && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 44px)`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: theme.spacing(2),
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                border: theme.general.border1,
              }}
            >
              {_.isArray(availableObjects) && availableObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ p: 0 }}>
                    {_.orderBy(
                      availableObjects,
                      ["resourceLabel"],
                      ["asc"]
                    ).map((fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <NtaiIconButton
                              icon={faAdd}
                              handleClick={() => handleAdd(fd.uuId)}
                            />
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                                color="inherit"
                              >
                                {fd.fieldLabel}
                              </Typography>
                            }
                            secondary={fd.fieldName}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Scrollbar>
              )}
              {_.isArray(availableObjects) && availableObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No fields available"
                  subheader="You have assigned all fields"
                />
              )}
            </Box>
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                height: "100%",
                border: theme.general.border1,
              }}
            >
              {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ width: "100%", p: 0 }}>
                    {selectedObjects &&
                      _.isArray(selectedObjects) &&
                      _.orderBy(selectedObjects, ["fieldOrder"], ["asc"]).map(
                        (so, i) => (
                          <React.Fragment key={`selected-objects-${i}`}>
                            <ListItem
                              secondaryAction={
                                <NtaiIconButton
                                  icon={faArchive}
                                  handleClick={() => handleRemove(so.uuId)}
                                />
                              }
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{ fontWeight: 700 }}
                                    variant="subtitle1"
                                    color="inherit"
                                  >
                                    {so.primary}
                                  </Typography>
                                }
                                secondary={so.secondary}
                              />
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        )
                      )}
                  </List>
                </Scrollbar>
              )}

              {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No fields assigned"
                  subheader="Add fields from left to the panel"
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
      {!selectedPanelId && (
        <NtaiEmptyMessage
          vAlign="center"
          header="No panel selected"
          subheader="Select panel from left panel to manage fields"
        />
      )}
    </Box>
  );
}
