import ReportFolderList from "./ReportFolderList";

const ReportFolderConfig = {
  routes: [
    {
      path: "/reports/folders",
      exact: true,
      component: ReportFolderList,
    },
  ],
};

export default ReportFolderConfig;
