import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { red } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import { GL_RUN_STATUS_CODE } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
const _ = require("lodash");

export default function CodeEditorCustomCodeResult({
  result,
  statusCode,
  statusDecode,
  message,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        border: "2px solid #eee",
        display: "flex",
        border: "2px solid #eee",
        borderLeft: "4px solid orange",
        borderRadius: "8px",
        height: "64px",
        background: statusCode === GL_RUN_STATUS_CODE.ERROR ? red[100] : null,
      }}
    >
      <Scrollbar>
        <Box
          sx={{
            p: theme.spacing(1),
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          {message}
        </Box>
      </Scrollbar>
    </Box>
  );
}
