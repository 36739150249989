import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../../../../../../http/ntaiServer";
const _ = require("lodash");

export const getAlertDefinitionInitAdjMin = createAsyncThunk(
  "alertDefinitionInitAdjs/getAlertDefinitionInitAdjMin",
  async (values, { rejectWithValue }) => {
    try {
      const { alertDefId, uuId } = values;
      const response = await server.get(
        `/monitor/definitions/${alertDefId}/init-adjs/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertDefinitionInitAdj = createAsyncThunk(
  "alertDefinitionInitAdjs/getAlertDefinitionInitAdj",
  async (alertDefId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/monitor/definitions/${alertDefId}/init-adjs`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertDefinitionInitAdjsSlice = createSlice({
  name: "alertDefinitionInitAdjs",
  initialState: {
    data: {},
    minData: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertDefinitionInitAdjMin.fulfilled]: (state, action) => {
      state.minData = action.payload;
      state.status = {
        result: "success",
        method: "getAlertDefinitionInitAdjMin",
      };
    },
    [getAlertDefinitionInitAdjMin.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertDefinitionInitAdjMin",
        message: action.payload.message,
      };
    },
    [getAlertDefinitionInitAdj.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getAlertDefinitionInitAdj" };
    },
    [getAlertDefinitionInitAdj.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertDefinitionInitAdj",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  alertDefinitionInitAdjsSlice.actions;

export default alertDefinitionInitAdjsSlice.reducer;
