import NtaiHelpDocs from "./NtaiHelpDocs";

const NtaiDocsConfig = {
  routes: [
    {
      path: "/docs",
      exact: true,
      component: NtaiHelpDocs,
    },
  ],
};

export default NtaiDocsConfig;
