import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourcePipelines = createAsyncThunk(
  "sourcePipelines/getSourcePipelines",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourcePipeline = createAsyncThunk(
  "sourcePipelines/getSourcePipeline",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourcePipeline = createAsyncThunk(
  "sourcePipelines/createSourcePipeline",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourcePipeline = createAsyncThunk(
  "sourcePipelines/updateSourcePipeline",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourcePipeline = createAsyncThunk(
  "sourcePipelines/deleteSourcePipeline",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePipelinesSlice = createSlice({
  name: "sourcePipelines",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePipelines.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourcePipelines" };
    },
    [getSourcePipelines.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelines",
        message: action.payload.message,
      };
    },
    [getSourcePipeline.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourcePipeline" };
    },
    [getSourcePipeline.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipeline",
        message: action.payload.message,
      };
    },
    [createSourcePipeline.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourcePipeline" };
    },
    [createSourcePipeline.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourcePipeline",
        message: action.payload.message,
      };
    },
    [updateSourcePipeline.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourcePipeline" };
    },
    [updateSourcePipeline.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourcePipeline",
        message: action.payload.message,
      };
    },
    [deleteSourcePipeline.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourcePipeline" };
    },
    [deleteSourcePipeline.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourcePipeline",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourcePipelinesSlice.actions;

export default sourcePipelinesSlice.reducer;
