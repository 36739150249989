import { useTheme } from "@mui/material";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceInXmlDatasetForm from "./SourceInXmlDatasetForm";
import {
  clearStatus,
  createSourceInStoreXmlDataset,
  deleteSourceInStoreXmlDataset,
  getSourceInStoreXmlDatasets,
  updateSourceInStoreXmlDataset,
} from "./store/sourceInStoreXmlDatasetsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "rootTag",
    numeric: false,
    disablePadding: false,
    label: "Root Tag",
  },

  {
    id: "rowTag",
    numeric: false,
    disablePadding: false,
    label: "Row Tag",
  },

  {
    id: "datasetName",
    numeric: false,
    disablePadding: false,
    label: "Dataset",
  },
];

const toolbarActions = [{ value: "delete", label: "Delete", icon: "delete" }];

export default function SourceInXmlDatasetList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceInId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );

  const sourceInStoreFileId = useSelector(
    (state) => state.sources.sourceInStoreFilesSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceInId: sourceInId,
    sourceInStoreFileId: sourceInStoreFileId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreXmlDatasetsSlice.data
  );
  const data = Object.values(dataMap);

  const status = useSelector(
    (state) => state.sources.sourceInStoreXmlDatasetsSlice.status
  );

  function handleEditDialogOpen() {
    setEditDialogOpen(true);
  }

  function handleEditDialogClose() {
    setEditDialogOpen(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(deleteSourceInStoreXmlDataset(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleToolbarAction(actionName, ids) {}

  function handleAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
    }
    handleEditDialogOpen();
  }

  function handleFormSubmit(formData) {
    if (action === "add")
      dispatch(
        createSourceInStoreXmlDataset({ ...pathVariables, formData: formData })
      );
    else if (action === "edit")
      dispatch(
        updateSourceInStoreXmlDataset({
          ...pathVariables,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  useEffect(() => {
    if (
      status &&
      [
        "createSourceInStoreXmlDataset",
        "updateSourceInStoreXmlDataset",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      handleEditDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceInStoreXmlDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
        sourceInStoreFileId: sourceInStoreFileId,
      })
    );
  }, []);

  return (
    <>
      <NtaiPanel
        width="100%"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
      >
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          handleToolbarAction={handleToolbarAction}
          keyColumn="uuId"
          isDensed={true}
          dialog={true}
          handleDialogAddEdit={handleAddEdit}
          title="Dataset"
        />
      </NtaiPanel>
      <NtaiDialog
        open={editDialogOpen}
        handleDialogClose={handleEditDialogClose}
        title="Add Dataset"
        size="sm"
      >
        <NtaiForm>
          <SourceInXmlDatasetForm
            action={action}
            handleDialogClose={handleEditDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
