import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReturnTypes = createAsyncThunk(
  "returnTypes/getReturnTypes",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/models/function-return-types");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReturnType = createAsyncThunk(
  "returnTypes/getReturnType",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/models/function-return-types/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createReturnType = createAsyncThunk(
  "returnTypes/createReturnType",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(
        "/models/function-return-types",
        values
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReturnType = createAsyncThunk(
  "returnTypes/updateReturnType",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/models/function-return-types/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReturnType = createAsyncThunk(
  "returnTypes/deleteReturnType",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(
        `/models/function-return-types/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const returnTypesSlice = createSlice({
  name: "returnTypes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReturnTypes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getReturnTypes" };
    },
    [getReturnTypes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReturnTypes",
        message: action.payload.message,
      };
    },
    [getReturnType.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getReturnType" };
    },
    [getReturnType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReturnType",
        message: action.payload.message,
      };
    },
    [createReturnType.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createReturnType" };
    },
    [createReturnType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createReturnType",
        message: action.payload.message,
      };
    },
    [updateReturnType.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateReturnType" };
    },
    [updateReturnType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateReturnType",
        message: action.payload.message,
      };
    },
    [deleteReturnType.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteReturnType" };
    },
    [deleteReturnType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteReturnType",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = returnTypesSlice.actions;

export default returnTypesSlice.reducer;
