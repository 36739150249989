import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceWidgetFieldColorSchemes from "./SourceWidgetFieldColorSchemes";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

const colorFormatOptions = [
  { value: 1, label: "Exact Value" },
  { value: 2, label: "Rule" },
];

export default function SourceWidgetFieldColorFormat({
  widget,
  handleWidgetSave,
  multiFields,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { setValue, reset, watch } = useFormContext();
  const [colorFormatCode, setColorFormatCode] = useState();
  const [colorUseCatTextValueFg, setColorUseCatTextValueFg] = useState();
  const [currentId, setCurrentId] = useState();
  const [action, setAction] = useState();
  const [loaded, setLoaded] = useState(false);

  const widgetFields = widget ? widget["sourceWidgetFields"] : [];

  const widgetFieldOptions =
    _.isArray(widgetFields) && widgetFields.length > 0
      ? NtaiUtils.getSelectOptions(widgetFields, "uuId", "fieldName")
      : [];

  const [selectedField, setSelectedField] = useState();
  const [selectedFieldUuId, setSelectedFieldUuId] = useState();

  const watchColorFieldUuId = watch("widgetForm.colorFieldUuId");

  const watchColorFormatCode = watch("widgetForm.colorFormatCode");
  const watchColorUseCatTextValueFg = watch(
    "widgetForm.colorUseCatTextValueFg"
  );

  function handleReset() {
    const widgetDataCopy = _.cloneDeep(widget);

    const selectedFieldIndex = _.findIndex(
      widgetDataCopy["sourceWidgetFields"],
      function (o) {
        if (o["uuId"] === selectedField["uuId"]) return true;
      }
    );

    const widgetFieldDataCopy =
      widgetDataCopy["sourceWidgetFields"][selectedFieldIndex];
    widgetFieldDataCopy["colorFormatCode"] = null;
    widgetFieldDataCopy["colorUseCatTextValueFg"] = null;
    widgetDataCopy["sourceWidgetFields"][selectedFieldIndex] =
      widgetFieldDataCopy;
    handleWidgetSave(widgetDataCopy);
  }

  useEffect(() => {
    if (selectedField && !_.isEmpty(selectedField)) {
      // console.log("watchColorUseCatTextValueFg: ", watchColorUseCatTextValueFg);
      const tmpColorUseCatTextValueFg = _.isBoolean(watchColorUseCatTextValueFg)
        ? watchColorUseCatTextValueFg
          ? 1
          : 0
        : watchColorUseCatTextValueFg;
      setColorUseCatTextValueFg(tmpColorUseCatTextValueFg);
    }
  }, [watchColorUseCatTextValueFg]);

  useEffect(() => {
    if (
      watchColorFormatCode &&
      !_.isEmpty(watchColorFormatCode) &&
      _.has(watchColorFormatCode, "value") &&
      selectedField &&
      !_.isEmpty(selectedField)
    ) {
      setColorFormatCode(watchColorFormatCode["value"]);

      if (selectedField["colorFormatCode"] !== watchColorFormatCode["value"]) {
        const widgetDataCopy = _.cloneDeep(widget);
        const widgetFieldDataCopy = _.cloneDeep(selectedField);
        widgetFieldDataCopy["colorFormatCode"] = watchColorFormatCode["value"];
        widgetFieldDataCopy["colorUseCatTextValueFg"] = colorUseCatTextValueFg;
        const selectedFieldIndex = _.findIndex(
          widget["sourceWidgetFields"],
          function (o) {
            if (o["uuId"] === widgetFieldDataCopy["uuId"]) return true;
          }
        );

        widgetDataCopy["sourceWidgetFields"][selectedFieldIndex] =
          widgetFieldDataCopy;

        handleWidgetSave(widgetDataCopy);
      }
    }
  }, [watchColorFormatCode]);

  useEffect(() => {
    if (
      watchColorFieldUuId &&
      !_.isEmpty(watchColorFieldUuId) &&
      _.has(watchColorFieldUuId, "value")
    ) {
      setSelectedField(
        _.filter(widgetFields, { uuId: watchColorFieldUuId["value"] })[0]
      );
    }
  }, [watchColorFieldUuId]);

  useEffect(() => {
    if (widgetFields && _.isArray(widgetFields)) {
      const colorField = _.filter(widgetFields, function (o) {
        return [1, 2].includes(o["colorFormatCode"]) ? true : false;
      });
      if (colorField.length > 0) {
        setValue("widgetForm.colorFieldUuId", colorField[0]["uuId"]);
        setSelectedField(colorField[0]);
      }
    }
  }, [widget]);

  useEffect(() => {
    if (selectedField && !_.isEmpty(selectedField)) {
      setValue("widgetForm.colorFormatCode", selectedField["colorFormatCode"]);
      setValue(
        "widgetForm.colorUseCatTextValueFg",
        selectedField["colorUseCatTextValueFg"]
      );
      setColorUseCatTextValueFg(selectedField["colorUseCatTextValueFg"]);
      setColorFormatCode(selectedField["colorFormatCode"]);
    }
  }, [selectedField]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: theme.spacing(2),
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          {widgetFields &&
            _.isArray(widgetFields) &&
            widgetFields.length > 0 && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: theme.spacing(1),
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexBasis: "65%",
                    gap: theme.spacing(2),
                  }}
                >
                  <NtaiSelectField
                    options={widgetFieldOptions}
                    label="Select Field"
                    name="widgetForm.colorFieldUuId"
                  />

                  <NtaiSelectField
                    isDisabled={
                      selectedField && selectedField["colorFormatCode"]
                        ? true
                        : false
                    }
                    options={colorFormatOptions}
                    name="widgetForm.colorFormatCode"
                    label="Select color formatter rule"
                  />
                </Box>

                <NtaiSwitchField
                  name="widgetForm.colorUseCatTextValueFg"
                  label="Use category value?"
                />
              </Box>
            )}
        </Box>
        {selectedField && !_.isEmpty(selectedField) && (
          <SourceWidgetFieldColorSchemes
            selectedField={selectedField}
            sourceWidgetId={widget["uuId"]}
            colorFormatCode={colorFormatCode}
            handleReset={handleReset}
          />
        )}
      </Box>
    </React.Fragment>
  );
}
