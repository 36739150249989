import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiColorPicker from "./NtaiColorPicker";
import { useFormContext } from "react-hook-form";
import NtaiTextField from "../inputs/NtaiTextField";

export default function TestNtaiColorPicker() {
  const theme = useTheme();

  const { getValues, setValue } = useFormContext();

  function onChange(color) {
    setValue("colorPickerForm.color", color);
  }

  return (
    <Box
      sx={{
        display: "flex",
        p: theme.spacing(2),
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <NtaiColorPicker onChange={onChange} />
      <NtaiTextField
        sx={{ width: "15%" }}
        name="colorPickerForm.color"
        label="Hex Color"
      />
    </Box>
  );
}
