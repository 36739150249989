import DomainList from "./domain/DomainList";
import ObjectList from "./object/ObjectList";
import ObjectCreate from "./object/ObjectCreate";
import ObjectEdit from "./object/ObjectEdit";

const CdoConfig = {
  routes: [
    {
      path: "/cdo/domains",
      exact: true,
      component: DomainList,
    },
    {
      path: "/cdo/objects",
      exact: true,
      component: ObjectList,
    },
    {
      path: "/cdo/domains/:domainId/objects/create",
      exact: true,
      component: ObjectCreate,
    },
    {
      path: "/cdo/domains/:domainId/objects/:id/edit",
      exact: true,
      component: ObjectEdit,
    },
  ],
};

export default CdoConfig;
