import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReportTemplates = createAsyncThunk(
  "reportTemplates/getReportTemplates",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/templates/reports");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReportTemplate = createAsyncThunk(
  "reportTemplates/getReportTemplate",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/templates/reports/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createReportTemplate = createAsyncThunk(
  "reportTemplates/createReportTemplate",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/templates/reports", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReportTemplate = createAsyncThunk(
  "reportTemplates/updateReportTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/templates/reports/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadReportTemplate = createAsyncThunk(
  "reportTemplates/uploadReportTemplate",
  async (values, { rejectWithValue }) => {
    const { uuId, formData, config } = values;
    try {
      const response = await server.post(
        `/admin/templates/reports/${uuId}/upload`,
        formData,
        config
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReportTemplate = createAsyncThunk(
  "reportTemplates/deleteReportTemplate",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/templates/reports/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportTemplatesSlice = createSlice({
  name: "reportTemplates",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReportTemplates.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getReportTemplates" };
    },
    [getReportTemplates.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportTemplates",
        message: action.payload.message,
      };
    },
    [getReportTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getReportTemplate" };
    },
    [getReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportTemplate",
        message: action.payload.message,
      };
    },
    [createReportTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createReportTemplate" };
      state.activeId = action.payload.uuId;
    },
    [createReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createReportTemplate",
        message: action.payload.message,
      };
    },
    [updateReportTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateReportTemplate" };
    },
    [updateReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateReportTemplate",
        message: action.payload.message,
      };
    },
    [uploadReportTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "uploadReportTemplate" };
    },
    [uploadReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "uploadReportTemplate",
        message: action.payload.message,
      };
    },

    [deleteReportTemplate.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteReportTemplate" };
    },
    [deleteReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteReportTemplate",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = reportTemplatesSlice.actions;

export default reportTemplatesSlice.reducer;
