import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceUserWidgets = createAsyncThunk(
  "sourceUserWidgets/getSourceUserWidgets",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceUserWidget = createAsyncThunk(
  "sourceUserWidgets/getSourceUserWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceUserWidget = createAsyncThunk(
  "sourceUserWidgets/createSourceUserWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceUserWidget = createAsyncThunk(
  "sourceUserWidgets/updateSourceUserWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceUserWidget = createAsyncThunk(
  "sourceUserWidgets/deleteSourceUserWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceUserWidgetsSlice = createSlice({
  name: "sourceUserWidgets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceUserWidgets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceUserWidgets" };
    },
    [getSourceUserWidgets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceUserWidgets",
        message: action.payload.message,
      };
    },
    [getSourceUserWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceUserWidget" };
    },
    [getSourceUserWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceUserWidget",
        message: action.payload.message,
      };
    },
    [createSourceUserWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceUserWidget" };
    },
    [createSourceUserWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceUserWidget",
        message: action.payload.message,
      };
    },
    [updateSourceUserWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceUserWidget" };
    },
    [updateSourceUserWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceUserWidget",
        message: action.payload.message,
      };
    },
    [deleteSourceUserWidget.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceUserWidget" };
    },
    [deleteSourceUserWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceUserWidget",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceUserWidgetsSlice.actions;

export default sourceUserWidgetsSlice.reducer;
