import React, { useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Dnd2MovableItem from "./Dnd2MovableItem";
import Dnd2Column from "./Dnd2Column";
import "./dnd2.css";
import { Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const NtaiDnd2 = ({
  idField,
  nameField,
  data,
  column1,
  column2,
  handleCallback,
  justifyContent,
}) => {
  const theme = useTheme();

  const [items, setItems] = useState([...data]);

  const returnItemsForColumn = (columnName) => {
    return items
      .filter((item) => item.column === columnName)
      .map((item) => (
        <Dnd2MovableItem
          key={item[idField]}
          name={item[nameField]}
          setItems={setItems}
          column1={column1}
          column2={column2}
        />
      ));
  };

  useEffect(() => {
    handleCallback(items);
  }, [items]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: theme.spacing(2),
        width: "100%",
        justifyContent: justifyContent ? justifyContent : "space-around",
      }}
    >
      {/* Wrap components that will be "draggable" and "droppable" */}
      <DndProvider backend={HTML5Backend}>
        <Dnd2Column title={column1} className="column">
          {returnItemsForColumn(column1)}
        </Dnd2Column>
        <Dnd2Column title={column2} className="column">
          {returnItemsForColumn(column2)}
        </Dnd2Column>
      </DndProvider>
    </Box>
  );
};

NtaiDnd2.propTypes = {
  idField: PropTypes.string.isRequired,
  nameField: PropTypes.string.isRequired,
  column1: PropTypes.string.isRequired,
  column2: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handleCallback: PropTypes.func.isRequired,
};
