import axios from "axios";

let instance = axios.create({
  baseURL: process.env.REACT_APP_NTAI_REST_URL,
  headers: {
    common: {
      "X-TenantID": localStorage.getItem("TENANT_ID"),
      // "X-TenantID": "tenant1",
    },
  },
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default instance;
