import { Slider, Typography } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiFilterBox from "./NtaiFilterBox";
const _ = require("lodash");

function milliSecondsToDate(v) {
  const d = new Date(v);
  return d.toLocaleDateString();
}

function NtaiFilterDateRangeSlider(props) {
  const { control } = useFormContext();

  const { min, max, label, displaymarks } = props;

  const marks = [
    {
      value: min,
      label: milliSecondsToDate(min),
    },
    {
      value: max,
      label: milliSecondsToDate(max),
    },
  ];

  return (
    <NtaiFilterBox>
      <Typography variant="subtitle2" fontWeight="700">
        {label}
      </Typography>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Slider
            size="small"
            defaultValue={[min, max]}
            value={value ? value : [min, max]}
            onChange={(_, val) => onChange(val)}
            min={min}
            max={max}
            step={86400000}
            valueLabelDisplay="auto"
            marks={displaymarks === "true" ? marks : null}
            valueLabelFormat={(value) => milliSecondsToDate(value)}
            {...props}
          />
        )}
        control={control}
        {...props}
      />
    </NtaiFilterBox>
  );
}

export default NtaiFilterDateRangeSlider;
