import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceThesauruses = createAsyncThunk(
  "sourceThesauruses/getSourceThesauruses",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/thesauruses`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSourceThesauruses = createAsyncThunk(
  "sourceThesauruses/createOrUpdateSourceThesauruses",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/thesauruses`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceThesaurusesSlice = createSlice({
  name: "sourceThesauruses",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceThesauruses.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "thesaurusUuId") };
      state.status = { result: "success", method: "getSourceThesauruses" };
    },
    [getSourceThesauruses.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceThesauruses",
        mesesage: action.payload.message,
      };
    },

    [createOrUpdateSourceThesauruses.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "thesaurusUuId") };
      state.status = {
        result: "success",
        method: "createOrUpdateSourceThesauruses",
      };
    },
    [createOrUpdateSourceThesauruses.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSourceThesauruses",
        mesesage: action.payload.mesesage,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceThesaurusesSlice.actions;

export default sourceThesaurusesSlice.reducer;
