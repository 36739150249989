import { Box, Button, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchDashboardInitContext } from "../SearchDashboardInit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrid,
  faGridHorizontal,
  faStop,
} from "@fortawesome/pro-light-svg-icons";
import SearchDashboardCompactBox from "../SearchDashboardCompactBox";
import SearchDashboardBox from "../SearchDashboardBox";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function SearchDashboardEntities() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [entities, setEntities] = useState([]);
  const [denseLayout, setDenseLayout] = useState(false);

  const {
    selectedEntities,
    setSelectedEntities,
    selectedEntityFilter,
    entitiesQueryText,
    setSelectedEntityDetails,
  } = React.useContext(SearchDashboardInitContext);

  const entitiesDataMap = useSelector(
    (state) => state.search.searchDashboardInitSlice.entities
  );

  const entitiesData =
    entitiesDataMap && !_.isEmpty(entitiesDataMap)
      ? Object.values(entitiesDataMap)
      : [];

  function getFilteredEntities() {
    const filteredEntities = _.filter(entities, function (o) {
      if (
        selectedEntityFilter &&
        selectedEntityFilter.length > 0 &&
        selectedEntityFilter !== "All"
      ) {
        if (o["objectName"] === selectedEntityFilter) return true;
        else return false;
      } else {
        return true;
      }
    });
    return _.filter(filteredEntities, function (o) {
      if (entitiesQueryText && entitiesQueryText.length > 0) {
        if (
          o["title"].toLowerCase().includes(entitiesQueryText.toLowerCase())
        ) {
          return true;
        } else return false;
      } else if (!entitiesQueryText || entitiesQueryText.length === 0) {
        return true;
      }
    });
  }

  function handleSelectEntity(entityUuId) {
    const tmpSelected = new Set(selectedEntities);
    if (selectedEntities && selectedEntities.has(entityUuId))
      tmpSelected.clear();
    else {
      tmpSelected.clear();
      tmpSelected.add(entityUuId);
    }

    if (tmpSelected.size > 0) {
      setSelectedEntityDetails(
        _.filter(entities, { uuId: [...tmpSelected][0] })[0]
      );
    } else {
      setSelectedEntityDetails(null);
    }

    setSelectedEntities(tmpSelected);
  }

  function clearSelectedEntities() {
    const tmpSelected = new Set(selectedEntities);
    tmpSelected.clear();
    setSelectedEntities(tmpSelected);
  }

  useEffect(() => {
    let revisedEntities = [];
    if (entitiesDataMap && !_.isEmpty(entitiesDataMap)) {
      revisedEntities = Object.values(entitiesDataMap).map((e, i) => {
        return {
          uuId: e.uuId,
          title:
            _.filter(e["fields"], { primaryFg: 1 }).length > 0
              ? _.filter(e["fields"], { primaryFg: 1 })[0]["fieldValue"]
              : "Not available",

          description:
            _.filter(e["fields"], { secondaryFg: 1 }).length > 0
              ? _.filter(e["fields"], { secondaryFg: 1 })[0]["fieldValue"]
              : "Not available",
          objectName: e["objectName"],
        };
      });
    }
    setLoaded(true);
    setEntities(revisedEntities);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexBasis: "85%",
        flexDirection: "column",
        justifyContent: "start",
        gap: theme.spacing(1),
      }}
    >
      {loaded && _.isArray(entitiesData) && entitiesData.length > 0 && (
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              pl: theme.spacing(2),
              justifyContent: "space-between",
              paddingTop: theme.spacing(2),
              width: "86%",
            }}
          >
            <Typography variant="h6">{`Entities (${
              getFilteredEntities().length
            })`}</Typography>

            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                justifyContent: "end",
              }}
            >
              <Button
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  gap: theme.spacing(1),
                }}
                size="small"
                color="inherit"
                onClick={() => setDenseLayout(!denseLayout)}
              >
                <FontAwesomeIcon
                  size="xl"
                  icon={denseLayout ? faGridHorizontal : faGrid}
                />
                {denseLayout ? "STANDARD" : "DENSE"}
              </Button>
              <Button
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  gap: theme.spacing(1),
                }}
                size="small"
                color="inherit"
                onClick={() => clearSelectedEntities()}
              >
                <FontAwesomeIcon size="xl" icon={faStop} />
                CLEAR
              </Button>
            </Box>
          </Box>

          {getFilteredEntities().length > 0 && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                p: theme.spacing(2),
                gap: theme.spacing(2),
                // gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                flexWrap: "wrap",
              }}
            >
              {getFilteredEntities().map((e, i) => {
                if (denseLayout) {
                  return (
                    <SearchDashboardCompactBox
                      key={`search-db-entity-${i}`}
                      id={e["uuId"]}
                      title={e["title"]}
                      subheader={e["objectName"]}
                      description={e["description"]}
                      handleSelect={handleSelectEntity}
                      selectedItems={selectedEntities}
                    />
                  );
                } else {
                  return (
                    <SearchDashboardBox
                      key={`search-db-entity-${i}`}
                      id={e["uuId"]}
                      title={e["title"]}
                      subheader={e["objectName"]}
                      description={e["description"]}
                      handleSelect={handleSelectEntity}
                      selectedItems={selectedEntities}
                    />
                  );
                }
              })}
            </Box>
          )}
          {getFilteredEntities().length === 0 && (
            <NtaiEmptyMessage
              header="No entities found"
              subheader="Entities not assigned or applied filters didn't return any result"
            />
          )}
        </Scrollbar>
      )}
    </Box>
  );
}
