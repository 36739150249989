import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import DistributionGroupForm from "./DistributionGroupForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createDistributionGroup,
  clearStatus,
} from "./store/distributionGroupsSlice";
import { useEffect } from "react";
import History from "@ntai/@history/@history";
import { useState } from "react";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DistributionGroupCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState();
  const status = useSelector(
    (state) => state.core.distributionGroupsSlice.status
  );
  const activeId = useSelector(
    (state) => state.core.distributionGroupsSlice.activeId
  );
  const dataMap = useSelector(
    (state) => state.core.distributionGroupsSlice.data
  );

  function handleFormSubmit(formData) {
    dispatch(createDistributionGroup(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createDistributionGroup" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Distribution group "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      History.push(`/library/distribution-groups/${activeId}/edit`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Distribution Groups / Add Distribution Group"
      back="/library/distribution-groups"
    >
      <NtaiForm>
        <DistributionGroupForm
          action="add"
          handleFormSubmit={handleFormSubmit}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
