import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjectTopics = createAsyncThunk(
  "objectTopics/getObjectTopics",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, params } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/topics`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectTopic = createAsyncThunk(
  "objectTopics/getObjectTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/topics/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectTopic = createAsyncThunk(
  "objectTopics/createObjectTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/topics`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectTopic = createAsyncThunk(
  "objectTopics/updateObjectTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/topics/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectTopic = createAsyncThunk(
  "objectTopics/deleteObjectTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/topics/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessFormObjectsForObjectTopic = createAsyncThunk(
  "objectTopics/getAssessFormObjectsForObjectTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, params } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects`,
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectTopicsSlice = createSlice({
  name: "objectTopics",
  initialState: {
    data: {},
    assessFormObjData: {},
    activeId: null,
    status: {},
    assessFormObjStatus: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectTopics.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjectTopics" };
    },
    [getObjectTopics.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectTopics",
        message: action.payload.message,
      };
    },

    [getAssessFormObjectsForObjectTopic.fulfilled]: (state, action) => {
      state.assessFormObjData = { ..._.mapKeys(action.payload, "uuId") };
      state.assessFormObjStatus = {
        result: "success",
        method: "getAssessFormObjectsForObjectTopic",
      };
    },
    [getAssessFormObjectsForObjectTopic.rejected]: (state, action) => {
      state.assessFormObjStatus = {
        result: "error",
        method: "getAssessFormObjectsForObjectTopic",
        message: action.payload.message,
      };
    },

    [getObjectTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getObjectTopic" };
    },
    [getObjectTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectTopic",
        message: action.payload.message,
      };
    },
    [createObjectTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createObjectTopic" };
    },
    [createObjectTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectTopic",
        message: action.payload.message,
      };
    },
    [updateObjectTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateObjectTopic" };
    },
    [updateObjectTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectTopic",
        message: action.payload.message,
      };
    },
    [deleteObjectTopic.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteObjectTopic" };
    },
    [deleteObjectTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectTopic",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = objectTopicsSlice.actions;

export default objectTopicsSlice.reducer;
