import { faClose, faBell } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getNumOfUserNotifications,
  getUserNotifications,
  removeUserNotification,
} from "./store/userNotificationsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function UserNotifications() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const dataMap = useSelector(
    (state) => state.core.userNotificationsSlice.data
  );

  const status = useSelector(
    (state) => state.core.userNotificationsSlice.status
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["dateCreated"], ["desc"])
      : [];

  function handleRemove(id) {
    dispatch(removeUserNotification(id));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getUserNotifications" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "removeUserNotification" &&
      status.result === "success"
    ) {
      dispatch(getNumOfUserNotifications());
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getUserNotifications());
  }, []);

  return (
    <React.Fragment>
      {loaded && data.length > 0 && (
        <List sx={{ p: 0 }}>
          {data &&
            _.isArray(data) &&
            data.length > 0 &&
            data.map((o, i) => (
              <Box
                key={`notification-${i}`}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <ListItem>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Box sx={{ flexBasis: "10%" }}>
                      <FontAwesomeIcon icon={faBell} />
                    </Box>
                    <Box sx={{ flexBasis: "80%" }}>
                      {
                        <Typography
                          variant="subtitle1"
                          fontWeight="700"
                          color="inherit"
                        >
                          {o["title"]}
                        </Typography>
                      }

                      {
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="caption" color="inherit">
                            {NtaiUtils.formatDateTimeCol(o["dateCreated"])}
                          </Typography>
                          <Typography variant="subtitle2">
                            {o["body"]}
                          </Typography>
                        </Box>
                      }
                    </Box>

                    <Box sx={{ flexBasis: "10%" }}>
                      <Tooltip title="Remove">
                        <IconButton onClick={() => handleRemove(o["uuId"])}>
                          <FontAwesomeIcon size="2xs" icon={faClose} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </ListItem>
                <Divider />
              </Box>
            ))}
        </List>
      )}
      {loaded && data.length === 0 && (
        <NtaiEmptyMessage
          vAlign="center"
          header="No notifications found"
          subheader="All published notifications appear here"
        />
      )}
      {!loaded && (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <NtaiCircularProgress size={24} />
        </Box>
      )}
    </React.Fragment>
  );
}
