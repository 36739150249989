import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import fileUploadServer from "app/http/ntaiFileUploadServer";
import _ from "lodash";

export const getSourceInStoreFiles = createAsyncThunk(
  "sourceInStoreFiles/getSourceInStoreFiles",
  async (values) => {
    const { sourceDefId, sourceId, sourceInId } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files`
    );
    return response.data;
  }
);

export const createSourceInStoreFile = createAsyncThunk(
  "sourceInStoreFiles/createSourceInStoreFile",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceInStoreFile = createAsyncThunk(
  "sourceInStoreFiles/updateSourceInStoreFile",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceInStoreFile = createAsyncThunk(
  "sourceInStoreFiles/deleteSourceInStoreFile",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadSourceInStoreFiles = createAsyncThunk(
  "sourceFileUpload/uploadSourceInStoreFiles",
  async (values, { rejectWithValue }) => {
    const { sourceDefId, sourceId, sourceInId, formData, config } = values;
    try {
      const response = await fileUploadServer.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/upload`,
        formData,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const copySourceInStoreFiles = createAsyncThunk(
  "sourceFileUpload/copySourceInStoreFiles",
  async (values, { rejectWithValue }) => {
    const { sourceDefId, sourceId, sourceInId } = values;
    try {
      const response = await fileUploadServer.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/copy`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceInStoreFilesSlice = createSlice({
  name: "sourceInStoreFiles",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceInStoreFiles.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceInStoreFiles",
      };
    },
    [getSourceInStoreFiles.rejected]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "error",
        method: "getSourceInStoreFiles",
        message: action.payload.message,
      };
    },
    [createSourceInStoreFile.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceInStoreFile",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceInStoreFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceInStoreFile",
        message: action.payload.message,
      };
    },
    [updateSourceInStoreFile.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceInStoreFile",
      };
    },
    [updateSourceInStoreFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceInStoreFile",
        message: action.payload.message,
      };
    },

    [deleteSourceInStoreFile.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceInStoreFile",
      };
    },
    [deleteSourceInStoreFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceInStoreFile",
        message: action.payload.message,
      };
    },

    [uploadSourceInStoreFiles.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "uploadSourceInStoreFiles",
      };
    },
    [uploadSourceInStoreFiles.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "uploadSourceInStoreFiles",
        message: action.payload.message,
      };
    },
    [uploadSourceInStoreFiles.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "uploadSourceInStoreFiles",
      };
    },

    [copySourceInStoreFiles.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "copySourceInStoreFiles",
      };
    },
    [copySourceInStoreFiles.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "copySourceInStoreFiles",
        message: action.payload.message,
      };
    },
    [copySourceInStoreFiles.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "copySourceInStoreFiles",
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceInStoreFilesSlice.actions;

export default sourceInStoreFilesSlice.reducer;
