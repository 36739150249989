import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import { getThesauruses } from "app/main/pages/core/library/thesaurus/store/thesaurusesSlice";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";

const _ = require("lodash");

export default function SourceFieldSemTypeForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const [thesaurusSemTypeOptions, setThesaurusSemTypeOptions] = useState([]);
  const { getValues, reset, watch } = useFormContext();
  const dispatch = useDispatch();

  const [thesaurusId, setThesaurusId] = useState(
    action === "edit" && _.get(formData, "thesaurusUuId")
  );

  const thesaurusDataMap = useSelector(
    (state) => state.core.thesaurusesSlice.data
  );

  const thesaurusData =
    !_.isEmpty(thesaurusDataMap) && Object.values(thesaurusDataMap);

  const thesaurusOptions = !_.isEmpty(thesaurusData)
    ? NtaiUtils.getSelectOptions(thesaurusData, "uuId", "name")
    : [];

  const watchThesaurusSelection = watch([
    "sourceFieldSemTypeForm.thesaurusUuId",
  ]);

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceFieldSemTypeForm")
    );
    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    if (
      thesaurusId &&
      thesaurusId.length > 0 &&
      thesaurusDataMap &&
      !_.isEmpty(thesaurusDataMap)
    ) {
      setThesaurusSemTypeOptions(
        NtaiUtils.getSelectOptions(
          _.get(_.get(thesaurusDataMap, thesaurusId), "semTypes"),
          "semTypeId",
          "name"
        )
      );
    }
  }, [thesaurusId]);

  useEffect(() => {
    if (
      Array.isArray(watchThesaurusSelection) &&
      _.has(watchThesaurusSelection[0], "value")
    ) {
      setThesaurusId(_.get(watchThesaurusSelection[0], "value"));
    }
  }, [watchThesaurusSelection]);

  useEffect(() => {
    dispatch(getThesauruses("*"));
    reset({ sourceFieldSemTypeForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiSelectField
          label="Select Thesaurus"
          isDisabled={action === "edit" && true}
          name="sourceFieldSemTypeForm.thesaurusUuId"
          options={thesaurusOptions}
        />
        <NtaiSelectField
          label="Select Semantic Type"
          isDisabled={action === "edit" && true}
          name="sourceFieldSemTypeForm.thesaurusSemTypeId"
          options={thesaurusSemTypeOptions}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
