import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getSourceOutDatasets } from "../../sourcedefinition/source/out/store/sourceOutDatasetsSlice";

export default function SourceDatasetLookup({ handleSave, handleCancel }) {
  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  const sourceOutStoreDatasetsDataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const sourceStoreDatasetsData = Object.values(sourceOutStoreDatasetsDataMap);

  const sourceStoreDatasetsForSelect =
    sourceStoreDatasetsData &&
    sourceStoreDatasetsData.map((o, i) => {
      return {
        uuId: o.uuId,
        name: o.datasetName + " - " + o.sourceMin.displayName,
      };
    });

  const sourceDatasetOptions = NtaiUtils.getSelectOptions(
    sourceStoreDatasetsForSelect,
    "uuId",
    "name"
  );

  return (
    <NtaiDialogFormPanel
      header="Lookup source dataset"
      subheader="Select source dataset from the available options"
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <NtaiSelectField
        name="datasetForm.sourceOutDatasetUuId"
        options={sourceDatasetOptions ? sourceDatasetOptions : []}
        label="Source dataset*"
        rules={{ required: "Source dataset is required" }}
      />
    </NtaiDialogFormPanel>
  );
}
