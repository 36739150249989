import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import React from "react";

export default function NtaiFlatMenu(props) {
  const { label, data, handleSelect, selected } = props;

  function generateItems() {
    let result = [];
    if (typeof data === "object") {
      Object.keys(data).forEach((item, index) => {
        result.push(
          <MenuItem
            selected={selected === item ? true : false}
            onClick={() => handleSelect(item)}
            key={`menu-item-${index}`}
          >
            <ListItemText>
              {item} ({data[item]})
            </ListItemText>
          </MenuItem>
        );
      });
    }

    return result;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1">{label}</Typography>
      <MenuList>{generateItems()}</MenuList>
    </Box>
  );
}
