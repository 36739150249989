import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createSourcePolicy,
  deleteSourcePolicy,
  getSourcePolicies,
  updateSourcePolicy,
} from "./store/sourcePoliciesSlice";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
// import SourcePolicyForm from "./SourcePolicyForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourcePolicyForm from "./SourcePolicyForm";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "30%",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "25%",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "15%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    width: "15%",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourcePolicyList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState("list");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [open, setOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = sourceDefDataMap[sourceDefId];

  const dataMap = useSelector(
    (state) => state.sources.sourcePoliciesSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourcePoliciesSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteSourcePolicy({
          sourceId: sourceId,
          sourceId: sourceId,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleSave(formData) {
    if (action === "add")
      dispatch(
        createSourcePolicy({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateSourcePolicy({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  function handleCancel() {
    setAction("list");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourcePolicies" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSourcePolicy", "updateSourcePolicy"].includes(status.method) &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(`Successfully saved source policy definition`, {
        variant: "success",
      });
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourcePolicy"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Source policy "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourcePolicies({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiPanel
          width="100%"
          header="Assessment Policies"
          subheader="Define and manage policies pertaining to creation and update of source record assessments and source record display. Policy rules are specified separately in policy definitions. These rules help you to group source records based on source fields and then apply assessment operations such as create and update to all records."
        >
          {action === "list" && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              selectedIds={selectedIds}
              handleDialogAddEdit={handleAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Policy"
            />
          )}

          {(action === "add" || (action === "edit" && currentId)) && (
            <NtaiForm>
              <SourcePolicyForm
                action={action}
                formData={action === "edit" ? dataMap[currentId] : {}}
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </NtaiForm>
          )}
        </NtaiPanel>
      )}
      {!loaded && <NtaiCircularProgress />}

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
