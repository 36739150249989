import { Box, Typography, useTheme } from "@mui/material";
import NtaiDecoTree from "@ntai/components/treeview/NtaiDecoTree";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchSourceHierarchy } from "../../../source/toolbar/hierarchy/store/searchSourceHierarchySlice";
import { SearchContext } from "../../../SearchEdit";
import Scrollbar from "@ntai/components/Scrollbar";
import { SearchSummaryContext } from "./SearchSummary";
import { useContext } from "react";
const _ = require("lodash");

const data = {
  id: "root",
  name: "Programs",
  count: 20,
  children: [
    {
      id: "1",
      name: "Pravastatin",
    },
    {
      id: "3",
      name: "Lovastatin",
      count: 145,
      children: [
        {
          id: "4",
          name: "Routine Monitoring",
          children: [
            {
              id: "5",
              name: "No name provided",
            },
          ],
        },
      ],
    },
    {
      id: "2",
      name: "Simvastatin",
    },
  ],
};

export default function SearchSummaryNavigation() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { searchData } = React.useContext(SearchContext);
  const { setNavSearchSourceUuId } = useContext(SearchSummaryContext);

  const searchId = _.get(searchData, "uuId");

  const hierarchyDataMap = useSelector(
    (state) => state.search.searchSourceHierarchySlice.data
  );

  const hierarchyData = _.has(hierarchyDataMap, "0")
    ? _.get(hierarchyDataMap, "0")
    : null;

  function handleSourceSelect(node) {
    setNavSearchSourceUuId(node.uuId);
  }

  useEffect(() => {
    dispatch(
      getSearchSourceHierarchy({
        searchId: searchId,
        searchSourceId: "*",
      })
    );
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "48px",
          display: "flex",
          borderBottom: theme.general.border1,
          alignItems: "center",
          p: theme.spacing(1),
        }}
      >
        <Typography variant="h6" fontWeight="700">
          Sources
        </Typography>
      </Box>
      <Box sx={{ height: `calc(100% - 48px)`, display: "flex" }}>
        {hierarchyData && (
          <Scrollbar>
            <Box sx={{ pr: theme.spacing(1), py: theme.spacing(1) }}>
              <NtaiDecoTree
                data={hierarchyData}
                idField="uuId"
                labelField="sourceName"
                badgeField="count"
                handleClick={handleSourceSelect}
              />
            </Box>
          </Scrollbar>
        )}
      </Box>
    </React.Fragment>
  );
}
