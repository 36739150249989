import { Box, useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import OversightTopicSources from "./sources/OversightTopicSources";
import OversightTopicSourceRecords from "./OversightTopicSourceRecords";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export const OversightSourceRecordsContext = React.createContext();

export default function OversightSourceRecordsWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sourcesLoaded, setSourcesLoaded] = useState(false);
  const [sourcesData, setSourcesData] = useState();
  const [sourceRecordsLoaded, setSourceRecordsLoaded] = useState(false);
  const [source, setSource] = useState();

  return (
    <OversightSourceRecordsContext.Provider
      value={{
        sourcesLoaded,
        setSourcesLoaded,
        sourcesData,
        setSourcesData,
        sourceRecordsLoaded,
        setSourceRecordsLoaded,
        source,
        setSource,
      }}
    >
      <NtaiPanel
        width="75%"
        header="Source Records"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi"
      >
        <Box
          sx={{
            display: "flex",
            height: "420px",
            border: theme.general.border1,
          }}
        >
          <OversightTopicSources />
          <OversightTopicSourceRecords />

          {sourcesLoaded && sourcesData.length === 0 && (
            <Box
              sx={{
                pt: theme.spacing(2),
                display: "flex",
                height: "100%",
                width: "100%",
                alignItems: "center",
              }}
            >
              <NtaiEmptyMessage header="No records available" />
            </Box>
          )}
        </Box>
      </NtaiPanel>
    </OversightSourceRecordsContext.Provider>
  );
}
