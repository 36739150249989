import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourcePipelineInStoreFilePreview = createAsyncThunk(
  "sourcePipelineInStoreFiles/getSourcePipelineInStoreFilePreview",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceInStoreId,
        sourceInStoreFileId,
      } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ins/${sourceInStoreId}/files/${sourceInStoreFileId}/preview`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePipelineInStoreFilesSlice = createSlice({
  name: "sourcePipelineInStoreFiles",
  initialState: {
    data: {},
    status: null,
    message: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
      state.message = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePipelineInStoreFilePreview.fulfilled]: (state, action) => {
      state.data = action.payload;

      state.status = {
        result: "success",
        method: "getSourcePipelineInStoreFilePreview",
      };
    },
    [getSourcePipelineInStoreFilePreview.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelineInStoreFilePreview",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourcePipelineInStoreFilesSlice.actions;

export default sourcePipelineInStoreFilesSlice.reducer;
