import React, { useContext, useEffect, useState } from "react";
import { Box, useTheme, Typography, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCheckSquare,
  faGlobe,
  faSquare,
  faLock,
} from "@fortawesome/pro-regular-svg-icons";

import { faDatabase, faList, faTable } from "@fortawesome/pro-light-svg-icons";

import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSparkRadialBarChart from "@ntai/components/charts/apexcharts/NtaiSparkRadialBarChart";
import { grey } from "@mui/material/colors";
import { SearchAdvInitContext } from "./SearchAdvInit";
const _ = require("lodash");

export default function SourceBox1(props) {
  const { type, searchInitSource, handleSelect } = props;
  const { selectedSource } = useContext(SearchAdvInitContext);

  const theme = useTheme();
  const [selected, setSelected] = useState(false);

  function onHandleSelect() {
    setSelected(!selected);
    handleSelect(
      type,
      type === "source"
        ? _.get(searchInitSource, "searchSourceDefinition.sourceUuId")
        : searchInitSource["uuId"]
    );
  }

  useEffect(() => {
    if (type === "source") {
      if (
        _.get(searchInitSource, "searchSourceDefinition.sourceUuId") ===
        selectedSource
      )
        setSelected(true);
      else setSelected(false);
    } else {
      if (searchInitSource["uuId"] === selectedSource) setSelected(true);
      else setSelected(false);
    }
  }, [selectedSource]);

  return (
    <Box
      onClick={() => onHandleSelect()}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "240px",
        height: "150px",
        background: "white",
        border: selected ? theme.general.border2p : theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "70px",
          display: "flex",
          p: theme.spacing(1.5),
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
            {/* <Box sx={{ width: "36px" }}>
              {_.has(searchInitSource, "totalHits") &&
              _.get(searchInitSource, "totalHits") > 0 ? (
                <NtaiSparkRadialBarChart
                  fontSize="8px"
                  label={NtaiUtils.abbrevNumber(
                    _.get(searchInitSource, "totalHits")
                  )}
                  series={75}
                />
              ) : (
                // <FontAwesomeIcon size="2x" icon={faDatabase} />
                <NtaiSparkRadialBarChart label="1.56k" series={34} />
              )}
            </Box> */}
            <Box>
              <Typography variant="body2" fontWeight="700">
                {NtaiUtils.trunc(
                  _.get(
                    searchInitSource,
                    type === "source" ? "searchSourceDefinition.abbrev" : "name"
                  ),
                  46
                )}
              </Typography>
              {type === "source" && (
                <Typography variant="caption" sx={{ color: grey[700] }}>
                  {`Updated:  ${NtaiUtils.localDate(
                    _.get(
                      searchInitSource,
                      "searchSourceDefinition.sourceDateModified"
                    )
                  )}`}
                </Typography>
              )}
              {type === "dataset" && (
                <Typography variant="caption" sx={{ color: grey[700] }}>
                  {_.get(searchInitSource, "typeDecode")}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <FontAwesomeIcon
            color="primary"
            icon={
              selected ? faCheckSquare : type === "source" ? faDatabase : faList
            }
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: "50px",
          display: "flex",
          py: "4px",
          px: theme.spacing(1.5),
        }}
      >
        <Typography variant="caption">
          {NtaiUtils.trunc(
            _.get(
              searchInitSource,
              type === "source"
                ? "searchSourceDefinition.description"
                : "description"
            ),
            100
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "30px",
          display: "flex",
          alignItems: "center",
          borderTop: theme.general.border1,
          background: grey[50],
          borderRadius: theme.general.borderRadiusSm,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingLeft: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <FontAwesomeIcon size="2xs" icon={faBuilding} />
            <Typography variant="caption" fontWeight="500" fontSize="10px">
              {type === "source"
                ? _.get(searchInitSource, "searchSourceDefinition.owner")
                : _.get(searchInitSource, "source.owner")}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              paddingRight: theme.spacing(1),
            }}
          >
            <Typography variant="caption" fontWeight="500" fontSize="10px">
              {type === "source"
                ? _.get(
                    searchInitSource,
                    "searchSourceDefinition.categoryDecode"
                  )
                : _.get(searchInitSource, "source.categoryDecode")}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="caption" fontWeight="700" fontSize="10px">
              {_.has(searchInitSource, "totalHits") &&
              _.get(searchInitSource, "totalHits") > 0
                ? NtaiUtils.abbrevNumber(_.get(searchInitSource, "totalHits"))
                : "0k"}
            </Typography>

            <FontAwesomeIcon
              size="2xs"
              icon={
                _.get(searchInitSource, "searchSourceDefinition.privacyFg") ===
                1
                  ? faLock
                  : faGlobe
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
