import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceSeriesImportCsvSchemes = createAsyncThunk(
  "sourceSeriesImportCsvSchemes/getSourceSeriesImportCsvSchemes",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/series-import-schemes`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceSeriesImportCsvScheme = createAsyncThunk(
  "sourceSeriesImportCsvSchemes/getSourceSeriesImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/series-import-schemes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceSeriesImportCsvScheme = createAsyncThunk(
  "sourceSeriesImportCsvSchemes/createSourceSeriesImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/series-import-schemes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceSeriesImportCsvScheme = createAsyncThunk(
  "sourceSeriesImportCsvSchemes/updateSourceSeriesImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/series-import-schemes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceSeriesImportCsvScheme = createAsyncThunk(
  "sourceSeriesImportCsvSchemes/deleteSourceSeriesImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/series-import-schemes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceSeriesImportCsvSchemesSlice = createSlice({
  name: "sourceSeriesImportCsvSchemes",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceSeriesImportCsvSchemes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceSeriesImportCsvSchemes",
      };
    },
    [getSourceSeriesImportCsvSchemes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceSeriesImportCsvSchemes",
        message: action.payload.message,
      };
    },
    [getSourceSeriesImportCsvScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceSeriesImportCsvScheme",
      };
    },
    [getSourceSeriesImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceSeriesImportCsvScheme",
        message: action.payload.message,
      };
    },
    [createSourceSeriesImportCsvScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceSeriesImportCsvScheme",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceSeriesImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceSeriesImportCsvScheme",
        message: action.payload.message,
      };
    },
    [updateSourceSeriesImportCsvScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceSeriesImportCsvScheme",
      };
    },
    [updateSourceSeriesImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceSeriesImportCsvScheme",
        message: action.payload.message,
      };
    },
    [deleteSourceSeriesImportCsvScheme.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceSeriesImportCsvScheme",
      };
    },
    [deleteSourceSeriesImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceSeriesImportCsvScheme",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceSeriesImportCsvSchemesSlice.actions;

export default sourceSeriesImportCsvSchemesSlice.reducer;
