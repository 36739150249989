import { useTheme } from "@mui/material";
import React from "react";

import NtaiDbListMetric1Widget from "@ntai/components/widgets/dashboard/templates/NtaiDbListMetric1Widget";

export default function SourceMigrationSummaryWidget(props) {
  const { data } = props;

  const theme = useTheme();

  return <NtaiDbListMetric1Widget data={data} />;
}
