import React from "react";
import {
  Badge,
  Box,
  Checkbox,
  Divider,
  IconButton,
  lighten,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchSourceRecordListContext } from "../SearchSourceRecordListPanel";
import { useContext } from "react";
import base64 from "react-native-base64";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faShare,
  faUserGroup,
  faDiagramProject,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faCommentAltEdit,
  faPaperclip,
  faTasks,
  faNote,
} from "@fortawesome/pro-solid-svg-icons";

import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import { blue, green, grey, orange, yellow } from "@mui/material/colors";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";

const _ = require("lodash");

export default function SearchSourceRecordListItem(props) {
  const {
    id,
    index,
    assessmentFg,
    numOfActions,
    numOfNotes,
    numOfDocuments,
    numOfWorkflows,
    numOfContacts,
    avatarText,
    primaryText,
    secondaryText,
    tertiaryText,
    handleDisplayRecord,
    label,
    imgUrl,
    handleLookup,
  } = props;
  const theme = useTheme();

  const [imgData, setImgData] = React.useState({});
  const [imgLoaded, setImgLoaded] = React.useState(false);

  const { selectedRecord, selectedRecords, setSelectedRecords } = useContext(
    SearchSourceRecordListContext
  );

  // useEffect(() => {
  //   server
  //     .get("/image", {
  //       params: {
  //         path: imgUrl,
  //         responseType: "arraybuffer",
  //       },
  //     })
  //     .then((res) => {
  //       setImgData(res.data);
  //       setImgLoaded(true);
  //     })
  //     .catch((err) => console.log(err));
  // }, [imgUrl]);

  function onHandleClick(e, id) {
    if (e.target.id.includes("checkbox")) {
      if (selectedRecords.includes(id)) {
        setSelectedRecords(
          _.filter(selectedRecords, function (o) {
            return o === id ? false : true;
          })
        );
      } else {
        setSelectedRecords([...selectedRecords, id]);
      }
    } else {
      handleDisplayRecord(e, id);
    }
  }

  function generateSecondaryLabels(secLabels) {
    let secondaryArr = [];
    secLabels.forEach((secLabel) => {
      secondaryArr.push(secLabel.label.concat(": ").concat(secLabel.value));
    });
    return secondaryArr.join(" | ");
  }

  function generateTags(tags) {
    let tagsArr = [];
    tags.forEach((tag) => {
      if (tag.value && tag["value"].length > 0)
        tagsArr.push(
          // <NtaiBadge
          //   title={NtaiUtils.trunc(tag.value, 15)}
          //   fg={tag.fgColor}
          //   bg={tag.bgColor}
          // />
          <Tooltip title={tag.value}>
            <Typography
              size="small"
              variant="subtitle2"
              sx={{
                py: "2px",
                px: "8px",
                borderRadius: theme.general.borderRadius,
                fontSize: "11px",
                fontWeight: "500",
                color: tag["fgColor"] ? tag["fgColor"] : null,
                background: tag["bgColor"] ? tag["bgColor"] : null,
                wordBreak: "break-word",
              }}
            >
              {NtaiUtils.trunc(tag.value, 15)}
            </Typography>
          </Tooltip>
        );
    });

    return tagsArr;
  }

  function generateText(text) {
    let result = null;
    if (text) {
      if (_.has(text, "lookupLinkUuId") && text.lookupLinkUuId) {
        let resultArr = [];
        if (_.isArray(text.value)) {
          text.value.forEach((v, i) => {
            resultArr.push(
              <Link
                id={`record-link`}
                key={`record-link-${text.lookupLinkUuId}-${i}`}
                color={blue[700]}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
                onClick={(e) =>
                  handleLookup(e, text.lookupLinkUuId, v, text.recordId)
                }
              >
                {v}
              </Link>
            );
          });
        } else {
          resultArr.push(
            <Link
              color={blue[700]}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={(e) =>
                handleLookup(e, text.lookupLinkUuId, text.value, text.recordId)
              }
            >
              {text.value}
            </Link>
          );
        }

        result = resultArr;
      } else {
        let resultText = null;
        if (text.value && _.isArray(text.value)) {
          resultText = NtaiUtils.trunc(_.join(text.value, ", "), 250);
        } else if (text.value) {
          resultText = NtaiUtils.trunc(text.value, 250);
        }

        result = resultText;
      }
    }

    return result;
  }

  return (
    <React.Fragment key={`search-source-record-listitem-${index}`}>
      <ListItem
        id={`search-source-record-listitem-${index}`}
        sx={{
          display: "flex",
          width: "100%",
          borderRadius: "0px",
          "&:hover": {
            background: grey[100],
            cursor: "pointer",
          },
        }}
        key={`search-source-record-listitem-${index}`}
        button
        // onClick={(e) => handleClick(e, id)}
        onClick={(e) => onHandleClick(e, id)}
        selected={
          _.get(selectedRecord, "recordId") === id ||
          selectedRecords.includes(id)
            ? true
            : false
        }
        secondaryAction={
          <Checkbox
            id={`search-source-record-checkbox-${index}`}
            edge="end"
            inputProps={{ "aria-labelledby": 12 }}
            name={`searchSourceRecords.${id}`}
            onClick={(e) => onHandleClick(e, id)}
            checked={selectedRecords.includes(id) ? true : false}
          />
        }
      >
        {/* <ListItemAvatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            <Box>
              <NtaiMuiBadge
                badgeContent={assessmentFg ? "Y" : null}
                right={0}
                top={4}
                color="primary"
                // bg={theme.colors.secondary.main}
              >
                <IconButton size="small">
                  <FontAwesomeIcon
                    size="xs"
                    style={{ color: assessmentFg ? "default" : null }}
                    icon={faCommentAltEdit}
                  />
                </IconButton>
              </NtaiMuiBadge>
            </Box>

            <Box>
              <NtaiMuiBadge
                badgeContent={
                  numOfActions && numOfActions > 0 ? numOfActions : null
                }
                right={0}
                top={4}
                color="success"
                // bg={theme.colors.secondary.main}
              >
                <IconButton size="small">
                  <FontAwesomeIcon size="xs" icon={faTasks} />
                </IconButton>
              </NtaiMuiBadge>
            </Box>

            <Box>
              <NtaiMuiBadge
                badgeContent={
                  numOfDocuments && numOfDocuments > 0 ? numOfDocuments : null
                }
                right={0}
                top={4}
                color="secondary"
                // bg={theme.colors.secondary.lighter}
              >
                <IconButton size="small">
                  <FontAwesomeIcon size="xs" icon={faPaperclip} />
                </IconButton>
              </NtaiMuiBadge>
            </Box>

            <Box>
              <NtaiMuiBadge
                badgeContent={numOfNotes && numOfNotes > 0 ? numOfNotes : null}
                right={0}
                top={4}
                color="secondary"
                // bg={theme.colors.secondary.lighter}
              >
                <IconButton size="small">
                  <FontAwesomeIcon size="xs" icon={faNote} />
                </IconButton>
              </NtaiMuiBadge>
            </Box>
          </Box>
        </ListItemAvatar> */}
        <ListItemText
          sx={{
            display: "flex",
            alignSelf: "start",
            flexDirection: "column",
          }}
          primary={
            <div
              style={{
                display: "inline-flex",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle1"
                color="textPrimary"
                fontWeight="700"
                sx={{
                  wordBreak: "break-word",
                }}
              >
                {generateText(primaryText)}
              </Typography>
            </div>
          }
          secondary={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(0.5),
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    wordBreak: "break-word",
                  }}
                  variant="subtitle1"
                  fontWeight="600"
                  // color="textSecondary"
                  color={grey[600]}
                >
                  {generateText(secondaryText)}
                </Typography>
              </div>

              {(assessmentFg ||
                numOfActions > 0 ||
                numOfNotes > 0 ||
                numOfDocuments > 0 ||
                numOfContacts > 0 ||
                numOfWorkflows > 0) && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1.5),
                    py: theme.spacing(0.5),
                  }}
                >
                  {assessmentFg && (
                    <NtaiMuiBadge
                      // badgeContent={assessmentFg ? 1 : null}
                      right={-5}
                      top={3}
                      color="secondary"
                    >
                      <FontAwesomeIcon
                        color={lighten(theme.colors.secondary.dark, 0.3)}
                        size="lg"
                        // size="lg"
                        icon={faCommentAltEdit}
                      />
                    </NtaiMuiBadge>
                  )}
                  {numOfDocuments > 0 && (
                    <NtaiMuiBadge
                      // badgeContent={numOfDocuments > 0 ? numOfDocuments : null}
                      right={-5}
                      top={3}
                      color="secondary"
                    >
                      <FontAwesomeIcon size="lg" icon={faPaperclip} />
                    </NtaiMuiBadge>
                  )}

                  {numOfActions > 0 && (
                    <NtaiMuiBadge
                      // badgeContent={numOfActions > 0 ? numOfActions : null}
                      right={-5}
                      top={3}
                      color="secondary"
                    >
                      <FontAwesomeIcon size="lg" icon={faTasks} />
                    </NtaiMuiBadge>
                  )}

                  {numOfNotes > 0 && (
                    <NtaiMuiBadge
                      // badgeContent={numOfNotes > 0 ? numOfNotes : null}
                      right={-5}
                      top={3}
                      color="secondary"
                    >
                      <FontAwesomeIcon size="lg" color="orange" icon={faNote} />
                    </NtaiMuiBadge>
                  )}

                  {numOfWorkflows > 0 && (
                    <Badge
                      // badgeContent={
                      //   numOfWorkflows && numOfWorkflows > 0
                      //     ? numOfWorkflows
                      //     : 0
                      // }
                      right={0}
                      top={4}
                      color="primary"
                      // variant="dot"
                      // bg={theme.colors.secondary.main}
                    >
                      <FontAwesomeIcon size="lg" icon={faDiagramProject} />
                    </Badge>
                  )}
                  {numOfContacts > 0 && (
                    <Badge
                      badgeContent={
                        numOfContacts && numOfContacts > 0 ? numOfContacts : 0
                      }
                      right={0}
                      top={4}
                      color="primary"
                      variant="dot"
                      // bg={theme.colors.secondary.main}
                    >
                      <FontAwesomeIcon icon={faUserGroup} />
                    </Badge>
                  )}
                </Box>
              )}

              {/* <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "11rem",
                }}
              >
                <Typography nowrap variant="subtitle1" color="textSecondary">
                  {generateText(secondaryText)}
                </Typography>
              </div> */}
              <div
                style={{
                  display: "inline-flex",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    wordBreak: "break-word",
                  }}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {generateText(tertiaryText)}
                </Typography>
              </div>

              {/* {imgUrl && imgLoaded && (
                <img
                  src={`data:image/jpeg;base64,${imgData}`}
                  width="200px"
                  height="auto"
                />
              )} */}
              {label && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(0.5),
                    pb: theme.spacing(1),
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        wordBreak: "break-word",
                      }}
                      variant="subtitle1"
                      fontWeight="300"
                    >
                      {label ? label.value : "Not Available"}
                    </Typography>
                  </div>

                  <Typography
                    sx={{
                      wordBreak: "break-word",
                    }}
                    variant="subtitle2"
                  >
                    {generateSecondaryLabels(
                      label && Array.isArray(_.get(label, "secondaryLabels"))
                        ? _.get(label, "secondaryLabels")
                        : []
                    )}
                  </Typography>
                  <div
                    style={{
                      display: "inline-flex",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      width: "100%",
                      gap: theme.spacing(1),
                    }}
                  >
                    {generateTags(
                      label && Array.isArray(_.get(label, "tags"))
                        ? _.get(label, "tags")
                        : []
                    )}
                  </div>
                </Box>
              )}
            </Box>
          }
        />
      </ListItem>

      <Divider />
    </React.Fragment>
  );
}
