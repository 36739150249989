import { Box, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSourceFilterSchemes } from "../filter/store/sourceFilterSchemesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  getSourceRecordListing,
  getSourceRecordListings,
} from "../listing/store/sourceRecordListingsSlice";
const _ = require("lodash");

export default function SourceMasterTemplateAggregate() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const accessOptions = codes
    ? _.get(codes, CODELIST_CODES.COR_ROLE_PRIV_GRANT_TYPE_CODE)
    : [];

  const filterSchemeDataMap = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.data
  );

  const filterSchemeOptions =
    filterSchemeDataMap && !_.isEmpty(filterSchemeDataMap)
      ? [
          ...NtaiUtils.getSelectOptions(
            Object.values(filterSchemeDataMap),
            "uuId",
            "name"
          ),
          { value: "", label: "Select None" },
        ]
      : [];

  const sourceListingsDataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const sourceListingListOptions =
    sourceListingsDataMap && !_.isEmpty(sourceListingsDataMap)
      ? [
          ...NtaiUtils.getSelectOptions(
            _.filter(
              _.orderBy(
                Object.values(sourceListingsDataMap),
                ["name"],
                ["asc"]
              ),
              { typeCode: 2 }
            ),
            "uuId",
            "name"
          ),
          { value: "", label: "Select None" },
        ]
      : [];

  const sourceListingTableOptions =
    sourceListingsDataMap && !_.isEmpty(sourceListingsDataMap)
      ? [
          ...NtaiUtils.getSelectOptions(
            _.filter(
              _.orderBy(
                Object.values(sourceListingsDataMap),
                ["name"],
                ["asc"]
              ),
              { typeCode: 1 }
            ),
            "uuId",
            "name"
          ),
          { value: "", label: "Select None" },
        ]
      : [];

  React.useEffect(() => {
    dispatch(
      getSourceFilterSchemes({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        params: {
          activeFg: 1,
        },
      })
    );

    dispatch(
      getSourceRecordListings({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [sourceId]);

  return (
    <NtaiPanel
      width="60%"
      header="Aggregate Level Access"
      subheader="More information"
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiSelectField
          name="sourceMasterTemplateForm.seriesCode"
          label="Series"
          options={accessOptions}
        />
        <NtaiSelectField
          name="sourceMasterTemplateForm.notesCode"
          label="Notes"
          options={accessOptions}
        />

        <NtaiSelectField
          name="sourceMasterTemplateForm.batchAssessmentCode"
          label="Batch Assessment"
          options={accessOptions}
        />
        <NtaiSelectField
          name="sourceMasterTemplateForm.bulkDocumentsCode"
          label="Batch Documents"
          options={accessOptions}
        />
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiSelectField
          name="sourceMasterTemplateForm.sourceFilterSchemeUuId"
          label="Filter Scheme"
          options={filterSchemeOptions}
        />

        <NtaiSelectField
          name="sourceMasterTemplateForm.sourceListingListUuId"
          label="List Template"
          options={sourceListingListOptions}
        />
        <NtaiSelectField
          name="sourceMasterTemplateForm.sourceListingTableUuId"
          label="Table Template"
          options={sourceListingTableOptions}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <NtaiSwitchField
          name="sourceMasterTemplateForm.linkedSourcesFg"
          label="Enable linked sources?"
        />
        <NtaiSwitchField
          name="sourceMasterTemplateForm.qbeFg"
          label="Enable query builder?"
        />
        <NtaiSwitchField
          name="sourceMasterTemplateForm.addWidgetsFg"
          label="Enable widgets additions?"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <NtaiSwitchField
          name="sourceMasterTemplateForm.distributeWfFg"
          label="Enable workflow distribution?"
        />
        <NtaiSwitchField
          name="sourceMasterTemplateForm.distributeContactFg"
          label="Enable contact distribution?"
        />

        <NtaiSwitchField
          name="sourceMasterTemplateForm.listingReportsFg"
          label="Enable listing reports?"
        />
      </Box>
    </NtaiPanel>
  );
}
