import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiVerticalBarChart from "./NtaiVerticalBarEChart";
import NtaiVerticalBarStackedChart from "./NtaiVerticalBarStackedEChart";
import NtaiVerticalBarSeriesChart from "./NtaiVerticalBarSeriesEChart";
import NtaiHorizontalBarEChart from "./NtaiHorizontalBarEChart";
import NtaiHorizontalBarSeriesEChart from "./NtaiHorizontalBarSeriesEChart";
import NtaiHorizontalBarStackedEChart from "./NtaiHorizontalBarStackedEChart";

export default function NtaiTestHBar() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  const barData = [
    {
      name: "USA",
      value: 12,
      total: 12,
      offset: 1,
    },
    {
      name: "India",
      value: 4,
      total: 13,
      offset: 0.3,
    },
    {
      name: "France",
      value: 12,
      total: 24,
      offset: 0.5,
    },
    {
      name: "UK",
      value: 10,
      total: 50,
      offset: 0.2,
    },
  ];

  const barSeriesData = {
    category: {
      name: "Phase",
      data: ["Phase 1", "Phase 2"],
    },
    series: {
      name: "Period",
      data: ["AM", "PM"],
    },
    data: [
      {
        category: "Phase 1",
        series: [
          {
            name: "AM",
            data: {
              total: 12,
              value: 4,
              offset: 0.3,
            },
          },
          {
            name: "PM",
            data: {
              total: 24,
              value: 3,
              offset: 0.125,
            },
          },
        ],
      },
      {
        category: "Phase 2",
        series: [
          {
            name: "AM",
            data: {
              total: 40,
              value: 10,
              offset: 0.25,
            },
          },
          {
            name: "PM",
            data: {
              total: 34,
              value: 17,
              offset: 0.5,
            },
          },
        ],
      },
    ],
  };

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Bar chart">
          <NtaiHorizontalBarEChart
            widgetId="horizontal-bar"
            data={barData}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId: {
                  name: "field",
                  fieldOrder: 1,
                  field: { uuId: "fieldUuId" },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    result.push(
      <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Bar Series Chart">
          <NtaiHorizontalBarSeriesEChart
            widgetId="horizontal-bar-series"
            data={barSeriesData}
            metadata={{
              filterFg: filtered,
              fields: {
                phaseid: {
                  fieldUuId: "phaseuuid",
                  name: "Phase",
                  fieldOrder: 1,
                  field: {
                    uuId: "field1uuid",
                  },
                },
                periodid: {
                  fieldUuId: "periodUuId",
                  name: "Period",
                  fieldOrder: 2,
                  field: {
                    uuId: "field2UuId",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    result.push(
      <Box key={3} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Bar Stacked Chart">
          <NtaiHorizontalBarStackedEChart
            widgetId="horizontal-bar-stacked"
            data={barSeriesData}
            metadata={{
              filterFg: filtered,
              fields: {
                phaseid: {
                  fieldUuId: "phaseuuid",
                  name: "Phase",
                  fieldOrder: 1,
                  field: {
                    uuId: "field1uuid",
                  },
                },
                periodid: {
                  fieldUuId: "periodUuId",
                  name: "Period",
                  fieldOrder: 2,
                  field: {
                    uuId: "field2UuId",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>

      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
