import React, { useEffect, useMemo, useState } from "react";
import { Box, darken, lighten, useTheme } from "@mui/material";
import {
  tooltipConfig,
  xAxisLabelConfig,
  xAxisLabelNoRotationConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: {
    // height: "53%",
    top: "10%",
    right: "5%",
    left: "5%",
    bottom: "10%",
    containLabel: true,
  },
  tooltip: tooltipConfig,
  xAxis: {
    type: "category",
    data: null,
    splitArea: {
      show: true,
    },
    axisLabel: xAxisLabelConfig,
    axisLine: {
      lineStyle: {
        width: 0.2,
      },
    },
    axisTick: {
      lineStyle: {
        width: 0.2,
      },
    },
  },
  yAxis: {
    type: "category",
    data: [],
    splitArea: {
      show: true,
    },
    axisLabel: {
      fontSize: 10,
    },
    axisLine: {
      lineStyle: {
        width: 0.2,
      },
    },
    axisTick: {
      lineStyle: {
        width: 0.2,
      },
    },
  },
  visualMap: {
    min: 0,
    max: 10,
    calculable: true,
    orient: "horizontal",
    left: "center",
    bottom: "10%",
    inRange: null,
    textStyle: {
      fontSize: 10,
    },
  },
  series: [
    {
      name: "Series1",
      type: "heatmap",
      data: [],
      cursor: "default",
      label: {
        show: false,
        fontSize: 10,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

function getAxisData(axis, data) {
  const axisValues = data.map((d, i) => {
    return d[axis];
  });

  return _.uniq(axisValues).sort();
}

function getValues(data) {
  let result = [];
  const x = getAxisData("x", data);
  const y = getAxisData("y", data);

  data.forEach((d, i) => {
    result.push([x.indexOf(d["x"]), y.indexOf(d["y"]), d["value"]]);
  });

  return result;
}

function getMinMax(minMax, data) {
  const values = data.map((d, i) => {
    return d["value"];
  });

  values.sort();

  if (minMax === 0) return values[0];
  else return values[values.length - 1];
}

export default function NtaiHeatmapEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(params) {
    const xIndex = params["value"][0];
    const yIndex = params["value"][1];

    const xValues = getAxisData("x", data);
    const yValues = getAxisData("y", data);

    const sourceWidgetFilterFields = [
      {
        sourceFieldUuId: NtaiEChartUtil.getSourceFieldUuId(
          metadataRef.current,
          1
        ),
        fieldOperatorCode: 1,
        fieldValue: xValues[xIndex],
      },
      {
        sourceFieldUuId: NtaiEChartUtil.getSourceFieldUuId(
          metadataRef.current,
          2
        ),
        fieldOperatorCode: 1,
        fieldValue: yValues[yIndex],
      },
    ];

    return sourceWidgetFilterFields;
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(params));
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(action, getSourceWidgetFilterFields(params));
  }

  useEffect(() => {
    const curThemeName = localStorage.getItem("appTheme") || "NtaiBlueTheme";

    let result = [];
    let colorMin = lighten(theme.colors.primary.light, 0.9);
    let colorMax = darken(theme.colors.primary.main, 0.1);

    if (curThemeName === "NtaiDarkTheme") {
      colorMin = lighten(theme.colors.chart.light, 0.9);
      colorMax = "#62A3F0";
    }

    const copyOption = _.cloneDeep(option);
    copyOption["xAxis"]["data"] = getAxisData("x", data);
    copyOption["yAxis"]["data"] = getAxisData("y", data);
    // TODO: IR Suggested change
    copyOption["series"][0]["name"] =
      NtaiEChartUtil.getSourceField(metadata, 1)["headerName"] +
      " - " +
      NtaiEChartUtil.getSourceField(metadata, 2)["headerName"];
    copyOption["series"][0]["data"] = getValues(data);
    copyOption["visualMap"]["inRange"] = {
      color: [colorMin, colorMax],
    };
    copyOption["visualMap"]["min"] = getMinMax(0, data);
    copyOption["visualMap"]["max"] = getMinMax(1, data);

    setOption(copyOption);
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"][0]["data"].length > 0 && chart}
    </Box>
  );
}
