import React, { useCallback, useMemo, useRef, useState } from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const rowData1 = [
  {
    event: "Headache",
    sex: "Male",
    serious: "Yes",
    count: 12,
  },
  {
    event: "Fever",
    sex: "Female",
    serious: "Yes",
    count: 34,
  },
  {
    event: "Fever",
    sex: "Male",
    serious: "No",
    count: 23,
  },
];
const NtaiAgGridTable4 = () => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    { field: "event", rowGroup: true },
    { field: "sex", pivot: true, minWidth: 180 },
    { field: "serious", pivot: true },
    { field: "count", minWidth: 150, aggFunc: "first" },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  //   const onGridReady = useCallback((params) => {
  //     fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //       .then((resp) => resp.json())
  //       .then((data) => setRowData(data));
  //   }, []);

  function cellClicked(p) {}

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          headerHeight={32}
          columnGroupShow="open"
          showOpenedGroup={true}
          suppressAggFuncInHeader={true}
          //   rowGroupPanelShow="always"
          onCellClicked={(p) => cellClicked(p)}
          rowData={rowData1}
          rowHeaderStyle={{ fontSize: "12px" }}
          rowStyle={{ fontSize: "12px" }}
          pivotMode={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          //   sideBar={true}
          //   onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default NtaiAgGridTable4;
