import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import RuleConditionList from "./conditions/RuleConditionList";
const _ = require("lodash");

export default function RuleForm({ action, formData, handleSave }) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  const dataMap = useSelector((state) => state.core.rulesSlice.data);
  const data = Object.values(dataMap);

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const ruleTypeOptions = _.has(codes, CODELIST_CODES.COM_RULE_TYPE_CODE)
    ? _.get(codes, CODELIST_CODES.COM_RULE_TYPE_CODE)
    : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(getValues("ruleForm"));
    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/admin/rules`);
  }

  useEffect(() => {
    reset({ ruleForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Description"
          subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: theme.spacing(2),
            }}
          >
            <NtaiTextField
              name="ruleForm.name"
              label="Name*"
              rules={{ required: "Name is required" }}
            />

            <NtaiTextField
              name="ruleForm.description"
              multiline
              minRows={2}
              maxRows={2}
              placeholder="Description"
              label="Description"
            />

            <NtaiSelectField
              name="ruleForm.typeCode"
              options={ruleTypeOptions}
              label="Type*"
              rules={{ required: "Type is required" }}
            />
          </Box>
        </NtaiFormPanel>
      </Box>
      <Box
        sx={{ p: theme.spacing(2) }}
        disabled={action === "add" ? true : false}
        label="CONDITIONS"
      >
        <RuleConditionList />
      </Box>
    </NtaiTabs>
  );
}
