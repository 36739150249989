import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
// import dayjs from "dayjs";
// import { Box, TextField } from "@mui/material";
import React from "react";

export default function NtaiMuiCalendar() {
  function handleOnChange(d) {
    console.log("In change: ", d);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CalendarPicker onChange={handleOnChange} />
    </LocalizationProvider>
  );
}
