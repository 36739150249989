import { Box, Button, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiRichEditor from "@ntai/components/editor/NtaiRichEditor";
import React, { useState } from "react";

export default function SourceCatalogDocumentation() {
  const theme = useTheme();

  const [documentation, setDocumentation] = useState("");
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          p: theme.spacing(2),
          height: "84px",
          display: "flex",
          width: "100%",
          borderBottom: theme.general.border1,
          gap: theme.spacing(1),
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">Documentation</Typography>
          <Typography variant="body2">
            You can use rich text editor to add information content about the
            asset
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button size="small" variant="contained">
            SAVE
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          height: "calc(100% - 84px)",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              height: "75%",
              width: "100%",
              p: theme.spacing(2),
            }}
          >
            <NtaiRichEditor
              style={{ width: "100%" }}
              value={documentation}
              setValue={setDocumentation}
            />
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
}
