import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React from "react";
import SearchSourceWidgetManager from "./SearchSourceWidgetManager";
import { useState } from "react";

export default function SearchSourceWidgetManagerWrapper() {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Tooltip title="Add Widget">
        <IconButton onClick={() => handleDialogOpen()}>
          <FontAwesomeIcon size="2xs" icon={faPlus} />
        </IconButton>
      </Tooltip>

      <NtaiDialog
        title="Add Widget"
        open={open}
        handleDialogClose={handleDialogClose}
        size="md"
      >
        <SearchSourceWidgetManager handleDialogClose={handleDialogClose} />
      </NtaiDialog>
    </React.Fragment>
  );
}
