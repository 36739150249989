import { faCaretDown, faSitemap } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, useTheme } from "@mui/material";
import NtaiPopper from "@ntai/components/poppers/NtaiPopper";
import SearchSourceHierarchy from "./SearchSourceHierarchy";
import { SearchSourceContext } from "../../SearchSource";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function SearchSourceHierarchyWrapper() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceName = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.name"
  );

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const clickAwayHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<FontAwesomeIcon size="sm" icon={faSitemap} />}
        size="small"
        color="inherit"
        sx={{
          fontSize: "11px",
          background: grey[100],
          boaxShadow: theme.colors.shadows.CardSm,
        }}
        onClick={(e) => handleOpen(e)}
        // endIcon={<FontAwesomeIcon size="sm" icon={faCaretDown} />}
      >
        {NtaiUtils.trunc(sourceName, 25).toUpperCase()}
      </Button>

      {open && (
        <NtaiPopover
          left={14}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          clickAwayHandler={clickAwayHandler}
        >
          <SearchSourceHierarchy handleClose={handleClose} />
        </NtaiPopover>
      )}
    </>
  );
}
