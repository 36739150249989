import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceWidgetDataPoints = createAsyncThunk(
  "searchSourceWidgetDataPoints/getSearchSourceWidgetDataPoints",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, params } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/datapoints`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceWidgetDataPoint = createAsyncThunk(
  "searchSourceWidgetDataPoints/getSearchSourceWidgetDataPoint",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/datapoints/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceWidgetDataPoint = createAsyncThunk(
  "searchSourceWidgetDataPoints/createSearchSourceWidgetDataPoint",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/datapoints`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceWidgetDataPoint = createAsyncThunk(
  "searchSourceWidgetDataPoints/updateSearchSourceWidgetDataPoint",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/datapoints/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceWidgetDataPoint = createAsyncThunk(
  "searchSourceWidgetDataPoints/deleteSearchSourceWidgetDataPoint",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/datapoints/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceWidgetDataPointsSlice = createSlice({
  name: "searchSourceWidgetDataPoints",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceWidgetDataPoints.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceWidgetDataPoints",
      };
    },
    [getSearchSourceWidgetDataPoints.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceWidgetDataPoints",
        message: action.payload.message,
      };
    },
    [getSearchSourceWidgetDataPoint.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceWidgetDataPoint",
      };
    },
    [getSearchSourceWidgetDataPoint.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceWidgetDataPoint",
        message: action.payload.message,
      };
    },
    [createSearchSourceWidgetDataPoint.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceWidgetDataPoint",
      };
    },
    [createSearchSourceWidgetDataPoint.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceWidgetDataPoint",
        message: action.payload.message,
      };
    },
    [updateSearchSourceWidgetDataPoint.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceWidgetDataPoint",
      };
    },
    [updateSearchSourceWidgetDataPoint.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceWidgetDataPoint",
        message: action.payload.message,
      };
    },
    [deleteSearchSourceWidgetDataPoint.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceWidgetDataPoint",
      };
    },
    [deleteSearchSourceWidgetDataPoint.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceWidgetDataPoint",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceWidgetDataPointsSlice.actions;

export default searchSourceWidgetDataPointsSlice.reducer;
