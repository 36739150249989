import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { clearStatus, setActiveId, updateUser } from "./store/usersSlice";
import UserForm from "./UserForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function UserEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.usersSlice.status);
  const activeId = useSelector((state) => state.core.usersSlice.activeId);
  const dataMap = useSelector((state) => state.core.usersSlice.data);

  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateUser({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (
      status &&
      status.method === "updateUser" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `User "${_.get(dataMap[activeId], "userName")}" updated successfully.`,
        {
          variant: "success",
        }
      );

      // history.push(`/admin/users`);
      dispatch(clearStatus());
    }

    if (status && status.method === "updateUser" && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage title={`Users / ${formData["userName"]}`} back="/admin/users">
      <NtaiForm>
        <UserForm
          action="edit"
          formData={formData}
          handleFormSubmit={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
