import { Box } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import UserAccountPassword from "./change-password/UserAccountPassword";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import UserAccountOverview from "./overview/UserAccountOverview";
import UserNotificationSetting from "./notifications/UserNotificationSetting";

export default function UserAccountHome() {
  return (
    <NtaiPage title="User Account">
      <NtaiTabs>
        <Box label="OVERVIEW">
          <UserAccountOverview />
        </Box>
        <Box label="CHANGE PASSWORD">
          <NtaiForm>
            <UserAccountPassword />
          </NtaiForm>
        </Box>
        <Box label="NOTIFICATIONS">
          <NtaiForm>
            <UserNotificationSetting />
          </NtaiForm>
        </Box>
      </NtaiTabs>
    </NtaiPage>
  );
}
