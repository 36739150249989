import { Popover, Typography, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useEffect, useRef, useState } from "react";
import NtaiContextMenuEChart from "./NtaiContextMenuEChart";
const _ = require("lodash");

export default function NtaiReactEChart(props) {
  const chartRef = useRef(null);
  const { handleChartClick, handleContextMenu, height, option } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuParams, setMenuParams] = useState();
  const [menuCoords, setMenuCoords] = useState();

  function handleMenuOpen() {
    setMenuOpen(true);
  }

  function handleMenuClose() {
    setMenuOpen(false);
    setMenuParams(null);
    setMenuCoords(null);
  }

  function onHandleContextMenu(action) {
    handleContextMenu(action, menuParams);
    handleMenuClose();
  }

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current?.getEchartsInstance();
      chartInstance.resize();
      const zr = chartInstance.getZr();

      // if (id) {
      //   const container = document.querySelector(`#${id}`);
      //   // const chart = echarts.init(container);

      //   new ResizeObserver(() => chartInstance.resize()).observe(container);
      // }

      window.addEventListener("resize", function () {
        chartInstance.resize();
      });

      chartInstance.on("click", function (params) {
        handleChartClick(params);
      });
      chartInstance.on("contextmenu", function (params) {
        params.event.event.preventDefault();
        setMenuCoords({
          left: params.event.event.clientX,
          top: params.event.event.clientY,
        });
        setMenuParams(params);
      });

      if (props.getChartInstance) props.getChartInstance(chartInstance);
    }
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current?.getEchartsInstance();
      if (props.getChartInstance) props.getChartInstance(chartInstance);
    }
  }, [chartRef]);

  useEffect(() => {
    if (menuCoords && !_.isEmpty(menuCoords)) {
      handleMenuOpen();
    }
  }, [menuCoords]);

  const reactEChart = React.useMemo(
    () => (
      <ReactECharts
        ref={chartRef}
        style={{
          display: "flex",
          width: "100%",
          height: height ? `${height}px` : "100%",
        }}
        {...props}
      />
    ),
    [props]
  );

  return (
    <React.Fragment>
      {reactEChart}

      {menuCoords && menuOpen && (
        <Popover
          open={menuOpen}
          onClose={() => handleMenuClose()}
          anchorReference="anchorPosition"
          anchorPosition={menuCoords}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <NtaiContextMenuEChart onHandleContextMenu={onHandleContextMenu} />
        </Popover>
      )}
    </React.Fragment>
  );
}
