import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceWidgetFieldFilterForm from "../filter/SourceWidgetFieldFilterForm";
import SourceWidgetFieldsForm from "../fields/SourceWidgetFieldsForm";
import SourceWidgetUtil from "../../util/SourceWidgetUtil";
import SourceWidgetFieldColorFormat from "../color-scheme/SourceWidgetFieldColorFormat";
import SourceWidgetTitle from "./SourceWidgetTitle";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import SourceWidgetFormPanel from "./SourceWidgetFormPanel";
import NtaiFieldsPanel from "@ntai/components/micros/NtaiFieldsPanel";
const _ = require("lodash");

export default function SourceWidgetBarLineChartForm({
  widget,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const [widgetFields, setWidgetFields] = useState(
    widget["sourceWidgetFields"]
  );

  const dimensionFields =
    SourceWidgetUtil.getDimensionWidgetFields(widgetFields);

  const measureFields = SourceWidgetUtil.getMeasureWidgetFields(widgetFields);

  const dimensionFieldOptions =
    dimensionFields && _.isArray(dimensionFields) && dimensionFields.length > 0
      ? NtaiUtils.getSelectOptions(dimensionFields, "uuId", "fieldName")
      : [];

  const measureFieldOptions =
    measureFields && _.isArray(measureFields) && measureFields.length > 0
      ? NtaiUtils.getSelectOptions(measureFields, "uuId", "fieldName")
      : [];

  const revisedMeasureFieldOptions = [
    { value: "0", label: "Count" },
    ...measureFieldOptions,
  ];

  function onHandleSave() {
    const widgetFormData = getValues("widgetForm");

    const sanitizedFormData = NtaiUtils.sanitizeFormData(widgetFormData);

    let revisedSourceWidgetFields = _.cloneDeep(widget["sourceWidgetFields"]);
    const sanitizedFormFieldsData = sanitizedFormData["sourceWidgetFields"];

    revisedSourceWidgetFields.forEach((f, i) => {
      const sanitizedFormFieldData = NtaiUtils.sanitizeFormData(
        _.filter(sanitizedFormFieldsData, {
          uuId: f["uuId"],
        })[0]
      );

      f["headerName"] = sanitizedFormFieldData["headerName"];

      if (
        sanitizedFormData["xAxisFieldUuId"] &&
        sanitizedFormData["xAxisFieldUuId"].length > 0 &&
        sanitizedFormData["xAxisFieldUuId"] === f["uuId"]
      ) {
        f["xAxisFg"] = 1;
      } else {
        f["xAxisFg"] = null;
      }

      if (
        sanitizedFormData["yAxisFieldUuId"] &&
        sanitizedFormData["yAxisFieldUuId"].length > 1 &&
        sanitizedFormData["yAxisFieldUuId"] === f["uuId"]
      ) {
        f["sizeFg"] = 1;
        f["yAxisFg"] = 1;
      } else {
        f["yAxisFg"] = null;
        f["sizeFg"] = null;
      }

      if (
        sanitizedFormData["yAxis1FieldUuId"] &&
        sanitizedFormData["yAxis1FieldUuId"].length > 1 &&
        sanitizedFormData["yAxis1FieldUuId"] === f["uuId"]
      ) {
        f["yAxisIndexFg"] = 1;
        f["sizeFg"] = 1;
      } else {
        f["yAxisIndexFg"] = null;
      }

      if (SourceWidgetUtil.isWidgetFieldMeasure(f)) {
        f["metricCode"] = sanitizedFormFieldData["metricCode"];
        f["esAggFilterScript"] = sanitizedFormFieldData["esAggFilterScript"];
      }

      if (SourceWidgetUtil.isWidgetFieldDimension(f)) {
        let sourceWidgetFieldFiltersResult = [];
        if (_.isArray(sanitizedFormFieldData["sourceWidgetFieldFilters"])) {
          const sourceWidgetFieldFilters = _.get(
            sanitizedFormFieldData,
            "sourceWidgetFieldFilters"
          );

          if (
            _.isArray(sourceWidgetFieldFilters) &&
            sourceWidgetFieldFilters.length > 0
          ) {
            sourceWidgetFieldFilters.forEach((swf) => {
              if (swf["typeCode"] !== undefined && swf["typeCode"]["value"]) {
                sourceWidgetFieldFiltersResult.push({
                  value: swf["value"],
                  typeCode: swf["typeCode"]["value"],
                });
              }
            });
            f["sourceWidgetFieldFilters"] = sourceWidgetFieldFiltersResult;
          }
        }
      }
    });

    sanitizedFormData["sourceWidgetFields"] = revisedSourceWidgetFields;

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    let widgetCopy = _.cloneDeep(widget);
    reset({ widgetForm: widgetCopy });
  }, [widget]);

  return (
    <SourceWidgetFormPanel
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <NtaiTabs>
        <Box label="TITLE">
          <SourceWidgetTitle />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FIELDS">
          <SourceWidgetFieldsForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="AXIS">
          <NtaiPanel
            height="100%"
            subheader="Provide meaningful name for the widget. Use other fields to specify measures and other values for the selected field"
          >
            {dimensionFieldOptions && dimensionFieldOptions.length > 0 && (
              <NtaiSelectField
                options={dimensionFieldOptions}
                name="widgetForm.xAxisFieldUuId"
                label="X-Axis Field"
              />
            )}

            {revisedMeasureFieldOptions &&
              revisedMeasureFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={revisedMeasureFieldOptions}
                  name="widgetForm.yAxisFieldUuId"
                  label="Bar Y-Axis Field"
                />
              )}

            {revisedMeasureFieldOptions &&
              revisedMeasureFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={revisedMeasureFieldOptions}
                  name="widgetForm.yAxis1FieldUuId"
                  label="Line Y-Axis Field"
                />
              )}
          </NtaiPanel>
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FILTERS">
          <SourceWidgetFieldFilterForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="COLOR">
          <SourceWidgetFieldColorFormat
            handleWidgetSave={handleSave}
            widget={widget}
          />
        </Box>
      </NtaiTabs>
    </SourceWidgetFormPanel>
  );
}
