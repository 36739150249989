import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React from "react";
import { ReportDefinitionSourceFilterContext } from "./ReportDefinitionSourceFilterWrapper";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { updateReportDefinitionParam } from "../store/reportDefinitionParamsSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function ReportDefinitionSourceFilterFieldForm() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, reset } = useFormContext();

  const { selectedField, setSelectedField } = React.useContext(
    ReportDefinitionSourceFilterContext
  );

  const reportDefinitionId = useSelector(
    (state) => state.reports.reportDefinitionsSlice.activeId
  );

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const sourceFilterFieldUIOptions = _.get(
    codelistData,
    CODELIST_CODES.RPT_DEFINITION_PARAM_SOURCE_FILTER_FIELD_UI_CODE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("reportDefinitionSourceFilterFieldForm")
    );

    dispatch(
      updateReportDefinitionParam({
        reportDefinitionId: reportDefinitionId,
        uuId: _.get(sanitizedFormData, "uuId"),
        formData: sanitizedFormData,
      })
    );
  }

  useEffect(() => {
    reset({ reportDefinitionSourceFilterFieldForm: selectedField });
  }, [selectedField]);

  return (
    <Box sx={{ flexBasis: "50%", display: "flex", flexDirection: "column" }}>
      {selectedField && !_.isEmpty(selectedField) && (
        <NtaiFormPanel
          header={_.get(selectedField, "name")}
          subheader={
            _.get(selectedField, "description") || "Description not available"
          }
          handleSave={onHandleSave}
        >
          <NtaiTextField
            name="reportDefinitionSourceFilterFieldForm.name"
            label="Name*"
            placeholder="Name"
            rules={{
              required: "Name is required",
            }}
          />
          <NtaiTextField
            name="reportDefinitionSourceFilterFieldForm.description"
            label="Description"
            placeholder="Description"
            multiline
            minRows={3}
            maxRows={3}
          />
          <NtaiSelectField
            name="reportDefinitionSourceFilterFieldForm.typeCode"
            label="Filter UI Type*"
            options={sourceFilterFieldUIOptions}
            rules={{
              required: "Filter UI type is required",
            }}
          />
        </NtaiFormPanel>
      )}
      {!selectedField && (
        <NtaiEmptyMessage
          header="No field selected"
          subheader="To update field, selected field from the left panel"
        />
      )}
    </Box>
  );
}
