import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  deleteDashboardView,
  getDashboardViews,
} from "./store/dashboardViewsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "archive",
    label: "Delete",
    icon: "archive",
  },
];

export default function DashboardViewList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dataMap = useSelector((state) => state.core.dashboardViewsSlice.data);
  const status = useSelector((state) => state.core.dashboardViewsSlice.status);
  const data = Object.values(dataMap);

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "archive") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "archive") {
        dispatch(deleteDashboardView(id));
      }
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getDashboardViews" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteDashboardView"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Dashboard(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getDashboardViews());
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Dashboard Views">
        {loaded && (
          <NtaiCrudTable
            helpText="You can create one or more dashboards in this section. To view dashboards, assign them to individual roles"
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/admin/dashboards/views`}
            handleToolbarAction={handleToolbarAction}
            title="Dashboard"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
