import { faFilter, faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiDashboardTableWidget from "@ntai/components/widgets/dashboard/templates/NtaiDashboardTableWidget";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";

function formatDate(dateValue) {
  if (dateValue !== null) {
    return NtaiUtils.localDate(dateValue);
  } else return null;
}

function formatType(typeCode) {
  if (typeCode === 1) return <FontAwesomeIcon icon={faSearch} />;
  else return <FontAwesomeIcon icon={faFilter} />;
}

const headCells = [
  {
    id: "abbrev",
    label: "Source",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "owner",
    label: "Owner",
  },
  {
    id: "date",
    label: "Modified",
    transformFunc: formatDate,
  },
];

export default function SourceListWidget(props) {
  const { data } = props;

  return <NtaiDashboardTableWidget data={data} headCells={headCells} />;
}
