import { faShareAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { getUsers } from "app/main/pages/core/admin/user/store/usersSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../SearchEdit";
import SearchActionShareForm from "./SearchActionShareForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createSearchActionShare,
  getSearchActionShares,
} from "./store/searchActionShareSlice";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
const _ = require("lodash");

export default function SearchActionShare() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchData } = React.useContext(SearchContext);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);

  const searchId = _.get(searchData, "uuId");
  const dataMap = useSelector(
    (state) => state.search.searchActionShareSlice.data
  );

  const status = useSelector(
    (state) => state.search.searchActionShareSlice.status
  );

  const count = _.isArray(Object.values(dataMap))
    ? Object.values(dataMap).length
    : null;

  function handleShareDialogOpen() {
    setShareDialogOpen(true);
  }

  function handleShareDialogClose() {
    setShareDialogOpen(false);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "createSearchActionShare"
    ) {
      handleShareDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  function handleSave(formData) {
    dispatch(
      createSearchActionShare({
        searchId: _.get(searchData, "uuId"),
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(getSearchActionShares(searchId));
  }, []);

  return (
    <React.Fragment>
      <NtaiMuiBadge badgeContent={count} color="secondary" top={8} right={2}>
        <Tooltip title="Share">
          <IconButton onClick={() => handleShareDialogOpen()}>
            <FontAwesomeIcon size="2xs" icon={faShareAlt} />
          </IconButton>
        </Tooltip>
      </NtaiMuiBadge>

      <NtaiDialog
        title="Share with other users and groups"
        size="sm"
        open={shareDialogOpen}
        handleDialogClose={handleShareDialogClose}
      >
        <NtaiForm>
          <SearchActionShareForm
            handleSave={handleSave}
            handleCancel={handleShareDialogClose}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
