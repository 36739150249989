import { useTheme } from "@mui/material";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadDataAdapterPythonPackage } from "../store/dataAdaptersSlice";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function DataAdapterFileUpload({ formData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fileUploadData, setFileUploadData] = useState();

  const dataMap = useSelector((state) => state.core.dataAdaptersSlice.data);

  const activeId = useSelector(
    (state) => state.core.dataAdaptersSlice.activeId
  );

  const data = dataMap && dataMap[activeId];

  function handleFileUpload(formData, config) {
    setFileUploadData({ formData: formData, config: config });
  }

  function onHandleSave() {
    if (!_.isEmpty(fileUploadData) && _.has(fileUploadData, "formData")) {
      dispatch(
        uploadDataAdapterPythonPackage({
          uuId: activeId,
          formData: _.get(fileUploadData, "formData"),
        })
      );
    }
  }

  function displayUploadedFile() {
    if (_.get(data, "pythonPackageFileName")) {
      return (
        <NtaiLabelValue
          label="Uploaded File"
          value={
            _.get(data, "pythonPackageFileName") +
            " (" +
            NtaiUtils.formatBytes(_.get(data, "pythonPackageFileSize")) +
            ")"
          }
        />
      );
    }
  }

  return (
    <NtaiFormPanel
      width="50%"
      header="Upload Python Package"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      handleSave={onHandleSave}
    >
      <NtaiFileUpload handleFileUpload={handleFileUpload} />
      {displayUploadedFile()}
    </NtaiFormPanel>
  );
}
