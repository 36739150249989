import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSourceLinkedSource,
  getSourceLinkedSources,
} from "./store/sourceLinkedSourcesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "linkedSourceName",
    numeric: false,
    disablePadding: false,
    label: "Linked Source",
    fontWeight: 700,
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "archive",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourceLinkedSourceList(props) {
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "archive") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "archive") {
        dispatch(
          deleteSourceLinkedSource({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      }
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  useEffect(() => {
    dispatch(
      getSourceLinkedSources({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <>
      <NtaiPage
        title={`${_.get(sourceDefData, "name")} / Linked Sources`}
        padding={0}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        <NtaiPanel
          width="75%"
          header="Linked Sources"
          subheader="Use common fields mapping to link one source to another. Linked sources are created automatically while performing searches on desired source. Users can navigate to one or more linked sources to create detailed exploratory analysis."
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`}
            handleToolbarAction={handleToolbarAction}
            title="Linked Source"
          />
        </NtaiPanel>
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromSourceLinkedSource(
            selectedIds,
            dataMap,
            "name"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
