import React, { useEffect, useRef, useState } from "react";

import {
  CHART_CODES,
  SEARCH_SOURCE_WIDGET_MENU_ACTIONS,
} from "app/main/constants/NtaiCodelistConstants";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import { Drawer, useTheme } from "@mui/material";
import { SearchSourceContext } from "../../SearchSource";
import { SearchContext } from "../../../SearchEdit";
import {
  clearStatus,
  updateWidgetTempFilters,
  getSearchSourceViewWidgetForSelect,
  deleteSearchSourceViewDrillWidget,
} from "./store/searchSourceViewWidgetsSlice";
import { useDispatch, useSelector } from "react-redux";
import SearchSourceWidgetInfoDialog from "./info/SearchSourceWidgetInfoDialog";
import SearchSourceWidgetChart from "./SearchSourceWidgetChart";
import useSearchSourceWidgetTempFilters from "../../hooks/useSearchSourceWidgetTempFilters";
import SearchNotesDrawer from "../../../notes/SearchNotesDrawer";
import SearchNotesIcon from "../../../notes/notes-icon/SearchNotesIcon";
import NtaiChartEmptyMessage from "@ntai/components/charts/NtaiChartEmptyMessage";
const _ = require("lodash");

function getWidgetDataPointFieldLabelValues(widgetFields, fieldValues) {
  const listResult = fieldValues.map((o, i) => {
    return (
      widgetFields[o["sourceWidgetFieldUuId"]]["field"]["label"] +
      " = " +
      o["fieldValue"]
    );
  });

  return listResult.join(" and ");
}

export default function SearchSourceWidget({
  searchSourceId,
  sourceViewUuId,
  widgetInitData,
  handleFilter,
  filterPresent,
  handleLinkClick,
  drillModeOn,
  drillModeWidgetUuId,
  handleDrillMode,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [widgetError, setWidgetError] = useState(false);
  const [widgetSearchText, setWidgetSearchText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [widgetRefresh, setWidgetRefresh] = useState(false);
  const [searchSourceWidgetFilterFields, setSearchSourceWidgetFilterFields] =
    React.useState();

  const [notesDrawerOpen, setNotesDrawerOpen] = React.useState(false);
  const [notesLevelCode, setNotesLevelCode] = React.useState();
  const [notesContext, setNotesContext] = React.useState();
  const [localWidgetInitData, setLocalWidgetInitData] =
    useState(widgetInitData);

  // const [localWidgetData, setLocalWidgetData] = useState();
  const { reload, refresh, setReload, setRefresh, searchSourceData } =
    React.useContext(SearchSourceContext);
  // const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const [localReload, setLocalReload] = useState(0);

  const previousWidgetInitDataValue =
    usePreviousWidgetInitDataValue(widgetInitData);

  const previousReloadValue = usePreviousReloadValue(reload);

  const previousWidgetSearchTextValue =
    usePreviousWidgetSearchTextValue(widgetSearchText);

  const previousCurrentPageValue = usePreviousCurrentPageValue(currentPage);

  const { searchData } = React.useContext(SearchContext);
  const searchId = _.get(searchData, "uuId");

  const dataMap = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.data
  );

  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const widgetStatusAll = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.widgetStatus
  );

  const status = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.status
  );

  const widget = drillModeOn
    ? _.get(dataMap, drillModeWidgetUuId)
    : _.get(dataMap, widgetInitData.sourceViewWidgetUuId);

  const widgetStatus =
    widgetStatusAll &&
    _.get(
      widgetStatusAll,
      drillModeOn
        ? widget["widgetUuId"]
        : localWidgetInitData.sourceViewWidgetUuId
    );

  const widgetRef = useRef();
  const widgetMetadataRef = useRef();
  widgetRef.current =
    widget && !_.isEmpty(widget)
      ? {
          widgetUuId: widget["widgetUuId"],
          rootWidgetUuId: widget["rootWidgetUuId"],
          parentSourceWidgetUuId: widget["parentSourceWidgetUuId"],
        }
      : null;

  widgetMetadataRef.current =
    widget && !_.isEmpty(widget) ? widget["metadata"] : null;

  const widgetMetadata = _.get(widget, "metadata");

  function handleNotesDrawerOpen(level) {
    setNotesDrawerOpen(true);
    setNotesLevelCode(level);
    if (level === 4) {
      setNotesContext(widget["widgetName"]);
    }
  }

  function handleNotesDrawerClose() {
    setNotesDrawerOpen(false);
    setSearchSourceWidgetFilterFields(null);
    setNotesContext(null);
  }

  function getFieldLabel(pSourceWidgetFieldUuId, pWidgetMetadataRef) {
    const pWidgetFieldData =
      pWidgetMetadataRef.current["fields"][pSourceWidgetFieldUuId];

    if (pWidgetFieldData["field"]) return pWidgetFieldData["field"]["label"];
    else return pWidgetFieldData["derivedField"]["label"];
  }

  function handleClick(searchSourceWidgetFilterFields) {
    if (widgetMetadataRef.current && !_.isEmpty(widgetMetadataRef.current)) {
      dispatch(
        updateWidgetTempFilters({
          searchSourceId: searchSourceId,
          widgetUuId: widgetRef.current["widgetUuId"],
          widgetTempFilter: searchSourceWidgetFilterFields.map((f, i) => {
            return {
              ...f,
              sourceFieldLabel: getFieldLabel(
                f.sourceWidgetFieldUuId,
                widgetMetadataRef
              ),
            };
          }),
        })
      );
    }

    // searchSourceWidgetFilters: [{searchSourceWidgetFilterRequestMode {actionCode, array of searchSourcefilterFields} }, {}]
  }

  function handleContextMenu(actionCode, pSearchSourceWidgetFilterFields) {
    //handleFilter(_.get(widget, "widgetUuId"), searchSourceWidgetFilterFields);

    if (widgetMetadataRef.current && !_.isEmpty(widgetMetadataRef.current)) {
      if (actionCode === SEARCH_SOURCE_WIDGET_MENU_ACTIONS.NOTES) {
        setSearchSourceWidgetFilterFields(pSearchSourceWidgetFilterFields);
        handleNotesDrawerOpen(5);

        setNotesContext(
          getWidgetDataPointFieldLabelValues(
            widget["metadata"]["fields"],
            pSearchSourceWidgetFilterFields
          )
        );
      } else {
        handleFilter(
          actionCode,
          widgetRef.current["widgetUuId"],
          pSearchSourceWidgetFilterFields
        );
      }
    }
  }

  function handleInfoDialogOpen() {
    setInfoDialogOpen(true);
  }

  function handleInfoDialogClose() {
    setInfoDialogOpen(false);
  }

  function handleWidgetSearch(text) {
    setWidgetSearchText(text);
    setCurrentPage(1);
  }

  function handlePagination(dir) {
    if (dir === -1 && currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1);
    } else if (
      dir === 1 &&
      currentPage + 1 <= widgetMetadata["pagination"]["totalPages"]
    ) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handleDeleteDrillWidget() {
    dispatch(
      deleteSearchSourceViewDrillWidget({
        searchId: searchId,
        searchSourceId: searchSourceId,
        sourceViewId: sourceViewUuId,
        sourceRootWidgetId: widgetRef.current["rootWidgetUuId"],
        formData: {
          widgetUuId: widgetRef.current["widgetUuId"],
          sourceWidgetUuId: widgetRef.current["widgetUuId"],
          sourceViewUuId: sourceViewUuId,
          parentSourceWidgetUuId: widgetRef.current["parentSourceWidgetUuId"],
          searchSourceWidgetTempFilters: searchSourceWidgetTempFilters,
        },
      })
    );
  }

  function usePreviousWidgetInitDataValue(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  function usePreviousReloadValue(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = reload;
    });
    return ref.current;
  }

  function usePreviousWidgetSearchTextValue(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = widgetSearchText;
    });
    return ref.current;
  }

  function usePreviousCurrentPageValue(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = currentPage;
    });
    return ref.current;
  }

  useEffect(() => {
    if (
      status &&
      ["updateWidgetTempFilters", "deleteWidgetTempFilter"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setReload(reload + 1);
      setRefresh(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    // setWidgetId(_.get(widgetInitData, "sourceViewWidgetUuId"));

    if (
      !_.isEqual(localWidgetInitData, previousWidgetInitDataValue) ||
      !_.isEqual(reload, previousReloadValue) ||
      !_.isEqual(widgetSearchText, previousWidgetSearchTextValue) ||
      !_.isEqual(currentPage, previousCurrentPageValue)
    ) {
      dispatch(
        getSearchSourceViewWidgetForSelect({
          searchId: searchId,
          searchSourceId: searchSourceId,
          sourceViewId: sourceViewUuId,
          sourceViewWidgetId: drillModeOn
            ? widget["widgetUuId"]
            : localWidgetInitData.sourceViewWidgetUuId,
          formData: {
            currentPage: currentPage,
            searchText: widgetSearchText,
            searchSourceWidgetFilters: searchSourceWidgetTempFilters,
          },
        })
      );

      if (reload > localReload) {
        setLocalReload(reload);
      }

      setWidgetRefresh(true);
    }
  }, [localWidgetInitData, reload, widgetSearchText, currentPage]);

  // sp: status for each widget should be unique. The below should be looked into.
  useEffect(() => {
    if (
      widgetStatus &&
      widgetStatus.method === "getSearchSourceViewWidgetForSelect" &&
      widgetStatus.result === "success"
    ) {
      setWidgetRefresh(false);
      setWidgetError(false);
    }

    if (
      widgetStatus &&
      widgetStatus.method === "getSearchSourceViewWidgetForSelect" &&
      widgetStatus.result === "error"
    ) {
      setWidgetRefresh(false);
      setWidgetError(true);
    }
  }, [widgetStatus]);

  useEffect(() => {
    setLocalWidgetInitData(widgetInitData);
  }, [widgetInitData]);

  return (
    <React.Fragment>
      <NtaiSourceWidget
        id={widgetInitData["sourceViewWidgetUuId"]}
        headerActionsFg={
          [CHART_CODES.AGGREGATE_METRIC, CHART_CODES.SCRIPTED_METRIC].includes(
            widgetInitData["sourceViewWidgetChartCode"]
          )
            ? false
            : true
        }
        filterPresent={filterPresent}
        footerPresent={true}
        loading={(widget && !widgetRefresh) || widgetError ? false : true}
        title={
          (widget &&
            _.has(widget, "widgetName") &&
            _.get(widget, "widgetName")) ||
          _.get(widgetInitData, "sourceViewWidgetName")
        }
        handleInfoDialogOpen={handleInfoDialogOpen}
        // handleNoteListDialogOpen={() => handleNotesDrawerOpen(4)}
        // numOfNotes={_.get(widgetInitData, "numOfNotes")}
        noteIcon={
          <SearchNotesIcon
            levelCode={4}
            searchId={searchId}
            searchSourceId={searchSourceId}
            sourceViewId={sourceViewUuId}
            sourceWidgetId={
              widgetInitData["sourceViewWidgetUuId"] ||
              widgetRef.current["widgetUuId"]
            }
            handleNotesDrawerOpen={handleNotesDrawerOpen}
          />
        }
        onSearchSubmit={
          widgetInitData["sourceViewWidgetSearchFg"] === 1
            ? handleWidgetSearch
            : null
        }
        handlePagination={
          widgetInitData["sourceViewWidgetPaginationFg"] === 1
            ? handlePagination
            : null
        }
        pagination={widgetMetadata ? widgetMetadata["pagination"] : null}
        handleDrillMode={() => handleDrillMode(_.get(widget, "widgetUuId"))}
        handleDeleteDrillWidget={
          widget &&
          _.has(widget, "parentSourceWidgetUuId") &&
          widget["parentSourceWidgetUuId"] != null &&
          widget["parentSourceWidgetUuId"].length > 0
            ? handleDeleteDrillWidget
            : null
        }
        widgetError={widgetError}
      >
        <SearchSourceWidgetChart
          widget={widget}
          widgetMetadata={widgetMetadataRef.current}
          handleClick={handleClick}
          handleLinkClick={handleLinkClick}
          handleContextMenu={handleContextMenu}
        />
      </NtaiSourceWidget>

      {/* {noteCreateDialogOpen && (
        <SearchSourceWidgetNoteCreateDialog
          open={noteCreateDialogOpen}
          handleDialogClose={handleNoteCreateDialogClose}
          sourceWidgetUuId={widgetInitData.sourceViewWidgetUuId}
          searchSourceWidgetDataPointFields={searchSourceWidgetFilterFields}
        />
      )}

      {noteListDialogOpen && (
        <SearchSourceWidgetNoteListDialog
          open={noteListDialogOpen}
          handleDialogClose={handleNoteListDialogClose}
          widgetInitData={widgetInitData}
        />
      )} */}

      {infoDialogOpen && (
        <SearchSourceWidgetInfoDialog
          open={infoDialogOpen}
          handleDialogClose={handleInfoDialogClose}
          metadata={widgetMetadata}
        />
      )}

      {notesDrawerOpen && notesContext && (
        <Drawer
          PaperProps={{
            style: {
              position: "absolute",
              top: 48, // 139
              height: `calc(100% - 48px)`,
              width: "360px",
            },
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={notesDrawerOpen}
          onClose={handleNotesDrawerClose}
          anchor="right"
          BackdropProps={{ invisible: true }}
        >
          {notesLevelCode &&
            [4, 5].includes(notesLevelCode) &&
            notesContext && (
              <SearchNotesDrawer
                levelCode={notesLevelCode}
                notesContext={notesContext}
                sourceViewId={sourceViewUuId}
                sourceWidgetId={widgetInitData["sourceViewWidgetUuId"]}
                searchSourceWidgetDataPointFields={
                  searchSourceWidgetFilterFields
                }
                handleClose={handleNotesDrawerClose}
              />
            )}
        </Drawer>
      )}
    </React.Fragment>
  );
}
