import React from "react";
import { CssBaseline } from "@mui/material";
import { create } from "jss";
import jssExtend from "jss-plugin-extend";
import rtl from "jss-rtl";
import history from "@ntai/@history";
import Provider from "react-redux/es/components/Provider";
import { store, persistor } from "./store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
} from "@mui/styles";
import { Router } from "react-router-dom";
import routes from "./configs/routesConfig";
import NtaiThemeProvider from "app/theme/NtaiThemeProvider";
import AppContext from "./AppContext";
import Layout from "./layouts/Layout";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import Layout1 from "./layouts/Layout1";
import { LocalizationProvider } from "@mui/x-date-pickers";
import UserPermissionProvider from "@ntai/components/rbac/UserPermissionProvider";
import NtaiSnackbarCloseButton from "@ntai/components/snackbar/NtaiSnackbarCloseButton";
import SessionTimeoutProvider from "./main/pages/login/SessionTimeoutProvider";
import Auth from "./auth/Auth";
import { useDispatch } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./main/pages/errors/ErrorFallback";

// import { ErrorBoundary } from "@sentry/react";

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const generateClassName = createGenerateClassName({ disableGlobal: true });

export default function App() {
  const handleErrorReset = () => {
    history.push("/");
  };

  return (
    <AppContext.Provider value={{ routes }}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={handleErrorReset}
      >
        <StylesProvider jss={jss} generateClassName={generateClassName}>
          <Provider store={store}>
            <Auth>
              <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                  <UserPermissionProvider>
                    <NtaiThemeProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <ErrorBoundary fallback={"An error has occurred"}> */}
                        <SnackbarProvider
                          maxSnack={6}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          action={(key) => <NtaiSnackbarCloseButton k={key} />}
                        >
                          <SessionTimeoutProvider>
                            <React.Fragment>
                              <CssBaseline />
                              <Layout1 />
                            </React.Fragment>
                          </SessionTimeoutProvider>
                        </SnackbarProvider>
                        {/* </ErrorBoundary> */}
                      </LocalizationProvider>
                    </NtaiThemeProvider>
                  </UserPermissionProvider>
                </Router>
              </PersistGate>
            </Auth>
          </Provider>
        </StylesProvider>
      </ErrorBoundary>
    </AppContext.Provider>
  );
}
