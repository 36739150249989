import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getWorkflowDefVersionActivityRules = createAsyncThunk(
  "workflowDefVersionActivityRules/getWorkflowDefVersionActivityRules",
  async (values, { rejectWithValue }) => {
    try {
      const {
        workflowDefId,
        workflowDefVersionId,
        workflowDefVersionActivityId,
      } = values;
      const response = await server.get(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${workflowDefVersionActivityId}/rules`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWorkflowDefVersionActivityRule = createAsyncThunk(
  "workflowDefVersionActivityRules/getWorkflowDefVersionActivityRule",
  async (values, { rejectWithValue }) => {
    try {
      const {
        workflowDefId,
        workflowDefVersionId,
        workflowDefVersionActivityId,
        uuId,
      } = values;
      const response = await server.get(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${workflowDefVersionActivityId}/rules/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createWorkflowDefVersionActivityRule = createAsyncThunk(
  "workflowDefVersionActivityRules/createWorkflowDefVersionActivityRule",
  async (values, { rejectWithValue }) => {
    try {
      const {
        workflowDefId,
        workflowDefVersionId,
        workflowDefVersionActivityId,
        formData,
      } = values;
      const response = await server.post(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${workflowDefVersionActivityId}/rules`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateWorkflowDefVersionActivityRule = createAsyncThunk(
  "workflowDefVersionActivityRules/updateWorkflowDefVersionActivityRule",
  async (values, { rejectWithValue }) => {
    try {
      const {
        workflowDefId,
        workflowDefVersionId,
        workflowDefVersionActivityId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${workflowDefVersionActivityId}/rules/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteWorkflowDefVersionActivityRule = createAsyncThunk(
  "workflowDefVersionActivityRules/deleteWorkflowDefVersionActivityRule",
  async (values, { rejectWithValue }) => {
    try {
      const {
        workflowDefId,
        workflowDefVersionId,
        workflowDefVersionActivityId,
        uuId,
      } = values;
      const response = await server.delete(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${workflowDefVersionActivityId}/rules/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const workflowDefVersionActivityRulesSlice = createSlice({
  name: "workflowDefVersionActivityRules",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getWorkflowDefVersionActivityRules.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getWorkflowDefVersionActivityRules",
      };
    },
    [getWorkflowDefVersionActivityRules.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefVersionActivityRules",
        message: action.payload.message,
      };
    },
    [getWorkflowDefVersionActivityRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getWorkflowDefVersionActivityRule",
      };
    },
    [getWorkflowDefVersionActivityRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefVersionActivityRule",
        message: action.payload.message,
      };
    },
    [createWorkflowDefVersionActivityRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createWorkflowDefVersionActivityRule",
      };
    },
    [createWorkflowDefVersionActivityRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createWorkflowDefVersionActivityRule",
        message: action.payload.message,
      };
    },
    [updateWorkflowDefVersionActivityRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateWorkflowDefVersionActivityRule",
      };
    },
    [updateWorkflowDefVersionActivityRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateWorkflowDefVersionActivityRule",
        message: action.payload.message,
      };
    },
    [deleteWorkflowDefVersionActivityRule.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteWorkflowDefVersionActivityRule",
      };
    },
    [deleteWorkflowDefVersionActivityRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteWorkflowDefVersionActivityRule",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  workflowDefVersionActivityRulesSlice.actions;

export default workflowDefVersionActivityRulesSlice.reducer;
