import { Box, Typography } from "@mui/material";
import withUserPermission from "@ntai/components/rbac/withUserPermission";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  moduleButton: {
    display: "flex",
    gap: "8px",
    margin: "1px",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    border: "1px solid rounded #eee",
    borderRadius: "6px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  moduleButtonHover: {
    "&:hover": {
      background: theme.colors.primary.light,
    },
  },
  moduleButtonUnselected: {
    backgroundColor: theme.colors.primary.main,
  },
  moduleButtonSelected: {
    background: theme.colors.primary.light,
  },
}));

function ModuleButtonRbac({
  key,
  module,

  handleSelect,
  permissions,
}) {
  const theme = useTheme();

  const selectedModule = useSelector(
    (state) => state.ntai.navigation.selectedModule
  );

  const classes = useStyles();

  return (
    <Box
      key={key}
      onClick={() => handleSelect(module["id"])}
      className={clsx({
        [classes.moduleButton]: true,
        [classes.moduleButtonSelected]: selectedModule === module.id,
        [classes.moduleButtonHover]: selectedModule !== module.id,
      })}
    >
      {module.icon}
      <Typography variant="subtitle1" color="inherit" fontWeight={600}>
        {module.label}
      </Typography>
    </Box>
  );
}

export default withUserPermission(ModuleButtonRbac);
