import { useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getDashboardSourceViews,
  getDashboardSourceWidgets,
} from "./store/dashboardSourceViewsSlice";
import { useState } from "react";
const _ = require("lodash");

export default function DashboardSourceWidgetSelection({ formData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [options, setOptions] = useState([]);

  const sourceId = _.get(formData, "sourceUuId");

  const sourceViewsDataMap = useSelector(
    (state) => state.core.dashboardSourceViewsSlice.data
  );

  const sourceWidgetsDataMap = useSelector(
    (state) => state.core.dashboardSourceViewsSlice.widgets
  );

  console.log("Dashboard mgmt widgets: ", sourceWidgetsDataMap);

  const status = useSelector(
    (state) => state.core.dashboardSourceViewsSlice.status
  );

  function fetchWidgetNamesFromViews() {
    let result = [];

    if (!_.isEmpty(sourceViewsDataMap)) {
      _.orderBy(Object.values(sourceViewsDataMap), ["name"], ["asc"]).forEach(
        (sv, i) => {
          if (_.isArray(sv["sourceWidgets"])) {
            _.orderBy(sv["sourceWidgets"], ["name"], ["asc"]).forEach(
              (svw, j) => {
                result.push({
                  value: svw["uuId"],
                  label: sv["name"] + " - " + svw["name"],
                });
              }
            );
          }
        }
      );
    }

    return result;
  }

  function fetchWidgetNamesFromWidgets() {
    let result = [];

    if (!_.isEmpty(sourceWidgetsDataMap)) {
      _.orderBy(Object.values(sourceWidgetsDataMap), ["name"], ["asc"]).forEach(
        (sv, i) => {
          // Add only widgets of view type 4 (Home - Dashboard)
          if (sv["sourceViewTypeCode"] === 4) {
            result.push({
              value: sv["uuId"],
              label: sv["sourceViewName"] + " - " + sv["name"],
            });
          }
        }
      );
    }

    return result;
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getDashboardSourceWidgets" &&
      status.result === "success"
    ) {
      setOptions([...fetchWidgetNamesFromWidgets()]);
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getDashboardSourceWidgets({
        sourceDefId: "*",
        sourceId: sourceId,
      })
    );

    // dispatch(
    //   getDashboardSourceViews({
    //     sourceDefId: "*",
    //     sourceId: sourceId,
    //     params: {
    //       fetchMin: false,
    //       activeFg: 1,
    //       sourceUuId: sourceId,
    //       typeCode: 4,
    //     },
    //   })
    // );
  }, [sourceId]);

  return (
    <NtaiSelectField
      label="Select Widget"
      name="dashboardWidgetForm.sourceWidgetUuId"
      options={options}
    />
  );
}
