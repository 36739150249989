import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourcePipelineInStoreDatasetPreview = createAsyncThunk(
  "sourcePipelineInStoreDatasets/getSourcePipelineInStoreDatasetPreview",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceInStoreId,
        sourceInStoreDatasetId,
      } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ins/${sourceInStoreId}/datasets/${sourceInStoreDatasetId}/preview`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePipelineInStoreDatasetsSlice = createSlice({
  name: "sourcePipelineInStoreDatasets",
  initialState: {
    data: {},
    status: null,
    message: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
      state.message = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePipelineInStoreDatasetPreview.fulfilled]: (state, action) => {
      state.data = action.payload;

      state.status = {
        result: "success",
        method: "getSourcePipelineInStoreDatasetPreview",
      };
    },
    [getSourcePipelineInStoreDatasetPreview.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelineInStoreDatasetPreview",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourcePipelineInStoreDatasetsSlice.actions;

export default sourcePipelineInStoreDatasetsSlice.reducer;
