import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import {
  getSchedulerJobs,
  startSchedulerJob,
  stopSchedulerJob,
} from "./store/schedulerJobsSlice";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "itemName",
    numeric: false,
    disablePadding: false,
    label: "Item",
  },
  {
    id: "cronTrigger",
    numeric: false,
    disablePadding: false,
    label: "Frequency",
    transformFunc: NtaiUtils.cronExpressionToString,
  },
  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: "Message",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    label: "Start",
    icon: "play",
    value: "start",
  },
  {
    label: "Stop",
    icon: "stop",
    value: "stop",
  },
];

export default function SchedulerJobs(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const schedulerId = props.match.params.id;
  const schedulerDataMap = useSelector(
    (state) => state.core.schedulersSlice.data
  );
  const schedulerData = schedulerDataMap[schedulerId];
  const dataMap = useSelector((state) => state.core.schedulerJobsSlice.data);
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function displayJobInfo() {}

  function handleToolbarAction(toolbarAction, selectedItems) {
    selectedItems.forEach((o) => {
      if (toolbarAction === "start")
        dispatch(startSchedulerJob({ schedulerId: schedulerId, uuId: o }));
      else dispatch(stopSchedulerJob({ schedulerId: schedulerId, uuId: o }));
    });
  }

  useEffect(() => {
    dispatch(getSchedulerJobs(schedulerId));
  }, [schedulerId]);

  return (
    <NtaiPage
      title={`Schedulers / ${schedulerData["name"]} / Jobs`}
      back="/admin/schedulers"
    >
      <NtaiPanel header={`${schedulerData["name"]} Jobs`} width="100%">
        {data && data.length > 0 && (
          <NtaiSimpleTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={displayJobInfo}
            handleToolbarAction={handleToolbarAction}
            title={`Scheduler Jobs (${data.length})`}
          />
        )}
        {data && data.length === 0 && (
          <NtaiEmptyMessage header="No jobs available" />
        )}
      </NtaiPanel>
    </NtaiPage>
  );
}
