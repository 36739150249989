import {
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import Scrollbar from "../Scrollbar";

export default function NtaiListSelect() {
  const theme = useTheme();
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
    >
      <TextField size="small" name="Something" />

      <Box
        sx={{
          height: "120px",
          border: theme.general.border1,
          borderRadius: theme.general.borderRadiusSm,
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <List sx={{ p: 0 }}>
          <ListItemButton sx={{ p: 0 }}>
            <ListItem secondaryAction={<Checkbox size="small" edge="end" />}>
              <ListItemText
                primary="Something more"
                secondary="more information for this dataset"
              />
            </ListItem>
          </ListItemButton>

          <Divider />
          <ListItemButton sx={{ p: 0 }}>
            <ListItem secondaryAction={<Checkbox size="small" edge="end" />}>
              <ListItemText primary="Something" />
            </ListItem>
          </ListItemButton>

          <Divider />
          <ListItemButton sx={{ p: 0 }}>
            <ListItem secondaryAction={<Checkbox size="small" edge="end" />}>
              <ListItemText primary="Something" />
            </ListItem>
          </ListItemButton>

          <Divider />
          <ListItemButton sx={{ p: 0 }}>
            <ListItem secondaryAction={<Checkbox size="small" edge="end" />}>
              <ListItemText primary="Something" />
            </ListItem>
          </ListItemButton>

          <Divider />
          <ListItemButton sx={{ p: 0 }}>
            <ListItem secondaryAction={<Checkbox size="small" edge="end" />}>
              <ListItemText primary="Something" />
            </ListItem>
          </ListItemButton>

          <Divider />
        </List>
      </Box>
    </Box>
  );
}
