import { Box, Typography, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";

export default function NtaiErrorMessage({ title, message }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        background: red[50],
        textAlign: "center",
      }}
    >
      <Typography variant="subtitle1" fontWeight="700" color="inherit">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="inherit">
        {message}
      </Typography>
    </Box>
  );
}
