import { Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSnackbar } from "notistack";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
const _ = require("lodash");

export default function WorkflowDefVersionUpload() {
  const theme = useTheme();
  const [open, setOpen] = useState();
  const { getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  function handleFileUpload(uploadFormData, config) {
    const workflowDefFormData = NtaiUtils.sanitizeFormData(
      getValues("workflowDefForm")
    );

    let formError = false;

    if (
      isNaN(_.get(workflowDefFormData, "totalDuration")) ||
      workflowDefFormData.totalDuration === "undefined"
    ) {
      formError = true;
      enqueueSnackbar("Total duration is required", {
        variant: "error",
      });
    }

    if (!formError) {
      uploadFormData.append(
        "versionDetails",
        JSON.stringify({
          totalDuration: workflowDefFormData.totalDuration,
          activeFg: workflowDefFormData.activeFg,
        })
      );

      //handleSave(uploadFormData, config);
    }
  }

  function handleSave() {}

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDialogOpen()}
          >
            Upload Process Definition
          </Button>
        </Box>
      </Box>
      <NtaiDialog
        title="Upload Process Definition"
        size="sm"
        open={open}
        handleDialogClose={handleDialogClose}
      >
        <NtaiDialogFormPanel
          subheader="Upload process definition file"
          handleSave={handleSave}
          handleCancel={handleDialogClose}
        >
          <NtaiFileUpload handleFileUpload={handleFileUpload} />
        </NtaiDialogFormPanel>
      </NtaiDialog>
    </React.Fragment>
  );
}
