import { combineReducers } from "@reduxjs/toolkit";
import ntai from "./ntai";
import auth from "app/auth/store";
import core from "../main/pages/core/store";
import monitor from "../main/pages/modules/monitor/store";
import sources from "../main/pages/core/sourcedefinition/store";
import models from "../main/pages/core/models/store";
import search from "../main/pages/modules/search/store";
import dashboard from "../main/pages/dashboard/store";
import oversight from "../main/pages/modules/oversight/store";
import reports from "../main/pages/modules/reports/store";
import tools from "../main/pages/modules/tools/store";
import message from "./messageSlice";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// const ntaiPersistConfig = {
//   key: "ntai",
//   storage: storage,
// };

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    ntai,
    auth,
    core,
    dashboard,
    monitor,
    search,
    sources,
    models,
    oversight,
    reports,
    tools,
    message,
    ...asyncReducers,
  });

  //   /*
  // 	Reset the redux store when user logged out
  // 	 */
  //   // if (action.type === "auth/user/userLoggedOut") {
  //   //   state = undefined;
  //   // }

  return combinedReducer(state, action);
};

export default createReducer;
