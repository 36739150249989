import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";

export default function SourceWidgetTitle(props) {
  const { children } = props;
  return (
    <NtaiPanel subheader="Enter widget title">
      <NtaiTextField
        sx={{ width: "100%" }}
        name="widgetForm.name"
        label="Name*"
        placeholder="Name"
        rules={{ required: "Name is required" }}
      />
      {children}
    </NtaiPanel>
  );
}
