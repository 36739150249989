import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";

import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createSourceOutJob,
  deleteSourceOutJob,
  getSourceOutJobs,
  setActiveId,
  submitSourceOutJob,
  updateSourceOutJob,
} from "./store/sourceOutJobsSlice";
import { useSnackbar } from "notistack";
import History from "@ntai/@history";
import SourceOutJobForm from "./SourceOutJobForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircle1,
  faGears,
} from "@fortawesome/pro-regular-svg-icons";
import { PendingOutlined } from "@mui/icons-material";
import { faCircleExclamation, faClock } from "@fortawesome/pro-solid-svg-icons";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

function getRunMode(runModeCode) {
  if (runModeCode === 1) return <FontAwesomeIcon icon={faCircle1} />;
  else return <FontAwesomeIcon icon={faClock} />;
}

function getJobOutStoreNames(sourceOutStoreNames) {
  return sourceOutStoreNames.join(", ");
}

function getStatusIcon(status) {
  if (status === 1) return <PendingOutlined />;
  else if (status === 2) return <FontAwesomeIcon icon={faGears} />;
  else if (status === 3) return <FontAwesomeIcon icon={faCheckCircle} />;
  else if (status === 4) return <FontAwesomeIcon icon={faCheckCircle} />;
  else if (status === 5) return <FontAwesomeIcon icon={faCircleExclamation} />;
  else return <FontAwesomeIcon icon={faCircleExclamation} />;
}

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "runModeCode",
    numeric: false,
    disablePadding: false,
    label: <FontAwesomeIcon icon={faClock} />,
    transformFunc: getRunMode,
    width: "5%",
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "15%",
    fontWeight: 700,
  },

  {
    id: "sourceOutStoreNames",
    numeric: false,
    disablePadding: false,
    label: "Stores",
    width: "15%",
    transformFunc: getJobOutStoreNames,
  },

  {
    id: "cronExpression",
    numeric: false,
    disablePadding: false,
    label: "Frequency",
    width: "15%",
    transformFunc: NtaiUtils.cronExpressionToString,
  },
  {
    id: "customFieldsUpdateJobFg",
    numeric: false,
    disablePadding: false,
    label: "Update Fields?",
    width: "10%",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "No";
    },
  },

  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "15%",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function SourceOutJobList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const params = { sourceDefId: sourceDefId, sourceId: sourceId };
  const dataMap = useSelector((state) => state.sources.sourceOutJobsSlice.data);
  const status = useSelector(
    (state) => state.sources.sourceOutJobsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(deleteSourceOutJob({ ...params, uuId: id }));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceOutJob({ ...params, uuId: currentId, formData: formData })
        )
      : dispatch(createSourceOutJob({ ...params, formData: formData }));
  }

  function handleSubmitJob() {
    dispatch(submitSourceOutJob({ ...params, uuId: currentId }));
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleRowAction(actionName, uuId) {
    if (actionName === "submit")
      dispatch(submitSourceOutJob({ ...params, uuId: uuId }));
    else if (actionName === "migrations") {
      dispatch(setActiveId(uuId));
      History.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs/${uuId}/migrations`
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceOutJobs" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      [
        "createSourceOutJob",
        "updateSourceOutJob",
        "submitSourceOutJob",
      ].includes(status.method)
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceOutJob"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "error" &&
      (status.method === "createSourceOutJob" ||
        status.method === "updateSourceOutJob")
    ) {
      enqueueSnackbar("Error storing job: " + status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceOutJobs(params));
  }, []);

  return (
    <>
      <NtaiPage
        padding={2}
        title={`${_.get(sourceDefData, "name")} / Migration Jobs`}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        {loaded && (
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleToolbarAction={handleToolbarAction}
            rowActions={[{ value: "migrations", label: "MIGRATIONS" }]}
            handleRowAction={handleRowAction}
            title="Migration Job"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Migration Job"
        size="md"
      >
        <NtaiForm>
          <SourceOutJobForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            handleSubmitJob={handleSubmitJob}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
