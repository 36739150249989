import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { getWidgets } from "../../../admin/widget/store/widgetsSlice";
import { useMeasure } from "react-use";
const _ = require("lodash");

export default function SourceUserWidgetForm({ action, formData, handleSave }) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [ref, { width, height }] = useMeasure();
  const [code, setCode] = React.useState();

  // const domainId = useSelector((state) => state.sources.domainsSlice.activeId);
  // const codes = useSelector((state) => state.sources.codelistsSlice.codes);
  // const objectCategoryOptions = _.get(
  //   codes,
  //   CODELIST_CODES.COR_META_OBJECT_CATEGORY_CODE
  // );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.data
  );
  const data = Object.values(dataMap);

  const widgetsDataMap = useSelector((state) => state.core.widgetsSlice.data);
  const widgetsData = Object.values(widgetsDataMap);

  const widgetOptions = !_.isEmpty(widgetsDataMap)
    ? NtaiUtils.getSelectOptions(
        _.orderBy(widgetsData, ["name"], ["asc"]),
        "uuId",
        "name"
      )
    : [];

  function onChangeCode(newValue) {
    setCode(newValue);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceUserWidgetForm")
    );

    sanitizedFormData["body"] = code;
    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`);
  }

  useEffect(() => {
    dispatch(getWidgets());
    reset({ sourceUserWidgetForm: action === "edit" ? formData : {} });
    setCode(action === "edit" ? formData["body"] : null);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          flexBasis: "50%",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: theme.spacing(2),
        }}
      >
        <NtaiFormPanel
          width="100%"
          header="General Information"
          subheader="Enter user widget general details including widget name, type and python file name"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="sourceUserWidgetForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />

          <NtaiTextField
            name="sourceUserWidgetForm.description"
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Description"
            label="Description"
          />
          <NtaiTextField
            name="sourceUserWidgetForm.pyFileName"
            label="Python file name*"
            placeholder="custom_widget.py"
            rules={{ required: "Python file name is required" }}
          />
          <NtaiSelectField
            options={widgetOptions}
            name="sourceUserWidgetForm.widgetUuId"
            label="Widget Display Type*"
            rules={{ required: "Widget display type is required" }}
          />
        </NtaiFormPanel>
      </Box>
      <Box
        sx={{
          flexBasis: "50%",
          display: "flex",
          height: "100%",
        }}
      >
        <NtaiFormPanel
          width="100%"
          header="Python Code"
          subheader="Paste python code for custom widget here. To add supporting python files use PY FILES tab"
        >
          <AceEditor
            mode="python"
            theme="github"
            onChange={onChangeCode}
            name="code"
            value={code}
            width="100%"
            height="250px"
            // editorProps={{ $blockScrolling: Infinity }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              // enableSnippets: true,
            }}
          />
        </NtaiFormPanel>
      </Box>
    </Box>
  );
}
