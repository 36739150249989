import { Box, Chip, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ObjectFormBuilderTabList from "./ObjectFormBuilderTabList";
import { faPlus, faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import ObjectFormBuilderTabForm from "./ObjectFormBuilderTabForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createObjectFormBuilderTab,
  deleteObjectFormBuilderTab,
  getObjectFormBuilderTabs,
  updateObjectFormBuilderTab,
} from "./store/objectFormBuilderTabsSlice";
import { ObjectFormBuilderContext } from "../ObjectFormBuilder";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import ObjectFormBuilderTabReorder from "./ObjectFormBuilderTabReorder";
const _ = require("lodash");

export default function ObjectFormBuilderTabs() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();

  const { selectedTabId, setSelectedTabId, refreshPanels, setRefreshPanels } =
    React.useContext(ObjectFormBuilderContext);

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);
  const objectFormId = useSelector(
    (state) => state.core.objectFormsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.objectFormBuilderTabsSlice.data
  );
  const status = useSelector(
    (state) => state.core.objectFormBuilderTabsSlice.status
  );

  const pathVariables = {
    domainId: domainId,
    objectId: objectId,
    objectFormId: objectFormId,
  };

  function onHandleSelectTab(id) {
    setSelectedTabId(selectedTabId === id ? null : id);
  }

  function onHandleAdd() {
    setAction("add");
    setCurrentId(null);
  }

  function onHandleEdit(id) {
    setAction("edit");
    setCurrentId(id);
  }

  function onHandleDelete(id) {
    dispatch(
      deleteObjectFormBuilderTab({
        ...pathVariables,
        uuId: id,
      })
    );
  }

  function onHandleReorder() {}

  function handleCancel() {
    setAction("list");
    setCurrentId(null);
  }

  function handleSave(formData) {
    action === "add"
      ? dispatch(
          createObjectFormBuilderTab({
            ...pathVariables,
            formData,
          })
        )
      : dispatch(
          updateObjectFormBuilderTab({
            ...pathVariables,
            uuId: currentId,
            formData: formData,
          })
        );
  }

  useEffect(() => {
    if (
      status &&
      ["createObjectFormBuilderTab", "updateObjectFormBuilderTab"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setAction("list");
      setCurrentId(null);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["deleteObjectFormBuilderTab"].includes(status.method) &&
      status.result === "success"
    ) {
      setRefreshPanels(true);
      setSelectedTabId(null);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getObjectFormBuilderTabs(pathVariables));
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "44px",
          width: "100%",
          justifyContent: "space-between",
          px: theme.spacing(1),
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <Typography variant="h6" fontWeight="700">
            Tabs
          </Typography>
          {selectedTabId &&
            !_.isEmpty(dataMap) &&
            _.has(dataMap, selectedTabId) && (
              <Chip
                variant="outlined"
                size="small"
                label={
                  <Typography variant="subtitle2" color="inherit">
                    {NtaiUtils.trunc(dataMap[selectedTabId]["name"], 15)}
                  </Typography>
                }
              />
            )}
        </Box>

        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <ObjectFormBuilderTabReorder
            disabled={!dataMap || _.isEmpty(dataMap) ? true : false}
          />

          <NtaiIconButton icon={faPlus} handleClick={onHandleAdd} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", height: `calc(100% - 44px)`, width: "100%" }}>
        <Scrollbar>
          {action === "list" && (
            <ObjectFormBuilderTabList
              onHandleEdit={onHandleEdit}
              onHandleDelete={onHandleDelete}
              onHandleSelectTab={onHandleSelectTab}
            />
          )}
          {("add" === action || (currentId && action === "edit")) && (
            <ObjectFormBuilderTabForm
              action={action}
              formData={action === "edit" ? dataMap[currentId] : null}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          )}
        </Scrollbar>
      </Box>
    </React.Fragment>
  );
}
