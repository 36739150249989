import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceIns,
  clearStatus as clearSourceInsSliceStatus,
} from "../../in/store/sourceInsSlice";
import {
  createSourcePipelineInStore,
  deleteSourcePipelineInStore,
  getSourcePipelineInStores,
  clearStatus,
} from "./store/sourcePipelineInStoresSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function SourcePipelineInStore({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: sourcePipelineId,
  };

  const sourceInsDataMap = useSelector(
    (state) => state.sources.sourceInsSlice.data
  );

  // Get processed stores with statusCode: 2
  const sourceInsData =
    sourceInsDataMap && !_.isEmpty(sourceInsDataMap)
      ? _.filter(Object.values(sourceInsDataMap), { statusCode: 2 })
      : [];

  const sourceInsSliceStatus = useSelector(
    (state) => state.sources.sourceInsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.sources.sourcePipelineInStoresSlice.data
  );
  const data = Object.values(dataMap);
  const status = useSelector(
    (state) => state.sources.sourcePipelineInStoresSlice.status
  );

  function handleCreateSourcePipelineInStore(uuId) {
    dispatch(
      createSourcePipelineInStore({
        ...params,
        formData: { sourceInStoreUuId: uuId },
      })
    );
  }

  function handleDeleteSourcePipelineInStore(uuId) {
    dispatch(deleteSourcePipelineInStore({ ...params, uuId: uuId }));
  }

  function handleClick(sourceInStoreId) {
    const present = _.filter(data, function (o) {
      return o.sourceInStoreUuId === sourceInStoreId;
    });

    if (present && present.length > 0) {
      handleDeleteSourcePipelineInStore(present[0].uuId);
    } else {
      handleCreateSourcePipelineInStore(sourceInStoreId);
    }
  }

  function handleSelectAll() {
    if (sourceInsData && sourceInsData.length > 0) {
      sourceInsData.forEach((o, i) => {
        const present = _.filter(data, function (p) {
          return o["uuId"] === p["sourceInStoreUuId"];
        });

        if (present.length === 0) {
          handleCreateSourcePipelineInStore(o["uuId"]);
        }
      });
    }
  }

  function handleClearAll() {
    if (sourceInsData && sourceInsData.length > 0) {
      sourceInsData.forEach((o, i) => {
        const present = _.filter(data, function (p) {
          return o["uuId"] === p["sourceInStoreUuId"];
        });

        if (present.length > 0) {
          handleDeleteSourcePipelineInStore(present[0]["uuId"]);
        }
      });
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourcePipelineInStores" &&
      status.result === "success" &&
      sourceInsSliceStatus &&
      sourceInsSliceStatus.method === "getSourceIns" &&
      sourceInsSliceStatus.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      dispatch(clearSourceInsSliceStatus());
    }
  }, [status, sourceInsSliceStatus]);

  useEffect(() => {
    dispatch(getSourceIns({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(getSourcePipelineInStores(params));
  }, []);

  return (
    <NtaiPanel
      width="50%"
      header="Inbound Stores"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
    >
      {loaded && sourceInsData.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              justifyContent: "space-between",
              alignItems: "center",
              pb: theme.spacing(1),
            }}
          >
            <Typography variant="h6">Selected ({data.length})</Typography>
            <Box>
              <Button
                sx={{ fontSize: "11px" }}
                size="small"
                onClick={() => handleClearAll()}
              >
                CLEAR ALL
              </Button>
              <Button
                sx={{ fontSize: "11px" }}
                size="small"
                onClick={() => handleSelectAll()}
              >
                SELECT ALL
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              border: theme.general.border1,
              borderRadius: theme.general.borderRadius,
              display: "flex",
              height: "240px",
            }}
          >
            <Scrollbar>
              {sourceInsData && sourceInsData.length > 0 && (
                <List sx={{ p: 0 }}>
                  {sourceInsData.map((sind, i) => (
                    <React.Fragment key={`source-in-store-${i}`}>
                      <ListItemButton
                        key={`source-in-store-${i}`}
                        onClick={(e) => handleClick(sind.uuId)}
                        sx={{ p: 0 }}
                      >
                        <ListItem
                          secondaryAction={
                            <NtaiCheckboxField
                              name={sind.uuId}
                              checked={
                                _.filter(data, function (o) {
                                  return o.sourceInStoreUuId === sind.uuId;
                                }).length > 0
                                  ? true
                                  : false
                              }
                            />
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography fontWeight="700">
                                {sind.name}
                              </Typography>
                            }
                            secondary={sind.description}
                          />
                        </ListItem>
                      </ListItemButton>

                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Scrollbar>
          </Box>
        </Box>
      )}
      {loaded && sourceInsData.length === 0 && (
        <NtaiEmptyMessage
          header="No stores available"
          subheader="You can create inbound stores under Source - Inbound Stores"
        />
      )}
      {!loaded && <NtaiCircularProgress />}
    </NtaiPanel>
  );
}
