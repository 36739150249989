import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import { getSourceSeriesBySourceId } from "app/main/pages/core/library/source-series/store/sourceSeriesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportContext } from "../../ReportMain";
import ReportSeriesFilter from "./ReportSeriesFilter";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { grey } from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare as faCheckSquareSolid } from "@fortawesome/pro-solid-svg-icons";
import {
  faCheckSquare as faCheckSquareLight,
  faSquare,
} from "@fortawesome/pro-light-svg-icons";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

function revisedSeriesData(
  allSeriesData,
  reportDefinition,
  reportFormData,
  seriesSearchText
) {
  if (
    reportFormData &&
    !_.isEmpty(reportFormData) &&
    _.has(reportFormData, "uuId") &&
    reportFormData["uuId"].length > 0 &&
    reportFormData["sourceSeriesUuId"] &&
    reportFormData["sourceSeriesUuId"].length > 0
  ) {
    return _.filter(allSeriesData, function (o) {
      return o["uuId"] === reportFormData["sourceSeriesUuId"] ? true : false;
    });
  } else {
    if (seriesSearchText && seriesSearchText.length > 0) {
      return _.filter(allSeriesData, function (o) {
        return o["name"]
          .toLowerCase()
          .includes(seriesSearchText.toLowerCase()) &&
          o["sourceUuId"] === reportDefinition["sourceUuId"]
          ? true
          : false;
      });
    } else {
      return _.filter(allSeriesData, function (o) {
        return o["sourceUuId"] === reportDefinition["sourceUuId"]
          ? true
          : false;
      });
    }
  }
}

export default function ReportSeriesCriteria() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [anchorElFilterMenu, setAnchorElFilterMenu] = useState(null);
  const [seriesSearchText, setSeriesSearchText] = useState();

  const {
    reportDefinition,
    reportFormData,
    selectedSourceSeriesId,
    setSelectedSourceSeriesId,
  } = React.useContext(ReportContext);

  const sourceSeriesDataMap = useSelector(
    (state) => state.core.sourceSeriesSlice.data
  );
  const allSeriesData =
    sourceSeriesDataMap && !_.isEmpty(sourceSeriesDataMap)
      ? Object.values(sourceSeriesDataMap)
      : [];

  const data =
    allSeriesData && _.isArray(allSeriesData)
      ? revisedSeriesData(
          allSeriesData,
          reportDefinition,
          reportFormData,
          seriesSearchText
        )
      : null;

  const debouncedOnHandleChange = _.debounce(handleSeriesSearch, 500);

  const handleFilterMenuOpen = (event) => {
    setFilterMenuOpen(true);
    setAnchorElFilterMenu(event.currentTarget);
  };
  const handleFilterMenuClose = () => {
    setFilterMenuOpen(false);
    setAnchorElFilterMenu(null);
  };

  const clickAwayHandler = () => {
    setFilterMenuOpen(false);
  };

  function handleSeriesSearch(txt) {
    setSeriesSearchText(txt.trim());
  }

  function handleSelect(id) {
    setSelectedSourceSeriesId(selectedSourceSeriesId === id ? null : id);
  }

  useEffect(() => {
    dispatch(
      getSourceSeriesBySourceId({ sourceUuId: reportDefinition["sourceUuId"] })
    );
  }, [reportDefinition]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        {/* <Box
          sx={{
            p: theme.spacing(2),
            height: "44px",
            display: "flex",
            alignItems: "center",
            borderBottom: theme.general.border1,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Report Criteria</Typography>
          <Button
            sx={{ fontSize: "11px" }}
            size="small"
            onClick={(e) => handleFilterMenuOpen(e)}
          >
            FILTER
          </Button>
        </Box> */}

        <Box
          sx={{
            p: theme.spacing(1),
            px: theme.spacing(2),
            display: "flex",
            height: "44px",
            width: "100%",
            alignItems: "center",
            borderBottom: theme.general.border1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{`Select Series`}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <NtaiSimpleSearchBar
              placeholder="Search..."
              height="28px"
              fontSize="12px"
              width="auto"
              background={grey[100]}
              handleChange={debouncedOnHandleChange}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            height: `calc(100% - 44px)`,
          }}
        >
          <Scrollbar>
            <List sx={{ height: "100%", p: 0 }}>
              {data &&
                data.length > 0 &&
                _.orderBy(data, ["name"], ["asc"]).map((o, i) => (
                  <Box
                    key={`source-series-${i}`}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <ListItem
                      sx={{
                        "&:hover": {
                          background: grey[100],
                          cursor: "pointer",
                        },
                      }}
                      onClick={() =>
                        reportFormData &&
                        reportFormData["sourceSeriesUuId"] &&
                        reportFormData["sourceSeriesUuId"].length > 0
                          ? null
                          : handleSelect(o["uuId"])
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          pr: theme.spacing(2),
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontWeight="700"
                            >
                              {o["name"]}
                            </Typography>
                          }
                          secondary={
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Typography variant="subtitle2">
                                {o["sourceMin"]["displayName"]}
                              </Typography>
                              <Typography variant="caption">
                                {o["description"]}
                              </Typography>
                            </Box>
                          }
                        />

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: theme.spacing(2),
                          }}
                        >
                          <Chip
                            label={
                              <Typography
                                color="inherit"
                                variant="subtitle2"
                                fontWeight="700"
                              >
                                {NtaiUtils.abbrevNumber(o["totalCount"])}
                              </Typography>
                            }
                            size="small"
                          />
                          <FontAwesomeIcon
                            size="lg"
                            icon={
                              selectedSourceSeriesId === o["uuId"]
                                ? faCheckSquareSolid
                                : faSquare
                            }
                          />
                        </Box>
                      </Box>
                    </ListItem>
                    <Divider />
                  </Box>
                ))}
            </List>
          </Scrollbar>
        </Box>
      </Box>
      {filterMenuOpen && (
        <NtaiPopover
          open={filterMenuOpen}
          anchorEl={anchorElFilterMenu}
          handleClose={handleFilterMenuClose}
          clickAwayHandler={clickAwayHandler}
        >
          <NtaiForm>
            <ReportSeriesFilter handleCancel={handleFilterMenuClose} />
          </NtaiForm>
        </NtaiPopover>
      )}
    </React.Fragment>
  );
}
