import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceMasterTemplates = createAsyncThunk(
  "sourceMasterTemplates/getSourceMasterTemplates",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/master-templates`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceMasterTemplate = createAsyncThunk(
  "sourceMasterTemplates/getSourceMasterTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/master-templates/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceMasterTemplate = createAsyncThunk(
  "sourceMasterTemplates/createSourceMasterTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/master-templates`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceMasterTemplate = createAsyncThunk(
  "sourceMasterTemplates/updateSourceMasterTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/master-templates/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceMasterTemplate = createAsyncThunk(
  "sourceMasterTemplates/deleteSourceMasterTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/master-templates/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceMasterTemplatesSlice = createSlice({
  name: "sourceMasterTemplates",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceMasterTemplates.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceMasterTemplates",
      };
    },
    [getSourceMasterTemplates.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceMasterTemplates",
        message: action.payload.message,
      };
    },
    [getSourceMasterTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceMasterTemplate",
      };
    },
    [getSourceMasterTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceMasterTemplate",
        message: action.payload.message,
      };
    },
    [createSourceMasterTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceMasterTemplate",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceMasterTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceMasterTemplate",
        message: action.payload.message,
      };
    },
    [updateSourceMasterTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceMasterTemplate",
      };
    },
    [updateSourceMasterTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceMasterTemplate",
        message: action.payload.message,
      };
    },
    [deleteSourceMasterTemplate.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceMasterTemplate",
      };
    },
    [deleteSourceMasterTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceMasterTemplate",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceMasterTemplatesSlice.actions;

export default sourceMasterTemplatesSlice.reducer;
