import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CodelistForm from "./CodelistForm";
import {
  clearStatus,
  setActiveId,
  updateCodelist,
} from "./store/codelistsSlice";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function CodelistEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.codelistsSlice.status);
  const dataMap = useSelector((state) => state.core.codelistsSlice.data);
  const activeId = useSelector((state) => state.core.codelistsSlice.activeId);
  const id = props.match.params.id;

  const formData = _.get(dataMap, id);

  function handleFormSubmit(formData) {
    dispatch(updateCodelist({ uuId: id, formData: { ...formData } }));
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "updateCodelist"
    ) {
      enqueueSnackbar(
        `Codelist "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "error" &&
      status.method === "updateCodelist"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back="/admin/codelists"
    >
      <NtaiForm>
        <CodelistForm
          action="edit"
          formData={formData}
          handleFormSubmit={handleFormSubmit}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
