import * as React from "react";
import ReactMapGL, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";

import Map from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { useEffect } from "react";
import { useCallback } from "react";
import MarkerPin from "./MarkerPin";
import { Box, Popover, Tooltip } from "@mui/material";
import { useMemo } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import { useState } from "react";
import NtaiContextMenuEChart from "../echarts/NtaiContextMenuEChart";
import NtaiEChartUtil from "../echarts/util/NtaiEChartUtil";
const _ = require("lodash");

// Add the below to the component that you are using react-mapbox-gl with. Also be sure to do a npm i worker-loader

// import mapboxgl from 'mapbox-gl';
// // eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = MapboxWorker;

const data1 = [
  {
    query: "Mumbai, India",
    limit: 1,
  },
  {
    query: "New Delhi, India",
    limit: 1,
  },
];

function getResults(locations) {
  const results = [];

  const geocodingClient = mbxGeocoding({
    accessToken:
      "pk.eyJ1Ijoic3lkYmFyIiwiYSI6ImNrcnlkbXJrMDA3anIydW9lZjJxMjA5N2QifQ.5pobKT6pI4ApsgDZdV8DqA",
  });

  locations.forEach((location) => {
    results.push(
      geocodingClient
        .forwardGeocode(location)
        .send()
        .then((response) => {
          const match = response.body;
          const coordinates = match.features[0].geometry.coordinates;
          const placeName = match.features[0].place_name;
          const center = match.features[0].center;

          return {
            type: "Feature",
            center: center,
            geometry: {
              type: "Point",
              coordinates: coordinates,
            },
            properties: {
              description: placeName,
            },
          };
        })
    );
  });

  return results;
}

export default function NtaiMapChart3(props) {
  const { widgetId, zoom, data, metadata, width, height, handleContextMenu } =
    props;
  const [markers, setMarkers] = React.useState([]);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuCoords, setMenuCoords] = useState();
  const [markerPoint, setMarkerPoint] = useState();

  const [loaded, setLoaded] = React.useState(false);
  const [popupInfo, setPopupInfo] = React.useState(null);
  const [showPopup, setShowPopup] = React.useState(false);
  const [placesMap, setPlacesMap] = React.useState({});
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  const [viewport, setViewport] = React.useState({
    latitude: 31.7771,
    longitude: -40.24965,
    height: height,
    width: width,
    zoom: zoom ? zoom : 0,
  });

  function handleMarkerClick(e, marker) {
    //e.originalEvent.stopPropagation();
    e.stopPropagation();
    console.log("I got left clicked: ", e, marker);
    e.preventDefault();
    setMenuCoords({
      left: e.center["x"],
      top: e.center["y"],
    });
    setMenuOpen(true);
    setMarkerPoint(marker["properties"]["originalText"]);

    // const sourceWidgetFilterFields = [
    //   {
    //     sourceFieldUuId: Object.keys(_.get(metadata, "fields"))[0],
    //     fieldOperatorCode: 1,
    //     fieldValue: marker.properties.originalText,
    //   },
    // ];

    // handleClick(sourceWidgetFilterFields, e);
  }

  function handleMarkerRightClick(e, marker) {
    e.preventDefault();
    console.log("I got right clicked");
  }

  function onHandleContextMenu(action) {
    //handleContextMenu(action, menuParams);
    const sourceWidgetFilterFields = [
      {
        sourceFieldUuId: NtaiEChartUtil.getSourceFieldUuId(
          metadataRef.current,
          1
        ),
        fieldOperatorCode: 1,
        fieldValue: markerPoint,
      },
    ];
    // console.log("Send this: ", action, sourceWidgetFilterFields);
    handleContextMenu(action, sourceWidgetFilterFields);
    handleMenuClose();
  }

  function handleMenuClose() {
    setMenuOpen(false);
  }
  function handleMouseOver(marker) {
    setPopupInfo({
      longitude: Number(marker.center[0]),
      latitude: Number(marker.center[1]),
    });
    setShowPopup(true);
  }

  function handleMouseOut(pl) {
    setPopupInfo(null);
    setShowPopup(true);
  }

  const fetchData = useCallback(() => {
    const geocodingClient = mbxGeocoding({
      accessToken:
        "pk.eyJ1Ijoic3lkYmFyIiwiYSI6ImNrcnlkbXJrMDA3anIydW9lZjJxMjA5N2QifQ.5pobKT6pI4ApsgDZdV8DqA",
    });

    const results = [];

    data.forEach((dat) => {
      const place = geocodingClient
        .forwardGeocode(_.pick(dat, ["query", "limit"]))
        .send()
        .then((response) => {
          const match = response.body;
          const coordinates = match.features[0].geometry.coordinates;
          const placeName = match.features[0].place_name;
          const center = match.features[0].center;

          return {
            type: "Feature",
            center: center,
            geometry: {
              type: "Point",
              coordinates: coordinates,
            },
            properties: {
              description: placeName,
              originalText: dat.query,
            },
          };
        });

      results.push(place);
    });

    // geocoding with countries
    return results;
  }, [data]);

  React.useEffect(() => {
    const results = fetchData();

    const tmpMarkers = [];
    let tmpPlacesMap = {};

    results.forEach((result, i) => {
      result.then((marker) => {
        tmpMarkers.push(
          <Box
            onMouseOver={() => handleMouseOver(marker)}
            onMouseOut={() => handleMouseOut()}
          >
            <Marker
              key={`marker-${marker.properties.description}-${i}`}
              longitude={Number(marker.center[0])}
              latitude={Number(marker.center[1])}
              anchor="bottom"
              onClick={(e) => handleMarkerClick(e, marker)}
              onContextMenu={(e) => handleMarkerRightClick(e, marker)}
            >
              <MarkerPin />
            </Marker>
          </Box>
        );
      });
    });

    console.log("Tmp markers: ", tmpMarkers);
    setMarkers(tmpMarkers);
  }, [fetchData]);

  return (
    <>
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={"mapbox://styles/mapbox/light-v10"}
        mapboxApiAccessToken="pk.eyJ1Ijoic3lkYmFyIiwiYSI6ImNrcnlkbXJrMDA3anIydW9lZjJxMjA5N2QifQ.5pobKT6pI4ApsgDZdV8DqA"
        //mapboxAccessToken="pk.eyJ1Ijoic3lkYmFyIiwiYSI6ImNrcnlkbXJrMDA3anIydW9lZjJxMjA5N2QifQ.5pobKT6pI4ApsgDZdV8DqA"
        onViewportChange={(viewport) => setViewport(viewport)}
      >
        {/* <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" /> */}
        {markers}
      </ReactMapGL>
      {menuCoords && menuOpen && (
        <Popover
          open={menuOpen}
          onClose={() => handleMenuClose()}
          anchorReference="anchorPosition"
          anchorPosition={menuCoords}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <NtaiContextMenuEChart onHandleContextMenu={onHandleContextMenu} />
        </Popover>
      )}
      {/* {showPopup && _.has(popupInfo, "latitude") && (
        <Popup
          anchor="top"
          longitude={-100}
          latitude={40}
          onClose={() => setShowPopup(false)}
        >
          you are here
        </Popup>
      )} */}
    </>
  );
}
