import { Box, Button, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { dispatch } from "d3";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function SourceAwsLivySession({
  handleFormSubmit,
  handleCancel,
  sessionCreationPending,
}) {
  const theme = useTheme();

  const { getValues } = useFormContext();

  const awsClustersDataMap = useSelector(
    (state) => state.core.awsClustersSlice.data
  );

  const awsClustersData =
    awsClustersDataMap && !_.isEmpty(awsClustersDataMap)
      ? Object.values(awsClustersDataMap)
      : [];

  const awsClusterOptions = NtaiUtils.getSelectOptions(
    awsClustersData,
    "uuId",
    "extAwsEmrClusterId"
  );

  function onHandleStartSession() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceAwsLivySessionForm")
    );
    handleFormSubmit({ actionCode: 1 });
  }

  function onHandleTerminateSession() {
    // const sanitizedFormData = NtaiUtils.sanitizeFormData(
    //   getValues("sourcePipelineAwsLivySessionForm")
    // );
    // handleFormSubmit(sanitizedFormData);
  }

  return (
    <NtaiDialogFormPanel
      subheader="Select or start cluster and start ETL session"
      handleSave={onHandleStartSession}
      handleSaveLabel="Start Session"
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(1),
          }}
        >
          {sessionCreationPending && <NtaiCircularProgress size={20} />}
          {/* <NtaiSelectField
              name="sourcePipelineAwsLivySessionForm.awsClusterUuId"
              options={awsClusterOptions}
            /> */}
        </Box>
      </Box>
    </NtaiDialogFormPanel>
  );
}
