import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReportScheduledReports = createAsyncThunk(
  "reportScheduledReports/getReportScheduledReports",
  async (values, { rejectWithValue }) => {
    try {
      const { reportId, reportScheduleId } = values;
      const response = await server.get(
        `/reports/${reportId}/schedules/${reportScheduleId}/documents`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReportScheduledReport = createAsyncThunk(
  "reportScheduledReports/getReportScheduledReport",
  async (values, { rejectWithValue }) => {
    try {
      const { reportId, reportScheduleId, uuId } = values;
      const response = await server.get(
        `/reports/${reportId}/schedules/${reportScheduleId}/documents/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportScheduledReportsSlice = createSlice({
  name: "reportScheduledReports",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReportScheduledReports.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getReportScheduledReports",
      };
    },
    [getReportScheduledReports.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportScheduledReports",
        message: action.payload.message,
      };
    },
    [getReportScheduledReport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getReportScheduledReport",
      };
    },
    [getReportScheduledReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportScheduledReport",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = reportScheduledReportsSlice.actions;

export default reportScheduledReportsSlice.reducer;
