import React, { useEffect, useState } from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";
import NtaiReactDataTable from "@ntai/components/data-table/NtaiReactDataTable";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NtaiErrorMessage from "@ntai/components/errors/NtaiErrorMessage";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { clearStatus, getSourceStage } from "../../../store/sourceStagesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    width: "15%",
  },
  {
    name: "Script",
    selector: (row) => row.datasetScript,
    sortable: true,
    width: "40%",
  },
  {
    name: "Status",
    selector: (row) => row.queryStatusDecode,
    sortable: true,
    width: "10%",
  },
  {
    name: "Message",
    selector: (row) => row.queryMessage,
    sortable: true,
    width: "25%",
  },
  {
    name: "Duration",
    selector: (row) => row.queryDurationMilliSecondsText,
    sortable: true,
    width: "10%",
  },
];

export default function SourceDatasetSidePanelStageBottomDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const { sidePanelMenuParam } = React.useContext(SourceDatasetManagerContext);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const dataMap = useSelector((state) => state.sources.sourceStagesSlice.data);

  const data =
    dataMap && !_.isEmpty(dataMap) ? dataMap[sidePanelMenuParam["uuId"]] : {};

  const status = useSelector((state) => state.sources.sourceStagesSlice.status);

  useEffect(() => {
    if (status && status.method === "getSourceStage") {
      if (status.result === "error") {
        enqueueSnackbar("Error fetching preview: " + status["message"], {
          variant: "error",
        });
      }
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (sidePanelMenuParam["menuId"] === 3) {
      dispatch(
        getSourceStage({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourcePipelineId: sourcePipelineId,
          uuId: sidePanelMenuParam["uuId"],
        })
      );
    }
  }, [sidePanelMenuParam]);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiPanel
          width="100%"
          header={data["name"]}
          subheader={data["description"]}
        >
          {_.has(data, "datasets") &&
            _.isArray(data["datasets"]) &&
            data["datasets"].length > 0 && (
              <NtaiReactDataTable
                data={_.orderBy(data["datasets"], ["datasetOrder"], ["asc"])}
                columns={columns}
              />
            )}
        </NtaiPanel>
      )}
      {!loaded && <NtaiCircularProgress vAlign="center" size={24} />}
    </React.Fragment>
  );
}
