import { Typography, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPassword,
  clearStatus,
} from "./store/userChangePasswordSlice";
import { useSnackbar } from "notistack";
import NtaiValidator from "@ntai/validator/NtaiValidator";
const _ = require("lodash");

export default function UserAccountPassword() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const [message, setMessage] = useState();

  const userInfo = useSelector((state) => state.auth.userDataSlice.data);

  const status = useSelector(
    (state) => state.core.userChangePasswordSlice.status
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("userAccountPasswordForm")
    );

    if (
      sanitizedFormData["password"] === sanitizedFormData["confirmPassword"]
    ) {
      dispatch(
        changeUserPassword({
          userUuId: userInfo["uuId"],
          formData: sanitizedFormData,
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "changeUserPassword" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      setMessage("Password changed successfully");
    }
  }, [status]);

  return (
    <NtaiFormPanel
      header="Change Password"
      subheader="You can change your password for security reasons or request the system administrator to reset it if you forget it."
      handleSave={onHandleSave}
    >
      <NtaiTextField
        type="password"
        name="userAccountPasswordForm.oldPassword"
        label="Old Password*"
        placeholder="Old Password"
        rules={{
          required: "Old password is required",
          validate: NtaiValidator.isPassword,
        }}
      />
      <NtaiTextField
        type="password"
        name="userAccountPasswordForm.password"
        label="New Password*"
        placeholder="New Password"
        rules={{
          required: "New password is required",
          validate: NtaiValidator.isPassword,
        }}
      />

      <NtaiTextField
        type="password"
        name="userAccountPasswordForm.confirmPassword"
        label="Confirm New Password*"
        placeholder="Confirm New Password"
        rules={{
          required: "Confirm new password is required",
          validate: NtaiValidator.isPassword,
        }}
      />
      {message && <Typography variant="success">{message}</Typography>}
    </NtaiFormPanel>
  );
}
