import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceOutDatasetPkFields = createAsyncThunk(
  "sourceDatasets/getSourceOutDatasetPkFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceOutStoreId, sourceOutDatasetId } =
        values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/pks`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceOutDatasetPkField = createAsyncThunk(
  "sourceOutDatasetPkFields/createSourceOutDatasetPkField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/pks`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceOutDatasetPkField = createAsyncThunk(
  "sourceOutDatasetPkFields/deleteSourceOutDatasetPkField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        uuId,
      } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/pks/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceOutDatasetPkFields = createAsyncThunk(
  "sourceOutDatasetPkFields/updateSourceOutDatasetPkFields",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutDatasetId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutDatasetId}/pks`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceOutDatasetPkFieldsSlice = createSlice({
  name: "sourceOutDatasetPkFields",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceOutDatasetPkFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceOutDatasetPkFields",
      };
    },
    [getSourceOutDatasetPkFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutDatasetPkFields",
        message: action.payload.message,
      };
    },

    [createSourceOutDatasetPkField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceOutDatasetPkField",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceOutDatasetPkField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceOutDatasetPkField",
        message: action.payload.message,
      };
    },

    [updateSourceOutDatasetPkFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "updateSourceOutDatasetPkFields",
      };
    },
    [updateSourceOutDatasetPkFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceOutDatasetPkFields",
        message: action.payload.message,
      };
    },

    [deleteSourceOutDatasetPkField.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "deleteSourceOutDatasetPkField",
      };
    },
    [deleteSourceOutDatasetPkField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceOutDatasetPkField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceOutDatasetPkFieldsSlice.actions;

export default sourceOutDatasetPkFieldsSlice.reducer;
