import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { FunctionEditorContext } from "../FunctionEdit";

const _ = require("lodash");

export default function FunctionPanel() {
  const theme = useTheme();
  const { functionCode, setFunctionCode } = useContext(FunctionEditorContext);

  function onChangeFunctionCode(newValue) {
    setFunctionCode(newValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "44px",
          display: "flex",
          borderBottom: theme.general.border1,
          alignItems: "center",
          px: theme.spacing(2),
        }}
      >
        <Typography variant="h6" fontWeight="700">
          Function
        </Typography>
      </Box>
      <Box sx={{ height: `calc(100% - 44px)` }}>
        <AceEditor
          mode="python"
          theme="github"
          fontSize="14px"
          onChange={onChangeFunctionCode}
          name="functionCode"
          value={functionCode}
          width="100%"
          height="100%"
          // editorProps={{ $blockScrolling: Infinity }}
          setOptions={{
            enableBasicAutocompletion: true,
            // enableLiveAutocompletion: true,
            // enableSnippets: true,
          }}
        />
      </Box>
    </Box>
  );
}
