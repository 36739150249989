import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordSourceViews = createAsyncThunk(
  "searchSourceRecordViews/getSearchSourceRecordSourceViews",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, params } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-views`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordView = createAsyncThunk(
  "searchSourceRecordCustomViews/getSearchSourceRecordView",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, sourceViewUuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewUuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordViewsSlice = createSlice({
  name: "searchSourceRecordCustomViews",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceRecordSourceViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordSourceViews",
      };
    },
    [getSearchSourceRecordSourceViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordSourceViews",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.sourceViewUuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordView",
      };
    },
    [getSearchSourceRecordView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceRecordViewsSlice.actions;

export default searchSourceRecordViewsSlice.reducer;
