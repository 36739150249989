import { Box, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SourceRecordTemplateGeneralForm from "./SourceRecordTemplateGeneralForm";
import SourceTemplateTemplateFields from "./SourceRecordTemplateFields";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import {
  clearStatus,
  getOtherSourceRecordTemplates,
  getSourceRecordTemplates,
} from "./store/sourceRecordTemplatesSlice";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "../../store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "../../derivedfield/store/sourceDerivedFieldsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

function getOtherSourceTemplateOptions(
  action,
  otherSourceTemplatesDataMap,
  sourceId,
  currentTemplatesData
) {
  let result = Object.values(otherSourceTemplatesDataMap);

  if (action === "add") {
    result = _.filter(result, function (o) {
      return o["sourceUuId"] !== sourceId &&
        _.filter(currentTemplatesData, function (p) {
          return p["extSourceRecordTemplateUuId"] === o["uuId"] ? true : false;
        }).length === 0
        ? true
        : false;
    });
  }

  return _.orderBy(
    result.map((o) => {
      return {
        value: o["uuId"],
        label: o["sourceMin"]["displayName"] + " - " + o["name"],
      };
    }),
    ["label"],
    ["asc"]
  );
}

export default function SourceOtherSourceRecordTemplateForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [fields, setFields] = useState();
  const [mergedFields, setMergedFields] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.activeId
  );

  const status = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.status
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );

  const otherSourceTemplatesDataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.otherSourceTemplatesData
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  console.log("Loaded = ", loaded);

  const otherSourceTemplateOptions =
    otherSourceTemplatesDataMap &&
    !_.isEmpty(otherSourceTemplatesDataMap) &&
    data &&
    _.isArray(data)
      ? getOtherSourceTemplateOptions(
          action,
          Object.values(otherSourceTemplatesDataMap),
          sourceId,
          data
        )
      : [];

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  const mergedFieldOptions =
    mergedFields && _.isArray(mergedFields)
      ? mergedFields.map((o) => {
          return {
            value: o["uuId"],
            label: o["label"],
          };
        })
      : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOtherSourceRecordTemplateForm")
    );

    if (
      sanitizedFormData["extSourceRecordTemplateUuId"] &&
      sanitizedFormData["extPkFieldUuId"]
    ) {
      sanitizedFormData["extTemplateFg"] = 1;
      const sourceFieldToExtPkField = _.filter(mergedFields, {
        uuId: sanitizedFormData["extPkFieldUuId"],
      })[0];
      sanitizedFormData["extPkSourceDerivedFieldFg"] =
        sourceFieldToExtPkField["type"] === 1 ? 0 : 1;

      handleFormSubmit(sanitizedFormData);
    }
  }

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success" &&
      status &&
      status.method === "getOtherSourceRecordTemplates" &&
      status.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefId,
        sourceId,
        mergedFields
      );

      setFields({ ...tmpFields });
      setMergedFields([...mergedFields]);
      setLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
      dispatch(clearStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    reset({
      sourceOtherSourceRecordTemplateForm: action === "edit" ? formData : null,
    });
  }, [formData]);

  useEffect(() => {
    dispatch(
      getOtherSourceRecordTemplates({ sourceDefId: "*", sourceId: "*" })
    );
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <>
      {mergedFields &&
        _.isArray(mergedFields) &&
        mergedFields.length > 0 &&
        otherSourceTemplateOptions.length > 0 && (
          <NtaiFormPanel
            width="40%"
            header="Other Source Record Template"
            subheader="Select other source record template and select and map current source field to the external source primary key field"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <NtaiSelectField
              name="sourceOtherSourceRecordTemplateForm.extSourceRecordTemplateUuId"
              options={otherSourceTemplateOptions}
              label="External Template"
              rules={{ required: "Other source record template is required" }}
            />
            <NtaiSelectField
              name="sourceOtherSourceRecordTemplateForm.extPkFieldUuId"
              options={mergedFieldOptions}
              label="Mapped Source Field"
              rules={{
                requied:
                  "Source field mapping to other source priamry key is required",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <NtaiSwitchField
                name="sourceOtherSourceRecordTemplateForm.activeFg"
                label="Active?"
              />
              <NtaiSwitchField
                name="sourceOtherSourceRecordTemplateForm.defaultFg"
                label="Default?"
              />
            </Box>
          </NtaiFormPanel>
        )}

      {(otherSourceTemplateOptions.length === 0 ||
        mergedFields.length === 0) && (
        <NtaiEmptyMessage
          header="No data available"
          subheader="No external source record templates available for selection"
        />
      )}
    </>
  );
}
