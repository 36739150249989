import { useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function ObjectFormBuilderTabForm(props) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const { action, formData, handleSave, handleCancel } = props;

  const dataMap = useSelector(
    (state) => state.core.objectFormBuilderTabsSlice.data
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectFormBuilderTabForm")
    );

    if (action === "add")
      sanitizedFormData["tabOrder"] =
        dataMap && !_.isEmpty(dataMap) ? Object.keys(dataMap).length + 1 : 1;

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ objectFormBuilderTabForm: action === "edit" ? formData : null });
  }, [formData]);

  return (
    <NtaiFormPanel
      width="100%"
      header={action === "add" ? "Add Tab" : "Edit Tab"}
      subheader="Provide tab name, label and help text."
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="objectFormBuilderTabForm.name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="objectFormBuilderTabForm.label"
        label="Label*"
        rules={{ required: "Label is required" }}
      />
      <NtaiTextField
        multiline
        minRows={2}
        maxRows={2}
        name="objectFormBuilderTabForm.helpText"
        label="Help Text"
      />
    </NtaiFormPanel>
  );
}
