import React from "react";
import { useSelector } from "react-redux";
import ReportDefinitionSourceFilterWrapper from "./source-filter/ReportDefinitionSourceFilterWrapper";
import ReportDefinitionParamList from "./ReportDefinitionParamList";
const _ = require("lodash");

export default function ReportDefinitionParamWrapper() {
  const reportDefinitionId = useSelector(
    (state) => state.reports.reportDefinitionsSlice.activeId
  );

  const reportDefinitionDataMap = useSelector(
    (state) => state.reports.reportDefinitionsSlice.data
  );

  return (
    <>
      {_.get(_.get(reportDefinitionDataMap, reportDefinitionId), "typeCode") ===
        1 &&
        _.get(
          _.get(reportDefinitionDataMap, reportDefinitionId),
          "criteriaTypeCode"
        ) === 1 && <ReportDefinitionSourceFilterWrapper />}

      {_.get(_.get(reportDefinitionDataMap, reportDefinitionId), "typeCode") ===
        2 && <ReportDefinitionParamList />}
    </>
  );
}
