import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjectFormBuilderTabs = createAsyncThunk(
  "objectFormBuilderTabs/getObjectFormBuilderTabs",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectFormBuilderTab = createAsyncThunk(
  "objectFormBuilderTabs/getObjectFormBuilderTab",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectFormBuilderTab = createAsyncThunk(
  "objectFormBuilderTabs/createObjectFormBuilderTab",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectFormBuilderTab = createAsyncThunk(
  "objectFormBuilderTabs/updateObjectFormBuilderTab",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reorderObjectFormBuilderTabs = createAsyncThunk(
  "objectFormBuilderTabs/reorderObjectFormBuilderTabs",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, formData } = values;
      const response = await server.put(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectFormBuilderTab = createAsyncThunk(
  "objectFormBuilderTabs/deleteObjectFormBuilderTab",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, objectFormId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${objectFormId}/tabs/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectFormBuilderTabsSlice = createSlice({
  name: "objectFormBuilderTabs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectFormBuilderTabs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjectFormBuilderTabs" };
    },
    [getObjectFormBuilderTabs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectFormBuilderTabs",
        message: action.payload.message,
      };
    },
    [getObjectFormBuilderTab.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getObjectFormBuilderTab" };
    },
    [getObjectFormBuilderTab.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectFormBuilderTab",
        message: action.payload.message,
      };
    },
    [createObjectFormBuilderTab.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createObjectFormBuilderTab",
      };
      state.activeId = action.payload.uuId;
    },
    [createObjectFormBuilderTab.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectFormBuilderTab",
        message: action.payload.message,
      };
    },
    [updateObjectFormBuilderTab.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateObjectFormBuilderTab",
      };
    },
    [updateObjectFormBuilderTab.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectFormBuilderTab",
        message: action.payload.message,
      };
    },

    [reorderObjectFormBuilderTabs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "reorderObjectFormBuilderTabs",
        message: action.payload.message,
      };
    },
    [reorderObjectFormBuilderTabs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "reorderObjectFormBuilderTabs",
      };
    },

    [deleteObjectFormBuilderTab.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteObjectFormBuilderTab",
      };
    },
    [deleteObjectFormBuilderTab.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectFormBuilderTab",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = objectFormBuilderTabsSlice.actions;

export default objectFormBuilderTabsSlice.reducer;
