import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useFormContext } from "react-hook-form";
const _ = require("lodash");

export default function EntitySourceFilterFields({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  const [selectedSourceObjectFieldUuId, setSelectedSourceObjectFieldUuId] =
    React.useState(
      action === "edit" &&
        _.get(formData, "sourceObject.sourceObjectFields")[0].uuId
    );

  function handleSourceObjectFieldSelect(sourceObjectFieldUuId) {
    setSelectedSourceObjectFieldUuId(sourceObjectFieldUuId);
  }

  function onHandleSave() {
    const entitySourceFormData = getValues("entitySourceForm");

    const dataSourceFieldsData = _.get(
      entitySourceFormData,
      "dataSourceFields"
    );
    const dataSourceFields = [];
    let c = 0;
    dataSourceFieldsData.forEach((dataSourceFieldData, i) => {
      const sanitizedDataSourceFieldData =
        NtaiUtils.sanitizeFormData(dataSourceFieldData);
      dataSourceFields.push({
        sourceObjectFieldUuId: _.get(
          formData,
          "sourceObject.sourceObjectFields"
        )[c].uuId,
        fieldValue: _.get(sanitizedDataSourceFieldData, "fieldValue"),
        excludeFg: sanitizedDataSourceFieldData["excludeFg"],
      });
      c = c + 1;
    });

    const formDataCopy = _.cloneDeep(formData);
    formDataCopy["dataSourceFields"] = dataSourceFields;
    handleFormSubmit(formDataCopy);
  }

  return (
    <NtaiFormPanel
      width="100%"
      header="Filter Fields"
      subheader="Enter filter values for the listed fields. Filter values are used by the search/analytics and RBAC modules in enforcing data access rules for the logged in user"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      {action === "edit" && (
        <Box sx={{ display: "flex", border: theme.general.border1 }}>
          <Box
            sx={{
              height: "140px",
              flexBasis: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Scrollbar>
              <Box sx={{ pr: theme.spacing(2) }}>
                <List sx={{ p: 0 }}>
                  {_.orderBy(
                    _.get(formData, "sourceObject.sourceObjectFields"),
                    ["uuId"],
                    ["asc"]
                  ).map((f, i) => (
                    <ListItemButton
                      selected={
                        f.uuId === selectedSourceObjectFieldUuId ? true : false
                      }
                      onClick={() => handleSourceObjectFieldSelect(f.uuId)}
                    >
                      <ListItem sx={{ p: 0 }}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              fontWeight="700"
                            >
                              {_.get(f, "sourceField.label")}
                            </Typography>
                          }
                          secondary={_.get(f, "sourceField.name")}
                        />
                      </ListItem>
                    </ListItemButton>
                  ))}
                </List>
              </Box>
            </Scrollbar>
          </Box>

          <Box
            sx={{
              p: theme.spacing(1),
              flexBasis: "70%",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            {_.orderBy(
              _.get(formData, "sourceObject.sourceObjectFields"),
              ["uuId"],
              ["asc"]
            ).map(function (f, i) {
              if (f.uuId === selectedSourceObjectFieldUuId) {
                return (
                  <React.Fragment>
                    <NtaiSwitchField
                      name={`entitySourceForm.dataSourceFields.${i}.excludeFg`}
                      label="Exclude values?"
                    />
                    <Typography variant="subtitle1">
                      Enter or paste values
                    </Typography>

                    <NtaiTextField
                      name={`entitySourceForm.dataSourceFields.${i}.fieldValue`}
                      multiline
                      minRows={4}
                      maxRows={4}
                    />
                  </React.Fragment>
                );
              }
            })}
          </Box>
        </Box>
      )}
    </NtaiFormPanel>
  );
}
