import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModelForm from "./ModelForm";
import {
  runModel,
  setActiveId,
  updateModel,
  clearStatus,
  uploadModel,
} from "./store/modelsSlice";
import { useSnackbar } from "notistack";
import History from "@ntai/@history";
const _ = require("lodash");

export default function ModelEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.models.modelsSlice.status);
  const dataMap = useSelector((state) => state.models.modelsSlice.data);
  const activeId = useSelector((state) => state.models.modelsSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateModel({ uuId: id, formData: formData }));
  }

  function handleRun(formData) {
    dispatch(runModel({ uuId: id, formData: { ...formData } }));
  }

  function handleUploadModel(modelUploadData) {
    dispatch(uploadModel(modelUploadData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateModel" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Model "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      History.push(`/models/models`);
    }

    if (
      status &&
      status.method === "uploadModel" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Model file uploaded for "${_.get(dataMap[activeId], "name")}".`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage title={`Models / Edit ${_.get(formData, "name")}`}>
      <NtaiForm>
        <ModelForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleUploadModel={handleUploadModel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
