import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getSourceMasterTemplates } from "app/main/pages/core/sourcedefinition/source/template/master/store/sourceMasterTemplatesSlice";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

export default function SearchInitReportSelectTemplate({ handleChange }) {
  const dispatch = useDispatch();

  const { watch } = useFormContext();

  const watchSourceMasterTemplateUuId = watch(
    "searchReportInitForm.sourceMasterTemplateUuId"
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.data
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  const sourceMasterTemplateOptions = NtaiUtils.getSelectOptions(
    data,
    "uuId",
    "name"
  );

  React.useEffect(() => {
    if (
      !_.isEmpty(watchSourceMasterTemplateUuId) &&
      _.has(watchSourceMasterTemplateUuId, "value")
    ) {
      handleChange(watchSourceMasterTemplateUuId["value"]);
    }
  }, [watchSourceMasterTemplateUuId]);

  React.useEffect(() => {
    dispatch(
      getSourceMasterTemplates({
        sourceDefId: "*",
        sourceId: "*",
      })
    );
  }, []);

  return (
    <NtaiSelectField
      name="searchReportInitForm.sourceMasterTemplateUuId"
      options={sourceMasterTemplateOptions}
      rules={{ required: "Source template is required" }}
    />
  );
}
