import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import NtaiReactSelect from "./NtaiReactSelect";
import server from "app/http/ntaiServer";
import NtaiReactSelect1 from "./NtaiReactSelect1";
import Scrollbar from "@ntai/components/Scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import NtaiReactServerSelect from "./NtaiReactServerSelect";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useFormContext } from "react-hook-form";
import NtaiFilterServerSelect from "../filters/NtaiFilterServerSelect";
import { useEffect } from "react";
import { useState } from "react";

export default function TestNtaiServerInputs() {
  const theme = useTheme();

  const { getValues, setValue } = useFormContext();

  const [loaded, setLoaded] = useState(false);

  const loadOptions = (inputValue, callback) => {
    console.log("on load options function: ", inputValue);
    server
      .get(`/codelists`, {
        params: { name: inputValue },
      })
      .then((response) => {
        const options = [];
        response.data.forEach((code) => {
          options.push({
            label: code.name,
            value: code.uuId,
          });
        });
        callback(options);
      });
  };

  function handleFormSub() {}

  useEffect(() => {
    setValue("my.somethong2", [{ value: "7", label: "Hello" }]);
    setLoaded(true);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        p: theme.spacing(4),
        gap: theme.spacing(4),
      }}
    >
      {/* <Box sx={{ width: "200px" }}>
        <NtaiReactSelect />
      </Box>
      <Box sx={{ width: "200px" }}>
        <NtaiReactSelect1
          handleCallback={loadOptions}
          //   defaultOptions={[(value: "1"), (label: "BER")]}
        />
      </Box> */}
      {/* <Box
        sx={{
          display: "flex",
          width: "200px",
          flexDirection: "column",
          gap: theme.spacing(1),
        }}
      >
        <NtaiReactSelect1
          placeholder="Search term..."
          handleCallback={loadOptions}
          //   defaultOptions={[(value: "1"), (label: "BER")]}
        />
        <Box
          sx={{
            border: theme.general.border1,
            borderRadius: theme.general.borderRadius,
            height: "120px",
          }}
        >
          <Scrollbar>
            <List sx={{ p: 0 }}>
              <ListItem sx={{ py: "2px", pl: "10px", pr: "12px" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle2">
                    Something long text for exteremely long for mw and more
                  </Typography>
                  <IconButton sx={{ width: "20px", height: "20px" }}>
                    <FontAwesomeIcon size="2xs" icon={faClose} />
                  </IconButton>
                </Box>
              </ListItem>
            </List>
          </Scrollbar>
        </Box>
      </Box> */}

      {/* <Box sx={{ width: "200px" }}>
        <NtaiReactServerSelect
          name="my.somethong"
          handleCallback={loadOptions}
          defaultValue={[{ value: "3", label: "GTY" }]}
          placeholder="Search me..."
        />
      </Box> */}

      {loaded && (
        <NtaiFilterServerSelect
          label="Hello"
          name="my.somethong2"
          handleCallback={loadOptions}
          defaultOptions={[{ value: "3", label: "GTY" }]}
          placeholder="Search me..."
        />
      )}

      <Button onClick={() => handleFormSub()}>SUBMIT</Button>
    </Box>
  );
}
