import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { ForceGraph3D, ForceGraph2D } from "react-force-graph";
import SpriteText from "three-spritetext";
// import { useWindowSize } from "@react-hook/window-size";
// import { blueGrey, grey } from "@material-ui/core/colors";

const _ = require("lodash");

const data1 = {
  nodes: [
    {
      id: "1",
      name: "N/A",
      val: 9,
    },
    {
      id: "2",
      name: "Recruiting",
      val: 9,
    },
    {
      id: "3",
      name: "Enrolling by invitation",
      val: 9,
    },
    {
      id: "4",
      name: "Phase 1/Phase 2",
      val: 9,
    },
    {
      id: "5",
      name: "Early Phase 1",
      val: 9,
    },
    {
      id: "6",
      name: "Not yet recruiting",
      val: 9,
    },
    {
      id: "7",
      name: "Withheld",
      val: 1,
    },
    {
      id: "8",
      name: "Terminated",
      val: 9,
    },
    {
      id: "9",
      name: "Active, not recruiting",
      val: 9,
    },
    {
      id: "10",
      name: "Completed",
      val: 9,
    },
    {
      id: "11",
      name: "Phase 4",
      val: 9,
    },
    {
      id: "12",
      name: "Phase 3",
      val: 9,
    },
    {
      id: "13",
      name: "Withdrawn",
      val: 9,
    },
    {
      id: "14",
      name: "Phase 2",
      val: 9,
    },
    {
      id: "15",
      name: "Unknown status",
      val: 9,
    },
    {
      id: "16",
      name: "Phase 1",
      val: 9,
    },
    {
      id: "17",
      name: "Suspended",
      val: 9,
    },
    {
      id: "18",
      name: "Phase 2/Phase 3",
      val: 9,
    },
    {
      id: "19",
      name: "Not available",
      val: 10,
    },
  ],
  links: [
    {
      source: "18",
      target: "9",
    },
    {
      source: "12",
      target: "6",
    },
    {
      source: "12",
      target: "10",
    },
    {
      source: "1",
      target: "2",
    },
    {
      source: "19",
      target: "13",
    },
    {
      source: "4",
      target: "13",
    },
    {
      source: "5",
      target: "13",
    },
    {
      source: "1",
      target: "17",
    },
    {
      source: "19",
      target: "3",
    },
    {
      source: "11",
      target: "3",
    },
    {
      source: "18",
      target: "8",
    },
    {
      source: "1",
      target: "10",
    },
    {
      source: "16",
      target: "3",
    },
    {
      source: "16",
      target: "2",
    },
    {
      source: "16",
      target: "6",
    },
    {
      source: "12",
      target: "15",
    },
    {
      source: "4",
      target: "10",
    },
    {
      source: "4",
      target: "9",
    },
    {
      source: "1",
      target: "13",
    },
    {
      source: "18",
      target: "13",
    },
    {
      source: "11",
      target: "13",
    },
    {
      source: "5",
      target: "10",
    },
    {
      source: "14",
      target: "9",
    },
    {
      source: "5",
      target: "2",
    },
    {
      source: "19",
      target: "7",
    },
    {
      source: "16",
      target: "9",
    },
    {
      source: "19",
      target: "17",
    },
    {
      source: "18",
      target: "15",
    },
    {
      source: "18",
      target: "6",
    },
    {
      source: "12",
      target: "2",
    },
    {
      source: "4",
      target: "8",
    },
    {
      source: "12",
      target: "13",
    },
    {
      source: "11",
      target: "15",
    },
    {
      source: "11",
      target: "2",
    },
    {
      source: "12",
      target: "17",
    },
    {
      source: "19",
      target: "2",
    },
    {
      source: "11",
      target: "10",
    },
    {
      source: "16",
      target: "17",
    },
    {
      source: "19",
      target: "6",
    },
    {
      source: "4",
      target: "15",
    },
    {
      source: "5",
      target: "6",
    },
    {
      source: "11",
      target: "8",
    },
    {
      source: "1",
      target: "3",
    },
    {
      source: "11",
      target: "6",
    },
    {
      source: "5",
      target: "15",
    },
    {
      source: "1",
      target: "8",
    },
    {
      source: "14",
      target: "6",
    },
    {
      source: "16",
      target: "10",
    },
    {
      source: "14",
      target: "13",
    },
    {
      source: "19",
      target: "9",
    },
    {
      source: "16",
      target: "8",
    },
    {
      source: "11",
      target: "9",
    },
    {
      source: "11",
      target: "17",
    },
    {
      source: "1",
      target: "6",
    },
    {
      source: "14",
      target: "3",
    },
    {
      source: "14",
      target: "15",
    },
    {
      source: "14",
      target: "2",
    },
    {
      source: "5",
      target: "9",
    },
    {
      source: "4",
      target: "2",
    },
    {
      source: "14",
      target: "10",
    },
    {
      source: "18",
      target: "10",
    },
    {
      source: "1",
      target: "15",
    },
    {
      source: "18",
      target: "17",
    },
    {
      source: "14",
      target: "17",
    },
    {
      source: "19",
      target: "15",
    },
    {
      source: "19",
      target: "8",
    },
    {
      source: "14",
      target: "8",
    },
    {
      source: "5",
      target: "8",
    },
    {
      source: "12",
      target: "3",
    },
    {
      source: "16",
      target: "15",
    },
    {
      source: "5",
      target: "3",
    },
    {
      source: "16",
      target: "13",
    },
    {
      source: "12",
      target: "8",
    },
    {
      source: "1",
      target: "9",
    },
    {
      source: "19",
      target: "10",
    },
    {
      source: "4",
      target: "17",
    },
    {
      source: "5",
      target: "17",
    },
    {
      source: "18",
      target: "3",
    },
    {
      source: "4",
      target: "6",
    },
    {
      source: "4",
      target: "3",
    },
    {
      source: "12",
      target: "9",
    },
    {
      source: "18",
      target: "2",
    },
  ],
};

function processData(dat) {
  let newData = {};
  const nodesData = _.get(dat, "nodes");
  const linksData = _.get(dat, "links");
  newData["nodes"] = nodesData;
  newData["links"] = linksData;

  return newData;
}
export default function NtaiForceDirectedChart(props) {
  const { nodeLabelField, width, height, data } = props;

  const nData = processData(data1);

  let lData = JSON.parse(JSON.stringify(data1));

  console.log("Width: ", width, ", height: ", height);

  const GROUPS = 5;
  return (
    <ForceGraph3D
      width={width}
      height={height}
      //   nodeId="id"
      //   nodeLabel={nodeLabelField}
      //   nodeColor="red"
      //   linkOpacity="0.4"
      graphData={lData}
      linkWidth={2}
      linkColor={grey[700]}
      backgroundColor="white"
      nodeAutoColorBy="group"
      //   nodeColor="black"
      //   linkAutoColorBy={(d) => lData.nodes[d.source].id % GROUPS}
      nodeThreeObject={(node) => {
        const sprite = new SpriteText(node.name);
        sprite.color = node.color;
        sprite.textHeight = 8;
        return sprite;
      }}
    />
  );
}
