import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getRules = createAsyncThunk(
  "rules/getRules",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/rules");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRule = createAsyncThunk(
  "rules/getRule",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/rules/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createRule = createAsyncThunk(
  "rules/createRule",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/rules", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRule = createAsyncThunk(
  "rules/updateRule",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/admin/rules/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRule = createAsyncThunk(
  "rules/deleteRule",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/rules/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rulesSlice = createSlice({
  name: "rules",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getRules.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getRules" };
    },
    [getRules.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getRules",
        message: action.payload.message,
      };
    },
    [getRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getRule" };
    },
    [getRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getRule",
        message: action.payload.message,
      };
    },
    [createRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createRule" };
    },
    [createRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createRule",
        message: action.payload.message,
      };
    },
    [updateRule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateRule" };
    },
    [updateRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateRule",
        message: action.payload.message,
      };
    },
    [deleteRule.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteRule" };
    },
    [deleteRule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteRule",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = rulesSlice.actions;

export default rulesSlice.reducer;
