import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiAutoSuggestIB from "@ntai/components/inputs/NtaiAutoSuggestIB";
import React, { useState } from "react";
import { SearchContext } from "../../SearchEdit";
import { useFormContext } from "react-hook-form";
const _ = require("lodash");

function processQueryTerms(qTerms) {}
export default function SearchCriteriaEdit({ handleClose }) {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState();
  const { getValues, reset } = useFormContext();

  const { searchData } = React.useContext(SearchContext);

  const queryTerms = _.map(_.get(searchData, "searchQueryTerms"), (obj) =>
    _.pick(obj, ["term"])
  );

  function handleInputChange(str) {
    setInputValue(str);
  }

  function handleChange(qTerms) {
    //setSearchQueryTerms(qTerms);
  }

  return (
    <Box
      sx={{
        display: "flex",
        pt: theme.spacing(2),
        gap: theme.spacing(2),
        flexDirection: "column",
      }}
    >
      <Typography fontWeight="300" variant="body1">
        Edit search criteria by adding and removing terms. You can also expand
        search term using thesaurus synonyms.
      </Typography>
      <NtaiAutoSuggestIB
        options={[]}
        name="searchCriteriaForm.query"
        optionLabelField="term"
        defaultValue={queryTerms}
        label="Search"
        handleChange={handleChange}
        handleInputChange={handleInputChange}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <Button onClick={handleClose} size="small">
          Cancel
        </Button>
        <Button variant="contained" size="small">
          Search
        </Button>
      </Box>
    </Box>
  );
}
