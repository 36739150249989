import { Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import React, { useEffect } from "react";
import { useState } from "react";
import FunctionAwsSession from "./FunctionAwsSession";
import { useDispatch, useSelector } from "react-redux";
import { getAwsClusters } from "app/main/pages/core/admin/aws/cluster/store/awsClustersSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  startFunctionAwsSession,
} from "./store/functionAwsSessionsSlice";
import { useSnackbar } from "notistack";
import { getFunction } from "../store/functionsSlice";
import { FunctionEditorContext } from "../FunctionEdit";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { faPlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FunctionAwsSessionDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sessionCreationPending, setSessionCreationPending] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { awsEnv, awsSession } = React.useContext(FunctionEditorContext);

  const functionId = useSelector(
    (state) => state.models.functionsSlice.activeId
  );

  const status = useSelector(
    (state) => state.models.functionAwsSessionsSlice.status
  );

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  function handleFormSubmit() {
    dispatch(
      startFunctionAwsSession({
        functionId: functionId,
        formData: { actionCode: awsEnv && !awsSession ? 1 : 2 },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "error" &&
      status.method === "startFunctionAwsSession"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      setSessionCreationPending(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "startFunctionAwsSession"
    ) {
      dispatch(getFunction(functionId));
      handleDialogClose();
      setSessionCreationPending(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "pending" &&
      status.method === "startFunctionAwsSession"
    ) {
      setSessionCreationPending(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getAwsClusters());
  }, []);

  return (
    <React.Fragment>
      {awsEnv && (
        <Button
          sx={{ fontSize: "11px" }}
          disabled={sessionCreationPending ? true : false}
          startIcon={
            sessionCreationPending ? (
              <NtaiCircularProgress size={18} />
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )
          }
          size="small"
          onClick={() => handleFormSubmit()}
        >
          {sessionCreationPending
            ? "STARTING SESSION"
            : awsEnv && !awsSession
            ? "START SESSION"
            : awsEnv
            ? "TERMINATE SESSION"
            : ""}
        </Button>
      )}

      <NtaiDialog
        title="Manage Session"
        open={open}
        handleDialogClose={handleDialogClose}
        size="sm"
      >
        <NtaiForm>
          <FunctionAwsSession
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleDialogClose}
            sessionCreationPending={sessionCreationPending}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
