import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NtaiIcons from "utils/NtaiIcons";
const _ = require("lodash");

export default function NtaiNavigationItem({
  item,
  selectedMenu,
  handleClick,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function onHandleClick(menuItem) {
    if (menuItem.type === "node") setOpen(!open);
    handleClick(menuItem);
  }

  return (
    <React.Fragment key={`item-${item.id}`}>
      <Link
        key={`item-${item.id}`}
        style={{ textDecoration: "none" }}
        to={item.url}
        onClick={() => onHandleClick(item)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            padding: "8px",
            gap: theme.spacing(1),
            background:
              selectedMenu === item.id ? theme.colors.alpha.black[10] : null,
            // borderRight:
            //   selectedMenu === item.id ? theme.general.border2p : null,
            "&:hover": {
              background: theme.colors.alpha.black[10],
            },
          }}
        >
          <Box sx={{ paddingLeft: "8px", flexBasis: "20%" }}>
            <FontAwesomeIcon color="secondary" icon={NtaiIcons[item.icon]} />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexBasis: "80%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight={selectedMenu === item.id ? 700 : 500}
            >
              {item.title}
            </Typography>
            {item.type === "node" &&
              (open ? (
                <ExpandMore fontSize="18px" />
              ) : (
                <ExpandLess fontSize="18px" />
              ))}
          </Box>
        </Box>
      </Link>
      {item.type === "node" && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {item.children.map((child, indexChild) => {
            return (
              <Link
                style={{ textDecoration: "none" }}
                key={`${item.id}-child-${indexChild}`}
                to={child.url}
                onClick={() => onHandleClick(child)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    padding: "8px",
                    gap: theme.spacing(1),
                    background:
                      selectedMenu === child.id
                        ? theme.colors.alpha.black[10]
                        : null,
                    "&:hover": {
                      background: theme.colors.alpha.black[10],
                    },
                  }}
                >
                  <Box sx={{ display: "flex", pl: theme.spacing(6) }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={selectedMenu === child.id ? 700 : 500}
                    >
                      {child.title}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            );
          })}
        </Collapse>
      )}
    </React.Fragment>
  );
}
