import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";

const _ = require("lodash");

export default function SearchSourceRecordSeriesForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchSourceRecordSeriesForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ searchSourceRecordSeriesForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="searchSourceRecordSeriesForm.name"
          label="Name"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="searchSourceRecordSeriesForm.description"
          label="Description"
          multiline
          minRows={3}
          maxRows={3}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
