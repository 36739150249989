import { Box, Typography, useTheme } from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import React, { useContext, useState } from "react";
import { FunctionEditorContext } from "../FunctionEdit";

export default function FunctionQueryPanel() {
  const theme = useTheme();
  const { queryCode, setQueryCode } = useContext(FunctionEditorContext);

  function onChangeQueryCode(newValue) {
    setQueryCode(newValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        borderLeft: theme.general.border1,
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          px: theme.spacing(2),
          borderBottom: theme.general.border1,
        }}
      >
        <Typography variant="h6" fontWeight="700">
          Query
        </Typography>
      </Box>
      <Box sx={{ display: "flex", height: `calc(100% - 44px)` }}>
        <AceEditor
          mode="sql"
          theme="github"
          fontSize="14px"
          onChange={onChangeQueryCode}
          name="queryCode"
          value={queryCode}
          width="100%"
          height="100%"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            // enableSnippets: true,
          }}
        />
      </Box>
    </Box>
  );
}
