import {
  faEdit,
  faListCheck,
  faNote,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";

import React from "react";
import { useEffect } from "react";
import { OversightContext } from "../../../../../Oversight";
import {
  clearRecordListingTemplatesStatus,
  clearStatus,
  getOversightSourceRecordListings,
  getOversightTopicSourceRecords,
} from "./store/oversightTopicSourceRecordsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { OversightSourceRecordsContext } from "./OversightSourceRecordsWrapper";
import { useState } from "react";
const jp = require("jsonpath");

const _ = require("lodash");

function getSourceRecordListingTemplates(dataMap) {
  const result = {};

  Object.keys(dataMap).forEach((sourceId, i) => {
    const sourceRecordListingTemplate = _.filter(
      dataMap[sourceId],
      function (template) {
        return template.typeCode === 2;
      }
    )[0];

    const fields = _.get(
      sourceRecordListingTemplate,
      "sourceRecordListingFields"
    );

    const primaryField = _.filter(fields, { placementCode: 1 })[0];
    const secondaryField = _.filter(fields, { placementCode: 2 })[0];
    const tertiaryField = _.filter(fields, { placementCode: 3 })[0];

    result[sourceId] = {
      primary: _.get(primaryField, "sourceField.path"),
      secondary: _.get(secondaryField, "sourceField.path"),
      tertiary: _.get(tertiaryField, "sourceField.path"),
    };
  });
  return result;
}

export default function OversightTopicSourceRecords() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sourceRecordListingsLoaded, setSourceRecordListingsLoaded] =
    useState(false);
  const { section, navNode } = React.useContext(OversightContext);
  const {
    sourcesLoaded,
    setSourcesLoaded,
    sourcesData,
    setSourcesData,
    sourceRecordsLoaded,
    setSourceRecordsLoaded,
  } = React.useContext(OversightSourceRecordsContext);

  const statusTopicSourceRecordsSlice = useSelector(
    (state) => state.oversight.oversightTopicSourceRecordsSlice.status
  );

  const recordListingTemplatesStatus = useSelector(
    (state) =>
      state.oversight.oversightTopicSourceRecordsSlice
        .recordListingTemplatesStatus
  );

  const dataMap = useSelector(
    (state) => state.oversight.oversightTopicSourceRecordsSlice.data
  );

  const data = Object.values(dataMap);

  const recordListingsTemplateDataMap = useSelector(
    (state) =>
      state.oversight.oversightTopicSourceRecordsSlice
        .recordListingTemplatesData
  );

  const sourceRecordListingTemplates =
    !_.isEmpty(recordListingsTemplateDataMap) &&
    getSourceRecordListingTemplates(recordListingsTemplateDataMap);

  useEffect(() => {
    if (
      recordListingTemplatesStatus &&
      recordListingTemplatesStatus.result === "success" &&
      recordListingTemplatesStatus.method === "getOversightSourceRecordListings"
    ) {
      setSourceRecordListingsLoaded(true);
      dispatch(clearRecordListingTemplatesStatus());
    }
  }, [recordListingTemplatesStatus]);

  useEffect(() => {
    if (
      statusTopicSourceRecordsSlice &&
      statusTopicSourceRecordsSlice.result === "success" &&
      statusTopicSourceRecordsSlice.method === "getOversightTopicSourceRecords"
    ) {
      setSourceRecordsLoaded(true);
      dispatch(clearStatus());
    }
  }, [statusTopicSourceRecordsSlice]);

  useEffect(() => {
    if (section === "source_records") {
      if (
        _.get(navNode, "uuId") &&
        _.get(navNode, "parentUuId") &&
        _.get(navNode, "type") &&
        ["OBJECT_TOPIC", "DATA_TOPIC"].includes(_.get(navNode, "type"))
      ) {
        dispatch(
          getOversightTopicSourceRecords({
            dataUuId: _.get(navNode, "parentUuId"),
            topicUuId: _.get(navNode, "uuId"),
            topicType: _.get(navNode, "type"),
          })
        );
      }
    }
  }, [section, navNode]);

  useEffect(() => {
    if (sourcesLoaded && _.isArray(sourcesData) && sourcesData.length > 0) {
      const sourceUuIds = sourcesData.map((sd, i) => _.get(sd, "sourceUuId"));

      dispatch(
        getOversightSourceRecordListings({
          items: sourceUuIds,
        })
      );
    }
  }, [sourcesData]);

  return (
    <>
      {sourcesLoaded && sourceRecordsLoaded && sourcesData.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexBasis: "75%",
            flexDirection: "column",
          }}
        >
          <React.Fragment>
            <Box
              sx={{
                p: theme.spacing(1),
                height: "44px",
                display: "flex",
                alignItems: "center",
                borderBottom: theme.general.border1,
              }}
            >
              <Typography variant="h6">Records ({data.length})</Typography>
            </Box>
            <Box sx={{ height: `calc(100% - 44px)`, display: "flex" }}>
              {data && data.length > 0 && (
                <Scrollbar>
                  <List
                    sx={{
                      width: "100%",
                      p: 0,
                    }}
                  >
                    {data.map((ostSourceRecord, i) => (
                      <React.Fragment key={`oversight-source-record-${i}`}>
                        <ListItem button sx={{ border: 0, borderRadius: 0 }}>
                          <ListItemAvatar>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: theme.spacing(2),
                              }}
                            >
                              <Chip
                                label={NtaiUtils.trunc(
                                  ostSourceRecord.sourceMin.abbrev,
                                  5
                                )}
                                sx={{
                                  fontSize: "10px",
                                  fontWeight: "700",
                                }}
                                size="small"
                              />
                              <NtaiMuiBadge
                                badgeContent={
                                  _.get(
                                    ostSourceRecord,
                                    "numOfGlobalAssessments"
                                  ) > 0
                                    ? _.get(
                                        ostSourceRecord,
                                        "numOfGlobalAssessments"
                                      )
                                    : null
                                }
                                color="secondary"
                                top={2}
                                right={-2}
                              >
                                <FontAwesomeIcon size="md" icon={faEdit} />
                              </NtaiMuiBadge>
                              <NtaiMuiBadge
                                badgeContent={
                                  _.get(ostSourceRecord, "numOfGlobalActions") >
                                  0
                                    ? _.get(
                                        ostSourceRecord,
                                        "numOfGlobalActions"
                                      )
                                    : null
                                }
                                color="secondary"
                                top={2}
                                right={-2}
                              >
                                <FontAwesomeIcon size="md" icon={faListCheck} />
                              </NtaiMuiBadge>
                              <NtaiMuiBadge
                                badgeContent={
                                  _.get(ostSourceRecord, "numOfGlobalNotes") > 0
                                    ? _.get(ostSourceRecord, "numOfGlobalNotes")
                                    : null
                                }
                                color="secondary"
                                top={2}
                                right={-2}
                              >
                                <FontAwesomeIcon size="md" icon={faNote} />
                              </NtaiMuiBadge>
                            </Box>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ pl: theme.spacing(4) }}
                            primary={
                              <Typography variant="subtitle1" fontWeight="700">
                                {jp.query(
                                  ostSourceRecord.sourceRecord.data,
                                  `$..${_.get(
                                    sourceRecordListingTemplates[
                                      ostSourceRecord.sourceMin.uuId
                                    ],
                                    "primary"
                                  )}`
                                )}
                              </Typography>
                            }
                            secondary={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: theme.spacing(0.5),
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                >
                                  {jp.query(
                                    ostSourceRecord.sourceRecord.data,
                                    `$..${_.get(
                                      sourceRecordListingTemplates[
                                        ostSourceRecord.sourceMin.uuId
                                      ],
                                      "secondary"
                                    )}`
                                  )}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  {NtaiUtils.trunc(
                                    _.isArray(
                                      jp.query(
                                        ostSourceRecord.sourceRecord.data,
                                        `$..${_.get(
                                          sourceRecordListingTemplates[
                                            ostSourceRecord.sourceMin.uuId
                                          ],
                                          "tertiary"
                                        )}`
                                      )
                                    )
                                      ? _.join(
                                          jp.query(
                                            ostSourceRecord.sourceRecord.data,
                                            `$..${_.get(
                                              sourceRecordListingTemplates[
                                                ostSourceRecord.sourceMin.uuId
                                              ],
                                              "tertiary"
                                            )}`
                                          ),
                                          ", "
                                        )
                                      : jp.query(
                                          ostSourceRecord.sourceRecord.data,
                                          `$..${_.get(
                                            sourceRecordListingTemplates[
                                              ostSourceRecord.sourceMin.uuId
                                            ],
                                            "tertiary"
                                          )}`
                                        ),
                                    250
                                  )}
                                </Typography>
                              </Box>
                            }
                          />

                          <ListItemSecondaryAction
                            sx={{ pr: theme.spacing(1) }}
                          >
                            <NtaiCheckboxField
                              name={`oversightSourceRecordList.recordIds.${ostSourceRecord.recordId}`}
                              edge="end"
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Scrollbar>
              )}
              {data && data.length === 0 && (
                <Box>
                  <NtaiEmptyMessage
                    title="No source records available"
                    subheader="Source records appear with at least one assessment or action or note"
                    vAlign="center"
                  />
                </Box>
              )}
            </Box>
          </React.Fragment>
        </Box>
      )}
    </>
  );
}
