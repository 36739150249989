import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createSourceCustomField,
  deleteSourceCustomField,
  getSourceCustomFields,
  updateSourceCustomField,
} from "./store/sourceCustomFieldsSlice";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "label",
    numeric: false,
    disablePadding: false,
    label: "Label",
    width: "15%",
    fontWeight: "700",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Field Name",
    width: "15%",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "10%",
  },
  {
    id: "includeInOutDatasetFg",
    numeric: false,
    disablePadding: false,
    label: "Include in output?",
    width: "10%",
    transformFunc: function (v) {
      return v === 1 ? "Yes" : "No";
    },
  },

  {
    id: "function.name",
    numeric: false,
    disablePadding: false,
    label: "Function",
    width: "15%",
  },
  {
    id: "datasetUpdatedFg",
    numeric: false,
    disablePadding: false,
    label: "Source Refreshed?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "No";
    },
    width: "10%",
  },
  {
    id: "dateDatasetUpdated",
    numeric: false,
    disablePadding: false,
    label: "Date updated",
    transformFunc: NtaiUtils.formatDateCol,
    width: "10%",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourceCustomFieldList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [open, setOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = sourceDefDataMap[sourceDefId];

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceCustomFieldsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceCustomFieldsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    if (action === "delete") {
      selectedIds.forEach((id) => {
        dispatch(
          deleteSourceCustomField({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      });
    }

    setCurrentId(null);
    setAction(null);
  }

  function confirmDelete() {
    setAction("delete");
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction(null);
  }

  function handleSubmit(formData) {
    if (action === "add")
      dispatch(
        createSourceCustomField({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateSourceCustomField({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceCustomFields" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSourceCustomField", "updateSourceCustomField"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      setAction(null);
      setCurrentId(null);
      enqueueSnackbar(status.message, {
        variant: "success",
      });
    }

    if (
      status &&
      status.method === "deleteSourceCustomField" &&
      status.result === "success"
    ) {
      setOpenConfirmDeleteDialog(false);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceCustomFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <NtaiPage
        title={`${_.get(sourceDefData, "name")} / Custom Fields`}
        padding={0}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        {loaded && (
          <NtaiPanel
            width="100%"
            header="Custom Fields"
            subheader="Custom fields, also known as user-defined fields, empower users to create new data attributes by applying custom logic or code during data pipeline processing or transformations. These fields are automatically included as part of the original dataset and are derived based on specific business rules, formulas, or expressions defined by the user. Use simple inline SQL using if-else conditions or spark based UDF to define simple to complex fields."
          >
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              path={`/sourcedefs/${sourceDefId}/sources/${sourceId}/custom-fields`}
              handleToolbarAction={handleToolbarAction}
              title="Custom Field"
            />
          </NtaiPanel>
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
