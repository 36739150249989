import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteSourceMasterTemplate,
  getSourceMasterTemplates,
} from "./store/sourceMasterTemplatesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceMasterTemplateEdit from "./SourceMasterTemplateEdit";
import SourceMasterTemplateCreate from "./SourceMasterTemplateCreate";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourceMasterTemplateList(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.status
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleCancel() {
    setAction("list");
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteSourceMasterTemplate({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
    setAction("list");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceMasterTemplates" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceMasterTemplate"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Master template(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceMasterTemplates({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [sourceId]);

  return (
    <>
      {["list", "delete"].includes(action) && (
        <NtaiPanel
          width="75%"
          header="Master Templates"
          subheader="Define and manage source record labels. Source record label appears as title in the source record display"
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              handleDialogAddEdit={handleDialogAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Master Template"
            />
          )}
          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      )}

      {action === "add" && (
        <SourceMasterTemplateCreate
          handleCancel={handleCancel}
          redirectToEdit={handleDialogAddEdit}
        />
      )}
      {action === "edit" && (
        <SourceMasterTemplateEdit id={currentId} handleCancel={handleCancel} />
      )}

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
