import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";

import {
  getReportFolders,
  createReportFolder,
  updateReportFolder,
  deleteReportFolder,
  clearStatus,
  setActiveId,
} from "./store/reportFoldersSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import ReportFolderForm from "./ReportFolderForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function ReportFolderList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector((state) => state.reports.reportFoldersSlice.data);
  const activeId = useSelector(
    (state) => state.reports.reportFoldersSlice.activeId
  );
  const status = useSelector(
    (state) => state.reports.reportFoldersSlice.status
  );
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteReportFolder(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(updateReportFolder({ uuId: currentId, formData: formData }))
      : dispatch(createReportFolder(formData));
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    dispatch(getReportFolders());
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createReportFolder" ||
        status.method === "updateReportFolder")
    ) {
      enqueueSnackbar(
        `Folder "${
          dataMap[action === "add" ? activeId : currentId]["name"]
        }" saved successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(setActiveId(null));
      setCurrentId(null);

      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteReportFolder"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Folder(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <NtaiPage padding={2} title="Report Folders" back="/reports/admin">
        <NtaiCrudTable
          helpText="Each generated report is saved under a folder having set of permissions - private, public and shared. Use this section to manage folders used for saved"
          rows={data}
          headCells={headCells}
          selectedIds={selectedIds}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          title="Report Folder"
        />
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Report Folder"
        size="sm"
      >
        <NtaiForm>
          <ReportFolderForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
