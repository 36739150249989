import React, { useRef, useLayoutEffect } from "react";
import { tooltipConfig } from "./chartConfigs";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Box } from "@mui/material";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

export default function NtaiChordChart(props) {
  const { widgetId, data, handleClick, metadata } = props;

  useLayoutEffect(() => {
    let chart = am4core.create(
      "chordchart".concat("-", widgetId),
      am4charts.ChordDiagram
    );

    // colors of main characters
    chart.colors.saturation = 0.45;
    chart.colors.step = 3;

    chart.data = data;

    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";

    chart.nodePadding = 0.5;
    chart.minNodeSize = 0.01;
    chart.startAngle = 80;
    chart.endAngle = chart.startAngle + 360;
    chart.sortBy = "value";
    chart.fontSize = 10;

    // tool tip
    chart.tooltip.getFillFromObject = false;
    chart.tooltip.autoTextColor = false;
    chart.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    chart.tooltip.background.fill = am4core.color(tooltipConfig.backgroundFill);
    chart.tooltip.background.fillOpacity = tooltipConfig.backgroundFillOpacity;
    chart.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    chart.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    let nodeTemplate = chart.nodes.template;
    nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
    nodeTemplate.showSystemTooltip = true;
    nodeTemplate.propertyFields.fill = "color";
    nodeTemplate.tooltipText = "{name}: {total}";

    // when rolled over the node, make all the links rolled-over
    nodeTemplate.events.on("over", function (event) {
      let node = event.target;
      node.outgoingDataItems.each(function (dataItem) {
        if (dataItem.toNode) {
          dataItem.link.isHover = true;
          dataItem.toNode.label.isHover = true;
        }
      });
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = true;
          dataItem.fromNode.label.isHover = true;
        }
      });

      node.label.isHover = true;
    });

    // when rolled out from the node, make all the links rolled-out
    nodeTemplate.events.on("out", function (event) {
      let node = event.target;
      node.outgoingDataItems.each(function (dataItem) {
        if (dataItem.toNode) {
          dataItem.link.isHover = false;
          dataItem.toNode.label.isHover = false;
        }
      });
      node.incomingDataItems.each(function (dataItem) {
        if (dataItem.fromNode) {
          dataItem.link.isHover = false;
          dataItem.fromNode.label.isHover = false;
        }
      });

      node.label.isHover = false;
    });

    let label = nodeTemplate.label;
    label.relativeRotation = 90;

    label.fillOpacity = 0.4;
    let labelHS = label.states.create("hover");
    labelHS.properties.fillOpacity = 1;

    nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
    nodeTemplate.adapter.add("fill", function (fill, target) {
      let node = target;
      let counters = {};
      let mainChar = false;
      node.incomingDataItems.each(function (dataItem) {
        if (isNaN(counters[dataItem.fromName])) {
          counters[dataItem.fromName] = dataItem.value;
        } else {
          counters[dataItem.fromName] += dataItem.value;
        }
      });
      if (mainChar) {
        return fill;
      }

      let count = 0;
      let color;
      let biggest = 0;
      let biggestName;

      for (var name in counters) {
        if (counters[name] > biggest) {
          biggestName = name;
          biggest = counters[name];
        }
      }

      return fill;
    });

    // link template
    let linkTemplate = chart.links.template;
    linkTemplate.strokeOpacity = 0;
    linkTemplate.fillOpacity = 0.15;
    linkTemplate.tooltipText = "{fromName} & {toName}:{value.value}";

    let hoverState = linkTemplate.states.create("hover");
    hoverState.properties.fillOpacity = 0.7;
    hoverState.properties.strokeOpacity = 0.7;

    // tool tip
    // nodeTemplate.tooltip.getFillFromObject = false;
    // nodeTemplate.tooltip.autoTextColor = false;
    // nodeTemplate.tooltip.background.cornerRadius =
    //   tooltipConfig.backgroundCornerRadius;
    // nodeTemplate.tooltip.background.fill = am4core.color(
    //   tooltipConfig.backgroundFill
    // );
    // nodeTemplate.tooltip.background.fillOpacity =
    //   tooltipConfig.backgroundFillOpacity;
    // nodeTemplate.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    // nodeTemplate.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    nodeTemplate.events.on(
      "hit",
      function (ev) {
        // console.log("Chord chart: ", ev);
        console.log("Metadata: ", metadata);
        // console.log(
        //   ev.target.dataItem.fromNode.label.currentText,
        //   ev.target.dataItem.toNode.label.currentText
        // );
        // handleClick(widgetId, [
        //   {
        //     sourceFieldUuId: metadata.fields[0],
        //     fieldValue: ev.target.fromNode.label.currentText,
        //     fieldOperatorCode: 1,
        //   },
        //   {
        //     sourceFieldUuId: metadata.fields[1],
        //     fieldValue: ev.target.toNode.label.currentText,
        //     fieldOperatorCode: 1,
        //   },
        // ]);

        const metadataFieldsArr = Object.values(_.get(metadata, "fields"));

        const sourceWidgetFilterFields = [
          {
            sourceFieldUuId: _.get(
              _.sortBy(metadataFieldsArr, ["fieldOrder"], ["asc"])[0],
              "field.uuId"
            ),
            fieldOperatorCode: 1,
            fieldValue: ev.target.dataItem.fromNode.label.currentText,
          },
          {
            sourceFieldUuId: _.get(
              _.sortBy(metadataFieldsArr, ["fieldOrder"], ["asc"])[1],
              "field.uuId"
            ),
            fieldOperatorCode: 1,
            fieldValue: ev.target.dataItem.toNode.label.currentText,
          },
        ];

        handleClick(sourceWidgetFilterFields, ev);
      },
      this
    );

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"chordchart".concat("-", widgetId)}
      style={{ width: "100%", height: "100%" }}
    ></Box>
  );
}
