import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import History from "@ntai/@history";
import {
  clearStatus,
  setActiveId,
  updatePythonFile,
} from "./store/pythonFilesSlice";
import PythonFileForm from "./PythonFileForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function PythonFileEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const id = props.match.params.id;

  const dataMap = useSelector((state) => state.models.pythonFilesSlice.data);
  const activeId = useSelector(
    (state) => state.models.pythonFilesSlice.activeId
  );
  const status = useSelector((state) => state.models.pythonFilesSlice.status);
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updatePythonFile({
        uuId: id,
        formData: formData,
      })
    );
  }

  function handleCancel() {
    History.push("/models/python-files");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updatePythonFile" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Python file "${_.get(
          dataMap[activeId],
          "fileName"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      History.push("/models/python-files");
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "fileName")}`}
      back="/models/python-files"
    >
      <NtaiForm>
        <PythonFileForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
