import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import ContactGroupForm from "./ContactGroupForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import { createContactGroup, clearStatus } from "./store/contactGroupsSlice";
import { useEffect } from "react";
import History from "@ntai/@history/@history";
import { useState } from "react";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ContactGroupCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState();
  const dataMap = useSelector((state) => state.core.contactGroupsSlice.data);
  const status = useSelector((state) => state.core.contactGroupsSlice.status);
  const activeId = useSelector(
    (state) => state.core.contactGroupsSlice.activeId
  );

  function handleFormSubmit(formData) {
    dispatch(createContactGroup(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createContactGroup" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Contact group "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      History.push(`/library/contact-groups/${activeId}/edit`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Contact Groups / Add Contact Group"
      back="/library/contact-groups"
    >
      <NtaiForm>
        <ContactGroupForm action="add" handleFormSubmit={handleFormSubmit} />
      </NtaiForm>
    </NtaiPage>
  );
}
