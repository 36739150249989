import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getNotificationDefs = createAsyncThunk(
  "notificationDefs/getNotificationDefs",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/notification-definitions");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNotificationDef = createAsyncThunk(
  "notificationDefs/getNotificationDef",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/admin/notification-definitions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const enableDisableNotificationDef = createAsyncThunk(
  "notificationDefs/enableDisableNotificationDef",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/admin/notification-definitions/${uuId}/enable-disable`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNotificationDef = createAsyncThunk(
  "notificationDefs/createNotificationDef",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(
        "/admin/notification-definitions",
        values
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateNotificationDef = createAsyncThunk(
  "notificationDefs/updateNotificationDef",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/notification-definitions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteNotificationDef = createAsyncThunk(
  "notificationDefs/deleteNotificationDef",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(
        `/admin/notification-definitions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const notificationDefsSlice = createSlice({
  name: "notificationDefs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getNotificationDefs.pending]: (state, action) => {
      state.status = { result: "pending", method: "getNotificationDefs" };
    },

    [getNotificationDefs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getNotificationDefs" };
    },
    [getNotificationDefs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getNotificationDefs",
        message: action.payload.message,
      };
    },
    [getNotificationDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getNotificationDef" };
    },

    [getNotificationDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getNotificationDef",
        message: action.payload.message,
      };
    },

    [enableDisableNotificationDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "enableDisableNotificationDef",
      };
    },

    [enableDisableNotificationDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "enableDisableNotificationDef",
        message: action.payload.message,
      };
    },

    [createNotificationDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createNotificationDef" };
      state.activeId = action.payload.uuId;
    },
    [createNotificationDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createNotificationDef",
        message: action.payload.message,
      };
    },
    [updateNotificationDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateNotificationDef" };
    },
    [updateNotificationDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateNotificationDef",
        message: action.payload.message,
      };
    },

    [deleteNotificationDef.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteNotificationDef" };
    },
    [deleteNotificationDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteNotificationDef",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = notificationDefsSlice.actions;

export default notificationDefsSlice.reducer;
