import { Box, FormControl, Typography } from "@mui/material";
import React from "react";
import NtaiFilterBox from "./NtaiFilterBox";
import NtaiReactServerSelect from "../server/NtaiReactServerSelect";

export default function NtaiFilterServerSelect(props) {
  const { label, handleCallback } = props;
  console.log("In NtaiFilterServerSelect: ");
  return (
    <NtaiFilterBox>
      <Typography variant="subtitle2" fontWeight="700">
        {label}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <NtaiReactServerSelect {...props} />
      </Box>
    </NtaiFilterBox>
  );
}
