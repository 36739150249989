import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

export default function NtaiListTable(props) {
  const {
    width,
    height,
    items,
    keyField,
    primaryLabelField,
    secondaryLabelField,
    actions,
    handleAction,
    actionAlignment,
    selectedItems,
  } = props;
  const theme = useTheme();
  const [checked, setChecked] = React.useState(
    selectedItems.length > 0 ? selectedItems : []
  );

  console.log(props);

  function onHandleAction(actionName) {
    handleAction(actionName, checked);
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Box
      sx={{
        width: width ? width : "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: height ? height : "240px",
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          border: theme.general.border1,
          borderRadius: theme.general.borderRadiusSm,
        }}
      >
        <Scrollbar>
          {Array.isArray(items) && items.length > 0 && (
            <List
              sx={{
                width: "100%",
                px: 0,
                py: theme.spacing(1),
              }}
            >
              {items.map((item, i) => {
                const labelId = `checkbox-list-label-${_.get(item, keyField)}`;

                return (
                  <React.Fragment key={item[keyField]}>
                    {i === 0 && (
                      <Divider key={`divider-key-${item[keyField]}`} />
                    )}
                    <ListItem
                      key={`listitem-key-${_.get(item, keyField)}`}
                      // secondaryAction={
                      //   <IconButton edge="end" aria-label="comments">
                      //     <FontAwesomeIcon size="sm" icon={faEllipsisV} />
                      //   </IconButton>
                      // }
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(item[keyField])}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(item[keyField]) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={item[primaryLabelField]}
                          secondary={item[secondaryLabelField]}
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          )}
          {!items ||
            (Array.isArray(items) && items.length == 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" fontWeight="700">
                  No records found
                </Typography>
              </Box>
            ))}
        </Scrollbar>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(1),
          justifyContent: actionAlignment ? actionAlignment : "start",
        }}
      >
        {Array.isArray(actions) &&
          actions.map((action) => {
            return (
              <Button
                key={action.value}
                variant={action.variant}
                size="small"
                onClick={() => onHandleAction(action.value)}
              >
                {action.label}
              </Button>
            );
          })}
      </Box>
    </Box>
  );
}
