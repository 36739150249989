import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import NtaiPageHeader from "./NtaiPageHeader";
const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  pageStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    // backgroundColor: grey[400],
  },
  pageHeaderStyle: {
    display: "flex",
    width: "100%",
    height: "53px",
    alignItems: "center",
    borderBottom: (props) => theme.general.border1,
    // boxShadow: "0 1px 1px -1px grey",
  },
  pageContainerStyle: {
    display: "flex",
    width: "100%",
    height: "calc(100% - 53px)",
    // background: "lime",
    // padding: (props) => props.padding && theme.spacing(props.padding),
  },
  pageContentStyle: {
    // padding: "8px",
    padding: (props) =>
      props.hasOwnProperty("padding") ? theme.spacing(props.padding) : "0px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
}));

export default function NtaiPage(props) {
  const classes = useStyles(props);
  const {
    startAction,
    back,
    title,
    titleExtras,
    titleHelpText,
    headerActions,
    children,
    disableScrollbar,
  } = props;

  return (
    <Box className={classes.pageStyle}>
      <Box className={classes.pageHeaderStyle}>
        <NtaiPageHeader
          back={back}
          startAction={startAction}
          title={title}
          titleExtras={titleExtras}
          titleHelpText={titleHelpText}
          headerActions={headerActions}
        />
      </Box>

      {((_.has(props, "disableScrollbar") && !props.disableScrollbar) ||
        !_.has(props, "disableScrollbar")) && (
        <Box className={classes.pageContainerStyle}>
          <Scrollbar>
            <Box className={classes.pageContentStyle}>{children}</Box>
          </Scrollbar>
        </Box>
      )}

      {_.has(props, "disableScrollbar") && props.disableScrollbar && (
        <Box className={classes.pageContainerStyle}>
          <Box className={classes.pageContentStyle}>{children}</Box>
        </Box>
      )}
    </Box>
  );
}
