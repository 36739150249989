import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDataAdapterJobs = createAsyncThunk(
  "dataAdapterJobs/getDataAdapterJobs",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/adapters/${uuId}/jobs`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const dataAdapterJobsSlice = createSlice({
  name: "dataAdapterJobs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDataAdapterJobs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getDataAdapterJobs" };
    },
    [getDataAdapterJobs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDataAdapterJobs",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = dataAdapterJobsSlice.actions;

export default dataAdapterJobsSlice.reducer;
