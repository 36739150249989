import { useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../../SearchEdit";
import {
  getSearchSourceRecordListings,
  getSearchSummarySourceRecords,
} from "./store/searchSummarySourceRecordsSlice";
import { SearchSummaryContext } from "../SearchSummary";
import { useState } from "react";
import SearchSummarySourceRecordList from "./SearchSummarySourceRecordList";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function SearchSummarySourceRecordListWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { searchData } = React.useContext(SearchContext);
  const { navSearchSourceUuId } = React.useContext(SearchSummaryContext);
  const [
    sourceRecordListingTemplatesLoaded,
    setSourceRecordListingTemplatesLoaded,
  ] = useState(false);

  const [
    searchSummarySourceRecordsLoaded,
    setSearchSummarySourceRecordsLoaded,
  ] = useState(false);

  const searchId = _.get(searchData, "uuId");

  const dataMap = useSelector(
    (state) => state.search.searchSummarySourceRecordsSlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSummarySourceRecordsSlice.status
  );

  const sourceRecordListingsDataMap = useSelector(
    (state) =>
      state.search.searchSummarySourceRecordsSlice.sourceRecordListingsData
  );

  const searchSourceRecordListingsStatus = useSelector(
    (state) =>
      state.search.searchSummarySourceRecordsSlice.sourceRecordListingsStatus
  );

  useEffect(() => {
    if (
      searchSourceRecordListingsStatus &&
      searchSourceRecordListingsStatus.result === "success" &&
      searchSourceRecordListingsStatus.method ===
        "getSearchSourceRecordListings"
    )
      setSourceRecordListingTemplatesLoaded(true);
  }, [searchSourceRecordListingsStatus]);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSummarySourceRecords"
    )
      setSearchSummarySourceRecordsLoaded(true);
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSummarySourceRecords({
        searchId: searchId,
        searchSourceId: navSearchSourceUuId === "0" ? "*" : navSearchSourceUuId,
        params: {
          from: 0,
          size: 25,
        },
      })
    );
  }, [navSearchSourceUuId]);

  useEffect(() => {
    _.get(searchData, "sourceMins").forEach((sourceMin) => {
      dispatch(
        getSearchSourceRecordListings({
          sourceDefId: "*",
          sourceId: sourceMin.uuId,
        })
      );
    });
  }, [searchId]);

  return (
    <React.Fragment>
      {searchSummarySourceRecordsLoaded &&
        sourceRecordListingTemplatesLoaded && <SearchSummarySourceRecordList />}
      {!(
        searchSummarySourceRecordsLoaded || sourceRecordListingTemplatesLoaded
      ) && <NtaiCircularProgress size={20} vAlign="center" />}
    </React.Fragment>
  );
}
