import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function ModelForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleUploadModel } = props;
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);
  const activeId = useSelector((state) => state.models.modelsSlice.activeId);

  const typeOptions = _.has(codelistData, CODELIST_CODES.MOD_MODEL_TYPE_CODE)
    ? _.get(codelistData, CODELIST_CODES.MOD_MODEL_TYPE_CODE)
    : [];

  const categoryOptions = _.has(
    codelistData,
    CODELIST_CODES.MOD_MODEL_CATEGORY_TYPE_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.MOD_MODEL_CATEGORY_TYPE_CODE)
    : [];

  const algorithmOptions = _.has(
    codelistData,
    CODELIST_CODES.MOD_ALGORITHM_TYPE_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.MOD_ALGORITHM_TYPE_CODE)
    : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("modelForm")
    );
    handleSave(sanitizedFormData);
  }

  function onHandleCancel() {
    history.push("/models/models");
  }

  function handleFileUpload(formData, config) {
    handleUploadModel({ itemId: activeId, formData: formData, config: config });
  }

  function displayUploadedFile() {
    return (
      <NtaiLabelValue
        label="Uploaded File"
        value={_.get(formData, "modelFileName")}
      />
    );
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.MOD_MODEL_CATEGORY_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.MOD_MODEL_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.MOD_ALGORITHM_TYPE_CODE));
  }, []);

  useEffect(() => {
    if (action === "add") reset({ modelForm: {} });
    else if (action === "edit") reset({ modelForm: formData });
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <Box sx={{ flexBasis: "60%", display: "flex", height: "100%" }}>
        <NtaiFormPanel
          header="Model Management"
          subheader="Define and manage AI/ML models to be used in user defined functions"
          width="70%"
          handleSave={onHandleSave}
          handleCancel={onHandleCancel}
        >
          {/* <NtaiTextField
            name="modelForm.alias"
            placeholder="Alias"
            label="Alias*"
            rules={{ required: "Alias is required" }}
          /> */}

          <NtaiTextField
            name="modelForm.name"
            placeholder="Name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />
          <NtaiTextField
            name="modelForm.description"
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Description"
            label="Description*"
            rules={{ required: "Description is required" }}
          />
          {/* <NtaiSelectField
            name="modelForm.categoryCode"
            options={categoryOptions}
            label="Select category"
          /> */}
          <NtaiSelectField
            name="modelForm.typeCode"
            options={typeOptions}
            label="Type"
          />
          <NtaiSelectField
            name="modelForm.algorithmCode"
            options={algorithmOptions}
            label="Algorithm"
          />
        </NtaiFormPanel>
      </Box>

      {action === "edit" && (
        <Box
          sx={{
            flexBasis: "40%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderLeft: theme.general.border1,
          }}
        >
          <NtaiPanel
            width="100%"
            header="Upload File"
            subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
          >
            <NtaiFileUpload handleFileUpload={handleFileUpload} />
            {displayUploadedFile()}
          </NtaiPanel>
        </Box>
      )}
    </Box>
  );
}
