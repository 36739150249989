import EntityCreate from "./EntityCreate";
import EntityEdit from "./EntityEdit";
import EntityList from "./EntityList";
import RedirectToEntityList from "./RedirectToEntityList";

const EntityConfig = {
  routes: [
    {
      path: "/entities-library/domains/:domainId/objects/:objectId/entities",
      exact: true,
      component: EntityList,
    },
    {
      path: "/entities-library/domains/:domainId/objects/:objectId/entities/create",
      exact: true,
      component: EntityCreate,
    },
    {
      path: "/entities-library/domains/:domainId/objects/:objectId/entities/:entityId/edit",
      exact: true,
      component: EntityEdit,
    },
    {
      path: "/entities-library",
      exact: true,
      component: RedirectToEntityList,
    },
  ],
};

export default EntityConfig;
