import React from "react";
import CytoscapeComponent from "react-cytoscapejs";
import Cytoscape from "cytoscape";
import COSEBilkent from "cytoscape-cose-bilkent";

Cytoscape.use(COSEBilkent);

const elements = [
  { data: { id: "one", label: "Node 1" }, position: { x: 0, y: 0 } },
  { data: { id: "two", label: "Node 2" }, position: { x: 100, y: 0 } },
  { data: { source: "one", target: "two", label: "Edge from Node1 to Node2" } },
];

export default function NtaiCytoscape() {
  const layout = { name: "cose-bilkent" };

  return (
    <CytoscapeComponent
      elements={elements}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
    />
  );
}
