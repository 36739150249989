import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import { getObjectTopics } from "app/main/pages/core/cdo/object/topic/store/objectTopicsSlice";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AlertDefObjectTopic1 from "./objecttopic/AlertDefObjectTopic";
import {
  clearStatus,
  deleteAllAlertDefSourceObjectTopics,
  getAlertDefSourceObjectTopics,
} from "./objecttopic/store/alertDefSourceObjectTopicsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AlertDefSourceTopic() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { setValue } = useFormContext();
  const [openObjectTopicDialog, setOpenObjectTopicDialog] =
    React.useState(false);

  const alertDefinitionDataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );

  const definitionId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  const alertDefinitionData = _.get(alertDefinitionDataMap, definitionId);

  const objectTopicsDataMap = useSelector(
    (state) => state.core.objectTopicsSlice.data
  );

  const objectTopicsData = Object.values(objectTopicsDataMap);

  const alertDefObjectTopicsDataMap = useSelector(
    (state) => state.monitor.alertDefSourceObjectTopicsSlice.data
  );

  const status = useSelector(
    (state) => state.monitor.alertDefSourceObjectTopicsSlice.status
  );

  const alertDefObjectTopicsData = Object.values(alertDefObjectTopicsDataMap);

  function handleObjectTopicDialogClose() {
    setOpenObjectTopicDialog(false);
  }

  function handleObjectTopicDialogOpen() {
    setOpenObjectTopicDialog(true);
  }

  function handleObjectTopicDeleteAll() {
    dispatch(
      deleteAllAlertDefSourceObjectTopics({
        alertDefinitionId: _.get(alertDefinitionData, "uuId"),
        alertDefSourceId: _.get(alertDefinitionData, "alertDefSource.uuId"),
        formData: {
          objectTopicUuIds: [],
        },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      ["deleteAllAlertDefSourceObjectTopics"].includes(status.method) &&
      status.result === "success"
    ) {
      const result = {};
      objectTopicsData.forEach((otd, i) => {
        result[otd.uuId] = false;
      });

      setValue("alertDefSourceObjectTopicForm.objectTopicUuIds", result);
      dispatch(clearStatus());
      enqueueSnackbar(
        `All object topics for alert "${alertDefinitionData["name"]}" deleted successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (status && status.result === "error") {
      dispatch(clearStatus());
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getObjectTopics({
        domainId: "*",
        objectId: "*",
        params: { sourceUuId: _.get(alertDefinitionData, "sourceUuId") },
      })
    );

    dispatch(
      getAlertDefSourceObjectTopics({
        alertDefinitionId: _.get(alertDefinitionData, "uuId"),
        alertDefSourceId: _.get(alertDefinitionData, "alertDefSource.uuId"),
      })
    );
  }, []);

  return (
    <>
      <NtaiPanel
        width="90%"
        header="Select Topics"
        subheader="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
      >
        <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
          <Box
            sx={{
              flexBasis: "48%",
              border: theme.general.border1,
              borderRadius: theme.general.borderRadius,
            }}
          >
            <NtaiPanel
              width="100%"
              header={`Assign Object Topics (${alertDefObjectTopicsData.length} available)`}
              subheader="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
            >
              <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleObjectTopicDialogOpen()}
                >
                  Manage Object Topics
                </Button>
                <Button
                  size="small"
                  onClick={() => handleObjectTopicDeleteAll()}
                >
                  Clear All
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {alertDefObjectTopicsData.map((adotd, i) => (
                  <NtaiLabelValue
                    key={`object-topic-${i}`}
                    direction="row"
                    label={adotd.objectTopic.topic.name}
                    value={adotd.objectTopic.objectName}
                  />
                ))}
              </Box>
            </NtaiPanel>
          </Box>

          <Divider orientation="vertical" flexItem>
            <Typography variant="h6" fontWeight="700">
              OR
            </Typography>
          </Divider>

          <Box
            sx={{
              flexBasis: "48%",
              border: theme.general.border1,
              borderRadius: theme.general.borderRadius,
            }}
          >
            <NtaiPanel
              width="100%"
              header="Assign Data Topics"
              subheader="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
            >
              <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
                <Button variant="contained" size="small">
                  Manage Data Topics
                </Button>
                <Button size="small">Clear All</Button>
              </Box>
            </NtaiPanel>
          </Box>
        </Box>
      </NtaiPanel>
      <NtaiDialog
        open={openObjectTopicDialog}
        handleDialogClose={handleObjectTopicDialogClose}
        title="Object Topics"
        size="sm"
      >
        <AlertDefObjectTopic1
          handleDialogClose={handleObjectTopicDialogClose}
        />
      </NtaiDialog>
    </>
  );
}
