import React, { useEffect, useState } from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";
import NtaiReactDataTable from "@ntai/components/data-table/NtaiReactDataTable";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NtaiErrorMessage from "@ntai/components/errors/NtaiErrorMessage";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getSourcePipelineRefSourceDatasetPreview,
  clearStatus,
} from "../../../../ref-datasets/store/sourcePipelineRefSourceDatasetsSlice";
const _ = require("lodash");

function getColumns(resultSet) {
  const row0 = resultSet[0];
  const columns = [];
  Object.keys(row0).forEach((c, i) => {
    columns.push({
      id: c,
      wrap: true,
      grow: true,
      name: c,
      selector: (row) => row[c],
      sortable: true,
    });
  });

  return columns;
}

function getRowData(rowData) {
  const result = [];
  rowData.forEach((rd, i) => {
    result.push({
      ...rd,
    });
  });

  return result;
}

export default function SourceDatasetPipelineRefSourceDatasetBottomDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const { sidePanelMenuParam } = React.useContext(SourceDatasetManagerContext);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourcePipelineRefSourceDatasetsSlice.data
  );

  const data =
    dataMap && !_.isEmpty(dataMap) ? dataMap[sidePanelMenuParam["uuId"]] : {};

  const status = useSelector(
    (state) => state.sources.sourcePipelineRefSourceDatasetsSlice.status
  );

  useEffect(() => {
    if (
      status &&
      status.method === "getSourcePipelineRefSourceDatasetPreview"
    ) {
      if (status.result === "error") {
        enqueueSnackbar("Error fetching preview: " + status["message"], {
          variant: "error",
        });
      }
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (sidePanelMenuParam["menuId"] === 2) {
      dispatch(
        getSourcePipelineRefSourceDatasetPreview({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourcePipelineId: sourcePipelineId,
          uuId: sidePanelMenuParam["uuId"],
        })
      );
    }
  }, [sidePanelMenuParam]);

  return (
    <React.Fragment>
      {loaded &&
        !_.isEmpty(data) &&
        data["preview"]["status"] === "success" &&
        _.isArray(data["preview"]["result"]) &&
        data["preview"]["result"].length > 0 && (
          <NtaiReactDataTable
            data={getRowData(data["preview"]["result"])}
            columns={getColumns(data["preview"]["result"])}
          />
        )}

      {loaded &&
        data &&
        data["preview"]["status"] === "success" &&
        _.isArray(data["preview"]["result"]) &&
        data["preview"]["result"].length === 0 && (
          <NtaiEmptyMessage header="No records available" />
        )}

      {loaded && data && data["preview"]["status"] === "error" && (
        <NtaiErrorMessage
          title="Error generating preview"
          message={data["preview"]["message"]}
        />
      )}
      {!loaded && <NtaiCircularProgress vAlign="center" size={24} />}
    </React.Fragment>
  );
}
