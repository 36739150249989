import { faChartSimple, faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getOversightEntityOverviewViews,
  setActiveId,
} from "./store/oversightEntityOverviewViewsSlice";
import { OversightContext } from "../../../Oversight";
import { useEffect } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import { useState } from "react";
import OversightEntityOverviewViewMenu from "./OversightEntityOverviewViewMenu";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import OversightEntityOverview from "./OversightEntityOverview";
const _ = require("lodash");

export const OversightEntityOverviewContext = React.createContext();

export default function OversightEntityOverviewWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [viewId, setViewId] = React.useState();
  const [openViewMenu, setOpenViewMenu] = useState(false);
  const [anchorElViewMenu, setAnchorElViewMenu] = useState(null);

  const handleViewMenuOpen = (event) => {
    setOpenViewMenu(true);
    setAnchorElViewMenu(event.currentTarget);
  };
  const handleViewMenuClose = () => {
    setOpenViewMenu(false);
    setAnchorElViewMenu(null);
  };

  const clickAwayHandler = () => {
    setOpenViewMenu(false);
  };

  const { navNode } = React.useContext(OversightContext);

  const dataUuId =
    _.has(navNode, "type") && _.get(navNode, "type") === "ENTITY"
      ? _.get(navNode, "uuId")
      : null;

  const dataMap = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewsSlice.data
  );

  const status = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewsSlice.status
  );

  useEffect(() => {
    if (
      status &&
      status.method === "getOversightEntityOverviewViews" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      dispatch(
        setActiveId(
          _.isEmpty(dataMap) ? null : _.get(Object.values(dataMap)[0], "uuId")
        )
      );
      setViewId(
        _.isEmpty(dataMap) ? null : _.get(Object.values(dataMap)[0], "uuId")
      );
    }
  }, [status]);

  useEffect(() => {
    if (dataUuId && dataUuId.length > 0)
      dispatch(
        getOversightEntityOverviewViews({
          objectUuId: _.get(navNode, "parentUuId"),
          dataUuId: dataUuId,
        })
      );
  }, [dataUuId]);

  return (
    <OversightEntityOverviewContext.Provider value={{ viewId, setViewId }}>
      <React.Fragment>
        {loaded && dataMap[viewId] && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                px: theme.spacing(2),
                height: "36px",
                display: "flex",
                alignItems: "center",
                borderBottom: theme.general.border1,
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" fontWeight="600">
                  {loaded && _.get(dataMap[viewId], "name")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
              >
                <Button
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,
                    gap: theme.spacing(1),
                  }}
                  size="small"
                  color="inherit"
                >
                  <FontAwesomeIcon size="xl" icon={faDownload} />
                  EXPORT
                </Button>

                <Button
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,
                    gap: theme.spacing(1),
                  }}
                  size="small"
                  color="inherit"
                  onClick={(e) => handleViewMenuOpen(e)}
                >
                  <FontAwesomeIcon size="xl" icon={faChartSimple} />
                  SOURCE VIEWS
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                height: `calc(100% - 36px)`,
              }}
            >
              {loaded && <OversightEntityOverview />}
              {!loaded && <NtaiCircularProgress />}
            </Box>
          </Box>
        )}

        {loaded && (!dataMap || _.isEmpty(dataMap)) && (
          <NtaiEmptyMessage
            header="No view available"
            subheader="Create object - source view"
          />
        )}

        {openViewMenu && (
          <NtaiPopover
            open={openViewMenu}
            anchorEl={anchorElViewMenu}
            handleClose={handleViewMenuClose}
            clickAwayHandler={clickAwayHandler}
          >
            <OversightEntityOverviewViewMenu
              handleClose={handleViewMenuClose}
            />
          </NtaiPopover>
        )}
      </React.Fragment>
    </OversightEntityOverviewContext.Provider>
  );
}
