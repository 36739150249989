import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getFunctionAwsSessions = createAsyncThunk(
  "functionAwsSessions/getFunctionAwsSessions",
  async (values, { rejectWithValue }) => {
    try {
      const { functionId } = values;
      const response = await server.get(
        `/models/functions/${functionId}/aws-sessions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFunctionAwsSession = createAsyncThunk(
  "functionAwsSessions/getFunctionAwsSession",
  async (values, { rejectWithValue }) => {
    try {
      const { functionId, uuId } = values;
      const response = await server.get(
        `/models/functions/${functionId}/aws-sessions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const startFunctionAwsSession = createAsyncThunk(
  "functionAwsSessions/startFunctionAwsSession",
  async (values, { rejectWithValue }) => {
    try {
      const { functionId, formData } = values;
      const response = await server.post(
        `/models/functions/${functionId}/aws-sessions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFunctionAwsSession = createAsyncThunk(
  "functionAwsSessions/updateFunctionAwsSession",
  async (values, { rejectWithValue }) => {
    try {
      const { functionId, uuId, formData } = values;
      const response = await server.patch(
        `/models/functions/${functionId}/aws-sessions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFunctionAwsSession = createAsyncThunk(
  "functionAwsSessions/deleteFunctionAwsSession",
  async (values, { rejectWithValue }) => {
    try {
      const { functionId, uuId } = values;
      const response = await server.delete(
        `/models/functions/${functionId}/aws-sessions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const functionAwsSessionsSlice = createSlice({
  name: "functionAwsSessions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getFunctionAwsSessions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getFunctionAwsSessions",
      };
    },
    [getFunctionAwsSessions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getFunctionAwsSessions",
        message: action.payload.message,
      };
    },
    [getFunctionAwsSession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getFunctionAwsSession",
      };
    },
    [getFunctionAwsSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getFunctionAwsSession",
        message: action.payload.message,
      };
    },
    [startFunctionAwsSession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "startFunctionAwsSession",
      };
    },
    [startFunctionAwsSession.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "startFunctionAwsSession",
      };
    },

    [startFunctionAwsSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "startFunctionAwsSession",
        message: action.payload.message,
      };
    },
    [updateFunctionAwsSession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateFunctionAwsSession",
      };
    },
    [updateFunctionAwsSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateFunctionAwsSession",
        message: action.payload.message,
      };
    },
    [deleteFunctionAwsSession.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteFunctionAwsSession",
      };
    },
    [deleteFunctionAwsSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteFunctionAwsSession",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = functionAwsSessionsSlice.actions;

export default functionAwsSessionsSlice.reducer;
