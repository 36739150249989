import { Box, useTheme } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFunction,
  updateFunctionReturnType,
} from "../../store/functionsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { FunctionEditorContext } from "../../FunctionEdit";
import { getReturnTypes } from "../../../returntype/store/returnTypesSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { MODEL_FUNCTION_TYPE_CODE } from "app/main/constants/NtaiCodelistConstants";

const _ = require("lodash");

export default function FunctionReturnTypeSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset, watch } = useFormContext();

  const { action, formData } = useContext(FunctionEditorContext);

  const watchReturnTypeSelection = watch(
    "functionForm.returnTypeUuId",
    _.get(formData, "returnTypeUuId")
  );

  const returnTypesDataMap = useSelector(
    (state) => state.models.returnTypesSlice.data
  );

  const returnTypesData = Object.values(returnTypesDataMap);

  const returnTypeOptions = NtaiUtils.getSelectOptions(
    returnTypesData,
    "uuId",
    "name"
  );

  const activeId = useSelector((state) => state.models.functionsSlice.activeId);
  //   const status = useSelector((state) => state.models.functionsSlice.status);

  function updateReturnType() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("functionForm")
    );

    if (
      _.has(sanitizedFormData, "returnTypeUuId") &&
      _.get(sanitizedFormData, "returnTypeUuId") !==
        _.get(formData, "returnTypeUuId")
    ) {
      dispatch(
        updateFunction({
          uuId: activeId,
          formData: sanitizedFormData,
        })
      );
    }
  }

  useEffect(() => {
    updateReturnType();
  }, [watchReturnTypeSelection]);

  //   useEffect(() => {
  //     setValue("functionForm.returnTypeUuId", _.get(formData, "returnTypeUuId"));
  //   }, [formData]);

  useEffect(() => {
    dispatch(getReturnTypes());
  }, []);

  return (
    <NtaiAccordion title="Return Type" titleIcon="turn-down-left">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: theme.spacing(2),
        }}
      >
        {Array.isArray(returnTypeOptions) && returnTypeOptions.length > 0 && (
          <NtaiSelectField
            width="100%"
            options={returnTypeOptions}
            isDisabled={
              !_.isEmpty(formData) &&
              formData["typeCode"] === MODEL_FUNCTION_TYPE_CODE.SRC_SOURCE_SCD
                ? true
                : false
            }
            name="functionForm.returnTypeUuId"
            label="Select Return Type"
          />
        )}
        {!returnTypeOptions ||
          (Array.isArray(returnTypeOptions) &&
            returnTypeOptions.length === 0 && (
              <Box sx={{ p: theme.spacing(2) }}>
                <NtaiEmptyMessage
                  align="center"
                  header="No records available"
                  subheader="Add return types from menu"
                />
              </Box>
            ))}
      </Box>
    </NtaiAccordion>
  );
}
