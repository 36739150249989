import {
  faClock as faClockLight,
  faSave,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBadgeCheck,
  faClock as faClockSolid,
  faTick,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import React, { useEffect } from "react";
import { useState } from "react";
import ReportScheduleForm from "./ReportScheduleForm";
import { ReportContext } from "../ReportMain";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createReportSchedule,
  deleteReportSchedule,
  getReportSchedule,
  submitReportSchedule,
  updateReportSchedule,
} from "./store/reportScheduleSlice";
import NtaiCronScheduler from "@ntai/components/cron/NtaiCronScheduler";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
const _ = require("lodash");

export default function ReportScheduleDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [localCronValue, setLocalCronValue] = useState();
  const [cronValue, setCronValue] = useState();
  const [loaded, setLoaded] = useState(false);

  const { action, reportId } = React.useContext(ReportContext);

  const dataMap = useSelector(
    (state) => state.reports.reportScheduleSlice.data
  );
  const status = useSelector(
    (state) => state.reports.reportScheduleSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function onChange(val) {
    setLocalCronValue(val);
  }

  function handleSave(val) {
    if (
      _.isArray(data) &&
      data.length > 0 &&
      data[0]["cronExpression"] &&
      data[0]["cronExpression"].length > 0
    ) {
      dispatch(
        updateReportSchedule({
          reportId: reportId,
          uuId: data[0]["uuId"],
          formData: {
            cronExpression: localCronValue,
          },
        })
      );
    } else {
      dispatch(
        createReportSchedule({
          reportId: reportId,
          formData: {
            cronExpression: localCronValue,
          },
        })
      );
    }
  }

  function handleOtherAction(action) {
    if (action === "schedule") {
      if (data && _.isArray(data) && data.length > 0) {
        dispatch(
          submitReportSchedule({
            reportId: reportId,
            uuId: data[0]["uuId"],
          })
        );
      }
    }

    if (action === "delete") {
      if (data && _.isArray(data) && data.length > 0) {
        dispatch(
          deleteReportSchedule({
            reportId: reportId,
            uuId: data[0]["uuId"],
          })
        );
      }
    }
  }

  useEffect(() => {
    if (
      status &&
      [
        "getReportSchedule",
        "createReportSchedule",
        "updateReportSchedule",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      if (_.isArray(data) && data.length > 0) {
        setCronValue(data[0]["cronExpression"]);
      } else setCronValue("");

      setLoaded(true);
      dispatch(clearStatus());
      handleClose();
    }

    if (
      status &&
      ["submitReportSchedule", "deleteReportSchedule"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      handleClose();
    }
  }, [status]);

  useEffect(() => {
    dispatch(getReportSchedule(reportId));
  }, [reportId]);

  return (
    <>
      <Button
        sx={{
          fontSize: "11px",
          gap: theme.spacing(1),
        }}
        color="inherit"
        onClick={() => handleOpen()}
        disabled={action === "add"}
      >
        <FontAwesomeIcon icon={faClockLight} />
        SCHEDULE
        {data && _.isArray(data) && data.length > 0 && (
          <FontAwesomeIcon size="xl" icon={faBadgeCheck} />
        )}
      </Button>

      <NtaiDialog
        title="Schedule Report"
        size="sm"
        open={open}
        handleDialogClose={handleClose}
      >
        <NtaiDialogFormPanel
          title="Schedule Report"
          subheader="Enter report scheduling frequency to general report at specified interval."
          handleSave={handleSave}
          handleCancel={handleClose}
          otherActions={[
            { value: "schedule", label: "Schedule" },
            { value: "delete", label: "Delete" },
          ]}
          handleOtherAction={handleOtherAction}
        >
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              {loaded && (
                <NtaiCronScheduler
                  value={cronValue}
                  onChange={onChange}
                  showResultText={true}
                />
              )}
              {!loaded && <NtaiCircularProgress size={24} />}
            </Box>
          </Box>
        </NtaiDialogFormPanel>
      </NtaiDialog>
    </>
  );
}
