import { Box } from "@mui/material";
import React from "react";
import ParentComponent from "./ParentComponent";

export default function TestReactStates() {
  return (
    <Box sx={{ p: "12px" }}>
      <ParentComponent />
    </Box>
  );
}
