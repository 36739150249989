import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

export default function NtaiPopover(props) {
  const {
    open,
    anchorEl,
    anchorOriginV,
    anchorOriginH,
    transformOriginV,
    transformOriginH,
    handleClose,
    children,
    left,
    right,
  } = props;

  function getTopPos() {}

  function getBottomPos() {}

  function getLeftPos() {}

  function getRightPos() {}

  return (
    <Popover
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1,
          "& .MuiAvatar-root": {
            width: 24,
            height: 24,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: left ? left : null,
            right: right ? right : left ? null : 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorOriginV ? anchorOriginV : "bottom",
        horizontal: anchorOriginH ? anchorOriginH : "right",
      }}
      transformOrigin={{
        vertical: transformOriginV ? transformOriginV : "top",
        horizontal: transformOriginH ? transformOriginH : "right",
      }}
    >
      {children}
    </Popover>
  );
}
