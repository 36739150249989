import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getParentEntities = createAsyncThunk(
  "entityParent/getParentEntities",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const entityParentSlice = createSlice({
  name: "entityParent",
  initialState: {
    data: {},
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getParentEntities.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getParentEntities" };
    },
    [getParentEntities.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getParentEntities",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = entityParentSlice.actions;

export default entityParentSlice.reducer;
