import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsStatus,
} from "../../../source/store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearDerivedFieldsStatus,
} from "../../../source/derivedfield/store/sourceDerivedFieldsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import {
  updateSourceRecordTemplate,
  updateSourceRecordTemplateFields,
} from "./store/sourceRecordTemplatesSlice";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { grey } from "@mui/material/colors";
import NtaiDnd3 from "@ntai/components/dnd3/NtaiDnd3";
import { faClose } from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

function transformSelectedFields(fields) {
  let result = [];
  if (_.isArray(fields)) {
    _.orderBy(fields, ["fieldOrder"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.fieldUuId,
        text:
          f.sourceDerivedFieldFg !== 1
            ? f.sourceField.label
            : f.sourceDerivedField.label,
      });
    });
  }

  return result;
}

function transformAvailableFields(allFields, selectedFields) {
  const revisedFields = _.filter(
    _.orderBy(allFields, ["label"], ["asc"]),
    function (f) {
      if (_.filter(selectedFields, { uuId: f.uuId }).length === 0) return true;
    }
  );

  return revisedFields;
}

export default function SourceRecordTemplateFields() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();

  const [availableFieldsLoaded, setAvailableFieldsLoaded] = useState(false);
  const [selectedFieldsLoaded, setSelectedFieldsLoaded] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const templateId = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );

  const data = _.get(dataMap, templateId);

  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    uuId: templateId,
  };

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const statusSourceFields = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const statusDerivedFields = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFields = Object.values(sourceFieldsDataMap).map((f) => {
    return {
      uuId: f.uuId,
      name: f.name,
      label: f.label,
      type: 1,
    };
  });

  const sourceDerivedFields = Object.values(sourceDerivedFieldsDataMap).map(
    (f) => {
      return {
        uuId: f.uuId,
        name: f.name,
        label: f.label,
        type: 2,
      };
    }
  );

  const allFieldsData = [...sourceFields, ...sourceDerivedFields];

  function handleReorder(items) {
    const reorderedSourceRecordTemplateFields = items.map((f, i) => {
      return {
        fieldUuId: f["uuId"],
        fieldOrder: i + 1,
      };
    });

    const selectedSourceRecordTemplateFields = selectedFields.map((f, i) => {
      return {
        fieldUuId: f.uuId,
        fieldOrder: i + 1,
      };
    });

    if (
      !_.isEqual(
        reorderedSourceRecordTemplateFields,
        selectedSourceRecordTemplateFields
      )
    ) {
      handleUpdate(reorderedSourceRecordTemplateFields);
    }
  }

  function handleAddRemove(action, fieldUuId) {
    let sourceRecordTemplateFields = [];
    if (action === "add") {
      sourceRecordTemplateFields = [
        ..._.orderBy(selectedFields, ["fieldOrder"], ["asc"]).map((f, i) => {
          return {
            fieldUuId: f.uuId,
            fieldOrder: i + 1,
          };
        }),
        {
          fieldUuId: fieldUuId,
          fieldOrder: selectedFields ? selectedFields.length + 1 : 1,
        },
      ];
    } else if (action === "remove") {
      const filteredSourceRecordTemplateFields = _.filter(
        selectedFields,
        function (f) {
          if (f.uuId !== fieldUuId) return true;
        }
      );

      sourceRecordTemplateFields = filteredSourceRecordTemplateFields.map(
        (f, i) => {
          return {
            fieldUuId: f.uuId,
            fieldOrder: i + 1,
          };
        }
      );
    }

    handleUpdate(sourceRecordTemplateFields);
  }

  function handleRemove(action, fieldUuId) {
    if (action === "remove") handleAddRemove("remove", fieldUuId);
  }

  function handleUpdate(sourceRecordTemplateFields) {
    dispatch(
      updateSourceRecordTemplateFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: templateId,
        formData: {
          sourceRecordTemplateFields: sourceRecordTemplateFields,
        },
      })
    );
  }

  useEffect(() => {
    const tmpSelectedFields = transformSelectedFields(
      _.get(data, "sourceRecordTemplateFields")
    );

    setSelectedFields([...tmpSelectedFields]);
  }, [data]);

  useEffect(() => {
    if (
      statusSourceFields &&
      statusDerivedFields &&
      statusSourceFields.result === "success" &&
      statusSourceFields.method === "getSourceFields" &&
      statusDerivedFields.result === "success" &&
      statusDerivedFields.method === "getSourceDerivedFields"
    ) {
      const tmpSelectedFields = transformSelectedFields(
        _.get(data, "sourceRecordTemplateFields")
      );

      const tmpAvailableFields = transformAvailableFields(
        allFieldsData,
        tmpSelectedFields
      );

      setAvailableFields([...tmpAvailableFields]);
      dispatch(clearSourceFieldsStatus());
      dispatch(clearDerivedFieldsStatus());
      setAvailableFieldsLoaded(true);
    }
  }, [statusSourceFields, statusDerivedFields]);

  useEffect(() => {
    if (availableFields.length > 0) setAvailableFieldsLoaded(true);
  }, [availableFields]);

  useEffect(() => {
    const tmpAvailableFields = transformAvailableFields(
      allFieldsData,
      selectedFields
    );

    setAvailableFields([...tmpAvailableFields]);
    setAvailableFieldsLoaded(true);
  }, [selectedFields]);

  useEffect(() => {
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "54px",
          gap: theme.spacing(1),
        }}
      >
        <Button sx={{ fontSize: "12px" }} size="small">
          REMOVE ALL
        </Button>
        <Button sx={{ fontSize: "12px" }} variant="contained" size="small">
          ADD ALL
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: `calc(100% - 54px)`,
          border: theme.general.border1,
        }}
      >
        <Box
          sx={{
            flexBasis: "30%",
            display: "flex",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          {availableFieldsLoaded &&
            _.isArray(availableFields) &&
            availableFields.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableFields, ["label"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`field-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "12px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAddRemove("add", fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <Box sx={{ pr: theme.spacing(1) }}>
                            <NtaiBadge
                              title={_.get(fd, "type") === 1 ? "S" : "D"}
                              fg="grey"
                              bg={grey[100]}
                            />
                          </Box>

                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.label}
                              </Typography>
                            }
                            secondary={fd.name}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
        </Box>
        <Box
          sx={{
            flexBasis: "70%",
            display: "flex",
          }}
        >
          <Scrollbar>
            <Box sx={{ p: theme.spacing(2) }}>
              {selectedFields &&
                _.isArray(selectedFields) &&
                selectedFields.length > 0 && (
                  <NtaiDnd3
                    id="uuId"
                    label="text"
                    items={selectedFields}
                    handleReorder={handleReorder}
                    direction="horizontal"
                    secondaryActions={[{ value: "remove", icon: faClose }]}
                    handleSecondaryAction={handleRemove}
                  />
                )}
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </Box>
  );
}
