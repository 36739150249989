import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const exportSourceConfig = createAsyncThunk(
  "sourceExportImport/exportSourceConfig",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/export`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const importSourceConfig = createAsyncThunk(
  "sourceExportImport/importSourceConfig",
  async (values, { rejectWithValue }) => {
    try {
      const { formData, config } = values;
      const response = await server.post(
        `/sourcedefs/import`,
        formData,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceExportImportSlice = createSlice({
  name: "sourceExportImport",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [exportSourceConfig.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "exportSourceConfig" };
    },
    [exportSourceConfig.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "exportSourceConfig",
        message: action.payload.message,
      };
    },
    [importSourceConfig.fulfilled]: (state, action) => {
      // state.data = {
      //   ...state.data,
      //   [action.payload.uuId]: action.payload,
      // };
      state.status = { result: "success", method: "importSourceConfig" };
    },
    [importSourceConfig.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "importSourceConfig",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceExportImportSlice.actions;

export default sourceExportImportSlice.reducer;
