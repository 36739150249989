import { grey, lime } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils/NtaiUtils";

const { lighten } = require("@mui/material");
const _ = require("lodash");

export const CHART_LEFT_CLICK_ACTION = 1;

class NtaiEChartUtil {
  static isNotData(data) {
    if (!data || (_.isArray(data) && data.length === 0) || _.isEmpty(data))
      return true;
    else return false;
  }

  static getFieldTypesFromMetadata(metadata, fieldTypes) {
    let result = {};
    const fields = _.filter(Object.values(metadata["fields"]), function (f) {
      if (fieldTypes.includes(f.field.dataTypeCode)) return true;
      else return false;
    });

    result = {
      categoryFields: _.filter(fields, { "field.dataTypeCode": 1 }).length,
    };

    return result;
  }

  static paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  static getSourceWidgetFieldUuId(metadata, fieldOrder) {
    const field = _.filter(Object.values(metadata["fields"]), {
      fieldOrder: fieldOrder,
    })[0];

    return field["uuId"];
  }

  static getSourceField(metadata, fieldOrder) {
    const field = _.filter(Object.values(metadata["fields"]), {
      fieldOrder: fieldOrder,
    })[0];

    return field;
  }

  static getCatSourceField(metadata) {
    console.log("MEtadata: ", metadata);
    let fields = _.filter(Object.values(metadata["fields"]), function (o) {
      if (
        o["field"]["dataTypeCode"] === 1 ||
        o["field"]["esDataTypeCode"] === 4
      )
        return true;
      else return false;
    });

    return fields;
  }

  static getSourceFieldBySizeFg(metadata) {
    let fields = _.filter(Object.values(metadata["fields"]), {
      sizeFg: 1,
    });

    if (fields.length > 0) return fields[0];
    else return null;
  }

  static getSourceFieldByAxisFg(metadata, axisFg) {
    let field = null;
    if (axisFg === 1)
      field = _.filter(Object.values(metadata["fields"]), {
        xAxisFg: 1,
      })[0];
    else
      field = _.filter(Object.values(metadata["fields"]), {
        yAxisFg: 1,
      })[0];

    return field;
  }

  static getSourceWidgetFilterFieldsForCharts(
    fieldOrders,
    params,
    metadata,
    fieldOperatorCode
  ) {
    return fieldOrders.map((fieldOrder, i) => {
      return {
        sourceWidgetFieldUuId: this.getSourceWidgetFieldUuId(
          metadata,
          fieldOrder
        ),
        fieldOperatorCode: fieldOperatorCode,
        fieldValue: params[fieldOrder === 1 ? "name" : "seriesName"],
      };
    });
  }

  static getMarker(color) {
    return (
      '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' +
      color +
      ';"></span>'
    );
  }

  static getColorPalette(theme, numOfColors) {
    const curThemeName = localStorage.getItem("appTheme") || "NtaiBlueTheme";

    let result = [];
    let colors = null;

    if (curThemeName === "NtaiDarkTheme")
      colors = ["#609FEC", "#F8A946", "#5Fd17F", grey[500], lime[500]];
    // FCBD55, F28127. green: 5Fd17F, 77F093
    else
      colors = [
        theme.colors.chart.light,
        lighten(theme.colors.chart.main, 0.5),
        lighten(theme.colors.chart.dark, 0.7),
      ];

    let l = 0.4;
    let c = 0;
    loop1: for (let i = 0; i < numOfColors; i++) {
      loop2: for (let j = 0; j < colors.length; j++) {
        const baseColor = colors[j];
        // const baseColor = theme.colors.chart.light;
        const color = lighten(baseColor, l);
        result.push(color);
        c = c + 1;

        if (c === numOfColors) break loop1;
      }
      l = l + 0.2;
      if (l === 0.8) l = 0.4;
    }

    return result;
  }

  static getRevisedOptionOnLeftClick(chartId, params, option, theme) {
    console.log("option: ", option);
    if (chartId === "bar-vertical") {
      option.series[0].data.forEach((data, index) => {
        if (index === params.dataIndex) {
          if (data["isChecked"]) {
            data["isChecked"] = false;
            data.itemStyle.color = theme.colors.chart.light;
          } else {
            data["isChecked"] = true;
            data.itemStyle.color = theme.colors.chart.light;
          }
        } else {
          if (data["isChecked"]) {
            data["isChecked"] = true;
            data.itemStyle.color = theme.colors.chart.light;
          } else {
            data.itemStyle.color = lighten(theme.colors.chart.light, 0.7);
          }
        }
      });
    }

    return option;
  }

  static getTooltipContent(data) {
    let result = "";
    if (_.has(data, "title")) {
      if (_.has(data["title"], "marker")) {
        result = this.getMarker(data["title"]["marker"]);
      }

      result =
        '<tr><td colspan="2">' +
        result +
        "<b>" +
        data["title"]["text"] +
        "</b></td></tr>";
    }

    if (_.has(data, "values")) {
      let tableRows = [];
      data["values"].forEach((v, i) => {
        tableRows.push(
          "<tr><td>" +
            v["key"] +
            '</td><td text-align="right"><b>' +
            v["value"] +
            "</b></td></tr>"
        );
      });

      result =
        '<table width="100%">' + result + tableRows.join(" ") + "</table>";
    }

    return result;
  }

  static getItemStyleColor(fgbg, value, defaultColor, metadata) {
    let result = defaultColor;

    // console.log("Color selection: ", fgbg, value, defaultColor, metadata);
    if (!_.isEmpty(metadata["fields"])) {
      Object.values(metadata["fields"]).forEach((f) => {
        if (_.isArray(f["colorSchemes"]) && f["colorSchemes"].length > 0) {
          f["colorSchemes"].forEach((cs) => {
            if (
              cs.hasOwnProperty("exactValue") &&
              cs["exactValue"] &&
              cs["exactValue"].length > 0
            ) {
              if (_.isString(value)) {
                if (value.toLowerCase() === cs["exactValue"].toLowerCase()) {
                  result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
                  return;
                }
              } else if (_.isNumber(value)) {
                if (value === cs["exactValue"] * 1) {
                  result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
                  return;
                }
              }
            } else {
              if (
                _.isNumber(parseFloat(cs["ruleGtValue"])) &&
                _.isNumber(parseFloat(cs["ruleLtValue"])) &&
                _.isNumber(value)
              ) {
                if (
                  parseFloat(cs["ruleGtValue"]) < value &&
                  parseFloat(cs["ruleLtValue"]) > value
                ) {
                  result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
                  return;
                }
              } else if (
                _.isString(cs["ruleGtValue"]) &&
                _.isString(cs["ruleGtValue"]) &&
                _.isString(value)
              ) {
                if (cs["ruleGtValue"] < value && cs["ruleLtValue"] > value) {
                  result = fgbg === "fg" ? cs["hexFgCode"] : cs["hexBgCode"];
                  return;
                }
              }
            }
          });
        }
      });
    }

    return result;
  }

  static evalValue(value, valScript) {
    return eval(valScript);
  }

  static getTooltipDate(ms, periodType) {
    let result = NtaiUtils.millisecondsToDate(ms);

    switch (periodType) {
      case 1:
        result = NtaiUtils.getDateYearFromMs(ms);
        break;
      case 2:
        result = NtaiUtils.getDateQuarterStringFromMs(ms);
        break;
      case 3:
        result = NtaiUtils.getDateMonthStringFromMs(ms);
        break;
      case 4:
        result = NtaiUtils.getDateWeekStringFromMs(ms);
        break;
      default:
        break;
    }

    return result;
  }
}

export default NtaiEChartUtil;
