import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { clearStatus, createPackage, setActiveId } from "./store/packagesSlice";
import PackageForm from "./PackageForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import History from "@ntai/@history";

export default function PackageCreate({ handleCancel, redirectToEdit }) {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.models.packagesSlice.status);
  const activeId = useSelector((state) => state.models.packagesSlice.activeId);

  function redirectToEdit() {
    History.push(`/models/packages/${activeId}/edit`);
  }

  function handleSave(formData) {
    dispatch(createPackage(formData));
  }

  function handleCancel() {
    History.push("/models/packages");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createPackage" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      redirectToEdit();
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(null));
  }, []);

  return (
    <NtaiPage title="Add Package">
      <NtaiForm>
        <PackageForm
          action="add"
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
