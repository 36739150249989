import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import NtaiCircularProgress from "../progress/NtaiCircularProgress";
import { CloseOutlined } from "@mui/icons-material";
const _ = require("lodash");

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

export default function NtaiInfiniteScroll2({
  data,
  fetchMoreData,
  generateItems,
  height,
  hasMore,
}) {
  const theme = useTheme();
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems([...data]);
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        height={height}
        hasMore={hasMore}
        loader={
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              p: theme.spacing(1),
            }}
          >
            {hasMore ? <CircularProgress size={18} /> : null}
          </Box>
        }
      >
        {generateItems(items)}
      </InfiniteScroll>
    </div>
  );
}
