import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUserDatasetSourceViews = createAsyncThunk(
  "userDatasetSourceViews/getUserDatasetSourceViews",
  async (datasetId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/library/datasets/${datasetId}/source-views`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserDatasetSourceView = createAsyncThunk(
  "userDatasetSourceViews/getUserDatasetSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { datasetId, uuId } = values;
      const response = await server.get(
        `/library/datasets/${datasetId}/source-views/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserDatasetSourceView = createAsyncThunk(
  "userDatasetSourceViews/createUserDatasetSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { datasetId, formData } = values;
      const response = await server.post(
        `/library/datasets/${datasetId}/source-views`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserDatasetSourceViewDefaultFg = createAsyncThunk(
  "userDatasetSourceViews/updateUserDatasetSourceViewDefaultFg",
  async (values, { rejectWithValue }) => {
    try {
      const { datasetId, uuId, formData } = values;
      const response = await server.patch(
        `/library/datasets/${datasetId}/source-views/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserDatasetSourceView = createAsyncThunk(
  "userDatasetSourceViews/deleteUserDatasetSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { datasetId, uuId } = values;
      const response = await server.delete(
        `/library/datasets/${datasetId}/source-views/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userDatasetSourceViewsSlice = createSlice({
  name: "userDatasetSourceViews",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserDatasetSourceViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getUserDatasetSourceViews",
      };
    },
    [getUserDatasetSourceViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDatasetSourceViews",
        message: action.payload.message,
      };
    },
    [getUserDatasetSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "getUserDatasetSourceView",
      };
    },
    [getUserDatasetSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDatasetSourceView",
        message: action.payload.message,
      };
    },
    [createUserDatasetSourceView.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createUserDatasetSourceView",
      };
    },
    [createUserDatasetSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserDatasetSourceView",
        message: action.payload.message,
      };
    },

    [updateUserDatasetSourceViewDefaultFg.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "updateUserDatasetSourceViewDefaultFg",
      };
    },
    [updateUserDatasetSourceViewDefaultFg.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUserDatasetSourceViewDefaultFg",
        message: action.payload.message,
      };
    },

    [deleteUserDatasetSourceView.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteUserDatasetSourceView",
      };
    },
    [deleteUserDatasetSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUserDatasetSourceView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = userDatasetSourceViewsSlice.actions;

export default userDatasetSourceViewsSlice.reducer;
