import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceDatasets,
  clearStatus,
} from "../../pl/stage/dataset/store/sourceDatasetsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import SourceOutElasticFields from "./fields/SourceOutElasticFields";
import SourceOutElasticLookupList from "./lookup/SourceOutElasticLookupList";
import SourceOutDatasetKeys from "../keys/SourceOutDatasetKeys";
import SourceOutDatasetScdPolicies from "../scd-policy/SourceOutDatasetScdPolicies";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function SourceOutStoreElasticForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [loaded, setLoaded] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceDatasetsDataMap = useSelector(
    (state) => state.sources.sourceDatasetsSlice.data
  );

  const sourceDatasetsSliceStatus = useSelector(
    (state) => state.sources.sourceDatasetsSlice.status
  );

  const sourceDatasetsData =
    sourceDatasetsDataMap && !_.isEmpty(sourceDatasetsDataMap)
      ? Object.values(sourceDatasetsDataMap)
      : [];

  const sourceDatasetOptions = NtaiUtils.getSelectOptions(
    sourceDatasetsData,
    "uuId",
    "name"
  );

  function onHandleSave() {
    if (action === "add" || (action === "edit" && editMode)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("sourceOutStoreElasticForm")
      );

      handleFormSubmit(sanitizedFormData);
    }
  }

  useEffect(() => {
    if (
      sourceDatasetsSliceStatus &&
      sourceDatasetsSliceStatus.method === "getSourceDatasets" &&
      sourceDatasetsSliceStatus.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [sourceDatasetsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourcePipelineId: "*",
        sourceStageId: "*",
      })
    );

    reset({ sourceOutStoreElasticForm: action === "edit" ? formData : {} });
    setValue(
      "sourceOutStoreElasticForm.datasetUuId",
      action === "edit" ? formData.datasetUuId : null
    );
  }, []);

  return (
    <NtaiTabs>
      <Box label="DATASET">
        {loaded &&
          sourceDatasetOptions &&
          _.isArray(sourceDatasetOptions) &&
          sourceDatasetOptions.length > 0 && (
            <NtaiFormPanel
              header="Select Dataset"
              subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
              handleSave={onHandleSave}
              handleCancel={handleCancel}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {action === "add" || (action === "edit" && editMode) ? (
                  <NtaiSelectField
                    name="sourceOutStoreElasticForm.datasetUuId"
                    label="Select Dataset"
                    options={sourceDatasetOptions}
                    // isDisabled={_.isEmpty(formData) ? false : true}
                    rules={{ required: "Dataset is required" }}
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography variant="h6">
                      Dataset:{" "}
                      {_.get(formData, "datasetName") && formData.datasetName}
                    </Typography>
                    <Link
                      sx={{ textDecoration: "underline" }}
                      href="#"
                      onClick={() => setEditMode(true)}
                    >
                      Update
                    </Link>
                  </Box>
                )}
              </Box>
            </NtaiFormPanel>
          )}
        {loaded &&
          sourceDatasetOptions &&
          _.isArray(sourceDatasetOptions) &&
          sourceDatasetOptions.length === 0 && (
            <NtaiEmptyMessage
              header="No datasets available"
              subheader="You can create datasets for target store in source pipeline"
            />
          )}
        {!loaded && <NtaiCircularProgress />}
      </Box>
      <Box label="FIELDS" disabled={_.isEmpty(formData) ? true : false}>
        <SourceOutElasticFields
          sourceDatasetId={_.get(formData, "datasetUuId")}
          handleCancel={handleCancel}
        />
      </Box>
      <Box
        label="KEYS AND SCD FIELDS"
        disabled={_.isEmpty(formData) ? true : false}
      >
        <SourceOutDatasetKeys
          format={1}
          sourceOutDatasetUuId={_.get(formData, "uuId")}
        />
      </Box>
      <Box label="SCD POLICIES" disabled={_.isEmpty(formData) ? true : false}>
        <SourceOutDatasetScdPolicies
          sourceOutDatasetUuId={_.get(formData, "uuId")}
        />
      </Box>
      <Box label="LOOKUPS" disabled={_.isEmpty(formData) ? true : false}>
        <SourceOutElasticLookupList />
      </Box>
    </NtaiTabs>
  );
}
