import {
  faCommentAlt,
  faCommentPen,
  faNote,
  faUserEdit,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { SearchSourceContext } from "../../SearchSource";
import SearchNotesDrawer from "../../../notes/SearchNotesDrawer";
import { SRH_SEARCH_TYPE_CODE } from "app/main/constants/NtaiCodelistConstants";
import { SearchContext } from "../../../SearchEdit";
const _ = require("lodash");

function fetchSourceName(searchType, searchSourceData) {
  let returnValue = "None";
  if (searchType === SRH_SEARCH_TYPE_CODE.SEARCH) {
    const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
    const selectedSearchSourceData =
      _.get(searchSourceData, "selectedFg") === 1
        ? searchSourceData
        : _.get(
            _.get(searchSourceData, "mapChildSearchSources"),
            searchSourceId
          );
    returnValue = _.get(
      selectedSearchSourceData,
      "searchSourceDefinition.name"
    );
  } else if (
    [
      SRH_SEARCH_TYPE_CODE.ANALYTICS_STANDARD,
      SRH_SEARCH_TYPE_CODE.DASHBOARD,
    ].includes(searchType)
  ) {
    returnValue = _.get(searchSourceData, "searchSourceDefinition.name");
  } else if (searchType === SRH_SEARCH_TYPE_CODE.ANALYTICS_DATASET) {
    returnValue = _.get(searchSourceData, "sourceDomainName");
  }

  return returnValue;
}

export default function SearchSourceNotes() {
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);
  const [notesLevelCode, setNotesLevelCode] = useState();
  const [notesContext, setNotesContext] = useState();
  const { searchData } = React.useContext(SearchContext);

  const { searchSourceData } = React.useContext(SearchSourceContext);

  function handleNotesDrawerOpen(level) {
    setNotesLevelCode(level);
    setNotesContext(fetchSourceName(searchData["typeCode"], searchSourceData));
    setNotesDrawerOpen(true);
  }

  function handleNotesDrawerClose() {
    setNotesLevelCode(null);
    setNotesContext(null);
    setNotesDrawerOpen(false);
  }

  return (
    <React.Fragment>
      <Tooltip title="Notes">
        <IconButton onClick={() => handleNotesDrawerOpen(2)}>
          <FontAwesomeIcon size="2xs" icon={faCommentPen} />
        </IconButton>
      </Tooltip>

      {notesDrawerOpen && notesContext && (
        <Drawer
          PaperProps={{
            style: {
              position: "absolute",
              top: 48, // 139
              height: `calc(100% - 48px)`,
              width: "360px",
            },
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={notesDrawerOpen}
          onClose={handleNotesDrawerClose}
          anchor="right"
          BackdropProps={{ invisible: true }}
        >
          {notesLevelCode && [2].includes(notesLevelCode) && notesContext && (
            <SearchNotesDrawer
              levelCode={notesLevelCode}
              notesContext={notesContext}
              handleClose={handleNotesDrawerClose}
            />
          )}
        </Drawer>
      )}
    </React.Fragment>
  );
}
