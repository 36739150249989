import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createSearch } from "../../store/searchSlice";
import history from "@ntai/@history";
import SearchDashboardEntityWrapper from "./entities/SearchDashboardEntityWrapper";
import SearchDashboardViewGroupsWrapper from "./view-groups/SearchDashboardViewGroupsWrapper";

const _ = require("lodash");

export const SearchDashboardInitContext = React.createContext();

export default function SearchDashboardInit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedEntityFilter, setSelectedEntityFilter] = useState("All");
  const [selectedViewGroupFilter, setSelectedViewGroupFilter] = useState("All");
  const [selectedEntities, setSelectedEntities] = useState(new Set([]));
  const [selectedEntityDetails, setSelectedEntityDetails] = useState();
  const [selectedViews, setSelectedViews] = useState(new Set([]));
  const [currentStep, setCurrentStep] = useState("select-entities");
  const [entitiesQueryText, setEntitiesQueryText] = useState();
  const [dashboardsQueryText, setDashboardsQueryText] = useState();

  //   const data = useSelector((state) => state.search.searchInitSlice.data);
  //   const status = useSelector((state) => state.search.searchInitSlice.status);
  const statusSearch = useSelector((state) => state.search.searchSlice.status);
  const activeId = useSelector((state) => state.search.searchSlice.activeId);

  function handleCreateSearch(sourceUuIds) {
    let searchSources = [];
    if (sourceUuIds && sourceUuIds.length > 0) {
      sourceUuIds.forEach((sourceUuId) => {
        searchSources.push({
          searchSourceDefinition: { sourceUuId: sourceUuId },
        });
      });

      if (
        selectedEntities.size > 0 &&
        selectedViews.size > 0 &&
        sourceUuIds.length > 0
      ) {
        dispatch(
          createSearch({
            typeCode: 3,
            objectEntityUuIds: [...selectedEntities],
            objectDashboardUuIds: [...selectedViews],
            searchSources: searchSources,
          })
        );
      }
    }
  }

  useEffect(() => {
    if (
      statusSearch &&
      statusSearch.result === "success" &&
      statusSearch.method === "createSearch"
    ) {
      history.push(`/search/${activeId}/edit`);
    }
  }, [statusSearch]);



  return (
    <SearchDashboardInitContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        selectedEntities,
        setSelectedEntities,
        selectedViews,
        setSelectedViews,
        selectedEntityFilter,
        setSelectedEntityFilter,
        selectedViewGroupFilter,
        setSelectedViewGroupFilter,
        entitiesQueryText,
        setEntitiesQueryText,
        dashboardsQueryText,
        setDashboardsQueryText,
        selectedEntityDetails,
        setSelectedEntityDetails,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexBasis: "100%",
          width: "100%",
        }}
      >
        {currentStep === "select-entities" && <SearchDashboardEntityWrapper />}
        {currentStep === "select-views" && (
          <SearchDashboardViewGroupsWrapper createSearch={handleCreateSearch} />
        )}
      </Box>
    </SearchDashboardInitContext.Provider>
  );
}
