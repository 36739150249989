import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React, { useEffect, useState } from "react";
import SourceVersionCreateForm from "./SourceVersionCreateForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import { createSourceVersion, clearStatus } from "../../store/sourcesSlice";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";

export default function SourceVersionCreateWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const status = useSelector((state) => state.sources.sourcesSlice.status);
  const activeId = useSelector((state) => state.sources.sourcesSlice.activeId);

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  function handleSave(formData) {
    dispatch(
      createSourceVersion({
        sourceDefId: sourceDefId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceVersion" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      handleDialogClose();
      history.push(`/sourcedefs/${sourceDefId}/sources/${activeId}/manage`);
      enqueueSnackbar("Source version created successfully.", {
        variant: "success",
      });
    }
  }, [status]);

  return (
    <React.Fragment>
      <Tooltip title="Create Version">
        <Button
          color="inherit"
          sx={{ fontSize: "11px", fontWeight: 700 }}
          size="small"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => handleDialogOpen()}
        >
          CREATE VERSION
        </Button>
      </Tooltip>

      <NtaiDialog
        title="Create new version"
        size="sm"
        open={open}
        handleDialogClose={handleDialogClose}
      >
        <NtaiForm>
          <SourceVersionCreateForm
            handleSave={handleSave}
            handleCancel={handleDialogClose}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
