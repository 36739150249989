import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import SplitPane, { Pane } from "split-pane-react";
import SourceInStoreDatasetDBBrowser from "./browser/SourceInStoreDatasetDBBrowser";
import { useDispatch } from "react-redux";
import SourceInStoreDatasetQueryEditor from "./query/SourceInStoreDatasetQueryEditor";
import SourceInStoreDatasetQueryResult from "./query/SourceInStoreDatasetQueryResult";
const _ = require("lodash");

export default function SourceInStoreDatasetQueryForm({
  formData,
  handleSave,
  handleRun,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sizes, setSizes] = useState(["30%", "auto"]);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <SplitPane split="vertical" sizes={sizes} onChange={setSizes}>
        <Pane
          style={{ borderRight: theme.general.border1 }}
          initialSize="25%"
          minSize="5%"
        >
          <SourceInStoreDatasetDBBrowser />
        </Pane>
        <Pane initialSize="75%" minSize="5%">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <SourceInStoreDatasetQueryEditor
              formData={formData}
              handleSave={handleSave}
              handleRun={handleRun}
            />
            <SourceInStoreDatasetQueryResult />
          </Box>
        </Pane>
      </SplitPane>
    </Box>
  );
}
