import History from "@ntai/@history";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import { deleteSource, getSources, setActiveId } from "./store/sourcesSlice";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "sourceVersion",
    numeric: false,
    disablePadding: false,
    label: "Version",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function SourceList(props) {
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = props.match.params.sourcedefid;
  const dispatch = useDispatch();

  const sourceDefinitionData = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  )[sourceDefId];

  const dataMap = useSelector((state) => state.sources.sourcesSlice.data);
  const data = Object.values(dataMap);

  const status = useSelector((state) => state.sources.sourcesSlice.status);

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "archive") {
        dispatch(deleteSource(id));
      }
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  function handleRowOtherAction(rowAction, sourceUuId) {
    dispatch(setActiveId(sourceUuId));
    if (rowAction === "objects") History.push(`/sources/${sourceUuId}/objects`);
    else if (rowAction === "templates")
      History.push(`/sources/${sourceUuId}/templates`);
    else if (rowAction === "views")
      History.push(`/sources/${sourceUuId}/views`);
    else if (rowAction === "thesauruses")
      History.push(`/sources/${sourceUuId}/thesauruses`);
    else if (rowAction === "linkedsources")
      History.push(`/sources/${sourceUuId}/linkedsources`);
  }

  useEffect(() => {
    dispatch(getSources(sourceDefId));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_MIGRATION_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.GL_FREQUENCY_UNIT_CODE));
  }, []);

  return (
    <NtaiPage
      padding={2}
      title={`${_.get(sourceDefinitionData, "name")} Versions`}
      back="/sourcedefs"
    >
      <NtaiCrudTable
        helpText="A source can have multiple versions with different configurations and mappings."
        rows={data}
        headCells={headCells}
        toolbarActions={toolbarActions}
        keyColumn="uuId"
        path="/sources"
        handleToolbarAction={handleToolbarAction}
        title="Source"
        rowOtherActions={[
          { label: "Manage Views", value: "views" },
          { label: "Manage Templates", value: "templates" },
          { label: "Manage Objects", value: "objects" },
          { label: "Manage Thesauruses", value: "thesauruses" },
          { label: "Manage Linked Sources", value: "linkedsources" },
        ]}
        handleRowOtherAction={handleRowOtherAction}
      />
    </NtaiPage>
  );
}
