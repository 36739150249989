import SourceLinkedSourceCreate from "./SourceLinkedSourceCreate";
import SourceLinkedSourceEdit from "./SourceLinkedSourceEdit";
import SourceLinkedSourceList from "./SourceLinkedSourceList";

const SourceLinkedSourceConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/linkedsources",
      exact: true,
      component: SourceLinkedSourceList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/linkedsources/create",
      exact: true,
      component: SourceLinkedSourceCreate,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/linkedsources/:id/edit",
      exact: true,
      component: SourceLinkedSourceEdit,
    },
  ],
};

export default SourceLinkedSourceConfig;
