import { Box, Typography, useTheme } from "@mui/material";
import NtaiAddRecordCard from "@ntai/components/cards/NtaiAddRecordCard";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiActionsPanel from "@ntai/components/panels/NtaiActionsPanel";
import { SRC_SOURCE_STORE_FORMAT } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createSourceOutStore,
  deleteSourceOutStore,
  getSourceOutStores,
  setActiveId,
} from "./store/sourceOutStoresSlice";
import SourceOutStoreForm from "./SourceOutStoreForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import History from "@ntai/@history";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

const dialogSize = {
  1: "sm",
  2: "md",
};

export default function SourceOutStoresList() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [selectedOutStoreCode, setSelectedOutStoreCode] = useState();
  const [open, setOpen] = useState(false);
  const [openOutStoreESDialog, setOpenOutStoreESDialog] = useState(false);
  const [openOutStoreDBDialog, setOpenOutStoreDBDialog] = useState(false);
  const [currentId, setCurrentId] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceOutStoresSlice.data
  );
  const elasticOutStore = _.get(dataMap, SRC_SOURCE_STORE_FORMAT.ELASTIC);
  const dbOutStore = _.get(dataMap, SRC_SOURCE_STORE_FORMAT.RDBMS);

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const status = useSelector(
    (state) => state.sources.sourceOutStoresSlice.status
  );

  const params = { sourceDefId: sourceDefId, sourceId: sourceId };

  function handleAdd() {
    setAction("add");
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  function handleOutStoreESDialogOpen() {
    setOpenOutStoreESDialog(true);
  }

  function handleOutStoreESDialogClose() {
    setOpenOutStoreESDialog(false);
  }

  function handleOutStoreDBDialogOpen() {
    setOpenOutStoreDBDialog(true);
  }

  function handleOutStoreDBDialogClose() {
    setOpenOutStoreDBDialog(false);
  }

  function handleFormSubmit(formData) {
    dispatch(createSourceOutStore({ ...params, formData: formData }));
  }

  function handleHeaderAction(actionName, id) {
    if (actionName === "delete") {
      dispatch(
        deleteSourceOutStore({
          ...params,
          uuId: _.get(dataMap[id], "uuId"),
          formatCode: id,
        })
      );
    }
    if (actionName === "edit") {
      setAction("edit");
      setCurrentId(id);
      handleDialogOpen();
    }
  }

  function manageOutStore(actionName, id) {
    setSelectedOutStoreCode(id);
    dispatch(setActiveId(_.get(dataMap[id], "uuId")));
    if (id === 1)
      History.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/elastic`
      );
    if (id === 2)
      History.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${_.get(
          dataMap[id],
          "uuId"
        )}/dbdatasets`
      );
  }

  function renderSourceOutStores() {
    let result = [];
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((item, index) => {
        result.push(
          <NtaiActionsPanel
            key={index}
            id={item.formatCode}
            title={item.formatDecode}
            subheader={
              _.has(item, "datasource") && item["datasource"]
                ? item["datasource"]["name"]
                : "System store"
            }
            headerActions={[
              { value: "edit", label: "Edit" },
              { value: "delete", label: "Delete" },
            ]}
            handleHeaderAction={handleHeaderAction}
            width="290px"
            actions={[{ value: "manage", label: "Manage Store" }]}
            handleAction={manageOutStore}
          >
            <Typography variant="subtitle2">{item.formatDecode}</Typography>
          </NtaiActionsPanel>
        );
      });

      return result;
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceOutStores" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "createSourceOutStore"
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceOutStores({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <NtaiPage
      title={`${_.get(sourceDefData, "name")} / Target Stores`}
      back={`/sourcedefs/${sourceDefId}/smwrapper`}
    >
      {loaded && (
        <NtaiPanel
          width="100%"
          header="Manage Target Stores"
          subheader="Source views provide easy to use templates to render source data in user specified templates"
        >
          <Box
            sx={{
              paddingTop: theme.spacing(1),
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              gap: theme.spacing(1),
            }}
          >
            <NtaiAddRecordCard
              handleClick={handleAdd}
              border
              width="290px"
              height="auto"
            >
              Add new store
            </NtaiAddRecordCard>
            {renderSourceOutStores()}
          </Box>
        </NtaiPanel>
      )}
      {!loaded && <NtaiCircularProgress />}
      <NtaiDialog
        open={open}
        handleDialogClose={handleDialogClose}
        title="Add Destination Store"
        size="sm"
      >
        <NtaiForm>
          {(action === "add" || (action === "edit" && currentId)) && (
            <SourceOutStoreForm
              action={action}
              formData={action === "edit" ? dataMap[currentId] : null}
              handleCancel={handleDialogClose}
              handleFormSubmit={handleFormSubmit}
            />
          )}
        </NtaiForm>
      </NtaiDialog>
    </NtaiPage>
  );
}
