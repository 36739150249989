import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils";
import { getSourceAdjudicationImportCsvSchemes } from "app/main/pages/core/sourcedefinition/source/adjudication/import-schemes/store/sourceAdjudicationImportCsvSchemesSlice";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToolsAssessmentImportContext } from "./ToolsAssessmentImportCreate";
const _ = require("lodash");

export default function ToolsAssessmentImportForm1(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const { handleContinue, handleCancel } = props;

  const { setLocalFormData } = React.useContext(ToolsAssessmentImportContext);

  const sourceAdjCsvSchemeDataMap = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.data
  );

  const sourceAdjCsvSchemeData =
    sourceAdjCsvSchemeDataMap && !_.isEmpty(sourceAdjCsvSchemeDataMap)
      ? Object.values(sourceAdjCsvSchemeDataMap)
      : [];

  const sourceAdjCsvSchemeOptions = NtaiUtils.getSelectOptions(
    _.map(sourceAdjCsvSchemeData, function (o) {
      return {
        name: o.sourceMin["displayName"] + " - " + o["name"],
        uuId: o["uuId"],
      };
    }),
    "uuId",
    "name"
  );

  function onHandleContinue() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("toolsAssessmentImportForm1")
    );
    console.log("Form 1: ", sanitizedFormData);
    if (
      sanitizedFormData["name"] &&
      sanitizedFormData["name"].trim().length > 0 &&
      sanitizedFormData["sourceAdjudicationSchemeUuId"] &&
      sanitizedFormData["sourceAdjudicationSchemeUuId"].trim().length > 0
    ) {
      setLocalFormData({
        ...sanitizedFormData,
        adjObjectUuId:
          sourceAdjCsvSchemeDataMap[
            sanitizedFormData["sourceAdjudicationSchemeUuId"]
          ]["adjObjectUuId"],
        sourceAdjudicationScheme:
          sourceAdjCsvSchemeDataMap[
            sanitizedFormData["sourceAdjudicationSchemeUuId"]
          ],
      });
      handleContinue(2);
    }
  }

  React.useEffect(() => {
    dispatch(
      getSourceAdjudicationImportCsvSchemes({
        sourceDefId: "*",
        sourceId: "*",
        sourceAdjudicationId: "*",
      })
    );
  }, []);

  return (
    <NtaiFormPanel
      header="Step 1: General Details"
      subheader="Enter name and description for the import operation, select source assessment import scheme and then click Next."
      handleSave={onHandleContinue}
      handleCancel={handleCancel}
      saveLabel="Continue"
    >
      <NtaiTextField
        name="toolsAssessmentImportForm1.name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="toolsAssessmentImportForm1.description"
        label="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
      <NtaiSelectField
        name="toolsAssessmentImportForm1.sourceAdjudicationSchemeUuId"
        label="Select Assessment Scheme*"
        options={sourceAdjCsvSchemeOptions}
      />
    </NtaiFormPanel>
  );
}
