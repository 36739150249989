import { Box, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import {
  updateSourceCriteria,
  clearStatus,
} from "../store/alertDefinitionsSlice";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
import SourceDatasetDateCriteria from "app/main/pages/shared/date-criteria/SourceDatasetDateCriteria";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AlertDefSourceCriteria({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { watch, getValues, setValue, reset } = useFormContext();
  const [criteria, setCriteria] = useState();
  const [esCriteria, setEsCriteria] = useState();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [fields, setFields] = useState();
  const [mergedFields, setMergedFields] = useState([]);

  const alertDefId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );
  const alertDefDataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );

  const alertDefSliceStatus = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );

  const alertDefData = _.get(alertDefDataMap, alertDefId);

  const alertDefSourceId = _.get(alertDefData, "alertDefSource.uuId");
  const sourceDefUuId = _.get(alertDefData, "alertDefSource.sourceDefUuId");
  const sourceUuId = _.get(alertDefData, "alertDefSource.sourceUuId");

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  const queryValue = _.get(alertDefData, "alertDefSource.sourceCriteria");

  function buildQuery(tree, config, jsonTree, formats) {
    setCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  function onHandleResetDateCriteria() {
    const revisedData = {
      alertDefSource: {
        sourceCriteria: criteria,
        esCriteria: esCriteria,
      },
    };

    revisedData["alertDefSource"] = {
      sourceCriteria: criteria,
      esCriteria: esCriteria,
      dateCriteriaTypeCode: null,
      dateCriteriaFieldUuId: null,
      dateCriteriaPeriodCode: null,
      dateCriteriaNumOfPeriods: null,
      dateCriteriaFrom: null,
      dateCriteriaTo: null,
    };

    dispatch(
      updateSourceCriteria({
        definitionId: alertDefId,
        alertDefSourceId: alertDefSourceId,
        formData: revisedData,
      })
    );
  }

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("alertDefSource")
    );
    const revisedData = {
      alertDefSource: {
        sourceCriteria: criteria,
        esCriteria: esCriteria,
      },
    };

    revisedData["alertDefSource"] = {
      sourceCriteria: criteria,
      esCriteria: esCriteria,
      dateCriteriaTypeCode: sanitizedFormData["dateCriteriaTypeCode"],
      dateCriteriaFieldUuId: sanitizedFormData["dateCriteriaFieldUuId"],
      dateCriteriaPeriodCode: sanitizedFormData["dateCriteriaPeriodCode"],
      dateCriteriaNumOfPeriods: sanitizedFormData["dateCriteriaNumOfPeriods"],
      dateCriteriaFrom:
        sanitizedFormData["dateCriteriaFrom"] &&
        sanitizedFormData["dateCriteriaFrom"].length > 0
          ? new Date(sanitizedFormData["dateCriteriaFrom"])
          : null,
      dateCriteriaTo:
        sanitizedFormData["dateCriteriaTo"] &&
        sanitizedFormData["dateCriteriaTo"].length > 0
          ? new Date(sanitizedFormData["dateCriteriaTo"])
          : null,
    };

    dispatch(
      updateSourceCriteria({
        definitionId: alertDefId,
        alertDefSourceId: alertDefSourceId,
        formData: revisedData,
      })
    );
  }

  useEffect(() => {
    if (
      alertDefSliceStatus &&
      alertDefSliceStatus.result === "success" &&
      alertDefSliceStatus.method === "updateSourceCriteria"
    ) {
      enqueueSnackbar(
        `Alert definition "${_.get(
          alertDefDataMap[alertDefId],
          "name"
        )}" criteria updated successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
    }
  }, [alertDefSliceStatus]);

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefUuId,
        sourceUuId,
        mergedFields
      );

      setFields({ ...tmpFields });
      setMergedFields(mergedFields);
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    dispatch(
      getSourceFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
    dispatch(
      getSourceDerivedFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
    setCriteria(alertDefData["alertDefSource"]["sourceCriteria"]);
    setEsCriteria(alertDefData["alertDefSource"]["esCriteria"]);
    // reset({
    //   alertDefSource: alertDefData["alertDefSource"],
    // });

    setValue("alertDefSource", alertDefData["alertDefSource"]);
  }, [alertDefData["alertDefSource"]]);

  return (
    <React.Fragment>
      {fieldsLoaded && _.isArray(mergedFields) && mergedFields.length > 0 && (
        <Box sx={{ display: "flex", width: "90%", height: "100%" }}>
          <Box
            sx={{
              flexBasis: "60%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <NtaiFormPanel
              header="Query Criteria"
              subheader="Specify alert criteria using source fields. Records qualifying criteria will be routed to the selected workflow."
              width="100%"
              handleSave={handleSave}
              handleCancel={handleCancel}
            >
              <NtaiQueryBuilder
                fields={fields}
                buildQuery={buildQuery}
                value={queryValue}
              />
            </NtaiFormPanel>
          </Box>
          <Box
            sx={{
              flexBasis: "40%",
              display: "flex",
              p: theme.spacing(2),
            }}
          >
            <SourceDatasetDateCriteria
              formName="alertDefSource"
              fields={mergedFields}
              formData={alertDefData["alertDefSource"]}
              handleResetDateCriteria={onHandleResetDateCriteria}
            />
          </Box>
        </Box>
      )}
      {!fieldsLoaded && <NtaiCircularProgress size={24} />}
    </React.Fragment>
  );
}
