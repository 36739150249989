import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Scrollbar from "@ntai/components/Scrollbar";
import { Box } from "@mui/material";
const _ = require("lodash");

function countDistinct(params) {
  let total = 0;
  let distinctItems = [];
  params.values.forEach((value) => {
    if (!distinctItems.includes(value)) distinctItems.concat(value);
  });

  return distinctItems.length;
}

function generateColumnDefs(fieldArray) {
  const colDefs = [];

  fieldArray.forEach((widgetField) => {
    colDefs.push({
      field: widgetField.field.name,
      rowGroup: widgetField.gridRowFieldGroupFg === 1 ? true : false,
      headerName: widgetField.field.label,
      pivot: widgetField.gridColFieldPivotFg === 1 ? true : false,
      aggFunc:
        widgetField.gridColFieldAggFuncCode > 1
          ? widgetField.gridColFieldAggFuncDecode
          : countDistinct,
    });
  });

  return colDefs;
}

const NtaiDataGrid = (props) => {
  const { widgetId, data, metadata } = props;
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const fieldArray = _.orderBy(
    Object.values(_.get(metadata, "fields")),
    ["fieldOrder"],
    ["asc"]
  );

  const [columnDefs, setColumnDefs] = useState(generateColumnDefs(fieldArray));

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: false,
      sortable: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  function cellClicked(p) {
    console.log("Change source records for ", p);
  }

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          suppressAggFuncInHeader={true}
          //   rowGroupPanelShow="always"
          onCellClicked={(p) => cellClicked(p)}
          rowData={data}
          pivotMode={false}
          rowStyle={{ fontSize: "12px" }}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          sideBar={true}
          //   onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default NtaiDataGrid;
