import React, { useEffect } from "react";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, useTheme } from "@mui/material";
import NtaiMuiAutoSuggestIB from "@ntai/components/autosuggest/NtaiMuiAutoSuggestIB";
import { SearchContext } from "../../SearchEdit";
import { useFormContext } from "react-hook-form";
const _ = require("lodash");

export default function SearchBar() {
  const theme = useTheme();
  const { searchData } = React.useContext(SearchContext);
  const { setValue, getValues, reset } = useFormContext();
  function handleChange(t1) {}

  function handleInputChange(t2) {}

  useEffect(() => {
    setValue(
      "searchEditForm.searchQueryTerms",
      _.get(searchData, "searchQueryTerms")
    );
  }, []);

  return (
    <Box
      sx={{
        flexBasis: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: theme.spacing(1),
      }}
    >
      {/* <NtaiAutoSuggest width="90%" /> */}

      <NtaiMuiAutoSuggestIB
        options={[]}
        name="searchEditForm.searchQueryTerms"
        optionLabelField="term"
        defaultValue={_.get(searchData, "searchQueryTerms")}
        label="Search"
        handleChange={handleChange}
        handleInputChange={handleInputChange}
      />

      <Button
        sx={{ fontSize: "10px" }}
        size="small"
        startIcon={<FontAwesomeIcon size="xs" icon={faSearch} />}
      >
        SEARCH
      </Button>
    </Box>
  );
}
