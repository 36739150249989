import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import NtaiBox from "../containers/NtaiBox";
import NtaiDecoTree from "./NtaiDecoTree";
import NtaiTreeView from "./NtaiTreeView";
import NtaiResourceTree from "./NtaiResourceTree";
import NtaiForm from "../forms/NtaiForm";
import NtaiDecoTree1 from "./NtaiDecoTree1";
import NtaiDecoTree2 from "./NtaiDecoTree2";
import NtaiTreeView1 from "./NtaiTreeView1";
import NtaiDecoTree3 from "./NtaiDecoTree3";

const data = {
  id: "root",
  name: "Parent",
  icon: "Root",
  counts: 12,
  children: [
    {
      id: "1",
      name: "Child - 1",
      icon: "Entity",
      counts: 4,
    },
    {
      id: "3",
      name: "Child - 3",
      icon: "Tree",
      counts: 3,
      children: [
        {
          id: "4",
          name: "Child - 4",
          icon: "Tree",
          counts: 6,
          children: [
            {
              id: "5",
              icon: "Tree",
              name: "Child - 5",
              counts: 9,
            },
          ],
        },
      ],
    },
  ],
};

const data1 = {
  id: "root",
  name: "SCHEMA",
  icon: "table",
  counts: 12,
  children: [
    {
      id: "1",
      name: "FDA AERS",
      icon: "database",
      counts: 4,
    },
    {
      id: "3",
      name: "CTGOV",
      icon: "table",
      counts: 3,
      children: [
        {
          id: "4",
          name: "LSSRM",
          icon: "table",
          counts: 6,
          children: [
            {
              id: "5",
              icon: "table",
              name: "LSMV - BMS",
              counts: 9,
            },
          ],
        },
      ],
    },
  ],
};

function handleClick(node) {
  console.log("Clicked: ", node);
}

export default function NtaiTestTreeView() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      {/* <NtaiTreeView idField="id" nameField="name" data={data} /> */}
      <Divider />
      <Typography>Resource Tree</Typography>
      {/* <NtaiForm>
        <NtaiResourceTree idField="id" nameField="name" data={data} />
      </NtaiForm> */}

      <Divider />

      <NtaiDecoTree2
        // handleClick={handleClick}
        idField="id"
        nameField="name"
        labelField="name"
        badgeField="counts"
        iconField="icon"
        data={data}
        handleClick={handleClick}
      />

      <NtaiDecoTree3
        // handleClick={handleClick}
        idField="id"
        nameField="name"
        labelField="name"
        badgeField="counts"
        iconField="icon"
        data={data1}
        handleClick={handleClick}
      />

      <NtaiDecoTree
        data={data}
        idField="id"
        iconField="icon"
        // typeField="type"
        labelField="name"
        badgeField="counts"
        handleClick={handleClick}
      />

      <NtaiTreeView1 data={data} />

      {/* <NtaiDecoTree
        data={data}
        idField="id"
        iconField="icon"
        // typeField="type"
        labelField="name"
        handleClick={handleClick}
      /> */}
    </Box>
  );
}
