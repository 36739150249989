import { IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/pro-light-svg-icons";
import SearchSourceRecordsSelectDistributionType from "./SearchSourceRecordsSelectDistributionType";
import SearchSourceRecordsDistributeContacts from "./contacts/SearchSourceRecordsDistributeContacts";
import SearchSourceRecordsWorkflow from "./workflow/SearchSourceRecordsWorkflow";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus } from "./store/searchSourceRecordDistributionsSlice";
import { SearchSourceRecordListContext } from "../SearchSourceRecordListPanel";
import { useSnackbar } from "notistack";
import { SearchContext } from "../../../SearchEdit";
const _ = require("lodash");

export default function SearchSourceRecordsDistributeWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectDialogOpen, setSelectDialogOpen] = useState(false);
  const [distributionDialogOpen, setDistributionDialogOpen] = useState(false);
  const [distributionType, setDistributionType] = useState();

  const { selectedRecords, setSelectedRecords } = React.useContext(
    SearchSourceRecordListContext
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordDistributionsSlice.status
  );

  function handleSelect(type) {
    setDistributionType(type);
    handleDistributionDialogOpen();
  }

  function handleSelectDialogOpen() {
    setSelectDialogOpen(true);
  }

  function handleSelectDialogClose() {
    setSelectDialogOpen(false);
  }

  function handleDistributionDialogOpen() {
    setDistributionDialogOpen(true);
  }

  function handleDistributionDialogClose() {
    setDistributionDialogOpen(false);
  }
  function handleClose() {
    handleDistributionDialogClose();
    handleSelectDialogClose();
  }

  useEffect(() => {
    if (
      status &&
      status.method === "submitSearchSourceRecordsDistribution" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(
        `${
          _.isArray(selectedRecords) && selectedRecords.length === 0
            ? "All"
            : selectedRecords.length
        } records successfully distributed`,
        {
          variant: "success",
        }
      );
      // TODO: Refresh selected records
      setSelectedRecords([]);
      handleClose();
    }

    if (
      status &&
      status.method === "submitSearchSourceRecordsDistribution" &&
      status.result === "error"
    ) {
      enqueueSnackbar("Error distributing items: " + status["message"], {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <React.Fragment>
      <Tooltip title="Distribute">
        <IconButton
          onClick={() => handleSelectDialogOpen()}

          //   disabled={selectedRecords.length === 0 ? true : false}
        >
          <FontAwesomeIcon size="2xs" icon={faShare} />
        </IconButton>
      </Tooltip>

      <NtaiDialog
        size="sm"
        open={selectDialogOpen}
        handleDialogClose={handleSelectDialogClose}
        title="Select Distribution Type"
      >
        <SearchSourceRecordsSelectDistributionType
          handleSelect={handleSelect}
          handleCancel={handleSelectDialogClose}
        />
      </NtaiDialog>
      {distributionType && distributionType === 1 && (
        <NtaiDialog
          size="sm"
          open={distributionDialogOpen}
          handleDialogClose={handleDistributionDialogClose}
          title="Distribute to contacts"
        >
          <SearchSourceRecordsDistributeContacts
            handleCancel={handleDistributionDialogClose}
          />
        </NtaiDialog>
      )}
      {distributionType && distributionType === 2 && (
        <NtaiDialog
          size="sm"
          open={distributionDialogOpen}
          handleDialogClose={handleDistributionDialogClose}
          title="Distribute to workflow"
        >
          {/* <SearchSourceRecordSeriesList handleCancel={() => setOpen(false)} /> */}
          <SearchSourceRecordsWorkflow
            handleCancel={handleDistributionDialogClose}
          />
        </NtaiDialog>
      )}
    </React.Fragment>
  );
}
