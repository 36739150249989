import { Box, useTheme } from "@mui/material";
import NtaiColorBox from "@ntai/components/color-picker/NtaiColorBox";
import NtaiColorPicker from "@ntai/components/color-picker/NtaiColorPicker";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

const ruleLtOptions = [
  { value: 1, label: "(<) is less than" },
  { value: 2, label: "(<=) is less than or equal to" },
];
const ruleGtOptions = [
  { value: 1, label: "(>) is greater than" },
  { value: 2, label: "(>=) is greater than or equal to" },
];

export default function NtaiColorSchemeForm(props) {
  const { formName, action, formData, handleSave, handleCancel } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const [bgColor, setBgColor] = useState();
  const [fgColor, setFgColor] = useState();
  const [colorSchemeType, setColorSchemeType] = useState();
  const { getValues, setValue, reset, watch } = useFormContext();

  const watchColorSchemeType = watch(`${formName}.typeCode`);

  function onFgChange(color) {
    setFgColor(color);
    setValue(`${formName}.hexFgCode`, color);
  }
  function onBgChange(color) {
    setBgColor(color);
    setValue(`${formName}.hexBgCode`, color);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(getValues(formName));
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    if (
      watchColorSchemeType &&
      watchColorSchemeType !== undefined &&
      !_.isEmpty(watchColorSchemeType)
    ) {
      setColorSchemeType(watchColorSchemeType["value"]);
    } else if (_.isNumber(watchColorSchemeType))
      setColorSchemeType(watchColorSchemeType);
  }, [watchColorSchemeType]);

  useEffect(() => {
    const formObj = {};
    formObj[formName] = action === "edit" ? formData : null;
    reset(formObj);
  }, [formData]);

  return (
    <NtaiDialogFormPanel handleSave={onHandleSave} handleCancel={handleCancel}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1.5),
        }}
      >
        <NtaiSelectField
          isDisabled={action === "edit" ? true : false}
          name={`${formName}.typeCode`}
          label="Color Scheme"
          options={[
            { value: 1, label: "Exact Value" },
            { value: 2, label: "Rule Based" },
          ]}
          rules={{ required: "Type is required" }}
        />

        {colorSchemeType === 1 && (
          <NtaiTextField name={`${formName}.exactValue`} label="Exact Value" />
        )}

        {colorSchemeType === 2 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            If
            <NtaiTextField name={`${formName}.ruleGtValue`} label="Value" />
            <NtaiSelectField
              options={ruleGtOptions}
              name={`${formName}.ruleGtOperatorCode`}
            />
            and
            <NtaiTextField name={`${formName}.ruleLtValue`} label="Value" />
            <NtaiSelectField
              name={`${formName}.ruleLtOperatorCode`}
              options={ruleLtOptions}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            flexDirection: "column",
          }}
        >
          <NtaiTextField name={`${formName}.hexFgCode`} label="Foreground" />
          <NtaiColorPicker onChange={onFgChange} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            justifyContent: "center",
          }}
        >
          <NtaiColorBox
            fg="#000000"
            text="fg"
            bg={fgColor}
            width={36}
            height={36}
            textVariant="subtitle2"
          />
          <NtaiColorBox
            fg="#000000"
            text="bg"
            bg={bgColor}
            width={36}
            height={36}
            textVariant="subtitle2"
          />
          <NtaiColorBox
            bg={bgColor}
            fg={fgColor}
            width={36}
            height={36}
            textVariant="h6"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            flexDirection: "column",
          }}
        >
          <NtaiTextField name={`${formName}.hexBgCode`} label="Background" />
          <NtaiColorPicker onChange={onBgChange} />
        </Box>
      </Box>
    </NtaiDialogFormPanel>
  );
}
