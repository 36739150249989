import { Box, Button, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import DatasourceAwsRedshftForm from "./aws-redshift/DatasourceAwsRedshftForm";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import DatasourceJdbcForm from "./jdbc/DatasourceJdbcForm";
import DatasourceElasticForm from "./elastic/DatasourceElasticForm";
const _ = require("lodash");

export default function DatasourceForm(props) {
  const theme = useTheme();
  const {
    type,
    action,
    formData,
    handleSave,
    handleTestConnection,
    handleCancel,
  } = props;

  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = getValues("datasourceForm");

      sanitizedFormData["typeCode"] =
        action === "add" ? type : formData["typeCode"];

      // JDBC Database reset
      if (
        sanitizedFormData["typeCode"] === 2 ||
        sanitizedFormData["typeCode"] === "2"
      ) {
        sanitizedFormData["jdbc"]["databaseTypeCode"] =
          sanitizedFormData["jdbc"]["databaseTypeCode"]["value"];
      }

      handleSave(sanitizedFormData);
    }
  }

  useEffect(() => {
    reset({ datasourceForm: action === "edit" ? formData : null });
  }, []);

  return (
    <NtaiFormPanel
      width="50%"
      header="Datasource connection properties"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
      handleOtherActions={
        action === "edit" && (
          <Button size="small" onClick={handleTestConnection}>
            TEST CONNECTION
          </Button>
        )
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="datasourceForm.name"
          label="Name*"
          placeholder="Name"
          rules={{ required: "Name is required" }}
        />
        {((action === "add" && type === "1") ||
          (action === "edit" && formData["typeCode"] === 1)) && (
          <DatasourceAwsRedshftForm />
        )}

        {((action === "add" && type === "2") ||
          (action === "edit" && formData["typeCode"] === 2)) && (
          <DatasourceJdbcForm />
        )}
        {((action === "add" && type === "4") ||
          (action === "edit" && formData["typeCode"] === 4)) && (
          <DatasourceElasticForm />
        )}
      </Box>
    </NtaiFormPanel>
  );
}
