import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceCatalogAssetViews = createAsyncThunk(
  "sourceCatalogAssetViews/getSourceCatalogAssetViews",
  async (filter, { rejectWithValue }) => {
    try {
      const response = await server.get("/source-catalog/asset-views", {
        params: { filter: filter },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceCatalogAssetView = createAsyncThunk(
  "sourceCatalogAssetViews/getSourceCatalogAssetView",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/source-catalog/asset-views/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceCatalogAssetViewsSlice = createSlice({
  name: "sourceCatalogAssetViews",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceCatalogAssetViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceCatalogAssetViews",
      };
    },
    [getSourceCatalogAssetViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceCatalogAssetViews",
        message: action.payload.message,
      };
    },
    [getSourceCatalogAssetView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceCatalogAssetView" };
    },
    [getSourceCatalogAssetView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceCatalogAssetView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceCatalogAssetViewsSlice.actions;

export default sourceCatalogAssetViewsSlice.reducer;
