import { useTheme } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDatasetRecords,
  clearStatus,
} from "./store/userDatasetRecordsSlice";
import { useSnackbar } from "notistack";
import NtaiTable from "@ntai/components/tables/main/NtaiTable";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

function generateColumns(data) {
  const result = [];
  if (_.isArray(data) && data.length > 0) {
    const row1 = data[0];
    Object.keys(row1["data"]).forEach((k) => {
      result.push({
        accessorKey: k,
        header: k,
      });
    });
  }

  return result;
}

export default function UserDatasetData() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const dataMap = useSelector(
    (state) => state.core.userDatasetRecordsSlice.data
  );
  const datasetId = useSelector(
    (state) => state.core.userDatasetsSlice.activeId
  );

  const status = useSelector(
    (state) => state.core.userDatasetRecordsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const columns = generateColumns(data);

  function handleRowClick(rowId) {}

  useEffect(() => {
    if (
      status &&
      status.method === "getUserDatasetRecords" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getUserDatasetRecords({
        datasetId: datasetId,
        params: {
          from: 1,
          size: 25,
        },
      })
    );
  }, [datasetId]);

  return (
    <NtaiPanel
      width="100%"
      header="Dataset Records"
      subheader="Displays dataset records qualifying the criteria. You can browse records and optionally export records in CSV format."
    >
      {loaded &&
        _.isArray(data) &&
        data.length > 0 &&
        columns &&
        _.isArray(columns) &&
        columns.length > 0 && (
          <NtaiTable
            data={data.map((o) => o["data"])}
            columns={columns}
            rowTitle="Record"
            rowTitlePlural="Records"
            tableTitle={`Records (${data.length})`}
            handleRowClick={handleRowClick}
            topToolbarLeftActions={[
              {
                value: "export",
                label: "Export",
                icon: faDownload,
                size: "small",
                textCase: "upper",
                rowSelectionEnable: false,
                confirmAction: true,
              },
            ]}
          />
        )}
      {!loaded && <NtaiCircularProgress />}
      {loaded &&
        _.isArray(data) &&
        data.length > 0 &&
        _.isEmpty(data.map((o) => o["data"])[0]) && (
          <NtaiEmptyMessage
            header="No records to show"
            subheader="Check the dataset criteria Or the table templates under source manager - listing - table templates."
          />
        )}
    </NtaiPanel>
  );
}
