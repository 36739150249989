import React from "react";
import NtaiTabs from "../tabs/NtaiTabs";
import NtaiMuiDataGrid from "./mui/NtaiMuiDataGrid";
import { Box, Link } from "@mui/material";
import NtaiAgGrid from "./aggrid/NtaiAgGrid";
import { blue } from "@mui/material/colors";

const rows = [
  {
    phase: "N/A",
    nct_id: "NCT02890667",
    intervention: "Incident cancer",
    status: "Completed",
    _id: "NCT02890667",
  },
  {
    phase: "N/A",
    country: "France",
    nct_id: "NCT02878122",
    intervention: "Cancer treatment",
    status: "Unknown status",
    _id: "NCT02878122",
  },
  {
    phase: "N/A",
    country: "Sweden",
    nct_id: "NCT02870270",
    intervention: "Cancer treatment",
    status: "Completed",
    _id: "NCT02870270",
  },
  {
    phase: "Phase 3",
    country: "France",
    nct_id: "NCT02890095",
    intervention: "Breast cancer surgery",
    status: "Recruiting",
    _id: "NCT02890095",
  },
  {
    phase: "N/A",
    country: "India",
    nct_id: "NCT02783235",
    intervention: "cervical cancer screening",
    status: "Recruiting",
    _id: "NCT02783235",
  },
  {
    phase: "N/A",
    country: "United States",
    nct_id: "NCT02849717",
    intervention: "Exercise for Cancer Patients",
    status: "Recruiting",
    _id: "NCT02849717",
  },
  {
    phase: "N/A",
    country: "United States",
    nct_id: "NCT02783950",
    intervention: "Decipher Prostate Cancer Classifier",
    status: "Active, not recruiting",
    _id: "NCT02783950",
  },
  {
    phase: "N/A",
    country: "United States",
    nct_id: "NCT02912273",
    intervention: "Baseline Participant Assessment",
    status: "Completed",
    _id: "NCT02912273",
  },
  {
    phase: "Phase 1/Phase 2",
    country: "United States",
    nct_id: "NCT02904226",
    intervention: "JTX-2011",
    status: "Active, not recruiting",
    _id: "NCT02904226",
  },
  {
    phase: "N/A",
    country: "France",
    nct_id: "NCT02898363",
    intervention: "Information campaign",
    status: "Completed",
    _id: "NCT02898363",
  },
  {
    phase: "N/A",
    country: "France",
    nct_id: "NCT02896478",
    intervention: "No intervention. Only observational study",
    status: "Completed",
    _id: "NCT02896478",
  },
  {
    phase: "N/A",
    country: "United States",
    nct_id: "NCT02891993",
    intervention: "IMPROVED",
    status: "Active, not recruiting",
    _id: "NCT02891993",
  },
  {
    phase: "Phase 2",
    country: "France",
    nct_id: "NCT02889718",
    intervention: "CONCERT-CL® station",
    status: "Completed",
    _id: "NCT02889718",
  },
  {
    phase: "N/A",
    country: "Germany",
    nct_id: "NCT02883699",
    intervention: "Standard endurance training",
    status: "Completed",
    _id: "NCT02883699",
  },
  {
    phase: "N/A",
    nct_id: "NCT02880046",
    status: "Unknown status",
    _id: "NCT02880046",
  },
  {
    phase: "N/A",
    country: "France",
    nct_id: "NCT02879864",
    intervention: "light therapy",
    status: "Recruiting",
    _id: "NCT02879864",
  },
  {
    phase: "N/A",
    country: "Belgium",
    nct_id: "NCT02878434",
    status: "Recruiting",
    _id: "NCT02878434",
  },
  {
    phase: "N/A",
    nct_id: "NCT02876731",
    intervention: "PET-CT",
    status: "Completed",
    _id: "NCT02876731",
  },
  {
    phase: "Phase 1",
    nct_id: "NCT02876627",
    intervention: "Exercise training",
    status: "Completed",
    _id: "NCT02876627",
  },
  {
    phase: "Phase 1",
    country: "United States",
    nct_id: "NCT02876510",
    intervention: "Fludarabine",
    status: "Active, not recruiting",
    _id: "NCT02876510",
  },
  {
    phase: "N/A",
    country: "United States",
    nct_id: "NCT02874053",
    status: "Withdrawn",
    _id: "NCT02874053",
  },
  {
    phase: "N/A",
    nct_id: "NCT02874001",
    status: "Completed",
    _id: "NCT02874001",
  },
  {
    phase: "N/A",
    nct_id: "NCT02873923",
    intervention: "No intervention",
    status: "Active, not recruiting",
    _id: "NCT02873923",
  },
  {
    phase: "N/A",
    country: "France",
    nct_id: "NCT02873533",
    intervention: "geriatric care and longitudinal follow up",
    status: "Completed",
    _id: "NCT02873533",
  },
  {
    phase: "N/A",
    country: "United States",
    nct_id: "NCT02872532",
    intervention: "Testicular tissue cryopreservation",
    status: "Recruiting",
    _id: "NCT02872532",
  },
];

function clickColumn(id) {
  console.log("Clicked: ", id);
}

const columns = [
  {
    field: "nct_id",
    headerName: "nct_id",
  },
  {
    field: "phase",
    headerName: "phase",
  },
  {
    field: "status",
    headerName: "status",
  },
  {
    field: "intervention",
    headerName: "intervention",
  },
  {
    field: "country",
    headerName: "country",
  },
];

export default function NtaiTestGrid() {
  return (
    <NtaiTabs>
      <Box sx={{ height: "100%" }} label="MUI">
        <NtaiMuiDataGrid
          disableSelectionOnClick={true}
          rowId="_id"
          rows={rows}
          columns={columns}
        />
      </Box>
      <Box sx={{ height: "100%" }} label="AG GRID">
        {/* <NtaiAgGrid /> */}
      </Box>
    </NtaiTabs>
  );
}
