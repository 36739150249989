import Dashboard from "./Dashboard";
import T from "./T";

const DashboardConfig = {
  routes: [
    {
      path: "/dashboard",
      exact: true,
      component: Dashboard,
    },
    {
      path: "/t",
      exact: true,
      component: T,
    },
  ],
};

export default DashboardConfig;
