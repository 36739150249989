import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import SourceRecordTemplateGeneralForm from "./SourceRecordTemplateGeneralForm";
import SourceTemplateTemplateFields from "./SourceRecordTemplateFields";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function SourceCurrentSourceRecordTemplateForm({
  action,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const activeId = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );

  const data = _.get(dataMap, activeId);

  return (
    <NtaiPanel
      width="75%"
      header="Template"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
    >
      <NtaiTabs>
        <Box label="GENERAL">
          <SourceRecordTemplateGeneralForm
            handleFormSubmit={handleFormSubmit}
            action={action}
            handleCancel={handleCancel}
          />
        </Box>
        <Box
          disabled={action === "edit" ? false : true}
          chip={
            _.isArray(_.get(data, "sourceRecordTemplateFields")) &&
            _.get(data, "sourceRecordTemplateFields").length > 0
              ? data.sourceRecordTemplateFields.length
              : null
          }
          label="FIELDS"
        >
          <SourceTemplateTemplateFields />
        </Box>
      </NtaiTabs>
    </NtaiPanel>
  );
}
