import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiErrorMessage from "@ntai/components/errors/NtaiErrorMessage";
import React from "react";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FontAwesomeIcon
        style={{ fontSize: "36px" }}
        color="red"
        icon={faWarning}
      />
      <Typography variant="h5">
        Something went wrong. Click on Login to continue.
      </Typography>
      <NtaiErrorMessage title="Message" message={error.message} />
      <Button onClick={resetErrorBoundary} variant="contained">
        Login
      </Button>
    </Box>
  );
}
