import { faGrid } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import NtaiIcons from "utils/NtaiIcons";

export default function NtaiMiniCard1(props) {
  const theme = useTheme();
  const { id, width, height, title, subheader, action, avatar, children } =
    props;
  return (
    <Card
      sx={{
        p: 0,
        border: theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        height: height ? height : "100px",
        width: width ? width : "250px",
        boxShadow: theme.colors.shadows.cardSm,

        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
          background: theme.colors.secondary.lighter,
        },
      }}
    >
      <CardHeader
        sx={{ py: 1 }}
        title={
          title ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" fontWeight="700" color="inherit">
                {title}
              </Typography>

              <Typography variant="subtitle1" color="inherit" fontWeight="700">
                {action}
              </Typography>
            </Box>
          ) : null
        }
        subheader={subheader ? subheader : null}
      />

      <CardContent sx={{ py: 0 }}>
        <Typography variant="subtitle2">{children}</Typography>
      </CardContent>
    </Card>
  );
}
