import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
const _ = require("lodash");

export default function NtaiMiniGaugeEChart(props) {
  const { chartId, data } = props;
  const theme = useTheme();
  const chartRef = useRef(null);

  const DEFAULT_OPTION = {
    series: [
      {
        type: "gauge",
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 0,
            color: theme.colors.secondary.light,
          },
        },
        axisLine: {
          lineStyle: {
            width: 10,
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data: null,
        title: {
          fontSize: 14,
        },
        detail: {
          show: false,
          width: 50,
          height: 14,
          fontSize: 14,
          color: "inherit",
          borderColor: "inherit",
          borderRadius: 20,
          borderWidth: 1,
          formatter: "{value}%",
        },
      },
    ],
  };

  const [option, setOption] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const copyOption = _.cloneDeep(DEFAULT_OPTION);
    copyOption["series"][0]["data"] = {
      value: data["value"],
      name: data["label"],
      title: {
        offsetCenter: ["0%", "0%"],
      },
    };

    setOption(copyOption);
    setLoaded(true);
  }, [data]);

  return (
    <Box style={{ display: "flex", width: "100%", height: "100%" }}>
      {loaded && option && !_.isEmpty(option) && (
        <ReactECharts
          ref={chartRef}
          option={option}
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </Box>
  );
}
