import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceViews = createAsyncThunk(
  "sourceViews/getSourceViews",
  async (values) => {
    const { sourceDefId, sourceId, params } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/views`,
      {
        params: params,
      }
    );
    return response.data;
  }
);

export const getSourceUserViews = createAsyncThunk(
  "sourceViews/getSourceUserViews",
  async (values) => {
    const { sourceDefId, sourceId, params } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-views`,
      {
        params: params,
      }
    );
    return response.data;
  }
);

export const createSourceView = createAsyncThunk(
  "sourceViews/createSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceView = createAsyncThunk(
  "sourceViews/updateSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceView = createAsyncThunk(
  "sourceViews/deleteSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceViewsSlice = createSlice({
  name: "sourceViews",
  initialState: {
    data: {},
    userData: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceViews",
      };
    },
    [getSourceViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceViews",
        message: action.payload.message,
      };
    },
    [getSourceUserViews.fulfilled]: (state, action) => {
      state.userData = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceUserViews",
      };
    },
    [getSourceUserViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceUserViews",
        message: action.payload.message,
      };
    },
    [createSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceView",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceView",
        message: action.payload.message,
      };
    },
    [updateSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceView",
      };
    },
    [updateSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceView",
        message: action.payload.message,
      };
    },

    [deleteSourceView.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceView",
      };
    },
    [deleteSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceViewsSlice.actions;

export default sourceViewsSlice.reducer;
