import React, { useContext, useEffect, useState } from "react";
import {
  faCog,
  faEdit,
  faPlay,
  faSave,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import FunctionForm from "./FunctionForm";
import { FunctionEditorContext } from "./FunctionEdit";
import { useSelector } from "react-redux";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function FunctionEditorActions({ onHandleSave, onHandleRun }) {
  const { formData, functionSaveInProgress, functionRunInProgress } =
    useContext(FunctionEditorContext);

  const theme = useTheme();

  const status = useSelector((state) => state.models.functionsSlice.status);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "44px",
          alignItems: "center",
          px: theme.spacing(2),
          borderBottom: theme.general.border1,
        }}
      >
        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        ></Box>

        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: theme.spacing(1),
          }}
        >
          <Button
            onClick={() => onHandleSave()}
            startIcon={
              functionSaveInProgress ? (
                <NtaiCircularProgress size={16} />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )
            }
            size="small"
            disabled={
              functionSaveInProgress || functionRunInProgress ? true : false
            }
          >
            SAVE
          </Button>

          <Button
            variant="contained"
            sx={{ fontSize: "11px" }}
            disabled={
              functionRunInProgress || functionSaveInProgress ? true : false
            }
            startIcon={
              functionRunInProgress ? (
                <NtaiCircularProgress size={16} />
              ) : (
                <FontAwesomeIcon icon={faPlay} />
              )
            }
            size="small"
            onClick={() => onHandleRun()}
          >
            RUN
          </Button>
        </Box>
      </Box>
    </>
  );
}
