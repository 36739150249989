import { Box, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export default function NtaiHelpText(props) {
  const theme = useTheme();
  const { header, subheader, direction, children, fg, bg } = props;
  return (
    <Box
      sx={{
        border: theme.general.border1,
        borderRadius: theme.general.borderRadius,
        p: theme.spacing(0.5),
        display: "flex",
        flexDirection: direction ? direction : "column",
        background: bg ? bg : grey[100],
        alignItems: direction && direction === "row" ? "center" : null,
        gap: direction && direction === "row" ? theme.spacing(1) : null,
      }}
    >
      <Typography
        sx={{ color: fg ? fg : grey[900] }}
        variant="subtitle1"
        fontWeight="700"
      >
        {header}
      </Typography>
      <Typography variant="subtitle1" color="inherit">
        {children}
      </Typography>
    </Box>
  );
}
