import React, { useEffect, useMemo, useState } from "react";
import { Box, darken, lighten, useTheme } from "@mui/material";
import {
  tooltipConfig,
  gridConfig,
  xAxisLabelNoRotationConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: gridConfig,
  tooltip: {
    ...tooltipConfig,
  },
  series: [
    {
      type: "sankey",
      emphasis: {
        focus: "adjacency",
      },
      nodeAlign: "right",
      data: [],
      links: [],
      lineStyle: {
        color: "source",
        curveness: 0.5,
      },
    },
  ],
};

export default function NtaiSankeyEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);

  function getSourceWidgetFilters(params) {
    let result = null;
    if (params["dataType"] === "node") {
      result = [
        {
          sourceFieldUuId: params["data"]["sourceFieldUuId"],
          fieldOperatorCode: 1,
          fieldValue: params["data"]["name"],
        },
      ];
    } else if (params["dataType"] === "edge") {
      result = [
        {
          sourceFieldUuId: params["data"]["sourceSourceFieldUuId"],
          fieldOperatorCode: 1,
          fieldValue: params["data"]["source"],
        },
        {
          sourceFieldUuId: params["data"]["targetSourceFieldUuId"],
          fieldOperatorCode: 1,
          fieldValue: params["data"]["target"],
        },
      ];
    }

    return result;
  }
  function onChartClick(params) {
    handleClick(getSourceWidgetFilters(params));
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(action, getSourceWidgetFilters(params));
  }

  useEffect(() => {
    const nodes = data["nodes"];
    const links = data["links"];

    const copyOption = _.cloneDeep(option);
    copyOption["series"][0]["data"] = nodes;
    copyOption["series"][0]["links"] = links;

    setOption(copyOption);
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"][0]["data"].length > 0 &&
        option["series"][0]["links"].length > 0 &&
        chart}
    </Box>
  );
}
