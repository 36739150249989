import DashboardViewCreate from "./views/DashboardViewCreate";
import DashboardViewList from "./views/DashboardViewList";
import DashboardViewEdit from "./views/DashboardViewEdit";
import DashboardWidgetList from "./widgets/DashboardWidgetList";
import DashboardWidgetCreate from "./widgets/DashboardWidgetCreate";
import DashboardWidgetEdit from "./widgets/DashboardWidgetEdit";
import DashboardWidgetSelection from "./widgets/DashboardWidgetSelection";

const DashboardMgmtConfig = {
  routes: [
    {
      path: "/admin/dashboards/views",
      exact: true,
      component: DashboardViewList,
    },
    {
      path: "/admin/dashboards/views/create",
      exact: true,
      component: DashboardViewCreate,
    },
    {
      path: "/admin/dashboards/views/:id/edit",
      exact: true,
      component: DashboardViewEdit,
    },

    {
      path: "/admin/dashboards/widgets",
      exact: true,
      component: DashboardWidgetList,
    },
    {
      path: "/admin/dashboards/widgets/create",
      exact: true,
      component: DashboardWidgetSelection,
    },
    {
      path: "/admin/dashboards/widgets/create-2/:wt",
      exact: true,
      component: DashboardWidgetCreate,
    },
    {
      path: "/admin/dashboards/widgets/:id/edit",
      exact: true,
      component: DashboardWidgetEdit,
    },
  ],
};

export default DashboardMgmtConfig;
