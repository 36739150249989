import { useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getSourceRecordTags } from "../../tag/store/sourceRecordTagsSlice";

export default function SourceRecordLabelTagForm(props) {
  const theme = useTheme();
  const { action, formData, handleFormSubmit, handleDialogClose } = props;
  const dispatch = useDispatch();

  const { getValues, reset } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTagsSlice.data
  );
  const data = Object.values(dataMap);

  const tagOptions = NtaiUtils.getSelectOptions(
    data,
    "uuId",
    "sourceDerivedField.label"
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceRecordLabelTagForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({
      sourceRecordLabelTagForm: action === "edit" ? formData : {},
    });
    dispatch(
      getSourceRecordTags({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <NtaiSelectField
        name="sourceRecordLabelTagForm.sourceRecordTagUuId"
        label="Select Tag"
        options={tagOptions}
        rules={{ required: "Tag is required" }}
      />
    </NtaiDialogFormPanel>
  );
}
