import { combineReducers } from "@reduxjs/toolkit";
import sourceDefinitionsSlice from "./sourceDefinitionsSlice";
import sourcesSlice from "../source/store/sourcesSlice";
import sourceObjectsSlice from "../source/object/store/sourceObjectsSlice";
import sourceObjectFieldsSlice from "../source/object/field/store/sourceObjectFieldsSlice";
import sourceViewsSlice from "../source/view/store/sourceViewsSlice";
import sourceUserWidgetsSlice from "../source/user-widgets/store/sourceUserWidgetsSlice";
import sourceWidgetsSlice from "../source/view/widgets/store/sourceWidgetsSlice";
import sourceThesaurusesSlice from "../source/thesaurus/store/sourceThesaurusesSlice";
import sourceLinkedSourcesSlice from "../source/linkedsource/store/sourceLinkedSourcesSlice";
import sourceLinkedSourceFieldsSlice from "../source/linkedsource/field/store/sourceLinkedSourceFieldsSlice";
import sourceLinkedSourceLinksSlice from "../source/linkedsource/field/link/store/sourceLinkedSourceLinksSlice";
import sourceDerivedFieldsSlice from "../source/derivedfield/store/sourceDerivedFieldsSlice";
import sourceDerivedFieldElemsSlice from "../source/derivedfield/elements/store/sourceDerivedFieldElemsSlice";
import sourceCustomFieldsSlice from "../source/customfields/store/sourceCustomFieldsSlice";
import sourceCustomFieldElemsSlice from "../source/customfields/elems/store/sourceCustomFieldElemsSlice";
import sourceTemplateWorkflowDefsSlice from "../source/template/workflow/store/sourceTemplateWorkflowsSlice";
import sourceInsSlice from "../source/in/store/sourceInsSlice";
import sourceInStoreDatasetsSlice from "../source/in/dataset/store/sourceInStoreDatasetsSlice";
import sourceInStoreFilesSlice from "../source/in/store/sourceInStoreFilesSlice";
import sourceInStoreFileFieldsSlice from "../source/in/store/sourceInStoreFileFieldsSlice";
import sourceInStoreFilePreviewSlice from "../source/in/store/sourceInStoreFilePreviewSlice";
import sourceInStoreXmlDatasetsSlice from "../source/in/xml/datasets/store/sourceInStoreXmlDatasetsSlice";
import sourceInStoreOcrPagesSlice from "../source/in/ocr/store/sourceInStoreOcrPagesSlice";
import sourceInStoreOcrFieldsSlice from "../source/in/ocr/fields/store/sourceInStoreOcrFieldsSlice";
import sourcePipelinesSlice from "../source/pl/store/sourcePipelinesSlice";
import sourcePipelineInStoresSlice from "../source/pl/in/store/sourcePipelineInStoresSlice";
import sourcePipelineInStoreFilesSlice from "../source/pl/stage/dataset/sidepanel/bottom-drawer/store/sourcePipelineInStoreFilesSlice";
import sourcePipelineInStoreDatasetsSlice from "../source/pl/stage/dataset/sidepanel/bottom-drawer/store/sourcePipelineInStoreDatasetsSlice";
import sourceFunctionPackagesSlice from "../source/pl/stage/dataset/sidepanel/packages/store/sourceFunctionPackagesSlice";
import sourceRefSourcesSlice from "../source/pl/refs/store/sourceRefSourcesSlice";
import sourcePipelineRefSourceDatasetsSlice from "../source/pl/ref-datasets/store/sourcePipelineRefSourceDatasetsSlice";
import sourceStagesSlice from "../source/pl/stage/store/sourceStagesSlice";
import sourceDatasetsSlice from "../source/pl/stage/dataset/store/sourceDatasetsSlice";
import sourceFieldsSlice from "../source/pl/stage/dataset/store/sourceFieldsSlice";
import sourceFieldLookupsSlice from "../source/out/elastic/lookup/store/sourceFieldLookupsSlice";
import sourceOutStoresSlice from "../source/out/store/sourceOutStoresSlice";
import sourceOutDatasetsSlice from "../source/out/store/sourceOutDatasetsSlice";
import sourceOutDatasetNkFieldsSlice from "../source/out/keys/nk/store/sourceOutDatasetNkFieldsSlice";
import sourceOutDatasetPkFieldsSlice from "../source/out/keys/pk/store/sourceOutDatasetPkFieldsSlice";
import sourceOutDatasetScdFieldsSlice from "../source/out/keys/scd-fields/store/sourceOutDatasetScdFieldsSlice";
import sourceOutJobsSlice from "../source/job/store/sourceOutJobsSlice";
import sourceOutJobMigrationsSlice from "../source/job/migration/store/sourceOutJobMigrationsSlice";
import sourceOutDatasetScdPoliciesSlice from "../source/out/scd-policy/store/sourceOutDatasetScdPoliciesSlice";
import sourceRecordListingsSlice from "../source/template/listing/store/sourceRecordListingsSlice";
import sourceRecordListingFieldsSlice from "../source/template/listing/fields/store/sourceRecordListingFieldsSlice";
import sourceRecordListingTagsSlice from "../source/template/listing/tags/store/sourceRecordListingTagsSlice";
import sourceRecordListingFieldColorsSlice from "../source/template/listing/fields/table/field-color/store/sourceRecordListingFieldColorsSlice";
import sourceRecordTagsSlice from "../source/template/tag/store/sourceRecordTagsSlice";
import sourceRecordTagColorRulesSlice from "../source/template/tag/color-rules/store/sourceRecordTagColorRulesSlice";
import sourceRecordLabelsSlice from "../source/template/label/store/sourceRecordLabelsSlice";
import sourceRecordLabelSecondarySlice from "../source/template/label/secondary/store/sourceRecordLabelSecondarySlice";
import sourceRecordLabelTagsSlice from "../source/template/label/tag/store/sourceRecordLabelTagsSlice";
import sourceFilterSchemesSlice from "../source/template/filter/store/sourceFilterSchemesSlice";
import sourceFilterSchemeFieldsSlice from "../source/template/filter/fields/store/sourceFilterSchemeFieldsSlice";
import sourceFieldSemTypesSlice from "../source/out/elastic/fields/semtype/store/sourceFieldSemTypesSlice";
import sourcePipelineAwsLivySessionsSlice from "../source/pl/session/store/sourcePipelineAwsLivySessionsSlice";
import sourceRecordTemplatesSlice from "../source/template/record/store/sourceRecordTemplatesSlice";
import sourceReportTemplatesSlice from "../source/template/report/store/sourceReportTemplatesSlice";
import sourceExportImportSlice from "../source/export-import/store/sourceExportImportSlice";
import sourceCatalogAssetViewsSlice from "../catalog/asset/store/sourceCatalogAssetViewsSlice";
import sourceCatalogAssetsSlice from "../catalog/asset/store/sourceCatalogAssetsSlice";
import sourceCatalogSlice from "../catalog/asset/store/sourceCatalogSlice";
import sourceAwsLivySessionsSlice from "../source/session/store/sourceAwsLivySessionsSlice";
import sourceWidgetFieldColorsSlice from "../source/view/widgets/forms/color-scheme/store/sourceWidgetFieldColorsSlice";
import sourceRecordsSlice from "../source/record/store/sourceRecordsSlice";
import sourceSeriesImportCsvSchemesSlice from "../source/template/series/store/sourceSeriesImportCsvSchemesSlice";
import sourceMasterTemplatesSlice from "../source/template/master/store/sourceMasterTemplatesSlice";
import sourceAdjudicationsSlice from "../source/adjudication/store/sourceAdjudicationsSlice";
import sourceAdjudicationImportCsvSchemesSlice from "../source/adjudication/import-schemes/store/sourceAdjudicationImportCsvSchemesSlice";
import sourcePolicyDefsSlice from "../source/policy/definition/store/sourcePolicyDefsSlice";
import sourcePoliciesSlice from "../source/policy/policy/store/sourcePoliciesSlice";
import sourcePolicyAssessmentsSlice from "../source/policy/policy/assessment/store/sourcePolicyAssessmentsSlice";

const sourcesReducer = combineReducers({
  sourceDefinitionsSlice,
  sourcesSlice,
  sourceInsSlice,
  sourceInStoreFilesSlice,
  sourceInStoreFileFieldsSlice,
  sourceInStoreXmlDatasetsSlice,
  sourceInStoreOcrPagesSlice,
  sourceInStoreOcrFieldsSlice,
  sourceInStoreFilePreviewSlice,
  sourceInStoreDatasetsSlice,
  sourceStagesSlice,
  sourceDatasetsSlice,
  sourceFieldsSlice,
  sourceFieldLookupsSlice,
  sourceOutStoresSlice,
  sourceOutDatasetsSlice,
  sourceOutDatasetNkFieldsSlice,
  sourceOutDatasetPkFieldsSlice,
  sourceOutJobsSlice,
  sourceOutJobMigrationsSlice,
  sourceOutDatasetScdPoliciesSlice,
  sourceOutDatasetScdFieldsSlice,
  sourceObjectsSlice,
  sourceObjectFieldsSlice,
  sourceRecordListingsSlice,
  sourceRecordListingFieldsSlice,
  sourceRecordListingTagsSlice,
  sourceRecordListingFieldColorsSlice,
  sourceViewsSlice,
  sourceUserWidgetsSlice,
  sourceWidgetsSlice,
  sourceThesaurusesSlice,
  sourceLinkedSourcesSlice,
  sourceLinkedSourceFieldsSlice,
  sourceLinkedSourceLinksSlice,
  sourceAdjudicationsSlice,
  sourceDerivedFieldsSlice,
  sourceDerivedFieldElemsSlice,
  sourceCustomFieldsSlice,
  sourceCustomFieldElemsSlice,
  sourceRecordTagsSlice,
  sourceRecordTagColorRulesSlice,
  sourceTemplateWorkflowDefsSlice,
  sourceFilterSchemesSlice,
  sourceFilterSchemeFieldsSlice,
  sourcePipelinesSlice,
  sourcePipelineInStoresSlice,
  sourcePipelineRefSourceDatasetsSlice,
  sourcePipelineInStoreDatasetsSlice,
  sourcePipelineAwsLivySessionsSlice,
  sourcePipelineInStoreFilesSlice,
  sourceRefSourcesSlice,
  sourceRecordLabelsSlice,
  sourceRecordLabelTagsSlice,
  sourceRecordLabelSecondarySlice,
  sourceFieldSemTypesSlice,
  sourceFunctionPackagesSlice,
  sourceRecordTemplatesSlice,
  sourceExportImportSlice,
  sourceCatalogAssetViewsSlice,
  sourceCatalogAssetsSlice,
  sourceCatalogSlice,
  sourceAwsLivySessionsSlice,
  sourceReportTemplatesSlice,
  sourceWidgetFieldColorsSlice,
  sourceRecordsSlice,
  sourceSeriesImportCsvSchemesSlice,
  sourceMasterTemplatesSlice,
  sourceAdjudicationImportCsvSchemesSlice,
  sourcePolicyDefsSlice,
  sourcePoliciesSlice,
  sourcePolicyAssessmentsSlice,
});

export default sourcesReducer;
