import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceIns = createAsyncThunk(
  "sourceIns/getSourceIns",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceIn = createAsyncThunk(
  "sourceIns/getSourceIn",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceIn = createAsyncThunk(
  "sourceIns/createSourceIn",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceIn = createAsyncThunk(
  "sourceIns/updateSourceIn",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const storeSourceInDatasets = createAsyncThunk(
  "sourceIns/storeSourceInDatasets",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInStoreId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/store-datasets`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const storeSourceInSingleDataset = createAsyncThunk(
  "sourceIns/storeSourceInSingleDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInStoreId, sourceInStoreDatasetId } =
        values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/store-datasets/${sourceInStoreDatasetId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceIn = createAsyncThunk(
  "sourceIns/deleteSourceIn",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceInsSlice = createSlice({
  name: "sourceIns",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceIns.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceIns",
      };
    },
    [getSourceIns.rejected]: (state, action) => {
      // state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "error",
        method: "getSourceIns",
        message: action.payload.message,
      };
    },

    [getSourceIn.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceIn",
      };
    },
    [getSourceIn.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceIn",
        message: action.payload.message,
      };
    },

    [createSourceIn.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceIn",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceIn.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceIn",
        message: action.payload.message,
      };
    },
    [updateSourceIn.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceIn",
      };
    },
    [updateSourceIn.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceIn",
        message: action.payload.message,
      };
    },

    [deleteSourceIn.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceIn",
      };
    },
    [deleteSourceIn.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceIn",
        message: action.payload.message,
      };
    },

    [storeSourceInDatasets.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "storeSourceInDatasets",
      };
    },
    [storeSourceInDatasets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "storeSourceInDatasets",
        message: action.payload.message,
      };
    },

    [storeSourceInSingleDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "storeSourceInSingleDataset",
      };
    },
    [storeSourceInSingleDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "storeSourceInSingleDataset",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceInsSlice.actions;

export default sourceInsSlice.reducer;
