import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
const _ = require("lodash");

const headCells1 = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "sex",
    label: "Sex",
  },
];

const data1 = [
  {
    name: "John",
    age: 23,
    sex: "M",
  },
  {
    name: "Mark",
    age: 45,
    sex: "F",
  },
];

function buildHeaderCells(headCells, aggFg) {
  let returnValue = [];
  const fields = Object.values(headCells);

  _.orderBy(fields, ["fieldOrder"], ["asc"]).forEach((field) => {
    returnValue.push(
      <TableCell sx={{ pl: "8px", background: grey[200] }}>
        {_.get(_.get(field, "field"), "label")}
      </TableCell>
    );
  });

  if (aggFg) returnValue.push(<TableCell>Count</TableCell>);
  return returnValue;
}

function buildRows(headCells, data, aggFg) {
  let returnValue = [];
  const fields = Object.values(headCells);
  data.forEach((row) => {
    returnValue.push(
      <TableRow>
        {fields.map((field) => {
          return (
            <TableCell sx={{ pl: "8px", fontSize: "12px" }}>
              {row[_.get(field.field, "name")]}
            </TableCell>
          );
        })}
        {aggFg ? <TableCell>{row["count"]}</TableCell> : null}
      </TableRow>
    );
  });

  return returnValue;
}

export default function NtaiWidgetTable(props) {
  const theme = useTheme();

  const { widgetId, data, metadata, aggFg } = props;

  console.log("Table widget: ", data, metadata);

  return (
    <TableContainer
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "start",
      }}
      component="paper"
    >
      <Scrollbar autoHide={true}>
        <Box sx={{ display: "flex" }}>
          <Table>
            <TableHead>
              <TableRow>
                {buildHeaderCells(_.get(metadata, "fields"), aggFg)}
              </TableRow>
            </TableHead>
            <TableBody>
              {buildRows(_.get(metadata, "fields"), data, aggFg)}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </TableContainer>
  );
}
