import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";

export default function ToolsAssessmentImportFinal({ handleCancel }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: theme.spacing(4),
      }}
    >
      <FontAwesomeIcon
        color="green"
        icon={faCircleCheck}
        style={{ fontSize: "48px" }}
      />
      <Typography variant="h1" fontWeight="300">
        Assessment import request successfully submitted. Click below to go back
        to the assessment import listing
      </Typography>
      <Button onClick={() => handleCancel()} variant="contained">
        Back to Listing
      </Button>
    </Box>
  );
}
