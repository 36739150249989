import React, { useState } from "react";
import {
  faArchive,
  faCommentAlt,
  faEllipsisV,
  faFilter,
  faSearch,
  faSitemap,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import useDimensions from "react-cool-dimensions";
import {
  Backdrop,
  Badge,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import NtaiSourceWidgetFooterWrapper from "./footer/NtaiSourceWidgetFooterWrapper";
import NtaiSourceWidgetNoteList from "./header/note/NtaiSourceWidgetNoteList";
import "./ntai-source-widget.css";

import { faCircleArrowDown } from "@fortawesome/pro-solid-svg-icons";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { blueGrey, grey, red } from "@mui/material/colors";
import NtaiChartEmptyMessage from "@ntai/components/charts/NtaiChartEmptyMessage";

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    height: "32px",
    // flexBasis: "10%",
    alignItems: "center",
    padding: "8px",
    // borderBottom: "1px solid #eee",
    "&:hover": {
      cursor: "grab",
    },
    justifyContent: "space-between",
    // borderBottom: "1px solid #eee",
  },
  headerTitle: {
    display: "flex",
    paddingLeft: "4px",
    alignItems: "center",
  },
  headerActions: {
    display: "flex",
    paddingRight: "4px",
    justifyContent: "end",
    alignItems: "center",
    gap: "4px",
  },
  main: {
    display: "flex",
    // flexBasis: "80%",
    height: `calc(100% - 32px)`,
    // height: (props) =>
    //   props.widgetFooterOpen ? `calc(100% - 70px)` : `calc(100% - 32px)`,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    position: "relative",
    zIndex: 0,
  },
}));

export default function NtaiSourceWidget(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [widgetFooterOpen, setWidgetFooterOpen] = useState(false);
  const [drillDownModeOn, setDrillDownModeOn] = useState(false);
  const {
    id,
    title,
    headerActionsFg,
    filterCount,
    children,
    loading,
    filterPresent,
    blank,
    notesCount,
    footerPresent,
    handlePagination,
    pagination,
    handleNoteListDialogOpen,
    numOfNotes,
    handleInfoDialogOpen,
    onSearchSubmit,
    handleDrillMode,
    handleDeleteDrillWidget,
    noteIcon,
    widgetError,
  } = props;
  // const divRef = React.useRef();
  const classes = useStyles({ widgetFooterOpen: widgetFooterOpen });

  function handleFooterOpen() {
    setWidgetFooterOpen(!widgetFooterOpen);
  }

  // const dims = useDimensions({
  //   onResize: ({ observe, unobserve, width, height, entry }) => {
  //     // Triggered whenever the size of the target is changed...

  //     unobserve(); // To stop observing the current target element
  //     observe(); // To re-start observing the current target element
  //   },
  // });

  // const observe1 = _.get(dims, "observe");
  // const unobserve1 = _.get(dims, "observe");
  // const height = _.get(dims, "height");

  // const handleOpen = (event) => {
  //   setOpen(true);
  //   setAnchorEl(divRef.current);
  //   //setAnchorElComments(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   setAnchorEl(null);
  // };

  // const clickAwayHandler = () => {
  //   setOpen(false);
  // };

  return (
    <Box
      id={id}
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
      // ref={observe1}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",

          border: theme.general.border1,
          bordeRadius: theme.general.borderRadiusSm,
          background: "white",
          "&:hover": { boxShadow: theme.colors.shadows.card },
          borderTop: open ? theme.general.border2p : null,
          boxShadow: open ? theme.colors.shadows.card : null,
        }}
        // ref={divRef}
      >
        <Box className={classes.widgetContainer}>
          <Box className="widget-header-wrapper">
            <Box id="title-action" className={classes.header}>
              <Box className={classes.headerTitle}>
                <Typography variant="h5" fontWeight="500" color={grey[900]}>
                  {title}
                </Typography>
              </Box>
              {!widgetError &&
                ((props.hasOwnProperty("headerActionsFg") &&
                  props.headerActionsFg) ||
                  !props.hasOwnProperty("headerActionsFg")) && (
                  <Box className={classes.headerActions}>
                    {/* <IconButton size="small" onClick={() => handleInfoDialogOpen()}>
                  <FontAwesomeIcon size="2xs" icon={faInfo} />
                </IconButton> */}
                    {onSearchSubmit && (
                      <IconButton
                        sx={{ cursor: "default" }}
                        size="small"
                        onClick={() => handleFooterOpen()}
                      >
                        <FontAwesomeIcon size="2xs" icon={faSearch} />
                      </IconButton>
                    )}
                    <IconButton
                      sx={{ cursor: "default" }}
                      size="small"
                      onClick={() => handleDrillMode()}
                    >
                      <FontAwesomeIcon size="2xs" icon={faSitemap} />
                    </IconButton>
                    {handleDeleteDrillWidget && (
                      <Tooltip title="Drill down">
                        <IconButton
                          sx={{ cursor: "default" }}
                          size="small"
                          onClick={() => handleDeleteDrillWidget()}
                        >
                          <FontAwesomeIcon size="2xs" icon={faArchive} />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/* <IconButton
                  sx={{ cursor: "default" }}
                  size="small"
                  onClick={(e) => handleNoteListDialogOpen()}
                >
                  <Badge
                    badgeContent={numOfNotes && numOfNotes > 0 ? numOfNotes : 0}
                    size="small"
                    variant="dot"
                    color="secondary"
                  >
                    <FontAwesomeIcon size="2xs" icon={faCommentAlt} />
                  </Badge>
                </IconButton> */}
                    {noteIcon}

                    <Tooltip title="Filters">
                      <IconButton size="small" sx={{ cursor: "default" }}>
                        <Badge
                          badgeContent={filterPresent ? 1 : 0}
                          size="small"
                          variant="dot"
                          color="secondary"
                        >
                          <FontAwesomeIcon size="2xs" icon={faFilter} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <IconButton size="small" sx={{ cursor: "default" }}>
                      <FontAwesomeIcon size="2xs" icon={faEllipsisV} />
                    </IconButton>
                  </Box>
                )}
            </Box>
          </Box>

          <Box id="main" className={classes.main}>
            {!widgetError && children}
            {widgetError && (
              <Chip
                size="small"
                label="Error: Unable to load data"
                sx={{ bgColor: red[500] }}
              />
            )}

            {widgetFooterOpen && (
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  top: `calc(100% - 32px)`,
                }}
              >
                <NtaiSourceWidgetFooterWrapper
                  id={id}
                  handlePagination={handlePagination ? handlePagination : null}
                  pagination={pagination}
                  onSearchSubmit={onSearchSubmit}
                />
              </div>
            )}
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: "absolute",
                backgroundColor: "transparent",
                opacity: 0.5,
              }}
              open={loading}
            >
              <CircularProgress size={20} />
            </Backdrop>
          </Box>
        </Box>
      </Box>
      {/* {open && (
        <NtaiSourceWidgetNoteList
          height={height}
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          clickAwayHandler={clickAwayHandler}
        />
      )} */}
    </Box>
  );
}
