import { faEye, faLock, faStar } from "@fortawesome/pro-light-svg-icons";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import {
  faLock as faLockSolid,
  faStar as faStarSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchContext } from "../../SearchEdit";
import history from "@ntai/@history";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { updateSearch, clearStatus } from "../../../store/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SearchDetails from "./SearchDetails";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { getSearchTopic } from "../actionpanel/topic/store/searchTopicsSlice";
import SearchLock from "../lock/SearchLock";
const _ = require("lodash");

export default function SearchTitleWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [titleFocus, setTitleFocus] = useState(false);
  const searchDataMap = useSelector((state) => state.search.searchSlice.data);
  const searchId = useSelector((state) => state.search.searchSlice.activeId);
  const searchData = _.get(searchDataMap, searchId);
  const searchTopicData = useSelector(
    (state) => state.search.searchTopicsSlice.data
  );

  const [lock, setLock] = useState();
  const [favorite, setFavorite] = useState();

  const status = useSelector((state) => state.search.searchSlice.status);

  function handleDrawerClose() {
    setOpenDrawer(false);
  }

  function handleSave(formData) {
    dispatch(
      updateSearch({ uuId: _.get(searchData, "uuId"), formData: formData })
    );
  }

  function handleLock() {
    const lockTmp = searchData["lockedFg"] === 1 ? true : false;
    setLock(lockTmp ? 0 : 1);
  }

  function handleFavorite() {
    const favoriteTmp = searchData["favoriteFg"] === 1 ? true : false;
    setFavorite(favoriteTmp ? 0 : 1);
  }

  useEffect(() => {
    if ([1, 0].includes(favorite)) {
      let searchDataCopy = _.cloneDeep(searchData);
      searchDataCopy["favoriteFg"] = favorite;
      dispatch(
        updateSearch({
          uuId: searchId,
          formData: searchDataCopy,
        })
      );
    }
  }, [favorite]);

  useEffect(() => {
    if ([1, 0].includes(lock)) {
      let searchDataCopy = _.cloneDeep(searchData);
      searchDataCopy["lockedFg"] = lock;
      dispatch(
        updateSearch({
          uuId: searchId,
          formData: searchDataCopy,
        })
      );
    }
  }, [lock]);

  useEffect(() => {
    dispatch(getSearchTopic(_.get(searchData, "uuId")));
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexBasis: "25%",
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          pl: theme.spacing(1),
        }}
      >
        <IconButton onClick={() => history.push("/search")}>
          <FontAwesomeIcon size="xs" icon={faArrowLeft} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            width: "100%%",
          }}
          onClick={() => setOpenDrawer(true)}
          onMouseOver={() => setTitleFocus(true)}
          onMouseOut={() => setTitleFocus(false)}
        >
          <Typography
            variant="h5"
            fontWeight="700"
            color={
              titleFocus
                ? theme.colors.alpha.black[300]
                : theme.colors.alpha.black[100]
            }
          >
            {NtaiUtils.trunc(searchData.name, 20)}
          </Typography>
          {/* <Typography
            variant="caption"
            fontWeight="500"
            color={
              titleFocus
                ? theme.colors.alpha.black[100]
                : theme.colors.alpha.black[70]
            }
          >
            {!_.isEmpty(searchTopicData)
              ? NtaiUtils.trunc(searchTopicData.dataOrObjectTopicName, 20)
              : "No Topic Assigned"}
          </Typography> */}
        </Box>

        <Tooltip title="Favorite">
          <IconButton onClick={() => handleFavorite()}>
            {searchData["favoriteFg"] && searchData["favoriteFg"] === 1 ? (
              <FontAwesomeIcon size="2xs" icon={faStarSolid} color="orange" />
            ) : (
              <FontAwesomeIcon size="2xs" icon={faStar} />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip title="Watch">
          <IconButton>
            <FontAwesomeIcon size="2xs" icon={faEye} />
          </IconButton>
        </Tooltip>

        <SearchLock handleLock={handleLock} />

        {/* <Divider orientation="vertical" flexItem /> */}
        {/* <NtaiBadge
          fg={theme.colors.secondary.contrastText}
          bg={theme.colors.secondary.dark}
          title="Published"
        /> */}
      </Box>
      <Drawer
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openDrawer}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{
            width: "375px",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer,
          }}
        >
          <SearchDetails
            handleSave={handleSave}
            handleClose={handleDrawerClose}
          />
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
