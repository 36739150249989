import { IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessagePlus } from "@fortawesome/pro-light-svg-icons";
import SearchSourceRecordsBatchAssessmentSelectForm from "./SearchSourceRecordsBatchAssessmentSelectForm";
const _ = require("lodash");

export default function SearchSourceRecordsBatchAssessmentWrapper() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }
  return (
    <React.Fragment>
      <Tooltip title="Batch Assessment">
        <IconButton onClick={() => handleOpen()}>
          <FontAwesomeIcon size="2xs" icon={faMessagePlus} />
        </IconButton>
      </Tooltip>

      <NtaiDialog
        size="sm"
        open={open}
        handleDialogClose={() => handleClose()}
        title="Select Batch Assessment Form"
      >
        <SearchSourceRecordsBatchAssessmentSelectForm
          handleCancel={handleClose}
        />
      </NtaiDialog>
    </React.Fragment>
  );
}
