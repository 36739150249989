import SourceCatalogConfig from "./catalog/SourceCatalogConfig";
import SourceConfig from "./source/SourceConfig";
import SourceDefList from "./SourceDefList";

const SourceDefConfig = {
  routes: [
    {
      path: "/sourcedefs",
      exact: true,
      component: SourceDefList,
    },
    ...SourceCatalogConfig.routes,
    ...SourceConfig.routes,
  ],
};

export default SourceDefConfig;
