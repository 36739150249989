import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourcePipelineLivySessions = createAsyncThunk(
  "sourcePipelineAwsLivySessions/getSourcePipelineLivySessions",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/aws-livy-sessions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourcePipelineLivySession = createAsyncThunk(
  "sourcePipelineAwsLivySessions/getSourcePipelineLivySession",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/aws-livy-sessions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const startSourcePipelineLivySession = createAsyncThunk(
  "sourcePipelineAwsLivySessions/startSourcePipelineLivySession",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/aws-livy-sessions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourcePipelineLivySession = createAsyncThunk(
  "sourcePipelineAwsLivySessions/updateSourcePipelineLivySession",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/aws-livy-sessions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourcePipelineLivySession = createAsyncThunk(
  "sourcePipelineAwsLivySessions/deleteSourcePipelineLivySession",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/aws-livy-sessions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePipelineAwsLivySessionsSlice = createSlice({
  name: "sourcePipelineAwsLivySessions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePipelineLivySessions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourcePipelineLivySessions",
      };
    },
    [getSourcePipelineLivySessions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelineLivySessions",
        message: action.payload.message,
      };
    },
    [getSourcePipelineLivySession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourcePipelineLivySession",
      };
    },
    [getSourcePipelineLivySession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelineLivySession",
        message: action.payload.message,
      };
    },
    [startSourcePipelineLivySession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "startSourcePipelineLivySession",
      };
    },
    [startSourcePipelineLivySession.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "startSourcePipelineLivySession",
      };
    },

    [startSourcePipelineLivySession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "startSourcePipelineLivySession",
        message: action.payload.message,
      };
    },
    [updateSourcePipelineLivySession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourcePipelineLivySession",
      };
    },
    [updateSourcePipelineLivySession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourcePipelineLivySession",
        message: action.payload.message,
      };
    },
    [deleteSourcePipelineLivySession.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourcePipelineLivySession",
      };
    },
    [deleteSourcePipelineLivySession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourcePipelineLivySession",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourcePipelineAwsLivySessionsSlice.actions;

export default sourcePipelineAwsLivySessionsSlice.reducer;
