import React from "react";
import SourceCatalogAssetSourceDefinitionInfo from "./SourceCatalogAssetSourceDefinitionInfo";
import SourceCatalogAssetDatasetInfo from "./SourceCatalogAssetDatasetInfo";
import SourceCatalogAssetFieldInfo from "./SourceCatalogAssetFieldInfo";

export default function SourceCatalogAssetInfo({ assetUuId, assetTypeCode }) {
  return (
    <React.Fragment>
      {assetTypeCode === 1 && (
        <SourceCatalogAssetSourceDefinitionInfo uuId={assetUuId} />
      )}
      {assetTypeCode === 3 && (
        <SourceCatalogAssetDatasetInfo uuId={assetUuId} />
      )}
      {assetTypeCode === 4 && <SourceCatalogAssetFieldInfo uuId={assetUuId} />}
    </React.Fragment>
  );
}
