import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const changeUserPassword = createAsyncThunk(
  "userChangePassword/changeUserPassword",
  async (values, { rejectWithValue }) => {
    try {
      const { userUuId, formData } = values;
      const response = await server.patch(
        `/users/${userUuId}/change-password`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userChangePasswordSlice = createSlice({
  name: "userChangePassword",
  initialState: {
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
  },
  extraReducers: {
    [changeUserPassword.fulfilled]: (state, action) => {
      state.status = { result: "success", method: "changeUserPassword" };
    },
    [changeUserPassword.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "changeUserPassword",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus } = userChangePasswordSlice.actions;

export default userChangePasswordSlice.reducer;
