import { Box, useTheme } from "@mui/material";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import SourceDatasetDateCriteria from "app/main/pages/shared/date-criteria/SourceDatasetDateCriteria";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "../../../store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "../../../derivedfield/store/sourceDerivedFieldsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function SourceWidgetCriteria({
  widgetId,
  widgetData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { watch, getValues, reset } = useFormContext();
  const [criteria, setCriteria] = useState();
  const [esCriteria, setEsCriteria] = useState();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [sourceFieldsLoaded, setSourceFieldsLoaded] = useState(false);
  const [sourceDerivedFieldsLoaded, setSourceDerivedFieldsLoaded] =
    useState(false);
  const [fields, setFields] = useState();
  const [mergedFields, setMergedFields] = useState();

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceViewId = useSelector(
    (state) => state.sources.sourceViewsSlice.activeId
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  const queryValue = _.get(widgetData, "criteria");

  function buildQuery(tree, config, jsonTree, formats) {
    setCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  function onHandleResetDateCriteria() {
    const widgetDataCopy = _.cloneDeep(widgetData);
    widgetDataCopy["dateCriteriaFieldUuId"] = null;
    widgetDataCopy["dateCriteriaFrom"] = null;
    widgetDataCopy["dateCriteriaTo"] = null;
    widgetDataCopy["dateCriteriaTypeCode"] = null;
    widgetDataCopy["dateCriteriaPeriodCode"] = null;
    widgetDataCopy["dateCriteriaNumOfPeriods"] = null;
    handleSave(widgetId, widgetDataCopy);
  }

  function onHandleSave() {
    const sanitizedwidgetData = NtaiUtils.sanitizeFormData(
      getValues("widgetCriteriaForm")
    );

    const widgetDataCopy = _.cloneDeep(widgetData);
    widgetDataCopy["criteria"] = criteria;
    widgetDataCopy["esCriteria"] = esCriteria;
    widgetDataCopy["dateCriteriaTypeCode"] =
      sanitizedwidgetData["dateCriteriaTypeCode"] !== undefined
        ? sanitizedwidgetData["dateCriteriaTypeCode"]
        : null;
    widgetDataCopy["dateCriteriaFieldUuId"] =
      sanitizedwidgetData["dateCriteriaFieldUuId"] !== undefined
        ? sanitizedwidgetData["dateCriteriaFieldUuId"]
        : null;

    widgetDataCopy["dateCriteriaPeriodCode"] =
      sanitizedwidgetData["dateCriteriaPeriodCode"] !== undefined
        ? sanitizedwidgetData["dateCriteriaPeriodCode"]
        : null;

    widgetDataCopy["dateCriteriaNumOfPeriods"] =
      sanitizedwidgetData["dateCriteriaNumOfPeriods"] !== undefined
        ? sanitizedwidgetData["dateCriteriaNumOfPeriods"]
        : null;

    widgetDataCopy["dateCriteriaFrom"] =
      sanitizedwidgetData["dateCriteriaFrom"] !== undefined
        ? sanitizedwidgetData["dateCriteriaFrom"]
        : null;
    widgetDataCopy["dateCriteriaTo"] =
      sanitizedwidgetData["dateCriteriaTo"] !== undefined
        ? sanitizedwidgetData["dateCriteriaTo"]
        : null;

    handleSave(widgetId, widgetDataCopy);
  }

  useEffect(() => {
    if (sourceFieldsLoaded && sourceDerivedFieldsLoaded) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );
      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefId,
        sourceId,
        mergedFields
      );
      setFields({ ...tmpFields });
      setMergedFields([...mergedFields]);
      setFieldsLoaded(true);
    }
  }, [sourceFieldsLoaded, sourceDerivedFieldsLoaded]);

  useEffect(() => {
    if (
      sourceDerivedFieldsStatus &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      setSourceDerivedFieldsLoaded(true);
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceDerivedFieldsStatus]);

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceFieldsStatus.result === "success" &&
      sourceFieldsStatus.method === "getSourceFields"
    ) {
      setSourceFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
    }
  }, [sourceFieldsStatus]);

  useEffect(() => {
    setCriteria(widgetData["criteria"]);
    setEsCriteria(widgetData["esCriteria"]);
    reset({
      widgetCriteriaForm: widgetData,
    });
  }, [widgetData]);

  useEffect(() => {
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, [widgetId]);

  return (
    <React.Fragment>
      {fieldsLoaded && _.isArray(mergedFields) && mergedFields.length > 0 && (
        <NtaiDialogFormPanel
          width="100%"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexBasis: "65%",
              }}
            >
              <NtaiPanel
                padding="0px"
                width="100%"
                header="Query Criteria"
                subheader="Build widget data criteria using source fields"
              >
                {fields && Object.keys(fields).length !== 0 && (
                  <NtaiQueryBuilder
                    fields={fields}
                    buildQuery={buildQuery}
                    value={queryValue}
                  />
                )}
              </NtaiPanel>
            </Box>
            <Box sx={{ flexBasis: "35%", display: "flex" }}>
              <SourceDatasetDateCriteria
                formName="widgetCriteriaForm"
                fields={mergedFields}
                formData={widgetData}
                handleResetDateCriteria={onHandleResetDateCriteria}
              />
            </Box>
          </Box>
        </NtaiDialogFormPanel>
      )}
      {!fieldsLoaded && (
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <NtaiCircularProgress vAlign="center" size={24} />
        </Box>
      )}
    </React.Fragment>
  );
}
