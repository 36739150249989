import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SourceTemplateListingListFields from "./fields/list/SourceRecordListingListFields";
import SourceRecordListingGeneralForm from "./SourceRecordListingGeneralForm";
import SourceTemplateListingTableFields from "./fields/table/SourceRecordListingTableFields";
import { getSourceFields } from "../../store/sourcesSlice";
import { getSourceDerivedFields } from "../../derivedfield/store/sourceDerivedFieldsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function SourceRecordListingForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const data = _.get(dataMap, activeId);

  useEffect(() => {
    reset({
      sourceRecordListingGeneralForm: action === "edit" ? formData : null,
    });
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <NtaiPanel
      width="75%"
      header="Listing"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
    >
      <NtaiTabs>
        <Box label="GENERAL">
          <SourceRecordListingGeneralForm
            handleFormSubmit={handleFormSubmit}
            action={action}
            handleCancel={handleCancel}
          />
        </Box>
        <Box
          disabled={action === "edit" ? false : true}
          chip={
            _.isArray(_.get(data, "sourceRecordListingFields")) &&
            _.get(data, "sourceRecordListingFields").length > 0
              ? data.sourceRecordListingFields.length
              : null
          }
          label="FIELDS"
        >
          {_.get(data, "typeCode") === 1 && (
            <SourceTemplateListingTableFields />
          )}
          {_.get(data, "typeCode") === 2 && (
            <SourceTemplateListingListFields handleCancel={handleCancel} />
          )}
        </Box>
      </NtaiTabs>
    </NtaiPanel>
  );
}
