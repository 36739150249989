import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";

import React, { useEffect } from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";

import { useDispatch, useSelector } from "react-redux";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { getSourcePipelineRefSourceDatasets } from "../../../../ref-datasets/store/sourcePipelineRefSourceDatasetsSlice";
const _ = require("lodash");

export default function SourceDatasetSidePanelRefSourceDatasets() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setOpenDrawer, setSidePanelMenuParam } = React.useContext(
    SourceDatasetManagerContext
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const sourcePipelineRefSourceDatasetsDataMap = useSelector(
    (state) => state.sources.sourcePipelineRefSourceDatasetsSlice.data
  );

  const sourcePipelineRefSourceDatasetsData =
    sourcePipelineRefSourceDatasetsDataMap &&
    !_.isEmpty(sourcePipelineRefSourceDatasetsDataMap)
      ? Object.values(sourcePipelineRefSourceDatasetsDataMap)
      : [];

  function onHandleClick(sourcePipelineId, sourcePipelineRefSourceDatasetId) {
    const sourceRefSourceData =
      sourcePipelineRefSourceDatasetsDataMap[sourcePipelineRefSourceDatasetId];

    setSidePanelMenuParam({
      menuId: 2,
      label: sourceRefSourceData["refSourceDatasetName"],
      sourcePipelineId: sourcePipelineId,
      uuId: sourcePipelineRefSourceDatasetId,
    });

    setOpenDrawer(true);
  }

  useEffect(() => {
    dispatch(
      getSourcePipelineRefSourceDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourcePipelineId: sourcePipelineId,
      })
    );
  }, []);

  return (
    <NtaiAccordion
      title="Reference Datasets"
      titleIcon="database"
      titleSecondary={
        <Chip
          variant="contained"
          size="small"
          label={
            <Typography variant="subtitle2" color="inherit">
              {sourcePipelineRefSourceDatasetsData.length}
            </Typography>
          }
        />
      }
    >
      <Box
        sx={{
          borderTop: theme.general.border1,
          p: theme.spacing(1),
          display: "flex",
          height: "160px",
          width: "100%",
        }}
      >
        <Box
          sx={{ display: "flex", width: "100%", border: theme.general.border1 }}
        >
          {sourcePipelineRefSourceDatasetsData &&
            _.isArray(sourcePipelineRefSourceDatasetsData) &&
            sourcePipelineRefSourceDatasetsData.length > 0 && (
              <Scrollbar autoHide={false}>
                <Box sx={{ pr: theme.spacing(1) }}>
                  <List sx={{ p: 0 }}>
                    {sourcePipelineRefSourceDatasetsData.map((o, i) => (
                      <React.Fragment
                        key={`source-dataset-side-panel-ref-dataset-${i}`}
                      >
                        <ListItemButton
                          onClick={() =>
                            onHandleClick(sourcePipelineId, o["uuId"])
                          }
                        >
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  alignItems: "center",
                                }}
                              >
                                <FontAwesomeIcon icon={faDatabase} />
                                <Typography
                                  sx={{ fontSize: "13px" }}
                                  color="inherit"
                                >
                                  {`${o["refSourceMin"]["abbrev"]} - ${o["refSourceDatasetName"]}`}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              </Scrollbar>
            )}

          {sourcePipelineRefSourceDatasetsData &&
            _.isArray(sourcePipelineRefSourceDatasetsData) &&
            sourcePipelineRefSourceDatasetsData.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No reference datasets available"
                subheader="You can add reference datasets in REF SOURCES section"
                headerVariant="subtitle1"
                headerFontWeight="700"
                subheaderVariant="subtitle2"
              />
            )}
        </Box>
      </Box>
    </NtaiAccordion>
  );
}
