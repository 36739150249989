import { Box } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import NtaiTestVBar from "./bar/NtaiTestVBar";
import NtaiTestHBar from "./bar/NtaiTestHBar";
import NtaiTestPie from "./pie/NtaiTestPie";
import NtaiTestLine from "./line/NtaiTestLine";
import NtaiTestTimeSeries from "./time-series/NtaiTestTimeSeries";
import NtaiTestTreeMap from "./treemap/NtaiTestTreeMap";
import NtaiTestDateHistogram from "./date-hist/NtaiTestDateHistogram";
import NtaiTestTree from "./tree/NtaiTestTree";
import NtaiTestHeatmap from "./heatmap/NtaiTestHeatmap";
import NtaiTestScatter from "./scatter/NtaiTestScatter";
import NtaiTestSankey from "./sankey/NtaiTestSankey";
import NtaiTestForceDirected from "./force-graph/NtaiTestForceDirected";
import NtaiTestGantt from "./gantt/NtaiTestGantt";
import NtaiTestWidgetMetrics from "../metrics/NtaiTestWidgetMetrics";

export default function NtaiTestECharts() {
  return (
    <NtaiTabs>
      <Box label="V Bar">
        <NtaiTestVBar />
      </Box>
      <Box label="H Bar">
        <NtaiTestHBar />
      </Box>
      <Box label="Pie">
        <NtaiTestPie />
      </Box>
      <Box label="Line">
        <NtaiTestLine />
      </Box>
      <Box label="Date Hist">
        <NtaiTestDateHistogram />
      </Box>
      <Box label="Time Series">
        <NtaiTestTimeSeries />
      </Box>
      <Box label="Scatter">
        <NtaiTestScatter />
      </Box>
      <Box label="Treemap">
        <NtaiTestTreeMap />
      </Box>
      <Box label="Tree">
        <NtaiTestTree />
      </Box>
      <Box label="Heatmap">
        <NtaiTestHeatmap />
      </Box>

      <Box label="Sankey">
        <NtaiTestSankey />
      </Box>
      <Box label="Force">
        <NtaiTestForceDirected />
      </Box>
      <Box label="Gantt">
        <NtaiTestGantt />
      </Box>
      <Box label="Metrics">
        <NtaiTestWidgetMetrics />
      </Box>
    </NtaiTabs>
  );
}
