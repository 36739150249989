import { useTheme } from "@mui/material";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataAdapterJobs } from "./store/dataAdapterJobsSlice";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    disablePadding: true,
    ignore: true,
    label: "UUID",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "10%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Date Modified",
    width: "25%",
  },
];

export default function DataAdapterJobList(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const id = props.match.params.id;
  const dataAdaptersDataMap = useSelector(
    (state) => state.core.dataAdaptersSlice.data
  );
  const dataAdapter = dataAdaptersDataMap ? dataAdaptersDataMap[id] : null;

  const dataMap = useSelector((state) => state.core.dataAdapterJobsSlice.data);
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];
  const status = useSelector((state) => state.core.dataAdapterJobsSlice.status);

  function handleToolbarAction(action, selectedIds) {}

  function handleEdit(uuId) {}

  useEffect(() => {
    if (
      status &&
      status.method === "getDataAdapterJobs" &&
      status.result === "success"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getDataAdapterJobs(id));
  }, [id]);

  return (
    <NtaiPage title={`${dataAdapter["name"]} - Job Tracker`} back="/adapters">
      {loaded && data.length > 0 && (
        <NtaiSimpleTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          dialog={true}
          toolbarActions={[{ value: "export", label: "Export" }]}
          handleToolbarAction={handleToolbarAction}
          handleDialogAddEdit={handleEdit}
          title="Jobs"
        />
      )}
      {loaded && data.length === 0 && (
        <NtaiEmptyMessage header="No jobs available" />
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </NtaiPage>
  );
}
