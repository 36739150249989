import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getPackages = createAsyncThunk(
  "packages/getPackages",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/models/packages");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPackage = createAsyncThunk(
  "packages/getPackage",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/models/packages/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createPackage = createAsyncThunk(
  "packages/createPackage",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/models/packages", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePackage = createAsyncThunk(
  "packages/updatePackage",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/models/packages/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePackage = createAsyncThunk(
  "packages/deletePackage",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/models/packages/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const packagesSlice = createSlice({
  name: "packages",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getPackages.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getPackages" };
    },
    [getPackages.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getPackages",
        message: action.payload.message,
      };
    },
    [getPackage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getPackage" };
    },
    [getPackage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getPackage",
        message: action.payload.message,
      };
    },
    [createPackage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createPackage" };
      state.activeId = action.payload.uuId;
    },
    [createPackage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createPackage",
        message: action.payload.message,
      };
    },
    [updatePackage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updatePackage" };
    },
    [updatePackage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updatePackage",
        message: action.payload.message,
      };
    },
    [deletePackage.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deletePackage" };
    },
    [deletePackage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deletePackage",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = packagesSlice.actions;

export default packagesSlice.reducer;
