import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiTreeMapEChart from "./NtaiTreeMapEChart";

const data = [
  {
    value: 10,
    total: 10,
    name: "Asia",
  },
  {
    value: 20,
    total: 20,
    name: "America",
    children: [
      {
        value: 2,
        total: 2,
        name: "California",
      },
      {
        value: 5,
        total: 5,
        name: "Kansas",
      },
      {
        value: 7,
        total: 7,
        name: "Jersey",
      },
    ],
  },
  {
    value: 9,
    total: 9,
    name: "Africa",
  },
  {
    value: 5,
    total: 5,
    name: "Europe",
    children: [
      {
        value: 2,
        total: 2,
        name: "Germany",
      },
      {
        value: 5,
        total: 5,
        name: "France",
      },
      {
        value: 10,
        total: 10,
        name: "Britain",
      },
    ],
  },
  {
    value: 4,
    total: 4,
    name: "Australia",
  },
];

const filteredData = [
  {
    value: 10,
    total: 100,
    offset: 0.2,
    name: "Asia",
    filterFg: true,
  },
  {
    value: 20,
    name: "America",
    total: 100,
    offset: 0.2,
    filterFg: true,
    children: [
      {
        value: 2,
        total: 123,
        offset: 0.5,
        name: "California",
        filterFg: true,
      },
      {
        value: 5,
        total: 145,
        offset: 0.12,
        name: "Kansas",
        filterFg: true,
      },
      {
        value: [7, 10],
        name: "Jersey",
        filterFg: true,
        total: 15,
        offset: 0.4,
      },
    ],
  },
  {
    value: 9,
    total: 10,
    offset: 0.2,
    name: "Africa",
    filterFg: true,
  },
  {
    value: 5,
    total: 34,
    offset: 0.2,
    name: "Europe",
    filterFg: true,
    children: [
      {
        value: 2,
        total: 10,
        offset: 0.2,
        name: "Germany",
        filterFg: true,
      },
      {
        total: 100,
        offset: 0.2,
        value: 8,
        name: "France",
        filterFg: true,
      },
      {
        total: 100,
        offset: 0.6,
        value: 10,
        name: "Britain",
        filterFg: true,
      },
      {
        total: 100,
        offset: 0.4,
        value: 17,
        name: "Germany",
        filterFg: true,
      },
    ],
  },
  {
    value: 4,
    total: 100,
    offset: 0.2,
    name: "Australia",
    filterFg: true,
  },
];

export default function NtaiTestTreeMap() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Treemap chart">
          <NtaiTreeMapEChart
            widgetId="treemap"
            data={filtered ? filteredData : data}
            metadata={{
              filterFg: filtered,
              colorRules: {
                low: {
                  min: 1,
                  max: 2,
                  color: "#eee",
                },
                medium: {
                  min: 3,
                  max: 6,
                  color: "#FF0000",
                },
                high: {
                  min: 10,
                  max: 200,
                  color: "#87CEEB",
                },
              },
              fields: {
                fielduuid1: {
                  fieldOrder: 1,
                  field: {
                    uuId: "countryuuid",
                    label: "Country",
                  },
                },
                fielduuid2: {
                  fieldOrder: 2,
                  field: {
                    uuId: "stateuuid",
                    label: "State",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>

      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
