import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import NtaiTreemapChart from "./amcharts/NtaiTreemapChart";
import useDimensions from "react-cool-dimensions";
import NtaiResizableGrid from "../resizable/NtaiResizableGrid";
import NtaiSourceWidget from "../widgets/source/NtaiSourceWidget";
import NtaiBarChart from "./amcharts/NtaiBarChart";
import NtaiSparkRadialBarChart from "./apexcharts/NtaiSparkRadialBarChart";
import NtaiCytoscape from "./cytoscape/NtaiCytoscape";
import NtaiForceDirectedChart from "./force-graph/NtaiForceDirectedChart";

import { useMeasure } from "react-use"; // or just 'react-use-measure'
import NtaiWidgetMetric from "./metrics/NtaiWidgetMetric";
import NtaiWidgetMetricList from "./metrics/NtaiWidgetMetricList";
const _ = require("lodash");

const treemapData = [
  {
    name: "India",
    children: [
      {
        name: "KA",
        value: 3,
      },
      {
        name: "IN",
        value: 4,
      },
    ],
  },
  {
    name: "France",
    children: [
      {
        name: "FG",
        value: 4,
      },
      {
        name: "YU",
        value: 2,
      },
    ],
  },
];

const histdata = [
  {
    name: "USA",
    count: 12,
  },
  {
    name: "IN",
    count: 1,
  },
  {
    name: "FR",
    count: 34,
  },
  {
    name: "GER",
    count: 16,
  },
  {
    name: "Srilanka",
    count: 11,
  },
  {
    name: "FR7",
    count: 4,
  },
  {
    name: "TY",
    count: 6,
  },
  {
    name: "FGH",
    count: 8,
  },
  {
    name: "kl",
    count: 1,
  },
  {
    name: "Rt",
    count: 5,
  },
];

export default function NtaiTestCharts2() {
  const theme = useTheme();

  function handleClick(t1, t2) {}

  const [ref, { width, height }] = useMeasure();

  console.log("Wi, he", width, height);

  function generateItems() {
    const items = [];

    items.push(
      <Box sx={{ display: "flex", width: "100%" }} key={1}>
        <NtaiSourceWidget
          title="Total count of serious"
          headerActionsFg={false}
        >
          <NtaiWidgetMetric
            data="1234"
            secondaryText="Phase"
            metadata={{
              widget: {
                secondaryText: "Phase",
              },
            }}
          />
        </NtaiSourceWidget>
      </Box>
    );

    items.push(
      <Box sx={{ display: "flex", width: "100%" }} key={2} ref={ref}>
        <NtaiSourceWidget
          title="Total count of serious"
          headerActionsFg={false}
        >
          <NtaiWidgetMetricList
            data={[
              { name: "Phase 1", value: 2.3 },
              { name: "Phase 3", value: 123 },
            ]}
            width={width}
            height={height}
            secondaryText="Phase"
            metadata={{
              widget: {
                secondaryText: "Phase",
              },
            }}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return items;
  }

  return <NtaiResizableGrid items={generateItems()} />;
}
