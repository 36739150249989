import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchReportSearches = createAsyncThunk(
  "searchReportInit/getSearchReportSearches",
  async (params, { rejectWithValue }) => {
    try {
      const response = await server.get(
        "/search",
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchReportInitSlice = createSlice({
  name: "searchReportInit",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchReportSearches.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchReportSearches",
      };
    },
    [getSearchReportSearches.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchReportSearches",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchReportInitSlice.actions;

export default searchReportInitSlice.reducer;
