import React, { useEffect, useMemo, useState } from "react";
import { Box, lighten, useTheme } from "@mui/material";
import {
  gridConfig,
  legendConfig,
  tooltipConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import NtaiBarEChartUtil, {
  hXAxisLabelConfig,
  hYAxisLabelConfig,
  hYAxisLineConfig,
  vXAxisLabelConfig,
  vXAxisLineConfig,
} from "./util/NtaiBarEChartUtil";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: { containLabel: true, left: 20, top: 40, right: 20, bottom: 20 },
  legend: legendConfig,
  tooltip: {
    ...tooltipConfig,
    formatter: function (params) {
      return NtaiBarEChartUtil.getTooltipMultiSeries(params);
    },
  },
  xAxis: {
    type: "value",
    axisLine: hYAxisLineConfig,
    axisLabel: {
      ...hXAxisLabelConfig,
      formatter: (val) => `${NtaiUtils.abbrevNumber(val)}`,
    },
  },
  yAxis: {
    type: "category",
    data: null,
    axisLine: hYAxisLineConfig,
    axisLabel: hYAxisLabelConfig,
  },
  series: null,
};

export default function NtaiHorizontalBarStackedEChart({
  widgetId,
  data,
  metadata,
  handleClick,
  handleContextMenu,
}) {
  const theme = useTheme();
  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  console.log("Data St: ", data);

  function getSourceWidgetFilterFields(action, params) {
    return metadataRef.current && !_.isEmpty(metadataRef.current)
      ? NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
          [1, 2],
          params,
          metadataRef.current,
          action
        )
      : [];
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params));
  }

  function onHandleContextMenu(action, params) {
    //console.log("Clicked right: ", action, params);
    handleContextMenu(action, getSourceWidgetFilterFields(action, params));
  }

  function processValues(data, color) {
    let result = {};

    result["_filterFg"] = metadata["filterFg"];
    result["value"] = data["total"];
    result["_actual"] = metadata["filterFg"] ? data["value"] : data["total"];

    const revisedColor = data.hasOwnProperty("colorValue")
      ? NtaiEChartUtil.getItemStyleColor(
          "bg",
          data["colorValue"] && data["colorValue"].length > 0
            ? data["colorValue"]
            : data["value"],
          color,
          color,
          metadata
        )
      : color;

    result["_color"] = revisedColor;
    result["itemStyle"] = NtaiBarEChartUtil.getItemStyle(
      data,
      metadata,
      revisedColor,
      "h"
    );

    return result;
  }

  useEffect(() => {
    const colors = NtaiEChartUtil.getColorPalette(
      theme,
      data["series"]["data"].length
    );
    const tmpCategories = data["category"]["name"];
    const tmpSubCategories = data["series"]["data"].map((d, i) => {
      let subcats = [];
      data["data"].map((d1, j) => {
        const subcats1 = _.filter(d1["series"], function (v) {
          return v.name === d;
        });
        subcats.push(processValues(subcats1[0]["data"], colors[i]));
      });

      return {
        name: d,
        type: "bar",
        data: subcats,
        stack: "one",
        cursor: "default",
      };
    });

    const copyOption = _.cloneDeep(option);
    copyOption["color"] = colors;
    copyOption["yAxis"]["data"] = tmpCategories;
    copyOption["series"] = tmpSubCategories;
    setOption(copyOption);
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box
      id={widgetId}
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        pb: theme.spacing(1),
      }}
    >
      {/* {option["series"] && chart} */}

      {_.has(data, "data") &&
        _.isArray(data["data"]) &&
        data["data"].length > 0 &&
        option["series"] &&
        chart}
      {_.has(data, "data") &&
        _.isArray(data["data"]) &&
        data["data"].length === 0 && <NtaiChartEmptyMessage />}
    </Box>
  );
}
