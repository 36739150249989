import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSourceDefinition } from "../../../../store/sourceDefinitionsSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

export default function SourceCatalogAssetSourceDefinitionInfo({ uuId }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const dataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const data =
    dataMap && !_.isEmpty(dataMap) && _.has(dataMap, uuId)
      ? dataMap[uuId]
      : null;

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceDefinition" &&
      status.result === "success"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceDefinition(uuId));
  }, [uuId]);

  return (
    <React.Fragment>
      {loaded && data && (
        <>
          <NtaiLabelValue label="Short Name" value={data["abbrev"]} />
          <NtaiLabelValue label="Name" value={data["name"]} />
          <NtaiLabelValue label="Category" value={data["categoryDecode"]} />
          <NtaiLabelValue label="Description" value={data["description"]} />
        </>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </React.Fragment>
  );
}
