import LoginPage from "./LoginPage";

const LoginConfig = {
  routes: [
    {
      path: "/",
      exact: true,
      component: LoginPage,
    },
  ],
};

export default LoginConfig;
