import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { getSourceDatasetField } from "../../../../source/pl/stage/dataset/store/sourceFieldsSlice";

export default function SourceCatalogAssetFieldInfo({ uuId }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const dataMap = useSelector((state) => state.sources.sourceFieldsSlice.data);

  const status = useSelector((state) => state.sources.sourceFieldsSlice.status);

  const data =
    dataMap && !_.isEmpty(dataMap) && _.has(dataMap, uuId)
      ? dataMap[uuId]
      : null;

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceDatasetField" &&
      status.result === "success"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceDatasetField({
        sourceDefId: "*",
        sourceId: "*",
        sourcePipelineId: "*",
        sourceStageId: "*",
        sourceDatasetId: "*",
        uuId: uuId,
      })
    );
  }, [uuId]);

  return (
    <React.Fragment>
      {loaded && data && (
        <>
          <NtaiLabelValue label="Name" value={data["name"]} />
          <NtaiLabelValue label="Label" value={data["label"]} />
          <NtaiLabelValue label="Description" value={data["description"]} />
          <NtaiLabelValue label="Data Type" value={data["dataTypeDecode"]} />
        </>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </React.Fragment>
  );
}
