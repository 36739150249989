import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightTopicSourceRecords = createAsyncThunk(
  "oversightTopicSourceRecords/getOversightTopicSourceRecords",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/oversight/topics/source-records`, {
        params: values,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOversightTopicSourceRecord = createAsyncThunk(
  "oversightTopicSourceRecords/getOversightTopicSourceRecord",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceUuId, sourceRecordUuId } = values;
      const response = await server.get(
        `/oversight/topics/source-records/${sourceUuId}/records/${sourceRecordUuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOversightSourceRecordListings = createAsyncThunk(
  "sourceRecordListings/getOversightSourceRecordListings",
  async (sourceUuIds, { rejectWithValue }) => {
    try {
      const response = await server.post(`/sources/listings`, sourceUuIds);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightTopicSourceRecordsSlice = createSlice({
  name: "oversightTopicSourceRecords",
  initialState: {
    data: {},
    recordListingTemplatesData: {},
    activeId: null,
    status: {},
    recordListingTemplatesStatus: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    clearRecordListingTemplatesStatus: (state, action) => {
      state.recordListingTemplatesStatus = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightTopicSourceRecords.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "sourceRecordDerivedUuId") };
      state.status = {
        result: "success",
        method: "getOversightTopicSourceRecords",
      };
    },
    [getOversightTopicSourceRecords.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightTopicSourceRecords",
        message: action.payload.message,
      };
    },

    [getOversightTopicSourceRecord.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.sourceRecordDerivedUuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getOversightTopicSourceRecord",
      };
    },
    [getOversightTopicSourceRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightTopicSourceRecord",
        message: action.payload.message,
      };
    },

    [getOversightSourceRecordListings.fulfilled]: (state, action) => {
      state.recordListingTemplatesData = action.payload;
      state.recordListingTemplatesStatus = {
        result: "success",
        method: "getOversightSourceRecordListings",
      };
    },
    [getOversightSourceRecordListings.rejected]: (state, action) => {
      state.recordListingTemplatesStatus = {
        result: "error",
        method: "getOversightSourceRecordListings",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, clearRecordListingTemplatesStatus, setActiveId } =
  oversightTopicSourceRecordsSlice.actions;

export default oversightTopicSourceRecordsSlice.reducer;
