import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReportSourceFieldFilterValues = createAsyncThunk(
  "reportSourceFilterFields/getReportSourceFieldFilterValues",
  async (values, { rejectWithValue }) => {
    try {
      const { reportDefinitionId, uuId } = values;
      const response = await server.get(
        `/reports/definitions/${reportDefinitionId}/params/${uuId}/source-field-values`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportSourceFilterFieldsSlice = createSlice({
  name: "reportSourceFilterFields",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = { ...state.status, [action.payload]: null };
    },
  },
  extraReducers: {
    [getReportSourceFieldFilterValues.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        ...state.status,
        [action.payload.uuId]: {
          result: "success",
          method: "getReportSourceFieldFilterValues",
        },
      };
    },
    [getReportSourceFieldFilterValues.rejected]: (state, action) => {
      state.status = {
        ...state.status,
        [action.payload.uuId]: {
          result: "error",
          method: "getReportSourceFieldFilterValues",
          message: action.payload.message,
        },
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  reportSourceFilterFieldsSlice.actions;

export default reportSourceFilterFieldsSlice.reducer;
