import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  updateNotificationDef,
} from "./store/notificationDefsSlice";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import NotificationDefForm from "./NotificationDefForm";
const _ = require("lodash");

export default function NotificationEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector(
    (state) => state.core.notificationDefsSlice.status
  );
  const dataMap = useSelector((state) => state.core.notificationDefsSlice.data);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateNotificationDef({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status.method === "updateNotificationDef" &&
      status.result === "success"
    ) {
      history.push(`/admin/notification-defs`);
      dispatch(clearStatus());
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Notifications / ${formData["name"]}`}
      back="/admin/notification-defs"
    >
      <NtaiForm>
        <NotificationDefForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
