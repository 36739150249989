import { Box, ClickAwayListener, Typography, useTheme } from "@mui/material";
import NtaiInputBase from "@ntai/components/inputs/NtaiInputBase";
import React, { useEffect, useState } from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { resetWarningCache } from "prop-types";
import { SourceDatasetsContext } from "../SourceDatasetList";
import { red } from "@mui/material/colors";
import NtaiErrorMessage from "@ntai/components/errors/NtaiErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const _ = require("lodash");

export default function CodeEditorName({
  stageId,
  datasetId,
  handleUpdateDataset,
}) {
  const theme = useTheme();
  const [editNameFg, setEditNameFg] = useState(false);
  const [segmentName, setSegmentName] = useState();
  const [nameError, setNameError] = useState(false);
  const { getValues, reset } = useFormContext();

  const { activeDatasetId } = React.useContext(SourceDatasetsContext);

  const dataMap = useSelector(
    (state) => state.sources.sourceDatasetsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceDatasetsSlice.status
  );

  const datasetName = _.get(dataMap[datasetId], "name");

  function handleClickAway() {
    if (editNameFg) {
      const formData = NtaiUtils.sanitizeFormData(
        getValues(`${stageId}.segments.${datasetId}`)
      );

      if (formData) {
        if (formData.name && NtaiUtils.isValidSparkDatasetName(formData.name)) {
          handleUpdateDataset(datasetId, formData);
          setSegmentName(formData.name);
        } else {
          setNameError(true);
        }
      }
    }
    setEditNameFg(false);
  }

  useEffect(() => {
    const key = `${stageId}.segments.${datasetId}.name`;
    reset({ key: datasetName });
  }, [datasetName]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          display: "flex",
          width: "30%",
          alignItems: "center",
        }}
        onClick={() =>
          status && status.result === "pending" && activeDatasetId === datasetId
            ? null
            : setEditNameFg(!editNameFg)
        }
      >
        {editNameFg ? (
          <NtaiInputBase
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: theme.spacing(1),
              border: "2px solid blue",
              borderRadius: "4px",
              paddingRight: theme.spacing(1),
              backgroundColor: nameError ? red[100] : null,
            }}
            size="small"
            inputRef={(input) => input && input.focus()}
            name={`${stageId}.segments.${datasetId}.name`}
            rules={{ required: "Name is required" }}
          />
        ) : (
          <Typography variant="h5" color="inherit">
            {datasetName ? datasetName : "Edit Title"}
          </Typography>
        )}
      </Box>
    </ClickAwayListener>
  );
}
