import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getReportScheduledReports,
} from "./store/reportScheduledReportsSlice";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { Box, Button, useTheme } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faList } from "@fortawesome/pro-light-svg-icons";
import history from "@ntai/@history";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    disablePadding: true,
    ignore: true,
    label: "UUID",
  },
  {
    id: "reportDocumentName",
    numeric: false,
    disablePadding: false,
    label: "Report File",
    width: "25%",
    fontWeight: "700",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "25%",
  },
  {
    id: "reportDocumentSize",
    numeric: false,
    disablePadding: false,
    label: "Size",
    width: "10%",
    transformFunc: NtaiUtils.bytesToSize,
  },
  {
    id: "runStatusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "15%",
  },

  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Created",
    width: "25%",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

export default function ReportScheduledReports({
  reportDefinitionId,
  reportId,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const reportDataMap = useSelector((state) => state.reports.reportsSlice.data);
  const reportData =
    reportDataMap && !_.isEmpty(reportDataMap) ? reportDataMap[reportId] : null;

  const reportScheduleId =
    reportData && !_.isEmpty(reportData)
      ? reportData["reportScheduleUuId"]
      : null;

  const dataMap = useSelector(
    (state) => state.reports.reportScheduledReportsSlice.data
  );
  const status = useSelector(
    (state) => state.reports.reportScheduledReportsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : null;

  function handleEditMain() {
    history.push(
      `/reports/${reportDefinitionId}/reports/${reportId}/redirect-main`
    );
  }

  function viewReport(id) {
    history.push(
      `/reports/${reportDefinitionId}/reports/${reportId}/schedules/${reportScheduleId}/reports/${id}`
    );
  }

  function handleToolbarAction(action, selected) {}

  useEffect(() => {
    if (
      status &&
      status.method === "getReportScheduledReports" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getReportScheduledReports({
        reportId: reportId,
        reportScheduleId: reportScheduleId,
      })
    );
  }, [reportId]);

  return (
    <NtaiPage
      padding={2}
      title={`${reportData["name"]} - Scheduled Reports`}
      back="/reports"
      headerActions={
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <Button
            sx={{ fontSize: "11px" }}
            startIcon={<FontAwesomeIcon size="2xs" icon={faList} />}
            size="small"
          >
            REPORT CRITERIA
          </Button>
          <Button
            sx={{ fontSize: "11px" }}
            startIcon={<FontAwesomeIcon size="2xs" icon={faEdit} />}
            size="small"
            onClick={() => handleEditMain()}
          >
            EDIT REPORT
          </Button>
        </Box>
      }
    >
      {loaded && data && _.isArray(data) && data.length > 0 && (
        <NtaiSimpleTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={viewReport}
          toolbarActions={[{ value: "export", label: "Export" }]}
          handleToolbarAction={handleToolbarAction}
          title={`Scheduled Reports (${data.length})`}
        />
      )}

      {loaded && (!data || (_.isArray(data) && data.length === 0)) && (
        <NtaiEmptyMessage header="No scheduled reports available" />
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </NtaiPage>
  );
}
