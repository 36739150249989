const { default: SourcePolicyWrapper } = require("./SourcePolicyWrapper");

const SourcePolicyWrapperConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/policy-home",
      exact: true,
      component: SourcePolicyWrapper,
    },
  ],
};

export default SourcePolicyWrapperConfig;
