import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getObjectInfoGeneralFields = createAsyncThunk(
  "objectInfoGeneralFields/getObjectInfoGeneralFields",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/general-fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateObjectInfoGeneralField = createAsyncThunk(
  "objectInfoGeneralFields/createOrUpdateObjectInfoGeneralField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/general-fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectInfoGeneralField = createAsyncThunk(
  "objectInfoGeneralFields/deleteObjectInfoGeneralField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/listing-fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectInfoGeneralFieldsSlice = createSlice({
  name: "objectInfoGeneralFields",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectInfoGeneralFields.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = {
        result: "success",
        method: "getObjectInfoGeneralFields",
      };
    },
    [getObjectInfoGeneralFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectInfoGeneralFields",
        message: action.payload.message,
      };
    },

    [createOrUpdateObjectInfoGeneralField.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = {
        result: "success",
        method: "createObjectInfoGeneralField",
      };
    },
    [createOrUpdateObjectInfoGeneralField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectInfoGeneralField",
        message: action.payload.message,
      };
    },

    [deleteObjectInfoGeneralField.fulfilled]: (state, action) => {
      state.data = null;
      state.status = {
        result: "success",
        method: "deleteObjectInfoGeneralField",
      };
    },
    [deleteObjectInfoGeneralField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectInfoGeneralField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  objectInfoGeneralFieldsSlice.actions;

export default objectInfoGeneralFieldsSlice.reducer;
