import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getRoles = createAsyncThunk(
  "roles/getRoles",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/roles");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createRole = createAsyncThunk(
  "roles/createRole",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/roles", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/admin/roles/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRoleSources = createAsyncThunk(
  "roles/updateRoleSources",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/roles/${uuId}/sources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/roles/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getRoles.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getRoles" };
    },
    [getRoles.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getRoles",
        message: action.payload.message,
      };
    },
    [createRole.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createRole" };
    },
    [createRole.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createRole",
        message: action.payload.message,
      };
    },
    [updateRole.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateRole" };
    },
    [updateRole.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateRole",
        message: action.payload.message,
      };
    },
    [updateRoleSources.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateRoleSources" };
    },
    [updateRoleSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateRoleSources",
        message: action.payload.message,
      };
    },

    [deleteRole.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteRole" };
    },
    [deleteRole.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteRole",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = rolesSlice.actions;

export default rolesSlice.reducer;
