import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableRow,
  Button,
  useTheme,
  Typography,
  IconButton,
} from "@mui/material";
import NtaiSimpleTableToolbar from "./NtaiSimpleTableToolbar";
import NtaiSimpleTableHead from "./NtaiSimpleTableHead";
import history from "@ntai/@history";
import { makeStyles } from "@mui/styles";
import NtaiSimpleTableHelpText from "./NtaiSimpleTableHelpText";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { MoreVert } from "@mui/icons-material";
import NtaiMenu from "@ntai/components/menus/NtaiDecoMenu";

const _ = require("lodash");

// add comment for demo
const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function NtaiSimpleTable(props) {
  const classes = useStyles();
  const {
    helpText,
    rows,
    emptyRowsContent,
    headCells,
    keyColumn,
    title,
    toolbarStaticActions,
    toolbarActions,
    handleToolbarAction,
    toolbarOtherActions,
    handleToolbarOtherAction,
    rowActions,
    handleRowAction,
    rowOtherActions,
    handleRowOtherAction,
    handleCellAction,
    selectedIds,
    path,
    dialog,
    pageSize,
  } = props;

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openRowOtherActionsMenu = Boolean(anchorEl);
  const [rowOtherActionId, setRowOtherActionId] = useState(null);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const [selected, setSelected] = React.useState(
    selectedIds && _.isArray(selectedIds) ? [...selectedIds] : []
  );

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSize ? pageSize : 5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[keyColumn]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleRedirectEdit = (event, name) => {
    if (event.target.id === "tableCell") {
      history.push(`${path}/${name}/edit`);
    }
  };

  const handleRedirectAdd = () => {
    history.push(`${path}/create`);
  };

  function onHandleDialogAddEdit(e, name) {
    if (!["checkbox", "button", "menu-button"].includes(e.target.id)) {
      props.handleDialogAddEdit(name);
    }
  }

  function formatCellData(row, col) {
    if (
      _.has(col, "transformFunc") &&
      typeof _.get(col, "transformFunc") === "function"
    ) {
      // let colValues = [];
      // col.id.forEach((colId) => {
      //   colValues.push(col[colId]);
      // });

      if (Array.isArray(_.get(col, "id"))) {
        let colValues = [];
        _.get(col, "id").forEach((colId) => {
          colValues.push({ colId: colId, colValue: row[colId] });
        });

        return col.transformFunc(colValues);
      } else {
        return col.transformFunc(_.get(row, col.id));
      }
    } else {
      let cellData = _.get(row, col.id);
      if (_.has(col, "dataType") && col.dataType === "date")
        cellData = NtaiUtils.localDate(cellData);
      return cellData;
    }
  }

  const buildTableData = (row) => {
    let cols = [];
    let count = 0;
    headCells.forEach((col, index) => {
      if (col.ignore === true) return;
      count++;
      cols.push(
        <TableCell
          id="tableCell"
          key={col.id}
          sx={{
            wordBreak: "break-word",
            fontWeight: col.fontWeight ? col.fontWeight : null,
            p: "8px",
            verticalAlign: "top",
          }}
        >
          {formatCellData(row, col)}

          {/* {row[col.id]} */}
        </TableCell>
      );
    });

    return cols;
  };

  const handleRowOtherActionsClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setRowOtherActionId(rowId);
  };
  const handleRowOtherActionsMenuClose = () => {
    setAnchorEl(null);
    setRowOtherActionId(null);
  };

  function onHandleRowOtherAction(action) {
    handleRowOtherAction(action, rowOtherActionId);
  }

  const buildRowOtherActions = (rowId) => {
    return (
      <TableCell sx={{ p: "8px" }}>
        <IconButton
          id="menu-button"
          size="small"
          onClick={(event) => handleRowOtherActionsClick(event, rowId)}
        >
          <MoreVert id="menu-button" />
        </IconButton>
      </TableCell>
    );
  };

  const buildRowOtherActionsMenuItems = () => {
    let items = [];
    Array.isArray(rowOtherActions) &&
      rowOtherActions.length > 0 &&
      rowOtherActions.forEach((rowOtherAction) => {
        items.push({
          label: rowOtherAction.label,
          value: rowOtherAction.value,
        });
      });

    return items;
  };

  function onHandleToolbarAction(actionValue) {
    handleToolbarAction(actionValue, selected);
  }

  function onHandleToolbarOtherAction(actionValue) {
    handleToolbarOtherAction(actionValue);
  }

  function onHandleRowAction(event, action, rowId) {
    handleRowAction(action, rowId);
  }

  function handleHoverOn(rowId) {
    console.log("Hover on for ", rowId);
  }

  function handleHoverOff(rowId) {
    console.log("Hover off for ", rowId);
  }

  React.useEffect(() => {
    if (selectedIds && _.isArray(selectedIds)) {
      setSelected([...selectedIds]);
    }
  }, [selectedIds]);

  return (
    <>
      {rows && rows.length == 0 && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(1),
            paddingTop: theme.spacing(2),
          }}
        >
          {emptyRowsContent}
        </Box>
      )}
      {rows && rows.length > 0 && (
        <Box
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {helpText && <NtaiSimpleTableHelpText content={helpText} />}
          <NtaiSimpleTableToolbar
            title={title}
            path={path}
            onHandleDialogAddEdit={onHandleDialogAddEdit}
            dialog={dialog}
            numSelected={
              Array.isArray(selected) && selected.length > 0
                ? selected.length
                : 0
            }
            toolbarStaticActions={toolbarStaticActions}
            actions={toolbarActions}
            otherActions={toolbarOtherActions}
            onHandleToolbarAction={onHandleToolbarAction}
            onHandleToolbarOtherAction={onHandleToolbarOtherAction}
            rows={rows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <TableContainer>
            <Table
              sx={{
                border: theme.general.border1,
              }}
            >
              <NtaiSimpleTableHead
                numSelected={
                  Array.isArray(selected) && selected.length > 0
                    ? selected.length
                    : 0
                }
                order={order}
                classes={classes}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
                rowActions={rowActions}
                rowOtherActions={rowOtherActions}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row[keyColumn]);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onMouseEnter={(e) => handleHoverOn(row[keyColumn])}
                        // onMouseLeave={(e) => handleHoverOff(row[keyColumn])}
                        onClick={
                          props.dialog
                            ? (e) => onHandleDialogAddEdit(e, row[keyColumn])
                            : (e) => handleRedirectEdit(e, row[keyColumn])
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row[keyColumn]}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer", vAlign: "top" }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{ verticalAlign: "top", p: "8x" }}
                        >
                          <Checkbox
                            id="checkbox"
                            size="small"
                            checked={isItemSelected}
                            onClick={(event) =>
                              handleClick(event, row[keyColumn])
                            }
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        {buildTableData(row)}

                        {rowActions && rowActions.length > 0 && (
                          <TableCell
                            sx={{
                              p: "8x",
                              pt: "6px",
                              verticalAlign: "top",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: theme.spacing(1),
                                alignItems: "center",
                              }}
                            >
                              {rowActions.map((rowAction, index) => (
                                <Button
                                  id="button"
                                  onClick={(e) =>
                                    onHandleRowAction(
                                      e,
                                      rowAction.value,
                                      row[keyColumn]
                                    )
                                  }
                                  variant={
                                    rowAction.variant
                                      ? rowAction.variant
                                      : "outlined"
                                  }
                                  size="small"
                                >
                                  {rowAction.label}
                                </Button>
                              ))}
                            </Box>
                          </TableCell>
                        )}
                        {rowOtherActions &&
                          Array.isArray(rowOtherActions) &&
                          rowOtherActions.length > 0 &&
                          buildRowOtherActions(row[keyColumn])}
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{ fontSize: "10px" }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows && rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <NtaiMenu
        open={openRowOtherActionsMenu}
        anchorEl={anchorEl}
        handleClose={handleRowOtherActionsMenuClose}
        items={
          Array.isArray(rowOtherActions) &&
          rowOtherActions.length > 0 &&
          buildRowOtherActionsMenuItems()
        }
        handleMenuItemSelect={onHandleRowOtherAction}
      />
    </>
  );
}
