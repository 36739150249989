import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourcePolicies = createAsyncThunk(
  "sourcePolicies/getSourcePolicies",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceId, sourceDefId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policies`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourcePolicy = createAsyncThunk(
  "sourcePolicies/getSourcePolicy",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceId, sourceDefId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policies/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourcePolicy = createAsyncThunk(
  "sourcePolicies/createSourcePolicy",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceId, sourceDefId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policies`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourcePolicy = createAsyncThunk(
  "sourcePolicies/updateSourcePolicy",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceId, sourceDefId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policies/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourcePolicy = createAsyncThunk(
  "sourcePolicies/deleteSourcePolicy",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceId, sourceDefId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policies/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePoliciesSlice = createSlice({
  name: "sourcePolicies",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePolicies.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourcePolicies" };
    },
    [getSourcePolicies.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePolicies",
        message: action.payload.message,
      };
    },
    [getSourcePolicy.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourcePolicy" };
    },
    [getSourcePolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePolicy",
        message: action.payload.message,
      };
    },
    [createSourcePolicy.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSourcePolicy" };
    },
    [createSourcePolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourcePolicy",
        message: action.payload.message,
      };
    },
    [updateSourcePolicy.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourcePolicy" };
    },
    [updateSourcePolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourcePolicy",
        message: action.payload.message,
      };
    },
    [deleteSourcePolicy.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourcePolicy" };
    },
    [deleteSourcePolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourcePolicy",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourcePoliciesSlice.actions;

export default sourcePoliciesSlice.reducer;
