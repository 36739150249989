import { faChartPie } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import { getWidgets } from "app/main/pages/core/admin/widget/store/widgetsSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchSourceWidgetDrillContext } from "../../SearchSourceView";
import SourceWidgetUtil from "app/main/pages/core/sourcedefinition/source/view/widgets/util/SourceWidgetUtil";
const _ = require("lodash");

export default function SearchSourceWidgetDrillCharts() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeWidgetIds, setActiveWidgetIds] = useState([]);
  const [searchText, setSearchText] = useState("");

  const { drillWidgetFieldIds, drillWidgetChartId, setDrillWidgetChartId } =
    React.useContext(SearchSourceWidgetDrillContext);

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const widgetsDataMap = useSelector((state) => state.core.widgetsSlice.data);
  const widgets =
    widgetsDataMap && !_.isEmpty(widgetsDataMap)
      ? _.filter(Object.values(widgetsDataMap), function (o) {
          if (searchText && searchText.length > 0) {
            if (o["name"].toLowerCase().includes(searchText.toLowerCase()))
              return true;
            else return false;
          } else {
            return true;
          }
        })
      : [];

  function onHandleWidgetClick(widgetUuId) {
    if (drillWidgetChartId === widgetUuId) setDrillWidgetChartId(null);
    else setDrillWidgetChartId(widgetUuId);
  }

  function handleSearch(txt) {
    setSearchText(txt);
  }

  useEffect(() => {
    // Active - deactivate widget based on fields selection

    const tmpActiveWidgetIds = SourceWidgetUtil.getActiveWidgetIds(
      drillWidgetFieldIds,
      sourceFieldsDataMap,
      sourceDerivedFieldsDataMap,
      widgets
    );
    setActiveWidgetIds(tmpActiveWidgetIds);
  }, [drillWidgetFieldIds]);

  useEffect(() => {
    dispatch(getWidgets());
  }, []);

  return (
    <Box
      sx={{
        flexBasis: "50%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", height: "28px", backgroundColor: grey[100] }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
            p: theme.spacing(1),
          }}
        >
          <FontAwesomeIcon icon={faChartPie} />

          <Typography variant="subtitle2" color="inherit" fontWeight="700">
            {`Select Chart (${
              drillWidgetChartId && drillWidgetChartId.length > 0 ? "1" : "0"
            })`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: theme.spacing(1),
          py: theme.spacing(0.5),
          height: "42px",
        }}
      >
        <NtaiSimpleSearchBar
          placeholder="Search Chart"
          fontSize="11px"
          handleChange={handleSearch}
        />
      </Box>

      <Box
        sx={{ display: "flex", height: "100%", border: theme.general.border1 }}
      >
        {_.isArray(widgets) && widgets.length > 0 && (
          <Scrollbar>
            <List sx={{ p: 0 }}>
              {_.orderBy(widgets, ["name"], ["asc"]).map((widget, index) => (
                <React.Fragment key={`widget-${index}`}>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                      borderRadius: 0,
                      cursor: "default",
                    }}
                    selected={
                      drillWidgetChartId &&
                      drillWidgetChartId.length > 0 &&
                      drillWidgetChartId === widget["uuId"]
                        ? true
                        : false
                    }
                    disabled={
                      activeWidgetIds.includes(widget["uuId"]) ? false : true
                    }
                    onClick={() => onHandleWidgetClick(widget["uuId"])}
                  >
                    <FontAwesomeIcon size="2xs" icon={faChartPie} />
                    <ListItemText primary={widget["name"]} />
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Scrollbar>
        )}
      </Box>
    </Box>
  );
}
