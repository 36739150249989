import { faBars, faEdit, faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Chip,
  Dialog,
  IconButton,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import React, { useState } from "react";
import { SearchContext } from "../../SearchEdit";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SearchCriteriaEdit from "./SearchCriteriaEdit";
const _ = require("lodash");

export default function SearchCriteria() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { searchData } = React.useContext(SearchContext);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: "35%",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "420px",
            borderRadius: "24px",
            px: theme.spacing(2),
            py: "8px",
            alignItems: "center",
            border: theme.general.border1,
            // boxShadow: theme.colors.shadows.cardSm,
            gap: theme.spacing(1),
            "&:hover": {
              cursor: "pointer",
              boxShadow: theme.colors.shadows.cardSm,
            },
          }}
          onClick={(e) => handleClick(e)}
        >
          <FontAwesomeIcon icon={faSearch} />
          <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
            {NtaiUtils.trunc(_.get(searchData, "criteria"), 60)}
          </Typography>
        </Box>
        {/* <Button
          size="small"
          sx={{
            fontWeight: "700",
            gap: "8px",
          }}
          variant="outlined"
          color="inherit"
          // startIcon={<FontAwesomeIcon size="2xs" icon={faSearch} />}
          onClick={(e) => handleClick(e)}
        >
          <FontAwesomeIcon icon={faSearch} />
          {NtaiUtils.trunc(_.get(searchData, "criteria"), 60)}
        </Button> */}
        {/* <IconButton>
          <FontAwesomeIcon size="2xs" icon={faBars} />
        </IconButton> */}
      </Box>
      {open && (
        <NtaiDialog
          open={open}
          handleDialogClose={handleClose}
          size="md"
          title="Edit Criteria"
        >
          <NtaiForm>
            <SearchCriteriaEdit handleClose={handleClose} />
          </NtaiForm>
        </NtaiDialog>
      )}
    </>
  );
}
