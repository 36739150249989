import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React from "react";

const logoIcon =
  process.env.REACT_APP_NTAI_WEB_BASE_URL + "/images/LOGO-Login2.png";

export default function TestLoginForm() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: theme.spacing(4),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: theme.spacing(2),
        justifyContent: "start",
        alignContent: "center",
        alignItems: "center",
        background: theme.colors.secondary.lighter,
        borderRadius: theme.general.borderRadius,
      }}
    >
      <img height="auto" width="120px" src={logoIcon} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="loginForm.userId"
          sx={{ background: "white" }}
          label="User Id"
          placeholder="User Id"
        />
        <NtaiTextField
          type="password"
          sx={{ background: "white" }}
          name="loginForm.password"
          label="Password"
          placeholder="Password"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Button variant="contained" color="primary">
            Submit
          </Button>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography sx={{ justifyContent: "end" }} variant="subtitle2">
              Forgot Password?
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
