import React, { useEffect, useState } from "react";
import SearchSource from "./SearchSource";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../SearchEdit";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { getSearchSources, clearStatus } from "./store/searchSourcesSlice";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import { Typography } from "@mui/material";
import { faList } from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

export default function SearchSourceWrapper() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { searchData } = React.useContext(SearchContext);

  const searchId = _.get(searchData, "uuId");
  const status = useSelector((state) => state.search.searchSourcesSlice.status);
  const dataMap = useSelector((state) => state.search.searchSourcesSlice.data);
  const data = Object.values(dataMap);

  function generateSearchSources() {
    const result = [];

    if (Array.isArray(data) && data.length > 0) {
      data.forEach((searchSourceData, index) => {
        result.push(
          <SearchSource
            key={`search-source-${index}`}
            selected={
              _.get(searchSourceData, "rank") === selectedTabIndex + 1
                ? true
                : false
            }
            searchSourceData={searchSourceData}
            label={
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {_.get(
                  searchSourceData,
                  "searchSourceDefinition.name"
                ).toUpperCase()}
              </Typography>
            }
            chip={NtaiUtils.abbrevNumber(_.get(searchSourceData, "totalHits"))}
            // action={{
            //   id: 1,
            //   icon: faList,
            //   handleActionClick: handleActionClick,
            // }}
          />
        );
      });
    }

    return result;
  }

  function handleTabChange(index) {
    setSelectedTabIndex(index);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSources"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSearchSources(searchId));
  }, []);

  return (
    <React.Fragment>
      {loaded && data.length > 1 && (
        <NtaiTabs
          sx={{ zIndex: 0 }}
          variant="fullWidth"
          handleChange={handleTabChange}
        >
          {generateSearchSources()}
        </NtaiTabs>
      )}
      {loaded && data.length === 1 && (
        <React.Fragment>{generateSearchSources()}</React.Fragment>
      )}

      {!loaded && <NtaiCircularProgress size={36} />}
    </React.Fragment>
  );
}
