import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import Scrollbar from "@ntai/components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

const toolsMenuItems = [
  {
    value: "1",
    label: "Import",
  },
  {
    value: "2",
    label: "Export",
  },
];

export default function ToolsSidebar() {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        flexBasis: "15%",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        borderRight: theme.general.border1,
      }}
    >
      <Box sx={{ p: theme.spacing(2), height: "54px", display: "flex" }}>
        <NtaiSimpleSearchBar placeholder="Search..." height="36px" />
      </Box>
      <Box
        sx={{
          pt: theme.spacing(1),
          height: `calc(100% - 54px)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MenuList sx={{ width: "100%", p: 0 }}>
          {toolsMenuItems.map((item, index) => {
            return (
              <MenuItem
                sx={{ pl: theme.spacing(2), py: theme.spacing(0.75) }}
                key={`tools-menu-${index}`}
              >
                <ListItemText>
                  <Typography variant="subtitle2" color="inherit">
                    {item.label}
                  </Typography>
                </ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
    </Box>
  );
}
