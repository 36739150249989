import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Link,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { faCloudUpload } from "@fortawesome/pro-light-svg-icons";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import { blue } from "@mui/material/colors";
const _ = require("lodash");

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function SearchSourceRecordDocumentForm({
  action,
  handleSave,
  handleDownload,
  handleCancel,
  formData,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const [selectedFile, setSelectedFile] = useState();
  const [displayFile, setDisplayFile] = useState();

  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchSourceRecordDocumentForm.document")
    );

    const revisedFormData = {
      recordId: selectedRecord["recordId"],
      document: sanitizedFormData,
    };

    const fileUploadFormData = new FormData();
    fileUploadFormData.append("data", JSON.stringify(revisedFormData));
    fileUploadFormData.append("files", selectedFile);
    handleSave(fileUploadFormData);
  }

  function handleCapture(e) {
    setSelectedFile(e.target.files[0]);
  }

  useEffect(() => {
    if (selectedFile) {
      setDisplayFile({
        name: selectedFile["name"],
        size: selectedFile["size"],
      });
    }
  }, [selectedFile]);

  useEffect(() => {
    reset({
      searchSourceRecordDocumentForm: action === "edit" ? formData : {},
    });

    if (action === "edit" && formData["document"]["title"]) {
      // setValue(
      //   "searchSourceRecordDocumentForm.document.title",
      //   formData["document"]["title"]
      // );
      // setValue(
      //   "searchSourceRecordDocumentForm.document.description",
      //   formData["document"]["description"]
      // );
      setDisplayFile({
        name: formData["document"]["fileName"],
        size: formData["document"]["fileSize"],
      });
    }
  }, [formData]);

  return (
    <NtaiFormPanel
      width="75%"
      header={`${action === "add" ? "Add" : "Edit"} Document`}
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="searchSourceRecordDocumentForm.document.title"
        label="Title*"
        rules={{ required: "Document title is required" }}
      />
      <NtaiTextField
        name="searchSourceRecordDocumentForm.document.description"
        label="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
      <Box
        sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
      >
        <Button
          size="small"
          component="label"
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
        >
          Upload File
          <VisuallyHiddenInput type="file" onChange={(e) => handleCapture(e)} />
        </Button>
        {displayFile && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="subtitle1">
              <Link
                sx={{
                  fontWeight: "500",
                  color: "inherit",
                  color: blue[700],
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={(e) =>
                  handleDownload(_.get(formData, "document.uuId"))
                }
              >
                {displayFile["name"]}
              </Link>
            </Typography>
            <Typography variant="subtitle1">
              ({NtaiUtils.bytesToSize(displayFile["size"])})
            </Typography>
          </Box>
        )}
      </Box>
    </NtaiFormPanel>
  );
}
