import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import {
  clearStatus,
  deleteAlertDefinition,
  getAlertDefinitions,
} from "./store/alertDefinitionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faClock,
  faPause,
  faSave,
  faWarning,
} from "@fortawesome/pro-light-svg-icons";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

function transformStatus(statusCode) {
  if (statusCode === 1) return <FontAwesomeIcon icon={faSave} />;
  if (statusCode === 2) return <FontAwesomeIcon icon={faClock} />;
  if (statusCode === 3) return <FontAwesomeIcon icon={faPause} />;
  if (statusCode === 4) return <FontAwesomeIcon icon={faArchive} />;
  if (statusCode === 5) return <FontAwesomeIcon icon={faWarning} />;
}

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "20%",
    fontWeight: "700",
  },
  {
    id: "sourceDisplayName",
    numeric: false,
    disablePadding: false,
    label: "Source",
    width: "25%",
  },
  {
    id: "scheduleCronExpression",
    numeric: false,
    disablePadding: false,
    label: "Frequency",
    transformFunc: NtaiUtils.cronExpressionToString,
    width: "15%",
  },
  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Date Modified",
    transformFunc: NtaiUtils.formatDateCol,
    width: "15%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified by",
    width: "15%",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

export default function AlertDefinitionList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [action, setAction] = useState();
  const [loaded, setLoaded] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );
  const status = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );
  const data = _.orderBy(Object.values(dataMap), ["dateModified"], ["desc"]);

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") {
      setAction("delete");
      confirmDelete();
    }
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteAlertDefinition(id));
    });
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    dispatch(getAlertDefinitions());
    dispatch(getCodes(CODELIST_CODES.MON_ALERT_DEF_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.MON_ALERT_DEF_CATEGORY_CODE));
  }, []);

  useEffect(() => {
    if (
      status.result === "success" &&
      status.method === "getAlertDefinitions"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status.result === "success" &&
      status.method === "deleteAlertDefinition"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Alert definition(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <NtaiPage padding={2} title="Alert Definitions">
        {loaded && (
          <NtaiCrudTable
            helpText="Define, manage and schedule alerts on sources of interest"
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path="/monitor/definitions"
            handleToolbarAction={handleToolbarAction}
            handleDelete={confirmDelete}
            title="Alert Definition"
          />
        )}
        {!loaded && <NtaiCircularProgress size={24} />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
