import SourceInList from "./SourceInList";
import SourceInWrapper from "./SourceInWrapper";
import SourceInStoreDatasetCreate from "./dataset/SourceInStoreDatasetCreate";
import SourceInStoreDatasetEdit from "./dataset/SourceInStoreDatasetEdit";
import SourceInStoreDatasetList from "./dataset/SourceInStoreDatasetList";
import SourceInStoreOcrPage from "./ocr/SourceInStoreOcrPage";

const SourceInConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/ins",
      exact: true,
      component: SourceInList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/ins/:id/files",
      exact: true,
      component: SourceInWrapper,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/ins/:id/datasets",
      exact: true,
      component: SourceInStoreDatasetList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/ins/:sourceinstoreid/datasets/create",
      exact: true,
      component: SourceInStoreDatasetCreate,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/ins/:sourceinstoreid/datasets/:id/edit",
      exact: true,
      component: SourceInStoreDatasetEdit,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/ins/:sourceinid/ocr-pages",
      exact: true,
      component: SourceInStoreOcrPage,
    },
  ],
};

export default SourceInConfig;
