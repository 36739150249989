import {
  Avatar,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import withUserPermission from "@ntai/components/rbac/withUserPermission";
import React from "react";

function SearchModeRbac({
  key,
  id,
  handleSearchSelect,
  avatarIcon,
  label,
  description,
  permissions,
}) {
  const theme = useTheme();

  return (
    <ListItem
      key={key}
      sx={{
        py: theme.spacing(1),
        px: theme.spacing(2),
        width: "100%",
        display: "flex",
        borderRadius: "0px",
        border: theme.general.border1,
        gap: theme.spacing(1),
        "&:hover": {
          background: grey[100],
          cursor: "pointer",
        },
      }}
      onClick={() => handleSearchSelect(id)}
    >
      <Avatar sx={{ height: 56, width: 56 }}>{avatarIcon}</Avatar>

      <ListItemText
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: theme.general.borderRadius,
        }}
        primary={
          <Typography variant="h6" fontWeight="700">
            {label}
          </Typography>
        }
        secondary={<Typography variant="subtitle2">{description}</Typography>}
      />
    </ListItem>
  );
}

export default withUserPermission(SearchModeRbac);
