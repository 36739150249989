import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceWidgetFieldColors = createAsyncThunk(
  "sourceWidgetFieldColors/getSourceWidgetFieldColors",
  async (values) => {
    const {
      sourceDefId,
      sourceId,
      viewId,
      sourceWidgetId,
      sourceWidgetFieldId,
    } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets/${sourceWidgetId}/fields/${sourceWidgetFieldId}/colors`
    );
    return response.data;
  }
);

export const createSourceWidgetFieldColor = createAsyncThunk(
  "sourceWidgetFieldColors/createSourceWidgetFieldColor",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        viewId,
        sourceWidgetId,
        sourceWidgetFieldId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets/${sourceWidgetId}/fields/${sourceWidgetFieldId}/colors`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceWidgetFieldColor = createAsyncThunk(
  "sourceWidgetFieldColors/updateSourceWidgetFieldColor",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        viewId,
        sourceWidgetId,
        sourceWidgetFieldId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets/${sourceWidgetId}/fields/${sourceWidgetFieldId}/colors/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceWidgetFieldColor = createAsyncThunk(
  "sourceWidgetFieldColors/deleteSourceWidgetFieldColor",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        viewId,
        sourceWidgetId,
        sourceWidgetFieldId,
        uuId,
      } = values;

      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets/${sourceWidgetId}/fields/${sourceWidgetFieldId}/colors/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceWidgetFieldColorsSlice = createSlice({
  name: "sourceWidgetFieldColors",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceWidgetFieldColors.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "succes",
        method: "getSourceWidgetFieldColors",
      };
    },
    [getSourceWidgetFieldColors.rejected]: (state, action) => {
      state.status = {
        result: "succes",
        method: "getSourceWidgetFieldColors",
        mesage: action.payload.mesage,
      };
    },
    [createSourceWidgetFieldColor.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "succes",
        method: "createSourceWidgetFieldColor",
      };
    },
    [createSourceWidgetFieldColor.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceWidgetFieldColor",
        mesage: action.payload.mesage,
      };
    },
    [updateSourceWidgetFieldColor.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "succes",
        method: "updateSourceWidgetFieldColor",
      };
    },
    [updateSourceWidgetFieldColor.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceWidgetFieldColor",
        mesage: action.payload.mesage,
      };
    },
    [deleteSourceWidgetFieldColor.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "succes",
        method: "deleteSourceWidgetFieldColor",
      };
    },
    [deleteSourceWidgetFieldColor.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceWidgetFieldColor",
        mesage: action.payload.mesage,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceWidgetFieldColorsSlice.actions;

export default sourceWidgetFieldColorsSlice.reducer;
