import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { SearchSourceContext } from "../../../SearchSource";
import SearchSourceWidgetManagerFieldSelection from "./SearchSourceWidgetManagerFieldSelection";
import SearchSourceWidgetManagerChartSelection from "./SearchSourceWidgetManagerChartSelection";
import {
  clearStatus,
  createSearchSourceWidget,
} from "./store/searchSourceWidgetManagerSlice";
import { SearchContext } from "../../../../SearchEdit";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export const SearchSourceWidgetManagerContext = React.createContext();

export default function SearchSourceWidgetManager({ handleDialogClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const [selectedFields, setSelectedFields] = useState([]);
  const [clearFields, setClearFields] = useState(false);
  const [sourceFieldsLoaded, setSourceFieldsLoaded] = useState(false);
  const [sourceDerivedFieldsLoaded, setSourceDerivedFieldsLoaded] =
    useState(false);

  const { searchData } = React.useContext(SearchContext);
  const searchId = _.get(searchData, "uuId");

  const { searchSourceData, setChangeView } =
    React.useContext(SearchSourceContext);
  const searchSourceId = _.get(searchSourceData, "uuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceViewUuId = _.get(
    selectedSearchSourceData,
    "selectedSourceViewUuId"
  );

  const sourceDefId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.uuId"
  );
  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const sourceWidgetStatus = useSelector(
    (state) => state.sources.sourceWidgetsSlice.status
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const status = useSelector(
    (state) => state.search.searchSourceWidgetManagerSlice.status
  );

  const statusSourceFields = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const statusDerivedFields = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  function resetFieldsSelection() {
    // reset({ viewForm: {} });
    setSelectedFields([]);
    setClearFields(true);
  }

  function handleAddWidget(widgetUuId) {
    // const selectedFields = NtaiUtils.sanitizeFormData(
    //   getValues("viewForm.fields")
    // );

    let widgetFields = [];
    let fieldNames = [];
    let c = 1;

    // if (Array.isArray(selectedFields) && selectedFields.length == 0) return;

    selectedFields.forEach((key) => {
      widgetFields.push({ sourceFieldUuId: key, fieldOrder: c });
      fieldNames.push(_.get(sourceFieldsDataMap[key], "name"));
      c = c + 1;
    });

    const widgetName = NtaiUtils.trunc(fieldNames.join("_"), 95);

    dispatch(
      createSearchSourceWidget({
        searchId: searchId,
        searchSourceId: searchSourceId,
        sourceViewId: sourceViewUuId,
        formData: {
          hiddenFg: 0,
          sourceWidget: {
            name: widgetName,
            widgetUuId: widgetUuId,
            sourceWidgetFields: widgetFields,
          },
        },
      })
    );
  }

  // useEffect(() => {
  //   if (status.result === "success" && status.method === "deleteSourceWidget") {
  //     dispatch(clearStatus());
  //   }

  //   if (
  //     status.result === "success" &&
  //     (status.method === "createSourceWidget" ||
  //       status.method === "updateSourceWidget")
  //   ) {
  //     dispatch(clearStatus());
  //     resetFieldsSelection();
  //   }
  // }, [status]);

  // useEffect(() => {
  //   dispatch(setActiveId(viewId));
  // }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "createSearchSourceWidget"
    ) {
      setChangeView(true);
      dispatch(clearStatus());
      handleDialogClose();
    }
  }, [status]);

  useEffect(() => {
    if (
      statusDerivedFields &&
      statusDerivedFields.result === "success" &&
      statusDerivedFields.method === "getSourceDerivedFields"
    ) {
      setSourceDerivedFieldsLoaded(true);
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [statusDerivedFields]);

  useEffect(() => {
    if (
      statusSourceFields &&
      statusSourceFields.result === "success" &&
      statusSourceFields.method === "getSourceFields"
    ) {
      setSourceFieldsLoaded(true);
      dispatch(clearSourceFieldsSliceStatus());
    }
  }, [statusSourceFields]);

  useEffect(() => {
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <>
      <SearchSourceWidgetManagerContext.Provider
        value={{
          selectedFields,
          setSelectedFields,
          clearFields,
          setClearFields,
        }}
      >
        <NtaiPanel
          width="100%"
          subheader="Select fields, chart type and click add to add a new widget"
        >
          <Box
            sx={{
              border: theme.general.border1,
              display: "flex",
              height: "420px",
              width: "100%",
              borderRadius: theme.general.borderRadius,
            }}
          >
            {sourceFieldsLoaded && sourceDerivedFieldsLoaded && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    flexBasis: "40%",
                    height: "100%",
                    flexDirection: "column",
                    borderRight: theme.general.border1,
                  }}
                >
                  <SearchSourceWidgetManagerFieldSelection
                    resetFieldsSelection={resetFieldsSelection}
                  />
                </Box>
                <Box
                  sx={{
                    flexBasis: "60%",
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    borderRight: theme.general.border1,
                  }}
                >
                  <SearchSourceWidgetManagerChartSelection
                    handleAddWidget={handleAddWidget}
                  />
                </Box>
              </Box>
            )}
            {(!sourceFieldsLoaded || !sourceDerivedFieldsLoaded) && (
              <NtaiCircularProgress size={24} />
            )}
          </Box>
        </NtaiPanel>
      </SearchSourceWidgetManagerContext.Provider>
    </>
  );
}
