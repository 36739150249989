import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import {
  getSourceFilterSchemes,
  clearStatus as clearSourceFilterSchemeSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/template/filter/store/sourceFilterSchemesSlice";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SearchSourceContext } from "../../SearchSource";
import SearchFilterSchemeField from "./SearchFilterSchemeField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchContext } from "../../../SearchEdit";
import {
  clearStatus as clearSearchSourcesSliceStatus,
  createOrUpdateFilterScheme,
} from "../../store/searchSourcesSlice";
import {
  getSearchSourceFilterSchemes,
  clearStatus as clearSearchSourceFilterSchemesStatus,
} from "./store/searchSourceFilterSchemesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFilterList } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import SearchSourceFilterUtil from "./SearchSourceFilterUtil";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

export default function SearchAdvFilterScheme() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [filterLoaded, setFilterLoaded] = useState(false);
  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData, reload, setReload, setRefresh } =
    React.useContext(SearchSourceContext);
  const [
    selectedSearchSourceFilterSchemeUuId,
    setSelectedSearchSourceFilterSchemeUuId,
  ] = useState();

  const [selectedSourceFilterSchemeUuId, setSelectedSourceFilterSchemeUuId] =
    useState();

  const [filterSchemeFields, setFilterSchemeFields] = useState([]);
  const [filterSchemeFieldsLoaded, setFilterSchemeFieldsLoaded] =
    useState(false);

  const sourceDefId = _.get(searchSourceData, "searchSourceDefinition.uuId");
  const sourceId = _.get(searchSourceData, "searchSourceDefinition.sourceUuId");
  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "uuId");

  const searchSourcesSliceStatus = useSelector(
    (state) => state.search.searchSourcesSlice.status
  );

  const sourceFilterSchemesDataMap = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.data
  );

  const sourceFilterSchemesSliceStatus = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.status
  );

  // lib dataset filter scheme vs others
  const sourceFilterSchemes =
    searchData["typeCode"] !== 4
      ? _.filter(Object.values(sourceFilterSchemesDataMap), {
          sourceUuId: sourceId,
        })
      : _.filter(Object.values(sourceFilterSchemesDataMap), function (o) {
          return o["uuId"] ===
            searchSourceData["sourceDomainDefaultFilterSchemeUuId"]
            ? true
            : false;
        });

  const sourceFilterSchemeFieldsArr =
    _.isArray(sourceFilterSchemes) && sourceFilterSchemes.length > 0
      ? _.get(sourceFilterSchemes[0], "fields")
      : [];

  const sourceFilterSchemeFields = {
    ..._.mapKeys(sourceFilterSchemeFieldsArr, "sourceFieldUuId"),
  };

  const searchSourceFilterSchemesDataMap = useSelector(
    (state) => state.search.searchSourceFilterSchemesSlice.data
  );

  const searchSourceFilterSchemesData =
    searchSourceFilterSchemesDataMap &&
    !_.isEmpty(searchSourceFilterSchemesDataMap)
      ? Object.values(searchSourceFilterSchemesDataMap)
      : [];

  const searchSourceFilterSchemesStatus = useSelector(
    (state) => state.search.searchSourceFilterSchemesSlice.status
  );

  function submitFilterForm() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("filterForm")
    );

    const tmpSearchSourceFilterSchemeFields =
      SearchSourceFilterUtil.processFilterFields(
        sanitizedFormData,
        sourceFilterSchemeFields
      );

    dispatch(
      createOrUpdateFilterScheme({
        searchId: searchId,
        searchSourceId: searchSourceId,
        formData: {
          sourceFilterSchemeUuId:
            Array.isArray(sourceFilterSchemes) &&
            _.has(sourceFilterSchemes[0], "uuId")
              ? sourceFilterSchemes[0].uuId
              : null,
          searchSourceFilterSchemeFields: tmpSearchSourceFilterSchemeFields,
        },
      })
    );
  }

  useEffect(() => {
    if (selectedSourceFilterSchemeUuId) {
      if ([2, 3, 5].includes(searchData["typeCode"])) {
        dispatch(
          getSourceFilterSchemes({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            params: {
              defaultFg: 1,
              activeFg: 1,
              sourceUuId: sourceId,
            },
          })
        );
      } else if (searchData["typeCode"] === 4) {
        dispatch(
          getSourceFilterSchemes({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            params: {
              uuId: searchSourceData["sourceDomainDefaultFilterSchemeUuId"],
              sourceUuId: sourceId,
            },
          })
        );
      } else if (searchData["typeCode"] === 6) {
        dispatch(
          getSourceFilterSchemes({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            params: {
              uuId: searchSourceData["selectedSourceFilterSchemeUuId"],
              sourceUuId: sourceId,
            },
          })
        );
      }
    }
  }, [selectedSourceFilterSchemeUuId]);

  useEffect(() => {
    if (
      searchSourcesSliceStatus &&
      searchSourcesSliceStatus.method === "createOrUpdateFilterScheme" &&
      searchSourcesSliceStatus.result === "success"
    ) {
      dispatch(
        getSearchSourceFilterSchemes({
          searchId: searchId,
          searchSourceId: searchSourceId,
          params: { searchSourceUuId: searchSourceId, selectedFg: 1 },
        })
      );

      setReload(reload + 1);
      setRefresh(true);
      dispatch(clearSearchSourcesSliceStatus());
    }
  }, [searchSourcesSliceStatus]);

  useEffect(() => {
    if (
      searchSourceFilterSchemesStatus &&
      searchSourceFilterSchemesStatus.method ===
        "getSearchSourceFilterSchemes" &&
      searchSourceFilterSchemesStatus.result === "success"
    ) {
      const selectedFilterSchemes = _.filter(
        searchSourceFilterSchemesData,
        function (o, i) {
          return o.selectedFg === 1;
        }
      );

      if (
        _.isArray(selectedFilterSchemes) &&
        selectedFilterSchemes.length > 0
      ) {
        setSelectedSourceFilterSchemeUuId(
          selectedFilterSchemes[0].sourceFilterSchemeUuId
        );
        setSelectedSearchSourceFilterSchemeUuId(selectedFilterSchemes[0].uuId);

        // const searchSourceFilterSchemeFormObj = !_.isEmpty(
        //   searchSourceFilterSchemesDataMap
        // )
        //   ? _.get(
        //       searchSourceFilterSchemesDataMap,
        //       selectedSearchSourceFilterSchemeUuId
        //     )
        //   : null;

        // if (
        //   searchSourceFilterSchemeFormObj &&
        //   !_.isEmpty(searchSourceFilterSchemeFormObj)
        // ) {
        //   const filterFormObj = SearchSourceFilterUtil.resetFilterForm(
        //     sourceFilterSchemeFields,
        //     searchSourceFilterSchemeFormObj
        //   );

        //   console.log("Resetting again: ", filterFormObj);

        //   reset({ filterForm: filterFormObj });
        // }
      } else {
        setSelectedSourceFilterSchemeUuId("not-available");
        setSelectedSearchSourceFilterSchemeUuId("not-available");
      }

      dispatch(clearSearchSourceFilterSchemesStatus());
    }
  }, [searchSourceFilterSchemesStatus]);

  useEffect(() => {
    dispatch(
      getSearchSourceFilterSchemes({
        searchId: searchId,
        searchSourceId: searchSourceId,
        params: { searchSourceUuId: searchSourceId, selectedFg: 1 },
      })
    );
  }, [searchSourceId]);

  useEffect(() => {
    if (
      selectedSearchSourceFilterSchemeUuId &&
      !_.isEmpty(sourceFilterSchemeFields)
    ) {
      const searchSourceFilterSchemeFormObj = !_.isEmpty(
        searchSourceFilterSchemesDataMap
      )
        ? _.get(
            searchSourceFilterSchemesDataMap,
            selectedSearchSourceFilterSchemeUuId
          )
        : null;

      if (
        searchSourceFilterSchemeFormObj &&
        !_.isEmpty(searchSourceFilterSchemeFormObj)
      ) {
        const filterFormObj = SearchSourceFilterUtil.resetFilterForm(
          sourceFilterSchemeFields,
          searchSourceFilterSchemeFormObj
        );

        setValue("filterForm", filterFormObj);
      }

      if (
        sourceFilterSchemesSliceStatus &&
        sourceFilterSchemesSliceStatus.method ===
          "getSearchSourceFilterSchemes" &&
        sourceFilterSchemesSliceStatus.result === "success"
      ) {
        setFilterLoaded(true);
        dispatch(clearSourceFilterSchemeSliceStatus());
      }
    }
  }, [sourceFilterSchemesSliceStatus, selectedSearchSourceFilterSchemeUuId]);

  // useEffect(() => {
  //   if (
  //     searchSourcesSliceStatus &&
  //     searchSourcesSliceStatus.method === "createOrUpdateFilterScheme" &&
  //     searchSourcesSliceStatus.result === "success"
  //   ) {
  //     setReload(reload + 1);
  //     setRefresh(true);
  //     dispatch(clearSearchSourcesSliceStatus());
  //   }
  // }, [searchSourcesSliceStatus]);

  return (
    <Box
      sx={{
        flexBasis: "15%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderLeft: theme.general.border1,
      }}
    >
      <Box
        id="content-header"
        sx={{
          height: "44px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
          justifyContent: "space-between",
          px: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight="700" variant="body2">
            Filters
          </Typography>

          {/* <IconButton>
            <FontAwesomeIcon size="xs" icon={faFilterList} />
          </IconButton> */}
        </Box>
      </Box>
      {selectedSourceFilterSchemeUuId &&
        selectedSearchSourceFilterSchemeUuId &&
        _.isArray(sourceFilterSchemes) &&
        sourceFilterSchemes.length > 0 &&
        sourceFilterSchemes[0].fields &&
        sourceFilterSchemes[0].fields.length > 0 && (
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                bottom: "54px",
                width: "100%",
                height: `calc(100% - 98px)`,
              }}
            >
              <Scrollbar autoHide={true}>
                {selectedSourceFilterSchemeUuId &&
                  selectedSearchSourceFilterSchemeUuId &&
                  _.isArray(sourceFilterSchemes) &&
                  sourceFilterSchemes.length > 0 &&
                  sourceFilterSchemes[0].fields &&
                  sourceFilterSchemes[0].fields.length > 0 &&
                  sourceFilterSchemes[0].fields.map((field, i) => (
                    <React.Fragment
                      key={`search-source-filter-panel-field-${i}`}
                    >
                      <SearchFilterSchemeField
                        key={`search-source-filter-panel-field-${i}`}
                        filterSchemeId={sourceFilterSchemes[0].uuId}
                        field={field}
                      />
                      <Divider />
                    </React.Fragment>
                  ))}
                {searchSourceFilterSchemesStatus &&
                  searchSourceFilterSchemesStatus.result === "success" &&
                  searchSourceFilterSchemesStatus.method ===
                    "getSearchSourceFilterSchemes" &&
                  _.isArray(sourceFilterSchemes) &&
                  sourceFilterSchemes.length === 0 && (
                    <NtaiEmptyMessage
                      vAlign="center"
                      headerVariant="subtitle1"
                      headerFontWeight={700}
                      header="Filter schemes not available"
                      subheaderVariant="subtitle2"
                      subheader="Configure filter scheme for the selected source"
                    />
                  )}
                {searchSourceFilterSchemesStatus &&
                  searchSourceFilterSchemesStatus.result === "pending" &&
                  searchSourceFilterSchemesStatus.method ===
                    "getSearchSourceFilterSchemes" && (
                    <NtaiCircularProgress vAlign="center" size={24} />
                  )}
              </Scrollbar>
            </Box>
            <Box
              sx={{
                // boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
                height: "54px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                px: theme.spacing(2),
              }}
            >
              <Button
                sx={{ display: "flex", gap: theme.spacing(1) }}
                variant="contained"
                size="small"
                onClick={() => submitFilterForm()}
              >
                <FontAwesomeIcon icon={faFilter} />
                APPLY FILTER
              </Button>
            </Box>
          </React.Fragment>
        )}
      {_.isArray(sourceFilterSchemes) && sourceFilterSchemes.length === 0 && (
        <NtaiEmptyMessage
          vAlign="center"
          header="Filter scheme not available"
          subheader="Configure filter scheme under source - templates section"
        />
      )}
    </Box>
  );
}
