import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  clearStatus,
  createSourceRecordLabel,
  setActiveId,
} from "./store/sourceRecordLabelsSlice";
import SourceRecordLabelForm from "./SourceRecordLabelForm";

export default function SourceRecordLabelCreate({
  handleCancel,
  redirectToEdit,
}) {
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.status
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.activeId
  );

  function handleSave(formData) {
    dispatch(
      createSourceRecordLabel({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceRecordLabel" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      redirectToEdit(activeId);
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(null));
  }, []);

  return (
    <NtaiForm>
      <SourceRecordLabelForm
        action="add"
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
