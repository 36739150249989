import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiDecoMenu from "@ntai/components/menus/NtaiDecoMenu";

import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import ThemeSelector from "./theme/ThemeSelector";
import { persistor } from "../../../store";
import History from "@ntai/@history";
import { setAppHeader } from "app/store/ntai/navigationSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../core/admin/user/store/usersSlice";
import { logout } from "app/auth/store/loginSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

function getInitial(firstName, lastName) {
  if (firstName && lastName)
    return (firstName?.charAt(0) + lastName?.charAt(0)).toUpperCase();
  else return "NA";
}

export default function UserPreferencePanel() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const [openThemeDialog, setOpenThemeDialog] = React.useState(false);
  const open = Boolean(anchorEl);

  const user = useSelector((state) => state.auth.userDataSlice.data);
  // console.log("User info: ", user);
  //const userDataMap = useSelector((state) => state.core.usersSlice.data);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeDialogClose = () => {
    setOpenThemeDialog(false);
  };

  function handleMenuItemSelect(menuItem) {
    if (menuItem === "selectTheme") setOpenThemeDialog(true);
    if (menuItem === "account") {
      History.push("/account");
    }
    if (menuItem === "signout") {
      console.log("Signing out: ");
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });

      dispatch(setAppHeader(false));
      localStorage.setItem("appHeader", "false");
      dispatch(logout());
      History.push("/");
    }
  }

  // useEffect(() => {
  //   if (userInfo) dispatch(getUser(userInfo["uuId"]));
  // }, [userInfo]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip
          title={`Account: ${
            user ? user.firstName + " " + user.lastName : "NA"
          } (${user ? user.userName : "NA"})`}
        >
          <Avatar
            onClick={handleClick}
            color="inherit"
            sx={{
              width: 32,
              height: 32,
              cursor: "pointer",
              "&:hover": { bgcolor: theme.colors.primary.light },
            }}
          >
            {user ? getInitial(user.firstName, user.lastName) : "NA"}
          </Avatar>
        </Tooltip>

        <NtaiDecoMenu
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleMenuItemSelect={handleMenuItemSelect}
          header={
            <Box
              sx={{
                mt: 1,
                mb: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: theme.colors.primary.main,
                  }}
                >
                  <Typography sx={{ fontSize: 11, fontWeight: 700 }}>
                    {user ? getInitial(user.firstName, user.lastName) : "NA"}
                  </Typography>
                </Avatar>
              </Box>

              <Typography variant="caption1">
                <Box sx={{ fontWeight: 700 }}>
                  {user
                    ? NtaiUtils.trunc(user.firstName + " " + user.lastName, 15)
                    : "Anonymous"}
                </Box>
              </Typography>
              <Divider sx={{ width: "100%" }} />
            </Box>
          }
          items={[
            {
              icon: "user",
              label: "Account",
              value: "account",
            },

            // {
            //   icon: "language",
            //   label: "Languages",
            //   value: "languages",
            // },
            {
              icon: "palette",
              label: "Theme",
              value: "selectTheme",
            },
            {
              icon: "signout",
              label: "Sign out",
              value: "signout",
            },
          ]}
        />
      </Box>
      <NtaiDialog
        open={openThemeDialog}
        size="xs"
        handleDialogClose={handleThemeDialogClose}
        title="Select Theme"
      >
        <ThemeSelector handleDialogClose={handleThemeDialogClose} />
      </NtaiDialog>
    </>
  );
}
