import { Box, useTheme } from "@mui/material";
import NtaiColorBox from "@ntai/components/color-picker/NtaiColorBox";
import NtaiColorPicker from "@ntai/components/color-picker/NtaiColorPicker";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const ruleLtOptions = [
  { value: 1, label: "(<) is less than" },
  { value: 2, label: "(<=) is less than or equal to" },
];
const ruleGtOptions = [
  { value: 1, label: "(>) is greater than" },
  { value: 2, label: "(>=) is greater than or equal to" },
];

export default function SourceRecordTagColorRuleForm(props) {
  const { action, formData, handleSave, handleCancel } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const [bgColor, setBgColor] = useState();
  const [fgColor, setFgColor] = useState();
  const { getValues, setValue, reset } = useFormContext();

  function onFgChange(color) {
    setFgColor(color);
    setValue("sourceRecordTagColorPickerForm.hexFgCode", color);
  }
  function onBgChange(color) {
    setBgColor(color);
    setValue("sourceRecordTagColorPickerForm.hexBgCode", color);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceRecordTagColorPickerForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({
      sourceRecordTagColorPickerForm: action === "edit" ? formData : null,
    });
  }, [formData]);

  return (
    <NtaiDialogFormPanel
      header="Select color"
      subheader="Build color rules based on field value"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
      >
        <NtaiTextField
          name="sourceRecordTagColorPickerForm.exactValue"
          label="Exact Value"
          placeholder="Exact value"
        />

        {/* {colorFormatCode === 2 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            If
            <NtaiTextField
              name="sourceRecordTagColorPickerForm.ruleGtValue"
              label="Value"
            />
            <NtaiSelectField
              options={ruleGtOptions}
              name="sourceRecordTagColorPickerForm.ruleGtOperatorCode"
            />
            and
            <NtaiTextField
              name="sourceRecordTagColorPickerForm.ruleLtValue"
              label="Value"
            />
            <NtaiSelectField
              name="sourceRecordTagColorPickerForm.ruleLtOperatorCode"
              options={ruleLtOptions}
            />
          </Box>
        )} */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            flexDirection: "column",
          }}
        >
          <NtaiTextField
            name="sourceRecordTagColorPickerForm.hexFgCode"
            label="Foreground"
          />
          <NtaiColorPicker onChange={onFgChange} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            justifyContent: "center",
          }}
        >
          <NtaiColorBox bg={fgColor} width={36} height={36} />
          <NtaiColorBox bg={bgColor} width={36} height={36} />
          <NtaiColorBox
            bg={bgColor}
            fg={fgColor}
            width={36}
            height={36}
            textVariant="h6"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            flexDirection: "column",
          }}
        >
          <NtaiTextField
            name="sourceRecordTagColorPickerForm.hexBgCode"
            label="Background"
          />
          <NtaiColorPicker onChange={onBgChange} />
        </Box>
      </Box>
    </NtaiDialogFormPanel>
  );
}
