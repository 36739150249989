import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  clearStatus,
  createSourceMasterTemplate,
  setActiveId,
} from "./store/sourceMasterTemplatesSlice";
import SourceMasterTemplateForm from "./SourceMasterTemplateForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SourceMasterTemplateCreate({
  handleCancel,
  redirectToEdit,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.status
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.activeId
  );

  function handleSave(formData) {
    dispatch(
      createSourceMasterTemplate({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceMasterTemplate" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Master template "${dataMap[activeId]["name"]}" created successfully`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      redirectToEdit(activeId);
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(null));
  }, []);

  return (
    <NtaiForm>
      <SourceMasterTemplateForm
        action="add"
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
