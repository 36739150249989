import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiTextArea from "@ntai/components/inputs/NtaiTextArea";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiSliderField from "@ntai/components/inputs/NtaiSliderField";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiMuiSelectField from "@ntai/components/inputs/NtaiMuiSelectField";
import NtaiMuiMultipleSelectField from "@ntai/components/inputs/NtaiMuiMultipleSelectField";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiRadioGroupField from "@ntai/components/inputs/NtaiRadioGroupField";

import { useFormContext } from "react-hook-form";
import NtaiSimpleSearchBar from "../searchbar/simple/NtaiSimpleSearchBar";
import NtaiTagsInput from "./NtaiTagsInput";
import NtaiListSelect from "./NtaiListSelect";
import NtaiChipsInput from "./NtaiChipsInput";
import NtaiMuiDateField from "./NtaiMuiDateField";
import dayjs from "dayjs";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiMultiSelectField from "./NtaiMultiSelectField";
import { NtaiCheckboxGroupField } from "./NtaiCheckboxGroupField";
import { NtaiCheckboxGroupField1 } from "./NtaiCheckboxGroupField1";
import NtaiTabs from "../tabs/NtaiTabs";
import NtaiForm from "../forms/NtaiForm";
import NtaiTestFormValidations from "./NtaiTestFormValidations";

export default function NtaiTestFormInputs() {
  const theme = useTheme();
  const [formData, setFormData] = React.useState();

  const { getValues, reset } = useFormContext();

  function submitForm(e) {
    //e.preventDefault();
    const formData = getValues("testForm");
    console.log("Initial Form values: ", formData);
    const sanitizedFormData = NtaiUtils.sanitizeFormData(formData);
    console.log("Post sanitize Form values: ", sanitizedFormData);
    // //setFormData(getValues("testForm"));
    // let date1 = formData["dateDob1"];
    // console.log(dayjs.isDayjs(date1));

    // console.log(dayjs(date1.$d).format("YYYY-MM-DD"));
    // // console.log("formattedDate: ", formattedDate);
  }

  function handleSelectedTags(items) {
    console.log("Items: ", items);
  }

  function handleChipsInputChange(chips) {
    console.log("Changed tags: ", chips);
  }

  useEffect(() => {
    reset({
      testForm: {
        muiMultipleSelectCountry: ["001", "002"],
        selectCountry: 2,
        // selectCountryMultiple: ,
        radioGender: 1,
        fSwitch: true,
        // checkboxGroupGender: "1,2",
        checkboxGroupGender1: "1,2",
      },
    });
  }, []);

  return (
    <NtaiTabs>
      <Box
        label="FORM INPUTS"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            paddingLeft: theme.spacing(4),
            paddingTop: theme.spacing(4),
          }}
        >
          <Button
            variant="contained"
            size="small"
            type="submit"
            onClick={(e) => submitForm(e)}
          >
            Submit
          </Button>
          <Box sx={{ display: "flex" }}>{formData}</Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            padding: theme.spacing(4),
            gap: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(4),
              border: "2px solid #eee",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h5">Text field:</Typography>
            <TextField
              size="small"
              inputProps={{
                style: {
                  height: "12px",
                  fontSize: "12px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 12,
                  backgroundColor: "#FFF",
                  color: "#383838",
                },
              }}
              label="Enter name"
              name="testForm.fname1"
              placeholder="Name"
              helperText="Please enter your name"
            />
            <NtaiTextField
              name="testForm.fname"
              placeholder="Name"
              label="Enter Name"
              helperText="Please enter your name"
            />
            <Typography variant="h5">Text area:</Typography>
            <NtaiTextArea
              name="testForm.description"
              placeholder="Enter Description"
              label="Enter Description"
            />
            <NtaiSimpleSearchBar />
          </Box>
          <Box
            sx={{
              padding: theme.spacing(4),
              border: "2px solid #eee",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h5">Switch:</Typography>
            <NtaiSwitchField name="testForm.fSwitch" label="Yes or no?" />

            <Typography variant="h5">Slider:</Typography>
            <NtaiSliderField
              name="testForm.sliderAge"
              label="Age"
              min={10}
              max={90}
              marks={true}
              step={10}
              size="small"
            />

            <Typography variant="h5">Date Field:</Typography>
            <NtaiDateField name="testForm.dateDob" />
          </Box>
          <Box
            sx={{
              padding: theme.spacing(4),
              border: "2px solid #eee",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h5">React Select:</Typography>
            <NtaiSelectField
              name="testForm.selectCountry"
              label="Country"
              options={[
                { label: "USA", value: 1 },
                { label: "India", value: 2 },
              ]}
            />
            <NtaiMultiSelectField
              name="testForm.selectCountryMultiple"
              label="Country"
              options={[
                { label: "USA", value: 1 },
                { label: "India", value: 2 },
                { label: "UK", value: 3 },
              ]}
            />

            <Typography variant="h5">MUI Select:</Typography>
            <NtaiMuiSelectField
              name="testForm.muiSelectCountry"
              size="small"
              label="Select Country"
              options={[
                { label: "None", id: "000" },
                { label: "USA", id: "001" },
                { label: "India", id: "002" },
              ]}
            />
            <NtaiMuiMultipleSelectField
              name="testForm.muiMultipleSelectCountry"
              size="small"
              label="Select Country Now"
              options={[
                { label: "USA", value: "001" },
                { label: "India", value: "002" },
                { label: "France", value: "003" },
              ]}
            />
          </Box>
          <Box
            sx={{
              padding: theme.spacing(4),
              border: "2px solid #eee",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h5">Checkbox:</Typography>
            {/* <NtaiCheckboxField name="testForm.checkboxSmoking" label="Smoking" /> */}
            {/* 
          <NtaiCheckboxGroupField
            name="testForm.checkboxGroupGender"
            label="Gender CB"
            options={[
              { label: "Male", value: 1 },
              { label: "Female", value: 2 },
            ]}
          /> */}

            <NtaiCheckboxGroupField1
              name="testForm.checkboxGroupGender1"
              label="Gender CB1"
              options={[
                { label: "Male", value: 1 },
                { label: "Female", value: 2 },
              ]}
            />

            <Typography variant="h5">Radios:</Typography>
            <NtaiRadioGroupField
              name="testForm.radioGender"
              label="Gender RD"
              row
              options={[
                { value: 1, label: "Male" },
                { value: 2, label: "Female" },
              ]}
            />
          </Box>

          <Box
            sx={{
              padding: theme.spacing(4),
              border: "2px solid #eee",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h5">Tags Inputs:</Typography>
            <NtaiTagsInput
              selectedTags={handleSelectedTags}
              fullWidth
              variant="outlined"
              id="tags"
              name="tags"
              placeholder="Add Tags"
              label="Tags"
            />

            <NtaiChipsInput
              data={["ABF", "DER"]}
              handleChange={handleChipsInputChange}
            />
          </Box>

          <Box
            sx={{
              padding: theme.spacing(4),
              border: "2px solid #eee",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h5">List Select Inputs:</Typography>
            <NtaiListSelect />
          </Box>
        </Box>
      </Box>
      <Box label="VALIDATIONS">
        <NtaiTestFormValidations />
      </Box>
    </NtaiTabs>
  );
}
