import { Button } from "@mui/material";
import React from "react";

export default function ChildComponent2({ handleChild1Click }) {
  return (
    <div>
      <Button onClick={() => handleChild1Click()}>CHILD 2</Button>
    </div>
  );
}
