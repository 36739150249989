import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  dialogTitle: {
    borderBottom: "1px solid #eee",
  },
  dialogHeader: {
    padding: theme.spacing(1),
    border: "1px solid rounded #eee",
    borderRadius: "10px",
    backgroundColor: theme.colors.secondary.main,
  },
}));

export default function NtaiDialog({
  handleDialogClose,
  open,
  title,
  size,
  children,
  dialogActions,
  noPadding,
  hideBackdrop,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState(size ? size : "md");

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby={title}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      style={{ overflow: "visible" }}
      hideBackdrop={hideBackdrop ? hideBackdrop : false}
    >
      <DialogTitle id="dialog-title" className={classes.dialogTitle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">{title}</Typography>
          <IconButton onClick={handleDialogClose}>
            <FontAwesomeIcon size="sm" icon={faClose} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        style={{ padding: noPadding ? 0 : null, overflow: "visible" }}
      >
        {children}
      </DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
}
