import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceTemplateWorkflows = createAsyncThunk(
  "sourceTemplateWorkflows/getSourceTemplateWorkflows",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/workflows`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceTemplateWorkflow = createAsyncThunk(
  "sourceTemplateWorkflows/getSourceTemplateWorkflow",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/workflows/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSourceTemplateWorkflows = createAsyncThunk(
  "sourceTemplateWorkflows/createOrUpdateSourceTemplateWorkflows",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;

      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/workflows`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceTemplateWorkflow = createAsyncThunk(
  "sourceTemplateWorkflows/deleteSourceTemplateWorkflow",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/workflows/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceTemplateWorkflowsSlice = createSlice({
  name: "sourceTemplateWorkflows",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceTemplateWorkflows.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceTemplateWorkflows",
      };
    },
    [getSourceTemplateWorkflows.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceTemplateWorkflows",
        message: action.payload.message,
      };
    },
    [getSourceTemplateWorkflow.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceTemplateWorkflow" };
    },
    [getSourceTemplateWorkflow.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceTemplateWorkflow",
        message: action.payload.message,
      };
    },
    [createOrUpdateSourceTemplateWorkflows.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createOrUpdateSourceTemplateWorkflows",
      };
    },
    [createOrUpdateSourceTemplateWorkflows.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSourceTemplateWorkflows",
        message: action.payload.message,
      };
    },

    [deleteSourceTemplateWorkflow.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceTemplateWorkflow",
      };
    },
    [deleteSourceTemplateWorkflow.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceTemplateWorkflow",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceTemplateWorkflowsSlice.actions;

export default sourceTemplateWorkflowsSlice.reducer;
