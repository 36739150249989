const _ = require("lodash");

class NtaiChartUtil {
  static getHexColorCode(value, colorRules) {
    if (value < 5) return "#E45GH34";
    else return "#000000";
  }
}

export default NtaiChartUtil;
