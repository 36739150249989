import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { clearStatus, deleteWidget, getWidgets } from "./store/widgetsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "contentDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "aggregationDecode",
    numeric: false,
    disablePadding: false,
    label: "Aggregation",
  },
];

const toolbarActions = [
  {
    value: "archive",
    label: "Delete",
    icon: "archive",
    disabled: true,
  },
];

export default function WidgetList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector((state) => state.core.widgetsSlice.data);
  const status = useSelector((state) => state.core.widgetsSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    // if (action === "archive") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "archive") {
        dispatch(deleteWidget(id));
      }
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getWidgets" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (status && status.method === "getWidgets" && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getWidgets());
    dispatch(getCodes(CODELIST_CODES.COR_VIEW_CONTENT_CODE));
    dispatch(getCodes(CODELIST_CODES.COR_VIEW_CONTENT_AGG_CODE));
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Widgets">
        {loaded && (
          <NtaiSimpleTable
            helpText="Widgets are the core components that make up a Dashboard and Source Views. They help you visualize and summarize the data stored in the application"
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path="/admin/widgets"
            handleToolbarAction={handleToolbarAction}
            title={`Widgets (${data.length})`}
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
