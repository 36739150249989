import { useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import React from "react";

export default function NtaiColorBox(props) {
  const theme = useTheme();
  const { width, height, bg, fg, text, textVariant, handleClick } = props;
  return (
    <Box
      sx={{
        display: "flex",
        width: props.hasOwnProperty("width") ? width : 18,
        height: props.hasOwnProperty("height") ? height : 18,
        border: theme.general.border1,
        bgcolor: props.hasOwnProperty("bg") ? props.bg : "white",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "&:hover": {
          cursor: "default",
        },
      }}
    >
      {props.hasOwnProperty("fg") && (
        <Typography
          sx={{ textAlign: "center" }}
          variant={
            props.hasOwnProperty("textVariant")
              ? props.textVariant
              : "subtitle2"
          }
          fontWeight="700"
          color={props.hasOwnProperty("fg") ? fg : "black"}
        >
          {props.hasOwnProperty("text") ? props.text : "T"}
        </Typography>
      )}
    </Box>
  );
}
