import React from "react";
import { useTheme } from "@mui/material";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceStageForm from "./SourceStageForm";
import {
  clearStatus,
  createSourceStage,
  deleteSourceStage,
  getSourceStages,
  setActiveId,
  updateSourceStage,
} from "./store/sourceStagesSlice";
import { useEffect } from "react";
import SourceDatasetManager from "./SourceDatasetManager";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "20%",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "30%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    dataType: "date",
    width: "15%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "archive",
    value: "delete",
  },
];

export default function SourceStageList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [view, setView] = useState("list");
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dataMap = useSelector((state) => state.sources.sourceStagesSlice.data);
  const status = useSelector((state) => state.sources.sourceStagesSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: sourcePipelineId,
  };

  function toggleView(viewName) {
    setView(viewName);
  }
  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteSourceStage({
            ...params,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "add"
      ? dispatch(
          createSourceStage({
            ...params,
            formData: formData,
          })
        )
      : dispatch(
          updateSourceStage({
            ...params,
            uuId: currentId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleRowAction(rowAction, uuId) {
    dispatch(setActiveId(uuId));
    setView(rowAction);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceStages" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceStage" ||
        status.method === "updateSourceStage")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceStage"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceStages(params));
  }, []);

  return (
    <>
      {view === "list" && (
        <React.Fragment>
          <NtaiPanel
            width="75%"
            header="Pipeline Stages"
            subheader="Define and manage pipeline stages to transform source data into usable format and subsequent storage into source outbound store"
          >
            {loaded && (
              <NtaiCrudTable
                rows={data}
                headCells={headCells}
                toolbarActions={toolbarActions}
                keyColumn="uuId"
                dialog={true}
                handleDialogAddEdit={handleDialogAddEdit}
                handleToolbarAction={handleToolbarAction}
                title="Stage"
                rowActions={[
                  { label: "MANAGE STAGE", id: "manage", value: "manage" },
                ]}
                handleRowAction={handleRowAction}
              />
            )}
            {!loaded && <NtaiCircularProgress />}
          </NtaiPanel>
          <NtaiDialog
            open={openDialog}
            handleDialogClose={handleDialogClose}
            title={action === "add" ? "Add Stage" : "Edit Stage"}
            size="sm"
          >
            <NtaiForm>
              <SourceStageForm
                action={action}
                handleDialogClose={handleDialogClose}
                handleFormSubmit={handleFormSubmit}
                formData={action === "edit" ? dataMap[currentId] : {}}
              />
            </NtaiForm>
          </NtaiDialog>
          <NtaiConfirmDeleteDialog
            items={
              selectedIds &&
              selectedIds.length &&
              NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
            }
            open={openConfirmDeleteDialog}
            handleConfirmDelete={handleDelete}
            handleCancelDelete={cancelDelete}
          />
        </React.Fragment>
      )}
      {view === "manage" && <SourceDatasetManager toggleView={toggleView} />}
    </>
  );
}
