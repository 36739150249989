import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { clearStatus, deleteDataset, getDatasets } from "./store/datasetsSlice";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import { getSourceOutDatasets } from "../../sourcedefinition/source/out/store/sourceOutDatasetsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "categoryDecode",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    label: "File",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function DatasetList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dataMap = useSelector((state) => state.models.datasetsSlice.data);
  const status = useSelector((state) => state.models.datasetsSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") {
      setAction("delete");
      confirmDelete();
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteDataset(id));
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getDatasets" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteDataset"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Dataset(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getDatasets());
    dispatch(getCodes(CODELIST_CODES.MOD_DATASET_FILE_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.MOD_DATASET_CATEGORY_CODE));
    dispatch(getCodes(CODELIST_CODES.GL_ACCESS_TYPE_CODE));
    dispatch(
      getSourceOutDatasets({
        sourceDefId: "*",
        sourceId: "*",
        sourceOutStoreId: "*",
      })
    );
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Datasets">
        {loaded && (
          <NtaiCrudTable
            helpText="Dataset management allows you to define and manage datasets that can be used during function definition and validation. Datasets can be created from an uploaded CSV file or reusing an existing source out store dataset"
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path="/models/datasets"
            handleToolbarAction={handleToolbarAction}
            title="Dataset"
          />
        )}

        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
