import {
  Box,
  Chip,
  Divider,
  Drawer,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { useDispatch, useSelector } from "react-redux";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import SourceOutJobMigrationStepCard from "./SourceOutJobMigrationStepCard";
import {
  CancelOutlined,
  CheckCircleOutlined,
  ErrorOutlined,
  LoopOutlined,
  PendingOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import SourceOutJobMigrationStep from "./SourceOutJobMigrationStep";
import {
  clearStatus,
  getSourceOutJobMigration,
} from "./store/sourceOutJobMigrationsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiErrorMessage from "@ntai/components/errors/NtaiErrorMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

const migrationSteps = [
  {
    id: 1,
    title: "Copy Files",
    description: "Copy uploaded inbound files for processing",
  },
  {
    id: 2,
    title: "Read Files",
    description: "Read individual store and associated files",
  },
  {
    id: 3,
    title: "Run Pipeline",
    description: "Perform transformation using pipeline",
  },
  {
    id: 4,
    title: "Execute SCD Policy",
    description: "Apply dataset SCD policy (optional)",
  },
  {
    id: 5,
    title: "Load",
    description: "Perform loading into target store",
  },
];

function getStepIcon(status) {
  if (status === 1) return PendingOutlined;
  else if (status === 2) return LoopOutlined;
  else if (status === 3) return CheckCircleOutlined;
  else if (status === 4) return CancelOutlined;
  else if (status === 5) return WarningOutlined;
  else return ErrorOutlined;
}

const MigrationStepIcon = (stepId, sourceMigOutStoreData, migrationData) => {
  // if (val < 3) return ErrorRounded;
  // else return CheckCircleOutline;

  if (stepId === 1) {
    const migrationInStoreCopyBatches =
      migrationData["migrationInStoreCopyBatches"];

    if (
      _.isArray(migrationInStoreCopyBatches) &&
      migrationInStoreCopyBatches.length > 0
    )
      return getStepIcon(migrationInStoreCopyBatches[0]["statusCode"]);
    else return getStepIcon(1);
  } else if (stepId === 2) {
    const migrationInStores = sourceMigOutStoreData["migrationInStores"];

    if (_.isArray(migrationInStores) && migrationInStores.length > 0)
      return getStepIcon(migrationInStores[0]["statusCode"]);
    else return getStepIcon(1);
  } else if (stepId === 3) {
    const migrationDatasets = sourceMigOutStoreData["migrationDatasets"];

    if (_.isArray(migrationDatasets) && migrationDatasets.length > 0)
      return getStepIcon(migrationDatasets[0]["statusCode"]);
    else return getStepIcon(1);
  } else if (stepId === 4) {
    const migrationDatasets = sourceMigOutStoreData["migrationDatasets"];

    if (_.isArray(migrationDatasets) && migrationDatasets.length > 0) {
      if (_.isArray(migrationDatasets[0]["migrationOutStoreDatasetScd"]))
        return getStepIcon(
          migrationDatasets[0]["migrationOutStoreDatasetScd"][0]["statusCode"]
        );
      else return getStepIcon(1);
    } else return getStepIcon(1);
  } else {
    const migrationDatasets = sourceMigOutStoreData["migrationDatasets"];

    if (_.isArray(migrationDatasets) && migrationDatasets.length > 0) {
      if (_.isArray(migrationDatasets[0]["migrationOutStoreDatasetLoad"]))
        return getStepIcon(
          migrationDatasets[0]["migrationOutStoreDatasetLoad"][0]["statusCode"]
        );
      else return getStepIcon(1);
    } else return getStepIcon(1);
  }
};

export const SourceOutJobMigrationContext = React.createContext();

export default function SourceOutJobMigration(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [displayStep, setDisplayStep] = React.useState();
  const [displayStore, setDisplayStore] = React.useState();

  const sourceJobId = props.match.params.jobid;
  const migrationId = props.match.params.id;

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceJobDataMap = useSelector(
    (state) => state.sources.sourceOutJobsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);
  const sourceJobData = _.get(sourceJobDataMap, sourceJobId);

  const dataMap = useSelector(
    (state) => state.sources.sourceOutJobMigrationsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceOutJobMigrationsSlice.status
  );

  const migrationData = _.get(dataMap, migrationId);

  function handleDrawerClose() {
    setOpenDrawer(false);
    setDisplayStep(null);
    setDisplayStore(null);
  }

  function handleDisplayStep(id, storeId) {
    setDisplayStep(id);
    setDisplayStore(storeId);
    setOpenDrawer(true);
  }

  function getStoreStepStatus(stepId, sourceMigrationOutStoreData) {
    if (stepId === 1) {
      const migrationInStoreCopyBatches =
        sourceMigrationOutStoreData["migrationInStoreCopyBatches"];
    } else if (stepId === 2) {
    }
  }

  React.useEffect(() => {
    if (
      status &&
      status.method === "getSourceOutJobMigration" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  React.useEffect(() => {
    dispatch(
      getSourceOutJobMigration({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceJobId: sourceJobId,
        uuId: migrationId,
      })
    );
  }, [migrationId]);

  return (
    <SourceOutJobMigrationContext.Provider
      value={{ migrationSteps, displayStep, displayStore, migrationData }}
    >
      <NtaiPage
        title={`${sourceDefData["name"]} / ${sourceJobData["name"]} / ${migrationData["migrationTs"]}`}
        back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs/${sourceJobId}/migrations`}
      >
        {loaded && (
          <NtaiPanel width="75%" header={migrationData["migrationTs"]}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: theme.spacing(2),
              }}
            >
              <NtaiLabelValue1
                label="Status"
                value={migrationData["statusDecode"]}
              />
              <NtaiLabelValue1
                label="Date Started"
                value={NtaiUtils.formatDateTimeCol(
                  migrationData["dateStarted"]
                )}
              />
              <NtaiLabelValue1
                label="Date Completed"
                value={NtaiUtils.formatDateTimeCol(
                  migrationData["dateCompleted"]
                )}
              />
              <NtaiLabelValue1
                label="Duration"
                value={migrationData["duration"]}
              />
            </Box>

            {migrationData["message"] && (
              <Box
                sx={{
                  border: theme.general.border1,
                  display: "flex",
                  maxHeight: "120px",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <NtaiErrorMessage
                    title="Message"
                    message={migrationData["message"]}
                  />
                </Box>
              </Box>
            )}

            <Divider />

            <Typography variant="h6" fontWeight="700" color="inherit">
              Target Stores
            </Typography>

            {_.isArray(migrationData["migrationOutStores"]) &&
              migrationData["migrationOutStores"].map((smos, i) => (
                <Box
                  key={`ntai-migration-store-${i}`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: theme.general.border1,
                    borderRadius: theme.general.borderRadiusSm,
                    p: theme.spacing(2),
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      justifyContent: "space-between",
                    }}
                  >
                    <Chip
                      size="small"
                      label={
                        <Typography
                          sx={{ px: theme.spacing(2) }}
                          variant="subtitle2"
                          color="inherit"
                          fontWeight="700"
                        >
                          {smos.storeDecode}
                        </Typography>
                      }
                    />
                    <Chip
                      size="small"
                      variant="contained"
                      label={
                        <Typography
                          sx={{ px: theme.spacing(2) }}
                          variant="subtitle2"
                          color="inherit"
                          fontWeight="700"
                        >
                          {smos.statusDecode}
                        </Typography>
                      }
                    />
                  </Box>

                  <Stepper sx={{ background: "white" }} alternativeLabel>
                    {migrationSteps.map((o, index) => {
                      return (
                        <Step key={o.id} active={false} completed={true}>
                          <StepLabel
                            StepIconComponent={MigrationStepIcon(
                              o.id,
                              smos,
                              migrationData
                            )}
                          >
                            <SourceOutJobMigrationStepCard
                              id={o.id}
                              storeId={smos.uuId}
                              // status={() => getStoreStepStatus(o.id, smos)}
                              title={o.title}
                              description={o.description}
                              handleClick={handleDisplayStep}
                            />
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              ))}
          </NtaiPanel>
        )}
        {!loaded && <NtaiCircularProgress size={24} />}
        {loaded && (_.isEmpty(migrationData) || !migrationData) && (
          <NtaiEmptyMessage header="Migration data not available" />
        )}
      </NtaiPage>
      {displayStep && displayStore && (
        <Drawer
          PaperProps={{
            style: {
              position: "absolute",
              top: 48, // 139
              height: `calc(100% - 48px)`,
            },
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openDrawer}
          onClose={handleDrawerClose}
          anchor="right"
          BackdropProps={{ invisible: true }}
        >
          <Box
            sx={{
              width: "620px",
              display: "flex",
              height: "100%",
              zIndex: (theme) => theme.zIndex.drawer,
            }}
          >
            <SourceOutJobMigrationStep />
          </Box>
        </Drawer>
      )}
    </SourceOutJobMigrationContext.Provider>
  );
}
