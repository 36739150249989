import { Box, Typography, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiMRTable from "@ntai/components/tables/material-react/NtaiMRTable";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function SourceInStoreDatasetQueryResult() {
  const theme = useTheme();
  const [result, setResult] = useState();

  const activeId = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.status
  );

  const data = dataMap[activeId];

  function displayResult() {
    if (
      status &&
      status.result === "pending" &&
      status.method === "runSourceInStoreDataset"
    ) {
      return <NtaiCircularProgress size={24} vAlign="center" />;
    } else if (
      status &&
      status.result === "error" &&
      status.method === "runSourceInStoreDataset"
    ) {
      return (
        <Box
          sx={{ p: theme.spacing(2), display: "flex", background: red[100] }}
        >
          {status.message}
        </Box>
      );
    } else if (data && data.queryStatusCode === 4) {
      return (
        <Box
          sx={{ p: theme.spacing(2), display: "flex", background: red[100] }}
        >
          {data["queryMessage"]}
        </Box>
      );
    } else if (
      data &&
      data.queryStatusCode === 3 &&
      result &&
      _.isArray(result) &&
      result.length > 0
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <NtaiMRTable width="100%" data={result} />
        </Box>
      );
    }
  }

  useEffect(() => {
    if (data && data["queryStatusCode"] === 3) {
      setResult(data["queryResult"]);
    } else setResult(null);
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexBasis: "50%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "38px",
          p: theme.spacing(1),
          borderBottom: theme.general.border1,
        }}
      >
        <Typography fontWeight="700">Query Result</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 38px)`,
          p: theme.spacing(1),
        }}
      >
        <Scrollbar>{displayResult()}</Scrollbar>
      </Box>
    </Box>
  );
}
