import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import OversightNavigation from "./navigation";
import OversightMain from "./main";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import OversightSummary from "./main/root/OversightSummary";
import { OVERSIGHT_SECTIONS } from "./constants/OversightConstants";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getOversightNavigationTree,
} from "./navigation/store/oversightNavigationSlice";
import { useEffect } from "react";
import { grey } from "@mui/material/colors";
import {
  faCheck,
  faCircleDown,
  faDownload,
  faPlus,
  faPlusCircle,
  faShare,
} from "@fortawesome/pro-light-svg-icons";
import { faSquarePlus } from "@fortawesome/pro-solid-svg-icons";
const _ = require("lodash");

export const OversightContext = React.createContext();

export default function Oversight() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [navLoaded, setNavLoaded] = useState(false);
  const [leftNav, setLeftNav] = useState(true);
  const [navNode, setNavNode] = useState();
  const [reloadOversightTopicSummary, setReloadOversightTopicSummary] =
    useState(false);
  const [oversightTopicId, setOversightTopicId] = useState();
  const [section, setSection] = useState(OVERSIGHT_SECTIONS.ROOT);
  const [subSection, setSubSection] = useState("overview");
  const [navigationData, setNavigationData] = useState();

  console.log("Oversight LS: ", localStorage);

  const navigationDataMap = useSelector(
    (state) => state.oversight.oversightNavigationSlice.data
  );

  const status = useSelector(
    (state) => state.oversight.oversightNavigationSlice.status
  );

  function toggleLeftNav() {
    setLeftNav(!leftNav);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getOversightNavigationTree"
    ) {
      setNavigationData(navigationDataMap);
      setNavLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getOversightNavigationTree());
  }, []);

  return (
    <>
      {navLoaded && !_.isEmpty(navigationData) && (
        <OversightContext.Provider
          value={{
            navigationData,
            navNode,
            setNavNode,
            oversightTopicId,
            setOversightTopicId,
            section,
            setSection,
            toggleLeftNav,
            reloadOversightTopicSummary,
            setReloadOversightTopicSummary,
            subSection,
            setSubSection,
          }}
        >
          <NtaiPage
            title={`Oversight`}
            padding="0"
            titleExtras={
              <Box
                sx={{
                  ml: theme.spacing(11),
                  justifyContent: "end",
                  flexBasis: "100%",
                  display: "flex",
                }}
              >
                <NtaiSimpleSearchBar
                  placeholder="Search..."
                  background={grey[50]}
                />
              </Box>
            }
            // titleExtras={
            // <Box
            //   sx={{
            //     justifyContent: "end",
            //     flexBasis: "100%",
            //     display: "flex",
            //   }}
            // >
            //   <NtaiSimpleSearchBar placeholder="Search Topics..." />
            // </Box>
            // navNode &&
            // _.get(navNode, "name") &&
            // _.get(navNode, "parentName") && (
            //   <Box
            //     sx={{
            //       display: "flex",
            //       width: "100%",
            //       gap: theme.spacing(1),
            //       alignItems: "center",
            //     }}
            //   >
            //     <Box
            //       sx={{
            //         display: "flex",
            //         gap: theme.spacing(1),
            //         alignItems: "center",
            //       }}
            //     >
            //       <IconButton>
            //         <FontAwesomeIcon size="2xs" icon={faEllipsisV} />
            //       </IconButton>
            //       <Typography
            //         variant="body1"
            //         fontWeight="500"
            //         color="inherit"
            //       >
            //         {_.get(navNode, "parentName")}
            //       </Typography>
            //       <IconButton>
            //         <FontAwesomeIcon size="2xs" icon={faEllipsisV} />
            //       </IconButton>
            //       <Typography
            //         variant="body1"
            //         fontWeight="500"
            //         color="inherit"
            //       >
            //         {_.get(navNode, "name")}
            //       </Typography>
            //     </Box>
            //     {/* <IconButton>
            //       <FontAwesomeIcon size="2xs" icon={faEllipsisV} />
            //     </IconButton> */}
            //     <Box sx={{ flexBasis: "50%", display: "flex" }}>
            //       <NtaiSimpleSearchBar placeholder="Search Topics..." />
            //     </Box>
            //   </Box>
            // )
            // }
            startAction={
              <IconButton onClick={toggleLeftNav}>
                <FontAwesomeIcon size="2xs" icon={faBars} />
              </IconButton>
            }
            headerActions={
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                {/* <Button
                  sx={{ fontSize: "10px" }}
                  startIcon={<FontAwesomeIcon icon={faShare} />}
                  size="small"
                >
                  PUBLISH
                </Button> */}
                {/* <IconButton>
                  <FontAwesomeIcon icon={faShare} />
                </IconButton>
                <Divider /> */}
                {/* <IconButton>
                  <FontAwesomeIcon icon={faShare} />
                </IconButton> */}

                <Button
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faSquarePlus} />}
                  // onClick={() => history.push("/search-basic-init")}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    NEW TOPIC
                  </Typography>
                </Button>

                {/* <Button
                  variant="outlined"
                  sx={{ fontSize: "12px" }}
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  size="small"
                >
                  CREATE TOPIC
                </Button> */}

                {/* <Button sx={{ fontSize: "10px" }} size="small">
                  EXPORT
                </Button> */}
              </Box>
            }
          >
            <Box sx={{ display: "flex", height: "100%" }}>
              {leftNav && <OversightNavigation />}
              <OversightMain />
            </Box>
          </NtaiPage>
        </OversightContext.Provider>
      )}
      {navLoaded && _.isEmpty(navigationData) && (
        <NtaiEmptyMessage
          header="Oversight data not available"
          subheader="Ensure your administrator has assigned you access to business entities and topics "
          vAlign="center"
        />
      )}
    </>
  );
}
