import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";
import { tooltipConfig } from "./chartConfigs";
import { Box, useTheme } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

function generateColors(color) {
  let colors = [];

  for (let i = 0; i < 100; i++) {
    const colRand = NtaiUtils.getRandomFloat(0.2, 0.6);
    colors.push(am4core.color(color).lighten(colRand));
  }

  return colors;
}

export default function NtaiPieSeriesChart(props) {
  const theme = useTheme();

  const { data, seriesData } = props;

  useLayoutEffect(() => {
    let chart = am4core.create("piechart", am4charts.PieChart);

    chart.data = data;

    function createSeries() {
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "filtered";
      pieSeries.dataFields.category = "country";
      //   chart.radius = am4core.percent(50);
      pieSeries.dataFields.radiusValue = "value";

      //   chart.innerRadius = am4core.percent(50);

      // pieSeries.labels.template.dataItem = data;
      pieSeries.labels.template.fontSize = 10;
      pieSeries.labels.template.paddingTop = 0;
      pieSeries.labels.template.paddingLeft = 0;
      pieSeries.labels.template.paddingBottom = 0;
      pieSeries.labels.template.paddingRight = 0;
      pieSeries.labels.template.text = "{category}";

      // wrap or truncate labels
      pieSeries.labels.template.maxWidth = 130;
      pieSeries.labels.template.wrap = true;
      // pieSeries.alignLabels = false;
      // pieSeries.alignLabels = false;

      pieSeries.slices.template.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;

      // stroke
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 1;
      pieSeries.slices.template.strokeOpacity = 1;

      //   if (active === "active") {
      const colorSet = new am4core.ColorSet();
      colorSet.list = generateColors(am4core.color(theme.palette.chart.light));
      pieSeries.colors = colorSet;
      //   } else {
      //     const colorSet = new am4core.ColorSet();
      //     colorSet.list = generateColors(
      //       am4core.color(theme.palette.chart.light)
      //     );
      //     pieSeries.colors = colorSet;
      //     pieSeries.slices.template.fillOpacity = 0.3;
      //   }
    }

    createSeries();

    return () => {
      chart.dispose();
    };
  }, [data, seriesData]);

  return <Box id={"piechart"} sx={{ width: "100%", height: "100%" }}></Box>;
}
