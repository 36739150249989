import { useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createSourceInStoreDataset,
} from "./store/sourceInStoreDatasetsSlice";
import history from "@ntai/@history";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourceInStoreDatasetForm from "./SourceInStoreDatasetForm";

export default function SourceInStoreDatasetCreate() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const sourceInStoreId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.status
  );

  const activeId = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.activeId
  );

  function handleSave(formData) {
    dispatch(
      createSourceInStoreDataset({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInStoreId: sourceInStoreId,
        formData: formData,
      })
    );
  }

  function handleCancel() {
    history.push(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets`
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceInStoreDataset" &&
      status.result === "success"
    ) {
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets/${activeId}/edit`
      );
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      padding={0}
      title="Add Dataset"
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets`}
    >
      <NtaiForm>
        <SourceInStoreDatasetForm
          action="add"
          formData={null}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
