import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchTopic = createAsyncThunk(
  "searchTopics/getSearchTopic",
  async (searchId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/search/${searchId}/topics`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSearchTopic = createAsyncThunk(
  "searchTopics/createOrUpdateSearchTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/topics`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchTopic = createAsyncThunk(
  "searchTopics/updateSearchTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, uuId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/topics/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchTopicsSlice = createSlice({
  name: "searchTopics",
  initialState: {
    data: [],
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchTopic.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getSearchTopic" };
    },
    [getSearchTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchTopic",
        message: action.payload.message,
      };
    },

    [createOrUpdateSearchTopic.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "createOrUpdateSearchTopic" };
    },
    [createOrUpdateSearchTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSearchTopic",
        message: action.payload.message,
      };
    },

    [updateSearchTopic.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "updateSearchTopic" };
    },
    [updateSearchTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchTopic",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchTopicsSlice.actions;

export default searchTopicsSlice.reducer;
