import React, { useEffect, useMemo, useState } from "react";
import { Box, darken, lighten, useTheme } from "@mui/material";
import {
  tooltipConfig,
  gridConfig,
  xAxisLabelNoRotationConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import { grey } from "@mui/material/colors";
import NtaiChartUtil from "../../util/NtaiChartUtil";
import NtaiScatterEChartUtil from "./util/NtaiScatterEChartUtil";
const _ = require("lodash");

export default function NtaiBubbleEChartOld(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;

  // hack to always have up to date metadata

  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  const DEFAULT_OPTION = {
    grid: {
      containLabel: true,
      left: 20,
      top: 20,
      right: 20,
      bottom: 20,
    },
    tooltip: {
      ...tooltipConfig,
      formatter: function (params) {
        return NtaiEChartUtil.getTooltipContent({
          values: [
            {
              key: params.marker,
              value: NtaiScatterEChartUtil.getTooltipValues(
                params["data"][9],
                metadataRef.current
              ),
            },
          ],
        });
        // return "X";
      },
    },
    legend: {
      right: "10%",
      top: "3%",
      data: null,
    },
    xAxis: {
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLabel: xAxisLabelNoRotationConfig,
      axisLine: {
        lineStyle: {
          width: 0.1,
        },
      },
      axisTick: {
        lineStyle: {
          width: 0.2,
        },
      },
      name: "X-Axis",
      nameLocation: "middle",
      nameGap: 20,
      nameTextStyle: {
        fontSize: 11,
        fontWeight: "bold",
      },
    },
    yAxis: {
      axisLabel: yAxisLabelConfig,
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLine: {
        lineStyle: {
          width: 0.1,
        },
      },
      axisTick: {
        lineStyle: {
          width: 0.2,
        },
      },
      name: "X-Axis",
      nameLocation: "middle",
      nameGap: 30,
      nameTextStyle: {
        fontSize: 11,
        fontWeight: "bold",
      },
      scale: true,
    },

    series: [],
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "true",
        },
      },
    },
  };

  const [option, setOption] = useState(DEFAULT_OPTION);

  function onChartClick(params) {
    handleClick(
      NtaiScatterEChartUtil.getSourceWidgetFilterFields(
        params,
        metadataRef.current
      )
    );
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(
      action,
      NtaiScatterEChartUtil.getSourceWidgetFilterFields(
        params,
        metadataRef.current
      )
    );
  }

  useEffect(() => {
    const seriesNames = ["Series Name"];
    const optionCopy = _.cloneDeep(option);
    const colors = NtaiEChartUtil.getColorPalette(theme, 1);
    // copyOption["legend"]["data"] = ["Series Name"];

    // optionCopy["xAxis"]["name"] = metadataRef.current["axisLabels"][0];
    //NtaiEChartUtil.getSourceField(metadata, 2)["field"]["label"] || "X";
    // optionCopy["yAxis"]["name"] = "Y";
    // optionCopy["yAxis"]["name"] = metadataRef.current["axisLabels"][1];

    optionCopy["xAxis"]["name"] =
      NtaiEChartUtil.getSourceFieldByAxisFg(metadata, 1)["field"]["label"] ||
      "X";
    optionCopy["yAxis"]["name"] =
      NtaiEChartUtil.getSourceFieldByAxisFg(metadata, 2)["field"]["label"] ||
      "Y";

    //NtaiEChartUtil.getSourceField(metadata, 3)["field"]["label"] || "Y";

    const revisedData = [data];
    const minMax = NtaiScatterEChartUtil.getMinMax(data, metadata);

    revisedData.forEach((dat, i) => {
      optionCopy["series"][i] = {
        name: ``,
        data: dat.map((d, i) => {
          return [
            d["x"],
            d["y"],
            d["value"],
            d["name"],
            "series",
            metadata["filterFg"],
            metadata["filterFg"] ? d["value"] : null,
            metadata["filterFg"] ? d["total"] : null,
            d.hasOwnProperty("colorValue") ? d["colorValue"] : null,
            d["values"],
          ];
        }),

        label: {
          show: true,
          position: "top",
          formatter: function (p) {
            // console.log("P = ", p);
            return p["data"][3];
          },
        },
        type: "scatter",
        symbolSize: function (d) {
          const sizeField = NtaiEChartUtil.getSourceFieldBySizeFg(metadata);
          if (sizeField) {
            const value = d[9][sizeField["field"]["uuId"]];
            return NtaiScatterEChartUtil.interpolate(
              value,
              minMax[0],
              minMax[1],
              5,
              20
            );
          } else return 5;
        },
        emphasis: {
          focus: "series",
          //   label: {
          //     show: true,
          //     formatter: function (param) {
          //       return param.data[3];
          //     },
          //     position: "top",
          //   },
        },
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(120, 36, 50, 0.5)",
          shadowOffsetY: 5,
          color: function (params) {
            console.log("Color params: ", params);
            return dat.hasOwnProperty("colorValue")
              ? NtaiEChartUtil.getItemStyleColor(
                  "bg",
                  dat["colorValue"],
                  colors[0],
                  metadata
                )
              : colors[0];
          },

          // return NtaiChartUtil.getHexColorCode(params["data"][8], null);
          //NtaiChartUtil.getHexColorCode(3, null), // colors[0],
          // return "blue";
        },
      };
    });

    setOption(optionCopy);
  }, [data, metadata]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"].length > 0 && chart}
    </Box>
  );
}
