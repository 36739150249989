import ReportDefinitionCreate from "./ReportDefinitionCreate";
import ReportDefinitionEdit from "./ReportDefinitionEdit";
import ReportDefinitionList from "./ReportDefinitionList";

const ReportDefinitionConfig = {
  routes: [
    {
      path: "/reports/definitions",
      exact: true,
      component: ReportDefinitionList,
    },
    {
      path: "/reports/definitions/create/:reportdefinitiontypeid",
      exact: true,
      component: ReportDefinitionCreate,
    },
    {
      path: "/reports/definitions/edit/:id",
      exact: true,
      component: ReportDefinitionEdit,
    },
  ],
};

export default ReportDefinitionConfig;
