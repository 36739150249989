import { Box, useTheme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import NtaiNavigationItem from "./NtaiNavigationItem";
import Scrollbar from "@ntai/components/Scrollbar";
import EntityMenu from "app/main/pages/core/entity/menu/EntityMenu";
const _ = require("lodash");

const useStyles = makeStyles({
  icon: {
    fontSize: 24,
    color: grey[600],
    "&:hover": {
      color: blue[700],
    },
  },
});
export default function NtaiNavigation(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigationData = useSelector((state) => state.ntai.navigation.data);
  const activeNavigationData = _.get(navigationData, props.activeNavigation);
  const [selectedMenu, setSelectedMenu] = React.useState();

  function handleClick(item) {
    setSelectedMenu(item.id);

    // to be connverted to list for all.
    // if (item.type === "node") setOpen(!open);
  }

  useEffect(() => {
    if (
      activeNavigationData &&
      _.has(activeNavigationData, "children") &&
      _.isArray(activeNavigationData["children"]) &&
      activeNavigationData["children"].length > 0
    ) {
      setSelectedMenu(activeNavigationData["children"][0]["id"]);
    }
  }, [activeNavigationData]);

  return (
    <Box
      sx={{
        height: `calc(100% - 53px)`,
        display: "flex",
        width: "100%",
      }}
    >
      <Scrollbar autoHide={false}>
        {props.activeNavigation === "entities-library" && <EntityMenu />}
        {!["entities-library"].includes(props.activeNavigation) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {activeNavigationData &&
              activeNavigationData.children.map((item, index) => {
                return (
                  <NtaiNavigationItem
                    key={`ntai-nav-item-${index}`}
                    item={item}
                    selectedMenu={selectedMenu}
                    handleClick={handleClick}
                  />
                );
              })}
          </Box>
        )}
      </Scrollbar>
    </Box>
  );
}
