import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils";
import { Box, IconButton, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceInCsvColumns from "./SourceInCsvColumns";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

export default function SourceInCsvEditForm(props) {
  const { action, handleClose, formData, handleFormSubmit } = props;
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceInCsvForm")
    );

    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    // reset({ sourceInCsvForm: action === "edit" ? formData : {} });
    setValue("sourceInCsvForm", action === "edit" ? formData : null);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "620px",
        height: "100%",
      }}
    >
      <React.Fragment>
        <NtaiPanel
          width="100%"
          header={`Edit ${_.get(formData, "fileName")}`}
          subheader="Enter dataset name, description, header data including delimiter and file patterns"
          headerActions={
            <IconButton onClick={() => handleClose()}>
              <FontAwesomeIcon size="xs" icon={faClose} />
            </IconButton>
          }
        />
        <NtaiTabs>
          <Box label="GENERAL">
            <NtaiFormPanel
              width="100%"
              title={`Edit ${_.get(formData, "fileName")}`}
              subheader="Enter dataset name, description, header data including delimiter and file patterns"
              handleSave={handleSave}
              handleCancel={handleClose}
            >
              <NtaiTextField
                name="sourceInCsvForm.datasetName"
                variant="outlined"
                size="small"
                placeholder="Dataset name"
                label="Dataset name"
                rules={{
                  required: "Dataset name is required",
                  validate: NtaiUtils.isValidSparkDatasetName,
                }}
              />

              <NtaiTextField
                name="sourceInCsvForm.description"
                variant="outlined"
                size="small"
                multiline
                minRows={2}
                maxRows={2}
                placeholder="Description"
                label="Description"
              />
              <NtaiTextField
                name="sourceInCsvForm.csvHeader"
                variant="outlined"
                size="small"
                multiline
                minRows={5}
                maxRows={5}
                placeholder="Header"
                label="CSV Header (Optional)"
              />

              <NtaiTextField
                name="sourceInCsvForm.filePatterns"
                variant="outlined"
                size="small"
                multiline
                minRows={3}
                maxRows={3}
                placeholder="File Patterns"
                label="File Patterns"
              />
            </NtaiFormPanel>
          </Box>
          <Box label="COLUMNS" sx={{ display: "flex", height: "100%" }}>
            <SourceInCsvColumns
              sourceInStoreFileId={action === "edit" ? formData["uuId"] : null}
              handleCancel={handleClose}
            />
          </Box>
        </NtaiTabs>
      </React.Fragment>
    </Box>
  );
}
