import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiSelectField from "../inputs/NtaiSelectField";
const _ = require("lodash");

export default function NtaiResourceTree(props) {
  const { data, idField, nameField } = props;
  const theme = useTheme();

  function generateLabel(label) {
    return (
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box sx={{ flexBasis: "60%" }}>{label}</Box>
        <Box
          sx={{ display: "flex", flexBasis: "40%", flexDirection: "column" }}
        >
          <NtaiSelectField name="t1" options={[]} />
        </Box>
      </Box>
    );
  }

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes[idField]}
      nodeId={nodes[idField]}
      label={generateLabel(nodes[nameField])}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      aria-label="tree view"
      defaultCollapseIcon={<ExpandMore />}
      defaultExpanded={[data[idField]]}
      defaultExpandIcon={<ChevronRight />}
      sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {renderTree(data)}
    </TreeView>
  );
}
