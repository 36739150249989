import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import DashboardViewWidgets from "./widgets/DashboardViewWidgets";
const _ = require("lodash");

export const DashboardViewContext = React.createContext();

export default function DashboardViewForm({
  action,
  formData,
  handleFormSubmit,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();

  const dashboardWidgets = _.get(formData, "dashboardWidgets");

  const [selectedWidgetIds, setSelectedWidgetIds] = React.useState(
    dashboardWidgets &&
      Array.isArray(dashboardWidgets) &&
      dashboardWidgets.length > 0
      ? _.map(dashboardWidgets, (obj) => obj.uuId)
      : []
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("dashboardViewForm")
    );

    const revisedFormData = {
      ...sanitizedFormData,
      dashboardWidgetUuIds: selectedWidgetIds,
    };

    handleFormSubmit(revisedFormData);
  }

  function onHandleCancel() {
    history.push("/admin/dashboards/views");
  }

  useEffect(() => {
    reset({ dashboardViewForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <DashboardViewContext.Provider
      value={{ selectedWidgetIds, setSelectedWidgetIds, handleFormSubmit }}
    >
      <NtaiTabs>
        <Box
          sx={{
            paddingTop: theme.spacing(0),
            display: "flex",
            justifyContent: "start",
          }}
          label="GENERAL"
        >
          <NtaiFormPanel
            width="520px"
            header="General Description"
            subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
            handleSave={onHandleSave}
            handleCancel={onHandleCancel}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(2),
                }}
              >
                <NtaiTextField
                  name="dashboardViewForm.name"
                  label="Name*"
                  rules={{ required: "Name is required" }}
                />
                <NtaiSwitchField
                  name="dashboardViewForm.activeFg"
                  label="Active?"
                />
              </Box>

              <NtaiTextField
                name="dashboardViewForm.description"
                label="Description"
                placeholder="Description"
                multiline
                minRows={2}
                maxRows={2}
              />
            </Box>
          </NtaiFormPanel>
        </Box>
        <Box
          disabled={action === "add" ? true : false}
          sx={{ display: "flex", height: "100%" }}
          label="WIDGETS"
        >
          <DashboardViewWidgets />
        </Box>
      </NtaiTabs>
    </DashboardViewContext.Provider>
  );
}
