import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiHeatmapEChart from "./NtaiHeatmapEChart";

const data = [
  {
    x: "Mon",
    y: "AM",
    value: 1.3,
    total: 5,
  },
  {
    x: "Mon",
    y: "PM",
    value: 2.3,
    total: 7,
  },
  {
    x: "Tue",
    y: "AM",
    value: 3.3,
    total: 12,
  },
  {
    x: "Tue",
    y: "PM",
    value: 4.3,
    total: 5,
  },
];

// const data = {
//   series: "Heatmap",
//   xAxis: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"],
//   yAxis: [
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//     "Sunday",
//   ],
//   values: [
//     [0, 0, 2],
//     [0, 1, 3],
//     [1, 0, 4],
//     [2, 3, 5],
//     [4, 5, 6],
//     [5, 1, 8],
//     [2, 2, 9],
//     [3, 6, 10],
//   ],
// };

export default function NtaiTestHeatmap() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Heatmap chart">
          <NtaiHeatmapEChart
            widgetId="treemap"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fielduuid1: {
                  fieldOrder: 1,
                  field: {
                    uuId: "weekUuId",
                    label: "Week",
                  },
                },
                fielduuid2: {
                  fieldOrder: 2,
                  field: {
                    uuId: "ampmUuId",
                    label: "Period",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>

      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
