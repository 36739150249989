import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjectForms = createAsyncThunk(
  "objectForms/getObjectForms",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/forms`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectForm = createAsyncThunk(
  "objectForms/getObjectForm",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectForm = createAsyncThunk(
  "objectForms/createObjectForm",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/forms`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectForm = createAsyncThunk(
  "objectForms/updateObjectForm",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectForm = createAsyncThunk(
  "objectForms/deleteObjectForm",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/forms/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectFormsSlice = createSlice({
  name: "objectForms",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectForms.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getObjectForms" };
    },
    [getObjectForms.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectForms",
        message: action.payload.message,
      };
    },
    [getObjectForm.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getObjectForm" };
    },
    [getObjectForm.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectForm",
        message: action.payload.message,
      };
    },
    [createObjectForm.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "createObjectForm" };
      state.activeId = action.payload.uuId;
    },
    [createObjectForm.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectForm",
        message: action.payload.message,
      };
    },
    [updateObjectForm.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateObjectForm" };
    },
    [updateObjectForm.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectForm",
        message: action.payload.message,
      };
    },
    [deleteObjectForm.fulfilled]: (state, action) => {
      state.data = null;
      state.status = { result: "success", method: "deleteObjectForm" };
    },
    [deleteObjectForm.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectForm",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, setActiveDomainId } =
  objectFormsSlice.actions;

export default objectFormsSlice.reducer;
