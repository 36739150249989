import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";

import { Box, useTheme } from "@mui/material";

const Scrollbar = ({ autoHide, className, children, ...rest }) => {
  const theme = useTheme();

  return (
    <Scrollbars
      autoHide={autoHide ? autoHide : false}
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 10,
              background: `${theme.colors.alpha.black[30]}`,
              // borderRadius: `${theme.general.borderRadiusSm}`,
              transition: `${theme.transitions.create(["background"])}`,

              "&:hover": {
                background: `${theme.colors.alpha.black[30]}`,
              },
            }}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Scrollbar;
