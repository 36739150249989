import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import History from "@ntai/@history";
import {
  clearStatus,
  setActiveId,
  updateThesaurus,
} from "./store/thesaurusesSlice";
import ThesaurusForm from "./ThesaurusForm";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ThesaurusEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.thesaurusesSlice.status);
  const dataMap = useSelector((state) => state.core.thesaurusesSlice.data);
  const activeId = useSelector((state) => state.core.thesaurusesSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);
  const domainId = useSelector(
    (state) => state.core.thesaurusesSlice.defaultDomainId
  );

  function toThesaurusImport() {
    History.push(`/library/thesauruses/${id}/import`);
  }

  function handleSave(formData) {
    formData["domainId"] = domainId;
    dispatch(updateThesaurus({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    if (status.method === "updateThesaurus" && status.result === "success") {
      enqueueSnackbar(
        `Thesaurus "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );

      History.push(`/library/thesauruses`);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back={`/library/thesauruses`}
      headerActions={
        <Button
          size="small"
          variant="contained"
          onClick={() => toThesaurusImport()}
        >
          Thesaurus Load Utility
        </Button>
      }
    >
      <NtaiForm>
        <ThesaurusForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
