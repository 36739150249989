import {
  Avatar,
  AvatarGroup,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { deepOrange, grey, red } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import React from "react";
const _ = require("lodash");

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: grey[50],
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OversightSummaryEntityList({ data }) {
  const theme = useTheme();

  function generateTableData() {
    const rows = [];

    if (_.isArray(data)) {
      data.forEach((row, i) => {
        rows.push(
          <StyledTableRow key={`ost-entity-row-${i}`}>
            <TableCell
              sx={{
                p: "8px",
                fontSize: "12px",
                fontWeight: 700,
                verticalAlign: "top",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {row.entityName}
                </Typography>
                <Typography variant="subtitle2">
                  {/* United States - Ryder Institute */}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ p: "8px", verticalAlign: "top" }}>
              {row.topicName}
            </TableCell>
            <TableCell sx={{ p: "8px", verticalAlign: "top", fontWeight: 700 }}>
              {row.numOfAssessments}
            </TableCell>
            <TableCell sx={{ p: "8px", verticalAlign: "top", fontWeight: 700 }}>
              {row.numOfActions}
            </TableCell>
            <TableCell sx={{ p: "8px", verticalAlign: "top", fontWeight: 700 }}>
              {row.numOfNotes}
            </TableCell>
            <TableCell sx={{ p: "8px", verticalAlign: "top", fontWeight: 700 }}>
              {row.numOfSnapshots}
            </TableCell>
            <TableCell sx={{ p: "8px", verticalAlign: "top", fontWeight: 700 }}>
              1
            </TableCell>
            <TableCell
              sx={{
                p: "8px",
                verticalAlign: "top",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",

                  verticalAlign: "top",
                  gap: "8px",
                }}
              >
                <Avatar
                  sx={{
                    fontSize: 10,
                    height: 24,
                    width: 24,
                    bgcolor: red[500],
                  }}
                >
                  AD
                </Avatar>
                <Avatar
                  sx={{
                    fontSize: 10,
                    height: 24,
                    width: 24,
                    bgcolor: deepOrange[300],
                  }}
                >
                  MN
                </Avatar>
              </Box>
            </TableCell>
          </StyledTableRow>
        );
      });
    }

    return rows;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          pt: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flexBasis: "75%" }}>
          <Typography variant="h6">
            Available Entities {_.isArray(data) ? ` (${data.length})` : null}
          </Typography>
        </Box>
        <Box sx={{ flexBasis: "25%" }}>
          <NtaiSimpleSearchBar size="12px" />
        </Box>
      </Box>

      <Box
        sx={{
          height: "220px",
          display: "flex",
          width: "100%",
          overflowY: "auto",
          border: theme.general.border1,
        }}
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "20%" }}
                >
                  Entity
                </TableCell>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "20%" }}
                >
                  Topic
                </TableCell>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "10%" }}
                >
                  Assessments
                </TableCell>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "10%" }}
                >
                  Actions
                </TableCell>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "7%" }}
                >
                  Notes
                </TableCell>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "10%" }}
                >
                  Published
                </TableCell>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "8%" }}
                >
                  Records
                </TableCell>
                <TableCell
                  sx={{ background: grey[100], p: "8px", width: "15%" }}
                >
                  Users
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{generateTableData()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}
