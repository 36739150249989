import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSourceOutJobMigrations,
} from "./store/sourceOutJobMigrationsSlice";
import {
  PendingOutlined,
  ErrorOutlined,
  CancelOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  LoopOutlined,
} from "@mui/icons-material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";

const _ = require("lodash");

function getStatusIcon(status) {
  if (status === 1) return <PendingOutlined />;
  else if (status === 2) return <LoopOutlined />;
  else if (status === 3) return <CheckCircleOutlined />;
  else if (status === 4) return <CancelOutlined />;
  else if (status === 5) return <WarningOutlined />;
  else return <ErrorOutlined color="error" />;
}

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "migrationTs",
    numeric: false,
    disablePadding: false,
    label: "Migration ID",
    width: "15%",
    fontWeight: 700,
  },
  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "10%",
  },

  {
    id: "dateStarted",
    numeric: false,
    disablePadding: false,
    label: "Started",
    width: "12%",
    transformFunc: NtaiUtils.formatDateTimeCol,
  },
  {
    id: "dateCompleted",
    numeric: false,
    disablePadding: false,
    label: "Completed",
    width: "13%",
    transformFunc: NtaiUtils.formatDateTimeCol,
  },
  {
    id: "duration",
    numeric: false,
    disablePadding: false,
    label: "Duration",
    width: "10%",
  },

  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: "Message",
    width: "40%",
  },
];

export default function SourceOutJobMigrationList(props) {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceJobId = props.match.params.id;

  const sourceJobDataMap = useSelector(
    (state) => state.sources.sourceOutJobsSlice.data
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceOutJobMigrationsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceOutJobMigrationsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleToolbarAction(action, selectedIds) {}

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceOutJobMigrations" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceOutJobMigrations({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceJobId: sourceJobId,
        params: {
          minMode: true,
        },
      })
    );
  }, []);

  return (
    <NtaiPage
      padding={2}
      title={`${sourceDefData["name"]} / ${_.get(
        _.get(sourceJobDataMap, sourceJobId),
        "name"
      )} / Migrations`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs`}
    >
      {loaded && (
        <NtaiSimpleTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          isDensed={true}
          toolbarActions={[{ value: "export", label: "Export" }]}
          handleToolbarAction={handleToolbarAction}
          path={`/sourcedefs/${sourceDefId}/sources/${sourceId}/jobs/${sourceJobId}/migrations`}
          title="Migrations"
        />
      )}
      {!loaded && <NtaiCircularProgress />}
      {loaded && (!data || data.length === 0) && (
        <NtaiEmptyMessage
          vAlign="center"
          header="Migration data not available"
          subheader="This section displays ongoing and completed migrations for the selected job."
        />
      )}
    </NtaiPage>
  );
}
