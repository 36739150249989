import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import ContactGroupForm from "./ContactGroupForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setActiveId,
  updateContactGroup,
  clearStatus,
} from "./store/contactGroupsSlice";
import History from "@ntai/@history/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ContactGroupEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = React.useState();
  const id = props.match.params.id;
  const status = useSelector((state) => state.core.contactGroupsSlice.status);
  const dataMap = useSelector((state) => state.core.contactGroupsSlice.data);
  const activeId = useSelector(
    (state) => state.core.contactGroupsSlice.activeId
  );
  const formData = dataMap[id];

  function handleFormSubmit(formData) {
    dispatch(updateContactGroup({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateContactGroup" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Contact group "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      History.push(`/library/contact-groups`);
    }

    if (
      status &&
      status.method === "updateContactGroup" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Contact Groups / Edit ${formData["name"]}`}
      back="/library/contact-groups"
    >
      <NtaiForm>
        <ContactGroupForm
          action="edit"
          handleFormSubmit={handleFormSubmit}
          formData={formData}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
