import { Box, Button, Divider, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiRadioGroupField from "../NtaiRadioGroupField";
import NtaiSelectField from "../NtaiSelectField";
import NtaiSliderField from "../NtaiSliderField";
import NtaiTextField from "../NtaiTextField";
import NtaiFilterCheckboxGroup from "./NtaiFilterCheckboxGroup";

import NtaiFilterDateRangeSlider from "./NtaiFilterDateRangeSlider";
import NtaiFilterMultiSelectList from "./NtaiFilterMultiSelectList";
import NtaiFilterRadioGroup from "./NtaiFilterRadioGroup";
import NtaiFilterSlider from "./NtaiFilterSlider";
import NtaiFilterRangeSlider from "./NtaiFilterRangeSlider";
import NtaiFilterDatePicker from "./NtaiFilterDatePicker";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiFilterDateRangePicker from "./NtaiFilterDateRangePicker";
const _ = require("lodash");

const radioOptions = [
  {
    label: "Radio Option 1",
    value: "1",
  },
  {
    label: "Radio Option 2",
    value: "2",
  },
];

const options = [
  {
    label: "Checkbox Option 1",
    value: "1",
  },
  {
    label: "Checkbox Option 2",
    value: "2",
  },
];

const options1 = { 1: "A", 2: "B" };

export default function NtaiTestFilters() {
  const theme = useTheme();

  const { getValues, reset, setValue } = useFormContext();

  function submitForm() {
    console.log("Form values: ", getValues("filterForm"));
    console.log(
      "Sanitized formData: ",
      NtaiUtils.sanitizeFormData(getValues("filterForm"))
    );
  }

  useEffect(() => {
    reset({
      // filterForm: {},
      filterForm: {
        radioGender: "1",
        cb1: ["1"],
        slider: 23,
        rangeSlider: [14, 67],
        dateRange: [1262284200000, 1514745000000],
        dateField: "2020-12-12",
        dateRangePicker: [1262284200000, 1514745000000],
        // cb2: ["1"],
      },
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        p: theme.spacing(4),
      }}
    >
      <Box
        sx={{
          border: theme.general.border1,
          width: "25%",
          height: "450px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: "12px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <NtaiFilterRadioGroup
            label="Sex"
            name="filterForm.radioGender"
            options={radioOptions}
          />
          <NtaiFilterCheckboxGroup
            name="filterForm.cb1"
            label="CB1"
            options={options}
          />
          <NtaiFilterMultiSelectList
            name="filterForm.countries"
            label="Countries"
            options={[
              { value: "a", label: "Sharad" },
              { value: "b", label: "Prakash" },
              { value: "c", label: "Nanu" },
              { value: "d", label: "John" },
              { value: "e", label: "Mike" },
              { value: "f", label: "Sancy" },
            ]}
          />
          <NtaiFilterSlider
            name="filterForm.slider"
            label="Income"
            min={10}
            max={100}
            step={1}
            displaymarks="true"
          />
          <NtaiFilterRangeSlider
            name="filterForm.rangeSlider"
            label="Income Range"
            min={10}
            max={100}
            step={10}
            displaymarks="false"
          />
          <NtaiFilterDateRangeSlider
            label="Date Range"
            name="filterForm.dateRange"
            min={946665000000}
            max={1721748676021}
            displaymarks="false"
          />
          <NtaiFilterDatePicker
            label="Date Field"
            name="filterForm.dateField"
          />
          <NtaiFilterDateRangePicker
            label="Date Range Picker"
            name="filterForm.dateRangePicker"
            // from={946665000000}
            // to={1721748676021}
          />
        </Box>
        <Button variant="contained" onClick={() => submitForm()}>
          Submit
        </Button>
      </Box>
    </Box>
  );
}
