import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceView = createAsyncThunk(
  "searchSourceViews/getSearchSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, sourceViewUuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewUuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceView = createAsyncThunk(
  "searchSourceViews/updateSearchSourceView",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, sourceViewUuId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewUuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceViewsSlice = createSlice({
  name: "searchSourceViews",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.sourceViewUuId]: action.payload,
      };
      state.status = { result: "success", method: "getSearchSourceView" };
    },
    [getSearchSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceView",
        message: action.payload.message,
      };
    },
    [getSearchSourceView.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSearchSourceView",
      };
    },
    [updateSearchSourceView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.sourceViewUuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSearchSourceView" };
    },
    [updateSearchSourceView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchSourceViewsSlice.actions;

export default searchSourceViewsSlice.reducer;
