import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDatasets = createAsyncThunk(
  "datasets/getDatasets",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/models/datasets");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDataset = createAsyncThunk(
  "datasets/getDataset",
  async (datasetUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/models/datasets/${datasetUuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDataset = createAsyncThunk(
  "datasets/createDataset",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/models/datasets", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDataset = createAsyncThunk(
  "datasets/updateDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/models/datasets/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDataset = createAsyncThunk(
  "datasets/deleteDataset",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/models/datasets/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadDatasetFile = createAsyncThunk(
  "datasets/uploadDatasetFile",
  async (values, { rejectWithValue }) => {
    const { uuId, formData, config } = values;
    try {
      const response = await server.post(
        `/models/datasets/${uuId}/upload`,
        formData,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const datasetsSlice = createSlice({
  name: "datasets",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDatasets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getDatasets" };
    },
    [getDatasets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDatasets",
        message: action.payload.message,
      };
    },
    [getDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getDataset" };
    },
    [getDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDataset",
        message: action.payload.message,
      };
    },
    [createDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createDataset" };
      state.activeId = action.payload.uuId;
    },
    [createDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createDataset",
        message: action.payload.message,
      };
    },
    [updateDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateDataset" };
    },
    [updateDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateDataset",
        message: action.payload.message,
      };
    },

    [uploadDatasetFile.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "uploadDatasetFile" };
    },
    [uploadDatasetFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "uploadDatasetFile",
        message: action.payload.message,
      };
    },

    [deleteDataset.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteDataset" };
    },
    [deleteDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteDataset",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = datasetsSlice.actions;

export default datasetsSlice.reducer;
