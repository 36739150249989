import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getDatasets } from "../../../dataset/store/datasetsSlice";
import { updateFunction } from "../../store/functionsSlice";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { FunctionEditorContext } from "../../FunctionEdit";
import { grey } from "@mui/material/colors";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

export default function FunctionDatasetSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset, watch } = useFormContext();
  const { action, formData } = useContext(FunctionEditorContext);

  const [selectedDatasetIds, setSelectedDatasetIds] = useState(
    formData["datasetUuIds"]
  );

  const datasetDataMap = useSelector(
    (state) => state.models.datasetsSlice.data
  );

  const datasets =
    datasetDataMap && !_.isEmpty(datasetDataMap)
      ? Object.values(datasetDataMap)
      : [];

  const activeId = useSelector((state) => state.models.functionsSlice.activeId);
  const status = useSelector((state) => state.models.functionsSlice.status);

  function toggleDatasetSelection(datasetId) {
    if (selectedDatasetIds.includes(datasetId)) {
      setSelectedDatasetIds(
        _.filter(selectedDatasetIds, function (o) {
          return o === datasetId ? false : true;
        })
      );
    } else {
      setSelectedDatasetIds([...selectedDatasetIds, datasetId]);
    }
  }

  useEffect(() => {
    if (!_.isEqual(formData["datasetUuIds"], selectedDatasetIds)) {
      const formDataCopy = _.cloneDeep(formData);
      formDataCopy["datasetUuIds"] = selectedDatasetIds;

      dispatch(
        updateFunction({
          uuId: activeId,
          formData: formDataCopy,
        })
      );
    }
  }, [selectedDatasetIds]);

  useEffect(() => {
    setSelectedDatasetIds(formData["datasetUuIds"]);
  }, [formData]);

  useEffect(() => {
    dispatch(getDatasets());
  }, []);

  return (
    <NtaiAccordion
      title="Datasets"
      titleIcon="table"
      titleSecondary={<Chip size="small" label={selectedDatasetIds.length} />}
    >
      <Box
        sx={{
          display: "flex",
          px: theme.spacing(2),
          py: theme.spacing(1),
          flexDirection: "column",
          height: "150px",
        }}
      >
        <Scrollbar>
          <Box sx={{ border: theme.general.border1 }}>
            {Array.isArray(datasets) && datasets.length > 0 && (
              <List sx={{ p: 0 }}>
                {datasets.map((o, i) => (
                  <React.Fragment>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "&:hover": {
                          background: grey[100],
                        },
                        cursor: "default",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: theme.spacing(1),
                          alignItems: "center",
                        }}
                      >
                        <NtaiIconButton
                          icon={
                            selectedDatasetIds.includes(o["uuId"])
                              ? faCheckSquare
                              : faSquare
                          }
                          handleClick={() => toggleDatasetSelection(o["uuId"])}
                        />

                        <ListItemText
                          primary={o["name"]}
                          secondary={o["description"]}
                        />
                      </Box>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            )}
          </Box>
        </Scrollbar>

        {!datasets ||
          (Array.isArray(datasets) && datasets.length === 0 && (
            <Box>No datasets available</Box>
          ))}
      </Box>
    </NtaiAccordion>
  );
}
