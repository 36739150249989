import {
  faCircleStar,
  faSitemap,
  faStar,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../SearchEdit";
import SearchTopicForm from "./SearchTopicForm";
import { createOrUpdateSearchTopic } from "./store/searchTopicsSlice";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { lighten } from "@amcharts/amcharts4/.internal/core/utils/Colors";
import { grey } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils/NtaiUtils";

const _ = require("lodash");

export default function SearchTopicWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchData } = React.useContext(SearchContext);
  const [open, setOpen] = React.useState(false);

  const searchTopicData = useSelector(
    (state) => state.search.searchTopicsSlice.data
  );

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSave(formData) {
    dispatch(
      createOrUpdateSearchTopic({
        searchId: _.get(searchData, "uuId"),
        formData: formData,
      })
    );
  }

  return (
    <React.Fragment>
      {!_.isEmpty(searchTopicData) && (
        <NtaiMuiBadge
          badgeContent={
            _.has(searchTopicData, "uuId") &&
            searchTopicData.publishedFg === 1 ? (
              <FontAwesomeIcon size="xs" icon={faCheck} />
            ) : null
          }
          color="success"
          top={4}
          right={2}
        >
          <Tooltip title="Change Topic">
            <Chip
              icon={<FontAwesomeIcon icon={faCircleStar} />}
              label={
                <Typography
                  color="inherit"
                  variant="subtitle2"
                  fontWeight="700"
                >
                  {NtaiUtils.trunc(searchTopicData.dataOrObjectTopicName, 20)}
                </Typography>
              }
              size="small"
              onClick={() => handleOpen()}
            />
          </Tooltip>
        </NtaiMuiBadge>
      )}
      {/* <NtaiMuiBadge
        badgeContent={
          _.has(searchTopicData, "uuId") &&
          searchTopicData.publishedFg === 1 ? (
            <FontAwesomeIcon size="xs" icon={faCheck} />
          ) : null
        }
        color="success"
        top={8}
        right={2}
      >
        <IconButton onClick={() => handleOpen()}>
          <FontAwesomeIcon size="2xs" icon={faCircleStar} />
        </IconButton>
      </NtaiMuiBadge> */}

      {(!searchTopicData || _.isEmpty(searchTopicData)) && (
        <Tooltip title="Assign Topic">
          <IconButton onClick={() => handleOpen()}>
            <FontAwesomeIcon size="2xs" icon={faCircleStar} />
          </IconButton>
        </Tooltip>
      )}

      <NtaiDialog
        title="Assign Topic"
        size="sm"
        open={open}
        handleDialogClose={handleClose}
      >
        <SearchTopicForm handleSave={handleSave} handleCancel={handleClose} />
      </NtaiDialog>
    </React.Fragment>
  );
}
