import { Box, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useSelector } from "react-redux";
import SourceTemplateListingList from "./listing/SourceRecordListingList";
import SourceTemplateRecordTagList from "./tag/SourceRecordTagList";
import SourceTemplateLabelList from "./label/SourceRecordLabelList";
import SourceTemplateFilterPanelList from "./filter/SourceFilterSchemeList";
import SourceRecordTemplateList from "./record/SourceRecordTemplateList";
import SourceReportTemplateList from "./report/SourceReportTemplateList";
import SourceSeriesImportCsvSchemeList from "./series/SourceSeriesImportCsvSchemeList";
import SourceMasterTemplateList from "./master/SourceMasterTemplateList";

const _ = require("lodash");

export default function SourceTemplateWrapper(props) {
  const theme = useTheme();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = props.match.params.id;

  return (
    <NtaiPage
      title={`${_.get(sourceDefData, "name")} / Templates`}
      back={`/sourcedefs/${sourceDefId}/smwrapper`}
    >
      <NtaiTabs>
        <Box sx={{ height: "100%" }} label="MASTER TEMPLATES">
          <SourceMasterTemplateList />
        </Box>
        <Box sx={{ height: "100%" }} label="LISTINGS">
          <SourceTemplateListingList />
        </Box>
        <Box sx={{ height: "100%" }} label="LABELS">
          <SourceTemplateLabelList />
        </Box>
        <Box sx={{ height: "100%" }} label="TAGS">
          <SourceTemplateRecordTagList />
        </Box>

        <Box label="RECORD">
          <SourceRecordTemplateList />
        </Box>
        {/* <Box label="WORKFLOW">
          <NtaiForm>
            <SourceTemplateWorkflowForm />
          </NtaiForm>
        </Box> */}
        <Box sx={{ height: "100%" }} label="FILTER SCHEMES">
          <NtaiForm>
            <SourceTemplateFilterPanelList />
          </NtaiForm>
        </Box>
        <Box label="REPORTS">
          <SourceReportTemplateList />
        </Box>
        <Box label="IMPORT SCHEME">
          <SourceSeriesImportCsvSchemeList />
        </Box>
      </NtaiTabs>
    </NtaiPage>
  );
}
