import React, { useEffect } from "react";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { useDispatch, useSelector } from "react-redux";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { Box, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getThesaurusConceptRelations } from "./store/thesaurusConceptRelationsSlice";
import { getRelThesaurusConcepts } from "../store/thesaurusConceptsSlice";

const _ = require("lodash");

export default function ThesaurusConceptRelationForm(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { action, formData, handleSave, handleCancel } = props;
  const { getValues, reset } = useFormContext();

  const thesaurusId = useSelector(
    (state) => state.core.thesaurusesSlice.activeId
  );

  const conceptId = useSelector(
    (state) => state.core.thesaurusConceptsSlice.activeId
  );

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const relationTypeOptions = _.get(
    codelistData,
    CODELIST_CODES.LIB_THESAURUS_CONCEPT_REL_TYPE_CODELIST
  );

  const relConceptsData = useSelector(
    (state) => state.core.thesaurusConceptsSlice.relConceptsData
  );

  const relConceptOptions = NtaiUtils.getSelectOptions(
    Object.values(relConceptsData),
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("conceptRelationForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    dispatch(
      getRelThesaurusConcepts({
        thesaurusId: thesaurusId,
        params: {
          exclude: conceptId,
        },
      })
    );
    reset({ conceptRelationForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiFormPanel
      header="Add Relation"
      subheader="Add relation"
      handleSave={
        Array.isArray(relConceptOptions) &&
        relConceptOptions.length > 0 &&
        onHandleSave
      }
      handleCancel={handleCancel}
    >
      {Array.isArray(relConceptOptions) && relConceptOptions.length > 0 && (
        <>
          <NtaiSelectField
            name="conceptRelationForm.relConceptUuId"
            options={relConceptOptions}
            label="Related Concept"
          />

          <NtaiSelectField
            name="conceptRelationForm.relTypeCode"
            options={relationTypeOptions}
            label="Relation Type"
          />

          <NtaiSwitchField
            name="conceptRelationForm.activeFg"
            label="Active?"
          />
        </>
      )}

      {!relConceptOptions ||
        (Array.isArray(relConceptOptions) && relConceptOptions.length === 0 && (
          <Box>No concepts found for relation. Start by adding concepts.</Box>
        ))}
    </NtaiFormPanel>
  );
}
