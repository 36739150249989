import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import {
  createSourceLinkedSource,
  clearStatus,
} from "./store/sourceLinkedSourcesSlice";
import history from "@ntai/@history";
import SourceLinkedSourceForm from "./SourceLinkedSourceForm";

export default function SourceLinkedSourceCreate(props) {
  const dispatch = useDispatch();

  const sourceId = props.match.params.id;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const status = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.status
  );
  const activeId = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.activeId
  );

  function handleSave(formData) {
    dispatch(
      createSourceLinkedSource({ sourceId: sourceId, formData: formData })
    );
  }

  function handleCancel() {
    history.push(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`
    );
  }

  useEffect(() => {
    if (
      status.method === "createSourceLinkedSource" &&
      status.result === "success"
    ) {
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${activeId}/edit`
      );
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Add Linked Source"
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`}
    >
      <NtaiForm>
        <SourceLinkedSourceForm
          action="add"
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
