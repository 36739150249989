import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "../../../../../../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../../../../../../node_modules/react-resizable/css/styles.css";
import classes from "./OversightEntityOverviewGrid.module.css";
import { Resizable } from "react-resizable";
import GridLayout from "react-grid-layout";
import { Box } from "@mui/material";
const _ = require("lodash");
const ReactGridLayout = WidthProvider(RGL);

export default function OversightEntityOverviewGrid({
  layout,
  height,
  width,
  items,
  handleLayoutChange,
}) {
  function generateDOM() {
    return items.map(
      (item, i) => item
      // <div className="flex w-full h-full">{item}</div>
    );
  }

  function generateLayout() {
    return _.map(new Array(items), function (item, i) {
      const y = Math.ceil(Math.random() * 2) + 1;
      return {
        x: (i * 2) % 12,
        y: Math.floor(i / 6) * y,
        w: 2,
        h: y,
        i: i.toString(),
        minH: 2,
        minW: 2,
      };
    });
  }

  function onLayoutChange(layout) {
    handleLayoutChange(layout);
  }

  return (
    <GridLayout
      width={1180}
      layout={layout}
      onLayoutChange={handleLayoutChange}
    >
      {generateDOM()}
    </GridLayout>
  );
}
