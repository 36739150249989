import { combineReducers } from "@reduxjs/toolkit";
import oversightTopicsSlice from "./oversightTopicsSlice";
import oversightNavigationSlice from "../navigation/store/oversightNavigationSlice";
import oversightTopicAdjsSlice from "../main/entity/topics/content/assessment/store/oversightTopicAdjsSlice";
import oversightTopicActionsSlice from "../main/entity/topics/content/actions/store/oversightTopicActionsSlice";
import oversightTopicNotesSlice from "../main/entity/topics/content/notes/store/oversightTopicNotesSlice";
import oversightTopicSourcesSlice from "../main/entity/topics/content/source-records/sources/store/oversightTopicSourcesSlice";
import oversightTopicSourceRecordsSlice from "../main/entity/topics/content/source-records/store/oversightTopicSourceRecordsSlice";
import oversightSummaryAllSlice from "../main/root/store/oversightSummaryAllSlice";
import oversightEntitiesSlice from "../main/entity/store/oversightEntitiesSlice";
import oversightEntityOverviewViewsSlice from "../main/entity/overview/store/oversightEntityOverviewViewsSlice";
import oversightEntityOverviewViewWidgetsSlice from "../main/entity/overview/store/oversightEntityOverviewViewWidgetsSlice";

const oversightReducer = combineReducers({
  oversightNavigationSlice,
  oversightTopicsSlice,
  oversightTopicAdjsSlice,
  oversightTopicActionsSlice,
  oversightTopicNotesSlice,
  oversightTopicSourcesSlice,
  oversightTopicSourceRecordsSlice,
  oversightSummaryAllSlice,
  oversightEntitiesSlice,
  oversightEntityOverviewViewsSlice,
  oversightEntityOverviewViewWidgetsSlice,
});

export default oversightReducer;
