import React, { useContext, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function OversightTopicNoteForm({
  note,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("oversightTopicNoteForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ oversightTopicNoteForm: note === "edit" ? formData : {} });
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}
      >
        <Box sx={{ width: "75%", display: "flex", flexDirection: "column" }}>
          <NtaiTextField
            name="oversightTopicNoteForm.title"
            label="Title"
            placeholder="Title"
          />
        </Box>

        <NtaiTextField
          name="oversightTopicNoteForm.body"
          label="Description"
          minRows={10}
          maxRows={10}
          multiline
          placeholder="Your notes"
        />

        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
          }}
        >
          <Button onClick={() => handleCancel()} size="small">
            CANCEL
          </Button>
          <Button
            onClick={() => onHandleSave()}
            size="small"
            variant="contained"
          >
            SAVE
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
