import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getThesaurusSemTypes = createAsyncThunk(
  "thesaurusSemTypes/getThesaurusSemTypes",
  async (thesaurusId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/semtypes`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getThesaurusSemType = createAsyncThunk(
  "thesaurusSemTypes/getThesaurusSemType",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, semTypeId } = values;
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/semtypes/${semTypeId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createThesaurusSemType = createAsyncThunk(
  "thesaurusSemTypes/createThesaurusSemType",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, formData } = values;
      const response = await server.post(
        `/library/thesauruses/${thesaurusId}/semtypes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateThesaurusSemType = createAsyncThunk(
  "thesaurusSemTypes/updateThesaurusSemType",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, semTypeId, formData } = values;
      const response = await server.patch(
        `/library/thesauruses/${thesaurusId}/semtypes/${semTypeId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteThesaurusSemType = createAsyncThunk(
  "thesaurusSemTypes/deleteThesaurusSemType",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, semTypeId } = values;
      const response = await server.delete(
        `/library/thesauruses/${thesaurusId}/semtypes/${semTypeId}`
      );
      return { semTypeId: semTypeId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const thesaurusSemTypesSlice = createSlice({
  name: "thesaurusSemTypes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getThesaurusSemTypes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "semTypeId") };
      state.status = { result: "success", method: "getThesaurusSemTypes" };
    },
    [getThesaurusSemTypes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusSemTypes",
        message: action.payload.message,
      };
    },
    [getThesaurusSemType.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.semTypeId]: action.payload,
      };
      state.activeId = action.payload.semTypeId;
      state.status = { result: "success", method: "getThesaurusSemType" };
    },
    [getThesaurusSemType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusSemType",
        message: action.payload.message,
      };
    },
    [createThesaurusSemType.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.semTypeId]: action.payload,
      };
      state.activeId = action.payload.semTypeId;
      state.status = { result: "success", method: "createThesaurusSemType" };
    },
    [createThesaurusSemType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createThesaurusSemType",
        message: action.payload.message,
      };
    },
    [updateThesaurusSemType.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.semTypeId]: action.payload,
      };
      state.status = { result: "success", method: "updateThesaurusSemType" };
    },
    [updateThesaurusSemType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateThesaurusSemType",
        message: action.payload.message,
      };
    },
    [deleteThesaurusSemType.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.semTypeId);
      state.status = { result: "success", method: "deleteThesaurusSemType" };
    },
    [deleteThesaurusSemType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteThesaurusSemType",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = thesaurusSemTypesSlice.actions;

export default thesaurusSemTypesSlice.reducer;
