import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordLabels = createAsyncThunk(
  "sourceRecordLabels/getSourceRecordLabels",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceRecordLabel = createAsyncThunk(
  "sourceRecordLabels/getSourceRecordLabel",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordLabel = createAsyncThunk(
  "sourceRecordLabels/createSourceRecordLabel",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordLabel = createAsyncThunk(
  "sourceRecordLabels/updateSourceRecordLabel",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordLabel = createAsyncThunk(
  "sourceRecordLabels/deleteSourceRecordLabel",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordLabelsSlice = createSlice({
  name: "sourceRecordLabels",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordLabels.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceRecordLabels",
      };
    },
    [getSourceRecordLabels.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordLabels",
        message: action.payload.message,
      };
    },
    [getSourceRecordLabel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceRecordLabel",
      };
    },
    [getSourceRecordLabel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordLabel",
        message: action.payload.message,
      };
    },
    [createSourceRecordLabel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordLabel",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceRecordLabel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordLabel",
        message: action.payload.message,
      };
    },
    [updateSourceRecordLabel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordLabel",
      };
    },
    [updateSourceRecordLabel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordLabel",
        message: action.payload.message,
      };
    },
    [deleteSourceRecordLabel.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordLabel",
      };
    },
    [deleteSourceRecordLabel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordLabel",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceRecordLabelsSlice.actions;

export default sourceRecordLabelsSlice.reducer;
