import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { SignalWifiStatusbar4BarSharp } from "@mui/icons-material";
const _ = require("lodash");

export default function DatasetFileUpload({
  formData,
  handleSave,
  handleCancel,
  handleFileUpload,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);
  const activeId = useSelector((state) => state.models.datasetsSlice.activeId);
  const status = useSelector((state) => state.models.datasetsSlice.status);

  const fileTypeOptions = _.get(
    codelistData,
    CODELIST_CODES.MOD_DATASET_FILE_TYPE_CODE
  );

  function displayUploadedFile() {
    if (_.get(formData, "fileName")) {
      return (
        <NtaiLabelValue
          label="Uploaded File"
          value={_.get(formData, "fileName")}
        />
      );
    }
  }

  return (
    <NtaiDialogFormPanel
      header="Upload file"
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <NtaiSelectField
        name="datasetForm.fileFormatCode"
        options={fileTypeOptions}
        label="Select file type"
      />
      <NtaiTextField
        name="datasetForm.fileDelimiter"
        placeholder="Delimiter"
        label="Delimiter"
      />
      <NtaiFileUpload handleFileUpload={handleFileUpload} />
      {displayUploadedFile()}
    </NtaiDialogFormPanel>
  );
}
