import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatasetForm from "./DatasetForm";
import { setActiveId, updateDataset, clearStatus, uploadDatasetFile } from "./store/datasetsSlice";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DatasetEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.models.datasetsSlice.status);
  const dataMap = useSelector((state) => state.models.datasetsSlice.data);
  const activeId = useSelector((state) => state.models.datasetsSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateDataset({ uuId: id, formData: formData }));
  }

  function handleFileUpload(fileUploadData) {
    dispatch(uploadDatasetFile(fileUploadData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateDataset" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Dataset "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "uploadDatasetFile" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `File uploaded for dataset "${_.get(dataMap[activeId], "name")}".`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      back="/models/datasets"
      title={`Datasets > ${_.get(dataMap[id], "name")}`}
    >
      <NtaiForm>
        <DatasetForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleDatasetFileUpload={handleFileUpload}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
