import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
const _ = require("lodash");

export default function DashboardStdWidgetForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const displayTypeOptions = _.get(
    codelistData,
    CODELIST_CODES.COM_DASHBOARD_WIDGET_DISPLAY_TYPE_CODE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("dashboardWidgetForm")
    );

    handleFormSubmit({ updateTypeFg: 1, ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ dashboardWidgetForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiFormPanel
      width="520px"
      header="General Details"
      subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
          }}
        >
          <NtaiTextField
            name="dashboardWidgetForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
            disabled={true}
          />
          <NtaiTextField
            name="dashboardWidgetForm.label"
            label="Label*"
            rules={{ required: "Label is required" }}
          />
          <NtaiSwitchField
            name="dashboardWidgetForm.activeFg"
            label="Active?"
            disabled={true}
          />
        </Box>

        <NtaiTextField
          name="dashboardWidgetForm.description"
          label="Description"
          placeholder="Description"
          multiline
          minRows={2}
          maxRows={2}
          disabled={true}
        />

        <NtaiSelectField
          name="dashboardWidgetForm.displayTypeCode"
          label="Display Type*"
          options={displayTypeOptions}
          rules={{ required: "Display type is required" }}
          isDisabled={true}
        />
      </Box>
    </NtaiFormPanel>
  );
}
