import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { useSelector, useDispatch } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  getAlertRecordBatchDocuments,
  createAlertRecordBatchDocument,
  updateAlertRecordBatchDocument,
  clearStatus,
  deleteAlertRecordBatchDocument,
  downloadAlertRecordBatchDocument,
  clearDownloadData,
} from "./store/alertRecordBatchDocumentsSlice";
import AlertRecordBatchDocumentForm from "./AlertRecordBatchDocumentForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { AlertEditContext } from "../../AlertEdit";
import NtaiDialogPanel from "@ntai/components/panels/NtaiDialogPanel";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "document.title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    fontWeight: 700,
    width: "20%",
  },
  {
    id: "document.fileName",
    numeric: false,
    disablePadding: false,
    label: "File",
    width: "20%",
  },
  {
    id: "document.fileSize",
    numeric: false,
    disablePadding: false,
    label: "Size",
    transformFunc: NtaiUtils.bytesToSize,
    width: "5%",
  },
  {
    id: "numberOfRecords",
    numeric: false,
    disablePadding: false,
    label: "# Records",
    width: "12%",
  },
  {
    id: "document.dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Created",
    transformFunc: NtaiUtils.formatDateCol,
    width: "15%",
  },
  {
    id: "userCreated",
    numeric: false,
    disablePadding: false,
    label: "Created By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

export default function AlertRecordBatchDocumentList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const { alertId, alertMasterId, gUuId, selectedRecords, setSelectedRecords } =
    React.useContext(AlertEditContext);

  const dataMap = useSelector(
    (state) => state.monitor.alertRecordBatchDocumentsSlice.data
  );

  const downloadData = useSelector(
    (state) => state.monitor.alertRecordBatchDocumentsSlice.downloadData
  );

  const downloadFileName = useSelector(
    (state) => state.monitor.alertRecordBatchDocumentsSlice.downloadFileName
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];
  console.log("Document dataMap: ", dataMap);
  console.log("Document list: ", data);

  const status = useSelector(
    (state) => state.monitor.alertRecordBatchDocumentsSlice.status
  );

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "document.title"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDownload(id) {
    dispatch(
      downloadAlertRecordBatchDocument({
        alertId: gUuId,
        alertMasterId: alertMasterId,
        uuId: id,
        fileName: dataMap[id]["document"]["fileName"],
      })
    );
  }

  function downloadFile() {
    // downloadedData
    if (downloadData) {
      const url = window.URL.createObjectURL(new Blob([downloadData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();
      dispatch(clearDownloadData());
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteAlertRecordBatchDocument({
          alertId: gUuId,
          alertMasterId: alertMasterId,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
    setCurrentId(null);
  }

  useEffect(() => {
    if (
      status &&
      ["createAlertRecordBatchDocument"].includes(status.method) &&
      status.result === "success"
    ) {
      setAction("list");
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "downloadAlertRecordBatchDocument" &&
      status.result === "success"
    ) {
      downloadFile();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteAlertRecordBatchDocument" &&
      status.result === "success"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Document(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  function handleSave(formData) {
    if (action === "add") {
      dispatch(
        createAlertRecordBatchDocument({
          alertId: gUuId,
          alertMasterId: alertMasterId,
          formData: formData,
        })
      );
    } else if (action === "edit")
      dispatch(
        updateAlertRecordBatchDocument({
          alertId: gUuId,
          alertMasterId: alertMasterId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  useEffect(() => {
    dispatch(
      getAlertRecordBatchDocuments({
        alertId: gUuId,
        alertMasterId: alertMasterId,
      })
    );
  }, []);

  return (
    <>
      {action === "list" && (
        <NtaiDialogPanel
          width="100%"
          subheader="Batch documents are document files that are attached with one or more records. Documents uploaded for bulk records are managed in this section."
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            keyColumn="document.uuId"
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            toolbarActions={toolbarActions}
            handleToolbarAction={handleToolbarAction}
            title="Batch Documents"
          />
        </NtaiDialogPanel>
      )}
      {(action === "add" || action === "edit") && (
        <NtaiForm>
          <AlertRecordBatchDocumentForm
            action={action}
            formData={action === "edit" ? _.get(dataMap, currentId) : null}
            handleSave={handleSave}
            handleDownload={handleDownload}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "document.title"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
