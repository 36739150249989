import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";

import {
  clearStatus,
  setActiveId,
  updateSourceReportTemplate,
} from "./store/sourceReportTemplatesSlice";
import SourceReportTemplateForm from "./SourceReportTemplateForm";
const _ = require("lodash");

export default function SourceReportTemplateEdit(props) {
  const dispatch = useDispatch();

  const { id, handleCancel, formData } = props;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.status
  );


  function handleSave(dat) {
    dispatch(
      updateSourceReportTemplate({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: dat,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceReportTemplate" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiForm>
      <SourceReportTemplateForm
        action="edit"
        formData={formData}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
