import { Box, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SourceReportTemplateGeneralForm from "./SourceReportTemplateGeneralForm";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsStatus,
} from "../../store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearDerivedFieldsStatus,
} from "../../derivedfield/store/sourceDerivedFieldsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  getReportTemplates,
  clearStatus as clearReportTemplatesStatus,
} from "app/main/pages/core/templates/report/store/reportTemplatesSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function SourceReportTemplateForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.data
  );

  const data = _.get(dataMap, activeId);

  const reportTemplatesDataMap = useSelector(
    (state) => state.core.reportTemplatesSlice.data
  );

  const statusReportTemplates = useSelector(
    (state) => state.core.reportTemplatesSlice.status
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const statusSourceFields = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const statusDerivedFields = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFields =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap).map((f) => {
          return {
            uuId: f.uuId,
            name: f.name,
            label: f.label,
            type: 1,
          };
        })
      : [];

  const sourceDerivedFields =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap).map((f) => {
          return {
            uuId: f.uuId,
            name: f.name,
            label: f.label + " (d)",
            type: 2,
          };
        })
      : [];

  const allFieldsData = [...sourceFields, ...sourceDerivedFields];

  const allFieldsDataOptions =
    _.isArray(allFieldsData) && allFieldsData.length > 0
      ? _.orderBy(
          NtaiUtils.getSelectOptions(allFieldsData, "uuId", "label"),
          ["label"],
          ["asc"]
        )
      : [];

  const reportTemplatesData =
    reportTemplatesDataMap && !_.isEmpty(reportTemplatesDataMap)
      ? _.orderBy(
          _.filter(Object.values(reportTemplatesDataMap), { activeFg: 1 }).map(
            (o) => {
              return {
                uuId: o["uuId"],
                name:
                  o["name"] +
                  " - " +
                  "[" +
                  o["sourceMins"]
                    .map((s) => {
                      return s["displayName"];
                    })
                    .join(", ") +
                  "]",
              };
            }
          ),
          ["name"],
          ["asc"]
        )
      : [];

  const reportTemplateOptions = _.isArray(reportTemplatesData)
    ? NtaiUtils.getSelectOptions(reportTemplatesData, "uuId", "name")
    : [];

  const revisedReportTemplateOptions =
    action === "add" && !_.isEmpty(dataMap)
      ? _.filter(reportTemplateOptions, function (o) {
          return _.filter(Object.values(dataMap), {
            reportTemplateUuId: o["value"],
          }).length > 0
            ? false
            : true;
        })
      : reportTemplateOptions;

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceReportTemplateForm")
    );

    const field = _.filter(allFieldsData, {
      uuId: sanitizedFormData["paramFieldUuId"],
    });

    sanitizedFormData["paramSourceDerivedFieldFg"] =
      field["type"] === 2 ? 1 : 0;

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    if (
      statusSourceFields &&
      statusDerivedFields &&
      statusSourceFields.result === "success" &&
      statusSourceFields.method === "getSourceFields" &&
      statusDerivedFields.result === "success" &&
      statusDerivedFields.method === "getSourceDerivedFields" &&
      statusReportTemplates &&
      statusReportTemplates.method === "getReportTemplates" &&
      statusReportTemplates.result === "success"
    ) {
      dispatch(clearSourceFieldsStatus());
      dispatch(clearDerivedFieldsStatus());
      dispatch(clearReportTemplatesStatus());
      setLoaded(true);
    }
  }, [statusSourceFields, statusDerivedFields, statusReportTemplates]);

  useEffect(() => {
    dispatch(getReportTemplates());
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
    reset({ sourceReportTemplateForm: action === "edit" ? formData : null });
  }, [formData]);

  return (
    <>
      {loaded && (
        <NtaiFormPanel
          width="40%"
          header={
            action === "add"
              ? "Add Report Template"
              : `Edit ${formData["reportTemplateName"]}`
          }
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
          handleSave={
            revisedReportTemplateOptions.length > 0 ? onHandleSave : null
          }
          handleCancel={handleCancel}
        >
          {revisedReportTemplateOptions.length > 0 && (
            <>
              <NtaiSelectField
                name="sourceReportTemplateForm.reportTemplateUuId"
                label="Report Template"
                options={revisedReportTemplateOptions}
                isDisabled={action === "edit" ? true : false}
              />
              <NtaiSelectField
                name="sourceReportTemplateForm.paramFieldUuId"
                label="Source Param Field"
                options={allFieldsDataOptions}
                isDisabled={action === "edit" ? true : false}
              />
            </>
          )}
          {revisedReportTemplateOptions.length === 0 && (
            <NtaiEmptyMessage
              header="No report templates available"
              subheader="Check if report templates are available in the admin section"
            />
          )}
        </NtaiFormPanel>
      )}

      {!loaded && (
        <Box
          sx={{
            p: theme.spacing(4),
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <NtaiCircularProgress vAlign="center" size={24} />
        </Box>
      )}
    </>
  );
}
