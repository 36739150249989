import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useFormContext } from "react-hook-form";

export default function NtaiDialogFormPanel(props) {
  const {
    height,
    title,
    subheader,
    children,
    handleCancel,
    handleSave,
    handleSaveLabel,
    handleCancelLabel,
    otherActions,
    handleOtherAction,
    disabledSave,
  } = props;
  const theme = useTheme();

  const { trigger } = useFormContext();

  const onHandleSubmit = async (e) => {
    // Trigger validations before submitting
    const isValid = await trigger();
    e.preventDefault();
    if (isValid) {
      handleSave();
    }
  };

  return (
    <Card
      elevation={0}
      sx={{ height: height ? height : null, pt: theme.spacing(1), p: 0 }}
    >
      {(title || subheader) && (
        <CardHeader sx={{ px: 0 }} title={title} subheader={subheader} />
      )}
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          pt: theme.spacing(1),
          px: 0,
        }}
      >
        {children}
      </CardContent>

      <CardActions
        sx={{
          px: 0,
          display: "flex",
          justifyContent: "space-between",
          // pt: theme.spacing(1),
          // borderTop: `1px solid ${theme.colors.alpha.black[20]}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          {otherActions &&
            Array.isArray(otherActions) &&
            otherActions.map((action, index) => {
              return (
                <Button
                  key={`dialog-form-otheraction-${index}`}
                  size="small"
                  variant={action.variant ? action.variant : "outlined"}
                  disabled={action.disabled ? action.disabled : false}
                  onClick={() => handleOtherAction(action.value)}
                >
                  {action.label.toUpperCase()}
                </Button>
              );
            })}
        </Box>

        {(handleSave || handleCancel) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            {handleCancel && (
              <Button size="small" onClick={() => handleCancel()}>
                {handleCancelLabel?.toUpperCase() || `CANCEL`}
              </Button>
            )}
            {handleSave && (
              <Button
                // Added submit
                type="submit"
                size="small"
                onClick={(e) => onHandleSubmit(e)}
                color="primary"
                variant="contained"
                startIcon={
                  props.hasOwnProperty("disabledSave") && props.disabledSave ? (
                    <FontAwesomeIcon icon={faSpinner} />
                  ) : null
                }
                disabled={
                  props.hasOwnProperty("disabledSave") && props.disabledSave
                    ? disabledSave
                    : false
                }
              >
                {handleSaveLabel?.toUpperCase() || "SAVE"}
              </Button>
            )}
          </Box>
        )}
      </CardActions>
    </Card>
  );
}
