import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceFilterSchemes = createAsyncThunk(
  "sourceFilterSchemes/getSourceFilterSchemes",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, params } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceFilterScheme = createAsyncThunk(
  "sourceFilterSchemes/getSourceFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceFilterScheme = createAsyncThunk(
  "sourceFilterSchemes/createSourceFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceFilterScheme = createAsyncThunk(
  "sourceFilterSchemes/updateSourceFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceFilterScheme = createAsyncThunk(
  "sourceFilterSchemes/deleteSourceFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceFilterSchemesSlice = createSlice({
  name: "sourceFilterSchemes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceFilterSchemes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceFilterSchemes",
      };
    },
    [getSourceFilterSchemes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceFilterSchemes",
        message: action.payload.message,
      };
    },
    [getSourceFilterScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceFilterScheme",
      };
    },
    [getSourceFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceFilterScheme",
        message: action.payload.message,
      };
    },
    [createSourceFilterScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceFilterScheme",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceFilterScheme",
        message: action.payload.message,
      };
    },
    [updateSourceFilterScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceFilterScheme",
      };
    },
    [updateSourceFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceFilterScheme",
        message: action.payload.message,
      };
    },
    [deleteSourceFilterScheme.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceFilterScheme",
      };
    },
    [deleteSourceFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceFilterScheme",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceFilterSchemesSlice.actions;

export default sourceFilterSchemesSlice.reducer;
