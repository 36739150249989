import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import { tooltipConfig } from "../chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

function processData(date_hist_data, categoryField) {
  let revised_data = _.cloneDeep(date_hist_data);

  // TODO: Fix date value without 'y' as prefix in milliseconds
  revised_data.forEach((dat) => {
    // dat[categoryField] = new Date(Number(_.get(dat, categoryField)));
    dat[categoryField] = new Date(Number(_.get(dat, categoryField)))
      // .getFullYear()
      .toISOString()
      .slice(0, 10);
  });

  console.log("Revised line series data: ", revised_data);
  return revised_data;
}

export default function NtaiLineSeriesChart(props) {
  const { widgetId, data, categoryField, valueField, handleClick, metadata } =
    props;
  const theme = useTheme();

  console.log("Line series init data: ", data);

  let colorSet = new am4core.ColorSet();

  React.useLayoutEffect(() => {
    let chart = am4core.create(
      "line-series".concat("-", widgetId),
      am4charts.XYChart
    );

    let dat = processData(data, categoryField);
    // console.log("Line series revised data: ", dat);
    chart.data = dat;
    // chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = 20;
    chart.cursor.behavior = "zoomY";
    // chart.cursor.zoomY = true; // new am4charts.XYCursor();
    chart.responsive.enabled = true;

    // zoom icon
    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#ffffff");
    chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#eeeeee");

    chart.zoomOutButton.icon.disabled = true;
    let zoomImage = chart.zoomOutButton.createChild(am4core.Image);
    zoomImage.href = "/images/zoom-icon.png";
    zoomImage.width = 15;
    zoomImage.height = 15;
    zoomImage.interactionsEnabled = false;

    let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0.1;
    categoryAxis.renderer.grid.template.stroke = "#fff";
    categoryAxis.title.fontSize = 11;
    categoryAxis.renderer.minGridDistance = 2;
    categoryAxis.interpolationDuration = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.rotation = 270;
    // label.maxWidth = 120;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    label.fontSize = 10;
    label.truncate = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    let valueAxisLabel = valueAxis.renderer.labels.template;
    valueAxisLabel.fontSize = 10;

    let axisTooltip = categoryAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 8;
    // categoryAxis.cursorTooltipEnabled = false;
    // categoryAxis.sortBySeries = series;

    let valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#767676");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    // valueAxisTooltip.rotation = 270;
    // valueAxisTooltip.dy = 5;
    valueAxisTooltip.dx = -20;
    valueAxisTooltip.label.fill = am4core.color("#fff");
    valueAxisTooltip.label.fontSize = 11;

    // Create series
    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = categoryField;
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.strokeWidth = 2;
      series.tensionX = 0.77;
      const colRand = NtaiUtils.getRandomFloat(0.2, 0.6);

      // series.stroke = am4core.color(
      //   lighten(theme.colors.chart.lighter, colRand)
      // );
      series.stroke = colorSet.next();

      series.legendSettings.labelText = "{name}";

      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color("#fff");
      bullet.circle.strokeWidth = 2;

      // let label = series.bullets.createChild(am4core.Label);
      // label.text = "Hello world!";
      // label.fontSize = 20;
      // label.align = "center";

      bullet.events.on("hit", function (ev) {
        // console.log("ev on ", ev);
        // console.log("metadata ", metadata);

        // console.log(
        //   "Clicked on " +
        //     ev.target.dataItem.dateX +
        //     ": " +
        //     ev.target.dataItem.valueY
        // );

        const sourceWidgetFilterFields = [
          {
            sourceFieldUuId: _.filter(
              Object.values(_.get(metadata, "fields")),
              {
                fieldOrder: 1,
              }
            )[0].field.uuId,
            fieldOperatorCode: 1,
            fieldValue: new Date(ev.target.dataItem.dateX)
              .toISOString()
              .slice(0, 10),
          },
          {
            sourceFieldUuId: _.filter(
              Object.values(_.get(metadata, "fields")),
              {
                fieldOrder: 2,
              }
            )[0].field.uuId,
            fieldOperatorCode: 1,
            fieldValue: ev.target.dataItem.component.dataFields.valueY,
          },
        ];

        // console.log("sourceWidgetFilterFields: ", sourceWidgetFilterFields);
        handleClick(sourceWidgetFilterFields, ev);
      });

      // tool tip
      //   series.tooltip.getFillFromObject = false;
      //   series.tooltip.autoTextColor = false;
      //   series.tooltip.background.cornerRadius =
      //     tooltipConfig.backgroundCornerRadius;
      //   series.tooltip.background.fill = am4core.color(
      //     tooltipConfig.backgroundFill
      //   );
      //   series.tooltip.background.fillOpacity =
      //     tooltipConfig.backgroundFillOpacity;
      //   series.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
      series.tooltip.label.fontSize = tooltipConfig.labelFontSize;
    }

    metadata["subCategoryValues"].forEach((smd) => {
      createSeries(smd.value, smd.label);
    });

    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = "10px";

    return () => {
      chart.dispose();
    };
  }, [data]);

  //   React.useEffect(() => {
  //     return () => {
  //       chart && chart.dispose();
  //     };
  //   }, []);

  return (
    <>
      {Array.isArray(data) && data.length > 0 && (
        <Box
          id={"line-series".concat("-", widgetId)}
          sx={{ width: "100%", height: "100%" }}
        ></Box>
      )}

      {Array.isArray(data) && data.length === 0 && <NtaiChartEmptyMessage />}
    </>
  );
}
