import RoleCreate from "./RoleCreate";
import RoleEdit from "./RoleEdit";
import RoleList from "./RoleList";

const RoleConfig = {
  routes: [
    {
      path: "/admin/roles",
      exact: true,
      component: RoleList,
    },
    {
      path: "/admin/roles/create",
      exact: true,
      component: RoleCreate,
    },
    {
      path: "/admin/roles/:id/edit",
      exact: true,
      component: RoleEdit,
    },
  ],
};

export default RoleConfig;
