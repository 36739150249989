import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getSourceDatasets } from "../../pl/stage/dataset/store/sourceDatasetsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import SourceOutStoreDbDatasetFields from "./fields/SourceOutStoreDbDatasetFields";
import SourceOutDatasetKeys from "../keys/SourceOutDatasetKeys";
import SourceOutDatasetScdPolicies from "../scd-policy/SourceOutDatasetScdPolicies";
const _ = require("lodash");

export default function SourceOutStoreDbDatasetForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const { reset, getValues } = useFormContext();
  const dispatch = useDispatch();
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceDatasetsDataMap = useSelector(
    (state) => state.sources.sourceDatasetsSlice.data
  );

  const sourceDatasetsData = Object.values(sourceDatasetsDataMap);

  const sourceDatasetOptions = NtaiUtils.getSelectOptions(
    sourceDatasetsData,
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOutStoreDbForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    dispatch(
      getSourceDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourcePipelineId: "*",
        sourceStageId: "*",
      })
    );

    reset({ sourceOutStoreDbForm: formData });
  }, []);

  return (
    <NtaiTabs>
      <Box label="DATASET">
        <NtaiFormPanel
          header="Select Dataset"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiSelectField
            name="sourceOutStoreDbForm.datasetUuId"
            label="Select Dataset"
            options={sourceDatasetOptions}
            isDisabled={action === "add" ? false : true}
          />

          <NtaiTextField
            name="sourceOutStoreDbForm.dbTableName"
            label="Table Name"
            placeholder="Table Name"
            isDisabled={action === "add" ? false : true}
          />
        </NtaiFormPanel>
      </Box>
      <Box label="FIELDS" disabled={action === "add" ? true : false}>
        <SourceOutStoreDbDatasetFields
          sourceDatasetId={_.get(formData, "datasetUuId")}
          handleCancel={handleCancel}
        />
      </Box>
      <Box
        label="KEYS AND SCD FIELDS"
        disabled={action === "add" ? true : false}
      >
        <SourceOutDatasetKeys
          format={1}
          sourceOutDatasetUuId={_.get(formData, "uuId")}
          sourceDatasetUuId={_.get(formData, "datasetUuId")}
        />
      </Box>
      <Box label="SCD POLICIES" disabled={action === "add" ? true : false}>
        <SourceOutDatasetScdPolicies
          sourceOutDatasetUuId={_.get(formData, "uuId")}
          sourceDatasetUuId={_.get(formData, "datasetUuId")}
        />
      </Box>
    </NtaiTabs>
  );
}
