import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getWorkflowDefs = createAsyncThunk(
  "workflowDefs/getWorkflowDefs",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/wfdefinitions");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWorkflowDef = createAsyncThunk(
  "workflowDefs/getWorkflowDef",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/wfdefinitions/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createWorkflowDef = createAsyncThunk(
  "workflowDefs/createWorkflowDef",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/wfdefinitions", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateWorkflowDef = createAsyncThunk(
  "workflowDefs/updateWorkflowDef",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/wfdefinitions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWorkflowDefActivityUsers = createAsyncThunk(
  "workflowDefs/getWorkflowDefActivityUsers",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/wfdefinitions/${uuId}/users`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteWorkflowDef = createAsyncThunk(
  "workflowDefs/deleteWorkflowDef",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/wfdefinitions/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const workflowDefsSlice = createSlice({
  name: "workflowDefs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
    startActivityUsers: [],
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getWorkflowDefs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getWorkflowDefs" };
    },
    [getWorkflowDefs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefs",
        message: action.payload.message,
      };
    },
    [getWorkflowDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getWorkflowDef" };
    },
    [getWorkflowDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDef",
        message: action.payload.message,
      };
    },
    [createWorkflowDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createWorkflowDef" };
    },
    [createWorkflowDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createWorkflowDef",
        message: action.payload.message,
      };
    },
    [updateWorkflowDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateWorkflowDef" };
    },
    [updateWorkflowDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateWorkflowDef",
        message: action.payload.message,
      };
    },

    [getWorkflowDefActivityUsers.fulfilled]: (state, action) => {
      state.startActivityUsers = action.payload;
      state.status = {
        result: "success",
        method: "getWorkflowDefActivityUsers",
      };
    },
    [getWorkflowDefActivityUsers.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefActivityUsers",
        message: action.payload.message,
      };
    },

    [deleteWorkflowDef.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteWorkflowDef" };
    },
    [deleteWorkflowDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteWorkflowDef",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = workflowDefsSlice.actions;

export default workflowDefsSlice.reducer;
