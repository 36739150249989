import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, setActiveId, updateRule } from "./store/rulesSlice";
import RuleForm from "./RuleForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function RuleEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.rulesSlice.status);
  const dataMap = useSelector((state) => state.core.rulesSlice.data);
  const activeId = useSelector((state) => state.core.rulesSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateRule({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (
      status &&
      status.method === "updateRule" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Rule "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
    }

    if (status && status.method === "updateRule" && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage title={`Edit ${_.get(dataMap[id], "name")}`} back="/admin/rules">
      <NtaiForm>
        <RuleForm action="edit" formData={formData} handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
