import React from "react";
import { MuiChipsInput } from "mui-chips-input";

export default function NtaiChipsInput({ data, handleChange }) {
  const [chips, setChips] = React.useState(data);

  const onHandleChange = (newChips) => {
    setChips(newChips);
  };

  React.useEffect(() => {
    handleChange(chips);
  }, [chips]);

  return (
    <MuiChipsInput
      size="small"
      label="Tags"
      value={chips}
      onChange={onHandleChange}
    />
  );
}
