import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Backdrop,
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  content: {
    // padding: theme.spacing(2),
  },
  paperStyle: {
    boxShadow: theme.colors.shadows.card,
    bgColor: "background.paper",
  },
}));

export default function NtaiPopper({
  open,
  anchorEl,
  placement,
  children,
  clickAwayHandler,
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement ? placement : "bottom-start"}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={4} square={true} className={classes.paperStyle}>
                <Box className={classes.content}>{children}</Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
}

NtaiPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object.isRequired,
  placement: PropTypes.string,
  clickAwayHandler: PropTypes.func.isRequired,
};
