import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  clearStatus,
  createSourceReportTemplate,
  setActiveId,
} from "./store/sourceReportTemplatesSlice";
import SourceReportTemplateForm from "./SourceReportTemplateForm";

export default function SourceReportTemplateCreate({
  handleCancel,
  redirectToList,
}) {
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.status
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.activeId
  );

  function handleFormSubmit(formData) {
    dispatch(
      createSourceReportTemplate({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceReportTemplate" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      redirectToList();
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(null));
  }, []);

  return (
    <NtaiForm>
      <SourceReportTemplateForm
        action="add"
        handleFormSubmit={handleFormSubmit}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
