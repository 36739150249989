import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

export default function NtaiReadOnlyTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    rowActions,
    rowOtherActions,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function buildHeader(headCell) {
    return headCell.ignore ? null : (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        // padding={headCell.disablePadding ? "none" : "default"}
        sortDirection={orderBy === headCell.id ? order : false}
        sx={{ px: "8px", width: headCell.width ? headCell.width : "auto" }}
        // style={{ fontWeight: "normal" }}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => buildHeader(headCell))}
        {rowActions &&
          rowActions.map((rowAction, index) => (
            <TableCell key={index}></TableCell>
          ))}
        {rowOtherActions &&
          Array.isArray(rowOtherActions) &&
          rowOtherActions.length > 0 && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

NtaiReadOnlyTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};
