import { Box, Typography, useTheme } from "@mui/material";
import NtaiAddRecordCard from "@ntai/components/cards/NtaiAddRecordCard";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiActionsPanel from "@ntai/components/panels/NtaiActionsPanel";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceViewForm from "./SourceViewForm";
import {
  clearStatus,
  createSourceView,
  deleteSourceView,
  getSourceViews,
  setActiveId,
  updateSourceView,
} from "./store/sourceViewsSlice";
import history from "@ntai/@history";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function SourceViewList(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);

  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  // const sourceId = props.match.params.id;

  const dataMap = useSelector((state) => state.sources.sourceViewsSlice.data);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];
  const status = useSelector((state) => state.sources.sourceViewsSlice.status);

  function handleFormDialogClose() {
    setOpenFormDialog(false);
  }

  function handleAdd() {
    setAction("add");
    setOpenFormDialog(true);
  }

  function handleDelete() {
    dispatch(
      deleteSourceView({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: currentId,
      })
    );
  }

  function handleCancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction(null);
  }

  function handleHeaderAction(actionName, id) {
    if (actionName === "edit") {
      setCurrentId(id);
      setAction("edit");
      setOpenFormDialog(true);
    } else if (actionName === "delete") {
      setAction("delete");
      setCurrentId(id);
      setOpenConfirmDeleteDialog(true);
    }
  }

  function handleAction(actionName, id) {
    dispatch(setActiveId(id));
    if (actionName === "widgets")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${id}/wm`
      );
    if (actionName === "criteria") {
      setAction("edit");
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${id}/criteria`
      );
    }
  }

  function handleFormSubmit(formData) {
    if (action === "add")
      dispatch(
        createSourceView({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: formData,
        })
      );
    else if (action === "edit") {
      // explicitly set criteria from orig object
      formData["criteria"] = dataMap[currentId]["criteria"];
      formData["esCriteria"] = dataMap[currentId]["esCriteria"];

      dispatch(
        updateSourceView({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: currentId,
          formData: formData,
        })
      );
    }
  }

  function renderSourceViewItems() {
    let result = [];
    if (Array.isArray(data) && data.length > 0) {
      _.orderBy(data, ["name"], ["asc"]).forEach((item, index) => {
        result.push(
          <NtaiActionsPanel
            key={index}
            id={item.uuId}
            title={item.name}
            subheader={item.typeDecode}
            headerActions={[
              { value: "edit", label: "Edit" },
              { value: "delete", label: "Delete" },
            ]}
            handleHeaderAction={handleHeaderAction}
            width="320px"
            actions={[
              { value: "widgets", label: "Manage Widgets" },
              { value: "criteria", label: "Manage Criteria" },
            ]}
            handleAction={handleAction}
          >
            <Typography variant="subtitle2">
              {NtaiUtils.trunc(item.description, 75)}
            </Typography>
          </NtaiActionsPanel>
        );
      });

      return result;
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceViews" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceView" ||
        status.method === "updateSourceView")
    ) {
      handleFormDialogClose();
      setCurrentId(null);
      clearStatus();
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceView"
    ) {
      setOpenConfirmDeleteDialog(false);
      // setSelectedIds([]);
      setCurrentId(null);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceViews({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        params: {
          sourceUuId: sourceId,
          fetchMin: false,
        },
      })
    );
    // dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_VIEW_DISPLAY_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_VIEW_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_VIEW_CATEGORY_CODE));
  }, []);

  return (
    <>
      <NtaiPage
        title={`${_.get(sourceDefData, "name")} / Views`}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        {loaded && (
          <NtaiPanel
            width="75%"
            header="Manage Views"
            subheader="Source views provide easy to use templates to render source data in user specified templates"
          >
            <Box
              sx={{
                width: "100%",
                paddingTop: theme.spacing(1),
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: theme.spacing(2),
              }}
            >
              <NtaiAddRecordCard
                handleClick={handleAdd}
                border
                width="340px"
                height="auto"
              >
                Add new view
              </NtaiAddRecordCard>
              {renderSourceViewItems()}
            </Box>
          </NtaiPanel>
        )}

        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={openFormDialog}
        handleDialogClose={handleFormDialogClose}
        title="Add View"
        size="sm"
      >
        <NtaiForm>
          <SourceViewForm
            action={action}
            handleDialogClose={handleFormDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={[_.get(dataMap[currentId], "name")]}
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={handleCancelDelete}
      />
    </>
  );
}
