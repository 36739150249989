import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import PackageContentsWrapper from "./PackageContentsWrapper";
const _ = require("lodash");

export default function PackageForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);

  const typeOptions = _.get(
    codelistCodes,
    CODELIST_CODES.MOD_PACKAGE_TYPE_CODE
  );

  const privacyOptions = _.get(
    codelistCodes,
    CODELIST_CODES.GL_ACCESS_TYPE_CODE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("packageForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ packageForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ flexBasis: "50%", height: "100%", display: "flex" }}>
        <NtaiFormPanel
          width="520px"
          header="General Details"
          subheader="Enter package name, description and select collection type for the package."
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="packageForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />
          <NtaiTextField
            name="packageForm.description"
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Description"
            label="Description"
          />
          <NtaiSelectField
            label="Type*"
            name="packageForm.typeCode"
            isDisabled={action === "edit" ? true : false}
            options={typeOptions}
            rules={{ required: "Type is required" }}
          />
          <NtaiSelectField
            label="Privacy"
            name="packageForm.privacyCode"
            isDisabled={action === "edit" ? true : false}
            options={privacyOptions}
          />
        </NtaiFormPanel>
      </Box>
      {action === "edit" && <PackageContentsWrapper formData={formData} />}
    </Box>
  );
}
