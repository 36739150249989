import { Box, Typography } from "@mui/material";
import React from "react";
import NtaiQueryBuilder from "./NtaiQueryBuilder";
import server from "app/http/ntaiServer";
const _ = require("lodash");

const fields1 = {
  "program_id.keyword": {
    label: "Id",
    preferWidgets: ["text"],
    type: "text",
    valueSource: ["value"],
  },
  "program_name.keyword": {
    label: "Name",
    preferWidgets: ["text"],
    type: "text",
    valueSource: ["value"],
  },
  "type.keyword": {
    label: "Type",
    preferWidgets: ["text"],
    type: "text",
    valueSource: ["value"],
  },
};

const fields = {
  qty: {
    label: "Qty",
    type: "number",
    fieldSettings: {
      min: 0,
    },
    valueSources: ["value"],
    preferWidgets: ["number"],
  },
  brand: {
    label: "Brand",
    type: "select",
    fieldSettings: {
      listValues: [
        { value: "yellow", title: "Yellow" },
        { value: "green", title: "Green" },
        { value: "orange", title: "Orange" },
      ],
    },
  },

  price: {
    label: "Price",
    type: "number",
    valueSources: ["value"],
    fieldSettings: {
      min: 10,
      max: 100,
    },
    preferWidgets: ["slider", "rangeslider"],
  },
  // color: {
  //   label: "Color",
  //   type: "multiselect",
  //   valueSources: ["value"],
  //   fieldSettings: {
  //     listValues: [
  //       { value: "yellow", title: "Yellow" },
  //       { value: "green", title: "Green" },
  //       { value: "orange", title: "Orange" },
  //     ],
  //   },
  // },

  color: {
    label: "Color",
    type: "select",
    valueSources: ["value", "field"],
    fieldSettings: {
      asyncFetch: async (search, offset) => {
        const toSearch = _.isEmpty(search) ? "null" : search;
        const prodApi = await server.get("/codelists", {
          params: { name: toSearch },
        });
        const productsValues = prodApi.data.map((product) => ({
          title: product.name,
          value: product.code.toString(),
        }));

        return {
          values: productsValues,
          hasMore: false,
        };
      },
      useAsyncSearch: true,
    },
  },

  is_promotion: {
    label: "Promo?",
    type: "boolean",
    operators: ["equal"],
    valueSources: ["value"],
  },
};

export default function TestNtaiQueryBuilder() {
  function buildQuery(tree, config, jsonTree, formats) {
    console.log("here...", formats);
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "50%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography>Basic</Typography>
      <NtaiQueryBuilder fields={fields} buildQuery={buildQuery} />
    </Box>
  );
}
