import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import React from "react";
import { useDispatch } from "react-redux";

export default function SourceInFileUploadForm({
  pending,
  handleFileUpload,
  handleFormSubmit,
  handleCancel,
}) {
  function onHandleFileUpload(formData, config) {
    handleFileUpload(formData, config);
  }

  return (
    <NtaiDialogFormPanel
      title="Upload Files"
      subheader="Upload as many files as you want"
      handleSave={handleFormSubmit}
      handleCancel={handleCancel}
    >
      <NtaiFileUpload pending={pending} handleFileUpload={onHandleFileUpload} />
    </NtaiDialogFormPanel>
  );
}
