import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";

const _ = require("lodash");

export default function SourceLinkedSourceLinkForm(props) {
  const theme = useTheme();
  const { action, formData, handleFormSubmit, handleCancel } = props;
  const [sourceFields, setSourceFields] = useState([]);
  const { getValues, reset, setValue } = useFormContext();
  const dispatch = useDispatch();

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceLinkedSourceLinkForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    !_.isEmpty(sourceFieldsDataMap) &&
      setSourceFields(
        NtaiUtils.getSelectOptions(
          Object.values(sourceFieldsDataMap),
          "uuId",
          "name"
        )
      );
  }, [sourceFieldsDataMap]);

  useEffect(() => {
    setValue(
      "sourceLinkedSourceLinkForm.name",
      action === "edit" ? _.get(formData, "name") : null
    );
    setValue(
      "sourceLinkedSourceLinkForm.description",
      action === "edit" ? _.get(formData, "description") : null
    );
    setValue(
      "sourceLinkedSourceLinkForm.fieldUuId",
      action === "edit" ? _.get(formData, "fieldUuId") : null
    );
    setValue(
      "sourceLinkedSourceLinkForm.useLinkValueFg",
      action === "edit" ? _.get(formData, "useLinkValueFg") : null
    );
  }, [formData]);

  return (
    <NtaiFormPanel
      header={action === "add" ? "Add new link" : _.get(formData, "name")}
      subheader={_.get(formData, "description")}
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="sourceLinkedSourceLinkForm.name"
          label="Name*"
          placeholder="Name"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="sourceLinkedSourceLinkForm.description"
          label="Description"
          placeholder="Description"
          multiline
          minRows={2}
          maxRows={2}
        />

        <NtaiSelectField
          name="sourceLinkedSourceLinkForm.fieldUuId"
          options={sourceFields}
          label="Link Field"
          rules={{ required: "Link Field is required" }}
        />

        <NtaiSwitchField
          name="sourceLinkedSourceLinkForm.useLinkValueFg"
          label="Use Link Value?"
        />
      </Box>
    </NtaiFormPanel>
  );
}
