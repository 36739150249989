import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "@ntai/@history";
import {
  clearStatus,
  createReportTemplate,
} from "./store/reportTemplatesSlice";
import ReportTemplateForm from "./ReportTemplateForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ReportTemplateCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.core.reportTemplatesSlice.data);
  const status = useSelector((state) => state.core.reportTemplatesSlice.status);
  const activeId = useSelector(
    (state) => state.core.reportTemplatesSlice.activeId
  );

  function handleSave(formData) {
    dispatch(createReportTemplate(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "createReportTemplate"
    ) {
      enqueueSnackbar(
        `Report template "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      history.push(`/admin/templates/reports/${activeId}/edit`);
    }

    if (
      status &&
      status.result === "error" &&
      status.method === "createReportTemplate"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage back="/admin/templates/reports" title="Add Report Template">
      <NtaiForm>
        <ReportTemplateForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
