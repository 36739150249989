import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import Scrollbar from "@ntai/components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  getReportDefinitionCategories,
  clearStatus,
} from "../../admin/category/store/reportDefinitionCategoriesSlice";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

const reportMenuItems = [
  {
    value: "1",
    label: "All Reports",
  },
  {
    value: "2",
    label: "Created by Me",
  },
  {
    value: "3",
    label: "Shared with Me",
  },
  {
    value: "4",
    label: "Public Reports",
  },
];

const folderMenuItems = [
  {
    value: "1",
    label: "All Folders",
  },
  {
    value: "2",
    label: "Created by Me",
  },
  {
    value: "3",
    label: "Shared with Me",
  },
  {
    value: "4",
    label: "Public Folders",
  },
];

export default function ReportSidebar() {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        flexBasis: "15%",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        borderRight: theme.general.border1,
      }}
    >
      <Box sx={{ p: theme.spacing(2), height: "54px", display: "flex" }}>
        <NtaiSimpleSearchBar placeholder="Search..." height="36px" />
      </Box>
      <Box
        sx={{
          height: `calc(100% - 54px)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            px: theme.spacing(2),
            pt: theme.spacing(2),
            pb: theme.spacing(1),
          }}
        >
          <Typography variant="subtitle2" fontWeight="700" color={grey[700]}>
            REPORTS
          </Typography>
        </Box>

        <MenuList sx={{ width: "100%", p: 0 }}>
          {reportMenuItems.map((item, index) => {
            return (
              <MenuItem
                sx={{ pl: theme.spacing(2), py: theme.spacing(0.75) }}
                key={`report-categories-menu-${index}`}
              >
                <ListItemText>
                  <Typography variant="subtitle2" color="inherit">
                    {item.label}
                  </Typography>
                </ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>

        <Box
          sx={{
            display: "flex",
            px: theme.spacing(2),
            pt: theme.spacing(2),
            pb: theme.spacing(1),
          }}
        >
          <Typography variant="subtitle2" fontWeight="700" color={grey[700]}>
            FOLDERS
          </Typography>
        </Box>

        <MenuList sx={{ width: "100%", p: 0 }}>
          {folderMenuItems.map((item, index) => {
            return (
              <MenuItem
                sx={{ pl: theme.spacing(2), py: theme.spacing(0.75) }}
                key={`report-categories-menu-${index}`}
              >
                <ListItemText>
                  <Typography variant="subtitle2" color="inherit">
                    {item.label}
                  </Typography>
                </ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
    </Box>
  );
}
