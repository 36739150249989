import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getWidgets = createAsyncThunk(
  "widgets/getWidgets",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/widgets");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWidget = createAsyncThunk(
  "widgets/getWidget",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/widgets/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createWidget = createAsyncThunk(
  "widgets/createWidget",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/widgets", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateWidget = createAsyncThunk(
  "widgets/updateWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/admin/widgets/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteWidget = createAsyncThunk(
  "widgets/deleteWidget",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/widgets/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const widgetsSlice = createSlice({
  name: "widgets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getWidgets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getWidgets" };
    },
    [getWidgets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWidgets",
        message: action.payload.message,
      };
    },
    [getWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getWidget" };
    },
    [getWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWidget",
        message: action.payload.message,
      };
    },
    [createWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createWidget" };
    },
    [createWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createWidget",
        message: action.payload.message,
      };
    },
    [updateWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateWidget" };
    },
    [updateWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateWidget",
        message: action.payload.message,
      };
    },
    [deleteWidget.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteWidget" };
    },
    [deleteWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteWidget",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = widgetsSlice.actions;

export default widgetsSlice.reducer;
