import { useTheme } from "@mui/material";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMigrations } from "./store/migrationsSlice";

const headCells = [
  {
    id: "uuId",
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "migrationTs",
    disablePadding: true,
    label: "Migration Id",
    width: "15%",
  },

  {
    id: "sourceMin.displayName",
    disablePadding: true,
    label: "Source",
    width: "15%",
  },

  {
    id: "typeDecode",
    disablePadding: true,
    label: "Type",
    width: "10%",
  },

  {
    id: "statusDecode",
    disablePadding: true,
    label: "Status",
    width: "10%",
  },

  {
    id: "message",
    disablePadding: true,
    label: "Message",
    width: "15%",
  },
  {
    id: "dateStarted",
    dataType: "date",
    disablePadding: true,
    label: "Date Started",
    width: "15%",
  },

  {
    id: "dateCompleted",
    dataType: "date",
    disablePadding: true,
    label: "Date Completed",
    width: "15%",
  },
];

export default function MigrationList() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const migrationsDataMap = useSelector(
    (state) => state.core.migrationsSlice.data
  );
  const data = Object.values(migrationsDataMap);

  function handleView(id) {}

  function handleToolbarAction(a, b) {}

  useEffect(() => {
    dispatch(getMigrations());
  }, []);

  return (
    <NtaiPage padding={2} title="Migrations">
      <NtaiSimpleTable
        rows={data}
        headCells={headCells}
        keyColumn="uuId"
        dialog={true}
        handleToolbarAction={handleToolbarAction}
        handleDialogAddEdit={handleView}
        title="Migration"
      />
    </NtaiPage>
  );
}
