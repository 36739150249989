import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import SourceDerivedFieldForm from "./SourceDerivedFieldForm";
import {
  createSourceDerivedField,
  deleteSourceDerivedField,
  getSourceDerivedFields,
  updateSourceDerivedField,
  clearStatus,
} from "./store/sourceDerivedFieldsSlice";
import { useSnackbar } from "notistack";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: "700",
  },
  {
    id: "label",
    numeric: false,
    disablePadding: false,
    label: "Label",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "categoryDecode",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "fieldTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Field Type",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourceDerivedFieldList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [open, setOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleOpen();
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setAction(null);
    setCurrentId(null);
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    if (action === "delete") {
      selectedIds.forEach((id) => {
        dispatch(
          deleteSourceDerivedField({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            sourceDerivedFieldId: id,
          })
        );
      });
    }

    setCurrentId(null);
    setAction(null);
  }

  function confirmDelete() {
    setAction("delete");
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction(null);
  }

  function handleSubmit(formData) {
    if (action === "add")
      dispatch(
        createSourceDerivedField({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateSourceDerivedField({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourceDerivedFieldId: currentId,
          formData: formData,
        })
      );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceDerivedFields" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSourceDerivedField", "updateSourceDerivedField"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      handleClose();
      dispatch(clearStatus());
      setAction(null);
      setCurrentId(null);
    }

    if (
      status &&
      status.method === "deleteSourceDerivedField" &&
      status.result === "success"
    ) {
      setOpenConfirmDeleteDialog(false);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      clearStatus();
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_DERIVED_FIELD_CATEGORY_CODE));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FIELD_ES_DATA_TYPE_CODE));
  }, []);

  return (
    <React.Fragment>
      <NtaiPage
        title={`${_.get(sourceDefData, "name")} / Derived Fields`}
        padding={0}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        {loaded && (
          <NtaiPanel
            width="100%"
            header="Derived Fields"
            subheader="Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa."
          >
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              handleDialogAddEdit={handleDialogAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Derived Field"
            />
          </NtaiPanel>
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={open}
        handleDialogClose={handleClose}
        size="md"
        title="Derived Field"
      >
        <NtaiForm>
          <SourceDerivedFieldForm
            action={action}
            formData={action === "edit" ? _.get(dataMap, currentId) : {}}
            handleSave={handleSubmit}
            handleCancel={handleClose}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
