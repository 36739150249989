import React from "react";
import { Box, Divider, useTheme } from "@mui/material";
import SearchTitleWrapper from "./searchdetails/SearchTitleWrapper";
import SearchBar from "./searchcriteria/SearchBar";
import SearchActionPanelWrapper from "./actionpanel/SearchActionPanelWrapper";
import SearchCriteria from "./searchcriteria/SearchCriteria";
import { SearchContext } from "../SearchEdit";
import SearchDahboardGroupsMenu from "../source/dashboard/SearchDashboardGroupsMenu";
const _ = require("lodash");

export default function SearchHeader() {
  const theme = useTheme();

  const { searchData } = React.useContext(SearchContext);

  return (
    <Box
      sx={{
        height: "54px",
        display: "flex",
        alignItems: "center",
        borderBottom: theme.general.border1,
        justifyContent: "space-between",
      }}
    >
      {searchData && !_.isEmpty(searchData) && (
        <>
          <SearchTitleWrapper />

          {searchData.typeCode === 1 && <SearchCriteria />}

          <SearchActionPanelWrapper />
        </>
      )}
    </Box>
  );
}
