import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";

import Scrollbar from "@ntai/components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { SearchAdvInitContext } from "./SearchAdvInit";
import {
  faGrid,
  faGridHorizontal,
  faStop,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SourceBox1 from "./SourceBox1";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

export default function SearchAdvInitSources() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [denseLayout, setDenseLayout] = useState(false);
  const [selectedList, setSelectedList] = useState("all");
  const [loaded, setLoaded] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [datasetList, setDatasetList] = useState([]);

  const {
    selectedSource,
    setSelectedSource,
    setSelectedType,
    sourcesQueryText,
  } = useContext(SearchAdvInitContext);

  const searchInitData = useSelector(
    (state) => state.search.searchInitSlice.data
  );

  const searchInitSources =
    searchInitData && _.get(searchInitData, "searchInitSources");

  const searchInitDatasets =
    searchInitData && _.get(searchInitData, "searchInitDatasets");

  function toggleList(listType) {
    setSelectedList(listType);
  }

  function buildSearchSources() {
    let result = [];

    if (Array.isArray(searchInitSources) && searchInitSources.length > 0) {
      _.orderBy(
        searchInitSources,
        ["searchSourceDefinition.abbrev"],
        ["asc"]
      ).forEach((searchInitSource, index) => {
        // TODO: Retsructure filtering
        if (
          (sourcesQueryText &&
            sourcesQueryText.length > 0 &&
            (_.get(searchInitSource, "searchSourceDefinition.abbrev")
              .toLowerCase()
              .includes(sourcesQueryText.toLowerCase()) ||
              _.get(searchInitSource, "searchSourceDefinition.name")
                .toLowerCase()
                .includes(sourcesQueryText.toLowerCase()))) ||
          !sourcesQueryText ||
          sourcesQueryText.length === 0
        ) {
          result.push(
            <SourceBox1
              key={`search-source-box-${index}`}
              type="source"
              searchInitSource={searchInitSource}
              handleSelect={handleSourceSelect}
            />
          );
        }
      });
    }

    return result;
  }

  function buildSearchDatasets() {
    const result = [];
    if (Array.isArray(searchInitDatasets) && searchInitDatasets.length > 0) {
      _.orderBy(searchInitDatasets, ["name"], ["asc"]).forEach(
        (searchInitDataset, index) => {
          if (
            (sourcesQueryText &&
              sourcesQueryText.length > 0 &&
              searchInitDataset["name"]
                .toLowerCase()
                .includes(sourcesQueryText.toLowerCase())) ||
            !sourcesQueryText ||
            sourcesQueryText.length === 0
          ) {
            result.push(
              <SourceBox1
                key={`search-dataset-box-${index}`}
                type="dataset"
                searchInitSource={searchInitDataset}
                handleSelect={handleSourceSelect}
              />
            );
          }
        }
      );
    }

    return result;
  }

  function handleSourceSelect(type, sourceOrDatasetUuId) {
    setSelectedType(type === "source" ? 2 : 4);
    setSelectedSource(sourceOrDatasetUuId);
  }

  //   useEffect(() => {
  //     const revisedQueryTerms = reviseQueryTerms(searchQueryTerms);
  //     console.log("revised Query terms: ", revisedQueryTerms);

  //     dispatch(previewSearch({ searchQueryTerms: revisedQueryTerms }));
  //   }, [searchQueryTerms]);

  useEffect(() => {
    if (_.isArray(searchInitSources) && _.isArray(searchInitDatasets)) {
      const tmpSourceList = ["all", "sources"].includes(selectedList)
        ? buildSearchSources()
        : [];
      const tmpDatasetList = ["all", "datasets"].includes(selectedList)
        ? buildSearchDatasets()
        : [];

      setSourceList(tmpSourceList);
      setDatasetList(tmpDatasetList);
      setLoaded(true);
    }
  }, [searchInitSources, searchInitDatasets, selectedList, sourcesQueryText]);

  return (
    <>
      <Box
        sx={{
          flexBasis: "85%",
          display: "flex",
          height: "100%",
        }}
      >
        {loaded && (
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                pl: theme.spacing(1),
                pr: theme.spacing(2),
                justifyContent: "space-between",
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(1),
                width: "86%",
              }}
            >
              <Typography variant="h6">{`Sources and Datasets (${
                sourceList.length + datasetList.length
              })`}</Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,
                    gap: theme.spacing(1),
                  }}
                  size="small"
                  color="inherit"
                  // onClick={() => setDenseLayout(!denseLayout)}
                >
                  <FontAwesomeIcon
                    size="xl"
                    icon={denseLayout ? faGridHorizontal : faGrid}
                  />
                  {denseLayout ? "STANDARD" : "DENSE"}
                </Button>

                <Button
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,
                    gap: theme.spacing(1),
                  }}
                  size="small"
                  color="inherit"
                >
                  <FontAwesomeIcon size="xl" icon={faStop} />
                  CLEAR ALL
                </Button>
                <Divider orientation="vertical" flexItem />

                <Chip
                  variant={selectedList === "all" ? "contained" : "outlined"}
                  label="All"
                  size="small"
                  onClick={() => toggleList("all")}
                />
                <Chip
                  variant={
                    selectedList === "sources" ? "contained" : "outlined"
                  }
                  label="Sources"
                  size="small"
                  onClick={() => toggleList("sources")}
                />
                <Chip
                  variant={
                    selectedList === "datasets" ? "contained" : "outlined"
                  }
                  label="Datasets"
                  size="small"
                  onClick={() => toggleList("datasets")}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: theme.spacing(1),
                flexWrap: "wrap",
                width: "86%",
                gap: theme.spacing(2),
              }}
            >
              {sourceList}
              {datasetList}
            </Box>
          </Scrollbar>
        )}

        {!loaded && <NtaiCircularProgress size={24} />}
      </Box>
    </>
  );
}
