import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUserQueries = createAsyncThunk(
  "userQueries/getUserQueries",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/library/queries`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserQuery = createAsyncThunk(
  "userQueries/getUserQuery",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId } = values;
      const response = await server.get(`/library/queries/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserQuery = createAsyncThunk(
  "userQueries/createUserQuery",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(`/library/queries`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserQuery = createAsyncThunk(
  "userQueries/updateUserQuery",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/library/queries/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserQuery = createAsyncThunk(
  "userQueries/deleteUserQuery",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/library/queries/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userQueriesSlice = createSlice({
  name: "userQueries",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserQueries.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getUserQueries" };
    },
    [getUserQueries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserQueries",
        message: action.payload.message,
      };
    },
    [getUserQuery.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getUserQuery" };
    },
    [getUserQuery.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserQuery",
        message: action.payload.message,
      };
    },
    [createUserQuery.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createUserQuery" };
      state.activeId = action.payload.uuId;
    },
    [createUserQuery.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserQuery",
        message: action.payload.message,
      };
    },
    [updateUserQuery.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateUserQuery" };
    },
    [updateUserQuery.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUserQuery",
        message: action.payload.message,
      };
    },
    [deleteUserQuery.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteUserQuery" };
    },
    [deleteUserQuery.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUserQuery",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = userQueriesSlice.actions;

export default userQueriesSlice.reducer;
