import { faMemoCircleInfo, faUserPen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AlertEditContext } from "../../AlertEdit";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getAlertDefinitionInitAdjMin,
} from "../../../definition/init-adj/store/alertDefinitionInitAdjsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import AlertEditSearchDistRequestForm from "./AlertEditSearchDistRequestForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
const _ = require("lodash");

export default function AlertEditSearchDistRequest() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const { alertData } = React.useContext(AlertEditContext);
  const alertDefInitAdjUuId = alertData["alertDefInitAdjUuId"];

  const minData = useSelector(
    (state) => state.monitor.alertDefinitionInitAdjsSlice.minData
  );

  const status = useSelector(
    (state) => state.monitor.alertDefinitionInitAdjsSlice.status
  );

  const alertDefId = minData ? minData["alertDefId"] : null;

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getAlertDefinitionInitAdjMin" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getAlertDefinitionInitAdjMin({
        alertDefId: "*",
        uuId: alertDefInitAdjUuId,
      })
    );
  }, [alertDefInitAdjUuId]);

  return (
    <>
      {minData && !_.isEmpty(minData) && (
        <Button
          size="small"
          sx={{
            fontSize: "12px",
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
          }}
          onClick={() => handleDialogOpen()}
        >
          <FontAwesomeIcon color="inherit" icon={faMemoCircleInfo} />
          {loaded && minData["adjObjectFormLabel"].toUpperCase()}
          {!loaded && <NtaiCircularProgress size={18} />}
        </Button>
      )}

      {loaded && minData && !_.isEmpty(minData) && (
        <NtaiDialog
          title={minData["adjObjectFormLabel"]}
          open={open}
          handleDialogClose={handleDialogClose}
          size="sm"
        >
          <NtaiForm>
            <AlertEditSearchDistRequestForm
              initData={minData}
              handleCancel={handleDialogClose}
            />
          </NtaiForm>
        </NtaiDialog>
      )}
    </>
  );
}
