import React from "react";
import { SourceOutJobMigrationContext } from "../SourceOutJobMigration";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function SourceMigrationOutJobPipelineStep() {
  const theme = useTheme();
  const { displayStore, migrationData } = React.useContext(
    SourceOutJobMigrationContext
  );

  const migrationStoreData = _.filter(migrationData["migrationOutStores"], {
    uuId: displayStore,
  })[0];

  return (
    <React.Fragment>
      {migrationStoreData["migrationDatasets"].map((o, i) => (
        <React.Fragment key={`ntai-migration-dataset-${i}`}>
          <Box
            sx={{
              p: theme.spacing(2),
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h3">{o["sourceDatasetName"]}</Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: theme.spacing(1),
              }}
            >
              <NtaiLabelValue1 label="Status" value={o["statusDecode"]} />
              <NtaiLabelValue1 label="Duration" value={o["duration"]} />
              <NtaiLabelValue1
                label="Started"
                value={NtaiUtils.formatDateTimeCol(o["dateStarted"])}
              />
              <NtaiLabelValue1
                label="Completed"
                value={NtaiUtils.formatDateTimeCol(o["dateCompleted"])}
              />
            </Box>
            <NtaiLabelValue1 label="Message" value={o["message"]} />
          </Box>
          <Divider />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
