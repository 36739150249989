import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  darken,
  lighten,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
const _ = require("lodash");

export default function NtaiDbListMetric1Widget(props) {
  const { data } = props;

  const theme = useTheme();
  // console.log("Widget data: ", data);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "start",
      }}
    >
      <Scrollbar autoHide={true}>
        <List
          sx={{
            p: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {data &&
            _.isArray(data) &&
            data.length > 0 &&
            data.map((d, i) => (
              <ListItem key={`widget-${i}`}>
                <Box
                  sx={{ width: "100%", display: "flex", alignItems: "center" }}
                >
                  <Box sx={{ flexBasis: "20%", display: "flex" }}>
                    <Avatar
                      sx={{
                        bgcolor: darken(
                          theme.colors.secondary.light,
                          Math.random() * (0.5 - 0.0)
                        ),
                        height: 24,
                        width: 24,
                      }}
                    >
                      {Object.keys(d)[0].substring(0, 1)}
                    </Avatar>
                  </Box>
                  <Box
                    sx={{
                      flexBasis: "80%",
                      display: "flex",
                      justifyContent: "space-between",
                      pr: theme.spacing(1),
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {Object.keys(d)[0]}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      {Object.values(d)[0]}
                    </Typography>
                  </Box>
                </Box>
              </ListItem>
            ))}
        </List>
      </Scrollbar>
    </Box>
  );
}
