import { Box, useTheme } from "@mui/material";
import React from "react";
import { getSearchSourceRecordViewWidget } from "./store/searchSourceRecordViewWidgetsSlice";
import { SearchContext } from "../../../../../SearchEdit";
import { SearchSourceContext } from "../../../../SearchSource";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import { SearchSourceRecordListContext } from "../../../SearchSourceRecordListPanel";
import NtaiMapChart3 from "@ntai/components/charts/maps/NtaiMapChart3";
import NtaiDataGrid from "@ntai/components/tables/aggrid/NtaiDataGrid";
import NtaiPackedCircleChart from "@ntai/components/charts/amcharts/NtaiPackedCircleChart";
import NtaiXTab from "@ntai/components/tables/xtab/NtaiXTab";
import NtaiSankeyChart from "@ntai/components/charts/amcharts/NtaiSankeyChart";
import NtaiWidgetMetric from "@ntai/components/charts/metrics/NtaiWidgetMetric";
import { useMeasure } from "react-use";
import NtaiHorizontalBarEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarEChart";
import NtaiHorizontalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarSeriesEChart";
import NtaiHorizontalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarStackedEChart";
import NtaiVerticalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarSeriesEChart";
import NtaiVerticalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarStackedEChart";
import NtaiPieEChart from "@ntai/components/charts/echarts/pie/NtaiPieEChart";
import NtaiDonutEChart from "@ntai/components/charts/echarts/pie/NtaiDonutEChart";
import NtaiDateHistogramEChart from "@ntai/components/charts/echarts/date-hist/NtaiDateHistogramEChart";
import NtaiTimeSeriesEChart from "@ntai/components/charts/echarts/time-series/NtaiTimeSeriesEChart";
import NtaiCircularDependencyEChart from "@ntai/components/charts/echarts/force-graph/NtaiCircularDependencyEChart";
import NtaiMuiDataGridWidget from "@ntai/components/grid/mui/NtaiMuiDataGridWidget";
import NtaiForceDirectedEChart from "@ntai/components/charts/echarts/force-graph/NtaiForceDirectedEChart";
import NtaiTreeMapEChart from "@ntai/components/charts/echarts/treemap/NtaiTreeMapEChart";
import NtaiBubbleEChart from "@ntai/components/charts/echarts/scatter/NtaiBubbleEChartOld";
import NtaiHeatmapEChart from "@ntai/components/charts/echarts/heatmap/NtaiHeatmapEChart";
import NtaiWordCloudChart from "@ntai/components/charts/amcharts/v2/NtaiWordCloudChart";
import NtaiLinearTreeEChart from "@ntai/components/charts/echarts/tree/NtaiLinearTreeEChart";
import NtaiRadialTreeEChart from "@ntai/components/charts/echarts/tree/NtaiRadialTreeEChart";
import NtaiVerticalBarEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarEChart";
import NtaiGanttChart from "@ntai/components/charts/amcharts/v2/NtaiGanttChart";
import NtaiScatterEChart from "@ntai/components/charts/echarts/scatter/NtaiScatterEChart";
import NtaiGeoMap from "@ntai/components/charts/geo/NtaiGeoMap";
import SearchSourceSummaryTabWidget from "../../../../view/widgets/shared/SearchSourceSummaryTabWidget";
const _ = require("lodash");

export default function SearchSourceRecordSourceViewWidget({
  widgetInitData,
  sourceViewUuId,
  sourceViewWidgetUuId,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [ref, { width, height }] = useMeasure();

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "uuId");

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordViewWidgetsSlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordViewWidgetsSlice.status
  );

  const widget =
    dataMap && !_.isEmpty(dataMap) ? dataMap[sourceViewWidgetUuId] : null;

  const widgetMetadata = widget && _.get(widget, "metadata");

  function getFieldTypesFromMetadata() {
    let result = {};
    const catFields = _.filter(
      Object.values(widgetMetadata["fields"]),
      function (f) {
        if ((f.field.esDataTypeCode === 4) | (f.field.dataTypeCode === 1))
          return true;
        else return false;
      }
    );

    result = {
      categoryFields: catFields.length,
    };

    // console.log("field type: ", result);

    return result;
  }

  function handleLinkClick(
    linkedSourceUuId,
    linkUuId,
    linkValue,
    sourceRecordId
  ) {}

  function handleClick(searchSourceWidgetFilterFields) {
    // searchSourceWidgetFilters: [{searchSourceWidgetFilterRequestMode {actionCode, array of searchSourcefilterFields} }, {}]
  }

  function handleContextMenu(actionCode, pSearchSourceWidgetFilterFields) {}

  useEffect(() => {
    dispatch(
      getSearchSourceRecordViewWidget({
        searchId: searchId,
        searchSourceId: searchSourceId,
        sourceViewId: sourceViewUuId,
        sourceViewWidgetId: sourceViewWidgetUuId,
        recordId: _.get(selectedRecord, "recordId"),
      })
    );
  }, [sourceViewUuId, sourceViewWidgetUuId]);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <NtaiSourceWidget title={widgetInitData.sourceViewWidgetName}>
        {widget && widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL && (
          <NtaiHorizontalBarEChart
            widgetId={_.get(widget, "widgetUuId")}
            data={_.get(widget, "data")}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget &&
          widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL_SERIES && (
            <NtaiHorizontalBarSeriesEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

        {widget &&
          widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL_STACKED && (
            <NtaiHorizontalBarStackedEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

        {widget && widget.widgetChartCode === CHART_CODES.BAR_VERTICAL && (
          <NtaiVerticalBarEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget &&
          widget.widgetChartCode === CHART_CODES.BAR_VERTICAL_SERIES && (
            <NtaiVerticalBarSeriesEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

        {widget &&
          widget.widgetChartCode === CHART_CODES.BAR_VERTICAL_STACKED && (
            <NtaiVerticalBarStackedEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

        {widget && widget.widgetChartCode === CHART_CODES.PIE && (
          <NtaiPieEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.DONUT && (
          <NtaiDonutEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.DATE_HISTOGRAM && (
          <NtaiDateHistogramEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.TIME_SERIES && (
          <NtaiTimeSeriesEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.CHORD && (
          <NtaiCircularDependencyEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.GEO && (
          <Box
            sx={{ display: "flex", width: "100%", height: "100%" }}
            ref={ref}
          >
            <NtaiGeoMap
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
              width={width}
              height={height}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.DATA_GRID_I && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiDataGrid
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.DATA_GRID_II && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiMuiDataGridWidget
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleLinkClick={handleLinkClick}
            />
          </Box>
        )}

        {widget &&
          widget.widgetChartCode === CHART_CODES.SUMMARY_TABULATION && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <SearchSourceSummaryTabWidget
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                handleClick={handleClick}
              />
            </Box>
          )}

        {widget && widget.widgetChartCode === CHART_CODES.PACKED_CIRCLE && (
          <NtaiPackedCircleChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.FORCE_DIRECTED && (
          <NtaiForceDirectedEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            metadata={widgetMetadata}
            handleClick={handleClick}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.TREEMAP && (
          <NtaiTreeMapEChart
            widgetId={widget.widgetUuId}
            data={widget.data}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
            metadata={widgetMetadata}
          />
        )}

        {widget && widget.widgetChartCode === CHART_CODES.PIVOT_GRID && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              pb: theme.spacing(1),
            }}
          >
            <NtaiXTab
              widgetId={widget.widgetUuId}
              rows={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.SCATTER && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiScatterEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.BUBBLE && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiBubbleEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.SANKEY_PLOT && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiSankeyChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              fromField="from"
              toField="to"
              valueField="value"
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.HEATMAP && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiHeatmapEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
              handleConetxtMenu={handleContextMenu}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.WORD_CLOUD && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiWordCloudChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              categoryField={"intervention"}
              valueField={"value"}
              metadata={widgetMetadata}
              handleClick={handleClick}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.TREE_LINEAR && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiLinearTreeEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.TREE_RADIAL && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiRadialTreeEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.SCRIPTED_METRIC && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiWidgetMetric
              widgetId={widget.widgetUuId}
              data={widget.data[0]["value"]}
              metadata={widgetMetadata}
            />
          </Box>
        )}

        {widget && widget.widgetChartCode === CHART_CODES.GANTT && (
          <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
            <NtaiGanttChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
            />
          </Box>
        )}
      </NtaiSourceWidget>
    </Box>
  );
}
