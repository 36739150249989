import axios from "axios";

let instance = axios.create({
  baseURL: process.env.REACT_APP_NTAI_REST_URL,
  headers: {
    common: {
      "Content-Type": "application/json",
      "X-TenantID": localStorage.getItem("TENANT_ID"),
      // "X-TenantID": "tenant1",
    },
  },
});

export default instance;
