import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";

export default function SourceWidgetFormPanel(props) {
  const { children, handleSave, saveLabel, handleCancel } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box sx={{ height: `calc(100% - 54px)` }}>
        <Scrollbar>{children}</Scrollbar>
      </Box>

      <Box
        sx={{
          borderTop: theme.general.border1,
          display: "flex",
          padding: theme.spacing(2),
          height: "54px",
          gap: theme.spacing(1),
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        {handleCancel && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button size="small" onClick={handleCancel}>
              CANCEL
            </Button>
          </Box>
        )}
        {handleSave && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              size="small"
              variant="contained"
              type="submit"
              onClick={handleSave}
            >
              {saveLabel ? saveLabel : "SAVE"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
