import { Box } from "@mui/material";
import React from "react";
import NtaiCrudTable from "./NtaiCrudTable";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

const headCells = [
  {
    id: "name",
    type: "data",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  {
    id: "calories",
    type: "data",
    numeric: false,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    type: "data",
    numeric: false,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    type: "data",
    numeric: false,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    type: "data",
    numeric: false,
    disablePadding: false,
    label: "Protein (g)",
  },
  {
    id: "manage",
    type: "action",
    numeric: false,
    disablePadding: false,
    label: "Manage Configuration",
  },
];

function handleToolbarAction(val, selected) {}

function handleRowOtherAction(action, rowId) {
  console.log("Row Other Action: ", action, rowId);
}

export default function NtaiTestCrudTable() {
  const [selectedItems, setSelectedItems] = React.useState([]);

  function handleCellAction(action, id) {
    console.log("Cell action and id: ", action, id);
  }
  return (
    <Box sx={{ padding: "8px" }}>
      <NtaiCrudTable
        rows={rows}
        selectedItems={selectedItems}
        headCells={headCells}
        toolbarActions={[
          { icon: "user-secret", label: "Archive", value: "archive" },
        ]}
        keyColumn="name"
        path="/admin/roles"
        handleToolbarAction={handleToolbarAction}
        handleCellAction={handleCellAction}
        rowOtherActions={[
          { label: "Custom1", value: "custom1" },
          { label: "Custom2", value: "custom2" },
          { label: "Custom3", value: "custom3" },
        ]}
        handleRowOtherAction={handleRowOtherAction}
        title="Role"
      />
    </Box>
  );
}
