import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Box, Checkbox, Link, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
const _ = require("lodash");

// const rows = [
//   { id: 1, col1: "Hello", col2: "World" },
//   { id: 2, col1: "DataGridPro", col2: "is Awesome" },
//   { id: 3, col1: "MUI", col2: "is Amazing" },
// ];

// const columns = [
//   { field: "col1", headerName: "Column 1", width: 150 },
//   { field: "col2", headerName: "Column 2", width: 150 },
// ];

function handleFilter(t1, t2) {
  console.log("T1 and t2: ", t1, t2);
}

export default function NtaiMuiDataGridWidget({
  widgetId,
  data,
  metadata,
  disableSelectionOnClick,
  handleLinkClick,
}) {
  const theme = useTheme();
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  const fieldArray = _.orderBy(
    Object.values(_.get(metadata, "fields")),
    ["fieldOrder"],
    ["asc"]
  );

  console.log("Mui data grid: ", fieldArray);
  console.log("Mui data grid data: ", data);

  function generateColumnDefs(fieldArray) {
    const colDefs = [];

    fieldArray.forEach((widgetField) => {
      colDefs.push({
        field: widgetField.field.name,
        headerName: widgetField.field.label,
        renderCell:
          _.has(widgetField.field, "sourceLinkedSourceLinks") &&
          _.isArray(widgetField.field.sourceLinkedSourceLinks) &&
          _.get(widgetField.field, "sourceLinkedSourceLinks").length > 0
            ? (params) => (
                <Link
                  href="#"
                  underline="always"
                  color={blue[700]}
                  onClick={() =>
                    onHandleLinkClick(
                      _.get(widgetField.field, "sourceLinkedSourceLinks")[0]
                        .linkedSourceUuId,
                      _.get(widgetField.field, "sourceLinkedSourceLinks")[0]
                        .uuId,
                      params.row[widgetField.field.name],
                      params.row["_id"]
                    )
                  }
                >
                  {params.row[widgetField.field.name]}
                </Link>
              )
            : null,
        flex: 1,
      });
    });

    return colDefs;
  }

  const columns = generateColumnDefs(fieldArray);

  function onHandleLinkClick(
    linkedSourceUuId,
    linkUuId,
    linkValue,
    sourceRecordId
  ) {
    handleLinkClick(linkedSourceUuId, linkUuId, linkValue, sourceRecordId);
  }

  return (
    <DataGrid
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: theme.colors.secondary.lighter,
          // color: "red"
        },
      }}
      onFilterModelChange={handleFilter}
      components={{
        BaseCheckbox: (props) => <Checkbox size="small" {...props} />,
      }}
      density="compact"
      rows={data}
      getRowId={(row) => row["_record_id"]}
      //   getRowId={() => Math.floor(Math.random() * 100000000)}
      columns={columns}
      disableSelectionOnClick={
        disableSelectionOnClick ? disableSelectionOnClick : true
      }
      checkboxSelection
    />
  );
}
