import React, { useContext } from "react";
import Scrollbar from "@ntai/components/Scrollbar";
import { Box, styled, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/pro-regular-svg-icons";
import NtaiDecoTree from "@ntai/components/treeview/NtaiDecoTree";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
const _ = require("lodash");

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    width: 100%; // ${theme.sidebar.width};
    background: ${theme.sidebar.background};
    height: 100%;
    border-radius: 0;
    border-right: ${theme.general.border1}
    
    // @media (min-width: ${theme.breakpoints.values.lg}px) {
    //     position: fixed;
    //     height: calc(100% - ${theme.spacing(6)});
    //     margin: 0; // ${theme.spacing(4, 0, 4, 4)};
    //     z-index: 10;
    //     border-radius: 0; //${theme.general.borderRadius};
    // }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 50px;
        align-items: center;
        // margin: 0 ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar({ data, handleClick }) {
  // const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [openSidebar, setOpenSidebar] = React.useState(true);
  // const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  function closeSidebar() {
    setOpenSidebar(false);
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <SidebarWrapper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            height: "53px",
            padding: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
            borderBottom: theme.general.border1,
          }}
        >
          <FontAwesomeIcon icon={faSitemap} />
          <Typography variant="h6" fontWeight="500">
            Topics and Groups
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "calc(100% - 53px)",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              py: theme.spacing(1),
              px: theme.spacing(2),
            }}
          >
            <NtaiSimpleSearchBar placeholder="Search..." />
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <Scrollbar autoHide={false}>
              {/* <SidebarMenu /> */}

              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  pr: theme.spacing(2),
                }}
              >
                <NtaiDecoTree
                  data={data}
                  idField="uuId"
                  // iconField="type"
                  typeField="type"
                  labelField="name"
                  badgeField="count"
                  handleClick={handleClick}
                />
              </Box>
            </Scrollbar>
          </Box>
        </Box>
      </SidebarWrapper>
    </Box>
  );
}

export default Sidebar;
