import { useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createOrUpdateSetting,
  getSettings,
} from "./store/settingsSlice";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SettingForm from "./SettingForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function Settings() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const dataMap = useSelector((state) => state.core.settingsSlice.data);
  const status = useSelector((state) => state.core.settingsSlice.status);
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleSave(formData) {
    dispatch(createOrUpdateSetting(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createOrUpdateSetting" &&
      status.result === "success"
    ) {
      enqueueSnackbar("Setting updated successfully", {
        variant: "success",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  return (
    <NtaiPage title="Settings">
      <NtaiForm>
        <SettingForm formData={data} handleFormSubmit={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
