import AlertContainer from "./AlertContainer";
import AlertEdit from "./AlertEdit";

const AlertConfig = {
  routes: [
    {
      path: "/monitor/alerts",
      exact: true,
      component: AlertContainer,
    },
    {
      path: "/monitor/alerts/:guuid",
      exact: true,
      component: AlertEdit,
    },
  ],
};

export default AlertConfig;
