import { Box } from "@mui/material";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import React from "react";

export default function FunctionEnvironmentSelection() {
  return (
    <NtaiAccordion title="Environment" titleIcon="archive">
      something
    </NtaiAccordion>
  );
}
