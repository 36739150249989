import { Box, useTheme } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SourceWidgetReorderFields from "../reorder/SourceWidgetReorderFields";

import NtaiRadioGroupField from "@ntai/components/inputs/NtaiRadioGroupField";

import Scrollbar from "@ntai/components/Scrollbar";

import NtaiMuiSelectField from "@ntai/components/inputs/NtaiMuiSelectField";

import SourceWidgetFormPanel from "./SourceWidgetFormPanel";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import SourceWidgetTitle from "./SourceWidgetTitle";
import SourceWidgetFieldsForm from "../fields/SourceWidgetFieldsForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import { NtaiDnd2 } from "@ntai/components/dnd2/NtaiDnd2";
const _ = require("lodash");

function getDndColumnsData(data) {
  let result = [];
  _.filter(data, function (d) {
    return ![2, 3, 4, 5, 6].includes(d.sourceFieldDataTypeCode);
  }).forEach((d, i) => {
    result.push({
      uuId: d.uuId,
      name: d.fieldName,
      column: d.rowGroupFg === 1 ? "Row group" : "Column group",
    });
  });

  return result;
}

function initializeWidgetFieldOrders(data) {
  let result = [];
  _.filter(data, function (d) {
    return ![2, 3, 4, 5, 6].includes(d.sourceFieldDataTypeCode);
  }).forEach((d, i) => {
    result.push({
      uuId: d.uuId,
      name: d.fieldName,
      column: d.rowGroupFg ? "Row group" : "Column group",
    });
  });

  return result;
}

export default function SourceWidgetPivotTableForm({
  widget,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [widgetFields, setWidgetFields] = useState([]);
  const [widgetOrderedFields, setWidgetOrderedFields] = useState([]);
  const [selectedFieldUuId, setSelectedFieldUuId] = useState();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const aggFuncCodeOptions = _.get(
    codes,
    CODELIST_CODES.SRC_SOURCE_VIEW_WIDGET_METRIC_CODELIST
  );

  function onHandleSave() {
    const widgetFormData = getValues("widgetForm");

    const sanitizedFormData = NtaiUtils.sanitizeFormData(widgetFormData);

    sanitizedFormData["sourceWidgetFields"] =
      widgetFormData["sourceWidgetFields"];

    const rowGroupFields = _.filter(widgetOrderedFields, {
      column: "Row group",
    });
    const colGroupFields = _.filter(widgetOrderedFields, {
      column: "Column group",
    });

    rowGroupFields.forEach((rgf, i) => {
      sanitizedFormData["sourceWidgetFields"].forEach((field, j) => {
        if (field.uuId === rgf.uuId) {
          field["rowGroupFg"] = 1;
          field["colGroupFg"] = 0;
          field["rowGroupOrder"] = i + 1;
          field["colGroupOrder"] = null;

          field["sourceWidgetFieldFilters"] = _.get(
            _.get(sanitizedFormData, "sourceWidgetFields")[j],
            "sourceWidgetFieldFilters"
          ).map((swff, k) => {
            return {
              value: swff.value,
              typeCode: 1,
            };
          });
        }
      });
    });

    colGroupFields.forEach((cgf, i) => {
      sanitizedFormData["sourceWidgetFields"].forEach((field, j) => {
        if (field.uuId === cgf.uuId) {
          field["rowGroupFg"] = 0;
          field["colGroupFg"] = 1;
          field["colGroupOrder"] = i + 1;
          field["rowGroupOrder"] = null;

          field["sourceWidgetFieldFilters"] = _.get(
            _.get(sanitizedFormData, "sourceWidgetFields")[j],
            "sourceWidgetFieldFilters"
          ).map((swff, k) => {
            return {
              value: swff.value,
              typeCode: 1,
            };
          });
        }
      });
    });

    // TODO: Set to count when nothing available for metric code
    sanitizedFormData["sourceWidgetFields"].forEach((field, i) => {
      if (
        (field.derivedFieldFg &&
          field.derivedFieldFg === 1 &&
          [2].includes(field.sourceFieldDataTypeCode)) ||
        (!field.derivedFieldFg &&
          [2, 3, 4, 5, 6].includes(field.sourceFieldDataTypeCode))
      ) {
        field["metricCode"] =
          !_.isEmpty(field["metricCode"]) && _.has(field["metricCode"], "value")
            ? _.get(field["metricCode"], "value")
            : 22;
      }
    });

    handleSave(sanitizedFormData);
  }

  function handleCallback(items) {
    setWidgetOrderedFields([...items]);
  }

  function handleSelectField(fieldUuId) {
    setSelectedFieldUuId(fieldUuId);
    const selectedField = _.filter(widget["sourceWidgetFields"], {
      uuId: fieldUuId,
    })[0];
  }

  useEffect(() => {
    let widgetCopy = _.cloneDeep(widget);

    setWidgetOrderedFields(
      initializeWidgetFieldOrders(widgetCopy["sourceWidgetFields"])
    );
    setSelectedFieldUuId(
      _.orderBy(widgetCopy["sourceWidgetFields"], ["fieldOrder"], ["asc"])[0][
        "uuId"
      ]
    );
    reset({ widgetForm: widgetCopy });

    // widgetCopy["sourceViewWidgetFields"].forEach((f) => {
    //   setValue(`widgetForm.pivotTableFieldHeaders.${f.uuId}`, f.headerName);
    // });
  }, []);

  return (
    <SourceWidgetFormPanel
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <NtaiTabs>
        <Box
          label="TITLE"
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            flexDirection: "column",
          }}
        >
          <SourceWidgetTitle />
        </Box>

        <Box
          label="FIELDS"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <SourceWidgetFieldsForm widget={widget} hideFieldOption={true} />
        </Box>

        <Box
          label="GROUPS"
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            justifyContent: "space-between",
          }}
        >
          <NtaiPanel
            width="100%"
            subheader="Arrange fields under row and column groups"
          >
            <NtaiDnd2
              data={getDndColumnsData(
                _.orderBy(
                  _.get(widget, "sourceWidgetFields"),
                  ["fieldOrder"],
                  ["asc"]
                )
              )}
              idField="uuId"
              nameField="name"
              column1="Row group"
              column2="Column group"
              handleCallback={handleCallback}
            />
          </NtaiPanel>
        </Box>
      </NtaiTabs>
    </SourceWidgetFormPanel>
  );
}
