import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import loginSlice from "./loginSlice";
import userDataSlice from "./userDataSlice";

const authReducer = combineReducers({
  authSlice,
  loginSlice,
  userDataSlice,
});

export default authReducer;
