import {
  faAnalytics,
  faChartUser,
  faClock,
  faFileLines,
  faFilePdf,
  faGlobe,
  faScrewdriverWrench,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiLogo from "./logo/NtaiLogo";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import AdminWrapper from "./panels/AdminWrapper";
import NotificationsWrapper from "./panels/NotificationsWrapper";
import UserPreferenceWrapper from "./panels/UserPreferenceWrapper";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import History from "@ntai/@history";
import {
  setLeftSidebarNav,
  setSelectedModule,
} from "app/store/ntai/navigationSlice";
import { useDispatch } from "react-redux";
import ModuleButtonRbac from "./modules/ModuleButtonRbac";
import ToolsWrapper from "app/main/pages/modules/tools/ToolsWrapper";

const modules = [
  {
    id: "search",
    label: "WORKSPACE",
    icon: <FontAwesomeIcon icon={faChartUser} />,
    permissions: ["app.search.all"],
  },
  {
    id: "monitor",
    label: "INBOX",
    icon: <FontAwesomeIcon icon={faClock} />,
    permissions: ["app.monitor.all"],
  },
  {
    id: "oversight",
    label: "OVERSIGHT",
    icon: <FontAwesomeIcon icon={faGlobe} />,
    permissions: ["app.oversight.all"],
  },
  {
    id: "reports",
    label: "REPORTS",
    icon: <FontAwesomeIcon icon={faFileLines} />,
    permissions: ["app.report.all"],
  },
  // {
  //   id: "tools",
  //   label: "TOOLS",
  //   icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
  //   permissions: ["app.tools.all"],
  // },
];

export default function AppHeader() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleSelect(item) {
    dispatch(setLeftSidebarNav(false));
    dispatch(setSelectedModule(item));
    if (item === "search") History.push("/search");
    else if (item === "monitor") History.push("/monitor/alerts");
    else if (item === "oversight") History.push("/oversight");
    else if (item === "reports") History.push("/reports");
    // else if (item === "tools") History.push("/tools");
  }

  function redirectToDocs() {
    History.push("/docs");
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          minHeight: "48px",
          maxHeight: "48px",
          position: "fixed",
          backgroundColor: theme.colors.primary.main,
          // color: theme.colors.alpha.white[100],
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        elevation={2}
      >
        <Toolbar
          disableGutters={true}
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            display: "flex",
            minHeight: "48px",
          }}
        >
          <NtaiLogo />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <Box
              sx={{
                display: "flex",
                marginLeft: theme.spacing(10),
                gap: theme.spacing(1),
              }}
            >
              {modules.map((module, index) => (
                <ModuleButtonRbac
                  key={`ntai-module-${index}`}
                  module={module}
                  permissions={module.permissions}
                  handleSelect={handleSelect}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center",
              }}
            >
              <AdminWrapper />
              <ToolsWrapper />
              <NotificationsWrapper />
              <UserPreferenceWrapper />
              <Tooltip title="Help">
                <IconButton color="inherit" onClick={() => redirectToDocs()}>
                  <FontAwesomeIcon size="sm" icon={faCircleQuestion} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    // </Box>
  );
}
