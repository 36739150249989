import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { AlertEditContext } from "../../../AlertEdit";
import { useContext } from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function AlertDetailsDrawer({ handleClose }) {
  const theme = useTheme();

  const { gUuId } = useContext(AlertEditContext);
  const alertsDataMap = useSelector((state) => state.monitor.alertsSlice.data);
  const alertData = _.get(alertsDataMap, gUuId);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: theme.spacing(2),
        gap: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <Typography variant="h2" fontWeight="300">
            {_.get(alertData, "alertName")}
          </Typography>
          <FontAwesomeIcon icon={faLock} />
        </Box>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon size="xs" icon={faClose} />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Description
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {alertData.alertDescription}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Entity
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {alertData.entityName}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Topic
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {alertData.objectTopicName || alertData.dataTopicName}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Pending Records
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {alertData["numOfRecords"]}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Current Activity
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {alertData.activityName}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Next Activities
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {_.isArray(alertData["nextActivityNames"]) &&
            alertData["nextActivityNames"].join(", ")}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Date Due
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {alertData.activityDueDate
            ? NtaiUtils.formatDateCol(alertData.activityDueDate)
            : "Not available"}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Date Created
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {NtaiUtils.formatDateTimeCol(alertData.dateCreated)}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" color="inherit" fontWeight="700">
          Date Modified
        </Typography>

        <Typography variant="body2" fontWeight="300">
          {NtaiUtils.formatDateTimeCol(alertData.dateModified)}
        </Typography>
      </Box>
    </Box>
  );
}
