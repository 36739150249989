import { faArchive, faEdit, faSquare } from "@fortawesome/pro-light-svg-icons";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import React from "react";
import { useSelector } from "react-redux";
import { ObjectFormBuilderContext } from "../ObjectFormBuilder";
import { grey } from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
const _ = require("lodash");

export default function ObjectFormBuilderPanelList({
  onHandleSelectPanel,
  onHandleEdit,
  onHandleDelete,
}) {
  const theme = useTheme();

  const { selectedTabId, selectedPanelId } = React.useContext(
    ObjectFormBuilderContext
  );

  const dataMap = useSelector(
    (state) => state.core.objectFormBuilderPanelsSlice.data
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  return (
    <React.Fragment>
      {selectedTabId && data && data.length > 0 && (
        <List sx={{ p: 0, width: "100%" }}>
          {_.orderBy(data, ["panelOrder"], ["asc"]).map((o, i) => (
            <React.Fragment key={`object-form-panel-listitem-${i}`}>
              <ListItem
                id={`object-form-panel-listitem-${i}`}
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: theme.spacing(1),
                  pr: theme.spacing(2),
                  cursor: "pointer",
                  background: o["uuId"] === selectedPanelId ? grey[100] : null,
                  "&:hover": {
                    background: grey[100],
                  },
                }}
                onClick={(e) => onHandleSelectPanel(e, o["uuId"])}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(2),
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={
                      o["uuId"] === selectedPanelId ? faCheckSquare : faSquare
                    }
                  />

                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontWeight="700"
                        color="inherit"
                      >
                        {o["name"]}
                      </Typography>
                    }
                    secondary={o["label"]}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  }}
                >
                  <NtaiIconButton
                    id={`object-form-panel-listitem-edit-${i}`}
                    icon={faEdit}
                    handleClick={() => onHandleEdit(o["uuId"])}
                  />

                  <NtaiIconButton
                    id={`object-form-panel-listitem-delete-${i}`}
                    icon={faArchive}
                    handleClick={(e) => onHandleDelete(e, o["uuId"])}
                  />
                </Box>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}

          <Divider />
        </List>
      )}
      {((data && data.length === 0) || !selectedTabId) && (
        <NtaiEmptyMessage
          vAlign="center"
          header="No panel or tab selected"
          subheader="Add panel using '+' icon or select a tab"
        />
      )}
    </React.Fragment>
  );
}
