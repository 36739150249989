import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import {
  getThesaurusConcepts,
  clearStatus,
} from "app/main/pages/core/library/thesaurus/concept/store/thesaurusConceptsSlice";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "../progress/NtaiCircularProgress";
import Scrollbar from "../Scrollbar";
const _ = require("lodash");

export default function NtaiInfiniteScroll(props) {
  const { data, fetchMoreData, generateItems } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        id="scrollableDiv"
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        {_.isArray(data) && data.length > 0 && (
          <InfiniteScroll
            dataLength={data.length}
            //   style={{ overflowY: "hidden" }}
            next={fetchMoreData}
            hasMore={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {generateItems(data)}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
}
