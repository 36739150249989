import {
  faArchive,
  faArrowUpArrowDown,
  faEdit,
  faReorder,
  faTrashList,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useSelector } from "react-redux";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { SourceFilterSchemeContext } from "./SourceFilterSchemeFieldsWrapper";
const _ = require("lodash");

export default function SourceFilterSchemeFields() {
  const theme = useTheme();

  const {
    mergedSourceFields,
    availableFields,
    setAvailableFields,
    selectedFields,
    setSelectedFields,
    setSelectedField,
    handleReorderDialogOpen,
  } = React.useContext(SourceFilterSchemeContext);

  const filterSchemeId = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceFilterSchemeFieldsSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleSelect(type, fieldUuId) {
    const tmpSelectedFields = _.filter(data, function (o) {
      if (type === 1) {
        if (o["sourceFieldUuId"] === fieldUuId) return true;
        else return false;
      } else {
        if (o["sourceDerivedFieldUuId"] === fieldUuId) return true;
        else return false;
      }
    })[0];

    setSelectedField({ ...tmpSelectedFields });
  }

  function handleRemove(fieldUuId) {
    setSelectedField(null);
    if (selectedFields.includes(fieldUuId))
      setSelectedFields(
        _.filter(selectedFields, function (d) {
          return d !== fieldUuId;
        })
      );

    setAvailableFields([
      ...availableFields,
      _.filter(mergedSourceFields, {
        uuId: fieldUuId,
      })[0],
    ]);
  }

  function handleRemoveAll() {
    setSelectedField(null);
    setSelectedFields([]);
    setAvailableFields([...mergedSourceFields]);
  }

  return (
    <Box
      sx={{
        flexBasis: "25%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRight: theme.general.border1,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(2),
          height: "54px",
          display: "flex",
          borderBottom: theme.general.border1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6">Selected Fields</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(0.5),
            justifyContent: "end",
          }}
        >
          <Tooltip title="Reorder">
            <IconButton
              onClick={() => handleReorderDialogOpen()}
              disabled={selectedFields.length > 0 ? false : true}
            >
              <FontAwesomeIcon size="2xs" icon={faArrowUpArrowDown} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Remove all">
            <IconButton
              onClick={() => handleRemoveAll()}
              disabled={selectedFields.length > 0 ? false : true}
            >
              <FontAwesomeIcon size="2xs" icon={faTrashList} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          height: `calc(100% - 54px)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Scrollbar>
          {selectedFields.length > 0 && (
            <List sx={{ p: 0 }}>
              {selectedFields.map((sf, i) => (
                <React.Fragment key={`source-filter-selected-field-${i}`}>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    id="filter-field-list-item"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                      }}
                    >
                      <Box sx={{ pr: theme.spacing(1) }}>
                        <NtaiBadge
                          title={_.filter(mergedSourceFields, { uuId: sf })[0]
                            ["esDataTypeDecode"].substring(0, 1)
                            .toLowerCase()}
                          fg="grey"
                          bg={grey[100]}
                        />
                      </Box>

                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            fontWeight="700"
                          >
                            {
                              _.filter(mergedSourceFields, { uuId: sf })[0][
                                "label"
                              ]
                            }
                          </Typography>
                        }
                        secondary={
                          _.filter(mergedSourceFields, { uuId: sf })[0]["name"]
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(0.5),
                      }}
                    >
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={(e) =>
                            handleSelect(
                              _.filter(mergedSourceFields, { uuId: sf })[0][
                                "type"
                              ],
                              sf
                            )
                          }
                        >
                          <FontAwesomeIcon size="2xs" icon={faEdit} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleRemove(sf)}>
                          <FontAwesomeIcon size="2xs" icon={faArchive} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
          {selectedFields.length === 0 && (
            <NtaiEmptyMessage
              header="No fields selected"
              subheader="Add fields from left panel to build report parameters"
            />
          )}
        </Scrollbar>
      </Box>
    </Box>
  );
}
