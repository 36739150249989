import React from "react";
import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormContext, Controller, useForm } from "react-hook-form";
const _ = require("lodash");

const defaultKeywords = ["and", "or", "not"];

const isKeyword = (o, keywords) => {
  return keywords.includes(o.toLowerCase().trim());
};
function NtaiAutoSuggestTF(props) {
  const theme = useTheme();
  const { control } = useFormContext();

  const {
    name,
    label,
    options,
    optionIdField,
    optionLabelField,
    handleInputChange,
    handleChange,
    keywords,
  } = props;

  const optionKeywords =
    keywords && Array.isArray(keywords) && keywords.length > 0
      ? keywords
      : defaultKeywords;

  const {
    transform,
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Controller
        control={control}
        name="item"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            onChange={(event, item) => {
              onChange(item);
              handleChange(item);
            }}
            multiple
            freeSolo
            disableClearable
            // value={value}
            options={options}
            onInputChange={(e, newInputValue) =>
              handleInputChange(newInputValue)
            }
            getOptionLabel={(item) =>
              item[optionLabelField] ? item[optionLabelField] : ""
            }
            // isOptionEqualToValue={(option, value) =>
            //   value === undefined || value === "" || option.id === value.id
            // }
            getOptionSelected={(option, value) =>
              value === undefined ||
              value === "" ||
              option[optionIdField] === value[optionIdField]
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                if (typeof option === "string" && ["(", ")"].includes(option)) {
                  return (
                    <Typography
                      sx={{ px: "4px" }}
                      variant="h3"
                      color="secondary"
                    >
                      {option}
                    </Typography>
                  );
                } else {
                  return (
                    <Chip
                      sx={{ fontWeight: "700" }}
                      variant={
                        typeof option !== "object" &&
                        isKeyword(option, optionKeywords)
                          ? "outlined"
                          : "contained"
                      }
                      size="small"
                      label={
                        typeof option === "object" &&
                        _.has(option, optionLabelField)
                          ? option[optionLabelField]
                          : isKeyword(option, optionKeywords)
                          ? option.toUpperCase()
                          : option
                      }
                      clickable={true}
                      {...getTagProps({ index })}
                    />
                  );
                }
              })
            }
            renderInput={(params) => (
              <TextField
                multiline={false}
                minRows={1}
                maxRows={1}
                {...params}
                sx={{
                  background: "white",
                }}
                label={label}
                size="small"
                margin="normal"
                variant="outlined"
                error={!!errors.item}
                helperText={errors.item && "item required"}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={{
                          display: "flex",
                          gap: theme.spacing(1),
                          flexWrap: "wrap",
                        }}
                      >
                        {/* <IconButton>
                          <FontAwesomeIcon size="sm" icon={faExpand} />
                        </IconButton>
                        <Divider orientation="vertical" flexItem />
                        <IconButton>
                          <FontAwesomeIcon size="sm" icon={faBars} />
                        </IconButton> */}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
        {...props}
      />
    </Box>
  );
}

export default NtaiAutoSuggestTF;
