import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import UserGroupSources from "./resources/source/UserGroupSources";
import { getCodes } from "../codelist/store/codelistsSlice";
import UserGroupUsers from "./users/UserGroupUsers";
import UserGroupEntities from "./resources/cdo/UserGroupEntities";
const _ = require("lodash");

export default function UserGroupForm({ action, formData, handleSave }) {
  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const userGroupTypeOptions = _.has(
    codes,
    CODELIST_CODES.COR_USER_GROUP_TYPE_CODE
  )
    ? _.get(codes, CODELIST_CODES.COR_USER_GROUP_TYPE_CODE)
    : [];

  const userGroupDataMap = useSelector(
    (state) => state.core.userGroupsSlice.data
  );
  const userGroupData = Object.values(userGroupDataMap);

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("userGroupForm")
      );

      if (!_.isEmpty(sanitizedFormData)) handleSave(sanitizedFormData);
    }
  }

  function handleCancel() {
    history.push(`/admin/user-groups`);
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.COR_USER_GROUP_TYPE_CODE));
    reset({ userGroupForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Description"
          subheader="User groups are a collection of users who perform a similar task. For example, a group of users responsible for product ACME  might be put in a ACME user group. Users can belong to multiple user groups to which permissions are assigned."
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: theme.spacing(2),
            }}
          >
            <NtaiTextField
              name="userGroupForm.name"
              label="Name*"
              placeholder="Name"
              rules={{ required: "Name is required" }}
            />

            <NtaiTextField
              name="userGroupForm.description"
              multiline
              minRows={2}
              maxRows={2}
              placeholder="Description"
              label="Description"
            />
            <NtaiSelectField
              name="userGroupForm.typeCode"
              label="Type"
              options={userGroupTypeOptions}
            />
          </Box>
        </NtaiFormPanel>
      </Box>
      <Box disabled={action === "add" ? true : false} label="USERS">
        <UserGroupUsers />
      </Box>
      <Box disabled={action === "add" ? true : false} label="SOURCES">
        <UserGroupSources />
      </Box>
      <Box disabled={action === "add" ? true : false} label="ENTITIES">
        <UserGroupEntities />
      </Box>
    </NtaiTabs>
  );
}
