import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDashboardWidget = createAsyncThunk(
  "dashboardWidgets/getDashboardWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { dashboardId, uuId, formData } = values;
      const response = await server.post(
        `/dashboard/${dashboardId}/widgets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const dashboardWidgetsSlice = createSlice({
  name: "dashboardWidgets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = { ...state.status, [action.payload]: null };
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    clearWidgetsData: (state, action) => {
      state.data = {};
    },
  },
  extraReducers: {
    [getDashboardWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        ...state.status,
        [action.payload.uuId]: {
          method: "getDashboardWidget",
          result: "success",
        },
      };
    },
    [getDashboardWidget.rejected]: (state, action) => {
      state.status = {
        ...state.status,
        [action.payload.uuId]: {
          method: "getDashboardWidget",
          result: "error",
          message: action.payload.message,
        },
      };
    },
  },
});

export const { clearStatus, setActiveId, clearWidgetsData } =
  dashboardWidgetsSlice.actions;

export default dashboardWidgetsSlice.reducer;
