import React, { createContext, useState } from "react";
import { Box, useTheme } from "@mui/material";
import SearchSourceRecordListHeader from "./SearchSourceRecordListHeader";
import SearchSourceRecordList from "./SearchSourceRecordList";
import SearchSourceBottomToolbar from "../bottomtoolbar/SearchSourceBottomToolbar";
import { SearchContext } from "../../SearchEdit";
import { SearchSourceContext } from "../SearchSource";
const _ = require("lodash");

export const SearchSourceRecordListContext = createContext();

export default function SearchSourceRecordListPanel() {
  const theme = useTheme();
  const [from, setFrom] = React.useState(0);
  const [templateType, setTemplateType] = useState(2);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [refreshSelectedRecord, setRefreshSelectedRecord] = useState(null);
  const { showAdvSearchFilterPanel, searchData } =
    React.useContext(SearchContext);
  const [recordsSearchText, setRecordsSearchText] = useState();
  const { tableView } = React.useContext(SearchSourceContext);

  const searchTypeCode = _.get(searchData, "typeCode");

  function getSearchSourceRecordPanelWidth(pSearchTypeCode, pShowFilterPanel) {
    if (pSearchTypeCode === 1) {
      return "25%";
    } else if (pSearchTypeCode === 3) {
      return pShowFilterPanel ? "20%" : "25%";
    } else {
      return pShowFilterPanel ? "20%" : "25%";
    }
  }

  return (
    <SearchSourceRecordListContext.Provider
      value={{
        from,
        setFrom,
        templateType,
        setTemplateType,
        selectedRecord,
        setSelectedRecord,
        selectedRecords,
        setSelectedRecords,
        refreshSelectedRecord,
        setRefreshSelectedRecord,
        recordsSearchText,
        setRecordsSearchText,
      }}
    >
      <Box
        id="records"
        sx={{
          // flexBasis:
          //   searchTypeCode === 1 ||
          //   (searchTypeCode === 2 && !showAdvSearchFilterPanel)
          //     ? "25%"
          //     : "20%",
          flexBasis: tableView
            ? searchTypeCode === 2 && showAdvSearchFilterPanel
              ? "85%"
              : "100%"
            : getSearchSourceRecordPanelWidth(
                searchTypeCode,
                showAdvSearchFilterPanel
              ),
          display: "flex",
          flexDirection: "column",
          height: "100%",
          borderLeft: theme.general.border1,
          zIndex: (theme) => (tableView ? 0 : theme.zIndex.drawer + 1),
        }}
      >
        <SearchSourceRecordListHeader />

        <SearchSourceRecordList />

        <SearchSourceBottomToolbar />
      </Box>
    </SearchSourceRecordListContext.Provider>
  );
}
