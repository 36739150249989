import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getSourceDefinitions } from "app/main/pages/core/sourcedefinition/store/sourceDefinitionsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

function getSourceOptions(sourceDefsData) {
  let result = [];
  sourceDefsData.forEach((o) => {
    o["sourceMins"].forEach((p) => {
      if (p["activeFg"] === 1 && p["publishedFg"] === 1)
        result.push({
          value: p["uuId"],
          label: p["displayName"],
        });
    });
  });

  return _.orderBy(result, ["label"], ["asc"]);
}

export default function AlertDefinitionGeneralForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleCancel } = props;
  const { reset, getValues, watch } = useFormContext();
  const dispatch = useDispatch();

  const status = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const alertDefTypeOptions = _.get(
    codes,
    CODELIST_CODES.MON_ALERT_DEF_TYPE_CODE
  );
  const alertDefCategoryOptions = _.get(
    codes,
    CODELIST_CODES.MON_ALERT_DEF_CATEGORY_CODE
  );

  const definitionId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  const definitionDataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );

  const definitionData = _.get(definitionDataMap, definitionId);

  console.log("definitionData: ", definitionData);

  const sourceDefsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );
  const sourceDefsData =
    sourceDefsDataMap && !_.isEmpty(sourceDefsDataMap)
      ? Object.values(sourceDefsDataMap)
      : [];

  const sourceOptions = getSourceOptions(sourceDefsData);

  function onHandleSave() {
    const formData = getValues("alertDefinitionForm");
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      _.pick(formData, [
        "name",
        "description",
        "sourceUuId",
        "typeCode",
        "categoryCode",
        "preventDuplicateRecordFg",
      ])
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    dispatch(getSourceDefinitions());
  }, []);

  return (
    <NtaiFormPanel
      header="General Details"
      subheader="Enter name, description and select source for which alert definition is created"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
      saveDisabled={status && status.result === "pending" ? true : false}
      handleOtherActions={
        status && status.result === "pending" ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <NtaiCircularProgress size={18} />
          </Box>
        ) : null
      }
    >
      <NtaiTextField
        name="alertDefinitionForm.name"
        label="Name*"
        placeholder="Enter alert name"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="alertDefinitionForm.description"
        label="Description"
        multiline
        minRows={2}
        maxRows={2}
        placeholder="Enter description"
      />
      <NtaiSelectField
        name="alertDefinitionForm.sourceUuId"
        label="Source*"
        options={sourceOptions}
        isDisabled={action === "edit" ? true : false}
        rules={{ required: "Source is required" }}
      />

      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiSelectField
          name="alertDefinitionForm.typeCode"
          label="Type"
          options={alertDefTypeOptions}
        />
        <NtaiSelectField
          name="alertDefinitionForm.categoryCode"
          label="Category"
          options={alertDefCategoryOptions}
        />
      </Box>

      <NtaiSwitchField
        name="alertDefinitionForm.preventDuplicateRecordFg"
        label="Prevent duplicate record in workflow?"
      />
    </NtaiFormPanel>
  );
}
