import DatasourceCreate from "./DatasourceCreate";
import DatasourceEdit from "./DatasourceEdit";
import DatasourceList from "./DatasourceList";

const DatasourceConfig = {
  routes: [
    {
      path: "/admin/datasources",
      exact: true,
      component: DatasourceList,
    },
    {
      path: "/admin/datasources/:type/create",
      exact: true,
      component: DatasourceCreate,
    },
    {
      path: "/admin/datasources/:id/edit",
      exact: true,
      component: DatasourceEdit,
    },
  ],
};

export default DatasourceConfig;
