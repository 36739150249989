import { faChartSimple, faFile } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../../../SearchEdit";
import { SearchSourceContext } from "../../../../SearchSource";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { getSearchSourceRecordSourceDocs } from "./store/searchSourceRecordSourceDocsSlice";
import { SearchSourceRecordListContext } from "../../../SearchSourceRecordListPanel";

const _ = require("lodash");

export default function SearchSourceRecordSourceDocsMenu({
  handleSelectSourceDoc,
  handleClose,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);

  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const recordId = selectedRecord ? selectedRecord["recordId"] : null;

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordSourceDocsSlice.data
  );
  const status = useSelector(
    (state) => state.search.searchSourceRecordSourceDocsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function selectSourceDoc(sourceFieldUuId) {
    handleSelectSourceDoc(sourceFieldUuId);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordSourceDocs"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSourceRecordSourceDocs({
        searchId: searchId,
        searchSourceId: searchSourceId,
        recordId: recordId,
      })
    );
  }, []);

  return (
    <Box
      sx={{
        height: "240px",
        width: "200px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faFile} />
        <Typography variant="body2" fontWeight="700">
          Source Documents
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {loaded && data.length > 0 && (
              <MenuList dense sx={{ p: "2px", width: "100%" }}>
                {data &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((item) => {
                    return (
                      <MenuItem
                        key={item.uuId}
                        value={item.uuId}
                        divider={true}
                        onClick={() => selectSourceDoc(item.sourceFieldUuId)}
                      >
                        <ListItemText>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "500" }}
                          >
                            {item.name}
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    );
                  })}
              </MenuList>
            )}
            {loaded && data.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography variant="subtitle2">No documents found</Typography>
              </Box>
            )}
            {!loaded && <NtaiCircularProgress size={20} />}
          </Box>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
