import React from "react";
import SourceJsonToTable from "./SourceJsonToTable";
import { Box, useTheme } from "@mui/material";

const fields = {
  "bd6057e3-2f8c-45fd-b429-814b22fc8c3f": {
    uuId: "bd6057e3-2f8c-45fd-b429-814b22fc8c3f",
    parentFieldUuId: "66757722-ae20-4f2a-8116-ce51ec266a06",
    name: "employeeid",
    description: null,
    label: "Emloyee Id",
    path: "employeeid",
    primaryFieldFg: 1,
    fieldOrder: 1,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "employeeid.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.830+00:00",
    dateModified: "2023-07-23T16:27:17.789+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "746fa470-9011-4161-acf5-12c773e2e0d3": {
    uuId: "746fa470-9011-4161-acf5-12c773e2e0d3",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "name",
    description: null,
    label: "Name",
    path: "name",
    primaryFieldFg: null,
    fieldOrder: 2,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "name.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.830+00:00",
    dateModified: "2023-07-23T16:27:21.933+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "f1620a72-7ec5-46f6-ba88-490257efd3c0": {
    uuId: "f1620a72-7ec5-46f6-ba88-490257efd3c0",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "email",
    description: null,
    label: "Email",
    path: "email",
    primaryFieldFg: null,
    fieldOrder: 3,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "email.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.831+00:00",
    dateModified: "2023-07-23T16:27:26.098+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "77163690-cf55-4f1a-851f-67b5b45b38a7": {
    uuId: "77163690-cf55-4f1a-851f-67b5b45b38a7",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "department",
    description: null,
    label: "Department",
    path: "department",
    primaryFieldFg: null,
    fieldOrder: 4,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "department.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.831+00:00",
    dateModified: "2023-07-23T16:27:32.427+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "a53d66f0-f1c1-4bc8-8cc8-1fbabd6b2afa": {
    uuId: "a53d66f0-f1c1-4bc8-8cc8-1fbabd6b2afa",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "location",
    description: null,
    label: "Location",
    path: "location",
    primaryFieldFg: null,
    fieldOrder: 5,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "location.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.831+00:00",
    dateModified: "2023-07-23T16:27:37.509+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "7411cd0b-d8b2-4c93-a749-c81119714af8": {
    uuId: "7411cd0b-d8b2-4c93-a749-c81119714af8",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "level2",
    description: null,
    label: "Level II",
    path: "level2",
    primaryFieldFg: null,
    fieldOrder: 6,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "level2.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.832+00:00",
    dateModified: "2023-07-23T16:27:47.212+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "799bc130-4eed-4b6a-aadb-8ea961d969b2": {
    uuId: "799bc130-4eed-4b6a-aadb-8ea961d969b2",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "bu",
    description: null,
    label: "BU",
    path: "bu",
    primaryFieldFg: null,
    fieldOrder: 7,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "bu.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.832+00:00",
    dateModified: "2023-07-23T16:27:51.218+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "cabe76b4-2ef7-43b8-a9a2-075677fb941c": {
    uuId: "cabe76b4-2ef7-43b8-a9a2-075677fb941c",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "designation",
    description: null,
    label: "Designation",
    path: "designation",
    primaryFieldFg: null,
    fieldOrder: 8,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "designation.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.833+00:00",
    dateModified: "2023-07-23T16:27:58.265+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "53043fe7-072c-41a0-9ebb-60d1b65142f0": {
    uuId: "53043fe7-072c-41a0-9ebb-60d1b65142f0",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "tenure",
    description: null,
    label: "Tenure",
    path: "tenure",
    primaryFieldFg: null,
    fieldOrder: 9,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "tenure.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.833+00:00",
    dateModified: "2023-07-23T16:28:02.812+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "8e701c10-05c0-4a68-b985-366bfe94fb2e": {
    uuId: "8e701c10-05c0-4a68-b985-366bfe94fb2e",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "grade",
    description: null,
    label: "Grade",
    path: "grade",
    primaryFieldFg: null,
    fieldOrder: 10,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "grade.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.833+00:00",
    dateModified: "2023-07-23T16:28:07.183+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "73cc6f62-5569-4a48-b045-417085364692": {
    uuId: "73cc6f62-5569-4a48-b045-417085364692",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "team",
    description: null,
    label: "Team",
    path: "team",
    primaryFieldFg: null,
    fieldOrder: 11,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "team.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.834+00:00",
    dateModified: "2023-07-23T16:28:11.044+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "35ad153e-b332-4551-9820-d748046e3369": {
    uuId: "35ad153e-b332-4551-9820-d748046e3369",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "region",
    description: null,
    label: "Region",
    path: "region",
    primaryFieldFg: null,
    fieldOrder: 12,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "region.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.834+00:00",
    dateModified: "2023-07-23T16:28:17.314+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "7d136611-2edf-4d4a-8a89-e4fe2cf12df4": {
    uuId: "7d136611-2edf-4d4a-8a89-e4fe2cf12df4",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "workarea",
    description: null,
    label: "Workarea",
    path: "workarea",
    primaryFieldFg: null,
    fieldOrder: 13,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "workarea.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.834+00:00",
    dateModified: "2023-07-23T16:28:21.694+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "5164a815-d26d-489a-8be5-a39f5a103aab": {
    uuId: "5164a815-d26d-489a-8be5-a39f5a103aab",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "city",
    description: null,
    label: "City",
    path: "city",
    primaryFieldFg: null,
    fieldOrder: 14,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "city.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.835+00:00",
    dateModified: "2023-07-23T16:28:25.854+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "e3810749-920b-43ad-9937-05e20789c5e6": {
    uuId: "e3810749-920b-43ad-9937-05e20789c5e6",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "state",
    description: null,
    label: "State",
    path: "state",
    primaryFieldFg: null,
    fieldOrder: 15,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "state.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.836+00:00",
    dateModified: "2023-07-23T16:28:30.066+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "37291afb-3d1e-47dd-a184-d3892b1fe92e": {
    uuId: "37291afb-3d1e-47dd-a184-d3892b1fe92e",
    parentFieldUuId: "ff0b4710-a684-4b1d-ac92-41365ceb23b4",
    name: "theme",
    description: null,
    label: "Theme",
    path: "themes.theme",
    primaryFieldFg: null,
    fieldOrder: 16,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.theme.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.836+00:00",
    dateModified: "2023-07-23T16:28:34.067+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "bcc980b2-e5ea-4729-b3a1-469cf35fba60": {
    uuId: "bcc980b2-e5ea-4729-b3a1-469cf35fba60",
    parentFieldUuId: "17c0b474-0887-4b8e-9d6e-59e15373ca8c",
    name: "subtheme",
    description: null,
    label: "Subtheme",
    path: "themes.subthemes.subtheme",
    primaryFieldFg: null,
    fieldOrder: 17,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.subthemes.subtheme.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.837+00:00",
    dateModified: "2023-07-23T16:28:40.605+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "bd56fd6b-92b1-474c-bff6-0232d12e3096": {
    uuId: "bd56fd6b-92b1-474c-bff6-0232d12e3096",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "question",
    description: null,
    label: "Question",
    path: "themes.subthemes.question",
    primaryFieldFg: null,
    fieldOrder: 18,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.subthemes.question.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.837+00:00",
    dateModified: "2023-07-23T16:28:46.539+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "b14f6f32-dedc-433f-a01f-0ec1319a5328": {
    uuId: "b14f6f32-dedc-433f-a01f-0ec1319a5328",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "question_type",
    description: null,
    label: "Qs. Type",
    path: "themes.subthemes.question_type",
    primaryFieldFg: null,
    fieldOrder: 19,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.subthemes.question_type.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.838+00:00",
    dateModified: "2023-07-23T16:28:52.071+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "27529af7-7b8b-4d96-9765-b8770614d04d": {
    uuId: "27529af7-7b8b-4d96-9765-b8770614d04d",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "rating",
    description: null,
    label: "Rating",
    path: "themes.subthemes.rating",
    primaryFieldFg: null,
    fieldOrder: 20,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 2,
    dataTypeDecode: "Integer",
    esDataTypeCode: 2,
    esDataTypeDecode: "Number",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.subthemes.rating",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.838+00:00",
    dateModified: "2023-07-23T16:28:56.388+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "20400348-3eec-485a-81c3-5a4d32f21ccb": {
    uuId: "20400348-3eec-485a-81c3-5a4d32f21ccb",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "response",
    description: null,
    label: "Response",
    path: "themes.subthemes.response",
    primaryFieldFg: null,
    fieldOrder: 21,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.subthemes.response.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.839+00:00",
    dateModified: "2023-07-23T16:29:00.451+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "6c9985cf-aed8-4181-9fe4-f1c66ae3df49": {
    uuId: "6c9985cf-aed8-4181-9fe4-f1c66ae3df49",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "followupquestion",
    description: null,
    label: "FU Question",
    path: "themes.subthemes.followupquestion",
    primaryFieldFg: null,
    fieldOrder: 22,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.subthemes.followupquestion.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.839+00:00",
    dateModified: "2023-07-23T16:29:07.384+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
  "c164a983-999d-415b-b251-aa6137c658b0": {
    uuId: "c164a983-999d-415b-b251-aa6137c658b0",
    parentFieldUuId: "731f7d1a-6909-4398-a67c-942b78b671ae",
    name: "followupresponse",
    description: null,
    label: "FU Response",
    path: "themes.subthemes.followupresponse",
    primaryFieldFg: null,
    fieldOrder: 23,
    typeCode: 1,
    typeDecode: "Simple",
    dataTypeCode: 1,
    dataTypeDecode: "String",
    esDataTypeCode: 4,
    esDataTypeDecode: "Keyword",
    esSearchStrategyCode: 1,
    esSearchStrategyDecode: null,
    esFieldPath: "themes.subthemes.followupresponse.keyword",
    dbColName: null,
    dbColDataTypeCode: null,
    dbColDataTypeDecode: null,
    dbColLength: null,
    dbColScale: null,
    dbColPrecision: null,
    dbColNotNullFg: null,
    dbColPrimaryKeyFg: null,
    dbColIndexFg: null,
    dbColFunctionScript: null,
    userCreated: "sprakash",
    userModified: "sprakash",
    dateCreated: "2023-07-23T15:52:52.839+00:00",
    dateModified: "2023-07-23T16:29:12.101+00:00",
    sourceMin: {
      uuId: "06126857-7dc7-47d7-8fce-b29f4070a00a",
      sourceDefUuId: "358aeee7-88f1-4702-8cd4-da58e28d7685",
      displayName: "Leena (leena)  (1.0)",
      majorVersion: 1,
      minorVersion: 0,
      abbrev: "leena",
      name: "Leena",
      description: "Leena",
      owner: "swiggy",
      privacyFg: 1,
      categoryCode: 2,
      categoryDecode: "Publication",
      defaultViewUuId: "9b35e99e-d935-462b-bb9b-7e7ed36d42db",
      defaultViewName: "Indi",
      dateModified: "2023-07-23T15:47:12.304+00:00",
    },
    lookupLinks: [],
  },
};

const data = {
  id: "77309411329",
  employeeid: "1625009",
  name: "Yash Joshi",
  email: "yash.joshi@swiggy.in",
  department: "Business Operations",
  location: "Bengaluru",
  level2: "NA",
  bu: "MarketPlace",
  designation: "Area Launch Manager",
  tenure: "2 - 5 Years",
  grade: "6",
  team: "Emerging Initiatives Business Strategy",
  region: "South - Bangalore",
  workarea: "Embassy Tech Village",
  city: "Bengaluru",
  state: "Karnataka",
  themes: [
    {
      theme: "My Performance and Rewards",
      subthemes: [
        {
          subtheme: "Perfomance",
          question:
            "Do you think your performance is fairly evaluated against the defined goals?",
          question_type: "primary",
          rating: 1,
          response: "Not at all!",
          followupquestion: "What makes you say so?",
          followupresponse:
            "There is no performance reward or recognization in the Swiggy EI team, no monthly, quarterly, or yea",
        },
      ],
    },
    {
      theme: "Senior Leadership",
      subthemes: [
        {
          subtheme: "Credibility",
          question: "Management's actions match its words.",
          question_type: "primary",
          rating: 3,
          response: "I'm not sure at the moment",
          followupquestion: "I'm glad you told me. Why do you feel this way?",
          followupresponse: "NA",
        },
        {
          subtheme: "Direction",
          question:
            "Do you believe that the senior leadership has set the right business goals and strategies to take the company forward?",
          question_type: "primary",
          rating: 5,
          response: "Yes, they have!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "Inclusion",
          question:
            "Management involves people in decisions that affect their jobs or work environment.",
          question_type: "primary",
          rating: 1,
          response: "Not at all",
          followupquestion:
            "That doesn't sound good! Please tell me more so that I can help.",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "Net Promoter Score",
      subthemes: [
        {
          subtheme: "eNPS",
          question:
            "Would you recommend Swiggy to your friends & family as a company to work for?",
          question_type: "primary",
          rating: 3,
          response: "Not sure",
          followupquestion: "Okay. What makes you say so?",
          followupresponse:
            "there is some role and profiles where we need some internal person to handle the work flow. New hiri",
        },
      ],
    },
    {
      theme: "My Performance & Rewards",
      subthemes: [
        {
          subtheme: "Perfomance",
          question:
            "Do you think your performance is fairly evaluated against the defined goals?",
          question_type: "primary",
          rating: 4,
          response: "Yes, to a large extent",
          followupquestion: "NA",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "Overall Experience",
      subthemes: [
        {
          subtheme: "Exp",
          question: "Do you consider yourself an ambassador of Swiggy?",
          question_type: "primary",
          rating: 5,
          response: "Yes, very much!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "15th day exp",
          question:
            "How has your experience in the organisation been till now?",
          question_type: "primary",
          rating: 3,
          response: "I'm not sure at the moment",
          followupquestion: "Okay. What makes you say so?",
          followupresponse: "Some Polices and Central level management",
        },
        {
          subtheme: "Exp",
          question: "Do you consider yourself as an ambassador of Swiggy?",
          question_type: "primary",
          rating: 4,
          response: "Yes, to some extent!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "My Manager",
      subthemes: [
        {
          subtheme: "Approachability",
          question:
            "Is your manager approachable and helpful towards your career development plans?",
          question_type: "primary",
          rating: 2,
          response: "Not as much as I'd like",
          followupquestion:
            "That doesn't sound good! Please tell me more so that I can help.",
          followupresponse: "NA",
        },
        {
          subtheme: "Caring Manager",
          question:
            "Does your manager support you when dealing with personal or family issues?",
          question_type: "primary",
          rating: 3,
          response: "Sometimes",
          followupquestion: "Is there a specific reason for this?",
          followupresponse:
            "Yes, there is No clearity on work , and also the timelines . no perticular management for work Flow ",
        },
        {
          subtheme: "Caring Manager",
          question:
            "Is the feedback provided to you by your manager respectfully?",
          question_type: "primary",
          rating: 5,
          response: "Yes, always!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "My Organisation",
      subthemes: [
        {
          subtheme: "Sense of Pride",
          question:
            "What is the one experience you had at work that should be changed?",
          question_type: "primary",
          rating: 3,
          response:
            "WhatsApp Groups for Offical Discussion and Data Publication.",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "Sense of Pride",
          question: "Are you proud of what Swiggy offers to the society?",
          question_type: "primary",
          rating: 5,
          response: "Always proud!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "My Career",
      subthemes: [
        {
          subtheme: "Opportunities",
          question:
            "Are you satisfied with the Career Growth Opportunities at Swiggy?",
          question_type: "primary",
          rating: 3,
          response: "I'm not sure how   I feel about it",
          followupquestion: "I'm glad you told me. Why do you feel this way?",
          followupresponse: "NA",
        },
        {
          subtheme: "Adequate training",
          question: "I have avenues to learn and develop new skills.",
          question_type: "primary",
          rating: 4,
          response: "Yes, to a large extent",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "Opportunities",
          question:
            "Are you satisfied with the Career Growth Opportunities at Swiggy?",
          question_type: "primary",
          rating: 3,
          response: "I'm not sure how  I feel about it",
          followupquestion: "I'm glad you told me. Why do you feel this way?",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "My Team",
      subthemes: [
        {
          subtheme: "Peer Support",
          question: "Do you think you can count on your peers for help?",
          question_type: "primary",
          rating: 5,
          response: "Totally count on them!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "My Work",
      subthemes: [
        {
          subtheme: "Sense of Purpose",
          question: "Do you enjoy the work that you do?",
          question_type: "primary",
          rating: 5,
          response: "Yes, completely!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "My Organisational Culture",
      subthemes: [
        {
          subtheme: "Belongingness",
          question: 'There is a "family" or "team" feeling here.',
          question_type: "primary",
          rating: 3,
          response: "Sometimes",
          followupquestion: "Okay. What makes you say so?",
          followupresponse: "NA",
        },
      ],
    },
    {
      theme: "Customer Backward Thinking",
      subthemes: [
        {
          subtheme: "Brand perception",
          question:
            "Do you feel Swiggy is doing better than its competitors in the market?",
          question_type: "primary",
          rating: 4,
          response: "Yes, to a large extent",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "Brand preference",
          question: "Do you prefer Swiggy's services over its competitors?",
          question_type: "primary",
          rating: 5,
          response: "Yes, always!",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "Ownership",
          question:
            "Please highlight some opportunities that you feel Swiggy can tap in the market?",
          question_type: "primary",
          rating: 3,
          response:
            "Some points in my Mind,  1. we need to improve our De hiring policies  in 5.x cites   2. need to set SOP for Biker and cycle De ratio  3. focus on marketing supply and need to closely monitor budget  4. open onground travel for BIZ ops team for the ground reality check rather than coming to the office   5.  quarterly gifts for our best-performing partners in 5.x cites  6.  inventory availability  for our fleet",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "NPS (CBT)",
          question:
            "Will you recommend the services of Swiggy to your friend's and family?",
          question_type: "primary",
          rating: 5,
          response: "Will surely recommend",
          followupquestion: "NA",
          followupresponse: "NA",
        },
        {
          subtheme: "Ownership",
          question:
            "Please highlight some opportunities that you feel Swiggy can tap in the market?",
          question_type: "primary",
          rating: 3,
          response:
            "Some points in my Mind, 1. we need to improve our De hiring policies  in 5.x cites  2. need to set SOP for Biker and cycle De ratio 3. focus on marketing supply and need to closely monitor budget 4. open onground travel for BIZ ops team for the ground reality check rather than coming to the office  5.  quarterly gifts for our best-performing partners in 5.x cites 6.  inventory availability  for our fleet",
          followupquestion: "NA",
          followupresponse: "NA",
        },
      ],
    },
  ],
  valid_from: 1690127638204,
  valid_to: 253402318799000,
};

export default function TestSourceJsonToTable() {
  const theme = useTheme();
  return (
    <Box sx={{ p: theme.spacing(2), width: "50%" }}>
      <SourceJsonToTable data={data} fields={fields} />
    </Box>
  );
}
