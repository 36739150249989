import React from "react";
import { useEffect, useState } from "react";
import { render } from "react-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import NtaiDndCard from "./NtaiDndCard";
import update from "immutability-helper";
import { Box, useTheme } from "@mui/material";
import { PropTypes } from "prop-types";
const _ = require("lodash");

function transformData(idField, labelField, data) {
  let revisedData = [];
  data.forEach((data, index) => {
    revisedData.push({
      id: _.get(data, idField),
      text: _.get(data, labelField),
    });
  });

  return revisedData;
}

export default function NtaiDnd(props) {
  const theme = useTheme();
  const {
    idField,
    labelField,
    data,
    handleCallback,
    direction,
    flexWrap,
    secondaryAction,
    handleSecondaryAction,
  } = props;

  const [cards, setCards] = useState(transformData(idField, labelField, data));

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = cards[dragIndex];
    setCards(
      update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  function handleClick(key) {
    console.log("Card clicked: ", key);
  }

  useEffect(() => {
    handleCallback(cards);
  }, [cards]);

  useEffect(() => {
    const newData = transformData(idField, labelField, data);
    setCards([...newData]);
  }, [data]);

  return (
    <Box>
      <DndProvider backend={HTML5Backend}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: direction ? direction : null,
            flexWrap: flexWrap ? flexWrap : null,
            gap: theme.spacing(1),
          }}
        >
          {cards.map((card, i) => (
            <NtaiDndCard
              key={`ntai-dnd-card-${i}`}
              index={i}
              id={card.id}
              text={card.text}
              moveCard={moveCard}
              handleClick={handleClick}
              secondaryAction={secondaryAction}
              handleSecondaryAction={handleSecondaryAction}
            />
          ))}
        </Box>
      </DndProvider>
    </Box>
  );
}

NtaiDnd.propTypes = {
  idField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handleCallback: PropTypes.func.isRequired,
  direction: PropTypes.string,
};
