import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { faArrowLeft, faCog } from "@fortawesome/pro-regular-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
import SourceDatasetSidePanelInStores from "./in-stores/SourceDatasetSidePanelInStores";
import SourceDatasetSidePanelStages from "./stages/SourceDatasetSidePanelStages";
import SourceDatasetSidePanelDatasets from "./datasets/SourceDatasetSidePanelDatasets";
import SourceDatasetSidePanelRefSourceDatasets from "./ref-datasets/SourceDatasetSidePanelRefSourceDatasets";
import SourceDatasetSidePanelPackages from "./packages/SourceDatasetSidePanelPackages";

export default function SourceDatasetSidePanel() {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          height: "44px",
          alignItems: "center",
          paddingLeft: theme.spacing(2),
          gap: theme.spacing(2),
        }}
      >
        <FontAwesomeIcon icon={faCog} />
        <Typography variant="h6" fontWeight="700">
          Settings
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 44px)`,
        }}
      >
        <Scrollbar autoHide={false}>
          <SourceDatasetSidePanelInStores />
          <SourceDatasetSidePanelRefSourceDatasets />
          <SourceDatasetSidePanelStages />
          <SourceDatasetSidePanelDatasets />
          <SourceDatasetSidePanelPackages />
        </Scrollbar>
      </Box>
    </React.Fragment>
  );
}
