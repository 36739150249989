import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ObjectFormBuilderContext } from "../../ObjectFormBuilder";
import { reorderObjectFormBuilderPanelFields } from "../store/objectFormBuilderPanelsSlice";
import NtaiDnd3 from "@ntai/components/dnd3/NtaiDnd3";
const _ = require("lodash");

export default function ObjectFormBuilderReorderFields() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { selectedTabId, selectedPanelId } = React.useContext(
    ObjectFormBuilderContext
  );
  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);
  const objectFormId = useSelector(
    (state) => state.core.objectFormsSlice.activeId
  );

  const pathVariables = {
    domainId: domainId,
    objectId: objectId,
    objectFormId: objectFormId,
    objectFormTabId: selectedTabId,
    uuId: selectedPanelId,
  };

  const objectTabPanelsDataMap = useSelector(
    (state) => state.core.objectFormBuilderPanelsSlice.data
  );

  const objectTabPanelData = selectedPanelId
    ? objectTabPanelsDataMap[selectedPanelId]
    : null;

  const objectFieldsData =
    objectTabPanelData && !_.isEmpty(objectTabPanelData)
      ? _.orderBy(objectTabPanelData["fields"], ["fieldOrder"], ["asc"]).map(
          (f) => {
            return {
              uuId: f["uuId"],
              label: f["field"]["fieldLabel"],
            };
          }
        )
      : [];

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleReorder(items) {
    const previousOrder = objectFieldsData.map((o, i) => {
      return o["uuId"];
    });

    const newOrder = items.map((o) => {
      return o["uuId"];
    });

    if (_.isArray(items) && !_.isEqual(previousOrder, newOrder)) {
      dispatch(
        reorderObjectFormBuilderPanelFields({
          ...pathVariables,
          formData: {
            items: newOrder,
          },
        })
      );
    }
  }

  return (
    <React.Fragment>
      <NtaiIconButton icon={faArrowUpArrowDown} handleClick={handleClick} />
      <NtaiDialog
        size="sm"
        title="Reorder Fields"
        open={open}
        handleDialogClose={handleClose}
      >
        <NtaiPanel
          padding="0px"
          height="250px"
          subheader="Use the drag and drop option to reorder fields"
        >
          <Scrollbar>
            <Box>
              {objectFieldsData &&
                _.isArray(objectFieldsData) &&
                objectFieldsData.length > 0 && (
                  <NtaiDnd3
                    id="uuId"
                    label="label"
                    items={objectFieldsData}
                    handleReorder={handleReorder}
                    direction="vertical"
                  />
                )}
            </Box>
          </Scrollbar>
        </NtaiPanel>
      </NtaiDialog>
    </React.Fragment>
  );
}
