import {
  Avatar,
  Box,
  Chip,
  Drawer,
  lighten,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AlertEditContext } from "../AlertEdit";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignUsersToAlertRecords,
  clearStatus,
  completeAlertRecordsActivity,
  getAlertRecords,
  setActiveId,
} from "./store/alertRecordsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import AlertRecordCompleteActivityDialog from "./complete-activity/AlertRecordCompleteActivityDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import {
  getSourceRecordListings,
  clearStatus as clearSourceRecordListingsStatus,
} from "app/main/pages/core/sourcedefinition/source/template/listing/store/sourceRecordListingsSlice";
import { getSourceRecordLabels } from "app/main/pages/core/sourcedefinition/source/template/label/store/sourceRecordLabelsSlice";
import {
  faArchive,
  faSquareCheck,
  faMessagePlus,
  faPaperclip,
  faSquareXmark,
  faCommentAltEdit,
  faUserCheck,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import AlertRecordsBatchUpdateSelectForm from "./batch-assessment/AlertRecordBatchAssessmentSelectForm";
import AlertRecordPanel from "./AlertRecordPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import AlertRecordTable from "./AlertRecordTable";
import { useSnackbar } from "notistack";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import AlertRecordBatchDocumentList from "./batch-document/AlertRecordBatchDocumentList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNote,
  faPaperclipVertical,
  faTasks,
} from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

function buildHeadCells(sourceFields, sourceRecordListingsDataMap) {
  let result = [];

  const templateFields = getTemplateFields(1, sourceRecordListingsDataMap);

  result.push({
    accessorKey: "userAssigned",
    numeric: false,
    disablePadding: false,
    header: (
      <Avatar sx={{ height: 24, width: 24 }}>
        <FontAwesomeIcon size="xs" icon={faUser} />
      </Avatar>
    ),
    size: 1,
  });

  result.push({
    accessorKey: "actions",
    numeric: false,
    disablePadding: false,
    header: "Actions",
    size: 10,
    grow: false,
  });

  // result.push({
  //   accessorKey: "assessments",
  //   numeric: false,
  //   disablePadding: false,
  //   header: "Assessments",
  //   size: 15,
  // });

  const strRecord = "record.";

  if (templateFields.length > 0) {
    templateFields.forEach((tf, i) => {
      if (tf.sourceDerivedFieldFg !== 1) {
        result.push({
          accessorKey: strRecord + tf["sourceField"]["name"],
          numeric: false,
          disablePadding: false,
          header: tf["sourceField"]["label"],
          colorSchemes: tf.sourceRecordListingFieldColors,
        });
      } else {
        result.push({
          accessorKey: strRecord + tf["sourceDerivedField"]["name"],
          numeric: false,
          disablePadding: false,
          header: tf["sourceDerivedField"]["label"],
          colorSchemes: tf.sourceRecordListingFieldColors,
          // primary: tf.primaryFieldFg ? true : false,
        });
      }
    });
  } else {
    if (
      Array.isArray(Object.values(sourceFields)) &&
      Object.values(sourceFields).length > 0
    ) {
      Object.values(sourceFields).forEach((field, index) => {
        if (!field.path.includes(".")) {
          result.push({
            accessorKey: strRecord + field["sourceFieldName"],
            numeric: false,
            disablePadding: false,
            header: field["sourceFieldName"],
            // primary: field.primaryFieldFg ? true : false,
          });
        }
      });
    }
  }
  if (result.length > 3) {
    result[2]["primary"] = true;
  }

  return result;
}

function getTemplateFields(templateTypeCode, sourceRecordListingsDataMap) {
  let result = [];

  let sourceListingTableTemplate = null;

  if (sourceRecordListingsDataMap && !_.isEmpty(sourceRecordListingsDataMap)) {
    sourceListingTableTemplate = _.filter(
      Object.values(sourceRecordListingsDataMap),
      { typeCode: templateTypeCode }
    )[0];
  }

  if (
    sourceListingTableTemplate &&
    !_.isEmpty(sourceListingTableTemplate) &&
    _.get(sourceListingTableTemplate, "sourceRecordListingFields").length > 0
  ) {
    _.get(sourceListingTableTemplate, "sourceRecordListingFields").forEach(
      (sf, i) => {
        result.push(sf);
      }
    );
  }
  return result;
}

function buildRows(
  sourceRecordArr,
  headCells,
  sourceRecordListingsDataMap,
  theme
) {
  let result = [];

  const templateFields = getTemplateFields(1, sourceRecordListingsDataMap);

  if (_.isArray(sourceRecordArr) && sourceRecordArr.length > 0) {
    sourceRecordArr.forEach((record) => {
      let sourceRecord = {};

      const actionIcons = [];
      if (_.get(record, "assessmentFormNames").length > 0) {
        actionIcons.push(
          <FontAwesomeIcon
            color={theme.colors.secondary.main}
            size="lg"
            icon={faCommentAltEdit}
          />
        );
      }

      if (_.get(record, "numOfRecordDocuments") > 0) {
        actionIcons.push(<FontAwesomeIcon icon={faPaperclip} />);
      }

      if (_.get(record, "numOfRecordActions") > 0) {
        actionIcons.push(<FontAwesomeIcon icon={faTasks} />);
      }

      if (_.get(record, "numOfRecordNotes") > 0) {
        actionIcons.push(<FontAwesomeIcon icon={faNote} />);
      }

      if (_.get(record, "userAssigned") && !_.isEmpty(record["userAssigned"]))
        sourceRecord["userAssigned"] = (
          <Tooltip title={record["userAssigned"]["userName"]}>
            <Avatar
              // variant="outlined"
              sx={{
                // bgcolor: lighten(theme.colors.primary.main, 0.6),
                height: 24,
                width: 24,
                fontSize: 10,
                fontWeight: 500,
                color: "black",
                bgcolor: lighten(
                  theme.colors.secondary.main,
                  _.get(record, "userAssigned") &&
                    !_.isEmpty(record["userAssigned"])
                    ? 0.7
                    : 0.9
                ),
              }}
            >
              {_.get(record, "userAssigned") &&
              !_.isEmpty(record["userAssigned"]) ? (
                NtaiUtils.getUserInitials(
                  record["userAssigned"]["firstName"],
                  record["userAssigned"]["lastName"]
                )
              ) : (
                <FontAwesomeIcon color="white" icon={faUser} />
              )}
            </Avatar>
          </Tooltip>
        );

      sourceRecord["actions"] = (
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
          }}
        >
          {actionIcons}
        </Box>
      );

      // sourceRecord["assessments"] = (
      //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
      //     {record["assessmentFormNames"].map((o, i) => (
      //       <Chip
      //         key={`ntai-monitor-assessment-${i}`}
      //         variant="outlined"
      //         size="small"
      //         label={
      //           <Typography variant="subtitle2" color="inherit">
      //             {o}
      //           </Typography>
      //         }
      //       />
      //     ))}
      //   </Box>
      // );

      sourceRecord["id"] = _.get(record, "uuId");
      sourceRecord["uuId"] = _.get(record, "uuId");
      sourceRecord["numOfRecordAssessments"] = _.get(
        record,
        "numOfRecordAssessments"
      );
      sourceRecord["numOfRecordActions"] = _.get(record, "numOfRecordActions");
      sourceRecord["numOfRecordNotes"] = _.get(record, "numOfRecordNotes");

      const tmpSourceRecord = _.get(record, "record.minData");

      sourceRecord["record"] = {};

      if (templateFields && templateFields.length > 0) {
        templateFields.forEach((tf, i) => {
          const field =
            tf["sourceDerivedFieldFg"] === 1
              ? tf["sourceDerivedField"]
              : tf["sourceField"];

          const fieldData = tmpSourceRecord[field.name];
          if (_.isArray(fieldData)) {
            sourceRecord["record"][field.name] = _.sortBy(
              tmpSourceRecord[field.name]
            ).join(", ");
          } else {
            if (
              [7, 8].includes(field["dataTypeCode"]) ||
              field["esDataTypeCode"] === 3
            ) {
              sourceRecord["record"][field.name] = NtaiUtils.formatDateCol(
                tmpSourceRecord[field.name]
              );
            } else
              sourceRecord["record"][field.name] = tmpSourceRecord[field.name];
          }
        });
      } else {
        Object.keys(tmpSourceRecord).forEach((key) => {
          const revisedHeadCells = _.filter(headCells, function (o) {
            return o.id === key;
          });

          if (revisedHeadCells && revisedHeadCells.length > 0) {
            const tf = _.get(revisedHeadCells[0], "id");
            const fieldData = tmpSourceRecord[tf];
            if (_.isArray(fieldData)) {
              sourceRecord["record"][tf] = _.sortBy(tmpSourceRecord[tf]).join(
                ", "
              );
            } else {
              sourceRecord["record"][tf] = tmpSourceRecord[tf];
            }
          }
        });
      }
      result.push(sourceRecord);
    });
  }

  console.log("Record table data: ", result);
  return result;
}

export default function AlertRecordList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = React.useState();
  const [headCells, setHeadCells] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [completeActivityDialogOpen, setCompleteActivityDialogOpen] =
    React.useState(false);
  const [batchAssessmentDialogOpen, setBatchAssessmentDialogOpen] =
    React.useState(false);
  const [batchDocumentsDialogOpen, setBatchDocumentsDialogOpen] =
    React.useState(false);
  const [tableTemplateAvailable, setTableTemplateAvailable] =
    React.useState(false);

  const {
    gUuId,
    alertId,
    alertMasterId,
    activityId,
    expandSidePanel,
    setExpandSidePanel,
    sidePanelContext,
    setSidePanelContext,
    selectedRecordId,
    setSelectedRecordId,
    sourceFields,
    alertData,
    selectedRecords,
    setSelectedRecords,
    refreshSelectedRecord,
    setRefreshSelectedRecord,
    refreshAllRecords,
    setRefreshAllRecords,
  } = useContext(AlertEditContext);

  const dataMap = useSelector((state) => state.monitor.alertRecordsSlice.data);
  const status = useSelector((state) => state.monitor.alertRecordsSlice.status);
  const recordsData = dataMap && Object.values(dataMap);

  const sourceUuId = _.get(alertData, "sourceUuId");

  const sourceRecordListingsDataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  function handleEdit(id) {
    setExpandSidePanel(true);
    setSidePanelContext("manage");
    setSelectedRecordId(id);
    dispatch(setActiveId(id));
    handleDrawerOpen();
  }

  function handleRowAction(menuAction, recordId) {
    if (menuAction === "manage") {
      setSidePanelContext("manage");
    }
    setSelectedRecordId(recordId);
    dispatch(setActiveId(recordId));
    setExpandSidePanel(true);
  }

  function handleAction(actionName, selectedRecords) {
    setAction(actionName);
    setSelectedRecords(selectedRecords);

    if (actionName === "complete") setCompleteActivityDialogOpen(true);
    if (actionName === "assessments") setBatchAssessmentDialogOpen(true);
    if (actionName === "documents") setBatchDocumentsDialogOpen(true);
    if (actionName === "terminate") {
    }
  }

  function handleCompleteActivityDialogClose() {
    setCompleteActivityDialogOpen(false);
  }

  function handleCompleteActivity(completeActivityFormData) {
    dispatch(
      completeAlertRecordsActivity({
        alertId: alertId,
        alertMasterId: alertMasterId,
        activityId: activityId,
        formData: {
          alertRecordUuIds: selectedRecords,
          ...completeActivityFormData,
        },
      })
    );
  }

  function handleUserAssignment(formData) {
    dispatch(
      assignUsersToAlertRecords({
        alertId: alertId,
        alertMasterId: alertMasterId,
        activityId: activityId,
        formData: formData,
      })
    );
  }

  function handleDrawerClose() {
    setExpandSidePanel(false);
    setSidePanelContext(null);
    setSelectedRecordId(null);
    setOpenDrawer(false);
  }

  function handleDrawerOpen() {
    setOpenDrawer(true);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      ["getAlertRecords", "completeAlertRecordsActivity"].includes(
        status.method
      )
    ) {
      if (!_.isEmpty(sourceRecordListingsDataMap)) {
        const tableListing = _.filter(
          Object.values(sourceRecordListingsDataMap),
          { typeCode: 1 }
        );

        if (
          tableListing.length > 0 &&
          tableListing[0]["sourceRecordListingFields"].length > 0
        ) {
          const revisedHeadCells = buildHeadCells(
            sourceFields,
            sourceRecordListingsDataMap
          );

          const revisedData = buildRows(
            recordsData,
            revisedHeadCells,
            sourceRecordListingsDataMap,
            theme
          );

          setHeadCells([...revisedHeadCells]);
          setData([...revisedData]);
          setDataLoaded(true);
          setTableTemplateAvailable(true);

          if (status.method === "completeAlertRecordsActivity") {
            setCompleteActivityDialogOpen(false);
            enqueueSnackbar(
              `Activity completed successfully for the selected items`,
              {
                variant: "success",
              }
            );
          }
          dispatch(clearStatus());
        } else {
          setTableTemplateAvailable(false);
          setDataLoaded(true);
        }
      } else {
        setTableTemplateAvailable(false);
        setDataLoaded(true);
      }
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "assignUsersToAlertRecords"
    ) {
      // setRefreshAllRecords(refreshAllRecords + 1);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    // TODO: refresh selected record should only fetch and update selected record not all records
    dispatch(
      getAlertRecords({
        alertId: alertId,
        alertMasterId: alertMasterId,
        activityId: activityId,
      })
    );
  }, [alertMasterId, activityId, refreshAllRecords]);

  useEffect(() => {
    dispatch(
      getSourceRecordLabels({
        sourceDefId: "*",
        sourceId: sourceUuId,
      })
    );
  }, [sourceUuId]);

  return (
    <>
      <Box
        sx={{
          // flexBasis: expandSidePanel ? `calc(100% - 520px)` : "100%",
          flexBasis: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {dataLoaded && data.length > 0 && tableTemplateAvailable && (
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                p: theme.spacing(2),
              }}
            >
              <AlertRecordTable
                enableStickyHeader={false}
                data={data}
                columns={headCells}
                id="uuId"
                rowClickRedirect={false}
                handleRowClick={handleEdit}
                tableTitle={`Pending Items (${data.length})`}
                rowTitle="Record"
                rowTitlePlural="Records"
                topToolbarLeftActions={[
                  {
                    value: "assign",
                    label: "Assign",
                    icon: faUserCheck,
                    size: "small",
                    textCase: "upper",
                    confirmAction: false,
                  },
                  {
                    value: "complete",
                    label: "Complete Activity",
                    icon: faSquareCheck,
                    variant: "contained",
                    size: "small",
                    textCase: "upper",
                    confirmAction: false,
                  },
                  {
                    value: "assessments",
                    label: "Assessments",
                    icon: faMessagePlus,
                    size: "small",
                    textCase: "upper",
                  },
                  {
                    value: "documents",
                    label: "Documents",
                    icon: faPaperclip,
                    size: "small",
                    textCase: "upper",
                  },
                  {
                    value: "terminate",
                    label: "Terminate",
                    icon: faSquareXmark,
                    size: "small",
                    textCase: "upper",
                    rowSelectionEnable: true,
                    confirmAction: true,
                  },
                ]}
                handleTopToolbarLeftAction={handleAction}
              />
            </Box>
          </Scrollbar>
        )}

        {dataLoaded && !tableTemplateAvailable && (
          <NtaiEmptyMessage
            vAlign="center"
            header="No table template available"
            subheader="Assign table template under Source - Template - Listings"
          />
        )}

        {dataLoaded && tableTemplateAvailable && data.length === 0 && (
          <NtaiEmptyMessage
            vAlign="center"
            header="No data available"
            subheader="No alert records found for the selected item. Check alert tracker message or contact administrator"
          />
        )}

        {!dataLoaded && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NtaiCircularProgress size={24} />
          </Box>
        )}
      </Box>
      <Drawer
        open={openDrawer}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        <AlertRecordPanel
          handleDrawerClose={handleDrawerClose}
          handleUserAssignment={handleUserAssignment}
        />
      </Drawer>
      {completeActivityDialogOpen && (
        <NtaiDialog
          open={completeActivityDialogOpen}
          size="sm"
          handleDialogClose={() => handleCompleteActivityDialogClose()}
          title="Complete Activity"
        >
          <NtaiForm>
            <AlertRecordCompleteActivityDialog
              handleCompleteActivity={handleCompleteActivity}
              handleDialogClose={() => handleCompleteActivityDialogClose()}
            />
          </NtaiForm>
        </NtaiDialog>
      )}
      {batchAssessmentDialogOpen && (
        <NtaiDialog
          open={batchAssessmentDialogOpen}
          size="sm"
          handleDialogClose={() => setBatchAssessmentDialogOpen(false)}
          title="Select Assessment Form"
        >
          <AlertRecordsBatchUpdateSelectForm
            handleCancel={() => setBatchAssessmentDialogOpen(false)}
          />
        </NtaiDialog>
      )}
      {batchDocumentsDialogOpen && (
        <NtaiDialog
          open={batchDocumentsDialogOpen}
          size="md"
          handleDialogClose={() => setBatchDocumentsDialogOpen(false)}
          title="Batch Documents"
        >
          <AlertRecordBatchDocumentList
            handleCancel={() => setBatchDocumentsDialogOpen(false)}
          />
        </NtaiDialog>
      )}
    </>
  );
}
