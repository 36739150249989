import React from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiPopperPanel from "@ntai/components/poppers/NtaiPopperPanel";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
import SourceBoxMin from "../../shared/SourceBoxMin";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function SearchSourceLinkedSourceList({
  handleLinkedSourceSelect,
  handleClose,
}) {
  const theme = useTheme();

  const dataMap = useSelector(
    (state) => state.search.searchSourceLinkedSourcePreviewsSlice.data
  );

  const data = Object.values(dataMap);

  return (
    <Box
      sx={{
        height: "320px",
        width: "320px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faLink} />
        <Typography variant="body2" fontWeight="700">
          Linked Sources
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <List
            sx={{
              p: 0,
              display: "flex",
              alignItems: "start",
              flexWrap: "wrap",
              height: "100%",
              gap: theme.spacing(1),
            }}
          >
            {Array.isArray(data) &&
              data.length > 0 &&
              data.map((source, index) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        py: theme.spacing(1),
                        pl: theme.spacing(1),
                        pr: theme.spacing(2),
                        borderRadius: "0px",
                        display: "flex",
                        gap: theme.spacing(1),
                        alignItems: "center",
                        justifyContent: "space-between",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => handleLinkedSourceSelect(source["uuId"])}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            sx={{ wordBreak: "break-word" }}
                            variant="subtitle1"
                            color="inherit"
                            fontWeight={700}
                          >
                            {source["abbrev"]}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{ wordBreak: "break-word" }}
                            variant="subtitle2"
                          >
                            {source["name"]}
                          </Typography>
                        }
                      />
                      <Chip
                        size="small"
                        label={
                          <Typography
                            variant="subtitle2"
                            fontWeight="700"
                            color="inherit"
                          >
                            {NtaiUtils.abbrevNumber(_.get(source, "hits"))}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider flexItem />
                  </Box>
                );
              })}
            {data && Array.isArray(data) && data.length === 0 && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No linked sources available"
                  subheader="Linked sources are retrieved for mapped source fields"
                />
              </Box>
            )}
          </List>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
