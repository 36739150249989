import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React, { useEffect, useState } from "react";
import EntitySourceCriteria from "./EntitySourceCriteria";
import { useDispatch, useSelector } from "react-redux";
import { getSourceObjects } from "../../sourcedefinition/source/object/store/sourceObjectsSlice";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import EntitySourceFilterFields from "./EntitySourceFilterFields";
const _ = require("lodash");

function revisedSourceOptions(selectOptions, existingEntitySources) {
  if (existingEntitySources.length === 0) return selectOptions;
  else {
    return _.filter(selectOptions, function (a) {
      existingEntitySources.forEach((b) => {
        return b["sourceObjectUuId"] === a["uuId"] ? false : true;
      });
    });
  }
}

export default function EntitySourceForm(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const [sourceCriteria, setSourceCriteria] = useState();
  const [esCriteria, setEsCriteria] = useState();

  const { action, formData, handleFormSubmit, handleCancel } = props;

  const objectId = useSelector((state) => state.core.entitiesSlice.objectUuId);
  const sourceObjectsDataMap = useSelector(
    (state) => state.sources.sourceObjectsSlice.data
  );

  const dataMap = useSelector((state) => state.core.entitySourcesSlice.data);
  const data = dataMap && _.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const allSourceOptions = NtaiUtils.getSelectOptions(
    Object.values(sourceObjectsDataMap),
    "uuId",
    "sourceMin.displayName"
  );

  const sourceOptions =
    action === "add"
      ? revisedSourceOptions(
          NtaiUtils.getSelectOptions(
            Object.values(sourceObjectsDataMap),
            "uuId",
            "sourceMin.displayName"
          ),
          data
        )
      : allSourceOptions;

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("entitySourceForm")
    );
    sanitizedFormData["criteria"] = sourceCriteria;
    sanitizedFormData["esCriteria"] = esCriteria;
    handleFormSubmit(sanitizedFormData);
  }

  function buildQuery(tree, config, jsonTree, formats) {
    setSourceCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  useEffect(() => {
    dispatch(
      getSourceObjects({
        sourceDefId: "*",
        sourceId: "*",
        params: { objectUuId: objectId },
      })
    );

    reset({ entitySourceForm: formData });

    if (formData && !_.isEmpty(formData["criteria"])) {
      setSourceCriteria(formData["criteria"]);
      setEsCriteria(formData["esCriteria"]);
    }
  }, [objectId, formData]);

  return (
    <NtaiPanel
      header={
        action === "add"
          ? "Add Source"
          : `Edit ${formData["sourceObject"]["sourceMin"]["displayName"]}`
      }
      width="100%"
      subheader="Edit source details - for ex. description, selection and filtering criteria."
    >
      <NtaiTabs>
        <Box label="GENERAL" sx={{ width: "100%", display: "flex" }}>
          <Box sx={{ flexBasis: "40%", display: "flex" }}>
            <NtaiFormPanel
              header="General Details"
              subheader="Select source and provide description."
              handleSave={
                _.isArray(sourceOptions) && sourceOptions.length > 0
                  ? onHandleSave
                  : null
              }
              handleCancel={handleCancel}
            >
              {(action === "edit" ||
                (action === "add" &&
                  _.isArray(sourceOptions) &&
                  sourceOptions.length > 0)) && (
                <React.Fragment>
                  <NtaiSelectField
                    name="entitySourceForm.sourceObjectUuId"
                    label="Source*"
                    options={sourceOptions}
                    isDisabled={action === "edit" ? true : false}
                    rules={{ required: "Source is required" }}
                  />
                  <NtaiTextField
                    multiline
                    name="entitySourceForm.description"
                    label="Description"
                    minRows={3}
                    maxRows={3}
                  />
                </React.Fragment>
              )}
              {action === "add" &&
                _.isArray(sourceOptions) &&
                sourceOptions.length === 0 && (
                  <NtaiEmptyMessage
                    header="No sources available"
                    subheader="Perhaps you have assigned all sources OR required Source-Object mapping is missing"
                  />
                )}
            </NtaiFormPanel>
          </Box>
          {["edit"].includes(action) && (
            <Box sx={{ flexBasis: "60%", display: "flex" }}>
              <EntitySourceCriteria
                formData={formData}
                buildQuery={buildQuery}
              />
            </Box>
          )}
        </Box>

        <Box visible={action === "edit" ? true : false} label="FILTER CRITERIA">
          <EntitySourceFilterFields
            action={action}
            formData={formData}
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </Box>
      </NtaiTabs>
    </NtaiPanel>
  );
}
