import { Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import React, { useEffect } from "react";
import { useState } from "react";
import SourceAwsLivySession from "./SourceAwsLivySession";
import { useDispatch, useSelector } from "react-redux";
import { getAwsClusters } from "app/main/pages/core/admin/aws/cluster/store/awsClustersSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { SourceContext } from "../SourceManager";
import {
  startSourceAwsLivySession,
  clearStatus,
} from "./store/sourceAwsLivySessionsSlice";
import { getSource } from "../store/sourcesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/pro-light-svg-icons";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

export default function SourceAwsLivySessionDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [sessionCreationPending, setSessionCreationPending] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector((state) => state.sources.sourcesSlice.data);
  const sourceData =
    dataMap && !_.isEmpty(dataMap) ? _.get(dataMap, sourceId) : null;

  const awsEnv =
    sourceData && !_.isEmpty(sourceData) ? sourceData["awsEnv"] : null;
  const awsLivySession =
    sourceData && !_.isEmpty(sourceData) ? sourceData["activeSession"] : null;

  const status = useSelector(
    (state) => state.sources.sourceAwsLivySessionsSlice.status
  );

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  function handleFormSubmit() {
    dispatch(
      startSourceAwsLivySession({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        formData: { actionCode: awsEnv && !awsLivySession ? 1 : 2 },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "error" &&
      status.method === "startSourceAwsLivySession"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      setSessionCreationPending(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "startSourceAwsLivySession"
    ) {
      dispatch(
        getSource({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
        })
      );
      handleDialogClose();
      setSessionCreationPending(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "pending" &&
      status.method === "startSourceAwsLivySession"
    ) {
      setSessionCreationPending(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getAwsClusters());
  }, []);

  return (
    <React.Fragment>
      {awsEnv && (
        <Button
          sx={{ fontSize: "11px" }}
          disabled={sessionCreationPending ? true : false}
          startIcon={
            sessionCreationPending ? (
              <NtaiCircularProgress size={18} />
            ) : (
              <FontAwesomeIcon
                icon={awsEnv && !awsLivySession ? faPowerOff : faCog}
                spin={awsEnv && awsLivySession && true}
              />
            )
          }
          size="small"
          onClick={() => handleFormSubmit()}
        >
          {sessionCreationPending
            ? "SESSION STARTING"
            : awsEnv && !awsLivySession
            ? "START SESSION"
            : "CLOSE SESSION"}
        </Button>
      )}

      <NtaiDialog
        title="Manage Session"
        open={open}
        handleDialogClose={handleDialogClose}
        size="sm"
      >
        <NtaiForm>
          <SourceAwsLivySession
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleDialogClose}
            sessionCreationPending={sessionCreationPending}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
