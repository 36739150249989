import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { deleteDashboard, getDashboards } from "./store/dashboardsSlice";
import {
  createDashboardWidgetField,
  deleteDashboardWidgetField,
  getDashboardWidgetFields,
  updateDashboardWidgetField,
  clearStatus,
} from "./store/dashboardWidgetFieldsSlice";
import { Drawer } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import DashboardWidgetFieldForm from "./DashboardWidgetFieldForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "fieldOrder",
    numeric: false,
    disablePadding: false,
    label: "Order",
  },

  {
    id: "fieldId",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];

const toolbarActions = [
  {
    value: "archive",
    label: "Delete",
    icon: "archive",
  },
];

export default function DashboardWidgetFieldList() {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const widgetId = useSelector(
    (state) => state.core.dashboardWidgetsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.dashboardWidgetFieldsSlice.data
  );
  const status = useSelector(
    (state) => state.core.dashboardWidgetFieldsSlice.status
  );
  const data = _.orderBy(Object.values(dataMap), ["fieldOrder", "asc"]);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDrawerOpen();
  }

  function handleDrawerOpen() {
    setOpenDrawer(true);
  }

  function handleDrawerClose() {
    setOpenDrawer(false);
    setAction(null);
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(deleteDashboardWidgetField({ widgetId: widgetId, uuId: id }));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateDashboardWidgetField({
            widgetId: widgetId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createDashboardWidgetField({ widgetId: widgetId, formData: formData })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "remove") confirmDelete();
  }

  useEffect(() => {
    dispatch(getDashboardWidgetFields(widgetId));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createDashboardWidgetField" ||
        status.method === "updateDashboardWidgetField")
    ) {
      handleDrawerClose();
      clearStatus();
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteDashboardWidgetField"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      clearStatus();
    }
  }, [status]);

  return (
    <>
      <NtaiPanel
        width="100%"
        header="Widget Fields"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      >
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleToolbarAction={handleToolbarAction}
          handleDialogAddEdit={handleDialogAddEdit}
          title="Dashboard Widget Field"
        />
      </NtaiPanel>

      <Drawer
        open={openDrawer}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        <NtaiForm>
          <DashboardWidgetFieldForm
            action={action}
            formData={action === "edit" ? _.get(dataMap, currentId) : {}}
            handleSave={handleFormSubmit}
            handleCancel={handleDrawerClose}
          />
        </NtaiForm>
      </Drawer>

      <NtaiConfirmDeleteDialog
        // items={
        //   selectedIds &&
        //   selectedIds.length &&
        //   NtaiUtils.getFieldArrayFromDashboard(selectedIds, dataMap, "name")
        // }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
