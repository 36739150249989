import SearchEdit from "./edit/SearchEdit";
import SearchListWrapper from "./SearchListWrapper";
import SearchBasicInit from "./init/basic/SearchBasicInit";
import SearchAdvInit from "./init/advanced/SearchAdvInit";
import SearchDashboardInit from "./init/dashboard/SearchDashboardInit";
import SearchAdvInitRedirect from "./init/advanced/SearchAdvInitRedirect";
import SearchSeriesImport from "./init/series/SearchSeriesImport";
import SearchSeriesImportPreview from "./init/series/SearchSeriesImportPreview";
import SearchReportInitWrapper from "./init/report/SearchReportInitWrapper";

const SearchConfig = {
  routes: [
    {
      path: "/search",
      exact: true,
      component: SearchListWrapper,
    },
    {
      path: "/search-basic-init",
      exact: true,
      component: SearchBasicInit,
    },
    {
      path: "/search-adv-init",
      exact: true,
      component: SearchAdvInit,
    },
    {
      path: "/search-dashboard-init",
      exact: true,
      component: SearchDashboardInit,
    },
    {
      path: "/search-report-init",
      exact: true,
      component: SearchReportInitWrapper,
    },
    {
      path: "/search-series-import",
      exact: true,
      component: SearchSeriesImport,
    },
    {
      path: "/search-series-import-preview",
      exact: true,
      component: SearchSeriesImportPreview,
    },
    {
      path: "/search/:id/edit",
      exact: true,
      component: SearchEdit,
    },
  ],
};

export default SearchConfig;
