import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  useTheme,
} from "@mui/material";
import NtaiInputErrorMessage from "@ntai/components/inputs/NtaiInputErrorMessage";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const NtaiSearchBarCustom = (props) => {
  const theme = useTheme();
  const { name, placeholder, width, height, background, onSearchSubmit } =
    props;

  return (
    <Box
      sx={{
        width: width ? width : "100%",
        height: height ? height : "100%",
        display: "flex",
        padding: "2px",
        alignItems: "center",
        border: "1px solid #eee",
        borderRadius: "16px",
        background: background ? background : null,
        "&:hover": {
          boxShadow: theme.colors.shadows.cardSm,
        },
      }}
    >
      <InputBase
        sx={{
          display: "flex",
          fontSize: "10px",
          flexGrow: 1,
          paddingLeft: theme.spacing(1),
        }}
        autoComplete="off"
        {...props}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearchSubmit(e.target.value);
          }
        }}
      />
      <Box sx={{ pr: theme.spacing(1) }}>
        <FontAwesomeIcon size="xs" icon={faSearch} />
      </Box>
    </Box>
  );
};

export default function NtaiSimpleSearchBar1(props) {
  const theme = useTheme();

  const { control } = useFormContext();

  return (
    <React.Fragment>
      <Controller
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <NtaiSearchBarCustom
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            inputRef={ref}
            {...props}
          />
        )}
        control={control}
        defaultValue=""
        {...props}
      />
    </React.Fragment>
  );
}
