import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createReportDefinition,
  clearStatus,
} from "./store/reportDefinitionsSlice";
import ReportDefinitionForm from "./ReportDefinitionForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";

export default function ReportDefinitionCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const reportDefinitionType = props.match.params.reportdefinitiontypeid;

  const status = useSelector(
    (state) => state.reports.reportDefinitionsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionsSlice.data
  );

  const activeId = useSelector(
    (state) => state.reports.reportDefinitionsSlice.activeId
  );

  function handleSave(formData) {
    dispatch(createReportDefinition(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createReportDefinition" &&
      status.result === "success"
    ) {
      history.push(`/reports/definitions/edit/${activeId}`);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "createReportDefinition" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, { variant: "error" });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Report Definition" back="/reports/definitions">
      <NtaiForm>
        <ReportDefinitionForm
          action="add"
          handleSave={handleSave}
          reportDefinitionType={reportDefinitionType}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
