import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiPieEChart from "./NtaiPieEChart";
import NtaiDonutEChart from "./NtaiDonutEChart";
import NtaiMiniGaugeEChart from "../gauge/NtaiMiniGaugeEChart";
import NtaiPieSeriesEChart from "./NtaiPieSeriesEChart";

const data = [
  {
    name: "USA",
    value: 9,
    total: 12,
    offset: 1,
  },
  {
    name: "India",
    value: 4,
    total: 13,
    offset: 0.3,
  },
  {
    name: "France",
    value: 8,
    total: 24,
    offset: 0.5,
  },
];

export default function NtaiTestPie() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Pie chart">
          <NtaiPieEChart
            widgetId="pie-chart"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId: {
                  name: "country",
                  fieldOrder: 1,
                  field: { uuId: "countryfieldUuId" },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    result.push(
      <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Donut chart">
          <NtaiDonutEChart
            widgetId="donut-chart"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId: {
                  name: "country",
                  fieldOrder: 1,
                  field: { uuId: "countryfieldUuId" },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    result.push(
      <Box key={3} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Pie Series chart">
          <NtaiPieSeriesEChart
            widgetId="pie-series-chart"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId: {
                  name: "country",
                  fieldOrder: 1,
                  field: { uuId: "countryfieldUuId" },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    // result.push(
    //   <Box key={3} sx={{ display: "flex", height: "100%", width: "100%" }}>
    //     <NtaiSourceWidget title="Donut chart">
    //       <NtaiMiniGaugeEChart
    //         chartId="wett"
    //         data={{ value: 75, label: "1.2k" }}
    //       />
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>

      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
