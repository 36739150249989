import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import * as React from "react";

function NtaiSnackbarCloseButton({ k }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton color="inherit" onClick={() => closeSnackbar(k)}>
      <FontAwesomeIcon size="2xs" icon={faClose} />
    </IconButton>
  );
}

export default NtaiSnackbarCloseButton;
