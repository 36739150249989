import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  deleteDashboardWidget,
  getDashboardWidgets,
} from "./store/dashboardWidgetsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "20%",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "20%",
  },
  {
    id: "stdWidgetFg",
    numeric: false,
    disablePadding: false,
    label: "Standard?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
    width: "10%",
  },
  {
    id: "displayTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Display",
  },
  {
    id: "queryTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Query",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

function isStandardWidgetPresent(selected, dataMap) {
  let present = false;
  if (_.isArray(selected)) {
    const stdWidgets = _.filter(selected, function (o) {
      const widget = dataMap[o];
      return widget["stdWidgetFg"] === 1 ? true : false;
    });

    if (stdWidgets.length > 0) present = true;
  }

  return present;
}

export default function DashboardWidgetList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector((state) => state.core.dashboardWidgetsSlice.data);
  const status = useSelector(
    (state) => state.core.dashboardWidgetsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") {
      if (isStandardWidgetPresent(selected, dataMap)) {
        enqueueSnackbar(
          `Your selection includes one or more standard widgets. Standard widgets cannot be deleted.`,
          {
            variant: "error",
          }
        );
      } else {
        setAction("delete");
        confirmDelete();
      }
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "delete") {
        dispatch(deleteDashboardWidget(id));
      }
    });

    setAction(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
    setAction(null);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getDashboardWidgets" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteDashboardWidget"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Widget(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getDashboardWidgets());
    dispatch(getCodes(CODELIST_CODES.COM_DASHBOARD_WIDGET_QUERY_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.COM_DASHBOARD_WIDGET_DISPLAY_TYPE_CODE));
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Dashboard Widgets">
        {loaded && (
          <NtaiCrudTable
            helpText="Displays standard and user defined widgets"
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/admin/dashboards/widgets`}
            handleToolbarAction={handleToolbarAction}
            title="Dashboard Widget"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
