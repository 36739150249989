import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@mui/material";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createOrDeleteAlertDefSourceObjectTopics,
} from "./store/alertDefSourceObjectTopicsSlice";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AlertDefObjectTopic({ handleDialogClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const alertDefinitionDataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );

  const definitionId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  const alertDefinitionData = _.get(alertDefinitionDataMap, definitionId);

  const objectTopicsDataMap = useSelector(
    (state) => state.core.objectTopicsSlice.data
  );

  const objectTopicsData = Object.values(objectTopicsDataMap);

  const alertDefObjectTopicsDataMap = useSelector(
    (state) => state.monitor.alertDefSourceObjectTopicsSlice.data
  );

  const status = useSelector(
    (state) => state.monitor.alertDefSourceObjectTopicsSlice.status
  );

  function handleSave() {
    const objectTopicUuIds = getValues(
      "alertDefSourceObjectTopicForm.objectTopicUuIds"
    );

    const selectedObjectTopicUuids = _.pickBy(objectTopicUuIds, (v, k) => v);

    dispatch(
      createOrDeleteAlertDefSourceObjectTopics({
        alertDefinitionId: _.get(alertDefinitionData, "uuId"),
        alertDefSourceId: _.get(alertDefinitionData, "alertDefSource.uuId"),
        formData: {
          objectTopicUuIds: !_.isEmpty(selectedObjectTopicUuids)
            ? Object.keys(selectedObjectTopicUuids)
            : [],
        },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      ["getAlertDefSourceObjectTopics"].includes(status.method) &&
      status.result === "success"
    ) {
      const result = {};
      Object.values(alertDefObjectTopicsDataMap).forEach((adotd, i) => {
        result[_.get(adotd, "objectTopic.uuId")] = true;
      });

      setValue("alertDefSourceObjectTopicForm.objectTopicUuIds", result);
      dispatch(clearStatus());
    }

    if (
      status.result === "error" &&
      status.method === "createOrDeleteAlertDefSourceObjectTopics"
    ) {
      enqueueSnackbar(`Unable to assign object topics: ${status.message}`, {
        variant: "error",
      });
      dispatch(clearStatus());
    } else if (
      status.result === "success" &&
      status.method === "createOrDeleteAlertDefSourceObjectTopics"
    ) {
      handleDialogClose();
      dispatch(clearStatus());

      enqueueSnackbar(
        `Object topics updated for alert "${alertDefinitionData["name"]}"`,
        {
          variant: "success",
        }
      );
    }
  }, [status]);

  return (
    <NtaiDialogFormPanel
      title="Assign Object Topic"
      subheader="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        }}
      >
        <NtaiSimpleSearchBar placeholder="Search Object Topics..." />

        <Box
          sx={{
            height: "240px",
            border: theme.general.border1,
            borderRadius: theme.general.borderRadiusSm,
            width: "100%",
            display: "flex",
          }}
        >
          <Scrollbar>
            <Box sx={{ display: "flex" }}>
              <List sx={{ width: "100%", p: 0 }}>
                {objectTopicsData.map((otd, i) => (
                  <Box
                    key={`alert-def-object-topic-${i}`}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <ListItem key={`alert-def-object-topic-${i}`} sx={{ p: 0 }}>
                      <ListItemButton>
                        <ListItemText
                          primary={otd.topic.name}
                          secondary={otd.objectName}
                        />
                        <ListItemSecondaryAction sx={{ pr: theme.spacing(1) }}>
                          <NtaiCheckboxField
                            name={`alertDefSourceObjectTopicForm.objectTopicUuIds.${otd.uuId}`}
                            edge="end"
                          />
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Box>
                ))}
              </List>
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </NtaiDialogFormPanel>
  );
}
