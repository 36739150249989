import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordDocuments = createAsyncThunk(
  "searchSourceRecordDocuments/getSearchSourceRecordDocuments",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.put(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/documents`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordDocument = createAsyncThunk(
  "searchSourceRecordDocuments/getSearchSourceRecordDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/documents/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecordDocument = createAsyncThunk(
  "searchSourceRecordDocuments/createSearchSourceRecordDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/documents`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecordDocument = createAsyncThunk(
  "searchSourceRecordDocuments/updateSearchSourceRecordDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId, formData } =
        values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/documents/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceRecordDocument = createAsyncThunk(
  "searchSourceRecordDocuments/deleteSearchSourceRecordDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/documents/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadSearchSourceRecordDocument = createAsyncThunk(
  "searchSourceRecordDocuments/downloadSearchSourceRecordDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, fileName, uuId } =
        values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/documents/${uuId}/download`
      );
      return { fileName: fileName, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordDocumentsSlice = createSlice({
  name: "searchSourceRecordDocuments",
  initialState: {
    data: {},
    downloadData: null,
    downloadFileName: null,
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    clearDownloadData: (state, action) => {
      state.downloadData = null;
      state.downloadFileName = null;
    },
  },
  extraReducers: {
    [getSearchSourceRecordDocuments.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "document.uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordDocuments",
      };
    },
    [getSearchSourceRecordDocuments.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordDocuments",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.document.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordDocument",
      };
    },
    [getSearchSourceRecordDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordDocument",
        message: action.payload.message,
      };
    },
    [createSearchSourceRecordDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.document.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceRecordDocument",
      };
    },
    [createSearchSourceRecordDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecordDocument",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecordDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.document.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceRecordDocument",
      };
    },
    [updateSearchSourceRecordDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecordDocument",
        message: action.payload.message,
      };
    },
    [deleteSearchSourceRecordDocument.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceRecordDocument",
      };
    },
    [deleteSearchSourceRecordDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceRecordDocument",
        message: action.payload.message,
      };
    },

    [downloadSearchSourceRecordDocument.fulfilled]: (state, action) => {
      state.downloadData = action.payload.data;
      state.downloadFileName = action.payload.fileName;
      state.status = {
        result: "success",
        method: "downloadSearchSourceRecordDocument",
      };
    },
    [downloadSearchSourceRecordDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "downloadSearchSourceRecordDocument",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, clearDownloadData } =
  searchSourceRecordDocumentsSlice.actions;

export default searchSourceRecordDocumentsSlice.reducer;
