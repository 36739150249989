import WidgetCreate from "./WidgetCreate";
import WidgetEdit from "./WidgetEdit";
import WidgetList from "./WidgetList";

const WidgetConfig = {
  routes: [
    {
      path: "/admin/widgets",
      exact: true,
      component: WidgetList,
    },
    {
      path: "/admin/widgets/create",
      exact: true,
      component: WidgetCreate,
    },
    {
      path: "/admin/widgets/:id/edit",
      exact: true,
      component: WidgetEdit,
    },
  ],
};

export default WidgetConfig;
