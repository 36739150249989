import React, { useState } from "react";
import { Box, useTheme, Typography, IconButton, Avatar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faLock, faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import {
  faChartUser,
  faFilter,
  faFilterList,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { faDatabase } from "@fortawesome/pro-light-svg-icons";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSparkRadialBarChart from "@ntai/components/charts/apexcharts/NtaiSparkRadialBarChart";
import { grey } from "@mui/material/colors";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
const _ = require("lodash");

function getSearchItemIcon(val) {
  if (val === 1) return faSearch;
  else if (val === 2) return faFilter;
  else if (val === 4) return faFilterList;
  else return faChartUser;
}

export default function SearchItemBox(props) {
  const { id, item, handleEdit, itemSelected, updateSelectedGridItems } = props;
  const theme = useTheme();
  const [selected, setSelected] = useState(itemSelected);

  function onHandleToggleSelect(itemUuId) {
    setSelected(!selected);
    updateSelectedGridItems(itemUuId);
  }

  React.useEffect(() => {
    setSelected(itemSelected ? true : false);
  }, [itemSelected]);

  return (
    <Box
      key={`ntai-search-list-item-box-${id}`}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "200px",
        minWidth: "335px",
        background: "white",
        border: selected ? theme.general.border2p : theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxHeight: "98px",
          minHeight: "54px",
          display: "flex",
          padding: theme.spacing(1),
          gap: theme.spacing(1),
          alignItems: "center",
        }}
        onClick={() => handleEdit(item["uuId"])}
      >
        <Avatar sx={{ height: 24, width: 24 }}>
          <FontAwesomeIcon
            size="xs"
            icon={getSearchItemIcon(item["typeCode"])}
          />
        </Avatar>

        <Typography fontWeight="600" variant="h5">
          {NtaiUtils.trunc(item["name"], 35)}
        </Typography>
      </Box>
      <Box
        sx={{
          maxHeight: `calc(100% - 78px)`,
          minHeight: `calc(100% - 98px)`,
          display: "flex",
          padding: theme.spacing(1),
          pt: "0px",
          px: "10px",
          pb: "4px",
          gap: theme.spacing(1),
        }}
        onClick={() => handleEdit(item["uuId"])}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="caption">
            Last Accessed: {NtaiUtils.formatDateCol(item.dateModified)}
          </Typography>

          <Typography variant="caption" fontWeight="700">
            Modified By: {item.userModified}
          </Typography>
          <Typography sx={{ pt: theme.spacing(0.5) }} variant="subtitle2">
            {NtaiUtils.trunc(item.description, 150)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderTop: theme.general.border1,
          background: grey[50],
          justifyContent: "space-between",
          px: theme.spacing(1),
          cursor: "default",
        }}
      >
        <Box
          sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
        >
          <FontAwesomeIcon icon={faDatabase} />
          <Typography variant="subtitle2" color="inherit">
            {_.isArray(item["sourceMins"]) && item["sourceMins"].length > 0
              ? item["sourceMins"][0]["abbrev"]
              : "NA"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1.5),
            alignItems: "center",
          }}
        >
          {item["favoriteFg"] && item["favoriteFg"] === 1 && (
            <FontAwesomeIcon icon={faStar} color="orange" />
          )}

          {item["lockedFg"] && item["lockedFg"] === 1 ? (
            <FontAwesomeIcon icon={faLock} color="red" />
          ) : null}

          <IconButton
            sx={{
              height: "24px",
              width: "24px",
              borderRadius: theme.general.borderRadiusSm,
              cursor: "default",
            }}
            onClick={() => onHandleToggleSelect(item["uuId"])}
          >
            <FontAwesomeIcon
              size="2xs"
              icon={selected ? faCheckSquare : faSquare}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
