import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import EntityTopic from "./topic/EntityTopic";
import EntityGeneral from "./general/EntityGeneral";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import EntityAccessWrapper from "./access/EntityAccessWrapper";
import EntitySourceList from "./source/EntitySourceList";
const _ = require("lodash");

function generateFieldNumNameObj(obj) {
  let fieldNumNameObj = {};
  const fields = _.get(obj, "objectFields");
  fields.forEach((field, index) => {
    fieldNumNameObj[_.get(field, "fieldName")] = _.get(field, "fieldNum");
  });

  return fieldNumNameObj;
}

export default function EntityForm({ action, formData, handleSave, object }) {
  const { getValues, reset } = useFormContext();

  const theme = useTheme();

  function handleCancel() {
    history.push(
      `/entities-library/domains/${_.get(object, "objectUuId")}/objects/${_.get(
        object,
        "uuId"
      )}/entities`
    );
  }

  return (
    <NtaiTabs>
      <Box label="DETAILS">
        <EntityGeneral
          object={object}
          action={action}
          formData={action === "edit" ? formData : {}}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </Box>
      <Box label="TOPICS">
        <EntityTopic handleCancel={handleCancel} />
      </Box>
      <Box label="SOURCES">
        <EntitySourceList handleCancel={handleCancel} />
      </Box>
      {/* <Box label="ACCESS">
        <EntityAccessWrapper handleCancel={handleCancel} />
      </Box> */}
    </NtaiTabs>
  );
}
