import SourceUserWidgetCreate from "./SourceUserWidgetCreate";
import SourceUserWidgetEdit from "./SourceUserWidgetEdit";
import SourceUserWidgetList from "./SourceUserWidgetList";

const SourceUserWidgetConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/user-widgets",
      exact: true,
      component: SourceUserWidgetList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/user-widgets/create",
      exact: true,
      component: SourceUserWidgetCreate,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/user-widgets/:id/edit",
      exact: true,
      component: SourceUserWidgetEdit,
    },
  ],
};

export default SourceUserWidgetConfig;
