import React from "react";
import {
  Badge,
  Box,
  Checkbox,
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { styled, withStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAltEdit,
  faNote,
  faTasks,
} from "@fortawesome/pro-light-svg-icons";

import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import { blue } from "@mui/material/colors";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";

const _ = require("lodash");

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

export default function SearchSummarySourceRecordListTemplate(props) {
  const {
    id,
    sourceAbbrev,
    assessmentFg,
    numOfActions,
    numOfNotes,
    avatarText,
    primaryText,
    secondaryText,
    tertiaryText,
    handleClick,
    label,
    imgUrl,
    handleLookup,
  } = props;
  const theme = useTheme();

  const [imgData, setImgData] = React.useState({});
  const [imgLoaded, setImgLoaded] = React.useState(false);

  function generateSecondaryLabels(secLabels) {
    let secondaryArr = [];
    secLabels.forEach((secLabel) => {
      secondaryArr.push(secLabel.label.concat(": ").concat(secLabel.value));
    });
    return secondaryArr.join(" | ");
  }

  function generateTags(tags) {
    let tagsArr = [];
    tags.forEach((tag) => {
      tagsArr.push(
        <NtaiBadge title={tag.value} fg={tag.fgColor} bg={tag.bgColor} />
      );
    });
    return tagsArr;
  }

  function generateText(text) {
    let result = null;
    if (text.lookupLinkUuId) {
      let resultArr = [];
      if (_.isArray(text.value)) {
        text.value.forEach((v, i) => {
          resultArr.push(
            <Link
              id={`record-link`}
              key={`record-link-${text.lookupLinkUuId}-${i}`}
              color={blue[700]}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={(e) =>
                handleLookup(e, text.lookupLinkUuId, v, text.recordId)
              }
            >
              {v}
            </Link>
          );
        });
      } else {
        resultArr.push(
          <Link
            color={blue[700]}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={(e) =>
              handleLookup(e, text.lookupLinkUuId, text.value, text.recordId)
            }
          >
            {text.value}
          </Link>
        );
      }

      result = resultArr;
    } else {
      let resultText = null;
      if (_.isArray(text.value)) {
        resultText = NtaiUtils.trunc(_.join(text.value, ", "), 250);
      } else {
        resultText = NtaiUtils.trunc(text.value, 250);
      }

      result = resultText;
    }

    return result;
  }

  return (
    <>
      <ListItem
        id={`list-item`}
        key={`list-item-${id}`}
        onClick={(e) => handleClick(e, id)}
        sx={{
          width: "100%",
          "&:hover": {
            cursor: "default",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: theme.spacing(2),
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ListItemAvatar>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1.5),
                }}
              >
                <NtaiBadge title={NtaiUtils.trunc(sourceAbbrev, 10)} />
                <Badge
                  badgeContent={assessmentFg ? 1 : 0}
                  color="primary"
                  variant="dot"
                >
                  <FontAwesomeIcon
                    style={{ color: assessmentFg ? "default" : null }}
                    icon={faCommentAltEdit}
                  />
                </Badge>

                <Badge
                  badgeContent={numOfActions}
                  color="success"
                  variant="dot"
                  // bg={theme.colors.secondary.main}
                >
                  <FontAwesomeIcon icon={faTasks} />
                </Badge>

                <Badge
                  badgeContent={numOfNotes}
                  variant="dot"
                  color="secondary"
                  // bg={theme.colors.secondary.lighter}
                >
                  <FontAwesomeIcon icon={faNote} />
                </Badge>
              </Box>
            </ListItemAvatar>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  fontWeight="700"
                >
                  {generateText(primaryText)}
                </Typography>
              }
              secondary={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(0.5),
                  }}
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    {generateText(secondaryText)}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {generateText(tertiaryText)}
                  </Typography>
                  {/* {imgUrl && imgLoaded && (
                <img
                  src={`data:image/jpeg;base64,${imgData}`}
                  width="200px"
                  height="auto"
                />
              )} */}
                  {label && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(0.5),
                        pb: theme.spacing(1),
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="secondary.dark"
                        fontWeight="300"
                      >
                        {label ? label.value : "Not Available"}
                      </Typography>
                      <Typography variant="subtitle2">
                        {generateSecondaryLabels(
                          label &&
                            Array.isArray(_.get(label, "secondaryLabels"))
                            ? _.get(label, "secondaryLabels")
                            : []
                        )}
                      </Typography>
                      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
                        {generateTags(
                          label && Array.isArray(_.get(label, "tags"))
                            ? _.get(label, "tags")
                            : []
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              }
            />
          </Box>
        </Box>
      </ListItem>

      <Divider />
    </>
  );
}
