import { Box, Button, Chip, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import ReportSaveDialog from "./save/ReportSaveDialog";
import ReportScheduleDialog from "./schedule/ReportScheduleDialog";
import ReportShareDialog from "./share/ReportShareDialog";
import ReportDownload from "./download/ReportDownload";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus as clearReportDefinitionStatus,
  getReportDefinition,
} from "../../admin/definition/store/reportDefinitionsSlice";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import ReportCriteria from "./criteria/ReportCriteria";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  createReport,
  getReport,
  clearStatus,
  updateReport,
} from "../store/reportsSlice";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import History from "@ntai/@history/@history";
import Scrollbar from "@ntai/components/Scrollbar";
import ReportViewer from "./viewer/ReportViewer";
import ReportViewer2 from "./viewer/ReportViewer2";
const _ = require("lodash");

export const ReportContext = React.createContext();

export default function ReportMain(props) {
  const { action, reportDefinitionId, reportId } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [reportDataLoaded, setReportDataLoaded] = useState(false);
  const [reportDefinition, setReportDefinition] = useState({});
  const [sourceCriteria, setSourceCriteria] = useState();
  const [esCriteria, setEsCriteria] = useState();
  const [generateReportCounter, setGenerateReportCounter] = useState(0);
  const [reportFormData, setReportFormData] = useState();
  const [selectedSourceSeriesId, setSelectedSourceSeriesId] = useState();
  const [selectedSourceDatasetId, setSelectedSourceDatasetId] = useState();

  const reportDefinitionDataMap = useSelector(
    (state) => state.reports.reportDefinitionsSlice.data
  );

  const reportDefinitionStatus = useSelector(
    (state) => state.reports.reportDefinitionsSlice.status
  );

  const reportDefinitionData = reportDefinitionDataMap
    ? reportDefinitionDataMap[reportDefinitionId]
    : null;

  const reportDataMap = useSelector((state) => state.reports.reportsSlice.data);
  const activeId = useSelector((state) => state.reports.reportsSlice.activeId);

  const status = useSelector((state) => state.reports.reportsSlice.status);

  const reportData = reportDataMap ? reportDataMap[reportId] : null;

  function handleFormSubmit(formData) {
    if (action === "add") {
      dispatch(
        createReport({
          groupId: "*",
          formData: formData,
        })
      );
    } else {
      dispatch(
        updateReport({
          groupId: "*",
          uuId: reportId,
          formData: formData,
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createReport" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      History.push(`/reports/${reportDefinitionId}/reports/${activeId}`);
    }

    if (
      status &&
      status.method === "getReport" &&
      status.result === "success"
    ) {
      setReportFormData({ ...reportData });
      dispatch(clearStatus());
      setReportDataLoaded(true);
      setSelectedSourceSeriesId(reportData["sourceSeriesUuId"]);
      setSelectedSourceDatasetId(reportData["sourceDomainUuId"]);
    }
  }, [status]);

  useEffect(() => {
    if (
      reportDefinitionStatus &&
      reportDefinitionStatus.method === "getReportDefinition" &&
      reportDefinitionStatus.result === "success"
    ) {
      const tmpData = _.cloneDeep(reportDefinitionData);

      setReportDefinition({ ...reportDefinitionData });
      dispatch(clearReportDefinitionStatus());
    }
  }, [reportDefinitionStatus]);

  useEffect(() => {
    if (!_.isEmpty(reportDefinition)) setLoaded(true);
  }, [reportDefinition]);

  useEffect(() => {
    if (reportDefinitionId) dispatch(getReportDefinition(reportDefinitionId));
  }, [reportDefinitionId]);

  useEffect(() => {
    if (action === "edit" && reportId)
      dispatch(
        getReport({
          groupId: "*",
          uuId: reportId,
        })
      );
  }, [reportId]);

  return (
    <React.Fragment>
      {loaded && _.has(reportDefinition, "uuId") && (
        <>
          <ReportContext.Provider
            value={{
              action,
              reportDefinition,
              reportId,
              sourceCriteria,
              setSourceCriteria,
              esCriteria,
              setEsCriteria,
              generateReportCounter,
              setGenerateReportCounter,
              reportFormData,
              setReportFormData,
              selectedSourceSeriesId,
              setSelectedSourceSeriesId,
              selectedSourceDatasetId,
              setSelectedSourceDatasetId,
            }}
          >
            <NtaiForm>
              <NtaiPage
                title={
                  action === "add"
                    ? `New ${reportDefinitionData.name}`
                    : _.get(reportData, "name")
                }
                titleExtras={
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                    }}
                  >
                    {
                      <Chip
                        size="small"
                        label={
                          <Typography variant="subtitle2" fontWeight="700">
                            {_.get(
                              reportDefinition,
                              "reportDefinitionGroupObject.reportDefinitionGroupName"
                            )}
                          </Typography>
                        }
                      />
                    }
                    {action === "add" && (
                      <Chip
                        size="small"
                        variant="outlined"
                        label={
                          <Typography variant="subtitle2">
                            Save Pending
                          </Typography>
                        }
                      />
                    )}
                  </Box>
                }
                back="/reports"
                headerActions={
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                    }}
                  >
                    {action === "edit" && <ReportScheduleDialog />}
                    {/* {action === "edit" && <ReportShareDialog />} */}
                    <ReportSaveDialog handleFormSubmit={handleFormSubmit} />
                    {/* <ReportDownload /> */}
                  </Box>
                }
              >
                <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
                  <Box
                    sx={{
                      flexBasis:
                        reportDefinition.typeCode === 1
                          ? reportDefinition.criteriaTypeCode === 1
                            ? "20%"
                            : "40%"
                          : "25%",
                      display: "flex",
                      flexDirection: "column",
                      borderRight: theme.general.border1,
                    }}
                  >
                    {loaded &&
                      (action === "add" ||
                        (action === "edit" && reportFormData)) && (
                        <ReportCriteria />
                      )}
                  </Box>

                  <Box
                    sx={{
                      height: "100%",
                      flexBasis:
                        reportDefinition.typeCode === 1
                          ? reportDefinition.criteriaTypeCode === 1
                            ? "80%"
                            : "60%"
                          : "75%",
                      display: "flex",
                      flexDirection: "column",
                      background: grey[50],
                    }}
                  >
                    <Scrollbar>
                      <ReportViewer2 />
                    </Scrollbar>
                  </Box>
                </Box>
              </NtaiPage>
            </NtaiForm>
          </ReportContext.Provider>
        </>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </React.Fragment>
  );
}
