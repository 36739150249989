import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordListings = createAsyncThunk(
  "sourceRecordListings/getSourceRecordListings",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceRecordListing = createAsyncThunk(
  "sourceRecordListings/getSourceRecordListing",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordListingId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordListing = createAsyncThunk(
  "sourceRecordListings/createSourceRecordListing",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordListing = createAsyncThunk(
  "sourceRecordListings/updateSourceRecordListing",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordListing = createAsyncThunk(
  "sourceRecordListings/deleteSourceRecordListing",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordListingsSlice = createSlice({
  name: "sourceRecordListings",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordListings.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceRecordListings" };
    },
    [getSourceRecordListings.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordListings",
        message: action.payload.message,
      };
    },
    [getSourceRecordListing.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceRecordListing" };
    },
    [getSourceRecordListing.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordListing",
        message: action.payload.message,
      };
    },
    [createSourceRecordListing.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordListing",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceRecordListing.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordListing",
        message: action.payload.message,
      };
    },
    [updateSourceRecordListing.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordListing",
      };
    },
    [updateSourceRecordListing.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordListing",
        message: action.payload.message,
      };
    },
    [deleteSourceRecordListing.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordListing",
      };
    },
    [deleteSourceRecordListing.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordListing",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceRecordListingsSlice.actions;

export default sourceRecordListingsSlice.reducer;
