import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlertNavigation = createAsyncThunk(
  "alertNavigations/getAlertNavigation",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get(`/monitor/alerts-navigation`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertNavigationsSlice = createSlice({
  name: "alertNavigations",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertNavigation.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getAlertNavigation" };
    },
    [getAlertNavigation.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertNavigation",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = alertNavigationsSlice.actions;

export default alertNavigationsSlice.reducer;
