import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getContactGroups = createAsyncThunk(
  "contactGroup/getContactGroups",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/library/contact-groups");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContactGroup = createAsyncThunk(
  "contactGroup/getContactGroup",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/library/contact-groups/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createContactGroup = createAsyncThunk(
  "contactGroup/createContactGroup",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/library/contact-groups", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateContactGroup = createAsyncThunk(
  "contactGroup/updateContactGroup",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/library/contact-groups/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateContactGroupObjects = createAsyncThunk(
  "contactGroup/updateContactGroupObjects",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/library/contact-groups/${uuId}/contacts`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteContactGroup = createAsyncThunk(
  "contactGroup/deleteContactGroup",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/library/contact-groups/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const contactGroupsSlice = createSlice({
  name: "contactGroups",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getContactGroups.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getContactGroups" };
    },
    [getContactGroups.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getContactGroups",
        message: action.payload.message,
      };
    },
    [getContactGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getContactGroup" };
    },
    [getContactGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getContactGroup",
        message: action.payload.message,
      };
    },
    [createContactGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createContactGroup" };
    },
    [createContactGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createContactGroup",
        message: action.payload.message,
      };
    },
    [updateContactGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateContactGroup" };
    },
    [updateContactGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateContactGroup",
        message: action.payload.message,
      };
    },

    [updateContactGroupObjects.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateContactGroupObjects" };
    },
    [updateContactGroupObjects.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateContactGroupObjects",
        message: action.payload.message,
      };
    },

    [deleteContactGroup.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteContactGroup" };
    },
    [deleteContactGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteContactGroup",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = contactGroupsSlice.actions;

export default contactGroupsSlice.reducer;
