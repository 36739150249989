import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import SourcePolicyDefFields from "./SourcePolicyDefFields";
const _ = require("lodash");

export default function SourcePolicyDefForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedFields, setSelectedFields] = React.useState(
    action === "add" ? [] : formData["sourcePolicyDefinitionFields"]
  );

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);
  const sourcePolicyDefTypeOptions =
    codelistCodes &&
    _.has(codelistCodes, CODELIST_CODES.SRC_SOURCE_POLICY_DEF_TYPE_CODE)
      ? _.get(codelistCodes, CODELIST_CODES.SRC_SOURCE_POLICY_DEF_TYPE_CODE)
      : [];

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = action === "add" ? null : formData["uuId"];

  const dataMap = useSelector(
    (state) => state.sources.sourcePolicyDefsSlice.data
  );

  const data = _.get(dataMap, activeId);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourcePolicyDefForm")
    );

    if (sanitizedFormData) {
      sanitizedFormData["sourcePolicyDefinitionFields"] = selectedFields;
      handleFormSubmit(sanitizedFormData);
    }
  }

  function handleFieldsUpdate(tmpFields) {
    setSelectedFields([...tmpFields]);
  }

  useEffect(() => {
    const existingFields =
      action === "edit" && data && _.has(data, "sourcePolicyDefinitionFields")
        ? data["sourcePolicyDefinitionFields"]
        : [];

    if (!_.isEqual(selectedFields, existingFields)) {
      onHandleSave();
    }
  }, [selectedFields]);

  useEffect(() => {
    reset({
      sourcePolicyDefForm: action === "edit" ? formData : null,
    });
  }, [formData]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_POLICY_DEF_TYPE_CODE));
  }, []);

  return (
    <NtaiFormPanel
      padding="0px"
      width="80%"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box sx={{ display: "flex", alignItems: "start", gap: theme.spacing(4) }}>
        <NtaiPanel
          padding="0px"
          width="40%"
          header="General Details"
          subheader="Enter name, description and select the policy type."
        >
          <NtaiTextField
            name="sourcePolicyDefForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />

          <NtaiTextField
            name="sourcePolicyDefForm.description"
            label="Description"
            multiline
            minRows={3}
            maxRows={3}
          />
          <NtaiSelectField
            options={sourcePolicyDefTypeOptions}
            label="Type*"
            name="sourcePolicyDefForm.typeCode"
            rules={{ required: "Type is required" }}
          />
        </NtaiPanel>
        <NtaiPanel
          padding="0px"
          width="60%"
          header="Fields"
          subheader="Select source fields for records grouping. Records in a group have the same assessment data unless overriden by the user."
        >
          <SourcePolicyDefFields
            action={action}
            handleFieldsUpdate={handleFieldsUpdate}
            sourcePolicyDefId={formData["uuId"]}
          />
        </NtaiPanel>
      </Box>
    </NtaiFormPanel>
  );
}
