import React, { useEffect } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function DashboardWidgetFieldForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();

  const { getValues, setValue, reset } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("dashboardWidgetFieldForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ dashboardWidgetFieldForm: action === "add" ? {} : formData });
  }, []);

  return (
    <NtaiFormPanel
      width="520px"
      header={
        action === "add"
          ? "Add Widget Field"
          : `Edit ${_.get(formData, "fieldId")}`
      }
      subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
      headerActions={
        <Box sx={{ display: "flex", alignItems: "start" }}>
          <IconButton onClick={handleCancel}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Box>
      }
    >
      <NtaiTextField
        name="dashboardWidgetFieldForm.fieldId"
        label="Field Id"
        placeholder="Field Id"
      />

      <NtaiTextField
        name="dashboardWidgetFieldForm.fieldOrder"
        label="Field Order"
        placeholder="Field Order"
        type="number"
      />

      <NtaiTextField
        name="dashboardWidgetFieldForm.fieldLabel"
        label="Field Label"
        placeholder="Field Label"
      />

      <NtaiTextField
        name="dashboardWidgetFieldForm.description"
        label="Description"
        placeholder="Description"
        multiline
        minRows={2}
        maxRows={2}
      />
    </NtaiFormPanel>
  );
}
