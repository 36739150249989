import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiLinearTreeEChart from "./NtaiLinearTreeEChart";
import NtaiRadialTreeEChart from "./NtaiRadialTreeEChart";

const data = [
  {
    name: "regions",
    children: [
      {
        value: 12,
        name: "Asia",
      },
      {
        value: 1,
        name: "America",
        children: [
          {
            value: 2,
            name: "California",
          },
          {
            value: 5,
            name: "Kansas",
          },
          {
            value: 7,
            name: "Jersey",
          },
        ],
      },
      {
        value: 1,
        name: "Africa",
      },
      {
        value: 1,
        name: "Europe",
      },
      {
        value: 1,
        name: "Australia",
      },
    ],
  },
];

export default function NtaiTestTree() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Linear Tree">
          <NtaiLinearTreeEChart
            widgetId="linear-tree"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fielduuid1: {
                  fieldOrder: 1,
                  field: {
                    uuId: "countryuuid",
                    label: "Country",
                  },
                },
                fielduuid2: {
                  fieldOrder: 2,
                  field: {
                    uuId: "stateuuid",
                    label: "State",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    result.push(
      <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Radial Tree">
          <NtaiRadialTreeEChart
            widgetId="radial-tree"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fielduuid1: {
                  fieldOrder: 1,
                  field: {
                    uuId: "countryuuid",
                    label: "Country",
                  },
                },
                fielduuid2: {
                  fieldOrder: 2,
                  field: {
                    uuId: "stateuuid",
                    label: "State",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
