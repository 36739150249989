import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSourceInStoreFileFields,
  updateSourceInStoreFileAllFields,
} from "../store/sourceInStoreFileFieldsSlice";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useFormContext } from "react-hook-form";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import Scrollbar from "@ntai/components/Scrollbar";
import { grey } from "@mui/material/colors";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SourceInCsvColumns(props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [loaded, setLoaded] = useState(false);

  const { sourceInStoreFileId, handleCancel } = props;

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceInId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const dataTypeOptions = codes
    ? _.get(codes, CODELIST_CODES.SRC_SOURCE_FIELD_DATA_TYPE_CODE)
    : [];

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreFileFieldsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceInStoreFileFieldsSlice.status
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function onHandleSave() {
    let fieldsFormData = [];
    const originalFieldsFormData = getValues("sourceInCsvFileFieldForm");

    Object.values(originalFieldsFormData).forEach((o, i) => {
      fieldsFormData.push({
        ...o,
        dataTypeCode: _.isNumber(o["dataTypeCode"])
          ? o["dataTypeCode"]
          : _.get(o["dataTypeCode"], "value"),
      });
    });

    dispatch(
      updateSourceInStoreFileAllFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
        sourceInFileId: sourceInStoreFileId,
        formData: {
          items: fieldsFormData,
        },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceInStoreFileFields" &&
      status.result === "success"
    ) {
      setLoaded(true);
      const fields = { ..._.mapKeys(data, "uuId") };
      setValue("sourceInCsvFileFieldForm", fields);
      dispatch(clearStatus());
      //   reset({ sourceInCsvFileFieldForm: fields });
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "updateSourceInStoreFileAllFields"
    ) {
      enqueueSnackbar("Successfully updated fields", {
        variant: "success",
      });
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceInStoreFileFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
        sourceInFileId: sourceInStoreFileId,
      })
    );
  }, [sourceInStoreFileId]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FIELD_DATA_TYPE_CODE));
  }, []);

  return (
    <React.Fragment>
      {_.get(codes, CODELIST_CODES.SRC_SOURCE_FIELD_DATA_TYPE_CODE) &&
        loaded && (
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  p: theme.spacing(2),
                  display: "flex",
                  height: "calc(100% - 54px)",
                  width: "100%",
                }}
              >
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            px: theme.spacing(1),
                            background: grey[100],
                            color: "default",
                          }}
                          width="40%"
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            px: theme.spacing(1),
                            background: grey[100],
                            color: "default",
                          }}
                          width="20%"
                        >
                          Order
                        </TableCell>
                        <TableCell
                          sx={{
                            px: theme.spacing(1),
                            background: grey[100],
                            color: "default",
                          }}
                        >
                          Data Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.map((o, i) => (
                          <TableRow key={`source-in-csv-file-field-${i}`}>
                            <TableCell
                              sx={{ px: theme.spacing(1), fontWeight: "700" }}
                            >
                              {o["name"]}
                            </TableCell>
                            <TableCell sx={{ px: theme.spacing(1) }}>
                              {o["fieldOrder"]}
                            </TableCell>
                            <TableCell sx={{ px: theme.spacing(1) }}>
                              <NtaiSelectField
                                name={`sourceInCsvFileFieldForm.${o["uuId"]}.dataTypeCode`}
                                options={dataTypeOptions}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  p: theme.spacing(1),
                  borderTop: theme.general.border1,
                  alignItems: "center",
                  height: "54px",
                  display: "flex",
                  justifyContent: "end",
                  gap: theme.spacing(1),
                }}
              >
                <Button size="small" onClick={handleCancel}>
                  CANCEL
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => onHandleSave()}
                >
                  SAVE
                </Button>
              </Box>
            </Box>
          </Scrollbar>
        )}
      {(!loaded ||
        !_.get(codes, CODELIST_CODES.SRC_SOURCE_FIELD_DATA_TYPE_CODE)) && (
        <NtaiCircularProgress />
      )}
    </React.Fragment>
  );
}
