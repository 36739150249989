import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getThesauruses = createAsyncThunk(
  "thesauruses/getThesauruses",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get(`/library/thesauruses`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getThesaurus = createAsyncThunk(
  "thesauruses/getThesaurus",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/library/thesauruses/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createThesaurus = createAsyncThunk(
  "thesauruses/createThesaurus",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(`/library/thesauruses`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateThesaurus = createAsyncThunk(
  "thesauruses/updateThesaurus",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/library/thesauruses/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteThesaurus = createAsyncThunk(
  "thesauruses/deleteThesaurus",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/library/thesauruses/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const startOrTerminateThesaurusAwsSession = createAsyncThunk(
  "thesauruses/startOrTerminateThesaurusAwsSession",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, formData } = values;
      const response = await server.post(
        `/library/thesauruses/${thesaurusId}/start-terminate-aws-session`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const thesaurusesSlice = createSlice({
  name: "thesauruses",
  initialState: {
    data: {},
    activeId: null,
    status: {},
    defaultDomainId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setThesaurusDefaultDomainId: (state, action) => {
      state.defaultDomainId = action.payload;
    },
  },
  extraReducers: {
    [getThesauruses.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getThesauruses" };
    },
    [getThesauruses.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesauruses",
        mesesage: action.payload.mesesage,
      };
    },
    [getThesaurus.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getThesaurus" };
    },
    [getThesaurus.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurus",
        mesesage: action.payload,
      };
    },
    [createThesaurus.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createThesaurus" };
      state.activeId = action.payload.uuId;
    },
    [createThesaurus.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createThesaurus",
        mesesage: action.payload.mesesage,
      };
    },
    [updateThesaurus.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateThesaurus" };
    },
    [updateThesaurus.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateThesaurus",
        mesesage: action.payload.mesesage,
      };
    },
    [deleteThesaurus.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteThesaurus" };
    },
    [deleteThesaurus.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteThesaurus",
        mesesage: action.payload.mesesage,
      };
    },

    [startOrTerminateThesaurusAwsSession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "startOrTerminateThesaurusAwsSession",
      };
    },
    [startOrTerminateThesaurusAwsSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "startOrTerminateThesaurusAwsSession",
        mesesage: action.payload,
      };
    },
    [startOrTerminateThesaurusAwsSession.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "startOrTerminateThesaurusAwsSession",
        mesesage: action.payload,
      };
    },
  },
});

export const { clearStatus, setActiveId, setThesaurusDefaultDomainId } =
  thesaurusesSlice.actions;

export default thesaurusesSlice.reducer;
