import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../../../../../../http/ntaiServer";
const _ = require("lodash");

export const getObjectFields = createAsyncThunk(
  "objectFields/getObjectFields",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectField = createAsyncThunk(
  "objectFields/getObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/fields//${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectField = createAsyncThunk(
  "objectFields/createObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectField = createAsyncThunk(
  "objectFields/updateObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectField = createAsyncThunk(
  "objectFields/deleteObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/fields/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectFieldsSlice = createSlice({
  name: "objectFields",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjectFields" };
    },
    [getObjectFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getobjectFields",
        message: action.payload.message,
      };
    },
    [getObjectField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getObjectField" };
    },
    [getObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectField",
        message: action.payload.message,
      };
    },
    [createObjectField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createObjectField" };
    },
    [createObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectField",
        message: action.payload.message,
      };
    },
    [updateObjectField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateObjectField" };
    },
    [updateObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectField",
        message: action.payload.message,
      };
    },
    [deleteObjectField.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteObjectField" };
    },
    [deleteObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = objectFieldsSlice.actions;

export default objectFieldsSlice.reducer;
