import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  clearStatus,
  createRoleResource,
  deleteRoleResource,
  getRoleResources,
} from "../store/roleResourcesSlice";
import {
  getResources,
  clearStatus as clearResourcesSliceStatus,
} from "../../../resource/store/resourcesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.resource.uuId]["resourceLabel"],
        secondary: allObjectsDataMap[f.resource.uuId]["description"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["resourceLabel"], ["asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.resource.uuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function RolePermissions() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const roleId = useSelector((state) => state.core.rolesSlice.activeId);

  const resourcesDataMap = useSelector(
    (state) => state.core.resourcesSlice.data
  );

  const resourcesSliceStatus = useSelector(
    (state) => state.core.resourcesSlice.status
  );

  const dataMap = useSelector((state) => state.core.roleResourcesSlice.data);

  const status = useSelector((state) => state.core.roleResourcesSlice.status);

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const resourcesData =
    resourcesDataMap && !_.isEmpty(resourcesDataMap)
      ? Object.values(resourcesDataMap)
      : [];

  function handleAdd(uuId) {
    if (_.filter(data, { "resource.uuId": uuId }).length === 0) {
      dispatch(
        createRoleResource({
          roleId: roleId,
          formData: {
            typeCode: 1,
            resourceDataUuIds: [uuId],
          },
        })
      );
    }
  }

  function handleRemove(uuId) {
    if (_.filter(data, { "resource.uuId": uuId }).length > 0) {
      dispatch(
        deleteRoleResource({
          roleId: roleId,
          uuId: uuId,
        })
      );
    }
  }

  function handleAddAll() {
    const availableResourceUuIds = availableObjects.map((o, i) => o["uuId"]);
    if (availableResourceUuIds.length > 0) {
      dispatch(
        createRoleResource({
          roleId: roleId,
          formData: {
            typeCode: 1,
            resourceDataUuIds: [...availableResourceUuIds],
          },
        })
      );
    }
  }

  function handleRemoveAll() {
    data.forEach((o, i) => {
      dispatch(
        deleteRoleResource({
          roleId: roleId,
          uuId: o["uuId"],
        })
      );
    });
  }

  useEffect(() => {
    if (
      status &&
      ["getRoleResources", "createRoleResource", "deleteRoleResource"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setAvailableObjects([...transformAvailableObjects(data, resourcesData)]);
      setSelectedObjects([...transformSelectedObjects(data, resourcesDataMap)]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      resourcesSliceStatus &&
      resourcesSliceStatus.result === "success" &&
      resourcesSliceStatus.method === "getResources"
    ) {
      dispatch(
        getRoleResources({
          roleId: roleId,
          params: {
            typeCode: 1,
          },
        })
      );
      dispatch(clearResourcesSliceStatus());
    }
  }, [resourcesSliceStatus]);

  useEffect(() => {
    dispatch(getResources());
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Resource Policies"
      subheader="Ensure users can only access permitted resources and perform actions allowed by their role"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Policies Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleRemoveAll()}
            >
              REMOVE ALL
            </Button>
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleAddAll()}
            >
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["resourceLabel"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.resourceLabel}
                              </Typography>
                            }
                            secondary={fd.description}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No resources available"
                subheader="You have assigned all resources"
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No resources assigned"
                subheader="Add resources from left panel to the role"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
