import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getDashboardSourceViews = createAsyncThunk(
  "dashboardSourceViews/getDashboardSourceViews",
  async (values) => {
    const { sourceDefId, sourceId, params } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/views`,
      {
        params: params,
      }
    );
    return response.data;
  }
);

export const getDashboardSourceWidgets = createAsyncThunk(
  "dashboardSourceViews/getDashboardSourceWidgets",
  async (values) => {
    const { sourceDefId, sourceId } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/*/widgets`
    );
    return response.data;
  }
);

export const dashboardSourceViewsSlice = createSlice({
  name: "dashboardSourceViews",
  initialState: {
    data: {},
    widgets: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDashboardSourceViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getDashboardSourceViews",
      };
    },
    [getDashboardSourceViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboardSourceViews",
        message: action.payload.message,
      };
    },
    [getDashboardSourceWidgets.fulfilled]: (state, action) => {
      state.widgets = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getDashboardSourceWidgets",
      };
    },
    [getDashboardSourceWidgets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboardSourceWidgets",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = dashboardSourceViewsSlice.actions;

export default dashboardSourceViewsSlice.reducer;
