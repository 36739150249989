import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceLinkedSourceLinks = createAsyncThunk(
  "sourceLinkedSourceLinks/getSourceLinkedSourceLinks",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, linkedSourceId, linkedSourceFieldId } =
        values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${linkedSourceFieldId}/links`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceLinkedSourceLink = createAsyncThunk(
  "sourceLinkedSourceLinks/getSourceLinkedSourceLink",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        linkedSourceId,
        linkedSourceFieldId,
        uuId,
      } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${linkedSourceFieldId}/links/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceLinkedSourceLink = createAsyncThunk(
  "sourceLinkedSourceLinks/createSourceLinkedSourceLink",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        linkedSourceId,
        linkedSourceFieldId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${linkedSourceFieldId}/links`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceLinkedSourceLink = createAsyncThunk(
  "sourceLinkedSourceLinks/updateSourceLinkedSourceLink",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        linkedSourceId,
        linkedSourceFieldId,
        uuId,
        formData,
      } = values;

      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${linkedSourceFieldId}/links/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceLinkedSourceLink = createAsyncThunk(
  "sourceLinkedSourceLinks/deleteSourceLinkedSourceLink",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        linkedSourceId,
        linkedSourceFieldId,
        uuId,
      } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${linkedSourceFieldId}/links/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceLinkedSourceLinksSlice = createSlice({
  name: "sourceLinkedSourceLinks",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceLinkedSourceLinks.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceLinkedSourceLinks",
      };
    },
    [getSourceLinkedSourceLinks.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceLinkedSourceLinks",
        message: action.payload.message,
      };
    },
    [getSourceLinkedSourceLink.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceLinkedSourceLink" };
    },
    [getSourceLinkedSourceLink.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceLinkedSourceLink",
        message: action.payload.message,
      };
    },
    [createSourceLinkedSourceLink.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceLinkedSourceLink",
      };
    },
    [createSourceLinkedSourceLink.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceLinkedSourceLink",
        message: action.payload.message,
      };
    },
    [updateSourceLinkedSourceLink.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceLinkedSourceLink",
      };
    },
    [updateSourceLinkedSourceLink.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceLinkedSourceLink",
        message: action.payload.message,
      };
    },
    [deleteSourceLinkedSourceLink.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceLinkedSourceLink",
      };
    },
    [deleteSourceLinkedSourceLink.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceLinkedSourceLink",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceLinkedSourceLinksSlice.actions;

export default sourceLinkedSourceLinksSlice.reducer;
