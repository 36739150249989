import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReportDefinitions = createAsyncThunk(
  "reportDefinitions/getReportDefinitions",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get(`/reports/definitions`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReportDefinition = createAsyncThunk(
  "reportDefinitions/getReportDefinition",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/reports/definitions/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createReportDefinition = createAsyncThunk(
  "reportDefinitions/createReportDefinition",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post(`/reports/definitions`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReportDefinition = createAsyncThunk(
  "reportDefinitions/updateReportDefinition",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/reports/definitions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadReportDefinitionTemplate = createAsyncThunk(
  "reportDefinitions/uploadReportDefinitionTemplate",
  async (values, { rejectWithValue }) => {
    const { uuId, formData, config } = values;
    try {
      const response = await server.post(
        `/reports/definitions/${uuId}/upload`,
        formData,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReportDefinition = createAsyncThunk(
  "reportDefinitions/deleteReportDefinition",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/reports/definitions/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportDefinitionsSlice = createSlice({
  name: "reportDefinitions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReportDefinitions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getReportDefinitions" };
    },
    [getReportDefinitions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportDefinitions",
        message: action.payload.message,
      };
    },
    [getReportDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getReportDefinition" };
    },
    [getReportDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportDefinition",
        message: action.payload.message,
      };
    },
    [createReportDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createReportDefinition" };
      state.activeId = action.payload.uuId;
    },
    [createReportDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createReportDefinition",
        message: action.payload.message,
      };
    },
    [updateReportDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateReportDefinition" };
    },
    [updateReportDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateReportDefinition",
        message: action.payload.message,
      };
    },

    [uploadReportDefinitionTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "uploadReportDefinitionTemplate",
      };
    },
    [uploadReportDefinitionTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "uploadReportDefinitionTemplate",
        message: action.payload.message,
      };
    },

    [deleteReportDefinition.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteReportDefinition" };
    },
    [deleteReportDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteReportDefinition",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = reportDefinitionsSlice.actions;

export default reportDefinitionsSlice.reducer;
