import React, { useEffect, useMemo, useState } from "react";
import { SearchSourceRecordListContext } from "../../../SearchSourceRecordListPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceRecordBySourceRecordTemplate,
  clearStatus,
} from "app/main/pages/core/sourcedefinition/source/record/store/sourceRecordsSlice";
import { SearchSourceContext } from "../../../../SearchSource";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import ReactJson from "react-json-view";
import "react-json-view-lite/dist/index.css";
import Scrollbar from "@ntai/components/Scrollbar";
import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import useSourceFields from "app/main/pages/core/sourcedefinition/source/hooks/useSourceFields";
import SourceJsonToTable from "@ntai/components/viewer/source-record-viewer/SourceJsonToTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiJsonToTable1 from "@ntai/components/viewer/NtaiJsonToTable1";
const _ = require("lodash");

function processData(data, sourceFields) {
  const result = {};
  Object.keys(data["data"]).forEach((fieldKey) => {
    const field = _.filter(Object.values(sourceFields), function (o) {
      return o["path"] === fieldKey ? true : false;
    });

    if (field && field.length > 0) {
      if (
        [7, 8].includes(field[0]["dataTypeCode"]) ||
        field[0]["esDataTypeCode"] === 3
      ) {
        result[fieldKey] = NtaiUtils.formatDateCol(data["data"][fieldKey]);
      } else result[fieldKey] = data["data"][fieldKey];
    } else {
      result[fieldKey] = data["data"][fieldKey];
    }
  });

  return result;
}

export default function SearchSourceRecordSourceRecordView({
  sourceRecordTemplateId,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [recordTemplateLoaded, setRecordTemplateLoaded] = useState(false);
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [fields, setFields] = useState([]);

  const { searchSourceData } = React.useContext(SearchSourceContext);
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const sourceDefId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.uuId"
  );

  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);
  const recordId = selectedRecord ? selectedRecord["recordId"] : null;

  const rawData = useSelector((state) => state.sources.sourceRecordsSlice.data);
  const status = useSelector(
    (state) => state.sources.sourceRecordsSlice.status
  );

  const mergedSourceFields = useSourceFields({
    sourceDefId: sourceDefId,
    sourceId: sourceId,
  });

  const data =
    rawData && !_.isEmpty(rawData) && !_.isEmpty(mergedSourceFields)
      ? processData(rawData, mergedSourceFields)
      : null;

  useEffect(() => {
    if (
      mergedSourceFields &&
      _.isArray(mergedSourceFields) &&
      mergedSourceFields.length > 0
    ) {
      setFields([...mergedSourceFields]);
      setFieldsLoaded(true);
    }
  }, [mergedSourceFields]);

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceRecordBySourceRecordTemplate" &&
      status.result === "success"
    ) {
      setRecordTemplateLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      recordId &&
      sourceRecordTemplateId &&
      sourceRecordTemplateId.length > 0
    ) {
      dispatch(
        getSourceRecordBySourceRecordTemplate({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourceRecordTemplateId: sourceRecordTemplateId,
          formData: {
            value: recordId,
          },
        })
      );
    }
  }, [recordId, sourceRecordTemplateId]);

  return (
    <>
      {fieldsLoaded &&
        recordTemplateLoaded &&
        data &&
        !_.isEmpty(data) &&
        !_.isEmpty(fields) && (
          <Scrollbar autoHide={false}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                background: grey[50],
              }}
            >
              <Box
                sx={{
                  m: theme.spacing(2),
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  p: theme.spacing(2),
                  border: theme.general.border1,
                  borderRadius: theme.general.borderRadiusLg,
                  boxShadow: theme.colors.shadows.cardSm,
                  background: "white",
                }}
              >
                {/* <SourceJsonToTable fields={fields} data={data} /> */}
                <NtaiJsonToTable1 fields={fields} json={data} />
                {/* <JsonView
                data={data["data"]}
                shouldExpandNode={allExpanded}
                style={defaultStyles}
              /> */}
                {/* <ReactJson
                  src={data["data"]}
                  displayDataTypes={false}
                  name="Source Data"
                  collapsed={2}
                  theme={{
                    base00: "white",
                    base01: "#ddd",
                    base02: "#ddd",
                    base03: "#444",
                    base04: "purple",
                    base05: "#444",
                    base06: "#444",
                    base07: "#444",
                    base08: "#444",
                    base09: "rgba(70, 70, 230, 1)",
                    base0A: "rgba(70, 70, 230, 1)",
                    base0B: "rgba(70, 70, 230, 1)",
                    base0C: "rgba(70, 70, 230, 1)",
                    base0D: "rgba(70, 70, 230, 1)",
                    base0E: "rgba(70, 70, 230, 1)",
                    base0F: "rgba(70, 70, 230, 1)",
                  }}
                /> */}
              </Box>
            </Box>
          </Scrollbar>
        )}
      {(!fieldsLoaded || !recordTemplateLoaded) && (
        <NtaiCircularProgress size={24} />
      )}
    </>
  );
}
