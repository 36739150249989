import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";

export default function SearchPublish() {
  return (
    <NtaiPanel
      width="100%"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
    ></NtaiPanel>
  );
}
