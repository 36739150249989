import { faCaretDown, faFileLines } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, useTheme } from "@mui/material";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertRecordMenuItems from "./AlertRecordMenuItems";
import { grey } from "@mui/material/colors";
import { AlertEditContext } from "../../../../AlertEdit";
const _ = require("lodash");

export default function AlertRecordMenuWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [recordViewMenuOpen, setRecordViewMenuOpen] = useState(false);
  const [anchorElRecordViewMenu, setAnchorElRecordViewMenu] = useState(null);
  const [recordTemplateName, setRecordTemplateName] = useState();
  const { selectedRecordId } = useContext(AlertEditContext);
  const dataMap = useSelector((state) => state.monitor.alertRecordsSlice.data);
  const recordData = _.get(dataMap, selectedRecordId);

  const handleRecordViewMenuOpen = (event) => {
    setRecordViewMenuOpen(true);
    setAnchorElRecordViewMenu(event.currentTarget);
  };
  const handleRecordViewMenuClose = () => {
    setRecordViewMenuOpen(false);
    setAnchorElRecordViewMenu(null);
  };

  const clickAwayHandlerRecordViewMenu = () => {
    setRecordViewMenuOpen(false);
  };

  function handleSelectView(uuId, type, name) {
    console.log("Select view: ", uuId, type, name);
  }

  useEffect(() => {
    let result = "Template Not Available";

    if (
      _.isArray(recordData["sourceRecordTemplates"]) &&
      recordData["sourceRecordTemplates"].length > 0
    ) {
      const defaultRecordTemplates = _.filter(
        recordData["sourceRecordTemplates"],
        { defaultFg: 1 }
      );

      if (defaultRecordTemplates.length > 0)
        result = defaultRecordTemplates[0]["name"];
      else {
        result = recordData["sourceRecordTemplates"][0]["name"];
      }
    }

    setRecordTemplateName(result.toUpperCase());
  }, [recordData]);

  return (
    <React.Fragment>
      <Tooltip title="SOURCE RECORD VIEW">
        <Button
          sx={{
            fontSize: "11px",
            gap: theme.spacing(1),
            boxShadow: "1px 1px 1px 1px rgb(0 0 0 / 0.1)",
            background: grey[50],
          }}
          size="small"
          color="inherit"
          onClick={(e) => handleRecordViewMenuOpen(e)}
        >
          <FontAwesomeIcon size="xl" icon={faFileLines} />
          {recordTemplateName}
          <FontAwesomeIcon size="xl" icon={faCaretDown} />
        </Button>
      </Tooltip>
      {recordViewMenuOpen && (
        <NtaiPopover
          open={recordViewMenuOpen}
          anchorEl={anchorElRecordViewMenu}
          handleClose={handleRecordViewMenuClose}
          clickAwayHandler={clickAwayHandlerRecordViewMenu}
        >
          <AlertRecordMenuItems
            handleSelectView={handleSelectView}
            handleClose={handleRecordViewMenuClose}
          />
        </NtaiPopover>
      )}
    </React.Fragment>
  );
}
