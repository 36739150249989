import { Button, Box, Avatar, useTheme, Typography } from "@mui/material";

import React from "react";
import NtaiLabelValue from "../micros/NtaiLabelValue";
import NtaiActionCard from "./NtaiActionCard";
import NtaiMiniCard from "./NtaiMiniCard";
import NtaiMiniCard1 from "./NtaiMiniCard1";
import NtaiCompactCard from "./NtaiCompactCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPodcast } from "@fortawesome/pro-light-svg-icons";
import NtaiCompactCard2 from "./NtaiCompactCard2";
import NtaiCompactCard3 from "./NtaiCompactCard3";

export default function NtaiTestCards() {
  const theme = useTheme();
  function handleHeaderActionMenu(val) {
    console.log(val);
  }

  function handleClick(action) {
    console.log("Action clicked: ", action);
  }
  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: theme.spacing(1),
      }}
    >
      <NtaiActionCard
        headerAvatar={<Avatar>A</Avatar>}
        headerTitle="Action Card"
        headerTitleDescription="Some Description"
        headerActions={["Edit", "Cancel"]}
        handleHeaderActionMenu={handleHeaderActionMenu}
        cardActions={<Button>Save me</Button>}
      >
        <Box>Title</Box>
      </NtaiActionCard>

      <NtaiActionCard headerTitle="Action Card">
        <Box>Title</Box>
      </NtaiActionCard>

      <NtaiMiniCard1 title="Clinical Trials Gov" action="1.2k">
        NLM sposnored medical research for cancer related trials
      </NtaiMiniCard1>

      <NtaiCompactCard
        id="action-1"
        avatar={<FontAwesomeIcon icon={faPodcast} />}
        title="Clinical Trials Gov sdfs d"
        hits="23k"
        handleClick={handleClick}
      >
        NLM sposnored medical research for cancer related trials. NLM sposnored
        medical research for cancer related trials
      </NtaiCompactCard>

      <NtaiCompactCard2
        id="action-1"
        avatar={<FontAwesomeIcon icon={faPodcast} />}
        title="Humira (Adalimumab)"
        hits="23k"
        handleClick={handleClick}
        description="NLM sposnored medical research for cancer related trials. NLM sposnored
        medical research for cancer related trials"
        subTitles={[
          { icon: "tasks", name: "Topics", value: 12 },
          { icon: "notes", name: "Notes", value: 23 },
        ]}
      />

      <NtaiCompactCard3
        id="action-1"
        avatar={<FontAwesomeIcon icon={faPodcast} />}
        title="Humira (Adalimumab)"
        hits="23k"
        handleClick={handleClick}
        description="NLM sposnored medical research for cancer related trials."
        subTitles={[
          { icon: "tasks", name: "Topics", value: 12 },
          { icon: "notes", name: "Notes", value: 23 },
        ]}
      />
    </Box>
  );
}
