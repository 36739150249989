import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiVerticalBarEChart from "./NtaiVerticalBarEChart";
import NtaiVerticalBarStackedEChart from "./NtaiVerticalBarStackedEChart";
import NtaiVerticalBarSeriesEChart from "./NtaiVerticalBarSeriesEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import NtaiBarLineEChart from "./NtaiBarLineEChart";
import * as echarts from "echarts";
import JSPDF from "jspdf";
import html2canvas from "html2canvas";

const PAGE_SIZE = 2;

const barData = [
  {
    name: "Male Breast Cancer",
    value: 45,
    total: 77,
    colorValue: 44,
  },
  {
    name: "Stage IV Breast Cancer",
    value: 6,
    total: 64,
    colorValue: 95,
  },
  {
    name: "Recurrent Breast Cancer",
    value: 14,
    total: 53,
    colorValue: 46,
  },
  {
    name: "Stage IIIB Breast Cancer",
    value: 23,
    total: 38,
    colorValue: 12,
  },
  {
    name: "Prostate Cancer",
    value: 12,
    total: 34,
    colorValue: 30,
  },
  {
    name: "Stage IIIC Breast Cancer",
    value: 32,
    total: 32,
    colorValue: 42,
  },
  {
    name: "Stage IIIA Breast Cancer",
    value: 12,
    total: 26,
    colorValue: 32,
  },
  {
    name: "HER2-positive Breast Cancer",
    value: 22,
    total: 22,
    colorValue: 32,
  },
  {
    name: "Breast Cancer",
    value: 20,
    total: 20,
    colorValue: 32,
  },
  {
    name: "Male Breast Carcinoma",
    value: 15,
    total: 15,
    colorValue: 32,
  },
  {
    name: "Stage II Breast Cancer",
    value: 14,
    total: 14,
    colorValue: 32,
  },
  {
    name: "Recurrent Breast Carcinoma",
    value: 12,
    total: 12,
    colorValue: 32,
  },
  {
    name: "Cancer",
    value: 11,
    total: 11,
    colorValue: 32,
  },
  {
    name: "Unspecified Adult Solid Tumor, Protocol Specific",
    value: 11,
    total: 11,
    colorValue: 11,
  },
  {
    name: "Genital Neoplasms, Male",
    value: 10,
    total: 10,
    colorValue: 10,
  },
  {
    name: "Estrogen Receptor-negative Breast Cancer",
    value: 8,
    total: 8,
    colorValue: 8,
  },
  {
    name: "HER2-negative Breast Cancer",
    value: 8,
    total: 8,
    colorValue: 32,
  },
  {
    name: "Prostatic Neoplasms",
    value: 8,
    total: 8,
    colorValue: 32,
  },
  {
    name: "Progesterone Receptor-negative Breast Cancer",
    value: 7,
    total: 7,
    colorValue: 32,
  },
  {
    name: "Recurrent Colon Cancer",
    value: 7,
    total: 7,
    colorValue: 32,
  },
  {
    name: "Recurrent Ovarian Epithelial Cancer",
    value: 7,
    total: 7,
    colorValue: 32,
  },
  {
    name: "Stage IV Renal Cell Cancer",
    value: 7,
    total: 7,
    colorValue: 32,
  },
  {
    name: "Urogenital Neoplasms",
    value: 7,
    total: 7,
    colorValue: 32,
  },
  {
    name: "Breast Cancer, Male",
    value: 6,
    total: 6,
    colorValue: 32,
  },
  {
    name: "HER2/Neu Negative",
    value: 6,
    total: 6,
    colorValue: 32,
  },
];

// const barData = [
//   {
//     name: "USA",
//     value: 12,
//     total: 12,
//     offset: 1,
//   },
//   {
//     name: "India",
//     value: 4,
//     total: 13,
//     offset: 0.3,
//   },
//   {
//     name: "France",
//     value: 12,
//     total: 24,
//     offset: 0.5,
//   },
//   {
//     name: "UK",
//     value: 12,
//     total: 12,
//     offset: 1,
//   },
//   {
//     name: "Germany",
//     value: 4,
//     total: 13,
//     offset: 0.3,
//   },
//   {
//     name: "Srilanka",
//     value: 12,
//     total: 24,
//     offset: 0.5,
//   },
// ];

const barLineData = [
  {
    name: "Male Breast Cancer",
    series: [
      { value: 120, total: 120 },
      { value: 10, total: 10 },
    ],
  },
  {
    name: "Stage IV Breast Cancer",
    series: [
      { value: 20, total: 20 },
      { value: 20, total: 20 },
    ],
  },
  {
    name: "Recurrent Breast Cancer",
    series: [
      { value: 50, total: 50 },
      { value: 90, total: 90 },
    ],
  },
];

const barSeriesData = {
  category: {
    name: [
      "N/A",
      "Phase 1",
      "Phase 2",
      "Phase 4",
      "Phase 1/Phase 2",
      "Phase 3",
      "Early Phase 1",
      "Phase 2/Phase 3",
    ],
  },
  data: [
    {
      category: "N/A",
      series: [
        {
          name: "Completed",
          data: {
            value: 113,
            colorValue: 21,
            total: 113,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 21,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 38,
            colorValue: 21,
            total: 38,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 21,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 21,
            colorValue: 21,
            total: 21,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 21,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 11,
            colorValue: 21,
            total: 11,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 21,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 6,
            colorValue: 21,
            total: 6,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 21,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 3,
            colorValue: 21,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 21,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 3,
            colorValue: 21,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 21,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 1,
            colorValue: 21,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 21,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 1,
            colorValue: 21,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 21,
            },
          },
        },
      ],
    },
    {
      category: "Phase 1",
      series: [
        {
          name: "Completed",
          data: {
            value: 28,
            colorValue: 28,
            total: 28,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 28,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 0,
            colorValue: 28,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 28,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 1,
            colorValue: 28,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 28,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 3,
            colorValue: 28,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 28,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 1,
            colorValue: 28,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 28,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 0,
            colorValue: 28,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 28,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 3,
            colorValue: 28,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 28,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 0,
            colorValue: 28,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 28,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 0,
            colorValue: 28,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 28,
            },
          },
        },
      ],
    },
    {
      category: "Phase 2",
      series: [
        {
          name: "Completed",
          data: {
            value: 6,
            colorValue: 6,
            total: 6,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 6,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 1,
            colorValue: 6,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 6,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 3,
            colorValue: 6,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 6,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 0,
            colorValue: 6,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 6,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 3,
            colorValue: 6,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 6,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 0,
            colorValue: 6,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 6,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 0,
            colorValue: 6,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 6,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 0,
            colorValue: 6,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 6,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 0,
            colorValue: 6,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 6,
            },
          },
        },
      ],
    },
    {
      category: "Phase 4",
      series: [
        {
          name: "Completed",
          data: {
            value: 4,
            colorValue: 4,
            total: 4,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 4,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 1,
            colorValue: 4,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 4,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 2,
            colorValue: 4,
            total: 2,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 4,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 0,
            colorValue: 4,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 4,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 1,
            colorValue: 4,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 4,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 0,
            colorValue: 4,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 4,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 1,
            colorValue: 4,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 4,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 0,
            colorValue: 4,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 4,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 0,
            colorValue: 4,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 4,
            },
          },
        },
      ],
    },
    {
      category: "Phase 1/Phase 2",
      series: [
        {
          name: "Completed",
          data: {
            value: 3,
            colorValue: 1,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 1,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 1,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 1,
            colorValue: 1,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 1,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 1,
            colorValue: 1,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 1,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 1,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 1,
            },
          },
        },
      ],
    },
    {
      category: "Phase 3",
      series: [
        {
          name: "Completed",
          data: {
            value: 0,
            colorValue: 3,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 3,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 2,
            colorValue: 3,
            total: 2,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 3,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 3,
            colorValue: 3,
            total: 3,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 3,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 0,
            colorValue: 3,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 3,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 0,
            colorValue: 3,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 3,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 2,
            colorValue: 3,
            total: 2,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 3,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 0,
            colorValue: 3,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 3,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 0,
            colorValue: 3,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 3,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 0,
            colorValue: 3,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 3,
            },
          },
        },
      ],
    },
    {
      category: "Early Phase 1",
      series: [
        {
          name: "Completed",
          data: {
            value: 1,
            colorValue: 1,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 1,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 1,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 1,
            colorValue: 1,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 1,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 1,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 1,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 1,
            },
          },
        },
      ],
    },
    {
      category: "Phase 2/Phase 3",
      series: [
        {
          name: "Completed",
          data: {
            value: 1,
            colorValue: 1,
            total: 1,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
              count: 1,
            },
          },
        },
        {
          name: "Unknown status",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
              count: 1,
            },
          },
        },
        {
          name: "Recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Active, not recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Active, not recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Terminated",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
              count: 1,
            },
          },
        },
        {
          name: "Not yet recruiting",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
              count: 1,
            },
          },
        },
        {
          name: "Withdrawn",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
              count: 1,
            },
          },
        },
        {
          name: "Enrolling by invitation",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Enrolling by invitation",
              count: 1,
            },
          },
        },
        {
          name: "Suspended",
          data: {
            value: 0,
            colorValue: 1,
            total: 0,
            values: {
              "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
              "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
              count: 1,
            },
          },
        },
      ],
    },
  ],
  series: {
    name: "status",
    data: [
      "Completed",
      "Unknown status",
      "Recruiting",
      "Active, not recruiting",
      "Terminated",
      "Not yet recruiting",
      "Withdrawn",
      "Enrolling by invitation",
      "Suspended",
    ],
  },
};

export default function NtaiTestVBar() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);
  const [data, setData] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  // function generatePdf() {
  //   console.log("Generating pdf...");
  //   const chart1 = echarts.init(document.getElementById("bar-hist"));
  //   // console.log("Chart 1 = ", chart1);
  //   // const gg = chart1.getDataURL({
  //   //   pixelRatio: 2,
  //   //   backgroundColor: "#fff",
  //   // });

  //   // const img1 = loadImage(gg);
  //   // console.log("img src: ", img1.src);
  //   // const pdf = new JSPDF();
  //   // // const dpr1 = chart1.getDevicePixelRatio();
  //   // // pdf.addImage(img1.src, "PNG", 0, 0, img1.width / dpr1, img1.height / dpr1);
  //   // pdf.text("Hello world!", 10, 10);
  //   // pdf.save("exports.pdf");

  //   // func(chart1);
  //   exportPdf([chart1]);
  //   // getChartImage(chart1);
  // }

  const func = async (chart1) => {
    const img1 = await getChartImage(chart1);
  };

  function loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  function getChartImage(chart) {
    return loadImage(chart.getDataURL());
  }

  function exportPdf(images) {
    const pdf = new JSPDF();
    const canvas = mergeCanvas(images);
    const imageUrl = canvas.toDataURL("image/png", 1);
    pdf.text("Hello world!", 10, 10);
    pdf.addImage(imageUrl, "png", 0, 20);
    pdf.save("exports.pdf");
  }

  function mergeCanvas(images) {
    console.log("Images = ", images);
    const canvas = document.createElement("canvas");
    const [maxH, maxW] = images.reduce(
      (ac, image) => {
        ac[0] = ac[0] + image.getHeight();
        if (image.getWidth() > ac[1]) ac[1] = image.getWidth();
        return ac;
      },
      [0, 0]
    );

    console.log("maxH, maxH", maxH);
    canvas.width = maxW;
    canvas.height = maxH;

    const context = canvas.getContext("2d");
    const top = 0;
    const left = 0;
    images.forEach((image) => {
      console.log("Image = ", image);
      const [w, h] = [image.getWidth(), image.getHeight()];
      context.drawImage(image.getRenderedCanvas(), left, top, w, h);
      top += h;
    });
    return canvas;
  }

  const handleDownloadPDF = () => {
    const input = document.getElementById("vertical-chart");
    // Specify the id of the element you want to convert to PDF
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JSPDF();
      pdf.addImage(imgData, "PNG", 0, 0, 100, 200);
      pdf.save("downloaded-file.pdf");
      // Specify the name of the downloaded PDF file
    });
  };

  const generatePDF = () => {
    const report = new JSPDF("portrait", "pt", "a4");
    // report.html(document.querySelector("#bar-hist")).then(() => {
    //   report.save("report.pdf");
    // });

    const canvas = document.getElementById("bar-hist");
    const dataURL = canvas[0].toDataURL();

    // var pdf = new jsPDF();
    report.addImage(dataURL, "JPEG", 0, 0);
    report.save("download.pdf");
  };

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function handlePagination(pageAction) {
    if (pageAction === 1) {
      setPageNumber(pageNumber + 1);
    } else {
      if (pageNumber > 1) setPageNumber(pageNumber - 1);
    }
  }

  useEffect(() => {
    setData(NtaiEChartUtil.paginate(barData, 2, pageNumber));
  }, [pageNumber]);

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Histogram chart">
          <NtaiVerticalBarEChart
            widgetId="bar-hist"
            data={barData}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId: {
                  name: "field",
                  fieldOrder: 1,
                  field: { uuId: "fieldUuId" },
                  color_schemes: [
                    {
                      uuId: "51d1aa4b-73a5-422c-ab92-63fdb6d245e9",
                      exactValue: null,
                      gradientStartValue: null,
                      gradientStopValue: null,
                      ruleGtOperatorCode: 1,
                      ruleGtOperatorDecode: null,
                      ruleLtOperatorCode: 1,
                      ruleLtOperatorDecode: null,
                      ruleGtValue: "14",
                      ruleLtValue: "32",
                      hexFgCode: "#0a0b0d",
                      hexBgCode: "#b6d2ee",
                    },
                  ],
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    // result.push(
    //   <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
    //     <NtaiSourceWidget
    //       footerPresent={true}
    //       title="Pagination chart"
    //       handlePagination={handlePagination}
    //     >
    //       <NtaiVerticalBarEChart
    //         widgetId="bar-hist"
    //         data={data}
    //         metadata={{
    //           filterFg: filtered,
    //           fields: {
    //             fieldUuId: {
    //               name: "field",
    //               fieldOrder: 1,
    //               field: { uuId: "fieldUuId" },
    //             },
    //           },
    //         }}
    //         handleClick={handleClick}
    //         handleContextMenu={handleContextMenu}
    //       />
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // result.push(
    //   <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
    //     <NtaiSourceWidget title="Bar Line chart">
    //       <NtaiBarLineEChart
    //         widgetId="bar-line"
    //         data={barLineData}
    //         metadata={{
    //           filterFg: filtered,
    //           fields: {
    //             fieldUuId1: {
    //               name: "field1",
    //               fieldOrder: 1,
    //               field: { uuId: "fieldUuId1" },
    //             },
    //             fieldUuId2: {
    //               name: "field2",
    //               fieldOrder: 1,
    //               field: { uuId: "fieldUuId2" },
    //             },
    //           },
    //         }}
    //         handleClick={handleClick}
    //         handleContextMenu={handleContextMenu}
    //       />
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // result.push(
    //   <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
    //     <NtaiSourceWidget title="Bar Line chart">
    //       <NtaiBarLineEChart
    //         widgetId="bar-line"
    //         data={barLineData}
    //         metadata={{
    //           filterFg: filtered,
    //           fields: {
    //             fieldUuId1: {
    //               name: "field1",
    //               fieldOrder: 1,
    //               field: { uuId: "fieldUuId1" },
    //             },
    //             fieldUuId2: {
    //               name: "field2",
    //               fieldOrder: 1,
    //               field: { uuId: "fieldUuId2" },
    //             },
    //           },
    //         }}
    //         handleClick={handleClick}
    //         handleContextMenu={handleContextMenu}
    //       />
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    result.push(
      <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Stacked Chart">
          <NtaiVerticalBarStackedEChart
            widgetId="bar-stacked"
            data={barSeriesData}
            metadata={{
              filterFg: filtered,
              fields: {
                phaseid: {
                  fieldUuId: "phaseuuid",
                  name: "Phase",
                  fieldOrder: 1,
                  field: {
                    uuId: "field1uuid",
                  },
                },
                periodid: {
                  fieldUuId: "periodUuId",
                  name: "Period",
                  fieldOrder: 2,
                  field: {
                    uuId: "field2UuId",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    result.push(
      <Box key={3} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Series Chart">
          <NtaiVerticalBarSeriesEChart
            widgetId="bar-series"
            data={barSeriesData}
            metadata={{
              filterFg: filtered,
              fields: {
                phaseid: {
                  fieldUuId: "phaseuuid",
                  name: "Phase",
                  fieldOrder: 1,
                  field: {
                    uuId: "field1uuid",
                  },
                },
                periodid: {
                  fieldUuId: "periodUuId",
                  name: "Period",
                  fieldOrder: 2,
                  field: {
                    uuId: "field2UuId",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  function loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  function getChartImage(chart) {
    return loadImage(chart.getDataURL());
  }

  return (
    <Box
      id="vertical-chart"
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>
      <Button onClick={() => handleDownloadPDF()}>download</Button>
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
