import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getRuleConditions = createAsyncThunk(
  "ruleConditions/getRuleConditions",
  async (ruleId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/rules/${ruleId}/conditions`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRuleCondition = createAsyncThunk(
  "ruleConditions/getRuleCondition",
  async (values, { rejectWithValue }) => {
    try {
      const { ruleId, uuId } = values;
      const response = await server.get(
        `/admin/rules/${ruleId}/conditions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createRuleCondition = createAsyncThunk(
  "ruleConditions/createRuleCondition",
  async (values, { rejectWithValue }) => {
    try {
      const { ruleId, formData } = values;
      const response = await server.post(
        `/admin/rules/${ruleId}/conditions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRuleCondition = createAsyncThunk(
  "ruleConditions/updateRuleCondition",
  async (values, { rejectWithValue }) => {
    try {
      const { ruleId, uuId, formData } = values;
      const response = await server.patch(
        `/admin/rules/${ruleId}/conditions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRuleCondition = createAsyncThunk(
  "ruleConditions/deleteRuleCondition",
  async (values, { rejectWithValue }) => {
    try {
      const { ruleId, uuId } = values;
      const response = await server.delete(
        `/admin/rules/${ruleId}/conditions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const ruleConditionsSlice = createSlice({
  name: "ruleConditions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getRuleConditions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getRuleConditions" };
    },
    [getRuleConditions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getRuleConditions",
        message: action.payload.message,
      };
    },
    [getRuleCondition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getRuleCondition" };
    },
    [getRuleCondition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getRuleCondition",
        message: action.payload.message,
      };
    },
    [createRuleCondition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createRuleCondition" };
    },
    [createRuleCondition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createRuleCondition",
        message: action.payload.message,
      };
    },
    [updateRuleCondition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateRuleCondition" };
    },
    [updateRuleCondition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateRuleCondition",
        message: action.payload.message,
      };
    },
    [deleteRuleCondition.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteRuleCondition" };
    },
    [deleteRuleCondition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteRuleCondition",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = ruleConditionsSlice.actions;

export default ruleConditionsSlice.reducer;
