import { useDispatch, useSelector } from "react-redux";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsSliceStatus,
} from "../store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "../derivedfield/store/sourceDerivedFieldsSlice";
import { useEffect, useState } from "react";
const _ = require("lodash");

function mergeFields(sourceFieldsData, sourceDerivedFieldsData) {
  let result = [];
  sourceFieldsData.forEach((o) => {
    result.push({
      uuId: o["uuId"],
      type: 1,
      name: o["name"],
      label: o["label"],
      path: o["path"],
      dataTypeCode: o["dataTypeCode"],
      esDataTypeCode: o["esDataTypeCode"],
      esDataTypeDecode:
        o["esDataTypeDecode"] && o["esDataTypeDecode"].length > 0
          ? o["esDataTypeDecode"]
          : "Custom",
    });
  });

  sourceDerivedFieldsData.forEach((o) => {
    result.push({
      uuId: o["uuId"],
      type: 2,
      name: o["name"],
      label: o["label"],
      path: o["name"],
      dataTypeCode: o["fieldTypeCode"],
      esDataTypeCode: o["fieldTypeCode"],
      esDataTypeDecode:
        o["fieldTypeDecode"] && o["fieldTypeDecode"].length > 0
          ? o["fieldTypeDecode"]
          : "Custom",
    });
  });

  result = _.orderBy(result, ["name"], ["asc"]);

  return result;
}

function useSourceFields(props) {
  const dispatch = useDispatch();
  const { sourceDefId, sourceId } = props;
  const [fields, setFields] = useState([]);

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceFieldsSliceStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );
  const sourceDerivedFieldsSliceStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  useEffect(() => {
    if (
      sourceFieldsSliceStatus &&
      sourceFieldsSliceStatus.method === "getSourceFields" &&
      sourceFieldsSliceStatus.result === "success" &&
      sourceDerivedFieldsSliceStatus &&
      sourceDerivedFieldsSliceStatus.method === "getSourceDerivedFields" &&
      sourceDerivedFieldsSliceStatus.result === "success"
    ) {
      dispatch(clearSourceFieldsSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
      setFields([...mergeFields(sourceFieldsData, sourceDerivedFieldsData)]);
    }
  }, [sourceFieldsSliceStatus, sourceDerivedFieldsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
    dispatch(
      getSourceDerivedFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [sourceId, sourceDefId]);

  return fields;
}

export default useSourceFields;
