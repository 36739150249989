import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createThesaurusSemType,
  deleteThesaurusSemType,
  getThesaurusSemTypes,
  updateThesaurusSemType,
  setActiveId,
} from "./store/thesaurusSemTypesSlice";
import ThesaurusSemTypeForm from "./ThesaurusSemTypeForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "25%",
    fontWeight: 700,
  },
  {
    id: "semTypeId",
    numeric: false,
    disablePadding: true,
    label: "Semantic Type Id",
    width: "20%",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "25%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
    width: "15%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function ThesaurusSemTypeList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const thesaurusId = useSelector(
    (state) => state.core.thesaurusesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.thesaurusSemTypesSlice.data
  );
  const activeId = useSelector(
    (state) => state.core.thesaurusSemTypesSlice.activeId
  );
  const status = useSelector(
    (state) => state.core.thesaurusSemTypesSlice.status
  );
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteThesaurusSemType({
            thesaurusId: thesaurusId,
            semTypeId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateThesaurusSemType({
            thesaurusId: thesaurusId,
            semTypeId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createThesaurusSemType({
            thesaurusId: thesaurusId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getThesaurusSemTypes" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createThesaurusSemType" ||
        status.method === "updateThesaurusSemType")
    ) {

      handleDialogClose();
      dispatch(setActiveId(null));
      setCurrentId(null);
      dispatch(clearStatus());


      enqueueSnackbar(
        `Semantic type "${
          dataMap[action === "add" ? activeId : currentId]["name"]
        }" saved successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteThesaurusSemType"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Semantic type(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }
  }, [status]);

  useEffect(() => {
    dispatch(getThesaurusSemTypes(thesaurusId));
  }, []);

  return (
    <>
      <NtaiPanel
        width="100%"
        header="Semantic Type"
        subheader="Semantic types is set of broad subject categories that provide a consistent categorization of all concepts represented in the thesaurus."
      >
        {loaded && (
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="semTypeId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleToolbarAction={handleToolbarAction}
            title="Semantic Type"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPanel>

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Semantic Type"
        size="sm"
      >
        {(action === "add" || (action === "edit" && currentId)) && (
          <NtaiForm>
            <ThesaurusSemTypeForm
              action={action}
              handleDialogClose={handleDialogClose}
              handleFormSubmit={handleFormSubmit}
              formData={action === "edit" ? dataMap[currentId] : {}}
            />
          </NtaiForm>
        )}
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
