import { faDatabase, faList, faSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import History from "@ntai/@history/@history";
import { grey } from "@mui/material/colors";

const types = [
  {
    id: "1",
    label: "Source Report",
    description:
      "Source based report works only off source fields that are created through source pipelines",
    avatar: <FontAwesomeIcon icon={faSquare} />,
  },
  {
    id: "2",
    label: "Custom Report",
    description:
      "Custom report can use all database fields that are created through RDBM store",
    avatar: <FontAwesomeIcon icon={faDatabase} />,
  },
  {
    id: "3",
    label: "Series Report",
    description:
      "Series reports are generated for the list of cases stored in search and monitor modules",
    avatar: <FontAwesomeIcon icon={faList} />,
  },
  {
    id: "4",
    label: "Dataset Report",
    description:
      "Dataset report can be generated for the set of records qualifying the dataset criteria",
    avatar: <FontAwesomeIcon icon={faList} />,
  },
  // {
  //   id: "4",
  //   label: "Standard Report",
  //   description:
  //     "Standard reports are in-built reports for modules including Admin, Workflow, Monitor and Others.",
  //   avatar: <FontAwesomeIcon icon={faList} />,
  // },
];

export default function ReportDefinitionTypeSelect({ handleDialogClose }) {
  const theme = useTheme();
  function selectReportDefinitionType(id) {
    History.push(`/reports/definitions/create/${id}`);
    handleDialogClose();
  }

  return (
    <List
      sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
    >
      {types.map((type, i) => (
        <React.Fragment key={`report-definition-type-${type.id}`}>
          <ListItemButton
            sx={{
              p: theme.spacing(2),
              width: "100%",
              display: "flex",
              borderRadius: theme.general.borderRadius,
              border: theme.general.border1,
              gap: theme.spacing(2),
              "&:hover": {
                background: grey[100],
              },
            }}
            onClick={() => selectReportDefinitionType(type.id)}
          >
            <Avatar>{type.avatar}</Avatar>

            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
              primary={
                <Typography
                  variant="subtitle1"
                  fontWeight="700"
                  color="inherit"
                >
                  {type.label}
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2">{type.description}</Typography>
              }
            />
          </ListItemButton>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
