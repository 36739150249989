import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils";
import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

export default function SourceInJsonEditForm(props) {
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceInJsonForm")
    );

    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ sourceInJsonForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      title={`Edit ${_.get(formData, "fileName")}`}
      subheader="More information to follow for this json"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="sourceInJsonForm.datasetName"
          variant="outlined"
          size="small"
          placeholder="Dataset name"
          label="Dataset name"
          rules={{
            validate: NtaiUtils.isValidSparkDatasetName,
          }}
        />

        <NtaiTextField
          name="sourceInJsonForm.description"
          variant="outlined"
          size="small"
          multiline
          minRows={2}
          maxRows={2}
          placeholder="Description"
          label="Description"
        />

        <NtaiTextField
          name="sourceInJsonForm.filePatterns"
          variant="outlined"
          size="small"
          multiline
          minRows={3}
          maxRows={3}
          placeholder="File Patterns"
          label="File Patterns"
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
