import React from "react";
import UserPermissionProvider from "./UserPermissionProvider";
import RBACComponent1 from "./RBACComponent1";
import RBACComponent2 from "./RBACComponent2";
import RBACComponent3 from "./RBACComponent3";
import { Box, useTheme } from "@mui/material";

const TestRBAC = (props) => {
  const theme = useTheme();

  return (
    <UserPermissionProvider>
      <Box
        sx={{
          display: "flex",
          p: theme.spacing(2),
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <RBACComponent1 rbac={["rbac1"]} />
        <RBACComponent2 rbac={["rbac2"]} />
        <RBACComponent3 rbac={["rbac3"]} />
      </Box>
    </UserPermissionProvider>
  );
};

export default TestRBAC;
