import { Box, Typography, useTheme } from "@mui/material";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import { AlertListContainerContext } from "../AlertContainer";
const _ = require("lodash");

export default function AlertNavigation() {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const [loaded, setLoaded] = useState(false);

  const { setNavNode } = React.useContext(AlertListContainerContext);

  const navigationData = useSelector(
    (state) => state.monitor.alertNavigationSlice.data
  );

  // const status = useSelector(
  //   (state) => state.monitor.alertNavigationSlice.status
  // );

  function handleNavItemClick(node) {
    let masterId = "*";
    let topicId = "*";
    let objectId = null;
    let objectTopicFg = null;

    if (node && !_.isEmpty(node)) {
      if (node["type"] === "OBJECT") {
        objectId = node["uuId"];
      } else if (node["type"] === "ENTITY") {
        masterId = node["uuId"];
      } else if (node["type"] === "OBJECT_TOPIC") {
        objectTopicFg = "1";
        topicId = node["uuId"];
        masterId = node["parentUuId"];
      } else if (node["type"] === "DATA_TOPIC") {
        objectTopicFg = "0";
        topicId = node["uuId"];
        masterId = node["parentUuId"];
      }
      setNavNode({
        masterId: masterId,
        topicId: topicId,
        objectId: objectId,
        objectTopicFg: objectTopicFg,
      });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      {navigationData && (
        <Sidebar data={navigationData} handleClick={handleNavItemClick} />
      )}
      {!navigationData && (
        <NtaiEmptyMessage
          header="No objects found"
          subheader="Add objects from Admin - Objects and Library menus"
          vAlign="center"
        />
      )}
      {/* {!loaded && <NtaiCircularProgress />} */}
    </Box>
  );
}
