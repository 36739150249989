import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getUserGroupResources = createAsyncThunk(
  "userGroupResources/getUserGroupResources",
  async (values, { rejectWithValue }) => {
    try {
      const { userGroupId, params } = values;
      const response = await server.get(
        `/user-groups/${userGroupId}/resources`,
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserGroupResource = createAsyncThunk(
  "userGroupResources/createUserGroupResource",
  async (values, { rejectWithValue }) => {
    try {
      const { userGroupId, formData } = values;
      const response = await server.post(
        `/user-groups/${userGroupId}/resources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserGroupResource = createAsyncThunk(
  "userGroupResources/deleteUserGroupResource",
  async (values, { rejectWithValue }) => {
    try {
      const { userGroupId, uuId } = values;
      const response = await server.delete(
        `/user-groups/${userGroupId}/resources/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userGroupResourcesSlice = createSlice({
  name: "userGroupResources",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserGroupResources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getUserGroupResources",
      };
    },
    [getUserGroupResources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserGroupResources",
        message: action.payload.message,
      };
    },

    [createUserGroupResource.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createUserGroupResource",
      };
      state.activeId = action.payload.uuId;
    },
    [createUserGroupResource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserGroupResource",
        message: action.payload.message,
      };
    },

    [deleteUserGroupResource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteUserGroupResource",
      };
    },
    [deleteUserGroupResource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUserGroupResource",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = userGroupResourcesSlice.actions;

export default userGroupResourcesSlice.reducer;
