import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDashboard = createAsyncThunk(
  "dashboards/getDashboard",
  async (thunkApi, { rejectWithValue }) => {
    try {
      const response = await server.get(`/dashboard`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDashboard = createAsyncThunk(
  "dashboards/updateDashboard",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/dashboard/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDashboard.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getDashboard" };
      state.activeId = _.has(action.payload, "uuId")
        ? action.payload.uuId
        : null;
    },
    [getDashboard.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboard",
        message: action.payload.message,
      };
    },

    [updateDashboard.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "updateDashboard" };
    },
    [updateDashboard.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateDashboard",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
