import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import Scrollbar from "@ntai/components/Scrollbar";
import SearchDetailsEdit from "./SearchDetailsEdit";
import { useFormContext } from "react-hook-form";
import { clearStatus } from "../../../store/searchSlice";
import { useEffect } from "react";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";

const _ = require("lodash");

export default function SearchDetails({ handleSave, handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);
  const { getValues } = useFormContext();

  const status = useSelector((state) => state.search.searchSlice.status);
  const searchData = useSelector(
    (state) => state.search.searchSlice.searchEditData
  );

  const [tags, setTags] = React.useState(searchData["tags"]);

  function handleEdit() {
    setEditMode(true);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchDetailsEditForm")
    );
    sanitizedFormData["tags"] = tags;

    handleSave(sanitizedFormData);
  }

  function handleOnChangeTags(t) {
    setTags([...t]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateSearch" &&
      status.result === "success"
    ) {
      setEditMode(false);
      // dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRH_SEARCH_CATEGORY_CODE));
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "78px",
          display: "flex",
          p: theme.spacing(2),
          gap: theme.spacing(1),
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: theme.general.border1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <Typography variant="h1" fontWeight="300">
            {NtaiUtils.trunc(searchData.name, 40)}
          </Typography>

          {_.get(searchData, "lockedFg") === 1 ? (
            <FontAwesomeIcon icon={faLock} color="red" />
          ) : null}
        </Box>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon size="xs" icon={faClose} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 78px)`,
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", height: `calc(100% - 44px)` }}>
          {!editMode && (
            <Scrollbar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  p: theme.spacing(2),
                  gap: theme.spacing(1),
                }}
              >
                <NtaiLabelValue1
                  label="Description"
                  value={searchData.description}
                />

                <NtaiLabelValue1 label="Owner" value={searchData.userCreated} />

                <NtaiLabelValue1
                  label="Locked?"
                  value={searchData.lockedFg === 1 ? "Yes" : "No"}
                />

                <NtaiLabelValue1
                  label="Date Created"
                  value={NtaiUtils.localDate(searchData.dateCreated)}
                />

                <NtaiLabelValue1
                  label="Date Modified"
                  value={NtaiUtils.localDate(searchData.dateModified)}
                />
                <NtaiLabelValue1
                  label="Tags"
                  value={
                    <Box
                      sx={{
                        py: theme.spacing(1),
                        display: "flex",
                        flexWrap: "wrap",
                        gap: theme.spacing(1),
                      }}
                    >
                      {_.map(tags, function (o, i) {
                        return (
                          <Chip
                            key={`search-tag-${i}`}
                            variant="outlined"
                            size="small"
                            label={o}
                          />
                        );
                      })}
                    </Box>
                  }
                />
              </Box>
            </Scrollbar>
          )}
          {editMode && (
            <Scrollbar autoHide={false}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                }}
              >
                <SearchDetailsEdit handleOnChangeTags={handleOnChangeTags} />
              </Box>
            </Scrollbar>
          )}
        </Box>

        <Box
          sx={{
            height: "64px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: theme.spacing(1),
            p: theme.spacing(2),
            borderTop: theme.general.border1,
          }}
        >
          <Button
            onClick={() => (editMode ? setEditMode(false) : handleClose())}
            size="small"
          >
            CANCEL
          </Button>
          <Button
            onClick={() => (editMode ? onHandleSave() : handleEdit())}
            size="small"
            variant="contained"
          >
            {editMode ? "SAVE" : "EDIT"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
