import React, { useEffect, useState } from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { Box, Typography } from "@mui/material";
import { clearStatus, getSearchSources } from "../store/searchSourcesSlice";
import { SearchContext } from "../../SearchEdit";
import SearchSource from "../SearchSource";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function SearchAdvSourceWrapper() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { searchData } = React.useContext(SearchContext);

  const searchId = _.get(searchData, "uuId");
  const status = useSelector((state) => state.search.searchSourcesSlice.status);
  const dataMap = useSelector((state) => state.search.searchSourcesSlice.data);
  const data = Object.values(dataMap);

  function generateSearchSources() {
    const result = [];

    if (Array.isArray(data) && data.length > 0) {
      data.forEach((searchSourceData, index) => {
        result.push(
          <SearchSource
            selected={true}
            key={`search-source-${index}`}
            searchSourceData={searchSourceData}
            label={
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {_.get(
                  searchSourceData,
                  "searchSourceDefinition.name"
                ).toUpperCase()}
              </Typography>
            }
            chip={NtaiUtils.abbrevNumber(_.get(searchSourceData, "totalHits"))}
          />
        );
      });
    }

    return result;
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSources"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSearchSources(searchId));
  }, []);

  return (
    <React.Fragment>
      {loaded && generateSearchSources()}

      {!loaded && <NtaiCircularProgress size={20} />}
    </React.Fragment>
  );
}
