import { Box, useTheme } from "@mui/material";
import React from "react";

export default function NtaiTestMicros() {
  const theme = useTheme();
  function handleClick() {
    console.log("clicked");
  }
  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        alignItems: "start",
        height: "100%",
        width: "100%",
      }}
    ></Box>
  );
}
