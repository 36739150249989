import React, { useEffect, useMemo, useState } from "react";
import NtaiReactEChart from "../NtaiReactEChart";
import { Box, darken, lighten, useTheme } from "@mui/material";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import NtaiBarEChartUtil, {
  vXAxisLabelConfig,
  vXAxisLineConfig,
} from "./util/NtaiBarEChartUtil";
import { tooltipConfig, yAxisLabelConfig } from "../chartConfig";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

const option = {
  grid: {
    containLabel: true,
    left: 15,
    top: 15,
    right: 15,
    bottom: 15,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },

  legend: {
    data: ["Evaporation", "Precipitation", "Temperature"],
  },
  xAxis: [
    {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      axisPointer: {
        type: "shadow",
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "Percentage Compliance (%)",
      min: 0,
      max: 100,
      interval: 10,
      axisLabel: {
        formatter: "{value}",
      },
    },
    {
      type: "value",
      name: "Volume",
      min: 0,
      max: 500,
      interval: 100,
      axisLabel: {
        formatter: "{value} %",
      },
    },
  ],
  series: [
    {
      name: "Percentage Compliance (%)",
      type: "line",
      tooltip: {
        valueFormatter: function (value) {
          return value + " %";
        },
      },
      data: [89, 91, 98, 60, 93, 90, 98],
    },
    {
      name: "Volume",
      type: "bar",
      tooltip: {
        valueFormatter: function (value) {
          return value + "";
        },
      },
      yAxisIndex: 1,
      data: [100, 200, 90, 250, 50, 100, 140],
    },
  ],
};

const DEFAULT_OPTION = {
  grid: {
    containLabel: true,
    left: 15,
    top: 15,
    right: 15,
    bottom: 15,
  },
  // toolbox: {
  //   feature: {
  //     dataView: { show: true, readOnly: false },
  //     magicType: { show: true, type: ["line", "bar"] },
  //     restore: { show: true },
  //     saveAsImage: { show: true },
  //   },
  // },
  animation: false,
  tooltip: {
    ...tooltipConfig,
    // formatter: function (params) {
    //   console.log("Params: ", params);
    //   return NtaiBarEChartUtil.getTooltipSingleSeries(params);
    // },
    // trigger: "axis",
    // axisPointer: {
    //   type: "cross",
    //   crossStyle: {
    //     color: "#999",
    //   },
    // },
  },
  xAxis: {
    type: "category",
    data: null,
    axisPointer: {
      type: "shadow",
    },

    axisLine: vXAxisLineConfig,
    axisLabel: vXAxisLabelConfig,
    // axisPointer: {
    //   type: "shadow",
    // },
  },
  yAxis: [
    {
      type: "value",
      name: "Percentage Compliance (%)",
      axisLabel: { ...yAxisLabelConfig, formatter: "{value}" },
    },
    {
      type: "value",
      name: "Volume",
      axisLabel: yAxisLabelConfig,
    },

    // {
    //   type: "value",
    //   name: "Compliance %",
    //   axisLabel: { ...yAxisLabelConfig, formatter: "{value} %" },
    //   interval: 10,
    // },
    // {
    //   type: "value",
    //   name: "Volume",
    //   axisLabel: yAxisLabelConfig,
    //   interval: 100,
    // },
  ],
  series: [
    {
      name: "Not available",
      cursor: "default",
      data: null,
      type: "line",
      symbolSize: 6,
      lineStyle: {
        color: null,
        width: 2,
      },
    },
    {
      name: "Not available",
      cursor: "default",
      data: null,
      type: "bar",
      yAxisIndex: 1,
    },
  ],
};

export default function NtaiBarLineEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);
  const [loaded, setLoaded] = useState(false);

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(action, params) {
    return metadataRef.current && !_.isEmpty(metadataRef.current)
      ? NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
          [1],
          params,
          metadataRef.current,
          action
        )
      : [];
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params));
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(action, getSourceWidgetFilterFields(action, params));
  }

  useEffect(() => {
    const sortedData = _.orderBy(data, ["total", "name"], ["desc", "asc"]);
    const tmpCategories = sortedData.map((d, i) => d["name"]);
    const colors = NtaiEChartUtil.getColorPalette(theme, 1);

    const revisedData1 = sortedData.map((d, i) => {
      const lineSeriesData = d["series"][1];

      return {
        value: lineSeriesData["total"],
        _color: theme.palette.getContrastText(colors[0]),
        _actual: metadata["filterFg"]
          ? lineSeriesData["value"]
          : lineSeriesData["total"],
        _filterFg: metadata["filterFg"],
        // itemStyle: NtaiBarEChartUtil.getItemStyle(
        //   lineSeriesData,
        //   metadata,
        //   theme.palette.getContrastText(colors[0]),
        //   "v"
        // ),
      };
    });

    const revisedData2 = sortedData.map((d, i) => {
      const barSeriesData = d["series"][0];

      return {
        value: barSeriesData["total"],
        _color: colors[0],
        _actual: metadata["filterFg"]
          ? barSeriesData["value"]
          : barSeriesData["total"],
        _filterFg: metadata["filterFg"],
        itemStyle: NtaiBarEChartUtil.getItemStyle(
          barSeriesData,
          metadata,
          colors[0],
          "v"
        ),
      };
    });

    const copyOption = _.cloneDeep(option);
    copyOption["color"] = colors[0];
    copyOption["xAxis"]["data"] = tmpCategories;
    copyOption["yAxis"][1]["max"] = 1000;
    copyOption["series"][0]["data"] = revisedData1;

    const yAxis1Field = _.filter(Object.values(metadata["fields"]), {
      yAxisIndexFg: 1,
    });

    if (yAxis1Field && _.isArray(yAxis1Field) && yAxis1Field.length > 0) {
      copyOption["series"][0]["name"] = yAxis1Field[0]["headerName"];
    } else copyOption["series"][0]["name"] = "Count";

    copyOption["series"][0]["lineStyle"]["color"] = darken(colors[0], 0.3);
    copyOption["series"][1]["data"] = revisedData2;
    const yAxisField = _.filter(Object.values(metadata["fields"]), {
      yAxisFg: 1,
    });

    copyOption["series"][1]["name"] =
      yAxisField.length > 0 ? yAxisField[0]["headerName"] : "Count";

    setOption(copyOption);
  }, [data, metadata]);

  useEffect(() => {
    setLoaded(true);
  }, [option]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );
  return (
    <Box
      style={{ display: "flex", width: "100%", height: "100%" }}
      id={widgetId}
    >
      {loaded && data.length > 0 && chart}
      {loaded && data.length === 0 && <NtaiChartEmptyMessage />}
    </Box>
  );
}
