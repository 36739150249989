import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

export default function NtaiObjectFormSwitchField(props) {
  const dispatch = useDispatch();
  const { formName, fieldObj } = props;

  const labelMandatoryAsterisk =
    _.get(fieldObj, "fieldMandatoryFg") === 1 ? "*" : "";

  return (
    <NtaiSwitchField
      width={
        fieldObj["fieldUiWidth"] && fieldObj["fieldUiWidth"] > 0
          ? "" + fieldObj["fieldUiWidth"].toString() + "%"
          : "100%"
      }
      name={`${formName}.${_.get(fieldObj, "fieldName")}`}
      label={_.get(fieldObj, "fieldLabel") + labelMandatoryAsterisk}
      rules={
        _.get(fieldObj, "fieldMandatoryFg") === 1
          ? { required: `${fieldObj["fieldLabel"]} is required` }
          : {}
      }
    />
  );
}
