import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, useTheme } from "@mui/material";
import {
  gridConfig,
  tooltipConfig,
  xAxisLabelConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import NtaiBarEChartUtil, {
  barGridConfig,
  vXAxisLabelConfig,
  vXAxisLineConfig,
} from "./util/NtaiBarEChartUtil";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
import useSearchSourceWidgetChartFields from "app/main/pages/modules/search/edit/source/view/widgets/hooks/useSearchSourceWidgetChartFields";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: {
    containLabel: true,
    left: 15,
    top: 15,
    right: 15,
    bottom: 15,
  },
  animation: false,
  tooltip: {
    ...tooltipConfig,
    formatter: function (params) {
      return NtaiBarEChartUtil.getTooltipSingleSeries(params);
    },
  },
  xAxis: {
    type: "category",
    data: null,
    axisLine: vXAxisLineConfig,
    axisLabel: vXAxisLabelConfig,
  },
  yAxis: {
    type: "value",
    axisLabel: yAxisLabelConfig,
    // name: "Y-Axis",
    // nameLocation: "middle",
    // nameGap: 30,
    // nameTextStyle: {
    //   fontSize: 10,
    //   fontWeight: "bold",
    // },
  },
  series: [
    {
      cursor: "default",
      data: null,
      type: "bar",
    },
  ],
};

export default function NtaiVerticalBarEChart({
  widgetId,
  data,
  metadata,
  handleClick,
  handleContextMenu,
}) {
  const theme = useTheme();
  const [option, setOption] = useState(DEFAULT_OPTION);
  const [loaded, setLoaded] = useState(false);

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(action, params) {
    return metadataRef.current && !_.isEmpty(metadataRef.current)
      ? NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
          [1],
          params,
          metadataRef.current,
          action
        )
      : [];
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params));
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(action, getSourceWidgetFilterFields(action, params));
  }

  useEffect(() => {
    const sortedData = _.orderBy(data, ["total", "name"], ["desc", "asc"]);
    const tmpCategories = sortedData.map((d, i) => d["name"]);
    const colors = NtaiEChartUtil.getColorPalette(theme, 1);
    const revisedData = sortedData.map((d, i) => {
      return {
        value: d["total"],
        _color: colors[0],
        _actual: metadata["filterFg"] ? d["value"] : d["total"],
        _filterFg: metadata["filterFg"],
        itemStyle: NtaiBarEChartUtil.getItemStyle(
          d,
          metadata,
          d.hasOwnProperty("colorValue")
            ? NtaiEChartUtil.getItemStyleColor(
                "bg",
                d["colorValue"] && d["colorValue"].length > 0
                  ? d["colorValue"]
                  : d["value"],

                colors[0],
                metadata
              )
            : colors[0],
          "v"
        ),
      };
    });

    const copyOption = _.cloneDeep(option);
    copyOption["color"] = colors[0];
    copyOption["xAxis"]["data"] = tmpCategories;
    copyOption["series"][0]["data"] = revisedData;
    copyOption["series"][0]["label"] = {
      show: true,
      position: "top",
      formatter: NtaiBarEChartUtil.getVBarLabelFormatter,
    };

    setOption(copyOption);
  }, [data, metadata]);

  useEffect(() => {
    setLoaded(true);
  }, [option]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        background: "#FFFFFF",
      }}
      id={widgetId}
    >
      {loaded && data.length > 0 && chart}
      {loaded && data.length === 0 && <NtaiChartEmptyMessage />}
    </Box>
  );
}
