import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceDefForm from "./SourceDefForm";
import {
  clearStatus,
  createSourceDefinition,
  deleteSourceDefinition,
  getSourceDefinitions,
  setActiveId,
  updateSourceDefinition,
} from "./store/sourceDefinitionsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getCodes } from "../admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import history from "@ntai/@history";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SourceExportImport from "./source/export-import/SourceExportImport";
import { Box, Button, useTheme } from "@mui/material";
import SourceCatalogAssetList from "./catalog/asset/SourceCatalogAssetViewList";
import {
  faAlbumCollection,
  faChartMixed,
} from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

function getPrivacyFg(cols) {
  if (cols[0].colValue === 1) return <FontAwesomeIcon icon={faLock} />;
}

function getNumVersions(cols) {
  if (cols[0].colValue && cols[0].colValue.length > 0) {
    return cols[0].colValue.length;
  }
}

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: ["privacyFg"],
    numeric: false,
    disablePadding: false,
    label: <FontAwesomeIcon icon={faLock} />,
    transformFunc: getPrivacyFg,
    width: "5%",
  },

  {
    id: "abbrev",
    numeric: false,
    disablePadding: false,
    label: "Short Name",
    width: "10%",
    fontWeight: "700",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "20%",
  },
  {
    id: ["sources"],
    numeric: false,
    disablePadding: false,
    label: "# Versions",
    width: "10%",
    transformFunc: getNumVersions,
  },

  {
    id: "categoryDecode",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "owner",
    numeric: false,
    disablePadding: false,
    label: "Owner",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

export default function SourceDefList() {
  const theme = useTheme();
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const dataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete(ids) {
    setSelectedIds(ids);
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteSourceDefinition(id));
      });
    }

    setSelectedIds([]);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceDefinition({ uuId: currentId, formData: formData })
        )
      : dispatch(createSourceDefinition(formData));
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    if (toolbarAction === "delete") confirmDelete(selectedItems);
  }

  function handleRowAction(action, rowUuId) {
    if (action === "manage") {
      dispatch(setActiveId(rowUuId));
      history.push(`/sourcedefs/${rowUuId}/smwrapper`);
    }
  }

  useEffect(() => {
    dispatch(getSourceDefinitions());
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_DEFINITION_CATEGORY_CODE));
  }, []);

  useEffect(() => {
    if (
      status.result === "success" &&
      (status.method === "createSourceDefinition" ||
        status.method === "updateSourceDefinition")
    ) {
      handleDialogClose();
      clearStatus();
    }

    if (
      status.result === "success" &&
      selectedIds.length === 0 &&
      status.method === "deleteSourceDefinition"
    ) {
      setOpenConfirmDeleteDialog(false);
      clearStatus();
    }

    if (
      status.result === "success" &&
      status.method === "getSourceDefinitions"
    ) {
      setLoaded(true);
    }
  }, [status]);

  return (
    <>
      <NtaiPage
        padding={2}
        title="Sources"
        headerActions={
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <Button
              sx={{ fontSize: "11px" }}
              startIcon={<FontAwesomeIcon icon={faAlbumCollection} />}
              size="small"
              onClick={() => history.push("/source-catalog")}
            >
              CATALOG
            </Button>

            <SourceExportImport type="import" />
          </Box>
        }
      >
        {loaded && (
          <NtaiCrudTable
            rows={data}
            selectedItems={
              Array.isArray(selectedIds) && selectedIds.length > 0
                ? selectedIds
                : []
            }
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleDelete={confirmDelete}
            handleToolbarAction={handleToolbarAction}
            rowActions={[
              // { value: "manage", label: "Manage" },
              {
                value: "manage",
                label: "MANAGE VERSIONS",
                variant: "outlined",
              },
            ]}
            handleRowAction={handleRowAction}
            title="Source"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title={
          action === "add"
            ? "Create Source"
            : `Edit ${_.get(dataMap[currentId], "name")}`
        }
        size="sm"
      >
        <NtaiForm>
          <SourceDefForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
