import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";

import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  clearStatus,
} from "./store/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
import { getRoles } from "../role/store/rolesSlice";
import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "accountLockedFg",
    numeric: false,
    disablePadding: false,
    label: <FontAwesomeIcon icon={faLock} />,
    transformFunc: function (o) {
      return o === 1 ? (
        <FontAwesomeIcon icon={faLock} style={{ color: "red" }} />
      ) : null;
    },
    width: "3%",
  },

  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "User Name",
    fontWeight: 700,
  },

  {
    id: ["firstName", "lastName"],
    numeric: false,
    disablePadding: false,
    label: "Name",
    transformFunc: getFullName,
  },

  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

function getFullName(names) {
  let result = "";
  let firstName = "";
  let lastName = "";

  if (_.get(names[0], "colValue") !== null)
    firstName = _.get(names[0], "colValue");
  if (_.get(names[0], "colValue") !== null)
    lastName = _.get(names[1], "colValue");

  return result.concat(firstName, " ", lastName);
}

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function UserList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loaded, setLoaded] = useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dataMap = useSelector((state) => state.core.usersSlice.data);
  const status = useSelector((state) => state.core.usersSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["userName"], ["asc"])
      : [];

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "userName"),
    ]);
    if (toolbarAction === "remove") confirmDelete();
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteUser(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(updateUser({ uuId: currentId, formData: formData }))
      : dispatch(createUser(formData));
  }

  function handleTableSearch(searchText) {}

  useEffect(() => {
    if (status && status.result === "success" && status.method === "getUsers") {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteUser"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `User(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getRoles());
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Users">
        {loaded && (
          <NtaiCrudTable
            helpText="Manage user accounts, grant appropriate level of access to resources based on user's roles and responsiblities and ensure security measures including setting and resetting passwords and locking/unlocking user accounts."
            rows={data}
            selectedIds={selectedIds}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            handleDelete={confirmDelete}
            handleToolbarAction={handleToolbarAction}
            path="/admin/users"
            title="User"
            handleSearch={handleTableSearch}
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "userName")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
