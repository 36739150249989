import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getParentEntities } from "./store/entityParentSlice";
const _ = require("lodash");

function getParentEntitiesOptions(parentEntitiesArr) {
  let result = [];

  if (Array.isArray(parentEntitiesArr)) {
    parentEntitiesArr.forEach((parentEntity) => {
      const fields = _.get(parentEntity, "fields");

      fields.forEach((field) => {
        if (field.primaryFg === 1)
          result.push({
            value: parentEntity.uuId,
            label: field.fieldValue,
          });
      });
    });
  }

  return result;
}

export default function SelectEntityParent({ object }) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);

  const objectRels = _.has(object, "objectRels")
    ? _.get(object, "objectRels")
    : null;

  const parentRelObject =
    objectRels &&
    objectRels.length > 0 &&
    _.filter(objectRels, { relTypeCode: 1 })[0];

  const relObjectUuId =
    parentRelObject && _.get(parentRelObject, "relObject.uuId");

  const parentEntitiesDataMaps = useSelector(
    (state) => state.core.entityParentSlice.data
  );

  const parentEntitiesOptions = getParentEntitiesOptions(
    Object.values(parentEntitiesDataMaps)
  );

  const status = useSelector((state) => state.core.entityParentSlice.status);

  useEffect(() => {
    if (
      status &&
      status.method === "getParentEntities" &&
      status.result === "success"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    if (relObjectUuId) {
      dispatch(
        getParentEntities({
          domainId: _.get(object, "domainUuId"),
          objectId: relObjectUuId,
        })
      );
    }
  }, []);

  return (
    relObjectUuId &&
    relObjectUuId.length > 0 &&
    parentEntitiesOptions.length > 0 && (
      <NtaiSelectField
        name="entityRelForm.relDataUuId"
        options={parentEntitiesOptions}
        label="Select Parent"
      />
    )
  );
}
