import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { getAwsClusterTemplates } from "../template/store/awsClusterTemplatesSlice";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import AwsClusterSessionList from "./session/AwsClusterSessionList";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";

const _ = require("lodash");

export default function AwsClusterForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleCancel } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const awsClusterTemplatesDataMap = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.data
  );
  const awsClusterTemplatesStatus = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.status
  );
  const status = useSelector((state) => state.core.awsClustersSlice.status);

  const awsClusterTemplateData =
    awsClusterTemplatesDataMap && !_.isEmpty(awsClusterTemplatesDataMap)
      ? Object.values(awsClusterTemplatesDataMap)
      : [];

  const awsClusterTemplateOptions = NtaiUtils.getSelectOptions(
    awsClusterTemplateData,
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("awsClusterForm")
    );
    handleSave({ ...sanitizedFormData });
  }

  useEffect(() => {
    dispatch(getAwsClusterTemplates());
    reset({ awsClusterForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{ display: "flex", width: "100%", height: "100%" }}
        label="GENERAL"
      >
        <Box sx={{ display: "flex", width: "50%" }}>
          <NtaiFormPanel
            width="100%"
            header={
              action === "add" ? "Create Cluster" : `Edit ${formData["name"]}`
            }
            subheader="Create aws cluster for source pipeline, scheduler migrations and AI model computation tasks"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <Box
              sx={{
                width: "75%",
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <NtaiTextField
                name="awsClusterForm.name"
                placeholder="Name"
                label="Name*"
                rules={{ required: "Name is required" }}
              />
              <NtaiSelectField
                isDisabled={action === "add" ? false : true}
                name="awsClusterForm.awsEmrClusterTemplateUuId"
                label="AWS Cluster Template*"
                options={awsClusterTemplateOptions}
                rules={{ required: "AWS cluster template is required" }}
              />
              <NtaiTextField
                name="awsClusterForm.description"
                placeholder="Name"
                label="Name"
                multiline
                minRows={2}
                maxRows={2}
              />
            </Box>
          </NtaiFormPanel>
        </Box>

        <Box sx={{ width: "50%", display: "flex" }}>
          {action === "edit" && (
            <NtaiFormPanel header="  " subheader="  ">
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(2),
                }}
              >
                <NtaiLabelValue
                  label="AWS Cluster Template"
                  value={formData["awsEmrClusterTemplateName"]}
                />

                <NtaiLabelValue
                  label="AWS Cluster ID"
                  value={formData["extAwsEmrClusterId"]}
                />

                <NtaiLabelValue
                  label="Status"
                  value={formData["statusDecode"]}
                />
                <NtaiLabelValue label="Message" value={formData["message"]} />
              </Box>
            </NtaiFormPanel>
          )}
        </Box>
      </Box>

      <Box disabled={action === "add" ? true : false} label="SESSIONS">
        <AwsClusterSessionList />
      </Box>
    </NtaiTabs>
  );
}
