import {
  faBell,
  faCalendar,
  faClock,
  faCog,
  faDatabase,
  faFile,
  faGrid,
  faSave,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  useTheme,
} from "@mui/material";
import React from "react";

export default function LeftSidebar() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        p: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: theme.spacing(1),
      }}
    >
      <MenuList sx={{ p: 0 }}>
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faBell} />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCog} />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faDatabase} />
          </ListItemIcon>
          <ListItemText>Database</ListItemText>
        </MenuItem>
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCalendar} />
          </ListItemIcon>
          <ListItemText>Calendar</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faFile} />
          </ListItemIcon>
          <ListItemText>New</ListItemText>
        </MenuItem>
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faSave} />
          </ListItemIcon>
          <ListItemText>Save</ListItemText>
        </MenuItem>
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faClock} />
          </ListItemIcon>
          <ListItemText>Reminders</ListItemText>
        </MenuItem>
        <MenuItem sx={{ py: 1 }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faGrid} />
          </ListItemIcon>
          <ListItemText>Apps</ListItemText>
        </MenuItem>
      </MenuList>
    </Box>
  );
}
