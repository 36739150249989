import NtaiUtils from "@ntai/utils/NtaiUtils";
import ntaiAuthServer from "app/http/ntaiAuthServer";
import ntaiServer from "app/http/ntaiServer";
import ntaiFileUploadServer from "app/http/ntaiFileUploadServer";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
/* eslint-disable camelcase */

class JwtService extends NtaiUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    ntaiServer.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        if (
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          const originalRequest = err.config;

          if (err.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
              const refreshToken = localStorage.getItem("refreshToken");
              console.log("Retrieved refresh token: ", refreshToken);
              const response = await ntaiAuthServer.post("/refresh-token", {
                refreshToken: refreshToken,
              });
              const { accessToken } = response.data;
              this.setSession(accessToken, this.getRefreshToken());
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              return axios(originalRequest);
            } catch (error) {
              console.log("Error refreshing token -> : ", error);
              // Handle refresh token error or redirect to login
              // this.logout();
              throw error;
            }
          }
        } else {
          // if you ever get an unauthorized response, logout the user

          // this.emit("onAutoLogout", "Invalid access_token");
          // this.setSession(null, null);
          throw err;
        }
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token, this.getRefreshToken());
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null, this.getRefreshToken());
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  signInWithUserNameAndPassword = (userName, password) => {
    return new Promise((resolve, reject) => {
      ntaiAuthServer
        .post("/authenticate", {
          username: userName,
          password: password,
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.accessToken,
              response.data.refreshToken
            );
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") reject("Network error");
          else reject(error.response.data);
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      ntaiAuthServer
        .get("/authenticate", {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.access_token,
              response.data.refresh_token
            );
            resolve(response.data.user);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  refreshToken = () => {
    return new Promise((resolve, reject) => {
      console.log("Refreshing token...");
      ntaiAuthServer
        .post("/refresh-token", {
          refreshToken: this.getRefreshToken(),
        })
        .then((response) => {
          if (response.data.accessToken) {
            this.setSession(response.data.accessToken, this.getRefreshToken());
            resolve(response.data.accessToken);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  setSession = (access_token, refresh_token) => {
    if (access_token) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);
    } else {
      localStorage.removeItem("accessToken");
      // localStorage.removeItem("refreshToken");
      delete ntaiServer.defaults.headers.common.Authorization;
      delete ntaiFileUploadServer.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null, null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("accessToken");
  };

  getRefreshToken = () => {
    return window.localStorage.getItem("refreshToken");
  };
}

const instance = new JwtService();

export default instance;
