import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import _ from "lodash";

export const testAmchartsSlice = createSlice({
  name: "testAmcharts",
  initialState: {
    filters: ["GER"],
    status: {},
  },
  reducers: {
    clearFilters: (state, action) => {
      state.filters = [];
    },
    applyFilter: (state, action) => {
      if (state.filters.includes(action.payload)) {
        const revFilters = state.filters.filter(
          (item) => item !== action.payload
        );
        state.filters = [...revFilters];
      } else {
        state.filters = [...state.filters, action.payload];
      }
    },
  },
});

export const { clearFilters, applyFilter } = testAmchartsSlice.actions;

export default testAmchartsSlice.reducer;
