import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/ext-language_tools";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SRC_SOURCE_STORE_FORMAT } from "app/main/constants/NtaiCodelistConstants";
import { updateSourceOutStore } from "../../store/sourceOutStoresSlice";
const _ = require("lodash");

const dbScriptsOptions = [
  {
    id: 1,
    label: "DB Create Pre-Script",
    description:
      "Add PL/SQL scripts that are executed before database is created",
  },
  {
    id: 2,
    label: "DB Create Post-Script",
    description:
      "Add PL/SQL scripts that are executed before database is created",
  },
  {
    id: 3,
    label: "DB Load Pre-Script",
    description: "Add PL/SQL scripts that are executed before data is loaded",
  },
  {
    id: 4,
    label: "DB Load Post-Script",
    description: "Add PL/SQL scripts that are executed after data is loaded",
  },
];

export default function SourceOutStoreDbScripts() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedScriptId, setSelectedScriptId] = useState(1);
  const [scriptValue, setScriptValue] = useState({});

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = useSelector(
    (state) => state.sources.sourceOutStoresSlice.activeId
  );

  const sourceOutStoreDataMap = useSelector(
    (state) => state.sources.sourceOutStoresSlice.data
  );

  const dbOutStore = _.get(
    sourceOutStoreDataMap,
    SRC_SOURCE_STORE_FORMAT.RDBMS
  );

  function onScriptChange(newValue) {
    setScriptValue({ ...scriptValue, [selectedScriptId]: newValue });
  }

  function onHandleSave() {
    const scriptValues = {
      dbCreatePreScript: scriptValue[1],
      dbCreatePostScript: scriptValue[2],
      dbLoadPreScript: scriptValue[3],
      dbLoadPostScript: scriptValue[4],
    };

    let dbOutStoreCopy = _.cloneDeep(dbOutStore);
    dbOutStoreCopy = { ...dbOutStoreCopy, ...scriptValues };
    // dispatch(updateSourceOutStore({}))
    dispatch(
      updateSourceOutStore({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: sourceOutStoreId,
        formData: dbOutStoreCopy,
      })
    );
  }

  function handleCancel() {}

  function selectScript(id) {
    setSelectedScriptId(id);
  }

  useEffect(() => {
    setScriptValue({
      1: dbOutStore["dbCreatePreScript"],
      2: dbOutStore["dbCreatePostScript"],
      3: dbOutStore["dbLoadPreScript"],
      4: dbOutStore["dbLoadPostScript"],
    });
  }, [dbOutStore]);

  useEffect(() => {
    setSelectedScriptId(1);
  }, []);

  return (
    <NtaiFormPanel
      width="75%"
      header="Manage Scripts"
      subheader="Manage pre and post processing scripts for database create and load operations. These scripts are run automatically by the pipeline manager during database ETL."
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          border: theme.general.border1,
          display: "flex",
          height: "380px",
          width: "100%",
        }}
      >
        <Box
          flexBasis="25%"
          sx={{ display: "flex", borderRight: theme.general.border1 }}
        >
          <List sx={{ p: 0, height: "100%", width: "100%" }}>
            {dbScriptsOptions.map((o, i) => (
              <React.Fragment key={`source-out-store-db-script-${o["id"]}`}>
                <ListItemButton onClick={() => selectScript(o.id)}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontWeight="700"
                        color="inherit"
                      >
                        {o["label"]}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2">
                        {o["description"]}
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
        <Box
          flexBasis="75%"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", p: theme.spacing(1) }}
          >
            <Typography variant="h6" fontWeight="700">
              {_.filter(dbScriptsOptions, { id: selectedScriptId })[0]["label"]}
            </Typography>
          </Box>

          <AceEditor
            style={{ border: theme.general.border1 }}
            theme="github"
            fontSize="14px"
            mode="mysql"
            onChange={onScriptChange}
            value={scriptValue[selectedScriptId]}
            name="functionCode"
            width="100%"
            height="100%"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              // enableSnippets: true,
              useWorker: false,
            }}
          />
        </Box>
      </Box>
    </NtaiFormPanel>
  );
}
