import { Box, useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import {
  createOrUpdateSourceFilterSchemeFields,
  getSourceFilterSchemeFields,
  clearStatus,
} from "./store/sourceFilterSchemeFieldsSlice";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import useSourceFields from "../../../hooks/useSourceFields";
import SourceFilterSchemeFieldSelection from "./SourceFilterSchemeFieldSelection";
import SourceFilterSchemeReorderFields from "./SourceFilterSchemeReorderFields";
import SourceFilterSchemeFieldForm from "./SourceFilterSchemeFieldForm";
import SourceFilterSchemeFields from "./SourceFilterSchemeFields";
const _ = require("lodash");

export const SourceFilterSchemeContext = React.createContext();

export default function SourceFilterSchemeFieldsWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [mergedSourceFields, setMergedSourceFields] = useState();
  const [selectedField, setSelectedField] = useState();
  const [filterSchemeFieldsLoaded, setFilterSchemeFieldsLoaded] =
    useState(false);
  const [mergedSourceFieldsLoaded, setMergedSourceFieldsLoaded] =
    useState(false);
  const [loaded, setLoaded] = useState(false);
  const [reorderDialogOpen, setReorderDialogOpen] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const mergedFields = useSourceFields({
    sourceDefId: sourceDefId,
    sourceId: sourceId,
  });

  const filterSchemeId = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.activeId
  );

  const filterSchemeFieldsDataMap = useSelector(
    (state) => state.sources.sourceFilterSchemeFieldsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceFilterSchemeFieldsSlice.status
  );

  const filterSchemeFields =
    filterSchemeFieldsDataMap && !_.isEmpty(filterSchemeFieldsDataMap)
      ? Object.values(filterSchemeFieldsDataMap)
      : [];

  function handleReorderDialogOpen() {
    setReorderDialogOpen(true);
  }

  function handleReorderDialogClose() {
    setReorderDialogOpen(false);
  }

  useEffect(() => {
    const tmpDataMapSourceFieldUuIds = filterSchemeFields.map((d) =>
      d.derivedFieldFg && d.derivedFieldFg === 1
        ? d.sourceDerivedFieldUuId
        : d.sourceFieldUuId
    );

    if (loaded && !_.isEqual(tmpDataMapSourceFieldUuIds, selectedFields)) {
      const revisedFields = selectedFields.map((sf, i) => {
        const filteredMergedSourceField = _.filter(
          mergedSourceFields,
          function (o) {
            return o["uuId"] === sf ? true : false;
          }
        )[0];

        return {
          fieldUiOrder: i + 1,
          sourceFieldUuId:
            filteredMergedSourceField["type"] === 1
              ? filteredMergedSourceField["uuId"]
              : null,
          sourceDerivedFieldUuId:
            filteredMergedSourceField["type"] === 2
              ? filteredMergedSourceField["uuId"]
              : null,
          derivedFieldFg: filteredMergedSourceField["type"] === 2 ? 1 : null,
        };
      });

      dispatch(
        createOrUpdateSourceFilterSchemeFields({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          filterSchemeId: filterSchemeId,
          formData: {
            filterSchemeFields: [...revisedFields],
          },
        })
      );
    }
  }, [selectedFields]);

  useEffect(() => {
    if (filterSchemeFieldsLoaded && mergedSourceFieldsLoaded) {
      const tmpSelectedFieldUuIds = filterSchemeFields.map((d) =>
        d.derivedFieldFg === 1 ? d.sourceDerivedFieldUuId : d.sourceFieldUuId
      );

      setAvailableFields([
        ..._.filter(mergedSourceFields, function (sf) {
          if (tmpSelectedFieldUuIds.includes(sf.uuId)) return false;
          else return true;
        }),
      ]);

      setSelectedFields([...tmpSelectedFieldUuIds]);
      setLoaded(true);
    }
  }, [filterSchemeFieldsLoaded, mergedSourceFieldsLoaded]);

  useEffect(() => {
    if (mergedFields && _.isArray(mergedFields) && mergedFields.length > 0) {
      setMergedSourceFields([...mergedFields]);
      setMergedSourceFieldsLoaded(true);
    }
  }, [mergedFields]);

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceFilterSchemeFields" &&
      status.result === "success"
    ) {
      setFilterSchemeFieldsLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceFilterSchemeFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        filterSchemeId: filterSchemeId,
      })
    );
  }, [filterSchemeId]);

  useEffect(() => {
    dispatch(
      getCodes(CODELIST_CODES.SRC_SOURCE_TEMPLATE_FILTER_SCHEME_FIELD_TYPE_CODE)
    );
  }, []);

  return (
    <SourceFilterSchemeContext.Provider
      value={{
        mergedSourceFields,
        availableFields,
        setAvailableFields,
        selectedFields,
        setSelectedFields,
        selectedField,
        setSelectedField,
        handleReorderDialogOpen,
      }}
    >
      <React.Fragment>
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          {loaded && (
            <React.Fragment>
              <SourceFilterSchemeFieldSelection />
              <SourceFilterSchemeFields />
              <SourceFilterSchemeFieldForm />
            </React.Fragment>
          )}
          {!loaded && <NtaiCircularProgress size={24} />}
        </Box>
        <NtaiDialog
          size="sm"
          open={reorderDialogOpen}
          handleDialogClose={handleReorderDialogClose}
          title="Reorder Fields"
        >
          <SourceFilterSchemeReorderFields
            handleClose={handleReorderDialogClose}
          />
        </NtaiDialog>
      </React.Fragment>
    </SourceFilterSchemeContext.Provider>
  );
}
