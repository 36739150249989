import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getContacts = createAsyncThunk(
  "contacts/getContacts",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/library/contacts");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContact = createAsyncThunk(
  "contacts/getContact",
  async (contactUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/cdo/contacts/${contactUuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createContact = createAsyncThunk(
  "contacts/createContact",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/library/contacts", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateContact = createAsyncThunk(
  "contacts/updateContact",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/library/contacts/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "contacts/deleteContact",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/library/contacts/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getContacts.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getContacts" };
    },
    [getContacts.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getContacts",
        message: action.payload.message,
      };
    },
    [getContact.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getContact" };
    },
    [getContact.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getContact",
        message: action.payload.message,
      };
    },
    [createContact.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createContact" };
    },
    [createContact.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createContact",
        message: action.payload.message,
      };
    },
    [updateContact.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateContact" };
    },
    [updateContact.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateContact",
        message: action.payload.message,
      };
    },
    [deleteContact.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteContact" };
    },
    [deleteContact.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteContact",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = contactsSlice.actions;

export default contactsSlice.reducer;
