import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import fileUploadServer from "app/http/ntaiFileUploadServer";
const _ = require("lodash");

export const getSourceInStoreOcrPages = createAsyncThunk(
  "sourceInStoreOcrPages/getSourceInStoreOcrPages",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceInStoreOcrPage = createAsyncThunk(
  "sourceInStoreOcrPages/getSourceInStoreOcrPage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceInStoreOcrPage = createAsyncThunk(
  "sourceInStoreOcrPages/createSourceInStoreOcrPage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceInStoreOcrPage = createAsyncThunk(
  "sourceInStoreOcrPages/updateSourceInStoreOcrPage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceInStoreOcrPage = createAsyncThunk(
  "sourceInStoreOcrPages/deleteSourceInStoreOcrPage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, uuId, formData } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadSourceInStoreOcrImageFile = createAsyncThunk(
  "sourceInStoreOcrPages/uploadSourceInStoreOcrImageFile",
  async (values, { rejectWithValue }) => {
    const { sourceDefId, sourceId, sourceInId, uuId, formData, config } =
      values;
    try {
      const response = await fileUploadServer.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/ocr-pages/${uuId}/upload`,
        formData,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceInStoreOcrPagesSlice = createSlice({
  name: "sourceInStoreOcrPages",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceInStoreOcrPages.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceInStoreOcrPages",
      };
    },
    [getSourceInStoreOcrPages.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceInStoreOcrPages",
        message: action.payload.message,
      };
    },
    [getSourceInStoreOcrPages.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSourceInStoreOcrPages",
      };
    },
    [getSourceInStoreOcrPage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceInStoreOcrPage",
      };
    },
    [getSourceInStoreOcrPage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceInStoreOcrPage",
        message: action.payload.message,
      };
    },
    [createSourceInStoreOcrPage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceInStoreOcrPage",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceInStoreOcrPage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceInStoreOcrPage",
        message: action.payload.message,
      };
    },
    [updateSourceInStoreOcrPage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "updateSourceInStoreOcrPage",
      };
    },
    [updateSourceInStoreOcrPage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceInStoreOcrPage",
        message: action.payload.message,
      };
    },
    [deleteSourceInStoreOcrPage.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceInStoreOcrPage",
      };
    },
    [deleteSourceInStoreOcrPage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceInStoreOcrPage",
        message: action.payload.message,
      };
    },

    [uploadSourceInStoreOcrImageFile.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "uploadSourceInStoreOcrImageFile",
      };
    },
    [uploadSourceInStoreOcrImageFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "uploadSourceInStoreOcrImageFile",
        message: action.payload.message,
      };
    },
    [uploadSourceInStoreOcrImageFile.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "uploadSourceInStoreOcrImageFile",
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceInStoreOcrPagesSlice.actions;

export default sourceInStoreOcrPagesSlice.reducer;
