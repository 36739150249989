import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceLinkedSourceFieldForm from "./SourceLinkedSourceFieldForm";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { Box, useTheme } from "@mui/material";
import { setActiveId } from "./store/sourceLinkedSourceFieldsSlice";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "sourceFieldName",
    numeric: false,
    disablePadding: false,
    label: "Source Field",
    fontWeight: 700,
  },
  {
    id: "linkedSourceFieldName",
    numeric: false,
    disablePadding: false,
    label: "Target Field",
  },
  {
    id: "linkedTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function SourceLinkedSourceFieldList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const linkedSourceId = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.activeId
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceLinkedSourceFieldsSlice.data
  );

  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
      dispatch(setActiveId(id));
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleFormSubmit(formData) {}

  function handleCancel() {
    setAction("list");
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "remove") confirmDelete();
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_LINKED_SOURCE_TYPE_CODE));
  }, []);

  return (
    <React.Fragment>
      {action === "list" && (
        <Box sx={{ p: theme.spacing(2) }}>
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleToolbarAction={handleToolbarAction}
            title="Linked Source Field"
          />
        </Box>
      )}

      {["add", "edit"].includes(action) && (
        <SourceLinkedSourceFieldForm
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleCancel}
          action={action}
          formData={action === "edit" ? dataMap[currentId] : null}
        />
      )}
    </React.Fragment>
  );
}
