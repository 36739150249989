import { AreaSelector, IAreaRendererProps } from "@bmunozg/react-image-area";
import { faArchive, faClose, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import NtaiBadge from "../badges/NtaiBadge";
import Scrollbar from "../Scrollbar";
const _ = require("lodash");

export default function NtaiImageArea(props) {
  const {
    imgAreas,
    imgSrc,
    imgHeight,
    imgWidth,
    imgName,
    handleCallback,
    handleDelete,
    handleEdit,
  } = props;
  const theme = useTheme();
  const [areas, setAreas] = React.useState(
    _.isArray(imgAreas) && imgAreas.length > 0 ? [...imgAreas] : []
  );

  //   {areaProps.areaNumber}

  function onHandleDelete(areaNumber, areaFieldUuId) {
    const areasCopy = _.cloneDeep(areas);
    const revisedAreas = _.filter(areasCopy, function (v, i) {
      return i !== areaNumber - 1;
    });

    setAreas(revisedAreas);

    if (areaFieldUuId) {
      handleDelete(areaFieldUuId);
    }
  }

  function onHandleEdit(areaNumber, areaFieldUuId) {
    handleEdit(areaNumber, areaFieldUuId);
  }

  const customRender = (areaProps) => {
    const fieldArea =
      imgAreas && _.isArray(imgAreas) && imgAreas.length > 0
        ? imgAreas[_.get(areaProps, "areaNumber") - 1]
        : null;

    if (!areaProps.isChanging) {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            border: theme.general.border2,
            alignItems: "center",
            justifyContent: "space-between",
            px: "4px",
          }}
          key={areaProps.areaNumber}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {fieldArea && fieldArea.fieldName && (
              <NtaiBadge title={fieldArea && fieldArea.fieldName} />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <IconButton
              sx={{ fontSize: 14 }}
              onClick={() =>
                onHandleEdit(
                  areaProps.areaNumber,
                  fieldArea ? fieldArea.uuId : null
                )
              }
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>

            <IconButton
              sx={{ fontSize: 14 }}
              onClick={() =>
                onHandleDelete(
                  areaProps.areaNumber,
                  fieldArea && fieldArea.uuId
                )
              }
            >
              <FontAwesomeIcon icon={faArchive} />
            </IconButton>
          </Box>
        </Box>
      );
    }
  };

  const onChangeHandler = (areas) => {
    const result = [];
    areas.forEach((a, i) => {
      const fieldArea = imgAreas[i];

      result.push({
        ...a,
        uuId: fieldArea ? fieldArea.uuId : null,
      });
    });

    setAreas([...result]);
  };

  useEffect(() => {
    handleCallback(areas);
  }, [areas]);

  return (
    <React.Fragment>
      <Box sx={{ p: "8px" }}>
        <AreaSelector
          areas={areas}
          customAreaRenderer={customRender}
          onChange={onChangeHandler}
        >
          {/* <div
            style={{
              height: "100%",
              width: "100%",
              overflow: "auto",
              m: "8px",
            }}
          > */}
          <img src={imgSrc} height={imgHeight} width={imgWidth} alt={imgName} />
          {/* </div> */}
        </AreaSelector>
      </Box>
    </React.Fragment>
  );
}
