import React from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./aggrid.css";
import { useEffect } from "react";
const _ = require("lodash");

export default function NtaiAgGridTable2() {
  const gridRef = React.useRef();
  const containerStyle = React.useMemo(
    () => ({ width: "100%", height: "100%" }),
    []
  );
  const gridStyle = React.useMemo(
    () => ({ height: "100%", width: "100%" }),
    []
  );

  const rowData = [
    {
      nct_id: "NCT000001",
      arm: "Intravenous Disorders and Organ",
      field1: 1,
      field3: 5,
      field4: 6,
    },
    {
      nct_id: "NCT000001",
      arm: "Arm2",
      field1: 4,
      field2: 5,
      field3: 8,
      field4: 8,
    },
    {
      nct_id: "NCT000002",
      arm: "Arm3",
      field1: 4,
      field2: 5,
    },
  ];

  const onGridReady = React.useCallback((params) => {
    // gridRef.current.api.autoSizeAllColumns();
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const colDefs = [
    { field: "nct_id", rowGroup: true, hide: true, resizable: true },
    { field: "arm", resizable: true },
    {
      resizable: true,
      headerName: "Headache",
      children: [
        { headerName: "Serious", field: "field1", resizable: true },
        { headerName: "Non Serious", field: "field2", resizable: true },
      ],
    },
    {
      headerName: "Fever",
      resizable: true,
      children: [
        { headerName: "Serious", field: "field3", resizable: true },
        { headerName: "Non Serious", field: "field4", resizable: true },
      ],
    },
  ];

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          reactUi={true}
          rowData={rowData}
          columnDefs={colDefs}
          onGridReady={onGridReady}
          groupDefaultExpanded={-1}
          groupIncludeFooter={true}
        ></AgGridReact>
      </div>
    </div>
  );
}
