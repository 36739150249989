import React from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useEffect } from "react";
import "./aggrid.css";
const _ = require("lodash");

export default function NtaiAgGrid() {
  const gridRef = React.useRef();
  const containerStyle = React.useMemo(
    () => ({ width: "100%", height: "100%" }),
    []
  );
  const gridStyle = React.useMemo(
    () => ({ height: "100%", width: "100%" }),
    []
  );

  function rowSpan(params) {
    return 2;
  }

  const rowData = [
    {
      nct_id: "NCT000001",
      arm: "Intravenous Disorders and Organ",
      events: {
        Headache: {
          Serious: 1,
          "Non-Serious": 3,
        },
      },
    },
    {
      nct_id: "NCT000001",
      arm: "Intravenous Disorders",
      events: {
        Headache: {
          Serious: 1,
          "Non-Serious": 3,
        },
      },
    },
  ];

  const onGridReady = React.useCallback((params) => {
    // gridRef.current.api.autoSizeAllColumns();
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const colDefs = [
    {
      field: "nct_id",
      rowSpan: (params) => 2,
      resizable: true,
      cellClassRules: {
        "cell-span": "true",
      },
      width: 200,
    },
    { field: "arm", resizable: true },
    {
      resizable: true,
      headerName: "Headache",
      children: [
        {
          headerName: "Serious",
          field: "events.Headache.Serious",
          resizable: true,
        },
        {
          headerName: "Non Serious",
          field: "events.Headache.Non-Serious",
          resizable: true,
        },
      ],
    },
  ];

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          sideBar={false}
          reactUi={true}
          rowData={rowData}
          columnDefs={colDefs}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}
