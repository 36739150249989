import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteSourceRecordTemplate,
  getSourceRecordTemplates,
} from "./store/sourceRecordTemplatesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceRecordTemplateEdit from "./SourceRecordTemplateEdit";
import SourceRecordTemplateCreate from "./SourceRecordTemplateCreate";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SourceRecordTemplateTypeSelection from "./SourceRecordTemplateTypeSelection";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { Box } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: "700",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "extTemplateFg",
    numeric: false,
    disablePadding: false,
    label: "External",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
  },
  {
    id: "defaultFg",
    numeric: false,
    disablePadding: false,
    label: "Default?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
  },
  {
    id: "activeFg",
    numeric: false,
    disablePadding: false,
    label: "Active?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "delete",
  },
];

export default function SourceRecordTemplateList(props) {
  const dispatch = useDispatch();

  const [action, setAction] = useState("list");
  const [templateType, setTemplateType] = useState();
  const [currentId, setCurrentId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];
  const status = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.status
  );

  function handleSelectTypeDialogOpen() {
    setOpenDialog(true);
    setCurrentId(null);
  }

  function handleSelectTypeDialogClose() {
    setOpenDialog(false);
    setAction("list");
    setCurrentId(null);
  }

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      handleSelectTypeDialogOpen();
    }
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") {
      confirmDelete();
      setAction(action);
    }
  }

  function handleCancel() {
    setAction("list");
    setTemplateType(null);
    setCurrentId(null);
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "delete") {
        dispatch(
          deleteSourceRecordTemplate({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      }
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction("list");
  }

  function handleSelect(type) {
    setAction("add");
    setTemplateType(type);
    setOpenDialog(false);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSourceRecordTemplates"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceRecordTemplate"
    ) {
      setOpenConfirmDeleteDialog(false);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceRecordTemplates({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <>
      <Box sx={{ height: "100%", width: "100%", display: "flex" }}>
        {["list", "delete"].includes(action) && (
          <NtaiPanel
            width="75%"
            header="Record Templates"
            subheader="Source record templates defines the templates and associated fields to be used during individual source record display. You can create as many source record templates as required based on individual role requirements"
          >
            {loaded && (
              <NtaiCrudTable
                rows={data}
                headCells={headCells}
                toolbarActions={toolbarActions}
                keyColumn="uuId"
                isDensed={true}
                dialog={true}
                handleDialogAddEdit={handleDialogAddEdit}
                handleToolbarAction={handleToolbarAction}
                title="Record Template"
              />
            )}
            {!loaded && <NtaiCircularProgress size={24} />}
          </NtaiPanel>
        )}

        {action === "add" && templateType && (
          <SourceRecordTemplateCreate
            templateType={templateType}
            handleCancel={handleCancel}
            redirectToEdit={handleDialogAddEdit}
          />
        )}
        {action === "edit" && (
          <SourceRecordTemplateEdit
            id={currentId}
            handleCancel={handleCancel}
          />
        )}
      </Box>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
      <NtaiDialog
        title="Select Source Type"
        open={openDialog}
        handleDialogClose={handleSelectTypeDialogClose}
        size="sm"
      >
        <SourceRecordTemplateTypeSelection handleSelect={handleSelect} />
      </NtaiDialog>
    </>
  );
}
