import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFieldsPanel from "@ntai/components/micros/NtaiFieldsPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function UserDatasetDateCriteria({
  formName,
  fields,
  formData,
  handleResetDateCriteria,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, watch, reset, setValue } = useFormContext();
  const [dateFilterType, setDateFilterType] = useState();
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const watchDateFilter = watch(`${formName}.dateCriteriaTypeCode`);

  const dateFields = NtaiAppUtils.getSourceDateFields(fields);

  const dateCriteriaTypeOptions = _.has(
    codelistData,
    CODELIST_CODES.GL_DATE_CRITERIA_TYPE_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.GL_DATE_CRITERIA_TYPE_CODE)
    : [];

  const dateCriteriaPeriodOptions = _.has(
    codelistData,
    CODELIST_CODES.GL_DATE_CRITERIA_PERIOD_CODE
  )
    ? _.filter(
        _.get(codelistData, CODELIST_CODES.GL_DATE_CRITERIA_PERIOD_CODE),
        function (o) {
          return o["value"] !== 1 ? true : false;
        }
      )
    : [];

  // useEffect(() => {
  //   setValue(
  //     `${formName}.dateCriteriaTypeCode`,
  //     formData["dateCriteriaTypeCode"]
  //   );
  //   setValue(
  //     `${formName}.dateCriteriaPeriodCode`,
  //     formData["dateCriteriaPeriodCode"]
  //   );
  //   setValue(
  //     `${formName}.dateCriteriaNumOfPeriods`,
  //     formData["dateCriteriaNumOfPeriods"]
  //   );
  //   setValue(`${formName}.dateCriteriaFrom`, formData["dateCriteriaFrom"]);
  //   setValue(`${formName}.dateCriteriaTo`, formData["dateCriteriaTo"]);
  //   setValue(
  //     `${formName}.dateCriteriaFieldUuId`,
  //     formData["dateCriteriaFieldUuId"]
  //   );
  //   setValue(
  //     `${formName}.dateCriteriaSourceFieldUuId`,
  //     formData["dateCriteriaSourceFieldUuId"]
  //   );
  //   setValue(
  //     `${formName}.dateCriteriaSourceDerivedFieldUuId`,
  //     formData["dateCriteriaSourceDerivedFieldUuId"]
  //   );
  // }, [formData]);

  useEffect(() => {
    if (watchDateFilter && !_.isEmpty(watchDateFilter)) {
      const value = watchDateFilter["value"];
      setDateFilterType(value);
    }

    if (watchDateFilter && _.isNumber(watchDateFilter))
      setDateFilterType(watchDateFilter);
  }, [watchDateFilter]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.GL_DATE_CRITERIA_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.GL_DATE_CRITERIA_PERIOD_CODE));
  }, []);

  return (
    <NtaiPanel
      padding="0px"
      header="Date Criteria"
      subheader="Optionally add date range criteria for selected date field from the source"
      handleOtherActions={
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleResetDateCriteria()}
        >
          Reset Date Criteria
        </Button>
      }
    >
      {dateFields && _.isArray(dateFields) && dateFields.length > 0 && (
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            flexDirection: "column",
          }}
        >
          <NtaiSelectField
            options={dateFields}
            label="Select date field"
            name={`${formName}.dateCriteriaFieldUuId`}
          />

          <NtaiSelectField
            options={dateCriteriaTypeOptions}
            label="Select date filter"
            name={`${formName}.dateCriteriaTypeCode`}
          />

          {[2, 3].includes(dateFilterType) && (
            <NtaiFieldsPanel label="Period">
              <NtaiSelectField
                name={`${formName}.dateCriteriaPeriodCode`}
                options={dateCriteriaPeriodOptions}
              />
            </NtaiFieldsPanel>
          )}

          {dateFilterType === 4 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <Typography>Last</Typography>
              <NtaiTextField
                label="Number of periods"
                width="40%"
                name={`${formName}.dateCriteriaNumOfPeriods`}
                type="number"
              />
              <NtaiSelectField
                options={dateCriteriaPeriodOptions}
                name={`${formName}.dateCriteriaPeriodCode`}
              />
            </Box>
          )}

          {dateFilterType === 5 && (
            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              <NtaiDateField
                name={`${formName}.dateCriteriaFrom`}
                label="From"
              />
              <NtaiDateField name={`${formName}.dateCriteriaTo`} label="To" />
            </Box>
          )}
        </Box>
      )}
      {(!dateFields || (_.isArray(dateFields) && dateFields.length === 0)) && (
        <NtaiEmptyMessage header="No date fields available" subheader="" />
      )}
    </NtaiPanel>
  );
}
