import {
  faChartSimple,
  faChartTreeMap,
  faFileCode,
  faFileImport,
  faFileInvoice,
  faInboxIn,
  faInboxOut,
  faInputText,
  faLink,
  faLinkSimple,
  faTasks,
  faTimeline,
  faUserGroup as faUsersLight,
  faFileImport as faFileImportLight,
  faArrowProgress as faArrowProgressLight,
  faUsersGear,
  faChartMixed as faChartMixedLight,
  faUserGroup as faUserGroupLight,
  faAlbumCollection,
  faBell,
  faHashtag,
  faCalendarDay,
  faImage,
  faCog,
  faStar,
  faFolders as faFoldersLight,
  faListTree,
  faUsersRectangle,
  faList,
  faFilePdf,
  faRectangleList,
  faMessagePen,
  faListCheck,
  faHeadSideGear,
  faPaperclip,
} from "@fortawesome/pro-light-svg-icons";

import {
  faBookAlt,
  faNote,
  faCloud,
  faPlay,
  faStop,
  faBallotCheck,
  faUser,
  faDatabase,
  faProcedures,
  faPeople,
  faUserGroup as faUserGroupRegular,
  faDiagramProject,
  faLock,
  faCode,
  faUnlock,
  faGridHorizontal,
  faRemove,
  faArchive,
  faSitemap,
  faStoreAlt,
  faBook,
  faGlobe,
  faLanguage,
  faPalette,
  faTable,
  faShapes,
  faFunction,
  faCheck,
  faCheckCircle,
  faClose,
  faSearch,
  faSave,
  faBackward,
  faArrowLeft,
  faCalendar,
  faClock,
  faGrid,
  faFileChartColumn,
  faUserGear,
  faUsers,
  faTurnDownLeft,
  faSignOut,
  faUpload,
  faPlusCircle,
  faPlus,
  faQuestion,
  faCheckSquare,
  faScreenshot,
  faListAlt,
  faFileImport as faFileImportRegular,
  faArrowProgress as faArrowProgressRegular,
  faUserCheck as faUserCheck,
  faDiamondTurnRight,
  faListTimeline,
  faChartMixed as faChartMixedRegular,
  faFolders as faFoldersRegular,
  faListTree as faListTreeRegular,
} from "@fortawesome/pro-regular-svg-icons";

// you'd put all valid values that can come from the backend here and then
const NtaiIcons = {
  addCircle: faPlusCircle,
  add: faPlus,
  assessment: faCheckSquare,
  adj: faMessagePen,
  adjudication: faMessagePen,
  create: faPlus,
  createCircle: faPlusCircle,
  user: faUser,
  star: faStar,
  topic: faStar,
  users: faUsers,
  usersLight: faUsersLight,
  userGroup: faUserGroupRegular,
  people: faPeople,
  globe: faGlobe,
  grid: faGrid,
  world: faGlobe,
  items: faListAlt,
  settings: faCog,
  setting: faCog,
  records: faListAlt,
  database: faDatabase,
  process: faDiagramProject,
  lock: faLock,
  unlock: faUnlock,
  gridHorizontal: faGridHorizontal,
  remove: faArchive,
  delete: faArchive,
  archive: faArchive,
  sitemap: faSitemap,
  snapshot: faScreenshot,
  screenshot: faScreenshot,
  foldersLight: faFoldersLight,
  foldersRegular: faFoldersRegular,
  "diagram-project": faDiagramProject,
  storage: faStoreAlt,
  book: faBook,
  palette: faPalette,
  language: faLanguage,
  table: faTable,
  shapes: faShapes,
  function: faFunction,
  check: faCheck,
  checkCircle: faCheckCircle,
  close: faClose,
  search: faSearch,
  save: faSave,
  run: faPlay,
  back: faArrowLeft,
  stop: faStop,
  play: faPlay,
  calendar: faCalendar,
  clock: faClock,
  libraryLight: faListTree,
  libraryRegular: faListTreeRegular,
  "file-chart-column": faFileChartColumn,
  "user-gear": faUserGear,
  "action-solid": faBallotCheck,
  "turn-down-left": faTurnDownLeft,
  notes: faNote,
  tree: faSitemap,
  hierarchy: faSitemap,
  python: faCode,
  signout: faSignOut,
  upload: faUpload,
  help: faQuestion,
  question: faQuestion,
  inputText: faInputText,
  text: faInputText,
  numeric: faHashtag,
  number: faHashtag,
  date: faCalendarDay,
  image: faImage,
  bool: faCheck,
  boolean: faCheck,
  json: faCode,
  timeline: faTimeline,
  inboxIn: faInboxIn,
  inboxOut: faInboxOut,
  chartSimple: faChartSimple,
  fileCode: faFileCode,
  shapes: faShapes,
  link: faLink,
  cloud: faCloud,
  tasks: faTasks,
  task: faTasks,
  view: faChartTreeMap,
  dashboard: faChartTreeMap,
  views: faChartTreeMap,
  doc: faFileInvoice,
  document: faFileInvoice,
  fileImportLight: faFileImportLight,
  fileImportRegular: faFileImportRegular,
  "arrow-progress-regular": faArrowProgressRegular,
  "arrow-progress-light": faArrowProgressLight,
  "user-role": faUserCheck,
  "user-check": faUserCheck,
  rule: faDiamondTurnRight,
  rules: faDiamondTurnRight,
  codelist: faListTimeline,
  list: faList,
  admin: faUsersGear,
  widgetsRegular: faChartMixedRegular,
  widgetsLight: faChartMixedLight,
  aws: faCloud,
  thesaurus: faAlbumCollection,
  notification: faBell,
  bell: faBell,
  distributionList: faUsersRectangle,
  report: faFilePdf,
  reports: faFilePdf,
  series: faRectangleList,
  "list-check": faListCheck,
  ai: faHeadSideGear,
  "machine-learning": faHeadSideGear,
  attachment: faPaperclip,
  attachments: faPaperclip,
};

export default NtaiIcons;
