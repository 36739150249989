import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import history from "@ntai/@history";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import DistributionGroupObjects from "./objects/DistributionGroupObjects";

export default function DistributionGroupForm(props) {
  const theme = useTheme();
  const { action, formData, handleFormSubmit } = props;

  const { getValues, reset, setValue } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("distributionGroupForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  function onHandleCancel() {
    history.push("/library/distribution-groups");
  }

  useEffect(() => {
    reset({ distributionGroupForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box label="GENERAL">
        <NtaiFormPanel
          header="General"
          subheader="A distribution group is an entity to group related distributions/users. You may also consider a distribution group as a company that have user/distributions grouped underneath"
          handleSave={onHandleSave}
          handleCancel={onHandleCancel}
        >
          <NtaiTextField
            name="distributionGroupForm.name"
            label="Name*"
            placeholder="Name"
            rules={{ required: "Name is required" }}
          />
          <NtaiTextField
            name="distributionGroupForm.description"
            label="Description*"
            placeholder="Description"
            multiline
            minRows={3}
            maxRows={3}
            rules={{ required: "Description is required" }}
          />
        </NtaiFormPanel>
      </Box>
      <Box label="CONTACTS" visible={action === "edit" ? true : false}>
        <DistributionGroupObjects />
      </Box>
    </NtaiTabs>
  );
}
