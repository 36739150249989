import TopicList from "./topic/TopicList";
import ThesaurusConfig from "./thesaurus/ThesaurusConfig";
import ContactConfig from "./contact/ContactConfig";
import UserDatasetConfig from "./user-dataset/UserDatasetConfig";
import ContactGroupConfig from "./contact-group/ContactGroupConfig";
import ContactList from "./contact/ContactList";
import DistributionGroupConfig from "./distribution-group/DistributionGroupConfig";
import UserQueryConfig from "./user-query/UserQueryConfig";
import SourceSeriesConfig from "./source-series/SourceSeriesConfig";

const LibraryConfig = {
  routes: [
    ...ThesaurusConfig.routes,
    ...ContactConfig.routes,
    ...ContactGroupConfig.routes,
    ...DistributionGroupConfig.routes,
    ...UserDatasetConfig.routes,
    ...UserQueryConfig.routes,
    ...SourceSeriesConfig.routes,
    {
      path: "/library",
      exact: true,
      component: ContactList,
    },
    {
      path: "/library/topics",
      exact: true,
      component: TopicList,
    },
  ],
};

export default LibraryConfig;
