import SourceSeriesCreate from "./SourceSeriesCreate";
import SourceSeriesEdit from "./SourceSeriesEdit";
import SourceSeriesList from "./SourceSeriesList";

const SourceSeriesConfig = {
  routes: [
    {
      path: "/library/source-series",
      exact: true,
      component: SourceSeriesList,
    },
    {
      path: "/library/source-series/create",
      exact: true,
      component: SourceSeriesCreate,
    },
    {
      path: "/library/source-series/:id/edit",
      exact: true,
      component: SourceSeriesEdit,
    },
  ],
};

export default SourceSeriesConfig;
