export const CODELIST_CODES = {
  GL_COUNTRY_CODE: 1,
  GL_GENDER_CODE: 2,
  GL_FREQUENCY_UNIT_CODE: 3,
  GL_RUN_STATUS_CODE: 4,
  GL_SYSTEM_USER_DEFINED_TYPE_CODE: 5,
  GL_FIELD_OPERATOR_CODE: 6,
  GL_ACCESS_TYPE_CODE: 7,
  GL_JOB_STATUS_CODE: 8,
  GL_MODULE_CODE: 9,
  GL_PRIVACY_ACCESS_CODE: 10,
  GL_QUERY_RESULT_CUT_OFF_CODE: 11,
  GL_QUERY_STATUS_CODE: 12,
  GL_DATE_CRITERIA_TYPE_CODE: 13,
  GL_DATE_CRITERIA_PERIOD_CODE: 14,
  GL_REPORT_DOCUMENT_FORMAT_CODE: 15,
  GL_CSV_DELIMITER_CODE: 16,
  COR_USER_TYPE_CODE: 1000,
  COR_ROLE_TYPE_CODE: 1001,
  COR_ROLE_PRIV_GRANT_TYPE_CODE: 1002,
  COR_VIEW_CONTENT_CODE: 1003,
  COR_VIEW_CONTENT_AGG_CODE: 1004,
  COR_VIEW_CONTENT_FIELD_TYPE_CODE: 1005,
  COR_VIEW_WIDGET_TYPE_CODE: 1006,
  COR_SCHEDULER_STATUS_CODE: 1007,
  COR_SCHEDULER_TYPE_CODE: 1008,
  COR_TEMPLATES_REPORT_TYPE_CODE: 1009,
  COR_TEMPLATES_REPORT_CATEGORY_CODE: 1010,
  COR_TEMPLATES_REPORT_PARAM_DATA_TYPE_CODE: 1011,
  COR_NOTIFICATION_CATEGORY_CODE: 1012,
  COR_NOTIFICATION_PRIORITY_CODE: 1013,
  COR_MIME_EXTENTSION_TYPE_CODE: 1014,
  COR_USER_GROUP_TYPE_CODE: 1015,
  COR_AWS_EMR_CLUSTER_TEMPLATE_STATUS_CODE: 1016,
  COR_AWS_EMR_CLUSTER_TEMPLATE_SOURCE_EXEC_TYPE_CODE: 1017,
  COR_AWS_CLUSTER_STATUS_CODE: 1018,
  COR_AWS_CLUSTER_LIVY_SESSION_STATUS_CODE: 1019,
  COR_AWS_CLUSTER_TEMPLATE_TYPE_CODE: 1020,
  COR_RESOURCE_TYPE_CODE: 1021,
  COR_AUDIT_ACTION_CODE: 1022,
  COR_DATASOURCE_TYPE_CODE: 1023,
  COR_DATASOURCE_STATUS_CODE: 1024,
  COR_DATASOURCE_JDBC_DATABASE_TYPE_CODE: 1025,
  COM_ACTION_CATEGORY_CODE: 2000,
  COM_ACTION_STATUS_CODE: 2001,
  COM_ACTION_PRIORITY_CODE: 2002,
  COM_DASHBOARD_WIDGET_QUERY_TYPE_CODE: 2003,
  COM_DASHBOARD_WIDGET_DISPLAY_TYPE_CODE: 2004,
  COM_DASHBOARD_WIDGET_FIELD_TYPE_CODE: 2005,
  COM_DASHBOARD_WIDGET_FIELD_SCRIPT_TYPE_CODE: 2006,
  COM_DASHBOARD_WIDGET_PARAM_TYPE_CODE: 2007,
  COM_DASHBOARD_WIDGET_PARAM_SCRIPT_TYPE_CODE: 2008,
  COM_WORKFLOW_TYPE_CODE: 2009,
  COM_WORKFLOW_DEPLOYMENT_STATUS_CODE: 2010,
  COM_WORKFLOW_TASK_STATUS_CODE: 2011,
  COM_RULE_TYPE_CODE: 2012,
  COM_RULE_EXEC_POSITION_CODE: 2013,
  COM_MODULE_TYPE_CODELIST: 2014,
  COM_ACCESS_TYPE_CODELIST: 2015,
  COM_ACCESS_OPERATION_CODELIST: 2016,
  COM_WORKFLOW_ACTIVITY_COMPLETION_STATUS_CODE: 2017,
  LIB_THESAURUS_CONCEPT_SYNONYM_TYPE_CODELIST: 3000,
  LIB_THESAURUS_CONCEPT_REL_TYPE_CODELIST: 3001,
  LIB_THESAURUS_LOAD_TYPE: 3002,
  LIB_QUERY_TYPE: 3003,
  LIB_QUERY_PARAM_TYPE: 3004,
  LIB_DATASET_TYPE: 3005,
  SRC_SOURCE_DEFINITION_CATEGORY_CODE: 4000,
  SRC_SOURCE_FILE_TYPE_CODE: 4001,
  SRC_SOURCE_STAGE_TYPE_CODE: 4002,
  SRC_SOURCE_FIELD_TYPE_CODE: 4003,
  SRC_SOURCE_FIELD_DATA_TYPE_CODE: 4004,
  SRC_SOURCE_FIELD_ES_DATA_TYPE_CODE: 4005,
  SRC_SOURCE_STORE_TYPE_CODE: 4006,
  SRC_SOURCE_STORE_FORMAT_CODE: 4007,
  SRC_LINKED_SOURCE_TYPE_CODE: 4008,
  SRC_LINKED_SOURCE_SEM_SCOPE_CODE: 4009,
  SRC_SOURCE_VIEW_DISPLAY_TYPE_CODE: 4019,
  SRC_SOURCE_VIEW_WIDGET_GRID_AGG_FUNC_CODELIST: 4020,
  SRC_SOURCE_VIEW_TYPE_CODE: 4010,
  SRC_SOURCE_VIEW_CATEGORY_CODE: 4011,
  SRC_SOURCE_MIGRATION_TYPE_CODE: 4012,
  SRC_SOURCE_FIELD_STRATEGY_CODE: 4013,
  SRC_SOURCE_RECORD_LISTING_TYPE_CODE: 4014,
  SRC_SOURCE_RECORD_LISTING_LIST_PLACEMENT_TYPE_CODE: 4015,
  SRC_SOURCE_RECORD_LISTING_CARD_PLACEMENT_TYPE_CODE: 4016,
  SRC_SOURCE_DERIVED_FIELD_CATEGORY_CODE: 4017,
  SRC_SOURCE_DERIVED_FIELD_TYPE_CODE: 4018,
  SRC_SOURCE_VIEW_DISPLAY_TYPE_CODELIST: 4019,
  SRC_SOURCE_VIEW_WIDGET_GRID_AGG_FUNC_CODELIST: 4020,
  SRC_SOURCE_STORE_DB_COL_DATA_TYPE: 4021,
  SRC_SOURCE_VIEW_WIDGET_METRIC_CODELIST: 4022,
  SRC_SOURCE_FILE_IMAGE_TYPE: 4023,
  SRC_SOURCE_TEMPLATE_FILTER_SCHEME_FIELD_TYPE_CODE: 4024,
  SRC_SOURCE_IN_STORE_STATUS_CODE: 4025,
  SRC_SOURCE_FIELD_LOOKUP_MATCH_TYPE_CODE: 4026,
  SRC_SOURCE_WIDGET_METRIC_RETURN_CODE: 4027,
  SRC_SOURCE_WIDGET_FILTER_INCLUDE_EXCLUDE_CODE: 4028,
  SRC_SOURCE_WIDGET_FIELD_DATE_INTERVAL_CODE: 4029,
  SRC_SOURCE_OUT_JOB_RUN_MODE_COD: 4030,
  SRC_SOURCE_OUT_JOB_INBOUND_FILES_SOURCE_CODE: 4031,
  SRC_SOURCE_OUT_JOB_LOAD_TYPE_CODE: 4032,
  SRC_SOURCE_OUT_SCD_TEST_RUN_STATUS_CODE: 4033,
  SRC_SOURCE_CATALOG_ASSET_TYPE_CODE: 4034,
  SRC_SOURCE_CATALOG_ASSET_SPECIAL_CATEGORY_CODE: 4035,
  SRC_SOURCE_CUSTOM_FIELD_TYPE_CODE: 4036,
  SRC_SOURCE_CUSTOM_FIELD_ELEMENT_TYPE_CODE: 4037,
  SRC_SOURCE_REPORT_TYPE_CODE: 4038,
  SRC_SOURCE_WIDGET_FIELD_COLOR_FORMAT_CODE: 4039,
  SRC_SOURCE_WIDGET_FIELD_COLOR_RULE_GT_CODE: 4040,
  SRC_SOURCE_WIDGET_FIELD_COLOR_RULE_LT_CODE: 4041,
  SRC_SOURCE_ADJUDICATION_RELATION_TYPE_CODE: 4042,
  SRC_SOURCE_CUSTOM_FIELD_EXEC_TYPE_CODE: 4043,
  SRC_SOURCE_POLICY_DEF_TYPE_CODE: 4044,
  SRC_SOURCE_POLICY_TYPE_CODE: 4045,
  MOD_DATASET_CATEGORY_CODE: 5000,
  MOD_DATASET_FILE_TYPE_CODE: 5001,
  MOD_MODEL_TYPE_CODE: 5002,
  MOD_ALGORITHM_TYPE_CODE: 5003,
  MOD_MODEL_CATEGORY_TYPE_CODE: 5004,
  MOD_MODEL_SERIAL_FORMAT_CODE: 5005,
  MOD_FUNCTION_TYPE_CODE: 5006,
  MOD_FUNCTION_CATEGORY_CODE: 5007,
  MOD_SPARK_RETURN_TYPE_CODE: 5008,
  MOD_PYTHON_RETURN_TYPE_CODE: 5009,
  MOD_PACKAGE_TYPE_CODE: 5010,
  COR_META_OBJECT_CATEGORY_CODE: 6000,
  COR_META_FIELD_TYPE_CODE: 6001,
  COR_META_FIELD_UI_CODE: 6002,
  COR_META_OBJECT_REL_TYPE_CODE: 6003,
  COR_OBJECT_DASHBOARD_TYPE_CODE: 6004,
  SRH_SEARCH_CATEGORY_CODE: 7000,
  SRH_SEARCH_TYPE_CODE: 7001,
  SRH_SEARCH_SOURCE_WIDGET_CHART_CLICK_ACTION_CODE: 7002,
  SRH_SEARCH_NOTES_LEVEL_CODE: 7003,
  MON_ALERT_DEF_TYPE_CODE: 8000,
  MON_ALERT_DEF_CATEGORY_CODE: 8001,
  MON_ALERT_DEF_STATUS: 8002,
  RPT_DEFINITION_TYPE_CODE: 10000,
  RPT_DEFINITION_SOURCE_CRITERIA_CODE: 10001,
  RPT_DEFINITION_PARAM_SOURCE_FILTER_FIELD_UI_CODE: 10002,
  RPT_SCHEDULER_STATUS_CODE: 10003,
  RPT_DEFINITION_PARAM_FIELD_UI_CODE: 10004,
};

export const SRH_SEARCH_TYPE_CODE = {
  SEARCH: 1,
  ANALYTICS_STANDARD: 2,
  DASHBOARD: 3,
  ANALYTICS_DATASET: 4,
  IMPORTED_SERIES: 5,
  REPORT: 6,
};

export const GL_RUN_STATUS_CODE = {
  PENDING: 1,
  RUNNING: 2,
  COMPLETED: 3,
  TERMINATED: 4,
  WARNING: 5,
  ERROR: 6,
};

export const SRC_SOURCE_STORE_FORMAT = {
  ELASTIC: 1,
  RDBMS: 2,
  AWS_REDSHIFT: 3,
  SNOWFLAKE: 4,
  PARQUET: 5,
  APACHE_DRUID: 6,
};

export const CHART_CODES = {
  BAR_HORIZONTAL: 1,
  BAR_HORIZONTAL_SERIES: 2,
  BAR_HORIZONTAL_STACKED: 3,
  BAR_VERTICAL: 4,
  BAR_VERTICAL_SERIES: 5,
  BAR_VERTICAL_STACKED: 6,
  DATE_HISTOGRAM: 7,
  TIME_SERIES: 8,
  LINE_SERIES: 9,
  PIE: 10,
  DONUT: 11,
  SCATTER: 12,
  GEO: 13,
  DATA_GRID_I: 14,
  DATA_GRID_II: 15,
  PIVOT_GRID: 16,
  CHORD: 17,
  TREEMAP: 18,
  PACKED_CIRCLE: 19,
  FORCE_DIRECTED: 20,
  HEATMAP: 21,
  SANKEY_PLOT: 22,
  GANTT: 23,
  SCRIPTED_METRIC: 24,
  WORD_CLOUD: 25,
  TREE_LINEAR: 26,
  TREE_RADIAL: 27,
  BUBBLE: 28,
  SUMMARY_TABULATION: 29,
  AGGREGATE_METRIC: 30,
  BAR_LINE: 31,
  DATE_LINE: 32,
};

export const DASHBOARD_WIDGET_DISPLAY_CODES = {
  METRIC1: 1,
  METRIC2: 2,
  METRIC3: 3,
  METRIC4: 4,
  HIST: 5,
  BAR: 6,
  STACKEDBAR: 7,
  PIE: 8,
  LINE: 9,
  SCATTER: 10,
  TABLE: 11,
  LIST: 12,
  PIVOT: 13,
  CALENDAR: 14,
  OTHER: 15,
};

export const SOURCE_FILE_FORMAT_TYPE_CODES = {
  CSV: 1,
  XML: 2,
  SAS74BT: 3,
  JSON: 4,
  PDFM: 5,
  PDFS: 6,
  IMG: 7,
  AWSREDSHIFT: 8,
  SNOWFLAKE: 9,
  JDBC: 10,
  PARQUET: 11,
};

export const SEARCH_SOURCE_WIDGET_MENU_ACTIONS = {
  SELECT: 1,
  EQUALS: 2,
  NOT_EQUALS: 3,
  INCLUDE: 4,
  EXCLUDE: 5,
  DRILL_DOWN: 6,
  DRILL_UP: 7,
  NOTES: 8,
};

export const OBJECT_FORM_UI_FIELD_TYPE_CODES = {
  TEXT_FIELD: 1,
  TEXT_AREA: 2,
  DROP_DOWN: 3,
  RADIO_GROUP: 4,
  MULTI_SELECT: 5,
  CHECKBOX_GROUP: 6,
  SWITCH: 7,
  DATE: 8,
};

export const MODEL_FUNCTION_TYPE_CODE = {
  SRC_SOURCE_PIPELINE_PRE_STORE: 1,
  SRC_SOURCE_PIPELINE_PRE_STORE: 2,
  SRC_SOURCE_SCD: 3,
};

export const SRC_SOURCE_RECORD_SUMMARY = {
  SRC_SOURCE_RECORD_SUMMARY_ALL: 1,
  SRC_SOURCE_RECORD_SUMMARY_ADJS: 2,
  SRC_SOURCE_RECORD_SUMMARY_DOCS: 3,
  SRC_SOURCE_RECORD_SUMMARY_ACTIONS: 4,
  SRC_SOURCE_RECORD_SUMMARY_NOTES: 5,
  SRC_SOURCE_RECORD_SUMMARY_DIST_CONTACTS: 6,
  SRC_SOURCE_RECORD_SUMMARY_DIST_WF: 7,
};
