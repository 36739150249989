import UserQueryCreate from "./UserQueryCreate";
import UserQueryEdit from "./UserQueryEdit";
import UserQueryList from "./UserQueryList";

const UserQueryConfig = {
  routes: [
    {
      path: "/library/queries",
      exact: true,
      component: UserQueryList,
    },
    {
      path: "/library/queries/create",
      exact: true,
      component: UserQueryCreate,
    },
    {
      path: "/library/queries/:id/edit",
      exact: true,
      component: UserQueryEdit,
    },
  ],
};

export default UserQueryConfig;
