import theme from "@amcharts/amcharts4/.internal/themes/animated";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
const _ = require("lodash");

export default function NtaiDashboardMetric2Widget(props) {
  const theme = useTheme();

  const { id, data, footer, bg, fg } = props;
  return (
    <Box
      sx={{
        p: theme.spacing(1),
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        gap: theme.spacing(1),
        background: bg ? bg : null,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {data &&
          _.isArray(data) &&
          data.map((d, i) => (
            <Box
              key={`${id}-${i}`}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  color={fg ? fg : "default"}
                  variant="subtitle2"
                  fontWeight="500"
                >
                  {Object.keys(d)[0]}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  color={fg ? fg : "default"}
                  variant="h1"
                  fontSize="700"
                >
                  {Object.values(d)[0]}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          mt: theme.spacing(1),
          border: theme.general.border1,
          display: "flex",
          borderRadius: theme.general.borderRadius,
        }}
      >
        <Box
          sx={{
            display: "flex",
            p: theme.spacing(1),
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" color={fg ? fg : "default"}>
            {footer}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
