import React from "react";
import { Box, Button, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import SearchInitFilters from "./SearchInitFilters";
import SearchBasicInitSources from "../basic/SearchBasicInitSources";
import SearchAdvInitSources from "../advanced/SearchAdvInitSources";
import { useState } from "react";

export const SearchInitSourceContext = React.createContext();

export default function SearchInitSourcesWrapper({ mode }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(null);

  return (
    <SearchInitSourceContext.Provider
      value={{ selectedFilter, setSelectedFilter }}
    >
      <Box
        sx={{
          flexBasis: mode === "basic" ? "65%" : "65%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <SearchInitFilters />
          {mode === "basic" ? (
            <SearchBasicInitSources />
          ) : (
            <SearchAdvInitSources />
          )}
        </Box>
      </Box>
    </SearchInitSourceContext.Provider>
  );
}
