import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceViewWidgets = createAsyncThunk(
  "searchSourceViewWidgets/getSearchSourceViewWidgets",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, sourceViewId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getSearchSourceViewWidget = createAsyncThunk(
//   "searchSourceViewWidgets/getSearchSourceViewWidget",
//   async (values, { rejectWithValue }) => {
//     try {
//       const { searchId, searchSourceId, sourceViewId, sourceViewWidgetId } =
//         values;
//       const response = await server.get(
//         `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets/${sourceViewWidgetId}`
//       );

//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const getSearchSourceViewWidgetForSelect = createAsyncThunk(
  "searchSourceViewWidgets/getSearchSourceViewWidgetForSelect",
  async (values, { rejectWithValue }) => {
    const {
      searchId,
      searchSourceId,
      sourceViewId,
      sourceViewWidgetId,
      formData,
    } = values;

    try {
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets/${sourceViewWidgetId}`,
        formData
      );

      return { widgetId: sourceViewWidgetId, data: response.data };
    } catch (err) {
      return rejectWithValue({
        widgetId: sourceViewWidgetId,
        data: err.response.data,
      });
    }
  }
);

export const createSearchSourceViewWidget = createAsyncThunk(
  "searchSourceViewWidgets/createSearchSourceViewWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, sourceViewId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets`,
        formData
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceViewWidget = createAsyncThunk(
  "searchSourceViewWidgets/updateSearchSourceViewWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, sourceViewId, uuId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceViewDrillDownWidget = createAsyncThunk(
  "searchSourceViewWidgets/createSearchSourceViewDrillDownWidget",
  async (values, { rejectWithValue }) => {
    try {
      const {
        searchId,
        searchSourceId,
        sourceViewId,
        sourceRootWidgetId,
        formData,
      } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets/${sourceRootWidgetId}/drill-down`,
        formData
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceViewDrillUpWidget = createAsyncThunk(
  "searchSourceViewWidgets/getSearchSourceViewDrillUpWidget",
  async (values, { rejectWithValue }) => {
    try {
      const {
        searchId,
        searchSourceId,
        sourceViewId,
        sourceRootWidgetId,
        formData,
      } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets/${sourceRootWidgetId}/drill-up`,
        formData
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceViewDrillWidget = createAsyncThunk(
  "searchSourceViewWidgets/deleteSearchSourceViewDrillWidget",
  async (values, { rejectWithValue }) => {
    try {
      const {
        searchId,
        searchSourceId,
        sourceViewId,
        sourceRootWidgetId,
        formData,
      } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets/${sourceRootWidgetId}/delete-drill-widget`,
        formData
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceViewWidget = createAsyncThunk(
  "searchSourceViewWidgets/deleteSearchSourceViewWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, sourceViewId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/views/${sourceViewId}/widgets/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceViewWidgetsSlice = createSlice({
  name: "searchSourceViewWidgets",
  initialState: {
    data: {},
    tempFilters: {},
    activeId: null,
    status: {},
    widgetStatus: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    clearAll: (state, action) => {
      state.status = {};
      state.data = {};
      state.tempFilters = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    updateWidgetTempFilters: (state, action) => {
      let searchSourceTempFilters = _.has(
        current(state).tempFilters,
        action.payload.searchSourceId
      )
        ? _.cloneDeep(
            _.get(current(state).tempFilters, action.payload.searchSourceId)
          )
        : {};

      let widgetFilters = _.has(
        searchSourceTempFilters,
        action.payload.widgetUuId
      )
        ? _.get(searchSourceTempFilters, action.payload.widgetUuId)
        : [];

      let revisedFilters = _.cloneDeep(widgetFilters);

      if (_.isArray(revisedFilters) && revisedFilters.length > 0) {
        const filterPresent = _.filter(revisedFilters, function (o) {
          if (_.isEqual(o, action.payload.widgetTempFilter)) return true;
        });

        if (_.isArray(filterPresent) && filterPresent.length > 0) {
          revisedFilters.splice(
            revisedFilters.indexOf(action.payload.widgetTempFilter),
            1
          );
        } else {
          revisedFilters.push(action.payload.widgetTempFilter);
        }

        if (revisedFilters.length > 0) {
          searchSourceTempFilters[action.payload.widgetUuId] = revisedFilters;
        } else delete searchSourceTempFilters[action.payload.widgetUuId];
      } else {
        revisedFilters.push(action.payload.widgetTempFilter);
        searchSourceTempFilters[action.payload.widgetUuId] = revisedFilters;
      }

      state.tempFilters = {
        ...state.tempFilters,
        [action.payload.searchSourceId]: searchSourceTempFilters,
      };

      state.status = { method: "updateWidgetTempFilters", result: "success" };
    },
    deleteWidgetTempFilter: (state, action) => {
      let searchSourceTempFilters = _.has(
        current(state).tempFilters,
        action.payload.searchSourceId
      )
        ? _.cloneDeep(
            _.get(current(state).tempFilters, action.payload.searchSourceId)
          )
        : {};

      let searchSourceWidgetFilters = _.has(
        searchSourceTempFilters,
        action.payload.widgetUuId
      )
        ? _.get(searchSourceTempFilters, action.payload.widgetUuId)
        : [];

      let revisedWidgetFilters = _.filter(
        searchSourceWidgetFilters,
        function (wf) {
          if (_.isEqual(wf, action.payload.widgetTempFilter)) return false;
          else return true;
        }
      );

      if (revisedWidgetFilters.length === 0)
        delete searchSourceTempFilters[action.payload.widgetUuId];
      else
        searchSourceTempFilters[action.payload.widgetUuId] =
          revisedWidgetFilters;

      state.tempFilters[action.payload.searchSourceId] =
        searchSourceTempFilters;

      state.status = { method: "deleteWidgetTempFilter", result: "success" };
    },
    clearWidgetTempFilters: (state, action) => {
      state.tempFilters = {};
      state.status = { method: "clearWidgetTempFilters", result: "success" };
    },
  },
  extraReducers: {
    [getSearchSourceViewWidgets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceViewWidgets",
      };
    },
    [getSearchSourceViewWidgets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceViewWidgets",
        message: action.payload,
      };
    },
    // [getSearchSourceViewWidget.fulfilled]: (state, action) => {
    //   state.data = {
    //     ...state.data,
    //     [action.payload.widgetUuId]: action.payload,
    //   };
    //   state.status = { result: "success", method: "getSearchSourceViewWidget" };
    // },
    // [getSearchSourceViewWidget.rejected]: (state, action) => {
    //   state.status = {
    //     result: "error",
    //     method: "getSearchSourceViewWidget",
    //     message: action.payload.message,
    //   };
    // },
    [createSearchSourceViewWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceViewWidget",
      };
    },
    [createSearchSourceViewWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceViewWidget",
        message: action.payload.message,
      };
    },

    [getSearchSourceViewWidgetForSelect.fulfilled]: (state, action) => {
      _.has(action.payload.data, "rootWidgetUuId") &&
      action.payload.data["rootWidgetUuId"] &&
      action.payload.data["rootWidgetUuId"].length > 0
        ? (state.data = {
            ...state.data,
            [action.payload.data.rootWidgetUuId]: action.payload.data,
          })
        : (state.data = {
            ...state.data,
            [action.payload.data.widgetUuId]: action.payload.data,
          });

      state.widgetStatus = {
        ...state.widgetStatus,
        [action.payload.widgetId]: {
          result: "success",
          method: "getSearchSourceViewWidgetForSelect",
          message: action.payload.message,
        },
      };
    },

    [getSearchSourceViewWidgetForSelect.rejected]: (state, action) => {
      state.widgetStatus = {
        ...state.widgetStatus,
        [action.payload.widgetId]: {
          result: "error",
          method: "getSearchSourceViewWidgetForSelect",
          message: action.payload.message,
        },
      };
    },

    [updateSearchSourceViewWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceViewWidget",
      };
    },
    [updateSearchSourceViewWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceViewWidget",
        message: action.payload.message,
      };
    },

    [createSearchSourceViewDrillDownWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.rootWidgetUuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceViewDrillDownWidget",
      };
    },

    [createSearchSourceViewDrillDownWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceViewDrillDownWidget",
        message: action.payload.message,
      };
    },

    [getSearchSourceViewDrillUpWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.rootWidgetUuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceViewDrillUpWidget",
      };
    },

    [getSearchSourceViewDrillUpWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceViewDrillUpWidget",
        message: action.payload.message,
      };
    },

    [deleteSearchSourceViewDrillWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.rootWidgetUuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "deleteSearchSourceViewDrillWidget",
      };
    },

    [deleteSearchSourceViewDrillWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceViewDrillWidget",
        message: action.payload.message,
      };
    },

    [deleteSearchSourceViewWidget.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceViewWidget",
      };
    },
    [deleteSearchSourceViewWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceViewWidget",
        message: action.payload.message,
      };
    },
  },
});

export const {
  clearAll,
  clearStatus,
  setActiveId,
  updateWidgetTempFilters,
  deleteWidgetTempFilter,
  clearWidgetTempFilters,
} = searchSourceViewWidgetsSlice.actions;

export default searchSourceViewWidgetsSlice.reducer;
