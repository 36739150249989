import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useEffect } from "react";
import { getSourceFilterSchemes } from "../../../sourcedefinition/source/template/filter/store/sourceFilterSchemesSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrRemoveUserDatasetFilterScheme,
  getUserDatasetFilterSchemes,
  updateUserDatasetFilterScheme,
} from "./store/userDatasetFilterSchemesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function UserDatasetFilterSchemes() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const dataMap = useSelector(
    (state) => state.core.userDatasetFilterSchemesSlice.data
  );
  const status = useSelector(
    (state) => state.core.userDatasetFilterSchemesSlice.data
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const userDatasetDataMap = useSelector(
    (state) => state.core.userDatasetsSlice.data
  );
  const userDatasetActiveId = useSelector(
    (state) => state.core.userDatasetsSlice.activeId
  );

  const userDatasetData = userDatasetDataMap[userDatasetActiveId];

  const sourceFilterSchemesDataMap = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.data
  );

  const sourceFilterSchemesData =
    sourceFilterSchemesDataMap && !_.isEmpty(sourceFilterSchemesDataMap)
      ? Object.values(sourceFilterSchemesDataMap)
      : [];

  function isFilterSchemeAdded(sourceFilterSchemeId) {
    let isAdded = false;
    const present = _.filter(data, {
      sourceFilterSchemeUuId: sourceFilterSchemeId,
    });

    if (_.isArray(present) && present.length > 0) isAdded = true;

    return isAdded;
  }

  function getUserDatasetFilterSchemeUuId(sourceFilterSchemeId) {
    const userDatasetFilterSchemes = _.filter(data, {
      sourceFilterSchemeUuId: sourceFilterSchemeId,
    });

    if (
      _.isArray(userDatasetFilterSchemes) &&
      userDatasetFilterSchemes.length > 0
    )
      return userDatasetFilterSchemes[0]["uuId"];
    else return null;
  }

  function isFilterSchemeDefault(sourceFilterSchemeId) {
    let isDefault = false;
    const present = _.filter(data, {
      defaultFg: 1,
      sourceFilterSchemeUuId: sourceFilterSchemeId,
    });

    if (_.isArray(present) && present.length > 0) isDefault = true;

    return isDefault;
  }

  function handleAddRemove(filterSchemeUuId) {
    dispatch(
      createOrRemoveUserDatasetFilterScheme({
        userDatasetUuId: userDatasetData["uuId"],
        formData: {
          sourceFilterSchemeUuIds: [filterSchemeUuId],
        },
      })
    );
  }

  function handleDefault(userDatasetFilterSchemeUuId) {
    dispatch(
      updateUserDatasetFilterScheme({
        userDatasetUuId: userDatasetData["uuId"],
        uuId: userDatasetFilterSchemeUuId,
        formData: {
          sourceFilterSchemeUuIds: [],
        },
      })
    );
  }

  useEffect(() => {
    dispatch(
      getSourceFilterSchemes({
        sourceDefId: "*",
        sourceId: userDatasetData["sourceUuId"],
        params: {
          sourceUuId: userDatasetData["sourceUuId"],
        },
      })
    );

    dispatch(getUserDatasetFilterSchemes(userDatasetData["uuId"]));
  }, []);

  return (
    <NtaiPanel
      width="50%"
      header="Filter Schemes"
      subheader="Select the desired source filter scheme for the dataset. Assigned filter scheme can be used in search - analytics "
    >
      <Box
        sx={{
          border: theme.general.border1,
          display: "flex",
          height: "320px",
          width: "100%",
        }}
      >
        {sourceFilterSchemesData &&
          _.isArray(sourceFilterSchemesData) &&
          sourceFilterSchemesData.length > 0 && (
            <Scrollbar>
              <List sx={{ p: 0 }}>
                {_.orderBy(sourceFilterSchemesData, ["name"], ["asc"]).map(
                  (sf, i) => (
                    <React.Fragment key={`user-dataset-filter-scheme-${i}`}>
                      <ListItem
                        sx={{ "&:hover": { background: grey[100] } }}
                        secondaryAction={
                          <Box
                            sx={{
                              display: "flex",
                              gap: theme.spacing(1),
                              alignItems: "center",
                            }}
                          >
                            {isFilterSchemeAdded(sf.uuId) && (
                              <Button
                                onClick={() =>
                                  handleDefault(
                                    getUserDatasetFilterSchemeUuId(sf.uuId)
                                  )
                                }
                                variant="outlined"
                                size="small"
                              >
                                {isFilterSchemeDefault(sf.uuId)
                                  ? "Undo Default"
                                  : "Make Default"}
                              </Button>
                            )}
                            <Button
                              onClick={() => handleAddRemove(sf.uuId)}
                              variant={
                                isFilterSchemeAdded(sf.uuId)
                                  ? "outlined"
                                  : "contained"
                              }
                              size="small"
                            >
                              {isFilterSchemeAdded(sf.uuId) ? "Delete" : "Add"}
                            </Button>
                          </Box>
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ fontWeight: 700, color: "inherit" }}
                              variant="subtitle1"
                            >
                              {sf.name}
                            </Typography>
                          }
                          secondary={sf.description}
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  )
                )}
              </List>
            </Scrollbar>
          )}
        {sourceFilterSchemesData &&
          _.isArray(sourceFilterSchemesData) &&
          sourceFilterSchemesData.length === 0 && (
            <NtaiEmptyMessage
              vAlign="center"
              header="No filter schemes available"
              subheader="Source filter schemes can be defined under Source Manager - Templates - Filter Schemes."
            />
          )}
      </Box>
    </NtaiPanel>
  );
}
