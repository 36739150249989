import React from "react";
import { NtaiDnd2 } from "./NtaiDnd2";

const items = [
  { id: 1, name: "Item 1", column: "Column 1" },
  { id: 2, name: "Item 2", column: "Column 1" },
  { id: 3, name: "Item 3", column: "Column 1" },
];

export default function TestNtaiDnd2() {
  function handleCallback(items) {
    console.log("Revised items: ", items);
  }
  return (
    <NtaiDnd2
      direction="column"
      idField="id"
      nameField="name"
      handleCallback={handleCallback}
      data={items}
      column1="Column 1"
      column2="Column 2"
    />
  );
}
