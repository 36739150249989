import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import {
  CODELIST_CODES,
  SRC_SOURCE_STORE_FORMAT,
} from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

const reservedFields = [
  "sys_is_current",
  "sys_is_deleted",
  "sys_valid_from",
  "sys_valid_to",
  "sys_date_created",
  "sys_date_modified",
];

function isReservedField(dbColName) {
  return reservedFields.includes(dbColName) ? true : false;
}

export default function SourceOutStoreDbDatasetFieldForm({
  sourceFieldId,
  dataMap,
  handleSave,
}) {
  const theme = useTheme();
  const { reset, getValues, setValue, watch } = useFormContext();

  const currentData = _.get(dataMap, sourceFieldId);

  const codes = useSelector((state) => state.core.codelistsSlice.codes);

  const dbColDataTypeOptions = _.get(
    codes,
    CODELIST_CODES.SRC_SOURCE_STORE_DB_COL_DATA_TYPE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOutStoreDbDatasetFieldForm")
    );

    sanitizedFormData["sourceStoreFormatCode"] = SRC_SOURCE_STORE_FORMAT.RDBMS;
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    if (!_.isEmpty(dataMap)) {
      reset({ sourceOutStoreDbDatasetFieldForm: currentData });
    }
  }, [sourceFieldId]);

  return (
    <React.Fragment>
      {!_.isEmpty(dataMap) &&
        Object.values(dataMap).map((sfd, i) => (
          <React.Fragment key={`source-field-${i}`}>
            {sfd.uuId === sourceFieldId && (
              <NtaiFormPanel
                header={sfd.name}
                subheader={sfd.label}
                handleSave={onHandleSave}
              >
                <NtaiTextField
                  name="sourceOutStoreDbDatasetFieldForm.dbColName"
                  label="Column Name*"
                  placeholder="Column Name"
                  disabled={isReservedField(sfd.name) ? true : false}
                  rules={{
                    required: "Column name is required",
                    validate: NtaiUtils.validateDBColumnNameForRHF,
                  }}
                />

                <NtaiSelectField
                  name="sourceOutStoreDbDatasetFieldForm.dbColDataTypeCode"
                  options={dbColDataTypeOptions}
                  label="Column Type*"
                  isDisabled={isReservedField(sfd.name) ? true : false}
                  rules={{ required: "Column data type is required" }}
                />

                <NtaiTextField
                  name="sourceOutStoreDbDatasetFieldForm.dbColLength"
                  type="number"
                  label="Column Length"
                  placeholder="Column Length"
                  disabled={isReservedField(sfd.name) ? true : false}
                />
                <Box
                  sx={{ display: "flex", width: "100%", gap: theme.spacing(1) }}
                >
                  <NtaiTextField
                    name="sourceOutStoreDbDatasetFieldForm.dbColScale"
                    type="number"
                    label="Column Scale"
                    placeholder="Column Scale"
                    disabled={isReservedField(sfd.name) ? true : false}
                  />
                  <NtaiTextField
                    name="sourceOutStoreDbDatasetFieldForm.dbColPrecision"
                    type="number"
                    label="Column Precision"
                    placeholder="Column Precision"
                    disabled={isReservedField(sfd.name) ? true : false}
                  />
                </Box>
              </NtaiFormPanel>
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
}
