import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import History from "@ntai/@history";
import { clearStatus, setActiveId, updatePackage } from "./store/packagesSlice";
import PackageForm from "./PackageForm";
const _ = require("lodash");

export default function PackageEdit(props) {
  const dispatch = useDispatch();

  const id = props.match.params.id;

  const dataMap = useSelector((state) => state.models.packagesSlice.data);
  const status = useSelector((state) => state.models.packagesSlice.status);
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updatePackage({
        uuId: id,
        formData: formData,
      })
    );
  }

  function handleCancel() {
    History.push("/models/packages");
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.method === "updatePackage" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back="/models/packages"
    >
      <NtaiForm>
        <PackageForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
