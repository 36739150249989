import {
  faMaximize,
  faSquareXmark,
  faWindowMaximize,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";
import SourceDatasetInStoreBottomDrawer from "../in-stores/SourceDatasetInStoreBottomDrawer";
import { useDispatch } from "react-redux";
import Scrollbar from "@ntai/components/Scrollbar";
import SourceDatasetPipelineRefSourceDatasetBottomDrawer from "../ref-datasets/SourceDatasetSidePanelRefSourceDatasetBottomDrawer";
import SourceDatasetSidePanelDatasetBottomDrawer from "../datasets/SourceDatasetSidePanelDatasetBottomDrawer";
import SourceDatasetSidePanelStageBottomDrawer from "../stages/SourceDatasetSidePanelStageBottomDrawer";
export default function SourceDatasetBottomDrawer({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { sidePanelMenuParam } = React.useContext(SourceDatasetManagerContext);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        border: theme.general.border1,
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          borderTop: theme.general.border1,
          display: "flex",
          height: "36px",
          width: "100%",
          justifyContent: "space-between",
          borderBottom: theme.general.border1,
          boxShadow: theme.colors.shadows.cardSm,
        }}
      >
        <Box
          sx={{
            pl: theme.spacing(1),
            display: "flex",
            width: "75%",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" color="inherit" fontWeight="700">
            {sidePanelMenuParam["label"]}
          </Typography>
        </Box>
        <Box
          sx={{
            pr: theme.spacing(1),
            display: "flex",
            width: "25%",
            alignItems: "center",
            gap: theme.spacing(1),
            justifyContent: "end",
          }}
        >
          <IconButton sx={{ cursor: "default" }} size="small">
            <FontAwesomeIcon size="xs" icon={faWindowMaximize} />
          </IconButton>
          <IconButton sx={{ cursor: "default" }} size="small">
            <FontAwesomeIcon size="xs" icon={faMaximize} />
          </IconButton>
          <IconButton
            sx={{ cursor: "default" }}
            size="small"
            onClick={() => handleClose()}
          >
            <FontAwesomeIcon size="xs" icon={faSquareXmark} />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 36px)`,
          width: "100%",
        }}
      >
        <Scrollbar>
          <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
            {sidePanelMenuParam["menuId"] === 1 && (
              <SourceDatasetInStoreBottomDrawer />
            )}
            {sidePanelMenuParam["menuId"] === 2 && (
              <SourceDatasetPipelineRefSourceDatasetBottomDrawer />
            )}

            {sidePanelMenuParam["menuId"] === 3 && (
              <SourceDatasetSidePanelStageBottomDrawer />
            )}

            {sidePanelMenuParam["menuId"] === 4 && (
              <SourceDatasetSidePanelDatasetBottomDrawer />
            )}
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
}
