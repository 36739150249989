import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React, { useState } from "react";
import DashboardViewLayout from "./DashboardViewLayout";
import { useSelector } from "react-redux";
import DashboardWidgetBox from "./DashboardWidgetBox";
import { grey } from "@mui/material/colors";
import { DashboardViewContext } from "../DashboardViewForm";
const _ = require("lodash");

export default function DashboardViewLayoutDialog() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { handleFormSubmit } = React.useContext(DashboardViewContext);

  const dashboardId = useSelector(
    (state) => state.core.dashboardViewsSlice.activeId
  );
  const dataMap = useSelector((state) => state.core.dashboardViewsSlice.data);
  const status = useSelector((state) => state.core.dashboardViewsSlice.status);

  const dashboardData =
    dataMap && !_.isEmpty(dataMap) ? dataMap[dashboardId] : null;

  const viewDataLayout = dashboardData ? dashboardData["layout"] : null;

  const data =
    dashboardData &&
    !_.isEmpty(dashboardData) &&
    _.has(dashboardData, "dashboardWidgets")
      ? Object.values(dashboardData["dashboardWidgets"])
      : [];

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleAdjustLayout() {
    handleOpen();
  }

  function generateWidgetList() {
    let result = [];

    if (Array.isArray(data) && data.length > 0) {
      _.orderBy(data, ["name"], ["asc"]).forEach((widget, index) => {
        result.push(
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
            key={index}
          >
            <DashboardWidgetBox key={`widget-${index}`} widget={widget} />
          </Box>
        );
      });
    }

    // if (result.length === 0) {
    //   result.push(
    //     <Box
    //       key="widget-empty-id"
    //       sx={{
    //         width: "100%",
    //         display: "flex",
    //         justifyContent: "center",
    //         flexDirection: "column",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography variant="body1" fontWeight="700">
    //         No widgets found.
    //       </Typography>
    //       <Typography variant="subtitle1">
    //         Follow the steps to add widgets to the view
    //       </Typography>
    //     </Box>
    //   );
    // }

    return result;
  }

  const debouncedOnHandleLayoutChange = _.debounce(handleLayoutChange, 500);

  function handleLayoutChange(layout) {
    if (
      _.isArray(layout) &&
      layout.length > 0 &&
      !_.isEqual(layout, dashboardData["layout"])
    ) {
      let tmpViewData = _.cloneDeep(dashboardData);
      tmpViewData["layout"] = layout;
      tmpViewData["dashboardWidgetUuIds"] = _.isArray(
        dashboardData["dashboardWidgets"]
      )
        ? dashboardData["dashboardWidgets"].map((o) => o.uuId)
        : [];

      handleFormSubmit(tmpViewData);
    }
  }

  return (
    <React.Fragment>
      <Button
        sx={{ fontSize: "12px" }}
        size="small"
        onClick={() => handleAdjustLayout()}
      >
        ADJUST LAYOUT
      </Button>
      <NtaiDialog
        title="Dashboard Layout"
        size="lg"
        open={open}
        handleDialogClose={handleClose}
      >
        <Box
          sx={{ display: "flex", height: "450px", justifyContent: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              background: grey[100],
            }}
          >
            <DashboardViewLayout
              layout={viewDataLayout}
              generateWidgetList={generateWidgetList}
              handleLayoutChange={debouncedOnHandleLayoutChange}
            />
          </Box>
        </Box>
      </NtaiDialog>
    </React.Fragment>
  );
}
