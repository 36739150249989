import { Box, Button, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceLinkedSourceFieldWrapper from "./field/SourceLinkedSourceFieldWrapper";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import { getSourceFields } from "../store/sourcesSlice";
const _ = require("lodash");

export default function SourceLinkedSourceForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleCancel } = props;
  const { reset, setValue, getValues } = useFormContext();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const allSources = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const allOtherSources = _.filter(Object.values(allSources), function (o) {
    return o.uuId !== sourceDefId;
  });

  const allOtherSourcesOptions = NtaiAppUtils.getSourcesForSelectOption(
    Object.values(allOtherSources)
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("linkedSourceForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ linkedSourceForm: action === "edit" ? formData : {} });
    //dispatch(getSourceDefinitions({ exclude: sourceDefId }));
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box sx={{ display: "flex", flexBasis: "50%", height: "100%" }}>
        <NtaiFormPanel
          header="General Details"
          subheader="Select source to be linked with the current source"
          width="100%"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiSelectField
            name="linkedSourceForm.linkedSourceUuId"
            options={allOtherSourcesOptions}
            isDisabled={action === "edit" ? true : false}
            label="Linked Source*"
            rules={{ required: "Linked source is required" }}
          />
        </NtaiFormPanel>
      </Box>
      {action === "edit" && (
        <SourceLinkedSourceFieldWrapper
          linkedSourceId={_.get(formData, "uuId")}
        />
      )}
    </Box>
  );
}
