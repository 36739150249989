import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

export default function NtaiSimpleTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    rowActions,
    rowOtherActions,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function buildHeader(headCell) {
    return headCell.ignore ? null : (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        // padding={headCell.disablePadding ? "none" : "default"}
        sortDirection={orderBy === headCell.id ? order : false}
        sx={{
          px: "8px",
          width: headCell.width ? headCell.width : "auto",
        }}
        // style={{ fontWeight: "normal" }}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: "2%" }} padding="checkbox">
          <Checkbox
            size="small"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all rows" }}
          />
        </TableCell>
        {headCells.map((headCell) => buildHeader(headCell))}
        {rowActions &&
          rowActions.map((rowAction, index) => (
            <TableCell key={index}></TableCell>
          ))}
        {rowOtherActions &&
          Array.isArray(rowOtherActions) &&
          rowOtherActions.length > 0 && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

NtaiSimpleTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};
