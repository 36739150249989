import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "../../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../../node_modules/react-resizable/css/styles.css";
import classes from "./DashboardGrid.module.css";
import { Resizable } from "react-resizable";
import GridLayout from "react-grid-layout";
import { Box } from "@mui/material";
const _ = require("lodash");
const ReactGridLayout = WidthProvider(RGL);

export default class DashboardGrid extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    items: 20,
    rowHeight: 120,
    onLayoutChange: function (layout) {
      //console.log("Layout changed..... to ", layout);
      this.props.handleLayoutChange(layout);
    },
    cols: 12,
  };

  constructor(props) {
    super(props);

    const layout = this.props.layout
      ? this.props.layout
      : this.generateLayout();
    this.state = { layout };
  }

  generateDOM() {
    return this.props.items.map(
      (item, i) => item
      // <div className="flex w-full h-full">{item}</div>
    );
  }

  generateLayout() {
    const p = this.props;
    return _.map(new Array(p.items), function (item, i) {
      const y = _.result(p, "y") || Math.ceil(Math.random() * 2) + 1;
      return {
        x: (i * 2) % 12,
        y: Math.floor(i / 6) * y,
        w: 2,
        h: y,
        i: i.toString(),
        minH: 2,
        minW: 2,
      };
    });
  }

  onLayoutChange(layout) {
    this.props.handleLayoutChange(layout);
  }

  render() {
    return (
      <GridLayout
        width={1410}
        layout={this.state.layout}
        onLayoutChange={this.props.handleLayoutChange}
        // {...this.props}
      >
        {this.generateDOM()}
      </GridLayout>
    );
  }
}
