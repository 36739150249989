import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { grey } from "@mui/material/colors";
import {
  tooltipConfig,
  gridConfig,
  xAxisLabelConfig,
  yAxisLabelConfig,
  legendConfig,
} from "../chartConfig";
import { Box, lighten } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
const _ = require("lodash");

const DEFAULT_OPTION = {
  legend: legendConfig,
  tooltip: tooltipConfig,
  xAxis: {
    type: "category",
    data: null,
    axisLabel: xAxisLabelConfig,
    axisLine: {
      lineStyle: {
        width: 0.1,
      },
    },
    axisTick: {
      lineStyle: {
        width: 0.2,
      },
    },
  },
  yAxis: {
    type: "value",
    axisLabel: yAxisLabelConfig,
  },
  series: [],
};

export default function NtaiLineEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function onChartClick(params) {
    const sourceWidgetFilterFields =
      NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
        [1, 2],
        params,
        metadataRef.current,
        CHART_LEFT_CLICK_ACTION
      );

    handleClick(sourceWidgetFilterFields);
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(
      action,
      NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
        [1, 2],
        params,
        metadataRef.current,
        action
      )
    );
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);
    const colors = NtaiEChartUtil.getColorPalette(theme, data["series"].length);
    optionCopy["color"] = colors;
    optionCopy["xAxis"]["data"] = data["category"]["data"];

    data["series"].forEach((s, i) => {
      optionCopy["series"].push({
        name: s["name"],
        data: s["data"],
        type: "line",
        smooth: true,
        symbolSize: 6,
        lineStyle: {
          color: colors[i],
          width: 4,
        },
      });
    });
    setOption(optionCopy);
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"].length > 0 && chart}
    </Box>
  );
}
