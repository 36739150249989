import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormControl, InputBase } from "@mui/material";

const NtaiMuiInputBase = (props) => {
  const { label, name } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl fullWidth={true}>
      {/* <InputLabel htmlFor={name}>{label}</InputLabel> */}
      <InputBase id={name} {...props} />
      {/* {errors.name?.type === "required" && (
        <div className="text-red-500 text-xs">Name is required</div>
      )} */}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="text-red-500 text-sm">{message}</div>
        )}
      />
    </FormControl>
  );
};

function NtaiInputBase(props) {
  const { control } = useFormContext();
  const { name, label } = props;
  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <NtaiMuiInputBase
            value={value || ""}
            onChange={onChange}
            {...props}
          />
        )}
        control={control}
        name={name}
        label={label}
        defaultValue=""
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiInputBase;
