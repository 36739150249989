import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { PropTypes } from "prop-types";
import NtaiIcons from "utils/NtaiIcons";
import NtaiBadge from "../badges/NtaiBadge";
const _ = require("lodash");

export default function NtaiMenuList(props) {
  const theme = useTheme();
  const [selectedMenuItemIds, setSelectedMenuItemIds] = useState([]);
  const { items, divider, handleSelect } = props;

  function handleClick(itemId) {
    if (selectedMenuItemIds.includes(itemId)) {
      // close
      setSelectedMenuItemIds(
        _.filter(selectedMenuItemIds, function (o) {
          return o !== itemId;
        })
      );
    } else {
      // open
      setSelectedMenuItemIds([...selectedMenuItemIds, itemId]);
      handleSelect(itemId);
    }
  }

  function generateListItems(nodes, level) {
    return nodes.map((node, index) => {
      return (
        <React.Fragment key={`list-item-${index}`}>
          <ListItemButton onClick={() => handleClick(node.id)}>
            {node.icon && (
              <ListItemIcon sx={{ mr: 0 }}>
                <FontAwesomeIcon
                  size={node.iconSize ? node.iconSize : null}
                  icon={NtaiIcons[node.icon]}
                />
              </ListItemIcon>
            )}
            <ListItemText
              primary={_.get(node, "primary")}
              secondary={_.get(node, "secondary")}
              inset={level === 0 ? false : true}
            />
            {node.badge ? <NtaiBadge {...node.badge} /> : null}
            {_.has(node, "children") &&
              (selectedMenuItemIds.includes(node.id) ? (
                <ExpandMore />
              ) : (
                <ExpandLess />
              ))}
          </ListItemButton>
          {_.has(node, "children") && (
            <Collapse
              in={selectedMenuItemIds.includes(node.id)}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {generateListItems(_.get(node, "children"), level + 1)}
              </List>
            </Collapse>
          )}

          {divider ? <Divider /> : null}
        </React.Fragment>
      );
    });
  }

  return (
    <List sx={{ width: "100%", maxWidth: 420 }}>
      {generateListItems(items, 0)}
    </List>
  );
}

NtaiMenuList.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};
