import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { red } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import { GL_RUN_STATUS_CODE } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
const _ = require("lodash");

export default function CodeEditorResult({
  result,
  statusCode,
  statusDecode,
  message,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "120px",
        border: "2px solid #eee",
        display: "flex",
        border: "2px solid #eee",
        borderLeft: "4px solid orange",
        borderRadius: "8px",
        background: statusCode === GL_RUN_STATUS_CODE.ERROR ? red[100] : null,
      }}
    >
      <Scrollbar>
        {Array.isArray(result) && result.length > 0 && (
          <Box sx={{ padding: theme.spacing(1) }}>
            <Table>
              <TableHead>
                <TableRow>
                  {result &&
                    result[0] &&
                    Object.keys(result[0]).map((k, i) => (
                      <TableCell sx={{ p: theme.spacing(1) }} key={i}>
                        {k}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {result.map((item, index) => (
                  <TableRow key={index}>
                    {Object.values(item).map((val, i) => (
                      <TableCell
                        key={i}
                        sx={{
                          p: theme.spacing(1),
                          verticalAlign: "top",
                        }}
                      >
                        {_.isArray(val) || _.isObject(val)
                          ? JSON.stringify(val)
                          : val}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}

        {statusCode === GL_RUN_STATUS_CODE.ERROR && (
          <Box
            sx={{
              p: theme.spacing(1),
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            {message}
          </Box>
        )}
      </Scrollbar>
    </Box>
  );
}
