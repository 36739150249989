import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { SearchSourceContext } from "../../../SearchSource";
import {
  clearStatus,
  createSearchSourceRecordNote,
  deleteSearchSourceRecordNote,
  getSearchSourceRecordNotes,
  updateSearchSourceRecordNote,
} from "./store/searchSourceRecordNotesSlice";
import SearchSourceRecordNoteForm from "./SearchSourceRecordNoteForm";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import Scrollbar from "@ntai/components/Scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faNote } from "@fortawesome/pro-regular-svg-icons";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { useSnackbar } from "notistack";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { SRC_SOURCE_RECORD_SUMMARY } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "note.title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    fontWeight: 700,
    width: "45%",
  },

  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Date",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

export default function SearchSourceRecordNoteList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { selectedRecord, setRefreshSelectedRecord } = React.useContext(
    SearchSourceRecordListContext
  );

  const searchId = useSelector((state) => state.search.searchSlice.activeId);
  const recordId = _.get(selectedRecord, "recordId");
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const selectedSearchSourceRecordUuId =
    selectedRecord &&
    selectedRecord["uuId"] &&
    selectedRecord["uuId"].length > 0
      ? _.get(selectedRecord, "uuId")
      : "*";

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordNotesSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const status = useSelector(
    (state) => state.search.searchSourceRecordNotesSlice.status
  );

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "note.title"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(
          deleteSearchSourceRecordNote({
            searchId: searchId,
            searchSourceId: searchSourceId,
            searchSourceRecordId: selectedSearchSourceRecordUuId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleCancel() {
    setAction("list");
  }

  function handleRefreshSelectedRecord() {
    setRefreshSelectedRecord({
      summaryType: SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_NOTES,
      recordIds: [selectedRecord["recordId"]],
    });
  }

  function handleSave(formData) {
    if (action === "add") {
      dispatch(
        createSearchSourceRecordNote({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          formData: {
            note: { ...formData, recordIds: [recordId] },
            recordId: recordId,
          },
        })
      );
    } else if (action === "edit") {
      dispatch(
        updateSearchSourceRecordNote({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          uuId: currentId,
          formData: {
            note: { ...formData, recordIds: [recordId] },
            recordId: recordId,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordNotes"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSearchSourceRecordNote", "updateSearchSourceRecordNote"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setAction("list");
      dispatch(clearStatus());
      enqueueSnackbar(`Note saved successfully.`, {
        variant: "success",
      });
      handleRefreshSelectedRecord();
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSearchSourceRecordNote"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Note(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
      handleRefreshSelectedRecord();
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (recordId) {
      dispatch(
        getSearchSourceRecordNotes({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          formData: {
            recordId: recordId,
            sourceId: sourceId,
          },
        })
      );
    }
  }, [recordId]);

  return (
    <>
      {action === "list" && (
        <NtaiPanel
          width="100%"
          header="Notes"
          subheader="Notes can be shared with other users in different modes."
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            keyColumn="note.uuId"
            toolbarActions={toolbarActions}
            selectedIds={selectedIds}
            handleToolbarAction={handleToolbarAction}
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            title="Note"
          />
        </NtaiPanel>
      )}
      {(action === "add" || action === "edit") && (
        <NtaiForm>
          <SearchSourceRecordNoteForm
            action={action}
            formData={
              action === "edit"
                ? _.get(_.get(dataMap, currentId), "note")
                : null
            }
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "note.title")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
