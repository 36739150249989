import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import {
  getSourceOutStores,
  clearStatus as clearSourceOutStoresStatus,
} from "../out/store/sourceOutStoresSlice";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceOutJobFrequency from "./frequency/SourceOutJobFrequency";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export const SourceOutJobContext = React.createContext();

export default function SourceOutJobForm(props) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const dispatch = useDispatch();

  const {
    action,
    handleDialogClose,
    formData,
    handleFormSubmit,
    handleSubmitJob,
  } = props;
  const [selectedRunMode, setSelectedRunMode] = useState(1);
  const [loaded, setLoaded] = React.useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceStoresDataMap = useSelector(
    (state) => state.sources.sourceOutStoresSlice.data
  );

  const sourceStoresStatus = useSelector(
    (state) => state.sources.sourceOutStoresSlice.status
  );

  const sourceStoresData =
    sourceStoresDataMap && !_.isEmpty(sourceStoresDataMap)
      ? Object.values(sourceStoresDataMap)
      : [];

  function onHandleSave() {
    const sourceOutJobFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOutJobForm")
    );
    const outStoreUuIdsData = _.get(
      getValues("sourceOutJobForm"),
      "sourceOutStoreUuIds"
    );
    const finalUuIds = [];

    if (outStoreUuIdsData && !_.isEmpty(outStoreUuIdsData)) {
      Object.keys(outStoreUuIdsData).forEach((outStoreUuId, i) => {
        if (outStoreUuIdsData[outStoreUuId]) finalUuIds.push(outStoreUuId);
      });
    }

    let revisedFormData = _.cloneDeep(sourceOutJobFormData);
    revisedFormData["sourceOutStoreUuIds"] = finalUuIds;
    revisedFormData["runModeCode"] = selectedRunMode === 1 ? 1 : 0;
    handleFormSubmit(revisedFormData);
  }

  function onHandleOtherAction(val) {
    if (val === "submit") {
      handleSubmitJob();
    }
  }

  useEffect(() => {
    if (
      sourceStoresStatus &&
      sourceStoresStatus.method === "getSourceOutStores" &&
      sourceStoresStatus.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearSourceOutStoresStatus());
    }
  }, [sourceStoresStatus]);

  useEffect(() => {
    dispatch(
      getSourceOutStores({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  useEffect(() => {
    if (action === "edit") {
      const sourceOutStoreUuIds =
        formData &&
        _.has(formData, "sourceOutStoreUuIds") &&
        _.get(formData, "sourceOutStoreUuIds");

      const sourceOutStoreUuIdsObj = {};
      if (_.isArray(sourceOutStoreUuIds)) {
        sourceOutStoreUuIds.forEach((sourceOutStoreUuId, i) => {
          sourceOutStoreUuIdsObj[sourceOutStoreUuId] = true;
        });
      }

      let revisedFormData = _.cloneDeep(formData);
      revisedFormData["sourceOutStoreUuIds"] = sourceOutStoreUuIdsObj;
      reset({ sourceOutJobForm: revisedFormData });
    }
  }, []);

  return (
    <SourceOutJobContext.Provider
      value={{
        selectedRunMode,
        setSelectedRunMode,
      }}
    >
      <NtaiDialogFormPanel
        subheader="Select target stores, specify frequency and schedule migration jobs."
        handleSave={onHandleSave}
        handleCancel={handleDialogClose}
        handleOtherAction={onHandleOtherAction}
        otherActions={[
          {
            value: "submit",
            label: "Submit",
            disabled:
              action === "edit" && formData["statusCode"] === 2
                ? true
                : action === "add"
                ? true
                : false,
          },
        ]}
      >
        {loaded && (
          <NtaiTabs>
            <Box
              label="GENERAL"
              sx={{
                height: "300px",
                p: theme.spacing(2),
                display: "flex",
                gap: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  flexBasis: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(2),
                }}
              >
                <Typography variant="h6">General Details</Typography>
                <NtaiTextField
                  name="sourceOutJobForm.name"
                  label="Name"
                  disabled={true}
                  rules={{ required: "Name is required" }}
                  defaultValue={`Job-${Date.now().toString()}`}
                />

                <NtaiTextField
                  name="sourceOutJobForm.description"
                  label="Description"
                  defaultValue="description"
                  multiline
                  minRows={4}
                  maxRows={4}
                />
                <NtaiSwitchField
                  disabled={action === "edit" ? true : false}
                  name="sourceOutJobForm.customFieldsUpdateJobFg"
                  label="Custom Fields Update Job?"
                />
              </Box>
              <Box
                sx={{
                  flexBasis: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
                }}
              >
                <Typography variant="h6">Select Target Stores</Typography>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    border: theme.general.border1,
                  }}
                >
                  {sourceStoresData &&
                    _.isArray(sourceStoresData) &&
                    sourceStoresData.length > 0 && (
                      <Scrollbar>
                        <Box>
                          <List sx={{ p: 0 }}>
                            {sourceStoresData.map((ssd, i) => (
                              <React.Fragment key={`source-job-store-${i}`}>
                                <ListItem
                                  sx={{ p: 0 }}
                                  secondaryAction={
                                    <NtaiCheckboxField
                                      name={`sourceOutJobForm.sourceOutStoreUuIds.${ssd.uuId}`}
                                    />
                                  }
                                >
                                  <ListItemButton>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="body2"
                                          fontWeight="700"
                                          color="inherit"
                                        >
                                          {ssd.formatDecode}
                                        </Typography>
                                      }
                                      secondary={ssd.dateModified}
                                    />
                                  </ListItemButton>
                                </ListItem>
                                <Divider />
                              </React.Fragment>
                            ))}
                          </List>
                        </Box>
                      </Scrollbar>
                    )}
                </Box>
              </Box>
            </Box>

            <Box
              label="FREQUENCY"
              sx={{
                display: "flex",
                height: "300px",
                gap: theme.spacing(2),
              }}
            >
              <SourceOutJobFrequency />
            </Box>
          </NtaiTabs>
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiDialogFormPanel>
    </SourceOutJobContext.Provider>
  );
}
