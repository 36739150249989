import { Box } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

export default function NtaiForm(props) {
  const methods = useForm({ mode: "all" });

  const handleOnSubmit = (data) => {
    //props.handleFormSubmit(data);
    // const isValid = methods.trigger();
    // console.log("Is Valid: ", isValid);
  };

  const handleOnErrors = (errors) => {
    // e.preventDefault();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          id={props.id ? props.id : "main-form"}
          style={{
            display: "inline",
            // position: "fixed",
            width: "100%", // to be checked
            height: "100%",
            // background: "limegreen",
          }}
          onSubmit={methods.handleSubmit(handleOnSubmit, handleOnErrors)}
        >
          {/* {props.children} */}
          {React.cloneElement(props.children, { ...methods })}
        </form>
      </FormProvider>
    </>
  );
}
