import { Box } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import ObjectInfoListingFields from "./listing/ObjectInfoListingFields";
import ObjectInfoGeneralFields from "./general/ObjectInfoGeneralFields";

export default function ObjectInfoFieldsWrapper() {
  return (
    <NtaiPanel
      width="100%"
      header="Object Display Fields"
      subheader="You can select fields to manage the appearance of object data entity in general, listing and individual record display sections"
    >
      <NtaiTabs>
        <Box label="GENERAL">
          <ObjectInfoGeneralFields />
        </Box>
        <Box sx={{ width: "100%" }} label="LISTING">
          <ObjectInfoListingFields />
        </Box>
        <Box label="RECORD"></Box>
      </NtaiTabs>
    </NtaiPanel>
  );
}
