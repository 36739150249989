import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { resetIdCounter } from "downshift";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import SourceWidgetUtil from "../../util/SourceWidgetUtil";
import { useSelector } from "react-redux";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

export default function SourceWidgetFieldsForm(props) {
  const theme = useTheme();
  const { setValue, reset } = useFormContext();
  const { widget, hideFieldOption, summaryTabOptions } = props;
  const widgetFields = widget ? widget["sourceWidgetFields"] : [];

  const [selectedField, setSelectedField] = useState(
    _.isArray(widgetFields) ? _.get(widgetFields[0], "uuId") : null
  );

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const aggFuncCodeOptions = _.get(
    codes,
    CODELIST_CODES.SRC_SOURCE_VIEW_WIDGET_METRIC_CODELIST
  );

  const dateIntervalOptions = _.get(
    codes,
    CODELIST_CODES.SRC_SOURCE_WIDGET_FIELD_DATE_INTERVAL_CODE
  );

  function selectField(widgetField) {
    setSelectedField({ ...widgetField });
  }

  useEffect(() => {
    if (widgetFields && _.isArray(widgetFields)) {
      setSelectedField({ ...widgetFields[0] });
    }
  }, [widget]);

  return (
    <NtaiPanel
      width="100%"
      subheader="Enter field labels and optionally select aggregate metric for measures fields."
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          gap: theme.spacing(2),
        }}
      >
        {selectedField &&
          widgetFields &&
          _.isArray(widgetFields) &&
          widgetFields.length > 0 && (
            <>
              <Box
                sx={{
                  pt: theme.spacing(1),
                  flexBasis: "40%",
                  display: "flex",
                  height: "180px",
                  border: theme.general.border1,
                }}
              >
                <Scrollbar>
                  <List
                    sx={{
                      paddingR: theme.spacing(2),
                      width: "100%",
                    }}
                  >
                    {widgetFields.map((wf, i) => (
                      <React.Fragment key={`rf-list-item-button-${i}`}>
                        <ListItemButton
                          key={`list-item-button-${i}`}
                          sx={{ borderRadius: 0 }}
                          selected={
                            wf.uuId === selectedField["uuId"] ? true : false
                          }
                          onClick={() => selectField(wf)}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                color="inherit"
                                fontWeight="700"
                              >
                                {wf.fieldName}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Scrollbar>
              </Box>
              <Box sx={{ flexBasis: "60%", display: "flex" }}>
                <Box
                  sx={{
                    px: theme.spacing(1),
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(1),
                  }}
                >
                  <Typography
                    sx={{ pb: theme.spacing(1) }}
                    variant="h6"
                    fontWeight="700"
                  >
                    {selectedField ? _.get(selectedField, "fieldName") : null}
                  </Typography>

                  {widgetFields.map((wf, i) => (
                    <Box
                      key={`widget-field-div-${i}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(1),
                      }}
                    >
                      {wf.uuId === selectedField["uuId"] && (
                        <React.Fragment>
                          <NtaiTextField
                            key={`widget-field-textfield-${i}`}
                            name={`widgetForm.sourceWidgetFields[${i}].headerName`}
                            label="Label*"
                            rules={{ required: "Label is required" }}
                          />
                        </React.Fragment>
                      )}
                      {!hideFieldOption &&
                        wf.uuId === selectedField["uuId"] &&
                        SourceWidgetUtil.isWidgetFieldMeasure(wf) && (
                          <React.Fragment>
                            <NtaiSelectField
                              options={aggFuncCodeOptions}
                              name={`widgetForm.sourceWidgetFields[${i}].metricCode`}
                              label="Aggregation Type"
                            />
                          </React.Fragment>
                        )}
                      {!hideFieldOption &&
                        wf.uuId === selectedField["uuId"] &&
                        SourceWidgetUtil.isWidgetFieldDate(wf) && (
                          <React.Fragment>
                            <NtaiSelectField
                              options={dateIntervalOptions}
                              name={`widgetForm.sourceWidgetFields[${i}].dateIntervalCode`}
                              label="Aggregation Type"
                            />
                          </React.Fragment>
                        )}
                      {!hideFieldOption &&
                        summaryTabOptions &&
                        wf.uuId === selectedField["uuId"] && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: theme.spacing(1),
                            }}
                          >
                            {SourceWidgetUtil.isWidgetFieldMeasure(wf) && (
                              <NtaiSwitchField
                                name={`widgetForm.sourceWidgetFields[${i}].numBoolMetricSumFg`}
                                label="Use sum metric?"
                              />
                            )}
                            <NtaiSwitchField
                              name={`widgetForm.sourceWidgetFields[${i}].summaryTabLinkEnabledFg`}
                              label="Enable drill down link?"
                            />
                            {SourceWidgetUtil.isWidgetFieldMeasure(wf) && (
                              <NtaiTextField
                                name={`widgetForm.sourceWidgetFields[${i}].summaryTabLinkAddValue`}
                                label="Query Value"
                              />
                            )}

                            <NtaiTextField
                              multiline
                              minRows={3}
                              maxRows={3}
                              name={`widgetForm.sourceWidgetFields[${i}].valueFormatterScript`}
                              label="Value Formatter Script"
                            />
                          </Box>
                        )}
                      {!hideFieldOption && !summaryTabOptions && (
                        <NtaiTextField
                          multiline
                          minRows={3}
                          maxRows={3}
                          name={`widgetForm.sourceWidgetFields[${i}].valueFormatterScript`}
                          label="Value Formatter Script"
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}
      </Box>
    </NtaiPanel>
  );
}
