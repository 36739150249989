import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import {
  CODELIST_CODES,
  SRC_SOURCE_STORE_FORMAT,
} from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import SourceFieldSemTypeList from "./semtype/SourceFieldSemTypeList";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiMuiMultipleSelectField from "@ntai/components/inputs/NtaiMuiMultipleSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
const _ = require("lodash");

export default function SourceOutElasticFieldForm({
  sourceFieldId,
  dataMap,
  handleSave,
}) {
  const theme = useTheme();
  const { reset, getValues, setValue, watch } = useFormContext();
  const [thesaurusSemTypeDialogOpen, setThesaurusSemTypeDialogOpen] =
    useState(false);

  const [showManageThesaurusSemTypes, setShowManageThesaurusSemTypes] =
    useState(false);

  //   console.log("Form data in field form: ", dataMap);
  //   console.log("selected field id: ", sourceFieldId);

  const watchEsDataType = watch("sourceOutElasticFieldForm.esDataTypeCode");
  const watchEsSearchStrategyCode = watch(
    "sourceOutElasticFieldForm.esSearchStrategyCode"
  );

  const currentData = _.get(dataMap, sourceFieldId);

  const codes = useSelector((state) => state.core.codelistsSlice.codes);

  const esDataTypeOptions = _.get(
    codes,
    CODELIST_CODES.SRC_SOURCE_FIELD_ES_DATA_TYPE_CODE
  );

  const strategyCodeOptions = _.get(
    codes,
    CODELIST_CODES.SRC_SOURCE_FIELD_STRATEGY_CODE
  );

  const sourceFieldLookupsData = useSelector(
    (state) => state.sources.sourceFieldLookupsSlice.data
  );

  const sourceFieldLookupOptions = NtaiUtils.getSelectOptions(
    Object.values(sourceFieldLookupsData),
    "uuId",
    "name"
  );

  function handleThesaurusSemTypeDialogOpen() {
    setThesaurusSemTypeDialogOpen(true);
  }

  function handleThesaurusSemTypeDialogClose() {
    setThesaurusSemTypeDialogOpen(false);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOutElasticFieldForm")
    );

    const lookupFieldUuIds = getValues(
      "sourceOutElasticFieldForm.lookupFieldUuIds"
    );

    let revisedFormData = _.cloneDeep(sanitizedFormData);

    revisedFormData["sourceStoreFormatCode"] = SRC_SOURCE_STORE_FORMAT.ELASTIC;
    revisedFormData["lookupFieldUuIds"] = lookupFieldUuIds;

    handleSave(revisedFormData);
  }

  useEffect(() => {
    let updatedEsDataTypeCode = null;

    if (_.isNumber(watchEsDataType)) {
      if (watchEsDataType !== _.get(currentData, "esDataTypeCode"))
        updatedEsDataTypeCode = watchEsDataType;
    } else if (_.isObject(watchEsDataType)) {
      if (
        _.get(watchEsDataType, "value") !== _.get(currentData, "esDataTypeCode")
      )
        updatedEsDataTypeCode = _.get(watchEsDataType, "value");
    }

    if (updatedEsDataTypeCode !== null) {
      let revisedFormData = _.cloneDeep(currentData);
      revisedFormData["esDataTypeCode"] = updatedEsDataTypeCode;
      revisedFormData["sourceStoreFormatCode"] =
        SRC_SOURCE_STORE_FORMAT.ELASTIC;
      handleSave(revisedFormData);
    }
  }, [watchEsDataType]);

  useEffect(() => {
    let tmpShowManageThesaurusSemTypes = false;
    let updatedEsSearchStrategyCode = null;
    if (_.isNumber(watchEsSearchStrategyCode)) {
      if (watchEsSearchStrategyCode === 3)
        tmpShowManageThesaurusSemTypes = true;

      if (
        watchEsSearchStrategyCode !== _.get(currentData, "esSearchStrategyCode")
      )
        updatedEsSearchStrategyCode = watchEsSearchStrategyCode;
    } else if (_.isObject(watchEsSearchStrategyCode)) {
      if (_.get(watchEsSearchStrategyCode, "value") === 3)
        tmpShowManageThesaurusSemTypes = true;

      if (
        _.get(watchEsSearchStrategyCode, "value") !==
        _.get(currentData, "esSearchStrategyCode")
      )
        updatedEsSearchStrategyCode = _.get(watchEsSearchStrategyCode, "value");
    }

    setShowManageThesaurusSemTypes(tmpShowManageThesaurusSemTypes);

    if (updatedEsSearchStrategyCode !== null) {
      let revisedFormData = _.cloneDeep(currentData);
      revisedFormData["esSearchStrategyCode"] = updatedEsSearchStrategyCode;
      revisedFormData["sourceStoreFormatCode"] =
        SRC_SOURCE_STORE_FORMAT.ELASTIC;
      handleSave(revisedFormData);
    }
  }, [watchEsSearchStrategyCode]);

  useEffect(() => {
    if (!_.isEmpty(dataMap))
      setValue("sourceOutElasticFieldForm.label", _.get(currentData, "label"));

    setValue(
      "sourceOutElasticFieldForm.primaryFieldFg",
      _.get(currentData, "primaryFieldFg")
    );

    setValue(
      "sourceOutElasticFieldForm.esDataTypeCode",
      _.get(currentData, "esDataTypeCode")
    );
    setValue(
      "sourceOutElasticFieldForm.esSearchStrategyCode",
      _.get(currentData, "esSearchStrategyCode")
    );

    setValue(
      "sourceOutElasticFieldForm.functionFieldFg",
      _.get(currentData, "functionFieldFg")
    );

    setValue(
      "sourceOutElasticFieldForm.functionFieldTitle",
      _.get(currentData, "functionFieldTitle")
    );

    setValue(
      "sourceOutElasticFieldForm.functionFieldDescription",
      _.get(currentData, "functionFieldDescription")
    );

    setValue(
      "sourceOutElasticFieldForm.lookupFieldUuIds",
      _.get(currentData, "lookupLinks").map((l, i) => l.lookupField.uuId)
    );

    // if (!_.isEmpty(dataMap)) setValue("sourceOutElasticFieldForm.esDataTypeCode", dataMap);
  }, [sourceFieldId]);

  return (
    <React.Fragment>
      {!_.isEmpty(dataMap) &&
        Object.values(dataMap).map((sfd, i) => (
          <React.Fragment key={`source-field-${i}`}>
            {sfd.uuId === sourceFieldId && (
              <Scrollbar>
                <NtaiFormPanel
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                    gap: theme.spacing(2),
                    p: theme.spacing(2),
                  }}
                  //   header={sfd.name}
                  //   subheader={sfd.label}
                  handleSave={onHandleSave}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      gap: theme.spacing(2),
                    }}
                  >
                    <Box
                      sx={{
                        flexBasis: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: theme.spacing(1),
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ flexBasis: "50%", display: "flex" }}>
                        <NtaiTextField
                          name="sourceOutElasticFieldForm.label"
                          label="Label*"
                          placeholder="Label"
                          rules={{ required: "Label is required" }}
                        />
                      </Box>

                      <Box sx={{ flexBasis: "50%", display: "flex" }}>
                        <NtaiSwitchField
                          name="sourceOutElasticFieldForm.primaryFieldFg"
                          label="Primary?"
                        />
                        <NtaiSwitchField
                          name="sourceOutElasticFieldForm.maskFg"
                          label="Mask?"
                        />
                      </Box>
                    </Box>
                  </Box>

                  <NtaiSelectField
                    label="Data Type*"
                    name={`sourceOutElasticFieldForm.esDataTypeCode`}
                    options={esDataTypeOptions}
                    rules={{ required: "Data type is required" }}
                  />

                  <NtaiSelectField
                    name={`sourceOutElasticFieldForm.esSearchStrategyCode`}
                    options={strategyCodeOptions}
                    label="Select Search Strategy"
                  />
                  {showManageThesaurusSemTypes && (
                    <Box sx={{ display: "flex" }}>
                      <Button
                        onClick={() => handleThesaurusSemTypeDialogOpen()}
                        variant="outlined"
                        size="small"
                      >
                        Manage Thesaurus Semantic Types
                      </Button>
                    </Box>
                  )}

                  <NtaiMuiMultipleSelectField
                    name="sourceOutElasticFieldForm.lookupFieldUuIds"
                    label="Lookup Links"
                    options={sourceFieldLookupOptions}
                  />

                  {/* <NtaiSwitchField
                    name="sourceOutElasticFieldForm.functionFieldFg"
                    label="Function Field?"
                  />

                  <NtaiTextField
                    name="sourceOutElasticFieldForm.functionFieldTitle"
                    label="Function field title"
                    placeholder="Function field title"
                  />

                  <NtaiTextField
                    name="sourceOutElasticFieldForm.functionFieldDescription"
                    label="Function field description"
                    placeholder="Function field description"
                    multiline
                    minRows={2}
                    maxRows={2}
                  /> */}

                  {/* <Box sx={{ py: theme.spacing(1), display: "flex" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => onHandleSave()}
                    >
                      SAVE
                    </Button>
                  </Box> */}
                </NtaiFormPanel>
              </Scrollbar>
            )}
          </React.Fragment>
        ))}

      <NtaiDialog
        size="sm"
        open={thesaurusSemTypeDialogOpen}
        handleDialogClose={handleThesaurusSemTypeDialogClose}
        title="Manage Thesaurus Semantic Types"
      >
        <SourceFieldSemTypeList fieldId={sourceFieldId} />
      </NtaiDialog>
    </React.Fragment>
  );
}
