import { FormControlLabel, Switch, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const NtaiMuiSwitch = (props) => {
  const { label, name } = props;
  const theme = useTheme();

  return (
    <FormControlLabel
      sx={{ pl: theme.spacing(1.5) }}
      label={
        label ? (
          <Typography variant="subtitle1" color="inherit">
            {label}
          </Typography>
        ) : (
          ""
        )
      }
      control={<Switch id={name} {...props} />}
    ></FormControlLabel>
  );
};

export default function NtaiSwitchField(props) {
  const { control } = useFormContext();
  const { name } = props;
  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <NtaiMuiSwitch
            checked={
              typeof value === "string"
                ? value === "1"
                  ? true
                  : false
                : typeof value === "boolean"
                ? value === true
                  ? true
                  : false
                : typeof value === "number"
                ? value === 1
                  ? true
                  : false
                : false
            }
            value={value || "off"}
            // onChange={(e) => (e.target.checked ? onChange(1) : onChange(0))}
            // onChange={(e) => onChange(e.target.checked)}
            onChange={(e, v) => onChange(v)}
            {...props}
          />
        )}
        control={control}
        name={name}
        {...props}
      />
    </React.Fragment>
  );
}
