import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlerts = createAsyncThunk(
  "alerts/getAlerts",
  async (values, { rejectWithValue }) => {
    try {
      const { masterId, topicId, params } = values;
      const response = await server.get(
        `/monitor/alerts/masters/${masterId}/topics/${topicId}/activity-groups`,
        params && !_.isEmpty(params)
          ? {
              params: params,
            }
          : {}
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAlert = createAsyncThunk(
  "alerts/deleteAlert",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, uuId } = values;
      const response = await server.delete(
        `/monitor/alerts"/${alertId}/activities/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertsSlice = createSlice({
  name: "alertsSlice",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlerts.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "gUuId") };
      state.status = { result: "success", method: "getAlerts" };
    },
    [getAlerts.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlerts",
        message: action.payload.message,
      };
    },
    [deleteAlert.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.gUuId);
      state.status = { result: "success", method: "deleteAlert" };
    },
    [deleteAlert.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAlert",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = alertsSlice.actions;

export default alertsSlice.reducer;
