import { useState, useEffect, useRef } from "react";

function useTimer() {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);

  // Start the timer
  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      timerRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  // Stop the timer
  const stopTimer = () => {
    if (isRunning) {
      clearInterval(timerRef.current);
      setIsRunning(false);
      setTime(0);
    }
  };

  // Clear the timer when the component is unmounted
  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  // Format time into hours, minutes, or seconds
  const formatTime = () => {
    if (time < 60) {
      return `${time}s`;
    } else if (time < 3600) {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}m, ${seconds}s`;
    } else {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      return `${hours}h, ${minutes}m`;
    }
  };

  return { time: formatTime(), isRunning, startTimer, stopTimer };
}

export default useTimer;
