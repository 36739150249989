import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourcePolicyDefs = createAsyncThunk(
  "sourcePolicyDefs/getSourcePolicyDefs",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policy-definitions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourcePolicyDef = createAsyncThunk(
  "sourcePolicyDefs/getSourcePolicyDef",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policy-definitions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourcePolicyDef = createAsyncThunk(
  "sourcePolicyDefs/createSourcePolicyDef",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policy-definitions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourcePolicyDef = createAsyncThunk(
  "sourcePolicyDefs/updateSourcePolicyDef",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policy-definitions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourcePolicyDef = createAsyncThunk(
  "sourcePolicyDefs/deleteSourcePolicyDef",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/policy-definitions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePolicyDefsSlice = createSlice({
  name: "sourcePolicyDefs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePolicyDefs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourcePolicyDefs" };
    },
    [getSourcePolicyDefs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePolicyDefs",
        message: action.payload.message,
      };
    },
    [getSourcePolicyDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourcePolicyDef" };
    },
    [getSourcePolicyDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePolicyDef",
        message: action.payload.message,
      };
    },
    [createSourcePolicyDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSourcePolicyDef" };
    },
    [createSourcePolicyDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourcePolicyDef",
        message: action.payload.message,
      };
    },
    [updateSourcePolicyDef.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourcePolicyDef" };
    },
    [updateSourcePolicyDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourcePolicyDef",
        message: action.payload.message,
      };
    },
    [deleteSourcePolicyDef.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourcePolicyDef" };
    },
    [deleteSourcePolicyDef.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourcePolicyDef",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourcePolicyDefsSlice.actions;

export default sourcePolicyDefsSlice.reducer;
