import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ObjectFormBuilderContext } from "../ObjectFormBuilder";
import { reorderObjectFormBuilderTabs } from "./store/objectFormBuilderTabsSlice";
import NtaiDnd3 from "@ntai/components/dnd3/NtaiDnd3";
const _ = require("lodash");

export default function ObjectFormBuilderTabReorder({ disabled }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);

  const objectFormId = useSelector(
    (state) => state.core.objectFormsSlice.activeId
  );

  const pathVariables = {
    domainId: domainId,
    objectId: objectId,
    objectFormId: objectFormId,
  };

  const objectFormTabsDataMap = useSelector(
    (state) => state.core.objectFormBuilderTabsSlice.data
  );

  const objectFormTabsData =
    objectFormTabsDataMap && !_.isEmpty(objectFormTabsDataMap)
      ? Object.values(objectFormTabsDataMap)
      : [];

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleReorder(items) {
    const previousOrder = objectFormTabsData.map((o, i) => {
      return o["uuId"];
    });

    const newOrder = items.map((o) => {
      return o["uuId"];
    });

    if (_.isArray(items) && !_.isEqual(previousOrder, newOrder)) {
      dispatch(
        reorderObjectFormBuilderTabs({
          ...pathVariables,
          formData: {
            items: newOrder,
          },
        })
      );
    }
  }

  return (
    <React.Fragment>
      <NtaiIconButton
        icon={faArrowUpArrowDown}
        disabled={disabled}
        handleClick={handleClick}
      />
      <NtaiDialog
        size="sm"
        title="Reorder Tabs"
        open={open}
        handleDialogClose={handleClose}
      >
        <NtaiPanel
          padding="0px"
          height="250px"
          subheader="Use the drag and drop option to reorder panels"
        >
          <Scrollbar>
            <Box>
              {objectFormTabsData &&
                _.isArray(objectFormTabsData) &&
                objectFormTabsData.length > 0 && (
                  <NtaiDnd3
                    id="uuId"
                    label="name"
                    items={objectFormTabsData}
                    handleReorder={handleReorder}
                    direction="horizontal"
                  />
                )}
            </Box>
          </Scrollbar>
        </NtaiPanel>
      </NtaiDialog>
    </React.Fragment>
  );
}
