import React, { useState, useEffect } from "react";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNote, faPlus } from "@fortawesome/pro-light-svg-icons";
import { useFormContext } from "react-hook-form";
import CodeEditorName from "./CodeEditorName";
import CodeEditorMenuActions from "./CodeEditorMenuActions";
import CodeEditorResult from "./CodeEditorResult";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { blue, grey } from "@mui/material/colors";
import {
  CODELIST_CODES,
  GL_RUN_STATUS_CODE,
} from "app/main/constants/NtaiCodelistConstants";
import { setActiveId } from "../store/sourceDatasetsSlice";
import { SourceDatasetsContext } from "../SourceDatasetList";
import CodeEditorCustomCodeResult from "./CodeEditorCustomCodeResult";
const _ = require("lodash");

export default function CodeEditor(props) {
  const {
    stageId,
    datasetId,
    handleDeleteDataset,
    handleUpdateDataset,
    handleRunScript,
    handleCancelScript,
    insertAfter,
    dsScript,
    wordCompleterList,
  } = props;
  const theme = useTheme();

  const { reset, getValues, setValue } = useFormContext();
  const [datasetScript, setDatasetScript] = useState();



  const { activeDatasetId, setActiveDatasetId } = React.useContext(
    SourceDatasetsContext
  );

  const wordCompleter = {
    getCompletions: function (editor, session, pos, prefix, callback) {
      callback(
        null,
        wordCompleterList.map(function (word) {
          return {
            caption: word.label,
            value: word.value,
            meta: word.meta,
          };
        })
      );
    },
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceDatasetsSlice.data
  );
  const data = _.get(dataMap, datasetId);

  const status = useSelector(
    (state) => state.sources.sourceDatasetsSlice.status
  );

  function onChange(newValue) {
    setDatasetScript(newValue);
  }

  function onHandleRunScript() {
    setActiveDatasetId(datasetId);
    // let sanitizedFormData = NtaiUtils.sanitizeFormData(
    //   getValues(`${stageId}.segments.${datasetId}`)
    // );
    // console.log(
    //   "sanitizedFormData: ",
    //   getValues(`${stageId}.segments.${datasetId}`)
    // );

    const datasetDataCopy = _.cloneDeep(data);
    datasetDataCopy["datasetScript"] = datasetScript;
    handleRunScript(datasetId, datasetDataCopy);
  }

  function onHandleCancelScript() {
    // setActiveDatasetId(datasetId);
    handleCancelScript(datasetId);
  }

  function onHandleDeleteDataset() {
    handleDeleteDataset(datasetId);
  }

  function renderResult() {
    if (data.customCodeFg !== 1) {
      if (
        data.queryStatusCode === GL_RUN_STATUS_CODE.COMPLETED ||
        data.queryStatusCode === GL_RUN_STATUS_CODE.ERROR
      ) {
        return (
          <CodeEditorResult
            statusCode={data.queryStatusCode}
            statusDecode={data.queryStatusDecode}
            message={data.queryMessage}
            result={data.queryResult ? data.queryResult : []}
          />
        );
      }
    } else {
      return (
        <CodeEditorCustomCodeResult
          statusCode={data.queryStatusCode}
          statusDecode={data.queryStatusDecode}
          message={data.queryMessage}
          result={data.queryResult ? data.queryResult : []}
        />
      );
    }

    if (status && status.result === "error") return <Box>{status.message}</Box>;
  }

  //
  useEffect(() => {
    const keyDatasetScript = `${stageId}.segments.${datasetId}.datasetScript`;
    setDatasetScript(data.datasetScript);
    setValue(keyDatasetScript, data.datasetScript);
    const keyDatasetId = `${stageId}.segments.${datasetId}`;
    setValue(keyDatasetId, {
      uuId: data.uuId,
      name: data.name,
      datasetScript: data.datasetScript,
      saveFg: data.saveFg === 1 ? true : false,
      datasetOrder: data.datasetOrder,
      queryStatusCode: data.queryStatusCode,
      queryStatusDecode: data.queryStatusDecode,
      queryMessage:
        data.queryStatusCode && data.queryStatusCode === 9
          ? data.queryMessage
          : null,
      //   queryResult:
      //     _.get(data, "queryResult") === null ? [] : _.get(data, "queryResult"),
    });
  }, [data]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "2px solid #eee",
          borderLeft: "4px solid #000",
          borderRadius: "8px",
          paddingBottom: theme.spacing(0),
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(1),
            display: "flex",
            height: "36px",
            alignItems: "center",
            borderBottom: "1px solid #eee",
            justifyContent: "space-between",
          }}
        >
          <CodeEditorName
            stageId={stageId}
            datasetId={datasetId}
            handleUpdateDataset={handleUpdateDataset}
          />

          <CodeEditorMenuActions
            data={data}
            stageId={stageId}
            datasetId={datasetId}
            handleRunScript={onHandleRunScript}
            handleCancelScript={onHandleCancelScript}
            handleUpdateDataset={handleUpdateDataset}
            handleDeleteDataset={onHandleDeleteDataset}
          />
        </Box>
        <AceEditor
          mode="python"
          theme="github"
          onChange={onChange}
          style={{
            background: grey[100],
          }}
          fontSize="14px"
          width="100%"
          name={`${stageId}.segments.${datasetId}.datasetScript`}
          value={datasetScript}
          // editorProps={{ $blockScrolling: true }}
          setOptions={{
            minLines: 3,
            maxLines: 10000,
            enableBasicAutocompletion: [wordCompleter],
            enableLiveAutocompletion: true,
          }}
        />
      </Box>

      <Box sx={{ display: "flex" }}>{renderResult()}</Box>

      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <Tooltip title="Insert Code Segment">
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            sx={{ fontSize: "12px" }}
            startIcon={<FontAwesomeIcon size="xs" icon={faPlus} />}
            onClick={() => insertAfter(_.get(data, "datasetOrder"))}
          >
            Code
          </Button>
        </Tooltip>
        <Tooltip title="Insert Markdown">
          <Button
            variant="outlined"
            color="inherit"
            sx={{ fontSize: "12px" }}
            size="small"
            startIcon={<FontAwesomeIcon size="xs" icon={faNote} />}
          >
            Markdown
          </Button>
        </Tooltip>
      </Box>
      <Box sx={{ height: "24px" }}></Box>
    </>
  );
}
