import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiAuthServer";
import ntaiServer from "app/http/ntaiServer";
import ntaiFileUploadServer from "app/http/ntaiFileUploadServer";

const _ = require("lodash");

export const authenticate = createAsyncThunk(
  "auth/authenticate",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post("/authenticate", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    userInfo: {}, // for user object
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    logout: (state) => {
      localStorage.removeItem("accessToken"); // deletes token from storage
      localStorage.removeItem("refreshToken");
      state.status = { method: "logout" };
      state.userInfo = null;
    },
  },
  extraReducers: {
    [authenticate.fulfilled]: (state, action) => {
      window.localStorage.setItem("accessToken", action.payload.accessToken);
      window.localStorage.setItem("refreshToken", action.payload.refreshToken);
      state.userInfo = action.payload.user;
      state.status = { result: "success", method: "authenticate" };
      ntaiServer.defaults.headers.common.Authorization = `Bearer ${action.payload.accessToken}`;
      ntaiFileUploadServer.defaults.headers.common.Authorization = `Bearer ${action.payload.accessToken}`;
    },
    [authenticate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "authenticate",
      };
    },
  },
});

export const { clearStatus, logout } = authSlice.actions;

export default authSlice.reducer;
