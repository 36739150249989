import {
  Box,
  Button,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Popper,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  scrollContainer: {
    height: 400,
    overflow: "auto",
    marginBottom: theme.spacing(3),
  },
  scroll: {
    position: "relative",
    width: "230%",
    backgroundColor: theme.palette.background.paper,
    height: "230%",
  },
  legend: {
    marginTop: theme.spacing(2),
    maxWidth: 300,
  },
  paper: {
    maxWidth: 400,
    overflow: "auto",
  },
  select: {
    width: 200,
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
}));

export default function NtaiPopperNew(props) {
  const { placement, arrow, open, children, clickAwayHandler, anchorEl } =
    props;

  const [arrowRef, setArrowRef] = React.useState(null);
  const [disablePortal, setDisablePortal] = React.useState(false);
  const [flip, setFlip] = React.useState(true);
  const [preventOverflow, setPreventOverflow] = React.useState("scrollParent");

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        disablePortal={disablePortal}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: flip,
          },
          preventOverflow: {
            enabled: preventOverflow !== "disabled",
            boundariesElement:
              preventOverflow === "disabled" ? "scrollParent" : preventOverflow,
          },
          arrow: {
            name: "arrow",
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
        {children}
      </Popper>
    </ClickAwayListener>
  );
}
