import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
import {
  getWorkflowDefVersionActivities,
  clearStatus,
  setActiveId,
  updateWorkflowDefVersionActivity,
} from "./store/workflowDefVersionActivitiesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import { Box, Drawer } from "@mui/material";
import WorkflowDefVersionActivityEdit from "./WorkflowDefVersionActivityEdit";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Activity",
    fontWeight: 700,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "durationDays",
    numeric: false,
    disablePadding: false,
    label: "Duration (days)",
    transformFunc: function (o) {
      return o ? o + " d" : "";
    },
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified by",
  },
];

const toolbarActions = [
  {
    value: "export",
    label: "Export",
    icon: "export",
  },
];

export default function WorkflowDefVersionActivityList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const workflowDefId = useSelector(
    (state) => state.core.workflowDefsSlice.activeId
  );
  const workflowDefVersionId = useSelector(
    (state) => state.core.workflowDefVersionsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.data
  );
  const status = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.status
  );

  const activityId = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.activeId
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
  }

  function handleActivityEdit(id) {
    setCurrentId(id);
    dispatch(setActiveId(id));
    handleDrawerOpen();
  }

  function handleDrawerClose() {
    setOpenDrawer(false);
    setCurrentId(null);
  }

  function handleDrawerOpen() {
    setOpenDrawer(true);
  }

  function handleSave(formData) {
    dispatch(
      updateWorkflowDefVersionActivity({
        workflowDefId: workflowDefId,
        workflowDefVersionId: workflowDefVersionId,
        uuId: currentId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateWorkflowDefVersionActivity" &&
      status.result === "success"
    ) {
      enqueueSnackbar(`Workflow definition version saved successfully.`, {
        variant: "success",
      });

      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "error" &&
      status.method === "getWorkflowDefVersionActivities"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "getWorkflowDefVersionActivities"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getWorkflowDefVersionActivities({
        workflowDefId: workflowDefId,
        workflowDefVersionId: workflowDefVersionId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <NtaiPanel
        width="100%"
        header="Workflow Activities"
        subheader="A Workflow activity is simply a work that a role or user performs in a business process governed by workflow definition."
      >
        {loaded && (
          <NtaiSimpleTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleActivityEdit}
            handleToolbarAction={handleToolbarAction}
            title={`# Activities (${data.length})`}
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPanel>

      {activityId && openDrawer && (
        <Drawer
          PaperProps={{
            style: {
              position: "absolute",
              top: 48, // 139
              height: `calc(100% - 48px)`,
            },
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openDrawer}
          onClose={handleDrawerClose}
          anchor="right"
          BackdropProps={{ invisible: true }}
        >
          <Box
            sx={{
              width: "620px",
              display: "flex",
              height: "100%",
              zIndex: (theme) => theme.zIndex.drawer,
            }}
          >
            <WorkflowDefVersionActivityEdit
              handleSave={handleSave}
              handleCancel={handleDrawerClose}
            />
          </Box>
        </Drawer>
      )}
    </React.Fragment>
  );
}
