import { Box, Typography, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceInStoreFiles,
  uploadSourceInStoreFiles,
  clearStatus,
} from "../store/sourceInStoreFilesSlice";
import { useEffect } from "react";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

export default function SourceInBinaryFiles({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [uploadData, setUploadData] = useState();
  const [uploadPending, setUploadPending] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceInId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreFilesSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceInStoreFilesSlice.status
  );

  const data = Object.values(dataMap);

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceInId: sourceInId,
  };

  function onHandleFileUpload(formData, config) {
    setUploadData({ formData: formData, config: config });
  }

  useEffect(() => {
    if (status && status.method === "uploadSourceInStoreFiles") {
      if (status.result === "pending") setUploadPending(true);
      else if (status.result === "success") {
        setUploadPending(false);
        dispatch(clearStatus());
      }
    }
  }, [status]);

  function handleSave() {
    dispatch(
      uploadSourceInStoreFiles({
        ...params,
        ...uploadData,
      })
    );
  }

  useEffect(() => {
    dispatch(getSourceInStoreFiles(params));
  }, []);

  return (
    <NtaiFormPanel
      width="75%"
      header="Upload Files"
      subheader="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi "
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          display: "flex",
          border: theme.general.border1,
          borderRadius: theme.general.borderRadius,
          p: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="h6">Upload Files</Typography>
          <NtaiFileUpload
            pending={uploadPending}
            handleFileUpload={onHandleFileUpload}
          />
        </Box>
        <Box
          sx={{
            flexBasis: "50%",
            height: "240px",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="h6">Uploaded Files ({data.length})</Typography>

          {data && data.length > 0 && (
            <Scrollbar>
              <Box>
                {data.map((f, i) => (
                  <Typography variant="subtitle1">{f.fileName}</Typography>
                ))}
              </Box>
            </Scrollbar>
          )}
          {data && data.length === 0 && (
            <NtaiEmptyMessage
              header="No files available"
              subheader="No files have been uploaded for this store"
            />
          )}
          {uploadPending && <NtaiCircularProgress />}
        </Box>
      </Box>
    </NtaiFormPanel>
  );
}
