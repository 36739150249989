import React, { Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import { faCircle2 } from "@fortawesome/pro-solid-svg-icons";
import { getWidgets } from "app/main/pages/core/admin/widget/store/widgetsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import { SearchSourceWidgetManagerContext } from "./SearchSourceWidgetManager";
import SourceWidgetUtil from "app/main/pages/core/sourcedefinition/source/view/widgets/util/SourceWidgetUtil";
import NtaiChartIcons from "utils/NtaiChartIcons";
import { faChartMixed } from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

export default function SearchSourceWidgetManagerChartSelection({
  handleAddWidget,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeWidgets, setActiveWidgets] = useState([]);

  const widgetsDataMap = useSelector((state) => state.core.widgetsSlice.data);
  const widgets =
    widgetsDataMap && !_.isEmpty(widgetsDataMap)
      ? Object.values(widgetsDataMap)
      : [];

  const { selectedFields } = React.useContext(SearchSourceWidgetManagerContext);

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  function onAddWidget(id) {
    handleAddWidget(id);
  }

  function generateWidgets() {
    let result = [];

    if (Array.isArray(widgets) && widgets.length > 0) {
      let listItems = [];
      _.orderBy(widgets, ["contentCode"], ["asc"]).forEach((widget, index) => {
        listItems.push(
          <Fragment key={`widget-fragment-${index}`}>
            <ListItem
              sx={{
                display: "flex",
                width: "100%",
                "&:hover": { background: grey[100] },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: theme.spacing(1),
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(2),
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={
                      NtaiChartIcons.hasOwnProperty(
                        widget["name"].toLowerCase()
                      )
                        ? NtaiChartIcons[widget["name"].toLowerCase()]
                        : faChartMixed
                    }
                  />
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ fontWeight: 700, color: "inherit" }}
                        variant="subtitle1"
                      >
                        {widget["name"]}
                      </Typography>
                    }
                    secondary={widget["description"]}
                  />
                </Box>

                <Button
                  onClick={() => onAddWidget(widget.uuId)}
                  variant="contained"
                  size="small"
                  disabled={activeWidgets.includes(widget.uuId) ? false : true}
                >
                  Add
                </Button>
              </Box>
            </ListItem>
            <Divider />
          </Fragment>
        );
      });

      result.push(
        <List
          key="widget-select-id"
          component="nav"
          sx={{ p: 0, width: "100%" }}
        >
          {listItems}
        </List>
      );
    }

    return result;
  }

  useEffect(() => {
    const tmpActiveWidgetIds = SourceWidgetUtil.getActiveWidgetIds(
      selectedFields,
      sourceFieldsDataMap,
      sourceDerivedFieldsDataMap,
      widgets
    );

    setActiveWidgets([...tmpActiveWidgetIds]);
  }, [selectedFields]);

  useEffect(() => {
    dispatch(getWidgets());
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
          px: theme.spacing(2),
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
        >
          <FontAwesomeIcon size="lg" icon={faCircle2} color="secondary" />
          <Typography variant="h6">Select Widget</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: `calc(100% - 44px)`,
        }}
      >
        <Scrollbar>{generateWidgets()}</Scrollbar>
      </Box>
    </>
  );
}
