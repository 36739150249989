import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceRecordTagColorRuleList from "./color-rules/SourceRecordTagColorRuleList";
const _ = require("lodash");

export default function SourceRecordTagForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();

  const { getValues, setValue, reset } = useFormContext();

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceDerivedFieldOptions = NtaiUtils.getSelectOptions(
    Object.values(sourceDerivedFieldsDataMap),
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceRecordTagForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ sourceRecordTagForm: action === "add" ? {} : formData });
  }, []);

  return (
    <NtaiPanel
      width="520px"
      header={action === "add" ? "Add Tag" : `Edit ${_.get(formData, "name")}`}
      subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
      headerActions={
        <Box sx={{ display: "flex", alignItems: "start" }}>
          <IconButton onClick={handleCancel}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Box>
      }
    >
      <NtaiTabs>
        <Box sx={{ py: theme.spacing(2) }} label="GENERAL">
          <NtaiFormPanel
            padding="0px"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <NtaiTextField
              name="sourceRecordTagForm.name"
              label="Name*"
              placeholder="Name"
              rules={{ required: "Name is required" }}
            />

            <NtaiTextField
              name="sourceRecordTagForm.description"
              label="Description"
              placeholder="Description"
              multiline
              minRows={2}
              maxRows={2}
            />

            <NtaiSelectField
              name="sourceRecordTagForm.sourceDerivedFieldUuId"
              label="Source Field*"
              isDisabled={action === "edit" ? true : false}
              options={sourceDerivedFieldOptions}
              rules={{ required: "Source field is required" }}
            />

            <NtaiTextField
              name="sourceRecordTagForm.maxLength"
              label="Maximum Length"
              placeholder="Maximum Length"
              type="number"
            />

            <NtaiTextField
              name="sourceRecordTagForm.fgColor"
              label="Foreground Color"
              placeholder="Foreground"
            />

            <NtaiTextField
              name="sourceRecordTagForm.bgColor"
              label="Background Color"
              placeholder="Background"
            />
          </NtaiFormPanel>
        </Box>

        <Box
          sx={{ py: theme.spacing(2) }}
          disabled={action === "edit" ? false : true}
          label="COLOR RULES"
        >
          <SourceRecordTagColorRuleList />
        </Box>
      </NtaiTabs>
    </NtaiPanel>
  );
}
