import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import { getSourceOutDataset } from "../../../source/out/store/sourceOutDatasetsSlice";

function generateHeadCells(dat) {
  const result = [];
  const row1 = dat[0];
  Object.keys(row1).forEach((o, i) => {
    result.push({
      id: o,
      name: o,
      label: o,
    });
  });

  return result;
}

export default function SourceCatalogAssetDatasetPreview({ uuId }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const dataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.status
  );

  const data =
    dataMap && !_.isEmpty(dataMap) && _.has(dataMap, uuId)
      ? dataMap[uuId]
      : null;

  const result =
    loaded &&
    data &&
    data["dataset"]["queryResult"] &&
    _.isArray(data["dataset"]["queryResult"]) &&
    data["dataset"]["queryResult"].length > 0
      ? data["dataset"]["queryResult"]
      : null;

  function handleView(id) {}

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceOutDataset" &&
      status.result === "success"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceOutDataset({
        sourceDefId: "*",
        sourceId: "*",
        sourceOutStoreId: "*",
        uuId: uuId,
      })
    );
  }, [uuId]);

  return (
    <React.Fragment>
      {loaded && result && _.isArray(result) && (
        <Scrollbar>
          {_.isArray(result) && result.length > 0 && (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                padding: theme.spacing(1),
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {result &&
                      result[0] &&
                      Object.keys(result[0]).map((k, i) => (
                        <TableCell sx={{ p: theme.spacing(1) }} key={i}>
                          {k}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.map((item, index) => (
                    <TableRow key={index}>
                      {Object.values(item).map((val, i) => (
                        <TableCell
                          key={i}
                          sx={{
                            p: theme.spacing(1),
                            verticalAlign: "top",
                          }}
                        >
                          {_.isArray(val) || _.isObject(val)
                            ? JSON.stringify(val)
                            : val}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Scrollbar>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </React.Fragment>
  );
}
