import { faDiagramProject, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const distributionTypes = [
  {
    id: 1,
    label: "Contacts",
    description:
      "Distribute selected records line listing report to external contacts email addresses",
    avatarIcon: <FontAwesomeIcon icon={faUsers} />,
  },
  {
    id: 2,
    label: "Workflow",
    description:
      "Add remarks and distribute selected records to assigned users in the workflow",
    avatarIcon: <FontAwesomeIcon icon={faDiagramProject} />,
  },
];

export default function SearchSourceRecordsSelectDistributionType({
  handleSelect,
}) {
  const theme = useTheme();
  return (
    <NtaiPanel
      width="100%"
      subheader="You can distribute selected records to either external contacts defined in the library or a pre-defined workflow configured for the source."
    >
      <List
        sx={{ display: "flex", flexDirection: "row", gap: theme.spacing(2) }}
      >
        {distributionTypes.map((st, i) => (
          <ListItemButton
            key={`search-source-record-distribute-type-${i}`}
            sx={{
              p: theme.spacing(2),
              width: "120px",
              display: "flex",
              flexDirection: "column",
              borderRadius: theme.general.borderRadius,
              border: theme.general.border1,
              gap: theme.spacing(1),
              "&:hover": {
                background: grey[100],
              },
            }}
            onClick={() => handleSelect(st["id"])}
          >
            <Avatar>{st["avatarIcon"]}</Avatar>

            <ListItemText
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: theme.general.borderRadius,
                gap: theme.spacing(1),
                justifyContent: "center",
              }}
              primary={
                <Typography
                  sx={{ textAlign: "center" }}
                  variant="h6"
                  fontWeight="700"
                >
                  {st["label"]}
                </Typography>
              }
              secondary={
                <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                  {st["description"]}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
      </List>
    </NtaiPanel>
  );
}
