import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { SOURCE_FILE_FORMAT_TYPE_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useSelector } from "react-redux";
import SourceInCsvFileList from "./csv/SourceInCsvList";
import SourceInJsonList from "./json/SourceInJsonList";
import SourceInSasFileList from "./sas/SourceInSasFileList";
import SourceInXmlList from "./xml/SourceInXmlList";
import history from "@ntai/@history/@history";
import SourceInBinaryFiles from "./binary/SourceInBinaryFiles";
import SourceInParquetList from "./parquet/SourceInParquetList";
const _ = require("lodash");

export default function SourceInWrapper(props) {
  const sourceInId = props.match.params.id;
  const dataMap = useSelector((state) => state.sources.sourceInsSlice.data);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceInData = _.get(dataMap, sourceInId);

  console.log("Source in data: ", sourceInData);

  function handleCancel() {
    history.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins`);
  }

  return (
    <NtaiPage
      title={_.get(_.get(dataMap, sourceInId), "name")}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins`}
      padding={
        _.get(sourceInData, "typeCode") === SOURCE_FILE_FORMAT_TYPE_CODES.IMG
          ? 0
          : 2
      }
    >
      {_.get(sourceInData, "typeCode") ===
        SOURCE_FILE_FORMAT_TYPE_CODES.CSV && (
        <SourceInCsvFileList handleCancel={handleCancel} />
      )}
      {_.get(sourceInData, "typeCode") ===
        SOURCE_FILE_FORMAT_TYPE_CODES.PARQUET && (
        <SourceInParquetList handleCancel={handleCancel} />
      )}
      {_.get(sourceInData, "typeCode") ===
        SOURCE_FILE_FORMAT_TYPE_CODES.JSON && (
        <SourceInJsonList handleCancel={handleCancel} />
      )}
      {_.get(sourceInData, "typeCode") ===
        SOURCE_FILE_FORMAT_TYPE_CODES.XML && (
        <SourceInXmlList handleCancel={handleCancel} />
      )}
      {_.get(sourceInData, "typeCode") ===
        SOURCE_FILE_FORMAT_TYPE_CODES.SAS74BT && (
        <SourceInSasFileList handleCancel={handleCancel} />
      )}
      {[
        SOURCE_FILE_FORMAT_TYPE_CODES.PDFM,
        SOURCE_FILE_FORMAT_TYPE_CODES.PDFS,
        SOURCE_FILE_FORMAT_TYPE_CODES.IMG,
      ].includes(_.get(sourceInData, "typeCode")) && (
        <SourceInBinaryFiles handleCancel={handleCancel} />
      )}
    </NtaiPage>
  );
}
