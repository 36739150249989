import React, { useEffect } from "react";
import { Box } from "@mui/material";
import NtaiFormPanel from "../panels/NtaiFormPanel";
import NtaiTextField from "../inputs/NtaiTextField";
import NtaiSelectField from "../inputs/NtaiSelectField";

export default function NtaiTestTabs2() {
  function handleSave() {}

  function handleCancel() {}
  return (
    <Box sx={{ display: "flex", w: "100%", h: "100%" }}>
      <NtaiFormPanel
        width="75%"
        header="Some title"
        subheader="Some description"
        handleSave={handleSave}
        handleCancel={handleCancel}
      >
        <NtaiTextField
          name="contactForm.firstName"
          label="First Name"
          placeholder="My first name"
        />
        <NtaiTextField
          name="contactForm.lastName"
          label="Last Name"
          placeholder="My last name"
        />
        <NtaiTextField
          name="contactForm.address"
          label="Address"
          multiline
          minRows={3}
          maxRows={3}
          placeholder="Street Address"
        />

        <NtaiSelectField
          name="contactForm.gender"
          label="Gender"
          options={[{ value: 1, label: "Females" }]}
        />
      </NtaiFormPanel>
    </Box>
  );
}
