import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getSourceDefinitions,
  clearStatus as clearSourceDefsSliceStatus,
} from "../../../sourcedefinition/store/sourceDefinitionsSlice";
import {
  clearStatus,
  createThesaurusSources,
  deleteThesaurusSource,
  getThesaurusSources,
} from "./store/thesaurusSourcesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.sourceDefUuId]["abbrev"],
        secondary: allObjectsDataMap[f.sourceDefUuId]["name"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["name"], ["asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.sourceDefUuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function ThesaurusSources() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const thesaurusId = useSelector(
    (state) => state.core.thesaurusesSlice.activeId
  );

  const sourceDefsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefsSliceStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const sourceDefsData =
    sourceDefsDataMap && !_.isEmpty(sourceDefsDataMap)
      ? Object.values(sourceDefsDataMap)
      : [];

  const dataMap = useSelector((state) => state.core.thesaurusSourcesSlice.data);
  const status = useSelector(
    (state) => state.core.thesaurusSourcesSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleAdd(uuId) {
    if (_.filter(data, { sourceDefUuId: uuId }).length === 0) {
      dispatch(
        createThesaurusSources({
          thesaurusId: thesaurusId,
          formData: {
            items: [uuId],
          },
        })
      );
    }
  }

  function handleRemove(uuId) {
    if (_.filter(data, { uuId: uuId }).length > 0) {
      dispatch(
        deleteThesaurusSource({
          thesaurusId: thesaurusId,
          uuId: uuId,
        })
      );
    }
  }

  function handleAddAll() {
    // const availableResourceUuIds = availableObjects.map((o, i) => o["uuId"]);
    // if (availableResourceUuIds.length > 0) {
    //   dispatch(
    //     createContactGroup({
    //       contactGroupId: contactGroupId,
    //       formData: {
    //         typeCode: 1,
    //         resourceDataUuIds: [...availableResourceUuIds],
    //       },
    //     })
    //   );
    // }
  }

  useEffect(() => {
    if (
      status &&
      [
        "getThesaurusSources",
        "createThesaurusSources",
        "deleteThesaurusSource",
      ].includes(status.method) &&
      status.result === "success" &&
      _.isArray(sourceDefsData) &&
      sourceDefsData.length > 0 &&
      !_.isEmpty(sourceDefsDataMap)
    ) {
      setAvailableObjects([...transformAvailableObjects(data, sourceDefsData)]);
      setSelectedObjects([
        ...transformSelectedObjects(data, sourceDefsDataMap),
      ]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      sourceDefsSliceStatus &&
      sourceDefsSliceStatus.result === "success" &&
      sourceDefsSliceStatus.method === "getSourceDefinitions"
    ) {
      dispatch(getThesaurusSources(thesaurusId));
      dispatch(clearSourceDefsSliceStatus());
    }
  }, [sourceDefsSliceStatus]);

  useEffect(() => {
    dispatch(getSourceDefinitions());
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Sources"
      subheader="You can assign available sources to the thesaurus."
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Sources Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              //   onClick={() => handleRemoveAll()}
            >
              REMOVE ALL
            </Button>
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleAddAll()}
            >
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["name"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.abbrev}
                              </Typography>
                            }
                            secondary={fd.name}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No sources available"
                subheader="You have assigned all sources"
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No sources assigned"
                subheader="Add sources from left panel to the contactGroup"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
