import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createDatasource,
  deleteDatasource,
  getDatasources,
  updateDatasource,
} from "./store/datasourcesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import DatasourceSelect from "./DatasourceSelect";
import history from "@ntai/@history";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "20%",
  },

  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "15%",
  },
  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Connection status",
    width: "15%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function DatasourceList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [selectDialogOpen, setSelectDialogOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector((state) => state.core.datasourcesSlice.data);
  const status = useSelector((state) => state.core.datasourcesSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleSelectOrEdit(id) {
    if (id) {
      history.push(`/admin/datasources/${id}/edit`);
    } else {
      setAction("add");
      handleSelectDialogOpen();
    }
  }

  function handleSelectDialogOpen() {
    setSelectDialogOpen(true);
  }

  function handleSelectDialogClose() {
    setSelectDialogOpen(false);
    setAction(null);
    setCurrentId(null);
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteDatasource(id));
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
    setCurrentId(null);
  }

  function handleSave(formData) {
    action === "add"
      ? dispatch(createDatasource(formData))
      : dispatch(updateDatasource({ uuId: currentId, formData: formData }));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getDatasources" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteDatasource" &&
      status.result === "success"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Datasource(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "success",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getDatasources());
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Data sources">
        {loaded && (
          <NtaiCrudTable
            helpText="Define and manage data sources of various types to store source data created during source pipeline or access external data stores. Data sources can be used for user specific tasks including report generation."
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleSelectOrEdit}
            handleToolbarAction={handleToolbarAction}
            title="Datasource"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={selectDialogOpen}
        handleDialogClose={handleSelectDialogClose}
        title="Add Data source"
        size="sm"
      >
        <DatasourceSelect
          handleSave={handleSave}
          handleCancel={handleSelectDialogClose}
        />
      </NtaiDialog>

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
