import React, { useEffect } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function OversightTopicActionForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const statusOptions = _.get(codes, CODELIST_CODES.COM_ACTION_STATUS_CODE);
  const priorityOptions = _.get(codes, CODELIST_CODES.COM_ACTION_PRIORITY_CODE);
  const categoryOptions = _.get(codes, CODELIST_CODES.COM_ACTION_CATEGORY_CODE);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("oversightTopicActionForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ oversightTopicActionForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}
      >
        <Box sx={{ width: "75%", display: "flex", flexDirection: "column" }}>
          <NtaiTextField
            name="oversightTopicActionForm.title"
            label="Title"
            placeholder="Title"
          />
        </Box>

        <NtaiTextField
          name="oversightTopicActionForm.description"
          label="Description"
          minRows={2}
          maxRows={2}
          multiline
          placeholder="Description"
        />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: theme.spacing(1),
          }}
        >
          <Box sx={{ display: "flex", width: "40%", flexDirection: "column" }}>
            <NtaiSelectField
              name="oversightTopicActionForm.categoryCode"
              label="Category"
              options={categoryOptions}
            />
          </Box>

          <Box sx={{ display: "flex", width: "30%", flexDirection: "column" }}>
            <NtaiSelectField
              name="oversightTopicActionForm.priorityCode"
              label="Priority"
              options={priorityOptions}
            />
          </Box>

          <Box sx={{ display: "flex", width: "30%", flexDirection: "column" }}>
            <NtaiSelectField
              name="oversightTopicActionForm.statusCode"
              label="Status"
              options={statusOptions}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <NtaiDateField
            label="Planned Start"
            name="oversightTopicActionForm.plannedStartDate"
          />
          <NtaiDateField
            label="Planned End"
            name="oversightTopicActionForm.plannedEndDate"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            pb: theme.spacing(2),
          }}
        >
          <NtaiDateField
            label="Actual Start"
            name="oversightTopicActionForm.actualStartDate"
          />
          <NtaiDateField
            label="Actual End"
            name="oversightTopicActionForm.actualEndDate"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
          }}
        >
          <Button onClick={() => handleCancel()} size="small">
            CANCEL
          </Button>
          <Button
            onClick={() => onHandleSave()}
            size="small"
            variant="contained"
          >
            SAVE
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
