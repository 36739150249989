import { faArchive, faEdit } from "@fortawesome/pro-light-svg-icons";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  IconButton,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Avatar,
} from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbar from "@ntai/components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchNote,
  getSearchNotes,
  clearStatus,
  deleteSearchNote,
  updateSearchNote,
} from "./store/searchNotesSlice";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { grey } from "@mui/material/colors";
import { SearchSourceContext } from "../source/SearchSource";
import { setReloadKey } from "./notes-icon/store/searchNotesIconSlice";
const _ = require("lodash");

function getNotesContext(level) {
  if (level === 1) return "Search";
  else if (level === 2) return "Source";
  else if (level === 3) return "View";
  else if (level === 4) return "Widget";
  else return "Data Point";
}
export default function SearchNotesDrawer({
  levelCode,
  notesContext,
  sourceViewId,
  sourceWidgetId,
  searchSourceWidgetDataPointFields,
  handleClose,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [loaded, setLoaded] = useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();

  const searchId = useSelector((state) => state.search.searchSlice.activeId);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const dataMap = useSelector((state) => state.search.searchNotesSlice.data);
  const status = useSelector((state) => state.search.searchNotesSlice.status);
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const pathVariables = {
    searchId: searchId,
    searchSourceId: searchSourceId,
    sourceViewId: sourceViewId,
    sourceWidgetId: sourceWidgetId,
  };

  function onHandleCreateUpdateNote() {
    const note = getValues("searchNoteForm");

    if (action === "edit") {
      dispatch(
        updateSearchNote({
          searchId: searchId,
          formData: {
            ...pathVariables,
            uuId: currentId,
            note: note["note"],
            levelCode: levelCode,
          },
        })
      );
    } else {
      dispatch(
        createSearchNote({
          searchId: searchId,
          formData: {
            ...pathVariables,
            note: note["note"],
            levelCode: levelCode,
          },
        })
      );
    }
  }

  function onHandleEditNote(uuId) {
    setValue("searchNoteForm", dataMap[uuId]);
    setAction("edit");
    setCurrentId(uuId);
  }

  function onHandleClearNote() {
    reset({ searchNoteForm: {} });
    setAction(null);
    setCurrentId(null);
  }

  function onHandleDeleteNote(uuId) {
    dispatch(
      deleteSearchNote({
        searchId: searchId,
        searchSourceId: searchSourceId,
        sourceViewId: sourceViewId,
        sourceWidgetId: sourceWidgetId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchNotes" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSearchNote", "updateSearchNote"].includes(status.method) &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      reset({ searchNoteForm: {} });
      dispatch(
        setReloadKey(
          [
            searchId,
            searchSourceId || "",
            sourceViewId || "",
            sourceWidgetId || "",
          ].join(",")
        )
      );
    }

    if (
      status &&
      ["deleteSearchNote"].includes(status.method) &&
      status.result === "success"
    ) {
      dispatch(
        setReloadKey(
          [
            searchId,
            searchSourceId || "",
            sourceViewId || "",
            sourceWidgetId || "",
          ].join(",")
        )
      );
      dispatch(clearStatus());
    }

    setAction(null);
    setCurrentId(null);
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchNotes({
        searchId: searchId,
        formData: {
          searchId: searchId,
          searchSourceId: searchSourceId,
          sourceViewId: sourceViewId,
          sourceWidgetId: sourceWidgetId,
          levelCode: levelCode,
        },
      })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "360px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(2),
          height: "54px",
          display: "flex",
          width: "100%",
          borderBottom: theme.general.border1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" color="primary.lighter" fontWeight="300">
          {`Notes (${data.length})`}
        </Typography>

        <IconButton
          sx={{ width: "24px", height: "24px" }}
          onClick={handleClose}
        >
          <FontAwesomeIcon size="xs" icon={faClose} />
        </IconButton>
      </Box>

      <Box
        sx={{
          padding: theme.spacing(2),
          height: "48px",
          display: "flex",
          width: "100%",
          borderBottom: theme.general.border1,
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" fontWeight="500">
          {getNotesContext(levelCode)}
        </Typography>
        <Typography variant="subtitle2" fontWeight="500" color="inherit">
          {NtaiUtils.trunc(notesContext, 100)}
        </Typography>
      </Box>

      <Box
        sx={{
          height: `calc(100% - 102px)`,
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {loaded && (
          <Box
            sx={{
              width: "100%",
              p: theme.spacing(2),
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
              borderBottom: theme.general.border1,
            }}
          >
            <NtaiTextField
              name="searchNoteForm.note.body"
              multiline
              minRows={2}
              maxRows={2}
              label="Note"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: theme.spacing(1),
              }}
            >
              <Button
                sx={{ p: 0, fontSize: "10px" }}
                onClick={() => onHandleClearNote()}
              >
                CLEAR
              </Button>
              <Button
                sx={{ fontSize: "10px" }}
                variant="contained"
                size="small"
                onClick={() => onHandleCreateUpdateNote()}
              >
                POST
              </Button>
            </Box>
          </Box>
        )}
        {loaded && _.isArray(data) && data.length > 0 && (
          <Scrollbar>
            <List sx={{ p: 0, background: grey[100] }}>
              {_.orderBy(data, ["dateModified"], ["desc"]).map((o, i) => (
                <React.Fragment>
                  <ListItem>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        border: theme.general.border1,
                        p: theme.spacing(1),
                        borderRadius: theme.general.borderRadiusSm,
                        background: "white",
                      }}
                    >
                      <Box sx={{ flexBasis: "95%" }}>
                        <ListItemText
                          primary={
                            <Box
                              sx={{ display: "flex", gap: theme.spacing(1) }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Avatar sx={{ width: 24, height: 24 }}>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight="700"
                                    color="white"
                                  >
                                    AP
                                  </Typography>
                                </Avatar>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="700"
                                  color="inherit"
                                >
                                  {o["userCreated"]}
                                </Typography>

                                <Typography variant="caption" color={grey[700]}>
                                  {NtaiUtils.formatDateCol(o["dateModified"])}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          secondary={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                py: theme.spacing(0.5),
                              }}
                            >
                              <Typography variant="subtitle2">
                                {o["note"]["body"]}
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>

                      <Box sx={{ flexBasis: "5%" }}>
                        <IconButton
                          size="small"
                          sx={{ cursor: "default" }}
                          onClick={() => onHandleEditNote(o["uuId"])}
                        >
                          <FontAwesomeIcon size="2xs" icon={faEdit} />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ cursor: "default" }}
                          onClick={() => onHandleDeleteNote(o["uuId"])}
                        >
                          <FontAwesomeIcon size="2xs" icon={faArchive} />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Scrollbar>
        )}
        {!loaded && <NtaiCircularProgress size={24} vAlign="center" />}
        {loaded && _.isArray(data) && data.length === 0 && (
          <NtaiEmptyMessage header="No notes available" />
        )}
      </Box>
    </Box>
  );
}
