import { useSelector } from "react-redux";

function useSearchSourceWidgetChartFields(metadata) {
  //   const widgetDataMap = useSelector(
  //     (state) => state.search.searchSourceViewWidgetsSlice.data
  //   );

  //   console.log("in hoook: wdmap", widgetDataMap);

  //   const widget = widgetDataMap[widgetId];

  //   console.log("in hoook: widget", widget);

  //   // const filters = generateSearchSourceWidgetTempFilters(
  //   //   searchSourceWidgetTempFilters
  //   // );

  //   return widget["metadata"];
  return metadata;
}

export default useSearchSourceWidgetChartFields;
