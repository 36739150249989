import { combineReducers } from "@reduxjs/toolkit";
import alertDefinitionsSlice from "../definition/store/alertDefinitionsSlice";
import alertDefinitionInitAdjsSlice from "../definition/init-adj/store/alertDefinitionInitAdjsSlice";
import alertsSlice from "../inbox/store/alertsSlice";
import alertRecordsSlice from "../inbox/record/store/alertRecordsSlice";
import alertRecordAdjudicationsSlice from "../inbox/record/sidepanels/manage/adjudication/store/alertRecordAdjudicationsSlice";
import alertRecordActionsSlice from "../inbox/record/sidepanels/manage/action/store/actionRecordActionsSlice";
import alertRecordNotesSlice from "../inbox/record/sidepanels/manage/note/store/alertRecordNotesSlice";
import alertNavigationSlice from "../inbox/navigation/store/alertNavigationSlice";
import alertDefSourceObjectTopicsSlice from "../definition/topic/objecttopic/store/alertDefSourceObjectTopicsSlice";
import alertTrackerSlice from "../tracker/store/alertTrackerSlice";
import alertActivityUsersSlice from "../inbox/header/details/users/store/alertActivityUsersSlice";
import alertRecordBatchAssessmentsSlice from "../inbox/record/batch-assessment/store/alertRecordBatchAssessmentsSlice";
import alertRecordBatchDocumentsSlice from "../inbox/record/batch-document/store/alertRecordBatchDocumentsSlice";
import alertRecordDocumentsSlice from "../inbox/record/sidepanels/manage/document/store/alertRecordDocumentsSlice";

const monitorReducer = combineReducers({
  alertDefinitionsSlice,
  alertDefinitionInitAdjsSlice,
  alertsSlice,
  alertRecordsSlice,
  alertRecordAdjudicationsSlice,
  alertRecordActionsSlice,
  alertRecordNotesSlice,
  alertRecordDocumentsSlice,
  alertNavigationSlice,
  alertDefSourceObjectTopicsSlice,
  alertTrackerSlice,
  alertActivityUsersSlice,
  alertRecordBatchAssessmentsSlice,
  alertRecordBatchDocumentsSlice,
});

export default monitorReducer;
