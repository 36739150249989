import React, { useEffect, useState } from "react";
import { Tooltip, useTheme } from "@mui/material";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { useSelector, useDispatch } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import {
  getAlertRecordDocuments,
  createAlertRecordDocument,
  updateAlertRecordDocument,
  clearStatus,
  deleteAlertRecordDocument,
  downloadAlertRecordDocument,
  clearDownloadData,
} from "./store/alertRecordDocumentsSlice";
import AlertRecordDocumentForm from "./AlertRecordDocumentForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { AlertEditContext } from "../../../../AlertEdit";
import { useSnackbar } from "notistack";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleHistoryCircleUser } from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "bulkFg",
    numeric: false,
    disablePadding: false,
    label: <FontAwesomeIcon icon={faRectangleHistoryCircleUser} />,
    width: "5%",
    transformFunc: function (o) {
      return o === 1 ? (
        <Tooltip title="Bulk upload">
          <FontAwesomeIcon icon={faRectangleHistoryCircleUser} />
        </Tooltip>
      ) : null;
    },
  },
  {
    id: "document.title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    fontWeight: 700,
    width: "25%",
  },
  {
    id: "document.fileName",
    numeric: false,
    disablePadding: false,
    label: "File",
    width: "20%",
  },
  {
    id: "document.fileSize",
    numeric: false,
    disablePadding: false,
    label: "Size",
    transformFunc: NtaiUtils.bytesToSize,
    width: "10%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
    width: "15%",
  },
  {
    id: "userCreated",
    numeric: false,
    disablePadding: false,
    label: "Created By",
    width: "15%",
  },
];

export default function AlertRecordDocumentList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const {
    alertId,
    alertMasterId,
    selectedRecordId,
    setSelectedRecordId,
    refreshSelectedRecord,
  } = React.useContext(AlertEditContext);

  const dataMap = useSelector(
    (state) => state.monitor.alertRecordDocumentsSlice.data
  );

  const downloadData = useSelector(
    (state) => state.monitor.alertRecordDocumentsSlice.downloadData
  );

  const downloadFileName = useSelector(
    (state) => state.monitor.alertRecordDocumentsSlice.downloadFileName
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["dateModified"], ["desc"])
      : [];

  console.log("Data: ", data);

  const status = useSelector(
    (state) => state.monitor.alertRecordDocumentsSlice.status
  );

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "document.title"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteAlertRecordDocument({
          alertId: alertId,
          alertMasterId: alertMasterId,
          alertRecordId: selectedRecordId,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
    setCurrentId(null);
  }

  function handleDownload(id) {
    dispatch(
      downloadAlertRecordDocument({
        alertId: alertId,
        alertMasterId: alertMasterId,
        alertRecordId: selectedRecordId,
        uuId: id,
        fileName: dataMap[id]["document"]["fileName"],
      })
    );
  }

  function downloadFile() {
    // downloadedData
    if (downloadData) {
      const url = window.URL.createObjectURL(new Blob([downloadData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();
      dispatch(clearDownloadData());
    }
  }

  useEffect(() => {
    if (
      status &&
      ["createAlertRecordDocument"].includes(status.method) &&
      status.result === "success"
    ) {
      setAction("list");
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "downloadAlertRecordDocument" &&
      status.result === "success"
    ) {
      downloadFile();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteAlertRecordDocument" &&
      status.result === "success"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Document(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  function handleSave(formData) {
    if (action === "add") {
      dispatch(
        createAlertRecordDocument({
          alertId: alertId,
          alertMasterId: alertMasterId,
          alertRecordId: selectedRecordId,
          formData: formData,
        })
      );
    } else if (action === "edit")
      dispatch(
        updateAlertRecordDocument({
          alertId: alertId,
          alertMasterId: alertMasterId,
          alertRecordId: selectedRecordId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  useEffect(() => {
    dispatch(
      getAlertRecordDocuments({
        alertId: alertId,
        alertMasterId: alertMasterId,
        alertRecordId: selectedRecordId,
      })
    );
  }, []);

  return (
    <>
      {action === "list" && (
        <NtaiPanel
          width="100%"
          header="Documents"
          subheader="Document management supports creation, update and delete of documents or attachments."
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            keyColumn="uuId"
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            toolbarActions={toolbarActions}
            handleToolbarAction={handleToolbarAction}
            title="Document"
          />
        </NtaiPanel>
      )}
      {(action === "add" || action === "edit") && (
        <NtaiForm>
          <AlertRecordDocumentForm
            action={action}
            formData={action === "edit" ? _.get(dataMap, currentId) : {}}
            handleSave={handleSave}
            handleDownload={handleDownload}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "document.title"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
