import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceWidgetFieldFilterForm from "../filter/SourceWidgetFieldFilterForm";
import SourceWidgetFormPanel from "./SourceWidgetFormPanel";
import SourceWidgetFieldsForm from "../fields/SourceWidgetFieldsForm";
import SourceWidgetUtil from "../../util/SourceWidgetUtil";
import SourceWidgetReorderFields from "../reorder/SourceWidgetReorderFields";
import SourceWidgetFieldMultiColorFormat from "../color-scheme/SourceWidgetFieldMultiColorFormat";
import SourceWidgetTitle from "./SourceWidgetTitle";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function SourceWidgetDataGridForm({
  widget,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const [widgetFields, setWidgetFields] = useState(
    widget["sourceWidgetFields"]
  );

  const chartCode = widget["widget"]["contentCode"];

  function handleReorderFieldsCallback(fields) {
    if (!_.isEqual(fields, widgetFields)) setWidgetFields([...fields]);
  }

  function onHandleSave() {
    const widgetFormData = getValues("widgetForm");

    const sanitizedFormData = NtaiUtils.sanitizeFormData(widgetFormData);

    let revisedSourceWidgetFields = _.cloneDeep(widget["sourceWidgetFields"]);
    const sanitizedFormFieldsData = sanitizedFormData["sourceWidgetFields"];

    revisedSourceWidgetFields.forEach((f, i) => {
      const sanitizedFormFieldData = NtaiUtils.sanitizeFormData(
        _.filter(sanitizedFormFieldsData, {
          uuId: f["uuId"],
        })[0]
      );

      f["headerName"] = sanitizedFormFieldData["headerName"];
      f["valueFormatterScript"] =
        sanitizedFormFieldData["valueFormatterScript"];

      f["fieldOrder"] = _.filter(widgetFields, { uuId: f["uuId"] })[0][
        "fieldOrder"
      ];

      if (chartCode === CHART_CODES.SUMMARY_TABULATION) {
        f["numBoolMetricSumFg"] = sanitizedFormFieldData["numBoolMetricSumFg"];
        f["summaryTabLinkEnabledFg"] =
          sanitizedFormFieldData["summaryTabLinkEnabledFg"];
        f["summaryTabLinkAddValue"] =
          sanitizedFormFieldData["summaryTabLinkAddValue"];
      }

      if (SourceWidgetUtil.isWidgetFieldMeasure(f)) {
        f["metricCode"] = sanitizedFormFieldData["metricCode"];
        f["esAggFilterScript"] = sanitizedFormFieldData["esAggFilterScript"];
      }

      if (SourceWidgetUtil.isWidgetFieldDimension(f)) {
        let sourceWidgetFieldFiltersResult = [];
        if (_.isArray(sanitizedFormFieldData["sourceWidgetFieldFilters"])) {
          const sourceWidgetFieldFilters = _.get(
            sanitizedFormFieldData,
            "sourceWidgetFieldFilters"
          );

          if (
            _.isArray(sourceWidgetFieldFilters) &&
            sourceWidgetFieldFilters.length > 0
          ) {
            sourceWidgetFieldFilters.forEach((swf) => {
              if (swf["typeCode"] !== undefined && swf["typeCode"]["value"]) {
                sourceWidgetFieldFiltersResult.push({
                  value: swf["value"],
                  typeCode: swf["typeCode"]["value"],
                });
              }
            });
            f["sourceWidgetFieldFilters"] = sourceWidgetFieldFiltersResult;
          }
        }
      }
    });

    sanitizedFormData["sourceWidgetFields"] = revisedSourceWidgetFields;

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    let widgetCopy = _.cloneDeep(widget);
    reset({ widgetForm: widgetCopy });
  }, [widget]);

  return (
    <SourceWidgetFormPanel
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <NtaiTabs>
        <Box label="TITLE">
          <SourceWidgetTitle />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FIELDS">
          <SourceWidgetFieldsForm
            widget={widget}
            hideFieldOption={
              chartCode === CHART_CODES.SUMMARY_TABULATION ? false : true
            }
            summaryTabOptions={true}
          />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="ORDER">
          <SourceWidgetReorderFields
            data={_.orderBy(widgetFields, ["fieldOrder"], ["asc"])}
            handleCallback={handleReorderFieldsCallback}
          />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FILTERS">
          <SourceWidgetFieldFilterForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="COLOR">
          <SourceWidgetFieldMultiColorFormat
            handleWidgetSave={handleSave}
            widget={widget}
          />
        </Box>
      </NtaiTabs>
    </SourceWidgetFormPanel>
  );
}
