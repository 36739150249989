import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiLineEChart from "./NtaiLineEChart";

const data = {
  series: [
    {
      name: "Series 1",
      data: [1, 23, 30, 45, 5, 6, 7],
    },
    {
      name: "Series 2",
      data: [2, 13, 4, 10, 15, 8, 9],
    },
  ],
  category: {
    name: "Category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
};

export default function NtaiTestLine() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Line chart">
          <NtaiLineEChart
            widgetId="line-chart"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId1: {
                  name: "field1",
                  fieldOrder: 1,
                  field: { uuId: "fieldUuId1" },
                },
                fieldUuId2: {
                  name: "field2",
                  fieldOrder: 2,
                  field: { uuId: "fieldUuId2" },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
