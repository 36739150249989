import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceRecordLabelTagForm from "./SourceRecordLabelTagForm";
import {
  createSourceRecordLabelTag,
  deleteSourceRecordLabelTag,
  getSourceRecordLabelTagList,
  updateSourceRecordLabelTag,
  clearStatus,
} from "./store/sourceRecordLabelTagsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "sourceRecordTag.name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "sourceRecordTag.fgColor",
    numeric: false,
    disablePadding: false,
    label: "FG Color",
  },
  {
    id: "sourceRecordTag.bgColor",
    numeric: false,
    disablePadding: false,
    label: "BG Color",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function SourceRecordLabelTagList() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const labelId = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.activeId
  );

  const parentIds = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    labelId: labelId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordLabelTagsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceRecordLabelTagsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["sourceRecordTag.name"], ["asc"])
      : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(deleteSourceRecordLabelTag({ ...parentIds, uuId: id }));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceRecordLabelTag({
            ...parentIds,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceRecordLabelTag({
            ...parentIds,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "remove") confirmDelete();
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceRecordLabelTagList" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceRecordLabelTag" ||
        status.method === "updateSourceRecordLabelTag")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceRecordLabelTag"
    ) {
      setOpenConfirmDeleteDialog(false);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceRecordLabelTagList(parentIds));
  }, []);

  return (
    <>
      {loaded && (
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleDelete={confirmDelete}
          handleToolbarAction={handleToolbarAction}
          title="Tag"
        />
      )}
      {!loaded && <NtaiCircularProgress />}
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Tag"
        size="sm"
      >
        <NtaiForm>
          <SourceRecordLabelTagForm
            action={action}
            formData={action === "edit" ? dataMap[currentId] : {}}
            handleFormSubmit={handleFormSubmit}
            handleDialogClose={handleDialogClose}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "sourceRecordTag.name"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
