import React, { useEffect, useState } from "react";
import { faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, useTheme } from "@mui/material";

import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import SearchSourceLinkedSourceList from "./SearchSourceLinkedSourceList";
import { SearchSourceContext } from "../../SearchSource";
import { SearchContext } from "../../../SearchEdit";
import { getSearchSourceLinkedSourcePreviews } from "./store/searchSourceLinkedSourcePreviewsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import {
  clearStatus,
  createOrRetrieveLinkedSearchSource,
} from "../../store/searchSourcesSlice";
import NtaiTooltip from "@ntai/components/tooltip/NtaiTooltip";
const _ = require("lodash");

export default function SearchSourceLinkedSourceWrapper(props) {
  const { searchSourceUuId } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  // const searchSourceId = _.get(searchSourceData, "uuId");

  const dataMap = useSelector(
    (state) => state.search.searchSourceLinkedSourcePreviewsSlice.data
  );


  const status = useSelector(
    (state) => state.search.searchSourceLinkedSourcePreviewsSlice.status
  );

  const searchSourcesSliceStatus = useSelector(
    (state) => state.search.searchSourcesSlice.status
  );

  const numOfLinkedSources = dataMap ? Object.keys(dataMap).length : 0;

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const clickAwayHandler = () => {
    setOpen(false);
  };

  function handleLinkedSourceSelect(linkedSourceSourceUuId) {
    dispatch(
      createOrRetrieveLinkedSearchSource({
        searchId: searchId,
        parentSearchSourceId: searchSourceUuId,
        linkedSourceSourceUuId: linkedSourceSourceUuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceLinkedSourcePreviews"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      searchSourcesSliceStatus &&
      searchSourcesSliceStatus.result === "success" &&
      searchSourcesSliceStatus.method === "createOrRetrieveLinkedSearchSource"
    ) {
      handleClose();
      dispatch(clearStatus());
    }
  }, [searchSourcesSliceStatus]);

  useEffect(() => {
    dispatch(
      getSearchSourceLinkedSourcePreviews({
        searchId: searchId,
        searchSourceId: searchSourceId,
      })
    );
  }, [searchSourceId]);

  return (
    <>
      {!loaded && (
        <IconButton>
          <NtaiCircularProgress size={12} />
        </IconButton>
      )}
      {loaded && (
        <NtaiMuiBadge
          top={7}
          right={3}
          badgeContent={numOfLinkedSources}
          color="secondary"
        >
          <Tooltip title="Linked Sources">
            <IconButton onClick={(e) => handleOpen(e)}>
              <FontAwesomeIcon size="2xs" icon={faLink} />
            </IconButton>
          </Tooltip>
        </NtaiMuiBadge>
      )}

      {open && (
        <NtaiPopover
          left={14}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          clickAwayHandler={clickAwayHandler}
          anchorOriginH="left"
          anchorOriginV="bottom"
          transformOriginH="left"
          transformOriginV="top"
        >
          <SearchSourceLinkedSourceList
            handleLinkedSourceSelect={handleLinkedSourceSelect}
            handleClose={handleClose}
          />
        </NtaiPopover>
      )}
    </>
  );
}
