import { faSave } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React from "react";
import { useState } from "react";
import ReportSaveForm from "./ReportSaveForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";

export default function ReportSaveDialog({ handleFormSubmit }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        sx={{
          fontSize: "11px",
          gap: theme.spacing(1),
        }}
        color="inherit"
        onClick={() => handleOpen()}
      >
        <FontAwesomeIcon icon={faSave} />
        SAVE
      </Button>
      <NtaiDialog
        title="Save Report"
        size="sm"
        open={open}
        handleDialogClose={handleClose}
      >
        <ReportSaveForm
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleClose}
        />
      </NtaiDialog>
    </>
  );
}
