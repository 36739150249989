import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightEntityOverviewViews = createAsyncThunk(
  "oversightEntityOverviewView/getOversightEntityOverviewViews",
  async (values, { rejectWithValue }) => {
    try {
      const { objectUuId, dataUuId } = values;
      const response = await server.get(
        `/oversight/objects/${objectUuId}/entities/${dataUuId}/dashboard/views`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOversightEntityOverviewView = createAsyncThunk(
  "oversightEntityOverviewView/getOversightEntityOverviewView",
  async (values, { rejectWithValue }) => {
    try {
      const { objectUuId, dataUuId, uuId } = values;
      const response = await server.get(
        `/oversight/objects/${objectUuId}/entities/${dataUuId}/dashboard/views/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateOversightEntityOverviewView = createAsyncThunk(
  "oversightEntityOverviewView/updateOversightEntityOverviewView",
  async (values, { rejectWithValue }) => {
    try {
      const { objectUuId, dataUuId, uuId, formData } = values;
      const response = await server.post(
        `/oversight/objects/${objectUuId}/entities/${dataUuId}/dashboard/views/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightEntityOverviewViewsSlice = createSlice({
  name: "oversightEntityOverviewViews",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightEntityOverviewViews.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getOversightEntityOverviewViews",
      };
    },
    [getOversightEntityOverviewViews.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightEntityOverviewViews",
        message: action.payload.message,
      };
    },

    [getOversightEntityOverviewView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getOversightEntityOverviewView",
      };
    },
    [getOversightEntityOverviewView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightEntityOverviewView",
        message: action.payload.message,
      };
    },
    [updateOversightEntityOverviewView.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateOversightEntityOverviewView",
      };
    },
    [updateOversightEntityOverviewView.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateOversightEntityOverviewView",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  oversightEntityOverviewViewsSlice.actions;

export default oversightEntityOverviewViewsSlice.reducer;
