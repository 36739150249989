import SourceCatalogHome from "./SourceCatalogHome";
import SourceCatalogAssetViewList from "./asset/SourceCatalogAssetViewList";

const SourceCatalogConfig = {
  routes: [
    {
      path: "/source-catalog",
      exact: true,
      component: SourceCatalogHome,
    },
    {
      path: "/source-catalog/assets/:filter",
      exact: true,
      component: SourceCatalogAssetViewList,
    },
  ],
};

export default SourceCatalogConfig;
