import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import {
  faFilter,
  faSearch,
  faChartUser,
  faFileImport,
  faArrowDownToSquare,
  faChartMixed,
} from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import React from "react";
import { grey } from "@mui/material/colors";
import SearchModeRbac from "./rbac/SearchModeRbac";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

const data = [
  {
    id: "search",
    label: "Search",
    description:
      "Search across sources using phrases and combination of AND/OR operators",
    avatarIcon: <FontAwesomeIcon icon={faSearch} />,
    permissions: ["app.search.modes.all", "app.search.modes.search"],
  },
  {
    id: "analytics",
    label: "Analytics",
    description:
      "Conduct analysis on source or dataset of interest using pre-defined filter schemes",
    avatarIcon: <FontAwesomeIcon icon={faFilter} />,
    permissions: ["app.search.modes.all", "app.search.modes.analytics"],
  },
  {
    id: "dashboard",
    label: "Dashboard",
    description:
      "Conduct analysis by accessing role based dashboards for desired entities and topics",
    avatarIcon: <FontAwesomeIcon icon={faChartUser} />,
    permissions: ["app.search.modes.all", "app.search.modes.dashboard"],
  },
  {
    id: "reports",
    label: "Reports",
    description:
      "Generate report on one or more analysis using desired report template",
    avatarIcon: <FontAwesomeIcon icon={faChartMixed} />,
    permissions: ["app.search.modes.all", "app.search.modes.report"],
  },
  {
    id: "import-series",
    label: "Import",
    description:
      "Perform analysis on imported series for a selected source using filter schemes",
    avatarIcon: <FontAwesomeIcon icon={faArrowDownToSquare} />,
    permissions: ["app.search.modes.all", "app.search.modes.import"],
  },
];

export default function SearchSelectTypeForm1({
  handleSearchSelect,
  handleClose,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "340px",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faChartSimple} />
        {/* <Typography variant="body2" fontWeight="700"> */}
        <Typography variant="h5" fontWeight="500" color={grey[700]}>
          Select Mode
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <List sx={{ p: 0, width: "100%" }}>
              {data.map((st, i) => {
                return (
                  <SearchModeRbac
                    key={`search-mode-${i}`}
                    id={st.id}
                    avatarIcon={st.avatarIcon}
                    label={st.label}
                    description={st.description}
                    handleSearchSelect={handleSearchSelect}
                    permissions={st.permissions}
                  />
                );
              })}
            </List>
          </Box>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
