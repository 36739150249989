import { Box, useTheme } from "@mui/material";
import React from "react";
import SourceOutJobFrequencyBox from "./SourceOutJobFrequencyBox";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { useDispatch } from "react-redux";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const runOnceInboundFilesOptions = [
  {
    value: 1,
    label: "Use files from pilot pipeline",
  },
  {
    value: 2,
    label: "Use files from inbound copy directory",
  },
];
export default function SourceOutJobFrequency() {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <NtaiPanel
      width="100%"
      subheader="Run once or scheduled frequency can be chosen from the list to schedule migration for the source. list to schedule migration for the source. list to schedule migration for the source list to schedule migration for the source list to schedule migration for the source"
    >
      <Box
        sx={{
          display: "flex",
          width: "75%",
          gap: theme.spacing(1),
        }}
      >
        <NtaiSelectField
          label="Load Type*"
          name="sourceOutJobForm.loadTypeCode"
          options={[
            { value: 1, label: "Overwrite" },
            { value: 2, label: "Append" },
          ]}
          rules={{ required: "Load type is required" }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          height: "100%",
          width: "100%",
        }}
      >
        <SourceOutJobFrequencyBox
          mode={1}
          header="Run one time"
          subheader="Run one time"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: theme.spacing(1),
            }}
          >
            <NtaiSelectField
              label="Read Source Files From"
              name="sourceOutJobForm.inboundFilesSourceCode"
              options={runOnceInboundFilesOptions}
            />
          </Box>
        </SourceOutJobFrequencyBox>
        <SourceOutJobFrequencyBox
          mode={2}
          header="Scheduled run"
          subheader="Schedule run as per pre-defined frequency"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: theme.spacing(1),
            }}
          >
            <NtaiTextField
              name="sourceOutJobForm.cronExpression"
              label="Cron Expression"
              helperText="Every minute = 0 0/1 * 1/1 * ? *"
            />
          </Box>
        </SourceOutJobFrequencyBox>
      </Box>
    </NtaiPanel>
  );
}
