import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import SourceAdjudicationForm from "./SourceAdjudicationForm";
import {
  clearStatus,
  setActiveId,
  updateSourceAdjudication,
} from "./store/sourceAdjudicationsSlice";
const _ = require("lodash");

export default function SourceAdjudicationEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  //   const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  //   const status = useSelector((state) => state.core.objectsSlice.status);
  //   const dataMap = useSelector((state) => state.core.objectsSlice.data);
  //   const activeId = useSelector((state) => state.core.objectsSlice.activeId);
  const id = props.match.params.id;
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const dataMap = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.data
  );
  const activeId = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.activeId
  );
  const status = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.status
  );

  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceAdjudication({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: formData,
      })
    );
  }

  function handleCancel() {
    history.push(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (
      status.method === "updateSourceAdjudication" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Source assessment form "${_.get(
          dataMap[activeId],
          "adjObjectShortName"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`${_.get(sourceDefData, "name")} / Assessment Forms / ${
        formData["adjObjectShortName"]
      }`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`}
    >
      <NtaiForm>
        <SourceAdjudicationForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
