import { Avatar, AvatarGroup, Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AlertEditContext } from "../../../AlertEdit";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getAlertActivityUsers,
} from "./store/alertActivityUsersSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function AlertActivityUsers() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { alertId, alertMasterId, activityId } =
    React.useContext(AlertEditContext);

  const dataMap = useSelector(
    (state) => state.monitor.alertActivityUsersSlice.data
  );
  const status = useSelector(
    (state) => state.monitor.alertActivityUsersSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  useEffect(() => {
    if (
      status &&
      status.method === "getAlertActivityUsers" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getAlertActivityUsers({
        alertId: alertId,
        alertMasterId: alertMasterId,
        activityId: activityId,
      })
    );
  }, [alertId, alertMasterId, activityId]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "2px",
      }}
    >
      <Typography variant="subtitle2" color="inherit" fontWeight="300">
        Users assigned
      </Typography>
      {loaded &&
        _.isArray(data) &&
        data.length == 1 &&
        data.map((o, i) => (
          <Avatar key={`avatar-list-${i}`} sx={{ width: 24, height: 24 }}>
            <Typography color={grey[900]} variant="subtitle2">
              {o["initial"]}
            </Typography>
          </Avatar>
        ))}

      {loaded && _.isArray(data) && data.length > 1 && (
        <AvatarGroup max={3} onClick={() => console.log("AG clicked")}>
          {data.map((o, i) => (
            <Avatar key={`avatar-list-${i}`} sx={{ width: 24, height: 24 }}>
              <Typography color={grey[900]} variant="subtitle2">
                {o["initial"]}
              </Typography>
            </Avatar>
          ))}
        </AvatarGroup>
      )}

      {!loaded && <NtaiCircularProgress size={18} />}
    </Box>
  );
}
