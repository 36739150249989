import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import {
  getSourceDefinitions,
  clearStatus,
} from "../../../../sourcedefinition/store/sourceDefinitionsSlice";
import DashboardSourceWidgetFormCriteria from "./DashboardSourceWidgetFormCriteria";
import DashboardSourceWidgetSelection from "./DashboardSourceWidgetSelection";

const _ = require("lodash");

function getSourceOptions(sourceDefData) {
  const result = [];
  sourceDefData.forEach((sd, i) => {
    sd.sourceMins.forEach((sdsm, j) => {
      result.push({
        value: sdsm.uuId,
        label: sdsm.displayName,
      });
    });
  });

  return result;
}

export default function DashboardSourceWidgetForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [sourcesLoaded, setSourcesLoaded] = React.useState();
  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const sourceDefData = Object.values(sourceDefDataMap);

  const sourceOptions =
    sourceDefData &&
    _.isArray(sourceDefData) &&
    sourceDefData.length > 0 &&
    getSourceOptions(sourceDefData);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("dashboardWidgetForm")
    );

    handleFormSubmit({ updateTypeFg: 1, ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ dashboardWidgetForm: action === "edit" ? formData : {} });
  }, []);

  useEffect(() => {
    if (
      sourceDefinitionStatus &&
      sourceDefinitionStatus.result === "success" &&
      sourceDefinitionStatus.method === "getSourceDefinitions"
    ) {
      setSourcesLoaded(true);
      dispatch(clearStatus());
    }
  }, [sourceDefinitionStatus]);

  useEffect(() => {
    dispatch(
      getSourceDefinitions({
        activeFg: 1,
      })
    );
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Description"
          subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          {sourcesLoaded && sourceDefData.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(2),
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "75%",
                    flexDirection: "column",
                  }}
                >
                  <NtaiTextField
                    name="dashboardWidgetForm.name"
                    label="Name*"
                    rules={{ required: "Name is required" }}
                  />
                </Box>

                <NtaiSwitchField
                  name="dashboardWidgetForm.activeFg"
                  label="Active?"
                />
              </Box>

              <NtaiTextField
                name="dashboardWidgetForm.label"
                label="Label*"
                rules={{ required: "Label is required" }}
              />

              <NtaiTextField
                name="dashboardWidgetForm.description"
                label="Description"
                placeholder="Description"
                multiline
                minRows={2}
                maxRows={2}
              />

              <NtaiSelectField
                isDisabled={action === "edit" ? true : false}
                name="dashboardWidgetForm.sourceUuId"
                options={sourceOptions}
                label="Source*"
                rules={{ required: "Source is required" }}
              />
              {action === "edit" && (
                <DashboardSourceWidgetSelection formData={formData} />
              )}
            </Box>
          )}
        </NtaiFormPanel>
      </Box>

      <Box
        disabled={action === "add" ? true : false}
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
        label="CRITERIA"
      >
        <DashboardSourceWidgetFormCriteria
          formData={formData}
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleCancel}
        />
      </Box>
      {/* <Box
        disabled={action === "add" ? true : false}
        sx={{ display: "flex", height: "100%" }}
        label="CHART"
      >
        <DashboardSourceWidgetManager formData={formData} />
      </Box>
      <Box
        disabled={action === "add" ? true : false}
        sx={{ display: "flex", height: "100%" }}
        label="WIDGET"
      >
 
      </Box> */}
    </NtaiTabs>
  );
}
