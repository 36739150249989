import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceInFileUploadForm from "../shared/SourceInFileUploadForm";
import { clearStatus } from "../store/sourceInsSlice";
import {
  copySourceInStoreFiles,
  deleteSourceInStoreFile,
  getSourceInStoreFiles,
  updateSourceInStoreFile,
  uploadSourceInStoreFiles,
} from "../store/sourceInStoreFilesSlice";
import SourceInJsonEditForm from "./SourceInJsonEditForm";
import { useSnackbar } from "notistack";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
const _ = require("lodash");

function getFileSize(cols) {
  return NtaiUtils.formatBytes(cols[0].colValue);
}

const headCells = [
  {
    id: "uuId",
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "datasetName",
    disablePadding: true,
    label: "Dataset Name",
    fontWeight: 700,
  },
  {
    id: "fileName",
    disablePadding: true,
    label: "File Name",
  },
  {
    id: ["fileSize"],
    disablePadding: true,
    label: "Size",
    transformFunc: getFileSize,
  },

  {
    id: "dateCreated",
    dataType: "date",
    disablePadding: true,
    label: "Date Started",
    width: "15%",
  },

  {
    id: "dateModified",
    dataType: "date",
    disablePadding: true,
    label: "Date Completed",
    width: "15%",
  },
];

export default function SourceInJsonList() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [uploadData, setUploadData] = useState();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [fileEditDialogOpen, setFileEditDialogOpen] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceInId = useSelector(
    (state) => state.sources.sourceInsSlice.activeId
  );
  const sourceInDataMap = useSelector(
    (state) => state.sources.sourceInsSlice.data
  );

  const sourceInData = sourceInDataMap[sourceInId];

  const copyFilesFg =
    _.get(sourceInData, "copyFromRemoteDirFg") == 1 ? true : false;

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceInId: sourceInId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreFilesSlice.data
  );
  const data = Object.values(dataMap);

  console.log("Data: ", data);

  const status = useSelector(
    (state) => state.sources.sourceInStoreFilesSlice.status
  );

  function handleProcessFiles() {
    if (copyFilesFg) {
      // copy files
      dispatch(
        copySourceInStoreFiles({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourceInId: sourceInId,
        })
      );
    } else {
      handleFileUploadDialogOpen();
    }
  }

  function handleFileUploadDialogOpen() {
    setFileUploadDialogOpen(true);
  }

  function handleFileUploadDialogClose() {
    setFileUploadDialogOpen(false);
  }

  function handleFileEditDialogOpen() {
    setFileEditDialogOpen(true);
  }

  function handleFileEditDialogClose() {
    setFileEditDialogOpen(false);
  }

  function handleToolbarAction(actionName, ids) {
    if (actionName === "upload") handleFileUploadDialogOpen();
    else if (actionName === "delete") {
      setSelectedIds(ids);
      setSelectedItems([
        ...NtaiUtils.getFieldArrayFromObject(ids, dataMap, "fileName"),
      ]);
      confirmDelete();
    }
  }

  function handleFileMetadataEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
      handleFileEditDialogOpen();
    }
  }

  function handleFormSubmit(formData) {
    dispatch(
      updateSourceInStoreFile({
        ...params,
        uuId: currentId,
        formData: {
          ...formData,
          sourceInTypeCode: sourceInData["typeCode"],
        },
      })
    );
  }

  function onHandleFileUpload(formData, config) {
    setUploadData({ formData: formData, config: config });
  }

  function handleFileUpload() {
    dispatch(
      uploadSourceInStoreFiles({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
        ...uploadData,
      })
    );
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteSourceInStoreFile({ ...params, uuId: id }));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "uploadSourceInStoreFiles" &&
      status.result === "success"
    ) {
      handleFileUploadDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "updateSourceInStoreFile" &&
      status.result === "success"
    ) {
      handleFileEditDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceInStoreFile"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `File(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceInStoreFiles({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <NtaiSimpleTable
        rows={data}
        selectedIds={selectedIds}
        emptyRowsContent={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Typography>
              {copyFilesFg
                ? `Files will be copied from ${_.get(
                    sourceInData,
                    "copyFromRemoteDir"
                  )}`
                : "Upload files from local dir"}
            </Typography>
            <Button
              size="small"
              variant="contained"
              onClick={(e) => handleProcessFiles()}
            >
              {copyFilesFg ? "Start copying files" : "Add Files"}
            </Button>
          </Box>
        }
        headCells={headCells}
        keyColumn="uuId"
        dialog={true}
        toolbarStaticActions={[
          { value: "upload", label: "Upload", icon: "upload" },
        ]}
        toolbarActions={[{ value: "delete", label: "Delete", icon: "delete" }]}
        handleToolbarAction={handleToolbarAction}
        handleDialogAddEdit={handleFileMetadataEdit}
      />

      <NtaiDialog
        title="File Upload"
        open={fileUploadDialogOpen}
        handleDialogClose={handleFileUploadDialogClose}
        size="sm"
      >
        <NtaiForm>
          <SourceInFileUploadForm
            handleCancel={handleFileUploadDialogClose}
            handleFileUpload={onHandleFileUpload}
            handleFormSubmit={handleFileUpload}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiDialog
        title="File Edit"
        open={fileEditDialogOpen}
        handleDialogClose={handleFileEditDialogClose}
        size="sm"
      >
        <NtaiForm>
          <SourceInJsonEditForm
            action={action}
            formData={action === "edit" ? dataMap[currentId] : {}}
            handleFormSubmit={handleFormSubmit}
            handleDialogClose={handleFileEditDialogClose}
          />
        </NtaiForm>
      </NtaiDialog>

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "fileName")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
