import { useTheme } from "@mui/material";
import NtaiMenu from "@ntai/components/menus/NtaiMenu";
import React from "react";

export default function SearchSourceRecordsMenu(props) {
  const theme = useTheme();

  const { open, anchorEl, handleClose, handleMenuItemSelect, items } = props;
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const menuOpen = Boolean(anchorMenuEl);

  function onHandleMenuItemSelect(menuAction) {
    handleMenuItemSelect(menuAction);
  }

  return (
    <React.Fragment>
      <NtaiMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        items={items}
        handleMenuItemSelect={handleMenuItemSelect}
      />
    </React.Fragment>
  );
}
