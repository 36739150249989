import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiDnd from "@ntai/components/dnd/NtaiDnd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { grey } from "@mui/material/colors";
import {
  clearStatus,
  getAllSourceViewsForObject,
} from "./store/objectDashboardsSlice";
import { ObjectDashboardContext } from "./ObjectDashboardForm";
const _ = require("lodash");

function getSelectedViews(objectDashboardViews, sourceViews) {
  let result = [];
  if (_.isArray(objectDashboardViews)) {
    objectDashboardViews.forEach((odv) => {
      result.push({
        uuId: odv.sourceViewUuId,
        label: sourceViews[odv.sourceViewUuId]["label"],
        viewOrder: odv.viewOrder,
      });
    });
  }

  return result;
}

function getAvailableViews(objectDashboardViews, sourceViews) {
  let result = [];

  result = _.filter(
    _.orderBy(Object.values(sourceViews), ["label"], ["asc"]),
    function (f) {
      if (objectDashboardViews.map((d) => d.sourceViewUuId).includes(f.uuId))
        return false;
      else return true;
    }
  );

  return result;
}

export default function ObjectDashboardSourceViews({ formData, handleUpdate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { selectedDashboardViews, setSelectedDashboardViews } =
    React.useContext(ObjectDashboardContext);

  const [availableViews, setAvailableViews] = useState();
  const [selectedViews, setSelectedViews] = useState();

  const { getValues, setValue, reset } = useFormContext();

  const domainsDataMap = useSelector((state) => state.core.domainsSlice.data);
  const domainsData = Object.values(domainsDataMap);
  const domainId = _.get(domainsData[0], "uuId");
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);

  const status = useSelector(
    (state) => state.core.objectDashboardsSlice.status
  );
  const allSourceViews = useSelector(
    (state) => state.core.objectDashboardsSlice.allSourceViews
  );

  function handleReorder(items) {
    const reorderedViews = items.map((f, i) => {
      return {
        sourceViewUuId: f.id,
        viewOrder: i + 1,
      };
    });
    const tmpSelectedViews = selectedViews.map((f, i) => {
      return {
        sourceViewUuId: f.uuId,
        viewOrder: i + 1,
      };
    });
    if (!_.isEqual(reorderedViews, tmpSelectedViews)) {
      handleUpdate(reorderedViews);
    }
  }

  function handleAddRemove(action, viewUuId) {
    let result = [];
    if (action === "add") {
      result = [
        ..._.orderBy(selectedViews, ["viewOrder"], ["asc"]).map((f, i) => {
          return {
            sourceViewUuId: f.uuId,
            viewOrder: i + 1,
          };
        }),
        {
          sourceViewUuId: viewUuId,
          viewOrder: selectedViews.length > 0 ? selectedViews.length + 1 : 1,
        },
      ];
    } else if (action === "remove") {
      result = _.filter(selectedViews, function (f) {
        if (f.uuId !== viewUuId) return true;
      });
      result = result.map((f, i) => {
        return {
          sourceViewUuId: f.uuId,
          viewOrder: i + 1,
        };
      });
    }
    setAvailableViews(getAvailableViews(result, allSourceViews));
    setSelectedViews(getSelectedViews(result, allSourceViews));
    handleUpdate(result);
  }

  function handleRemove(viewUuId) {
    handleAddRemove("remove", viewUuId);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getAllSourceViewsForObject"
    ) {
      setAvailableViews(
        getAvailableViews(formData["objectDashboardViews"], allSourceViews)
      );
      setSelectedViews(
        getSelectedViews(formData["objectDashboardViews"], allSourceViews)
      );

      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getAllSourceViewsForObject({
        domainId: domainId,
        objectId: objectId,
      })
    );
  }, [objectId]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "54px",
          gap: theme.spacing(1),
        }}
      >
        <Button sx={{ fontSize: "12px" }} size="small">
          REMOVE ALL
        </Button>
        <Button sx={{ fontSize: "12px" }} variant="contained" size="small">
          ADD ALL
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: `calc(100% - 54px)`,
          border: theme.general.border1,
        }}
      >
        <Box
          sx={{
            flexBasis: "30%",
            display: "flex",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          {_.isArray(availableViews) && availableViews.length > 0 && (
            <Scrollbar>
              <List sx={{ p: 0 }}>
                {_.orderBy(availableViews, ["label"], ["asc"]).map((fd, i) => (
                  <React.Fragment key={`field-${i}`}>
                    <ListItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: theme.spacing(1),
                          pr: theme.spacing(1),
                          alignItems: "center",
                        }}
                      >
                        {/* <NtaiBadge
                          title={_.get(fd, "type") === 1 ? "S" : "D"}
                          fg="grey"
                          bg={grey[100]}
                        /> */}
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ fontWeight: 700 }}
                              variant="subtitle1"
                            >
                              {fd.label}
                            </Typography>
                          }
                          secondary={fd.name}
                        />
                      </Box>

                      <Button
                        sx={{ fontSize: "12px" }}
                        variant="outlined"
                        size="small"
                        onClick={() => handleAddRemove("add", fd.uuId)}
                      >
                        ADD
                      </Button>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Scrollbar>
          )}
        </Box>
        <Box
          sx={{
            flexBasis: "70%",
            display: "flex",
          }}
        >
          <Scrollbar>
            <Box sx={{ p: theme.spacing(2) }}>
              {selectedViews &&
                _.isArray(selectedViews) &&
                selectedViews.length > 0 && (
                  <NtaiDnd
                    idField="uuId"
                    labelField="label"
                    data={selectedViews}
                    handleCallback={handleReorder}
                    direction="row"
                    flexWrap="wrap"
                    secondaryAction="close"
                    handleSecondaryAction={handleRemove}
                  />
                )}
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </Box>
  );
}
