import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceDatasetFields,
  updateSourceDatasetField,
} from "../../../pl/stage/dataset/store/sourceFieldsSlice";
import SourceOutStoreDbDatasetFieldForm from "./SourceOutStoreDbDatasetFieldForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiUtils from "@ntai/utils";
import SourceOutDbFieldMapping from "./SourceOutDbFieldMapping";
const _ = require("lodash");

export default function SourceOutStoreDbDatasetFields({
  sourceDatasetId,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [currentId, setCurrentId] = useState();
  const [mappingDrawerOpen, setMappingDrawerOpen] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = useSelector(
    (state) => state.sources.sourceOutStoresSlice.activeId
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourceFieldsSlice.data
  );

  const status = useSelector((state) => state.sources.sourceFieldsSlice.status);

  const sourceFieldsData = _.filter(
    Object.values(sourceFieldsDataMap),
    function (o) {
      return o["name"].startsWith("sys_") ? false : true;
    }
  );

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: "*",
    sourceStageId: "*",
    sourceDatasetId: sourceDatasetId,
  };

  function handleFieldSelect(sourceFieldUuId) {
    setCurrentId(sourceFieldUuId);
  }

  function handleSave(formData) {
    dispatch(
      updateSourceDatasetField({
        ...params,
        uuId: currentId,
        formData: formData,
      })
    );
  }

  function handleMappingDrawerOpen() {
    setMappingDrawerOpen(true);
  }

  function handleMappingDrawerClose() {
    setMappingDrawerOpen(false);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceDatasetFields" &&
      status.result === "success"
    ) {
      if (_.isArray(sourceFieldsData)) {
        setCurrentId(_.get(sourceFieldsData[0], "uuId"));
      }
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceDatasetFields(params));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FIELD_ES_DATA_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FIELD_STRATEGY_CODE));
  }, [sourceOutStoreId]);

  return (
    <React.Fragment>
      <NtaiPanel
        width="75%"
        header="Store Fields"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
        actionsJustifyContent="end"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Available Fields ({sourceFieldsData && sourceFieldsData.length})
          </Typography>
          <Button size="small" onClick={() => handleMappingDrawerOpen()}>
            View Mapping
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "380px",
            border: theme.general.border1,
            borderRadius: theme.general.borderRadius,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              borderRight: theme.general.border1,
            }}
          >
            {sourceFieldsData && _.isArray(sourceFieldsData) && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {sourceFieldsData.map((sf, i) => (
                    <React.Fragment key={`source-field-${i}`}>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemButton
                          onClick={(e) => handleFieldSelect(sf.uuId)}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                color="inherit"
                                fontWeight="700"
                              >
                                {sf.label}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="subtitle2">
                                {sf.name} [
                                {NtaiUtils.getPostgresDataTypeText(
                                  sf.dbColDataTypeDecode,
                                  sf.dbColLength,
                                  sf.dbColScale,
                                  sf.dbColPrecision
                                )}
                                ]
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Scrollbar>
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
            }}
          >
            {sourceFieldsDataMap && currentId && (
              <SourceOutStoreDbDatasetFieldForm
                sourceFieldId={currentId}
                dataMap={sourceFieldsDataMap}
                handleSave={handleSave}
              />
            )}
            {!sourceFieldsDataMap && <NtaiCircularProgress />}
          </Box>
        </Box>
      </NtaiPanel>
      <Drawer
        open={mappingDrawerOpen}
        onClose={handleMappingDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        <SourceOutDbFieldMapping />
      </Drawer>
    </React.Fragment>
  );
}
