import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";

const _ = require("lodash");

export default function TopicForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("topicForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ topicForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
      subheader="Provide meaningful name and description for the topic"
    >
      <NtaiTextField
        name="topicForm.name"
        label="Name*"
        rules={{ required: "Topic name is required" }}
      />

      <NtaiTextField
        name="topicForm.description"
        label="Description*"
        multiline
        minRows={2}
        maxRows={2}
        rules={{ required: "Topic description is required" }}
      />

      <NtaiSwitchField name="topicForm.privacyFg" label="Private?" />
    </NtaiDialogFormPanel>
  );
}
