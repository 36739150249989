import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import RolePermissions from "./resources/policy/RolePermissions";
import RoleDashboards from "./resources/dashboard/RoleDashboards";
import RoleSourceViews from "./resources/source-view/RoleSourceViews";
import RoleWorkflowActivities from "./resources/workflow-activity/RoleWorkflowActivities";
const _ = require("lodash");

export default function RoleForm({ action, formData, handleSave }) {
  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const roleTypeOptions = _.get(codes, CODELIST_CODES.COR_ROLE_TYPE_CODE);

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("roleForm")
      );

      if (!_.isEmpty(sanitizedFormData)) {
        handleSave(sanitizedFormData);
      }
    }
  }

  function handleCancel() {
    history.push("/admin/roles");
  }

  useEffect(() => {
    reset({ roleForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Description"
          subheader="Enter a unique name for the role, such as Admin or Manager and provide brief description of the role's purpose and responsibilities."
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: theme.spacing(2),
            }}
          >
            <NtaiTextField
              name="roleForm.name"
              label="Name*"
              rules={{ required: "Name is required" }}
            />
            <NtaiTextField
              name="roleForm.description"
              multiline
              minRows={3}
              maxRows={3}
              placeholder="Description"
              label="Description"
            />
            {/* <NtaiSelectField
              name="roleForm.typeCode"
              options={roleTypeOptions}
            /> */}
            <NtaiSwitchField name="roleForm.activeFg" label="Active?" />
          </Box>
        </NtaiFormPanel>
      </Box>
      {/* <Box disabled={action === "add" ? true : false} label="SOURCES">
        <RoleSourceList />
      </Box> */}
      <Box disabled={action === "add" ? true : false} label="POLICIES">
        <RolePermissions />
      </Box>
      <Box disabled={action === "add" ? true : false} label="DASHBOARDS">
        <RoleDashboards />
      </Box>
      <Box disabled={action === "add" ? true : false} label="SOURCE VIEWS">
        <RoleSourceViews />
      </Box>
      <Box disabled={action === "add" ? true : false} label="WORKFLOWS">
        <RoleWorkflowActivities />
      </Box>
    </NtaiTabs>
  );
}
