import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import NtaiRichEditor from "./NtaiRichEditor";

export default function TestRichEditor() {
  const [value, setValue] = useState("");

  return (
    <Box sx={{ display: "flex", p: "12px", flexDirection: "column" }}>
      <NtaiRichEditor value={value} setValue={setValue} />
      <Button onClick={() => console.log(value)}>SUBMIT</Button>
    </Box>
  );
}
