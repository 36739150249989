import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import fileUploadServer from "app/http/ntaiFileUploadServer";
const _ = require("lodash");

export const getWorkflowDefVersions = createAsyncThunk(
  "workflowDefVersions/getWorkflowDefVersions",
  async (workflowDefId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/admin/wfdefinitions/${workflowDefId}/versions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWorkflowDefVersion = createAsyncThunk(
  "workflowDefVersions/getWorkflowDefVersion",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, uuId } = values;
      const response = await server.get(
        `/admin/wfdefinitions/${workflowDefId}/versions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createWorkflowDefVersion = createAsyncThunk(
  "workflowDefVersions/createWorkflowDefVersion",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, formData, config } = values;
      const response = await server.post(
        `/admin/wfdefinitions/${workflowDefId}/versions`,
        formData,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateWorkflowDefVersion = createAsyncThunk(
  "workflowDefVersions/updateWorkflowDefVersion",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, uuId, formData, config } = values;
      const response = await server.patch(
        `/admin/wfdefinitions/${workflowDefId}/versions/${uuId}`,
        formData,
        config
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deployWorkflowDefVersion = createAsyncThunk(
  "workflowDefVersions/deployWorkflowDefVersion",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, uuId } = values;
      const response = await server.post(
        `/admin/wfdefinitions/${workflowDefId}/versions/${uuId}/deploy`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteWorkflowDefVersion = createAsyncThunk(
  "workflowDefVersions/deleteWorkflowDefVersion",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, uuId } = values;
      const response = await server.delete(
        `/admin/wfdefinitions/${workflowDefId}/versions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const workflowDefVersionsSlice = createSlice({
  name: "workflowDefVersions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getWorkflowDefVersions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getWorkflowDefVersions" };
    },
    [getWorkflowDefVersions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefVersions",
        message: action.payload.message,
      };
    },
    [getWorkflowDefVersion.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getWorkflowDefVersion" };
    },
    [getWorkflowDefVersion.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefVersion",
        message: action.payload.message,
      };
    },
    [createWorkflowDefVersion.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createWorkflowDefVersion" };
    },
    [createWorkflowDefVersion.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createWorkflowDefVersion",
        message: action.payload.message,
      };
    },
    [updateWorkflowDefVersion.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateWorkflowDefVersion" };
    },
    [updateWorkflowDefVersion.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateWorkflowDefVersion",
        message: action.payload.message,
      };
    },
    [deployWorkflowDefVersion.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "deployWorkflowDefVersion" };
    },
    [deployWorkflowDefVersion.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deployWorkflowDefVersion",
        message: action.payload.message,
      };
    },

    [deleteWorkflowDefVersion.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteWorkflowDefVersion" };
    },
    [deleteWorkflowDefVersion.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteWorkflowDefVersion",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = workflowDefVersionsSlice.actions;

export default workflowDefVersionsSlice.reducer;
