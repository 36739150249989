import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import { getSourceOutDataset } from "../../../../source/out/store/sourceOutDatasetsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

export default function SourceCatalogAssetDatasetInfo({ uuId }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const dataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.status
  );

  const data =
    dataMap && !_.isEmpty(dataMap) && _.has(dataMap, uuId)
      ? dataMap[uuId]
      : null;

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceOutDataset" &&
      status.result === "success"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceOutDataset({
        sourceDefId: "*",
        sourceId: "*",
        sourceOutStoreId: "*",
        uuId: uuId,
      })
    );
  }, [uuId]);

  return (
    <React.Fragment>
      {loaded && data && (
        <>
          <NtaiLabelValue label="Name" value={data["datasetName"]} />
          <NtaiLabelValue
            label="Source"
            value={data["sourceMin"]["displayName"]}
          />
        </>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </React.Fragment>
  );
}
