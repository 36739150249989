import { Box, Icon, useTheme } from "@mui/material";
import React from "react";
import NtaiAccordion from "./NtaiAccordion";

export default function NtaiTestAccordions() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        display: "flex",
        gap: "12px",
        justifyContent: "center",
      }}
    >
      <NtaiAccordion titleIcon={<Icon>star</Icon>} title="Test me again">
        <Box sx={{ width: 250 }}>Some text here and there</Box>
      </NtaiAccordion>
    </Box>
  );
}
