import React, { useState } from "react";
import {
  faCaretDown,
  faChartSimple,
  faFileLines,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import { SearchSourceContext } from "../../SearchSource";
import SearchSourceReportTemplateItems from "./SearchSourceReportTemplateItems";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import NtaiTooltip from "@ntai/components/tooltip/NtaiTooltip";

export default function SearchSourceReportTemplateWrapper() {
  const theme = useTheme();
  const [openSourceReportMenu, setOpenSourceReportMenu] = useState(false);
  const [anchorElSourceReportMenu, setAnchorElSourceReportMenu] =
    useState(null);

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const handleSourceReport = (event) => {
    setOpenSourceReportMenu(true);
    setAnchorElSourceReportMenu(event.currentTarget);
  };
  const handleSourcReportMenuClose = () => {
    setOpenSourceReportMenu(false);
    setAnchorElSourceReportMenu(null);
  };

  const clickAwayHandler = () => {
    setOpenSourceReportMenu(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Reports">
          <IconButton onClick={(e) => handleSourceReport(e)}>
            <FontAwesomeIcon size="2xs" icon={faFileLines} />
          </IconButton>
        </Tooltip>

        {/* 
        <Button
          sx={{
            fontSize: "10px",
            fontWeight: 500,
            gap: theme.spacing(1),
          }}
          size="small"
          color="inherit"
          onClick={(e) => handleSourceReport(e)}
        >
          <FontAwesomeIcon size="xl" icon={faFilePdf} />
          REPORTS
        </Button> */}
      </Box>
      {openSourceReportMenu && (
        <NtaiPopover
          open={openSourceReportMenu}
          anchorEl={anchorElSourceReportMenu}
          handleClose={handleSourcReportMenuClose}
          clickAwayHandler={clickAwayHandler}
        >
          <SearchSourceReportTemplateItems
            handleClose={handleSourcReportMenuClose}
          />
        </NtaiPopover>
      )}
    </>
  );
}
