import { Box, Chip, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createEntityTopic,
  deleteEntityTopic,
  getEntityTopics,
} from "./store/entityTopicsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import { clearStatus } from "./store/entityTopicsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function EntityTopic({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const domainId = useSelector((state) => state.core.entitiesSlice.domainUuId);
  const objectId = useSelector((state) => state.core.entitiesSlice.objectUuId);
  const dataId = useSelector((state) => state.core.entitiesSlice.activeId);

  const dataMap = useSelector((state) => state.core.entityTopicsSlice.data);
  const data = Object.values(dataMap);
  const status = useSelector((state) => state.core.entityTopicsSlice.status);

  const { getValues, reset } = useFormContext();

  const parameters = { domainId: domainId, objectId: objectId, dataId: dataId };

  useEffect(() => {
    dispatch(
      getEntityTopics({
        ...parameters,
        params: { includeObjectTopics: true },
      })
    );
  }, []);

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("entityTopicForm")
    );

    dispatch(
      createEntityTopic({
        ...parameters,
        formData: { topic: sanitizedFormData },
      })
    );
  }

  function handleDelete(uuId) {
    dispatch(
      deleteEntityTopic({
        ...parameters,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createEntityTopic" &&
      status.result === "success"
    ) {
      reset({ entityTopicForm: {} });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <NtaiFormPanel
        width="50%"
        header="Add Data Topic"
        handleSave={handleSave}
        handleCancel={handleCancel}
        subheader="You can add one or more topics within the current entity context"
      >
        <NtaiTextField
          name="entityTopicForm.name"
          label="Name*"
          placeholder="Topic"
          rules={{ required: "Name is required" }}
        />
        <NtaiTextField
          name="entityTopicForm.description"
          label="Description"
          placeholder="Description"
          multiline
          minRows={3}
          maxRows={3}
        />
      </NtaiFormPanel>
      <NtaiPanel
        width="50%"
        header="Available Topics"
        subheader="Topics inherited from entity's object and data are shown here."
      >
        <Box
          sx={{
            display: "flex",
            alignContent: "start",
            flexWrap: "wrap",
            gap: theme.spacing(1),
          }}
        >
          {data.map((dataTopic, index) => {
            return (
              <Chip
                label={dataTopic.topic.name}
                variant="outlined"
                size="small"
                onDelete={
                  dataTopic.objectTopicFg
                    ? null
                    : () => handleDelete(dataTopic.uuId)
                }
              />
            );
          })}
        </Box>
      </NtaiPanel>
    </Box>
  );
}
