import { Box, FormControl, Typography } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { default as Select } from "react-select";
import NtaiInputErrorMessage from "./NtaiInputErrorMessage";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

const NtaiSelect = (props) => {
  const { options, onChange, name, label } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      sx={{
        display: "flex",
        width: props.width ? props.width : "100%",
      }}
    >
      <FormControl fullWidth>
        {label && (
          <Typography variant="subtitle1" fontWeight="500" color={grey[600]}>
            {label}
          </Typography>
        )}

        <Select
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          onChange={(val) => onChange(val.map((c) => c.value))}
          options={options}
          // displayEmpty
          // renderValue={(value) => {
          //   return value || "None";
          // }}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          // menuPortalTarget={document.body}
          // menuPosition={isFixed ? "fixed" : "absolute"}
          // menuPlacement={portalPlacement}
          menuShouldScrollIntoView={false}
          {...props}
        />

        <NtaiInputErrorMessage name={name} errors={errors} />
      </FormControl>
    </Box>
  );
};

function NtaiSelectField(props) {
  const { control } = useFormContext();
  const { name, options } = props;

  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <NtaiSelect
              onChange={onChange}
              value={options.find((c) => c.value === value)}
              {...props}
            />
          );
        }}
        control={control}
        name={name}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiSelectField;
