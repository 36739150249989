import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightEntityOverviewViewWidget = createAsyncThunk(
  "oversightEntityOverviewViewWidget/getOversightEntityOverviewViewWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { objectUuId, dataUuId, viewId, uuId } = values;
      const response = await server.get(
        `/oversight/objects/${objectUuId}/entities/${dataUuId}/dashboard/views/${viewId}/widgets/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightEntityOverviewViewWidgetsSlice = createSlice({
  name: "oversightEntityOverviewViewWidgets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = { ...state.status, [action.payload]: null };
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightEntityOverviewViewWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };

      state.status = {
        ...state.status,
        [action.payload.uuId]: {
          method: "getOversightEntityOverviewViewWidget",
          result: "success",
        },
      };
    },
    [getOversightEntityOverviewViewWidget.rejected]: (state, action) => {
      state.status = {
        ...state.status,
        [action.payload.uuId]: {
          method: "getOversightEntityOverviewViewWidget",
          result: "error",
          message: action.payload.message,
        },
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  oversightEntityOverviewViewWidgetsSlice.actions;

export default oversightEntityOverviewViewWidgetsSlice.reducer;
