import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useState } from "react";
import NtaiDnd3 from "@ntai/components/dnd3/NtaiDnd3";
import { SourceFilterSchemeContext } from "./SourceFilterSchemeFieldsWrapper";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function SourceFilterSchemeReorderFields(props) {
  const theme = useTheme();
  const [reorderedItems, setReorderedItems] = useState();
  const { handleClose } = props;
  const { mergedSourceFields, selectedFields, setSelectedFields } =
    React.useContext(SourceFilterSchemeContext);

  function generateItems() {
    let result = [];

    selectedFields.forEach((fieldUuId, index) => {
      result.push({
        uuId: fieldUuId,
        label: _.filter(mergedSourceFields, { uuId: fieldUuId })[0]["label"],
      });
    });

    return result;
  }

  function handleReorder(selectedItems) {
    const tmpSelectedItems = selectedItems.map((o, i) => o["uuId"]);
    setSelectedFields([...tmpSelectedItems]);
  }

  return (
    <NtaiPanel
      title="Reorder Fields"
      subheader="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
      // handleSave={handleSave}
      handleCancel={handleClose}
    >
      <Box sx={{ height: "240px" }}>
        <Scrollbar>
          <Box sx={{ p: theme.spacing(2) }}>
            <NtaiDnd3
              id="uuId"
              label="label"
              items={generateItems()}
              handleReorder={handleReorder}
              direction="vertical"
            />
          </Box>
        </Scrollbar>
      </Box>
    </NtaiPanel>
  );
}
