import React, { useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, Link, useTheme } from "@mui/material";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownWideShort,
  faEllipsisVertical,
  faSearch,
  faSortDown,
} from "@fortawesome/pro-regular-svg-icons";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

function getColumns(id, data) {
  const result = [];
  if (_.isArray(data) && data.length > 0) {
    const row1 = data[0];
    Object.keys(row1).forEach((o) => {
      result.push({
        accessorKey: o,
        header: o,
      });
    });
  }

  return result;
}

export default function NtaiMRTable(props) {
  const theme = useTheme();
  const [rowSelection, setRowSelection] = React.useState({});
  const {
    data,
    columns,
    sortColumnId,
    enableFullScreenToggle,
    enableHiding,
    enableColumnResizing,
  } = props;

  const [cols, setCols] = useState([]);

  useEffect(() => {
    console.log("Row selection: ", rowSelection);
  }, [rowSelection]);

  useEffect(() => {
    setCols(columns ? columns : getColumns(data));
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      {cols && cols.length > 0 && (
        <MaterialReactTable
          icons={{
            SearchIcon: () => <FontAwesomeIcon icon={faSearch} />,
            MoreVertIcon: () => (
              <FontAwesomeIcon size="xs" icon={faEllipsisVertical} />
            ),
          }}
          enableRowSelection={true}
          // onRowSelectionChange={setRowSelection}
          // getRowId={(r) => r.id}
          muiColumnActionsButtonProps={{
            sx: {
              mr: theme.spacing(0.5),
              height: 24,
              width: 24,
            },
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: "0",
              border: theme.general.border1,
            },
          }}
          columns={cols}
          initialState={{
            columnVisibility: { description: false },
            density: "compact",
            showGlobalFilter: false,
            // sorting: [{ id: sortColumnId, desc: false }],
          }}
          enableFullScreenToggle={
            enableFullScreenToggle ? enableFullScreenToggle : false
          }
          enableDensityToggle={false}
          muiTableHeadRowProps={{
            sx: {
              background: grey[100],
              color: "black",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              color: grey[900],
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              verticalAlign: "top",
              height: "auto",
              fontSize: "13px",
              whiteSpace: "normal",
              wordWrap: "break-word",
            },
          }}
          muiPaginationProps={{
            size: "small",
            variant: "string",
          }}
          enableTopToolbar={true}
          renderTopToolbarCustomActions={() => (
            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              <Button variant="contained" size="small">
                CREATE ACCOUNT
              </Button>
              <Button size="small">ARCHIVE</Button>
            </Box>
          )}
          {...props}
        />
      )}
      {cols && cols.length === 0 && (
        <NtaiEmptyMessage title="No data available" />
      )}
    </Box>
  );
}
