import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightTopicAdjs = createAsyncThunk(
  "oversightTopicAdjs/getOversightTopicAdjs",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, params } = values;
      const response = await server.get(
        `/oversight/topics/${oversightTopicId}/adjudications`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOversightTopicAdj = createAsyncThunk(
  "oversightTopicAdjs/getOversightTopicAdj",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId } = values;
      const response = await server.get(
        `/oversight/topics/${oversightTopicId}/adjudications/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOversightTopicAdj = createAsyncThunk(
  "oversightTopicAdjs/createOversightTopicAdj",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, formData } = values;
      const response = await server.post(
        `/oversight/topics/${oversightTopicId}/adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateOversightTopicAdj = createAsyncThunk(
  "oversightTopicAdjs/updateOversightTopicAdj",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId, formData } = values;
      const response = await server.patch(
        `/oversight/topics/${oversightTopicId}/adjudications/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOversightTopicAdj = createAsyncThunk(
  "oversightTopicAdjs/deleteOversightTopicAdj",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId } = values;
      const response = await server.delete(
        `/oversight/topics/${oversightTopicId}/adjudications/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightTopicAdjsSlice = createSlice({
  name: "oversightTopicAdjs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightTopicAdjs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getOversightTopicAdjs" };
    },
    [getOversightTopicAdjs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightTopicAdjs",
        message: action.payload.message,
      };
    },
    [getOversightTopicAdj.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getOversightTopicAdj" };
    },
    [getOversightTopicAdj.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightTopicAdj",
        message: action.payload.message,
      };
    },
    [createOversightTopicAdj.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "createOversightTopicAdj",
      };
    },
    [createOversightTopicAdj.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOversightTopicAdj",
        message: action.payload.message,
      };
    },
    [updateOversightTopicAdj.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "updateOversightTopicAdj",
      };
    },
    [updateOversightTopicAdj.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateOversightTopicAdj",
        message: action.payload.message,
      };
    },
    [deleteOversightTopicAdj.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteOversightTopicAdj",
      };
    },
    [deleteOversightTopicAdj.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteOversightTopicAdj",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = oversightTopicAdjsSlice.actions;

export default oversightTopicAdjsSlice.reducer;
