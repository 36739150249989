import { Box, Slider, Typography } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiFilterBox from "./NtaiFilterBox";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

function NtaiFilterRangeSlider(props) {
  const { control } = useFormContext();

  const { min, max, step, label, displaymarks } = props;

  // const marks = [
  //   {
  //     value: min,
  //     label: NtaiUtils.abbrevNumber(min),
  //   },
  //   {
  //     value: max,
  //     label: NtaiUtils.abbrevNumber(max),
  //   },
  // ];

  return (
    <NtaiFilterBox>
      <Typography variant="subtitle2" fontWeight="700">
        {label}
      </Typography>
      <Box sx={{ px: "0px" }}>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Slider
              size="small"
              defaultValue={[min, max]}
              value={value ? value : [min, max]}
              onChange={(_, val) => onChange(val)}
              min={min}
              max={max}
              valueLabelDisplay="auto"
              // marks={displaymarks === "true" ? marks : null}
              // step={step ? step : _.round((max - min) / 100)}
              step={1}
              {...props}
            />
          )}
          control={control}
          {...props}
        />
      </Box>
    </NtaiFilterBox>
  );
}

export default NtaiFilterRangeSlider;
