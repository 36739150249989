import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const validateSearchSeries = createAsyncThunk(
  "searchSeries/validateSearchSeries",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/search/series/validate", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSeriesSlice = createSlice({
  name: "searchSeries",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [validateSearchSeries.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "validateSearchSeries" };
    },
    [validateSearchSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "validateSearchSeries",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchSeriesSlice.actions;

export default searchSeriesSlice.reducer;
