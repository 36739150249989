import { faRemove } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { ObjectSourceViewContext } from "./ObjectSourceViewForm";
const _ = require("lodash");

export default function ObjectSourceViewWidgetBox(props) {
  const theme = useTheme();
  const [selected, setSelected] = React.useState(false);
  const { selectedWidgetIds, setSelectedWidgetIds } = React.useContext(
    ObjectSourceViewContext
  );
  const { id, title, description, removeOption, handleRemove } = props;

  function toggleWidgetSelect(currentId) {
    setSelected(!selected);
    if (selectedWidgetIds.includes(currentId)) {
      let selectedWidgetIdsCopy = [...selectedWidgetIds];
      let updatedWidgetIds = _.filter(
        selectedWidgetIdsCopy,
        (widgetId) => widgetId !== currentId
      );
      setSelectedWidgetIds([...updatedWidgetIds]);
    } else {
      setSelectedWidgetIds([...selectedWidgetIds, currentId]);
    }
  }

  return (
    <Tooltip title={title}>
      <Box
        sx={{
          height: "100px",
          width: "140px",
          display: "flex",
          flexDirection: "column",
          p: theme.spacing(1),
          border: selected ? theme.general.border2p : theme.general.border1,
          borderRadius: theme.general.borderRadius,
          "&:hover": {
            boxShadow: theme.colors.shadows.cardSm,
            cursor: "pointer",
          },
        }}
        onClick={() => (removeOption ? null : toggleWidgetSelect(id))}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {NtaiUtils.trunc(title, 15)}
          </Typography>
          {removeOption && (
            <IconButton onClick={() => handleRemove(id)}>
              <FontAwesomeIcon size="2xs" icon={faRemove} />
            </IconButton>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle2">{description}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}
