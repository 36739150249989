import { Box, Chip, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { SearchContext } from "../../SearchEdit";
import SearchDashboardViewSourceWrapper from "./SearchDashboardViewSourceWrapper";
const _ = require("lodash");

export default function SearchDashboardWrapper() {
  const theme = useTheme();

  const { searchData, selectedSearchObjectDashboardIndex } =
    React.useContext(SearchContext);
  const searchSourceViews =
    searchData["searchObjectDashboards"] &&
    _.isArray(searchData["searchObjectDashboards"])
      ? _.get(
          searchData["searchObjectDashboards"][
            selectedSearchObjectDashboardIndex
          ],
          "searchSourceViews"
        )
      : [];

  return (
    <NtaiTabs>
      {searchSourceViews &&
        searchSourceViews.length > 0 &&
        searchSourceViews.map((searchSourceView, i) => (
          <SearchDashboardViewSourceWrapper
            key={`search-dashboard-view-${i}`}
            index={i}
            label={searchSourceView["sourceView"]["name"].toUpperCase()}
            searchId={searchData["uuId"]}
            searchSourceId={searchSourceView["searchSourceUuId"]}
            sourceView={searchSourceView["sourceView"]}
          />
        ))}
    </NtaiTabs>
  );
}
