import {
  FormHelperText,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import NtaiFilterBox from "./NtaiFilterBox";
const _ = require("lodash");

export default function NtaiFilterCheckboxGroup(props) {
  const { error, helperText, label, name, options } = props;

  const { control } = useFormContext();

  return (
    <NtaiFilterBox>
      <Typography variant="subtitle2" fontWeight="700">
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref, ...field } }) => (
          <FormGroup>
            {options.map((o) => {
              return (
                <FormControlLabel
                  label={
                    <Typography variant="subtitle2" color="inherit">
                      {o.label}
                    </Typography>
                  }
                  key={o.value}
                  control={
                    <Checkbox
                      sx={{ py: 0 }}
                      size="small"
                      {...field}
                      name={o.value}
                      checked={
                        value
                          ? value.some((option) => option === o.value)
                          : false
                      }
                      onChange={(event, checked) => {
                        if (checked) {
                          onChange(
                            _.isArray(value)
                              ? [...value, event.target.name]
                              : [event.target.name]
                          );
                        } else {
                          onChange(
                            value.filter((value) => value !== event.target.name)
                          );
                        }
                      }}
                      inputRef={ref}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        )}
      />
    </NtaiFilterBox>
  );
}
