import { Box, IconButton, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateSourceCatalogAsset,
  getSourceCatalogAsset,
  clearStatus,
} from "./store/sourceCatalogAssetsSlice";
import SourceCatalogAssetGeneral from "./general/SourceCatalogAssetGeneral";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceCatalogAssetStatistics from "./statistics/SourceCatalogAssetStatistics";
import SourceCatalogAssetSchema from "./schema/SourceCatalogAssetSchema";
import SourceCatalogDocumentation from "./documentation/SourceCatalogDocumentation";
import SourceCatalogAssetPreview from "./preview/SourceCatalogAssetPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

export default function SourceCatalogAsset({ asset, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const dataMap = useSelector(
    (state) => state.sources.sourceCatalogAssetsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceCatalogAssetsSlice.status
  );

  function handleSave(formData) {
    dispatch(createOrUpdateSourceCatalogAsset(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      ["getSourceCatalogAsset", "createOrUpdateSourceCatalogAsset"].includes(
        status.method
      )
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceCatalogAsset({
        assetUuId: asset["uuId"],
        typeCode: asset["typeCode"],
      })
    );
  }, [asset]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "720px",
        height: "100%",
      }}
    >
      {loaded && (
        <React.Fragment>
          <NtaiPanel
            width="100%"
            header={asset["name"]}
            subheader={
              "Source: " +
              asset["sourceName"] +
              " | " +
              "Type: " +
              asset["typeDecode"] +
              " | Date Modified: " +
              NtaiUtils.formatDateCol(asset["dateModified"])
            }
            headerActions={
              <IconButton onClick={() => handleCancel()}>
                <FontAwesomeIcon size="xs" icon={faClose} />
              </IconButton>
            }
          />
          <NtaiTabs>
            <Box label="GENERAL">
              <NtaiForm>
                <SourceCatalogAssetGeneral
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                  assetUuId={asset["uuId"]}
                  assetTypeCode={asset["typeCode"]}
                />
              </NtaiForm>
            </Box>
            <Box
              visible={[3, 4].includes(asset["typeCode"]) ? true : false}
              label="STATISTICS"
              sx={{ display: "flex", height: "100%" }}
            >
              <SourceCatalogAssetStatistics asset={asset} />
            </Box>
            <Box
              visible={[3].includes(asset["typeCode"]) ? true : false}
              label="PREVIEW"
              sx={{ display: "flex", height: "100%" }}
            >
              <SourceCatalogAssetPreview
                assetUuId={asset["uuId"]}
                assetTypeCode={asset["typeCode"]}
              />
            </Box>

            <Box
              sx={{ display: "flex", height: "100%", width: "100%" }}
              visible={[1, 2, 3].includes(asset["typeCode"]) ? true : false}
              label="SCHEMA"
            >
              <SourceCatalogAssetSchema asset={asset} />
            </Box>
            <Box sx={{ height: "100%" }} label="DOCUMENTATION">
              <SourceCatalogDocumentation />
            </Box>
          </NtaiTabs>
        </React.Fragment>
      )}
    </Box>
  );
}
