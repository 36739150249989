import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createEntitySource,
  deleteEntitySource,
  getEntitySources,
  updateEntitySource,
  setActiveId,
} from "./store/entitySourcesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import EntitySourceForm from "./EntitySourceForm";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

function transformFields(cols) {
  const result = [];
  if (_.isArray(cols[0].colValue)) {
    cols[0].colValue.forEach((f, i) => {
      result.push(f.sourceObjectField.sourceField.name);
    });
  }

  return _.join(result, ", ");
}

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "sourceObject.sourceMin.displayName",
    numeric: false,
    disablePadding: false,
    label: "Source",
    width: "20%",
    fontWeight: "700",
  },
  {
    id: "esCriteria",
    numeric: false,
    disablePadding: false,
    label: "Selection Criteria",
    transformFunc: function (o) {
      if (!_.isEmpty(o)) {
        return JSON.stringify(o);
      }
    },
    width: "30%",
  },

  {
    id: "dataSourceFields",
    numeric: false,
    disablePadding: false,
    label: "Filter Criteria",
    transformFunc: function (o) {
      const fieldValues = o.map((p) => {
        return p["fieldValue"];
      });

      return fieldValues.join(" and ");
    },
    width: "20%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
    width: "15%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function EntitySourceList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState("list");
  const [loaded, setLoaded] = React.useState(false);
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const domainId = useSelector((state) => state.core.entitiesSlice.domainUuId);
  const objectId = useSelector((state) => state.core.entitiesSlice.objectUuId);
  const dataId = useSelector((state) => state.core.entitiesSlice.activeId);

  const params = { domainId: domainId, objectId: objectId, dataId: dataId };
  const dataMap = useSelector((state) => state.core.entitySourcesSlice.data);
  const status = useSelector((state) => state.core.entitySourcesSlice.status);
  const activeId = useSelector(
    (state) => state.core.entitySourcesSlice.activeId
  );
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
      dispatch(setActiveId(id));
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteEntitySource({ ...params, uuId: id }));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateEntitySource({ ...params, uuId: currentId, formData: formData })
        )
      : dispatch(createEntitySource({ ...params, formData: formData }));
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(
        selected,
        dataMap,
        "sourceObject.sourceMin.displayName"
      ),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getEntitySources" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "createEntitySource"
    ) {
      enqueueSnackbar(`Source assigned successfully.`, {
        variant: "success",
      });

      setAction("edit");
      setCurrentId(activeId);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "updateEntitySource"
    ) {
      dispatch(clearStatus());

      enqueueSnackbar(
        `Source "${dataMap[currentId]["sourceObject"]["sourceMin"]["displayName"]}" updated successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteEntitySource"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Source(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getEntitySources({ ...params }));
  }, []);

  return (
    <React.Fragment>
      {action === "list" && (
        <NtaiPanel
          width="100%"
          header="Assign Sources"
          subheader="You can assign one or more sources to the entity. For example, for country unit entities you can assign Product source and then use selection and filter criteria to add products specific to the country unit"
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              dialog={true}
              handleDialogAddEdit={handleDialogAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Sources"
            />
          )}

          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      )}
      {(action === "add" || (action === "edit" && currentId)) && (
        <EntitySourceForm
          action={action}
          handleCancel={handleCancel}
          handleFormSubmit={handleFormSubmit}
          formData={action === "edit" ? dataMap[currentId] : null}
        />
      )}

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "sourceObject.sourceMin.displayName"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
