import { Drawer, useTheme } from "@mui/material";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import ObjectFieldForm from "./ObjectFieldForm";
import {
  clearStatus,
  createObjectField,
  deleteObjectField,
  getObjectFields,
  updateObjectField,
  setActiveId,
} from "./store/objectFieldsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "fieldName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "fieldLabel",
    numeric: false,
    disablePadding: false,
    label: "Label",
  },
  {
    id: "fieldTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "fieldUiDecode",
    numeric: false,
    disablePadding: false,
    label: "UI Type",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "delete",
  },
];

export default function ObjectFieldList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dataMap = useSelector((state) => state.core.objectFieldsSlice.data);
  const activeId = useSelector(
    (state) => state.core.objectFieldsSlice.activeId
  );
  const status = useSelector((state) => state.core.objectFieldsSlice.status);
  const objectUuId = useSelector((state) => state.core.objectsSlice.activeId);

  const objectDataMap = useSelector((state) => state.core.objectsSlice.data);
  const domainId = _.get(_.get(objectDataMap, objectUuId), "domainUuId");

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["fieldName"], ["asc"])
      : [];

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateObjectField({
            objectId: objectUuId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createObjectField({
            objectId: objectUuId,
            formData: formData,
          })
        );
  }

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDrawerOpen();
  }

  function handleDrawerOpen() {
    setOpenDrawer(true);
  }

  function handleDrawerClose() {
    setOpenDrawer(false);
  }

  function confirmDelete(ids) {
    setSelectedIds(ids);
    setOpenConfirmDeleteDialog(true);
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "fieldName"),
    ]);

    if (action === "delete") {
      confirmDelete();
    }

    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteObjectField({
          objectId: objectUuId,
          uuId: id,
        })
      );
    });
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getObjectFields" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status.result === "success" &&
      (status.method === "createObjectField" ||
        status.method === "updateObjectField")
    ) {
      handleDrawerClose();
      setCurrentId(null);
      dispatch(setActiveId(null));
      dispatch(clearStatus());

      enqueueSnackbar(
        `Field "${
          dataMap[action === "add" ? activeId : currentId]["fieldName"]
        }" saved successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteObjectField"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Field(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getObjectFields({ domainId: domainId, objectId: objectUuId }));
    dispatch(getCodes(CODELIST_CODES.COR_META_FIELD_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.COR_META_FIELD_UI_CODE));
  }, []);

  return (
    <React.Fragment>
      <NtaiPanel
        width="75%"
        header="Object Fields"
        subheader="An object field (also called data member or member variable or data field) is a particular piece of data encapsulated within an object. Think of First Name, Last Name, Age, Gender and so on while defining fields."
      >
        {loaded && (
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleDelete={confirmDelete}
            handleToolbarAction={handleToolbarAction}
            title="Field"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPanel>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "fieldName")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
      <Drawer
        open={openDrawer}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            position: "absolute",
            width: "520px",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        {(action === "add" || (action === "edit" && currentId)) && (
          <NtaiForm>
            <ObjectFieldForm
              action={action}
              handleCancel={handleDrawerClose}
              handleFormSubmit={handleFormSubmit}
              formData={action === "edit" ? dataMap[currentId] : {}}
            />
          </NtaiForm>
        )}
      </Drawer>
    </React.Fragment>
  );
}
