import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import NtaiUtils from "@ntai/utils/NtaiUtils";

import _ from "lodash";

const mapKeysDeep = (obj, id) => {
  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;
    if (Array.isArray(obj[key])) {
      obj[key] = { ..._.mapKeys(obj[key], id) };
    }
    if (value && typeof value === "object") {
      mapKeysDeep(value, id);
    }
  });
};

export const getLatestSource = createAsyncThunk(
  "sources/getLatestSource",
  async (sourceDefId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/sourcedefs/${sourceDefId}/sources`, {
        params: {
          latestVersion: true,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllSources = createAsyncThunk(
  "sources/getAllSources",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, latestVersion } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources`,
        values && {
          params: values,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSources = createAsyncThunk(
  "sources/getSources",
  async (sourceDefId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/sourcedefs/${sourceDefId}/sources`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSource = createAsyncThunk(
  "sources/getSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const selectSourceVersion = createAsyncThunk(
  "sources/selectSourceVersion",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSource = createAsyncThunk(
  "sources/createSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceVersion = createAsyncThunk(
  "sources/createSourceVersion",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSource = createAsyncThunk(
  "sources/updateSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSource = createAsyncThunk(
  "sources/deleteSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}`
      );
      return { uuId: sourceId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceFields = createAsyncThunk(
  "sources/getSourceFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceDatasetsMin = createAsyncThunk(
  "sources/getSourceDatasetsMin",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/datasets`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcesSlice = createSlice({
  name: "sources",
  initialState: {
    data: {},
    activeId: null,
    status: null,
    fields: {},
    fieldLookups: {},
    datasets: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getLatestSource.fulfilled]: (state, action) => {
      state.data = { ...state.data, ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getLatestSource" };
      state.activeId =
        _.has(action.payload[0], "uuId") && action.payload[0].uuId;
    },
    [getLatestSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getLatestSource",
        message: action.payload.message,
      };
    },

    [getAllSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAllSources" };
    },
    [getAllSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAllSources",
        message: action.payload.message,
      };
    },

    [getSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSources" };
    },
    [getSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSources",
        message: action.payload.message,
      };
    },

    [selectSourceVersion.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "selectSourceVersion" };
    },

    [selectSourceVersion.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "selectSourceVersion",
        message: action.payload.message,
      };
    },

    [createSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSource" };
    },

    [createSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSource",
        message: action.payload.message,
      };
    },

    [createSourceVersion.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSourceVersion" };
    },

    [createSourceVersion.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceVersion",
        message: action.payload.message,
      };
    },

    [updateSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSource" };
    },
    [updateSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSource",
        message: action.payload.message,
      };
    },
    [deleteSource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.activeId = null;
      state.status = { result: "success", method: "deleteSource" };
    },
    [deleteSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSource",
        message: action.payload.message,
      };
    },

    [getSourceFields.fulfilled]: (state, action) => {
      state.fields = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceFields" };
    },

    [getSourceFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceFields",
        message: action.payload.message,
      };
    },

    [getSourceDatasetsMin.fulfilled]: (state, action) => {
      state.datasets = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceDatasetsMin" };
    },

    [getSourceDatasetsMin.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDatasetsMin",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourcesSlice.actions;

export default sourcesSlice.reducer;
