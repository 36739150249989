import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceInStoreDatasets = createAsyncThunk(
  "sourceInStoreDatasets/getSourceInStoreDatasets",
  async (values) => {
    const { sourceDefId, sourceId, sourceInStoreId } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets`
    );
    return response.data;
  }
);

export const createSourceInStoreDataset = createAsyncThunk(
  "sourceInStoreDatasets/createSourceInStoreDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInStoreId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceInStoreDataset = createAsyncThunk(
  "sourceIns/updateSourceInStoreDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInStoreId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceInStoreDataset = createAsyncThunk(
  "sourceInStoreDatasets/deleteSourceInStoreDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInStoreId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const runSourceInStoreDataset = createAsyncThunk(
  "sourceInStoreDatasets/runSourceInStoreDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInStoreId, uuId, formData } = values;
      const response = await server.put(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceInStoreDatasetsSlice = createSlice({
  name: "sourceInStoreDatasets",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceInStoreDatasets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceInStoreDatasets",
      };
    },
    [getSourceInStoreDatasets.rejected]: (state, action) => {
      // state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "error",
        method: "getSourceInStoreDatasets",
        message: action.payload.message,
      };
    },
    [createSourceInStoreDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceInStoreDataset",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceInStoreDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceInStoreDataset",
        message: action.payload.message,
      };
    },
    [updateSourceInStoreDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceInStoreDataset",
      };
    },
    [updateSourceInStoreDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceInStoreDataset",
        message: action.payload.message,
      };
    },

    [runSourceInStoreDataset.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "runSourceInStoreDataset",
      };
    },

    [runSourceInStoreDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "runSourceInStoreDataset",
      };
    },
    [runSourceInStoreDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "runSourceInStoreDataset",
        message: action.payload.message,
      };
    },

    [deleteSourceInStoreDataset.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceInStoreDataset",
      };
    },
    [deleteSourceInStoreDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceInStoreDataset",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceInStoreDatasetsSlice.actions;

export default sourceInStoreDatasetsSlice.reducer;
