import {
  faCaretDown,
  faChartUser,
  faEllipsisV,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import { SearchContext } from "../../SearchEdit";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import SearchDashboardGroupMenuItems from "./SearchDashboardGroupMenuItems";

export default function SearchDahboardGroupsMenu() {
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuOpen(true);
    setAnchorElMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
    setAnchorElMenu(null);
  };

  const clickAwayHandler = () => {
    setMenuOpen(false);
  };

  const { searchData, selectedSearchObjectDashboardIndex } =
    React.useContext(SearchContext);

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(2) }}
      >
        <Button
          size="small"
          color="inherit"
          sx={{
            px: theme.spacing(2),
            display: "flex",
            gap: theme.spacing(1.5),
            fontSize: "11px",
            boxShadow: theme.colors.shadows.cardSm,
            background: theme.colors.secondary.lighter,
          }}
          onClick={(e) => handleMenuOpen(e)}
        >
          <FontAwesomeIcon size="lg" icon={faChartUser} />
          {searchData["searchObjectDashboards"][
            selectedSearchObjectDashboardIndex
          ]["objectDashboardName"].toUpperCase()}
          <FontAwesomeIcon size="lg" icon={faCaretDown} />
        </Button>
        <FontAwesomeIcon icon={faEllipsisV} />
      </Box>

      {menuOpen && (
        <NtaiPopover
          open={menuOpen}
          anchorEl={anchorElMenu}
          handleClose={handleMenuClose}
          clickAwayHandler={clickAwayHandler}
        >
          <SearchDashboardGroupMenuItems handleClose={handleMenuClose} />
        </NtaiPopover>
      )}
    </React.Fragment>
  );
}
