import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";
import SourceMigrationOutJobCopyFilesStep from "./steps/SourceMigrationOutJobCopyFilesStep";
import SourceMigrationOutJobReadFilesStep from "./steps/SourceMigrationOutJobReadFilesStep";
import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import { SourceOutJobMigrationContext } from "./SourceOutJobMigration";
import SourceMigrationOutJobPipelineStep from "./steps/SourceMigrationOutJobPipelineStep";
import SourceMigrationOutJobScdPolicy from "./steps/SourceMigrationOutJobScdPolicy";
import SourceMigrationOutJobLoadStep from "./steps/SourceMigrationOutJobLoadStep";
const _ = require("lodash");

export default function SourceOutJobMigrationStep() {
  const theme = useTheme();

  const { migrationSteps, displayStep, displayStore, migrationData } =
    React.useContext(SourceOutJobMigrationContext);

  const step = migrationSteps[displayStep - 1];

  const migrationStore = _.filter(migrationData["migrationOutStores"], {
    uuId: displayStore,
  })[0];

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <NtaiPanel
        height="80px"
        header={`${migrationStore["storeDecode"]} | ${step["title"]}`}
        subheader={step["description"]}
      />

      <Box
        sx={{
          borderTop: theme.general.border1,
          display: "flex",
          width: "100%",
          height: `calc(100% - 80px)`,
        }}
      >
        <Scrollbar>
          {displayStep === 1 && <SourceMigrationOutJobCopyFilesStep />}
          {displayStep === 2 && <SourceMigrationOutJobReadFilesStep />}
          {displayStep === 3 && <SourceMigrationOutJobPipelineStep />}
          {displayStep === 4 && <SourceMigrationOutJobScdPolicy />}
          {displayStep === 5 && <SourceMigrationOutJobLoadStep />}
        </Scrollbar>
      </Box>
    </Box>
  );
}
