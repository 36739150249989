import { useTheme } from "@mui/material";
import React, { useState } from "react";
import NtaiMRTable from "./NtaiMRTable";
import usePrevious from "app/main/hooks/usePrevious";
const _ = require("lodash");

export default function NtaiMRTableWrapper({
  id,
  path,
  data1,
  columns1,
  rowCount1,
  handleFetch,
  handleTopToolbarLeftAction,
}) {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const searchTerm = usePrevious(globalFilter);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  console.log("Pagination: ", pagination);

  //if you want to avoid useEffect, look at the React Query example instead
  React.useEffect(() => {
    if (
      !_.isEqual(searchTerm, globalFilter) &&
      searchTerm &&
      searchTerm.length > 0
    ) {
      setPagination({ pageSize: pagination.pageSize, pageIndex: 1 });
    }

    handleFetch({
      limit: pagination.pageSize,
      page: !_.isEqual(searchTerm, globalFilter) ? 1 : pagination.pageIndex + 1,
      searchTerm: globalFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // columnFilters, //re-fetch when column filters change
    globalFilter, //re-fetch when global filter changes
    pagination.pageIndex, //re-fetch when page index changes
    pagination.pageSize, //re-fetch when page size changes
    // sorting, //re-fetch when sorting changes
  ]);

  React.useEffect(() => {
    setRowCount(rowCount1);
  }, [rowCount1]);

  return (
    <NtaiMRTable
      data={data1}
      columns={columns1}
      id={id}
      pagination={pagination}
      rowCount={rowCount}
      rowNameKey="userName"
      setPagination={setPagination}
      setGlobalFilter={setGlobalFilter}
      rowClickRedirect={true}
      path={path}
      exportOption={true}
      // enableSearchText={false}
      // enableToggleFilter={false}
      // enableShowHideColumn={false}
      // enableShowHideColumn={true}
      // handleRowClick={handleRowClick}
      tableTitle="Users (3)"
      rowTitle="User"
      rowTitlePlural="Users"
      crud={true}
      handleTopToolbarLeftAction={handleTopToolbarLeftAction}
    />
  );
}
