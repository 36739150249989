import { Box, useTheme } from "@mui/material";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";
import { useSelector } from "react-redux";

export default function UserAccountOverview() {
  const theme = useTheme();

  const userInfo = useSelector((state) => state.auth.userDataSlice.data);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <NtaiPanel
        header="Account Details"
        subheader={`Account details for user ${userInfo.userName}`}
      >
        <NtaiLabelValue1 label="User name:" value={userInfo["userName"]} />
        <NtaiLabelValue1 label="Email:" value={userInfo["email"]} />
      </NtaiPanel>
    </Box>
  );
}
