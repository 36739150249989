import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  updateAwsClusterTemplate,
} from "./store/awsClusterTemplatesSlice";
import AwsClusterTemplateForm from "./AwsClusterTemplateForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AwsClusterTemplateEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const status = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.status
  );
  const dataMap = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.data
  );
  const activeId = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.activeId
  );

  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateAwsClusterTemplate({
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (
      status &&
      status.method === "updateAwsClusterTemplate" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Aws cluster template "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      // history.push(`/aws-cluster-templates`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back="/aws-cluster-templates"
    >
      <NtaiForm>
        <AwsClusterTemplateForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
