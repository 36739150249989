import { Box, darken, lighten, Link, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import NtaiMuiDataGrid from "@ntai/components/grid/mui/NtaiMuiDataGrid";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiTable from "@ntai/components/tables/main/NtaiTable";
import NtaiMRHookTable from "@ntai/components/tables/material-react/NtaiMRHookTable";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchSourceRecordListContext } from "../SearchSourceRecordListPanel";
import {
  clearStatus,
  getSearchSourceRecordsForTable,
} from "../store/searchSourceRecordsSlice";
import { SearchSourceContext } from "../../SearchSource";
import useSearchSourceWidgetTempFilters from "../../hooks/useSearchSourceWidgetTempFilters";
import { SearchContext } from "../../../SearchEdit";
import SearchSourceRecordMRTable from "./SearchSourceRecordMRTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAltEdit } from "@fortawesome/pro-solid-svg-icons";
import {
  // faCommentAltEdit,
  faNote,
  faPaperclip,
  faTasks,
} from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

export default function SearchSourceRecordTableTemplate(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { height, width, handleDisplayRecord } = props;

  const {
    from,
    setFrom,
    templateType,
    selectedRecord,
    setSelectedRecord,
    recordsSearchText,
  } = useContext(SearchSourceRecordListContext);
  const { searchData } = React.useContext(SearchContext);
  const searchId = _.get(searchData, "uuId");
  const { searchSourceData, reload, refresh } =
    React.useContext(SearchSourceContext);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const sourceTemplateListingsDataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const sourceTableTemplatesData =
    sourceTemplateListingsDataMap && !_.isEmpty(sourceTemplateListingsDataMap)
      ? _.filter(Object.values(sourceTemplateListingsDataMap), {
          typeCode: 1,
        })
      : [];

  const tableDataMap = useSelector(
    (state) => state.search.searchSourceRecordsSlice.tableData
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordsSlice.status
  );

  const data =
    tableDataMap && !_.isEmpty(tableDataMap) ? Object.values(tableDataMap) : [];

  const rows = data.map((d) => {
    const actions = [];
    if (d["assessmentFg"])
      actions.push(
        <FontAwesomeIcon
          color={lighten(theme.colors.secondary.dark, 0.3)}
          icon={faCommentAltEdit}
        />
      );
    if (d["numOfDocuments"] > 0)
      actions.push(<FontAwesomeIcon icon={faPaperclip} />);
    if (d["numOfActions"] > 0) actions.push(<FontAwesomeIcon icon={faTasks} />);
    if (d["numOfNotes"]) actions.push(<FontAwesomeIcon icon={faNote} />);

    return {
      recordId: d.recordId,
      actions: (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: theme.spacing(1),
            alignItems: "center",
          }}
        >
          {actions}
        </Box>
      ),
      record: d.minData,
    };
  });

  // const columns = generateTableColumns(sourceTemplateListingsDataMap);
  const columns =
    sourceTemplateListingsDataMap && !_.isEmpty(sourceTemplateListingsDataMap)
      ? getMaterialReactTableColumns(sourceTemplateListingsDataMap)
      : null;

  function fetchCellValue(f, cell) {
    return f.sourceDerivedFieldFg === 0
      ? ![7, 8].includes(f.sourceField["dataTypeCode"])
        ? cell.getValue()
        : NtaiUtils.formatDateCol(cell.getValue())
      : !f.sourceDerivedField["fieldTypeCode"] === 3
      ? cell.getValue()
      : NtaiUtils.formatDateCol(cell.getValue());
  }

  function getMaterialReactTableColumns(templatesDataMap) {
    let result = null;
    const tableTemplates = _.filter(templatesDataMap, function (t) {
      return t.typeCode === 1;
    });

    if (tableTemplates.length === 0) {
      return result;
    }

    if (_.isArray(tableTemplates) && tableTemplates.length > 0) {
      result = [];
      const tableTemplate = tableTemplates[0];

      result.push({
        accessorKey: "actions",
        header: "Actions",
        numeric: false,
        disablePadding: false,
        size: 10,
      });

      const strRecord = "record.";

      if (
        tableTemplate.sourceRecordListingFields &&
        _.isArray(tableTemplate.sourceRecordListingFields) &&
        tableTemplate.sourceRecordListingFields.length > 0
      )
        _.orderBy(
          tableTemplate["sourceRecordListingFields"],
          ["fieldOrder"],
          ["asc"]
        ).forEach((f, i) => {
          result.push({
            accessorKey:
              strRecord +
              (f.sourceDerivedFieldFg === 0
                ? f.sourceField.path
                : f.sourceDerivedField.name),
            header:
              f.sourceDerivedFieldFg === 0
                ? f.sourceField.label
                : f.sourceDerivedField.label,
            primary:
              f.sourceDerivedFieldFg === 0
                ? f.sourceField.primaryFieldFg
                  ? true
                  : false
                : i === 0
                ? true
                : false,
            colorSchemes: f.sourceRecordListingFieldColors,
            Cell: ({ cell, row }) =>
              i === 0 ? (
                <Link
                  sx={{
                    fontWeight: "500",
                    color: "inherit",
                    color: blue[700],
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={(e) =>
                    handleDisplayRecord(e, row.original["recordId"])
                  }
                >
                  {fetchCellValue(f, cell)}
                </Link>
              ) : (
                <React.Fragment>{fetchCellValue(f, cell)}</React.Fragment>
              ),
          });
        });
    } else result = null;

    return result;
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordsForTable"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    // setLoaded(false); // changing 12aug
    if (
      sourceTableTemplatesData &&
      _.isArray(sourceTableTemplatesData) &&
      sourceTableTemplatesData.length > 0
    ) {
      dispatch(
        getSearchSourceRecordsForTable({
          searchId: searchId,
          searchSourceId: searchSourceId,
          reload: reload,
          clear: refresh ? true : false,
          formData: {
            templateType: 1,
            from: 0,
            size: from + 25,
            searchText: recordsSearchText, // Added: 16Jul24
            searchSourceRecordsFetchFilter: "DEFAULT",
            searchSourceWidgetFilters: searchSourceWidgetTempFilters,
          },
        })
      );
    }
  }, []);

  return (
    // <NtaiMuiDataGrid rowId="_id" rows={rows} columns={columns} />s

    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      {loaded &&
        data &&
        data.length > 0 &&
        columns &&
        _.isArray(columns) &&
        columns.length > 0 && (
          <SearchSourceRecordMRTable
            height={height}
            width={width}
            tableTitle={`Records (${rows.length})`}
            id="recordId"
            enableStickyHeader={true}
            enableRowClick={false}
            data={rows}
            columns={columns}
          />
        )}

      {sourceTableTemplatesData && sourceTableTemplatesData.length === 0 && (
        <NtaiEmptyMessage
          vAlign="center"
          header="Table template not configured or assigned"
          subheader="You can configure source table templates under Controls - Source - Tenplates section"
        />
      )}
      {!loaded && sourceTableTemplatesData.length > 0 && (
        <NtaiCircularProgress size={24} />
      )}
    </Box>
  );
}
