import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, InputBase, useTheme } from "@mui/material";
import React from "react";
const _ = require("lodash");

export default function NtaiSimpleSearchBar({
  placeholder,
  height,
  width,
  background,
  fontSize,
  handleChange,
}) {
  const theme = useTheme();

  const debouncedOnHandleChange = _.debounce(onHandleChange, 500);

  function onHandleChange(val) {
    handleChange(val);
  }

  return (
    <Box
      sx={{
        width: width ? width : "100%",
        height: height ? height : "100%",
        display: "flex",
        padding: "2px",
        alignItems: "center",
        border: "1px solid #eee",
        borderRadius: "16px",
        background: background ? background : null,
        "&:hover": {
          boxShadow: theme.colors.shadows.cardSm,
        },
      }}
    >
      <Box sx={{ paddingLeft: "8px", justifyContent: "end" }}>
        <FontAwesomeIcon icon={faSearch} />
      </Box>
      <InputBase
        sx={{
          paddingLeft: "8px",
          fontSize: fontSize ? fontSize : null,
          width: "90%",
        }}
        name="name"
        autoComplete="off"
        placeholder={placeholder ? placeholder : null}
        onChange={(e) => debouncedOnHandleChange(e.target.value)}
      />
    </Box>
  );
}
