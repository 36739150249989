import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, updateSource } from "../../store/sourcesSlice";

export default function SourceVersionEdit({ handleCancel }) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector((state) => state.sources.sourcesSlice.data);
  const status = useSelector((state) => state.sources.sourcesSlice.status);
  const formData = dataMap && sourceId ? dataMap[sourceId] : null;

  function onHandleEditOrSave() {
    if (editMode) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("sourceEditForm")
      );

      dispatch(
        updateSource({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: sanitizedFormData,
        })
      );
    } else {
      setEditMode(true);
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateSource" &&
      status["result"] === "success"
    ) {
      dispatch(clearStatus());
      handleCancel();
    }
  }, [status]);

  useEffect(() => {
    reset({ sourceEditForm: formData });
  }, [sourceId]);

  return (
    <NtaiFormPanel
      width="100%"
      header="Version 1.0"
      subheader={`Date created: ${NtaiUtils.formatDateCol(
        formData["dateCreated"]
      )} | Date Modified: ${NtaiUtils.formatDateCol(formData["dateModified"])}`}
      handleSave={onHandleEditOrSave}
      handleCancel={handleCancel}
      saveLabel={editMode ? "SAVE" : "EDIT"}
    >
      <Scrollbar>
        {!editMode && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <NtaiLabelValue
              label="Published?"
              value={
                formData["publishedFg"] && formData["publishedFg"] === 1
                  ? "Yes"
                  : "No"
              }
            />
            <NtaiLabelValue
              label="Active?"
              value={
                formData["activeFg"] && formData["activeFg"] === 1
                  ? "Yes"
                  : "No"
              }
            />
            <NtaiLabelValue
              label="Locked?"
              value={
                formData["lockedFg"] && formData["lockedFg"] === 1
                  ? "Yes"
                  : "No"
              }
            />

            <NtaiLabelValue
              label="Remarks"
              value={formData["creationRemarks"]}
            />
          </Box>
        )}
        {editMode && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <NtaiSwitchField
              name="sourceEditForm.publishedFg"
              label="Publish"
            />
            <NtaiSwitchField
              name="sourceEditForm.activeFg"
              label="Make active"
            />
            <NtaiSwitchField
              name="sourceEditForm.lockedFg"
              label="Lock this version"
            />
            <NtaiTextField
              name="sourceEditForm.creationRemarks"
              multiline
              minRows={3}
              maxRows={3}
              label="Remarks"
              rules={{ required: "Remarks is required" }}
            />
          </Box>
        )}
      </Scrollbar>
    </NtaiFormPanel>
  );
}
