import AlertConfig from "./inbox/AlertConfig";
import AlertDefinitionConfig from "./definition/AlertDefinitionConfig";
import AlertTrackerConfig from "./tracker/AlertTrackerConfig";

const MonitorConfig = {
  routes: [
    ...AlertDefinitionConfig.routes,
    ...AlertConfig.routes,
    ...AlertTrackerConfig.routes,
  ],
};

export default MonitorConfig;
