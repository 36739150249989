import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import AlertDefinitionGeneralForm from "./general/AlertDefinitionGeneralForm";
import history from "@ntai/@history";
import AlertDefSchedule from "./schedule/AlertDefSchedule";
import AlertDefPreviewSubmit from "./previewsubmit/AlertDefPreviewSubmit";
import AlertDefSourceCriteria from "./criteria/AlertDefSourceCriteria";
import AlertDefSourceTopic from "./topic/AlertDefSourceTopic";
import AlertDefWorkflow from "./workflow/AlertDefWorkflow";
import { useSelector } from "react-redux";

export default function AlertDefinitionForm(props) {
  const theme = useTheme();
  const { action, handleSave, formData } = props;
  const { reset } = useFormContext();

  const disabledFg = action === "add" ? true : false;

  function handleCancel() {
    history.push("/monitor/definitions");
  }
  useEffect(() => {
    reset({ alertDefinitionForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs padding={2}>
      <Box label="GENERAL">
        <AlertDefinitionGeneralForm
          action={action}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </Box>
      <Box disabled={disabledFg} label="CRITERIA">
        <AlertDefSourceCriteria handleCancel={handleCancel} />
      </Box>
      <Box disabled={disabledFg} label="TOPICS">
        <AlertDefSourceTopic handleCancel={handleCancel} />
      </Box>
      <Box disabled={disabledFg} label="WORKFLOW">
        <AlertDefWorkflow handleCancel={handleCancel} />
      </Box>
      <Box disabled={disabledFg} label="SCHEDULE">
        <AlertDefSchedule handleCancel={handleCancel} />
      </Box>
      <Box sx={{ height: "100%" }} disabled={disabledFg} label="PREVIEW">
        <AlertDefPreviewSubmit />
      </Box>
    </NtaiTabs>
  );
}
