import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiSparkRadialBarChart from "./NtaiSparkRadialBarChart";

export default function TestNtaiApexCharts() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        p: theme.spacing(2),
        width: "100%",
        height: "100%",
      }}
    >
      <NtaiSparkRadialBarChart />
    </Box>
  );
}
