import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const MuiSelect = (props) => {
  const { label, name, options } = props;

  return (
    <FormControl>
      <InputLabel size="small" htmlFor={name}>
        {label}
      </InputLabel>
      <Select id={name} {...props}>
        {options.map((item, i) => {
          return (
            <MenuItem key={`select-option-${i}`} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

function NtaiMuiSelectField(props) {
  const { control } = useFormContext();
  const { name, label, options } = props;
  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MuiSelect
            onChange={onChange}
            {...props}
            defaultValue=""
            // value={options.find((c) => c.id === value)}
          />
        )}
        control={control}
        name={name}
        label={label}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiMuiSelectField;
