import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordBulkDocuments = createAsyncThunk(
  "searchSourceRecordBulkDocuments/getSearchSourceRecordBulkDocuments",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/bulk-documents`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordBulkDocument = createAsyncThunk(
  "searchSourceRecordBulkDocuments/getSearchSourceRecordBulkDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/bulk-documents/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecordBulkDocument = createAsyncThunk(
  "searchSourceRecordBulkDocuments/createSearchSourceRecordBulkDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/records/bulk-documents`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecordBulkDocument = createAsyncThunk(
  "searchSourceRecordBulkDocuments/updateSearchSourceRecordBulkDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId, formData } =
        values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/records/bulk-documents/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceRecordBulkDocument = createAsyncThunk(
  "searchSourceRecordBulkDocuments/deleteSearchSourceRecordBulkDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/records/bulk-documents/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadSearchSourceRecordBulkDocument = createAsyncThunk(
  "searchSourceRecordBulkDocuments/downloadSearchSourceRecordBulkDocument",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, fileName, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/documents/${uuId}/download`
      );
      return { fileName: fileName, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordBulkDocumentsSlice = createSlice({
  name: "searchSourceRecordBulkDocuments",
  initialState: {
    data: {},
    downloadData: null,
    downloadFileName: null,
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    clearDownloadData: (state, action) => {
      state.downloadData = null;
      state.downloadFileName = null;
    },
  },
  extraReducers: {
    [getSearchSourceRecordBulkDocuments.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordBulkDocuments",
      };
    },
    [getSearchSourceRecordBulkDocuments.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordBulkDocuments",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordBulkDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordBulkDocument",
      };
    },
    [getSearchSourceRecordBulkDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordBulkDocument",
        message: action.payload.message,
      };
    },
    [createSearchSourceRecordBulkDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceRecordBulkDocument",
      };
    },
    [createSearchSourceRecordBulkDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecordBulkDocument",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecordBulkDocument.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceRecordBulkDocument",
      };
    },
    [updateSearchSourceRecordBulkDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecordBulkDocument",
        message: action.payload.message,
      };
    },
    [deleteSearchSourceRecordBulkDocument.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceRecordBulkDocument",
      };
    },
    [deleteSearchSourceRecordBulkDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceRecordBulkDocument",
        message: action.payload.message,
      };
    },

    [downloadSearchSourceRecordBulkDocument.fulfilled]: (state, action) => {
      state.downloadData = action.payload.data;
      state.downloadFileName = action.payload.fileName;
      state.status = {
        result: "success",
        method: "downloadSearchSourceRecordBulkDocument",
      };
    },
    [downloadSearchSourceRecordBulkDocument.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "downloadSearchSourceRecordBulkDocument",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, clearDownloadData } =
  searchSourceRecordBulkDocumentsSlice.actions;

export default searchSourceRecordBulkDocumentsSlice.reducer;
