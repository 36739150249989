import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourcePolicyAssessments = createAsyncThunk(
  "sourcePolicyAssessments/getSourcePolicyAssessments",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceAssessmentId, sourceId, sourcePolicyId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceAssessmentId}/sources/${sourceId}/policies/${sourcePolicyId}/adjudications`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourcePolicyAssessment = createAsyncThunk(
  "sourcePolicyAssessments/getSourcePolicyAssessment",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceAssessmentId, sourceId, sourcePolicyId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceAssessmentId}/sources/${sourceId}/policies/${sourcePolicyId}/adjudications/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourcePolicyAssessment = createAsyncThunk(
  "sourcePolicyAssessments/createSourcePolicyAssessment",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceAssessmentId, sourceId, sourcePolicyId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceAssessmentId}/sources/${sourceId}/policies/${sourcePolicyId}/adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourcePolicyAssessment = createAsyncThunk(
  "sourcePolicyAssessments/updateSourcePolicyAssessment",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceAssessmentId, sourceId, sourcePolicyId, uuId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceAssessmentId}/sources/${sourceId}/policies/${sourcePolicyId}/adjudications/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourcePolicyAssessment = createAsyncThunk(
  "sourcePolicyAssessments/deleteSourcePolicyAssessment",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceAssessmentId, sourceId, sourcePolicyId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceAssessmentId}/sources/${sourceId}/policies/${sourcePolicyId}/adjudications/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePolicyAssessmentsSlice = createSlice({
  name: "sourcePolicyAssessments",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePolicyAssessments.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourcePolicyAssessments",
      };
    },
    [getSourcePolicyAssessments.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePolicyAssessments",
        message: action.payload.message,
      };
    },
    [getSourcePolicyAssessment.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourcePolicyAssessment" };
    },
    [getSourcePolicyAssessment.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePolicyAssessment",
        message: action.payload.message,
      };
    },
    [createSourcePolicyAssessment.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "createSourcePolicyAssessment",
      };
    },
    [createSourcePolicyAssessment.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourcePolicyAssessment",
        message: action.payload.message,
      };
    },
    [updateSourcePolicyAssessment.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourcePolicyAssessment",
      };
    },
    [updateSourcePolicyAssessment.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourcePolicyAssessment",
        message: action.payload.message,
      };
    },
    [deleteSourcePolicyAssessment.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourcePolicyAssessment",
      };
    },
    [deleteSourcePolicyAssessment.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourcePolicyAssessment",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourcePolicyAssessmentsSlice.actions;

export default sourcePolicyAssessmentsSlice.reducer;
