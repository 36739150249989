import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useEffect, useState } from "react";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiHelpText from "@ntai/components/helptext/NtaiHelpText";
import {
  getWorkflowDefActivityUsers,
  getWorkflowDefs,
} from "app/main/pages/core/admin/workflow/store/workflowDefsSlice";
import { useDispatch, useSelector } from "react-redux";
import useSearchSourceWidgetTempFilters from "../../../hooks/useSearchSourceWidgetTempFilters";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchSourceContext } from "../../../SearchSource";
import { getObjectTopics } from "app/main/pages/core/cdo/object/topic/store/objectTopicsSlice";
import { useFormContext } from "react-hook-form";
import NtaiMultiSelectField from "@ntai/components/inputs/NtaiMultiSelectField";
import SearchSourceRecordsWorkflowRequestForm from "./SearchSourceRecordsWorkflowRequestForm";
const _ = require("lodash");

function getWorkflowDefOptions(sourceDefId, workflowDefsData) {
  let result = [];
  const filteredWfData = _.filter(workflowDefsData, function (o) {
    if (o["sourceDefUuIds"].includes(sourceDefId)) return true;
    else return false;
  });

  return NtaiUtils.getSelectOptions(filteredWfData, "uuId", "name");
}

export default function SearchSourceRecordsWorkflow({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [selectedWfDefinitionUuId, setSelectedWfDefinitionUuId] = useState();
  const [workflowForms, setWorkflowForms] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState();

  const { selectedRecords } = React.useContext(SearchSourceRecordListContext);

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const sourceDefId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.uuId"
  );

  const workflowDefsDataMap = useSelector(
    (state) => state.core.workflowDefsSlice.data
  );

  const workflowDefsData =
    workflowDefsDataMap && !_.isEmpty(workflowDefsDataMap)
      ? Object.values(workflowDefsDataMap)
      : [];

  const workflowDefOptions = getWorkflowDefOptions(
    sourceDefId,
    workflowDefsData
  );

  const objectTopicsDataMap = useSelector(
    (state) => state.core.objectTopicsSlice.data
  );

  const objectTopicsData =
    objectTopicsDataMap && !_.isEmpty(objectTopicsDataMap)
      ? Object.values(objectTopicsDataMap)
      : [];

  const objectTopicOptions = NtaiUtils.getSelectOptions(
    objectTopicsData,
    "uuId",
    "topic.name"
  );

  const watchWorkflowDefUuId = watch(
    "searchSourceWorkflowForm.workflowDefinitionUuId"
  );

  const workflowDefActivityUsers = useSelector(
    (state) => state.core.workflowDefsSlice.startActivityUsers
  );

  const workflowDefActivityUserOptions =
    workflowDefActivityUsers && _.isArray(workflowDefActivityUsers)
      ? NtaiUtils.getSelectOptions(workflowDefActivityUsers, "uuId", "userName")
      : [];

  function handleGoBack() {
    setCurrentStep(1);
    reset({ searchSourceWorkflowForm: formData });
  }

  function onHandleNext() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchSourceWorkflowForm")
    );

    if (
      currentStep === 1 &&
      _.isEmpty(errors) &&
      !_.isEmpty(sanitizedFormData)
    ) {
      if (
        sanitizedFormData["name"] &&
        sanitizedFormData["name"].length > 0 &&
        sanitizedFormData["objectTopicUuIds"] &&
        sanitizedFormData["objectTopicUuIds"].length > 0 &&
        sanitizedFormData["workflowDefinitionUuId"] &&
        sanitizedFormData["workflowDefinitionUuId"].length > 0
      ) {
        const workflowFormData = _.pick(sanitizedFormData, [
          "name",
          "description",
          "objectTopicUuIds",
          "workflowDefinitionUuId",
          "userAssignedUuIds",
        ]);

        workflowFormData["objectTopicUuIds"] = [
          workflowFormData["objectTopicUuIds"],
        ];

        const revisedFormData = {
          typeCode: 2,
          recordIds: [...selectedRecords],
          selectAllFg: selectedRecords.length === 0 ? 1 : 0,
          searchSourceWidgetFilters: searchSourceWidgetTempFilters,
          adjObjectUuId: sanitizedFormData["adjObjectUuId"],
          workflow: workflowFormData,
        };

        setCurrentStep(2);
        setFormData({ ...revisedFormData });
      }
    }
  }

  useEffect(() => {
    if (
      selectedWfDefinitionUuId &&
      selectedWfDefinitionUuId !== undefined &&
      selectedWfDefinitionUuId.length > 0
    ) {
      const selectedWfData = workflowDefsDataMap[selectedWfDefinitionUuId];

      if (
        selectedWfData["adjudicationObjects"] &&
        _.isArray(selectedWfData["adjudicationObjects"]) &&
        selectedWfData["adjudicationObjects"].length > 0
      ) {
        setWorkflowForms(
          NtaiUtils.getSelectOptions(
            selectedWfData["adjudicationObjects"],
            "uuId",
            "name"
          )
        );
      }

      dispatch(getWorkflowDefActivityUsers(selectedWfDefinitionUuId));
    }
  }, [selectedWfDefinitionUuId]);

  useEffect(() => {
    if (
      watchWorkflowDefUuId !== undefined &&
      !_.isEmpty(watchWorkflowDefUuId) &&
      _.has(watchWorkflowDefUuId, "value")
    ) {
      setSelectedWfDefinitionUuId(watchWorkflowDefUuId["value"]);
    }
  }, [watchWorkflowDefUuId]);

  useEffect(() => {
    dispatch(getWorkflowDefs());
    dispatch(
      getObjectTopics({
        domainId: "*",
        objectId: "*",
        params: {
          sourceUuId: sourceId,
        },
      })
    );
    reset({ searchSourceWorkflowForm: null });
  }, []);

  return (
    <>
      {currentStep === 1 && (
        <NtaiDialogFormPanel
          handleSave={onHandleNext}
          handleCancel={handleCancel}
          handleSaveLabel="Next"
          // otherActions={currentStep === 2 && [{ value: "back", label: "Back" }]}
        >
          <React.Fragment>
            <NtaiHelpText header="Records selected: " direction="row">
              {selectedRecords.length === 0 ? "All" : selectedRecords.length}
            </NtaiHelpText>
            <NtaiHelpText direction="row" header="Criteria:">
              My criteria
            </NtaiHelpText>

            <NtaiTextField
              name="searchSourceWorkflowForm.name"
              label="Request Name*"
              rules={{ required: "Name is required" }}
            />

            <NtaiTextField
              name="searchSourceWorkflowForm.description"
              multiline
              minRows={2}
              maxRows={2}
              label="Description"
            />

            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              <NtaiSelectField
                name="searchSourceWorkflowForm.objectTopicUuIds"
                options={objectTopicOptions}
                label="Select Topic*"
                rules={{ required: "Topic is required" }}
              />
              <NtaiSelectField
                name="searchSourceWorkflowForm.workflowDefinitionUuId"
                options={workflowDefOptions}
                label="Select Workflow*"
                rules={{ required: "Workflow is required" }}
              />
              <NtaiSelectField
                name="searchSourceWorkflowForm.adjObjectUuId"
                options={workflowForms}
                label="Select Request Form*"
                rules={{ required: "Request form is required" }}
              />
            </Box>

            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              <NtaiMultiSelectField
                name="searchSourceWorkflowForm.userAssignedUuIds"
                options={
                  workflowDefActivityUserOptions &&
                  _.isArray(workflowDefActivityUserOptions)
                    ? workflowDefActivityUserOptions
                    : []
                }
                label="Assign users*"
                rules={{ required: "User to be assigned is required" }}
              />
            </Box>
          </React.Fragment>
        </NtaiDialogFormPanel>
      )}

      {currentStep === 2 &&
        formData &&
        _.has(formData, "adjObjectUuId") &&
        formData["adjObjectUuId"].length > 0 && (
          <SearchSourceRecordsWorkflowRequestForm
            handleGoBack={handleGoBack}
            handleCancel={handleCancel}
            prevStepData={formData}
          />
        )}
    </>
  );
}
