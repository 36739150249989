import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import {
  getObjects,
  clearStatus as clearObjectsSliceStatus,
} from "app/main/pages/core/cdo/object/store/objectsSlice";
import { getSourceAdjudications } from "../../adjudication/store/sourceAdjudicationsSlice";

const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];

  if (allObjectsDataMap && !_.isEmpty(allObjectsDataMap)) {
    if (_.isArray(selectedObjects) && selectedObjects.length > 0) {
      selectedObjects.forEach((f, i) => {
        result.push({
          uuId: f,
          primary: allObjectsDataMap[f]["adjObjectShortName"],
          secondary: allObjectsDataMap[f]["adjObjectName"],
        });
      });
    }
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (allObjectsDataMap && !_.isEmpty(allObjectsDataMap)) {
    result = _.filter(
      _.orderBy(
        Object.values(allObjectsDataMap),
        ["adjObjectShortName"],
        ["asc"]
      ),
      function (f) {
        if (
          _.filter(selectedObjects, function (g) {
            if (g === f.uuId) return true;
            else return false;
          }).length === 0
        )
          return true;
      }
    );
  }

  return result;
}

export default function SourceMasterTemplateAssessmentForms({ handleSave }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceAdjsDataMap = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.data
  );

  const sourceAdjsSliceStatus = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.data
  );

  const activeId = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.activeId
  );

  const data = dataMap && !_.isEmpty(dataMap) ? dataMap[activeId] : [];
  const sourceAdjObjectUuIds = data ? data["sourceAdjObjectUuIds"] : [];

  function handleAdd(uuId) {
    const sourceAdjObjectUuIdsCopy = _.cloneDeep(sourceAdjObjectUuIds);
    const dataCopy = _.cloneDeep(data);
    if (!sourceAdjObjectUuIdsCopy.includes(uuId))
      sourceAdjObjectUuIdsCopy.push(uuId);
    dataCopy["sourceAdjObjectUuIds"] = sourceAdjObjectUuIdsCopy;
    handleSave(dataCopy);
  }

  function handleRemove(uuId) {
    if (sourceAdjObjectUuIds.includes(uuId)) {
      const sourceAdjObjectUuIdsCopy = _.filter(
        sourceAdjObjectUuIds,
        function (o) {
          return o === uuId ? false : true;
        }
      );
      const dataCopy = _.cloneDeep(data);
      dataCopy["sourceAdjObjectUuIds"] = sourceAdjObjectUuIdsCopy;
      handleSave(dataCopy);
    }
  }

  useEffect(() => {
    if (
      (sourceAdjsSliceStatus &&
        sourceAdjsSliceStatus.result === "success" &&
        sourceAdjsSliceStatus.method === "getSourceAdjudications") ||
      sourceAdjObjectUuIds
    ) {
      const tmpSelectedObjects = transformSelectedObjects(
        sourceAdjObjectUuIds,
        sourceAdjsDataMap
      );
      const tmpAvailableObjects = transformAvailableObjects(
        sourceAdjObjectUuIds,
        sourceAdjsDataMap
      );
      setAvailableObjects(tmpAvailableObjects);
      setSelectedObjects(tmpSelectedObjects);
      dispatch(clearObjectsSliceStatus());
      setLoaded(true);
    }
  }, [sourceAdjsSliceStatus, sourceAdjObjectUuIds]);

  useEffect(() => {
    dispatch(
      getSourceAdjudications({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [sourceId]);

  return (
    <NtaiPanel
      width="60%"
      height="100%"
      header="Source Assessment Forms"
      subheader="Implement role-based filters to display only authorized source views based on each user's role."
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "280px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Assessment Forms Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {loaded &&
              _.isArray(availableObjects) &&
              availableObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ p: 0 }}>
                    {_.orderBy(
                      availableObjects,
                      ["adjObjectShortName"],
                      ["asc"]
                    ).map((fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.adjObjectShortName}
                              </Typography>
                            }
                            secondary={fd["adjObjectName"]}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Scrollbar>
              )}
            {loaded &&
              _.isArray(availableObjects) &&
              availableObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No forms available"
                  subheader="You can configure assessment forms in objects section"
                />
              )}
            {!loaded && <NtaiCircularProgress size={24} />}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {loaded &&
              _.isArray(selectedObjects) &&
              selectedObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ width: "100%", p: 0 }}>
                    {selectedObjects &&
                      _.isArray(selectedObjects) &&
                      _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                        (so, i) => (
                          <React.Fragment key={`selected-objects-${i}`}>
                            <ListItem
                              secondaryAction={
                                <Button
                                  sx={{
                                    display: "flex",
                                    gap: theme.spacing(1),
                                    fontSize: "11px",
                                  }}
                                  onClick={() => handleRemove(so.uuId)}
                                >
                                  <FontAwesomeIcon icon={faArchive} />
                                  REMOVE
                                </Button>
                              }
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{ fontWeight: 700 }}
                                    variant="subtitle1"
                                  >
                                    {so.primary}
                                  </Typography>
                                }
                                secondary={so.secondary}
                              />
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        )
                      )}
                  </List>
                </Scrollbar>
              )}

            {loaded &&
              _.isArray(selectedObjects) &&
              selectedObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No forms assigned"
                  subheader="Add forms from left panel"
                />
              )}
            {!loaded && <NtaiCircularProgress size={24} />}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
