import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUserGroups = createAsyncThunk(
  "userGroups/getUserGroups",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/user-groups`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserGroup = createAsyncThunk(
  "userGroups/getUserGroup",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/user-groups/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserGroup = createAsyncThunk(
  "userGroups/createUserGroup",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(`/user-groups`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserGroup = createAsyncThunk(
  "userGroups/updateUserGroup",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/user-groups/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserGroup = createAsyncThunk(
  "userGroups/deleteUserGroup",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/user-groups/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userGroupsSlice = createSlice({
  name: "userGroups",
  initialState: {
    data: {},
    activeId: null,
    status: {},
    activeDomainId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setActiveDomainId: (state, action) => {
      state.activeDomainId = action.payload;
    },
  },
  extraReducers: {
    [getUserGroups.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getUserGroups" };
    },
    [getUserGroups.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserGroups",
        message: action.payload.message,
      };
    },
    [getUserGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getUserGroup" };
    },
    [getUserGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserGroup",
        message: action.payload.message,
      };
    },
    [createUserGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createUserGroup" };
    },
    [createUserGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserGroup",
        message: action.payload.message,
      };
    },
    [updateUserGroup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateUserGroup" };
    },
    [updateUserGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUserGroup",
        message: action.payload.message,
      };
    },
    [deleteUserGroup.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteUserGroup" };
    },
    [deleteUserGroup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUserGroup",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, setActiveDomainId } =
  userGroupsSlice.actions;

export default userGroupsSlice.reducer;
