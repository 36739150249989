import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Box, Checkbox, useTheme } from "@mui/material";
import NtaiChartEmptyMessage from "@ntai/components/charts/NtaiChartEmptyMessage";
const _ = require("lodash");

// const rows = [
//   { id: 1, col1: "Hello", col2: "World" },
//   { id: 2, col1: "DataGridPro", col2: "is Awesome" },
//   { id: 3, col1: "MUI", col2: "is Amazing" },
// ];

// const columns = [
//   { field: "col1", headerName: "Column 1", width: 150 },
//   { field: "col2", headerName: "Column 2", width: 150 },
// ];

function handleFilter(t1, t2) {
  console.log("T1 and t2: ", t1, t2);
}

export default function NtaiMuiDataGrid({
  rowId,
  rows,
  columns,
  disableSelectionOnClick,
}) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      {rows && _.isArray(rows) && rows.length > 0 && (
        <DataGrid
          sx={{
            borderRadius: "0px",
            border: "0px",
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: theme.colors.secondary.lighter,
              // color: "red"
            },
          }}
          onFilterModelChange={handleFilter}
          components={{
            BaseCheckbox: (props) => <Checkbox size="small" {...props} />,
          }}
          density="compact"
          rows={rows}
          getRowId={(row) => row[rowId]}
          columns={columns}
          disableSelectionOnClick={
            disableSelectionOnClick ? disableSelectionOnClick : true
          }
          disableColumnFilter
          checkboxSelection
        />
      )}
      {!rows && <NtaiChartEmptyMessage />}
    </Box>
  );
}
