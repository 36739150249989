import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, getSearchSource } from "../store/searchSourcesSlice";
import SearchSource from "../SearchSource";
import { Box, Typography } from "@mui/material";
import { SearchContext } from "../../SearchEdit";
const _ = require("lodash");

export default function SearchDashboardViewSourceWrapper({
  index,
  searchId,
  searchSourceId,
  sourceView,
}) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { searchDashboardSourceViewId, setSearchDashboardSourceViewId } =
    React.useContext(SearchContext);

  const searchSourceStatus = useSelector(
    (state) => state.search.searchSourcesSlice.status
  );
  const dataMap = useSelector((state) => state.search.searchSourcesSlice.data);
  const searchSourceData =
    dataMap && !_.isEmpty(dataMap) ? dataMap[searchSourceId] : null;

  useEffect(() => {
    if (
      searchSourceStatus &&
      searchSourceStatus.method === "getSearchSource" &&
      searchSourceStatus.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [searchSourceStatus]);

  useEffect(() => {
    dispatch(
      getSearchSource({
        searchId: searchId,
        searchSourceId: searchSourceId,
      })
    );
  }, [searchSourceId]);

  return (
    <React.Fragment>
      {loaded && (
        <SearchSource
          key={`search-db-source-view-${index}`}
          searchSourceData={searchSourceData}
          searchDashboardSourceViewId={sourceView["uuId"]}
          selected={true}
        />
      )}
    </React.Fragment>
  );
}
