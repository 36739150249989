import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
const _ = require("lodash");

export default function NtaiPanel(props) {
  const {
    padding,
    headerIcon,
    header,
    height,
    headerActions,
    subheader,
    width,
    children,
    handleCancel,
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        padding: padding ? padding : theme.spacing(2),
        width: width ? width : "450px",
        height: height ? height : "auto",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      {(header || subheader) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
            }}
          >
            {props.hasOwnProperty("headerIcon") && headerIcon && (
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                {headerIcon}
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(0.5),
              }}
            >
              {header && (
                <Box
                  sx={{
                    display: "flex",
                    gap: theme.spacing(1),
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4">{header}</Typography>
                </Box>
              )}
              {subheader && (
                <Typography variant="body2" color="textSecondary">
                  {subheader}
                </Typography>
              )}
            </Box>
          </Box>

          {headerActions && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "start",
              }}
            >
              {headerActions}
            </Box>
          )}
        </Box>
      )}
      {children}
      {_.has(props, "handleCancel") && (
        <Box sx={{ display: "flex" }}>
          <Button size="small" onClick={() => props.handleCancel()}>
            CANCEL
          </Button>
        </Box>
      )}
    </Box>
  );
}
