import { Box, Button, Drawer, useTheme } from "@mui/material";
import React, { useState } from "react";
import NtaiDrawer from "./NtaiDrawer";

export default function NtaiTestDrawers() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <Box
        sx={{
          padding: theme.spacing(2),
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flexBasis: "20%",
            position: "relative",
            display: "flex",
            height: "100%",
            alignItems: "start",
          }}
        >
          <Drawer
            // hideBackdrop
            PaperProps={{
              style: {
                position: "absolute",
                top: "123px",
                right: "20%",
              },
            }}
            open={open}
            onClose={handleClose}
            anchor="right"
            BackdropProps={{ invisible: true }}
          >
            <Box sx={{ width: "500px" }}>Hello me</Box>
          </Drawer>
          <Button size="small" onClick={handleClick}>
            Open Drawer
          </Button>
        </Box>
      </Box>
    </>
  );
}
