import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReportFolders = createAsyncThunk(
  "reportFolders/getReportFolders",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/reports/folders");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReportFolder = createAsyncThunk(
  "reportFolders/getReportFolder",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/reports/folders/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createReportFolder = createAsyncThunk(
  "reportFolders/createReportFolder",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/reports/folders", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReportFolder = createAsyncThunk(
  "reportFolders/updateReportFolder",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/reports/folders/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReportFolder = createAsyncThunk(
  "reportFolders/deleteReportFolder",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/reports/folders/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportFoldersSlice = createSlice({
  name: "reportFolders",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReportFolders.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getReportFolders" };
    },
    [getReportFolders.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportFolders",
        message: action.payload.message,
      };
    },
    [getReportFolder.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getReportFolder" };
    },
    [getReportFolder.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportFolder",
        message: action.payload.message,
      };
    },
    [createReportFolder.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createReportFolder" };
    },
    [createReportFolder.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createReportFolder",
        message: action.payload.message,
      };
    },
    [updateReportFolder.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateReportFolder" };
    },
    [updateReportFolder.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateReportFolder",
        message: action.payload.message,
      };
    },
    [deleteReportFolder.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteReportFolder" };
    },
    [deleteReportFolder.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteReportFolder",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = reportFoldersSlice.actions;

export default reportFoldersSlice.reducer;
