import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportDefinitionCategories,
  clearStatus,
} from "../../admin/category/store/reportDefinitionCategoriesSlice";
import { useState } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useEffect } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

export default function ReportSelectionCategories() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionCategoriesSlice.data
  );

  const status = useSelector(
    (state) => state.reports.reportDefinitionCategoriesSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  useEffect(() => {
    if (
      status &&
      status.method === "getReportDefinitionCategories" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getReportDefinitionCategories());
  }, []);

  return (
    <Box
      sx={{
        flexBasis: "25%",
        display: "flex",
        borderRight: theme.general.border1,
        flexDirection: "column",
      }}
    >
      <Box sx={{ pl: "24px", pt: "12px", display: "flex" }}>
        <Typography variant="h6">Category</Typography>
      </Box>

      <Box sx={{ pt: theme.spacing(1), display: "flex", height: "100%" }}>
        {loaded && data.length > 0 && (
          <Scrollbar>
            <MenuList sx={{ width: "100%", p: 0 }}>
              <MenuItem
                sx={{ pl: "24px", py: theme.spacing(0.75) }}
                key={`report-categories-menu-root`}
              >
                <ListItemText>
                  <Typography variant="subtitle1" fontWeight={700}>
                    All categories
                  </Typography>
                </ListItemText>
              </MenuItem>

              {_.sortBy(data, "name").map((category, index) => {
                return (
                  <MenuItem
                    sx={{ pl: "24px", py: theme.spacing(0.75) }}
                    key={`report-categories-menu-${index}`}
                  >
                    <ListItemText>
                      <Typography variant="subtitle1" fontWeight={500}>
                        {category.name}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Scrollbar>
        )}
        {loaded && data.length === 0 && (
          <NtaiEmptyMessage header="No categories available" />
        )}
        {!loaded && <NtaiCircularProgress size={18} />}
      </Box>
    </Box>
  );
}
