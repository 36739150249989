import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceWidgetManager from "./SourceWidgetManager";
import { updateSourceView } from "../store/sourceViewsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export const SourceViewContext = React.createContext();

export default function SourceWidgetManagerWrapper(props) {
  const dispatch = useDispatch();
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const viewId = props.match.params.id;

  const dataMap = useSelector((state) => state.sources.sourceViewsSlice.data);

  const viewData = dataMap[viewId];

  function handleLayoutChange(layout) {
    let tmpViewData = _.cloneDeep(viewData);
    tmpViewData["layout"] = layout;

    dispatch(
      updateSourceView({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: viewId,
        formData: tmpViewData,
      })
    );
  }

  return (
    <SourceViewContext.Provider value={{ handleLayoutChange }}>
      <NtaiPage
        title="Manage Widgets"
        back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/views`}
      >
        <NtaiForm>
          <SourceWidgetManager viewId={viewId} />
        </NtaiForm>
      </NtaiPage>
    </SourceViewContext.Provider>
  );
}
