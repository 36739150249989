import { Box, useTheme } from "@mui/material";
import React from "react";
import AlertList from "./AlertList";
import AlertNavigation from "./navigation/AlertNavigation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlertNavigation } from "./navigation/store/alertNavigationSlice";
import { useState } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import AlertListRbac from "./rbac/AlertListRbac";
const _ = require("lodash");

export const AlertListContainerContext = React.createContext();

export default function AlertContainer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [navLoaded, setNavLoaded] = useState(false);
  const [navNode, setNavNode] = useState({
    masterId: "*",
    topicId: "*",
    objectId: null,
    objectTopicFg: null,
  });

  const navigationData = useSelector(
    (state) => state.monitor.alertNavigationSlice.data
  );

  const status = useSelector(
    (state) => state.monitor.alertNavigationSlice.status
  );

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getAlertNavigation"
    ) {
      setNavLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getAlertNavigation());
  }, []);

  return (
    <AlertListContainerContext.Provider value={{ navNode, setNavNode }}>
      <React.Fragment>
        {navLoaded && !_.isEmpty(navigationData) && (
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                width: "280px",
                height: "100%",
                borderRight: theme.general.border1,
              }}
            >
              <AlertNavigation />
            </Box>
            <Box sx={{ width: "100%", display: "flex" }}>
              <AlertList />
            </Box>
          </Box>
        )}

        {navLoaded && _.isEmpty(navigationData) && (
          <NtaiEmptyMessage
            header="Inbox data not available"
            subheader="Ensure your administrator has assigned you access to business entities and topics for alerts"
            vAlign="center"
          />
        )}
      </React.Fragment>
    </AlertListContainerContext.Provider>
  );
}
