import React, { useEffect } from "react";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { useSelector } from "react-redux";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { Box, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";

const _ = require("lodash");

export default function ThesaurusConceptSynonymForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleCancel } = props;
  const { getValues, reset } = useFormContext();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const typeOptions = _.get(
    codelistData,
    CODELIST_CODES.LIB_THESAURUS_CONCEPT_SYNONYM_TYPE_CODELIST
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("conceptSynonymForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ conceptSynonymForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiFormPanel
      header={
        action === "add"
          ? "Synonyms / Add Synonym"
          : `Synonyms / ${formData["name"]}`
      }
      subheader={
        action === "add"
          ? "Enter term Id, Name, Description and optionally designate synonym as preferred term."
          : formData["description"]
      }
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="conceptSynonymForm.termId"
        label="Term Id*"
        placeholder="Term Id"
        rules={{ required: "Term Id is required" }}
        disabled={action === "edit" ? true : false}
      />

      <NtaiTextField
        name="conceptSynonymForm.name"
        label="Name*"
        placeholder="Name"
        rules={{ required: "Term name is required" }}
      />

      <NtaiTextField
        name="conceptSynonymForm.description"
        label="Description"
        placeholder="Description"
        multiline
        minRows={2}
        maxRows={2}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <NtaiSwitchField
          name="conceptSynonymForm.preferredFg"
          label="Preferred?"
        />

        <NtaiSwitchField name="conceptSynonymForm.activeFg" label="Active?" />
      </Box>

      {/* <NtaiSelectField
        name="conceptSynonymForm.typeCode"
        options={typeOptions}
        label="Type"
      /> */}
    </NtaiFormPanel>
  );
}
