import { Box, Button, useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSourceIn,
  setActiveId as setSourceInStoreActiveId,
  storeSourceInDatasets,
  storeSourceInSingleDataset,
} from "../store/sourceInsSlice";
import { getSourceInStoreDatasets } from "./store/sourceInStoreDatasetsSlice";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faRefresh,
  faSpinner,
} from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "20%",
    fontWeight: 700,
  },

  {
    id: "queryStatusDecode",
    numeric: false,
    disablePadding: false,
    label: "Query Status",
    width: "10%",
  },
  {
    id: "storeStatusDecode",
    numeric: false,
    disablePadding: false,
    label: "Extraction Status",
    width: "15%",
  },
  {
    id: "storeNumOfRecords",
    numeric: false,
    disablePadding: false,
    label: "Rows Extracted",
    width: "10%",
  },

  {
    id: "storeDuration",
    numeric: false,
    disablePadding: false,
    label: "Duration",
    width: "10%",
  },
  {
    id: "storeMessage",
    numeric: false,
    disablePadding: false,
    label: "Message",
    width: "15%",
  },
];

const toolbarActions = [{ value: "delete", label: "Delete" }];

export default function SourceInStoreDatasetList(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const sourceInStoreId = props.match.params.id;
  const sourceInsDataMap = useSelector(
    (state) => state.sources.sourceInsSlice.data
  );
  const sourceInData =
    sourceInsDataMap && !_.isEmpty(sourceInsDataMap)
      ? sourceInsDataMap[sourceInStoreId]
      : null;

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreDatasetsSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const sourceInStoresSliceStatus = useSelector(
    (state) => state.sources.sourceInsSlice.status
  );

  function handleToolbarAction(action, selectedIds) {}

  function onHandleRefresh() {
    dispatch(
      getSourceIn({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: sourceInStoreId,
      })
    );

    dispatch(
      getSourceInStoreDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInStoreId: sourceInStoreId,
      })
    );
  }

  function onHandleStoreDatasets() {
    dispatch(
      storeSourceInDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInStoreId: sourceInStoreId,
      })
    );
  }

  function onHandleStoreDataset(action, id) {
    if (action === "extract") {
      dispatch(
        storeSourceInSingleDataset({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourceInStoreId: sourceInStoreId,
          sourceInStoreDatasetId: id,
        })
      );
    }
  }

  useEffect(() => {
    if (
      sourceInStoresSliceStatus &&
      sourceInStoresSliceStatus.method === "storeSourceInDatasets" &&
      sourceInStoresSliceStatus.result === "error"
    ) {
      enqueueSnackbar(sourceInStoresSliceStatus.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [sourceInStoresSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceInStoreDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInStoreId: sourceInStoreId,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setSourceInStoreActiveId(sourceInStoreId));
  }, [sourceInStoreId]);

  return (
    <NtaiPage
      title={`Source In Stores / ${sourceInData["name"]}`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/`}
      padding={2}
      headerActions={
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <Button
            sx={{ fontSize: "11px" }}
            startIcon={<FontAwesomeIcon icon={faRefresh} />}
            variant="outlined"
            size="small"
            onClick={() => onHandleRefresh()}
          >
            REFRESH
          </Button>

          <Button
            disabled={sourceInData["statusCode"] === 1 ? true : false}
            sx={{ fontSize: "11px" }}
            startIcon={
              <FontAwesomeIcon
                icon={
                  sourceInData["statusCode"] === 1
                    ? faSpinner
                    : faArrowRightFromBracket
                }
              />
            }
            variant="outlined"
            size="small"
            onClick={() => onHandleStoreDatasets()}
          >
            EXTRACT ALL DATASETS
          </Button>
        </Box>
      }
    >
      <NtaiCrudTable
        rows={data}
        headCells={headCells}
        toolbarActions={toolbarActions}
        keyColumn="uuId"
        path={`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInStoreId}/datasets`}
        handleToolbarAction={handleToolbarAction}
        rowActions={[
          {
            value: "extract",
            label: "Extract",
            disabled: sourceInData["statusCode"] === 1 ? true : false,
            startIcon:
              sourceInData["statusCode"] === 1 ? (
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              ) : null,
          },
        ]}
        handleRowAction={onHandleStoreDataset}
        title="Dataset"
      />
    </NtaiPage>
  );
}
