import { Box, useTheme } from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportDefinitionParamWithSourceFieldValues } from "../../../../admin/definition/param/store/reportDefinitionParamsSlice";
import { useEffect } from "react";
import {
  clearStatus,
  getReportSourceFieldFilterValues,
} from "./store/reportSourceFilterFieldsSlice";
import { ReportContext } from "../../ReportMain";
import NtaiFilterMultiSelectList from "@ntai/components/inputs/filters/NtaiFilterMultiSelectList";
import NtaiFilterCheckboxGroup from "@ntai/components/inputs/filters/NtaiFilterCheckboxGroup";
import NtaiFilterRadioGroup from "@ntai/components/inputs/filters/NtaiFilterRadioGroup";
import NtaiFilterSlider from "@ntai/components/inputs/filters/NtaiFilterSlider";
import NtaiFilterRangeSlider from "@ntai/components/inputs/filters/NtaiFilterRangeSlider";
import NtaiFilterDateRangeSlider from "@ntai/components/inputs/filters/NtaiFilterDateRangeSlider";
import NtaiFilterServerSelect from "@ntai/components/inputs/filters/NtaiFilterServerSelect";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export default function ReportSourceFilterField({ field }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [defaultOptionValuesLoaded, setDefaultOptionValuesLoaded] =
    useState(false);
  const [defaultOptionValues, setDefaultOptionValues] = useState([]);
  const { reportDefinition } = React.useContext(ReportContext);

  const sourceDefId = _.get(reportDefinition, "sourceDefUuId");
  const sourceId = _.get(reportDefinition, "sourceUuId");

  const dataMap = useSelector(
    (state) => state.reports.reportSourceFilterFieldsSlice.data
  );

  const fieldData =
    dataMap && !_.isEmpty(dataMap) ? _.get(dataMap, field.uuId) : null;

  const status = useSelector(
    (state) => state.reports.reportSourceFilterFieldsSlice.status
  );

  function generateOptionValues() {
    const result = [];

    if (fieldData && Array.isArray(fieldData.values)) {
      fieldData.values.forEach((fv, i) => {
        result.push({ value: fv, label: fv });
      });
    }

    return result;
  }

  function getFieldName(field) {
    return "reportParamForm." + field.uuId;
  }

  const loadOptions = (inputValue, callback) => {
    const toSearch = _.isEmpty(inputValue) ? "null" : inputValue;
    server
      .get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields/${_.get(
          field,
          "sourceFieldUuId"
        )}/suggest`,
        {
          params: {
            text: toSearch,
            size: 10,
            prefixFg: 1,
            derivedFieldFg:
              field["derivedFieldFg"] && field["derivedFieldFg"] === 1 ? 1 : 0,
          },
        }
      )
      .then((response) => {
        const options = [];
        response.data.forEach((text) => {
          options.push({
            label: text,
            value: text,
          });
        });
        callback(options);
      });
  };

  const loadDefaultOptions = () => {
    server
      .get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields/${_.get(
          field,
          "sourceFieldUuId"
        )}/suggest`,
        {
          params: {
            text: "",
            size: 10,
            prefixFg: 1,
            derivedFieldFg:
              field["derivedFieldFg"] && field["derivedFieldFg"] === 1 ? 1 : 0,
          },
        }
      )
      .then((response) => {
        const options = [];
        response.data.forEach((text) => {
          options.push({
            label: text,
            value: text,
          });
        });
        setDefaultOptionValues([..._.orderBy(options, ["label"], ["asc"])]);
      });
  };

  useEffect(() => {
    if (defaultOptionValues.length > 0) {
      setDefaultOptionValuesLoaded(true);
    }
  }, [defaultOptionValues]);

  useEffect(() => {
    if (field.typeCode === 1) {
      loadDefaultOptions();
    }
  }, []);

  useEffect(() => {
    if (
      _.has(status, field.uuId) &&
      _.get(status[field.uuId], "method") ===
        "getReportSourceFieldFilterValues" &&
      _.get(status[field.uuId], "result") === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus(field.uuId));
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getReportSourceFieldFilterValues({
        reportDefinitionId: _.get(reportDefinition, "uuId"),
        uuId: _.get(field, "uuId"),
      })
    );
  }, [field]);

  return (
    <>
      {loaded && fieldData && !_.isEmpty(fieldData) && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          {/* {field.typeCode === 1 && (
            <NtaiFilterMultiSelectList
              name={getFieldName(field)}
              label={_.get(field, "name")}
              options={generateOptionValues()}
            />
          )} */}

          {field.typeCode === 1 && defaultOptionValuesLoaded && (
            <NtaiFilterServerSelect
              label={_.get(field, "name")}
              name={getFieldName(field)}
              handleCallback={loadOptions}
              defaultOptions={[...defaultOptionValues]}
              placeholder="Search..."
            />
          )}

          {field.typeCode === 2 && (
            <NtaiFilterCheckboxGroup
              name={getFieldName(field)}
              label={_.get(field, "name")}
              options={generateOptionValues()}
            />
          )}

          {field.typeCode === 3 && (
            <NtaiFilterRadioGroup
              label={_.get(field, "name")}
              name={getFieldName(field)}
              row
              options={generateOptionValues()}
            />
          )}

          {field.typeCode === 4 && (
            <NtaiFilterSlider
              name={getFieldName(field)}
              label={_.get(field, "name")}
              min={fieldData.values[0]}
              max={fieldData.values[1]}
              size="small"
            />
          )}

          {field.typeCode === 5 && (
            <NtaiFilterRangeSlider
              name={getFieldName(field)}
              label={_.get(field, "name")}
              min={fieldData.values[0]}
              max={fieldData.values[1]}
              size="small"
            />
          )}

          {field.typeCode === 6 && (
            <NtaiFilterDateRangeSlider
              label={_.get(field, "name")}
              name={getFieldName(field)}
              min={fieldData.values[0]}
              max={fieldData.values[1]}
            />
          )}
        </Box>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </>
  );
}
