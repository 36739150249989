import AlertDefinitionCreate from "./AlertDefinitionCreate";
import AlertDefinitionEdit from "./AlertDefinitionEdit";
import AlertDefinitionList from "./AlertDefinitionList";
import AlertTracker from "./tracker/AlertTracker";

const AlertDefinitionConfig = {
  routes: [
    {
      path: "/monitor/definitions",
      exact: true,
      component: AlertDefinitionList,
    },
    {
      path: "/monitor/definitions/create",
      exact: true,
      component: AlertDefinitionCreate,
    },
    {
      path: "/monitor/definitions/:id/edit",
      exact: true,
      component: AlertDefinitionEdit,
    },
    {
      path: "/monitor/definitions/tracker",
      exact: true,
      component: AlertTracker,
    },
  ],
};

export default AlertDefinitionConfig;
