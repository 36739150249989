import {
  Avatar,
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useEffect, useState } from "react";
import { SearchBasicInitContext } from "../SearchBasicInit";
import {
  clearStatus,
  getSearchThesaurusLookup,
} from "./store/searchThesaurusLookupSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

const DEFAULT_DATA = [
  {
    conceptId: "123",
    conceptName: "Fever",
    thesaurusUuId: "23",
    thesaurusName: "META",
    level: "B", //  Broad, Rel, Narrow (3, 2, 1)
    selected: 0,
    conceptTerms: [
      {
        name: "FY Adalimumab",
        selected: 1,
      },
      {
        name: "FY Adalimumab 20 mg",
        selected: 0,
      },
    ],
  },
];

export default function SearchThesaurusLookupForm({ termIndex, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [lookupTerm, setLookupTerm] = useState();
  const [data, setData] = useState();
  const [synonyms, setSynonyms] = useState([]);

  const { searchQueryTerms, setSearchQueryTerms } = React.useContext(
    SearchBasicInitContext
  );

  const dataMap = useSelector(
    (state) => state.search.searchThesaurusLookupSlice.data
  );

  const lookupData =
    dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const status = useSelector(
    (state) => state.search.searchThesaurusLookupSlice.status
  );

  function handleSave() {
    const lookupTermCopy = _.cloneDeep(lookupTerm);
    lookupTermCopy["synonyms"] = [...synonyms];
    const searchQueryTermsCopy = _.cloneDeep(searchQueryTerms);
    searchQueryTermsCopy[termIndex] = lookupTermCopy;
    setSearchQueryTerms([...searchQueryTermsCopy]);
    handleCancel();
  }

  function handleClick(conceptTerm, conceptId, thesaurusUuId) {
    let dataCopy = _.cloneDeep(data);

    const conceptIndex = dataCopy.findIndex(
      (o) =>
        o["thesaurusUuId"] === thesaurusUuId && o["conceptId"] === conceptId
    );

    if (conceptIndex > -1) {
      const pConcept = dataCopy[conceptIndex];
      const conceptTermIndex = pConcept["conceptTerms"].findIndex(
        (o) => o["term"] === conceptTerm
      );

      if (conceptTermIndex > -1) {
        const pConceptTerm = pConcept["conceptTerms"][conceptTermIndex];
        pConceptTerm["selected"] = pConceptTerm["selected"] === 1 ? 0 : 1;
        if (pConceptTerm["selected"] === 1) {
          // add to synonyms
          setSynonyms([
            ...synonyms,
            {
              term: conceptTerm,
              conceptId: conceptId,
              thesaurusUuId: thesaurusUuId,
            },
          ]);
        } else {
          // was previously selected. now remove from synonyms
          const synonymsFiltered = _.filter(synonyms, function (s) {
            if (
              s["term"] === conceptTerm &&
              s["conceptId"] === conceptId &&
              s["thesaurusUuId"] === thesaurusUuId
            ) {
              return false;
            } else return true;
          });
          setSynonyms([...synonymsFiltered]);
        }
      }
    }

    // dataCopy.forEach((concept, i) => {
    //   if (
    //     concept["thesaurusUuId"] === thesaurusUuId &&
    //     concept["conceptId"] === conceptId
    //   ) {
    //     concept["conceptTerms"].forEach((ct, j) => {
    //       if (ct["term"] === conceptTerm) {
    //         ct["selected"] = ct["selected"] === 1 ? 0 : 1;

    //         if (ct["selected"] === 1) {
    //           // add to synonyms
    //           setSynonyms([
    //             ...synonyms,
    //             {
    //               term: conceptTerm,
    //               conceptId: conceptId,
    //               thesaurusUuId: thesaurusUuId,
    //             },
    //           ]);
    //         } else {
    //           // was previously selected. now remove from synonyms
    //           const synonymsFiltered = _.filter(synonyms, function (s) {
    //             console.log("S = ", s);
    //             if (
    //               s["term"] === conceptTerm &&
    //               s["conceptId"] === conceptId &&
    //               s["thesaurusUuId"] === thesaurusUuId
    //             ) {
    //               return false;
    //             } else return true;
    //           });
    //           setSynonyms([...synonymsFiltered]);
    //         }
    //       }
    //     });
    //   }
    // });

    // setData([...dataCopy]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchThesaurusLookup" &&
      status.result === "success"
    ) {
      const lookupDataCopy = lookupData.map((ld, i) => {
        return {
          ...ld,
          conceptTerms: ld.conceptTerms.map((ct, i) => {
            return {
              term: ct,
              selected: 0,
            };
          }),
        };
      });
      setLoaded(true);
      setData(lookupDataCopy);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (_.isNumber(termIndex) && termIndex >= 0) {
      const lookupTermCopy = searchQueryTerms[termIndex];
      dispatch(
        getSearchThesaurusLookup({
          qterm: lookupTermCopy["term"],
          thesaurusUuIds: [lookupTermCopy["thesaurusUuId"]],
        })
      );
      setLookupTerm(lookupTermCopy);
      setSynonyms(
        _.has(lookupTermCopy, "synonyms") ? lookupTermCopy["synonyms"] : []
      );
    }
  }, [termIndex]);

  return (
    <NtaiDialogFormPanel
      title={lookupTerm ? lookupTerm["term"] : null}
      subheader={`Selected (${synonyms.length})`}
      handleSave={loaded && data.length > 0 ? handleSave : null}
      handleCancel={handleCancel}
      handleSaveLabel="Apply"
    >
      <Box
        sx={{
          height: "280px",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        {loaded && data.length > 0 && (
          <Scrollbar>
            <List sx={{ p: 0 }}>
              {data &&
                _.isArray(data) &&
                data.length > 0 &&
                data.map((concept, i) => (
                  <React.Fragment key={`list-item-${i}`}>
                    <ListItem
                      sx={{
                        p: theme.spacing(2),
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        gap: theme.spacing(2),
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: theme.spacing(1),
                        }}
                      >
                        <Chip
                          sx={{ fontSize: "12px", fontWeight: "700" }}
                          label={concept["thesaurusName"]}
                          size="small"
                        />

                        <Avatar sx={{ width: 24, height: 24 }}>
                          {concept["level"]}
                        </Avatar>

                        <Typography
                          variant="h5"
                          fontWeight="500"
                          color="inherit"
                        >
                          {concept["conceptName"]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: theme.spacing(1),
                        }}
                      >
                        <Box
                          sx={{
                            width: "10%",
                            display: "flex",
                            alignItems: "start",
                          }}
                        >
                          <Switch />
                        </Box>

                        <Box
                          sx={{
                            width: "90%",
                            display: "flex",
                            alignItems: "start",
                            flexWrap: "wrap",
                            gap: theme.spacing(1),
                          }}
                        >
                          {_.isArray(concept["conceptTerms"]) &&
                            concept["conceptTerms"].map((conceptTerm, i) => {
                              const selected =
                                synonyms.findIndex(
                                  (o) =>
                                    o["term"] === conceptTerm["term"] &&
                                    o["conceptId"] === concept["conceptId"] &&
                                    o["thesaurusUuId"] ===
                                      concept["thesaurusUuId"]
                                ) > -1
                                  ? true
                                  : false;
                              return (
                                <Chip
                                  key={`concept-term-${i}`}
                                  size="small"
                                  clickable={true}
                                  variant={selected ? "contained" : "outlined"}
                                  label={conceptTerm["term"]}
                                  color={selected ? "success" : "secondary"}
                                  onClick={() =>
                                    handleClick(
                                      conceptTerm["term"],
                                      concept["conceptId"],
                                      concept["thesaurusUuId"]
                                    )
                                  }
                                />
                              );
                            })}
                        </Box>
                      </Box>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
            </List>
          </Scrollbar>
        )}
        {!loaded && <NtaiCircularProgress size={24} />}
        {loaded && (!data || data.length === 0) && (
          <NtaiEmptyMessage
            vAlign="center"
            header="No synonyms found"
            subheader="Check term spelling and try again"
          />
        )}
      </Box>
    </NtaiDialogFormPanel>
  );
}
