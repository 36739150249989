import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceOutDatasetScdPolicies = createAsyncThunk(
  "sourceOutDatasetScdPolicies/getSourceOutDatasetScdPolicies",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutStoreDatasetId,
      } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutStoreDatasetId}/scd-policies`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceOutDatasetScdPolicy = createAsyncThunk(
  "sourceOutDatasetScdPolicies/getSourceOutDatasetScdPolicy",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutStoreDatasetId,
        uuId,
      } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutStoreDatasetId}/scd-policies/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceOutDatasetScdPolicy = createAsyncThunk(
  "sourceOutDatasetScdPolicies/createSourceOutDatasetScdPolicy",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutStoreDatasetId,
        formData,
      } = values;

      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutStoreDatasetId}/scd-policies`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceOutDatasetScdPolicy = createAsyncThunk(
  "sourceOutDatasetScdPolicies/updateSourceOutDatasetScdPolicy",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutStoreDatasetId,
        uuId,
        formData,
      } = values;

      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutStoreDatasetId}/scd-policies/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceOutDatasetScdPolicy = createAsyncThunk(
  "sourceOutDatasetScdPolicies/deleteSourceOutDatasetScdPolicy",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceOutStoreId,
        sourceOutStoreDatasetId,
        uuId,
      } = values;

      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${sourceOutStoreId}/datasets/${sourceOutStoreDatasetId}/scd-policies/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceOutDatasetScdPoliciesSlice = createSlice({
  name: "sourceOutDatasetScdPolicies",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceOutDatasetScdPolicies.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceOutDatasetScdPolicies",
      };
    },
    [getSourceOutDatasetScdPolicies.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutDatasetScdPolicies",
        message: action.payload.message,
      };
    },

    [getSourceOutDatasetScdPolicy.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceOutDatasetScdPolicy",
      };
    },
    [getSourceOutDatasetScdPolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutDatasetScdPolicy",
        message: action.payload.message,
      };
    },
    [createSourceOutDatasetScdPolicy.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceOutDatasetScdPolicy",
      };
    },
    [createSourceOutDatasetScdPolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceOutDatasetScdPolicy",
        message: action.payload.message,
      };
    },
    [updateSourceOutDatasetScdPolicy.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceOutDatasetScdPolicy",
      };
    },
    [updateSourceOutDatasetScdPolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceOutDatasetScdPolicy",
        message: action.payload.message,
      };
    },
    [deleteSourceOutDatasetScdPolicy.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceOutDatasetScdPolicy",
      };
    },
    [deleteSourceOutDatasetScdPolicy.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceOutDatasetScdPolicy",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceOutDatasetScdPoliciesSlice.actions;

export default sourceOutDatasetScdPoliciesSlice.reducer;
