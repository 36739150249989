import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDistributionGroupObjects = createAsyncThunk(
  "distributionGroupObject/getDistributionGroupObjects",
  async (distributionGroupId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/library/distribution-groups/${distributionGroupId}/objects`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDistributionGroupObject = createAsyncThunk(
  "distributionGroupObject/getDistributionGroupObject",
  async (values, { rejectWithValue }) => {
    try {
      const { distributionGroupId, uuId } = values;
      const response = await server.get(
        `/library/distribution-groups/${distributionGroupId}/objects/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDistributionGroupObjects = createAsyncThunk(
  "distributionGroupObject/createDistributionGroupObjects",
  async (values, { rejectWithValue }) => {
    try {
      const { distributionGroupId, formData } = values;
      const response = await server.post(
        `/library/distribution-groups/${distributionGroupId}/objects`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDistributionGroupObject = createAsyncThunk(
  "distributionGroupObject/deleteDistributionGroupObject",
  async (values, { rejectWithValue }) => {
    try {
      const { distributionGroupId, uuId } = values;
      const response = await server.delete(
        `/library/distribution-groups/${distributionGroupId}/objects/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const distributionGroupObjectsSlice = createSlice({
  name: "distributionGroupObjects",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDistributionGroupObjects.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getDistributionGroupObjects",
      };
    },
    [getDistributionGroupObjects.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDistributionGroupObjects",
        message: action.payload.message,
      };
    },
    [getDistributionGroupObject.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getDistributionGroupObject",
      };
    },
    [getDistributionGroupObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDistributionGroupObject",
        message: action.payload.message,
      };
    },
    [createDistributionGroupObjects.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };

      state.status = {
        result: "success",
        method: "createDistributionGroupObjects",
      };
    },
    [createDistributionGroupObjects.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createDistributionGroupObjects",
        message: action.payload.message,
      };
    },

    [deleteDistributionGroupObject.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "deleteDistributionGroupObject",
      };
    },
    [deleteDistributionGroupObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteDistributionGroupObject",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  distributionGroupObjectsSlice.actions;

export default distributionGroupObjectsSlice.reducer;
