import React from "react";
import { SourceOutJobMigrationContext } from "../SourceOutJobMigration";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiReadOnlyTable from "@ntai/components/tables/read-only/NtaiReadOnlyTable";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    label: "File",
    fontWeight: 700,
  },

  {
    id: "fileSize",
    numeric: false,
    disablePadding: false,
    label: "Size",
    transformFunc: NtaiUtils.bytesToSize,
  },

  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

export default function SourceMigrationOutJobReadFilesStep() {
  const theme = useTheme();
  const { displayStore, displayStep, migrationData } = React.useContext(
    SourceOutJobMigrationContext
  );

  const migrationOutStores = _.filter(migrationData["migrationOutStores"], {
    uuId: displayStore,
  });

  function handleSelect(rowId) {
    console.log("Fetch this: ", rowId);
  }

  return (
    <React.Fragment>
      {migrationOutStores &&
        _.isArray(migrationOutStores) &&
        migrationOutStores[0]["migrationInStores"].map((o, i) => (
          <React.Fragment key={`ntai-migration-read-files-batch-${i}`}>
            <Box
              sx={{
                p: theme.spacing(2),
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(1),
              }}
            >
              <Typography variant="h3">{o["sourceInStoreName"]}</Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: theme.spacing(1),
                }}
              >
                <NtaiLabelValue1 label="Status" value={o["statusDecode"]} />
                <NtaiLabelValue1 label="Duration" value={o["duration"]} />
                <NtaiLabelValue1
                  label="Started"
                  value={NtaiUtils.formatDateTimeCol(o["dateStarted"])}
                />
                <NtaiLabelValue1
                  label="Completed"
                  value={NtaiUtils.formatDateTimeCol(o["dateCompleted"])}
                />
              </Box>
              {_.has(o, "migrationInStoreFiles") &&
                _.isArray(o["migrationInStoreFiles"]) && (
                  <NtaiReadOnlyTable
                    rows={o["migrationInStoreFiles"]}
                    headCells={headCells}
                    keyColumn="uuId"
                    isDensed={true}
                    handleRowClick={handleSelect}
                    title={`Files (${o["migrationInStoreFiles"].length})`}
                  />
                )}
            </Box>
            <Divider />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
}
