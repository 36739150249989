import NtaiUtils from "@ntai/utils/NtaiUtils";

export const tooltipConfig = {
  // backgroundColor: "rgba(34, 34, 34, 0.8)",
  trigger: "item",
  borderWidth: 1,
  borderColor: "#ccc",
  padding: 8,
  textStyle: {
    // color: "#fff",
    fontSize: 12,
  },
  // axisPointer: { type: "cross", crossStyle: { type: "solid" } },
};

export const gridConfig = {
  containLabel: true,
  left: 20,
  top: 20,
  right: 20,
  bottom: 20,
};

export const gridConfigPadding10 = {
  containLabel: true,
  left: 10,
  top: 10,
  right: 10,
  bottom: 10,
};

export const gridConfigPadding0 = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};

export const yAxisLabelConfig = {
  overflow: "truncate",
  width: 100,
  ellipsis: "...",
  show: true,
  interval: 0,
  fontSize: 12,
  formatter: (val) => `${NtaiUtils.abbrevNumber(val)}`,
};

export const xAxisLabelConfig = {
  overflow: "truncate",
  width: 100,
  ellipsis: "...",
  show: true,
  interval: 0,
  rotate: 45,
  fontSize: 12,
};

export const xAxisLabelNoRotationConfig = {
  overflow: "truncate",
  width: 100,
  ellipsis: "...",
  show: true,
  interval: 0,
  fontSize: 12,
};

export const legendConfig = {
  type: "scroll",
  icon: "circle",
  orient: "horizontal",
  align: "auto",
  top: "top",
  left: "center",
  textStyle: {
    fontSize: 12,
  },
};
