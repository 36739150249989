import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getEntityObject = createAsyncThunk(
  "entities/getEntityObject",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEntities = createAsyncThunk(
  "entities/getEntities",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data`
      );
      return { objectUuId: objectId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  "entities/getEntity",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  "entities/createEntity",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/data`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  "entities/updateEntity",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId, formData } = values;
      const response = await server.put(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEntityTopics = createAsyncThunk(
  "entities/updateEntityTopics",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, entityId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${entityId}/topics/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEntity = createAsyncThunk(
  "entities/deleteEntity",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/data/${uuId}`
      );
      return { objectId: objectId, uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const entitiesSlice = createSlice({
  name: "entities",
  initialState: {
    domains: {},
    data: {},
    activeId: null,
    objectUuId: null,
    domainUuId: null,
    entityObjects: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setObjectUuId: (state, action) => {
      state.objectUuId = action.payload;
    },
    setDomainUuId: (state, action) => {
      state.domainUuId = action.payload;
    },
  },
  extraReducers: {
    [getEntityObject.fulfilled]: (state, action) => {
      state.entityObjects = {
        ...state.entityObjects,
        ..._.mapKeys([action.payload], "uuId"),
      };
      state.status = { result: "success", method: "getEntityObject" };
    },
    [getEntityObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getEntityObject",
        message: action.payload.message,
      };
    },

    [getEntities.fulfilled]: (state, action) => {
      let objectUuId = action.payload.objectUuId;

      if (
        objectUuId === "undefined" &&
        Array.isArray(action.payload.data) &&
        action.payload.data.length > 0
      ) {
        objectUuId = _.get(action.payload.data[0], "objectUuId");
      }

      if (
        Array.isArray(action.payload.data) &&
        action.payload.data.length > 0
      ) {
        state.data[objectUuId] = { ..._.mapKeys(action.payload.data, "uuId") };
      }

      state.status = { result: "success", method: "getEntities" };
    },
    [getEntities.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getEntities",
        message: action.payload.message,
      };
    },

    [createEntity.fulfilled]: (state, action) => {
      const objectUuId = action.payload.objectUuId;
      const objectEntityData = { ...state.data[objectUuId] };
      objectEntityData[action.payload.uuId] = action.payload;
      state.data[objectUuId] = { ...objectEntityData };
      state.status = { result: "success", method: "createEntity" };
      state.activeId = action.payload.uuId;
    },
    [createEntity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createEntity",
        message: action.payload.message,
      };
    },
    [updateEntity.fulfilled]: (state, action) => {
      const objectUuId = action.payload.objectUuId;
      const objectEntityData = { ...state.data[objectUuId] };
      objectEntityData[action.payload.uuId] = action.payload;
      state.data[objectUuId] = { ...objectEntityData };
      state.status = { result: "success", method: "updateEntity" };
    },
    [updateEntity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateEntity",
        message: action.payload.message,
      };
    },
    [updateEntityTopics.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateEntityTopics" };
    },
    [updateEntityTopics.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateEntityTopics",
        message: action.payload.message,
      };
    },

    [deleteEntity.fulfilled]: (state, action) => {
      const objectUuId = action.payload.objectId;
      const objectEntityData = { ...state.data[objectUuId] };
      const revisedEntityData = _.omit(objectEntityData, action.payload.uuId);
      state.data[objectUuId] = { ...revisedEntityData };
      state.status = { result: "success", method: "deleteEntity" };
    },
    [deleteEntity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteEntity",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, setObjectUuId, setDomainUuId } =
  entitiesSlice.actions;

export default entitiesSlice.reducer;
