import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSourceCatalogAsset } from "../store/sourceCatalogAssetsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import Scrollbar from "@ntai/components/Scrollbar";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "columnName",
    disablePadding: true,
    label: "Field",
    width: "25%",
  },
  {
    id: "statisticType",
    disablePadding: true,
    label: "Statistic",
    width: "25%",
  },
  {
    id: "statisticValue",
    disablePadding: true,
    label: "Value",
    width: "50%",
  },
];

export default function SourceCatalogAssetStatistics({ asset }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const dataMap = useSelector(
    (state) => state.sources.sourceCatalogAssetsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceCatalogAssetsSlice.status
  );

  const data =
    dataMap && _.has(dataMap, asset["uuId"])
      ? dataMap[asset["uuId"]]["sourceCatalogAssetStatistics"]
      : [];

  function handleView(uuId) {}

  useEffect(() => {
    dispatch(
      getSourceCatalogAsset({
        assetUuId: asset["uuId"],
        typeCode: asset["typeCode"],
        requestTypeCode: 3,
      })
    );
  }, [asset]);

  return (
    <Scrollbar>
      <Box sx={{ p: theme.spacing(2) }}>
        <NtaiSimpleTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleView}
          title={`Fields (${data.length})`}
          pageSize={100}
        />
      </Box>
    </Scrollbar>
  );
}
