import { ArrowCircleDown } from "@mui/icons-material";
import React from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";

// const CheckBoxIcon = ({ variant, ...rest }) => {
//   switch (variant) {
//     case "all":
//       return <FaCheckSquare {...rest} />;
//     case "none":
//       return <FaSquare {...rest} />;
//     case "some":
//       return <FaMinusSquare {...rest} />;
//     default:
//       return null;
//   }
// };

export default function NtaiTreeView1({ data }) {
  const revisedData = flattenTree(data);

  return (
    <TreeView
      data={revisedData}
      aria-label="directory tree"
      nodeRenderer={({
        element,
        isBranch,
        isExpanded,
        getNodeProps,
        level,
      }) => (
        <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
          {isBranch ? (
            <ArrowCircleDown isOpen={isExpanded} />
          ) : (
            <ArrowCircleDown filename={element.name} />
          )}

          {element.name}
        </div>
      )}
    />
  );
}
