import NtaiMultiSelectField from "@ntai/components/inputs/NtaiMultiSelectField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

export default function NtaiObjectFormMultiSelectField(props) {
  const dispatch = useDispatch();
  const { formName, fieldObj } = props;

  const codelistCode = fieldObj["fieldCodelistCode"];

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const labelMandatoryAsterisk =
    _.get(fieldObj, "fieldMandatoryFg") === 1 ? "*" : "";

  const options =
    fieldObj["codelistCodes"] &&
    _.isArray(fieldObj["codelistCodes"]) &&
    fieldObj["codelistCodes"].length > 0
      ? NtaiUtils.getSelectOptions(fieldObj["codelistCodes"], "code", "name")
      : codelistData && _.has(codelistData, codelistCode)
      ? _.get(codelistData, codelistCode)
      : [];

  useEffect(() => {
    if (
      fieldObj["codelistCodes"] &&
      _.isArray(fieldObj["codelistCodes"]) &&
      fieldObj["codelistCodes"].length === 0
    )
      dispatch(getCodes(codelistCode));
  }, [fieldObj]);

  return (
    <NtaiMultiSelectField
      width={
        fieldObj["fieldUiWidth"] && fieldObj["fieldUiWidth"] > 0
          ? "" + fieldObj["fieldUiWidth"].toString() + "%"
          : "100%"
      }
      name={`${formName}.${_.get(fieldObj, "fieldName")}`}
      label={_.get(fieldObj, "fieldLabel") + labelMandatoryAsterisk}
      options={options}
      rules={
        _.get(fieldObj, "fieldMandatoryFg") === 1
          ? { required: `${fieldObj["fieldLabel"]} is required` }
          : {}
      }
    />
  );
}
