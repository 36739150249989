import { Box, useTheme } from "@mui/material";
import NtaiResizableGridRecord from "@ntai/components/resizable/NtaiResizableGridRecord";
import useDimensions from "react-cool-dimensions";
import React from "react";
import Scrollbar from "@ntai/components/Scrollbar";
import { useEffect } from "react";
import { SearchContext } from "../../../../../SearchEdit";
import { SearchSourceContext } from "../../../../SearchSource";
import { getSearchSourceRecordView } from "../store/searchSourceRecordViewsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import SearchSourceRecordSourceViewWidget from "./SearchSourceRecordSourceViewWidget";
const _ = require("lodash");

export default function SearchSourceRecordCustomView({ sourceViewUuId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "uuId");

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordViewsSlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordViewsSlice.status
  );

  const viewData =
    dataMap && !_.isEmpty(dataMap) ? dataMap[sourceViewUuId] : [];

  function generateWidgets() {
    let result = [];

    const sourceViewWidgets = _.get(viewData, "sourceViewWidgets");

    if (_.isArray(sourceViewWidgets) && sourceViewWidgets.length > 0) {
      sourceViewWidgets.forEach((svw, i) => {
        result.push(
          <Box sx={{ display: "flex", width: "100%", height: "100%" }} key={i}>
            <SearchSourceRecordSourceViewWidget
              widgetInitData={svw}
              sourceViewUuId={sourceViewUuId}
              sourceViewWidgetUuId={svw.sourceViewWidgetUuId}
            />
          </Box>
        );
      });
    }

    return result;
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordView"
    ) {
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSourceRecordView({
        searchId: searchId,
        searchSourceId: searchSourceId,
        sourceViewUuId: sourceViewUuId,
      })
    );
  }, [sourceViewUuId]);

  return (
    <Scrollbar autoHide={false}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          p: theme.spacing(1),
        }}
      >
        {loaded && <NtaiResizableGridRecord items={generateWidgets()} />}
        {!loaded && <NtaiCircularProgress size={24} />}
      </Box>
    </Scrollbar>
  );
}
