import { useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function FunctionForm(props) {
  const theme = useTheme();
  const { action, formData, handleSave, handleCancel } = props;
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);
  const activeId = useSelector((state) => state.models.functionsSlice.activeId);

  const typeOptions = _.has(codelistData, CODELIST_CODES.MOD_FUNCTION_TYPE_CODE)
    ? _.get(codelistData, CODELIST_CODES.MOD_FUNCTION_TYPE_CODE)
    : [];

  const categoryOptions = _.has(
    codelistData,
    CODELIST_CODES.MOD_FUNCTION_CATEGORY_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.MOD_FUNCTION_CATEGORY_CODE)
    : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("functionForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.MOD_FUNCTION_CATEGORY_CODE));
    dispatch(getCodes(CODELIST_CODES.MOD_FUNCTION_TYPE_CODE));
  }, []);

  useEffect(() => {
    if (action === "add") reset({ functionForm: {} });
    else if (action === "edit") reset({ functionForm: formData });
  }, []);

  return (
    <NtaiFormPanel
      width="50%"
      header="General Information"
      subheader={
        "Enter general information about user defined function including name, description and type"
      }
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="functionForm.name"
        placeholder="Name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="functionForm.description"
        multiline
        minRows={3}
        maxRows={3}
        placeholder="Description"
        label="Description"
      />
      <NtaiSelectField
        name="functionForm.typeCode"
        options={typeOptions}
        label="Function type*"
        rules={{ required: "Function type is required" }}
      />
      {/* <NtaiSelectField
        name="functionForm.categoryCode"
        options={categoryOptions}
        label="Select category"
      /> */}
    </NtaiFormPanel>
  );
}
