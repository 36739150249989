import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceCatalogAsset = createAsyncThunk(
  "sourceCatalogAssets/getSourceCatalogAsset",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.put("/source-catalog/assets", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSourceCatalogAsset = createAsyncThunk(
  "sourceCatalogAssets/createOrUpdateSourceCatalogAsset",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post("/source-catalog/assets", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceCatalogAssetsSlice = createSlice({
  name: "sourceCatalogAssets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceCatalogAsset.fulfilled]: (state, action) => {
      if (action.payload)
        state.data = {
          ...state.data,
          [action.payload.assetUuId]: action.payload,
        };
      state.status = { result: "success", method: "getSourceCatalogAsset" };
    },
    [getSourceCatalogAsset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceCatalogAsset",
        message: action.payload.message,
      };
    },
    [createOrUpdateSourceCatalogAsset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.assetUuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createOrUpdateSourceCatalogAsset",
      };
    },
    [createOrUpdateSourceCatalogAsset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSourceCatalogAsset",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceCatalogAssetsSlice.actions;

export default sourceCatalogAssetsSlice.reducer;
