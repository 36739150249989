import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import SourceOutDatasetNkFields from "./nk/SourceOutDatasetNkFields";
import SourceOutDatasetScdFields from "./scd-fields/SourceOutDatasetScdFields";
import SourceOutDatasetPkFields from "./pk/SourceOutDatasetPkFields";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

export default function SourceOutDatasetKeys({
  sourceDatasetUuId,
  sourceOutDatasetUuId,
}) {
  const theme = useTheme();

  return (
    <NtaiPanel
      width="75%"
      header="Store Keys and SCD Fields"
      subheader="More information"
    >
      <NtaiTabs>
        <Box label="PRIMARY KEYS">
          <SourceOutDatasetPkFields
            sourceDatasetUuId={sourceDatasetUuId}
            sourceOutDatasetUuId={sourceOutDatasetUuId}
          />
        </Box>
        <Box label="NATURAL KEYS">
          <SourceOutDatasetNkFields
            sourceDatasetUuId={sourceDatasetUuId}
            sourceOutDatasetUuId={sourceOutDatasetUuId}
          />
        </Box>
        <Box label="SCD FIELDS">
          <SourceOutDatasetScdFields
            sourceDatasetUuId={sourceDatasetUuId}
            sourceOutDatasetUuId={sourceOutDatasetUuId}
          />
        </Box>
      </NtaiTabs>
    </NtaiPanel>
  );
}
