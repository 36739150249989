import React from "react";
import { render } from "react-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { Box } from "@mui/material";

const staticWordCompleter = {
  getCompletions: function (editor, session, pos, prefix, callback) {
    var wordList = ["foo", "bar", "baz"];
    callback(
      null,
      wordList.map(function (word) {
        return {
          caption: word,
          value: word,
          meta: "static",
        };
      })
    );
  },
};

export default function NtaiAceEditor() {
  function onChange(newValue) {
    console.log("change", newValue);
  }

  // Render editor
  return (
    <Box
      sx={{
        display: "fixed",
        position: "absolute",
        height: "500px",
        width: "250px",
      }}
    >
      <AceEditor
        mode="python"
        theme="github"
        onChange={onChange}
        name="query"
        width="100%"
        height="100%"
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: [staticWordCompleter],
          enableLiveAutocompletion: true,
        }}
        // setOptions={{
        //   enableBasicAutocompletion: true,
        //   //   enableLiveAutocompletion: true,
        //   enableSnippets: true,
        // }}
      />
    </Box>
  );
}
