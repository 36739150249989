import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getObjectInfoListingFields = createAsyncThunk(
  "objectInfoListingFields/getObjectInfoListingFields",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/listing-fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectInfoListingField = createAsyncThunk(
  "objectInfoListingFields/createObjectInfoListingField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, formData } = values;
      const response = await server.post(
        `/cdo/domains/${domainId}/objects/${objectId}/listing-fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectInfoListingField = createAsyncThunk(
  "objectInfoListingFields/deleteObjectInfoListingField",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/domains/${domainId}/objects/${objectId}/listing-fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectInfoListingFieldsSlice = createSlice({
  name: "objectInfoListingFields",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectInfoListingFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getObjectInfoListingFields",
      };
    },
    [getObjectInfoListingFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectInfoListingFields",
        message: action.payload.message,
      };
    },

    [createObjectInfoListingField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };

      state.status = {
        result: "success",
        method: "createObjectInfoListingField",
      };
    },
    [createObjectInfoListingField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectInfoListingField",
        message: action.payload.message,
      };
    },

    [deleteObjectInfoListingField.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "deleteObjectInfoListingField",
      };
    },
    [deleteObjectInfoListingField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectInfoListingField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  objectInfoListingFieldsSlice.actions;

export default objectInfoListingFieldsSlice.reducer;
