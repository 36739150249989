import React, { useCallback, useMemo, useRef, useState } from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const rowData1 = [
  {
    count1: "481",
    count2: 819,
    event: "Ocular Adverse Event (Not Serious)",
    type: "other",
    nct_id: "NCT00000125",
    arm: "Observation",
  },
  {
    count1: "12",
    count2: 819,
    event: "Ocular Serious Adverse Events (OHTS I, June 2002)",
    type: "serious",
    nct_id: "NCT00000125",
    arm: "Observation",
  },
  {
    count1: "481",
    count2: 819,
    event: "Total, other adverse events",
    type: "other",
    nct_id: "NCT00000125",
    arm: "Observation",
  },
  {
    count1: "12",
    count2: 819,
    event: "Total, serious adverse events",
    type: "serious",
    nct_id: "NCT00000125",
    arm: "Observation",
  },
  {
    count1: "570",
    count2: 817,
    event: "Ocular Adverse Event (Not Serious)",
    type: "other",
    nct_id: "NCT00000125",
    arm: "Treatment",
  },
  {
    count1: "13",
    count2: 817,
    event: "Ocular Serious Adverse Events (OHTS I, June 2002)",
    type: "serious",
    nct_id: "NCT00000125",
    arm: "Treatment",
  },
  {
    count1: "570",
    count2: 817,
    event: "Total, other adverse events",
    type: "other",
    nct_id: "NCT00000125",
    arm: "Treatment",
  },
  {
    count1: "13",
    count2: 817,
    event: "Total, serious adverse events",
    type: "serious",
    nct_id: "NCT00000125",
    arm: "Treatment",
  },
];
const NtaiAgGridTable3 = () => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "nct_id",
      rowGroup: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    { field: "arm", rowGroup: true },
    { field: "event", pivot: true, minWidth: 180 },
    { field: "type", pivot: true },
    { field: "count1", minWidth: 150, aggFunc: "first", headerName: "N" },
    { field: "count2", aggFunc: "first", headerName: "Total" },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  //   const onGridReady = useCallback((params) => {
  //     fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //       .then((resp) => resp.json())
  //       .then((data) => setRowData(data));
  //   }, []);

  function cellClicked(p) {}

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          suppressAggFuncInHeader={true}
          //   rowGroupPanelShow="always"
          onCellClicked={(p) => cellClicked(p)}
          rowData={rowData1}
          rowHeaderStyle={{ fontSize: "12px" }}
          rowStyle={{ fontSize: "12px" }}
          pivotMode={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          //   sideBar={true}
          //   onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default NtaiAgGridTable3;
