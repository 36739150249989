import { Box, Typography, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "../../sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function UserQueryCriteria({ handleSave, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { watch, getValues, reset } = useFormContext();
  const [sourceCriteria, setSourceCriteria] = useState();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [esCriteria, setEsCriteria] = useState();
  const [fields, setFields] = useState();

  const userQueryId = useSelector(
    (state) => state.core.userQueriesSlice.activeId
  );
  const userQueryDataMap = useSelector(
    (state) => state.core.userQueriesSlice.data
  );

  const userQueryData = _.get(userQueryDataMap, userQueryId);
  const sourceUuId = _.get(userQueryData, "source.uuId");
  const sourceDefUuId = _.get(userQueryData, "source.sourceDefUuId");

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  const queryValue = _.get(userQueryData, "uiCriteria");

  function buildQuery(tree, config, jsonTree, formats) {
    setSourceCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  function onHandleSave() {
    const formDataCopy = _.cloneDeep(userQueryDataMap[userQueryId]);
    if (userQueryData["queryModeFg"] === 1) {
      formDataCopy["uiEsCriteria"] = esCriteria;
      formDataCopy["uiCriteria"] = sourceCriteria;
    } else {
      formDataCopy["nativeCriteria"] = getValues(
        "userQueryForm.nativeCriteria"
      );
    }

    handleSave(formDataCopy);

    // const revisedData = {
    //   alertDefSource: {
    //     sourceCriteria: sourceCriteria,
    //     esCriteria: esCriteria,
    //   },
    // };
    // dispatch(
    //   updateSourceCriteria({
    //     definitionId: alertDefId,
    //     alertDefSourceId: alertDefSourceId,
    //     formData: revisedData,
    //   })
    // );
  }

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefUuId,
        sourceUuId,
        mergedFields
      );

      setFields({ ...tmpFields });
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    dispatch(
      getSourceFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
    dispatch(
      getSourceDerivedFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      {fieldsLoaded && (
        <NtaiFormPanel
          header="Build Criteria"
          width="75%"
          subheader="Define source query criteria using query builder tool"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingBottom: theme.spacing(1),
            }}
          >
            {userQueryData["queryModeFg"] === 1 &&
              fieldsLoaded &&
              fields &&
              Object.keys(fields).length !== 0 && (
                <NtaiQueryBuilder
                  fields={fields}
                  buildQuery={buildQuery}
                  value={queryValue}
                />
              )}
            {userQueryData["queryModeFg"] === 2 && (
              <NtaiTextField
                name="userQueryForm.nativeCriteria"
                multiline
                minRows={10}
                maxRows={10}
              />
            )}
          </Box>
        </NtaiFormPanel>
      )}
      {!fieldsLoaded && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <NtaiCircularProgress />
        </Box>
      )}
    </Box>
  );
}
