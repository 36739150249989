import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  clearStatus,
  createSourceRecordTemplate,
  setActiveId,
} from "./store/sourceRecordTemplatesSlice";
import SourceOtherSourceRecordTemplateForm from "./SourceOtherSourceRecordTemplateForm";
import SourceCurrentSourceRecordTemplateForm from "./SourceCurrentSourceRecordTemplateForm";

export default function SourceRecordTemplateCreate({
  templateType,
  handleCancel,
  redirectToEdit,
}) {
  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.status
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.activeId
  );

  function handleFormSubmit(formData) {
    dispatch(
      createSourceRecordTemplate({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceRecordTemplate" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      redirectToEdit(activeId);
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(null));
  }, []);

  return (
    <NtaiForm>
      <>
        {templateType === 1 && (
          <SourceCurrentSourceRecordTemplateForm
            action="add"
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        )}
        {templateType === 2 && (
          <SourceOtherSourceRecordTemplateForm
            action="add"
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        )}
      </>
    </NtaiForm>
  );
}
