import { Box } from "@mui/material";
import React from "react";
import DummyGridContainer from "./aggrid/DummyGridContainer";
import NtaiAgGridTable from "./aggrid/NtaiAgGridTable";
import NtaiAgGridTable2 from "./aggrid/NtaiAgGridTable2";
import NtaiAgGridTable3 from "./aggrid/NtaiAgGridTable3";
import NtaiAgGridTable4 from "./aggrid/NtaiAgGridTable4";
import NtaiGroupedTable from "./grouped/NtaiGroupedTable";
import NtaiTanStackTable from "./tanstack/NtaiTanStackTable";

export default function TestNtaiTables() {
  return (
    <Box sx={{ p: 4, display: "flex", width: "100%", height: "100%" }}>
      <NtaiAgGridTable4 />
    </Box>
  );
}
