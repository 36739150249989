import { faArchive, faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";

export default function SourceInStoreOcrPageBox({
  data,
  handleEdit,
  handleDelete,
  selected,
  handleSelect,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        height: "180px",
        width: "100%",
        flexDirection: "column",
        border:
          selected === data.uuId
            ? theme.general.border2p
            : theme.general.border1,
        boxShadow: theme.colors.shadows.cardSm,
        background: "white",
        cursor: "pointer",
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
        },
      }}
      onClick={() => handleSelect(data.uuId)}
    >
      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: theme.spacing(1),
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
          Page {data.pageSeq}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(0.5),
          }}
        >
          <IconButton
            sx={{ width: "24px", height: "24px" }}
            onClick={() => handleEdit(data.uuId)}
          >
            <FontAwesomeIcon size="2xs" icon={faEdit} />
          </IconButton>
          <IconButton
            sx={{ width: "24px", height: "24px" }}
            onClick={() => handleDelete(data.uuId)}
          >
            <FontAwesomeIcon size="2xs" icon={faArchive} />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          height: `calc(100% - 44px)`,
          display: "flex",
          p: theme.spacing(1),
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {data.name}
        </Typography>
        <Typography variant="caption">{data.description}</Typography>
      </Box>
    </Box>
  );
}
