import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiScrollableTabs from "./NtaiScrollableTabs";
import NtaiTabs from "./NtaiTabs";

export default function NtaiTestTabs() {
  const theme = useTheme();

  function handleTabChange(tabValue) {
    console.log("Tab changed to ", tabValue);
  }
  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <NtaiTabs handleChange={handleTabChange}>
        <Box label="Tab1">I am tab1</Box>
        <Box label="Tab2">I am tab2</Box>
        <Box label="Disabled" disabled>
          I am tab3
        </Box>
      </NtaiTabs>

      <NtaiTabs centered>
        <Box label="FDA FAERS">I am tab1 from centered</Box>
        <Box label="CLINICAL TRIALS">I am tab2</Box>
      </NtaiTabs>

      <Box sx={{ maxWidth: "320px" }}>
        <NtaiTabs variant="scrollable" scrollButtons>
          <Box label="FDA FAERS">I am tab1</Box>
          <Box label="CLINICAL">I am tab2</Box>
          <Box label="CLINICAL1">I am tab2</Box>
          <Box label="CLINICAL3">I am tab2</Box>
        </NtaiTabs>
      </Box>

      <NtaiScrollableTabs />
    </Box>
  );
}
