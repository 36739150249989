import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive, faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import {
  createUserDatasetSourceView,
  deleteUserDatasetSourceView,
  getUserDatasetSourceViews,
  clearStatus,
  updateUserDatasetSourceViewDefaultFg,
} from "./store/userDatasetSourceViewsSlice";
import {
  getSourceViews,
  clearStatus as clearSourceViewsSliceStatus,
} from "../../../sourcedefinition/source/view/store/sourceViewsSlice";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.sourceViewUuId]["name"],
        secondary: allObjectsDataMap[f.sourceViewUuId]["description"],
        defaultFg: f.defaultFg === 1 ? true : false,
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["name"], ["asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.sourceViewUuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function UserDatasetSourceViews() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const datasetId = useSelector(
    (state) => state.core.userDatasetsSlice.activeId
  );
  const datasetDataMap = useSelector(
    (state) => state.core.userDatasetsSlice.data
  );

  const datasetData = datasetDataMap[datasetId];
  const sourceId = datasetData["sourceUuId"];

  const sourceViewsDataMap = useSelector(
    (state) => state.sources.sourceViewsSlice.data
  );

  const sourceViewsSliceStatus = useSelector(
    (state) => state.sources.sourceViewsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.core.userDatasetSourceViewsSlice.data
  );

  const status = useSelector(
    (state) => state.core.userDatasetSourceViewsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const sourceViewsData =
    sourceViewsDataMap && !_.isEmpty(sourceViewsDataMap)
      ? Object.values(sourceViewsDataMap)
      : [];

  function handleAdd(uuId) {
    if (_.filter(data, { sourceUuId: uuId }).length === 0) {
      dispatch(
        createUserDatasetSourceView({
          datasetId: datasetId,
          formData: {
            sourceViewUuIds: [uuId],
          },
        })
      );
    }
  }

  function handleRemove(uuId) {
    if (_.filter(data, { uuId: uuId }).length > 0) {
      dispatch(
        deleteUserDatasetSourceView({
          datasetId: datasetId,
          uuId: uuId,
        })
      );
    }
  }

  function handleAddAll() {
    const availableResourceUuIds = availableObjects.map((o, i) => o["uuId"]);
    if (availableResourceUuIds.length > 0) {
      dispatch(
        createUserDatasetSourceView({
          datasetId: datasetId,
          formData: {
            sourceViewUuIds: [...availableResourceUuIds],
          },
        })
      );
    }
  }

  function handleRemoveAll() {
    data.forEach((o, i) => {
      dispatch(
        deleteUserDatasetSourceView({
          datasetId: datasetId,
          uuId: o["uuId"],
        })
      );
    });
  }

  function handleMakeDefault(uuId) {
    const selectedRow = dataMap[uuId];
    dispatch(
      updateUserDatasetSourceViewDefaultFg({
        datasetId: datasetId,
        uuId: uuId,
        formData: {
          defaultFg: 1,
          sourceViewUuIds: [selectedRow["sourceViewUuId"]],
        },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      [
        "getUserDatasetSourceViews",
        "createUserDatasetSourceView",
        "deleteUserDatasetSourceView",
        "updateUserDatasetSourceViewDefaultFg",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setAvailableObjects([
        ...transformAvailableObjects(data, sourceViewsData),
      ]);
      setSelectedObjects([
        ...transformSelectedObjects(data, sourceViewsDataMap),
      ]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      sourceViewsSliceStatus &&
      sourceViewsSliceStatus.result === "success" &&
      sourceViewsSliceStatus.method === "getSourceViews"
    ) {
      dispatch(getUserDatasetSourceViews(datasetId));
      dispatch(clearSourceViewsSliceStatus());
    }
  }, [sourceViewsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceViews({
        sourceDefId: "*",
        sourceId: sourceId,
        params: {
          typeCode: 6,
          fetchMin: true,
        },
      })
    );
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Source Views"
      subheader="You can assign one or more source views to the dataset"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Views Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleRemoveAll()}
            >
              REMOVE ALL
            </Button>
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleAddAll()}
            >
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["name"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.name}
                              </Typography>
                            }
                            secondary={fd.description}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No views available"
                subheader="No source views available in the library or you have assigned all views"
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: theme.spacing(1),
                              }}
                            >
                              <Button
                                variant={so["defaultFg"] ? "outlined" : null}
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleMakeDefault(so.uuId)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    so["defaultFg"] ? faCheckCircle : faCheck
                                  }
                                />
                                DEFAULT
                              </Button>

                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            </Box>
                          </ListItem>

                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No views assigned"
                subheader="Add views from left panel to the dataset"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
