import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";

import {
  clearStatus,
  setActiveId,
  updateSourceRecordTemplate,
} from "./store/sourceRecordTemplatesSlice";
import SourceCurrentSourceRecordTemplateForm from "./SourceCurrentSourceRecordTemplateForm";
import SourceOtherSourceRecordTemplateForm from "./SourceOtherSourceRecordTemplateForm";
const _ = require("lodash");

export default function SourceRecordTemplateEdit(props) {
  const dispatch = useDispatch();

  const { id, handleCancel } = props;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );

  const formData = _.get(dataMap, id);

  function handleFormSubmit(formData) {
    dispatch(
      updateSourceRecordTemplate({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceRecordTemplate" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiForm>
      {formData && (
        <React.Fragment>
          {(!formData["extTemplateFg"] || formData["extTemplateFg"] === 0) && (
            <SourceCurrentSourceRecordTemplateForm
              action="edit"
              formData={formData}
              handleFormSubmit={handleFormSubmit}
              handleCancel={handleCancel}
            />
          )}
          {formData["extTemplateFg"] && formData["extTemplateFg"] === 1 && (
            <SourceOtherSourceRecordTemplateForm
              action="edit"
              formData={formData}
              handleFormSubmit={handleFormSubmit}
              handleCancel={handleCancel}
            />
          )}
        </React.Fragment>
      )}
    </NtaiForm>
  );
}
