import { useTheme } from "@emotion/react";
import { Box, Chip, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { withStyles } from "@mui/styles";
import React, { Children, Fragment, useState } from "react";
import NtaiBadge from "../badges/NtaiBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-light-svg-icons";
import NtaiIconButton from "../buttons/NtaiIconButton";
const _ = require("lodash");

const styles = (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    background: "white",
    // borderRadius: "1px",
  },
  tabContent: {
    height: "100%",
    paddingTop: theme.spacing(0),
  },
});

const NtaiTab = withStyles((theme) => ({
  root: {},
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const NtaiTabContainer = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const onChange = (e, value) => {
    setValue(value);
    if (props.handleChange) props.handleChange(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: (props) =>
          props.hasOwnProperty("width") ? props.width : "100%",
      }}
    >
      <Tabs
        value={value}
        onChange={onChange}
        variant={props.hasOwnProperty("variant") ? props.variant : null}
      >
        {Children.map(props.children, (child, index) => {
          if (_.has(child.props, "visible") && child.props.visible === false) {
            return null;
          } else if (
            !_.has(child.props, "visible") ||
            child.props.visible === true
          ) {
            return (
              <NtaiTab
                label={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    {child.props.label}
                    {child.props.chip && (
                      <Box
                        sx={{
                          py: "1px",
                          px: "4px",
                          borderRadius: theme.general.borderRadius,
                          border: theme.general.border1,
                        }}
                      >
                        <Typography variant="caption" color="inherit">
                          {child.props.chip}
                        </Typography>
                      </Box>
                    )}
                    {/* {child.props.action && (
                      <NtaiIconButton
                        icon={child.props.action.icon}
                        handleClick={() =>
                          child.props.action.handleActionClick(
                            child.props.action.id
                          )
                        }
                      />
                    )} */}
                  </Box>
                }
                disabled={child.props.disabled}
              />
            );
          }
        })}
      </Tabs>
      {Children.map(props.children, (child, index) =>
        index === value ? <TabContent>{child}</TabContent> : null
      )}
    </Box>
  );
};

export const TabContent = withStyles(styles)(({ classes, children }) => (
  <Box className={classes.tabContent}>{children}</Box>
));

const NtaiTabs = withStyles(styles)((props) => (
  <Box className={props.classes.root}>
    <NtaiTabContainer {...props}>{props.children}</NtaiTabContainer>
  </Box>
));

export default NtaiTabs;
