import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiChartEmptyMessage from "../NtaiChartEmptyMessage";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

export default function NtaiHistogramChart(props) {
  const { widgetId, data, categoryField, valueField, handleClick, metadata } =
    props;
  const theme = useTheme();

  console.log("Hist Data: ", data);
  console.log("Hist metadata: ", metadata);

  useLayoutEffect(() => {
    let chart = am4core.create(
      "histchart".concat("-", widgetId),
      am4charts.XYChart
    );

    chart.data = data;

    chart.cursor = new am4charts.XYCursor();
    chart.responsive.enabled = true;

    // zoom icon
    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#ffffff");
    chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#eeeeee");

    chart.zoomOutButton.icon.disabled = true;
    let zoomImage = chart.zoomOutButton.createChild(am4core.Image);
    zoomImage.href = "/images/zoom-icon.png";
    zoomImage.width = 15;
    zoomImage.height = 15;
    zoomImage.interactionsEnabled = false;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = categoryField;
    // categoryAxis.title.text = "Countries";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0.5;
    categoryAxis.title.fontSize = 11;
    categoryAxis.renderer.minGridDistance = 4;
    categoryAxis.interpolationDuration = 0;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.rotation = 270;
    label.maxWidth = 120;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    label.fontSize = 10;
    label.truncate = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    let valueAxisLabel = valueAxis.renderer.labels.template;
    valueAxisLabel.fontSize = 10;
    valueAxisLabel.rotation = 270;

    let series = chart.series.push(new am4charts.ColumnSeries());
    // series.showOnInit = false;
    // series.sequencedInterpolation = false;
    series.dataFields.valueY = valueField;
    series.dataFields.categoryX = categoryField;
    series.stroke = am4core.color("#CDA2AB");

    series.columns.template.strokeWidth = 0;
    series.columns.template.tooltipText = "{categoryX}\n{valueY}";

    series.defaultState.transitionDuration = 0;
    series.sequencedInterpolationDelay = 0;

    series.columns.template.fill = am4core.color(
      lighten(theme.colors.chart.lighter, 0.5)
    ); // main: FFD366, orange: FDC65C green: 73EB90, blue: 1E90FF, red: FF7276 // green: 65fe08 // brand: FFD366

    // tool tip
    chart.tooltip.getFillFromObject = false;

    // tool tip
    series.tooltip.getFillFromObject = false;
    series.tooltip.autoTextColor = false;
    series.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    series.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    series.tooltip.background.fillOpacity = tooltipConfig.backgroundFillOpacity;
    series.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    series.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    //
    // chart.tooltip.background.fill = am4core.color("#67b7dc");

    let axisTooltip = categoryAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 8;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.sortBySeries = series;

    let valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#767676");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    valueAxisTooltip.rotation = 270;
    valueAxisTooltip.dy = 5;
    valueAxisTooltip.dx = -20;
    valueAxisTooltip.label.fill = am4core.color("#fff");
    valueAxisTooltip.label.fontSize = 11;

    series.columns.template.events.on(
      "hit",
      function (ev) {
        const sourceWidgetFilterFields = [
          {
            sourceFieldUuId: Object.keys(_.get(metadata, "fields"))[0],
            fieldOperatorCode: 1,
            fieldValue: _.get(ev.target.dataItem.dataContext, categoryField),
          },
        ];
        handleClick(sourceWidgetFilterFields, ev);
      },
      this
    );

    // series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      {Array.isArray(data) && data.length > 0 && (
        <Box
          id={"histchart".concat("-", widgetId)}
          sx={{ width: "100%", height: "100%" }}
        ></Box>
      )}

      {Array.isArray(data) && data.length === 0 && <NtaiChartEmptyMessage />}
    </>
  );
}
