import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getToolsAssessmentImports = createAsyncThunk(
  "toolsAssessmentImports/getToolsAssessmentImports",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/cdo/data-imports`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getToolsAssessmentImport = createAsyncThunk(
  "toolsAssessmentImports/getToolsAssessmentImport",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/cdo/data-imports/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createToolsAssessmentImport = createAsyncThunk(
  "toolsAssessmentImports/createToolsAssessmentImport",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post(`/cdo/data-imports`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateToolsAssessmentImport = createAsyncThunk(
  "toolsAssessmentImports/updateToolsAssessmentImport",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/cdo/data-imports/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteToolsAssessmentImport = createAsyncThunk(
  "toolsAssessmentImport/deleteToolsAssessmentImport",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/cdo/data-imports/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const validateToolsAssessmentImport = createAsyncThunk(
  "searchSeries/validateToolsAssessmentImport",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/cdo/data-imports/validate", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitToolsAssessmentImport = createAsyncThunk(
  "searchSeries/submitToolsAssessmentImport",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/cdo/data-imports/submit", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const toolsAssessmentImportSlice = createSlice({
  name: "toolsAssessmentImport",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getToolsAssessmentImports.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getToolsAssessmentImports" };
    },
    [getToolsAssessmentImports.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getToolsAssessmentImports",
        message: action.payload.message,
      };
    },
    [getToolsAssessmentImport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getToolsAssessmentImport" };
    },
    [getToolsAssessmentImport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getToolsAssessmentImport",
        message: action.payload.message,
      };
    },
    [createToolsAssessmentImport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createToolsAssessmentImport",
      };
      state.activeId = action.payload.uuId;
    },
    [createToolsAssessmentImport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createToolsAssessmentImport",
        message: action.payload.message,
      };
    },
    [updateToolsAssessmentImport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateToolsAssessmentImport",
      };
    },
    [updateToolsAssessmentImport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateToolsAssessmentImport",
        message: action.payload.message,
      };
    },
    [deleteToolsAssessmentImport.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteToolsAssessmentImport",
      };
    },
    [deleteToolsAssessmentImport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteToolsAssessmentImport",
        message: action.payload.message,
      };
    },
    [validateToolsAssessmentImport.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = {
        result: "success",
        method: "validateToolsAssessmentImport",
      };
    },
    [validateToolsAssessmentImport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "validateToolsAssessmentImport",
        message: action.payload.message,
      };
    },

    [submitToolsAssessmentImport.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = {
        result: "success",
        method: "submitToolsAssessmentImport",
      };
    },
    [submitToolsAssessmentImport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "submitToolsAssessmentImport",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = toolsAssessmentImportSlice.actions;

export default toolsAssessmentImportSlice.reducer;
