import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import ThesaurusSemTypeList from "./semtype/ThesaurusSemTypeList";
import ThesaurusConceptList from "./concept/ThesaurusConceptList";
import { getSourceDefinitions } from "../../sourcedefinition/store/sourceDefinitionsSlice";
import ThesaurusSources from "./source/ThesaurusSources";
const _ = require("lodash");

export default function ThesaurusForm({ action, formData, handleSave }) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  const dataMap = useSelector((state) => state.core.thesaurusesSlice.data);
  const data = Object.values(dataMap);

  const domainDataMap = useSelector((state) => state.core.domainsSlice.data);
  const domainData = Object.values(domainDataMap);
  const domainOptions = NtaiUtils.getSelectOptions(domainData, "uuId", "name");

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );
  const sourceDefData =
    sourceDefDataMap && !_.isEmpty(sourceDefDataMap)
      ? Object.values(sourceDefDataMap)
      : [];

  const sourceDefOptions = NtaiUtils.getSelectOptions(
    sourceDefData,
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("thesaurusForm")
    );

    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push("/library/thesauruses");
  }

  useEffect(() => {
    dispatch(
      getSourceDefinitions({
        thesaurusFg: 1,
      })
    );
  }, []);

  useEffect(() => {
    reset({ thesaurusForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="50%"
          header="General Description"
          subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="thesaurusForm.shortName"
            label="Short Name*"
            placeholder="Short Name"
            rules={{ required: "Short name is required" }}
            disabled={action === "edit" ? true : false}
          />
          <NtaiTextField
            name="thesaurusForm.name"
            label="Name*"
            placeholder="Name"
            rules={{ required: "Name is required" }}
          />

          <NtaiTextField
            name="thesaurusForm.description"
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Description"
            label="Description*"
            rules={{ required: "Description is required" }}
          />

          <NtaiSwitchField label="Primary?" name="thesaurusForm.primaryFg" />

          <NtaiSelectField
            name="thesaurusForm.sourceDefUuId"
            options={sourceDefOptions}
            label="Select Source"
          />
        </NtaiFormPanel>
      </Box>
      <Box disabled={action === "add" ? true : false} label="SEMANTIC TYPES">
        <ThesaurusSemTypeList />
      </Box>
      <Box disabled={action === "add" ? true : false} label="CONCEPTS">
        <ThesaurusConceptList />
      </Box>
      <Box disabled={action === "add" ? true : false} label="SOURCES">
        <ThesaurusSources />
      </Box>
    </NtaiTabs>
  );
}
