import { Avatar, Box, IconButton, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  CHART_CODES,
  CODELIST_CODES,
} from "app/main/constants/NtaiCodelistConstants";
import { useDispatch } from "react-redux";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import SourceWidgetXYChartForm from "./widget-forms/SourceWidgetXYChartForm";
import SourceWidgetPivotTableForm from "./widget-forms/SourceWidgetPivotTableForm";
import SourceWidgetGeoMapForm from "./widget-forms/SourceWidgetGeoMapForm";
import SourceWidgetDataGridForm from "./widget-forms/SourceWidgetDataGridForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartMixed, faClose } from "@fortawesome/pro-light-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiChartIcons from "utils/NtaiChartIcons";
import SourceWidgetScriptedMetricForm from "./widget-forms/SourceWidgetScriptedMetricForm";
import SourceWidgetPieChartForm from "./widget-forms/SourceWidgetPieChartForm";
import SourceWidgetDefaultChartForm from "./widget-forms/SourceWidgetDefaultChartForm";
import SourceWidgetBarLineChartForm from "./widget-forms/SourceWidgetBarLineChartForm";
import SourceWidgetScatterChartForm from "./widget-forms/SoureWidgetScatterChartForm";
const _ = require("lodash");

export default function SourceWidgetForm({ widget, handleSave, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();

  function onHandleSave(formData) {
    handleSave(widget.uuId, formData);
  }

  function renderWidgetForm() {
    switch (widget.widget.contentCode) {
      case CHART_CODES.BAR_VERTICAL:
      case CHART_CODES.BAR_VERTICAL_SERIES:
      case CHART_CODES.BAR_VERTICAL_STACKED:
      case CHART_CODES.BAR_HORIZONTAL:
      case CHART_CODES.BAR_HORIZONTAL_SERIES:
      case CHART_CODES.BAR_HORIZONTAL_STACKED:
      case CHART_CODES.DATE_HISTOGRAM:
      case CHART_CODES.TIME_SERIES:
        return (
          <SourceWidgetXYChartForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      case CHART_CODES.PIE:
      case CHART_CODES.DONUT:
        return (
          <SourceWidgetPieChartForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      case CHART_CODES.BUBBLE:
      case CHART_CODES.SCATTER:
        return (
          <SourceWidgetScatterChartForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      case CHART_CODES.DATA_GRID_I:
      case CHART_CODES.DATA_GRID_II:
      case CHART_CODES.SUMMARY_TABULATION:
        return (
          <SourceWidgetDataGridForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      case CHART_CODES.PIVOT_GRID:
        return (
          <SourceWidgetPivotTableForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      case CHART_CODES.SCRIPTED_METRIC:
        return (
          <SourceWidgetScriptedMetricForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      case CHART_CODES.GEO:
        return (
          <SourceWidgetGeoMapForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      case CHART_CODES.BAR_LINE:
        return (
          <SourceWidgetBarLineChartForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;

      default:
        return (
          <SourceWidgetDefaultChartForm
            widget={widget}
            handleCancel={handleCancel}
            handleSave={onHandleSave}
          />
        );
        break;
    }
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_VIEW_WIDGET_METRIC_CODELIST));
    dispatch(
      getCodes(CODELIST_CODES.SRC_SOURCE_WIDGET_FIELD_DATE_INTERVAL_CODE)
    );
    reset({ widgetForm: widget });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <NtaiPanel
          height="80px"
          width="100%"
          headerIcon={
            <Avatar>
              <FontAwesomeIcon
                size="xl"
                icon={
                  NtaiChartIcons.hasOwnProperty(
                    widget["widget"]["name"].toLowerCase()
                  )
                    ? NtaiChartIcons[widget.widget["name"].toLowerCase()]
                    : faChartMixed
                }
              />
            </Avatar>
          }
          header={`Edit ${widget["name"]}`}
          subheader={widget["widget"]["contentDecode"]}
          headerActions={
            <IconButton onClick={handleCancel}>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          }
        />
        <Box
          sx={{
            height: `calc(100% - 80px)`,
            display: "flex",
            width: "100%",
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
              }}
            >
              {renderWidgetForm()}
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </>
  );
}
