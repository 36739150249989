import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportContext } from "../../ReportMain";
import { grey } from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare as faCheckSquareSolid } from "@fortawesome/pro-solid-svg-icons";
import {
  faCheckSquare as faCheckSquareLight,
  faSquare,
} from "@fortawesome/pro-light-svg-icons";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  getUserDatasetBySourceId,
  clearStatus,
} from "app/main/pages/core/library/user-dataset/store/userDatasetsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

function revisedDatasetData(sourceDatasetData, reportFormData, searchText) {
  if (
    reportFormData &&
    _.has(reportFormData, "uuId") &&
    reportFormData["uuId"].length > 0 &&
    reportFormData["sourceDomainUuId"] &&
    reportFormData["sourceDomainUuId"].length > 0
  ) {
    return _.filter(sourceDatasetData, function (o) {
      return o["uuId"] === reportFormData["sourceDomainUuId"] ? true : false;
    });
  } else {
    if (searchText && searchText.length > 0) {
      return _.filter(sourceDatasetData, function (o) {
        return o["name"].toLowerCase().includes(searchText.toLowerCase())
          ? true
          : false;
      });
    } else return sourceDatasetData;
  }
}

export default function ReportDatasetCriteria() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState();

  const {
    reportDefinition,
    reportFormData,
    selectedSourceDatasetId,
    setSelectedSourceDatasetId,
  } = React.useContext(ReportContext);

  const sourceDatasetDataMap = useSelector(
    (state) => state.core.userDatasetsSlice.data
  );

  const sourceDatasetSliceStatus = useSelector(
    (state) => state.core.userDatasetsSlice.status
  );

  const sourceDatasetData =
    sourceDatasetDataMap && !_.isEmpty(sourceDatasetDataMap)
      ? Object.values(sourceDatasetDataMap)
      : [];

  const data =
    sourceDatasetData && _.isArray(sourceDatasetData)
      ? revisedDatasetData(sourceDatasetData, reportFormData, searchText)
      : null;

  const debouncedOnHandleChange = _.debounce(handleDatasetSearch, 500);

  function handleDatasetSearch(txt) {
    setSearchText(txt.trim());
  }

  function handleSelect(id) {
    setSelectedSourceDatasetId(selectedSourceDatasetId === id ? null : id);
  }

  useEffect(() => {
    if (
      sourceDatasetSliceStatus &&
      sourceDatasetSliceStatus.method === "getUserDatasetBySourceId" &&
      sourceDatasetSliceStatus.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [sourceDatasetSliceStatus]);

  useEffect(() => {
    dispatch(
      getUserDatasetBySourceId({ sourceUuId: reportDefinition["sourceUuId"] })
    );
  }, [reportDefinition["sourceUuId"]]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <Box
          sx={{
            p: theme.spacing(1),
            px: theme.spacing(2),
            display: "flex",
            height: "44px",
            width: "100%",
            alignItems: "center",
            borderBottom: theme.general.border1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{`Select Dataset`}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <NtaiSimpleSearchBar
              placeholder="Search..."
              height="28px"
              fontSize="12px"
              width="auto"
              background={grey[100]}
              handleChange={debouncedOnHandleChange}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            height: `calc(100% - 44px)`,
          }}
        >
          {loaded && (
            <Scrollbar>
              <List sx={{ height: "100%", p: 0 }}>
                {data &&
                  data.length > 0 &&
                  _.orderBy(data, ["name"], ["asc"]).map((o, i) => (
                    <React.Fragment>
                      <ListItem
                        sx={{
                          "&:hover": {
                            background: grey[100],
                            cursor: "pointer",
                          },
                        }}
                        onClick={() =>
                          reportFormData &&
                          reportFormData["sourceDomainUuId"] &&
                          reportFormData["sourceDomainUuId"].length > 0
                            ? null
                            : handleSelect(o["uuId"])
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            pr: theme.spacing(2),
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                color="inherit"
                                fontWeight="700"
                              >
                                {o["name"]}
                              </Typography>
                            }
                            secondary={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography variant="subtitle2">
                                  {o["sourceMin"]["displayName"]}
                                </Typography>
                                <Typography variant="caption">
                                  {o["description"]}
                                </Typography>
                              </Box>
                            }
                          />

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: theme.spacing(2),
                            }}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              icon={
                                selectedSourceDatasetId === o["uuId"]
                                  ? faCheckSquareSolid
                                  : faSquare
                              }
                            />
                          </Box>
                        </Box>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
            </Scrollbar>
          )}
          {!loaded && <NtaiCircularProgress />}
        </Box>
      </Box>
    </React.Fragment>
  );
}
