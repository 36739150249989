import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiHorizontalScrollingItems from "./NtaiHorizontalScrollingItems";

export default function TestHorizontalScollingItems() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
      }}
    >
      <NtaiHorizontalScrollingItems />
    </Box>
  );
}
