import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDashboardWidgets = createAsyncThunk(
  "dashboardWidgets/getDashboardWidgets",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/dashboards/widgets");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDashboardWidget = createAsyncThunk(
  "dashboardWidgets/getDashboardWidget",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/dashboards/widgets/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDashboardWidget = createAsyncThunk(
  "dashboardWidgets/createDashboardWidget",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/dashboards/widgets", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDashboardWidget = createAsyncThunk(
  "dashboardWidgets/updateDashboardWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/dashboards/widgets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDashboardWidget = createAsyncThunk(
  "dashboardWidgets/deleteDashboardWidget",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/dashboards/widgets/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const dashboardWidgetsSlice = createSlice({
  name: "dashboardWidgets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDashboardWidgets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getDashboardWidgets" };
    },
    [getDashboardWidgets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboardWidgets",
        message: action.payload.message,
      };
    },
    [getDashboardWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getDashboardWidget" };
    },
    [getDashboardWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboardWidget",
        message: action.payload.message,
      };
    },
    [createDashboardWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createDashboardWidget" };
      state.activeId = action.payload.uuId;
    },
    [createDashboardWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createDashboardWidget",
        message: action.payload.message,
      };
    },
    [updateDashboardWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateDashboardWidget" };
    },
    [updateDashboardWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateDashboardWidget",
        message: action.payload.message,
      };
    },
    [deleteDashboardWidget.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteDashboardWidget" };
    },
    [deleteDashboardWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteDashboardWidget",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = dashboardWidgetsSlice.actions;

export default dashboardWidgetsSlice.reducer;
