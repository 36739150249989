import React from "react";
import { useDrop } from "react-dnd";
import "./dnd2.css";
import { Box, Typography, useTheme } from "@mui/material";

const Dnd2Column = ({ children, className, title, direction }) => {
  const theme = useTheme();
  const [, drop] = useDrop({
    accept: "Our first type",
    drop: () => ({ name: title }),
  });

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <div ref={drop} className={className}>
        {children}
      </div>
    </Box>
  );
};

export default Dnd2Column;
