import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../codelist/store/codelistsSlice";
const _ = require("lodash");

export default function DatasourceJdbcForm() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const datasourceJdbcDatabaseTypeOptions =
    codelistData &&
    !_.isEmpty(codelistData) &&
    _.has(codelistData, CODELIST_CODES.COR_DATASOURCE_JDBC_DATABASE_TYPE_CODE)
      ? _.get(
          codelistData,
          CODELIST_CODES.COR_DATASOURCE_JDBC_DATABASE_TYPE_CODE
        )
      : [];

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.COR_DATASOURCE_JDBC_DATABASE_TYPE_CODE));
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            flexBasis: "60%",
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <NtaiTextField
            name="datasourceForm.jdbc.host"
            label="JDBC Host*"
            placeholder="127.0.0.1"
            rules={{ required: "Jdbc host is required" }}
          />
        </Box>

        <Box
          sx={{
            flexBasis: "40%",
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <NtaiTextField
            type="number"
            name="datasourceForm.jdbc.port"
            label="JDBC Port*"
            placeholder="5432"
            // defaultValue="5432"
            rules={{ required: "Jdbc port is required" }}
          />
        </Box>
      </Box>

      <NtaiTextField
        name="datasourceForm.jdbc.url"
        label="JDBC URL*"
        placeholder="JDBC URL"
        defaultValue="jdbc:postgresql://localhost:5432/ntai_mt?currentSchema=tenant1"
        rules={{ required: "Jdbc url is required" }}
      />

      <NtaiTextField
        name="datasourceForm.jdbc.userName"
        label="Database user*"
        placeholder="databaseuser"
        defaultValue="postgres"
        rules={{ required: "User name is required" }}
      />
      <NtaiTextField
        type="password"
        name="datasourceForm.jdbc.password"
        label="Database password*"
        placeholder="*********"
        defaultValue="manager123"
        rules={{ required: "Password is required" }}
      />

      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiTextField
          name="datasourceForm.jdbc.databaseName"
          label="JDBC Database*"
          placeholder="JDBC Database"
          defaultValue="database_name"
          rules={{ required: "Jdbc database name is required" }}
        />

        <NtaiTextField
          name="datasourceForm.jdbc.defaultSchema"
          label="JDBC Schema"
          placeholder="JDBC Schema"
        />
      </Box>

      <NtaiSelectField
        label="Database*"
        name="datasourceForm.jdbc.databaseTypeCode"
        options={datasourceJdbcDatabaseTypeOptions}
        rules={{ required: "Database selection is required" }}
      />

      {/* <NtaiSwitchField name="datasourceForm.jdbc.isSsl" label="SSL?" /> */}
    </Box>
  );
}
