import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import ChildComponent from "./ChildComponent";

export default function ParentComponent() {
  const theme = useTheme();
  const [counter, setCounter] = useState(1);
  function handleClick() {
    setCounter(counter + 1);
  }
  return (
    <Box
      sx={{
        display: "flex",
        jutifyContent: "center",
        alignItems: "center",
        p: "14px",
      }}
    >
      <Box sx={{ flexBasis: "50%", display: "flex" }}>
        <Button onClick={() => handleClick()}>CLICK</Button>
        <Typography variant="body1">{counter}</Typography>
      </Box>

      <Box
        sx={{
          border: theme.general.border1,
          flexBasis: "50%",
          display: "flex",
        }}
      >
        <ChildComponent counter={counter} />
      </Box>
    </Box>
  );
}
