import { Box } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import NtaiXTab from "./NtaiXTab";
import NtaiXTabNested1 from "./NtaiXTabNested1";
import NtaiXTabNested2 from "./NtaiXTabNested2";
import NtaiXTabParallel from "./NtaiXTabParallel";

const metadata = {
  header: {
    1: [
      {
        label: "Drug",
        rowSpan: 4,
        colSpan: 1,
      },
      {
        label: "Event",
        rowSpan: 4,
        colSpan: 1,
      },
      {
        label: "Serious",
        rowSpan: 1,
        colSpan: 4,
        textAlign: "center",
      },
    ],
    2: [
      {
        label: "Yes",
        textAlign: "center",
        colSpan: 2,
      },
      {
        label: "No",
        textAlign: "center",
        colSpan: 2,
      },
    ],
    3: [
      {
        label: "Countries",
        textAlign: "center",
        colSpan: 2,
      },
      {
        label: "Countries",
        textAlign: "center",
        colSpan: 2,
      },
    ],
    4: [
      {
        label: "US",
        textAlign: "center",
      },
      {
        label: "UK",
        textAlign: "center",
      },
      {
        label: "US",
        textAlign: "center",
      },
      {
        label: "UK",
        textAlign: "center",
      },
    ],
  },
  linkFields: [
    {
      uuId: "f1",
      esFieldPath: "f1.path",
    },
    {
      uuId: "f2",
      esFieldPath: "f2.path",
    },
    {
      uuId: "f3",
      esFieldPath: "f3.path",
    },
    {
      uuId: "f4",
      esFieldPath: "f4.path",
    },
  ],
};

const rows = {
  1: [
    {
      value: "Aspirin",
    },
    {
      value: "Fibrosis",
    },
    {
      value: 73,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
    {
      value: 67,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
    {
      value: 9,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
    {
      value: 126,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
  ],
  2: [
    {
      value: "Aspirin",
    },
    {
      value: "Acute Abdomen",
    },
    {
      value: 6,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
    {
      value: 98,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
    {
      value: 12,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
    {
      value: 8,
      linkParams: ["a", "b", "c", "d"],
      textAlign: "center",
    },
  ],
};

export default function NtaiXTable0() {
  return (
    <NtaiTabs>
      <Box label="Nested Type">
        <NtaiXTab metadata={metadata} rows={rows} />
      </Box>
      <Box label="Nested Type 1">
        <NtaiXTabNested1 />
      </Box>

      <Box label="Nested Type 2">
        <NtaiXTabNested2 />
      </Box>

      <Box label="Parallel">
        <NtaiXTabParallel />
      </Box>
    </NtaiTabs>
  );
}
