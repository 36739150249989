import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlertRecords = createAsyncThunk(
  "alertRecords/getAlertRecords",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, activityId } = values;

      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/activities/${activityId}/records`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertPaginatedRecords = createAsyncThunk(
  "alertRecords/getAlertPaginatedRecords",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, activityId, formData } = values;

      const response = await server.put(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/activities/${activityId}/records`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertRecord = createAsyncThunk(
  "alertRecords/getAlertRecord",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, activityId, uuId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/activities/${activityId}/records/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const assignUserToSingleAlertRecord = createAsyncThunk(
  "alertRecords/assignUserToSingleAlertRecord",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, activityId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/activities/${activityId}/records/assign-users`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const assignUsersToAlertRecords = createAsyncThunk(
  "alertRecords/assignUsersToAlertRecords",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, activityId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/activities/${activityId}/records/assign-users`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAlertRecord = createAsyncThunk(
  "alertRecords/deleteAlertRecord",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/cdo/alertRecords/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertRecordWorklflowActivityTransitions = createAsyncThunk(
  "alertRecords/getAlertRecordWorklflowActivityTransitions",
  async (activityId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/admin/wfdefinitions/*/versions/*/activities/${activityId}/transitions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const completeAlertRecordsActivity = createAsyncThunk(
  "alertRecords/completeAlertRecordsActivity",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, activityId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/activities/${activityId}/records/complete-activity`,
        formData
      );
      return {
        selectedRecords: formData["alertRecordUuIds"],
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertRecordsSlice = createSlice({
  name: "alertRecords",
  initialState: {
    data: {},
    activeId: null,
    status: null,
    wfTransitionsData: {},
    metadata: null,
    pageData: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertRecords.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAlertRecords" };
    },
    [getAlertRecords.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecords",
        message: action.payload.message,
      };
    },
    [getAlertPaginatedRecords.fulfilled]: (state, action) => {
      // state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAlertPaginatedRecords" };
      state.metadata = action.payload.metadata;
      state.data = { ..._.mapKeys(action.payload.data, "uuId") };
    },
    [getAlertPaginatedRecords.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertPaginatedRecords",
        message: action.payload.message,
      };
    },
    [getAlertRecord.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getAlertRecord" };
    },
    [getAlertRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecord",
        message: action.payload.message,
      };
    },
    [completeAlertRecordsActivity.fulfilled]: (state, action) => {
      const completedRecords =
        _.isArray(action.payload.selectedRecords) &&
        action.payload.selectedRecords.length > 0
          ? action.payload.selectedRecords
          : Object.keys[state.data];

      state.data = _.omit(state.data, completedRecords);
      state.status = {
        result: "success",
        method: "completeAlertRecordsActivity",
      };
    },
    [completeAlertRecordsActivity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "completeAlertRecordsActivity",
        message: action.payload.message,
      };
    },
    [assignUsersToAlertRecords.fulfilled]: (state, action) => {
      // state.data = {
      //   ...state.data,
      //   [action.payload.uuId]: action.payload,
      // };
      state.data = { ...state.data, ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "assignUsersToAlertRecords" };
    },
    [assignUsersToAlertRecords.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "assignUsersToAlertRecords",
        message: action.payload.message,
      };
    },

    [assignUserToSingleAlertRecord.fulfilled]: (state, action) => {
      // state.data = {
      //   ...state.data,
      //   [action.payload.uuId]: action.payload,
      // };
      state.data = { ...state.data, ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "assignUserToSingleAlertRecord",
      };
    },
    [assignUserToSingleAlertRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "assignUserToSingleAlertRecord",
        message: action.payload.message,
      };
    },

    [deleteAlertRecord.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteAlertRecord" };
    },
    [deleteAlertRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAlertRecord",
        message: action.payload.message,
      };
    },

    [getAlertRecordWorklflowActivityTransitions.fulfilled]: (state, action) => {
      state.wfTransitionsData = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getAlertRecordWorklflowActivityTransitions",
      };
    },
    [getAlertRecordWorklflowActivityTransitions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordWorklflowActivityTransitions",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = alertRecordsSlice.actions;

export default alertRecordsSlice.reducer;
