import { useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import SourceCatalogAssetInfo from "./info/SourceCatalogAssetInfo";
const _ = require("lodash");

export default function SourceCatalogAssetGeneral({
  handleSave,
  handleCancel,
  assetUuId,
  assetTypeCode,
}) {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);
  const { getValues, reset, setValue } = useFormContext();

  const dataMap = useSelector(
    (state) => state.sources.sourceCatalogAssetsSlice.data
  );

  const formData = dataMap && !_.isEmpty(dataMap) ? dataMap[assetUuId] : {};

  function onHandleEdit() {
    setEditMode(true);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceCatalogAssetGeneralForm")
    );

    handleSave({
      uuId: formData && !_.isEmpty(formData) ? formData["uuId"] : null,
      assetUuId: assetUuId,
      typeCode: assetTypeCode,
      ...sanitizedFormData,
    });
  }

  useEffect(() => {
    reset({ sourceCatalogAssetGeneralForm: formData });
  }, [assetUuId]);

  return (
    <NtaiFormPanel
      width="100%"
      header="General Information"
      subheader="General Information means any form of recorded information that does not relate to personal data or personally identifiable information"
      handleSave={editMode ? onHandleSave : onHandleEdit}
      saveLabel={editMode ? "SAVE" : "EDIT"}
      handleCancel={handleCancel}
    >
      {editMode && (
        <>
          <NtaiTextField
            width="50%"
            name="sourceCatalogAssetGeneralForm.userLabel"
            label="Friendly Name"
          />
          <NtaiTextField
            multiline
            minRows={3}
            maxRows={3}
            name="sourceCatalogAssetGeneralForm.moreInformation"
            label="More Information"
          />
        </>
      )}
      {!editMode && (
        <>
          <NtaiLabelValue1
            label="Friendly Name"
            value={
              _.has(formData, "uuId") && formData["uuId"]
                ? formData["userLabel"]
                : ""
            }
          />
          <NtaiLabelValue1
            label="More Information"
            value={
              _.has(formData, "uuId") && formData["uuId"]
                ? formData["moreInformation"]
                : ""
            }
          />
        </>
      )}

      <SourceCatalogAssetInfo
        assetUuId={assetUuId}
        assetTypeCode={assetTypeCode}
      />
    </NtaiFormPanel>
  );
}
