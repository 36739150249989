import React from "react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "./aggrid.css";
import { makeStyles } from "@mui/styles";

const _ = require("lodash");

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    fontSize: "12px",
    // height: "12px",
    backgroundColor: "white",
  },
});

const dynamicCellStyle = (params, rules) => {
  console.log("params value and range: ", params.value, rules);

  let returnValue = {};

  for (let i = 0; i < rules.length; i++) {
    let rule = rules[i];
    if (
      params.value >= _.get(rule, "range")[0] &&
      params.value <= _.get(rule, "range")[1]
    ) {
      console.log("hey..");
      returnValue = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: _.get(rule, "color"),
        textAlign: "center",
        backgroundColor: _.get(rule, "background"),
      };
      break;
    } else {
      console.log("hey..no");
      returnValue = {
        display: "flex",
        alignItems: "center",
      };
      break;
    }
  }

  return returnValue;
};

export default function DummyGrid(props) {
  const { data, metadata } = props;
  const classes = useStyles();

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "700px",
        width: "100%",
      }}
    >
      <AgGridReact
        rowData={data}
        sideBar={false}
        reactUi={true}
        groupHeaderHeight="24"
        headerHeight="24"
        rowHeight="24"
        rowStyle={{ fontSize: "12px" }}
        fontSize="12"
        style={{ height: "100%", width: "100%" }}
      >
        {_.get(metadata, "single_columns").map((col, index) => {
          let sparkData = {};

          if (Array.isArray(_.get(data[0], col))) {
            console.log("IS ARRAY");
            sparkData = {
              cellRenderer: "agSparklineCellRenderer",
              cellRendererParams: {
                sparklineOptions: {
                  type: "column",
                },
              },
            };
          }
          return (
            <AgGridColumn
              sortable={true}
              resizable={true}
              filter={true}
              field={col}
              cellStyle={{
                display: "flex",
                alignItems: "center",
              }}
              {...sparkData}
            ></AgGridColumn>
          );
        })}

        {_.get(metadata, "column_groups").map((col_group, index) => {
          return (
            <AgGridColumn headerName={col_group} headerClass={classes.header}>
              {_.get(metadata, "group_columns").map((group_col, index) => {
                return (
                  <AgGridColumn
                    resizable={true}
                    field={_.get(group_col, "label")}
                    type="centerAligned"
                    cellStyle={(params) =>
                      dynamicCellStyle(params, _.get(group_col, "rules"))
                    }
                    valueGetter={(params) => {
                      return _.get(
                        _.get(_.get(params, "data"), col_group),
                        _.get(group_col, "valueGetterField")
                      );
                    }}
                  />
                );
              })}
            </AgGridColumn>
          );
        })}
      </AgGridReact>
    </div>
  );
}
