import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDatasourceJdbcMetadata = createAsyncThunk(
  "datasourceJdbcMetadata/getDatasourceJdbcMetadata",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/datasources/${uuId}/metadata`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const datasourceJdbcMetadataSlice = createSlice({
  name: "datasources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDatasourceJdbcMetadata.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.datasourceUuId]: action.payload,
      };
      state.status = { result: "success", method: "getDatasourceJdbcMetadata" };
    },
    [getDatasourceJdbcMetadata.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDatasourceJdbcMetadata",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = datasourceJdbcMetadataSlice.actions;

export default datasourceJdbcMetadataSlice.reducer;
