import { MoreVert, Settings } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  listSubheaderClasses,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export default function NtaiEmptyPanel(props) {
  const { width, height, children, border, handleClick } = props;

  const theme = useTheme();
  return (
    <Card
      elevation={0}
      onClick={() => handleClick}
      sx={{
        // marginTop: "8px",
        width: width ? width : null,
        height: height ? height : null,
        border: border && `1px solid ${theme.colors.alpha.black[20]}`,
        "&:hover": {
          background: theme.colors.secondary.lighter,
          boxShadow: theme.colors.shadows.cardSm,
          cursor: "pointer",
        },
      }}
    >
      <CardContent
        sx={{ display: "flex", height: "100%", justifyContent: "center" }}
      >
        {children}
      </CardContent>
    </Card>
  );
}
