import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import { tooltipConfig } from "../chartConfigs";
import { Box, useTheme } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

export default function NtaiWordCloudChart(props) {
  const theme = useTheme();
  const { widgetId, data, valueField, categoryField, handleClick, metadata } =
    props;

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  useLayoutEffect(() => {
    let chart = am4core.create(
      "wordcloud".concat("-", widgetId),
      am4plugins_wordCloud.WordCloud
    );

    // chart.cursor = new am4charts.XYCursor();
    // chart.responsive.enabled = true;

    // // zoom icon
    // chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    // chart.zoomOutButton.background.fill = am4core.color("#ffffff");
    // chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    // chart.zoomOutButton.icon.strokeWidth = 2;
    // chart.zoomOutButton.background.states.getKey("hover").properties.fill =
    //   am4core.color("#eeeeee");

    // chart.zoomOutButton.icon.disabled = true;
    // let zoomImage = chart.zoomOutButton.createChild(am4core.Image);
    // zoomImage.href = "/images/zoom-icon.png";
    // zoomImage.width = 15;
    // zoomImage.height = 15;
    // zoomImage.interactionsEnabled = false;

    let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());

    series.data = data;

    series.dataFields.word = "name";
    series.dataFields.value = "value";

    series.labels.template.events.on(
      "hit",
      function (ev) {
        const sourceWidgetFilterFields = [
          {
            sourceWidgetFieldUuId: Object.keys(
              _.get(metadataRef.current, "fields")
            )[0],
            fieldOperatorCode: 1,
            fieldValue: ev.target.currentText,
          },
        ];
        handleClick(sourceWidgetFilterFields);
      },
      this
    );

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"wordcloud".concat("-", widgetId)}
      sx={{ width: "100%", height: "100%" }}
    ></Box>
  );
}
