import React, { useEffect } from "react";
import { faGrid } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import {
  clearStatus,
  deleteSourceWidget,
  getSourceWidgets,
  updateSourceWidget,
} from "./store/sourceWidgetsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import SourceWidget from "./SourceWidget";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import { SourceViewContext } from "./SourceWidgetManagerWrapper";
import { useState } from "react";
import SourceWidgetLayout from "./layout/SourceWidgetLayout";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import { grey } from "@mui/material/colors";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import SourceWidgetCriteria from "./criteria/SourceWidgetCriteria";
import NtaiForm from "@ntai/components/forms/NtaiForm";
const _ = require("lodash");

export default function SourceWidgetList({ viewId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [layoutDialogOpen, setLayoutDialogOpen] = useState(false);
  const [criteriaDialogOpen, setCriteriaDialogOpen] = useState(false);
  const [confirmDeleteAllDialogOpen, setConfirmDeleteAllDialogOpen] =
    useState(false);
  const [confirmDeleteAll, setConfirmDeleteAll] = useState(false);
  const [currentWidgetId, setCurrentWidgetId] = useState();
  const { getValues, reset, setValue } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector((state) => state.sources.sourceWidgetsSlice.data);
  const status = useSelector(
    (state) => state.sources.sourceWidgetsSlice.status
  );
  const viewsDataMap = useSelector(
    (state) => state.sources.sourceViewsSlice.data
  );

  const viewDataLayout = _.get(viewsDataMap[viewId], "layout");

  const data = Object.values(dataMap);

  const { handleLayoutChange } = React.useContext(SourceViewContext);

  function handleCriteriaDialogOpen() {
    setCriteriaDialogOpen(true);
  }
  function handleCriteriaDialogClose() {
    setCurrentWidgetId(null);
    setCriteriaDialogOpen(false);
  }

  function handleWidgetAction(action, id) {
    if (action === "delete") handleDelete(id);
    if (action === "criteria") {
      setCurrentWidgetId(id);
      handleCriteriaDialogOpen();
    }
  }

  function handleDelete(widgetId) {
    dispatch(
      deleteSourceWidget({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        viewId: viewId,
        uuId: widgetId,
      })
    );
  }

  function handleDeleteAll() {
    if (data && _.isArray(data)) {
      data.forEach((o, i) => {
        handleDelete(o["uuId"]);
      });
    }
  }

  function handleSave(widgetId, formData) {
    dispatch(
      updateSourceWidget({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        viewId: viewId,
        uuId: widgetId,
        formData: formData,
      })
    );
  }

  // function handleLayoutChange(layout) {
  //   console.log("Layout changed to ", layout);
  // }

  function generateWidgetList() {
    let result = [];

    if (Array.isArray(data) && data.length > 0) {
      _.orderBy(data, ["dateCreated"], ["asc"]).forEach(
        (sourceWidget, index) => {
          result.push(
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
              }}
              key={index}
            >
              {/* <SourceWidget
                key={`widget-${index}`}
                sourceWidget={sourceWidget}
                handleSave={handleSave}
                handleDelete={handleDelete}
              /> */}

              <SourceWidget
                key={`widget-${index}`}
                disableEdit={true}
                sourceWidget={sourceWidget}
                handleSave={handleSave}
                headerActions={[
                  { value: "criteria", label: "Criteria" },
                  { value: "delete", label: "Delete" },
                ]}
                handleHeaderAction={handleWidgetAction}
                // handleDelete={handleDelete}
              />
            </Box>
          );
        }
      );
    }

    if (result.length === 0) {
      result.push(
        <Box
          key="widget-empty-id"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" fontWeight="700">
            No widgets found.
          </Typography>
          <Typography variant="subtitle1">
            Follow the steps to add widgets to the view
          </Typography>
        </Box>
      );
    }

    return result;
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceWidgets" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "updateSourceWidget"
    ) {
      dispatch(clearStatus());
      if (criteriaDialogOpen) {
        handleCriteriaDialogClose();
      }
    }

    if (
      status &&
      status.method === "deleteSourceWidget" &&
      status.result === "success"
    ) {
      setConfirmDeleteAllDialogOpen(false);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceWidgets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        viewId: viewId,
      })
    );
    dispatch(
      getCodes(CODELIST_CODES.SRC_SOURCE_VIEW_WIDGET_GRID_AGG_FUNC_CODELIST)
    );
  }, []);

  return (
    <React.Fragment>
      {loaded && data && _.isArray(data) && data.length === 0 && (
        <Box
          key="widget-empty-id"
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" fontWeight="700">
            No widgets found.
          </Typography>
          <Typography variant="subtitle1">
            Follow the steps to add widgets to the view
          </Typography>
        </Box>
      )}

      {loaded && data && _.isArray(data) && data.length > 0 && (
        <React.Fragment>
          <Box
            sx={{
              height: "44px",
              display: "flex",
              alignItems: "center",
              borderBottom: theme.general.border1,
              px: theme.spacing(2),

              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <FontAwesomeIcon icon={faGrid} color="secondary" />
              <Typography variant="h6">{`Available Widgets (${data.length})`}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <NtaiButton
                icon="grid"
                size="small"
                onClick={() => setLayoutDialogOpen(true)}
              >
                Change Layout
              </NtaiButton>
              <NtaiButton
                onClick={() => setConfirmDeleteAllDialogOpen(true)}
                icon="archive"
                size="small"
              >
                Delete All
              </NtaiButton>
            </Box>
          </Box>
          {_.isArray(data) && data.length > 0 && (
            <Box
              sx={{
                display: "flex",
                // gridTemplateColumns: "1fr 1fr 1fr 1fr",
                height: `calc(100% - 44px)`,
                width: "100%",
              }}
            >
              <Scrollbar>
                <Box
                  sx={{
                    p: theme.spacing(2),
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: theme.spacing(1),
                    width: "100%",
                  }}
                >
                  {_.orderBy(data, ["dateCreated"], ["asc"]).map(
                    (sourceWidget, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          width: "100%",
                        }}
                        key={index}
                      >
                        <SourceWidget
                          key={`widget-${index}`}
                          sourceWidget={sourceWidget}
                          handleSave={handleSave}
                          headerActions={[
                            {
                              value: "criteria",
                              label: "Criteria",
                              icon: "database",
                            },
                            {
                              value: "delete",
                              label: "Delete",
                              icon: "archive",
                            },
                          ]}
                          handleHeaderAction={handleWidgetAction}
                        />
                      </Box>
                    )
                  )}
                </Box>
              </Scrollbar>

              {/* <SourceWidgetLayout
              layout={viewDataLayout}
              generateWidgetList={generateWidgetList}
              handleLayoutChange={handleLayoutChange}
            /> */}
            </Box>
          )}
        </React.Fragment>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
      <NtaiDialog
        title="Adjust Layout"
        size="lg"
        handleDialogClose={() => setLayoutDialogOpen(false)}
        open={layoutDialogOpen}
      >
        <Box
          sx={{ display: "flex", height: "480px", justifyContent: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              background: grey[100],
            }}
          >
            <SourceWidgetLayout
              layout={viewDataLayout}
              generateWidgetList={generateWidgetList}
              handleLayoutChange={handleLayoutChange}
            />
          </Box>
        </Box>
      </NtaiDialog>
      <NtaiDialog
        title="Confirm delete"
        open={confirmDeleteAllDialogOpen}
        handleDialogClose={() => setConfirmDeleteAllDialogOpen(false)}
        size="sm"
      >
        <NtaiDialogFormPanel
          handleSave={handleDeleteAll}
          handleCancel={() => setConfirmDeleteAllDialogOpen(false)}
          subheader=""
          saveLabel="Yes"
        >
          <Typography>Are you sure you want to delete all widgets?</Typography>
        </NtaiDialogFormPanel>
      </NtaiDialog>
      <NtaiDialog
        title={`${
          currentWidgetId ? dataMap[currentWidgetId]["name"] : "Edit"
        } / Criteria`}
        size="md"
        open={criteriaDialogOpen}
        handleDialogClose={handleCriteriaDialogClose}
      >
        {currentWidgetId && (
          <NtaiForm>
            <SourceWidgetCriteria
              widgetId={currentWidgetId}
              widgetData={dataMap[currentWidgetId]}
              handleSave={handleSave}
              handleCancel={handleCriteriaDialogClose}
            />
          </NtaiForm>
        )}
      </NtaiDialog>
    </React.Fragment>
  );
}
