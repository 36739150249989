import WorkflowDefVersionCreate from "./versions/WorkflowDefVersionCreate";
import WorkflowDefVersionEdit from "./versions/WorkflowDefVersionEdit";
import WorkflowDefVersionList from "./versions/WorkflowDefVersionList";
import WorkflowDefList from "./WorkflowDefList";

const WorkflowDefConfig = {
  routes: [
    {
      path: "/admin/workflows",
      exact: true,
      component: WorkflowDefList,
    },
    {
      path: "/admin/workflows/:id/versions",
      exact: true,
      component: WorkflowDefVersionList,
    },
    {
      path: "/admin/workflows/:id/versions/create",
      exact: true,
      component: WorkflowDefVersionCreate,
    },
    {
      path: "/admin/workflows/:workflowdefid/versions/:id/edit",
      exact: true,
      component: WorkflowDefVersionEdit,
    },

    // {
    //   path: "/admin/users/create",
    //   exact: true,
    //   component: UserCreate,
    // },
    // {
    //   path: "/admin/users/:id/edit",
    //   exact: true,
    //   component: UserEdit,
    // },
  ],
};

export default WorkflowDefConfig;
