import { useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getObjectFields,
  clearStatus as clearFieldsStatus,
} from "../../field/store/objectFieldsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import {
  createOrUpdateObjectInfoGeneralField,
  getObjectInfoGeneralFields,
  clearStatus,
} from "./store/objectInfoGeneralFieldsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function ObjectInfoGeneralFields() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, reset, setValue } = useFormContext();

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);

  const fieldsDataMap = useSelector(
    (state) => state.core.objectFieldsSlice.data
  );

  const fieldsStatus = useSelector(
    (state) => state.core.objectFieldsSlice.status
  );

  const fieldsData =
    fieldsDataMap && !_.isEmpty(fieldsDataMap)
      ? Object.values(fieldsDataMap)
      : [];

  const fieldOptions = NtaiUtils.getSelectOptions(
    fieldsData,
    "uuId",
    "fieldLabel"
  );

  const data = useSelector(
    (state) => state.core.objectInfoGeneralFieldsSlice.data
  );

  const status = useSelector(
    (state) => state.core.objectInfoGeneralFieldsSlice.status
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectInfoGeneralFieldForm")
    );

    dispatch(
      createOrUpdateObjectInfoGeneralField({
        domainId: domainId,
        objectId: objectId,
        formData: sanitizedFormData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getObjectInfoGeneralFields" &&
      status.result === "success"
    ) {
      reset({
        objectInfoGeneralFieldForm: data && !_.isEmpty(data) ? data : null,
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      fieldsStatus &&
      fieldsStatus.method === "getObjectFields" &&
      fieldsStatus.result === "success"
    ) {
      dispatch(
        getObjectInfoGeneralFields({
          domainId: domainId,
          objectId: objectId,
        })
      );
      dispatch(clearFieldsStatus());
    }
  }, [fieldsStatus]);

  useEffect(() => {
    dispatch(
      getObjectFields({
        domainId: domainId,
        objectId: objectId,
      })
    );
  }, []);

  return (
    <NtaiPanel
      header="General Display"
      subheader="More informatjon on display"
      handleSave={onHandleSave}
    >
      <NtaiSelectField
        name="objectInfoGeneralFieldForm.primaryField1UuId"
        options={fieldOptions}
        label="Primary Field 1"
      />

      <NtaiSelectField
        name="objectInfoGeneralFieldForm.primaryField2UuId"
        options={fieldOptions}
        label="Primary Field 2"
      />
      <NtaiSelectField
        name="objectInfoGeneralFieldForm.secondaryField1UuId"
        options={fieldOptions}
        label="Secondary Field 1"
      />
      <NtaiSelectField
        name="objectInfoGeneralFieldForm.secondaryField2UuId"
        options={fieldOptions}
        label="Secondary Field 2"
      />
    </NtaiPanel>
  );
}
