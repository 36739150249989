import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "../chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

export default function NtaiBarVerticalSeriesChart(props) {
  const { widgetId, data, categoryField, valueField, handleClick, metadata } =
    props;
  const theme = useTheme();

  React.useLayoutEffect(() => {
    let chart = am4core.create(
      "bar-vertical".concat("-", widgetId),
      am4charts.XYChart
    );

    chart.data = data;
    // chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";
    chart.cursor.maxTooltipDistance = 1;
    chart.responsive.enabled = true;

    chart.colors.step = 2;

    // zoom icon
    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#ffffff");
    chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#eeeeee");

    chart.zoomOutButton.icon.disabled = true;
    let zoomImage = chart.zoomOutButton.createChild(am4core.Image);
    zoomImage.href = "/images/zoom-icon.png";
    zoomImage.width = 15;
    zoomImage.height = 15;
    zoomImage.interactionsEnabled = false;

    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";
    // chart.legend.contentAlign = "center";
    // chart.legend.paddingBottom = 20;
    // chart.legend.labels.template.maxWidth = 95;
    // chart.legend.labels.template.fontSize = 10;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = categoryField;
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;
    xAxis.cursorTooltipEnabled = false;

    let label = xAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 120;
    label.fontSize = 10;
    label.truncate = true;

    let axisTooltip = xAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 10;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    let valueAxisLabel = yAxis.renderer.labels.template;
    valueAxisLabel.fontSize = 10;
    valueAxisLabel.rotation = 270;

    let valueAxisTooltip = yAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#767676");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.dy = 5;
    valueAxisTooltip.dx = 0;
    valueAxisTooltip.label.fill = am4core.color("#fff");
    valueAxisTooltip.label.fontSize = 10;

    yAxis.min = 0;

    function createSeries(value, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = categoryField;
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;
      series.columns.template.stroke = lighten(
        theme.colors.primary.lighter,
        0.9
      );

      series.tooltip.label.fontSize = tooltipConfig.labelFontSize;

      const colRand = NtaiUtils.getRandomFloat(0.1, 0.7);

      series.columns.template.fill = am4core.color(
        lighten(theme.colors.chart.lighter, colRand)
      );

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = -25;

      bullet.adapter.add("rotation", function (rotation, target) {
        return 270;
      });

      bullet.label.text = "{name}";
      bullet.label.truncate = false;
      bullet.label.fill = am4core.color(grey[700]);
      bullet.label.fontSize = 8;

      series.columns.template.events.on(
        "hit",
        function (ev) {
          const sourceWidgetFilterFields = [
            {
              sourceFieldUuId: _.get(
                _.filter(Object.values(_.get(metadata, "fields")), {
                  fieldOrder: 1,
                })[0],
                "field.uuId"
              ),
              fieldOperatorCode: 1,
              fieldValue: ev.target.dataItem.categories.categoryX,
            },
            {
              sourceFieldUuId: _.get(
                _.filter(Object.values(_.get(metadata, "fields")), {
                  fieldOrder: 2,
                })[0],
                "field.uuId"
              ),
              fieldOperatorCode: 1,
              fieldValue: ev.target.dataItem.component.dataFields.valueY,
            },
          ];

          handleClick(sourceWidgetFilterFields, ev);
        },
        this
      );

      return series;
    }

    metadata["subCategoryValues"].forEach((smd) => {
      createSeries(smd.value, smd.label);
    });

    function arrangeColumns() {
      let series = chart.series.getIndex(0);

      let w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      {Array.isArray(data) && data.length > 0 && (
        <Box
          id={"bar-vertical".concat("-", widgetId)}
          sx={{ width: "100%", height: "100%" }}
        ></Box>
      )}

      {Array.isArray(data) && data.length === 0 && <NtaiChartEmptyMessage />}
    </>
  );
}
