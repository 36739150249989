import { FormControl, Slider, TextField, Typography } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiFilterBox from "./NtaiFilterBox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import NtaiInputErrorMessage from "../NtaiInputErrorMessage";
const _ = require("lodash");

function milliSecondsToDate(v) {
  const d = new Date(v);
  return d.toLocaleDateString();
}

const NtaiMuiDateRangeField = (props) => {
  const { name, value, label, handleUpdateRange } = props;
  const [localValue, setLocalValue] = React.useState();

  function handleChangeDate(d) {
    setLocalValue(d);
    handleUpdateRange(name, d);
  }

  React.useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <FormControl>
      <Typography variant="body2" fontWeight="300">
        {label}
      </Typography>
      <DatePicker
        name={name}
        value={localValue || ""}
        renderInput={(props1) => <TextField size="small" {...props1} />}
        onChange={(date) => handleChangeDate(date)}
      />
    </FormControl>
  );
};

function NtaiFilterDateRangePicker(props) {
  const {
    formState: { errors },
    getValues,
    control,
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  const { label, from, to } = props;

  function handleUpdateRange(rangeName, d) {
    const currentValues = getValues(props.name);
    let valuesCopy = [null, null];

    if (currentValues && _.isArray(currentValues) && currentValues.length === 2)
      valuesCopy = _.cloneDeep(currentValues);
    if (rangeName.includes("from")) {
      valuesCopy[0] = d?.valueOf();
    } else {
      valuesCopy[1] = d?.valueOf();
    }

    if (valuesCopy[0] && valuesCopy[1] && valuesCopy[0] > valuesCopy[1]) {
      setError(props.name, {
        type: "custom",
        message: "Invalid date range",
      });
    } else {
      clearErrors(props.name);
      setValue(props.name, valuesCopy);
    }
  }

  return (
    <NtaiFilterBox>
      <Typography variant="subtitle2" fontWeight="700">
        {label}
      </Typography>
      <Controller
        render={({ ref, field: { onChange, name, value } }) => {
          return (
            <React.Fragment>
              <NtaiMuiDateRangeField
                label="From"
                name={name + ".from"}
                // value={from}
                value={_.isArray(value) && value[0] ? value[0] : ""}
                handleUpdateRange={handleUpdateRange}
              />
              <NtaiInputErrorMessage name={name} errors={errors} />
              <NtaiMuiDateRangeField
                label="To"
                name={name + ".to"}
                value={_.isArray(value) && value[1] ? value[1] : ""}
                handleUpdateRange={handleUpdateRange}
              />
              <NtaiInputErrorMessage name={name} errors={errors} />
            </React.Fragment>
          );
        }}
        control={control}
        defaultValue={null}
        {...props}
      />
    </NtaiFilterBox>
  );
}

export default NtaiFilterDateRangePicker;
