import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAwsClusters = createAsyncThunk(
  "awsClusters/getAwsClusters",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/aws-emr-clusters");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAwsCluster = createAsyncThunk(
  "awsClusters/getAwsCluster",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/aws-emr-clusters/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAwsCluster = createAsyncThunk(
  "awsClusters/createAwsCluster",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/aws-emr-clusters", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const terminateAwsCluster = createAsyncThunk(
  "awsClusters/terminateAwsCluster",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/aws-emr-clusters/${uuId}/terminate`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAwsCluster = createAsyncThunk(
  "awsClusters/updateAwsCluster",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/aws-emr-clusters/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAwsCluster = createAsyncThunk(
  "awsClusters/deleteAwsCluster",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/aws-emr-clusters/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const awsClustersSlice = createSlice({
  name: "awsClusters",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAwsClusters.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAwsClusters" };
    },
    [getAwsClusters.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAwsClusters",
        message: action.payload.message,
      };
    },
    [getAwsCluster.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getAwsCluster" };
    },
    [getAwsCluster.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAwsCluster",
        message: action.payload.message,
      };
    },
    [createAwsCluster.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createAwsCluster" };
    },
    [createAwsCluster.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createAwsCluster",
        message: action.payload.message,
      };
    },
    [terminateAwsCluster.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "terminateAwsCluster" };
    },
    [terminateAwsCluster.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "terminateAwsCluster",
        message: action.payload.message,
      };
    },
    [updateAwsCluster.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateAwsCluster" };
    },
    [updateAwsCluster.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateAwsCluster",
        message: action.payload.message,
      };
    },
    [deleteAwsCluster.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteAwsCluster" };
    },
    [deleteAwsCluster.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAwsCluster",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = awsClustersSlice.actions;

export default awsClustersSlice.reducer;
