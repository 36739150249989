import ThesaurusLoadList from "./load/ThesaurusLoadList";
import ThesaurusCreate from "./ThesaurusCreate";
import ThesaurusEdit from "./ThesaurusEdit";
import ThesaurusList from "./ThesaurusList";

const ThesaurusConfig = {
  routes: [
    {
      path: "/library/thesauruses",
      exact: true,
      component: ThesaurusList,
    },
    {
      path: "/library/thesauruses/create",
      exact: true,
      component: ThesaurusCreate,
    },
    {
      path: "/library/thesauruses/:id/edit",
      exact: true,
      component: ThesaurusEdit,
    },
    {
      path: "/library/thesauruses/:thesaurusId/import",
      exact: true,
      component: ThesaurusLoadList,
    },
  ],
};

export default ThesaurusConfig;
