import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

// am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

export default function NtaiHorizontalStackedChart(props) {
  const theme = useTheme();

  const { data, seriesData } = props;

  useLayoutEffect(() => {
    let chart = am4core.create("horizontalstackedchart", am4charts.XYChart);

    chart.data = data;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

    // let series = chart.series.push(new am4charts.ColumnSeries());
    // series.dataFields.valueX = "value";
    // series.dataFields.categoryY = "category";

    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "category";
      series.name = name;
      series.sequencedInterpolation = true;
      series.columns.template.stroke = lighten(theme.colors.chart.lighter, 0.9);

      if (field === "inactive")
        series.columns.template.fill = am4core.color(grey[100]);

      //   let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      //   valueLabel.label.text = "{category}";
      //   valueLabel.label.fontSize = 12;
      //   valueLabel.label.horizontalCenter = "right";

      series.stacked = true;
    }

    seriesData.forEach((s, i) => {
      createSeries(s, s);
    });

    return () => {
      chart.dispose();
    };
  }, [data, seriesData]);

  return (
    <>
      <Box
        id={"horizontalstackedchart"}
        sx={{ width: "100%", height: "100%" }}
      ></Box>
    </>
  );
}
