import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getOversightEntity,
} from "./store/oversightEntitiesSlice";
import { useEffect, useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { OversightContext } from "../../Oversight";
import OversightEntityOverviewWrapper from "./overview/OversightEntityOverviewWrapper";
import OversightEntityTopics from "./topics/OversightEntityTopics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarChart,
  faCircleStar,
  faHome,
  faShapes,
  faStar,
  faUserChart,
} from "@fortawesome/pro-light-svg-icons";

import { OversightStyledBreadcrumb } from "../shared/OversightUtil";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import OversightTopicContent from "./topics/content/OversightTopicContent";
const _ = require("lodash");

export default function OversightEntity() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  // const [subSection, setSubSection] = useState("overview");
  const [entityNavNode, setEntityNavNode] = useState();
  const [topicNavNode, setTopicNavNode] = useState();

  const {
    navNode,
    setNavNode,
    oversightTopicId,
    section,
    setReloadOversightTopicSummary,
    subSection,
    setSubSection,
  } = React.useContext(OversightContext);

  // console.log("Entity summary nav node: ", navNode);
  // console.log("Local Nav Nodes: ", navNode);
  // console.log("section and sub: ", section, subSection);

  const dataUuId =
    _.has(navNode, "type") && _.get(navNode, "type") === "ENTITY"
      ? _.get(navNode, "uuId")
      : null;

  const data = useSelector(
    (state) => state.oversight.oversightEntitiesSlice.data
  );
  const status = useSelector(
    (state) => state.oversight.oversightEntitiesSlice.status
  );

  function handleGoBack() {
    // setNavNode(navNode["parentUuId"]);
    // OversightUtil;
  }

  function handleSubSectionChange(e, val) {
    setSubSection(val[0]);
    if (val[0] === "overview") setNavNode(entityNavNode);
  }

  function handleTopicSelect(topicNavNode) {
    setSubSection("topic-details");
    //setTopicNavNode(topicNavNode);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getOversightEntity"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (dataUuId && dataUuId.length > 0) {
      setEntityNavNode(navNode);
      setTopicNavNode(null);
      dispatch(
        getOversightEntity({
          objectUuId: _.get(navNode, "parentUuId"),
          dataUuId: dataUuId,
        })
      );
    }
  }, [dataUuId]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: theme.spacing(2),
            display: "flex",
            height: "72px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(0.5),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              {/* <IconButton sx={{ width: 24, height: 24 }}>
                <FontAwesomeIcon size="2xs" icon={faArrowLeft} />
              </IconButton> */}
              <Typography variant="h4" fontWeight="700">
                {entityNavNode && entityNavNode["name"]}
              </Typography>
              <Typography variant="h5" fontWeight="500">
                | {subSection === "overview" ? `Overview` : `All Topics`}
              </Typography>
              {navNode &&
                ["OBJECT_TOPIC", "DATA_TOPIC"].includes(navNode["type"]) && (
                  <Typography variant="h5" fontWeight="500">
                    | {navNode["name"]}
                  </Typography>
                )}
            </Box>

            <Breadcrumbs aria-label="breadcrumb">
              <OversightStyledBreadcrumb
                component="a"
                href="#"
                label="All"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <OversightStyledBreadcrumb
                component="a"
                href="#"
                label="Products"
                icon={<FontAwesomeIcon icon={faShapes} />}
              />
              <OversightStyledBreadcrumb
                component="a"
                href="#"
                label={entityNavNode && entityNavNode["name"]}
                icon={<FontAwesomeIcon icon={faShapes} />}
              />
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <ToggleButtonGroup size="small" onChange={handleSubSectionChange}>
              <ToggleButton
                value="overview"
                sx={{
                  display: "flex",
                  fontSize: "11px",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                selected={subSection === "overview" ? true : false}
              >
                <FontAwesomeIcon icon={faUserChart} />
              </ToggleButton>
              <ToggleButton
                value="topics"
                sx={{
                  display: "flex",
                  fontSize: "11px",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                selected={
                  ["topics", "topic-details"].includes(subSection)
                    ? true
                    : false
                }
              >
                <FontAwesomeIcon icon={faCircleStar} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: `calc(100% - 72px)`,
            flexDirection: "column",
            width: "100%",
          }}
        >
          {subSection === "overview" && <OversightEntityOverviewWrapper />}
          {subSection === "topics" && (
            <OversightEntityTopics handleTopicSelect={handleTopicSelect} />
          )}
          {subSection === "topic-details" && <OversightTopicContent />}
        </Box>
      </Box>
    </React.Fragment>
  );
}
