import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsStatus,
} from "../../../../store/sourcesSlice";
import {
  getSourceDerivedFields,
  clearStatus as clearDerivedFieldsStatus,
} from "../../../../derivedfield/store/sourceDerivedFieldsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { updateSourceRecordListing } from "../../store/sourceRecordListingsSlice";
import NtaiDnd3 from "@ntai/components/dnd3/NtaiDnd3";
import {
  faClose,
  faFillDrip,
  faPaintBrush,
  faPalette,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SourceRecordListingTableFieldColorList from "./field-color/SourceRecordListingTableFieldColorList";
const _ = require("lodash");

function transformSelectedFields(fields) {
  let result = [];
  if (_.isArray(fields)) {
    _.orderBy(fields, ["fieldOrder"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.sourceFieldUuId,
        text:
          f.sourceDerivedFieldFg !== 1
            ? f.sourceField.label
            : f.sourceDerivedField.label,
      });
    });
  }

  return result;
}

function transformAvailableFields(allFields, selectedFields) {
  const revisedFields = _.filter(
    _.orderBy(allFields, ["label"], ["asc"]),
    function (f) {
      if (_.filter(selectedFields, { uuId: f.uuId }).length === 0) return true;
    }
  );

  return revisedFields;
}

export default function SourceRecordListingTableFields() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [colorDialogOpen, setColorDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState();

  const { getValues, setValue, reset } = useFormContext();

  const [availableFieldsLoaded, setAvailableFieldsLoaded] = useState(false);
  const [selectedFieldsLoaded, setSelectedFieldsLoaded] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const listingId = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.activeId
  );

  const listingDataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const listingData = _.get(listingDataMap, listingId);

  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    uuId: listingId,
  };

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const statusSourceFields = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const statusDerivedFields = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFields = Object.values(sourceFieldsDataMap).map((f) => {
    return {
      uuId: f.uuId,
      name: f.name,
      label: f.label,
    };
  });

  const sourceDerivedFields = Object.values(sourceDerivedFieldsDataMap).map(
    (f) => {
      return {
        uuId: f.uuId,
        name: f.name,
        label: f.label,
      };
    }
  );

  const allFieldsData = [...sourceFields, ...sourceDerivedFields];

  function handleColorDialogOpen() {
    setColorDialogOpen(true);
  }

  function handleColorDialogClose() {
    setColorDialogOpen(false);
    setCurrentId(null);
  }

  function handleReorder(items) {
    const reorderedSourceRecordListingFields = items.map((f, i) => {
      return {
        sourceFieldUuId: f.uuId,
        fieldOrder: i + 1,
      };
    });

    const selectedSourceRecordListingFields = selectedFields.map((f, i) => {
      return {
        sourceFieldUuId: f.uuId,
        fieldOrder: i + 1,
      };
    });

    if (
      !_.isEqual(
        reorderedSourceRecordListingFields,
        selectedSourceRecordListingFields
      )
    ) {
      handleUpdate(reorderedSourceRecordListingFields);
    }
  }

  function handleAddRemove(action, fieldUuId) {
    let sourceRecordListingFields = [];
    if (action === "add") {
      sourceRecordListingFields = [
        ..._.orderBy(selectedFields, ["fieldOrder"], ["asc"]).map((f, i) => {
          return {
            sourceFieldUuId: f.uuId,
            fieldOrder: i + 1,
          };
        }),
        {
          fieldOrder: selectedFields ? selectedFields.length + 1 : 1,
          sourceFieldUuId: fieldUuId,
        },
      ];
    } else if (action === "remove") {
      const filteredSourceRecordListingFields = _.filter(
        selectedFields,
        function (f) {
          if (f.uuId !== fieldUuId) return true;
        }
      );

      sourceRecordListingFields = filteredSourceRecordListingFields.map(
        (f, i) => {
          return {
            sourceFieldUuId: f.uuId,
            fieldOrder: i + 1,
          };
        }
      );
    }

    handleUpdate(sourceRecordListingFields);
  }

  function handleSecondaryAction(action, fieldUuId) {
    if (action === "remove") handleAddRemove("remove", fieldUuId);
    if (action === "color") {
      const sourceRecordListingFieldUuId = _.filter(
        _.get(listingData, "sourceRecordListingFields"),
        {
          sourceFieldUuId: fieldUuId,
        }
      )[0]["uuId"];

      setCurrentId(sourceRecordListingFieldUuId);
      handleColorDialogOpen();
    }
  }

  function handleUpdate(sourceRecordListingFields) {
    dispatch(
      updateSourceRecordListing({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: listingId,
        formData: {
          typeCode: 1,
          updateTypeFg: 2,
          sourceRecordListingFields: sourceRecordListingFields,
        },
      })
    );
  }

  useEffect(() => {
    const tmpSelectedFields = transformSelectedFields(
      _.get(listingData, "sourceRecordListingFields")
    );

    setSelectedFields([...tmpSelectedFields]);
  }, [listingData]);

  useEffect(() => {
    if (
      statusSourceFields &&
      statusDerivedFields &&
      statusSourceFields.result === "success" &&
      statusSourceFields.method === "getSourceFields" &&
      statusDerivedFields.result === "success" &&
      statusDerivedFields.method === "getSourceDerivedFields"
    ) {
      const tmpSelectedFields = transformSelectedFields(
        _.get(listingData, "sourceRecordListingFields")
      );

      const tmpAvailableFields = transformAvailableFields(
        allFieldsData,
        tmpSelectedFields
      );

      setAvailableFields([...tmpAvailableFields]);
      dispatch(clearSourceFieldsStatus());
      dispatch(clearDerivedFieldsStatus());
      setAvailableFieldsLoaded(true);
    }
  }, [statusSourceFields, statusDerivedFields]);

  useEffect(() => {
    if (availableFields.length > 0) setAvailableFieldsLoaded(true);
  }, [availableFields]);

  useEffect(() => {
    const tmpAvailableFields = transformAvailableFields(
      allFieldsData,
      selectedFields
    );

    setAvailableFields([...tmpAvailableFields]);
    setAvailableFieldsLoaded(true);
  }, [selectedFields]);

  // useEffect(() => {
  //   dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
  //   dispatch(
  //     getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
  //   );
  // }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          height: "450px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "54px",
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="h6">
            Selected Fields ({selectedFields.length})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} variant="contained" size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "30%",
              display: "flex",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {availableFieldsLoaded &&
              _.isArray(availableFields) &&
              availableFields.length > 0 && (
                <Scrollbar>
                  <List sx={{ p: 0 }}>
                    {_.orderBy(availableFields, ["label"], ["asc"]).map(
                      (fd, i) => (
                        <React.Fragment key={`field-${i}`}>
                          <ListItem
                            sx={{ borderRadius: 0 }}
                            secondaryAction={
                              <Button
                                sx={{ fontSize: "12px" }}
                                size="small"
                                startIcon={
                                  <FontAwesomeIcon size="2xs" icon={faPlus} />
                                }
                                onClick={() => handleAddRemove("add", fd.uuId)}
                              >
                                ADD
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                  color="inherit"
                                >
                                  {fd.label}
                                </Typography>
                              }
                              secondary={fd.name}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                  </List>
                </Scrollbar>
              )}
          </Box>
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                p: theme.spacing(2),
              }}
            >
              {selectedFields &&
                _.isArray(selectedFields) &&
                selectedFields.length > 0 && (
                  <NtaiDnd3
                    id="uuId"
                    label="text"
                    items={selectedFields}
                    handleReorder={handleReorder}
                    direction="horizontal"
                    secondaryActions={[
                      { value: "color", icon: faFillDrip },
                      { value: "remove", icon: faClose },
                    ]}
                    handleSecondaryAction={handleSecondaryAction}
                  />
                )}
            </Box>
          </Box>
        </Box>
      </Box>
      <NtaiDialog
        size="md"
        handleDialogClose={handleColorDialogClose}
        open={colorDialogOpen}
        title="Field Colors"
      >
        {currentId && colorDialogOpen && (
          <SourceRecordListingTableFieldColorList
            sourceRecordListingFieldId={currentId}
          />
        )}
      </NtaiDialog>
    </React.Fragment>
  );
}
