import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getContacts,
  clearStatus as clearContactsSliceStatus,
} from "../contact/store/contactsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { updateContactGroupObjects } from "./store/contactGroupsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["lastName", ["firstName"]], ["asc", "asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g["contact"]["uuId"] === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function ContactGroupObjects({ handleFormSubmit }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);
  const [contactsLoaded, setContactsLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const contactGroupId = useSelector(
    (state) => state.core.contactGroupsSlice.activeId
  );

  const contactGroupDataMap = useSelector(
    (state) => state.core.contactGroupsSlice.data
  );

  const contactGroupObjects =
    contactGroupDataMap && !_.isEmpty(contactGroupDataMap)
      ? contactGroupDataMap[contactGroupId]["contactGroupObjects"]
      : null;

  const contactsDataMap = useSelector((state) => state.core.contactsSlice.data);
  const contactsSliceStatus = useSelector(
    (state) => state.core.contactsSlice.status
  );
  const contactsData =
    contactsDataMap && !_.isEmpty(contactsDataMap)
      ? Object.values(contactsDataMap)
      : [];

  function handleAdd(uuId) {
    if (
      _.isArray(contactGroupObjects) &&
      _.filter(contactGroupObjects, function (o) {
        return o["contact"]["uuId"] === uuId ? true : false;
      }).length === 0
    ) {
      const tmpNewContactGroupObjectUuIds = [
        ..._.map(contactGroupObjects, (o) => o["contact"]["uuId"]),
        uuId,
      ];
      const copyContactGroupData = _.cloneDeep(
        contactGroupDataMap[contactGroupId]
      );
      copyContactGroupData["contactUuIds"] = tmpNewContactGroupObjectUuIds;

      dispatch(
        updateContactGroupObjects({
          uuId: contactGroupId,
          formData: copyContactGroupData,
        })
      );
    }
  }

  function handleRemove(uuId) {
    if (
      _.isArray(contactGroupObjects) &&
      _.filter(contactGroupObjects, function (o) {
        return o["contact"]["uuId"] === uuId ? true : false;
      }).length > 0
    ) {
      const tmpNewContactGroupObjectUuIds = _.filter(
        contactGroupObjects,
        function (o) {
          return o["contact"]["uuId"] !== uuId ? true : false;
        }
      );

      const copyContactGroupData = _.cloneDeep(
        contactGroupDataMap[contactGroupId]
      );
      copyContactGroupData["contactUuIds"] = tmpNewContactGroupObjectUuIds;

      dispatch(
        updateContactGroupObjects({
          uuId: contactGroupId,
          formData: copyContactGroupData,
        })
      );
    }
  }

  useEffect(() => {
    if (contactsLoaded && !_.isEmpty(contactGroupDataMap)) {
      const tmpAvailableObjects = transformAvailableObjects(
        contactGroupObjects,
        contactsData
      );

      setAvailableObjects(tmpAvailableObjects);
      setSelectedObjects(contactGroupObjects);
      setLoaded(true);
    }
  }, [contactsLoaded, contactGroupDataMap]);

  useEffect(() => {
    if (
      contactsSliceStatus &&
      contactsSliceStatus.result === "success" &&
      contactsSliceStatus.method === "getContacts"
    ) {
      setContactsLoaded(true);
      dispatch(clearContactsSliceStatus());
    }
  }, [contactsSliceStatus]);

  useEffect(() => {
    dispatch(getContacts());
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Contacts"
      subheader="You can assign available contacts to the contact group"
    >
      {loaded && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "420px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              height: "54px",
              gap: theme.spacing(1),
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">
              Contacts Assigned (
              {_.isArray(selectedObjects) ? selectedObjects.length : 0})
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <Button
                sx={{ fontSize: "12px" }}
                size="small"
                // onClick={() => handleRemoveAll()}
              >
                REMOVE ALL
              </Button>
              <Button
                sx={{ fontSize: "12px" }}
                size="small"
                //   onClick={() => handleAddAll()}
              >
                ADD ALL
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: `calc(100% - 54px)`,
              border: theme.general.border1,
            }}
          >
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                borderRight: theme.general.border1,
              }}
            >
              {_.isArray(availableObjects) && availableObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ p: 0 }}>
                    {availableObjects.map((fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: theme.spacing(2),
                            borderRadius: 0,
                          }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.lastName + " " + fd.firstName}
                              </Typography>
                            }
                            secondary={fd.email}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Scrollbar>
              )}
              {_.isArray(availableObjects) && availableObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No contacts available"
                  subheader="You have assigned all contacts"
                />
              )}
            </Box>
            <Box
              sx={{
                flexBasis: "50%",
                display: "flex",
                height: "100%",
              }}
            >
              {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ width: "100%", p: 0 }}>
                    {selectedObjects &&
                      _.isArray(selectedObjects) &&
                      _.orderBy(
                        selectedObjects,
                        ["contact.lastName", "contact.firstName"],
                        ["asc", "asc"]
                      ).map((so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: theme.spacing(2),
                              borderRadius: 0,
                            }}
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() =>
                                  handleRemove(so["contact"]["uuId"])
                                }
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so["contact"]["lastName"] +
                                    " " +
                                    so["contact"]["firstName"]}
                                </Typography>
                              }
                              secondary={so["contact"]["email"]}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                  </List>
                </Scrollbar>
              )}

              {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No contacts assigned"
                  subheader="Add contacts from left panel to the distribution group"
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </NtaiPanel>
  );
}
