import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiDnd from "./NtaiDnd";

const items = [
  {
    id: 1,
    text: "John",
  },
  {
    id: 2,
    text: "Rick",
  },
];
export default function NtaiTestDnD() {
  const theme = useTheme();
  function handleCallback(selectedItems) {
    console.log("Selected Items: ", selectedItems);
  }
  return (
    <Box sx={{ padding: theme.spacing(4) }}>
      <NtaiDnd
        idField="id"
        labelField="text"
        data={items}
        handleCallback={handleCallback}
        direction="column"
      />
    </Box>
  );
}
