import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import {
  getThesaurusConcepts,
  clearStatus,
} from "app/main/pages/core/library/thesaurus/concept/store/thesaurusConceptsSlice";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiInfiniteScroll from "./NtaiInfiniteScroll";
import { getCodelists } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiInfiniteScroll1 from "./NtaiInfiniteSrcoll1";
import { useState } from "react";
import NtaiInfiniteScroll2 from "./NtaiInfiniteScroll2";
import { useMeasure } from "react-use";
const _ = require("lodash");

export default function TestNtaiInfiniteScroll() {
  const [ref, { width, height }] = useMeasure();
  const [page, setPage] = React.useState(0);
  const [from, setFrom] = useState(1);
  const [items, setItems] = React.useState([]);
  const theme = useTheme();
  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordsSlice.data
  );
  const status = useSelector(
    (state) => state.search.searchSourceRecordsSlice.status
  );
  const data = Object.values(dataMap);

  console.log("Data length: ", data.length);

  const dispatch = useDispatch();

  function generateItems(data) {
    return data.map((item, index) => (
      <Box key={index} sx={{ p: "2px", border: "2px" }}>
        div - #{item.recordId}
      </Box>
    ));
  }

  function fetchMoreData() {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    console.log("In fetch more data...", from + 10);
    // setTimeout(() => {
    //   let itemsCopy = _.cloneDeep(items);
    //   itemsCopy = itemsCopy.concat(Array.from({ length: 20 }));
    //   setItems([...itemsCopy]);
    // }, 1500);
    setFrom(from + 10);
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "250px",
        height: "120px",
      }}
      ref={ref}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        {/* <NtaiInfiniteScroll2
        height={height}
        data={items}
        fetchMoreData={fetchMoreData}
        generateItems={generateItems}
      /> */}
        <NtaiInfiniteScroll1
          height={height}
          data={Array.from({ length: 20 })}
        />
      </Box>
    </Box>

    // <NtaiInfiniteScroll
    //   data={data}
    //   fetchMoreData={fetchMoreData}
    //   generateItems={generateItems}
    // />
  );
}
