import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { tooltipConfig } from "../chartConfig";
import { Box } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
const _ = require("lodash");

const DEFAULT_OPTION = {
  legend: {
    type: "scroll",
    icon: "circle",
    textStyle: {
      fontSize: 10,
    },
  },
  tooltip: {
    ...tooltipConfig,
  },

  series: [
    {
      type: "graph",
      layout: "force",
      animation: false,
      label: {
        show: true,
        position: "right",
        formatter: "{b}",
        fontSize: 11,
      },
      lineStyle: {
        color: "source",
        curveness: 0.3,
      },
      draggable: false,
      roam: true,
      zoom: 2,
      data: [],
      categories: [],
      force: {
        edgeLength: 10,
        repulsion: 40,
        gravity: 0.2,
      },
      edges: [],
      emphasis: {
        focus: "adjacency",
        lineStyle: {
          width: 10,
        },
      },
    },
  ],
};

function interpolate(old_value, old_min, old_max, new_min, new_max) {
  return (
    ((old_value - old_min) / (old_max - old_min)) * (new_max - new_min) +
    new_min
  );
}

export default function NtaiForceDirectedEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function onChartClick(params) {
    // const sourceWidgetFilterFields =
    //   NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
    //     [1],
    //     params,
    //     metadata,
    //     CHART_LEFT_CLICK_ACTION
    //   );
    // handleClick(sourceWidgetFilterFields);
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(
      action,
      NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
        [1],
        params,
        metadata,
        action
      )
    );
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);

    const colors = NtaiEChartUtil.getColorPalette(
      theme,
      data["categories"].length
    );

    optionCopy["color"] = colors;
    optionCopy["legend"]["data"] = data["categories"];
    optionCopy["series"][0]["data"] = data["nodes"].map((node, i) => {
      return {
        ...node,
        itemStyle: {
          color: colors[node["id"]],
        },
        symbolSize: interpolate(
          node["value"],
          data["node_ranges"][0],
          data["node_ranges"][1],
          5,
          20
        ),
      };
    });

    // optionCopy["series"][0]["data"] = data["nodes"].map((n, i) => {
    //   return {
    //     id: n["id"],
    //     name: n["name"],
    //     symbolSize: 5,
    //     category: n["category"],
    //     value: n["value"],
    //   };
    // });

    optionCopy["series"][0]["categories"] = data["categories"];
    optionCopy["series"][0]["edges"] = data["links"];

    setOption(optionCopy);
  }, [data, metadata]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"][0]["data"] && chart}
    </Box>
  );
}
