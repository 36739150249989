import React, { useContext, useState } from "react";
import { Box, useTheme } from "@mui/material";
import FunctionEditorConsole from "./console/FunctionEditorConsole";
import FunctionSidepanelWrapper from "./sidepanel/FunctionSidepanelWrapper";
import FunctionPanel from "./editors/FunctionPanel";
import FunctionQueryPanel from "./editors/FunctionQueryPanel";
import FunctionEditorActions from "./FunctionEditorActions";
import { FunctionEditorContext } from "./FunctionEdit";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import FunctionForm from "./FunctionForm";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SplitPane from "split-pane-react/esm/SplitPane";
import { Pane } from "split-pane-react";
const _ = require("lodash");

export default function FunctionEditor() {
  const theme = useTheme();
  const [sizes, setSizes] = useState(["50%", "50%"]);

  const {
    formData,
    functionCode,
    queryCode,
    showSettingsPanel,
    functionFormDialogOpen,
    handleFunctionFormDialogClose,
    handleSave,
    handleRun,
  } = useContext(FunctionEditorContext);

  const { reset, getValues } = useFormContext();

  const status = useSelector((state) => state.models.functionsSlice.status);

  function onHandleSave() {
    let sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("functionForm")
    );
    sanitizedFormData["body"] = functionCode;
    sanitizedFormData["query"] = queryCode;
    sanitizedFormData["datasetUuIds"] = formData["datasetUuIds"];
    handleSave(sanitizedFormData);
  }

  function onHandleRun() {
    let sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("functionForm")
    );
    sanitizedFormData["body"] = functionCode;
    sanitizedFormData["query"] = queryCode;
    sanitizedFormData["datasetUuIds"] = formData["datasetUuIds"];
    handleRun(sanitizedFormData);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "updateFunction"
    ) {
      handleFunctionFormDialogClose();
    }
  }, [status]);

  useEffect(() => {
    reset({ functionForm: formData });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              flexBasis: showSettingsPanel ? "80%" : "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FunctionEditorActions
              onHandleSave={onHandleSave}
              onHandleRun={onHandleRun}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: `calc(100% - 44px)`,
                gap: theme.spacing(1),
              }}
            >
              <SplitPane split="vertical" sizes={sizes} onChange={setSizes}>
                <FunctionPanel />
                <FunctionQueryPanel />
              </SplitPane>
            </Box>
          </Box>
          {showSettingsPanel && <FunctionSidepanelWrapper />}
        </Box>
        <FunctionEditorConsole />
      </Box>
      {functionFormDialogOpen && (
        <NtaiDialog
          open={functionFormDialogOpen}
          handleDialogClose={handleFunctionFormDialogClose}
          size="sm"
          title={`Edit ${formData.name}`}
        >
          <FunctionForm
            action="edit"
            formData={formData}
            handleCancel={handleFunctionFormDialogClose}
            handleSave={onHandleSave}
          />
        </NtaiDialog>
      )}
    </>
  );
}
