import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { useSelector, useDispatch } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  CODELIST_CODES,
  SRC_SOURCE_RECORD_SUMMARY,
} from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import { SearchSourceContext } from "../../../SearchSource";
import {
  getSearchSourceRecordDocuments,
  createSearchSourceRecordDocument,
  updateSearchSourceRecordDocument,
  clearStatus,
  deleteSearchSourceRecordDocument,
  downloadSearchSourceRecordDocument,
  clearDownloadData,
} from "./store/searchSourceRecordDocumentsSlice";
import SearchSourceRecordDocumentForm from "./SearchSourceRecordDocumentForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "document.title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    fontWeight: 700,
    width: "50%",
  },
  {
    id: "document.fileSize",
    numeric: false,
    disablePadding: false,
    label: "Size",
    transformFunc: NtaiUtils.bytesToSize,
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

export default function SearchSourceRecordDocumentList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState("list");
  const [loaded, setLoaded] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const { selectedRecord, setRefreshSelectedRecord } = React.useContext(
    SearchSourceRecordListContext
  );
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = useSelector((state) => state.search.searchSlice.activeId);
  const searchSourceId = _.get(searchSourceData, "uuId");
  const recordId = _.get(selectedRecord, "recordId");

  const searchSourceRecordsDataMap = useSelector(
    (state) => state.search.searchSourceRecordsSlice.data
  );

  const searchSourceRecordsData =
    searchSourceRecordsDataMap && !_.isEmpty(searchSourceRecordsDataMap)
      ? Object.values(searchSourceRecordsDataMap)
      : [];

  const selectedSearchSourceRecordUuId =
    selectedRecord &&
    selectedRecord["recordId"] &&
    selectedRecord["recordId"].length > 0 &&
    _.filter(searchSourceRecordsData, {
      recordId: selectedRecord["recordId"],
    })[0]["uuId"]
      ? _.filter(searchSourceRecordsData, {
          recordId: selectedRecord["recordId"],
        })[0]["uuId"]
      : "*";

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordDocumentsSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const downloadData = useSelector(
    (state) => state.search.searchSourceRecordDocumentsSlice.downloadData
  );
  const downloadFileName = useSelector(
    (state) => state.search.searchSourceRecordDocumentsSlice.downloadFileName
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordDocumentsSlice.status
  );

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "document.title"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDownload(id) {
    dispatch(
      downloadSearchSourceRecordDocument({
        searchId: searchId,
        searchSourceId: searchSourceId,
        searchSourceRecordId: selectedSearchSourceRecordUuId,
        uuId: id,
        fileName: dataMap[id]["document"]["fileName"],
      })
    );
  }

  function downloadFile() {
    // downloadedData
    if (downloadData) {
      const url = window.URL.createObjectURL(new Blob([downloadData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();
      dispatch(clearDownloadData());
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteSearchSourceRecordDocument({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
    setCurrentId(null);
  }

  function handleRefreshSelectedRecord() {
    setRefreshSelectedRecord({
      summaryType: SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_DOCS,
      recordIds: [selectedRecord["recordId"]],
    });
  }

  useEffect(() => {
    if (
      status &&
      ["getSearchSourceRecordDocuments"].includes(status.method) &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      [
        "createSearchSourceRecordDocument",
        "updateSearchSourceRecordDocument",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setAction("list");
      dispatch(clearStatus());
      handleRefreshSelectedRecord();
    }

    if (
      status &&
      status.method === "deleteSearchSourceRecordDocument" &&
      status.result === "success"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Document(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
      handleRefreshSelectedRecord();
    }

    if (
      status &&
      status.method === "downloadSearchSourceRecordDocument" &&
      status.result === "success"
    ) {
      downloadFile();
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  function handleSave(formData) {
    if (action === "add") {
      dispatch(
        createSearchSourceRecordDocument({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          formData: formData,
        })
      );
    } else if (action === "edit")
      dispatch(
        updateSearchSourceRecordDocument({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  useEffect(() => {
    if (action === "list" && selectedRecord)
      dispatch(
        getSearchSourceRecordDocuments({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          formData: {
            sourceId: _.get(
              searchSourceData,
              "searchSourceDefinition.sourceUuId"
            ),
            recordId: recordId,
          },
        })
      );
  }, [action, selectedSearchSourceRecordUuId]);

  return (
    <>
      {action === "list" && (
        <NtaiPanel
          width="100%"
          header="Documents"
          subheader="Document Items are generally understood as tasks or actions. In simple words, the Document Items provide tiny insights on what, how, and when the tasks or actions are expected to be completed."
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              keyColumn="document.uuId"
              isDensed={true}
              dialog={true}
              selectedIds={selectedIds}
              handleDialogAddEdit={handleDialogAddEdit}
              toolbarActions={toolbarActions}
              handleToolbarAction={handleToolbarAction}
              title="Document"
            />
          )}
          {!loaded && <NtaiCircularProgress vAlign="center" />}
        </NtaiPanel>
      )}
      {(action === "add" || action === "edit") && (
        <NtaiForm>
          <SearchSourceRecordDocumentForm
            action={action}
            formData={action === "edit" ? _.get(dataMap, currentId) : {}}
            handleSave={handleSave}
            handleCancel={handleCancel}
            handleDownload={handleDownload}
          />
        </NtaiForm>
      )}
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "document.title"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
