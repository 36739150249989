import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ObjectFormUtil from "../util/ObjectFormUtil";
import Scrollbar from "@ntai/components/Scrollbar";
import { getObjectForms } from "../store/objectFormsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { useFormContext } from "react-hook-form";
import NtaiErrorMessage from "@ntai/components/errors/NtaiErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { green, lime } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils";
const _ = require("lodash");

export default function ObjectFormBuilderPreview({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    reset,
    getValues,
    formState: { errors },
  } = useFormContext();

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);

  const objectFormData = useSelector(
    (state) => state.core.objectFormsSlice.data
  );

  function onHandleTestSave() {
    console.log("Errors -> : ", errors);
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("previewForm")
    );
    console.log("Preview form submit data: ", sanitizedFormData);
    // setValidationsPassed(true);
  }

  function onHandleTestCancel() {
    handleCancel();
  }

  useEffect(() => {
    dispatch(
      getObjectForms({
        domainId: domainId,
        objectId: objectId,
      })
    );
  }, []);

  return (
    <NtaiFormPanel
      width="100%"
      handleSave={onHandleTestSave}
      handleCancel={onHandleTestCancel}
      saveLabel="Test Save"
    >
      <Box
        sx={{
          border: theme.general.border1,
          display: "flex",
          height: "420px",
          width: "100%",
          borderRadius: theme.general.borderRadius,
          flexDirection: "column",
        }}
      >
        {errors && !_.isEmpty(errors) && (
          <NtaiErrorMessage
            title={"Some validations have failed"}
            message={JSON.stringify(errors)}
          />
        )}

        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              borderRadius: theme.general.borderRadius,
              py: theme.spacing(2),
              px: theme.spacing(3),
            }}
          >
            {ObjectFormUtil.generateForm(
              "previewForm",
              objectFormData,
              true,
              theme
            )}
          </Box>
        </Scrollbar>
      </Box>
    </NtaiFormPanel>
  );
}
