import { faScrewdriverWrench } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import history from "@ntai/@history";
import { setLeftSidebarNav } from "app/store/ntai/navigationSlice";
import React from "react";
import { useDispatch } from "react-redux";

export default function ToolsWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();

  function handleClick(e) {
    dispatch(setLeftSidebarNav(false));
    history.push("/tools/assessment-import");
  }

  return (
    <Tooltip title="Tools">
      <IconButton
        sx={{
          width: "32px",
          height: "32px",
          "&:hover": { bgcolor: theme.colors.primary.light },
        }}
        color="inherit"
        onClick={(e) => handleClick(e)}
      >
        <FontAwesomeIcon icon={faScrewdriverWrench} size="xs" />
      </IconButton>
    </Tooltip>
  );
}
