import React, { useContext } from "react";
import parse from "html-react-parser";
import {
  faClose,
  faWindowMaximize,
  faWindowMinimize,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { FunctionEditorContext } from "../FunctionEdit";
import Scrollbar from "@ntai/components/Scrollbar";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { grey, red } from "@mui/material/colors";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

// const JsonTable = require("react-json-table");
const JsonTable = require("ts-react-json-table");

const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  table1: {
    width: "100%",
    borderCollapse: "collapse",
    margin: "2px 0",
    fontSize: "0.9em",
    fontFamily: "sans-serif",
    minWidth: "400px",
    textAlign: "left",
    // boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
    "& th": {
      padding: "2px",
      paddingLeft: "4px",
      backgroundColor: "#D6EEEE",
    },
    "& tr": {
      padding: "2px",
      borderBottom: theme.general.border1,
    },
  },
  table1thead1: {
    backgroundColor: "#009879",
    color: "#ffffff",
    textAlign: "left",
  },
  tr1: {
    borderBottom: theme.general.border1,
  },
}));

export default function FunctionEditorConsole() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { formData, functionRunInProgress } = useContext(FunctionEditorContext);

  const rows =
    _.get(formData, "runStatusCode") === 3 ? _.get(formData, "result") : null;

  const status = useSelector((state) => state.models.functionsSlice.status);

  return (
    <Box
      sx={{
        display: "flex",
        height: "220px",
        borderTop: theme.general.border1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "32px",
          borderBottom: theme.general.border1,
          display: "flex",
          alignItems: "center",
          // background: theme.colors.alpha.black[100],
          // color: theme.colors.alpha.white[100],
          px: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",

              alignItems: "center",
            }}
          >
            <Typography variant="body2" fontWeight="700">
              Console
            </Typography>
          </Box>

          <Box
            sx={{
              flexBasis: "50%",
              justifyContent: "end",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(2),
            }}
          >
            <FontAwesomeIcon icon={faWindowMaximize} />
            <FontAwesomeIcon icon={faWindowMinimize} />
            <FontAwesomeIcon icon={faClose} />
          </Box>
        </Box>
      </Box>
      {!functionRunInProgress && _.get(formData, "runStatusCode") === 6 && (
        <Box
          sx={{
            height: "100%",
            backgroundColor: red[100],
            px: theme.spacing(2),
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                p: theme.spacing(1),
                display: "flex",
                height: "100%",
                width: "100%",
              }}
            >
              {_.isString(_.get(formData, "message")) &&
                parse(_.get(formData, "message"))}
            </Box>
          </Scrollbar>
        </Box>
      )}
      {!functionRunInProgress && _.get(formData, "runStatusCode") === 3 && (
        <Box
          sx={{
            display: "flex",
            height: `calc(100% - 32px)`,
            p: theme.spacing(1),

            // background: theme.colors.alpha.black[100],
            // color: theme.colors.alpha.white[100],
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                border: theme.general.border1,
                borderRadius: theme.general.borderRadius,
              }}
            >
              {rows && Array.isArray(rows) && rows.length > 0 && (
                // <JsonTable
                //   rows={_.get(formData, "result")}
                //   className={classes.table1}
                // />
                <Table>
                  <TableHead>
                    <TableRow>
                      {rows &&
                        rows[0] &&
                        Object.keys(rows[0]).map((k, i) => (
                          <TableCell
                            sx={{
                              px: theme.spacing(2),
                              py: theme.spacing(0.5),
                            }}
                            key={i}
                          >
                            {k}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((item, index) => (
                      <TableRow key={index}>
                        {Object.values(item).map((val, i) => (
                          <TableCell
                            key={i}
                            sx={{
                              py: theme.spacing(0.5),
                              px: theme.spacing(2),
                              verticalAlign: "top",
                            }}
                          >
                            {_.isArray(val) || _.isObject(val)
                              ? JSON.stringify(val)
                              : val}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              {!rows ||
                (Array.isArray(rows) && rows.length === 0 && (
                  <Box>Nothing to display</Box>
                ))}
            </Box>
          </Scrollbar>
        </Box>
      )}
      {functionRunInProgress && <NtaiCircularProgress size={20} />}
    </Box>
  );
}
