import NotificationDefEdit from "./NotificationDefEdit";
import NotificationDefListWrapper from "./NotificationDefListWrapper";

const NotificationDefConfig = {
  routes: [
    {
      path: "/admin/notification-defs",
      exact: true,
      component: NotificationDefListWrapper,
    },
    {
      path: "/admin/notification-defs/:id/edit",
      exact: true,
      component: NotificationDefEdit,
    },
  ],
};

export default NotificationDefConfig;
