import { faClock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import React, { useState } from "react";
import SearchScheduleForm from "./SearchScheduleForm";

export default function SearchScheduleWrapper() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  function handleSave(formData) {}

  return (
    <React.Fragment>
      <Tooltip title="Schedule">
        <IconButton onClick={() => handleDialogOpen()}>
          <FontAwesomeIcon size="2xs" icon={faClock} />
        </IconButton>
      </Tooltip>
      <NtaiDialog
        title="Schedule Analysis"
        size="sm"
        open={open}
        handleDialogClose={handleDialogClose}
      >
        <NtaiForm>
          <SearchScheduleForm
            handleCancel={handleDialogClose}
            handleSave={handleSave}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
