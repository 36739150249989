import { Box, Button, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrid,
  faGridHorizontal,
  faStop,
} from "@fortawesome/pro-light-svg-icons";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { SearchReportInitContext } from "./SearchReportInitWrapper";
import SearchReportBox from "./SearchReportBox";
import SearchReportCompactBox from "./SearchReportCompactBox";
const _ = require("lodash");

export default function SearchReportInit() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [searches, setSearches] = useState([]);
  const [denseLayout, setDenseLayout] = useState(false);

  const {
    selectedFilter,
    setSelectedFilter,
    selectedSearches,
    setSelectedSearches,
    selectedSearchDetails,
    setSelectedSearchDetails,
    queryText,
    setQueryText,
  } = React.useContext(SearchReportInitContext);

  const dataMap = useSelector(
    (state) => state.search.searchReportInitSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function getFilteredSearches() {
    const filteredSearches = _.filter(data, function (o) {
      if (
        selectedFilter &&
        selectedFilter.length > 0 &&
        selectedFilter !== "All"
      ) {
        if (o["categoryCode"] === selectedFilter) return true;
        else return false;
      } else {
        return true;
      }
    });

    const result = _.filter(data, function (o) {
      if (queryText && queryText.length > 0) {
        if (o["name"].toLowerCase().includes(queryText.toLowerCase())) {
          return true;
        } else return false;
      } else if (!queryText || queryText.length === 0) {
        return true;
      }
    });

    return result;
  }

  function handleSelectSearch(searchUuId) {
    const tmpSelected = new Set(selectedSearches);
    if (selectedSearches && selectedSearches.has(searchUuId))
      tmpSelected.delete(searchUuId);
    else {
      tmpSelected.add(searchUuId);
    }

    if (tmpSelected.size > 0) {
      setSelectedSearchDetails(
        _.filter(searches, { uuId: [...tmpSelected][0] })[0]
      );
    } else {
      setSelectedSearchDetails(null);
    }

    setSelectedSearches(tmpSelected);
  }

  function clearSelectedSearches() {
    const tmpSelected = new Set(selectedSearches);
    tmpSelected.clear();
    setSelectedSearches(tmpSelected);
  }

  useEffect(() => {
    let revisedSearches = [];
    if (dataMap && !_.isEmpty(dataMap)) {
      revisedSearches = Object.values(dataMap).map((o, i) => {
        return {
          uuId: o.uuId,
          title: o.name,
          description: o.description,
        };
      });
    }
    setLoaded(true);
    setSearches(revisedSearches);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexBasis: "85%",
        flexDirection: "column",
        justifyContent: "start",
        gap: theme.spacing(1),
      }}
    >
      {loaded && _.isArray(data) && data.length > 0 && (
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              pl: theme.spacing(2),
              justifyContent: "space-between",
              paddingTop: theme.spacing(2),
              width: "86%",
            }}
          >
            <Typography variant="h6">{`Analysis (${
              getFilteredSearches().length
            })`}</Typography>

            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                justifyContent: "end",
              }}
            >
              <Button
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  gap: theme.spacing(1),
                }}
                size="small"
                color="inherit"
                onClick={() => setDenseLayout(!denseLayout)}
              >
                <FontAwesomeIcon
                  size="xl"
                  icon={denseLayout ? faGridHorizontal : faGrid}
                />
                {denseLayout ? "STANDARD" : "DENSE"}
              </Button>
              <Button
                sx={{
                  fontSize: "10px",
                  fontWeight: 500,
                  gap: theme.spacing(1),
                }}
                size="small"
                color="inherit"
                onClick={() => clearSelectedSearches()}
              >
                <FontAwesomeIcon size="xl" icon={faStop} />
                CLEAR
              </Button>
            </Box>
          </Box>

          {getFilteredSearches().length > 0 && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                p: theme.spacing(2),
                gap: theme.spacing(2),
                // gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                flexWrap: "wrap",
              }}
            >
              {getFilteredSearches().map((e, i) => {
                if (denseLayout) {
                  return (
                    <SearchReportCompactBox
                      key={`search-box-${i}`}
                      id={e["uuId"]}
                      title={e["name"]}
                      description={e["description"]}
                      handleSelect={handleSelectSearch}
                      selectedItems={selectedSearches}
                    />
                  );
                } else {
                  return (
                    <SearchReportBox
                      key={`search-${i}`}
                      id={e["uuId"]}
                      title={e["name"]}
                      description={e["description"]}
                      handleSelect={handleSelectSearch}
                      selectedItems={selectedSearches}
                    />
                  );
                }
              })}
            </Box>
          )}
          {getFilteredSearches().length === 0 && (
            <NtaiEmptyMessage
              header="No searches found"
              subheader="Searches not assigned or applied filters didn't return any result"
            />
          )}
        </Scrollbar>
      )}
    </Box>
  );
}
