import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import {
  getSourceRecordTemplates,
  clearStatus as clearSourceRecordTemplatesSliceStatus,
} from "../record/store/sourceRecordTemplatesSlice";

const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (allObjectsDataMap && !_.isEmpty(allObjectsDataMap)) {
    if (_.isArray(selectedObjects) && selectedObjects.length > 0) {
      selectedObjects.forEach((f, i) => {
        result.push({
          uuId: f,
          primary: allObjectsDataMap[f]["name"],
          secondary: allObjectsDataMap[f]["description"],
        });
      });
    }
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (allObjectsDataMap && !_.isEmpty(allObjectsDataMap)) {
    result = _.filter(
      _.orderBy(Object.values(allObjectsDataMap), ["name"], ["asc"]),
      function (f) {
        if (
          _.filter(selectedObjects, function (g) {
            if (g === f.uuId) return true;
            else return false;
          }).length === 0
        )
          return true;
      }
    );
  }

  return result;
}

export default function SourceMasterTemplateRecordTemplates({ handleSave }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceRecordTemplatesDataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );

  const sourceRecordTemplatesSliceStatus = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.status
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.data
  );

  const activeId = useSelector(
    (state) => state.sources.sourceMasterTemplatesSlice.activeId
  );

  const data = dataMap && !_.isEmpty(dataMap) ? dataMap[activeId] : [];
  const sourceRecordTemplateUuIds = data
    ? data["sourceRecordTemplateUuIds"]
    : [];

  function handleAdd(uuId) {
    const sourceRecordTemplateUuIdsCopy = _.cloneDeep(
      sourceRecordTemplateUuIds
    );
    const dataCopy = _.cloneDeep(data);
    if (!sourceRecordTemplateUuIdsCopy.includes(uuId))
      sourceRecordTemplateUuIdsCopy.push(uuId);
    dataCopy["sourceRecordTemplateUuIds"] = sourceRecordTemplateUuIdsCopy;
    handleSave(dataCopy);
  }

  function handleRemove(uuId) {
    if (sourceRecordTemplateUuIds.includes(uuId)) {
      const sourceRecordTemplateUuIdsCopy = _.filter(
        sourceRecordTemplateUuIds,
        function (o) {
          return o === uuId ? false : true;
        }
      );
      const dataCopy = _.cloneDeep(data);
      dataCopy["sourceRecordTemplateUuIds"] = sourceRecordTemplateUuIdsCopy;
      handleSave(dataCopy);
    }
  }

  useEffect(() => {
    if (
      (sourceRecordTemplatesSliceStatus &&
        sourceRecordTemplatesSliceStatus.result === "success" &&
        sourceRecordTemplatesSliceStatus.method ===
          "getSourceRecordTemplates") ||
      sourceRecordTemplateUuIds
    ) {
      const tmpSelectedObjects = transformSelectedObjects(
        sourceRecordTemplateUuIds,
        sourceRecordTemplatesDataMap
      );
      const tmpAvailableObjects = transformAvailableObjects(
        sourceRecordTemplateUuIds,
        sourceRecordTemplatesDataMap
      );
      setAvailableObjects(tmpAvailableObjects);
      setSelectedObjects(tmpSelectedObjects);
      dispatch(clearSourceRecordTemplatesSliceStatus());
      setLoaded(true);
    }
  }, [sourceRecordTemplatesSliceStatus, sourceRecordTemplateUuIds]);

  useEffect(() => {
    dispatch(
      getSourceRecordTemplates({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [activeId]);

  return (
    <NtaiPanel
      width="60%"
      height="100%"
      header="Source Record Templates"
      subheader="Implement role-based filters to display only authorized source views based on each user's role."
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "280px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Record Templates Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {loaded &&
              _.isArray(availableObjects) &&
              availableObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ p: 0 }}>
                    {_.orderBy(availableObjects, ["name"], ["asc"]).map(
                      (fd, i) => (
                        <React.Fragment key={`available-objects-${i}`}>
                          <ListItem
                            sx={{ borderRadius: 0 }}
                            secondaryAction={
                              <Button
                                sx={{ fontSize: "11px" }}
                                variant="outlined"
                                size="small"
                                onClick={() => handleAdd(fd.uuId)}
                              >
                                ADD
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {fd.name}
                                </Typography>
                              }
                              secondary={fd["description"]}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                  </List>
                </Scrollbar>
              )}
            {loaded &&
              _.isArray(availableObjects) &&
              availableObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No source views available"
                  subheader="Contact admin for source views configuration"
                />
              )}
            {!loaded && <NtaiCircularProgress size={24} />}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {loaded &&
              _.isArray(selectedObjects) &&
              selectedObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ width: "100%", p: 0 }}>
                    {selectedObjects &&
                      _.isArray(selectedObjects) &&
                      _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                        (so, i) => (
                          <React.Fragment key={`selected-objects-${i}`}>
                            <ListItem
                              secondaryAction={
                                <Button
                                  sx={{
                                    display: "flex",
                                    gap: theme.spacing(1),
                                    fontSize: "11px",
                                  }}
                                  onClick={() => handleRemove(so.uuId)}
                                >
                                  <FontAwesomeIcon icon={faArchive} />
                                  REMOVE
                                </Button>
                              }
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{ fontWeight: 700 }}
                                    variant="subtitle1"
                                  >
                                    {so.primary}
                                  </Typography>
                                }
                                secondary={so.secondary}
                              />
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        )
                      )}
                  </List>
                </Scrollbar>
              )}

            {loaded &&
              _.isArray(selectedObjects) &&
              selectedObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No templates assigned"
                  subheader="Add templates from left panel"
                />
              )}
            {!loaded && <NtaiCircularProgress size={24} />}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
