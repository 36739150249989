import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceLinkedSources = createAsyncThunk(
  "sourceLinkedSources/getSourceLinkedSources",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceLinkedSource = createAsyncThunk(
  "sourceLinkedSources/getSourceLinkedSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceLinkedSource = createAsyncThunk(
  "sourceLinkedSources/createSourceLinkedSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceLinkedSource = createAsyncThunk(
  "sourceLinkedSources/updateSourceLinkedSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceLinkedSource = createAsyncThunk(
  "sourceLinkedSources/deleteSourceLinkedSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceLinkedSourcesSlice = createSlice({
  name: "sourceLinkedSources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceLinkedSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceLinkedSources" };
    },
    [getSourceLinkedSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceLinkedSources",
        message: action.payload.message,
      };
    },
    [getSourceLinkedSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceLinkedSource" };
    },
    [getSourceLinkedSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceLinkedSource",
        message: action.payload.message,
      };
    },
    [createSourceLinkedSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSourceLinkedSource" };
    },
    [createSourceLinkedSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceLinkedSource",
        message: action.payload.message,
      };
    },
    [updateSourceLinkedSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceLinkedSource" };
    },
    [updateSourceLinkedSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceLinkedSource",
        message: action.payload.message,
      };
    },
    [deleteSourceLinkedSource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceLinkedSource" };
    },
    [deleteSourceLinkedSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceLinkedSource",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceLinkedSourcesSlice.actions;

export default sourceLinkedSourcesSlice.reducer;
