import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { deepOrange, grey } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useState } from "react";
import NtaiIcons from "utils/NtaiIcons";

export default function NtaiCompactCard(props) {
  const theme = useTheme();
  const { id, width, height, avatar, title, hits, children, handleClick } =
    props;

  return (
    <Card
      sx={{
        p: 0,
        paddingY: "4px",
        border: theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        height: height ? height : "100%",
        width: width ? width : "100%",
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
          background: theme.colors.secondary.lighter,
        },
      }}
      onClick={() => handleClick(id)}
    >
      <CardHeader
        sx={{ py: 1 }}
        title={
          title ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                {avatar && (
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      color: grey[900],
                      width: 24,
                      height: 24,
                    }}
                  >
                    {avatar}
                  </Avatar>
                )}

                <Typography
                  variant="subtitle1"
                  fontWeight="700"
                  color="inherit"
                >
                  {NtaiUtils.trunc(title, 25)}
                </Typography>
              </Box>

              {/* <Typography variant="subtitle1" color="inherit" fontWeight="700">
                {NtaiUtils.abbrevNumber(hits)}
              </Typography> */}
            </Box>
          ) : null
        }
      />

      <CardContent sx={{ py: 0 }}>
        <Typography variant="subtitle2">
          {NtaiUtils.trunc(children, 90)}
        </Typography>
      </CardContent>
    </Card>
  );
}
