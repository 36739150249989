import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlertRecordAdjudications = createAsyncThunk(
  "alertRecordAdjudications/getAlertRecordAdjudications",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/adjudications`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertRecordAdjudicationsByAdjObjectUuId = createAsyncThunk(
  "alertRecordAdjudications/getAlertRecordAdjudicationsByAdjObjectUuId",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, formData } = values;
      const response = await server.put(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertRecordAdjudication = createAsyncThunk(
  "alertRecordAdjudications/getAlertRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId } = values;
      const response = await server.get(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/adjudications/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAlertRecordAdjudication = createAsyncThunk(
  "alertRecordAdjudications/createAlertRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAlertRecordAdjudication = createAsyncThunk(
  "alertRecordAdjudications/updateAlertRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId, formData } = values;
      const response = await server.patch(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/adjudications/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAlertRecordAdjudication = createAsyncThunk(
  "alertRecordAdjudications/deleteAlertRecordAdjudication",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, alertRecordId, uuId } = values;
      const response = server.delete(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/${alertRecordId}/adjudications/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertRecordAdjudicationsSlice = createSlice({
  name: "alertRecordAdjudications",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertRecordAdjudications.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getAlertRecordAdjudications",
      };
    },
    [getAlertRecordAdjudications.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordAdjudications",
        message: action.payload.message,
      };
    },
    [getAlertRecordAdjudications.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getAlertRecordAdjudications",
      };
    },

    [getAlertRecordAdjudicationsByAdjObjectUuId.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getAlertRecordAdjudicationsByAdjObjectUuId",
      };
    },
    [getAlertRecordAdjudicationsByAdjObjectUuId.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordAdjudicationsByAdjObjectUuId",
        message: action.payload.message,
      };
    },
    [getAlertRecordAdjudicationsByAdjObjectUuId.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getAlertRecordAdjudicationsByAdjObjectUuId",
      };
    },

    [getAlertRecordAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getAlertRecordAdjudication",
      };
    },
    [getAlertRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertRecordAdjudication",
        message: action.payload.message,
      };
    },
    [createAlertRecordAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createAlertRecordAdjudication",
      };
      state.activeId = action.payload.uuId;
    },
    [createAlertRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createAlertRecordAdjudication",
        message: action.payload.message,
      };
    },
    [createAlertRecordAdjudication.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "createAlertRecordAdjudication",
      };
    },

    [updateAlertRecordAdjudication.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateAlertRecordAdjudication",
      };
      state.activeId = action.payload.uuId;
    },
    [updateAlertRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateAlertRecordAdjudication",
        message: action.payload.message,
      };
    },
    [updateAlertRecordAdjudication.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateAlertRecordAdjudication",
      };
    },

    [deleteAlertRecordAdjudication.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteAlertRecordAdjudication",
      };
    },
    [deleteAlertRecordAdjudication.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAlertRecordAdjudication",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  alertRecordAdjudicationsSlice.actions;

export default alertRecordAdjudicationsSlice.reducer;
