import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import ObjectSourceViewWidgetsWrapper from "./ObjectSourceViewWidgetsWrapper";
import { useDispatch } from "react-redux";
const _ = require("lodash");

export const ObjectSourceViewContext = React.createContext();

export default function ObjectSourceViewForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedWidgetIds, setSelectedWidgetIds] = React.useState(
    _.get(formData, "sourceWidgetUuIds")
  );

  const { getValues, reset, setValue } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectSourceViewForm")
    );
    const revisedFormData = {
      ...sanitizedFormData,
      sourceWidgetUuIds: selectedWidgetIds,
    };

    handleSave(revisedFormData);
  }

  useEffect(() => {
    reset({ objectSourceViewForm: formData });
  }, [formData]);

  return (
    <ObjectSourceViewContext.Provider
      value={{ selectedWidgetIds, setSelectedWidgetIds, onHandleSave }}
    >
      <NtaiTabs>
        <Box label="GENERAL">
          <NtaiFormPanel
            width="50%"
            subheader="Lorem ipsum more text to follow Lorem ipsum more text to follow Lorem ipsum more text to follow"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <NtaiTextField
                name="objectSourceViewForm.name"
                label="Name*"
                placeholder="Name"
                rules={{ required: "Name is required" }}
              />
              <NtaiTextField
                name="objectSourceViewForm.description"
                label="Description"
                placeholder="Description"
                multiline
                minRows={3}
                maxRows={3}
              />
            </Box>
          </NtaiFormPanel>
        </Box>
        <Box disabled={action === "add" ? true : false} label="WIDGETS">
          <ObjectSourceViewWidgetsWrapper handleSave={onHandleSave} />
        </Box>
      </NtaiTabs>
    </ObjectSourceViewContext.Provider>
  );
}
