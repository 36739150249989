import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import NtaiIcons from "utils/NtaiIcons";

export default function NtaiButton(props) {
  const theme = useTheme();
  const { icon, children } = props;
  return (
    <Button {...props}>
      <Box
        sx={{
          m: 0,
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        {icon && <FontAwesomeIcon icon={NtaiIcons[icon]} />}
        {children}
      </Box>
    </Button>
  );
}
