import {
  faAdd,
  faArrowDownToLine,
  faCommentAlt,
  faComments,
  faEquals,
  faMinus,
  faNotEqual,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import { SEARCH_SOURCE_WIDGET_MENU_ACTIONS } from "app/main/constants/NtaiCodelistConstants";
import React from "react";

export default function NtaiContextMenuEChart({ onHandleContextMenu }) {
  const theme = useTheme();

  return (
    <MenuList sx={{ px: 0, py: "2px" }}>
      <MenuItem
        key={1}
        onClick={() =>
          onHandleContextMenu(SEARCH_SOURCE_WIDGET_MENU_ACTIONS.EQUALS)
        }
      >
        <ListItemIcon>
          <FontAwesomeIcon size="xs" icon={faEquals} />
        </ListItemIcon>
        <Typography variant="subtitle2">Equals</Typography>
      </MenuItem>
      <MenuItem
        key={2}
        onClick={() =>
          onHandleContextMenu(SEARCH_SOURCE_WIDGET_MENU_ACTIONS.NOT_EQUALS)
        }
      >
        <ListItemIcon>
          <FontAwesomeIcon size="xs" icon={faNotEqual} />
        </ListItemIcon>
        <Typography variant="subtitle2">Not Equals</Typography>
      </MenuItem>
      <MenuItem
        key={3}
        onClick={() =>
          onHandleContextMenu(SEARCH_SOURCE_WIDGET_MENU_ACTIONS.INCLUDE)
        }
      >
        <ListItemIcon>
          <FontAwesomeIcon size="xs" icon={faAdd} />
        </ListItemIcon>
        <Typography variant="subtitle2">Include</Typography>
      </MenuItem>
      <MenuItem
        key={4}
        onClick={() =>
          onHandleContextMenu(SEARCH_SOURCE_WIDGET_MENU_ACTIONS.EXCLUDE)
        }
      >
        <ListItemIcon>
          <FontAwesomeIcon size="xs" icon={faMinus} />
        </ListItemIcon>
        <Typography variant="subtitle2">Exclude</Typography>
      </MenuItem>
      {/* <MenuItem
        key={5}
        onClick={() =>
          onHandleContextMenu(SEARCH_SOURCE_WIDGET_MENU_ACTIONS.DRILL_DOWN)
        }
      >
        <ListItemIcon>
          <FontAwesomeIcon size="xs" icon={faArrowDownToLine} />
        </ListItemIcon>
        <Typography variant="subtitle2">Drill Down</Typography>
      </MenuItem> */}
      {/* <MenuItem key={6}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faArrowDownTriangleSquare} />
        </ListItemIcon>
        <Typography variant="subtitle2">Drill Through</Typography>
      </MenuItem> */}
      <MenuItem
        key={6}
        onClick={() =>
          onHandleContextMenu(SEARCH_SOURCE_WIDGET_MENU_ACTIONS.NOTES)
        }
      >
        <ListItemIcon>
          <FontAwesomeIcon size="xs" icon={faCommentAlt} />
        </ListItemIcon>
        <Typography variant="subtitle2">Comments</Typography>
      </MenuItem>
    </MenuList>
  );
}
