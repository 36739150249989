import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, Button, Drawer, Typography, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faPlus,
  faUserCheck,
  faUserGroup,
} from "@fortawesome/pro-light-svg-icons";

import { faLock as faLockSolid } from "@fortawesome/pro-solid-svg-icons";

import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiListTable from "@ntai/components/tables/list/NtaiListTable";
import { useSnackbar } from "notistack";
import {
  clearStatus,
  resetUserPassword,
  updateUserRoles,
} from "./store/usersSlice";
import NtaiTitleItems from "@ntai/components/micros/NtaiTitleItems";
import history from "@ntai/@history";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiValidator from "@ntai/validator/NtaiValidator";

const _ = require("lodash");

export default function UserForm(props) {
  const theme = useTheme();
  const { action, formData, handleFormSubmit } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [openManageRolesDialog, setOpenManageRolesDialog] = useState(false);
  const [resetPasswordConfirmDialogOpen, setResetPasswordConfirmDialogOpen] =
    useState(false);
  const [rolesSelected, setRolesSelected] = useState([]);
  const {
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useFormContext({ mode: "onBlur" });
  const dispatch = useDispatch();

  const activeId = useSelector((state) => state.core.usersSlice.activeId);
  const status = useSelector((state) => state.core.usersSlice.status);
  const rolesDataMap = useSelector((state) => state.core.rolesSlice.data);
  const roles = Object.values(rolesDataMap);

  function handleCancel() {
    history.push("/admin/users");
  }

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(getValues("userForm"));
    handleFormSubmit({ ...sanitizedFormData, roleUuIds: rolesSelected });
  }

  function handleOpenManageRolesDialog() {
    setOpenManageRolesDialog(true);
  }

  function handleCloseManageRolesDialog() {
    setOpenManageRolesDialog(false);
  }

  function handleRolesSelection(actionName, selected) {
    if (actionName === "save") {
      dispatch(
        updateUserRoles({ uuId: activeId, formData: { roleUuIds: selected } })
      );
    } else if (actionName === "cancel") handleCloseManageRolesDialog();
  }

  function onHandleResetPassword() {
    setResetPasswordConfirmDialogOpen(true);
  }

  function handleResetPasswordConfirmDialogClose() {
    setResetPasswordConfirmDialogOpen(false);
  }

  function handleResetPassword() {
    dispatch(resetUserPassword(activeId));
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "updateUserRoles"
    ) {
      handleCloseManageRolesDialog();
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "resetUserPassword"
    ) {
      enqueueSnackbar(
        "Password reset successfully for user: " + formData["userName"],
        {
          variant: "success",
        }
      );

      handleResetPasswordConfirmDialogClose();
    }

    clearStatus();
  }, [status]);

  useEffect(() => {
    reset({ userForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
        <Box sx={{ flexBasis: "60%", display: "flex" }}>
          <NtaiFormPanel
            header="User Details"
            subheader="User details encompass essential information associated with a user account, including the username, name, password and email. These details play a crucial role in user authentication and personalization within a system."
            handleSave={handleSave}
            handleCancel={handleCancel}
            handleOtherActions={
              <Button
                disabled={action === "add" ? true : false}
                size="small"
                onClick={() => onHandleResetPassword()}
              >
                RESET PASSWORD
              </Button>
            }
          >
            <NtaiTextField
              name="userForm.userName"
              label="User Name*"
              placeholder="User Name"
              autoComplete="off"
              rules={{
                required: "User name is required",
                minLength: {
                  value: 3,
                  message: "User name must be >= 3 chars",
                },
                maxLength: {
                  value: 30,
                  message: "User name must be <= 30 chars",
                },
                validate: NtaiValidator.isUserName,
              }}
            />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: theme.spacing(1),
              }}
            >
              <NtaiTextField
                name="userForm.firstName"
                label="First Name*"
                placeholder="First Name"
                autoComplete="off"
                rules={{
                  required: "First name is required",
                  validate: NtaiValidator.isPersonName,
                }}
              />
              <NtaiTextField
                name="userForm.lastName"
                label="Last Name*"
                placeholder="Last Name"
                autoComplete="off"
                rules={{
                  required: "Last name is required",
                  validate: NtaiValidator.isPersonName,
                }}
              />
            </Box>

            {action === "add" && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: theme.spacing(1),
                }}
              >
                <NtaiTextField
                  name="userForm.password"
                  label="Password*"
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be >= 8 chars",
                    },
                    maxLength: {
                      value: 20,
                      message: "Password must be =< 20 chars",
                    },
                    validate: (val, formValues) => {
                      return NtaiValidator.isPassword(val);
                    },
                  }}
                />
                <NtaiTextField
                  name="userForm.confirmPassword"
                  label="Confirm Password*"
                  type="password"
                  placeholder="Confirm Password"
                  autoComplete="off"
                  rules={{
                    required: "Confirm Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be >= 8 chars",
                    },
                    maxLength: {
                      value: 20,
                      message: "Password must be <= 20 chars",
                    },
                    validate: (val, formValues) => {
                      if (
                        formValues["userForm"]["password"] !==
                        formValues["userForm"]["confirmPassword"]
                      ) {
                        return "Passwords do not match";
                      }

                      return NtaiValidator.isPassword(val);
                    },
                  }}
                />
              </Box>
            )}

            <NtaiTextField
              name="userForm.email"
              label="Email*"
              placeholder="Email"
              autoComplete="off"
              rules={{
                required: "Email is required",
                validate: NtaiValidator.isEmail,
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <NtaiSwitchField
                name="userForm.activeFg"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={faUserCheck} />
                    Active?
                  </Box>
                }
              />
              {action === "edit" && formData["accountLockedFg"] === 1 && (
                <NtaiSwitchField
                  name="userForm.accountLockedFg"
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        gap: theme.spacing(1),
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        color={formData["accountLockedFg"] === 1 ? "red" : null}
                        icon={
                          formData["accountLockedFg"] === 1
                            ? faLockSolid
                            : faLock
                        }
                      />
                      Account Locked?
                    </Box>
                  }
                />
              )}
            </Box>
          </NtaiFormPanel>
        </Box>
        {action === "edit" && (
          <Box
            sx={{
              borderLeft: theme.general.border1,
              flexBasis: "40%",
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <NtaiFormPanel
              header="Manage Roles"
              subheader="User role assignment involves allocating specific roles and associated permissions to individual users within the system."
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <Button
                  startIcon={<FontAwesomeIcon size="2xs" icon={faUserGroup} />}
                  variant="outlined"
                  size="small"
                  onClick={() => handleOpenManageRolesDialog()}
                >
                  Assign Roles
                </Button>
                <NtaiTitleItems
                  title="Assigned Roles"
                  items={
                    action === "edit"
                      ? NtaiUtils.getFieldArrayFromObject(
                          _.get(formData, "roleUuIds"),
                          rolesDataMap,
                          "name"
                        )
                      : []
                  }
                />
              </Box>
            </NtaiFormPanel>
          </Box>
        )}
      </Box>
      <NtaiDialog
        open={openManageRolesDialog}
        handleDialogClose={handleCloseManageRolesDialog}
        title="Assign Roles"
        size="sm"
      >
        <Box sx={{ p: theme.spacing(2) }}>
          <NtaiListTable
            handleAction={handleRolesSelection}
            actions={[
              { value: "cancel", label: "Cancel" },
              { value: "save", label: "Save", variant: "contained" },
            ]}
            keyField="uuId"
            primaryLabelField="name"
            secondaryLabelField="description"
            items={roles}
            selectedItems={_.get(formData, "roleUuIds")}
            actionAlignment="end"
          />
        </Box>
      </NtaiDialog>
      {action === "edit" && (
        <NtaiDialog
          size="sm"
          open={resetPasswordConfirmDialogOpen}
          handleDialogClose={handleResetPasswordConfirmDialogClose}
          title={`Confirm password reset for ${formData["userName"]}`}
        >
          <NtaiDialogFormPanel
            subheader={`Are you sure you want to reset password for user ${formData["userName"]}?`}
            handleSave={() => handleResetPassword()}
            handleCancel={() => handleResetPasswordConfirmDialogClose()}
            handleSaveLabel="Confirm"
          ></NtaiDialogFormPanel>
        </NtaiDialog>
      )}
    </>
  );
}
