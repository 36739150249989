import { Box, Button, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiTextField from "../inputs/NtaiTextField";

export default function NtaiPlayground() {
  const theme = useTheme();
  const [filters, setFilters] = React.useState([]);
  const { getValues } = useFormContext();

  function addItem() {
    const val = getValues("country");
    console.log("Add country: ", val);
    if (!filters.includes(val)) {
      setFilters([...filters, val]);
    }
  }

  function removeItem() {
    const val = getValues("country");
    console.log("Remove country: ", val);
    if (filters.includes(val)) {
      const revisedFilters = filters.filter((item) => item !== val);
      console.log("Revised filters (Removed): ", revisedFilters);
      setFilters(revisedFilters);
    }
  }

  useEffect(() => {
    console.log("Filters changed: ", filters);
  }, [filters]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: theme.spacing(1),
        gap: theme.spacing(1),
      }}
    >
      <NtaiTextField name="country" size="small" label="Country" />
      <Button onClick={() => addItem()}>Add Item</Button>
      <Button onClick={() => removeItem()}>Remove Item</Button>
      <Box>
        <ul>
          {filters.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      </Box>
    </Box>
  );
}
