import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_TableContainer,
  MRT_ToggleFiltersButton,
  MRT_TablePagination,
  useMaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_GlobalFilterTextField,
  MRT_ActionMenuItem,
  getMRT_RowSelectionHandler,
} from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/pro-regular-svg-icons";
import NtaiIconButton from "@ntai/components/buttons/NtaiIconButton";
import { Delete, Edit, RowingOutlined } from "@mui/icons-material";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import {
  faBars,
  faColumns3,
  faExpand,
  faPlus,
  faFilter,
  faFilterSlash,
} from "@fortawesome/pro-regular-svg-icons";

import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import {
  faArchive,
  faEllipsisV,
  faSquarePlus,
} from "@fortawesome/pro-solid-svg-icons";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiIcons from "utils/NtaiIcons";
import {
  faDeleteLeft,
  faEdit,
  faFileCsv,
  faFilePdf,
} from "@fortawesome/pro-light-svg-icons";
import history from "@ntai/@history";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

function getSelectedRow(rowSelection) {
  let rowSelectionArr = [];
  if (!_.isEmpty(rowSelection)) {
    rowSelectionArr = Object.keys(rowSelection).filter(function (key) {
      return rowSelection[key];
    });
  }
  return rowSelectionArr;
}

export default function SearchSourceWidgetNtaiTable(props) {
  const theme = useTheme();
  const [actionObj, setActionObj] = useState();
  const [rowSelection, setRowSelection] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const {
    id,
    tableTitle,
    rowTitle,
    rowTitlePlural,
    crud,
    exportOption,
    columns,
    data,
    enableStickyHeader,
    enableRowClick,
    topToolbarLeftActions,
    handleTopToolbarLeftAction,
    rowClickRedirect,
    path,
    handleRowClick,
    rowActionsType,
    rowButtonActions,
    rowMenuActions,
    handleRowAction,
    enableSearchText,
    enableToggleFilter,
    enableShowHideColumn,
  } = props;

  const CRUD_STD_ACTIONS = [
    {
      value: "create",
      label: `Create ${rowTitle}`,
      icon: faSquarePlus,
      variant: "contained",
      size: "small",
      textCase: "upper",
    },
    {
      value: "delete",
      label: `Delete ${rowTitle}`,
      icon: faArchive,
      size: "small",
      textCase: "upper",
      rowSelectionEnable: true,
      confirmAction: true,
    },
  ];

  const EXPORT_ACTIONS = [
    {
      value: "export",
      label: "Export",
      icon: faFilePdf,
      size: "small",
      textCase: "upper",
    },
  ];

  const revisedTopToolbarLeftActions =
    props.hasOwnProperty("topToolbarLeftActions") &&
    _.isArray(topToolbarLeftActions)
      ? [...topToolbarLeftActions]
      : [];

  let finalTopToolbarLeftActions =
    props.hasOwnProperty("crud") && crud === true
      ? [...CRUD_STD_ACTIONS, ...revisedTopToolbarLeftActions]
      : revisedTopToolbarLeftActions;

  finalTopToolbarLeftActions =
    props.hasOwnProperty("exportOption") && exportOption === true
      ? [...finalTopToolbarLeftActions, ...EXPORT_ACTIONS]
      : finalTopToolbarLeftActions;

  function handleConfirmDialogOpen() {
    setConfirmDialogOpen(true);
  }

  function handleConfirmDialogClose() {
    setConfirmDialogOpen(false);
    setRowSelection({});
    setActionObj(null);
  }

  function handleExport(rowsData) {
    const doc = new jsPDF();
    const tableData = rowsData.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  }

  function onHandleTopToolbarLeftAction(o) {
    const selectedRows = getSelectedRow(rowSelection);
    if (o["confirmAction"]) {
      setActionObj(o);
      handleConfirmDialogOpen();
    } else {
      if (crud && o["value"] === "create") {
        if (rowClickRedirect) {
          history.push(path + "/create");
        } else {
          handleTopToolbarLeftAction(o["value"], selectedRows);
          setRowSelection({});
        }
      } else if (exportOption && o["value"] === "export") {
        handleExport(table.getPrePaginationRowModel().rows);
      } else {
        handleTopToolbarLeftAction(o["value"], selectedRows);
        setRowSelection({});
      }
    }
  }

  function handleConfirmAction() {
    const selectedRows = getSelectedRow(rowSelection);
    handleTopToolbarLeftAction(actionObj["value"], selectedRows);
    setRowSelection({});
    handleConfirmDialogClose();
  }

  function onHandleRowClick(e, rowId) {
    if (!e.target.id.includes("action")) {
      if (rowClickRedirect) {
        history.push(path + "/" + rowId + "/edit");
      } else {
        handleRowClick(rowId);
      }
    }
  }

  function onHandleRowAction(e, action, rowId) {
    if (e.target.id.includes("action")) {
      handleRowAction(action, rowId);
    }
  }

  const table = useMaterialReactTable({
    columns,
    getRowId: (originalRow) => originalRow[id],
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableRowSelection: true,
    enableStickyHeader: props.hasOwnProperty("enableStickyHeader")
      ? enableStickyHeader
      : true,
    onRowSelectionChange: setRowSelection,
    enableRowActions:
      (rowButtonActions &&
        _.isArray(rowButtonActions) &&
        rowButtonActions.length > 0) ||
      (rowMenuActions && _.isArray(rowMenuActions) && rowMenuActions.length > 0)
        ? true
        : false,
    enableColumnActions: false,
    positionActionsColumn: "last",
    // muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
    //   onClick: (event) =>
    //     getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event), //import this helper function from material-react-table
    // }),

    renderRowActions:
      rowButtonActions &&
      _.isArray(rowButtonActions) &&
      rowButtonActions.length > 0
        ? ({ row }) => {
            return (
              <Box
                sx={{
                  pt: theme.spacing(0.5),
                  pb:
                    !props.hasOwnProperty("rowActionsType") ||
                    rowActionsType === "button"
                      ? theme.spacing(0.5)
                      : 0,
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  gap:
                    !props.hasOwnProperty("rowActionsType") ||
                    rowActionsType === "button"
                      ? 0
                      : theme.spacing(0.5),
                  alignItems: "center",
                }}
              >
                {(!props.hasOwnProperty("rowActionsType") ||
                  rowActionsType === "icon") &&
                  rowButtonActions.map((rba, i) => (
                    <Tooltip
                      title={rba["label"]}
                      key={`row-action-tooltip-iconbutton-${i}`}
                    >
                      <IconButton
                        sx={{
                          height: "24px",
                          width: "24px",
                          borderRadius: "6px",
                        }}
                        id={`row-action-iconbutton-${i}`}
                        key={`row-action-iconbutton-${i}`}
                        onClick={(e) =>
                          onHandleRowAction(e, rba["value"], row.original[id])
                        }
                      >
                        <FontAwesomeIcon size="2xs" icon={rba["icon"]} />
                      </IconButton>
                    </Tooltip>
                  ))}

                {(!props.hasOwnProperty("rowActionsType") ||
                  rowActionsType === "button") &&
                  rowButtonActions.map((rba, i) => (
                    <Button
                      size="small"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        fontSize: "11px",
                      }}
                      id={`row-action-button-${i}`}
                      key={`row-action-button-${i}`}
                      onClick={(e) =>
                        onHandleRowAction(e, rba["value"], row.original[id])
                      }
                    >
                      {rba.hasOwnProperty("icon") && (
                        <FontAwesomeIcon icon={rba["icon"]} />
                      )}
                      {rba["label"].toUpperCase()}
                    </Button>
                  ))}
              </Box>
            );
          }
        : null,

    renderRowActionMenuItems:
      rowMenuActions && _.isArray(rowMenuActions) && rowMenuActions.length > 0
        ? ({ row, table }) =>
            rowMenuActions.map((ra, i) => (
              <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
                id={`row-menu-action-${i}`}
                key={ra["value"]}
                label={ra["label"]}
                onClick={() => handleRowAction(ra["value"], row.original[id])}
                table={table}
              />
            ))
        : null,
    state: { rowSelection },
    enableGlobalFilter: true,
    muiTableContainerProps: {
      sx: {
        overflowX: "hidden",
      },
    },
    muiTableHeadCellProps: ({ column }) => {
      return {
        sx: {
          borderTop: theme.general.border1,
          color: grey[900],
          backgroundColor: grey[100],
          "&:hover": {
            color: grey[900],
          },
          // textAlign: column.columnDef.header === "Actions" ? "center" : "left",
        },
      };
    },
    muiColumnActionsButtonProps: {
      sx: {
        mr: theme.spacing(0.5),
        ml: theme.spacing(0.5),
        height: 24,
        width: 24,
      },
    },
    // muiPaginationProps: {
    //   component: "div",
    //   rowsPerPageOptions: [5, 10, 20],
    //   showFirstButton: false,
    //   showLastButton: false,
    // },

    muiTableBodyRowProps:
      !props.hasOwnProperty("enableRowClick") || enableRowClick
        ? ({ row }) => ({
            onClick: (e) => onHandleRowClick(e, row.original[id]),
            sx: {
              cursor: "pointer", //you might want to change the cursor too when adding an onClick
            },
          })
        : null,
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: {
          verticalAlign: "top",
          height: "auto",
          whiteSpace: "normal",
          wordWrap: "break-word",
          fontWeight: column.columnDef.primary ? 500 : null,
          color: column.columnDef.primary
            ? blue[700]
            : _.isArray(column.columnDef.colorSchemes) &&
              column.columnDef.colorSchemes.length > 0
            ? NtaiUtils.getColorFromColorSchemes(
                "fg",
                cell.getValue(),
                "default",
                column.columnDef.colorSchemes
              )
            : "default",
          textDecoration: column.columnDef.primary ? "underline" : null,
          background:
            _.isArray(column.columnDef.colorSchemes) &&
            column.columnDef.colorSchemes.length > 0
              ? NtaiUtils.getColorFromColorSchemes(
                  "bg",
                  cell.getValue(),
                  null,
                  column.columnDef.colorSchemes
                )
              : null,
        },
      };
    },

    initialState: {
      columnVisibility: { description: false },
      density: "compact",
      showGlobalFilter: true,
      // sorting: [{ id: sortColumnId, desc: false }],
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
      sx: {
        height: 28,
      },
      inputProps: {
        style: {
          paddingLeft: 6,
        },
      },
      InputProps: {
        height: 28,
        style: {
          height: 28,
          fontSize: "12px",
          ml: "12px",
        },
      },
    },
    icons: {
      CloseIcon: () => (
        <FontAwesomeIcon style={{ fontSize: "12px" }} icon={faClose} />
      ),
      SearchIcon: () => (
        <FontAwesomeIcon style={{ fontSize: "12px" }} icon={faSearch} />
      ),
      SearchOffIcon: () => (
        <FontAwesomeIcon style={{ fontSize: "12px" }} icon={faSearch} />
      ),
      DensitySmallIcon: () => <FontAwesomeIcon size="2xs" icon={faBars} />,
      DensityLargeIcon: () => <FontAwesomeIcon size="2xs" icon={faBars} />,
      DensityMediumIcon: () => <FontAwesomeIcon size="2xs" icon={faBars} />,
      FilterListIcon: () => (
        <FontAwesomeIcon
          style={{ fontSize: "12px" }}
          icon={faFilter}
          color="primary"
        />
      ),
      FilterListOffIcon: () => (
        <FontAwesomeIcon style={{ fontSize: "12px" }} icon={faFilterSlash} />
      ),
      ViewColumnIcon: () => (
        <FontAwesomeIcon
          style={{ fontSize: "12px" }}
          size="2xs"
          icon={faColumns3}
        />
      ),
      FullscreenIcon: () => <FontAwesomeIcon size="2xs" icon={faExpand} />,
      MoreHorizIcon: () => (
        <FontAwesomeIcon
          style={{ fontSize: "14px", fontWeight: "700" }}
          icon={faEllipsisV}
        />
      ),
    },
  });

  return (
    //   <MaterialReactTable table={table} />;
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          gap: theme.spacing(0.5),
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "36px",
            pb: theme.spacing(0.5),
            alignItems: "center",
            // borderTop: theme.general.border1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(0.5),
            }}
          >
            {props.hasOwnProperty("tableTitle") && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pr: theme.spacing(0.5),
                }}
              >
                <Typography variant="h6">{tableTitle}</Typography>
              </Box>
            )}
            {_.isArray(finalTopToolbarLeftActions) &&
              finalTopToolbarLeftActions.map((o, i) => (
                <Button
                  key={`table-toolbar-top-left-action-${i}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                  size={o["size"] ? o["size"] : "small"}
                  onClick={() => onHandleTopToolbarLeftAction(o)}
                  disabled={
                    o["rowSelectionEnable"] && _.isEmpty(rowSelection)
                      ? true
                      : false
                  }
                >
                  {o["icon"] && (
                    <FontAwesomeIcon icon={o["icon"] ? o["icon"] : null} />
                  )}
                  {o["label"].toUpperCase()}
                </Button>
              ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {(props.hasOwnProperty("enableSearchText")
              ? enableSearchText
              : true) && <MRT_GlobalFilterTextField table={table} />}
            {/* <MRT_ToggleGlobalFilterButton table={table} /> */}
            {(props.hasOwnProperty("enableToggleFilter")
              ? enableToggleFilter
              : true) && (
              <MRT_ToggleFiltersButton
                sx={{ height: 24, width: 24 }}
                table={table}
              />
            )}
            {(props.hasOwnProperty("enableShowHideColumn")
              ? enableShowHideColumn
              : true) && (
              <MRT_ShowHideColumnsButton
                sx={{ height: 24, width: 24 }}
                table={table}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: `calc(100% - 72px)`,
            width: "100%",
            // zIndex: 0,
          }}
        >
          <MRT_TableContainer table={table} />
        </Box>
        <Box
          sx={{
            height: "36px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <MRT_TablePagination table={table} />
        </Box>
      </Box>
      <NtaiDialog
        open={confirmDialogOpen}
        size="sm"
        title={`Confirm ${actionObj && actionObj["value"]}`}
        handleDialogClose={handleConfirmDialogClose}
      >
        <NtaiDialogFormPanel
          handleSave={handleConfirmAction}
          handleCancel={handleConfirmDialogClose}
          handleSaveLabel="Confirm"
        >
          {`Are you sure you want to ${
            actionObj && actionObj["value"]
          } these rows?`}
        </NtaiDialogFormPanel>
      </NtaiDialog>
    </React.Fragment>
  );
}
