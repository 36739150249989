import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReportTemplateForm from "./ReportTemplateForm";
import {
  setActiveId,
  updateReportTemplate,
  uploadReportTemplate,
  clearStatus,
  getReportTemplate,
} from "./store/reportTemplatesSlice";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function ReportTemplateEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const status = useSelector((state) => state.core.reportTemplatesSlice.status);
  const dataMap = useSelector((state) => state.core.reportTemplatesSlice.data);
  const activeId = useSelector(
    (state) => state.core.reportTemplatesSlice.activeId
  );
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateReportTemplate({ uuId: id, formData: formData }));
  }

  function handleFileUpload(formData, config) {
    dispatch(
      uploadReportTemplate({
        uuId: id,
        formData: formData,
        config: config,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getReportTemplate"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "updateReportTemplate"
    ) {
      enqueueSnackbar(
        `Report template "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "error" &&
      status.method === "updateReportTemplate"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
    dispatch(getReportTemplate(id));
  }, [id]);

  return (
    <NtaiPage
      back="/admin/templates/reports"
      title={`Report Templates / Edit ${_.get(formData, "name")}`}
    >
      {loaded && (
        <NtaiForm>
          <ReportTemplateForm
            action="edit"
            formData={formData}
            handleSave={handleSave}
            handleFileUpload={handleFileUpload}
          />
        </NtaiForm>
      )}
      {!loaded && <NtaiCircularProgress />}
    </NtaiPage>
  );
}
