import React from "react";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, InputBase, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  inputBase: {
    display: "flex",
    alignItems: "center",
    pl: "12px",
  },
}));

export default function NtaiSourceWidgetSearchBar() {
  const theme = useTheme();
  const classes = useStyles();

  function onHandleSearch() {}

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: theme.general.border1,
        borderRadius: theme.general.borderRadiusLg,
        height: "24px",
        px: "8px",
        background: grey[50],
      }}
    >
      <InputBase
        name="widgetname"
        inputProps={{ style: { fontSize: 10 } }}
        className={classes.inputBase}
      />
      <IconButton
        sx={{ height: "18px", width: "18px" }}
        onClick={() => onHandleSearch()}
      >
        <FontAwesomeIcon size="2xs" icon={faSearch} />
      </IconButton>
    </Box>
  );
}
