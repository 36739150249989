import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
const _ = require("lodash");

function getSearchSourceWidgetFilterFields(fieldsArray) {
  let result = [];
  if (_.isArray(fieldsArray)) {
    fieldsArray.forEach((fa, i) => {
      fa.forEach((f, j) => {
        result.push(f);
      });
    });
  }

  return result;
}

function generateSearchSourceWidgetTempFilters(
  searchSourceId,
  searchSourceTempFilters
) {
  let result = [];

  const searchSourceWidgetTempFilters = _.has(
    searchSourceTempFilters,
    searchSourceId
  )
    ? searchSourceTempFilters[searchSourceId]
    : {};

  if (
    searchSourceWidgetTempFilters &&
    !_.isEmpty(searchSourceWidgetTempFilters)
  ) {
    Object.keys(searchSourceWidgetTempFilters).forEach((key, i) => {
      result.push({
        primarySourceWidgetUuId: key,
        actionCode: 1,
        searchSourceWidgetFilterFields: getSearchSourceWidgetFilterFields(
          searchSourceWidgetTempFilters[key]
        ),
      });
    });
  }

  return result;
}

function useSearchSourceWidgetTempFilters(props) {
  const searchSourceTempFilters = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.tempFilters
  );

  const filters = generateSearchSourceWidgetTempFilters(
    props.searchSourceId,
    searchSourceTempFilters
  );

  return filters;
}

export default useSearchSourceWidgetTempFilters;
