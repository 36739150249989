import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

// am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

export default function NtaiHistogramStackedChart(props) {
  const theme = useTheme();

  const { data, seriesData } = props;

  useLayoutEffect(() => {
    let chart = am4core.create("histstackedchart", am4charts.XYChart);

    // am4core.InteractionObject.getInteraction("dd");
    // inter.contextMenuDisabled(true);

    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name, active) {
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      //   series.columns.template.tooltipText =
      //     "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

      //   let tActiveState = series.columns.template.states.create("active");
      //   tActiveState.properties.fill = "yellow";

      //   let tDefaultState = series.columns.template.states.create("default");
      //   tDefaultState.properties.fill = "#eee";

      if (active === "inactive")
        series.columns.template.adapter.add("fill", function (fill, target) {
          var pattern = new am4core.LinePattern();
          pattern.width = 7;
          pattern.height = 7;
          pattern.strokeWidth = 0.3;
          // pattern.stroke = target.dataItem.dataContext.color;
          pattern.rotation = 45;
          return pattern;
        });

      return series;
    }

    seriesData.forEach((s, i) => {
      createSeries(s, s, i === 0 ? "active" : "inactive");
    });

    // createSeries("EU", "EU", "active");

    // series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      <Box id={"histstackedchart"} sx={{ width: "100%", height: "100%" }}></Box>
    </>
  );
}
