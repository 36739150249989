import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createSourceFilterScheme,
  deleteSourceFilterScheme,
  getSourceFilterSchemes,
  updateSourceFilterScheme,
  setActiveId,
} from "./store/sourceFilterSchemesSlice";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SourceFilterSchemeForm from "./SourceFilterSchemeForm";
import SourceFilterSchemeFieldsWrapper1 from "./fields/SourceFilterSchemeFieldsWrapper";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "defaultFg",
    numeric: false,
    disablePadding: false,
    label: "Default?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
  },
  {
    id: "activeFg",
    numeric: false,
    disablePadding: false,
    label: "Active?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "delete",
  },
];

export default function SourceFilterSchemeList(props) {
  const dispatch = useDispatch();

  const [action, setAction] = useState("list");
  const [loaded, setLoaded] = React.useState(false);
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.data
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];
  const status = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.status
  );

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
    setAction("list");
  }

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }

    handleDialogOpen();
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") {
      confirmDelete();
      setAction(action);
    }
  }

  function handleCancel() {
    setAction("list");
    handleDialogClose();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "delete") {
        dispatch(
          deleteSourceFilterScheme({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      }
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction("list");
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceFilterScheme({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceFilterScheme({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            formData: formData,
          })
        );
  }

  function handleRowOtherAction(actionName, rowId) {
    setAction("manageFields");
    dispatch(setActiveId(rowId));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceFilterSchemes" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceFilterScheme" ||
        status.method === "updateSourceFilterScheme")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceFilterScheme"
    ) {
      setOpenConfirmDeleteDialog(false);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceFilterSchemes({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        params: { sourceUuId: sourceId },
      })
    );
  }, []);

  return (
    <>
      {(["list", "delete"].includes(action) || openDialog) && (
        <NtaiPanel
          width="75%"
          height="100%"
          header="Filter Schemes"
          subheader="Define and manage pre-defined set of filters that allow users to quickly apply common filtering criteria to their data analysis."
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              handleDialogAddEdit={handleDialogAddEdit}
              handleToolbarAction={handleToolbarAction}
              rowActions={[{ label: "Manage Fields", value: "fields" }]}
              handleRowAction={handleRowOtherAction}
              title="Filter Scheme"
            />
          )}
          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      )}
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />

      {openDialog && (
        <NtaiDialog
          open={openDialog}
          handleDialogClose={handleDialogClose}
          title={action === "add" ? "Add Filter Scheme" : "Edit Filter Scheme"}
          size="sm"
        >
          <SourceFilterSchemeForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiDialog>
      )}
      {action === "manageFields" && <SourceFilterSchemeFieldsWrapper1 />}
    </>
  );
}
