class EntityUtils {
  static getPrimaryFieldValue(entityData) {
    let result = "Not available";
    if (entityData["fields"]) {
      entityData["fields"].forEach((element) => {
        if (element["primaryFg"] == 1) {
          result = element["fieldValue"];
        }
      });
    }

    return result;
  }
}

export default EntityUtils;
