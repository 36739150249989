import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useState } from "react";
import SourceInStoreOcrPageForm from "./SourceInStoreOcrPageForm";
import {
  clearStatus,
  createSourceInStoreOcrPage,
  deleteSourceInStoreOcrPage,
  getSourceInStoreOcrPages,
  updateSourceInStoreOcrPage,
  uploadSourceInStoreOcrImageFile,
} from "./store/sourceInStoreOcrPagesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SourceInStoreOcrPageBox from "./SourceInStoreOcrPageBox";
import { grey } from "@mui/material/colors";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SourceInFileUploadForm from "../shared/SourceInFileUploadForm";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  createSourceInStoreOcrField,
  deleteSourceInStoreOcrField,
  updateSourceInStoreOcrField,
} from "./fields/store/sourceInStoreOcrFieldsSlice";
import SourceInStoreOcrFieldList from "./fields/SourceInStoreOcrFieldList";
import SourceInStoreOcrImageFields from "./fields/SourceInStoreOcrImageFields";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import NtaiImageArea from "@ntai/components/img-area/NtaiImageArea";
const _ = require("lodash");

export default function SourceInStoreOcrPage(props) {
  const theme = useTheme();

  const [pageDialogOpen, setPageDialogOpen] = useState(false);
  const [action, setAction] = useState();
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [uploadPending, setUploadPending] = useState(false);
  const [uploadData, setUploadData] = useState();
  const [selected, setSelected] = useState();
  const [loaded, setLoaded] = useState();
  const [imageAreas, setImageAreas] = useState();

  const dispatch = useDispatch();
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceInId = props.match.params.sourceinid;

  const dataMap = useSelector(
    (state) => state.sources.sourceInStoreOcrPagesSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceInStoreOcrPagesSlice.status
  );

  const activeId = useSelector(
    (state) => state.sources.sourceInStoreOcrPagesSlice.activeId
  );

  const data = Object.values(dataMap);

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceInId: sourceInId,
  };

  const handlePageDialogOpen = (id) => {
    setAction(id ? "edit" : "add");
    setCurrentId(id);
    setPageDialogOpen(true);
  };

  const handlePageDialogClose = () => {
    setPageDialogOpen(false);
    setAction(null);
  };

  function handleFormSubmit(formData) {
    action === "add"
      ? dispatch(
          createSourceInStoreOcrPage({
            ...pathVariables,
            formData: formData,
          })
        )
      : dispatch(
          updateSourceInStoreOcrPage({
            ...pathVariables,
            uuId: currentId,
            formData: formData,
          })
        );
  }

  function handleEdit(id) {
    handlePageDialogOpen(id);
  }

  function handleDelete(id) {
    dispatch(
      deleteSourceInStoreOcrPage({
        ...pathVariables,
        uuId: id,
      })
    );
  }

  function handleFileUploadDialogOpen() {
    setFileUploadDialogOpen(true);
  }

  function handleFileUploadDialogClose() {
    setFileUploadDialogOpen(false);
  }

  function onHandleFileUpload(formData, config) {
    setUploadData({ formData: formData, config: config });
  }

  function handleFileUpload() {
    dispatch(
      uploadSourceInStoreOcrImageFile({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceInId: sourceInId,
        uuId: selected,
        ...uploadData,
      })
    );
  }

  function handleSelect(id) {
    setSelected(id);
  }

  function generatePages() {
    const result = [];
    if (_.isArray(data) && data.length > 0) {
      data.forEach((d, i) => {
        result.push(
          <SourceInStoreOcrPageBox
            key={`source-ocr-page-${i}`}
            data={d}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            selected={selected}
            handleSelect={handleSelect}
          />
        );
      });
    }

    return result;
  }

  function handleImageAreasCallback(areas) {
    setImageAreas(areas);
  }

  function handleSaveFields() {
    dispatch(
      createSourceInStoreOcrField({
        ...pathVariables,
        sourceInStoreOcrPageId: selected,
        formData: {
          sourceInStoreOcrFields: imageAreas,
        },
      })
    );
  }

  function handleImageAreaSave(uuId, formData) {
    dispatch(
      updateSourceInStoreOcrField({
        ...pathVariables,
        uuId: uuId,
        formData: formData,
      })
    );
  }

  function handleImageAreaDelete(areaFieldUuId) {
    dispatch(
      deleteSourceInStoreOcrField({
        ...pathVariables,
        sourceInStoreOcrPageId: selected,
        uuId: areaFieldUuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      ["createSourceInStoreOcrPage", "updateSourceInStoreOcrPage"].includes(
        status.method
      )
    ) {
      handlePageDialogClose();
      dispatch(clearStatus());
      setSelected(activeId);
    }

    if (
      status &&
      status.result === "success" &&
      ["getSourceInStoreOcrPages"].includes(status.method)
    ) {
      setLoaded(true);

      if (data && data.length > 0) {
        setSelected(_.get(data[0], "uuId"));
      }

      dispatch(clearStatus());
    }

    if (status && ["uploadSourceInStoreOcrImageFile"].includes(status.method)) {
      if (status.result === "pending") setUploadPending(true);
      else if (status.result === "success") {
        setUploadPending(false);
        handleFileUploadDialogClose();
        dispatch(clearStatus());
      }
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceInStoreOcrPages(pathVariables));
  }, []);

  return (
    <React.Fragment>
      <NtaiPage
        title="OCR Page List"
        back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/ins`}
      >
        {loaded && dataMap && !_.isEmpty(dataMap) && (
          <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
            <Box
              sx={{
                flexBasis: "15%",
                display: "flex",
                height: "100%",
                borderRight: theme.general.border1,
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "44px",
                  display: "flex",
                  width: "100%",
                  borderBottom: theme.general.border1,
                  p: theme.spacing(2),
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                  Pages: {data.length}
                </Typography>
                <Button
                  sx={{ fontSize: "12px", fontWeight: 700 }}
                  size="small"
                  startIcon={<FontAwesomeIcon size="2xs" icon={faPlus} />}
                  onClick={() => handlePageDialogOpen(null)}
                >
                  PAGE
                </Button>
              </Box>

              <Box
                sx={{
                  height: `calc(100% - 44px)`,
                  display: "flex",
                  background: grey[50],
                }}
              >
                {loaded && (
                  <Scrollbar>
                    <Box
                      sx={{
                        p: theme.spacing(4),
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        gap: theme.spacing(2),
                      }}
                    >
                      {generatePages()}
                    </Box>
                  </Scrollbar>
                )}
                {!loaded && <NtaiCircularProgress vAlign="center" />}
              </Box>
            </Box>
            <Box
              sx={{
                flexBasis: "85%",
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "44px",
                  display: "flex",
                  borderBottom: theme.general.border1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: theme.spacing(2),
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {selected ? _.get(dataMap[selected], "name") : null}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: theme.spacing(1),
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                    }}
                  >
                    <IconButton>
                      <FontAwesomeIcon
                        size="2xs"
                        icon={faCircle}
                        color="orange"
                      />
                    </IconButton>
                    <IconButton>
                      <FontAwesomeIcon
                        size="2xs"
                        icon={faCircle}
                        style={{ fontWeight: 700 }}
                        color="yellow"
                      />
                    </IconButton>
                    <IconButton>
                      <FontAwesomeIcon
                        size="2xs"
                        icon={faCircle}
                        color="black"
                      />
                    </IconButton>
                    <IconButton>
                      <FontAwesomeIcon
                        size="2xs"
                        icon={faCircle}
                        color={grey[100]}
                      />
                    </IconButton>
                    <IconButton>
                      <FontAwesomeIcon
                        size="2xs"
                        icon={faCircle}
                        color="lime"
                      />
                    </IconButton>
                  </Box>
                  <FontAwesomeIcon icon={faEllipsisV} />
                  <Button size="small" onClick={() => handleSaveFields()}>
                    Save Fields
                  </Button>
                  <Button
                    component="label"
                    size="small"
                    onClick={(e) => handleFileUploadDialogOpen()}
                  >
                    Add Image
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  height: `calc(100% - 44px)`,
                  display: "flex",
                  width: "100%",
                }}
              >
                <SourceInStoreOcrImageFields
                  sourceInId={sourceInId}
                  sourceInStoreOcrPageUuId={selected}
                  handleImageAreaEdit={handleImageAreaSave}
                  handleImageAreaDelete={handleImageAreaDelete}
                  handleImageAreasCallback={handleImageAreasCallback}
                />
              </Box>
            </Box>
          </Box>
        )}
        {!dataMap ||
          (status &&
            status.result === "pending" &&
            status.method === "getSourceInStoreOcrPages" && (
              <NtaiCircularProgress vAlign="center" />
            ))}

        {loaded && _.isEmpty(dataMap) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <NtaiEmptyMessage
              header="No Pages available"
              subheader="Start building your OCR Plan by adding one or more pages"
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handlePageDialogOpen(null)}
              >
                Add Page
              </Button>
            </Box>
          </Box>
        )}
      </NtaiPage>
      <NtaiDialog
        title="Create Page"
        size="sm"
        open={pageDialogOpen}
        handleDialogClose={handlePageDialogClose}
      >
        <NtaiForm>
          <SourceInStoreOcrPageForm
            handleDialogClose={handlePageDialogClose}
            action={action}
            formData={currentId && action === "edit" ? dataMap[currentId] : {}}
            handleFormSubmit={handleFormSubmit}
          />
        </NtaiForm>
      </NtaiDialog>

      <NtaiDialog
        title="File Upload"
        open={fileUploadDialogOpen}
        handleDialogClose={handleFileUploadDialogClose}
        size="sm"
      >
        <NtaiForm>
          <SourceInFileUploadForm
            pending={uploadPending}
            handleCancel={handleFileUploadDialogClose}
            handleFileUpload={onHandleFileUpload}
            handleFormSubmit={handleFileUpload}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
