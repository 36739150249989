import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function SettingLabelValue(props) {
  const theme = useTheme();
  const { label, children } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1">{label}</Typography>
      {children}
    </Box>
  );
}
