import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import navigationConfig from "../../configs/navigationConfig";
const _ = require("lodash");

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    data: navigationConfig,
    activeNavigation: "default",
    selectedModule: null,
    leftSidebarNav: false,
    appHeader: false,
  },

  reducers: {
    setNavigation: (state, action) => {
      state.activeNavigation = action.payload["name"];
      state.leftSidebarNav = _.has(action.payload, "leftSidebar")
        ? action.payload["leftSidebar"]
        : true;
    },
    resetNavigation: (state, action) => {
      state.activeNavigation = "default";
    },
    setLeftSidebarNav: (state, action) => {
      state.leftSidebarNav = action.payload;
    },

    setAppHeader: (state, action) => {
      state.appHeader = action.payload;
    },

    setSelectedModule: (state, action) => {
      state.selectedModule = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setNavigation,
  setAppHeader,
  resetNavigation,
  setLeftSidebarNav,
  setSelectedModule,
} = navigationSlice.actions;

export default navigationSlice.reducer;
