import { useTheme } from "@mui/material";
import NtaiCronScheduler from "@ntai/components/cron/NtaiCronScheduler";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React from "react";

export default function SearchScheduleForm(props) {
  const theme = useTheme();
  const { handleSave, handleCancel } = props;

  const [localCronValue, setLocalCronValue] = React.useState();
  const [value, setValue] = React.useState();

  function onChange(val) {
    setLocalCronValue(val);
  }

  function onHandleSave() {}

  return (
    <NtaiDialogFormPanel
      subheader="Schedule this analysis using provided scheduling frequency and share the scheduled output with users and user groups."
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiCronScheduler
        value={value}
        onChange={onChange}
        showResultText={true}
      />
    </NtaiDialogFormPanel>
  );
}
