import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import SourceAdjudicationForm from "./SourceAdjudicationForm";
import {
  clearStatus,
  createSourceAdjudication,
} from "./store/sourceAdjudicationsSlice";
const _ = require("lodash");

export default function SourceAdjudicationCreate(props) {
  const { action, formData } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const dataMap = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.data
  );
  const activeId = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.activeId
  );
  const status = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.status
  );

  function handleSave(formData) {
    dispatch(
      createSourceAdjudication({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        formData: formData,
      })
    );
  }

  function handleCancel() {
    history.push(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceAdjudication" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Source assessment form "${_.get(
          dataMap[activeId],
          "adjObjectShortName"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${activeId}/edit`
      );
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`${_.get(sourceDefData, "name")} / Assessment Forms / Add`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications`}
    >
      <NtaiForm>
        <SourceAdjudicationForm
          action="add"
          handleSave={handleSave}
          handleCancel={handleCancel}
          formData={{}}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
