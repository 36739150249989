import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";

export default function NtaiSplitPane() {
  const theme = useTheme();
  const [sizes, setSizes] = useState([100, "30%", "auto"]);

  const layoutCSS = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <SplitPane split="vertical" sizes={sizes} onChange={setSizes}>
        <Pane minSize={50} maxSize="50%">
          <div style={{ ...layoutCSS, background: "#ddd" }}>pane1</div>
        </Pane>
        <div style={{ ...layoutCSS, background: "#d5d7d9" }}>pane2</div>
        <div style={{ ...layoutCSS, background: "#a1a5a9" }}>pane3</div>
      </SplitPane>
    </Box>
  );
}
