import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, IconButton, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { getCodelists } from "../../../admin/codelist/store/codelistsSlice";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-light-svg-icons";

const _ = require("lodash");

export default function ObjectFieldForm(props) {
  const theme = useTheme();
  const { action, handleCancel, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const fieldTypeOptions = _.get(
    codes,
    CODELIST_CODES.COR_META_FIELD_TYPE_CODE
  );
  const fieldUXOptions = _.get(codes, CODELIST_CODES.COR_META_FIELD_UI_CODE);

  const codelistDataMap = useSelector(
    (state) => state.core.codelistsSlice.data
  );

  const codelistData = Object.values(codelistDataMap);

  const codelistOptions = NtaiUtils.getSelectOptions(
    codelistData,
    "code",
    "name"
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("fieldForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ fieldForm: action === "edit" ? formData : {} });
  }, []);

  useEffect(() => {
    dispatch(
      getCodelists({ params: { codeGtEqThan: 50000 } })
      // getCodelists({
      //   params: {
      //     codeGtEqThan: 50000,
      //   },
      // })
    );
  }, []);

  return (
    <NtaiFormPanel
      width="100%"
      header={action === "add" ? "Add Field" : `Edit ${formData["fieldName"]}`}
      subheader="An object field (also called data member or member variable or data field) is a particular piece of data encapsulated within an object."
      handleSave={handleSave}
      handleCancel={handleCancel}
      headerActions={
        <IconButton onClick={() => handleCancel()}>
          <FontAwesomeIcon icon={faClose} />
        </IconButton>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
          <NtaiTextField
            name="fieldForm.fieldName"
            label="Name*"
            rules={{ required: "Field name is required" }}
          />

          <NtaiSwitchField
            name="fieldForm.fieldMandatoryFg"
            label="Mandatory?"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
          }}
        >
          <NtaiTextField
            name="fieldForm.fieldPlaceholder"
            label="Field Placeholder"
          />
          <NtaiTextField
            name="fieldForm.fieldLabel"
            label="Label*"
            rules={{ required: "Field label is required" }}
          />
        </Box>
        <NtaiTextField
          name="fieldForm.description"
          multiline
          minRows={2}
          maxRows={2}
          placeholder="Description"
          label="Description"
        />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <NtaiSwitchField name="fieldForm.fieldUiListingFg" label="Listing?" />
          <NtaiSwitchField name="fieldForm.primaryFg" label="Primary?" />
          <NtaiSwitchField name="fieldForm.secondaryFg" label="Secondary?" />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <NtaiSelectField
            name="fieldForm.fieldTypeCode"
            label="Database Column Type*"
            options={fieldTypeOptions}
            rules={{ required: "Column type is required" }}
          />
          <NtaiSelectField
            name="fieldForm.fieldUiCode"
            label="UI Type*"
            options={fieldUXOptions}
            rules={{ required: "Field UI type is required" }}
          />
          <NtaiSelectField
            name="fieldForm.fieldCodelistCode"
            options={codelistOptions}
            label="Codelist"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <NtaiTextField
            name="fieldForm.fieldUiWidth"
            label="UI Width*"
            type="number"
            defaultValue={100}
            min={10}
            max={100}
            rules={{ required: "UI width is required" }}
          />
        </Box>

        <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
          <NtaiTextField
            name="fieldForm.fieldLength"
            type="number"
            label="Field length*"
            rules={{ required: "Length is required" }}
          />
          <NtaiTextField
            name="fieldForm.fieldPrecision"
            type="number"
            label="Field precision"
          />
          <NtaiTextField
            name="fieldForm.fieldScale"
            type="number"
            label="Field scale"
          />
        </Box>
        <NtaiTextField
          name="fieldForm.fieldRules"
          multiline
          minRows={2}
          maxRows={2}
          placeholder="Validation Rules"
          label="Validation Rules"
        />
      </Box>
    </NtaiFormPanel>
  );
}
