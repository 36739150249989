import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";

const _ = require("lodash");

export default function SourceViewForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  // const displayTypeOptions = _.get(
  //   codelistData,
  //   CODELIST_CODES.SRC_SOURCE_DISPLAY_TYPE_CODE
  // );

  const typeOptions = _.get(
    codelistData,
    CODELIST_CODES.SRC_SOURCE_VIEW_TYPE_CODE
  );
  const categoryOptions = _.get(
    codelistData,
    CODELIST_CODES.SRC_SOURCE_VIEW_CATEGORY_CODE
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceViewForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    reset({ sourceViewForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="sourceViewForm.name"
          label="Name*"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          name="sourceViewForm.description"
          label="Description*"
          multiline
          minRows={2}
          maxRows={2}
          rules={{ required: "Description is required" }}
        />
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <NtaiSwitchField label="Default?" name="sourceViewForm.defaultFg" />
          <NtaiSwitchField label="Active?" name="sourceViewForm.activeFg" />
          <NtaiSwitchField label="Private?" name="sourceViewForm.privacyFg" />
        </Box>

        <NtaiSelectField
          label="Type*"
          options={typeOptions}
          name="sourceViewForm.typeCode"
          rules={{ required: "Type is required" }}
        />
        <NtaiSelectField
          label="Category"
          options={categoryOptions}
          name="sourceViewForm.categoryCode"
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
