import React, { useState } from "react";
import { OversightContext } from "../../../../Oversight";
import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import OversightTopicActionList from "./actions/OversightTopicActionList";
import Scrollbar from "@ntai/components/Scrollbar";
import OversightSnapshots from "./snapshots/OversightSnapshots";
import OversightTopicAdj from "./assessment/OversightTopicAdj";
import {
  OVERSIGHT_SECTIONS,
  OVERSIGHT_TOPIC_SECTIONS,
} from "../../../../constants/OversightConstants";
import OversightSourceRecordsWrapper from "./source-records/OversightSourceRecordsWrapper";
import OversightTopicNoteList from "./notes/OversightTopicNoteList";
import OversightContentHeader from "../header/OversightContentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-light-svg-icons";

export const OversightTopicContext = React.createContext();

export default function OversightTopicContent() {
  const theme = useTheme();
  const { section } = React.useContext(OversightContext);
  const [topicSection, setTopicSection] = useState(
    OVERSIGHT_TOPIC_SECTIONS.ASSESSMENTS
  );

  return (
    <OversightTopicContext.Provider value={{ topicSection, setTopicSection }}>
      <React.Fragment>
        {/* <Box
          sx={{
            px: theme.spacing(2),
            height: "36px",
            display: "flex",
            alignItems: "center",
            borderBottom: theme.general.border1,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">Routine Monitoring</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button
              sx={{
                fontSize: "10px",
                fontWeight: 500,
                gap: theme.spacing(1),
              }}
              size="small"
              color="inherit"
            >
              <FontAwesomeIcon size="xl" icon={faDownload} />
              EXPORT
            </Button>
          </Box>
        </Box> */}
        <OversightContentHeader />
        <Box
          sx={{ display: "flex", height: `calc(100% - 64px)`, width: "100%" }}
        >
          <Scrollbar>
            {topicSection === OVERSIGHT_SECTIONS.ASSESSMENTS && (
              <NtaiForm>
                <OversightTopicAdj />
              </NtaiForm>
            )}

            {topicSection === OVERSIGHT_SECTIONS.ACTIONS && (
              <OversightTopicActionList />
            )}

            {topicSection === OVERSIGHT_SECTIONS.NOTES && (
              <OversightTopicNoteList />
            )}

            {topicSection === OVERSIGHT_SECTIONS.SNAPSHOTS && (
              <NtaiForm>
                <OversightSnapshots />
              </NtaiForm>
            )}

            {topicSection === OVERSIGHT_SECTIONS.SOURCE_RECORDS && (
              <NtaiForm>
                <OversightSourceRecordsWrapper />
              </NtaiForm>
            )}
          </Scrollbar>
        </Box>
      </React.Fragment>
    </OversightTopicContext.Provider>
  );
}
