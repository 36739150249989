import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSummarySourceRecords = createAsyncThunk(
  "searchSummarySourceRecords/getSearchSummarySourceRecords",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, params } = values;
      const response = await server.get(
        `/search/${searchId}/summary/sources/${searchSourceId}/records`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSummarySourceRecord = createAsyncThunk(
  "searchSummarySourceRecords/getSearchSummarySourceRecord",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId } = values;
      const response = await server.get(
        `/search/${searchId}/summary/sources/${searchSourceId}/records/${searchSourceRecordId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordListings = createAsyncThunk(
  "searchSummarySourceRecords/getSearchSourceRecordListings",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSummarySourceRecordsSlice = createSlice({
  name: "searchSummarySourceRecords",
  initialState: {
    data: {},
    sourceRecordListingsData: {},
    activeId: null,
    status: {},
    sourceRecordListingsStatus: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSummarySourceRecords.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSummarySourceRecords",
      };
    },
    [getSearchSummarySourceRecords.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSummarySourceRecords",
        message: action.payload.message,
      };
    },
    [getSearchSummarySourceRecord.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSummarySourceRecord",
      };
    },
    [getSearchSummarySourceRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSummarySourceRecord",
        message: action.payload.message,
      };
    },

    [getSearchSourceRecordListings.fulfilled]: (state, action) => {
      state.sourceRecordListingsData = {
        ...state.sourceRecordListingsData,
        ..._.mapKeys(action.payload, "uuId"),
      };
      state.sourceRecordListingsStatus = {
        result: "success",
        method: "getSearchSourceRecordListings",
      };
    },
    [getSearchSourceRecordListings.rejected]: (state, action) => {
      state.sourceRecordListingsStatus = {
        result: "error",
        method: "getSearchSourceRecordListings",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSummarySourceRecordsSlice.actions;

export default searchSummarySourceRecordsSlice.reducer;
