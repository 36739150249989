import {
  faArrowRight,
  faCalendar,
  faCircle,
  faClock,
  faDiagramNext,
  faForward,
  faForwardStep,
  faPlay,
  faPlusCircle,
  faStar,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowLeft,
  faCalendarDays,
  faTimer,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import history from "@ntai/@history";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import AlertDetailsDrawer from "./drawer/AlertDetailsDrawer";
import { AlertEditContext } from "../../AlertEdit";
import { useContext } from "react";
import AlertActivityUsers from "./users/AlertActivityUsers";
import AlertDetailsBox from "./AlertDetailsBox";
import { green, grey, lime, orange, yellow } from "@mui/material/colors";
const _ = require("lodash");

export default function AlertDetails() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [titleFocus, setTitleFocus] = useState(false);

  const { gUuId } = useContext(AlertEditContext);

  const alertsDataMap = useSelector((state) => state.monitor.alertsSlice.data);

  const alertData = _.get(alertsDataMap, gUuId);

  function handleDrawerClose() {
    setOpenDrawer(false);
  }

  function handleSave(formData) {}

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flexBasis: "75%",
            display: "flex",
            alignItems: "center",
            pl: theme.spacing(2),
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              minWidth: "25%",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <IconButton onClick={() => history.push("/monitor/alerts")}>
              <FontAwesomeIcon size="xs" icon={faArrowLeft} />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={() => setOpenDrawer(true)}
              onMouseOver={() => setTitleFocus(true)}
              onMouseOut={() => setTitleFocus(false)}
            >
              <Typography variant="h6" fontWeight="700">
                {NtaiUtils.trunc(_.get(alertData, "alertName"), 20)}
              </Typography>
              <Typography variant="subtitle2" color="inherit" fontWeight="300">
                {NtaiUtils.trunc(
                  alertData.objectTopicName || alertData.dataTopicName,
                  25
                )}
              </Typography>
            </Box>
          </Box>

          <Divider orientation="vertical" flexItem />

          <AlertDetailsBox header="Entity" icon={faCircle}>
            {NtaiUtils.trunc(_.get(alertData, "entityName"), 18)}
          </AlertDetailsBox>

          <Divider orientation="vertical" flexItem />

          <AlertDetailsBox header="Topic" icon={faStar}>
            {NtaiUtils.trunc(
              alertData.objectTopicName || alertData.dataTopicName,
              25
            )}
          </AlertDetailsBox>

          <Divider orientation="vertical" flexItem />

          <AlertDetailsBox header="Current Activity" icon={faPlay}>
            {NtaiUtils.trunc(_.get(alertData, "activityName"), 20)}
          </AlertDetailsBox>

          <Divider orientation="vertical" flexItem />

          <AlertDetailsBox header="Next Activity" icon={faForward}>
            {_.isArray(alertData["nextActivityNames"]) &&
            alertData["nextActivityNames"].length > 0
              ? `${NtaiUtils.trunc(alertData["nextActivityNames"][0], 15)} ${
                  alertData["nextActivityNames"].length > 1 ? " +1" : ""
                }`
              : null}
          </AlertDetailsBox>

          <Divider orientation="vertical" flexItem />

          {/* <AlertDetailsBox header="Date Due" icon={faCalendarDays}>
            {alertData["activityDueDate"]
              ? NtaiUtils.formatDateCol(alertData["activityDueDate"])
              : "Not available"}
          </AlertDetailsBox> */}

          {/* <Divider orientation="vertical" flexItem /> */}
          {/* <Box
          sx={{
            p: theme.spacing(0.5),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AlertActivityUsers />
        </Box> */}

          {/* <AlertDetailsBox>
          <Tooltip title="Pending">
            <Chip
              variant="outlined"
              // sx={{ bgcolor: theme.colors.primary.main, color: "white" }}
              label={
                <Typography variant="h5">
                  {_.get(alertData, "numOfRecords")}
                </Typography>
              }
            />
          </Tooltip>
        </AlertDetailsBox> */}
        </Box>
        <Box
          sx={{
            height: "100%",
            flexBasis: "25%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexBasis: "33%",
              px: theme.spacing(1),
              height: "100%",
              display: "flex",
              bgcolor: green[100],
              color: "black",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight={700} color="inherit">
              {alertData["numOfAssignedRecords"]}
            </Typography>
            <Typography variant="subtitle2" fontWeight={300} color="inherit">
              Assigned
            </Typography>
          </Box>

          <Box
            sx={{
              flexBasis: "33%",
              px: theme.spacing(1),
              height: "100%",
              display: "flex",
              bgcolor: orange[100],
              color: "black",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight={700} color="inherit">
              {alertData["numOfRecords"] - alertData["numOfAssignedRecords"]}
            </Typography>
            <Typography variant="subtitle2" fontWeight={300} color="inherit">
              Not Assigned
            </Typography>
          </Box>

          <Box
            sx={{
              flexBasis: "33%",
              px: theme.spacing(1),
              height: "100%",
              display: "flex",
              bgcolor: grey[100],
              color: "black",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight={700} color="inherit">
              {alertData["numOfRecords"]}
            </Typography>
            <Typography variant="subtitle2" fontWeight={300} color="inherit">
              Total Items
            </Typography>
          </Box>
        </Box>
      </Box>

      <Drawer
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openDrawer}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{
            width: "475px",
            zIndex: (theme) => theme.zIndex.drawer,
          }}
        >
          <AlertDetailsDrawer handleClose={handleDrawerClose} />
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
