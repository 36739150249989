import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAwsCluster, clearStatus } from "./store/awsClustersSlice";
import AwsClusterForm from "./AwsClusterForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AwsClusterCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const dataMap = useSelector((state) => state.core.awsClustersSlice.data);
  const status = useSelector((state) => state.core.awsClustersSlice.status);
  const activeId = useSelector((state) => state.core.awsClustersSlice.activeId);

  function handleSave(formData) {
    dispatch(createAwsCluster(formData));
  }

  function handleCancel() {
    history.push(`/aws-clusters`);
  }

  useEffect(() => {
    if (status.method === "createAwsCluster" && status.result === "success") {
      enqueueSnackbar(
        `Aws cluster "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/aws-clusters/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Aws Cluster" back="/aws-clusters">
      <NtaiForm>
        <AwsClusterForm
          action="add"
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
