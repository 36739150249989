import { Badge, Box, Drawer, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import {
  clearStatus,
  getSourceCatalogAssetViews,
} from "./store/sourceCatalogAssetViewsSlice";
import SourceCatalogAsset from "./SourceCatalogAsset";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartArea,
  faDatabase,
  faInputText,
  faTable,
  faText,
} from "@fortawesome/pro-light-svg-icons";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";

const _ = require("lodash");

function displayTypeIcon(typeCode) {
  if (typeCode === 1) return <FontAwesomeIcon icon={faDatabase} />;
  else if (typeCode === 2) return <FontAwesomeIcon icon={faDatabase} />;
  else if (typeCode === 3) return <FontAwesomeIcon icon={faTable} />;
  else return <FontAwesomeIcon icon={faInputText} />;
}

const toolbarActions = [
  {
    label: "Export",
    icon: "check",
    value: "export",
    variant: "contained",
  },
];

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "typeCode",
    numeric: false,
    disablePadding: true,
    label: "T",
    transformFunc: displayTypeIcon,
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: "700",
    width: "20%",
  },
  {
    id: "sourceName",
    numeric: false,
    disablePadding: false,
    label: "Source",
    width: "20%",
  },
  {
    id: "sourceVersion",
    numeric: false,
    disablePadding: false,
    label: "Version",
    width: "10%",
  },
  {
    id: "datasetName",
    numeric: false,
    disablePadding: false,
    label: "Dataset",
    width: "20%",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "10%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified by",
    width: "10%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
    width: "10%",
  },
];

function getFilterLabel(filter) {
  if (filter === "0") return "All Assets";
  else if (filter === "1") return "Sources";
  else if (filter === "2") return "Source Versions";
  else if (filter === "3") return "Datasets and Tables";
  else if (filter === "4") return "Fields";
}

export default function SourceCatalogAssetViewList(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [action, setAction] = useState();
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [assetUuId, setAssetUuId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const filter = props.match.params.filter;

  const dataMap = useSelector(
    (state) => state.sources.sourceCatalogAssetViewsSlice.data
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(
          Object.values(dataMap),
          ["sourceName", "sourceVersion", "typeDecode", "datasetName"],
          ["asc", "asc", "asc", "asc"]
        )
      : [];

  const status = useSelector(
    (state) => state.sources.sourceCatalogAssetViewsSlice.status
  );

  function handleAction(id) {
    setOpenDrawer(true);
    setAssetUuId(id);
  }

  function handleRowAction(menuAction, recordId) {
    // if (menuAction === "manage") {
    //   setSidePanelContext("manage");
    // }
    // setSelectedRecordId(recordId);
    // dispatch(setActiveId(recordId));
    // setExpandSidePanel(true);
  }

  function handleToolbarAction(actionName, selectedRecords) {
    // setAction(actionName);
    // setSelectedRecords(selectedRecords);
    // if (actionName === "complete") setOpenCompleteActivityDialog(true);
    // if (actionName === "terminate") {
    //   // console.log("Terminate...");
    // }
  }

  function handleClose() {
    setOpenDrawer(false);
    setAssetUuId(null);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceCatalogAssetViews" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceCatalogAssetViews(filter));
  }, [filter]);

  return (
    <React.Fragment>
      <NtaiPage
        padding={2}
        title={`Source Catalog Assets | ${getFilterLabel(filter)}`}
        back="/source-catalog"
      >
        {loaded && data.length > 0 && (
          <NtaiSimpleTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            handleToolbarAction={handleToolbarAction}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleAction}
            title={`${getFilterLabel(filter)} (${data.length})`}
            pageSize={10}
          />
        )}
        {!loaded && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NtaiCircularProgress size={24} />
          </Box>
        )}
        {loaded && data.length === 0 && (
          <NtaiEmptyMessage
            header="No assets available"
            vAlign="center"
            subheader="Source assets will appear automatically"
          />
        )}
      </NtaiPage>

      <Drawer
        PaperProps={{
          style: {
            top: "48px",
          },
        }}
        open={openDrawer}
        onClose={handleClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
      >
        {assetUuId && (
          <SourceCatalogAsset
            asset={dataMap[assetUuId]}
            handleCancel={handleClose}
          />
        )}
      </Drawer>
    </React.Fragment>
  );
}
