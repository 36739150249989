import { Box, Typography, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "../../sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";

import UserDatasetDateCriteria from "./UserDatasetDateCriteria";

import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function UserDatasetCriteria({
  action,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { watch, getValues, reset } = useFormContext();
  const [criteria, setCriteria] = useState();
  const [esCriteria, setEsCriteria] = useState();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);

  const [fields, setFields] = useState();
  const [mergedFields, setMergedFields] = useState();

  const userDatasetId = useSelector(
    (state) => state.core.userDatasetsSlice.activeId
  );
  const userDatasetDataMap = useSelector(
    (state) => state.core.userDatasetsSlice.data
  );

  const userDatasetData = _.get(userDatasetDataMap, userDatasetId);

  const sourceUuId = _.get(userDatasetData, "sourceMin.uuId");
  const sourceDefUuId = _.get(userDatasetData, "sourceMin.sourceDefUuId");

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  const queryValue = _.get(userDatasetData, "uiCriteria");

  function buildQuery(tree, config, jsonTree, formats) {
    setCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  function onHandleResetDateCriteria() {
    const userDatasetDataCopy = _.cloneDeep(userDatasetData);
    userDatasetDataCopy["dateCriteriaFieldUuId"] = null;
    userDatasetDataCopy["dateCriteriaFrom"] = null;
    userDatasetDataCopy["dateCriteriaTo"] = null;
    userDatasetDataCopy["dateCriteriaTypeCode"] = null;
    userDatasetDataCopy["dateCriteriaPeriodCode"] = null;
    userDatasetDataCopy["dateCriteriaNumOfPeriods"] = null;
    handleSave(userDatasetDataCopy);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("userDatasetForm")
    );

    const userDatasetDataCopy = _.cloneDeep(userDatasetData);
    const nativeCriteria = getValues("userDatasetForm.nativeCriteria");
    userDatasetDataCopy["uiCriteria"] = criteria;
    userDatasetDataCopy["uiEsCriteria"] = esCriteria;
    userDatasetDataCopy["nativeCriteria"] = nativeCriteria;
    userDatasetDataCopy["dateCriteriaTypeCode"] =
      sanitizedFormData["dateCriteriaTypeCode"];
    userDatasetDataCopy["dateCriteriaFieldUuId"] =
      sanitizedFormData["dateCriteriaFieldUuId"];
    userDatasetDataCopy["dateCriteriaPeriodCode"] =
      sanitizedFormData["dateCriteriaPeriodCode"];
    userDatasetDataCopy["dateCriteriaNumOfPeriods"] =
      sanitizedFormData["dateCriteriaNumOfPeriods"];

    userDatasetDataCopy["dateCriteriaFrom"] =
      sanitizedFormData["dateCriteriaFrom"];
    userDatasetDataCopy["dateCriteriaTo"] = sanitizedFormData["dateCriteriaTo"];

    handleSave(userDatasetDataCopy);
  }

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefUuId,
        sourceUuId,
        mergedFields
      );

      setFields({ ...tmpFields });
      setMergedFields([...mergedFields]);
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    setCriteria(userDatasetData["uiCriteria"]);
    setEsCriteria(userDatasetData["uiEsCriteria"]);
    // reset({
    //   userDatasetCriteriaForm: action === "edit" ? userDatasetData : null,
    // });
  }, [userDatasetData]);

  useEffect(() => {
    dispatch(
      getSourceFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
    dispatch(
      getSourceDerivedFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
  }, [sourceUuId]);

  return (
    <React.Fragment>
      {fieldsLoaded && _.isArray(mergedFields) && mergedFields.length > 0 && (
        <NtaiPanel width="100%">
          <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexBasis: "60%",
                  flexDirection: "column",
                  paddingBottom: theme.spacing(1),
                }}
              >
                <NtaiFormPanel
                  padding="0px"
                  width="100%"
                  header="Query Criteria"
                  subheader="Build query criteria using source fields"
                  handleSave={onHandleSave}
                  handleCancel={handleCancel}
                >
                  {userDatasetData["queryModeFg"] === 1 &&
                    fields &&
                    Object.keys(fields).length !== 0 && (
                      <NtaiQueryBuilder
                        fields={fields}
                        buildQuery={buildQuery}
                        value={queryValue}
                      />
                    )}
                  {userDatasetData["queryModeFg"] === 2 && (
                    <NtaiTextField
                      name="userDatasetForm.nativeCriteria"
                      multiline
                      minRows={10}
                      maxRows={10}
                    />
                  )}
                </NtaiFormPanel>
              </Box>
              <Box sx={{ flexBasis: "40%", display: "flex" }}>
                <UserDatasetDateCriteria
                  formName="userDatasetForm"
                  fields={mergedFields}
                  formData={userDatasetData}
                  handleResetDateCriteria={onHandleResetDateCriteria}
                />
              </Box>
            </React.Fragment>
          </Box>
        </NtaiPanel>
      )}
      {!fieldsLoaded && (
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <NtaiCircularProgress vAlign="center" size={24} />
        </Box>
      )}
    </React.Fragment>
  );
}
