import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { getSourceDefinitions } from "../../sourcedefinition/store/sourceDefinitionsSlice";
import UserDatasetCriteria from "./UserDatasetCriteria";
import UserDatasetFilterSchemes from "./filter-scheme/UserDatasetFilterSchemes";
import { getCodes } from "../../admin/codelist/store/codelistsSlice";
import UserDatasetData from "./records/UserDatasetRecords";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import UserDatasetSourceViews from "./source-view/UserDatasetSourceViews";
const _ = require("lodash");

function getSourceOptions(sourceDefData) {
  const result = [];
  sourceDefData.forEach((sd, i) => {
    sd.sourceMins.forEach((sdsm, j) => {
      result.push({
        value: sdsm.uuId,
        label: sdsm.displayName,
      });
    });
  });

  return result;
}

export default function UserDatasetForm({ action, formData, handleSave }) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();
  const codes = useSelector((state) => state.core.codelistsSlice.codes);

  const datasetTypeOptions =
    codes && _.has(codes, CODELIST_CODES.LIB_DATASET_TYPE)
      ? _.get(codes, CODELIST_CODES.LIB_DATASET_TYPE)
      : [];

  const sourceDefinitionsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionsSliceStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const sourceDefData = Object.values(sourceDefinitionsDataMap);

  const sourceOptions =
    sourceDefData && _.isArray(sourceDefData) && sourceDefData.length > 0
      ? getSourceOptions(sourceDefData)
      : [];

  const activeId = formData && !_.isEmpty(formData) ? formData["uuId"] : null;

  const userDatasetDataMap = useSelector(
    (state) => state.core.userDatasetsSlice.data
  );

  const userDatasetData = Object.values(userDatasetDataMap);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("userDatasetForm")
    );

    sanitizedFormData["uiCriteria"] = formData["uiCriteria"];
    sanitizedFormData["uiEsCriteria"] = formData["uiEsCriteria"];
    sanitizedFormData["nativeCriteria"] = formData["nativeCriteria"];
    sanitizedFormData["dateCriteriaTypeCode"] =
      formData["dateCriteriaTypeCode"];
    sanitizedFormData["dateCriteriaFieldUuId"] =
      formData["dateCriteriaFieldUuId"];
    sanitizedFormData["dateCriteriaPeriodCode"] =
      formData["dateCriteriaPeriodCode"];
    sanitizedFormData["dateCriteriaNumOfPeriods"] =
      formData["dateCriteriaNumOfPeriods"];

    sanitizedFormData["dateCriteriaFrom"] = formData["dateCriteriaFrom"];
    sanitizedFormData["dateCriteriaTo"] = formData["dateCriteriaTo"];

    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/library/datasets`);
  }

  useEffect(() => {
    dispatch(getSourceDefinitions());
    dispatch(getCodes(CODELIST_CODES.LIB_DATASET_TYPE));
    reset({ userDatasetForm: action === "edit" ? formData : {} });
  }, [formData]);

  return (
    <NtaiTabs>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Information"
          subheader="Enter dataset name, description, source, criteria mode and optionally select random sampling and size parameters"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="userDatasetForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />
          <NtaiTextField
            name="userDatasetForm.description"
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Description"
            label="Description*"
            rules={{ required: "Description is required" }}
          />
          <NtaiSelectField
            label="Source*"
            name="userDatasetForm.sourceUuId"
            options={sourceOptions}
            isDisabled={action === "add" ? false : true}
            rules={{ required: "Source is required" }}
          />
          <NtaiSelectField
            label="Criteria Mode*"
            name="userDatasetForm.queryModeFg"
            options={[
              { value: 1, label: "Query Builder" },
              { value: 2, label: "Native Query" },
            ]}
            isDisabled={action === "add" ? false : true}
            rules={{ required: "Criteria mode is required" }}
          />
          <NtaiSelectField
            label="Type"
            name="userDatasetForm.typeCode"
            options={datasetTypeOptions}
          />
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexBasis: "50%", display: "flex" }}>
              <NtaiSwitchField
                label="Random Sampling?"
                name="userDatasetForm.randomSamplingFg"
              />
            </Box>
            <Box sx={{ flexBasis: "50%", display: "flex" }}>
              <NtaiSelectField
                name="userDatasetForm.randomSamplingAlgorithmCode"
                defaultValue={1}
                options={[
                  { value: 1, label: "Gamma Distribution" },
                  { value: 2, label: "Exponential" },
                  { value: 3, label: "Linear" },
                ]}
              />
            </Box>

            <Box sx={{ flexBasis: "20%", display: "flex" }}>
              <NtaiTextField
                name="userDatasetForm.size"
                label="Size"
                type="number"
              />
            </Box>
          </Box>
        </NtaiFormPanel>
      </Box>
      <Box
        sx={{
          height: "auto",
          display: "flex",
          justifyContent: "start",
        }}
        label="CRITERIA"
        disabled={action === "add" ? true : false}
      >
        {action === "edit" &&
        userDatasetDataMap[activeId].queryModeFg &&
        [1, 2].includes(userDatasetDataMap[activeId].queryModeFg) ? (
          <UserDatasetCriteria
            action={action}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="FILTER SCHEMES"
        disabled={action === "add" ? true : false}
      >
        <UserDatasetFilterSchemes />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="SOURCE VIEWS"
        disabled={action === "add" ? true : false}
      >
        <UserDatasetSourceViews />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="DATA"
        disabled={action === "add" ? true : false}
      >
        <UserDatasetData />
      </Box>
    </NtaiTabs>
  );
}
