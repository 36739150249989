import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteSourceReportTemplate,
  getSourceReportTemplates,
} from "./store/sourceReportTemplatesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceReportTemplateEdit from "./SourceReportTemplateEdit";
import SourceReportTemplateCreate from "./SourceReportTemplateCreate";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: "700",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "extSourceTemplateFg",
    numeric: false,
    disablePadding: false,
    label: "External?",
    transformFunc: function (o) {
      return o && o === 1 ? "Yes" : "No";
    },
  },

  {
    id: "sourceMins",
    numeric: false,
    disablePadding: false,
    label: "Source (s)",
    transformFunc: function (o) {
      return o
        .map((o) => {
          return o["displayName"];
        })
        .join(", ");
    },
  },
  {
    id: "paramFieldName",
    numeric: false,
    disablePadding: false,
    label: "Param Field",
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Created",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "delete",
  },
];

export default function SourceReportTemplateList(props) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];
  const status = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.status
  );

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") {
      confirmDelete();
      setAction(action);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "delete") {
        dispatch(
          deleteSourceReportTemplate({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      }
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction("list");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceReportTemplates" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceReportTemplate"
    ) {
      setOpenConfirmDeleteDialog(false);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceReportTemplates({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <>
      {["list", "delete"].includes(action) && (
        <NtaiPanel
          width="75%"
          header="Reports"
          subheader="Define and manage source report templates for individual source record and aggregate record listing. You can also access report templates from other sources."
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              handleDialogAddEdit={handleDialogAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Report"
            />
          )}
          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      )}

      {action === "add" && (
        <SourceReportTemplateCreate
          handleCancel={handleCancel}
          redirectToList={() => setAction("list")}
        />
      )}
      {action === "edit" && currentId && (
        <SourceReportTemplateEdit
          id={currentId}
          handleCancel={handleCancel}
          formData={dataMap[currentId]}
        />
      )}

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "reportTemplateName"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
