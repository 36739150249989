import React, { useState } from "react";
import NtaiCronScheduler from "./NtaiCronScheduler";
import { Box, Button, useTheme } from "@mui/material";

const defaultValue = "0 0 00 1/1 * ? *";

export default function TestNtaiCronScheduler() {
  const theme = useTheme();
  const [localCronValue, setLocalCronValue] = useState(defaultValue);

  function onChange(val) {
    setLocalCronValue(val);
  }

  function handleSave() {
    console.log("Save this: ", localCronValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        p: theme.spacing(2),
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <NtaiCronScheduler
        value={defaultValue}
        onChange={onChange}
        showResultText={true}
      />
      <Button onClick={() => handleSave()}>SUBMIT</Button>
    </Box>
  );
}
