import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceStages = createAsyncThunk(
  "sourcePipelines/getSourceStages",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceStage = createAsyncThunk(
  "sourcePipelines/getSourceStage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceStage = createAsyncThunk(
  "sourceStages/createSourceStage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceStage = createAsyncThunk(
  "sourceStages/updateSourceStage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceStage = createAsyncThunk(
  "sourceStages/deleteSourceStage",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceStagesSlice = createSlice({
  name: "sourceStages",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
      state.message = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceStages.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceStages" };
    },
    [getSourceStages.rejected]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "error",
        method: "getSourceStages",
        message: action.payload.message,
      };
    },
    [getSourceStage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload["uuId"]]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceStage",
      };
    },
    [getSourceStage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceStage",
        message: action.payload.message,
      };
    },

    [createSourceStage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload["uuId"]]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceStage",
      };
    },
    [createSourceStage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceStage",
        message: action.payload.message,
      };
    },

    [updateSourceStage.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceStage" };
    },
    [updateSourceStage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceStage",
        message: action.payload.message,
      };
    },

    [deleteSourceStage.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceStage" };
    },
    [deleteSourceStage.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceStage",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceStagesSlice.actions;

export default sourceStagesSlice.reducer;
