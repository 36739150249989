import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getThesaurusConcepts = createAsyncThunk(
  "thesaurusConcepts/getThesaurusConcepts",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, params } = values;
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/concepts`,
        params ? { params: params } : null
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRelThesaurusConcepts = createAsyncThunk(
  "thesaurusConcepts/getRelThesaurusConcepts",
  async (values, { rejectWithValue }) => {
    const { thesaurusId, params } = values;
    try {
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/concepts`,
        { params: params }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getThesaurusConcept = createAsyncThunk(
  "thesaurusConcepts/getThesaurusConcept",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId } = values;
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createThesaurusConcept = createAsyncThunk(
  "thesaurusConcepts/createThesaurusConcept",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, formData } = values;
      const response = await server.post(
        `/library/thesauruses/${thesaurusId}/concepts`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateThesaurusConcept = createAsyncThunk(
  "thesaurusConcepts/updateThesaurusConcept",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, formData } = values;
      const response = await server.patch(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteThesaurusConcept = createAsyncThunk(
  "thesaurusConcepts/deleteThesaurusConcept",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, params } = values;
      const response = await server.delete(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}`,
        {
          params: params,
        }
      );
      return { conceptId: conceptId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const thesaurusConceptsSlice = createSlice({
  name: "thesaurusConcepts",
  initialState: {
    data: {},
    relConceptsData: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getThesaurusConcepts.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "conceptId") };
      state.status = { result: "success", method: "getThesaurusConcepts" };
    },
    [getThesaurusConcepts.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusConcepts",
        message: action.payload.message,
      };
    },
    [getRelThesaurusConcepts.fulfilled]: (state, action) => {
      state.relConceptsData = { ..._.mapKeys(action.payload, "conceptId") };
      state.status = {
        result: "success",
        method: "getRelThesaurusConcepts",
      };
    },
    [getRelThesaurusConcepts.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getRelThesaurusConcepts",
        message: action.payload.message,
      };
    },

    [getThesaurusConcept.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.conceptId]: action.payload,
      };
      state.activeId = action.payload.conceptId;
      state.status = { result: "success", method: "getThesaurusConcept" };
    },
    [getThesaurusConcept.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusConcept",
        message: action.payload.message,
      };
    },
    [createThesaurusConcept.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.conceptId]: action.payload,
      };
      state.activeId = action.payload.conceptId;
      state.status = { result: "success", method: "createThesaurusConcept" };
    },
    [createThesaurusConcept.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createThesaurusConcept",
        message: action.payload.message,
      };
    },
    [updateThesaurusConcept.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.conceptId]: action.payload,
      };
      state.status = { result: "success", method: "updateThesaurusConcept" };
    },
    [updateThesaurusConcept.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateThesaurusConcept",
        message: action.payload.message,
      };
    },
    [deleteThesaurusConcept.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.conceptId);
      state.status = { result: "success", method: "deleteThesaurusConcept" };
    },
    [deleteThesaurusConcept.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteThesaurusConcept",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = thesaurusConceptsSlice.actions;

export default thesaurusConceptsSlice.reducer;
