import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getThesaurusConceptSynonyms = createAsyncThunk(
  "thesaurusThesaurusConceptSynonyms/getThesaurusConceptSynonyms",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, semTypeId } = values;
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/synonyms`,
        {
          params: {
            semTypeId: semTypeId,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getThesaurusConceptSynonym = createAsyncThunk(
  "thesaurusThesaurusConceptSynonyms/getThesaurusConceptSynonym",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, uuId } = values;
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/synonyms/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createThesaurusConceptSynonym = createAsyncThunk(
  "thesaurusThesaurusConceptSynonyms/createThesaurusConceptSynonym",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, formData } = values;
      const response = await server.post(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/synonyms`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateThesaurusConceptSynonym = createAsyncThunk(
  "thesaurusThesaurusConceptSynonyms/updateThesaurusConceptSynonym",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, uuId, formData } = values;
      const response = await server.patch(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/synonyms/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteThesaurusConceptSynonym = createAsyncThunk(
  "thesaurusThesaurusConceptSynonyms/deleteThesaurusConceptSynonym",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, termId, params } = values;
      const response = await server.delete(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/synonyms/${termId}`,
        {
          params: params,
        }
      );
      return { termId: termId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const thesaurusThesaurusConceptSynonymsSlice = createSlice({
  name: "thesaurusThesaurusConceptSynonyms",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getThesaurusConceptSynonyms.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "termId") };
      state.status = {
        result: "success",
        method: "getThesaurusConceptSynonyms",
      };
    },
    [getThesaurusConceptSynonyms.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusConceptSynonyms",
        message: action.payload.message,
      };
    },
    [getThesaurusConceptSynonym.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.termId]: action.payload,
      };
      state.activeId = action.payload.termId;
      state.status = {
        result: "success",
        method: "getThesaurusConceptSynonym",
      };
    },
    [getThesaurusConceptSynonym.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusConceptSynonym",
        message: action.payload.message,
      };
    },
    [createThesaurusConceptSynonym.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.termId]: action.payload,
      };
      state.activeId = action.payload.termId;
      state.status = {
        result: "success",
        method: "createThesaurusConceptSynonym",
      };
    },
    [createThesaurusConceptSynonym.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createThesaurusConceptSynonym",
        message: action.payload.message,
      };
    },
    [updateThesaurusConceptSynonym.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.termId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateThesaurusConceptSynonym",
      };
    },
    [updateThesaurusConceptSynonym.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateThesaurusConceptSynonym",
        message: action.payload.message,
      };
    },
    [deleteThesaurusConceptSynonym.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.termId);
      state.status = {
        result: "success",
        method: "deleteThesaurusConceptSynonym",
      };
    },
    [deleteThesaurusConceptSynonym.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteThesaurusConceptSynonym",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  thesaurusThesaurusConceptSynonymsSlice.actions;

export default thesaurusThesaurusConceptSynonymsSlice.reducer;
