import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getEntityMins = createAsyncThunk(
  "entityMins/getEntityMins",
  async (values, { rejectWithValue }) => {
    try {
      const { domainId, objectId, params } = values;
      const response = await server.get(
        `/cdo/domains/${domainId}/objects/${objectId}/data-mins`,
        params && {
          params: params,
        }
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const entityMinsSlice = createSlice({
  name: "entityMins",
  initialState: {
    data: {},
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
  },
  extraReducers: {
    [getEntityMins.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getEntityMins" };
    },
    [getEntityMins.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getEntityMins",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus } = entityMinsSlice.actions;

export default entityMinsSlice.reducer;
