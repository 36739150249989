import { useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import React, { useEffect, useState } from "react";
import SearchSeriesImportForm from "./SearchSeriesImportForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useDispatch, useSelector } from "react-redux";
import { validateSearchSeries, clearStatus } from "./store/searchSeriesSlice";
import SearchSeriesImportPreviewForm from "./SearchSeriesImportPreviewForm";
import { createSearchFromSeries } from "../../store/searchSlice";

export const SearchSeriesImportContext = React.createContext();

export default function SearchSeriesImport() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [view, setView] = useState("import");
  const [initialFormData, setInitialFormData] = useState();

  const searchSeriesDataMap = useSelector(
    (state) => state.search.searchSeriesSlice.data
  );

  const searchSeriesSliceStatus = useSelector(
    (state) => state.search.searchSeriesSlice.status
  );

  const searchSliceStatus = useSelector(
    (state) => state.search.searchSlice.status
  );

  const searchActiveId = useSelector(
    (state) => state.search.searchSlice.activeId
  );

  console.log("searchSeriesDataMap: ", searchSeriesDataMap);

  function handleCancel() {
    history.push("/search");
  }

  function goBack() {
    setView("import");
  }

  function handleValidate(formData) {
    dispatch(validateSearchSeries(formData));
  }

  function handleCreateSearch() {
    dispatch(createSearchFromSeries(initialFormData));
  }

  useEffect(() => {
    if (
      searchSliceStatus &&
      searchSliceStatus.method === "createSearchFromSeries" &&
      searchSliceStatus.result === "success"
    ) {
      dispatch(clearStatus());
      history.push(`/search/${searchActiveId}/edit`);
    }
  }, [searchSliceStatus]);

  useEffect(() => {
    if (
      searchSeriesSliceStatus &&
      searchSeriesSliceStatus.method === "validateSearchSeries" &&
      searchSeriesSliceStatus.result === "success"
    ) {
      setView("preview");
      dispatch(clearStatus());
    }
  }, [searchSeriesSliceStatus]);

  return (
    <SearchSeriesImportContext.Provider
      value={{ initialFormData, setInitialFormData }}
    >
      <React.Fragment>
        {view === "import" && (
          <NtaiPage title="Import Series" back="/search">
            <NtaiForm>
              <SearchSeriesImportForm
                handleValidate={handleValidate}
                handleCancel={handleCancel}
              />
            </NtaiForm>
          </NtaiPage>
        )}
        {view === "preview" && (
          <NtaiPage title="Import Series" back={goBack}>
            <NtaiForm>
              <SearchSeriesImportPreviewForm
                handleCreateSearch={handleCreateSearch}
                handleCancel={handleCancel}
              />
            </NtaiForm>
          </NtaiPage>
        )}
      </React.Fragment>
    </SearchSeriesImportContext.Provider>
  );
}
