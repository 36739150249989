import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";

const _ = require("lodash");

export default function RuleConditionForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("ruleConditionForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ ruleConditionForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="ruleConditionForm.name"
          label="Name*"
          rules={{ required: "Name is required" }}
        />

        <NtaiTextField
          type="number"
          name="ruleConditionForm.execOrder"
          label="Order*"
          rules={{ required: "Order is required" }}
        />

        <NtaiTextField
          name="ruleConditionForm.execWhen"
          label="When Condition*"
          multiline
          minRows={4}
          maxRows={4}
          rules={{ required: "When condition is required" }}
        />

        <NtaiTextField
          name="ruleConditionForm.execThen"
          label="Then Condition*"
          multiline
          minRows={4}
          maxRows={4}
          rules={{ required: "Then condition is required" }}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
