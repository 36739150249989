import ReportsHome from "./ReportsHome";
import ReportDefinitionConfig from "./admin/definition/ReportDefinitionConfig";
import ReportCreate from "./reports/ReportCreate";
import ReportEdit from "./reports/ReportEdit";
import ReportAdminConfig from "./admin/ReportAdminConfig";
import ReportMain from "./reports/main/ReportMain";
import ReportMainRedirect from "./reports/main/scheduled-reports/ReportMainRedirect";
import ReportScheduledReportView from "./reports/main/scheduled-reports/ReportScheduledReportView";

const ReportsConfig = {
  routes: [
    ...ReportAdminConfig.routes,
    {
      path: "/reports",
      exact: true,
      component: ReportsHome,
    },
    {
      path: "/reports/:reportdefinitionid",
      exact: true,
      component: ReportCreate,
    },
    {
      path: "/reports/:reportdefinitionid/reports/:id",
      exact: true,
      component: ReportEdit,
    },
    {
      path: "/reports/:reportdefinitionid/reports/:id/redirect-main",
      exact: true,
      component: ReportMainRedirect,
    },
    {
      path: "/reports/:reportdefinitionid/reports/:reportid/schedules/:reportscheduleid/reports/:id",
      exact: true,
      component: ReportScheduledReportView,
    },
  ],
};

export default ReportsConfig;
