import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";

const _ = require("lodash");

export default function ReturnTypeForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const typeOptions = _.has(
    codelistData,
    CODELIST_CODES.GL_SYSTEM_USER_DEFINED_TYPE_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.GL_SYSTEM_USER_DEFINED_TYPE_CODE)
    : [];

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("returnTypeForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ returnTypeForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <NtaiTextField
        name="returnTypeForm.name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />

      <NtaiTextField
        name="returnTypeForm.description"
        multiline
        minRows={2}
        maxRows={2}
        label="Description"
      />

      {/* <NtaiSelectField
        name="returnTypeForm.typeCode"
        label="Type"
        options={typeOptions}
      /> */}

      <NtaiTextField
        name="returnTypeForm.body"
        label="Body*"
        multiline
        minRows={8}
        maxRows={8}
        rules={{ required: "Body is required" }}
      />
    </NtaiDialogFormPanel>
  );
}
