import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { COLUMN_NAMES } from "./dnd2constants";
import "./dnd2.css";
import { Box } from "@mui/material";
import { autoBatchEnhancer } from "@reduxjs/toolkit";

const style = {
  display: "flex",
  alignItems: "center",
  border: "1px dashed gray",
  padding: "0.5em 2rem",
  marginBottom: ".5rem",
  height: "auto",
  height: "32px",
  backgroundColor: "white",
  borderRadius: "4px",
  cursor: "move",
};

const Dnd2MovableItem = ({ name, setItems, column1, column2 }) => {
  const changeItemColumn = (currentItem, columnName) => {
    setItems((prevState) => {
      return prevState.map((e) => {
        return {
          ...e,
          column: e.name === currentItem.name ? columnName : e.column,
        };
      });
    });
  };

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: "Our first type" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult && dropResult.name === column1) {
        changeItemColumn(item, column1);
      } else {
        changeItemColumn(item, column2);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <Box ref={drag} sx={{ ...style, opacity }}>
      {name}
    </Box>
  );
};

export default Dnd2MovableItem;
