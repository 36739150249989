import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import NtaiBadge from "../badges/NtaiBadge";
import { grey } from "@mui/material/colors";
import { IconButton, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/pro-light-svg-icons";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const theme = useTheme();
  const { bgColor, color, iconText, labelText, badgeText, actions, ...other } =
    props;

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            p: "6px",
            pr: 0,
            gap: theme.spacing(1),
          }}
        >
          {iconText && (
            <NtaiBadge
              title={iconText.substring(0, 1).toLowerCase()}
              fg="grey"
              bg={grey[100]}
            />
          )}

          <Typography
            variant="subtitle2"
            sx={{ display: "flex", width: "100%", fontWeight: "inherit" }}
          >
            {labelText}
          </Typography>

          {badgeText && (
            <NtaiBadge
              title={badgeText}
              fg="secondary.contrastText"
              bg="secondary.main"
            />
          )}

          {/* <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "10%",
              gap: theme.spacing(1),
              justifyContent: "end",
            }}
          >
            <IconButton size="small">
              <FontAwesomeIcon size="xs" icon={faArchive} />
            </IconButton>
          </Box> */}
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function NtaiDecoTree1(props) {
  const theme = useTheme();
  const {
    data,
    idField,
    iconField,
    labelField,
    badgeField,
    typeField,
    handleClick,
  } = props;

  function generateLabel(label) {
    return (
      <Box sx={{ display: "flex", p: "4px", justifyContent: "space-between" }}>
        <Typography variant="subtitle2" fontWeight="700">
          {label}
        </Typography>
        <NtaiBadge title="23" fg="secondary.contrastText" bg="secondary.main" />
      </Box>
    );
  }

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes[idField]}
      nodeId={nodes[idField]}
      iconText={nodes[iconField]}
      labelText={nodes[labelField]}
      label={nodes[labelField]}
      badgeText={nodes[badgeField]}
      onClick={() => handleClick(nodes)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  return (
    <TreeView
      aria-label="ntai-tree"
      defaultExpanded={["3"]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {renderTree(data)}
    </TreeView>
  );
}
