import React from "react";
import { useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import history from "@ntai/@history";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SearchDashboardViewGroups from "./SearchDashboardViewGroups";
import {
  clearStatus,
  getSearchDashboardSourceUuIds,
} from "../store/searchDashboardInitSlice";
import { SearchDashboardInitContext } from "../SearchDashboardInit";
import SearchDashboardViewGroupFilters from "./SearchDashboardViewGroupFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChartTreeMap,
  faEllipsisV,
} from "@fortawesome/pro-light-svg-icons";
import SearchDashboardViewGroupSearch from "./SearchDashboardViewGroupSearch";
const _ = require("lodash");

export default function SearchDashboardViewGroupsWrapper({ createSearch }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    selectedEntities,
    setCurrentStep,
    selectedEntityDetails,
    selectedViews,
  } = React.useContext(SearchDashboardInitContext);

  const entitiesDataMap = useSelector(
    (state) => state.search.searchDashboardInitSlice.entities
  );

  const sourceUuIds = useSelector(
    (state) => state.search.searchDashboardInitSlice.sourceUuIds
  );

  const status = useSelector(
    (state) => state.search.searchDashboardInitSlice.status
  );

  // function getSelectedEntityName() {
  //   let result = "";
  //   let entities = [];
  //   if (selectedEntities && selectedEntities.size > 0) {
  //     selectedEntities.forEach((se, i) => {
  //       const entity = entitiesDataMap[se];
  //       entities.push(
  //         _.filter(_.get(entity, "fields"), { primaryFg: 1 })[0]["fieldValue"]
  //       );
  //     });
  //   }

  //   return entities.length > 0 ? "[" + entities.join(", ") + "]" : "";
  // }

  function handleBack() {
    setCurrentStep("select-entities");
  }

  function handleCreateSearch() {
    if (selectedEntities.size > 0) {
      const objectId = _.get(
        entitiesDataMap[[...selectedEntities][0]],
        "objectUuId"
      );
      dispatch(getSearchDashboardSourceUuIds({ values: [objectId] }));
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchDashboardSourceUuIds" &&
      status.result === "success"
    ) {
      if (sourceUuIds && _.isArray(sourceUuIds) && sourceUuIds.length > 0) {
        createSearch(sourceUuIds);
      } else {
        // console.log("No source uuids available for selected entities..");
      }
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: theme.spacing(2),
          flexBasis: "10%",
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          background: grey[50],
        }}
      >
        <NtaiButton
          onClick={() => history.push("/search")}
          size="small"
          icon="back"
        >
          Back to Listing
        </NtaiButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: theme.general.border1,
          background: grey[50],
          pb: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "60%",
            flexDirection: "column",
            justifyContent: "start",
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="h1" fontWeight="300">
            Select Dashboards
          </Typography>
          <Typography variant="body1" fontWeight="300">
            Search for configured dashboards using search bar below then select
            one or more to begin analysis
          </Typography>
          <NtaiForm>
            <SearchDashboardViewGroupSearch />
          </NtaiForm>
        </Box>
      </Box>

      <Box sx={{ height: `calc(100% - 88px)`, display: "flex", width: "100%" }}>
        <SearchDashboardViewGroupFilters />
        <SearchDashboardViewGroups />
      </Box>

      <Box sx={{ height: "88px", borderTop: theme.general.border1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: theme.spacing(2),
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={() => handleBack()}
          >
            Back
          </Button>
          {selectedEntityDetails && (
            <React.Fragment>
              <FontAwesomeIcon icon={faEllipsisV} />
              <Typography variant="h6">{`Selected Entity:`}</Typography>
              <Typography variant="h6" fontWeight={700}>
                {selectedEntityDetails["title"]}
              </Typography>
              <FontAwesomeIcon icon={faEllipsisV} />
            </React.Fragment>
          )}
          <Button
            startIcon={<FontAwesomeIcon icon={faChartTreeMap} />}
            variant="contained"
            onClick={() => handleCreateSearch()}
            disabled={selectedViews.size > 0 ? false : true}
          >
            View Dashboards
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
