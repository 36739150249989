import React, { useContext, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import NtaiFlatMenu from "@ntai/components/menus/NtaiFlatMenu";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchDashboardInitContext } from "../SearchDashboardInit";
const _ = require("lodash");

export default function SearchDashboardEntityFilters() {
  const theme = useTheme();
  const [filters, setFilters] = useState("All");

  const { selectedEntityFilter, setSelectedEntityFilter } = React.useContext(
    SearchDashboardInitContext
  );

  const entitiesDataMap = useSelector(
    (state) => state.search.searchDashboardInitSlice.entities
  );

  const entitiesData =
    entitiesDataMap && !_.isEmpty(entitiesDataMap)
      ? Object.values(entitiesDataMap)
      : [];

  function handleSelect(item) {
    setSelectedEntityFilter(item);
  }

  useEffect(() => {
    setFilters({
      All: _.sum(Object.values(_.countBy(entitiesData, "objectUuId"))),
      ...NtaiUtils.sortObjectOnKeys(_.countBy(entitiesDataMap, "objectName")),
    });
  }, [entitiesDataMap]);

  return (
    <Box sx={{ flexBasis: "15%", padding: theme.spacing(2) }}>
      <NtaiFlatMenu
        label="FILTER BY"
        data={filters}
        selected={selectedEntityFilter}
        handleSelect={handleSelect}
      />
    </Box>
  );
}
