import {
  faCircleNotch,
  faClose,
  faCode,
  faEllipsisStrokeVertical,
  faSave,
} from "@fortawesome/pro-light-svg-icons";

import { faPlay } from "@fortawesome/pro-regular-svg-icons";

import { faDatabase, faStop } from "@fortawesome/pro-solid-svg-icons";

import { faCode as faCodeSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { SourceDatasetsContext } from "../SourceDatasetList";
import { SourcePipelineContext } from "../../../SourcePipelineManage";
import { grey } from "@mui/material/colors";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { SourceDatasetManagerContext } from "../../SourceDatasetManager";
import useTimer from "app/main/hooks/useTimer";
import NtaiChipsInput from "@ntai/components/inputs/NtaiChipsInput";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { faCircleStop } from "@fortawesome/pro-regular-svg-icons";
import NtaiCircularProgressWithContent from "@ntai/components/progress/NtaiCircularProgressWithContent";
const _ = require("lodash");

export default function CodeEditorMenuActions({
  data,
  stageId,
  datasetId,
  handleRunScript,
  handleCancelScript,
  handleUpdateDataset,
  handleDeleteDataset,
}) {
  const theme = useTheme();
  const { getValues } = useFormContext();

  const { time, isRunning, startTimer, stopTimer } = useTimer();

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector((state) => state.sources.sourcesSlice.data);
  const sourceData =
    dataMap && !_.isEmpty(dataMap) ? _.get(dataMap, sourceId) : null;

  const awsEnv =
    sourceData && !_.isEmpty(sourceData) ? sourceData["awsEnv"] : null;
  const awsLivySession =
    sourceData && !_.isEmpty(sourceData) ? sourceData["activeSession"] : null;

  const { activeDatasetId } = React.useContext(SourceDatasetsContext);
  const { runAllDatasetCurrentId } = React.useContext(
    SourceDatasetManagerContext
  );

  const status = useSelector(
    (state) => state.sources.sourceDatasetsSlice.status
  );

  const scriptRunningFg =
    (data["queryStatusCode"] === 2 ||
      (status &&
        status.result === "pending" &&
        status.method === "runSourceDatasetScript")) &&
    (activeDatasetId === data.uuId || runAllDatasetCurrentId === data.uuId)
      ? true
      : false;

  function toggleCustomCodeFg() {
    let datasetDataCopy = _.cloneDeep(data);
    datasetDataCopy["customCodeFg"] =
      datasetDataCopy["customCodeFg"] === 1 ? 0 : 1;
    handleUpdateDataset(datasetId, datasetDataCopy);
  }

  function onHandleRunScript() {
    startTimer();
    handleRunScript();
  }

  function onHandleCancelScript() {
    stopTimer();
    handleCancelScript();
  }

  useEffect(() => {
    if (
      !(
        status &&
        status.result === "pending" &&
        status.method === "runSourceDatasetScript" &&
        (activeDatasetId === data.uuId || runAllDatasetCurrentId === data.uuId)
      )
    ) {
      stopTimer();
    }
  }, [status]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: theme.spacing(1),
        }}
      >
        <Typography variant="subtitle2" color="default" fontWeight="600">
          {isRunning ? time : data["queryDurationMilliSecondsText"]}
        </Typography>

        {/* {data["queryDurationMilliSecondsText"] && (
          <Typography
            sx={{ mr: theme.spacing(1) }}
            variant="subtitle2"
            color="inherit"
          >
            Time:
            {` ${data["queryDurationMilliSecondsText"]}`}
          </Typography>
        )} */}

        <Chip
          sx={{
            background: [6].includes(data.queryStatusCode) ? "red" : "white",
          }}
          variant={
            [6].includes(data.queryStatusCode) ? "contained" : "outlined"
          }
          size="small"
          label={
            <Typography
              fontWeight="600"
              sx={{
                fontSize: "11px",
                color: [6].includes(data.queryStatusCode) ? "white" : "black",
              }}
            >
              {scriptRunningFg ? "Running" : data.queryStatusDecode}
            </Typography>
          }
        />

        <Tooltip title={scriptRunningFg ? "Cancel" : "Run"}>
          <IconButton
            sx={{ height: 24, width: 24, cursor: "pointer" }}
            size="small"
            disabled={awsEnv && !awsLivySession}
            onClick={() =>
              scriptRunningFg ? onHandleCancelScript() : onHandleRunScript()
            }
          >
            {scriptRunningFg ? (
              // <FontAwesomeIcon size="2xs" icon={faCircleNotch} spin />
              // <NtaiCircularProgress size={14} />
              <NtaiCircularProgressWithContent size={20}>
                <FontAwesomeIcon icon={faStop} fontSize="12px" />
              </NtaiCircularProgressWithContent>
            ) : (
              // <FontAwesomeIcon spin icon={faCircleStop} />
              <FontAwesomeIcon size="xs" icon={faPlay} />
            )}
          </IconButton>
        </Tooltip>

        {/* <IconButton size="small">
          <FontAwesomeIcon size="xs" icon={faSave} />
        </IconButton> */}

        <Tooltip title="Python Code">
          <IconButton
            sx={{
              height: 24,
              width: 24,
              backgroundColor: data["customCodeFg"] === 1 ? grey[700] : null,
              color: data["customCodeFg"] === 1 ? "white" : null,
            }}
            onClick={() => toggleCustomCodeFg()}
            size="small"
            disabled={scriptRunningFg}
          >
            <FontAwesomeIcon size="2xs" icon={faCodeSolid} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            sx={{ height: 24, width: 24 }}
            onClick={() => handleDeleteDataset()}
            disabled={scriptRunningFg}
          >
            <FontAwesomeIcon size="2xs" icon={faArchive} />
          </IconButton>
        </Tooltip>

        {data["sourceOutDatasetCount"] > 0 && (
          <Tooltip title="Target store dataset">
            <IconButton sx={{ height: 24, width: 24, cursor: "default" }}>
              <FontAwesomeIcon size="2xs" icon={faDatabase} />
            </IconButton>
          </Tooltip>
        )}

        <IconButton sx={{ height: 24, width: 24 }} disabled={scriptRunningFg}>
          <FontAwesomeIcon size="2xs" icon={faEllipsisStrokeVertical} />
        </IconButton>
      </Box>
    </React.Fragment>
  );
}
