import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceReportTemplates = createAsyncThunk(
  "searchSourceReportTemplates/getSearchSourceReportTemplates",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, params } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/reports`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateSearchSourceReport = createAsyncThunk(
  "searchSourceReportTemplates/generateSearchSourceReport",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, reportTemplateId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/reports/${reportTemplateId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceReportTemplatesSlice = createSlice({
  name: "searchSourceReportTemplates",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceReportTemplates.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceReportTemplates",
      };
    },
    [getSearchSourceReportTemplates.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceReportTemplates",
        message: action.payload.message,
      };
    },
    [generateSearchSourceReport.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.recordId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "generateSearchSourceReport",
      };
    },
    [generateSearchSourceReport.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "generateSearchSourceReport",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceReportTemplatesSlice.actions;

export default searchSourceReportTemplatesSlice.reducer;
