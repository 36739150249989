import { Box, IconButton, Typography, useTheme } from "@mui/material";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import { useSelector } from "react-redux";
import ThesaurusConceptRelationList from "./relation/ThesaurusConceptRelationList";
import ThesaurusConceptSynonymList from "./synonym/ThesaurusConceptSynonymList";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function ThesaurusConceptManagement(props) {
  const theme = useTheme();
  const { conceptId, handleDrawerClose } = props;

  const conceptDataMap = useSelector(
    (state) => state.core.thesaurusConceptsSlice.data
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "520px",
        height: "100%",
        width: "520px",
        flexDirection: "column",
      }}
    >
      <NtaiPanel
        width="100%"
        header={_.get(_.get(conceptDataMap, conceptId), "name")}
        subheader={NtaiUtils.trunc(
          _.get(_.get(conceptDataMap, conceptId), "description"),
          100
        )}
        headerActions={
          <IconButton onClick={() => handleDrawerClose()}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        }
      />
      <NtaiTabs>
        <Box label="SYNONYMS">
          <ThesaurusConceptSynonymList conceptId={conceptId} />
        </Box>
        <Box label="RELATIONS">
          <ThesaurusConceptRelationList conceptId={conceptId} />
        </Box>
      </NtaiTabs>
    </Box>
  );
}
