import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

function processData(date_hist_data, categoryField, valueField) {
  let revised_data = [];

  // TODO: Fix date value without 'y' as prefix in milliseconds
  date_hist_data.forEach((dat) => {
    let obj = {};
    obj[categoryField] = new Date(Number(_.get(dat, categoryField)))
      // .getFullYear()
      .toISOString()
      .slice(0, 10);
    obj["count"] = _.get(dat, valueField);
    // console.log("Date obj: ", obj);
    revised_data.push(obj);
  });

  return revised_data;
}

export default function NtaiDateHistogramChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, categoryField, valueField, handleClick } =
    props;

  useLayoutEffect(() => {
    let chart = am4core.create(
      "datehistchart".concat("-", widgetId),
      am4charts.XYChart
    );

    chart.data = processData(data, categoryField, valueField);
    chart.cursor = new am4charts.XYCursor();
    // chart.responsive.enabled = true;

    // zoom icon
    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#ffffff");
    chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#eeeeee");

    chart.zoomOutButton.icon.disabled = true;
    let zoomImage = chart.zoomOutButton.createChild(am4core.Image);
    zoomImage.href = "/images/zoom-icon.png";
    zoomImage.width = 15;
    zoomImage.height = 15;
    zoomImage.interactionsEnabled = false;

    let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0.5;
    categoryAxis.renderer.ticks.template.location = 0;
    categoryAxis.renderer.inside = false;
    categoryAxis.dateFormatter = new am4core.DateFormatter();

    //categoryAxis.dateFormatter.dateFormat = "MM-dd";
    categoryAxis.dateFormats.setKey("month", "yyyy-MM");
    // categoryAxis.renderer.labels.template.dy = 40;
    categoryAxis.title.fontSize = 11;
    categoryAxis.renderer.minGridDistance = 10;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.rotation = 270;
    label.maxWidth = 120;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    label.fontSize = 11;
    label.truncate = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    let valueAxisLabel = valueAxis.renderer.labels.template;
    valueAxisLabel.fontSize = 10;
    valueAxisLabel.rotation = 270;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = valueField;
    series.dataFields.dateX = categoryField;

    series.columns.template.strokeWidth = 0;
    series.columns.template.tooltipText = "Date: {dateX}\nCount: {valueY}";
    // series.columns.template.fill = am4core.color("#92DFF3");
    series.columns.template.fill = am4core.color(
      lighten(theme.colors.chart.lighter, 0.4)
    ); //am4core.color("#FDc65C"); // .. blue: 1E90FF, red: FF7276 // green: 65fe08

    // tool tip
    chart.tooltip.getFillFromObject = false;

    // tool tip
    series.tooltip.getFillFromObject = false;
    series.tooltip.autoTextColor = false;
    series.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    series.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    series.tooltip.background.fillOpacity = tooltipConfig.backgroundFillOpacity;
    series.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    series.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    //
    // chart.tooltip.background.fill = am4core.color("#67b7dc");

    let axisTooltip = categoryAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 8;
    categoryAxis.cursorTooltipEnabled = true;
    categoryAxis.sortBySeries = series;

    let valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#767676");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    valueAxisTooltip.rotation = 270;
    valueAxisTooltip.dy = 5;
    valueAxisTooltip.dx = -20;
    valueAxisTooltip.label.fill = am4core.color("#fff");
    valueAxisTooltip.label.fontSize = 11;

    series.columns.template.events.on(
      "hit",
      function (ev) {
        // console.log("Date clicked ", ev.target.dataItem.dataContext);
        //handleClick(widgetId, ev.target.dataItem.dataContext);
        const sourceWidgetFilterFields = [
          {
            sourceFieldUuId: Object.keys(_.get(metadata, "fields"))[0],
            fieldOperatorCode: 1,
            fieldValue: _.get(ev.target.dataItem.dataContext, categoryField),
          },
        ];
        handleClick(sourceWidgetFilterFields, ev);
      },
      this
    );
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"datehistchart".concat("-", widgetId)}
      style={{ width: "100%", height: "100%" }}
    ></Box>
  );
}
