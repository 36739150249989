import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  getSourceRecordListingFieldColors,
  createSourceRecordListingFieldColor,
  updateSourceRecordListingFieldColor,
  deleteSourceRecordListingFieldColor,
  clearStatus,
} from "./store/sourceRecordListingFieldColorsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box } from "@mui/material";
import NtaiColorSchemeForm from "app/main/pages/shared/color-scheme/NtaiColorSchemeForm";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "typeCode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    transformFunc: function (o) {
      return o === 1 ? "Exact Value" : "Rule ";
    },
  },
  {
    id: "exactValue",
    numeric: false,
    disablePadding: false,
    label: "Exact Value",
  },
  {
    id: "hexFgCode",
    numeric: false,
    disablePadding: false,
    label: "FG",
  },
  {
    id: "hexBgCode",
    numeric: false,
    disablePadding: false,
    label: "BG",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function SourceRecordListingFieldColorList({
  sourceRecordListingFieldId,
}) {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceRecordListingId = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceRecordListingId: sourceRecordListingId,
    sourceRecordListingFieldId: sourceRecordListingFieldId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordListingFieldColorsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceRecordListingFieldColorsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteSourceRecordListingFieldColor({
            ...pathVariables,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleSave(formData) {
    action === "edit"
      ? dispatch(
          updateSourceRecordListingFieldColor({
            ...pathVariables,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceRecordListingFieldColor({
            ...pathVariables,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "remove") confirmDelete();
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceRecordListingFieldColor" ||
        status.method === "updateSourceRecordListingFieldColor")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceRecordListingFieldColor"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceRecordListingFieldColors(pathVariables));
  }, [sourceRecordListingFieldId]);

  return (
    <>
      <Box sx={{ display: "flex", height: "240px", width: "100%" }}>
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          title="Field Color Scheme"
        />
      </Box>

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Field Color Scheme"
        size="sm"
      >
        <NtaiForm>
          {(action === "add" || (action === "edit" && currentId)) && (
            <NtaiColorSchemeForm
              formName="sourceRecordListingFieldColorForm"
              action={action}
              handleCancel={handleDialogClose}
              handleSave={handleSave}
              formData={action === "edit" ? dataMap[currentId] : {}}
            />
          )}
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "exactValue")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
