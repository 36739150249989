import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import SearchSourceHierarchyWrapper from "./hierarchy/SearchSourceHierarchyWrapper";
import SearchSourceFiltersWrapper from "./filters/SearchSourceFiltersWrapper";
import SearchSourceViewMenu from "./viewmenu/SearchSourceViewMenu";
import { SearchContext } from "../../SearchEdit";
import { SearchSourceContext } from "../SearchSource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import SearchSourceLinkedSourceWrapper from "./linkedsource/SearchSourceLinkedSourceWrapper";
import SearchSourceWidgetManagerWrapper from "./viewmenu/widgets/SearchSourceWidgetManagerWrapper";
import SearchSourceSnapshots from "./snapshots/SearchSourceSnapshots";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { grey } from "@mui/material/colors";
import SearchSourceNotes from "./notes/SearchSourceNotes";
import {
  CODELIST_CODES,
  SRH_SEARCH_TYPE_CODE,
} from "app/main/constants/NtaiCodelistConstants";
import SearchSourceViewExport from "./export/SearchSourceViewExport";
const _ = require("lodash");

export default function SearchSourceToolbar() {
  const theme = useTheme();

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const searchTypeCode = _.get(searchData, "typeCode");

  return (
    <>
      <Box
        id="graph-header"
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          px: theme.spacing(1),
          justifyContent: "space-between",
          borderBottom: theme.general.border1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          {searchTypeCode === SRH_SEARCH_TYPE_CODE.SEARCH ? (
            <SearchSourceHierarchyWrapper />
          ) : (
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              sx={{ fontSize: "11px" }}
              // endIcon={<FontAwesomeIcon size="sm" icon={faCaretDown} />}
            >
              {NtaiUtils.trunc(
                _.get(
                  searchSourceData,
                  [
                    SRH_SEARCH_TYPE_CODE.ANALYTICS_STANDARD,
                    SRH_SEARCH_TYPE_CODE.DASHBOARD,
                    SRH_SEARCH_TYPE_CODE.IMPORTED_SERIES,
                    SRH_SEARCH_TYPE_CODE.REPORT,
                  ].includes(searchTypeCode)
                    ? "searchSourceDefinition.name"
                    : "sourceDomainName"
                ),
                36
              ).toUpperCase()}
            </Button>
          )}
          {searchTypeCode !== SRH_SEARCH_TYPE_CODE.DASHBOARD && (
            <SearchSourceLinkedSourceWrapper
              searchSourceUuId={_.get(searchSourceData, "uuId")}
            />
          )}
          {/* <FontAwesomeIcon icon={faEllipsisV} /> */}
          <SearchSourceFiltersWrapper />
          {searchSourceData &&
          searchSourceData["seriesUuId"] &&
          searchSourceData["seriesUuId"].length > 0 ? (
            <>
              <Box
                sx={{
                  px: theme.spacing(1),
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
                <Chip
                  sx={{ px: theme.spacing(1) }}
                  size="small"
                  label={
                    <Typography
                      color="inherit"
                      variant="subtitle2"
                      fontWeight="700"
                    >
                      {NtaiUtils.trunc(searchSourceData["seriesName"], 25)}
                    </Typography>
                  }
                />
              </Box>
            </>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(0.5),
            alignItems: "center",
          }}
        >
          <SearchSourceNotes />
          <SearchSourceViewExport />
          <SearchSourceWidgetManagerWrapper />
          {searchTypeCode !== SRH_SEARCH_TYPE_CODE.DASHBOARD && (
            <SearchSourceViewMenu />
          )}
        </Box>
      </Box>
    </>
  );
}
