import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { SearchBasicInitContext } from "./SearchBasicInit";
import SourceBox from "../sources/SourceBox";
import { previewSearch } from "../store/searchInitSlice";
import { SearchInitSourceContext } from "../sources/SearchInitSourcesWrapper";
import SourceCompactBox from "../shared/SourceCompactBox";
import {
  faFilter,
  faGrid,
  faGridHorizontal,
  faStop,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

export default function SearchBasicInitSources() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [denseLayout, setDenseLayout] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const {
    searchQueryTerms,
    reviseQueryTerms,
    selectedSources,
    setSelectedSources,
  } = useContext(SearchBasicInitContext);

  const { selectedFilter, setSelectedFilter } = React.useContext(
    SearchInitSourceContext
  );

  const searchInitData = useSelector(
    (state) => state.search.searchInitSlice.data
  );

  const searchInitSources =
    searchInitData && _.get(searchInitData, "searchInitSources");

  function buildSearchSources() {
    const result = [];
    if (Array.isArray(searchInitSources) && searchInitSources.length > 0) {
      _.orderBy(
        searchInitSources,
        ["searchSourceDefinition.abbrev"],
        ["asc"]
      ).forEach((searchInitSource, index) => {
        let pushSource = false;

        if (
          !selectedFilter ||
          selectedFilter === "All" ||
          (selectedFilter &&
            _.get(searchInitSource, "searchSourceDefinition.categoryDecode") ===
              selectedFilter)
        )
          pushSource = true;

        if (pushSource && denseLayout) {
          result.push(
            <SourceCompactBox
              id={_.get(searchInitSource, "searchSourceDefinition.sourceUuId")}
              key={`searchsourcecompactbox-${index}`}
              title={_.get(searchInitSource, "searchSourceDefinition.name")}
              description={_.get(
                searchInitSource,
                "searchSourceDefinition.description"
              )}
              hits={_.get(searchInitSource, "totalHits")}
              handleSelect={handleSourceSelect}
            />
          );
        } else if (pushSource && !denseLayout)
          result.push(
            <SourceBox
              key={`searchsourcebox-${index}`}
              searchInitSource={searchInitSource}
              handleSelect={handleSourceSelect}
            />
          );
      });
    }

    return result;
  }

  function handleSourceSelect(sourceUuId) {
    const tmpSelected = new Set(selectedSources);
    if (selectedSources && selectedSources.has(sourceUuId)) {
      tmpSelected.delete(sourceUuId);
    } else {
      tmpSelected.add(sourceUuId);
    }

    setSelectedSources(tmpSelected);
  }

  // useEffect(() => {
  //   const revisedQueryTerms = reviseQueryTerms(searchQueryTerms);
  //   dispatch(previewSearch({ searchQueryTerms: revisedQueryTerms }));
  // }, [searchQueryTerms]);

  useEffect(() => {
    if (_.isArray(searchInitSources)) {
      const tmpSourceList = buildSearchSources();
      setSourceList(tmpSourceList);
      setLoaded(true);
    }
  }, [searchInitSources, selectedFilter, denseLayout, selectedSources]);

  return (
    <>
      <Box
        sx={{
          flexBasis: "85%",
          display: "flex",
        }}
      >
        {loaded && (
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                px: theme.spacing(2),
                justifyContent: "space-between",
                paddingTop: theme.spacing(2),
                width: "86%",
              }}
            >
              <Typography variant="h6">Sources</Typography>
              {/* <Typography variant="h6">{`Sources (${searchInitSources.length})`}</Typography> */}

              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  justifyContent: "end",
                }}
              >
                <Button
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,
                    gap: theme.spacing(1),
                  }}
                  size="small"
                  color="inherit"
                  onClick={() => setDenseLayout(!denseLayout)}
                >
                  <FontAwesomeIcon
                    size="xl"
                    icon={denseLayout ? faGridHorizontal : faGrid}
                  />
                  {denseLayout ? "STANDARD" : "DENSE"}
                </Button>
                <Button
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,
                    gap: theme.spacing(1),
                  }}
                  size="small"
                  color="inherit"
                >
                  <FontAwesomeIcon size="xl" icon={faStop} />
                  CLEAR ALL
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: theme.spacing(1),
                flexWrap: "wrap",
                width: "86%",
                gap: theme.spacing(2),
              }}
            >
              {sourceList}
            </Box>
          </Scrollbar>
        )}

        {!loaded && <NtaiCircularProgress size={24} />}
      </Box>
    </>
  );
}
