import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationDefList from "./NotificationDefList";
import {
  clearStatus,
  getNotificationDefs,
} from "./store/notificationDefsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NotificationDefActions from "./NotificationDefActions";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
const _ = require("lodash");

export default function NotificationDefListWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const status = useSelector(
    (state) => state.core.notificationDefsSlice.status
  );

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getNotificationDefs"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getNotificationDefs());
  }, []);

  return (
    <NtaiPage title="Notifications">
      {loaded && (
        <NtaiPanel
          width="75%"
          header="Manage Notifications"
          subheader="Notifications are short messages sent to users in the application or through emails. They inform individuals about important events, transactions, or updates that require their attention"
        >
          <NtaiForm>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                flexDirection: "column",
              }}
            >
              <NotificationDefActions />
              <NotificationDefList />
            </Box>
          </NtaiForm>
        </NtaiPanel>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </NtaiPage>
  );
}
