import React, { useEffect, useState } from "react";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { getUsers, deleteUser, clearStatus } from "./store/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
import { getRoles } from "../role/store/rolesSlice";
import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiMRTableWrapper from "./mr/NtaiMRTableWrapper";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "accountLockedFg",
    numeric: false,
    disablePadding: false,
    label: <FontAwesomeIcon icon={faLock} />,
    transformFunc: function (o) {
      return o === 1 ? (
        <FontAwesomeIcon icon={faLock} style={{ color: "red" }} />
      ) : null;
    },
    width: "3%",
  },

  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "User Name",
    fontWeight: 700,
  },

  {
    id: ["firstName", "lastName"],
    numeric: false,
    disablePadding: false,
    label: "Name",
    transformFunc: getFullName,
  },

  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

function getFullName(names) {
  let result = "";
  let firstName = "";
  let lastName = "";

  if (_.get(names[0], "colValue") !== null)
    firstName = _.get(names[0], "colValue");
  if (_.get(names[0], "colValue") !== null)
    lastName = _.get(names[1], "colValue");

  return result.concat(firstName, " ", lastName);
}

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

const columns = [
  {
    accessorFn: function (row) {
      if (row.accountLockedFg === 1)
        return <FontAwesomeIcon style={{ color: "red" }} icon={faLock} />;
    },
    header: "L",
    size: 1,
    grow: false,
  },
  {
    accessorKey: "userName", //access nested data with dot notation
    header: "User Name",
    primary: true,
  },
  {
    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
    header: "Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorFn: (row) => `${NtaiUtils.formatDateCol(row.dateModified)}`,
    header: "Modified",
  },
  {
    accessorKey: "userModified",
    header: "Modified By",
  },
];

export default function UserList1() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [reloadCounter, setReloadCounter] = useState(0);
  const [paginationRequest, setPaginationRequest] = useState({
    page: 1,
    limit: 10,
  });

  const [loaded, setLoaded] = useState(false);
  const [action, setAction] = useState();
  const dataMap = useSelector((state) => state.core.usersSlice.data);
  const pageMetadata = useSelector((state) => state.core.usersSlice.metadata);

  const status = useSelector((state) => state.core.usersSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["userName"], ["asc"])
      : [];

  //   console.log("USer data: ", data);
  function handleTopToolbarLeftAction(toolbarAction, selected, names) {
    console.log("Handle toolbar action: ", toolbarAction, selected, names);
    setAction({ value: toolbarAction, ids: selected, names: names });
  }

  function handleDelete() {
    if (Array.isArray(action["ids"]) && action["ids"].length > 0) {
      action["ids"].forEach((id) => {
        dispatch(deleteUser(id));
      });
    }
  }

  useEffect(() => {
    if (_.has(action, "value") && action["value"]) {
      if (action["value"] === "delete") {
        handleDelete();
      }
    }
  }, [action]);

  useEffect(() => {
    if (status && status.result === "success" && status.method === "getUsers") {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteUser"
    ) {
      const remainingIds = _.filter(action["ids"], function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setAction(null);
        dispatch(clearStatus());
        enqueueSnackbar(
          `User(s) "${action["names"].join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getUsers(paginationRequest));
  }, [paginationRequest]);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  function handleFetch(paginationRequestModel) {
    setPaginationRequest({ ...paginationRequestModel });
  }

  return (
    <>
      <NtaiPage padding={2} title="Users">
        {loaded && (
          <NtaiMRTableWrapper
            id="uuId"
            path="/admin/users"
            data1={_.orderBy(data, ["userName"], ["asc"])}
            columns1={columns}
            handleFetch={handleFetch}
            handleTopToolbarLeftAction={handleTopToolbarLeftAction}
            rowCount1={pageMetadata["totalCount"]}
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
    </>
  );
}
