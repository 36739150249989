import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import NtaiTextArea from "@ntai/components/inputs/NtaiTextArea";
import { useSnackbar } from "notistack";
import NtaiValidator from "@ntai/validator/NtaiValidator";
const _ = require("lodash");

export default function PythonFileForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [pythonCode, setPythonCode] = React.useState(_.get(formData, "body"));

  function onChangeCode(newValue) {
    setPythonCode(newValue);
  }

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);

  const typeOptions = _.get(
    codelistCodes,
    CODELIST_CODES.GL_SYSTEM_USER_DEFINED_TYPE_CODE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("pythonFileForm")
    );

    if (NtaiUtils.containsSpecialCharacters(_.get(formData, "fileName"))) {
      enqueueSnackbar("Invalid characters in file name", {
        variant: "error",
      });
    } else {
      sanitizedFormData["body"] = pythonCode;
      sanitizedFormData["requirementsFileTxt"] = getValues(
        "pythonFileForm.requirementsFileTxt"
      );

      handleSave(sanitizedFormData);
    }
  }

  useEffect(() => {
    reset({ pythonFileForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box label="GENERAL">
        <NtaiFormPanel
          width="50%"
          header="General Information"
          subheader="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="pythonFileForm.fileName"
            label="File Name*"
            rules={{
              required: "File name is required",
              validate: NtaiValidator.isPythonFileName,
            }}
          />

          <NtaiTextField
            name="pythonFileForm.description"
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Description"
            label="Description*"
            rules={{ required: "Description is required" }}
          />
          {/* <NtaiSelectField
            name="pythonFileForm.typeCode"
            isDisabled={action === "edit" ? true : false}
            options={typeOptions}
          /> */}
        </NtaiFormPanel>
      </Box>
      <Box
        disabled={action === "add" ? true : false}
        sx={{ height: "100%" }}
        label="CODE"
      >
        <NtaiFormPanel
          width="75%"
          height="100%"
          header="Python Code"
          subheader="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              border: theme.general.border1,
              display: "flex",
              height: `calc(100% - 30%)`,
            }}
          >
            <AceEditor
              mode="python"
              theme="github"
              onChange={onChangeCode}
              name="pythonCode"
              value={pythonCode}
              width="100%"
              height="100%"
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                // enableSnippets: true,
              }}
            />
          </Box>
        </NtaiFormPanel>
      </Box>
      <Box disabeld={action === "add" ? true : false} label="REQUIREMENTS">
        <NtaiFormPanel
          width="50%"
          header="Requirements"
          subheader="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextArea
            label="Requirements"
            name="pythonFileForm.requirementsFileTxt"
            multiline
            minRows={20}
            maxRows={20}
          />
        </NtaiFormPanel>
      </Box>
    </NtaiTabs>
  );
}
