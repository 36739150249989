import { Box, Button, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useSelector } from "react-redux";
import SourcePipelineInStore from "./in/SourcePipelineInStore";
import History from "@ntai/@history";
import SourceStageList from "./stage/SourceStageList";
import SourcePipelineRefSourceDatasetList from "./ref-datasets/SourcePipelineRefSourceDatasetList";
import SourceAwsLivySessionDialog from "../session/SourceAwsLivySessionDialog";
const _ = require("lodash");

export default function SourcePipelineManage(props) {
  const theme = useTheme();
  const sourcePipelineId = props.match.params.id;

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourcePipelinesSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourcePipelinesSlice.status
  );

  const data = _.get(dataMap, sourcePipelineId);

  function handleCancel() {
    History.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines`);
  }

  return (
    <NtaiPage
      title={`Pipelines > ${_.get(data, "name")}`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines`}
      headerActions={<SourceAwsLivySessionDialog />}
    >
      <NtaiTabs>
        <Box label="INBOUND STORES">
          <NtaiForm>
            <SourcePipelineInStore handleCancel={handleCancel} />
          </NtaiForm>
        </Box>
        {/* <Box label="REF SOURCES">
            <NtaiForm>
              <SourceRefSourceList handleCancel={handleCancel} />
            </NtaiForm>
          </Box> */}
        <Box label="REF. DATASETS">
          <NtaiForm>
            <SourcePipelineRefSourceDatasetList handleCancel={handleCancel} />
          </NtaiForm>
        </Box>

        <Box sx={{ height: "100%" }} label="STAGES">
          <NtaiForm>
            <SourceStageList />
          </NtaiForm>
        </Box>
      </NtaiTabs>
    </NtaiPage>
  );
}
