import {
  faBell,
  faDatabase,
  faGrid,
  faShapes,
  faSitemap,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setNavigation,
  setSelectedModule,
} from "../../../store/ntai/navigationSlice";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import history from "@ntai/@history";
import CoreItem from "./CoreItem";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import {
  faHeadSideGear,
  faListTree,
  faPlug,
  faUserGear,
} from "@fortawesome/pro-light-svg-icons";
import CoreItemRbac from "./rbac/CoreItemRbac";

export default function AdminPanel() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  function handleSelect(item) {
    dispatch(setNavigation(item));
    history.push(item.url);
    setOpen(false);
    dispatch(setSelectedModule(null));
  }

  const clickAwayHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Administration">
        <IconButton
          sx={{
            width: "32px",
            height: "32px",
            "&:hover": { bgcolor: theme.colors.primary.light },
          }}
          color="inherit"
          onClick={(e) => handleClick(e)}
        >
          <FontAwesomeIcon icon={faGrid} size="xs" />
        </IconButton>
      </Tooltip>

      {open && (
        <NtaiPopover
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          clickAwayHandler={clickAwayHandler}
        >
          <Box
            sx={{
              p: theme.spacing(2),
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "12px",
            }}
          >
            <CoreItemRbac
              permissions={["app.control.object.all"]}
              handleSelect={handleSelect}
              label="Objects"
              item={{ name: "cdo", url: "/cdo/domains" }}
              icon={<FontAwesomeIcon icon={faSitemap} />}
            />
            <CoreItemRbac
              permissions={["app.control.entity.all"]}
              handleSelect={handleSelect}
              label="Entities"
              item={{ name: "entities-library", url: "/entities-library" }}
              icon={<FontAwesomeIcon icon={faShapes} />}
            />
            <CoreItemRbac
              permissions={["app.control.source.all"]}
              handleSelect={handleSelect}
              label="Sources"
              item={{ name: "sources", url: "/sourcedefs", leftSidebar: false }}
              icon={<FontAwesomeIcon icon={faDatabase} />}
            />
            <CoreItemRbac
              permissions={["app.control.library.all"]}
              handleSelect={handleSelect}
              label="Library"
              item={{ name: "library", url: "/library" }}
              icon={<FontAwesomeIcon icon={faListTree} />}
            />
            <CoreItemRbac
              permissions={["app.control.alert.all"]}
              handleSelect={handleSelect}
              label="Alerts"
              item={{ name: "alerts", url: "/monitor/definitions" }}
              icon={<FontAwesomeIcon icon={faBell} />}
            />
            <CoreItemRbac
              permissions={["app.control.model.all"]}
              handleSelect={handleSelect}
              label="Models"
              item={{ name: "models", url: "/models/datasets" }}
              icon={<FontAwesomeIcon icon={faHeadSideGear} />}
            />

            <CoreItemRbac
              permissions={["app.control.adapter.all"]}
              handleSelect={handleSelect}
              label="Adapters"
              item={{ name: "adapters", url: "/adapters", leftSidebar: false }}
              icon={<FontAwesomeIcon icon={faPlug} />}
            />

            <CoreItem
              permissions={["app.control.admin.all"]}
              handleSelect={handleSelect}
              label="Admin"
              item={{ name: "admin", url: "/admin/users" }}
              icon={<FontAwesomeIcon icon={faUserGear} />}
            />
          </Box>
        </NtaiPopover>
      )}
    </>
  );
}
