import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteSourceRecordListing,
  getSourceRecordListings,
} from "./store/sourceRecordListingsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceRecordListingEdit from "./SourceRecordListingEdit";
import SourceRecordListingCreate from "./SourceRecordListingCreate";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: "700",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "defaultFg",
    numeric: false,
    disablePadding: false,
    label: "Default?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
  },
  {
    id: "activeFg",
    numeric: false,
    disablePadding: false,
    label: "Active?",
    transformFunc: function (o) {
      return o === 1 ? "Yes" : "";
    },
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "delete",
  },
];

export default function SourceRecordListingList(props) {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  const status = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.status
  );

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") {
      confirmDelete();
      setAction(action);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "delete") {
        dispatch(
          deleteSourceRecordListing({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      }
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction("list");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceRecordListings" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceRecordListing"
    ) {
      setOpenConfirmDeleteDialog(false);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_RECORD_LISTING_TYPE_CODE));
    dispatch(
      getSourceRecordListings({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <>
      {["list", "delete"].includes(action) && (
        <NtaiPanel
          width="75%"
          header="Listings"
          subheader="Define and manage listing views of types list and table/grid for the source"
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              handleDialogAddEdit={handleDialogAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Listing Record"
            />
          )}
          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      )}

      {action === "add" && (
        <SourceRecordListingCreate
          handleCancel={handleCancel}
          redirectToEdit={handleDialogAddEdit}
        />
      )}
      {action === "edit" && (
        <SourceRecordListingEdit id={currentId} handleCancel={handleCancel} />
      )}

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
