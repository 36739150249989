import { Box, Typography, lighten, useTheme } from "@mui/material";
import React from "react";

export default function SourceCatalogAssetWidget(props) {
  const theme = useTheme();

  const { id, header, subheader, footer, bg, fg, handleClick } = props;
  return (
    <Box
      sx={{
        p: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        gap: theme.spacing(1),
        background: bg ? bg : lighten(theme.colors.secondary.lighter, 0.2),
        borderRadius: theme.general.borderRadiusSm,
        "&:hover": {
          background: lighten(theme.colors.secondary.lighter, 0.1),
          cursor: "pointer",
          boxShadow: theme.colors.shadows.cardSm,
        },
      }}
      onClick={() => handleClick(id)}
    >
      <Box>
        <Typography
          color={fg ? fg : "default"}
          variant="subtitle1"
          fontWeight="700"
        >
          {header}
        </Typography>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
      >
        <Typography color={fg ? fg : "default"} variant="h1" fontSize="700">
          {subheader}
        </Typography>

        {footer && (
          <Typography
            color={fg ? fg : "default"}
            variant="caption"
            fontSize="500"
          >
            {footer}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
