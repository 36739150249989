import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceDatasets = createAsyncThunk(
  "sourceDatasets/getSourceDatasets",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, sourceStageId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceDatasetPreview = createAsyncThunk(
  "sourceDatasets/getSourceDatasetPreview",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, sourceStageId, uuId } =
        values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${uuId}/preview`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceDataset = createAsyncThunk(
  "sourceDatasets/createSourceDataset",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        formData,
      } = values;

      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceDataset = createAsyncThunk(
  "sourceDatasets/updateSourceDataset",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        uuId,
        formData,
      } = values;

      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceDataset = createAsyncThunk(
  "sourceDatasets/deleteSourceDataset",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        datasetId,
        toBeDeletedDataset,
      } = values;

      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${datasetId}/delete`,
        toBeDeletedDataset
      );

      return {
        uuId: datasetId,
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const runSourceDatasetScript = createAsyncThunk(
  "sourceDatasets/runSourceDatasetScript",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        uuId,
        formData,
        requestMode,
      } = values;

      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${uuId}/run`,
        formData
      );
      return {
        uuId: uuId,
        data: response.data,
        requestMode: requestMode,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const cancelSourceDatasetScript = createAsyncThunk(
  "sourceDatasets/cancelSourceDatasetScript",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, sourceStageId, uuId } =
        values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets/${uuId}/cancel`
      );
      return {
        uuId: uuId,
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const runAll = createAsyncThunk(
  "sourceDatasets/runAll",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourcePipelineId,
        sourceStageId,
        formData,
      } = values;

      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/stages/${sourceStageId}/datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceDatasetsSlice = createSlice({
  name: "sourceDatasets",
  initialState: {
    data: {},
    activeId: null,
    status: null,
    message: null,
    counter: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
      state.message = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    setCounter: (state, action) => {
      state.counter = action.payload;
    },
    clearSourceDatasets: (state, action) => {
      state.data = {};
    },
  },
  extraReducers: {
    [getSourceDatasets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceDatasets" };
    },

    [getSourceDatasets.rejected]: (state, action) => {
      state.status = {
        result: "success",
        method: "getSourceDatasets",
        message: action.payload.message,
      };
    },

    [createSourceDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload["uuId"]]: action.payload,
      };

      // update for others
      if (
        action.payload.reorderedSourceDatasets &&
        action.payload.reorderedSourceDatasets.length > 0
      ) {
        action.payload.reorderedSourceDatasets.forEach((rd) => {
          state.data[rd.uuId].datasetOrder = rd.datasetOrder;
        });
      }

      state.counter = Object.keys(state.data).length;
      state.status = { result: "success", method: "createSourceDataset" };
    },
    [createSourceDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceDataset",
        message: action.payload.message,
      };
    },

    [updateSourceDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceDataset" };
    },
    [updateSourceDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceDataset",
        message: action.payload.message,
      };
    },

    [deleteSourceDataset.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);

      if (
        action.payload.data.reorderedSourceDatasets &&
        action.payload.data.reorderedSourceDatasets.length > 0
      ) {
        action.payload.data.reorderedSourceDatasets.forEach((rd) => {
          state.data[rd.uuId].datasetOrder = rd.datasetOrder;
        });
      }
      state.counter = Object.keys(state.data).length;
      state.status = { result: "success", method: "deleteSourceDataset" };
    },
    [deleteSourceDataset.rejected]: (state, action) => {
      state.status = {
        result: "success",
        method: "deleteSourceDataset",
        message: action.payload.message,
      };
    },

    [runSourceDatasetScript.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "runSourceDatasetScript",
        requestMode: action.meta.arg.requestMode,
      };
    },

    [runSourceDatasetScript.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "runSourceDatasetScript",
        requestMode: action.payload.requestMode,
        uuId: action.payload.uuId,
      };
    },

    [runSourceDatasetScript.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "runSourceDatasetScript",
        message: action.payload.message,
      };
    },

    [cancelSourceDatasetScript.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "cancelSourceDatasetScript",
      };
    },

    [cancelSourceDatasetScript.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "cancelSourceDatasetScript",
      };
    },

    [cancelSourceDatasetScript.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "cancelSourceDatasetScript",
        message: action.payload.message,
      };
    },

    [runAll.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "runAll",
      };
    },
    [runAll.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "runAll",
        message: action.payload.message,
      };
    },

    [getSourceDatasetPreview.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceDatasetPreview" };
    },
    [getSourceDatasetPreview.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDatasetPreview",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId, setCounter, clearSourceDatasets } =
  sourceDatasetsSlice.actions;

export default sourceDatasetsSlice.reducer;
