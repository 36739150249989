const _ = require("lodash");

class SearchSourceFilterUtil {
  static processFilterFields(sanitizedFormData, filterSchemeFields) {
    const returnValue = [];

    if (sanitizedFormData) {
      Object.keys(sanitizedFormData).forEach((filterFieldId, i) => {
        const filterFieldMetaObj = _.get(filterSchemeFields, filterFieldId);
        const filterFieldValues = _.get(sanitizedFormData, filterFieldId);
        const searchSourceFilterSchemeField = {
          sourceFilterSchemeFieldUuId: _.get(filterFieldMetaObj, "uuId"),
        };

        switch (filterFieldMetaObj.fieldUiTypeCode) {
          case 1:
          case 2:
            const searchSourceFilterSchemeListFieldValues = _.map(
              filterFieldValues,
              function (f) {
                return {
                  value: filterFieldMetaObj.fieldUiTypeCode === 1 ? f.value : f,
                };
              }
            );

            searchSourceFilterSchemeField[
              "searchSourceFilterSchemeFieldValues"
            ] = searchSourceFilterSchemeListFieldValues;

            returnValue.push(searchSourceFilterSchemeField);
            break;

          case 3:
            if (filterFieldValues !== 0) {
              const searchSourceFilterSchemeFieldValue = [
                {
                  value: _.toString(filterFieldValues),
                },
              ];

              searchSourceFilterSchemeField[
                "searchSourceFilterSchemeFieldValues"
              ] = searchSourceFilterSchemeFieldValue;

              returnValue.push(searchSourceFilterSchemeField);
            }

            break;

          case 4:
            const searchSourceFilterSchemeFieldValue = [
              {
                value: _.toString(filterFieldValues),
              },
            ];

            searchSourceFilterSchemeField[
              "searchSourceFilterSchemeFieldValues"
            ] = searchSourceFilterSchemeFieldValue;

            returnValue.push(searchSourceFilterSchemeField);

            break;

          case 5:

          case 6:
          case 7:
            const searchSourceFilterSchemeRangeFieldValues = _.map(
              filterFieldValues,
              function (f) {
                return { value: _.toString(f) };
              }
            );

            searchSourceFilterSchemeField[
              "searchSourceFilterSchemeFieldValues"
            ] = searchSourceFilterSchemeRangeFieldValues;

            returnValue.push(searchSourceFilterSchemeField);
            break;

          default:
          // code block
        }
      });
    }

    return returnValue;
  }

  static resetFilterForm(
    sourceFilterSchemeFields,
    searchSourceFilterSchemeObj
  ) {
    const returnValue = {};
    searchSourceFilterSchemeObj.searchSourceFilterSchemeFields.forEach(
      (f, i) => {
        const sourceFilterSchemeField = _.get(
          sourceFilterSchemeFields,
          _.get(f, "sourceFieldUuId")
        );

        switch (sourceFilterSchemeField.fieldUiTypeCode) {
          case 1:
            returnValue[_.get(f, "sourceFieldUuId")] =
              f.searchSourceFilterSchemeFieldValues.map(function (v) {
                return { value: v.value, label: v.value };
              });
            break;
          case 2:
            returnValue[_.get(f, "sourceFieldUuId")] =
              f.searchSourceFilterSchemeFieldValues.map(function (v) {
                return v.value;
              });
            break;
          case 3:
            returnValue[_.get(f, "sourceFieldUuId")] =
              f.searchSourceFilterSchemeFieldValues.map(function (v) {
                return v.value;
              })[0];
            break;

          case 4:
            returnValue[_.get(f, "sourceFieldUuId")] =
              f.searchSourceFilterSchemeFieldValues.map((v) =>
                _.toNumber(v.value)
              )[0];
            break;
          case 5:
          case 6:
          case 7:
            returnValue[_.get(f, "sourceFieldUuId")] =
              f.searchSourceFilterSchemeFieldValues.map((v) =>
                _.toNumber(v.value)
              );
            break;
        }
      }
    );

    return returnValue;
  }
}

export default SearchSourceFilterUtil;
