import { Box, Button, Drawer, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getObjectAllSourceWidgets } from "./store/objectSourceViewsSlice";
import ObjectSourceViewWidgetsSelection from "./ObjectSourceViewWidgetsSelection";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { ObjectSourceViewContext } from "./ObjectSourceViewForm";
import ObjectSourceViewWidgetBox from "./ObjectSourceViewWidgetBox";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function ObjectSourceViewWidgetsWrapper({ handleSave }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { selectedWidgetIds, setSelectedWidgetIds } = React.useContext(
    ObjectSourceViewContext
  );
  const [openWidgetsPanel, setOpenWidgetsPanel] = React.useState(false);
  const [removeOption, setRemoveOption] = React.useState(false);

  const widgetsDataMap = useSelector(
    (state) => state.core.objectSourceViewsSlice.sourceWidgets
  );

  const widgetsData = widgetsDataMap && Object.values(widgetsDataMap);

  const dataMap = useSelector(
    (state) => state.core.objectSourceViewsSlice.data
  );
  const status = useSelector(
    (state) => state.core.objectSourceViewsSlice.status
  );
  const activeId = useSelector(
    (state) => state.core.objectSourceViewsSlice.activeId
  );
  const data = Object.values(dataMap);

  function handleWidgetsPanelOpen() {
    setOpenWidgetsPanel(true);
  }

  function handleWidgetsPanelClose() {
    setOpenWidgetsPanel(false);
  }

  function handleRemove(currentId) {
    let selectedWidgetIdsCopy = [...selectedWidgetIds];
    let updatedWidgetIds = _.filter(
      selectedWidgetIdsCopy,
      (widgetId) => widgetId !== currentId
    );
    setSelectedWidgetIds([...updatedWidgetIds]);
    setRemoveOption(true);
  }

  function generateSelectedWidgets() {
    let result = [];
    const availableSourceWidgetUuIds = _.get(
      _.get(dataMap, activeId),
      "sourceWidgetUuIds"
    );

    availableSourceWidgetUuIds.forEach((sourceWidgetUuId, index) => {
      if (_.has(widgetsDataMap, sourceWidgetUuId)) {
        const widget = widgetsDataMap[sourceWidgetUuId];

        result.push(
          <ObjectSourceViewWidgetBox
            key={`available-source-widget-${index}`}
            id={widget.uuId}
            title={widget.name}
            description={widget.sourceMin.abbrev}
            removeOption={true}
            handleRemove={handleRemove}
          />
        );
      }
    });

    return result;
  }

  useEffect(() => {
    if (removeOption) {
      handleSave();
      setRemoveOption(false);
    }
  }, [removeOption]);

  useEffect(() => {
    dispatch(getObjectAllSourceWidgets());
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <NtaiPanel
          width="75%"
          subheader="Oincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: theme.spacing(1),
              justifyContent: "end",
            }}
          >
            <Button
              size="small"
              sx={{ fontSize: 10 }}
              variant="outlined"
              onClick={() => handleWidgetsPanelOpen()}
            >
              ADD WIDGET
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "320px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                border: theme.general.border1,
                display: "flex",
                height: "100%",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Scrollbar>
                <Box
                  sx={{
                    p: theme.spacing(2),
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: theme.spacing(1),
                  }}
                >
                  {generateSelectedWidgets()}
                </Box>
                {_.get(_.get(dataMap, activeId), "sourceWidgetUuIds").length ===
                  0 && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NtaiEmptyMessage
                      vAlign="center"
                      header="No widgets available"
                    />
                  </Box>
                )}
              </Scrollbar>
            </Box>
          </Box>
        </NtaiPanel>
      </Box>
      <Drawer
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openWidgetsPanel}
        onClose={handleWidgetsPanelClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{
            display: "flex",
            width: "480px",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer,
          }}
        >
          <ObjectSourceViewWidgetsSelection />
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
