import SourceOutStoreDbDatasetCreate from "./database/SourceOutStoreDbDatasetCreate";
import SourceOutStoreDbDatasetEdit from "./database/SourceOutStoreDbDatasetEdit";
import SourceOutStoreDbDatasetList from "./database/SourceOutStoreDbDatasetList";
import SourceOutStoreElastic from "./elastic/SourceOutStoreElastic";
import SourceOutStoresList from "./SourceOutStoreList";

const SourceOutStoreConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/outstores",
      exact: true,
      component: SourceOutStoresList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/outstores/elastic",
      exact: true,
      component: SourceOutStoreElastic,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/outstores/:id/dbdatasets",
      exact: true,
      component: SourceOutStoreDbDatasetList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/outstores/:id/dbdatasets/create",
      exact: true,
      component: SourceOutStoreDbDatasetCreate,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/outstores/:outstoreid/dbdatasets/:id/edit",
      exact: true,
      component: SourceOutStoreDbDatasetEdit,
    },
  ],
};

export default SourceOutStoreConfig;
