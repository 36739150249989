import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createObject, clearStatus } from "./store/objectsSlice";
import ObjectForm from "./ObjectForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ObjectCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.objectsSlice.status);
  const dataMap = useSelector((state) => state.core.objectsSlice.data);
  const activeId = useSelector((state) => state.core.objectsSlice.activeId);
  const domainId = useSelector(
    (state) => state.core.objectsSlice.activeDomainId
  );

  function handleSave(domainId, formData) {
    dispatch(createObject({ domainId: domainId, formData: formData }));
  }

  useEffect(() => {
    if (status.method === "createObject" && status.result === "success") {
      enqueueSnackbar(
        `Object "${_.get(dataMap[activeId], "name")}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/cdo/domains/${domainId}/objects/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Object" back="/cdo/objects">
      <NtaiForm>
        <ObjectForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
