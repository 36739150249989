import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import ChildComponent2 from "./ChildComponent2";

export default function ChildComponent(props) {
  const theme = useTheme();
  const [childCounter, setChildCounter] = useState();
  const { counter } = props;

  function handleChildClick() {
    console.log("counter and Child counter: ", counter, childCounter);
  }

  React.useEffect(() => {
    setChildCounter(counter);
  }, [counter]);

  return (
    <Box
      sx={{
        display: "flex",
        border: theme.general.border2,
        gap: theme.spacing(2),
      }}
    >
      <Typography variant="h6">{counter}</Typography>
      <Button variant="contained" onClick={() => handleChildClick()}>
        CHILD CLICK
      </Button>
      <Typography variant="h6">{counter}</Typography>
      <ChildComponent2 handleChild1Click={handleChildClick} />
    </Box>
  );
}
