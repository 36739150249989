import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import fileUploadServer from "app/http/ntaiFileUploadServer";
import _ from "lodash";

export const getSourceInStoreFileFields = createAsyncThunk(
  "sourceInStoreFileFields/getSourceInStoreFileFields",
  async (values) => {
    const { sourceDefId, sourceId, sourceInId, sourceInFileId } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${sourceInFileId}/fields`
    );
    return response.data;
  }
);

export const updateSourceInStoreFileField = createAsyncThunk(
  "sourceInStoreFileFields/updateSourceInStoreFileField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceInId,
        sourceInFileId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${sourceInFileId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceInStoreFileAllFields = createAsyncThunk(
  "sourceInStoreFileFields/updateSourceInStoreFileAllFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, sourceInFileId, formData } =
        values;
      const response = await server.put(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${sourceInFileId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceInStoreFileFieldsSlice = createSlice({
  name: "sourceInStoreFileFields",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceInStoreFileFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceInStoreFileFields",
      };
    },
    [getSourceInStoreFileFields.rejected]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "error",
        method: "getSourceInStoreFileFields",
        message: action.payload.message,
      };
    },

    [updateSourceInStoreFileField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceInStoreFileField",
      };
    },
    [updateSourceInStoreFileField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceInStoreFileField",
        message: action.payload.message,
      };
    },

    [updateSourceInStoreFileAllFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "updateSourceInStoreFileAllFields",
      };
    },
    [updateSourceInStoreFileAllFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceInStoreFileAllFields",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceInStoreFileFieldsSlice.actions;

export default sourceInStoreFileFieldsSlice.reducer;
