import {  useTheme } from "@mui/material";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function EntitySourceCriteria({ formData, buildQuery }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { watch, getValues, reset } = useFormContext();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [fields, setFields] = useState();

  const sourceDefUuId = formData["sourceObject"]["sourceMin"]["sourceDefUuId"];
  const sourceUuId = formData["sourceObject"]["sourceMin"]["uuId"];

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  const queryValue = _.get(formData, "criteria");

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefUuId,
        sourceUuId,
        mergedFields
      );

      setFields({ ...tmpFields });
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    dispatch(
      getSourceFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
    dispatch(
      getSourceDerivedFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
  }, [sourceUuId]);

  return (
    <NtaiPanel
      width="100%"
      header="Entity Selection Criteria"
      subheader="Build entity specific criteria using source fields. For example, if the entity is US, you can map the relevant country field in the source to United States."
    >
      {fieldsLoaded && fields && Object.keys(fields).length !== 0 && (
        <NtaiQueryBuilder
          fields={fields}
          buildQuery={buildQuery}
          value={queryValue}
        />
      )}
      {!fieldsLoaded && <NtaiCircularProgress size={24} />}
    </NtaiPanel>
  );
}
