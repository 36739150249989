import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";

import {
  clearStatus,
  setActiveId,
  updateSourceRecordListing,
} from "./store/sourceRecordListingsSlice";
import SourceRecordListingForm from "./SourceRecordListingForm";
const _ = require("lodash");

export default function SourceRecordListingEdit(props) {
  const dispatch = useDispatch();

  const { id, handleCancel } = props;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceRecordListing({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceRecordListing" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiForm>
      <SourceRecordListingForm
        action="edit"
        formData={formData}
        handleFormSubmit={handleSave}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
