import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import useDimensions from "react-cool-dimensions";
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import "./ntai-source-view-widget.css";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import NtaiMenu from "@ntai/components/menus/NtaiMenu";
const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    minHeight: "120px",
  },
  header: {
    display: "flex",
    height: "42px",
    alignItems: "center",
    padding: "8px",
    borderBottom: "1px solid #eee",
    cursor: "grab",
    justifyContent: "space-between",
  },
  headerTitle: {
    display: "flex",
    paddingLeft: "4px",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  headerActions: {
    display: "flex",
    paddingRight: "4px",
    justifyContent: "end",
    alignItems: "center",
    gap: "4px",
  },
  main: {
    display: "flex",
    // flexBasis: "80%",
    height: `calc(100% - 42px)`,
    width: "100%",
    padding: theme.spacing(1.5),
    position: "relative",
    zIndex: 0,
    cursor: "pointer",
  },
}));

export default function NtaiSourceViewWidget(props) {
  const theme = useTheme();

  const {
    id,
    width,
    avatar,
    title,
    handleAction,
    headerActions,
    handleHeaderAction,
    children,
  } = props;

  const divRef = React.useRef();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleMenuItemSelect(menuAction) {
    handleHeaderAction(menuAction, id);
    handleClose();
  }

  const dims = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...

      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  const observe1 = _.get(dims, "observe");
  const unobserve1 = _.get(dims, "observe");
  const height = _.get(dims, "height");

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: width ? width : "100%",
          height: "100%",
        }}
        ref={observe1}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            border: theme.general.border1,
            bordeRadius: theme.general.borderRadiusSm,
            background: "white",
            "&:hover": { boxShadow: theme.colors.shadows.card },
          }}
          ref={divRef}
        >
          <Box className={classes.widgetContainer}>
            <Box className="widget-header-wrapper">
              <Box id="title-action" className={classes.header}>
                <Box className={classes.headerTitle}>
                  {avatar}
                  <Typography
                    variant="subtitle2"
                    fontWeight="700"
                    color="inherit"
                  >
                    {title}
                  </Typography>
                </Box>
                <Box className={classes.headerActions}>
                  {headerActions && (
                    <IconButton
                      sx={{ height: 24, width: 24 }}
                      onClick={handleMenuClick}
                      size="small"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              id="main"
              className={classes.main}
              onClick={(e) => handleAction(e)}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
      {headerActions && (
        <NtaiMenu
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          items={headerActions}
          handleMenuItemSelect={handleMenuItemSelect}
        />
      )}
    </React.Fragment>
  );
}
