import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteSourceUserWidget,
  getSourceUserWidgets,
} from "./store/sourceUserWidgetsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "pyFileName",
    numeric: false,
    disablePadding: false,
    label: "Py File",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourceUserWidgetList() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = sourceDefDataMap[sourceDefId];

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteSourceUserWidget({ domainId: "*", uuId: id }));
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceUserWidgets" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceUserWidgets({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <>
      <NtaiPage
        padding={2}
        title={`${_.get(sourceDefData, "name")} / User Widgets`}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        {loaded && (
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`}
            handleToolbarAction={handleToolbarAction}
            title="Source User Widget"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
