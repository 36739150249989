import { Box, Button, Drawer, useTheme } from "@mui/material";
import React, { useState } from "react";
import NtaiDialog from "./NtaiDialog";
import NtaiConfirmDeleteDialog from "./NtaiConfirmDeleteDialog";

export default function NtaiTestDialogs() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    console.log("dialog clicked");
  }

  function handleConfirmDelete() {
    setOpenDelete(false);
  }

  function handleCancelDelete() {
    setOpenDelete(false);
  }

  function handleDelete() {
    setOpenDelete(true);
  }

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        display: "flex",
        gap: "12px",
        justifyContent: "center",
      }}
    >
      <Button size="small" onClick={handleClick}>
        Open Dialog
      </Button>
      <Button size="small" onClick={handleDelete}>
        Confirm Delete
      </Button>

      <NtaiDialog
        size="sm"
        title="Add User"
        open={open}
        handleDialogClose={handleClose}
      >
        <Box>Hello me</Box>
      </NtaiDialog>

      <NtaiConfirmDeleteDialog
        size="sm"
        name="User"
        open={openDelete}
        handleConfirmDelete={handleConfirmDelete}
        handleCancelDelete={handleCancelDelete}
      />
    </Box>
  );
}
