import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteReportDefinition,
  getReportDefinitions,
} from "./store/reportDefinitionsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import History from "@ntai/@history/@history";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import { Box } from "@mui/material";
import ReportDefinitionTypeSelect from "./ReportDefinitionTypeSelect";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function ReportDefinitionList() {
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [reportDefintitionTypeDialogOpen, setReportDefinitionTypeDialogOpen] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionsSlice.data
  );
  const status = useSelector(
    (state) => state.reports.reportDefinitionsSlice.status
  );
  const data = Object.values(dataMap);

  function handleReportDefinitionTypeDialogOpen() {
    setReportDefinitionTypeDialogOpen(true);
  }

  function handleReportDefinitionTypeDialogClose() {
    setReportDefinitionTypeDialogOpen(false);
  }

  function handleSelect(id) {
    if (id) {
      History.push(`/reports/definitions/edit/${id}`);
    } else {
      handleReportDefinitionTypeDialogOpen();
    }
  }
  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteReportDefinition(id));
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getReportDefinitions"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteReportDefinition"
    ) {
      cancelDelete();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getReportDefinitions());
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Report Definitions" back="/reports/admin">
        {loaded && (
          <NtaiCrudTable
            helpText="Report definitions are the templates used for the report generation. System supports two kinds of report definitions - source and custom"
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleSelect}
            handleToolbarAction={handleToolbarAction}
            title="Report Definition"
          />
        )}
        {!loaded && <NtaiCircularProgress size={24} />}
      </NtaiPage>
      <NtaiDialog
        title="Select Report Definition Type"
        open={reportDefintitionTypeDialogOpen}
        handleDialogClose={handleReportDefinitionTypeDialogClose}
        size="sm"
      >
        <ReportDefinitionTypeSelect
          handleDialogClose={handleReportDefinitionTypeDialogClose}
        />
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
