import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  getSourcePolicyAssessments,
  createSourcePolicyAssessment,
  updateSourcePolicyAssessment,
  deleteSourcePolicyAssessment,
  clearStatus,
} from "./store/sourcePolicyAssessmentsSlice";
import SourcePolicyAssessmentForm from "./SourcePolicyAssessmentForm";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "sourceAdjudicationObjectName",
    numeric: false,
    disablePadding: false,
    label: "Assessment Form",
    width: "30%",
    fontWeight: 700,
  },
  {
    id: "sourcePolicyDefinitionName",
    numeric: false,
    disablePadding: false,
    label: "Policy Definition",
    width: "25%",
  },
  {
    id: "allowOverrideFg",
    numeric: false,
    disablePadding: false,
    label: "Allow Override?",
    width: "15%",
    transformFunc: (o) => (o === 1 ? "Yes" : "No"),
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    width: "15%",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "15%",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function SourcePolicyAssessmentList({ sourcePolicyId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [open, setOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = sourceDefDataMap[sourceDefId];

  const dataMap = useSelector(
    (state) => state.sources.sourcePolicyAssessmentsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourcePolicyAssessmentsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(
          Object.values(dataMap),
          ["sourceAdjudicationObjectName"],
          ["asc"]
        )
      : [];

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePolicyId: sourcePolicyId,
  };

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(
        selected,
        dataMap,
        "sourceAdjudicationObjectName"
      ),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteSourcePolicyAssessment({
          ...pathVariables,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleSave(formData) {
    if (action === "add")
      dispatch(
        createSourcePolicyAssessment({
          ...pathVariables,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateSourcePolicyAssessment({
          ...pathVariables,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  function handleCancel() {
    setAction("list");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourcePolicyAssessments" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSourcePolicyAssessment", "updateSourcePolicyAssessment"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(`Successfully saved assessment policy`, {
        variant: "success",
      });
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourcePolicyAssessment"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Assessment form(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourcePolicyAssessments(pathVariables));
  }, []);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiPanel
          width="100%"
          header={`Assessment Policies`}
          subheader="Manage policy for assessment forms. Associated policies will be applied to the selected assessment form in relevant sections including workspace and workflow."
        >
          {action === "list" && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              selectedIds={selectedIds}
              dialog={true}
              handleDialogAddEdit={handleAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Policy"
            />
          )}

          {(action === "add" || (action === "edit" && currentId)) && (
            <NtaiForm>
              <SourcePolicyAssessmentForm
                action={action}
                formData={action === "edit" ? dataMap[currentId] : {}}
                handleFormSubmit={handleSave}
                handleCancel={handleCancel}
              />
            </NtaiForm>
          )}
        </NtaiPanel>
      )}
      {!loaded && <NtaiCircularProgress />}

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "sourceAdjudicationObjectName"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
