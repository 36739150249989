import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";

const _ = require("lodash");

export default function ObjectRelForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const relTypeCodeOptions = _.get(
    codes,
    CODELIST_CODES.COR_META_OBJECT_REL_TYPE_CODE
  );

  const objectUuId = useSelector((state) => state.core.objectsSlice.activeId);
  const objectDataMap = useSelector((state) => state.core.objectsSlice.data);
  const objectData = Object.values(objectDataMap);

  const filteredObjectData = _.filter(objectData, function (o) {
    return o.uuId !== objectUuId;
  });

  const relObjectOptions = NtaiUtils.getSelectOptions(
    filteredObjectData,
    "uuId",
    "name"
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectRelForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    reset({ objectRelForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiSelectField
          name="objectRelForm.relTypeCode"
          label="Relation Type"
          options={relTypeCodeOptions}
        />

        <NtaiSelectField
          name="objectRelForm.relObjectUuId"
          label="Relation Object"
          options={relObjectOptions}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
