import { faCalendarLinesPen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React, { useEffect, useState } from "react";
import { SearchContext } from "../../../SearchEdit";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  updateSearchDateRange,
} from "./store/searchDateRangeSlice";
import SearchDateRangeForm from "./SearchDateRangeForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
const _ = require("lodash");

export default function SearchDateRangeWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { searchData } = React.useContext(SearchContext);
  const searchId = _.get(searchData, "uuId");

  const status = useSelector(
    (state) => state.search.searchDateRangeSlice.status
  );

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  function handleSave(formData) {
    dispatch(
      updateSearchDateRange({
        searchId: searchId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateSearchDateRange" &&
      status.result === "success"
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <React.Fragment>
      <Tooltip title="Edit Date Range">
        <IconButton onClick={() => handleDialogOpen()}>
          <FontAwesomeIcon size="2xs" icon={faCalendarLinesPen} />
        </IconButton>
      </Tooltip>
      <NtaiDialog
        title="Edit date range"
        size="sm"
        open={open}
        handleDialogClose={handleDialogClose}
      >
        <NtaiForm>
          <SearchDateRangeForm
            handleCancel={handleDialogClose}
            handleSave={handleSave}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
