import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSourceSeries, clearStatus } from "./store/sourceSeriesSlice";
import SourceSeriesForm from "./SourceSeriesForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SourceSeriesCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.core.sourceSeriesSlice.data);
  const status = useSelector((state) => state.core.sourceSeriesSlice.status);
  const activeId = useSelector(
    (state) => state.core.sourceSeriesSlice.activeId
  );

  function handleSave(formData) {
    dispatch(createSourceSeries(formData));
  }

  useEffect(() => {
    if (status.method === "createSourceSeries" && status.result === "success") {
      enqueueSnackbar(
        `Source series "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/library/source-series/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Series" back="/library/source-series">
      <NtaiForm>
        <SourceSeriesForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
