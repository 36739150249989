import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import AceEditor from "react-ace";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/ext-language_tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceDerivedFieldElemList from "./elements/SourceDerivedFieldElemList";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
const _ = require("lodash");

export default function SourceDerivedFieldForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const [scriptValue, setScriptValue] = useState(
    action === "edit" ? _.get(formData, "script") : null
  );
  const { getValues, setValue, reset } = useFormContext();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const categoryOptions = _.get(
    codelistData,
    CODELIST_CODES.SRC_SOURCE_DERIVED_FIELD_CATEGORY_CODE
  );

  const fieldTypeOptions = _.get(
    codelistData,
    CODELIST_CODES.SRC_SOURCE_FIELD_ES_DATA_TYPE_CODE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceDerivedFieldForm")
    );

    if (scriptValue && scriptValue.length > 0)
      handleSave({ ...sanitizedFormData, script: scriptValue });
  }

  function onScriptChange(newValue) {
    setScriptValue(newValue);
  }

  useEffect(() => {
    reset({ sourceDerivedFieldForm: action === "add" ? {} : formData });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        }}
        label="GENERAL"
      >
        <NtaiDialogFormPanel
          title={
            action === "add"
              ? "Add Derived Field"
              : `Edit ${_.get(formData, "name")}`
          }
          subheader="Enter name, label, description, category, field data type and script (painless) for derived field"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
          headerActions={
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <IconButton onClick={handleCancel}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Box>
          }
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <NtaiTextField
              name="sourceDerivedFieldForm.name"
              label="Name*"
              placeholder="Name"
              rules={{ required: "Name is required" }}
            />
            <NtaiTextField
              name="sourceDerivedFieldForm.label"
              label="Label*"
              placeholder="Label"
              rules={{ required: "Label is required" }}
            />
          </Box>

          <Box
            sx={{
              width: "75%",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <NtaiTextField
              multiline
              minRows={2}
              maxRows={2}
              name="sourceDerivedFieldForm.description"
              label="Description"
              placeholder="Description"
            />
          </Box>

          <Box
            sx={{
              width: "75%",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <NtaiSelectField
              width="100%"
              options={categoryOptions}
              name="sourceDerivedFieldForm.categoryCode"
              label="Category*"
              rules={{ required: "Category is required" }}
            />
            <NtaiSelectField
              options={fieldTypeOptions}
              name="sourceDerivedFieldForm.fieldTypeCode"
              label="Field Data Type*"
              rules={{ required: "Field data type is required" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              height: "140px",
            }}
          >
            <Typography color={theme.colors.alpha.black[70]}>
              Script*
            </Typography>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <AceEditor
                style={{ border: theme.general.border1 }}
                theme="github"
                mode="javascript"
                onChange={onScriptChange}
                value={scriptValue || `return params.doc['field'].value`}
                name="functionCode"
                width="100%"
                height="100%"
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  // enableSnippets: true,
                  useWorker: false,
                }}
              />
              <Box sx={{ display: "flex" }}>
                <Typography variant="caption">
                  Example: For keyword field: return params.doc['phase'].value,
                  For text field: return params._source['narrative']
                </Typography>
              </Box>
            </Box>
          </Box>
        </NtaiDialogFormPanel>
      </Box>

      <Box
        label="FIELDS"
        visible={
          action === "edit" && formData["categoryCode"] === 3 ? true : false
        }
      >
        <SourceDerivedFieldElemList sourceDerivedFieldId={formData["uuId"]} />
      </Box>
    </NtaiTabs>
  );
}
