import React, { useContext } from "react";
import { UserPermissionStore } from "./UserPermissionProvider";
import { Box, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull } from "@fortawesome/pro-solid-svg-icons";
const _ = require("lodash");

const withUserPermission = (Component) => (props) => {
  const theme = useTheme();
  const { userPermissions } = useContext(UserPermissionStore);

  let allowed = false;

  if (!_.has(props, "permissions") || !_.isArray(props.permissions))
    throw new Error("Permissions not available for the component");

  if (_.isArray(userPermissions)) {
    allowed = userPermissions.some((elem) => {
      return props.permissions.includes(elem);
    });
  }

  if (!allowed) return null;

  return <Component {...props} />;
};

export default withUserPermission;
