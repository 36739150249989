import { Box, Button, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ObjectSourceViewWidgetBox from "./ObjectSourceViewWidgetBox";
import { ObjectSourceViewContext } from "./ObjectSourceViewForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function ObjectSourceViewWidgetsSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { onHandleSave } = React.useContext(ObjectSourceViewContext);

  const objectSourceViewsDataMap = useSelector(
    (state) => state.core.objectSourceViewsSlice.data
  );

  const activeId = useSelector(
    (state) => state.core.objectSourceViewsSlice.activeId
  );

  const selectedWidgetUuIds = _.get(
    _.get(objectSourceViewsDataMap, activeId),
    "sourceWidgetUuIds"
  );

  const widgetsDataMap = useSelector(
    (state) => state.core.objectSourceViewsSlice.sourceWidgets
  );

  const widgetsData = Object.values(widgetsDataMap);

  function generateAvailableWidgets() {
    let result = [];

    if (Array.isArray(widgetsData)) {
      widgetsData.forEach((widget, index) => {
        if (!selectedWidgetUuIds.includes(widget.uuId)) {
          result.push(
            <ObjectSourceViewWidgetBox
              key={`source-widget-${index}`}
              id={widget.uuId}
              title={widget.name}
              description={widget.sourceMin.abbrev}
            />
          );
        }
      });
    }

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <NtaiPanel
        width="100%"
        height="15%"
        header="Select and Add Widgets"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            p: theme.spacing(2),
            flexDirection: "column",
            borderBottom: theme.general.border1,
          }}
        >
          <NtaiTextField name="search" label="Search.." />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            px: theme.spacing(2),
            py: theme.spacing(1),
            borderBottom: theme.general.border1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end" }}></Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: theme.spacing(1),
            }}
          >
            <Button size="small">RESET</Button>

            <Button
              onClick={() => onHandleSave()}
              size="small"
              variant="contained"
            >
              ADD
            </Button>
          </Box>
        </Box>

        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: theme.spacing(1),
                p: theme.spacing(2),
              }}
            >
              {generateAvailableWidgets()}
            </Box>
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
}
