import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import SearchSourceToolbar from "./toolbar/SearchSourceToolbar";
import SearchSourceRecordListPanel from "./records/SearchSourceRecordListPanel";
import { SearchContext } from "../SearchEdit";
import { useDispatch, useSelector } from "react-redux";
import SearchSourceView from "./view/SearchSourceView";
import {
  clearStatus,
  createOrDeleteWidgetFilter,
  getSearchSource,
  handleLinkClickForLinkedSearchSource,
} from "./store/searchSourcesSlice";
import SearchFilterScheme from "./advanced/filter/SearchFilterScheme";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { SRH_SEARCH_TYPE_CODE } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

export const SearchSourceContext = React.createContext();

export default function SearchSource(props) {
  const { searchSourceData, selected, searchDashboardSourceViewId } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(0);
  const [recordsCount, setRecordsCount] = useState();
  const [refresh, setRefresh] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [selectedWidgetRecordId, setSelectedWidgetRecordId] = useState();
  const [chartIds, setChartIds] = useState([]);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const {
    searchData,
    showAdvSearchFilterPanel,
    showSearchSourceRecords,
    selectedSearchObjectDashboardIndex,
  } = React.useContext(SearchContext);

  const searchId = _.get(searchData, "uuId");
  const status = useSelector((state) => state.search.searchSourcesSlice.status);
  const statusSearchSourceHierarchySlice = useSelector(
    (state) => state.search.searchSourceHierarchySlice.status
  );

  const searchTypeCode = _.get(searchData, "typeCode");

  function getSearchSourceViewWidth(
    pSearchTypeCode,
    pShowSearchSourceRecords,
    pShowFilterPanel
  ) {
    if (pSearchTypeCode === 1) {
      return pShowSearchSourceRecords ? "75%" : "100%";
    } else if (pSearchTypeCode === 3) {
      const searchDashboardRecordsFg = _.get(
        searchData["searchObjectDashboards"][
          selectedSearchObjectDashboardIndex
        ],
        "displayRecordsFg"
      );

      const searchDashboardFiltersFg = _.get(
        searchData["searchObjectDashboards"][
          selectedSearchObjectDashboardIndex
        ],
        "displayFiltersFg"
      );

      if (searchDashboardRecordsFg === 1) {
        if (searchDashboardFiltersFg === 1) {
          if (pShowSearchSourceRecords) {
            return pShowFilterPanel ? "65%" : "75%";
          } else {
            return pShowFilterPanel ? "85%" : "100%";
          }
        } else {
          return pShowSearchSourceRecords ? "75%" : "100%";
        }
      } else {
        if (searchDashboardFiltersFg === 1) {
          return pShowFilterPanel ? "85%" : "100%";
        } else {
          return "100%";
        }
      }
    } else {
      if (pShowSearchSourceRecords) {
        return pShowFilterPanel ? "65%" : "75%";
      } else {
        return pShowFilterPanel ? "85%" : "100%";
      }
    }
  }

  function handleFilter(
    actionCode,
    sourceWidgetUuId,
    searchSourceWidgetFilterFields
  ) {
    dispatch(
      createOrDeleteWidgetFilter({
        searchId: _.get(searchData, "uuId"),
        searchSourceId: _.get(searchSourceData, "uuId"),
        formData: {
          actionCode: actionCode,
          primarySourceWidgetUuId: sourceWidgetUuId,
          searchSourceWidgetFilterFields: searchSourceWidgetFilterFields,
        },
      })
    );
  }

  function handleLinkClick(
    linkedSourceUuId,
    linkUuId,
    linkValue,
    sourceRecordId
  ) {
    dispatch(
      handleLinkClickForLinkedSearchSource({
        searchId: searchId,
        parentSearchSourceId: searchSourceId,
        linkedSourceSourceUuId: linkedSourceUuId,
        linkUuId: linkUuId,
        linkValue: linkValue,
        sourceRecordId: sourceRecordId,
      })
    );
  }

  useEffect(() => {
    if (
      statusSearchSourceHierarchySlice &&
      [
        "selectSearchSourceHierarchyNode",
        "deleteSearchSourceHierarchyNode",
      ].includes(statusSearchSourceHierarchySlice.method) &&
      statusSearchSourceHierarchySlice.result === "success"
    ) {
      dispatch(
        getSearchSource({
          searchId: searchId,
          searchSourceId: _.get(searchSourceData, "uuId"),
        })
      );
    }
  }, [statusSearchSourceHierarchySlice]);

  useEffect(() => {
    if (
      status &&
      [
        "createOrRetrieveLinkedSearchSource",
        "handleLinkClickForLinkedSearchSource",
        "createOrDeleteWidgetFilter",
        "getSearchSource",
        "loadSearchSourceRecordSeries",
        "unloadSearchSourceRecordSeries",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setReload(reload + 1);
      setRefresh(true);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <SearchSourceContext.Provider
      value={{
        searchSourceData,
        reload,
        setReload,
        refresh,
        setRefresh,
        selected,
        changeView,
        setChangeView,
        recordsCount,
        setRecordsCount,
        tableView,
        setTableView,
        selectedWidgetRecordId,
        setSelectedWidgetRecordId,
        chartIds,
        setChartIds,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        {([
          SRH_SEARCH_TYPE_CODE.SEARCH,
          SRH_SEARCH_TYPE_CODE.DASHBOARD,
          SRH_SEARCH_TYPE_CODE.ANALYTICS_DATASET,
        ].includes(searchTypeCode) ||
          ([
            SRH_SEARCH_TYPE_CODE.ANALYTICS_STANDARD,
            SRH_SEARCH_TYPE_CODE.IMPORTED_SERIES,
            SRH_SEARCH_TYPE_CODE.REPORT,
          ].includes(searchTypeCode) &&
            _.get(searchSourceData, "searchSourceAdvancedFilterPresentFg") ===
              1)) && (
          <React.Fragment>
            {!tableView && (
              <Box
                sx={{
                  flexBasis: getSearchSourceViewWidth(
                    searchTypeCode,
                    showSearchSourceRecords,
                    showAdvSearchFilterPanel
                  ),
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
                id="search-source-view-panel"
              >
                <React.Fragment>
                  <SearchSourceToolbar />
                  <SearchSourceView
                    handleFilter={handleFilter}
                    handleLinkClick={handleLinkClick}
                    searchDashboardSourceViewId={searchDashboardSourceViewId}
                  />
                </React.Fragment>
              </Box>
            )}

            {showSearchSourceRecords &&
              ([
                SRH_SEARCH_TYPE_CODE.SEARCH,
                SRH_SEARCH_TYPE_CODE.ANALYTICS_STANDARD,
                SRH_SEARCH_TYPE_CODE.ANALYTICS_DATASET,
                SRH_SEARCH_TYPE_CODE.IMPORTED_SERIES,
                SRH_SEARCH_TYPE_CODE.REPORT,
              ].includes(searchData["typeCode"]) ||
                (searchData["typeCode"] === SRH_SEARCH_TYPE_CODE.DASHBOARD &&
                  _.get(
                    searchData["searchObjectDashboards"][
                      selectedSearchObjectDashboardIndex
                    ],
                    "displayRecordsFg"
                  ) === 1)) && <SearchSourceRecordListPanel />}
          </React.Fragment>
        )}

        {[
          SRH_SEARCH_TYPE_CODE.ANALYTICS_STANDARD,
          SRH_SEARCH_TYPE_CODE.IMPORTED_SERIES,
          SRH_SEARCH_TYPE_CODE.REPORT,
        ].includes(searchTypeCode) &&
          _.get(searchSourceData, "searchSourceAdvancedFilterPresentFg") ===
            0 && (
            <Box
              sx={{
                flexBasis: getSearchSourceViewWidth(
                  searchTypeCode,
                  false,
                  showAdvSearchFilterPanel
                ),
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
              id="search-source-view-panel"
            >
              <NtaiEmptyMessage
                vAlign="center"
                header="View data not available"
                subheader="Select filters or enter criteria in the right planel to generate view"
              />
            </Box>
          )}

        {([2, 4, 5, 6].includes(searchTypeCode) ||
          (searchTypeCode === 3 &&
            _.get(
              searchData["searchObjectDashboards"][
                selectedSearchObjectDashboardIndex
              ],
              "displayFiltersFg"
            ) === 1)) &&
          showAdvSearchFilterPanel && <SearchFilterScheme />}
      </Box>
    </SearchSourceContext.Provider>
  );
}
