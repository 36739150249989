import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useMeasure } from "react-use";
import DashboardViewGridLayout from "./DashboardViewGridLayout";

export default function DashboardViewLayout({
  layout,
  generateWidgetList,
  handleLayoutChange,
}) {
  const theme = useTheme();
  const [ref, { width, height }] = useMeasure();

  return (
    <Scrollbar autoHide={true}>
      <Box
        ref={ref}
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
        }}
      >
        <DashboardViewGridLayout
          width={width}
          rowHeight={40}
          cols={12}
          layout={layout ? layout : []}
          items={generateWidgetList()}
          handleLayoutChange={handleLayoutChange}
        />
      </Box>
    </Scrollbar>
  );
}
