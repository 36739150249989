import {
  faClose,
  faExclamationTriangle,
  faStar,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useState } from "react";
import NtaiIcons from "utils/NtaiIcons";
const _ = require("lodash");

export default function SourceOutJobMigrationStepCard(props) {
  const theme = useTheme();
  const {
    id,
    storeId,
    width,
    height,
    status,
    title,
    description,
    handleClick,
    subTitles,
  } = props;

  return (
    <Card
      sx={{
        p: 0,
        paddingY: "4px",
        border: theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        height: height ? height : "100%",
        width: width ? width : "100%",
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
          background: theme.colors.secondary.lighter,
        },
      }}
      onClick={() => handleClick(id, storeId)}
    >
      <CardHeader
        sx={{ pt: 1 }}
        title={
          title ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="700"
                  color="inherit"
                >
                  {NtaiUtils.trunc(title, 25)}
                </Typography>
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(0.5),
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
              </Box> */}
            </Box>
          ) : null
        }
      />

      <CardContent sx={{ py: 0 }}>
        <Box sx={{ display: "flex", textAlign: "start" }}>
          <Typography variant="subtitle2">{description}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
