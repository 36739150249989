import { useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import WorklfowDefVersionActivityRuleForm from "./WorklfowDefVersionActivityRuleForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import { getRules } from "../../../../rule/store/rulesSlice";
import {
  getWorkflowDefVersionActivityRules,
  createWorkflowDefVersionActivityRule,
  updateWorkflowDefVersionActivityRule,
  deleteWorkflowDefVersionActivityRule,
  clearStatus,
} from "./store/workflowDefVersionActivityRulesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "ruleName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "execPositionDecode",
    numeric: false,
    disablePadding: false,
    label: "Exec Position",
  },

  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified by",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const data = [
  {
    uuId: "1",
    name: "Some",
    description: "Some more",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function WorkflowDefVersionActivityRules() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [action, setAction] = useState();

  const workflowDefId = useSelector(
    (state) => state.core.workflowDefsSlice.activeId
  );
  const workflowDefVersionId = useSelector(
    (state) => state.core.workflowDefVersionsSlice.activeId
  );

  const workflowDefVersionActivityId = useSelector(
    (state) => state.core.workflowDefVersionActivitiesSlice.activeId
  );

  const rulesDataMap = useSelector((state) => state.core.rulesSlice.data);
  const ruleSliceStatus = useSelector((state) => state.core.rulesSlice.status);
  const rulesData =
    rulesDataMap && !_.isEmpty(rulesDataMap) ? Object.values(rulesDataMap) : [];

  const dataMap = useSelector(
    (state) => state.core.workflowDefVersionActivityRulesSlice.data
  );
  const status = useSelector(
    (state) => state.core.workflowDefVersionActivityRulesSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const pathVariables = {
    workflowDefId: workflowDefId,
    workflowDefVersionId: workflowDefVersionId,
    workflowDefVersionActivityId: workflowDefVersionActivityId,
  };

  function handleDialogAddEdit(id) {
    setAction(id ? "edit" : "add");
    setCurrentId(id);
    handleDialogOpen();
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteWorkflowDefVersionActivityRule({ ...pathVariables, uuId: id })
      );
    });
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  function handleDialogClose() {
    setOpen(false);
    setCurrentId(null);
  }

  function handleDialogOpen() {
    setOpen(true);
  }

  function handleSave(formData) {
    dispatch(
      action === "add"
        ? createWorkflowDefVersionActivityRule({
            ...pathVariables,
            formData: formData,
          })
        : updateWorkflowDefVersionActivityRule({
            ...pathVariables,
            uuId: currentId,
            formData: formData,
          })
    );
  }

  useEffect(() => {
    if (
      status &&
      [
        "createWorkflowDefVersionActivityRule",
        "updateWorkflowDefVersionActivityRule",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      ["deleteWorkflowDefVersionActivityRule"].includes(status.method) &&
      status.result === "success"
    ) {
      cancelDelete();
      dispatch(clearStatus());
      setSelectedIds([]);
    }
  }, [status]);

  React.useEffect(() => {
    dispatch(getRules());
    dispatch(
      getWorkflowDefVersionActivityRules({
        workflowDefId: workflowDefId,
        workflowDefVersionId: workflowDefVersionId,
        workflowDefVersionActivityId: workflowDefVersionActivityId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <NtaiPanel
        width="100%"
        header="Rules"
        subheader="You can associate one or more rules from rule library with activity pre and post exit transition"
      >
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          selectedIds={selectedIds}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          title="Rule"
        />
      </NtaiPanel>
      <NtaiDialog
        open={open}
        handleDialogClose={handleDialogClose}
        title="Add Rule"
        size="sm"
      >
        {(action === "add" || (action === "edit" && currentId)) && (
          <NtaiForm>
            <WorklfowDefVersionActivityRuleForm
              action={action}
              formData={action === "edit" ? dataMap[currentId] : {}}
              handleSave={handleSave}
              handleCancel={handleDialogClose}
            />
          </NtaiForm>
        )}
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "ruleName")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
