import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, lighten, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH300072417");

export default function NtaiScatterChart(props) {
  const { widgetId, data, xField, yField } = props;
  const theme = useTheme();

  useLayoutEffect(() => {
    let chart = am4core.create(
      "scatter-chart".concat("-", widgetId),
      am4charts.XYChart
    );
    chart.data = data;

    chart.cursor = new am4charts.XYCursor();
    chart.responsive.enabled = true;

    let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    let xAxisLabel = xAxis.renderer.labels.template;
    xAxisLabel.fontSize = 10;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    let yAxisLabel = yAxis.renderer.labels.template;
    yAxisLabel.fontSize = 10;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = xField;
    series.dataFields.valueY = yField;
    series.strokeOpacity = 0;
    series.tooltipText = "x: {valueX}, y: {valueY}";

    let bullet = series.bullets.push(new am4charts.Bullet());
    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 2;

    // let errorBulletY = series.bullets.create(am4charts.ErrorBullet);
    // errorBulletY.isDynamic = true;
    // errorBulletY.strokeWidth = 2;
    // errorBulletY.tooltipText =
    //   "x: {valueX.value} y: {valueY.value} error x:{errorX}, error y:{errorY}";

    let xAxisTooltip = xAxis.tooltip;
    xAxisTooltip.background.fill = am4core.color("#767676");
    xAxisTooltip.background.strokeWidth = 0;
    xAxisTooltip.background.cornerRadius = 3;
    xAxisTooltip.background.pointerLength = 0;
    xAxisTooltip.dy = 5;
    xAxisTooltip.label.fill = am4core.color("#fff");
    xAxisTooltip.label.fontSize = 8;
    xAxisTooltip.cursorTooltipEnabled = false;
    xAxisTooltip.sortBySeries = series;

    let yAxisTooltip = yAxis.tooltip;
    yAxisTooltip.background.fill = am4core.color("#767676");
    yAxisTooltip.background.strokeWidth = 0;
    yAxisTooltip.background.cornerRadius = 3;
    yAxisTooltip.background.pointerLength = 0;
    yAxisTooltip.rotation = 270;
    yAxisTooltip.dy = 5;
    yAxisTooltip.dx = -20;
    yAxisTooltip.label.fill = am4core.color("#fff");
    yAxisTooltip.label.fontSize = 11;

    am4charts.ValueAxis.prototype.getSeriesDataItem = function (
      series,
      position
    ) {
      const key = this.axisFieldName + this.axisLetter;
      const value = this.positionToValue(position);
      const dataItem = series.dataItems.getIndex(
        series.dataItems.findClosestIndex(
          value,
          (x) => {
            return x[key] ? x[key] : undefined;
          },
          "any"
        )
      );
      return dataItem;
    };

    // tool tip
    series.tooltip.getFillFromObject = false;
    series.tooltip.autoTextColor = false;
    series.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    series.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    series.tooltip.background.fillOpacity = tooltipConfig.backgroundFillOpacity;
    series.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    series.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    // series.columns.template.events.on(
    //   "hit",
    //   function (ev) {
    //     console.log("EV: ", ev);
    //     // const sourceWidgetFilterFields = [
    //     //   {
    //     //     sourceFieldUuId: Object.keys(_.get(metadata, "fields"))[0],
    //     //     fieldOperatorCode: 1,
    //     //     fieldValue: _.get(ev.target.dataItem.dataContext, categoryField),
    //     //   },
    //     // ];
    //     // handleClick(sourceWidgetFilterFields, ev);
    //   },
    //   this
    // );

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"scatter-chart".concat("-", widgetId)}
      sx={{ width: "100%", height: "100%" }}
    ></Box>
  );
}
