import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  clearStatus,
  createPythonFile,
  setActiveId,
} from "./store/pythonFilesSlice";
import PythonFileForm from "./PythonFileForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import History from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function PythonFileCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.models.pythonFilesSlice.data);
  const status = useSelector((state) => state.models.pythonFilesSlice.status);
  const activeId = useSelector(
    (state) => state.models.pythonFilesSlice.activeId
  );

  function handleSave(formData) {
    dispatch(createPythonFile(formData));
  }

  function handleCancel() {
    History.push("/models/python-files");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createPythonFile" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Python file "${_.get(
          dataMap[activeId],
          "fileName"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
      History.push(`/models/python-files/${activeId}/edit`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(null));
  }, []);

  return (
    <NtaiPage title="Add Python File">
      <NtaiForm>
        <PythonFileForm
          action="add"
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
