export const OVERSIGHT_SECTIONS = {
  ROOT: "root",
  OBJECT: "object",
  ENTITY: "entity",
  ASSESSMENTS: "assessments",
  ACTIONS: "actions",
  NOTES: "notes",
  DOCUMENTS: "documents",
  SNAPSHOTS: "snapshots",
  SOURCE_RECORDS: "source_records",
};

export const OVERSIGHT_TOPIC_SECTIONS = {
  ASSESSMENTS: "assessments",
  ACTIONS: "actions",
  NOTES: "notes",
  DOCUMENTS: "documents",
  SNAPSHOTS: "snapshots",
  SOURCE_RECORDS: "source_records",
};
