import { Box, useTheme } from "@mui/material";
import * as React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./ntaiCalendar.css";

export default function NtaiDateCalendar() {
  const theme = useTheme();
  const [value, onChange] = React.useState(new Date());

  return <Calendar onChange={onChange} value={value} />;
}
