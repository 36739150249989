import React, { useContext } from "react";
import withUserPermission from "./withUserPermission";
import { Box, Button, useTheme } from "@mui/material";
import { UserPermissionStore } from "./UserPermissionProvider";

const RBACComponent1 = (props) => {
  const theme = useTheme();

  return (
    <Box sx={{ p: theme.spacing(2) }}>
      <Button variant="contained">RBACComponent1</Button>
    </Box>
  );
};

export default withUserPermission(RBACComponent1);
