import React from "react";

const Svg = () => {
  return (
    <svg
      style={{
        border: "2px solid gold",
      }}
    />
  );
};

export default function NtaiD31() {
  return (
    <div>
      <Svg />
    </div>
  );
}
