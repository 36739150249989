import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourcePolicyAssessmentList from "./assessment/SourcePolicyAssessmentList";
const _ = require("lodash");

export default function SourcePolicyForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedFields, setSelectedFields] = React.useState(
    action === "add" ? [] : formData["sourcePolicyinitionFields"]
  );

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);
  const sourcePolicyTypeOptions =
    codelistCodes &&
    _.has(codelistCodes, CODELIST_CODES.SRC_SOURCE_POLICY_TYPE_CODE)
      ? _.get(codelistCodes, CODELIST_CODES.SRC_SOURCE_POLICY_TYPE_CODE)
      : [];

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const activeId = action === "add" ? null : formData["uuId"];

  const dataMap = useSelector(
    (state) => state.sources.sourcePoliciesSlice.data
  );

  const data = _.get(dataMap, activeId);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourcePolicyForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({
      sourcePolicyForm: action === "edit" ? formData : null,
    });
  }, [formData]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_POLICY_TYPE_CODE));
  }, []);

  return (
    <NtaiTabs>
      <Box label="GENERAL">
        <NtaiFormPanel
          width="100%"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{ display: "flex", alignItems: "start", gap: theme.spacing(4) }}
          >
            <NtaiPanel
              padding="0px"
              width="40%"
              header="General Details"
              subheader="Enter name, description and select the section wher policy will be applied"
            >
              <NtaiTextField
                name="sourcePolicyForm.name"
                label="Name*"
                rules={{ required: "Name is required" }}
              />

              <NtaiTextField
                name="sourcePolicyForm.description"
                label="Description"
                multiline
                minRows={3}
                maxRows={3}
              />
              <NtaiSelectField
                isDisabled={action === "edit" ? true : false}
                name="sourcePolicyForm.typeCode"
                label="Applicable Section*"
                options={sourcePolicyTypeOptions}
                rules={{ required: "Type is required" }}
              />
            </NtaiPanel>
          </Box>
        </NtaiFormPanel>
      </Box>
      <Box label="ASSESSMENT FORMS">
        <SourcePolicyAssessmentList sourcePolicyId={activeId} />
      </Box>
    </NtaiTabs>
  );
}
