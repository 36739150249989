import UserAccountHome from "./UserAccountHome";

const UserAccountConfig = {
  routes: [
    {
      path: "/account",
      exact: true,
      component: UserAccountHome,
    },
  ],
};

export default UserAccountConfig;
