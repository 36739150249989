import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import NtaiDecoTree from "@ntai/components/treeview/NtaiDecoTree";
import React from "react";
import { OversightContext } from "../Oversight";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useState } from "react";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  clearStatus,
  getOversightNavigationTree,
  selectOversightNavigationTopic,
} from "./store/oversightNavigationSlice";
import { OVERSIGHT_SECTIONS } from "../constants/OversightConstants";
const _ = require("lodash");

export default function OversightNavigation() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const {
    navNode,
    setNavNode,
    setOversightTopicId,
    toggleLeftNav,
    setSection,
    setSubSection,
  } = React.useContext(OversightContext);

  const navigationData = useSelector(
    (state) => state.oversight.oversightNavigationSlice.data
  );

  const status = useSelector(
    (state) => state.oversight.oversightNavigationSlice.status
  );

  const activeOversightTopicId = useSelector(
    (state) => state.oversight.oversightNavigationSlice.activeId
  );

  function handleNavItemClick(node) {
    setNavNode(node);

    if (
      _.get(node, "uuId") &&
      _.get(node, "parentUuId") &&
      _.get(node, "type")
    ) {
      if (["OBJECT_TOPIC", "DATA_TOPIC"].includes(_.get(node, "type"))) {
        // setSection(OVERSIGHT_SECTIONS.ASSESSMENTS);
        setSection(OVERSIGHT_SECTIONS.ENTITY);
        setNavNode(node);
        setSubSection("topic-details");
        // setSubSection("topic-details");
      }
    }

    if ("ROOT" === _.get(node, "type")) {
      setSection(OVERSIGHT_SECTIONS.ROOT);
    }

    if ("OBJECT" === _.get(node, "type")) {
      setSection(OVERSIGHT_SECTIONS.OBJECT);
    }

    if ("ENTITY" === _.get(node, "type")) {
      setSection(OVERSIGHT_SECTIONS.ENTITY);
    }
  }

  useEffect(() => {
    if (
      navNode &&
      _.get(navNode, "uuId") &&
      _.get(navNode, "parentUuId") &&
      _.get(navNode, "type")
    ) {
      const topicUuId = navNode["uuId"];
      const parentUuId = navNode["parentUuId"];
      const type = navNode["type"];

      if (["OBJECT_TOPIC", "DATA_TOPIC"].includes(type)) {
        // setNavNode(navNode);

        dispatch(
          selectOversightNavigationTopic({
            dataUuId: parentUuId,
            topicUuId: topicUuId,
            params: {
              topicType: type,
            },
          })
        );
      }
    }
  }, [navNode]);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "selectOversightNavigationTopic"
    ) {
      setOversightTopicId(activeOversightTopicId);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "280px",
        borderRight: theme.general.border1,
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <React.Fragment>
          <Box
            sx={{
              alignItems: "center",
              py: theme.spacing(1),
              px: theme.spacing(2),
            }}
          >
            <NtaiSimpleSearchBar placeholder="Search..." />
          </Box>
          <Box
            sx={{
              display: "flex",
              height: `calc(100% - 44px)`,
            }}
          >
            {/* <SidebarMenu /> */}
            <Scrollbar>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  pr: theme.spacing(2),
                }}
              >
                <NtaiDecoTree
                  data={navigationData}
                  idField="uuId"
                  iconField="type"
                  typeField="type"
                  labelField="name"
                  badgeField="count"
                  handleClick={handleNavItemClick}
                />
              </Box>
            </Scrollbar>
          </Box>
        </React.Fragment>

        {!navigationData && (
          <NtaiEmptyMessage
            header="No records found"
            subheader="Add library objects"
            vAlign="center"
          />
        )}
      </Box>
    </Box>
  );
}
