import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import History from "@ntai/@history";
import {
  clearStatus,
  setActiveId,
  updateSourceRecordLabel,
} from "./store/sourceRecordLabelsSlice";
import SourceRecordLabelForm from "./SourceRecordLabelForm";
const _ = require("lodash");

export default function SourceRecordLabelEdit(props) {
  const dispatch = useDispatch();

  const { id, handleCancel } = props;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.data
  );

  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceRecordLabel({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.method === "updateSourceRecordLabel" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiForm>
      <SourceRecordLabelForm
        action="edit"
        formData={formData}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
