import { createSlice } from "@reduxjs/toolkit";
import jwtService from "app/services/jwtService";
import history from "@ntai/@history";

const _ = require("lodash");

export const setUserData = (user) => async (dispatch, getState) => {
  /*
        You can redirect the logged-in user to a specific route depending on his role
         */

  //   History.location.state = {
  //     redirectUrl: "/dashboard", // for example 'apps/academy'
  //   };

  history.push("/dashboard");

  // console.log("Setting user data: ", user);
  /*
    Set User Settings
     */
  //   dispatch(setDefaultSettings(user.data.settings));

  dispatch(setUser(user));
};

// export const updateUserSettings = (settings) => async (dispatch, getState) => {
//   const oldUser = getState().auth.user;
//   const user = _.merge({}, oldUser, { data: { settings } });

//   dispatch(updateUserData(user));

//   return dispatch(setUserData(user));
// };

// export const updateUserShortcuts =
//   (shortcuts) => async (dispatch, getState) => {
//     const { user } = getState().auth;
//     const newUser = {
//       ...user,
//       data: {
//         ...user.data,
//         shortcuts,
//       },
//     };

//     dispatch(updateUserData(user));

//     return dispatch(setUserData(newUser));
//   };

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  history.push({
    pathname: "/",
  });

  jwtService.logout();
  //   dispatch(setInitialSettings());
  return dispatch(userLoggedOut());
};

// export const updateUserData = (user) => async (dispatch, getState) => {
//   if (!user.role || user.role.length === 0) {
//     // is guest
//     return;
//   }

//   jwtService
//     .updateUserData(user)
//     .then(() => {
//       dispatch(showMessage({ message: "User data saved with api" }));
//     })
//     .catch((error) => {
//       dispatch(showMessage({ message: error.message }));
//     });
// };

const initialState = {
  data: {},
};

const userDataSlice = createSlice({
  name: "auth/userData",
  initialState: {
    data: {},
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userDataSlice.actions;

export default userDataSlice.reducer;
