import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import {
  clearStatus,
  getSearchTopic,
  updateSearchTopic,
} from "./store/searchTopicsSlice";
import { SearchContext } from "../../../SearchEdit";
import { getClientDataTopics } from "app/main/pages/core/cdo/topic/store/clientDataTopicsSlice";
const _ = require("lodash");

function transformTopics(topics) {
  const returnValue = [];
  topics.forEach((topic) => {
    returnValue.push({
      value: topic.uuId.concat(",", topic.entityUuId, ",", topic.objectTopicFg),
      label: topic.entityName.concat(" - ").concat(topic.topicName),
    });
  });

  return returnValue;
}

export default function SearchTopicForm({ handleSave, handleCancel }) {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { searchData } = React.useContext(SearchContext);

  const { getValues, setValue, reset } = useFormContext();

  const allTopicsDataMap = useSelector(
    (state) => state.core.clientDataTopicsSlice.data
  );

  const topicOptions = transformTopics(Object.values(allTopicsDataMap));

  const searchTopicData = useSelector(
    (state) => state.search.searchTopicsSlice.data
  );

  const status = useSelector((state) => state.search.searchTopicsSlice.status);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchTopicForm")
    );

    if (
      _.get(sanitizedFormData, "uuIds") &&
      _.get(sanitizedFormData, "uuIds").length > 0
    ) {
      const topicUuId = _.get(sanitizedFormData, "uuIds").split(",")[0];
      const dataUuId = _.get(sanitizedFormData, "uuIds").split(",")[1];
      const objectTopicFg = _.get(sanitizedFormData, "uuIds").split(",")[2];

      handleSave({
        dataOrObjectTopicUuId: topicUuId,
        dataUuId: dataUuId,
        objectTopicFg: objectTopicFg === "true" ? 1 : 0,
        remarks: _.get(sanitizedFormData, "remarks"),
      });
    }
  }

  function handleOtherAction(actionName) {
    if (actionName === "publish") {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("searchTopicForm")
      );

      dispatch(
        updateSearchTopic({
          searchId: _.get(searchData, "uuId"),
          uuId: _.get(searchTopicData, "uuId"),
          formData: {
            ...searchTopicData,
            remarks: _.get(sanitizedFormData, "remarks"),
          },
        })
      );
    }
  }

  useEffect(() => {
    // TODO: Get topics separately. There is a collision between search topic and data/topics
    dispatch(getClientDataTopics());
    dispatch(getSearchTopic(_.get(searchData, "uuId")));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      [
        "getSearchTopic",
        "createOrUpdateSearchTopic",
        "updateSearchTopic",
      ].includes(status.method)
    ) {
      if (searchTopicData && !_.isEmpty(searchTopicData)) {
        setValue(
          "searchTopicForm.uuIds",

          searchTopicData.dataOrObjectTopicUuId.concat(
            ",",
            searchTopicData.dataUuId,
            ",",
            searchTopicData.objectTopicFg ? true : false
          )
        );
        setValue("searchTopicForm.remarks", searchTopicData.remarks);

        if (
          status.method === "createOrUpdateSearchTopic" ||
          status.method === "updateSearchTopic"
        )
          handleCancel();
        dispatch(clearStatus());
      }
    }
  }, [status]);

  return (
    <>
      <NtaiDialogFormPanel
        handleSave={onHandleSave}
        handleCancel={handleCancel}
        handleSaveLabel="Save"
        otherActions={
          _.has(searchTopicData, "uuId")
            ? [
                {
                  value: "publish",
                  label: searchTopicData.publishedFg
                    ? "Undo Publish"
                    : "Publish",
                },
              ]
            : []
        }
        handleOtherAction={handleOtherAction}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <NtaiSelectField
            options={topicOptions}
            name="searchTopicForm.uuIds"
            label="Topic"
          />

          <NtaiTextField
            name="searchTopicForm.remarks"
            multiline
            minRows={3}
            maxRows={3}
            label="Remarks"
          />
        </Box>
      </NtaiDialogFormPanel>
    </>
  );
}
