import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceObjectFields = createAsyncThunk(
  "sourceObjectFields/getSourceObjectFields",
  async (values, { rejectWithValue }) => {
    const { sourceDefId, sourceId, sourceObjectId } = values;
    try {
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${sourceObjectId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceObjectField = createAsyncThunk(
  "sourceObjectFields/getSourceObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceObjectId, sourceObjectFieldId } =
        values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${sourceObjectId}/fields/${sourceObjectFieldId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceObjectField = createAsyncThunk(
  "sourceObjectFields/createSourceObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceObjectId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${sourceObjectId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceObjectField = createAsyncThunk(
  "sourceObjectFields/updateSourceObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceObjectId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${sourceObjectId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceObjectField = createAsyncThunk(
  "sourceObjectFields/deleteSourceObjectField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceObjectId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${sourceObjectId}/fields/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceObjectFieldsSlice = createSlice({
  name: "sourceObjectFields",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceObjectFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceObjectFields" };
    },
    [getSourceObjectFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceObjectFields",
        message: action.payload.message,
      };
    },
    [getSourceObjectField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceObjectField" };
    },
    [getSourceObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceObjectField",
        message: action.payload.message,
      };
    },
    [createSourceObjectField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceObjectField" };
    },
    [createSourceObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceObjectField",
        message: action.payload.message,
      };
    },
    [updateSourceObjectField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceObjectField" };
    },
    [updateSourceObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceObjectField",
        message: action.payload.message,
      };
    },
    [deleteSourceObjectField.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceObjectField" };
    },
    [deleteSourceObjectField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceObjectField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceObjectFieldsSlice.actions;

export default sourceObjectFieldsSlice.reducer;
