import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRules } from "../../../../rule/store/rulesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getCodes } from "../../../../codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { useFormContext } from "react-hook-form";
const _ = require("lodash");

export default function WorklfowDefVersionActivityRuleForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const rulesDataMap = useSelector((state) => state.core.rulesSlice.data);
  const ruleSliceStatus = useSelector((state) => state.core.rulesSlice.status);
  const rulesData =
    rulesDataMap && !_.isEmpty(rulesDataMap) ? Object.values(rulesDataMap) : [];

  const dataMap = useSelector(
    (state) => state.core.workflowDefVersionActivityRulesSlice.data
  );
  const status = useSelector(
    (state) => state.core.workflowDefVersionActivityRulesSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];
  const availableRuleOptions = NtaiUtils.getSelectOptions(
    rulesData,
    "uuId",
    "name"
  );

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const ruleExecPositionCodeOptions = _.has(
    codelistData,
    CODELIST_CODES.COM_RULE_EXEC_POSITION_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.COM_RULE_EXEC_POSITION_CODE)
    : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("workflowDefVersionActivityRuleForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    dispatch(getRules());
    dispatch(getCodes(CODELIST_CODES.COM_RULE_EXEC_POSITION_CODE));
    reset({
      workflowDefVersionActivityRuleForm: action === "edit" ? formData : {},
    });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="More informaiton"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiSelectField
        name="workflowDefVersionActivityRuleForm.ruleUuid"
        options={availableRuleOptions}
        label="Rule"
        rules={{ required: "Rule is required" }}
      />
      <NtaiSelectField
        name="workflowDefVersionActivityRuleForm.execPositionCode"
        options={ruleExecPositionCodeOptions}
        label="Pre/Post Execution"
        rules={{ required: "Pre/Post execution is required" }}
      />
    </NtaiDialogFormPanel>
  );
}
