import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
const _ = require("lodash");

export default function WidgetForm({ action, formData, handleSave }) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const contentCodes = _.get(codes, CODELIST_CODES.COR_VIEW_CONTENT_CODE);
  const aggregationCodes = _.get(
    codes,
    CODELIST_CODES.COR_VIEW_CONTENT_AGG_CODE
  );

  const dataMap = useSelector((state) => state.core.widgetsSlice.data);
  const data = Object.values(dataMap);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("widgetForm")
    );
    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push("/admin/widgets");
  }

  useEffect(() => {
    reset({ widgetForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiFormPanel
      width="520px"
      header="General Information"
      subheader="All standard widget fields are uneditable except the size field which determines number of records to be displayed for the widget. "
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiTextField name="widgetForm.name" label="Name" disabled={true} />
        <NtaiTextField name="widgetForm.size" label="# Records" type="number" />
      </Box>

      <NtaiTextField
        name="widgetForm.description"
        multiline
        minRows={2}
        maxRows={3}
        placeholder="Description"
        label="Description"
        disabled={true}
      />
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiSelectField
          label="Chart Type"
          name="widgetForm.contentCode"
          options={contentCodes}
          isDisabled={true}
        />
        <NtaiSelectField
          label="Aggregation Type"
          name="widgetForm.aggregationCode"
          options={aggregationCodes}
          isDisabled={true}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(1),
        }}
      >
        <NtaiTextField
          name="widgetForm.minNumOfCategoryFields"
          label="Minimum Category Fields"
          type="number"
          disabled={true}
        />
        <NtaiTextField
          name="widgetForm.maxNumOfCategoryFields"
          label="Maximum Category Fields"
          type="number"
          disabled={true}
        />
      </Box>

      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <NtaiTextField
          name="widgetForm.minNumOfNumericFields"
          label="Minimum Category Fields"
          type="number"
          disabled={true}
        />
        <NtaiTextField
          name="widgetForm.maxNumOfNumericFields"
          label="Maximum Numeric Fields"
          type="number"
          disabled={true}
        />
      </Box>

      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <NtaiTextField
          name="widgetForm.minNumOfDateFields"
          label="Minimum Date Fields"
          type="number"
          disabled={true}
        />
        <NtaiTextField
          name="widgetForm.maxNumOfDateFields"
          label="Maximum Date Fields"
          type="number"
          disabled={true}
        />
      </Box>
      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <NtaiSwitchField
          name="widgetForm.searchFg"
          label="Enable Search?"
          disabled={true}
        />
        <NtaiSwitchField
          name="widgetForm.paginationFg"
          label="Enable Pagination?"
          disabled={true}
        />
      </Box>
    </NtaiFormPanel>
  );
}
