import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";

const _ = require("lodash");

export default function SourceDefForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const categoryOptions = _.get(
    codelistData,
    CODELIST_CODES.SRC_SOURCE_DEFINITION_CATEGORY_CODE
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceDefForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ sourceDefForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
      subheader="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <NtaiTextField
            name="sourceDefForm.abbrev"
            label="Short Name*"
            rules={{ required: "Short name is required" }}
          />
          <NtaiTextField
            name="sourceDefForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />
        </Box>

        <NtaiTextField
          name="sourceDefForm.description"
          multiline
          minRows={2}
          maxRows={2}
          placeholder="Description"
          label="Description*"
          rules={{ required: "Description is required" }}
        />
        <NtaiTextField
          name="sourceDefForm.owner"
          label="Owner*"
          rules={{ required: "Owner is required" }}
        />
        <NtaiSelectField
          label="Category*"
          name="sourceDefForm.categoryCode"
          options={categoryOptions}
          rules={{ required: "Category is required" }}
        />
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <NtaiSwitchField name="sourceDefForm.privacyFg" label="Private?" />
          <NtaiSwitchField name="sourceDefForm.activeFg" label="Active?" />
          <NtaiSwitchField
            name="sourceDefForm.thesaurusFg"
            label="Thesaurus?"
          />
        </Box>
      </Box>
    </NtaiDialogFormPanel>
  );
}
