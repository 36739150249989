import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceDerivedFieldElems = createAsyncThunk(
  "sourceDerivedFieldElems/getSourceDerivedFieldElems",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}/elements`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceDerivedFieldElem = createAsyncThunk(
  "sourceDerivedFieldElems/getSourceDerivedFieldElem",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}/elemenets/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceDerivedFieldElem = createAsyncThunk(
  "sourceDerivedFieldElems/createSourceDerivedFieldElem",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}/elements`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceDerivedFieldElem = createAsyncThunk(
  "sourceDerivedFieldElems/updateSourceDerivedFieldElem",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId, uuId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}/elements/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceDerivedFieldElem = createAsyncThunk(
  "sourceDerivedFieldElems/deleteSourceDerivedFieldElem",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceDerivedFieldId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/derived-fields/${sourceDerivedFieldId}/elements/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceDerivedFieldElemsSlice = createSlice({
  name: "sourceDerivedFieldElems",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceDerivedFieldElems.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceDerivedFieldElems",
      };
    },
    [getSourceDerivedFieldElems.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDerivedFieldElems",
        message: action.payload.message,
      };
    },
    [getSourceDerivedFieldElem.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceDerivedFieldElem" };
    },
    [getSourceDerivedFieldElem.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceDerivedFieldElem",
        message: action.payload.message,
      };
    },
    [createSourceDerivedFieldElem.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceDerivedFieldElem",
      };
    },
    [createSourceDerivedFieldElem.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceDerivedFieldElem",
        message: action.payload.message,
      };
    },
    [updateSourceDerivedFieldElem.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceDerivedFieldElem",
      };
    },
    [updateSourceDerivedFieldElem.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceDerivedFieldElem",
        message: action.payload.message,
      };
    },
    [deleteSourceDerivedFieldElem.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceDerivedFieldElem",
      };
    },
    [deleteSourceDerivedFieldElem.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceDerivedFieldElem",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceDerivedFieldElemsSlice.actions;

export default sourceDerivedFieldElemsSlice.reducer;
