import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUserGroupResources,
  clearStatus,
  createUserGroupResource,
  deleteUserGroupResource,
} from "../store/userGroupResourcesSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getEntityMins,
  clearStatus as clearEntityMinsSliceStatus,
} from "app/main/pages/core/entity/store/entityMinsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];

  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.resourceDataUuId]["primaryField"],
        secondary: allObjectsDataMap[f.resourceDataUuId]["secondaryField"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["primaryField"], ["asc"]),
    function (f) {
      if (_.filter(selectedObjects, { resourceDataUuId: f.uuId }).length === 0)
        return true;
    }
  );

  return result;
}

export default function UserGroupEntities() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const userGroupId = useSelector(
    (state) => state.core.userGroupsSlice.activeId
  );

  const entityMinsDataMap = useSelector(
    (state) => state.core.entityMinsSlice.data
  );

  const entityMinsSliceStatus = useSelector(
    (state) => state.core.entityMinsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.core.userGroupResourcesSlice.data
  );

  const status = useSelector(
    (state) => state.core.userGroupResourcesSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const entityMinsData =
    entityMinsDataMap && !_.isEmpty(entityMinsDataMap)
      ? Object.values(entityMinsDataMap)
      : [];

  function handleAdd(entityUuId) {
    dispatch(
      createUserGroupResource({
        userGroupId: userGroupId,
        formData: {
          typeCode: 2,
          resoureDataUuIds: [entityUuId],
        },
      })
    );
  }

  function handleRemove(uuId) {
    dispatch(
      deleteUserGroupResource({
        userGroupId: userGroupId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      [
        "getUserGroupResources",
        "createUserGroupResource",
        "deleteUserGroupResource",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setAvailableObjects([...transformAvailableObjects(data, entityMinsData)]);
      setSelectedObjects([
        ...transformSelectedObjects(data, entityMinsDataMap),
      ]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      entityMinsSliceStatus &&
      entityMinsSliceStatus.result === "success" &&
      entityMinsSliceStatus.method === "getEntityMins"
    ) {
      dispatch(
        getUserGroupResources({
          userGroupId: userGroupId,
          params: {
            typeCode: 2,
          },
        })
      );
      dispatch(clearEntityMinsSliceStatus());
    }
  }, [entityMinsSliceStatus]);

  useEffect(() => {
    dispatch(
      getEntityMins({
        domainId: "*",
        objectId: "*",
        params: {
          categoryCode: 1,
        },
      })
    );
  }, []);

  return (
    <NtaiPanel
      width="75%"
      height="100%"
      header="Manage User Group Entities"
      subheader="You can assign available sources to the user group. User belonging to the user group will have all privileges pertaining to the source. Use user group source privilege in conjunction with user role to establish the source governance"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Entities Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "40%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["primaryField"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.primaryField}
                              </Typography>
                            }
                            secondary={fd.secondaryField}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No entities available"
                subheader="You can configure entities in Library."
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "60%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No entities assigned"
                subheader="Add entities from left panel to the user group"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
