import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceWidgets = createAsyncThunk(
  "sourceWidgetss/getSourceWidgets",
  async (values) => {
    const { sourceDefId, sourceId, viewId } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets`
    );
    return response.data;
  }
);

export const createSourceWidget = createAsyncThunk(
  "sourceWidgets/createSourceWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, viewId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceWidget = createAsyncThunk(
  "sourceWidgets/updateSourceWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, viewId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceWidget = createAsyncThunk(
  "sourceWidgets/deleteSourceWidget",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, viewId, uuId } = values;

      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/views/${viewId}/widgets/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceWidgetsSlice = createSlice({
  name: "sourceWidgets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceWidgets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceWidgets" };
    },
    [getSourceWidgets.rejected]: (state, action) => {
      state.status = {
        result: "success",
        method: "getSourceWidgets",
        message: action.payload.message,
      };
    },
    [createSourceWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceWidget" };
    },
    [createSourceWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceWidget",
        message: action.payload.message,
      };
    },
    [updateSourceWidget.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceWidget" };
    },
    [updateSourceWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceWidget",
        message: action.payload.message,
      };
    },
    [deleteSourceWidget.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceWidget" };
    },
    [deleteSourceWidget.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceWidget",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceWidgetsSlice.actions;

export default sourceWidgetsSlice.reducer;
