import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUserGroupUsers,
  clearStatus,
  createUserGroupUser,
  deleteUserGroupUser,
} from "./store/userGroupUsersSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getUsers,
  clearStatus as clearUsersSliceStatus,
} from "../../user/store/usersSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["user.userName"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.user.uuId]["userName"],
        secondary:
          allObjectsDataMap[f.user.uuId]["lastName"] +
          ", " +
          allObjectsDataMap[f.user.uuId]["firstName"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["userName"], ["asc"]),
    function (f) {
      const filtered = _.filter(selectedObjects, function (o) {
        if (o["user"]["uuId"] === f.uuId) return true;
        else return false;
      });

      return filtered.length === 0 ? true : false;
    }
  );

  return result;
}

export default function UserGroupUsers() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [sourcesLoaded, setUsersLoaded] = useState(false);
  const [userGroupUsersLoaded, setUserGroupUsersLoaded] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const userGroupId = useSelector(
    (state) => state.core.userGroupsSlice.activeId
  );

  const usersDataMap = useSelector((state) => state.core.usersSlice.data);

  const usersSliceStatus = useSelector((state) => state.core.usersSlice.status);

  const dataMap = useSelector((state) => state.core.userGroupUsersSlice.data);

  const status = useSelector((state) => state.core.userGroupUsersSlice.status);

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const usersData =
    usersDataMap && !_.isEmpty(usersDataMap) ? Object.values(usersDataMap) : [];

  function handleAdd(userUuId) {
    dispatch(
      createUserGroupUser({
        userGroupId: userGroupId,
        formData: {
          userUuIds: [userUuId],
        },
      })
    );
  }

  function handleRemove(uuId) {
    dispatch(
      deleteUserGroupUser({
        userGroupId: userGroupId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      [
        "getUserGroupUsers",
        "createUserGroupUser",
        "deleteUserGroupUser",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setAvailableObjects([...transformAvailableObjects(data, usersData)]);
      setSelectedObjects([...transformSelectedObjects(data, usersDataMap)]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      usersSliceStatus &&
      usersSliceStatus.result === "success" &&
      usersSliceStatus.method === "getUsers"
    ) {
      dispatch(getUserGroupUsers(userGroupId));
      dispatch(clearUsersSliceStatus());
    }
  }, [usersSliceStatus]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <NtaiPanel
      width="75%"
      height="100%"
      header="Manage User Group Users"
      subheader="You can assign available users to the user group. User belonging to the user group will have all privileges pertaining to the user group resources. Use user group privileges in conjunction with user role to establish the user specific governance"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Users Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "40%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["userName"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.userName}
                              </Typography>
                            }
                            secondary={fd.lastName + ", " + fd.firstName}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No users available"
                subheader="You can configure users from Admin - Users."
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "60%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No users assigned"
                subheader="Add users from left panel to the user group"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
