import { Box, Typography } from "@mui/material";
import React from "react";

export default function NtaiLabelValue1({ label, value }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1" fontWeight="700" color="inherit">
        {label}
      </Typography>

      <Typography variant="body2" fontWeight="300">
        {value}
      </Typography>
    </Box>
  );
}
