import SourcePipelineList from "./SourcePipelineList";
import SourcePipelineManage from "./SourcePipelineManage";

const SourcePlConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/pipelines",
      exact: true,
      component: SourcePipelineList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/pipelines/:id",
      exact: true,
      component: SourcePipelineManage,
    },
  ],
};

export default SourcePlConfig;
