import { Box, Card, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import AppContext from "app/AppContext";
import { renderRoutes } from "react-router-config";
import React, { useContext } from "react";
import { makeStyles, styled } from "@mui/styles";
import AppHeader from "./header/AppHeader";
import Footer from "./footer/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import LeftNav from "./leftnav/LeftNavigation";

const useStyles = makeStyles((theme) => ({
  root1: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#000",
    height: "100vh",
  },

  main: {
    height: "calc(100vh - 48px)",
    display: "flex",
    position: "relative",
    top: "48px",
    // bottom: "20px",
    backgroundColor: "white",
  },
  page: {
    // position: "fixed",
    display: "flex",
    flexDirection: "column",
    // flexBasis: "96%",
    width: "100%",
    // height: "calc(100vh - 44px)",
    // top: "44px",
    // borderBottom: "1px solid #000",
    // backgroundColor: grey[100],
  },

  leftPanel: {
    // flexBasis: "4%",
    width: "48px",
    // padding: "0px",
    borderRight: `solid 1px ${grey[300]}`,
    // backgroundColor: theme.palette.secondary.dark,
  },

  rightPanel: {
    flexBasis: "4%",
    padding: "2px",
    borderLeft: `solid 1px ${grey[300]}`,
  },

  footer: {
    display: "flex",
    height: "20px",
    borderTop: "0px solid #eee",
    backgroundColor: "white", // grey[50],
  },
}));

const PageWrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    display: absolute;
    background: ${theme.palette.common.white};
    flex: 1;
    overflow-x: hidden;
    height: "100vh",
`
);

export default function Layout1(props) {
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const classes = useStyles({ ...props });

  const appHeader = useSelector((state) => state.ntai.navigation.appHeader);

  const leftSidebarNav = useSelector(
    (state) => state.ntai.navigation.leftSidebarNav
  );

  // console.log("app header: ", appHeader);
  // console.log("left bar nav: ", leftSidebarNav);

  return (
    <PageWrapper>
      <HelmetProvider>
        <Helmet>
          <title>NextTrial AI Platform</title>
        </Helmet>

        {appHeader && <AppHeader />}

        <Box className={classes.main}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {leftSidebarNav && <LeftNav />}
            {renderRoutes(routes)}
          </Box>
        </Box>
      </HelmetProvider>
    </PageWrapper>
  );
}
