import React from "react";
import SourceCatalogAssetDatasetPreview from "./SourceCatalogAssetDatasetPreview";

export default function SourceCatalogAssetPreview({
  assetUuId,
  assetTypeCode,
}) {
  return (
    <React.Fragment>
      {assetTypeCode === 3 && (
        <SourceCatalogAssetDatasetPreview uuId={assetUuId} />
      )}
    </React.Fragment>
  );
}
