import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getWorkflowDefVersionActivities = createAsyncThunk(
  "workflowDefVersionActivity/getWorkflowDefVersionActivities",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, workflowDefVersionId } = values;
      const response = await server.get(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWorkflowDefVersionActivity = createAsyncThunk(
  "workflowDefVersionActivity/getWorkflowDefVersionActivity",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, workflowDefVersionId, uuId } = values;
      const response = await server.get(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createWorkflowDefVersionActivity = createAsyncThunk(
  "workflowDefVersionActivity/createWorkflowDefVersionActivity",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, workflowDefVersionId, formData } = values;
      const response = await server.post(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateWorkflowDefVersionActivity = createAsyncThunk(
  "workflowDefVersionActivity/updateWorkflowDefVersionActivity",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, workflowDefVersionId, uuId, formData } = values;
      const response = await server.patch(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteWorkflowDefVersionActivity = createAsyncThunk(
  "workflowDefVersionActivity/deleteWorkflowDefVersionActivity",
  async (values, { rejectWithValue }) => {
    try {
      const { workflowDefId, workflowDefVersionId, uuId } = values;
      const response = await server.delete(
        `/admin/wfdefinitions/${workflowDefId}/versions/${workflowDefVersionId}/activities/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const workflowDefVersionActivitiesSlice = createSlice({
  name: "workflowDefVersionActivity",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getWorkflowDefVersionActivities.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getWorkflowDefVersionActivities",
      };
    },
    [getWorkflowDefVersionActivities.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefVersionActivities",
        message: action.payload.message,
      };
    },
    [getWorkflowDefVersionActivity.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getWorkflowDefVersionActivity",
      };
    },
    [getWorkflowDefVersionActivity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getWorkflowDefVersionActivity",
        message: action.payload.message,
      };
    },
    [createWorkflowDefVersionActivity.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createWorkflowDefVersionActivity",
      };
    },
    [createWorkflowDefVersionActivity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createWorkflowDefVersionActivity",
        message: action.payload.message,
      };
    },
    [updateWorkflowDefVersionActivity.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateWorkflowDefVersionActivity",
      };
    },
    [updateWorkflowDefVersionActivity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateWorkflowDefVersionActivity",
        message: action.payload.message,
      };
    },
    [deleteWorkflowDefVersionActivity.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteWorkflowDefVersionActivity",
      };
    },
    [deleteWorkflowDefVersionActivity.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteWorkflowDefVersionActivity",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  workflowDefVersionActivitiesSlice.actions;

export default workflowDefVersionActivitiesSlice.reducer;
