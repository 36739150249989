import { useTheme } from "@mui/material";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getSourceDefinition } from "../../../store/sourceDefinitionsSlice";
const _ = require("lodash");

export default function SourceVersionCreateForm({ handleSave, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, errors } = useFormContext();

  const [sourceDefinitionLoaded, setSourceDefinitionLoaded] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = sourceDefinitionLoaded
    ? sourceDefDataMap[sourceDefId]
    : null;

  const sourceDefinitionsSliceStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const sourceVersionOptions = sourceDefinitionLoaded
    ? NtaiUtils.getSelectOptions(
        _.orderBy(
          sourceDefData["sourceMins"],
          ["majorVersion", "minorVersion"],
          ["asc", "asc"]
        ),
        "uuId",
        "displayName"
      )
    : [];

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("sourceVersionCreateForm")
      );

      if (
        !_.isEmpty(sanitizedFormData) &&
        sanitizedFormData["creationRemarks"] &&
        sanitizedFormData["creationRemarks"].length > 0
      ) {
        handleSave(sanitizedFormData);
      }
    }
  }

  useEffect(() => {
    if (
      sourceDefinitionsSliceStatus &&
      sourceDefinitionsSliceStatus.method === "getSourceDefinition" &&
      sourceDefinitionsSliceStatus.result === "success"
    ) {
      setSourceDefinitionLoaded(true);
    }
  }, [sourceDefinitionsSliceStatus]);

  useEffect(() => {
    dispatch(getSourceDefinition(sourceDefId));
  }, [sourceDefId]);

  return (
    <NtaiDialogFormPanel
      subheader="You can create new source version from start or using existing source version to clone data from"
      handleSave={onHandleSave}
      handleSaveLabel="Create Version"
      handleCancel={handleCancel}
    >
      <NtaiTextField
        label="Version remarks*"
        name="sourceVersionCreateForm.creationRemarks"
        placeholder="Remarks"
        multiline
        minRows={2}
        maxRows={3}
        rules={{ requried: "Creation remarks is required" }}
      />
      <NtaiSelectField
        label="Select source version to clone data from"
        name="sourceVersionCreateForm.cloneSourceVersionUuId"
        options={sourceVersionOptions}
      />
      <NtaiSwitchField
        label="Create minor version?"
        name="sourceVersionCreateForm.minorVersionFg"
      />
    </NtaiDialogFormPanel>
  );
}
