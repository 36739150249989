import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import fileUploadServer from "app/http/ntaiFileUploadServer";
import _ from "lodash";

export const getSourceInStoreXmlDatasets = createAsyncThunk(
  "sourceInStoreXmlDatasets/getSourceInStoreXmlDatasets",
  async (values) => {
    const { sourceDefId, sourceId, sourceInId, sourceInStoreFileId } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${sourceInStoreFileId}/datasets`
    );
    return response.data;
  }
);

export const createSourceInStoreXmlDataset = createAsyncThunk(
  "sourceInStoreXmlDatasets/createSourceInStoreXmlDataset",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceInId,
        sourceInStoreFileId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${sourceInStoreFileId}/datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceInStoreXmlDataset = createAsyncThunk(
  "sourceInStoreXmlDatasets/updateSourceInStoreXmlDataset",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceInId,
        sourceInStoreFileId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${sourceInStoreFileId}/datasets/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceInStoreXmlDataset = createAsyncThunk(
  "sourceInStoreXmlDatasets/deleteSourceInStoreXmlDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceInId, sourceInStoreFileId, uuId } =
        values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${sourceInId}/files/${sourceInStoreFileId}/datasets/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceInStoreXmlDatasetsSlice = createSlice({
  name: "sourceInStoreXmlDatasets",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceInStoreXmlDatasets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceInStoreXmlDatasets",
      };
    },
    [getSourceInStoreXmlDatasets.rejected]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "error",
        method: "getSourceInStoreXmlDatasets",
        message: action.payload.message,
      };
    },
    [createSourceInStoreXmlDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceInStoreXmlDataset",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceInStoreXmlDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceInStoreXmlDataset",
        message: action.payload.message,
      };
    },
    [updateSourceInStoreXmlDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceInStoreXmlDataset",
      };
    },
    [updateSourceInStoreXmlDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceInStoreXmlDataset",
        message: action.payload.message,
      };
    },

    [deleteSourceInStoreXmlDataset.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceInStoreXmlDataset",
      };
    },
    [deleteSourceInStoreXmlDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceInStoreXmlDataset",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceInStoreXmlDatasetsSlice.actions;

export default sourceInStoreXmlDatasetsSlice.reducer;
