import {
  faDatabase,
  faFilter,
  faRectangleCode,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Button, Tooltip, useTheme } from "@mui/material";
import NtaiPopper from "@ntai/components/poppers/NtaiPopper";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import React, { useState } from "react";
import SearchSourceWidgetFilters from "./widgets/SearchSourceWidgetFilters";
import SearchSourceQueryBuilderFilter from "./querybuilder/SearchSourceQueryBuilderFilter";
import { SearchSourceContext } from "../../SearchSource";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function SearchSourceFiltersWrapper() {
  const theme = useTheme();
  const [openWidgetFiltersDialog, setOpenWidgetFiltersDialog] = useState(false);
  const [openQueryBuilderFilterDialog, setOpenQueryBuilderFilterDialog] =
    useState(false);
  const [widgetFiltersAnchorEl, setWidgetFiltersAnchorEl] = useState(null);
  const [queryBuilderFilterAnchorEl, setQueryBuilderFilterAnchorEl] =
    useState(null);

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const searchSourceTempFiltersDataMap = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.tempFilters
  );

  const searchSourceWidgetTempFiltersDataMap =
    searchSourceTempFiltersDataMap && !_.isEmpty(searchSourceTempFiltersDataMap)
      ? searchSourceTempFiltersDataMap[searchSourceId]
      : {};

  const searchSourceWidgetFilters = _.has(
    searchSourceData,
    "searchSourceWidgetFilters"
  )
    ? _.get(searchSourceData, "searchSourceWidgetFilters")
    : [];

  const searchSourceWidgetIEFilters = _.has(
    searchSourceData,
    "searchSourceWidgetIEs"
  )
    ? _.get(searchSourceData, "searchSourceWidgetIEs")
    : [];

  const searchSourceWidgetTempFilters =
    searchSourceWidgetTempFiltersDataMap &&
    !_.isEmpty(searchSourceWidgetTempFiltersDataMap)
      ? Object.values(searchSourceWidgetTempFiltersDataMap).map(
          (widgetFilter, i) => {
            if (_.isArray(widgetFilter)) return widgetFilter.length;
          }
        )
      : [];

  const queryValue = _.get(
    searchSourceData,
    "searchSourceQueryBuilderFilter.criteria"
  );

  const searchSourceWidgetTempFiltersCount =
    searchSourceWidgetTempFilters.reduce((a, b) => a + b, 0);

  const handleOpenWidgetFiltersDialog = (event) => {
    setOpenWidgetFiltersDialog(true);
    setWidgetFiltersAnchorEl(event.currentTarget);
  };
  const handleWidgetFiltersDialogClose = () => {
    setOpenWidgetFiltersDialog(false);
    setWidgetFiltersAnchorEl(null);
  };

  const clickAwayHandlerWidgetFilters = () => {
    setOpenWidgetFiltersDialog(false);
  };

  const handleOpenQueryBuilderFilterDialog = (event) => {
    setOpenQueryBuilderFilterDialog(true);
    setQueryBuilderFilterAnchorEl(event.currentTarget);
  };
  const handleQueryBuilderFilterDialogClose = () => {
    setOpenQueryBuilderFilterDialog(false);
    setQueryBuilderFilterAnchorEl(null);
  };

  const clickAwayHandlerQueryBuilderFilter = () => {
    setOpenWidgetFiltersDialog(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <NtaiMuiBadge
          badgeContent={
            queryValue &&
            _.has(queryValue, "children1") &&
            _.isArray(queryValue["children1"]) &&
            queryValue["children1"].length > 0
              ? queryValue["children1"].length
              : 0
          }
          color="secondary"
          top={10}
          right={4}
        >
          <Tooltip title="Query Builder">
            <Button
              size="small"
              sx={{ fontSize: "10px", fontWeight: 500 }}
              color="inherit"
              startIcon={
                <Badge
                  // badgeContent="3"
                  color="secondary"
                  // variant="dot"
                  sx={{ zIndex: 0 }}
                >
                  <FontAwesomeIcon size="xs" icon={faDatabase} />
                </Badge>
              }
              onClick={(e) => handleOpenQueryBuilderFilterDialog(e)}
            >
              QUERY
            </Button>
          </Tooltip>
        </NtaiMuiBadge>

        <NtaiMuiBadge
          badgeContent={
            (_.isArray(searchSourceWidgetFilters) &&
              searchSourceWidgetFilters.length > 0) ||
            (_.isArray(searchSourceWidgetIEFilters) &&
              searchSourceWidgetIEFilters.length > 0) ||
            (searchSourceWidgetTempFiltersCount &&
              searchSourceWidgetTempFiltersCount > 0)
              ? searchSourceWidgetFilters.length +
                searchSourceWidgetIEFilters.length +
                searchSourceWidgetTempFiltersCount
              : 0
          }
          color="secondary"
          top={10}
          right={2}
        >
          <Tooltip title="Widget Filters">
            <Button
              sx={{
                fontSize: "10px",
                fontWeight: 500,
                gap: theme.spacing(0.5),
              }}
              size="small"
              color="inherit"
              onClick={(e) => handleOpenWidgetFiltersDialog(e)}
            >
              <FontAwesomeIcon size="xl" icon={faFilter} />
              FILTERS
            </Button>
          </Tooltip>
        </NtaiMuiBadge>
      </Box>

      {openQueryBuilderFilterDialog && (
        <NtaiPopover
          left={260}
          open={openQueryBuilderFilterDialog}
          anchorEl={queryBuilderFilterAnchorEl}
          handleClose={handleQueryBuilderFilterDialogClose}
          clickAwayHandler={clickAwayHandlerQueryBuilderFilter}
        >
          <SearchSourceQueryBuilderFilter
            handleClose={handleQueryBuilderFilterDialogClose}
          />
        </NtaiPopover>
      )}

      {openWidgetFiltersDialog && (
        <NtaiPopover
          left={14}
          open={openWidgetFiltersDialog}
          anchorEl={widgetFiltersAnchorEl}
          handleClose={handleWidgetFiltersDialogClose}
          clickAwayHandler={clickAwayHandlerWidgetFilters}
          anchorOriginH="left"
          anchorOriginV="bottom"
          transformOriginH="left"
          transformOriginV="top"
        >
          <SearchSourceWidgetFilters
            handleClose={handleWidgetFiltersDialogClose}
          />
        </NtaiPopover>
      )}
    </>
  );
}
