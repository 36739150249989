import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getObjects } from "app/main/pages/core/cdo/object/store/objectsSlice";
import NtaiCompactCard from "@ntai/components/cards/NtaiCompactCard";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import Scrollbar from "@ntai/components/Scrollbar";
import { useSnackbar } from "notistack";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { SearchSourceContext } from "../../SearchSource";
import {
  submitSearchSourceRecordsBatchAssessment,
  clearStatus,
} from "./store/searchSourceRecordsBatchAssessmentsSlice";
import SearchSourceRecordsBatchAssessment from "./SearchSourceRecordsBatchAssessment";
import { SearchContext } from "../../../SearchEdit";
import { SearchSourceRecordListContext } from "../SearchSourceRecordListPanel";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
const _ = require("lodash");

export default function SearchSourceRecordsBatchAssessmentSelectForm({
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [adjObjectsLoaded, setAdjObjectsLoaded] = useState(false);
  const [selectedAdjObjectUuId, setSelectedAdjObjUuId] = useState();
  const [adjFormDialogOpen, setAdjFormDialogOpen] = useState(false);
  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { selectedRecords, setSelectedRecords } = React.useContext(
    SearchSourceRecordListContext
  );

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const sourceAdjudications =
    searchSourceData["searchSourceDefinition"]["sourceAdjudications"];

  const objectsDataMap = useSelector((state) => state.core.objectsSlice.data);
  const status = useSelector(
    (state) => state.search.searchSourceRecordsBatchAssessmentsSlice.status
  );

  function handleSelect(id) {
    setSelectedAdjObjUuId(id);
    handleAdjFormDialogOpen();
  }

  function handleAdjFormDialogOpen() {
    setAdjFormDialogOpen(true);
  }

  function handleAdjFormDialogClose() {
    setAdjFormDialogOpen(false);
  }

  function handleSave(formData) {
    dispatch(
      submitSearchSourceRecordsBatchAssessment({
        searchId: searchId,
        searchSourceId: searchSourceId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    const objectKeys = Object.keys(objectsDataMap);
    if (
      _.difference(
        _.map(sourceAdjudications, (o) => o.adjObjectUuId),
        objectKeys
      ).length === 0
    ) {
      setAdjObjectsLoaded(true);
    }
  }, [objectsDataMap]);

  useEffect(() => {
    if (
      status &&
      status.method === "submitSearchSourceRecordsBatchAssessment" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(
        `Batch assessment request placed for ${
          _.isArray(selectedRecords) && selectedRecords.length === 0
            ? "All"
            : selectedRecords.length
        } records`,
        {
          variant: "success",
        }
      );

      handleAdjFormDialogClose();
      handleCancel();
      setSelectedRecords([]);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getObjects({
        domainId: "*",
        params: {
          categoryCode: 3,
        },
      })
    );
  }, []);

  return (
    <React.Fragment>
      <NtaiDialogFormPanel
        padding="0px"
        width="100%"
        subheader="Batch update allows you to make changes to multiple items simultaneously using the selected form. Select the form to proceed with batch update."
        handleCancel={handleCancel}
      >
        <Box sx={{ display: "flex", height: "220px" }}>
          <Scrollbar>
            {adjObjectsLoaded &&
              sourceAdjudications &&
              _.isArray(sourceAdjudications) &&
              sourceAdjudications.length > 0 && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: theme.spacing(2),
                  }}
                >
                  {sourceAdjudications.map((o, i) => {
                    const adjForm =
                      objectsDataMap[o["adjObjectUuId"]]["objectForm"];
                    if (adjForm && !_.isEmpty(adjForm)) {
                      return (
                        <Box
                          key={`search-source-record-batch-assessment-form-${i}`}
                        >
                          <NtaiCompactCard
                            id={o["adjObjectUuId"]}
                            title={adjForm["label"].toUpperCase()}
                            description={adjForm["helpText"]}
                            handleClick={handleSelect}
                          >
                            {adjForm["helpText"] || ""}
                          </NtaiCompactCard>
                        </Box>
                      );
                    }
                  })}
                </Box>
              )}
          </Scrollbar>
        </Box>
      </NtaiDialogFormPanel>
      {selectedAdjObjectUuId && selectedAdjObjectUuId.length > 0 && (
        <NtaiDialog
          title={`Batch Assessment - ${objectsDataMap[selectedAdjObjectUuId]["objectForm"]["label"]}`}
          open={adjFormDialogOpen}
          handleDialogClose={handleAdjFormDialogClose}
          size="md"
        >
          <NtaiForm>
            <SearchSourceRecordsBatchAssessment
              adjObject={objectsDataMap[selectedAdjObjectUuId]}
              handleSave={handleSave}
              handleCancel={handleAdjFormDialogClose}
            />
          </NtaiForm>
        </NtaiDialog>
      )}
    </React.Fragment>
  );
}
