import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";

import {
  getSourceRecordTagColorRules,
  createSourceRecordTagColorRule,
  updateSourceRecordTagColorRule,
  deleteSourceRecordTagColorRule,
  clearStatus,
} from "./store/sourceRecordTagColorRulesSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourceRecordTagColorRuleForm from "./SourceRecordTagColorRuleForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiColorBox from "@ntai/components/color-picker/NtaiColorBox";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: ["hexFgCode", "hexBgCode"],
    numeric: false,
    disablePadding: false,
    label: "Color",
    transformFunc: function (o) {
      return (
        <NtaiColorBox
          fg={_.get(o[0], "colValue")}
          bg={_.get(o[1], "colValue")}
        />
      );
    },
    width: "10%",
  },

  {
    id: "exactValue",
    numeric: false,
    disablePadding: false,
    label: "Value",
    width: "40%",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function SourceRecordTagColorRuleList() {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceRecordTagId = useSelector(
    (state) => state.sources.sourceRecordTagsSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTagColorRulesSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceRecordTagColorRulesSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceRecordTagId: sourceRecordTagId,
  };

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteSourceRecordTagColorRule({ ...pathVariables, uuId: id })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceRecordTagColorRule({
            ...pathVariables,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceRecordTagColorRule({
            ...pathVariables,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "remove") confirmDelete();
  }

  useEffect(() => {
    dispatch(getSourceRecordTagColorRules(pathVariables));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceRecordTagColorRule" ||
        status.method === "updateSourceRecordTagColorRule")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceRecordTagColorRule"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <NtaiCrudTable
        rows={data}
        headCells={headCells}
        toolbarActions={toolbarActions}
        keyColumn="uuId"
        dialog={true}
        handleDialogAddEdit={handleDialogAddEdit}
        handleToolbarAction={handleToolbarAction}
        title="Color Rule"
      />

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Source Record Tag Color Rule"
        size="sm"
      >
        <NtaiForm>
          <SourceRecordTagColorRuleForm
            action={action}
            handleCancel={handleDialogClose}
            handleSave={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "uuId")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
