import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModelForm from "./ModelForm";
import { createModel, clearStatus } from "./store/modelsSlice";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ModelCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.models.modelsSlice.data);
  const status = useSelector((state) => state.models.modelsSlice.status);
  const activeId = useSelector((state) => state.models.modelsSlice.activeId);

  function handleSave(formData) {
    dispatch(createModel(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "createModel"
    ) {
      enqueueSnackbar(
        `Model "${_.get(dataMap[activeId], "name")}" created successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());

      history.push(`/models/models/${activeId}/edit`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Models / Add Model">
      <NtaiForm>
        <ModelForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
