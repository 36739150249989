import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import history from "@ntai/@history";
import { useDispatch, useSelector } from "react-redux";
import { createSearch } from "../../store/searchSlice";
import SearchInitSourcesWrapper from "../sources/SearchInitSourcesWrapper";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { initializeSearch, clearStatus } from "../store/searchInitSlice";
import SearchAdvInitSuggest from "./SearchAdvInitSuggest";
import withUserPermission from "@ntai/components/rbac/withUserPermission";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export const SearchAdvInitContext = React.createContext();

const getCriteria = (queryTerms) => {};

export default function SearchAdvInit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedSource, setSelectedSource] = useState();
  const [selectedType, setSelectedType] = useState();
  const [sourcesQueryText, setSourcesQueryText] = useState("");
  const [loaded, setLoaded] = useState(false);

  const data = useSelector((state) => state.search.searchInitSlice.data);
  const status = useSelector((state) => state.search.searchInitSlice.status);
  const statusSearch = useSelector((state) => state.search.searchSlice.status);
  const activeId = useSelector((state) => state.search.searchSlice.activeId);

  function handleSearch() {
    let searchSources = [];
    let searchDatasets = [];
    if (selectedSource && selectedSource.length > 0) {
      if (selectedSource && !_.isEmpty(selectedSource)) {
        if (selectedType === 2) {
          searchSources.push({
            searchSourceDefinition: { sourceUuId: selectedSource },
          });
        } else {
          searchDatasets.push(selectedSource);
        }
      }

      dispatch(
        createSearch({
          typeCode: selectedType,
          searchSources: searchSources,
          sourceDomainUuIds: searchDatasets,
        })
      );
    }
  }

  useEffect(() => {
    if (
      statusSearch &&
      statusSearch.result === "success" &&
      statusSearch.method === "createSearch"
    ) {
      history.push(`/search/${activeId}/edit`);
    }
  }, [statusSearch]);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "initializeSearch"
    ) {
      dispatch(clearStatus());
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(initializeSearch({ type: "2" }));
  }, []);

  return (
    <SearchAdvInitContext.Provider
      value={{
        sourcesQueryText,
        setSourcesQueryText,
        selectedSource,
        setSelectedSource,
        selectedType,
        setSelectedType,
      }}
    >
      {loaded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(2),
              flexBasis: "5%",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
              background: grey[50],
            }}
          >
            <NtaiButton
              onClick={() => history.push("/search")}
              size="small"
              icon="back"
            >
              Back to Listing
            </NtaiButton>
          </Box>
          <Box
            sx={{
              flexBasis: "30%",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: theme.general.border1,
              background: grey[50],
              pb: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "60%",
                flexDirection: "column",
                justifyContent: "start",
                gap: theme.spacing(1),
              }}
            >
              <Typography variant="h1" fontWeight="300">
                Select source or dataset for analysis
              </Typography>
              <Typography variant="body1" fontWeight="300">
                Search source or dataset and then select one to begin
                exploratory analysis
              </Typography>
              <NtaiForm>
                <SearchAdvInitSuggest handleSearch={handleSearch} />
              </NtaiForm>
            </Box>
          </Box>

          <SearchInitSourcesWrapper mode="adv" />

          {/* <Box sx={{ flexBasis: "10%", borderTop: theme.general.border1 }}>
          <Box
            sx={{ display: "flex", justifyContent: "end", p: theme.spacing(2) }}
          >
            <Button variant="contained" onClick={() => handleSearch()}>
              Begin Search
            </Button>
          </Box>
        </Box> */}
        </Box>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </SearchAdvInitContext.Provider>
  );
}
