import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import DataTable from "react-data-table-component";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      background: grey[50],
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      fontWeight: "700",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderRight: "1px solid #eee",
      verticalAlign: "top",
    },
  },
};

export default function NtaiReactDataTable(props) {
  const theme = useTheme();
  const { columns, data } = props;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        border: theme.general.border1,
      }}
    >
      <DataTable
        fixedHeader={true}
        fixedHeaderScrollHeight="auto"
        customStyles={customStyles}
        columns={columns}
        data={data}
        dense
        pagination
      />
    </Box>
  );
}
