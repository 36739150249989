import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import { getSourceDefinitions } from "app/main/pages/core/sourcedefinition/store/sourceDefinitionsSlice";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";

const _ = require("lodash");

const UI_SCHEDULE_EXEC_OPTIONS = [
  {
    value: 1,
    label: "Pipeline (UI) Only",
  },
  {
    value: 2,
    label: "Pipeline (Scheduler) Only",
  },
  {
    value: 3,
    label: "Pipeline (UI and Scheduler)",
  },
];

export default function AwsClusterTemplateSourceFormForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const sourceDefinitionsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionsStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );
  const sourceDefinitionsData =
    sourceDefinitionsDataMap && !_.isEmpty(sourceDefinitionsDataMap)
      ? Object.values(sourceDefinitionsDataMap)
      : [];

  const sourceDefinitionOptions = NtaiUtils.getSelectOptions(
    _.orderBy(
      _.map(sourceDefinitionsData, function (o) {
        return {
          uuId: o["uuId"],
          name: o["abbrev"] + " [" + o["name"] + "]",
        };
      }),
      ["name"],
      ["asc"]
    ),
    "uuId",
    "name"
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("awsClusterTemplateSourceForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({
      awsClusterTemplateSourceForm: action === "edit" ? formData : {},
    });
  }, []);

  useEffect(() => {
    dispatch(getSourceDefinitions());
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiSelectField
          name="awsClusterTemplateSourceForm.sourceDefUuId"
          label="Source*"
          options={sourceDefinitionOptions}
          rules={{ required: "Source is required" }}
        />
        <NtaiSelectField
          label="Use Cluster for Pipeline"
          name="awsClusterTemplateSourceForm.uiOrScheduleExecCode"
          options={UI_SCHEDULE_EXEC_OPTIONS}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
