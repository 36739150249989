import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  submitDataAdapter,
  updateDataAdapter,
} from "./store/dataAdaptersSlice";
import DataAdapterForm from "./DataAdapterForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { Box, Button, useTheme } from "@mui/material";
import { faPlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DataAdapterEdit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const status = useSelector((state) => state.core.dataAdaptersSlice.status);
  const dataMap = useSelector((state) => state.core.dataAdaptersSlice.data);
  const activeId = useSelector(
    (state) => state.core.dataAdaptersSlice.activeId
  );
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateDataAdapter({ uuId: id, formData: formData }));
  }

  function handleAdapterSubmit(id) {
    dispatch(submitDataAdapter(id));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateDataAdapter" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Data adapater "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      history.push("/adapters");
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back="/adapters"
      headerActions={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          {formData["ioTypeCode"] === 1 && (
            <Button
              sx={{ fontSize: "11px" }}
              size="small"
              startIcon={<FontAwesomeIcon size="2xs" icon={faPlay} />}
              // onClick={() => history.push("/search-basic-init")}
              onClick={() => handleAdapterSubmit(id)}
            >
              RUN DATA ADAPTER
            </Button>
          )}
        </Box>
      }
    >
      <NtaiForm>
        <DataAdapterForm
          activeId={id}
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
