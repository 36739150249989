import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUserNotifications = createAsyncThunk(
  "userNotifications/getUserNotifications",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/notifications`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserNotification = createAsyncThunk(
  "userNotifications/getUserNotification",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/notifications/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNumOfUserNotifications = createAsyncThunk(
  "userNotifications/getNumOfUserNotifications",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.put(`/notifications`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserNotification = createAsyncThunk(
  "userNotifications/createUserNotification",
  async (values, { rejectWithValue }) => {
    try {
      const { formData } = values;
      const response = await server.post(`/notifications`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserNotification = createAsyncThunk(
  "userNotifications/updateUserNotification",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/notifications/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeUserNotification = createAsyncThunk(
  "userNotifications/removeUserNotification",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/notifications/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userNotificationsSlice = createSlice({
  name: "userNotifications",
  initialState: {
    data: {},
    activeId: null,
    status: {},
    count: 0,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserNotifications.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getUserNotifications" };
    },
    [getUserNotifications.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserNotifications",
        message: action.payload.message,
      };
    },

    [getNumOfUserNotifications.fulfilled]: (state, action) => {
      state.count = _.has(action.payload, "value") ? action.payload.value : 0;
      state.status = { result: "success", method: "getNumOfUserNotifications" };
    },
    [getNumOfUserNotifications.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getNumOfUserNotifications",
        message: action.payload.message,
      };
    },

    [getUserNotification.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getUserNotification" };
    },
    [getUserNotification.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserNotification",
        message: action.payload.message,
      };
    },
    [createUserNotification.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload.data,
      };
      state.status = { result: "success", method: "createUserNotification" };
      state.activeId = action.payload.data.uuId;
    },
    [createUserNotification.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserNotification",
        message: action.payload.message,
      };
    },
    [updateUserNotification.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateUserNotification" };
    },
    [updateUserNotification.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUserNotification",
        message: action.payload.message,
      };
    },
    [removeUserNotification.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "removeUserNotification" };
    },
    [removeUserNotification.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "removeUserNotification",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = userNotificationsSlice.actions;

export default userNotificationsSlice.reducer;
