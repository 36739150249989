import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "@ntai/@history";
import { persistor } from "app/store/index";
import { setAppHeader } from "app/store/ntai/navigationSlice";
import { session_timeout } from "app/auth/store/loginSlice";
import { IdleTimerProvider } from "react-idle-timer";
import { getSetting } from "../core/admin/setting/store/settingsSlice";
const _ = require("lodash");

export const SessionTimeoutContext = React.createContext();

export default function SessionTimeoutProvider({ children }) {
  const dispatch = useDispatch();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [sessionTimeoutMins, setSessionTimeoutMins] = useState(5);

  const userToken = localStorage.getItem("accessToken");
  const userInfo = useSelector((state) => state.auth.authSlice.userInfo);
  // const dataMap = useSelector((state) => state.core.settingsSlice.data);
  // const status = useSelector((state) => state.core.settingsSlice.status);

  // console.log("User info in session timeout: ", userInfo);

  function onIdle() {
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    dispatch(session_timeout());
    dispatch(setAppHeader(false));
    history.push("/");
  }

  // useEffect(() => {
  //   if (
  //     status &&
  //     status.method === "getSetting" &&
  //     status.result === "success"
  //   ) {
  //     const sessionTimeoutData = _.filter(Object.values(dataMap), function (o) {
  //       return o["name"] === "sessionTimeoutMins" ? true : false;
  //     });

  //     if (sessionTimeoutData.length > 0) {
  //       console.log(
  //         "Setting session timeout to: ",
  //         sessionTimeoutData[0]["value"]
  //       );
  //       setSessionTimeoutMins(sessionTimeoutData[0]["value"]);
  //     }
  //   }
  // }, [status]);

  // useEffect(() => {
  //   if (userInfo && !_.isEmpty(userInfo))
  //     dispatch(getSetting("sessionTimeoutMins"));
  // }, [userInfo]);

  // useEffect(() => {
  //   if (redirectToLogin) {
  //     dispatch(logout());
  //     persistor.pause();
  //     persistor.flush().then(() => {
  //       return persistor.purge();
  //     });
  //     dispatch(setAppHeader(false));
  //     history.push("/");
  //   }
  // }, [redirectToLogin]);

  // useEffect(() => {
  //   if (!userToken) {
  //     setRedirectToLogin(true);
  //   }
  // }, [userToken]);

  // 5 minutes inactivity = session time out

  return (
    <SessionTimeoutContext.Provider value={{}}>
      <IdleTimerProvider
        timeout={1000 * 60 * sessionTimeoutMins}
        onIdle={onIdle}
      >
        {children}
      </IdleTimerProvider>
    </SessionTimeoutContext.Provider>
  );
}
