import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import history from "@ntai/@history";
import { getCodes } from "../admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import DataAdapterGeneralForm from "./general/DataAdapterGeneralForm";
import DataAdapterPythonFiles from "./python-files/DataAdapterPythonFiles";
const _ = require("lodash");

export default function DataAdapterForm({
  action,
  activeId,
  formData,
  handleSave,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();

  function handleCancel() {
    history.push(`/adapters`);
  }

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FILE_TYPE_CODE));
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
          height: "100%",
        }}
        label="GENERAL"
      >
        <DataAdapterGeneralForm
          action={action}
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </Box>

      <Box disabled={action === "add" ? true : false} label={"PYTHON PACKAGE"}>
        <DataAdapterPythonFiles
          action={action}
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </Box>
      <Box
        disabled={action === "add" ? true : false}
        label="REQUIREMENTS"
      ></Box>
    </NtaiTabs>
  );
}
