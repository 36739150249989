import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Box, lighten, useTheme } from "@mui/material";
import { tooltipConfig } from "./chartConfigs";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

function generateColors(color) {
  let colors = [];

  for (let i = 0; i < 100; i++) {
    const colRand = NtaiUtils.getRandomFloat(0.2, 0.6);
    colors.push(am4core.color(color).lighten(colRand));
  }

  return colors;
}

function getTreeValues(ev, parent, level, result) {
  if (level === "root") {
    result.push(ev.target.dataItem.dataContext.properties.name);

    if (
      typeof ev.target.dataItem.dataContext.parent === "object" &&
      typeof ev.target.dataItem.dataContext.parent.component === "object"
    ) {
      getTreeValues(
        null,
        ev.target.dataItem.dataContext.parent,
        "parent",
        result
      );
    }
  } else {
    result.push(parent.dataContext.name);
    if (typeof parent.parent.component === "object")
      getTreeValues(null, parent.parent, "parent", result);
  }

  return result;
}

export default function NtaiTreemapChart(props) {
  const theme = useTheme();
  const { widgetId, data, height, width, metadata, handleClick } = props;

  const levels = Object.keys(metadata["fields"]).length;
  console.log("Treedata: ", data);
  // console.log("Treedata metadata: ", metadata);

  useLayoutEffect(() => {
    // let container = am4core.create("container", am4core.Container);
    // container.width = am4core.percent(100);
    // container.height = am4core.percent(100);

    let chart = am4core.create(
      "treemap".concat("-", widgetId),
      am4charts.TreeMap
    );

    chart.data = data;

    // let chart = container.createChild(am4charts.TreeMap);

    // rect.fill = am4core.color("red");

    // chart.parent = container;

    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    chart.responsive.enabled = true;

    // only one level visible initially
    chart.maxLevels = 1;
    // define data fields
    chart.dataFields.value = "value";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";
    // chart.homeText = "MedDRA Levels";

    chart.colors.list = generateColors(
      lighten(theme.colors.chart.lighter, 0.3)
    );

    chart.colors.step = 1;

    // tool tip
    chart.tooltip.getFillFromObject = false;
    chart.tooltip.autoTextColor = false;
    chart.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    chart.tooltip.background.fill = am4core.color(tooltipConfig.backgroundFill);
    chart.tooltip.background.fillOpacity = tooltipConfig.backgroundFillOpacity;
    chart.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    chart.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    // enable navigation
    chart.navigationBar = new am4charts.NavigationBar();

    // zoom icon
    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#ffffff");
    chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill =
      am4core.color("#eeeeee");

    chart.zoomOutButton.icon.disabled = true;
    let zoomImage = chart.zoomOutButton.createChild(am4core.Image);
    zoomImage.href = "/images/zoom-icon.png";
    zoomImage.width = 15;
    zoomImage.height = 15;
    zoomImage.interactionsEnabled = false;

    for (let level = 0; level < levels; level++) {
      const lastLevel = (levels - 1).toString();

      let levelSeriesTemplate = chart.seriesTemplates.create(level.toString());

      if (level === 0) {
        levelSeriesTemplate.strokeWidth = 1;
        levelSeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
        levelSeriesTemplate.bulletsContainer.hiddenState.properties.visible = true;
        let columnTemplate = levelSeriesTemplate.columns.template;
        let hoverState = columnTemplate.states.create("hover");

        // darken
        hoverState.adapter.add("fill", function (fill, target) {
          if (fill instanceof am4core.Color) {
            return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
          }
          return fill;
        });
      } else {
        // levelSeriesTemplate.columns.template.fillOpacity = 0;
      }

      if (level === levels - 1) {
        // levelSeriesTemplate.columns.template.column.fill = "red";

        levelSeriesTemplate.columns.template.events.on("hit", function (ev) {
          const treeValues = _.reverse(getTreeValues(ev, null, "root", []));
          let sourceWidgetFilterFields = [];
          _.orderBy(metadata["fields"], ["fieldOrder"], ["asc"]).forEach(
            (f, i) => {
              sourceWidgetFilterFields.push({
                sourceFieldUuId: f.field.uuId,
                fieldOperatorCode: 1,
                fieldValue: treeValues[i],
              });
            }
          );

          handleClick(sourceWidgetFilterFields, ev);
        });
      }

      let bullet = levelSeriesTemplate.bullets.push(
        new am4charts.LabelBullet()
      );
      bullet.locationX = 0.5;
      bullet.locationY = 0.5;
      bullet.align = "center";
      bullet.label.text = "{name}\n{value}";
      bullet.label.fill = am4core.color("#000");
      bullet.fontSize = 10;
    }

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"treemap".concat("-", widgetId)}
      sx={{
        // width: { width },
        width: "100%",
        height: height,
      }}
    ></Box>
  );
}
