import { Backdrop, Box, Button, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertEditContext } from "../../../../AlertEdit";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  createAlertRecordAdjudication,
  getAlertRecordAdjudicationsByAdjObjectUuId,
  setActiveId,
  updateAlertRecordAdjudication,
} from "./store/alertRecordAdjudicationsSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  getAlertRecord,
  getAlertRecords,
} from "../../../store/alertRecordsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { faEdit, faPlus, faSave } from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

export default function AlertRecordAdjudication({
  adjObjectUuId,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);
  const {
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext();
  const {
    selectedRecordId,
    activityId,
    refreshAllRecords,
    setRefreshAllRecords,
  } = useContext(AlertEditContext);

  const [loaded, setLoaded] = useState(false);
  const alertId = useSelector((state) => state.monitor.alertsSlice.activeId);
  const alertDataMap = useSelector((state) => state.monitor.alertsSlice.data);
  const alertData = _.get(alertDataMap, alertId);

  const alertMasterId = _.get(alertData, "alertMasterUuId");

  const dispatchParams = {
    alertId: alertId,
    alertMasterId: alertMasterId,
    alertRecordId: selectedRecordId,
  };

  const objectDataMap = useSelector((state) => state.core.objectsSlice.data);

  const adjObject = _.get(objectDataMap, adjObjectUuId);

  console.log("adjObject: ", adjObject);

  const alertRecordAdjDataMap = useSelector(
    (state) => state.monitor.alertRecordAdjudicationsSlice.data
  );

  const alertRecordAdjDataArr =
    alertRecordAdjDataMap && !_.isEmpty(alertRecordAdjDataMap)
      ? _.filter(Object.values(alertRecordAdjDataMap), function (o) {
          if (o["adjudication"]["clientData"]["objectUuId"] === adjObjectUuId)
            return true;
          else return false;
        })
      : [];

  const alertRecordAdjData =
    alertRecordAdjDataArr.length > 0 ? alertRecordAdjDataArr[0] : null;

  const alertRecordAdjId = alertRecordAdjData
    ? alertRecordAdjData["uuId"]
    : null;

  const status = useSelector(
    (state) => state.monitor.alertRecordAdjudicationsSlice.status
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(getValues("adjForm"));
    if (sanitizedFormData) {
      const revisedFormData = ObjectFormUtil.generateFormSubmissionData(
        sanitizedFormData,
        adjObject
      );

      const finalFormData = {
        adjVersion: 1,
        approvedFg: "Y",
        adjudication: {
          moduleCode: 2,
          clientData: {
            uuId: _.get(alertRecordAdjData, "adjudication.clientData.uuId"),
            objectUuId: _.get(adjObject, "uuId"),
            ...revisedFormData,
          },
        },
      };

      if (alertRecordAdjId) {
        dispatch(
          updateAlertRecordAdjudication({
            ...dispatchParams,
            uuId: alertRecordAdjId,
            formData: finalFormData,
          })
        );
      } else {
        dispatch(
          createAlertRecordAdjudication({
            ...dispatchParams,
            formData: finalFormData,
          })
        );
      }
    }
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getAlertRecordAdjudicationsByAdjObjectUuId"
    ) {
      if (alertRecordAdjData) {
        reset({
          adjForm: ObjectFormUtil.populateFormData(
            _.get(alertRecordAdjData, "adjudication.clientData")
          ),
        });
        dispatch(setActiveId(_.get(alertRecordAdjData, "uuId")));
      } else {
        reset({ adjForm: {} });
        dispatch(setActiveId(null));
      }
      setLoaded(true);
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createAlertRecordAdjudication" ||
        status.method === "updateAlertRecordAdjudication")
    ) {
      reset({
        adjForm: ObjectFormUtil.populateFormData(
          _.get(alertRecordAdjData, "adjudication.clientData")
        ),
      });
      setEditMode(false);
      setLoaded(true);
      setRefreshAllRecords(refreshAllRecords + 1);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getAlertRecordAdjudicationsByAdjObjectUuId({
        ...dispatchParams,
        formData: {
          value: adjObjectUuId,
        },
      })
    );
  }, [selectedRecordId, adjObjectUuId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "1px",
          width: "100%",
          justifyContent: "end",
          alignItems: "center",
          paddingRight: theme.spacing(2),
          gap: theme.spacing(1),
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: `calc(100% - 3px)`,
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {adjObject && !_.isEmpty(adjObject["objectForm"]) && loaded && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {(alertRecordAdjId || editMode) && (
              <Scrollbar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: theme.spacing(2),
                    p: theme.spacing(2),
                  }}
                >
                  {ObjectFormUtil.generateForm(
                    "adjForm",
                    adjObject["objectForm"],
                    editMode,
                    theme
                  )}
                </Box>
              </Scrollbar>
            )}

            {!alertRecordAdjId && !editMode && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <NtaiEmptyMessage
                  vAlign="center"
                  header="Assessment data not available"
                  subheader="Click on + CREATE button to add a new assessment"
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                height: "58px",
                width: "100%",
                alignItems: "center",
                paddingRight: theme.spacing(2),
                gap: theme.spacing(1),
                justifyContent: "end",
                borderTop: theme.general.border1,
              }}
            >
              {editMode && (
                <Button size="small" onClick={() => setEditMode(false)}>
                  CANCEL
                </Button>
              )}

              {!editMode && (
                <Button size="small" onClick={handleCancel}>
                  CANCEL
                </Button>
              )}

              <Button
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                disabled={
                  !loaded || (status && status.result === "pending")
                    ? true
                    : false
                }
                variant={
                  status && status.result === "pending"
                    ? "outlined"
                    : "contained"
                }
                size="small"
                onClick={() => (editMode ? handleSave() : setEditMode(true))}
              >
                {status && status.result === "pending" ? (
                  <NtaiCircularProgress size={16} />
                ) : (
                  <FontAwesomeIcon
                    icon={
                      editMode ? faSave : alertRecordAdjId ? faEdit : faPlus
                    }
                  />
                )}
                {editMode ? "SAVE" : alertRecordAdjId ? "EDIT" : "CREATE"}
              </Button>
            </Box>
          </Box>
        )}
        {!loaded && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NtaiCircularProgress size={24} />
          </Box>
        )}

        {(!adjObject || !adjObject["objectForm"]) && (
          <Box
            sx={{
              display: "flex",

              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NtaiEmptyMessage
              header="Assessment form not available"
              subheader="No assessment form has been configured for the selected source"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
