import { combineReducers } from "@reduxjs/toolkit";
import datasetsSlice from "../dataset/store/datasetsSlice";
import modelsSlice from "../models/store/modelsSlice";
import functionsSlice from "../function/store/functionsSlice";
import functionAwsSessionsSlice from "../function/session/store/functionAwsSessionsSlice";
import returnTypesSlice from "../returntype/store/returnTypesSlice";
import packagesSlice from "../package/store/packagesSlice";
import pythonFilesSlice from "../pythonfile/store/pythonFilesSlice";

const modelsReducer = combineReducers({
  datasetsSlice,
  modelsSlice,
  functionsSlice,
  functionAwsSessionsSlice,
  returnTypesSlice,
  packagesSlice,
  pythonFilesSlice,
});

export default modelsReducer;
