import React from "react";
import { SourceOutJobMigrationContext } from "../SourceOutJobMigration";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    label: "File",
    fontWeight: 700,
  },

  {
    id: "fileSize",
    numeric: false,
    disablePadding: false,
    label: "Size",
    transformFunc: NtaiUtils.bytesToSize,
  },

  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

export default function SourceMigrationOutJobCopyFilesStep() {
  const theme = useTheme();
  const { migrationData } = React.useContext(SourceOutJobMigrationContext);

  return (
    <React.Fragment>
      {migrationData["migrationInStoreCopyBatches"].map((o, i) => (
        <Box
          key={`ntai-migration-copy-files-batch-${i}`}
          sx={{
            p: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="h3">{o["sourceInStoreName"]}</Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: theme.spacing(1),
            }}
          >
            <NtaiLabelValue1 label="Status" value={o["statusDecode"]} />
            <NtaiLabelValue1 label="Duration" value={o["duration"]} />
            <NtaiLabelValue1 label="Started" value={o["dateStarted"]} />
            <NtaiLabelValue1 label="Completed" value={o["dateCompleted"]} />
          </Box>
          {_.has(o, "migrationInStoreCopyBatchFiles") &&
            _.isArray(o["migrationInStoreCopyBatchFiles"]) && (
              <NtaiSimpleTable
                rows={o["migrationInStoreCopyBatchFiles"]}
                headCells={headCells}
                keyColumn="uuId"
                isDensed={true}
                dialog={true}
                handleDialogAddEdit={null}
                handleToolbarAction={null}
                title={`Files Copied (${o["migrationInStoreCopyBatchFiles"].length})`}
              />
            )}
        </Box>
      ))}
    </React.Fragment>
  );
}
