import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import history from "@ntai/@history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import NtaiIcons from "utils/NtaiIcons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "44px !important",
    maxHeight: "44px !important",
    marginBottom: theme.spacing(0.2),
    padding: "0px !important",
  },
  highlight: {
    minHeight: "44px !important",
    maxHeight: "44px !important",
    display: "flex",
    itemsAlign: "center",
    marginBottom: theme.spacing(0.2),
    // backgroundColor: theme.colors.alpha.black[10],
  },
  title: {
    flex: "1 1 100%",
  },
  iconButton: {
    paddingRight: "8px",
  },
}));

export default function NtaiCrudTableToolbar(props) {
  const classes = useToolbarStyles();
  const theme = useTheme();
  const {
    numSelected,
    title,
    path,
    dialog,
    onHandleDialogAddEdit,
    actions,
    onHandleToolbarAction,
    otherActions,
    onHandleToolbarOtherAction,
    handleSearch,
  } = props;

  function handleRedirectAdd() {
    history.push(`${path}/create`);
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Box
          sx={{
            display: "flex",
            itemsAlign: "center",
            gap: theme.spacing(1),
          }}
        >
          {actions.map((action, index) => {
            return (
              <Button
                sx={{ fontSize: 11 }}
                key={index}
                size="small"
                variant={action.variant ? action.variant : "outlined"}
                startIcon={
                  <FontAwesomeIcon icon={NtaiIcons[action.icon]} size="sm" />
                }
                onClick={() => onHandleToolbarAction(action.value)}
              >
                {action.label.toUpperCase()}
              </Button>
            );
          })}

          {actions && actions.length > 0 && (
            <Divider flexItem orientaton="vertical" />
          )}
          <Box
            sx={{
              display: "flex",
              paddingLeft: "4px",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Typography variant="subtitle1">Selected</Typography>
            <Chip size="small" color="default" label={numSelected} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "4px",
            itemsAlign: "center",
          }}
        >
          <Button
            size="small"
            sx={{ fontSize: 11 }}
            variant="outlined"
            startIcon={<FontAwesomeIcon size="2xs" icon={faPlus} />}
            onClick={
              dialog === true
                ? (e) => onHandleDialogAddEdit(e, null)
                : (e) => handleRedirectAdd()
            }
          >
            CREATE
          </Button>
          {otherActions &&
            Array.isArray(otherActions) &&
            otherActions.map((a, i) => {
              return (
                <Button
                  key={`toolbar-action-button${i}`}
                  sx={{ fontSize: 11 }}
                  size="small"
                  variant={a.variant ? a.variant : null}
                  startIcon={<FontAwesomeIcon icon={NtaiIcons[a.icon]} />}
                  onClick={() => onHandleToolbarOtherAction(a.value)}
                >
                  {a.label.toUpperCase()}
                </Button>
              );
            })}
        </Box>
      )}

      {numSelected == 0 && (
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2px",
                border: "1px solid #eee",
                borderRadius: "20px",
                paddingLeft: "2px",
                marginLeft: "2px",
              }}
            >
              <InputBase
                sx={{ paddingLeft: "8px", fontSize: "12px" }}
                placeholder="Search..."
                onChange={
                  handleSearch ? (e) => handleSearch(e.target.value) : null
                }
              />

              <IconButton size="small" className={classes.iconButton}>
                <Search sx={{ fontSize: "18px" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Toolbar>
  );
}

NtaiCrudTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
