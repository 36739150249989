import { faHexagon, faHome, faTools } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import Scrollbar from "@ntai/components/Scrollbar";
import {
  setAppHeader,
  setSelectedModule,
} from "app/store/ntai/navigationSlice";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardCustomize from "./customize/DashboardCustomize";
import { clearStatus, getDashboard } from "./store/dashboardSlice";
import DashboardWidget from "./widgets/DashboardWidget";
import DashboardGrid from "./resizable/DashboardGrid";
import { grey, red } from "@mui/material/colors";
import { createOrUpdateDashboardUser } from "./store/dashboardUsersSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiResizableGrid1 from "@ntai/components/resizable/NtaiResizableGrid1";
import { useMeasure } from "react-use";
import { clearWidgetsData } from "./widgets/store/dashboardWidgetsSlice";
const _ = require("lodash");

export default function Dashboard() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [ref, { width, height }] = useMeasure();
  const [loaded, setLoaded] = React.useState(false);
  const [openCustomizeDialog, setOpenCustomizeDialog] = React.useState(false);

  const data = useSelector((state) => state.dashboard.dashboardSlice.data);
  const status = useSelector((state) => state.dashboard.dashboardSlice.status);

  const widgets = _.get(data, "widgets");

  function handleCustomizeDialogOpen() {
    setOpenCustomizeDialog(true);
  }

  function handleCustomizeDialogClose() {
    setOpenCustomizeDialog(false);
  }

  function handleLayoutChange(layout) {
    dispatch(
      createOrUpdateDashboardUser({
        dashboardId: _.get(data, "uuId"),
        formData: {
          layout: layout,
        },
      })
    );
  }

  function generateWidgets() {
    let result = [];

    if (widgets && Array.isArray(widgets)) {
      widgets.forEach((widget, index) => {
        result.push(
          <Box
            key={index}
            sx={{ display: "flex", width: "100%", height: "100%" }}
          >
            <DashboardWidget initData={widget} />
          </Box>
        );
      });
    }

    return result;
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getDashboard" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      setLoaded(true);
      enqueueSnackbar("Dashboard not accessible: " + status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setAppHeader(true));
    dispatch(setSelectedModule(null));
  }, []);

  useEffect(() => {
    // TODO: Get the dashboard and layout for the logged in user
    dispatch(clearWidgetsData());
    dispatch(getDashboard());
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "44px",
            alignItems: "center",
            boxShadow: theme.colors.shadows.cardSm,
          }}
        >
          <Toolbar sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexBasis: "50%",
                  pl: theme.spacing(2),
                  gap: theme.spacing(1),
                }}
              >
                <FontAwesomeIcon icon={faHome} />
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  Home
                </Typography>
              </Box>
              <Box
                sx={{
                  pr: theme.spacing(2),
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  flexBasis: "50%",
                }}
              >
                <Button
                  sx={{ fontSize: "12px", gap: theme.spacing(1) }}
                  size="sm"
                  onClick={() => handleCustomizeDialogOpen()}
                >
                  <FontAwesomeIcon icon={faTools} />
                  CUSTOMIZE
                </Button>
              </Box>
            </Box>
          </Toolbar>
        </Box>
        <Box
          sx={{
            mt: "2px",
            display: "flex",
            width: "100%",
            height: `calc(100% - 44px)`,
            background: grey[100],
          }}
        >
          {loaded && Array.isArray(widgets) && widgets.length > 0 && (
            <Scrollbar>
              <Box
                sx={{
                  width: "100%",
                  p: theme.spacing(1),
                  gap: theme.spacing(1),
                }}
                ref={ref}
              >
                {loaded && (
                  <NtaiResizableGrid1
                    rowHeight={50}
                    cols={12}
                    layout={
                      _.get(data, "layout") ? _.get(data, "layout") : null
                    }
                    items={generateWidgets()}
                    handleLayoutChange={handleLayoutChange}
                    width={width}
                  />

                  // <DashboardGrid
                  //   layout={
                  //     _.get(data, "layout") ? _.get(data, "layout") : null
                  //   }
                  //   items={generateWidgets()}
                  //   handleLayoutChange={handleLayoutChange}
                  // />
                )}
              </Box>
            </Scrollbar>
          )}

          {!loaded && <NtaiCircularProgress />}

          {loaded && Array.isArray(widgets) && widgets.length === 0 && (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: theme.spacing(2),
                }}
              >
                <FontAwesomeIcon
                  icon={faHexagon}
                  size="5x"
                  color={theme.colors.primary.main}
                  spin
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: theme.spacing(1),
                  }}
                >
                  <Typography variant="h1">Welcome to NextTrial AI</Typography>
                  <Typography variant="h6" color={grey[500]}>
                    A cloud-based, AI powered, smart data platform to expedite
                    drug development research
                  </Typography>
                  <NtaiBadge
                    title="NO DASHBOARD CONFIGURED"
                    fg="white"
                    bg="red"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <NtaiDialog
        size="md"
        title="Customize Dashboard"
        open={openCustomizeDialog}
        handleDialogClose={handleCustomizeDialogClose}
      >
        <DashboardCustomize />
      </NtaiDialog>
    </React.Fragment>
  );
}
