import React from "react";
import { SourceOutJobMigrationContext } from "../SourceOutJobMigration";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function SourceMigrationOutJobScdPolicy() {
  const theme = useTheme();
  const { displayStore, migrationData } = React.useContext(
    SourceOutJobMigrationContext
  );

  const migrationStoreData = _.filter(migrationData["migrationOutStores"], {
    uuId: displayStore,
  })[0];

  return (
    <React.Fragment>
      {migrationStoreData["migrationDatasets"].map((o, i) => (
        <React.Fragment key={`ntai-migration-dataset-scd-1-${i}`}>
          <Box
            sx={{
              p: theme.spacing(2),
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h3">{o.sourceDatasetName}</Typography>
            {_.has(o, "migrationOutStoreDatasetScd") &&
              _.isArray(o["migrationOutStoreDatasetScd"]) &&
              o["migrationOutStoreDatasetScd"].map((p, j) => (
                <React.Fragment key={`ntai-migration-dataset-scd-2-${j}`}>
                  <NtaiLabelValue1 label="Status" value={p["statusDecode"]} />
                  <NtaiLabelValue1 label="Message" value={p["message"]} />
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: theme.spacing(1),
                    }}
                  >
                    <NtaiLabelValue1
                      label="# Total Records"
                      value={p["mergedRecordsCount"]}
                    />
                    <NtaiLabelValue1
                      label="Total Records Status"
                      value={p["mergedRecordsStatusDecode"]}
                    />
                    <NtaiLabelValue1
                      label="Total Records Date Started"
                      value={NtaiUtils.formatDateTimeCol(
                        p["mergedRecordsDateStarted"]
                      )}
                    />
                    <NtaiLabelValue1
                      label="Total Records Date Completed"
                      value={NtaiUtils.formatDateTimeCol(
                        p["mergedRecordsDateCompleted"]
                      )}
                    />
                  </Box>
                  <NtaiLabelValue1
                    label="Total Records Message"
                    value={p["mergedRecordsMessage"]}
                  />

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: theme.spacing(1),
                    }}
                  >
                    <NtaiLabelValue1
                      label="# New Records"
                      value={p["newRecordsCount"]}
                    />
                    <NtaiLabelValue1
                      label="New Records Status"
                      value={p["newRecordsStatusDecode"]}
                    />
                    <NtaiLabelValue1
                      label="New Records Date Started"
                      value={NtaiUtils.formatDateTimeCol(
                        p["newRecordsDateStarted"]
                      )}
                    />
                    <NtaiLabelValue1
                      label="New Records Date Completed"
                      value={NtaiUtils.formatDateTimeCol(
                        p["newRecordsDateCompleted"]
                      )}
                    />
                  </Box>

                  <NtaiLabelValue1
                    label="New Records Message"
                    value={p["newRecordsMessage"]}
                  />

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: theme.spacing(1),
                    }}
                  >
                    <NtaiLabelValue1
                      label="# Follow-up Versions"
                      value={p["fuVersionsCount"]}
                    />
                    <NtaiLabelValue1
                      label="Follow-up Versions Status"
                      value={p["fuVersionsStatusDecode"]}
                    />
                    <NtaiLabelValue1
                      label="Follow-up Versions Date Started"
                      value={NtaiUtils.formatDateTimeCol(
                        p["fuVersionsDateStarted"]
                      )}
                    />
                    <NtaiLabelValue1
                      label="Follow-up Versions Date Completed"
                      value={NtaiUtils.formatDateTimeCol(
                        p["fuVersionsDateCompleted"]
                      )}
                    />
                  </Box>

                  <NtaiLabelValue1
                    label="Follow-up Versions Message"
                    value={p["fuVersionsMessage"]}
                  />

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: theme.spacing(1),
                    }}
                  >
                    <NtaiLabelValue1
                      label="# Old Versions"
                      value={p["oldVersionsCount"]}
                    />
                    <NtaiLabelValue1
                      label="Old Versions Status"
                      value={p["oldVersionsStatusDecode"]}
                    />
                    <NtaiLabelValue1
                      label="Old Versions Date Started"
                      value={NtaiUtils.formatDateTimeCol(
                        p["oldVersionsDateStarted"]
                      )}
                    />
                    <NtaiLabelValue1
                      label="Old Versions Date Completed"
                      value={NtaiUtils.formatDateTimeCol(
                        p["oldVersionsDateCompleted"]
                      )}
                    />
                  </Box>

                  <NtaiLabelValue1
                    label="Old Versions Message"
                    value={p["oldVersionsMessage"]}
                  />

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: theme.spacing(1),
                    }}
                  >
                    <NtaiLabelValue1
                      label="# Deleted Records"
                      value={p["deletedRecordsCount"]}
                    />
                    <NtaiLabelValue1
                      label="Deleted Records Status"
                      value={p["deletedRecordsStatusDecode"]}
                    />
                    <NtaiLabelValue1
                      label="Deleted Records Date Started"
                      value={NtaiUtils.formatDateTimeCol(
                        p["deletedRecordsDateStarted"]
                      )}
                    />
                    <NtaiLabelValue1
                      label="Deleted Records Date Completed"
                      value={NtaiUtils.formatDateTimeCol(
                        p["deletedRecordsDateCompleted"]
                      )}
                    />
                  </Box>

                  <NtaiLabelValue1
                    label="Deleted Records Message"
                    value={p["deletedRecordsMessage"]}
                  />
                </React.Fragment>
              ))}

            {(!_.has(o, "migrationOutStoreDatasetScd") ||
              !_.isArray(o["migrationOutStoreDatasetScd"])) && (
              <NtaiEmptyMessage header="SCD Policy not available" />
            )}
          </Box>
          <Divider />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
