import React, { useState } from "react";
import { faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import SearchSourceViewMenuItems from "./SearchSourceViewMenuItems";
import { SearchSourceContext } from "../../SearchSource";
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { faChartUser } from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

export default function SearchSourceViewMenu() {
  const theme = useTheme();
  const [openSourceViewMenu, setOpenSourceViewMenu] = useState(false);
  const [anchorElSourceViewMenu, setAnchorElSourceViewMenu] = useState(null);

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const handleSourceView = (event) => {
    setOpenSourceViewMenu(true);
    setAnchorElSourceViewMenu(event.currentTarget);
  };
  const handleSourcViewMenuClose = () => {
    setOpenSourceViewMenu(false);
    setAnchorElSourceViewMenu(null);
  };

  const clickAwayHandler = () => {
    setOpenSourceViewMenu(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Select View">
          <Button
            sx={{
              fontSize: "11px",
              gap: theme.spacing(1),
            }}
            size="small"
            color="inherit"
            onClick={(e) => handleSourceView(e)}
          >
            {/* <FontAwesomeIcon size="xl" icon={faChartUser} /> */}
            {_.has(searchSourceData, "selectedSourceViewName")
              ? NtaiUtils.trunc(
                  searchSourceData["selectedSourceViewName"],
                  50
                ).toUpperCase()
              : "VIEW NOT FOUND"}
            <FontAwesomeIcon size="xl" icon={faCaretDown} />
          </Button>
        </Tooltip>
      </Box>
      {openSourceViewMenu && (
        <NtaiPopover
          open={openSourceViewMenu}
          anchorEl={anchorElSourceViewMenu}
          handleClose={handleSourcViewMenuClose}
          clickAwayHandler={clickAwayHandler}
        >
          <SearchSourceViewMenuItems handleClose={handleSourcViewMenuClose} />
        </NtaiPopover>
      )}
    </>
  );
}
