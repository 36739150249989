import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import React from "react";
import { useDispatch } from "react-redux";
import { ReportDefinitionSourceFilterContext } from "./ReportDefinitionSourceFilterWrapper";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
const _ = require("lodash");

export default function ReportDefinitionSourceFilterFieldSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    sourceFields,
    availableFields,
    setAvailableFields,
    selectedFields,
    setSelectedFields,
  } = React.useContext(ReportDefinitionSourceFilterContext);

  function handleAdd(sourceFieldUuId) {
    if (!selectedFields.includes(sourceFieldUuId)) {
      setSelectedFields([...selectedFields, sourceFieldUuId]);
      setAvailableFields([
        ..._.filter(availableFields, function (f) {
          return f.uuId !== sourceFieldUuId ? true : false;
        }),
      ]);
    }
  }

  return (
    <Box
      sx={{
        flexBasis: "25%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRight: theme.general.border1,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(2),
          height: "54px",
          display: "flex",
          borderBottom: theme.general.border1,
          alignItems: "center",
        }}
      >
        <Box sx={{ flexBasis: "30%", display: "flex" }}>
          <Typography variant="h6">Add Field</Typography>
        </Box>
        <Box sx={{ flexBasis: "70%", display: "flex" }}>
          <NtaiSimpleSearchBar height="32px" />
        </Box>
      </Box>
      <Box
        sx={{
          height: `calc(100% - 54px)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Scrollbar>
          {availableFields.length > 0 && (
            <List sx={{ p: 0 }}>
              {_.orderBy(availableFields, ["label"], ["asc"]).map((af, i) => (
                <React.Fragment key={`source-field-${i}`}>
                  <ListItem
                    sx={{
                      width: "100%",
                      display: "flex",

                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(2),
                      }}
                    >
                      <Chip
                        size="small"
                        label={
                          _.get(af, "esDataTypeDecode") &&
                          _.get(af, "esDataTypeDecode")
                            .substring(0, 1)
                            .toLowerCase()
                        }
                        fg="grey"
                        bg={grey[100]}
                      />
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            fontWeight="700"
                          >
                            {af.label}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              wordBreak: "break-word",
                            }}
                            variant="subtitle2"
                            color="default"
                          >
                            {af.path}
                          </Typography>
                        }
                      />
                    </Box>

                    <Button size="small" onClick={() => handleAdd(af.uuId)}>
                      ADD
                    </Button>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
        </Scrollbar>
      </Box>
    </Box>
  );
}
