import { faBell, faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import UserNotifications from "app/main/pages/core/user-notification/UserNotifications";
import { getNumOfUserNotifications } from "app/main/pages/core/user-notification/store/userNotificationsSlice";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function NotificationsWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const count = useSelector((state) => state.core.userNotificationsSlice.count);

  const status = useSelector(
    (state) => state.core.userNotificationsSlice.status
  );

  function handleNotificationsPanel() {
    setOpen(!open);
  }
  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    dispatch(getNumOfUserNotifications());
  }, []);

  return (
    <>
      <Tooltip title="Notifications">
        <Badge
          badgeContent={count}
          variant="dot"
          sx={{
            "& .MuiBadge-badge": {
              right: 3,
              top: 6,
              background: lighten(theme.colors.primary.light, 0.5),
              color: theme.colors.primary.main,
            },
          }}
        >
          <IconButton
            sx={{
              width: "32px",
              height: "32px",
              "&:hover": { bgcolor: theme.colors.primary.light },
            }}
            color="inherit"
            onClick={handleNotificationsPanel}
          >
            <FontAwesomeIcon icon={faBell} size="xs" />
          </IconButton>
        </Badge>
      </Tooltip>

      <Drawer
        open={open}
        onClose={handleClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "360px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(2),
              height: "72px",
              display: "flex",
              width: "100%",
              borderBottom: theme.general.border1,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2" color="primary.lighter" fontWeight="300">
              Notifications
            </Typography>
            <IconButton onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>

          <Box
            sx={{
              height: `calc(100% - 72px)`,
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: theme.spacing(2),
            }}
          >
            <Scrollbar>
              <Box sx={{ height: "100%" }}>
                <UserNotifications />
              </Box>
            </Scrollbar>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
