import SchedulerJobs from "./jobs/SchedulerJobs";
import SchedulerList from "./ScheulerList";

const SchedulerConfig = {
  routes: [
    {
      path: "/admin/schedulers",
      exact: true,
      component: SchedulerList,
    },
    {
      path: "/admin/schedulers/:id/edit",
      exact: true,
      component: SchedulerJobs,
    },
  ],
};

export default SchedulerConfig;
