import {
  faDatabase,
  faEdit,
  faFile,
  faTable,
  faTableCells,
  faTableColumns,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import React, { useEffect } from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { getSourcePipelineInStores } from "../../../../in/store/sourcePipelineInStoresSlice";
const _ = require("lodash");

export default function SourceDatasetSidePanelInStores() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setOpenDrawer, setSidePanelMenuParam } = React.useContext(
    SourceDatasetManagerContext
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const sourcePipelineInStoresDataMap = useSelector(
    (state) => state.sources.sourcePipelineInStoresSlice.data
  );

  const sourcePipelineInStoresData =
    sourcePipelineInStoresDataMap && !_.isEmpty(sourcePipelineInStoresDataMap)
      ? Object.values(sourcePipelineInStoresDataMap)
      : [];

  function onHandleClick(pipelineStoreId, itemTypeFg, storeFileOrDatasetId) {
    const inStore = sourcePipelineInStoresDataMap[pipelineStoreId]["inStore"];
    const inStoreName = inStore["name"];

    let itemName = null;

    if (itemTypeFg === 1) {
      itemName = _.filter(inStore["sourceInStoreFiles"], {
        uuId: storeFileOrDatasetId,
      })[0]["fileName"];
    } else if (itemTypeFg === 2) {
      itemName = _.filter(inStore["sourceInStoreDatasets"], {
        uuId: storeFileOrDatasetId,
      })[0]["name"];
    }

    setSidePanelMenuParam({
      menuId: 1,
      label: inStoreName + " - " + itemName,
      sourceInStoreId: inStore["uuId"],
      itemTypeFg: itemTypeFg,
      sourceInStoreItemId: storeFileOrDatasetId,
    });

    setOpenDrawer(true);
  }

  useEffect(() => {
    dispatch(
      getSourcePipelineInStores({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourcePipelineId: sourcePipelineId,
      })
    );
  }, []);

  return (
    <NtaiAccordion
      title="Input Stores"
      titleIcon="fileImportRegular"
      titleSecondary={
        <Chip
          variant="contained"
          size="small"
          label={
            <Typography variant="subtitle2" color="inherit">
              {sourcePipelineInStoresData.length}
            </Typography>
          }
        />
      }
    >
      <Box
        sx={{
          borderTop: theme.general.border1,
          p: theme.spacing(1),
          display: "flex",
          height: "160px",
          width: "100%",
        }}
      >
        <Box
          sx={{ display: "flex", width: "100%", border: theme.general.border1 }}
        >
          {sourcePipelineInStoresData &&
            _.isArray(sourcePipelineInStoresData) &&
            sourcePipelineInStoresData.length > 0 && (
              <Scrollbar autoHide={false}>
                <Box sx={{ pr: theme.spacing(1) }}>
                  <TreeView
                    key={`source-pipeline-in-stores-tree`}
                    aria-label="source in store nav"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                  >
                    {sourcePipelineInStoresData.map((si, i) => (
                      <TreeItem
                        key={`source-pipeline-in-store-${i}`}
                        nodeId={`store-${i}`}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: theme.spacing(1),
                            }}
                          >
                            <FontAwesomeIcon size="sm" icon={faDatabase} />
                            <Typography variant="subtitle1" color="inherit">
                              {si["inStore"]["name"]}
                            </Typography>
                          </Box>
                        }
                      >
                        {_.has(si["inStore"], "sourceInStoreFiles") &&
                          _.isArray(si["inStore"]["sourceInStoreFiles"]) &&
                          si["inStore"]["sourceInStoreFiles"].length > 0 &&
                          si["inStore"]["sourceInStoreFiles"].map((sif, j) => (
                            <TreeItem
                              onClick={() =>
                                onHandleClick(si["uuId"], 1, sif["uuId"])
                              }
                              nodeId={`store-item-${j}`}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: theme.spacing(1),
                                  }}
                                >
                                  <FontAwesomeIcon size="sm" icon={faFile} />
                                  <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                  >
                                    {sif["fileName"]}
                                  </Typography>
                                </Box>
                              }
                            />
                          ))}

                        {_.has(si["inStore"], "sourceInStoreDatasets") &&
                          _.isArray(si["inStore"]["sourceInStoreDatasets"]) &&
                          si["inStore"]["sourceInStoreDatasets"].length > 0 &&
                          si["inStore"]["sourceInStoreDatasets"].map(
                            (sif, j) => (
                              <TreeItem
                                onClick={() =>
                                  onHandleClick(si["uuId"], 2, sif["uuId"])
                                }
                                nodeId={`store-item-${j}`}
                                label={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: theme.spacing(1),
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      size="sm"
                                      icon={faTableColumns}
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      color="inherit"
                                    >
                                      {sif["name"]}
                                    </Typography>
                                  </Box>
                                }
                              />
                            )
                          )}
                      </TreeItem>
                    ))}
                  </TreeView>
                </Box>
              </Scrollbar>
            )}
        </Box>
      </Box>
    </NtaiAccordion>
  );
}
