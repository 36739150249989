import { Button } from "@mui/material";
import React from "react";
import NtaiPage from "./NtaiPage";

export default function TestNtaiPage() {
  return (
    <NtaiPage
      title="Protocol Ammendments"
      titleHelpText="Some description"
      headerActions={
        <Button variant="outlined" size="small">
          Save Me
        </Button>
      }
    >
      Some content
    </NtaiPage>
  );
}
