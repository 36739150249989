import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFunction, clearStatus } from "./store/functionsSlice";
import history from "@ntai/@history";
import FunctionForm from "./FunctionForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function FunctionCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.models.functionsSlice.data);
  const status = useSelector((state) => state.models.functionsSlice.status);
  const activeId = useSelector((state) => state.models.functionsSlice.activeId);

  function handleSave(formData) {
    dispatch(createFunction(formData));
  }
  function handleCancel() {
    history.push("/models/functions");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createFunction" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Function "${_.get(dataMap[activeId], "name")}" created successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
      history.push(`/models/functions/${activeId}/edit`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Functions / Add Function">
      <NtaiForm>
        <FunctionForm
          action="add"
          formData={{}}
          handleCancel={handleCancel}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
