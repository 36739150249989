import React, { useEffect, useState } from "react";
import { Box, Drawer, useTheme } from "@mui/material";
import NtaiAutoSuggestTF from "@ntai/components/inputs/NtaiAutoSuggestTF";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getUsers } from "app/main/pages/core/admin/user/store/usersSlice";
const _ = require("lodash");

export default function SearchActionShareForm({ handleSave, handleCancel }) {
  const theme = useTheme();
  const [userUuIds, setUserUuIds] = useState([]);

  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();

  const usersDataMap = useSelector((state) => state.core.usersSlice.data);

  const options = Object.values(usersDataMap).map((user) =>
    _.pick(user, ["uuId", "userName"])
  );

  function handleInputChange(t1) {}

  function handleChange(t2) {
    setUserUuIds([..._.map(t2, "uuId")]);
  }

  function onHandleSave() {
    // console.log("share form details: ", getValues("searchShareForm"));
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchShareForm")
    );

    handleSave({ ...sanitizedFormData, userUuIds: [...userUuIds] });
  }

  function handleOtherAction(otherAction) {}

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <>
      <NtaiDialogFormPanel
        handleSave={onHandleSave}
        handleCancel={handleCancel}
        handleSaveLabel="Share"
        otherActions={[{ label: "Shared List", value: "shared-list" }]}
        handleOtherAction={handleOtherAction}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              justifyContent: "space-between",
            }}
          >
            <NtaiAutoSuggestTF
              options={options}
              name="searchShareForm.userNames"
              label="Select Users or Groups"
              optionIdField="uuId"
              optionLabelField="userName"
              handleInputChange={handleInputChange}
              handleChange={handleChange}
            />
            <NtaiCheckboxField
              name="searchShareForm.fullAccessFg"
              label="Full Access"
            />
          </Box>

          <NtaiCheckboxField
            name="searchShareForm.notificationFg"
            label="Notify users"
          />
          <NtaiTextField
            multiline
            minRows={3}
            maxRows={3}
            name="searchShareForm.remarks"
            label="Message"
          />
        </Box>
      </NtaiDialogFormPanel>
    </>
  );
}
