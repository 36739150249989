import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function NtaiTitleItems({ title, items, itemLabel }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        pt: theme.spacing(1),
      }}
    >
      <Typography variant="body1" fontWeight="700">
        {title}
      </Typography>
      {items &&
        Array.isArray(items) &&
        items.length > 0 &&
        items.map((item, index) => {
          return (
            <Typography key={`title-items-${index}`} variant="body2">
              {itemLabel ? item[itemLabel] : item}
            </Typography>
          );
        })}
      {items && Array.isArray(items) && items.length === 0 && (
        <Typography variant="body2">No records found</Typography>
      )}
    </Box>
  );
}
