import {
  faCircleEllipsisVertical,
  faEllipsisV,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Archive, MoreVert, Settings } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import NtaiIcons from "utils/NtaiIcons";
import NtaiMenu from "../menus/NtaiMenu";

export default function NtaiActionsPanel(props) {
  const {
    id,
    avatar,
    title,
    subheader,
    headerActions,
    handleHeaderAction,
    children,
    actions,
    handleAction,
    width,
    height,
  } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleMenuItemSelect(menuAction) {
    handleHeaderAction(menuAction, id);
  }

  return (
    <>
      <Card
        elevation={0}
        sx={{
          paddingBottom: "4px",
          width: width ? width : null,
          height: height ? height : null,
          border: `1px solid ${theme.colors.alpha.black[20]}`,
        }}
      >
        {title && (
          <CardHeader
            title={title}
            avatar={avatar ? avatar : null}
            sx={{ paddingBottom: "4px" }}
            subheader={subheader}
            action={
              headerActions && (
                <IconButton onClick={handleMenuClick} size="small">
                  <MoreVert />
                </IconButton>
              )
            }
          />
        )}
        <CardContent>{children}</CardContent>
        {actions && (
          <CardActions
            component="button"
            sx={{
              display: "flex",
              borderTop: `1px solid ${theme.colors.alpha.black[20]}`,
            }}
          >
            {actions.map((action, index) => {
              return (
                <Button
                  key={index}
                  startIcon={
                    action.icon && (
                      <FontAwesomeIcon icon={NtaiIcons[action.icon]} />
                    )
                  }
                  onClick={() => handleAction(action.value, id)}
                  size="small"
                >
                  {action.label}
                </Button>
              );
            })}
          </CardActions>
        )}
      </Card>
      {headerActions && (
        <NtaiMenu
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          items={headerActions}
          handleMenuItemSelect={handleMenuItemSelect}
        />
      )}
    </>
  );
}
