import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const generateRadioOptions = (value, options) => {
  return options.map((singleOption, i) => (
    <FormControlLabel
      key={`radio-option-${i}`}
      value={singleOption.value}
      label={singleOption.label}
      control={<Radio checked={singleOption.value === value ? true : false} />}
    />
  ));
};

export default function NtaiRadioGroupField(props) {
  const { control } = useFormContext();
  const { name, label, options } = props;
  return (
    <React.Fragment>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <RadioGroup value={value} onChange={onChange}>
              {generateRadioOptions(value, options)}
            </RadioGroup>
          )}
        />
      </FormControl>
    </React.Fragment>
  );
}
