import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import Scrollbar from "@ntai/components/Scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faNote } from "@fortawesome/pro-regular-svg-icons";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";

import {
  clearStatus,
  createOversightTopicNote,
  deleteOversightTopicNote,
  getOversightTopicNotes,
  updateOversightTopicNote,
} from "./store/oversightTopicNotesSlice";
import { OversightContext } from "../../../../../Oversight";
import OversightTopicNoteForm from "./OversightTopicNoteForm";
import { useSnackbar } from "notistack";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function OversightTopicNoteList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const [note, setNote] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);

  const {
    navNode,
    oversightTopicId,
    setOversightTopicId,
    setReloadOversightTopicSummary,
  } = React.useContext(OversightContext);

  const formDataParams = navNode
    ? {
        dataUuId: navNode["parentUuId"],
        objectTopicUuId:
          navNode["type"] === "OBJECT_TOPIC" ? navNode["uuId"] : null,
        dataTopicUuId:
          navNode["type"] === "DATA_TOPIC" ? navNode["uuId"] : null,
      }
    : null;

  const dataMap = useSelector(
    (state) => state.oversight.oversightTopicNotesSlice.data
  );

  const activeId = useSelector(
    (state) => state.oversight.oversightTopicNotesSlice.activeId
  );

  const data = Object.values(dataMap);

  const status = useSelector(
    (state) => state.oversight.oversightTopicNotesSlice.status
  );

  function handleAddEdit(id) {
    if (id) {
      setNote("edit");
      setCurrentId(id);
    } else {
      setNote("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setNote("list");
  }

  function handleSave(formData) {
    if (note === "add")
      dispatch(
        createOversightTopicNote({
          oversightTopicId: oversightTopicId,
          formData: { ...formDataParams, note: formData },
        })
      );
    else if (note === "edit")
      dispatch(
        updateOversightTopicNote({
          uuId: currentId,
          oversightTopicId: oversightTopicId,
          formData: { ...formDataParams, note: formData },
        })
      );
  }

  function handleDelete(uuId) {
    dispatch(
      deleteOversightTopicNote({
        oversightTopicId: oversightTopicId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getOversightTopicNotes"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status.result === "success" &&
      (status.method === "createOversightTopicNote" ||
        status.method === "updateOversightTopicNote")
    ) {
      if (oversightTopicId === "*") {
        setOversightTopicId(
          _.get(_.get(dataMap, activeId), "oversightTopicUuId")
        );
      }

      enqueueSnackbar("Record successfully created", {
        variant: "success",
      });
      dispatch(clearStatus());
      handleCancel();
      setReloadOversightTopicSummary(true);
    }

    if (
      status.result === "success" &&
      selectedIds.length === 0 &&
      status.method === "deleteOversightTopicNote"
    ) {
      enqueueSnackbar("Record deleted successfully.", {
        variant: "success",
      });
      dispatch(clearStatus());
      setReloadOversightTopicSummary(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(getOversightTopicNotes(oversightTopicId));
  }, []);

  return (
    <NtaiPanel
      width="75%"
      header={`Notes (${data.length})`}
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi"
      headerActions={
        <Button
          sx={{ display: "flex", gap: theme.spacing(1), fontSize: "12px" }}
          variant="contained"
          size="small"
          onClick={() => handleAddEdit(null)}
        >
          <FontAwesomeIcon icon={faNote} />
          ADD
        </Button>
      }
    >
      <>
        {note === "list" && (
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              border: theme.general.border1,
            }}
          >
            {loaded && Array.isArray(data) && data.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {Array.isArray(data) &&
                    data.map((dat, i) => {
                      return (
                        <React.Fragment key={`oversight-note-${i}`}>
                          <ListItem
                            sx={{ p: 0 }}
                            secondaryAction={
                              <IconButton
                                onClick={() => handleDelete(dat.uuId)}
                              >
                                <FontAwesomeIcon size="2xs" icon={faArchive} />
                              </IconButton>
                            }
                          >
                            <ListItemButton
                              onClick={() => handleAddEdit(dat.uuId)}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    fontSize: 12,
                                  }}
                                >
                                  {dat.userModified
                                    .substring(0, 2)
                                    .toUpperCase()}
                                </Avatar>
                              </ListItemAvatar>
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{
                                    flexBasis: "75%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="700"
                                  >
                                    {dat.note.title}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {dat.note.body}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    flexBasis: "25%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    {dat.userModified}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {NtaiUtils.localDate(dat.dateModified)}
                                  </Typography>
                                </Box>
                              </Box>
                            </ListItemButton>
                          </ListItem>

                          <Divider />
                        </React.Fragment>
                      );
                    })}
                </List>
              </Scrollbar>
            )}
            {loaded && Array.isArray(data) && data.length === 0 && (
              <NtaiEmptyMessage
                header="No notes available"
                subheader="You can add notes and share with other users"
                vAlign="center"
              />
            )}
            {!loaded && <NtaiCircularProgress />}
          </Box>
        )}
        {(note === "add" || note === "edit") && (
          <NtaiForm>
            <OversightTopicNoteForm
              note={note}
              formData={
                note === "edit" ? _.get(_.get(dataMap, currentId), "note") : {}
              }
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </NtaiForm>
        )}
      </>
    </NtaiPanel>
  );
}
