import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import CodelistCodeList from "./code/CodelistCodeList";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
const _ = require("lodash");

export default function CodelistForm(props) {
  const { action, formData, handleFormSubmit } = props;
  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();

  function handleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("codelistForm")
      );

      if (!_.isEmpty(sanitizedFormData)) handleFormSubmit(sanitizedFormData);
    }
  }

  function handleCancel() {
    history.push("/admin/codelists");
  }

  useEffect(() => {
    reset({ codelistForm: action === "edit" ? { ...formData } : {} });
  }, []);
  return (
    <NtaiTabs>
      <Box label="CODELISTS">
        <NtaiFormPanel
          width="520px"
          header="General Description"
          subheader="Enter name and description for the codelist. Note: All user defined codelists will have auto generated code."
          handleSave={handleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="codelistForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />
          <NtaiTextField
            name="codelistForm.description"
            label="Description*"
            multiline
            minRows={2}
            maxRows={2}
            rules={{ required: "Description is required" }}
          />
        </NtaiFormPanel>
      </Box>
      <Box label="CODES" disabled={action === "add" ? true : false}>
        <CodelistCodeList />
      </Box>
    </NtaiTabs>
  );
}
