import { createSlice } from "@reduxjs/toolkit";
import jwtService from "app/services/jwtService";
import { setUserData } from "./userDataSlice";

export const submitLogin =
  ({ username, password }) =>
  async (dispatch) => {
    return jwtService
      .signInWithUserNameAndPassword(username, password)
      .then((user) => {
        dispatch(setUserData(user));
        return dispatch(loginSuccess());
      })
      .catch((error) => {
        return dispatch(loginError(error));
      });
  };

const loginSlice = createSlice({
  name: "auth/login",
  initialState: {
    status: {},
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.status = { result: "success", message: null };
    },
    loginError: (state, action) => {
      state.status = { result: "error", message: action.payload };
    },
    logout: (state, action) => {
      jwtService.logout();
      state.status = {
        result: "logout",
      };
    },
    session_timeout: (state, action) => {
      jwtService.logout();
      state.status = {
        result: "session-timeout",
      };
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError, logout, session_timeout } =
  loginSlice.actions;

export default loginSlice.reducer;
