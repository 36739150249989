import {
  faArrowProgress,
  faDatabase,
  faEdit,
  faTable,
  faTableCells,
  faTableColumns,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import React, { useEffect } from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { getSourceDatasets } from "../../store/sourceDatasetsSlice";
import { getSourceStages } from "../../../store/sourceStagesSlice";
const _ = require("lodash");

function getNumOfDatasets(stagesData) {
  let result = 0;
  if (_.isArray(stagesData)) {
    stagesData.forEach((sd, i) => {
      if (_.isArray(sd["datasets"])) {
        result = result + sd["datasets"].length;
      }
    });
  }

  return result;
}

export default function SourceDatasetSidePanelDatasets() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setOpenDrawer, setSidePanelMenuParam } = React.useContext(
    SourceDatasetManagerContext
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const sourceStagesDataMap = useSelector(
    (state) => state.sources.sourceStagesSlice.data
  );

  const sourceStagesData =
    sourceStagesDataMap && !_.isEmpty(sourceStagesDataMap)
      ? Object.values(sourceStagesDataMap)
      : [];


  function onHandleClick(sourcePipelineStageId, sourceDatasetId) {
    const sourceStage = sourceStagesDataMap[sourcePipelineStageId];
    const stageName = sourceStage["name"];
    const datasetName = _.filter(sourceStage["datasets"], {
      uuId: sourceDatasetId,
    })[0]["name"];

    setSidePanelMenuParam({
      menuId: 4,
      label: sourceStage + " - " + datasetName,
      sourcePipelineStageId: sourcePipelineStageId,
      uuId: sourceDatasetId,
    });

    setOpenDrawer(true);
  }

  useEffect(() => {
    dispatch(
      getSourceStages({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourcePipelineId: sourcePipelineId,
      })
    );
  }, []);

  return (
    <NtaiAccordion
      title="Datasets"
      titleIcon="table"
      titleSecondary={
        <Chip
          variant="contained"
          size="small"
          label={
            <Typography variant="subtitle2" color="inherit">
              {getNumOfDatasets(sourceStagesData)}
            </Typography>
          }
        />
      }
    >
      <Box
        sx={{
          borderTop: theme.general.border1,
          p: theme.spacing(1),
          display: "flex",
          height: "160px",
          width: "100%",
        }}
      >
        <Box
          sx={{ display: "flex", width: "100%", border: theme.general.border1 }}
        >
          {sourceStagesData &&
            _.isArray(sourceStagesData) &&
            sourceStagesData.length > 0 && (
              <Scrollbar autoHide={false}>
                <Box sx={{ pr: theme.spacing(1) }}>
                  <TreeView
                    key={`source-pipeline-stages-tree`}
                    aria-label="source pipeline stages nav"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                  >
                    {sourceStagesData.map((ss, i) => (
                      <TreeItem
                        key={`source-pipeline-stage-${i}`}
                        nodeId={`${i}`}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: theme.spacing(1),
                            }}
                          >
                            <FontAwesomeIcon size="sm" icon={faArrowProgress} />
                            <Typography variant="subtitle1" color="inherit">
                              {ss["name"]}
                            </Typography>
                          </Box>
                        }
                      >
                        {_.has(ss, "datasets") &&
                          _.isArray(ss["datasets"]) &&
                          ss["datasets"].length > 0 &&
                          ss["datasets"].map((sd, j) => (
                            <TreeItem
                              onClick={() =>
                                onHandleClick(ss["uuId"], sd["uuId"])
                              }
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: theme.spacing(1),
                                  }}
                                >
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faTableColumns}
                                  />
                                  <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                  >
                                    {sd["name"]}
                                  </Typography>
                                </Box>
                              }
                            />
                          ))}
                      </TreeItem>
                    ))}
                  </TreeView>
                </Box>
              </Scrollbar>
            )}
        </Box>
      </Box>
    </NtaiAccordion>
  );
}
