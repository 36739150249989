import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createAwsClusterTemplate,
  clearStatus,
} from "./store/awsClusterTemplatesSlice";
import AwsClusterTemplateForm from "./AwsClusterTemplateForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AwsClusterTemplateCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const status = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.status
  );
  const dataMap = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.data
  );
  const activeId = useSelector(
    (state) => state.core.awsClusterTemplatesSlice.activeId
  );

  function handleSave(formData) {
    dispatch(createAwsClusterTemplate(formData));
  }

  useEffect(() => {
    if (
      status.method === "createAwsClusterTemplate" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Aws cluster template "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      history.push(`/aws-cluster-templates/${activeId}/edit`);
    }

    if (
      status &&
      status.method === "createAwsClusterTemplate" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Aws Cluster Template" back="/aws-cluster-Templates">
      <NtaiForm>
        <AwsClusterTemplateForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
