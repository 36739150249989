import {
  Avatar,
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiIcons from "utils/NtaiIcons";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
const _ = require("lodash");

export default function NtaiList(props) {
  const theme = useTheme();
  const { items, divider, handleSelect } = props;

  function handleClick(itemId) {
    handleSelect(itemId);
  }

  function generateListItems(nodes) {
    return nodes.map((node, index) => {
      return (
        <React.Fragment key={`list-item-${index}`}>
          <ListItem
            secondaryAction={
              <Box sx={{ display: "flex", gap: theme.spacing(1) }}></Box>
            }
            onClick={() => handleClick(node.id)}
          >
            <ListItemButton>
              {node.avatar && <ListItemAvatar>{node.avatar}</ListItemAvatar>}
              {node.icon && (
                <ListItemIcon>
                  <FontAwesomeIcon
                    size={node.iconSize ? node.iconSize : null}
                    icon={NtaiIcons[node.icon]}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                primary={_.get(node, "primary")}
                secondary={_.get(node, "secondary")}
              />
            </ListItemButton>
          </ListItem>

          {divider ? <Divider /> : null}
        </React.Fragment>
      );
    });
  }

  return <List sx={{ width: "100%" }}>{generateListItems(items)}</List>;
}

NtaiList.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};
