import React, { useRef, useEffect, useState, useContext } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { Box, Popover } from "@mui/material";
import NtaiContextMenuEChart from "../echarts/NtaiContextMenuEChart";
import "./ntaimapboxgeo.css";
import NtaiEChartUtil from "../echarts/util/NtaiEChartUtil";
const _ = require("lodash");

const accessToken =
  "pk.eyJ1Ijoic3lkYmFyIiwiYSI6ImNrcnlkbXJrMDA3anIydW9lZjJxMjA5N2QifQ.5pobKT6pI4ApsgDZdV8DqA";

const geoJson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: { vol: 100, comp: 79 },
      geometry: { type: "Point", coordinates: [88.3639, 22.5726] },
    },
    {
      type: "Feature",
      properties: { vol: 500, comp: 91 },
      geometry: { type: "Point", coordinates: [85.3, 23.3] },
    },
    {
      type: "Feature",
      properties: { vol: 2000, comp: 95 },
      geometry: { type: "Point", coordinates: [85.8, 20.4] },
    },
  ],
};

const geojsonsource = "geojsonsource";

function getCategoriesDescription(data, metadata) {
  let returnValue = "";
  const result = [];
  // const catFields = _.filter(
  //   _.orderBy(Object.values(metadata["fields"]), ["field.fieldOrder"], ["asc"]),
  //   function (o) {
  //     if (
  //       (o["derivedFieldFg"] !== 1 && o["field"]["dataTypeCode"] === 1) ||
  //       (o["derivedFieldFg"] === 1 && o["derivedField"]["fieldTypeCode"] === 4)
  //     ) {
  //       return true;
  //     } else return false;
  //   }
  // );

  if (!_.isEmpty(data["values"]) && Object.keys(data["values"]).length > 0) {
    Object.keys(data["values"]).forEach((o) => {
      const field = _.filter(Object.values(metadata["fields"]), function (p) {
        return p["uuId"] === o ? true : false;
      });

      if (field.length > 0) {
        result.push(
          field[0]["headerName"] +
            ": " +
            "<strong>" +
            data["values"][o] +
            "</strong>"
        );
      } else {
        // result.push("Count: " + "<strong>" + data["values"][o] + "</strong>");
      }
    });
  }

  if (result.length > 0) returnValue = result.join("<br/>");

  return returnValue;
}

export default function NtaiGeoMap(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const {
    widgetId,
    metadata,
    data,
    width,
    height,
    handleClick,
    handleContextMenu,
  } = props;

  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuCoords, setMenuCoords] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [source, setSource] = useState();
  const [selectedlocation, setSelectedlocation] = useState();
  const [circleRadiusSteps, setCircleRadiusSteps] = useState([
    5, 15, 100, 30, 500, 40, 1000, 50, 2000, 60,
  ]);
  const [circleColorSteps, setCircleColorSteps] = useState([
    [80, "red"],
    [90, "yellow"],
    [95, "green"],
    [98, "#757ce8"],
  ]);

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  const geocodingClient = mbxGeocoding({
    accessToken: accessToken,
  });

  function handleMenuClose() {
    setMenuOpen(false);
  }

  function getSourceWidgetFilterFields(location) {
    return [
      {
        sourceWidgetFieldUuId: _.filter(
          Object.values(metadataRef.current["fields"]),
          { geoLocFg: 1 }
        )[0]["uuId"],
        fieldOperatorCode: 1,
        fieldValue: location,
      },
    ];
  }
  function onHandleClick(location) {
    const sourceWidgetFilterFields = getSourceWidgetFilterFields(location);
    handleClick(sourceWidgetFilterFields);
  }

  function onHandleContextMenu(action) {
    const sourceWidgetFilterFields =
      getSourceWidgetFilterFields(selectedlocation);

    handleContextMenu(action, sourceWidgetFilterFields);
    handleMenuClose();
  }

  async function fetchCoordinates(dat) {
    const response = await geocodingClient
      .forwardGeocode(_.pick(dat, ["query", "limit"]))
      .send()
      .then((response) => {
        const match = response.body;

        if (
          match !== undefined &&
          match &&
          _.isArray(match["features"]) &&
          match["features"].length > 0
        ) {
          const coordinates = match.features[0].geometry.coordinates;
          const locationName = match.features[0].location_name;
          const center = match.features[0].center;

          return {
            type: "Feature",
            center: center,
            geometry: {
              type: "Point",
              coordinates: coordinates,
            },
            // clusterProperties: {
            //   sum: ["+", ["get", "count", ["get", "value", ["properties"]]]],
            // },
            properties: {
              query: dat["query"],
              value: dat["value"],
              total: dat.hasOwnProperty("total") ? dat["total"] : 0,
              colorValue: dat.hasOwnProperty("colorValue")
                ? dat["colorValue"]
                : 1,
              description: `${getCategoriesDescription(
                dat,
                metadata
              )}<br/>Value: <strong>${dat["value"]}</strong>`,
            },
          };
        }
      });

    const data = await response;

    return { ...data };
  }

  async function fetchMultipleCoordinates(data) {
    const coordinatesPromises = data.map(async (dat) => {
      try {
        return await fetchCoordinates(dat);
      } catch (error) {
        console.error(`Error fetching coordinates for ${dat}:`, error);
      }
    });

    const coordinatesList = await Promise.all(coordinatesPromises);
    setSource({ type: "FeatureCollection", features: coordinatesList });
  }

  useEffect(() => {
    if (map.current) map.current.resize();
  }, [width, height]);

  useEffect(() => {
    fetchMultipleCoordinates(data);

    if (
      metadata["widget"]["geoMapCircleRadiusSteps"] &&
      metadata["widget"]["geoMapCircleRadiusSteps"].length > 0
    ) {
      const parts = metadata["widget"]["geoMapCircleRadiusSteps"].split(",");
      setCircleRadiusSteps(parts.map((o) => 1 * o));
    }
    if (
      metadata["widget"]["geoMapCircleColorSteps"] &&
      metadata["widget"]["geoMapCircleColorSteps"].length > 0
    ) {
      let result = [];

      const parts = metadata["widget"]["geoMapCircleColorSteps"].split(",");

      if (parts.length % 2 == 0) {
        for (let i = 0; i < parts.length; i = i + 2) {
          const value = 1 * parts[i];
          const color = parts[i + 1];
          result.push([value, color]);
        }

        setCircleColorSteps([...result]);
      }
    }
  }, [data, metadata]);

  useEffect(() => {
    if (source !== undefined && map.current && source && !_.isEmpty(source)) {
      if (map.current.getSource(geojsonsource) != null) {
        map.current.getSource(geojsonsource).setData(source);
      }

      map.current.on("load", function () {
        if (map.current.getSource(geojsonsource) == null) {
          map.current.addSource(geojsonsource, {
            type: "geojson",
            data: source,
            cluster: true, //clusters the points in this source
          });
        }

        map.current.addLayer({
          id: "circle-layer",
          type: "circle",
          source: geojsonsource,
          paint: {
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["get", "value"],
              ...circleRadiusSteps,
            ],
            // "circle-color": [
            //   ["get", "color"],
            //   80,
            //   "red",
            //   85,
            //   "yellow",
            //   90,
            //   "green",
            //   98,
            //   "blue",
            // ],
            "circle-color": {
              property: "colorValue",
              stops: circleColorSteps,
            },

            "circle-opacity": 0.5,
          },
        });

        map.current.addLayer(
          {
            id: "text-layer",
            type: "symbol",
            source: geojsonsource,
            layout: {
              "text-field": [
                "case",
                ["all", [">=", ["abs", ["get", "value"]], 1.0e9]],
                [
                  "concat",
                  [
                    "to-string",
                    [
                      "round",
                      [
                        "/",
                        ["number", ["get", "value"]],
                        ["number", 1000000000],
                      ],
                    ],
                  ],
                  "B",
                ],
                ["all", [">=", ["abs", ["get", "value"]], 1.0e6]],
                [
                  "concat",
                  [
                    "to-string",
                    [
                      "round",
                      ["/", ["number", ["get", "value"]], ["number", 1000000]],
                    ],
                  ],
                  "M",
                ],
                ["all", [">=", ["abs", ["get", "value"]], 1.0e3]],
                [
                  "concat",
                  [
                    "to-string",
                    [
                      "round",
                      ["/", ["number", ["get", "value"]], ["number", 1.0e3]],
                    ],
                  ],
                  "K",
                ],
                ["get", "value"],
              ],
              "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],

              // "text-size": 14,
            },
          },
          "circle-layer"
        );

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        // map.current.setLayoutProperty("text-layer", "text-size", [
        //   "interpolate",
        //   ["exponential", 2],
        //   ["zoom"],
        //   3,
        //   16,
        //   5,
        //   18,
        //   25,
        //   300,
        // ]);

        map.current.on("mouseenter", "circle-layer", (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;
          popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
        });
        map.current.on("mouseleave", "circle-layer", (e) => {
          map.current.getCanvas().style.cursor = "";
          popup.remove();
        });

        // inspect a cluster on click
        map.current.on("click", "circle-layer", (e) => {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ["circle-layer"],
          });

          const query_location = features[0].properties.query;
          onHandleClick(query_location);
          // setSelectedlocation(query_location);

          // const clusterId = features[0].properties.cluster_id;
          // console.log("I got clicked..", features);

          // map.current
          //   .getSource("jsonsource")
          //   .getClusterExpansionZoom(clusterId, (err, zoom) => {
          //     if (err) return;

          //     map.current.easeTo({
          //       center: features[0].geometry.coordinates,
          //       zoom: zoom,
          //     });
          //   });

          // map
          //   .getSource("earthquakes")
          //   .getClusterExpansionZoom(clusterId, (err, zoom) => {
          //     if (err) return;

          //     map.easeTo({
          //       center: features[0].geometry.coordinates,
          //       zoom: zoom,
          //     });
          //   });
        });

        map.current.on("contextmenu", "circle-layer", (e) => {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ["circle-layer"],
          });

          const query_location = features[0].properties.query;
          setSelectedlocation(query_location);
          setMenuCoords({
            left: e["originalEvent"]["clientX"],
            top: e["originalEvent"]["clientY"],
          });
          setMenuOpen(true);
        });
      });
    }
  }, [source, circleRadiusSteps, circleColorSteps]);

  useEffect(() => {
    mapboxgl.accessToken = accessToken;

    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat], // longitude and latitude
      zoom: zoom,
      attributionControl: false,
    });

    const nav = new mapboxgl.NavigationControl();
    map.current.addControl(nav, "bottom-left");
  }, []);

  return (
    <>
      <div
        style={{
          width: width ? width : "!00%",
          height: height ? height : "100%",
          display: "flex",
        }}
        ref={mapContainer}
        className="map-container"
      />

      {menuCoords && menuOpen && (
        <Popover
          open={menuOpen}
          onClose={() => handleMenuClose()}
          anchorReference="anchorPosition"
          anchorPosition={menuCoords}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <NtaiContextMenuEChart onHandleContextMenu={onHandleContextMenu} />
        </Popover>
      )}
    </>
  );
}
