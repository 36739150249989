import { Box, Button, Typography, useTheme } from "@mui/material";
import { themeCreator } from "app/theme/base";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import NtaiTextField from "../inputs/NtaiTextField";
import { parse } from "twitter-search-query-parser";

const parser = require("logic-query-parser");

export default function NtaiQueryParser() {
  const theme = useTheme();
  const { getValues } = useFormContext();
  const [tqpResult, setTqpResult] = useState();
  const [lqpResult, setLqpResult] = useState();
  const [lqpQueryResult, setLqpQueryResult] = useState();

  function parseQuery() {
    const data = getValues("query");
    // console.log("parsed query (tqp): ", parse(data));
    setTqpResult(JSON.stringify(parse(data)));
    setLqpResult(JSON.stringify(parser.parse(data)));
    setLqpQueryResult(
      JSON.stringify(parser.utils.binaryTreeToQueryJson(parser.parse(data)))
    );
    // console.log("parsed query (lqp): ", parser.parse(data));
  }

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      }}
    >
      <NtaiTextField name="query" label="Query" />
      <Button onClick={() => parseQuery()}>Parse Query</Button>
      <Typography variant="body1">TQP: {tqpResult}</Typography>
      <Typography variant="body1">LQP: {lqpResult}</Typography>
      <Typography variant="body1">LQP Query: {lqpQueryResult}</Typography>
    </Box>
  );
}
