import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function NtaiHeaderSubheader({ header, subheader }) {
  const theme = useTheme();
  return (
    <Box
      sx={{ py: theme.spacing(1), display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h6">{header}</Typography>
      <Typography variant="subtitle1">{subheader}</Typography>
    </Box>
  );
}
