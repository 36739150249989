import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Divider, IconButton, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import NtaiAutoSuggestIB from "@ntai/components/inputs/NtaiAutoSuggestIB";
import { faBars, faExpand } from "@fortawesome/pro-light-svg-icons";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import { useSelector } from "react-redux";
import { SearchBasicInitContext } from "./SearchBasicInit";
import SearchThesaurusLookupForm from "./lookup/SearchThesaurusLookupForm";
import server from "app/http/ntaiServer";
import SearchBasicInitSuggestBar from "./SearchBasicInitSuggestBar";

const _ = require("lodash");

export default function SearchBasicInitSuggest({ handleSearch }) {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [openExpandDialog, setOpenExpandDialog] = useState(false);
  const [thesaurusDialogOpen, setThesaurusDialogOpen] = useState(false);
  const [thesaurusDialogTermIndex, setThesaurusDialogTermIndex] = useState();
  const [options, setOptions] = useState([]);
  const { getValues, reset } = useFormContext();

  const codelistDataMap = useSelector(
    (state) => state.core.codelistsSlice.data
  );

  const data = Object.values(codelistDataMap);

  const { searchQueryTerms, setSearchQueryTerms, mode } = useContext(
    SearchBasicInitContext
  );

  const getOptionsAsync = (query) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        server
          .get("/autocomplete", { params: { q: query } })
          .then((response) => {
            resolve(response.data);
          });
      }, 300);
    });
  };

  const getOptionsDelayed = useCallback(
    _.debounce((text, callback) => {
      setOptions([]);
      getOptionsAsync(text).then(callback);
    }, 300),
    []
  );

  function handleInputChange(str) {
    setInputValue(str);
  }

  function handleChange(qTerms) {
    const revisedSearchQueryTerms = [];
    qTerms.forEach((qt, i) => {
      if (typeof qt === "object" && !Array.isArray(qt) && qt !== null) {
        const st = _.filter(searchQueryTerms, function (s) {
          if (
            s["term"] === qt["term"] &&
            s["conceptId"] === qt["conceptId"] &&
            s["thesaurusUuId"] === qt["thesaurusUuId"]
          )
            return true;
        });

        if (st.length > 0) {
          revisedSearchQueryTerms.push(st[0]);
        } else {
          revisedSearchQueryTerms.push(qt);
        }
      } else {
        revisedSearchQueryTerms.push(qt);
      }
    });

    setSearchQueryTerms([...revisedSearchQueryTerms]);
  }

  function submitSearch() {
    handleSearch(getValues("searchInitForm.query"));
  }

  function handleExpand() {
    setOpenExpandDialog(true);
  }
  function handleExpandDialogClose() {
    setOpenExpandDialog(false);
  }

  function handleTagClick(index, tag) {
    setThesaurusDialogTermIndex(index);
  }

  function handleThesaurusDialogOpen() {
    setThesaurusDialogOpen(true);
  }

  function handleThesaurusDialogClose() {
    setThesaurusDialogOpen(false);
    setThesaurusDialogTermIndex(null);
  }

  useEffect(() => {
    if (_.isNumber(thesaurusDialogTermIndex) && thesaurusDialogTermIndex >= 0)
      handleThesaurusDialogOpen();
  }, [thesaurusDialogTermIndex]);

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      getOptionsDelayed(inputValue, (autoCompleteResult) => {
        setOptions(_.get(autoCompleteResult, "concepts"));
      });
    } else {
      setOptions([]);
    }
  }, [inputValue, getOptionsDelayed]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          width: "100%",
        }}
      >
        <SearchBasicInitSuggestBar
          options={options}
          placeholder="Search for anything..."
          name="searchInitForm.query"
          optionLabelField="term"
          defaultValue={[]}
          label="Search"
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleTagClick={handleTagClick}
          terms={searchQueryTerms}
          endAdornments={
            <>
              <>
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faExpand} />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faBars} />
                </IconButton>
              </>
            </>
          }
        />

        <Button
          onClick={() => submitSearch()}
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faSearch} />}
          icon="search"
        >
          Search
        </Button>
      </Box>
      <NtaiDialog
        title="Manage Query"
        open={openExpandDialog}
        size="md"
        handleDialogClose={handleExpandDialogClose}
      >
        context
      </NtaiDialog>
      <NtaiDialog
        title={`Include synonyms`}
        open={thesaurusDialogOpen}
        size="md"
        handleDialogClose={handleThesaurusDialogClose}
      >
        <SearchThesaurusLookupForm
          termIndex={thesaurusDialogTermIndex}
          handleCancel={handleThesaurusDialogClose}
        />
      </NtaiDialog>
    </>
  );
}
