import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import { getObject } from "app/main/pages/core/cdo/object/store/objectsSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiHelpText from "@ntai/components/helptext/NtaiHelpText";
import { SearchContext } from "../../../../SearchEdit";
import {
  clearStatus,
  submitSearchSourceRecordsDistribution,
} from "../store/searchSourceRecordDistributionsSlice";
import { SearchSourceContext } from "../../../SearchSource";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SearchSourceRecordsWorkflowRequestForm({
  handleCancel,
  handleGoBack,
  prevStepData,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRecords } = React.useContext(SearchSourceRecordListContext);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const objectDataMap = useSelector((state) => state.core.objectsSlice.data);

  const adjObject =
    objectDataMap && !_.isEmpty(objectDataMap)
      ? _.get(objectDataMap, prevStepData["adjObjectUuId"])
      : null;

  function handleOtherAction(actionValue) {
    if (actionValue === "back") {
      handleGoBack();
    }
  }

  function onHandleSubmit() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchSourceWfAdjForm")
    );
    const revisedAdjFormData = ObjectFormUtil.generateFormSubmissionData(
      sanitizedFormData,
      adjObject
    );

    const requestFormData = {
      moduleCode: 1,
      clientData: {
        objectUuId: _.get(adjObject, "uuId"),
        ...revisedAdjFormData,
      },
    };

    const finalFormData = _.cloneDeep(prevStepData);
    finalFormData["workflow"]["requestFormData"] = requestFormData;
    finalFormData["contact"] = null;

    dispatch(
      submitSearchSourceRecordsDistribution({
        searchId: searchId,
        searchSourceId: searchSourceId,
        formData: finalFormData,
      })
    );
  }

  useEffect(() => {
    dispatch(getObject({ domainId: "*", uuId: prevStepData["adjObjectUuId"] }));
  }, [prevStepData]);

  return (
    <NtaiDialogFormPanel
      handleSave={onHandleSubmit}
      handleCancel={handleCancel}
      handleSaveLabel="Submit"
      otherActions={[{ value: "back", label: "Back" }]}
      handleOtherAction={handleOtherAction}
    >
      <NtaiHelpText header="Records selected: " direction="row">
        {selectedRecords.length === 0 ? "All" : selectedRecords.length}
      </NtaiHelpText>
      <NtaiHelpText direction="row" header="Criteria:">
        My criteria
      </NtaiHelpText>

      <Box
        sx={{
          display: "flex",
          height: "220px",
          width: "100%",
          flexDirection: "column",
          // border: theme.general.border1,
          // borderRadius: theme.general.borderRadius,
        }}
      >
        {adjObject && !_.isEmpty(adjObject["objectForm"]) && (
          <Scrollbar autoHide={true}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(2),
                // px: theme.spacing(2),
                // py: theme.spacing(1),
                pr: theme.spacing(2),
              }}
            >
              {ObjectFormUtil.generateForm(
                "searchSourceWfAdjForm",
                adjObject["objectForm"],
                true,
                theme
              )}
            </Box>
          </Scrollbar>
        )}
        {(!adjObject || !adjObject["objectForm"]) && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <NtaiEmptyMessage
              header="Assessment form not available"
              subheader="No assessment form has been configured"
            />
          </Box>
        )}
      </Box>
    </NtaiDialogFormPanel>
  );
}
