import {
  faGlobe,
  faLocation,
  faNote,
  faTasks,
  faThumbTack,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { faMapPin, faPinata, faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { deepOrange, green, grey, red } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useState } from "react";
import NtaiIcons from "utils/NtaiIcons";
import NtaiBadge from "../badges/NtaiBadge";
const _ = require("lodash");

export default function NtaiCompactCard3(props) {
  const theme = useTheme();
  const {
    id,
    width,
    height,
    avatar,
    title,
    description,
    handleClick,
    subTitles,
  } = props;

  return (
    <Card
      sx={{
        p: 0,
        paddingY: "4px",
        border: theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        height: height ? height : "100%",
        width: width ? width : "100%",
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
          background: theme.colors.secondary.lighter,
        },
      }}
      onClick={() => handleClick(id)}
    >
      <CardHeader
        sx={{ py: 1 }}
        title={
          title ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                {/* {avatar && (
                    <Avatar
                      sx={{
                        bgcolor: "white",
                        color: grey[900],
                        width: 24,
                        height: 24,
                      }}
                    >
                      {avatar}
                    </Avatar>
                  )} */}

                <Typography variant="h6" fontWeight="700" color="inherit">
                  {NtaiUtils.trunc(title, 25)}
                </Typography>
                {/* <NtaiBadge title="New" fg="white" bg="red" /> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(0.5),
                  alignItems: "center",
                }}
              >
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faStar} color="orange" />
                </IconButton>
              </Box>
            </Box>
          ) : null
        }
      />

      <CardContent
        sx={{
          py: 0,
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        }}
      >
        <Typography variant="subtitle2">
          {NtaiUtils.trunc(description, 60)}
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            alignItems: "center",
            justifyContent: "space-between",
            px: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faNote} />
            <Typography variant="subtitle2">34</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faLocation} />
            <Typography variant="subtitle2">14</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faTasks} />
            <Typography variant="subtitle2">24</Typography>
          </Box>
        </Box>
        <Divider />
        {/* {subTitles && _.isArray(subTitles) && subTitles.length > 0 && (
          <List sx={{ p: 0 }}>
            {subTitles.map((st, i) => (
              <>
                <ListItem sx={{ px: 0, py: theme.spacing(0.5) }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <FontAwesomeIcon icon={NtaiIcons[st["icon"]]} />
                    <ListItemText primary={st["name"]} />

                    <NtaiBadge title={st["value"]} fg="white" bg="red" />
                  </Box>
                </ListItem>
              </>
            ))}
          </List>
        )} */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(0.5),
          }}
        >
          <Avatar sx={{ height: 24, width: 24 }}>
            <Typography color="inherit" variant="subtitle2">
              KR
            </Typography>
          </Avatar>
          <Avatar sx={{ height: 24, width: 24 }}>
            <Typography color="inherit" variant="subtitle2">
              LO
            </Typography>
          </Avatar>
          <Avatar sx={{ height: 24, width: 24 }}>
            <Typography color="inherit" variant="subtitle2">
              +4
            </Typography>
          </Avatar>
        </Box> */}
      </CardContent>
    </Card>
  );
}
