import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAwsClusterSessions = createAsyncThunk(
  "awsClusterSessions/getAwsClusterSessions",
  async (awsClusterId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/aws-emr-clusters/${awsClusterId}/livy-sessions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAwsClusterSession = createAsyncThunk(
  "awsClusterSessions/getAwsClusterSession",
  async (values, { rejectWithValue }) => {
    try {
      const { awsClusterId, uuId } = values;
      const response = await server.get(
        `/aws-emr-clusters/${awsClusterId}/livy-sessions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const terminateAwsClusterSession = createAsyncThunk(
  "awsClusterSessions/terminateAwsClusterSession",
  async (values, { rejectWithValue }) => {
    try {
      const { awsClusterId, uuId } = values;
      const response = await server.get(
        `/aws-emr-clusters/${awsClusterId}/livy-sessions/${uuId}/terminate`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAwsClusterSession = createAsyncThunk(
  "awsClusterSessions/deleteAwsClusterSession",
  async (values, { rejectWithValue }) => {
    try {
      const { awsClusterId, uuId } = values;
      const response = await server.delete(
        `/aws-emr-clusters/${awsClusterId}/livy-sessions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const awsClusterSessionsSlice = createSlice({
  name: "awsClusterSessions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAwsClusterSessions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAwsClusterSessions" };
    },
    [getAwsClusterSessions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAwsClusterSessions",
        message: action.payload.message,
      };
    },
    [getAwsClusterSession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getAwsClusterSession" };
    },
    [getAwsClusterSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAwsClusterSession",
        message: action.payload.message,
      };
    },

    [terminateAwsClusterSession.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "terminateAwsClusterSession",
      };
    },
    [terminateAwsClusterSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "terminateAwsClusterSession",
        message: action.payload.message,
      };
    },

    [deleteAwsClusterSession.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteAwsClusterSession" };
    },
    [deleteAwsClusterSession.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAwsClusterSession",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = awsClusterSessionsSlice.actions;

export default awsClusterSessionsSlice.reducer;
