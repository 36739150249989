import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/settings");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSetting = createAsyncThunk(
  "settings/getSetting",
  async (name, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/settings/${name}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSetting = createAsyncThunk(
  "settings/createOrUpdateSetting",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/settings", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSetting = createAsyncThunk(
  "settings/updateSetting",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/admin/settings/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSetting = createAsyncThunk(
  "settings/deleteSetting",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/settings/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSettings.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSettings" };
    },
    [getSettings.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSettings",
        message: action.payload.message,
      };
    },
    [getSetting.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSetting" };
    },
    [getSetting.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSetting",
        message: action.payload.message,
      };
    },
    [createOrUpdateSetting.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "createOrUpdateSetting" };
    },
    [createOrUpdateSetting.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSetting",
        message: action.payload.message,
      };
    },
    [updateSetting.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSetting" };
    },
    [updateSetting.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSetting",
        message: action.payload.message,
      };
    },
    [deleteSetting.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSetting" };
    },
    [deleteSetting.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSetting",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = settingsSlice.actions;

export default settingsSlice.reducer;
