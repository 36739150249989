import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";

const _ = require("lodash");

export const getSearches = createAsyncThunk(
  "search/getSearches",
  async (params, { rejectWithValue }) => {
    try {
      const response = await server.get(
        "/search",
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getSearch = createAsyncThunk(
  "search/getSearch",
  async (searchId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/search/${searchId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const run = createAsyncThunk(
  "search/run",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/search/run", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearch = createAsyncThunk(
  "search/createSearch",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/search/create", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchFromSeries = createAsyncThunk(
  "search/createSearchFromSeries",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/search/create-from-series", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearch = createAsyncThunk(
  "search/updateSearch",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/search/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearch = createAsyncThunk(
  "search/deleteSearch",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/search/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    data: {},
    searchEditData: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearches.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSearches" };
    },
    [getSearches.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearches",
        message: action.payload.message,
      };
    },

    [getSearch.fulfilled]: (state, action) => {
      state.searchEditData = action.payload;
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getSearch" };
    },
    [getSearch.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSearch",
      };
    },
    [getSearch.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearch",
        message: action.payload.message,
      };
    },

    [createSearch.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSearch" };
    },
    [createSearch.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearch",
        message: action.payload.message,
      };
    },

    [createSearchFromSeries.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createSearchFromSeries" };
    },
    [createSearchFromSeries.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchFromSeries",
        message: action.payload.message,
      };
    },

    [updateSearch.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.searchEditData = action.payload;
      state.status = { method: "updateSearch", result: "success" };
    },
    [updateSearch.rejected]: (state, action) => {
      state.status = "error";
      state.method = "updateSearch";
      state.message = action.payload.message;
    },
    [deleteSearch.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSearch" };
    },
    [deleteSearch.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearch",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchSlice.actions;

export default searchSlice.reducer;
