import React from "react";
import { Box, useTheme, Typography, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiInfoPanel from "@ntai/components/micros/NtaiInfoPanel";
import ReactJson from "react-json-view";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function AlertDefPreviewSubmit() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const alertDefinitionDataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );

  const definitionId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  const alertDefinitionData = _.get(alertDefinitionDataMap, definitionId);

  const alertDefObjectTopicsDataMap = useSelector(
    (state) => state.monitor.alertDefSourceObjectTopicsSlice.data
  );

  const alertDefObjectTopicsData = Object.values(alertDefObjectTopicsDataMap);

  return (
    <>
      <NtaiPanel
        width="100%"
        height="100%"
        header="Preview"
        subheader="Review alert definition details and then click on submit to start the workflow. Ensure you have selected source, workflow and assigned object/data topics to the alert. For recurring alert, also ensure you have specified frequency under the schedule tab."
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            width: "100%",
            height: "420px",
            border: theme.general.border1,
            borderRadius: theme.general.borderRadiusSm,
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
                p: theme.spacing(2),
              }}
            >
              <NtaiInfoPanel border={false} title="General">
                <NtaiLabelValue label="Name" value={alertDefinitionData.name} />
                <NtaiLabelValue
                  label="Description"
                  value={alertDefinitionData.description}
                />
                <NtaiLabelValue
                  label="Type"
                  value={alertDefinitionData.typeDecode}
                />
                <NtaiLabelValue
                  label="Category"
                  value={alertDefinitionData.categoryDecode}
                />
              </NtaiInfoPanel>
              <Divider />

              <NtaiInfoPanel border={false} title="Source">
                <NtaiLabelValue
                  label="Name"
                  value={`${alertDefinitionData.alertDefSource.sourceMin.name} (${alertDefinitionData.alertDefSource.sourceMin.abbrev}) (${alertDefinitionData.alertDefSource.sourceMin.majorVersion}.${alertDefinitionData.alertDefSource.sourceMin.minorVersion})`}
                />
                <NtaiLabelValue
                  label="Description"
                  value={
                    alertDefinitionData.alertDefSource.sourceMin.description
                  }
                />
              </NtaiInfoPanel>
              <Divider />

              <Typography variant="h4">Criteria</Typography>
              <ReactJson
                src={
                  alertDefinitionData.alertDefSource.esCriteria &&
                  !_.isEmpty(alertDefinitionData.alertDefSource.esCriteria)
                    ? alertDefinitionData.alertDefSource.esCriteria
                    : {}
                }
              />
              <Divider />
              <NtaiInfoPanel border={false} title="Topics">
                <NtaiLabelValue
                  label="Object Topics"
                  value={
                    _.isArray(alertDefObjectTopicsData) &&
                    alertDefObjectTopicsData.map((adotd, i) => (
                      <Typography>
                        {adotd.objectTopic.topic.name} (
                        {adotd.objectTopic.objectName})
                      </Typography>
                    ))
                  }
                />
                <NtaiLabelValue label="Data Topics" value="" />
              </NtaiInfoPanel>
              <Divider />

              <NtaiInfoPanel border={false} title="Workflow">
                <NtaiLabelValue
                  label="Name"
                  value={
                    alertDefinitionData.alertDefWorkflow
                      ? alertDefinitionData.alertDefWorkflow.workflowDefinition
                          .name
                      : null
                  }
                />
              </NtaiInfoPanel>
              <Divider />

              <NtaiInfoPanel border={false} title="Schedule">
                <NtaiLabelValue
                  label="Frequency"
                  value={
                    alertDefinitionData.alertDefSchedule
                      ? NtaiUtils.cronExpressionToString(
                          alertDefinitionData.alertDefSchedule.cronExpression
                        )
                      : null
                  }
                />
              </NtaiInfoPanel>
              <Divider />
            </Box>
          </Scrollbar>
        </Box>
      </NtaiPanel>
    </>
  );
}
