import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import SearchSourceWidgetDrillFields from "./SearchSourceWidgetDrillFields";
import SearchSourceWidgetDrillCharts from "./SearchSourceWidgetDrillCharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faFilter,
} from "@fortawesome/pro-light-svg-icons";
import { SearchSourceWidgetDrillContext } from "../../SearchSourceView";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function SearchSourceWidgetDrillPanel({
  handleDrillUp,
  handleDrillDown,
}) {
  const theme = useTheme();

  const { drillModeWidgetUuId, drillWidgetFieldIds, drillWidgetChartId } =
    React.useContext(SearchSourceWidgetDrillContext);

  const dataMap = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.data
  );

  const widget =
    dataMap && !_.isEmpty(dataMap) ? dataMap[drillModeWidgetUuId] : null;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        border: theme.general.border1,
        bordeRadius: theme.general.borderRadiusSm,
        background: "white",
        // borderTop: open ? theme.general.border2p : null,
        // boxShadow: open ? theme.colors.shadows.card : null,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            flexBasis: "32px",
            width: "100%",
            display: "flex",
            borderBottom: theme.general.border1,
          }}
        >
          <Box
            sx={{
              p: theme.spacing(1),
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2" color="inherit" fontWeight="700">
              Drill Settings
            </Typography>
            <IconButton sx={{ cursor: "default" }} size="small">
              <FontAwesomeIcon size="2xs" icon={faFilter} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            height: `calc(100% - 80px)`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SearchSourceWidgetDrillFields />
          <SearchSourceWidgetDrillCharts />
        </Box>
        <Box
          sx={{
            height: "48px",
            width: "100%",
            display: "flex",

            borderTop: theme.general.border1,
          }}
        >
          <Box
            sx={{
              p: theme.spacing(1),
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{ display: "flex", fontSize: "11px", gap: theme.spacing(1) }}
              size="small"
              onClick={() => handleDrillUp()}
              disabled={
                widget["widgetUuId"] !== drillModeWidgetUuId ? false : true
              }
            >
              <FontAwesomeIcon icon={faArrowUp} />
              DRILL UP
            </Button>
            <Button
              sx={{ display: "flex", fontSize: "11px", gap: theme.spacing(1) }}
              size="small"
              variant="contained"
              onClick={() => handleDrillDown()}
              disabled={
                _.isArray(drillWidgetFieldIds) &&
                drillWidgetFieldIds.length > 0 &&
                drillWidgetChartId &&
                drillWidgetChartId.length > 0
                  ? false
                  : true
              }
            >
              DRILL DOWN
              <FontAwesomeIcon icon={faArrowDown} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
