import React, { useEffect, useState } from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, Drawer, Typography, useTheme } from "@mui/material";
import { clearStatus } from "./store/sourceWidgetsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiSourceViewWidget from "@ntai/components/widgets/source-view/NtaiSourceViewWidget";
import SourceWidgetFormDialog from "./forms/SourceWidgetFormDialog";
import NtaiChartIcons from "utils/NtaiChartIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartMixed } from "@fortawesome/pro-light-svg-icons";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourceWidgetForm from "./forms/SourceWidgetForm";
const _ = require("lodash");

export default function SourceWidget(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    disableEdit,
    sourceWidget,
    handleSave,
    headerActions,
    handleHeaderAction,
  } = props;

  const status = useSelector(
    (state) => state.sources.sourceWidgetsSlice.status
  );

  function handleDrawerOpen(id) {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleNoEdit(id) {}
  useEffect(() => {
    if (status.result === "success" && status.method === "updateSourceWidget") {
      dispatch(clearStatus());
      // handleDrawerClose();
    }
  }, [status]);

  return (
    <>
      <NtaiSourceViewWidget
        id={sourceWidget.uuId}
        // height="120px"
        // width="180px"
        avatar={
          <FontAwesomeIcon
            icon={
              NtaiChartIcons.hasOwnProperty(
                sourceWidget.widget["name"].toLowerCase()
              )
                ? NtaiChartIcons[sourceWidget.widget["name"].toLowerCase()]
                : faChartMixed
            }
          />
        }
        title={NtaiUtils.trunc(sourceWidget.name, 15)}
        handleAction={
          props.hasOwnProperty("disableEdit") && disableEdit
            ? handleNoEdit
            : handleDrawerOpen
        }
        handleHeaderAction={handleHeaderAction}
        headerActions={headerActions}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {Array.isArray(sourceWidget.sourceWidgetFields) &&
            sourceWidget.sourceWidgetFields.length > 0 &&
            _.orderBy(sourceWidget.sourceWidgetFields, ["fieldOrder"], ["asc"])
              .slice(
                0,
                sourceWidget.sourceWidgetFields.length > 3
                  ? 3
                  : sourceWidget.sourceWidgetFields.length
              )
              .map((sourceWidgetField, index) => {
                return (
                  <Typography key={`widget-field-${index}`} variant="subtitle2">
                    {sourceWidgetField.fieldName}
                  </Typography>
                );
              })}
          {sourceWidget.sourceWidgetFields.length > 3 && (
            <Typography variant="caption">
              [+] {sourceWidget.sourceWidgetFields.length - 3} more
            </Typography>
          )}
        </Box>
      </NtaiSourceViewWidget>

      <Drawer
        open={open}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            position: "absolute",
            width: "720px",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        <NtaiForm>
          <SourceWidgetForm
            handleSave={handleSave}
            handleCancel={handleDrawerClose}
            widget={sourceWidget}
          />
        </NtaiForm>
      </Drawer>

      {/* {open && (
        <SourceWidgetFormDialog
          open={open}
          sourceWidget={sourceWidget}
          handleDialogClose={handleDialogClose}
          handleSave={handleSave}
        />
      )} */}
    </>
  );
}
