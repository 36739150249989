import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { getSourceDefinitions } from "app/main/pages/core/sourcedefinition/store/sourceDefinitionsSlice";
import NtaiFileUpload from "@ntai/components/upload/NtaiFileUpload";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import { useState } from "react";

import {
  clearStatus,
  uploadReportDefinitionTemplate,
} from "./store/reportDefinitionsSlice";
import ReportDefinitionParamWrapper from "./param/ReportDefinitionParamWrapper";
import { getReportDefinitionCategories } from "../category/store/reportDefinitionCategoriesSlice";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function getSourceOptions(sourceDefinitionsData) {
  let result = [];

  if (_.isArray(sourceDefinitionsData)) {
    sourceDefinitionsData.forEach((sd) => {
      if (_.isArray(sd.sourceMins))
        _.orderBy(sd.sourceMins, ["displayName"], ["asc"]).forEach((s) => {
          result.push({
            value: s.uuId,
            label: s.displayName,
          });
        });
    });
  }

  return result;
}

export default function ReportDefinitionForm({
  action,
  formData,
  handleSave,
  reportDefinitionType,
}) {
  const {
    getValues,
    reset,

    formState: { errors },
  } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const reportDefinitionCategoriesDataMap = useSelector(
    (state) => state.reports.reportDefinitionCategoriesSlice.data
  );

  const reportDefinitionCategoriesSliceStatus = useSelector(
    (state) => state.reports.reportDefinitionCategoriesSlice.status
  );

  const reportCategoryOptions = reportDefinitionCategoriesDataMap
    ? NtaiUtils.getSelectOptions(
        Object.values(reportDefinitionCategoriesDataMap),
        "uuId",
        "name"
      )
    : [];

  const reportDocFormatOptions = _.has(
    codelistData,
    CODELIST_CODES.GL_REPORT_DOCUMENT_FORMAT_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.GL_REPORT_DOCUMENT_FORMAT_CODE)
    : [];

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionsSlice.data
  );
  const activeId = useSelector(
    (state) => state.reports.reportDefinitionsSlice.activeId
  );

  const status = useSelector(
    (state) => state.reports.reportDefinitionsSlice.status
  );

  const data = Object.values(dataMap);

  const sourceDefinitionsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionsData = sourceDefinitionsDataMap
    ? Object.values(sourceDefinitionsDataMap)
    : [];

  const sourceOptions = getSourceOptions(sourceDefinitionsData);

  const sourceFilterSchemesDataMap = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.data
  );

  const sourceFilterSchemesData = !_.isEmpty(sourceFilterSchemesDataMap)
    ? Object.values(sourceFilterSchemesDataMap)
    : [];

  const sourceFilterSchemeOptions = NtaiUtils.getSelectOptions(
    sourceFilterSchemesData,
    "uuId",
    "name"
  );

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("reportDefinitionForm")
      );
      sanitizedFormData["typeCode"] =
        action === "add" ? reportDefinitionType : _.get(formData, "typeCode");
      handleSave(sanitizedFormData);
    }
  }

  function handleCancel() {
    history.push(`/reports/definitions`);
  }

  function handleFileUpload(formData, config) {
    dispatch(
      uploadReportDefinitionTemplate({
        uuId: activeId,
        formData: formData,
        config: config,
      })
    );
  }

  function displayUploadedFile() {
    return (
      <NtaiLabelValue
        label="Uploaded File"
        value={_.get(formData, "templateFileName")}
      />
    );
  }

  useEffect(() => {
    if (status && status.method === "uploadReportDefinitionTemplate") {
      if (status.result === "pending") setPending(true);
      if (status.result === "success") {
        setPending(false);
        dispatch(clearStatus());
      }
    }
  }, [status]);

  useEffect(() => {
    reset({ reportDefinitionForm: action === "edit" ? formData : {} });
  }, []);

  useEffect(() => {
    if (
      ["1", "3"].includes(reportDefinitionType) ||
      ["1", "3"].includes(_.get(formData, "typeCode"))
    )
      dispatch(getSourceDefinitions());

    dispatch(getReportDefinitionCategories());
    dispatch(getCodes(CODELIST_CODES.GL_REPORT_DOCUMENT_FORMAT_CODE));
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <Box sx={{ width: "50%", display: "flex" }}>
          <NtaiFormPanel
            width="75%"
            header="General Description"
            subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <Box sx={{ flexBasis: "50%", display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: theme.spacing(2),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <NtaiTextField
                    name="reportDefinitionForm.name"
                    label="Name*"
                    placeholder="Name"
                    rules={{ required: "Name is required" }}
                  />
                </Box>

                <NtaiTextField
                  name="reportDefinitionForm.description"
                  multiline
                  minRows={2}
                  maxRows={2}
                  placeholder="Description"
                  label="Description*"
                  rules={{ required: "Description is required" }}
                />

                <NtaiSelectField
                  name="reportDefinitionForm.reportDefinitionGroupUuId"
                  options={reportCategoryOptions}
                  label="Report Group*"
                  rules={{ required: "Report group is required" }}
                />

                {((action === "add" &&
                  ["1", "3", "4"].includes(reportDefinitionType)) ||
                  (action === "edit" &&
                    ["1", "3", "4"].includes(_.get(formData, "typeCode")))) && (
                  <NtaiSelectField
                    name="reportDefinitionForm.sourceUuId"
                    options={sourceOptions}
                    label="Source*"
                    isDisabled={action === "edit" ? true : false}
                    rules={{ required: "Source is required" }}
                  />
                )}

                {((action === "add" &&
                  ["1", "3", "4"].includes(reportDefinitionType)) ||
                  (action === "edit" &&
                    [1, 3, 4].includes(_.get(formData, "typeCode")))) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    {((action === "add" &&
                      ["1"].includes(reportDefinitionType)) ||
                      (action === "edit" &&
                        [1].includes(_.get(formData, "typeCode")))) && (
                      <NtaiSelectField
                        name="reportDefinitionForm.criteriaTypeCode"
                        options={[
                          { value: 1, label: "Filter Scheme" },
                          { value: 2, label: "Query Builder" },
                        ]}
                        label="Criteria Type*"
                        isDisabled={action === "edit" ? true : false}
                        rules={{
                          required: "Source report criteria type is required",
                        }}
                      />
                    )}
                    <NtaiSelectField
                      name="reportDefinitionForm.sourceDatasourceTypeCode"
                      options={[
                        { value: 1, label: "Source Document (JSON)" },
                        { value: 2, label: "External Datasource (RDBMS)" },
                      ]}
                      label="Source Datasource Type*"
                      isDisabled={action === "edit" ? true : false}
                      rules={{
                        required: "Source input datasource type is required",
                      }}
                    />
                    <NtaiSelectField
                      name="reportDefinitionForm.documentFormatCode"
                      options={reportDocFormatOptions}
                      label="Report Format*"
                      isDisabled={action === "edit" ? true : false}
                      rules={{
                        required: "Report format is required",
                      }}
                    />
                  </Box>
                )}
                {((action === "add" &&
                  ["1", "3", "4"].includes(reportDefinitionType)) ||
                  (action === "edit" &&
                    [1, 3, 4].includes(_.get(formData, "typeCode")))) && (
                  <NtaiTextField
                    name="reportDefinitionForm.extParamName"
                    label="External Parameter Name*"
                    isDisabled={action === "edit" ? true : false}
                    rules={{
                      required: "External parameter name is required",
                    }}
                  />
                )}
              </Box>
            </Box>
          </NtaiFormPanel>
        </Box>

        {action === "edit" && (
          <Box
            sx={{
              display: "flex",
              flexBasis: "50%",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <NtaiPanel
              width="100%"
              header="Upload File"
              subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
            >
              <NtaiFileUpload
                pending={pending}
                handleFileUpload={handleFileUpload}
              />
              {displayUploadedFile()}
            </NtaiPanel>
          </Box>
        )}
      </Box>
      <Box
        sx={{ height: "100%" }}
        label="PARAMETERS"
        disabled={
          action === "add" ||
          (action === "edit" && _.get(formData, "criteriaTypeCode") === 2)
        }
      >
        <ReportDefinitionParamWrapper />
      </Box>
    </NtaiTabs>
  );
}
