import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";

const _ = require("lodash");

export default function SourceInXmlDatasetForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceInXmlDatasetForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ sourceInXmlDatasetForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="sourceInXmlDatasetForm.rootTag"
          label="Root tag"
          rules={{ required: "Root tag  is required" }}
        />

        <NtaiTextField
          name="sourceInXmlDatasetForm.rowTag"
          label="Row tag"
          rules={{ required: "Row tag is required" }}
        />

        <NtaiTextField
          name="sourceInXmlDatasetForm.datasetName"
          label="Dataset Name"
          rules={{
            required: "Dataset name is required",
            validate: NtaiUtils.isValidSparkDatasetName,
          }}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
