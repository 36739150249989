import { Box, Link, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect } from "react";
import history from "@ntai/@history";
import SourceCatalogAssetWidget from "./SourceCatalogAssetWidget";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSourceCatalog,
} from "./asset/store/sourceCatalogSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const catalog_home_widgets = [
  {
    id: "0",
    header: "All Assets",
    subheader: "numOfAllAssets",
    footer: "All assets generated during source store",
  },
  {
    id: "1",
    header: "Sources",
    subheader: "numOfSourceDefinitions",
    footer: "All distinct source definitions with approved pipeline",
  },
  {
    id: "2",
    header: "Source Versions",
    subheader: "numOfSourceVersions",
    footer: "All source versions belonging to source definitions",
  },
  {
    id: "3",
    header: "Datasets and Tables",
    subheader: "numOfSourceOutStoreDatasets",
    footer: "All datasets and tables used during final store",
  },
  {
    id: "4",
    header: "Source Fields",
    subheader: "numOfSourceOutStoreDatasetFields",
    footer: "All source fields from final datasets and tables",
  },
];

export default function SourceCatalogHome() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loaded, setLoaded] = React.useState(false);

  const data = useSelector((state) => state.sources.sourceCatalogSlice.data);

  const status = useSelector(
    (state) => state.sources.sourceCatalogSlice.status
  );

  function handleClick(id) {
    history.push(`/source-catalog/assets/${id}`);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceCatalog" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceCatalog());
  }, []);

  return (
    <NtaiPage
      title="Source Catalog"
      subheader="More information"
      back="/sourcedefs"
    >
      {loaded && (
        <NtaiPanel
          width="80%"
          header="Overview"
          subheader="A Data Catalog is a collection of metadata, combined with data management and search tools, that helps analysts and other data users to find the data that they need, serves as an inventory of available data, and provides information to evaluate fitness of data for intended uses."
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              gap: theme.spacing(2),
            }}
          >
            {catalog_home_widgets.map((chw, i) => (
              <SourceCatalogAssetWidget
                id={chw["id"]}
                header={chw["header"]}
                subheader={data[chw["subheader"]]}
                footer={chw["footer"]}
                handleClick={handleClick}
              />
            ))}
          </Box>

          <Link
            id={`catalog-assets-link`}
            color={blue[700]}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={(e) => history.push("/source-catalog/assets/0")}
          >
            VIEW ALL ASSETS
          </Link>
        </NtaiPanel>
      )}

      {!loaded && <NtaiCircularProgress size={24} />}
    </NtaiPage>
  );
}
