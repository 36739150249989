import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordLookups = createAsyncThunk(
  "searchSourceRecordLookups/getSearchSourceRecordLookups",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, recordId, lookupLinkId, params } =
        values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${recordId}/lookup-links/${lookupLinkId}/records`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordLookup = createAsyncThunk(
  "searchSourceRecordLookups/getSearchSourceRecordLookup",
  async (searchSourceRecordLookupUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/cdo/searchSourceRecordLookups/${searchSourceRecordLookupUuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecordLookup = createAsyncThunk(
  "searchSourceRecordLookups/createSearchSourceRecordLookup",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post(
        "/cdo/searchSourceRecordLookups",
        values
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecordLookup = createAsyncThunk(
  "searchSourceRecordLookups/updateSearchSourceRecordLookup",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/cdo/searchSourceRecordLookups/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceRecordLookup = createAsyncThunk(
  "searchSourceRecordLookups/deleteSearchSourceRecordLookup",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(
        `/cdo/searchSourceRecordLookups/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordLookupsSlice = createSlice({
  name: "searchSourceRecordLookups",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceRecordLookups.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "recordId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordLookups",
      };
    },
    [getSearchSourceRecordLookups.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordLookups",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordLookup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordLookup",
      };
    },
    [getSearchSourceRecordLookup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordLookup",
        message: action.payload.message,
      };
    },
    [createSearchSourceRecordLookup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceRecordLookup",
      };
    },
    [createSearchSourceRecordLookup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecordLookup",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecordLookup.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceRecordLookup",
      };
    },
    [updateSearchSourceRecordLookup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecordLookup",
        message: action.payload.message,
      };
    },
    [deleteSearchSourceRecordLookup.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceRecordLookup",
      };
    },
    [deleteSearchSourceRecordLookup.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceRecordLookup",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceRecordLookupsSlice.actions;

export default searchSourceRecordLookupsSlice.reducer;
