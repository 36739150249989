import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getLinkedSourceFields = createAsyncThunk(
  "linkedSourceFields/getLinkedSourceFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceLinkedSourceFields = createAsyncThunk(
  "sourceLinkedSourceFields/getSourceLinkedSourceFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, linkedSourceId } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceLinkedSourceField = createAsyncThunk(
  "sourceLinkedSourceFields/getSourceLinkedSourceField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, linkedSourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceLinkedSourceField = createAsyncThunk(
  "sourceLinkedSourceFields/createSourceLinkedSourceField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, linkedSourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceLinkedSourceField = createAsyncThunk(
  "sourceLinkedSourceFields/updateSourceLinkedSourceField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, linkedSourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceLinkedSourceField = createAsyncThunk(
  "sourceLinkedSourceFields/deleteSourceLinkedSourceField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, linkedSourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/linkedsources/${linkedSourceId}/fields/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceLinkedSourceFieldsSlice = createSlice({
  name: "sourceLinkedSourceFields",
  initialState: {
    data: {},
    linkedSourceFields: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getLinkedSourceFields.fulfilled]: (state, action) => {
      state.linkedSourceFields = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getLinkedSourceFields",
      };
    },
    [getLinkedSourceFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getLinkedSourceFields",
        message: action.payload.message,
      };
    },

    [getSourceLinkedSourceFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceLinkedSourceFields",
      };
    },
    [getSourceLinkedSourceFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceLinkedSourceFields",
        message: action.payload.message,
      };
    },
    [getSourceLinkedSourceField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceLinkedSourceField",
      };
    },
    [getSourceLinkedSourceField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceLinkedSourceField",
        message: action.payload.message,
      };
    },
    [createSourceLinkedSourceField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "createSourceLinkedSourceField",
      };
    },
    [createSourceLinkedSourceField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceLinkedSourceField",
        message: action.payload.message,
      };
    },
    [updateSourceLinkedSourceField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceLinkedSourceField",
      };
    },
    [updateSourceLinkedSourceField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceLinkedSourceField",
        message: action.payload.message,
      };
    },
    [deleteSourceLinkedSourceField.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceLinkedSourceField",
      };
    },
    [deleteSourceLinkedSourceField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceLinkedSourceField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceLinkedSourceFieldsSlice.actions;

export default sourceLinkedSourceFieldsSlice.reducer;
