import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightNavigationTree = createAsyncThunk(
  "oversightNavigation/getOversightNavigationTree",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get(`/oversight/navigation`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const selectOversightNavigationTopic = createAsyncThunk(
  "oversightNavigation/selectOversightNavigationTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { dataUuId, topicUuId, params } = values;
      const response = await server.get(
        `/oversight/navigation/${dataUuId}/topics/${topicUuId}`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightNavigationSlice = createSlice({
  name: "oversightNavigation",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightNavigationTree.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = {
        result: "success",
        method: "getOversightNavigationTree",
      };
    },
    [getOversightNavigationTree.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightNavigationTree",
        message: action.payload.message,
      };
    },

    [selectOversightNavigationTopic.fulfilled]: (state, action) => {
      state.activeId = action.payload ? action.payload : "*";
      state.status = {
        result: "success",
        method: "selectOversightNavigationTopic",
      };
    },
    [selectOversightNavigationTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "selectOversightNavigationTopic",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = oversightNavigationSlice.actions;

export default oversightNavigationSlice.reducer;
