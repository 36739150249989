import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordSourceDocs = createAsyncThunk(
  "searchSourceRecordSourceDocs/getSearchSourceRecordSourceDocs",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, recordId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${recordId}/url-objects`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordSourceDoc = createAsyncThunk(
  "searchSourceRecordSourceDocs/getSearchSourceRecordSourceDoc",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, recordId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${recordId}/url-objects/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordSourceDocsSlice = createSlice({
  name: "searchSourceRecordCustomDocs",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceRecordSourceDocs.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "sourceFieldUuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordSourceDocs",
      };
    },
    [getSearchSourceRecordSourceDocs.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordSourceDocs",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordSourceDoc.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.sourceFieldUuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordSourceDoc",
      };
    },
    [getSearchSourceRecordSourceDoc.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordSourceDoc",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceRecordSourceDocsSlice.actions;

export default searchSourceRecordSourceDocsSlice.reducer;
