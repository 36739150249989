import { Box, Button, useTheme } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect, useState } from "react";
import ObjectFormGeneral from "./general/ObjectFormGeneral";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createObjectForm,
  getObjectForms,
  setActiveId,
  updateObjectForm,
} from "./store/objectFormsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import ObjectFormBuilder from "./ObjectFormBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2 } from "@fortawesome/pro-light-svg-icons";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import ObjectFormBuilderPreview from "./preview/ObjectFormBuilderPreview";
const _ = require("lodash");

export default function ObjectFormBuilderWrapper({ handleCancel }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loaded, setLoaded] = React.useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);

  const objectFormData = useSelector(
    (state) => state.core.objectFormsSlice.data
  );

  const status = useSelector((state) => state.core.objectFormsSlice.status);

  function handlePreviewDialogOpen() {
    setPreviewDialogOpen(true);
  }

  function handlePreviewDialogClose() {
    setPreviewDialogOpen(false);
  }

  function handleSave(formData) {
    if (formData) {
      if (formData["uuId"] && formData["uuId"].length > 0) {
        dispatch(
          updateObjectForm({
            domainId: domainId,
            objectId: objectId,
            uuId: formData["uuId"],
            formData: formData,
          })
        );
      } else {
        dispatch(
          createObjectForm({
            domainId: domainId,
            objectId: objectId,
            formData: formData,
          })
        );
      }
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getObjectForms" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
      dispatch(setActiveId(objectFormData ? objectFormData["uuId"] : null));
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getObjectForms({
        domainId: domainId,
        objectId: objectId,
      })
    );
  }, [objectId]);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiPanel
          width="100%"
          header="Object form"
          subheader="Design form to support data entry on object entities using form elements such as tab, panel and UI form fields supported by object fields"
          headerActions={
            <Button
              variant="outlined"
              sx={{
                fontSize: "11px",
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
              onClick={() => handlePreviewDialogOpen()}
            >
              <FontAwesomeIcon size="lg" icon={faGrid2} />
              PREVIEW
            </Button>
          }
        >
          <NtaiTabs>
            <Box label="GENERAL">
              <ObjectFormGeneral
                handleSave={handleSave}
                handleCancel={handleCancel}
              />
            </Box>
            <Box
              label="FORM BUILDER"
              sx={{ display: "flex", height: "100%" }}
              visible={
                objectFormData && _.has(objectFormData, "uuId") ? true : false
              }
            >
              <ObjectFormBuilder />
            </Box>
          </NtaiTabs>
        </NtaiPanel>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}

      <NtaiDialog
        open={previewDialogOpen}
        handleDialogClose={handlePreviewDialogClose}
        title={`Preview Form - ${objectFormData["name"]}`}
        size="md"
      >
        <ObjectFormBuilderPreview handleCancel={handlePreviewDialogClose} />
      </NtaiDialog>
    </React.Fragment>
  );
}
