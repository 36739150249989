import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { useDispatch, useSelector } from "react-redux";
import { createDataset, clearStatus } from "./store/datasetsSlice";
import DatasetForm from "./DatasetForm";
import { useEffect } from "react";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DatasetCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.models.datasetsSlice.data);
  const status = useSelector((state) => state.models.datasetsSlice.status);
  const activeId = useSelector((state) => state.models.datasetsSlice.activeId);

  function handleSave(formData) {
    dispatch(createDataset(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createDataset" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Dataset "${_.get(dataMap[activeId], "name")}" created successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
      history.push(`/models/datasets/${activeId}/edit`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Dataset">
      <NtaiForm>
        <DatasetForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
