import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SourceMasterTemplateGeneralForm from "./SourceMasterTemplateGeneralForm";
import SourceMasterTemplateAggregate from "./SourceMasterTemplateAggregate";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import SourceMasterTemplateViews from "./SourceMasterTemplateViews";
import SourceMasterTemplateAssessmentForms from "./SourceMasterTemplateAssessmentForms";
import SourceMasterTemplateRecordAccess from "./SourceMasterTemplateRecordAccess";
import SourceMasterTemplateRecordTemplates from "./SourceMasterTemplateRecordTemplates";
import SourceMasterTemplateReportTemplates from "./SourceMasterTemplateReportTemplates";
const _ = require("lodash");

export default function SourceMasterTemplateForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourcesSlice.activeId
  );
  const sourceId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceMasterTemplateForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ sourceMasterTemplateForm: action === "edit" ? formData : {} });
    dispatch(getCodes(CODELIST_CODES.COR_ROLE_PRIV_GRANT_TYPE_CODE));
  }, []);

  return (
    <NtaiFormPanel
      width="100%"
      header="Master Template"
      subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTabs>
        <Box label="GENERAL">
          <SourceMasterTemplateGeneralForm />
        </Box>
        <Box label="AGGREGATE ACCESS">
          <SourceMasterTemplateAggregate />
        </Box>
        <Box label="RECORD ACCESS">
          <SourceMasterTemplateRecordAccess />
        </Box>
        <Box label="VIEWS">
          <SourceMasterTemplateViews handleSave={handleSave} />
        </Box>
        <Box label="ASSESSMENT FORMS">
          <SourceMasterTemplateAssessmentForms handleSave={handleSave} />
        </Box>
        <Box label="RECORD TEMPLATES">
          <SourceMasterTemplateRecordTemplates handleSave={handleSave} />
        </Box>
        <Box label="REPORT TEMPLATES">
          <SourceMasterTemplateReportTemplates handleSave={handleSave} />
        </Box>
      </NtaiTabs>
    </NtaiFormPanel>
  );
}
