import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { OversightContext } from "../../../../../Oversight";
import {
  getObject,
  clearStatus as clearObjectsSliceStatus,
} from "app/main/pages/core/cdo/object/store/objectsSlice";
import {
  clearStatus,
  createOversightTopicAdj,
  getOversightTopicAdjs,
  setActiveId,
  updateOversightTopicAdj,
} from "./store/oversightTopicAdjsSlice";
import { useFormContext } from "react-hook-form";
import { useSnackbar } from "notistack";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { OVERSIGHT_SECTIONS } from "../../../../../constants/OversightConstants";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import Scrollbar from "@ntai/components/Scrollbar";
import { OversightTopicContext } from "../OversightTopicContent";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
const _ = require("lodash");

export default function OversightTopicAdj() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();
  const [load, setLoad] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { navNode, oversightTopicId, section, setReloadOversightTopicSummary } =
    React.useContext(OversightContext);

  const { topicSection } = React.useContext(OversightTopicContext);

  const formDataParams = navNode
    ? {
        dataUuId: navNode["parentUuId"],
        objectTopicUuId:
          navNode["type"] === "OBJECT_TOPIC" ? navNode["uuId"] : null,
        dataTopicUuId:
          navNode["type"] === "DATA_TOPIC" ? navNode["uuId"] : null,
      }
    : null;

  const objectDataMap = useSelector((state) => state.core.objectsSlice.data);

  const adjObject = _.get(
    objectDataMap,
    _.get(navNode, "topicAssessFormObjectUuId")
  );

  const objectsSliceStatus = useSelector(
    (state) => state.core.objectsSlice.status
  );

  const oversightTopicAdjDataMap = useSelector(
    (state) => state.oversight.oversightTopicAdjsSlice.data
  );

  const oversightTopicAdjData =
    oversightTopicAdjDataMap &&
    _.isArray(Object.values(oversightTopicAdjDataMap)) &&
    Object.values(oversightTopicAdjDataMap).length > 0
      ? _.filter(Object.values(oversightTopicAdjDataMap), { latestFg: 1 })[0]
      : null;

  const oversightTopicAdjId = _.get(oversightTopicAdjData, "uuId");

  const status = useSelector(
    (state) => state.oversight.oversightTopicAdjsSlice.status
  );

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("oversightTopicAdjForm")
    );

    const revisedFormData = ObjectFormUtil.generateFormSubmissionData(
      sanitizedFormData,
      adjObject
    );

    const parentProps = {
      oversightTopicId: oversightTopicId,
    };

    const finalFormData = {
      ...formDataParams,
      adjudication: {
        moduleCode: 3,
        clientData: {
          uuId: _.get(oversightTopicAdjData, "adjudication.clientData.uuId"),
          objectUuId: _.get(adjObject, "uuId"),
          ...revisedFormData,
        },
      },
    };

    if (oversightTopicAdjId) {
      dispatch(
        updateOversightTopicAdj({
          ...parentProps,
          uuId: oversightTopicAdjId,
          formData: finalFormData,
        })
      );
    } else {
      dispatch(
        createOversightTopicAdj({
          ...parentProps,
          formData: finalFormData,
        })
      );
    }
  }

  function handleCancel() {}

  // useEffect(() => {
  //   console.log("get nav details for oversight topic id: ", oversightTopicId);
  // }, [oversightTopicId]);

  useEffect(() => {
    if (
      topicSection === OVERSIGHT_SECTIONS.ASSESSMENTS &&
      _.get(navNode, "topicAssessFormObjectUuId")
    )
      dispatch(
        getObject({
          domainId: "*",
          uuId: _.get(navNode, "topicAssessFormObjectUuId"),
        })
      );
  }, [navNode]);

  useEffect(() => {
    if (topicSection === OVERSIGHT_SECTIONS.ASSESSMENTS)
      dispatch(
        getOversightTopicAdjs({
          oversightTopicId: oversightTopicId,
          params: {
            fetchType: "latest",
          },
        })
      );
  }, [oversightTopicId]);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getOversightTopicAdjs"
    ) {
      if (oversightTopicAdjData) {
        reset({
          oversightTopicAdjForm: ObjectFormUtil.populateFormData(
            _.get(oversightTopicAdjData, "adjudication.clientData")
          ),
        });
        dispatch(setActiveId(_.get(oversightTopicAdjData, "uuId")));
      } else {
        reset({ oversightTopicAdjForm: {} });
      }
      setLoad(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createOversightTopicAdj" ||
        status.method === "updateOversightTopicAdj")
    ) {
      setEditMode(false);
      enqueueSnackbar("Record updated successfully.", {
        variant: "success",
      });

      reset({
        oversightTopicAdjForm: _.get(
          oversightTopicAdjData,
          "adjudication.clientData"
        )
          ? ObjectFormUtil.populateFormData(
              _.get(oversightTopicAdjData, "adjudication.clientData")
            )
          : {},
      });

      dispatch(clearStatus());

      if (status.method === "createOversightTopicAdj")
        setReloadOversightTopicSummary(true);
    }
  }, [status]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: `calc(100% - 54px)`,
        }}
      >
        {adjObject && (
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(2),
                p: theme.spacing(2),
                // p: theme.spacing(2),
                // border: theme.general.border1,
                // borderRadius: theme.general.borderRadius,
              }}
            >
              {adjObject &&
                !_.isEmpty(adjObject["objectForm"]) &&
                ObjectFormUtil.generateForm(
                  "oversightTopicAdjForm",
                  adjObject["objectForm"],
                  editMode,
                  theme
                )}
            </Box>
          </Scrollbar>
        )}
        {!_.get(navNode, "topicAssessFormObjectUuId") && (
          <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
            <NtaiEmptyMessage header="No assessment form available for the selected topic" />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "54px",
          width: "100%",
          alignItems: "center",
          px: theme.spacing(2),
          gap: theme.spacing(1),
          justifyContent: "start",
          borderTop: theme.general.border1,
        }}
      >
        {editMode && (
          <Button
            sx={{ fontSize: "11px" }}
            size="small"
            onClick={() => setEditMode(false)}
          >
            CANCEL
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ fontSize: "11px" }}
          onClick={() => (editMode ? handleSave() : setEditMode(true))}
        >
          {editMode ? "SAVE" : "EDIT"}
        </Button>
      </Box>
    </Box>
  );
}
