import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useState } from "react";

export default function SearchDashboardCompactBox(props) {
  const theme = useTheme();
  const {
    id,
    width,
    height,
    title,
    subheader,
    hits,
    avatar,
    description,
    handleSelect,
    selectedItems,
  } = props;

  const [selected, setSelected] = useState(false);

  function onHandleSelect() {
    setSelected(!selected);
    handleSelect(id);
  }

  return (
    <Card
      sx={{
        p: 0,
        border: selectedItems.has(id)
          ? theme.general.border2p
          : theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        height: height ? height : "110px",
        width: width ? width : "238px",
        boxShadow: theme.colors.shadows.cardSm,

        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
        },
      }}
      onClick={() => onHandleSelect()}
    >
      <CardHeader
        sx={{ py: 1 }}
        title={
          title ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" fontWeight="700" color="inherit">
                {NtaiUtils.trunc(title, 25)}
              </Typography>

              <Typography variant="subtitle1" color="inherit">
                {NtaiUtils.abbrevNumber(hits)}
              </Typography>
            </Box>
          ) : null
        }
        subheader={
          subheader ? (
            <Typography fontWeight="500" variant="subtitle2">
              {subheader}
            </Typography>
          ) : null
        }
      />

      <CardContent sx={{ py: 0 }}>
        <Typography variant="subtitle2">
          {NtaiUtils.trunc(description, 60)}
        </Typography>
      </CardContent>
    </Card>
  );
}
