import React, { useEffect, useState } from "react";
import { Box, Button, Link, Typography, styled, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import { faCloudUpload } from "@fortawesome/pro-light-svg-icons";
import { AlertEditContext } from "../../../../AlertEdit";
import { blue } from "@mui/material/colors";
import NtaiHelpText from "@ntai/components/helptext/NtaiHelpText";
const _ = require("lodash");

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AlertRecordDocumentForm({
  action,
  handleSave,
  handleDownload,
  handleCancel,
  formData,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const [selectedFile, setSelectedFile] = useState();
  const [displayFile, setDisplayFile] = useState();

  const { selectedRecordId } = React.useContext(AlertEditContext);

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("alertRecordDocumentForm.document")
    );

    const revisedFormData = {
      document: sanitizedFormData,
    };

    const fileUploadFormData = new FormData();
    fileUploadFormData.append("data", JSON.stringify(revisedFormData));
    fileUploadFormData.append("files", selectedFile);
    handleSave(fileUploadFormData);
  }

  function handleCapture(e) {
    setSelectedFile(e.target.files[0]);
  }

  useEffect(() => {
    if (selectedFile) {
      setDisplayFile({
        name: selectedFile["name"],
        size: selectedFile["size"],
      });
    }
  }, [selectedFile]);

  useEffect(() => {
    reset({
      alertRecordDocumentForm: action === "edit" ? formData : {},
    });

    if (action === "edit" && formData["document"]["title"]) {
      setDisplayFile({
        name: formData["document"]["title"],
        size: formData["document"]["fileSize"],
      });
    }
  }, [formData]);

  return (
    <NtaiFormPanel
      width="85%"
      header={`${action === "add" ? "Add" : "Edit"} Document`}
      subheader="Enter document title, description and upload file to attach with the record."
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      {action === "edit" && formData["bulkFg"] === 1 && (
        <NtaiHelpText header="This document was uploaded using Bulk Upload feature.">
          <Typography variant="subtitle2">
            This document was uploaded during bulk update. Use Batch Documents
            section to update the document
          </Typography>
        </NtaiHelpText>
      )}
      <NtaiTextField
        name="alertRecordDocumentForm.document.title"
        label="Title*"
        rules={{ required: "Document title is required" }}
        disabled={action === "edit" && formData["bulkFg"] === 1 ? true : false}
      />
      <NtaiTextField
        name="alertRecordDocumentForm.document.description"
        label="Description"
        multiline
        minRows={3}
        maxRows={3}
        disabled={action === "edit" && formData["bulkFg"] === 1 ? true : false}
      />
      <Box
        sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
      >
        <Button
          size="small"
          component="label"
          variant="outlined"
          disabled={
            action === "edit" && formData["bulkFg"] === 1 ? true : false
          }
          startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
        >
          Upload File
          <VisuallyHiddenInput type="file" onChange={(e) => handleCapture(e)} />
        </Button>
        {displayFile && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="subtitle1">
              <Link
                sx={{
                  fontWeight: "500",
                  color: "inherit",
                  color: blue[700],
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={(e) => handleDownload(formData["uuId"])}
              >
                {displayFile["name"]}
              </Link>
            </Typography>
            <Typography variant="subtitle1">
              ({NtaiUtils.bytesToSize(displayFile["size"])})
            </Typography>
          </Box>
        )}
      </Box>
    </NtaiFormPanel>
  );
}
