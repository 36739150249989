import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  createSearchSourceRecordSeries,
  updateSearchSourceRecordSeries,
  deleteSearchSourceRecordSeries,
  clearStatus,
  getSearchSourceRecordSeriesList,
} from "./store/searchSourceRecordSeriesSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SearchSourceRecordSeriesForm from "./SearchSourceRecordSeriesForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchSourceContext } from "../../SearchSource";
import { SearchContext } from "../../../SearchEdit";
import useSearchSourceWidgetTempFilters from "../../hooks/useSearchSourceWidgetTempFilters";
import { useSnackbar } from "notistack";
import {
  loadSearchSourceRecordSeries,
  unloadSearchSourceRecordSeries,
} from "../../store/searchSourcesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "totalCount",
    numeric: false,
    disablePadding: false,
    label: "# Records",
  },
  {
    id: "ownerName",
    numeric: false,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
    transformFunc: NtaiUtils.localDate,
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

// function formatDate(dateValue) {
//   if (dateValue !== null) {
//     return NtaiUtils.localDate(dateValue);
//   } else return null;
// }

export default function SearchSourceRecordSeriesList({ handleCancel }) {
  const dispatch = useDispatch();
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  // const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters();
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { enqueueSnackbar } = useSnackbar();

  const searchId = useSelector((state) => state.search.searchSlice.activeId);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordSeriesSlice.data
  );
  const status = useSelector(
    (state) => state.search.searchSourceRecordSeriesSlice.status
  );
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteSearchSourceRecordSeries({
            searchId: searchId,
            searchSourceId: searchSourceId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSearchSourceRecordSeries({
            searchId: searchId,
            searchSourceId: searchSourceId,
            uuId: currentId,
            formData: {
              ...formData,
              searchSourceWidgetFilters: searchSourceWidgetTempFilters,
            },
          })
        )
      : dispatch(
          createSearchSourceRecordSeries({
            searchId: searchId,
            searchSourceId: searchSourceId,
            formData: {
              ...formData,
              searchSourceWidgetFilters: searchSourceWidgetTempFilters,
            },
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleRowAction(actionName, uuId) {
    if (actionName === "load") {
      dispatch(
        loadSearchSourceRecordSeries({
          searchId: searchId,
          searchSourceId: searchSourceId,
          uuId: uuId,
        })
      );
    }
    if (actionName === "unload") {
      dispatch(
        unloadSearchSourceRecordSeries({
          searchId: searchId,
          searchSourceId: searchSourceId,
          uuId: uuId,
        })
      );
    }
    handleCancel();
  }

  useEffect(() => {
    dispatch(
      getSearchSourceRecordSeriesList({
        searchId: searchId,
        searchSourceId: searchSourceId,
      })
    );
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createSearchSourceRecordSeries" ||
        status.method === "updateSearchSourceRecordSeries")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSearchSourceRecordSeries"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }

    if (
      status.result === "error" &&
      (status.method === "createSearchSourceRecordSeries" ||
        status.method === "updateSearchSourceRecordSeries")
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <NtaiPanel
        width="100%"
        subheader="Manage series for records in the current search context. You can create, update, delete and load series. Load series action will refresh the search source view with records from the series."
      >
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          rowActions={[
            { value: "load", label: "Load" },
            { value: "unload", label: "Unload" },
          ]}
          handleRowAction={handleRowAction}
          title="Series"
        />
      </NtaiPanel>

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title={action === "add" ? `Create Series` : `Edit Series`}
        size="sm"
      >
        <NtaiForm>
          <SearchSourceRecordSeriesForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
