import { Box, useTheme, Typography } from "@mui/material";
import NtaiAddRecordCard from "@ntai/components/cards/NtaiAddRecordCard";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiActionsPanel from "@ntai/components/panels/NtaiActionsPanel";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import History from "@ntai/@history";
import {
  clearStatus,
  createSourcePipeline,
  deleteSourcePipeline,
  getSourcePipelines,
  setActiveId,
  updateSourcePipeline,
} from "./store/sourcePipelinesSlice";
import SourcePipelineForm from "./SourcePipelineForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function SourcePipelineList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);

  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector(
    (state) => state.sources.sourcePipelinesSlice.data
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  const status = useSelector(
    (state) => state.sources.sourcePipelinesSlice.status
  );

  function handleFormDialogOpen() {
    setOpenFormDialog(true);
  }

  function handleFormDialogClose() {
    setOpenFormDialog(false);
  }

  function handleAdd() {
    setAction("add");
    setOpenFormDialog(true);
  }

  function handleDelete() {
    dispatch(
      deleteSourcePipeline({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: currentId,
      })
    );
  }

  function manage(action, id) {
    dispatch(setActiveId(id));
    History.push(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${id}`
    );
  }

  function handleCancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction(null);
  }

  function handleHeaderAction(actionName, id) {
    if (actionName === "edit") {
      setCurrentId(id);
      setAction("edit");
      setOpenFormDialog(true);
    } else if (actionName === "delete") {
      setAction("delete");
      setCurrentId(id);
      setOpenConfirmDeleteDialog(true);
    }
  }

  function handleFormSubmit(formData) {
    if (action === "add")
      dispatch(
        createSourcePipeline({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateSourcePipeline({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  function renderItems() {
    let result = [];
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((item, index) => {
        result.push(
          <NtaiActionsPanel
            key={index}
            id={item.uuId}
            title={item.name}
            subheader={item.typeDecode}
            headerActions={[
              { value: "edit", label: "Edit" },
              { value: "delete", label: "Delete" },
            ]}
            handleHeaderAction={handleHeaderAction}
            width="320px"
            actions={[{ value: "manage", label: "Manage Pipeline" }]}
            handleAction={manage}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(1),
              }}
            >
              {item.description}
            </Box>
          </NtaiActionsPanel>
        );
      });

      return result;
    }
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourcePipelines" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourcePipeline" ||
        status.method === "updateSourcePipeline")
    ) {
      handleFormDialogClose();
      setCurrentId(null);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourcePipeline"
    ) {
      setOpenConfirmDeleteDialog(false);
      setCurrentId(null);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourcePipelines({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiPage
          title={`${_.get(sourceDefData, "name")} / Pipelines`}
          back={`/sourcedefs/${sourceDefId}/smwrapper`}
        >
          <NtaiPanel
            width="75%"
            header="Manage Pipelines"
            subheader="Source ins provide easy to use templates to render source data in user specified templates"
          >
            <Box
              sx={{
                paddingTop: theme.spacing(1),
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: theme.spacing(1),
              }}
            >
              <NtaiAddRecordCard
                handleClick={handleAdd}
                border
                width="320px"
                height="auto"
              >
                Create pipeline
              </NtaiAddRecordCard>
              {renderItems()}
            </Box>
          </NtaiPanel>
        </NtaiPage>
      )}
      {!loaded && <NtaiCircularProgress />}
      <NtaiDialog
        open={openFormDialog}
        handleDialogClose={handleFormDialogClose}
        title="Create Pipeline"
        size="sm"
      >
        <NtaiForm>
          <SourcePipelineForm
            action={action}
            handleDialogClose={handleFormDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        items={[_.get(dataMap[currentId], "name")]}
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={handleCancelDelete}
      />
    </React.Fragment>
  );
}
