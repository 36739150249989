import AwsClusterCreate from "./cluster/AwsClusterCreate";
import AwsClusterEdit from "./cluster/AwsClusterEdit";
import AwsClusterList from "./cluster/AwsClusterList";
import AwsClusterTemplateCreate from "./template/AwsClusterTemplateCreate";
import AwsClusterTemplateEdit from "./template/AwsClusterTemplateEdit";
import AwsClusterTemplateList from "./template/AwsClusterTemplateList";

const AwsConfig = {
  routes: [
    {
      path: "/aws-cluster-templates",
      exact: true,
      component: AwsClusterTemplateList,
    },
    {
      path: "/aws-cluster-templates/create",
      exact: true,
      component: AwsClusterTemplateCreate,
    },
    {
      path: "/aws-cluster-templates/:id/edit",
      exact: true,
      component: AwsClusterTemplateEdit,
    },
    {
      path: "/aws-clusters",
      exact: true,
      component: AwsClusterList,
    },
    {
      path: "/aws-clusters/create",
      exact: true,
      component: AwsClusterCreate,
    },
    {
      path: "/aws-clusters/:id/edit",
      exact: true,
      component: AwsClusterEdit,
    },
  ],
};

export default AwsConfig;
