import * as React from "react";
import { JSONObjectType } from "./JsonToTableUtils";
import JSONToTableUtils from "./JsonToTableUtils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import NtaiAccordion from "../accordions/NtaiAccordion";
import { ExpandMoreSharp } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltFromLeft,
  faArrowRight,
  faArrowRightArrowLeft,
} from "@fortawesome/pro-regular-svg-icons";
import Scrollbar from "../Scrollbar";
import { blue, grey } from "@mui/material/colors";
const _ = require("lodash");
// import styles from "./NtaiJsonToTable.module.css";

export default class NtaiJsonToTable1 extends React.Component {
  // constructor
  constructor(props, context) {
    super(props, context);
  }

  fetchLabel(fieldName) {
    const field = _.filter(this.props.fields, {
      name: fieldName,
    })[0];

    if (field) {
      return field.label;
    } else {
      return fieldName;
    }
  }

  processCellValue(value, fieldName) {
    const field = _.filter(this.props.fields, {
      name: fieldName,
    })[0];

    if (field && field["esDataTypeCode"] === 9) {
      return (
        <Link
          sx={{
            color: blue[700],
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={(e) => this.openLink(e, value)}
        >
          {value}
        </Link>
      );
    } else {
      return value;
    }
  }

  openLink(e, link) {
    e.preventDefault();
    const urlPath = window.location.protocol + "//" + window.location.host;
    window.open(
      urlPath + "/images/AER.pdf",
      "_blank",
      "width=620,height=520,location=no"
    );
  }

  render() {
    return (
      <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
        <Table key={`__j2t_root_table`}>
          <TableBody key={`__j2t_root_tbody`}>
            {this.renderObject(this.props.json, undefined, 0)}
          </TableBody>
        </Table>
      </Box>
    );
  }

  renderObject = (obj, header, idx) => {
    const phrase = [];
    let tmp;
    const objType = JSONToTableUtils.getObjectType(obj);

    if (header && objType !== JSONObjectType.Array) {
      phrase.push(this.renderRowHeader(header));
    }

    switch (objType) {
      case JSONObjectType.ObjectWithNonNumericKeys:
        tmp = header ? (
          <Table key={`__j2t_tableObj${idx}`}>
            <TableBody key={`__j2t_bObj${idx}`}>
              {this.renderRows(obj)}
            </TableBody>
          </Table>
        ) : (
          this.renderRows(obj)
        );
        break;
      case JSONObjectType.Array:
        tmp = header ? (
          <Accordion
            sx={{
              p: 0,
            }}
            expanded={true}
          >
            <AccordionSummary
              sx={{
                py: 0,
                px: "4px",
                background: grey[50],
              }}
              expandIcon={<ExpandMoreSharp />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "default", fontWeight: 700 }}
              >
                {_.upperCase(header)} ({obj.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table key={`__j2t_tableArr${idx}`}>
                <TableBody key={`__j2t_bArr${idx}`}>
                  {this.parseArray(obj)}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ) : (
          this.parseArray(obj)
        );
        break;
    }
    phrase.push(tmp);
    const retval = phrase.map((p) => p);
    return header ? (
      <TableRow key={`__j2t_trObj${idx}`}>
        {this.renderCell({ content: retval, colspan: 2 })}
      </TableRow>
    ) : (
      retval
    );
  };

  getCellValue = (content) => {
    const valueDisplay =
      content === true || content === false ? content.toString() : content;

    return valueDisplay;
  };

  renderCell = (params) => {
    const { content, colspan, isHeader } = params;

    const valueDisplay = isHeader ? (
      <strong>
        <div style={{ "word-break": "break-word" }}>
          {this.fetchLabel(this.getCellValue(content))}
        </div>
      </strong>
    ) : (
      this.getCellValue(content)
    );
    return (
      <TableCell
        colSpan={colspan ? colspan : 0}
        key={`__j2t_trObj${valueDisplay}`}
      >
        {valueDisplay}
      </TableCell>
    );
  };

  renderHeader = (labels) => {
    return (
      <TableRow sx={{ w: "100%" }} key={`__j2t_trHeader`}>
        {labels.map((v) => {
          return this.renderCell({ content: v, isHeader: true });
        })}
      </TableRow>
    );
  };

  // renderValues = (values) => {
  //   return (
  //     <TableRow key={`__j2t_trArrString`}>
  //       {values.map((k) => {
  //         return this.renderCell({ content: k });
  //       })}
  //     </TableRow>
  //   );
  // };

  renderValues = (values) => {
    const result = [];
    values.forEach((v, i) => {
      result.push(
        <TableRow key={`__j2t_trArrString_${i}`}>
          <TableCell sx={{ width: "40%" }}></TableCell>
          {this.renderCell({ content: v })}
        </TableRow>
      );
    });

    return result;
  };

  renderRowValues = (anArray, labels) => {
    return anArray.map((item, idx) => {
      return (
        <TableRow key={`__j2t_Arr${idx.toString()}`}>
          {labels.map((k) => {
            const isValuePrimitive =
              JSONToTableUtils.getObjectType(item[k]) ===
              JSONObjectType.Primitive;
            return isValuePrimitive
              ? this.renderCell({ content: item[k] })
              : this.renderObject(item[k], k, idx);
          })}
        </TableRow>
      );
    });
  };

  parseArray = (anArray) => {
    const phrase = [];
    const labels = JSONToTableUtils.getUniqueObjectKeys(anArray);
    if (JSONToTableUtils.checkLabelTypes(labels.labels) !== "number") {
      phrase.push(this.renderHeader(labels.labels));
      phrase.push(this.renderRowValues(anArray, labels.labels));
    } else {
      phrase.push(this.renderValues(anArray));
    }
    return phrase;
  };

  renderRow = (k, v, idx) => {
    return (
      <TableRow key={`__j2t_tr${idx}`}>
        <TableCell
          sx={{ verticalAlign: "top", width: "25%", p: "8px" }}
          key={`__j2t_tdk${idx}`}
        >
          <Box sx={{ verticalAlign: "top", wordBreak: "break-word" }}>
            <strong>{this.fetchLabel(k)}</strong>
          </Box>
        </TableCell>
        <TableCell sx={{ p: "8px" }} key={`__j2t_tdv${idx}`}>
          <div style={{ "word-break": "break-word" }}>
            {this.processCellValue(v, k)}
          </div>
        </TableCell>
      </TableRow>
    );
  };

  renderRowHeader = (label) => {
    return (
      <Box key={`__j2t_rw${label}`}>
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
    );
  };

  renderRows = (obj, labelKey) => {
    return Object.keys(obj).map((k, idx) => {
      const value = obj[k];
      const isValuePrimitive =
        JSONToTableUtils.getObjectType(value) === JSONObjectType.Primitive;
      // render row when value is primitive otherwise inspect the value and make the key as header
      const retval = isValuePrimitive
        ? this.renderRow(k, value, idx)
        : this.renderObject(value, k, idx);

      return retval;
    });
  };
}
