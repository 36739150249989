import SourceAdjudicationCreate from "./SourceAdjudicationCreate";
import SourceAdjudicationEdit from "./SourceAdjudicationEdit";
import SourceAdjudicationList from "./SourceAdjudicationList";

const SourceAdjudicationConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/adjudications",
      exact: true,
      component: SourceAdjudicationList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/adjudications/create",
      exact: true,
      component: SourceAdjudicationCreate,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/adjudications/:id/edit",
      exact: true,
      component: SourceAdjudicationEdit,
    },
  ],
};

export default SourceAdjudicationConfig;
