import React from "react";
import {
  faEdit,
  faListCheck,
  faNote,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

export default function OversightSnapshots() {
  const theme = useTheme();
  return (
    <NtaiPanel
      width="75%"
      header="Snapshots (2)"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi"
    >
      <List sx={{ width: "100%", p: 0, border: theme.general.border1 }}>
        <ListItemButton sx={{ border: 0, borderRadius: 0 }}>
          <ListItemAvatar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(2),
              }}
            >
              <NtaiMuiBadge
                badgeContent={4}
                color="secondary"
                top={2}
                right={-2}
              >
                <FontAwesomeIcon size="md" icon={faEdit} />
              </NtaiMuiBadge>
              <NtaiMuiBadge
                badgeContent={4}
                color="secondary"
                top={2}
                right={-2}
              >
                <FontAwesomeIcon size="md" icon={faListCheck} />
              </NtaiMuiBadge>
              <NtaiMuiBadge
                badgeContent={4}
                color="secondary"
                top={2}
                right={-2}
              >
                <FontAwesomeIcon size="md" icon={faNote} />
              </NtaiMuiBadge>
            </Box>
          </ListItemAvatar>
          <ListItemText
            sx={{ pl: theme.spacing(4) }}
            primary={
              <Typography variant="subtitle1" fontWeight="700">
                My search items
              </Typography>
            }
            secondary="Lorem ipsum text adn more information "
          />

          <ListItemSecondaryAction sx={{ pr: theme.spacing(1) }}>
            <NtaiCheckboxField name={`packageForm.functionUuId`} edge="end" />
          </ListItemSecondaryAction>
        </ListItemButton>
        <Divider />
        <ListItemButton sx={{ border: 0, borderRadius: 0 }}>
          <ListItemAvatar>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(2),
              }}
            >
              <NtaiMuiBadge
                badgeContent={4}
                color="secondary"
                top={2}
                right={-2}
              >
                <FontAwesomeIcon size="md" icon={faEdit} />
              </NtaiMuiBadge>
              <NtaiMuiBadge
                badgeContent={4}
                color="secondary"
                top={2}
                right={-2}
              >
                <FontAwesomeIcon size="md" icon={faListCheck} />
              </NtaiMuiBadge>
              <NtaiMuiBadge
                badgeContent={4}
                color="secondary"
                top={2}
                right={-2}
              >
                <FontAwesomeIcon size="md" icon={faNote} />
              </NtaiMuiBadge>
            </Box>
          </ListItemAvatar>
          <ListItemText
            sx={{ pl: theme.spacing(4) }}
            primary={
              <Typography variant="subtitle1" fontWeight="700">
                My search items
              </Typography>
            }
            secondary="Lorem ipsum text adn more information "
          />

          <ListItemSecondaryAction sx={{ pr: theme.spacing(1) }}>
            <NtaiCheckboxField name={`packageForm.functionUuId`} edge="end" />
          </ListItemSecondaryAction>
        </ListItemButton>
        <Divider />
      </List>
    </NtaiPanel>
  );
}
