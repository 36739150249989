import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import history from "@ntai/@history";
import { useDispatch, useSelector } from "react-redux";
import { createSearch } from "../../store/searchSlice";
import SearchInitSourcesWrapper from "../sources/SearchInitSourcesWrapper";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  clearStatus,
  initializeSearch,
  previewSearch,
} from "../store/searchInitSlice";
import SearchBasicInitSuggest from "./SearchBasicInitSuggest";
import { useSnackbar } from "notistack";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export const SearchBasicInitContext = React.createContext();

const reviseQueryTerms = (qTerms) => {
  let result = [];
  if (Array.isArray(qTerms) && qTerms.length > 0) {
    qTerms.forEach((qTerm, index) => {
      if (typeof qTerm === "object") {
        result.push(qTerm);
      } else {
        result.push({
          term: qTerm,
        });
      }
    });
  }

  return result;
};

const getCriteria = (queryTerms) => {
  let result = [];
  if (Array.isArray(queryTerms) && queryTerms.length > 0) {
    queryTerms.forEach((queryTerm, index) => {
      if (typeof queryTerm === "object") {
        result.push(_.get(queryTerm, "term"));
      } else {
        result.push(queryTerm);
      }
    });
  }

  return result.join(" ");
};

export default function SearchBasicInit(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loaded, setLoaded] = useState(false);
  const [searchQueryTerms, setSearchQueryTerms] = useState([]);
  const [selectedSources, setSelectedSources] = useState(new Set([]));

  const data = useSelector((state) => state.search.searchInitSlice.data);
  const status = useSelector((state) => state.search.searchInitSlice.status);
  const statusSearch = useSelector((state) => state.search.searchSlice.status);

  const activeId = useSelector((state) => state.search.searchSlice.activeId);

  function handleSearch(queryTerms) {
    let searchSources = [];

    if (selectedSources && !_.isEmpty(selectedSources)) {
      selectedSources.forEach((uuId) => {
        searchSources.push({ searchSourceDefinition: { sourceUuId: uuId } });
      });
    }

    if (searchSources.length > 0) {
      dispatch(
        createSearch({
          typeCode: 1,
          searchQueryTerms: reviseQueryTerms(queryTerms),
          searchSources: searchSources,
          criteria: getCriteria(queryTerms),
        })
      );
    }
  }

  useEffect(() => {
    const revisedQueryTerms = reviseQueryTerms(searchQueryTerms);
    dispatch(previewSearch({ searchQueryTerms: revisedQueryTerms }));
  }, [searchQueryTerms]);

  useEffect(() => {
    if (
      statusSearch.result === "success" &&
      statusSearch.method === "createSearch"
    ) {
      history.push(`/search/${activeId}/edit`);
    }
  }, [statusSearch]);

  useEffect(() => {
    if (
      status &&
      status.result === "error" &&
      status.method === "initializeSearch"
    ) {
      // enqueueSnackbar("Error retrieving sources: " + status.message, {
      //   variant: "error",
      // });
      setLoaded(true);
      // dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "initializeSearch"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(initializeSearch({ type: "1" }));
  }, []);

  return (
    <SearchBasicInitContext.Provider
      value={{
        searchQueryTerms,
        reviseQueryTerms,
        setSearchQueryTerms,
        selectedSources,
        setSelectedSources,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(2),
            flexBasis: "5%",
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
            background: grey[50],
          }}
        >
          <NtaiButton
            onClick={() => history.push("/search")}
            size="small"
            icon="back"
          >
            Back to Listing
          </NtaiButton>
        </Box>

        <React.Fragment>
          <Box
            sx={{
              flexBasis: "30%",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: theme.general.border1,
              background: grey[50],
              pb: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "60%",
                flexDirection: "column",
                justifyContent: "start",
                gap: theme.spacing(1),
              }}
            >
              <Typography variant="h1" fontWeight="300">
                Begin your search
              </Typography>
              <Typography variant="body1" fontWeight="300">
                Enter one or more terms or phrases separated by AND or OR. Use
                suggest options to narrow or expand your search query.
              </Typography>
              <NtaiForm>
                <SearchBasicInitSuggest handleSearch={handleSearch} />
              </NtaiForm>
            </Box>
          </Box>

          {loaded &&
            _.has(data, "searchInitSources") &&
            _.isArray(data["searchInitSources"]) &&
            data["searchInitSources"].length > 0 && (
              <SearchInitSourcesWrapper mode="basic" />
            )}
          {loaded &&
            _.has(data, "searchInitSources") &&
            _.isArray(data["searchInitSources"]) &&
            data["searchInitSources"].length === 0 && (
              <Box
                sx={{
                  flexBasis: "65%",
                  display: "flex",
                }}
              >
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No records found"
                  subheader="No sources matched your query. Refine your search criteria or use different search mode."
                />
              </Box>
            )}

          {loaded && _.isEmpty(data) && (
            <Box
              sx={{
                flexBasis: "65%",
                display: "flex",
              }}
            >
              <NtaiEmptyMessage
                vAlign="center"
                header="No sources found"
                subheader="Ensure your administrator has assigned you sufficient source privileges"
              />
            </Box>
          )}
        </React.Fragment>

        {loaded && status && status.result === "error" && (
          <NtaiEmptyMessage
            vAlign="center"
            header="No sources found"
            subheader="No sources assinged or found. Ensure you have sufficient source privileges."
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </Box>
    </SearchBasicInitContext.Provider>
  );
}
