import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getMigrations = createAsyncThunk(
  "migrations/getMigrations",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/migrations");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMigration = createAsyncThunk(
  "migrations/getMigration",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/migrations/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const migrationsSlice = createSlice({
  name: "migrations",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getMigrations.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getMigrations" };
    },
    [getMigrations.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getMigrations",
        message: action.payload.message,
      };
    },
    [getMigration.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getMigration" };
    },
    [getMigration.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getMigration",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = migrationsSlice.actions;

export default migrationsSlice.reducer;
