import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiVerticalBarEChart from "./NtaiVerticalBarEChart";
import NtaiVerticalBarStackedEChart from "./NtaiVerticalBarStackedEChart";
import NtaiVerticalBarSeriesEChart from "./NtaiVerticalBarSeriesEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";

export default function NtaiTestVBar2() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);
  const [data, setData] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const barSeriesData = {
    category: {
      name: [
        "N/A",
        "Phase 1",
        "Phase 2",
        "Phase 4",
        "Phase 1/Phase 2",
        "Phase 3",
        "Early Phase 1",
        "Phase 2/Phase 3",
      ],
    },
    data: [
      {
        category: "N/A",
        series: [
          {
            name: "Completed",
            data: {
              value: 113,
              colorValue: 21,
              total: 113,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 21,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 38,
              colorValue: 21,
              total: 38,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 21,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 21,
              colorValue: 21,
              total: 21,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 21,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 11,
              colorValue: 21,
              total: 11,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 21,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 6,
              colorValue: 21,
              total: 6,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 21,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 3,
              colorValue: 21,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 21,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 3,
              colorValue: 21,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 21,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 1,
              colorValue: 21,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 21,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 1,
              colorValue: 21,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "N/A",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 21,
              },
            },
          },
        ],
      },
      {
        category: "Phase 1",
        series: [
          {
            name: "Completed",
            data: {
              value: 28,
              colorValue: 28,
              total: 28,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 28,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 0,
              colorValue: 28,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 28,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 1,
              colorValue: 28,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 28,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 3,
              colorValue: 28,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 28,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 1,
              colorValue: 28,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 28,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 0,
              colorValue: 28,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 28,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 3,
              colorValue: 28,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 28,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 0,
              colorValue: 28,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 28,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 0,
              colorValue: 28,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 28,
              },
            },
          },
        ],
      },
      {
        category: "Phase 2",
        series: [
          {
            name: "Completed",
            data: {
              value: 6,
              colorValue: 6,
              total: 6,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 6,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 1,
              colorValue: 6,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 6,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 3,
              colorValue: 6,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 6,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 0,
              colorValue: 6,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 6,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 3,
              colorValue: 6,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 6,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 0,
              colorValue: 6,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 6,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 0,
              colorValue: 6,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 6,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 0,
              colorValue: 6,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 6,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 0,
              colorValue: 6,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 6,
              },
            },
          },
        ],
      },
      {
        category: "Phase 4",
        series: [
          {
            name: "Completed",
            data: {
              value: 4,
              colorValue: 4,
              total: 4,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 4,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 1,
              colorValue: 4,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 4,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 2,
              colorValue: 4,
              total: 2,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 4,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 0,
              colorValue: 4,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 4,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 1,
              colorValue: 4,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 4,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 0,
              colorValue: 4,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 4,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 1,
              colorValue: 4,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 4,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 0,
              colorValue: 4,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 4,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 0,
              colorValue: 4,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 4",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 4,
              },
            },
          },
        ],
      },
      {
        category: "Phase 1/Phase 2",
        series: [
          {
            name: "Completed",
            data: {
              value: 3,
              colorValue: 1,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 1,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 1,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 1,
              colorValue: 1,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 1,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 1,
              colorValue: 1,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 1,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 1,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 1/Phase 2",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 1,
              },
            },
          },
        ],
      },
      {
        category: "Phase 3",
        series: [
          {
            name: "Completed",
            data: {
              value: 0,
              colorValue: 3,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 3,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 2,
              colorValue: 3,
              total: 2,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 3,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 3,
              colorValue: 3,
              total: 3,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 3,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 0,
              colorValue: 3,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 3,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 0,
              colorValue: 3,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 3,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 2,
              colorValue: 3,
              total: 2,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 3,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 0,
              colorValue: 3,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 3,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 0,
              colorValue: 3,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 3,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 0,
              colorValue: 3,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 3,
              },
            },
          },
        ],
      },
      {
        category: "Early Phase 1",
        series: [
          {
            name: "Completed",
            data: {
              value: 1,
              colorValue: 1,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 1,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 1,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 1,
              colorValue: 1,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 1,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 1,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 1,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Early Phase 1",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 1,
              },
            },
          },
        ],
      },
      {
        category: "Phase 2/Phase 3",
        series: [
          {
            name: "Completed",
            data: {
              value: 1,
              colorValue: 1,
              total: 1,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Completed",
                count: 1,
              },
            },
          },
          {
            name: "Unknown status",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Unknown status",
                count: 1,
              },
            },
          },
          {
            name: "Recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Active, not recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Active, not recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Terminated",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Terminated",
                count: 1,
              },
            },
          },
          {
            name: "Not yet recruiting",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Not yet recruiting",
                count: 1,
              },
            },
          },
          {
            name: "Withdrawn",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Withdrawn",
                count: 1,
              },
            },
          },
          {
            name: "Enrolling by invitation",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17":
                  "Enrolling by invitation",
                count: 1,
              },
            },
          },
          {
            name: "Suspended",
            data: {
              value: 0,
              colorValue: 1,
              total: 0,
              values: {
                "7a16a3ae-466e-45ae-9a3d-3f2680cab390": "Phase 2/Phase 3",
                "a14e9d5e-f5c4-4fb5-a593-2adafc404b17": "Suspended",
                count: 1,
              },
            },
          },
        ],
      },
    ],
    series: {
      name: "status",
      data: [
        "Completed",
        "Unknown status",
        "Recruiting",
        "Active, not recruiting",
        "Terminated",
        "Not yet recruiting",
        "Withdrawn",
        "Enrolling by invitation",
        "Suspended",
      ],
    },
  };

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function handlePagination(pageAction) {
    if (pageAction === 1) {
      setPageNumber(pageNumber + 1);
    } else {
      if (pageNumber > 1) setPageNumber(pageNumber - 1);
    }
  }

  useEffect(() => {
    // setData(NtaiEChartUtil.paginate(barData, 2, pageNumber));
  }, [pageNumber]);

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Series Chart">
          <NtaiVerticalBarSeriesEChart
            widgetId="bar-series"
            data={barSeriesData}
            metadata={{
              filterFg: filtered,
              fields: {
                phaseid: {
                  fieldUuId: "phaseuuid",
                  name: "Phase",
                  fieldOrder: 1,
                  field: {
                    uuId: "field1uuid",
                  },
                },
                periodid: {
                  fieldUuId: "periodUuId",
                  name: "Period",
                  fieldOrder: 2,
                  field: {
                    uuId: "field2UuId",
                  },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      id="vertical-chart"
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
