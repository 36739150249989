import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { setAppHeader } from "app/store/ntai/navigationSlice";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function T() {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppHeader(true));
    localStorage.setItem("appHeader", "true");
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "64px",
          width: "100%",
        }}
      >
        <Typography variant="h1" sx={{ pl: theme.spacing(2), fontWeight: 300 }}>
          Your Searches
        </Typography>
      </Box>
      <Box
        sx={{
          p: theme.spacing(2),
          display: "flex",
          height: `calc(100% - 64px)`,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexBasis: "10%",
            justifyContent: "start",
            gap: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "220px",
              alignItems: "center",
              justifyContent: "center",
              h: "70px",
              borderRadius: theme.general.borderRadius,
            }}
          >
            <Typography variant="h4">All</Typography>
          </Box>
          <Box
            sx={{
              borderBottom: theme.general.border2,
              display: "flex",
              width: "220px",
              alignItems: "center",
              justifyContent: "center",
              h: "70px",
            }}
          >
            <Typography color="default" variant="h4">
              Shared
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            pt: theme.spacing(2),
            display: "flex",
            flexBasis: "90%",
            width: "100%",
          }}
        >
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ background: "red" }}>SP</Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Box
                  sx={{
                    display: "flex",
                    p: theme.spacing(1),
                    width: "100%",
                    gap: theme.spacing(1),
                    justifyContent: "space-between",
                  }}
                >
                  <Box x={{ flexDirection: "column" }}>
                    <Typography variant="h4">
                      Myocardial infarction and other items to be considers soon
                    </Typography>
                    <Typography variant="subtitle1">
                      Ask what is coming next
                    </Typography>
                  </Box>
                  <Box x={{ flexDirection: "column" }}>
                    <Typography variant="h4">12-Jun-2022</Typography>
                    <Typography variant="subtitle1">
                      No cmments for now
                    </Typography>
                  </Box>
                  <Box x={{ flexDirection: "column" }}>
                    <Typography variant="h4">Active</Typography>
                    <Typography variant="subtitle1">
                      Psanju, Nihal, Others..
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ background: "red" }}>SP</Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Box
                  sx={{
                    display: "flex",
                    p: theme.spacing(1),
                    width: "100%",
                    gap: theme.spacing(1),
                    justifyContent: "space-between",
                  }}
                >
                  <Box x={{ flexDirection: "column" }}>
                    <Typography variant="h4">
                      Myocardial infarction and other items to be considers soon
                    </Typography>
                    <Typography variant="subtitle1">
                      Ask what is coming next
                    </Typography>
                  </Box>
                  <Box x={{ flexDirection: "column" }}>
                    <Typography variant="h4">12-Jun-2022</Typography>
                    <Typography variant="subtitle1">
                      No cmments for now
                    </Typography>
                  </Box>
                  <Box x={{ flexDirection: "column" }}>
                    <Typography variant="h4">Active</Typography>
                    <Typography variant="subtitle1">
                      Psanju, Nihal, Others..
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <Divider />
          </List>
        </Box>
      </Box>
    </Box>
  );
}
