import { Box, useTheme } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateFunction } from "../../store/functionsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { FunctionEditorContext } from "../../FunctionEdit";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { getSourceOutDatasets } from "app/main/pages/core/sourcedefinition/source/out/store/sourceOutDatasetsSlice";

const _ = require("lodash");

export default function FunctionSourceScdDatasetSelection() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset, watch } = useFormContext();

  const { action, formData } = useContext(FunctionEditorContext);

  const watchSourceOutScdDatasetSelection = watch(
    "functionForm.sourceOutScdDatasetUuId",
    _.get(formData, "sourceOutScdDatasetUuId")
  );

  const sourceOutDatasetsDataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const sourceOutDatasetsData =
    sourceOutDatasetsDataMap && !_.isEmpty(sourceOutDatasetsDataMap)
      ? Object.values(sourceOutDatasetsDataMap)
      : [];

  const sourceOutDatasetOptions = NtaiUtils.getSelectOptions(
    sourceOutDatasetsData.map((sod, i) => ({
      uuId: sod.uuId,
      name: sod["sourceMin"]["displayName"] + " - " + sod.datasetName,
    })),
    "uuId",
    "name"
  );

  const activeId = useSelector((state) => state.models.functionsSlice.activeId);
  //   const status = useSelector((state) => state.models.functionsSlice.status);

  function updateSourceOutScdDataset() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("functionForm")
    );

    if (
      _.has(sanitizedFormData, "sourceOutScdDatasetUuId") &&
      _.get(sanitizedFormData, "sourceOutScdDatasetUuId") !==
        _.get(formData, "sourceOutScdDatasetUuId")
    ) {
      dispatch(
        updateFunction({
          uuId: activeId,
          formData: sanitizedFormData,
        })
      );
    }
  }

  useEffect(() => {
    updateSourceOutScdDataset();
  }, [watchSourceOutScdDatasetSelection]);

  useEffect(() => {
    dispatch(
      getSourceOutDatasets({
        sourceDefId: "*",
        sourceId: "*",
        sourceOutStoreId: "*",
      })
    );
  }, []);

  return (
    <NtaiAccordion title="SCD Dataset" titleIcon="table">
      <Box
        sx={{ display: "flex", flexDirection: "column", p: theme.spacing(2) }}
      >
        {_.isArray(sourceOutDatasetOptions) &&
          sourceOutDatasetOptions.length > 0 && (
            <NtaiSelectField
              width="100%"
              options={sourceOutDatasetOptions}
              name="functionForm.sourceOutScdDatasetUuId"
              label="Select dataset for SCD"
            />
          )}
        {!sourceOutDatasetOptions ||
          (_.isArray(sourceOutDatasetOptions) &&
            sourceOutDatasetOptions.length === 0 && (
              <Box sx={{ p: theme.spacing(2) }}>
                <NtaiEmptyMessage
                  align="center"
                  header="No datasets available"
                  subheader="Create source out dataset"
                />
              </Box>
            ))}
      </Box>
    </NtaiAccordion>
  );
}
