import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceFilterSchemeFields = createAsyncThunk(
  "sourceFilterSchemeFields/getSourceFilterSchemeFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, filterSchemeId } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${filterSchemeId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceFilterSchemeField = createAsyncThunk(
  "sourceFilterSchemeFields/getSourceFilterSchemeField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, filterSchemeId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${filterSchemeId}/fields/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSourceFilterSchemeFields = createAsyncThunk(
  "sourceFilterSchemeFields/createOrUpdateSourceFilterSchemeFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, filterSchemeId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${filterSchemeId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceFilterSchemeField = createAsyncThunk(
  "sourceFilterSchemeields/updateSourceFilterSchemeField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, filterSchemeId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${filterSchemeId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceFilterSchemeField = createAsyncThunk(
  "sourceFilterSchemeFields/deleteSourceFilterSchemeField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, filterSchemeId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/filter-schemes/${filterSchemeId}/fields/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceFilterSchemeFieldsSlice = createSlice({
  name: "sourceFilterSchemeFields",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceFilterSchemeFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceFilterSchemeFields",
      };
    },
    [getSourceFilterSchemeFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceFilterSchemeFields",
        message: action.payload.message,
      };
    },
    [getSourceFilterSchemeField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceFilterSchemeField",
      };
    },
    [getSourceFilterSchemeField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceFilterSchemeField",
        message: action.payload.message,
      };
    },
    [createOrUpdateSourceFilterSchemeFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createOrUpdateSourceFilterSchemeFields",
      };
      state.activeId = action.payload.uuId;
    },
    [createOrUpdateSourceFilterSchemeFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSourceFilterSchemeFields",
        message: action.payload.message,
      };
    },
    [updateSourceFilterSchemeField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceFilterSchemeField",
      };
    },
    [updateSourceFilterSchemeField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceFilterSchemeField",
        message: action.payload.message,
      };
    },
    [deleteSourceFilterSchemeField.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceFilterSchemeField",
      };
    },
    [deleteSourceFilterSchemeField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceFilterSchemeField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceFilterSchemeFieldsSlice.actions;

export default sourceFilterSchemeFieldsSlice.reducer;
