import { useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "@ntai/@history";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SearchSeriesImportPreviewForm from "./SearchSeriesImportPreviewForm";

export default function SearchSeriesImportPreview() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.search.searchSeriesSlice.data);

  const status = useSelector((state) => state.search.searchSeriesSlice.status);

  function handleCancel() {
    history.push("/search");
  }

  function handleCreateSearch() {
    console.log("Handle create search:...");
  }

  return (
    <NtaiPage title={`Import Series Preview / ${data["name"]}`}>
      <NtaiForm>
        <SearchSeriesImportPreviewForm
          handleCancel={handleCancel}
          handleCreateSearch={handleCreateSearch}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
