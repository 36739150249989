import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getFunctions } from "../../../models/function/store/functionsSlice";
import { getSourceOutDatasets } from "../out/store/sourceOutDatasetsSlice";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import SourceCustomFieldElems from "./elems/SourceCustomFieldElems";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

const _ = require("lodash");

export default function SourceCustomFieldForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue, reset, watch } = useFormContext();
  const [customCode, setCustomCode] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutDatasetsDataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const sourceOutDatasetsData =
    sourceOutDatasetsDataMap && !_.isEmpty(sourceOutDatasetsDataMap)
      ? Object.values(sourceOutDatasetsDataMap)
      : [];

  const functionsDataMap = useSelector(
    (state) => state.models.functionsSlice.data
  );

  const functionsData =
    functionsDataMap && !_.isEmpty(functionsDataMap)
      ? Object.values(functionsDataMap)
      : [];

  const datasetOptions = NtaiUtils.getSelectOptions(
    sourceOutDatasetsData,
    "uuId",
    "datasetName"
  );
  const functionOptions = NtaiUtils.getSelectOptions(
    functionsData,
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceCustomFieldForm")
    );

    sanitizedFormData["customCode"] = customCode;

    handleSave(sanitizedFormData);
  }

  function onChangeCustomCode(newValue) {
    setCustomCode(newValue);
  }

  useEffect(() => {
    dispatch(getFunctions());
    dispatch(
      getSourceOutDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceOutStoreId: "*",
      })
    );
    reset({ sourceCustomFieldForm: action === "add" ? {} : formData });
    if (action === "edit")
      setCustomCode(
        [1, 3].includes(formData["execTypeCode"])
          ? formData["customCode"]
          : null
      );
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "95%" }}>
      <NtaiFormPanel
        width="50%"
        header={
          action === "add"
            ? "Add Custom Field"
            : `Edit ${_.get(formData, "name")}`
        }
        subheader="Add custom field single or group to the current source. Custom fields can be created using user defined function or they can be collection of standard or custom fields of type single"
        handleSave={onHandleSave}
        handleCancel={handleCancel}
      >
        <Box
          sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
        >
          <Box sx={{ display: "flex", width: "45%" }}>
            <NtaiTextField
              name="sourceCustomFieldForm.name"
              label="Field Name*"
              placeholder="Field name"
              rules={{
                required: "Field name is required",
                validate: NtaiUtils.isValidColumnName,
              }}
            />
          </Box>

          <NtaiSwitchField
            name="sourceCustomFieldForm.includeInOutDatasetFg"
            label="Include in dataset?"
          />
          <NtaiSwitchField
            name="sourceCustomFieldForm.activeFg"
            label="Active?"
          />
        </Box>

        <NtaiTextField
          name="sourceCustomFieldForm.label"
          label="Label*"
          placeholder="Label"
          rules={{
            required: "Label is required",
          }}
        />

        <NtaiTextField
          multiline
          minRows={2}
          maxRows={2}
          name="sourceCustomFieldForm.description"
          label="Description*"
          placeholder="Description"
          rules={{ required: "Description is required" }}
        />

        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <NtaiSelectField
            name="sourceCustomFieldForm.typeCode"
            label="Type*"
            isDisabled={action === "edit" ? true : false}
            options={[
              { value: 1, label: "Simple" },
              { value: 2, label: "Array" },
            ]}
            rules={{ required: "Type is required" }}
          />

          <NtaiSelectField
            name="sourceCustomFieldForm.execTypeCode"
            label="Execution Mode*"
            isDisabled={action === "edit" ? true : false}
            options={[
              { value: 1, label: "Inline SQL" },
              { value: 2, label: "UDF" },
              { value: 2, label: "DataFrame API" },
            ]}
            rules={{ required: "Execution mode is required" }}
          />
        </Box>

        <NtaiSelectField
          width="100%"
          options={datasetOptions}
          isDisabled={action === "edit" ? true : false}
          name="sourceCustomFieldForm.sourceOutDatasetUuId"
          label="Target store dataset*"
          rules={{ required: "Target store dataset is required" }}
        />

        {action === "edit" &&
          formData["typeCode"] === 1 &&
          formData["execTypeCode"] === 2 && (
            <React.Fragment>
              <NtaiSelectField
                options={functionOptions}
                name="sourceCustomFieldForm.functionUuId"
                label="Custom field function*"
                rules={{ required: "Function is required" }}
              />

              <NtaiTextField
                name="sourceCustomFieldForm.functionFieldArgs"
                label="Function field arguments*"
                placeholder="field1,field2"
                rules={{ required: "Function field args is required" }}
              />
            </React.Fragment>
          )}
        {action === "edit" &&
          formData["typeCode"] === 2 &&
          formData["execTypeCode"] === 2 && (
            <React.Fragment>
              <NtaiSelectField
                options={functionOptions}
                name="sourceCustomFieldForm.functionUuId"
                label="Post aggregation function"
              />
            </React.Fragment>
          )}
        {/* {action === "edit" && [1, 3].includes(formData["execTypeCode"]) && (
          <React.Fragment>
            <NtaiTextField
              name="sourceCustomFieldForm.customCode"
              label="Custom SQL"
              multiline
              minRows={3}
              maxRows={4}
            />
          </React.Fragment>
        )} */}
      </NtaiFormPanel>
      {action === "edit" && formData["typeCode"] === 2 && (
        <NtaiPanel
          width="50%"
          header="Assign fields to group"
          subheader="You can assign one or more fields to the group field. Fields added to a group are part of the group field collection (ex. Array). Ensure all elements have same return types."
        >
          <SourceCustomFieldElems />
        </NtaiPanel>
      )}
      {action === "edit" &&
        formData["typeCode"] === 1 &&
        [1, 3].includes(formData["execTypeCode"]) && (
          <NtaiPanel
            width="50%"
            header="Custom Code or SQL"
            subheader="Use Custom Code to define and inject field specific logic for filtering or transforming data within SQL queries or Spark DataFrame operations. The custom logic should align with the syntax and semantics of the underlying platform: SQL expressions for SQL queries or Python/Scala code for Spark DataFrame APIs"
          >
            <Box
              sx={{
                display: "flex",
                height: "100%",
                border: theme.general.border1,
              }}
            >
              <AceEditor
                mode="python"
                theme="github"
                fontSize="14px"
                onChange={onChangeCustomCode}
                name="functionCode"
                value={customCode}
                width="100%"
                height="100%"
                // editorProps={{ $blockScrolling: Infinity }}
                setOptions={{
                  enableBasicAutocompletion: true,
                  // enableLiveAutocompletion: true,
                  // enableSnippets: true,
                }}
              />
            </Box>
          </NtaiPanel>
        )}
    </Box>
  );
}
