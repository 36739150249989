import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordsDistributions = createAsyncThunk(
  "searchSourceRecordDistributions/getSearchSourceRecordsDistributions",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, params } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/distributions`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitSearchSourceRecordsDistribution = createAsyncThunk(
  "searchSourceRecordDistributions/submitSearchSourceRecordsDistribution",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/distributions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordDistributionsSlice = createSlice({
  name: "searchSourceRecordDistributions",
  initialState: {
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
  },
  extraReducers: {
    [getSearchSourceRecordsDistributions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordsDistributions",
      };
    },
    [getSearchSourceRecordsDistributions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordsDistributions",
        message: action.payload.message,
      };
    },

    [submitSearchSourceRecordsDistribution.fulfilled]: (state, action) => {
      state.status = {
        result: "success",
        method: "submitSearchSourceRecordsDistribution",
      };
    },
    [submitSearchSourceRecordsDistribution.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "submitSearchSourceRecordsDistribution",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus } = searchSourceRecordDistributionsSlice.actions;

export default searchSourceRecordDistributionsSlice.reducer;
