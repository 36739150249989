import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getSourceOutStores = createAsyncThunk(
  "sourceOutStores/getSourceOutStores",
  async (values) => {
    const { sourceDefId, sourceId } = values;
    const response = await server.get(
      `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`
    );
    return response.data;
  }
);

export const createSourceOutStore = createAsyncThunk(
  "sourceOutStores/createSourceOutStore",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceOutStore = createAsyncThunk(
  "sourceOutStores/updateSourceOutStore",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceOutStore = createAsyncThunk(
  "sourceOutStores/deleteSourceOutStore",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formatCode } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores/${uuId}`
      );
      return { formatCode: formatCode, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceOutStoresSlice = createSlice({
  name: "sourceOutStores",
  initialState: {
    data: {},
    sourceDatasets: [],
    es: {
      status: {},
    },
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceOutStores.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "formatCode") };
      state.status = { result: "success", method: "getSourceOutStores" };
    },
    [getSourceOutStores.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceOutStores",
        message: action.payload.message,
      };
    },

    [createSourceOutStore.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.formatCode]: action.payload,
      };
      state.status = { result: "success", method: "createSourceOutStore" };
    },
    [createSourceOutStore.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceOutStore",
        message: action.payload.message,
      };
    },

    [updateSourceOutStore.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.formatCode]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceOutStore" };
    },
    [updateSourceOutStore.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceOutStore",
        message: action.payload.message,
      };
    },

    [deleteSourceOutStore.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.formatCode);
      state.status = { result: "success", method: "deleteSourceOutStore" };
    },
    [deleteSourceOutStore.rejected]: (state, action) => {
      state.status = { result: "error", method: "deleteSourceOutStore" };
    },
  },
});

export const { clearStatus, setActiveId } = sourceOutStoresSlice.actions;

export default sourceOutStoresSlice.reducer;
