import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourcePipelineInStores = createAsyncThunk(
  "sourcePipelineInStores/getSourcePipelineInStores",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ins`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourcePipelineInStore = createAsyncThunk(
  "sourcePipelineInStores/createSourcePipelineInStore",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ins`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourcePipelineInStore = createAsyncThunk(
  "sourcePipelineInStores/deleteSourcePipelineInStore",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ins/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePipelineInStoresSlice = createSlice({
  name: "sourcePipelineInStores",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePipelineInStores.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourcePipelineInStores" };
    },
    [getSourcePipelineInStores.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelineInStores",
        message: action.payload.message,
      };
    },

    [createSourcePipelineInStore.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourcePipelineInStore",
      };
    },
    [createSourcePipelineInStore.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourcePipelineInStore",
        message: action.payload.message,
      };
    },

    [deleteSourcePipelineInStore.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourcePipelineInStore",
      };
    },
    [deleteSourcePipelineInStore.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourcePipelineInStore",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourcePipelineInStoresSlice.actions;

export default sourcePipelineInStoresSlice.reducer;
