import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import Chart from "react-apexcharts";

export default function NtaiSparkRadialBarChart(props) {
  const { label, series, fontSize, fontWeight } = props;
  const theme = useTheme();

  const options = {
    chart: {
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 4,
          size: "75%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: true,
          name: {
            //   offsetY: -10,
            offsetY: 5,
            show: true,
            color: grey[700],
            fontSize: fontSize ? fontSize : "11px",
            fontWeight: fontWeight ? fontWeight : "normal",
          },
          value: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: [theme.colors.secondary.light], // light green: 73EB90, dark green: 73EB90
    },
    labels: [label],
  };
  return <Chart options={options} series={[series]} type="radialBar" />;
}
