import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  deleteAlertTracker,
  getAlertTrackers,
} from "./store/alertTrackerSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import { useTheme } from "@mui/material";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "20%",
  },
  {
    id: "entityName",
    numeric: false,
    disablePadding: false,
    label: "Entity",
  },
  {
    id: "numOfRecords",
    numeric: false,
    disablePadding: false,
    label: "# Records",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Created",
    transformFunc: NtaiUtils.formatDateTimeCol,
  },
];

const toolbarActions = [
  {
    value: "complete",
    label: "Complete",
    icon: "archive",
  },
  {
    value: "terminate",
    label: "Terminate",
    icon: "terminate",
  },
];

export default function AlertTrackerList() {
  const theme = useTheme();
  const [action, setAction] = useState();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const dataMap = useSelector((state) => state.monitor.alertTrackerSlice.data);
  const status = useSelector((state) => state.monitor.alertTrackerSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["dateCreated"], ["desc"])
      : [];

  console.log("Tracker data: ", data);

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getAlertTrackers" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getAlertTrackers());
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Alert Tracker">
        {loaded && _.isArray(data) && data.length > 0 && (
          <NtaiSimpleTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/monitor/tracker/alerts`}
            handleToolbarAction={handleToolbarAction}
            title={`Records (${data.length})`}
          />
        )}
        {loaded && _.isArray(data) && data.length === 0 && (
          <NtaiEmptyMessage header="No data available" />
        )}
        {!loaded && <NtaiCircularProgress size={24} />}
      </NtaiPage>
    </>
  );
}
