import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRule, clearStatus } from "./store/rulesSlice";
import RuleForm from "./RuleForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function RuleCreate() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const dataMap = useSelector((state) => state.core.rulesSlice.data);
  const status = useSelector((state) => state.core.rulesSlice.status);
  const activeId = useSelector((state) => state.core.rulesSlice.activeId);

  function handleSave(formData) {
    dispatch(createRule(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createRule" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Rule "${_.get(dataMap[activeId], "name")}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/admin/rules/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Rule" back="/admin/rules">
      <NtaiForm>
        <RuleForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
