import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiImageArea from "./NtaiImageArea";
import Scrollbar from "../Scrollbar";
import { deepOrange, lime } from "@mui/material/colors";

export default function TestNtaiImageArea() {
  const theme = useTheme();
  function handleCallback(are) {}

  function handleDelete(d) {}

  function handleEdit(e) {}
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexBasis: "15%",
          height: "100%",
          background: deepOrange[50],
        }}
      >
        Content
      </Box>

      <Box
        sx={{
          display: "flex",
          flexBasis: "70%",
          height: "100%",
          border: theme.general.border2p,
          overflow: "auto",
          p: theme.spacing(4),
        }}
      >
        <NtaiImageArea
          imgAreas={[]}
          imgSrc="/images/3500a.png"
          imgHeight={3300}
          imgWidth={2550}
          imgName="nothing"
          handleCallback={handleCallback}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      </Box>

      <Box sx={{ display: "flex", flexBasis: "15%", background: lime[100] }}>
        Content
      </Box>
    </Box>
  );
}
