import { Box, useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "@ntai/@history";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiDashboardMetric1Widget from "@ntai/components/widgets/dashboard/templates/NtaiDashboardMetric1Widget";
import { green, lime } from "@mui/material/colors";

export default function SearchSeriesImportPreviewForm({
  handleCreateSearch,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.search.searchSeriesSlice.data);

  const status = useSelector((state) => state.search.searchSeriesSlice.status);

  return (
    <NtaiFormPanel
      width="50%"
      header={data["statusDecode"]}
      subheader={data["message"]}
      handleSave={handleCreateSearch}
      handleCancel={handleCancel}
      saveLabel="Create"
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <NtaiDashboardMetric1Widget
            fg="black"
            bg={lime[300]}
            subheader={data["numOfRecordsMatched"]}
            header={"Imported"}
          />
          <NtaiDashboardMetric1Widget
            fg="white"
            bg="red"
            subheader={data["numOfRecordsRejected"]}
            header={"Rejected"}
          />
          <NtaiDashboardMetric1Widget
            fg="black"
            bg={green[200]}
            subheader={
              data["numOfRecordsMatched"] + data["numOfRecordsRejected"]
            }
            header={"Total"}
          />
        </Box>

        <NtaiLabelValue1 label="Name" value={data["name"]} />
        <NtaiLabelValue1 label="Description" value={data["description"]} />
        <NtaiLabelValue1 label="Status" value={data["statusDecode"]} />
        <NtaiLabelValue1 label="Message" value={data["message"]} />
        <NtaiLabelValue1
          label="Total No. of Records"
          value={data["numOfRecordsMatched"] + data["numOfRecordsRejected"]}
        />
        <NtaiLabelValue1
          label="No. of Records Matched"
          value={data["numOfRecordsMatched"]}
        />
        <NtaiLabelValue1
          label="No. of Records Rejected"
          value={data["numOfRecordsRejected"]}
        />
      </Box>
    </NtaiFormPanel>
  );
}
