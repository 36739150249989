import { UserPermissionStore } from "@ntai/components/rbac/UserPermissionProvider";
import CoreItem from "../CoreItem";
import { useContext } from "react";
import withUserPermission from "@ntai/components/rbac/withUserPermission";

const CoreItemRbac = (props) => {
  return <CoreItem {...props} />;
};

export default withUserPermission(CoreItemRbac);
