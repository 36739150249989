import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import FieldList from "./field/ObjectFieldList";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import ObjectRelList from "./relation/ObjectRelList";
import ObjectTopicList from "./topic/ObjectTopicList";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import ObjectSourceViewList from "./source-view/ObjectSourceViewList";
import ObjectDashboardList from "./dashboard/ObjectDashboardList";
import ObjectInfoFieldsWrapper from "./info/ObjectInfoFieldsWrapper";
import ObjectFormBuilderWrapper from "./form/ObjectFormBuilderWrapper";
const _ = require("lodash");

export default function ObjectForm({ action, formData, handleSave }) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const objectCategoryOptions = _.get(
    codes,
    CODELIST_CODES.COR_META_OBJECT_CATEGORY_CODE
  );

  const objectDataMap = useSelector((state) => state.core.objectsSlice.data);
  const objectData = Object.values(objectDataMap);

  const filteredObjectData =
    action === "edit"
      ? _.filter(objectData, function (o) {
          return o.uuId !== _.get(formData, "uuId");
        })
      : objectData;

  const objectOptions = NtaiUtils.getSelectOptions(
    filteredObjectData,
    "uuId",
    "name"
  );

  const domainDataMap = useSelector((state) => state.core.domainsSlice.data);
  const domainData = Object.values(domainDataMap);
  const domainOptions = NtaiUtils.getSelectOptions(domainData, "uuId", "name");

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectForm")
    );

    handleSave(_.get(sanitizedFormData, "domainUuId"), sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/cdo/objects`);
  }

  useEffect(() => {
    reset({ objectForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Details"
          subheader="Provide general details about object including short name, name, label, domain and object category."
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="objectForm.shortName"
            label="Short Name*"
            rules={{ required: "Short name is required" }}
            disabled={action === "edit" ? true : false}
          />

          <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
            <NtaiTextField
              name="objectForm.name"
              label="Name*"
              rules={{ required: "Name is required" }}
            />
            <NtaiSwitchField label="Root?" name="objectForm.rootFg" />
          </Box>

          <NtaiTextField
            name="objectForm.label"
            label="Label*"
            rules={{ required: "Label is required" }}
          />

          <NtaiSelectField
            name="objectForm.domainUuId"
            label="Domain*"
            options={domainOptions}
            rules={{ required: "Domain is required" }}
          />

          <NtaiTextField
            name="objectForm.description"
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Description"
            label="Description"
          />
          <NtaiSelectField
            label="Category*"
            name="objectForm.categoryCode"
            options={objectCategoryOptions}
            rules={{ required: "Category is required" }}
          />
        </NtaiFormPanel>
      </Box>
      <Box disabled={action === "add" ? true : false} label="FIELDS">
        <FieldList />
      </Box>
      <Box disabled={action === "add" ? true : false} label="DISPLAY">
        <ObjectInfoFieldsWrapper />
      </Box>
      <Box disabled={action === "add" ? true : false} label="FORM">
        <ObjectFormBuilderWrapper handleCancel={handleCancel} />
      </Box>

      <Box disabled={action === "add" ? true : false} label="RELATIONS">
        <ObjectRelList />
      </Box>
      <Box
        disabled={action === "add" ? true : false}
        label="TOPICS"
        visible={
          formData &&
          _.has(formData, "categoryCode") &&
          formData["categoryCode"] === 1
            ? true
            : false
        }
      >
        <ObjectTopicList />
      </Box>
      <Box
        disabled={action === "add" ? true : false}
        label="OVERSIGHT VIEWS"
        visible={
          formData &&
          _.has(formData, "categoryCode") &&
          formData["categoryCode"] === 1
            ? true
            : false
        }
      >
        <ObjectSourceViewList />
      </Box>
      <Box
        disabled={action === "add" ? true : false}
        label="OBJECT DASHBOARDS"
        visible={
          formData &&
          _.has(formData, "categoryCode") &&
          formData["categoryCode"] === 1
            ? true
            : false
        }
      >
        <ObjectDashboardList />
      </Box>
    </NtaiTabs>
  );
}
