import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useTheme } from "@mui/material";
const _ = require("lodash");

export default function ThesaurusSemTypeForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("thesaurusSemTypeForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  useEffect(() => {
    reset({ thesaurusSemTypeForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Enter general details about semantic type including Semantic Type Id, Name and Description"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <NtaiTextField
        name="thesaurusSemTypeForm.semTypeId"
        label="Sem Type Id*"
        rules={{ required: "Semantic type identifier is required" }}
      />

      <NtaiTextField
        name="thesaurusSemTypeForm.name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />

      <NtaiTextField
        name="thesaurusSemTypeForm.description"
        label="Description"
        multiline
        minRows={2}
        maxRows={2}
      />
    </NtaiDialogFormPanel>
  );
}
