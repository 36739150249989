import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { grey } from "@mui/material/colors";
import {
  tooltipConfig,
  gridConfig,
  xAxisLabelConfig,
  yAxisLabelConfig,
  legendConfig,
} from "../chartConfig";
import { Box, lighten } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import NtaiBarEChartUtil from "../bar/util/NtaiBarEChartUtil";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

const DEFAULT_OPTION = {
  legend: {
    type: "scroll",
    icon: "circle",
    orient: "horizontal",
    align: "auto",
    top: "bottom",
    left: "center",
    textStyle: {
      fontSize: 12,
    },
  },
  tooltip: {
    ...tooltipConfig,
    formatter: function (params) {
      return NtaiEChartUtil.getTooltipContent({
        title: {
          marker: _.has(params["data"], "_color")
            ? params["data"]["_color"]
            : null,
          text: NtaiEChartUtil.getTooltipDate(
            params["data"]["value"][0],
            params["data"]["_periodType"]
          ),
        },
        values: [
          {
            key: params.seriesName,
            value: params.data._filterFg
              ? params.data._actual + ` (${params.data._total})`
              : params.data._actual,
          },
        ],
      });
    },
  },
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: [0],
        xAxisIndex: [0],
      },
    },
  },
  grid: {
    containLabel: true,
    left: 20,
    top: 40,
    right: 20,
    bottom: 0,
  },
  lineStyle: {
    width: 4,
  },
  xAxis: {
    type: "time",
    axisLabel: xAxisLabelConfig,
    axisLine: {
      lineStyle: {
        width: 0.1,
      },
    },
    axisTick: {
      lineStyle: {
        width: 0.2,
      },
    },
  },
  yAxis: {
    type: "value",
    axisLabel: yAxisLabelConfig,
    // min: "dataMin",
    // max: "dataMax",
    // type: "log",
    // logBase: 2,
  },
  series: [],
};

export default function NtaiDateHistogramEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(params) {
    let sourceWidgetFilterFields = [];
    if (metadataRef.current && !_.isEmpty(metadataRef.current)) {
      sourceWidgetFilterFields = [
        {
          sourceWidgetFieldUuId: NtaiEChartUtil.getSourceWidgetFieldUuId(
            metadataRef.current,
            1
          ),
          fieldOperatorCode: 1,
          fieldValue: NtaiUtils.millisecondsToDate(params["data"]["value"][0]),
        },
      ];

      if (Object.keys(metadataRef.current["fields"]).length > 1) {
        sourceWidgetFilterFields.push({
          sourceWidgetFieldUuId: NtaiEChartUtil.getSourceWidgetFieldUuId(
            metadataRef.current,
            2
          ),
          fieldOperatorCode: 1,
          fieldValue: params.seriesName,
        });
      }
    }

    return sourceWidgetFilterFields;
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(params));
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(action, getSourceWidgetFilterFields(params));
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);
    const seriesLen = data["series"].length;
    const colors = NtaiEChartUtil.getColorPalette(theme, seriesLen);
    optionCopy["series"] = [];

    const periodFields = _.filter(
      Object.values(metadata["fields"]),
      function (o) {
        return o["dateIntervalCode"] && _.isNumber(o["dateIntervalCode"])
          ? true
          : false;
      }
    );

    let periodType = 5; // day

    if (periodFields.length > 0)
      periodType = periodFields[0]["dateIntervalCode"];

    data["data"].forEach((s, i) => {
      optionCopy["series"].push({
        name: seriesLen === 1 ? "Count" : s["name"],
        data: s["data"].map((d, j) => {
          const revisedColor = d.hasOwnProperty("colorValue")
            ? NtaiEChartUtil.getItemStyleColor(
                "bg",
                d["colorValue"] && d["colorValue"].length > 0
                  ? d["colorValue"]
                  : d["value"],
                colors[i],
                metadata
              )
            : colors[i];

          return {
            value: [d["time"], d["value"]["total"]],
            _total: d["value"]["total"],
            _actual: d["value"]["value"],
            _filterFg: metadata["filterFg"],
            _periodType: periodType,
            _color: revisedColor,
            itemStyle: NtaiBarEChartUtil.getItemStyle(
              d["value"],
              metadata,
              revisedColor,
              "v"
            ),
          };
        }),
        type: "bar",
        cursor: "default",
        label: {
          show: true,
          position: "top",
          formatter: NtaiBarEChartUtil.getVBarLabelFormatter,
        },
      });
    });

    setOption(optionCopy);
  }, [data, metadata]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"].length > 0 && chart}
    </Box>
  );
}
