import { faCloudUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  getSourceSeriesImportCsvSchemes,
  clearStatus as clearSourceSeriesImportSchemeSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/template/series/store/sourceSeriesImportCsvSchemesSlice";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SearchSeriesImportContext } from "./SearchSeriesImport";
const _ = require("lodash");

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function SearchSeriesImportForm({
  handleValidate,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const [selectedFile, setSelectedFile] = useState();
  const [displayFile, setDisplayFile] = useState();
  const [localFormData, setLocalFormData] = useState();
  const [loaded, setLoaded] = useState(false);

  const { setInitialFormData } = React.useContext(SearchSeriesImportContext);

  const sourceSeriesSchemeDataMap = useSelector(
    (state) => state.sources.sourceSeriesImportCsvSchemesSlice.data
  );

  const sourceSeriesImportSchemeSliceStatus = useSelector(
    (state) => state.sources.sourceSeriesImportCsvSchemesSlice.status
  );

  const sourceSeriesSchemeOptions =
    sourceSeriesSchemeDataMap && !_.isEmpty(sourceSeriesSchemeDataMap)
      ? NtaiUtils.getSelectOptions(
          _.orderBy(
            Object.values(sourceSeriesSchemeDataMap),
            ["fullDisplayName"],
            ["asc"]
          ),
          "uuId",
          "fullDisplayName"
        )
      : [];

  function handleCapture(e) {
    setSelectedFile(e.target.files[0]);
  }

  function onHandleSubmit() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchSeriesForm")
    );

    console.log("sanitizedFormData: ", sanitizedFormData);
    const formData = new FormData();
    formData.append("files", selectedFile ? selectedFile : []);
    formData.append("data", JSON.stringify(sanitizedFormData));
    setLocalFormData(getValues("searchSeriesForm"));
    setInitialFormData(formData);
    handleValidate(formData);
  }

  useEffect(() => {
    if (selectedFile) {
      setDisplayFile({
        name: selectedFile["name"],
        size: selectedFile["size"],
      });
    }
  }, [selectedFile]);

  useEffect(() => {
    if (
      sourceSeriesImportSchemeSliceStatus &&
      sourceSeriesImportSchemeSliceStatus.method ===
        "getSourceSeriesImportCsvSchemes" &&
      sourceSeriesImportSchemeSliceStatus.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearSourceSeriesImportSchemeSliceStatus());
    }
  }, [sourceSeriesImportSchemeSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceSeriesImportCsvSchemes({
        sourcedefId: "*",
        sourceId: "*",
      })
    );
  }, []);

  useEffect(() => {
    if (localFormData) {
      reset({ searchSeriesForm: localFormData });
    }
  }, [localFormData]);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiFormPanel width="75%">
          <Box sx={{ display: "flex", gap: theme.spacing(4) }}>
            <NtaiFormPanel
              padding="0px"
              header="General Information"
              subheader="You can create a search by importing series for a source using import series scheme and uploading or pasting data."
              handleSave={onHandleSubmit}
              handleCancel={handleCancel}
              saveLabel="Import"
            >
              <Box
                sx={{
                  flexBasis: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(2),
                }}
              >
                <NtaiTextField
                  name="searchSeriesForm.name"
                  label="Analysis Name*"
                  rules={{ required: "Analysis name is required" }}
                />
                <NtaiTextField
                  name="searchSeriesForm.description"
                  label="Description*"
                  multiline
                  minRows={3}
                  maxRows={3}
                  rules={{ required: "Description is required" }}
                />
                <NtaiSelectField
                  name="searchSeriesForm.sourceSeriesSchemeUuId"
                  label="Select  Scheme*"
                  options={sourceSeriesSchemeOptions}
                />
                <NtaiSwitchField
                  name="searchSeriesForm.csvHeaderFg"
                  label="Header present?"
                />
              </Box>
            </NtaiFormPanel>

            <NtaiFormPanel
              padding="0px"
              header="Upload CSV File or Paste Data"
              subheader="Upload CSV file or paste data following the structure as specified in delected import scheme for the source"
            >
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                <Button
                  size="small"
                  component="label"
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
                >
                  Upload File
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => handleCapture(e)}
                  />
                </Button>
                {displayFile && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography variant="subtitle1">
                      {displayFile["name"]}
                    </Typography>
                    <Typography variant="subtitle1">
                      ({NtaiUtils.bytesToSize(displayFile["size"])})
                    </Typography>
                  </Box>
                )}
              </Box>
              <Divider flexItem>OR</Divider>
              <NtaiTextField
                name="searchSeriesForm.csvData"
                label="CSV Data"
                multiline
                minRows={7}
                maxRows={7}
              />
            </NtaiFormPanel>
          </Box>
        </NtaiFormPanel>
      )}

      {!loaded && <NtaiCircularProgress />}
    </React.Fragment>
  );
}
