import {
  faCog,
  faFile,
  faFolders,
  faObjectGroup,
  faPlus,
  faUserCog,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import ReportSelection from "./reports/selection/ReportSelection";
import History from "@ntai/@history/@history";
import ReportList from "./reports/ReportList";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useState } from "react";
import {
  getReportDefinitionCategories,
  clearStatus,
} from "./admin/category/store/reportDefinitionCategoriesSlice";
import { useEffect } from "react";
import ReportSidebar from "./reports/sidebar/ReportSidebar";
import NtaiForm from "@ntai/components/forms/NtaiForm";
const _ = require("lodash");

export default function ReportsHome() {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const dataMap = useSelector(
    (state) => state.reports.reportDefinitionCategoriesSlice.data
  );

  const status = useSelector(
    (state) => state.reports.reportDefinitionCategoriesSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getReportDefinitionCategories" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getReportDefinitionCategories());
  }, []);

  return (
    <React.Fragment>
      <NtaiPage
        title="Reports"
        headerActions={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button
              sx={{ fontSize: "11px" }}
              size="small"
              startIcon={<FontAwesomeIcon size="2xs" icon={faUserCog} />}
              // onClick={() => history.push("/search-basic-init")}
              onClick={() => History.push("/reports/admin")}
            >
              ADMINISTRATION
            </Button>

            {loaded && data.length > 0 && (
              <Button
                sx={{ fontSize: "11px" }}
                size="small"
                variant="contained"
                startIcon={<FontAwesomeIcon size="2xs" icon={faPlus} />}
                // onClick={() => history.push("/search-basic-init")}
                onClick={() => handleDialogOpen()}
              >
                NEW REPORT
              </Button>
            )}
          </Box>
        }
      >
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          <ReportSidebar />
          <ReportList />
        </Box>
      </NtaiPage>

      <NtaiDialog
        title="Select Report Type"
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
        size="md"
        noPadding={true}
      >
        <ReportSelection />
      </NtaiDialog>
    </React.Fragment>
  );
}
