import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  updateSourceSeries,
} from "./store/sourceSeriesSlice";
import SourceSeriesForm from "./SourceSeriesForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SourceSeriesEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const status = useSelector((state) => state.core.sourceSeriesSlice.status);
  const dataMap = useSelector((state) => state.core.sourceSeriesSlice.data);
  const activeId = useSelector(
    (state) => state.core.sourceSeriesSlice.activeId
  );
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateSourceSeries({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (status.method === "updateSourceSeries" && status.result === "success") {
      enqueueSnackbar(
        `Source series "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back="/library/source-series"
    >
      <NtaiForm>
        <SourceSeriesForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
