import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiChipsInput from "@ntai/components/inputs/NtaiChipsInput";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
const _ = require("lodash");

export default function SearchDetailsEdit({
  handleSave,
  handleDialogClose,
  handleOnChangeTags,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();

  const searchData = useSelector(
    (state) => state.search.searchSlice.searchEditData
  );

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);

  const categoryCodeOptions = _.get(
    codelistCodes,
    CODELIST_CODES.SRH_SEARCH_CATEGORY_CODE
  );

  function handleChipsInputChange(tags) {
    handleOnChangeTags(tags);
  }

  useEffect(() => {
    // dispatch(getCodes(CODELIST_CODES.SRH_SEARCH_CATEGORY_CODE));
    reset({
      searchDetailsEditForm: searchData,
    });
  }, [searchData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: theme.spacing(2),
        p: theme.spacing(2),
      }}
    >
      <NtaiTextField
        name="searchDetailsEditForm.name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="searchDetailsEditForm.description"
        label="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
      <NtaiSelectField
        name="searchDetailsEditForm.categoryCode"
        label="Category"
        options={categoryCodeOptions}
      />

      <Box>
        <NtaiSwitchField
          name="searchDetailsEditForm.publicFg"
          label="Public?"
        />
      </Box>
      <NtaiChipsInput
        handleChange={handleChipsInputChange}
        data={searchData["tags"]}
      />
    </Box>
  );
}
