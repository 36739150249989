import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { getAssessFormObjectsForObjectTopic } from "./store/objectTopicsSlice";

const _ = require("lodash");

export default function ObjectTopicForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const objectUuId = useSelector((state) => state.core.objectsSlice.activeId);
  const topicDataMap = useSelector((state) => state.core.topicsSlice.data);
  const topicData = topicDataMap && Object.values(topicDataMap);

  const topicOptions =
    Array.isArray(topicData) && topicData.length > 0
      ? NtaiUtils.getSelectOptions(topicData, "uuId", "name")
      : [];

  const assessFormObjDataMap = useSelector(
    (state) => state.core.objectTopicsSlice.assessFormObjData
  );

  const assessFormObjStatus = useSelector(
    (state) => state.core.objectTopicsSlice.assessFormObjStatus
  );

  const assessFormObjData =
    assessFormObjDataMap && Object.values(assessFormObjDataMap);

  const assessFormOptions =
    Array.isArray(assessFormObjData) && assessFormObjData.length > 0
      ? NtaiUtils.getSelectOptions(assessFormObjData, "uuId", "name")
      : [];

  function handleSave() {
    const sanitizedTopicFormData = NtaiUtils.sanitizeFormData(
      getValues("objectTopicForm.topic")
    );
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectTopicForm")
    );

    sanitizedFormData["topic"] = sanitizedTopicFormData;

    handleFormSubmit(sanitizedFormData);
  }

  // useEffect(() => {
  //   if (
  //     assessFormObjStatus &&
  //     assessFormObjStatus.method === "getAssessFormObjectsForObjectTopic" &&
  //     assessFormObjStatus.result === "success"
  //   ) {
  //     console.log("");
  //   }
  // }, [assessFormObjStatus]);

  useEffect(() => {
    dispatch(
      getAssessFormObjectsForObjectTopic({
        domainId: "*",
        params: { categoryCode: 3 },
      })
    );

    reset({
      objectTopicForm: action === "edit" ? formData : {},
    });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="objectTopicForm.topic.name"
          label="Name*"
          rules={{ required: "Topic name is required" }}
        />
        <NtaiTextField
          multiline
          minRows={3}
          maxRows={3}
          name="objectTopicForm.topic.description"
          label="Description"
        />
        <NtaiSelectField
          name="objectTopicForm.assessFormObjectUuId"
          options={
            assessFormOptions &&
            _.isArray(assessFormOptions) &&
            assessFormOptions.length > 0
              ? assessFormOptions
              : []
          }
          label="Assessment Form Template"
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
