import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "app/main/pages/core/models/package/store/packagesSlice";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiFilterCheckboxGroup from "@ntai/components/inputs/filters/NtaiFilterCheckboxGroup";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  createSourceFunctionPackage,
  deleteSourceFunctionPackage,
  getSourceFunctionPackages,
} from "./store/sourceFunctionPackagesSlice";
import { Box, Chip, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
const _ = require("lodash");

export default function SourceDatasetSidePanelPackages() {
  const dispatch = useDispatch();

  const { getValues, watch, reset } = useFormContext();
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: sourcePipelineId,
  };

  const packagesDataMap = useSelector(
    (state) => state.models.packagesSlice.data
  );

  const packagesSliceStatus = useSelector(
    (state) => state.models.packagesSlice.status
  );
  const packagesData =
    packagesDataMap && !_.isEmpty(packagesDataMap)
      ? Object.values(packagesDataMap)
      : [];

  const dataMap = useSelector(
    (state) => state.sources.sourceFunctionPackagesSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceFunctionPackagesSlice.status
  );
  const data = Object.values(dataMap);

  const watchFunctionPackages = watch(
    "sourceFunctionPackageForm.functionPackages"
  );

  function generateOptionValues() {
    const result = [];
    if (packagesData && _.isArray(packagesData)) {
      packagesData.forEach((pd, i) => {
        result.push({ value: pd.uuId, label: pd.name });
      });
    }

    return result;
  }

  useEffect(() => {
    const packageUuIds = Object.values(dataMap).map((d, i) => d.packageUuId);

    if (
      _.isArray(packageUuIds) &&
      _.isArray(watchFunctionPackages) &&
      !_.isEqual(watchFunctionPackages, packageUuIds)
    ) {
      const added = _.difference(watchFunctionPackages, packageUuIds);
      const removed = _.difference(packageUuIds, watchFunctionPackages);

      if (_.isArray(added) && added.length > 0)
        dispatch(
          createSourceFunctionPackage({
            ...pathVariables,
            formData: { packageUuId: added[0] },
          })
        );
      else if (_.isArray(removed) && removed.length > 0)
        dispatch(
          deleteSourceFunctionPackage({
            ...pathVariables,
            uuId: _.get(
              _.filter(Object.values(dataMap), function (o) {
                return o.packageUuId === removed[0];
              })[0],
              "uuId"
            ),
          })
        );
    }
  }, [watchFunctionPackages]);

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceFunctionPackages" &&
      status.result === "success"
    ) {
      reset({
        "sourceFunctionPackageForm.functionPackages": Object.values(
          dataMap
        ).map((d, i) => d.packageUuId),
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(getPackages());
    dispatch(
      getSourceFunctionPackages({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourcePipelineId: sourcePipelineId,
      })
    );
  }, []);

  return (
    <NtaiAccordion
      title="Functions"
      titleIcon="function"
      borderBottom={true}
      titleSecondary={
        <Chip
          variant="contained"
          size="small"
          label={
            <Typography variant="subtitle2" color="inherit">
              {packagesData.length}
            </Typography>
          }
        />
      }
    >
      <Box sx={{ height: "120px" }}>
        {packagesData && _.isArray(packagesData) && packagesData.length > 0 && (
          <Scrollbar>
            <NtaiFilterCheckboxGroup
              name="sourceFunctionPackageForm.functionPackages"
              options={generateOptionValues()}
            />
          </Scrollbar>
        )}
        {packagesData &&
          _.isArray(packagesData) &&
          packagesData.length === 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <NtaiEmptyMessage
                header="No functions available"
                subheader="You can define UDF functions in models section"
                headerVariant="subtitle1"
                headerFontWeight="700"
                subheaderVariant="subtitle2"
              />
            </Box>
          )}

        {!packagesData && <NtaiCircularProgress />}
      </Box>
    </NtaiAccordion>
  );
}
