import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import DistributionGroupForm from "./DistributionGroupForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setActiveId,
  updateDistributionGroup,
  clearStatus,
} from "./store/distributionGroupsSlice";
import History from "@ntai/@history/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DistributionGroupEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = React.useState();
  const id = props.match.params.id;
  const status = useSelector(
    (state) => state.core.distributionGroupsSlice.status
  );
  const dataMap = useSelector(
    (state) => state.core.distributionGroupsSlice.data
  );
  const activeId = useSelector(
    (state) => state.core.distributionGroupsSlice.activeId
  );

  const formData = dataMap[id];

  function handleFormSubmit(formData) {
    dispatch(updateDistributionGroup({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateDistributionGroup" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Distribution group "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      History.push(`/library/distribution-groups`);
    }

    if (
      status &&
      status.method === "updateDistributionGroup" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Distribution Groups / Edit ${formData["name"]}`}
      back="/library/distribution-groups"
    >
      <NtaiForm>
        <DistributionGroupForm
          action="edit"
          handleFormSubmit={handleFormSubmit}
          formData={formData}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
