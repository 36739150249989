import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AlertEditContext } from "../../AlertEdit";
import { useDispatch, useSelector } from "react-redux";
import { getObject } from "app/main/pages/core/cdo/object/store/objectsSlice";
import NtaiCompactCard from "@ntai/components/cards/NtaiCompactCard";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import AlertRecordBatchAssessment from "./AlertRecordBatchAssessment";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import Scrollbar from "@ntai/components/Scrollbar";
import {
  clearStatus,
  submitAlertRecordBatchAssessment,
} from "./store/alertRecordBatchAssessmentsSlice";
import { useSnackbar } from "notistack";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function AlertRecordsBatchAssessmentSelectForm({
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [adjObjectsLoaded, setAdjObjectsLoaded] = useState(false);
  const [selectedAdjObjectUuId, setSelectedAdjObjUuId] = useState();
  const [adjFormDialogOpen, setAdjFormDialogOpen] = useState(false);
  const alertId = useSelector((state) => state.monitor.alertsSlice.activeId);
  const { alertData, alertMasterId, selectedRecords } =
    React.useContext(AlertEditContext);
  const adjObjectUuIds = _.get(alertData, "adjObjectUuIds");

  const objectsDataMap = useSelector((state) => state.core.objectsSlice.data);
  const status = useSelector(
    (state) => state.monitor.alertRecordBatchAssessmentsSlice.status
  );

  function handleSelect(id) {
    setSelectedAdjObjUuId(id);
    handleAdjFormDialogOpen();
  }

  function handleAdjFormDialogOpen() {
    setAdjFormDialogOpen(true);
  }

  function handleAdjFormDialogClose() {
    setAdjFormDialogOpen(false);
  }

  function handleSave(formData) {
    dispatch(
      submitAlertRecordBatchAssessment({
        alertId: alertId,
        alertMasterId: alertMasterId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    const adjObjectUuIds = _.get(alertData, "adjObjectUuIds");
    const objectKeys = Object.keys(objectsDataMap);
    if (_.difference(adjObjectUuIds, objectKeys).length === 0) {
      setAdjObjectsLoaded(true);
    }
  }, [objectsDataMap]);

  useEffect(() => {
    if (adjObjectUuIds && _.isArray(adjObjectUuIds)) {
      adjObjectUuIds.forEach((o) => {
        dispatch(
          getObject({
            domainId: "*",
            uuId: o,
          })
        );
      });
    }
  }, [adjObjectUuIds]);

  useEffect(() => {
    if (
      status &&
      status.method === "submitAlertRecordBatchAssessment" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(
        `Batch assessment request placed for ${
          _.isArray(selectedRecords) && selectedRecords.length === 0
            ? "All"
            : selectedRecords.length
        } records`,
        {
          variant: "success",
        }
      );

      handleAdjFormDialogClose();
      handleCancel();
    }
  }, [status]);

  return (
    <React.Fragment>
      <NtaiPanel
        padding="0px"
        width="100%"
        subheader="Batch update allows you to make changes to multiple items simultaneously using the selected form. Select the form to proceed with batch update."
        // handleSave={handleSelect}
        handleCancel={handleCancel}
        // handleSaveLabel="Next"
      >
        <Box sx={{ display: "flex", height: "210px" }}>
          <Scrollbar>
            {adjObjectsLoaded &&
              adjObjectUuIds &&
              _.isArray(adjObjectUuIds) &&
              adjObjectUuIds.length > 0 && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: theme.spacing(2),
                  }}
                >
                  {adjObjectUuIds.map((o, i) => {
                    const adjForm = objectsDataMap[o]["objectForm"];
                    if (adjForm && !_.isEmpty(adjForm)) {
                      return (
                        <Box key={`ntai-monitor-batch-form-select-${i}`}>
                          <NtaiCompactCard
                            id={o}
                            title={adjForm["label"].toUpperCase()}
                            description={adjForm["helpText"]}
                            handleClick={handleSelect}
                          >
                            {adjForm["helpText"] || ""}
                          </NtaiCompactCard>
                        </Box>
                      );
                    }
                  })}
                </Box>
              )}
          </Scrollbar>
        </Box>
      </NtaiPanel>
      {selectedAdjObjectUuId && selectedAdjObjectUuId.length > 0 && (
        <NtaiDialog
          title={`Batch Assessment - ${objectsDataMap[selectedAdjObjectUuId]["objectForm"]["label"]}`}
          open={adjFormDialogOpen}
          handleDialogClose={handleAdjFormDialogClose}
          size="md"
        >
          <NtaiForm>
            <AlertRecordBatchAssessment
              adjObject={objectsDataMap[selectedAdjObjectUuId]}
              handleSave={handleSave}
              handleCancel={handleAdjFormDialogClose}
            />
          </NtaiForm>
        </NtaiDialog>
      )}
    </React.Fragment>
  );
}
