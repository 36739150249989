import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import history from "@ntai/@history";

export default function ContactForm(props) {
  const theme = useTheme();
  const { action, formData, handleFormSubmit } = props;

  const { getValues, reset, setValue } = useFormContext();

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("contactForm")
    );

    handleFormSubmit(sanitizedFormData);
  }

  function onHandleCancel() {
    history.push("/library/contacts");
  }

  useEffect(() => {
    reset({ contactForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiFormPanel
      width="50%"
      header="Create Contact"
      subheader="Create or edit contact details such as names, address, emails and phone numbers."
      handleSave={onHandleSave}
      handleCancel={onHandleCancel}
    >
      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <NtaiTextField
          name="contactForm.firstName"
          label="First Name*"
          placeholder="First Name"
          rules={{ required: "First name is required" }}
        />
        <NtaiTextField
          name="contactForm.middleName"
          label="Middle Name"
          placeholder="Middle Name"
        />
        <NtaiTextField
          name="contactForm.lastName"
          label="Last Name*"
          placeholder="Last Name"
          rules={{ required: "Last name is required" }}
        />
      </Box>

      <NtaiTextField
        name="contactForm.streetAddress1"
        label="Street Address 1"
        placeholder="Street Address 1"
      />
      <NtaiTextField
        name="contactForm.streetAddress2"
        label="Street Address 2"
        placeholder="Street Address 2"
      />

      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiTextField
          name="contactForm.city"
          label="City"
          placeholder="City"
        />
        <NtaiTextField
          name="contactForm.state"
          label="State"
          placeholder="State"
        />
        <NtaiTextField
          name="contactForm.postalCode"
          label="Postal Code"
          placeholder="Postal Code"
        />
      </Box>
      <NtaiSelectField
        name="contactForm.countryCode"
        label="Country"
        options={[]}
      />
      <NtaiTextField
        name="contactForm.email"
        label="Email*"
        type="email"
        rules={{ required: "Email is required" }}
      />
      <NtaiTextField
        name="contactForm.phoneNo"
        label="Phone Number"
        placeholder="Phne Number"
      />
    </NtaiFormPanel>
  );
}
