import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import React from "react";
import useDimensions from "react-cool-dimensions";
import NtaiBarHorizontalSeriesChart from "./NtaiBarHorizontalSeriesChart";
import NtaiBarVertical from "./NtaiBarVerticalSeriesChart";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBarVerticalStackedChart from "./NtaiBarVerticalStackedChart";
import NtaiBarHorizontalStackedChart from "./NtaiBarHorizontalStackedChart";
import NtaiTestTooltip from "./NtaiTestTooltip";
import NtaiDateHistogramSeriesChart from "./NtaiDateHistogramSeriesChart";
import NtaiLineSeriesChart from "./NtaiLineSeriesChart";
import NtaiWordCloudChart from "./NtaiWordCloudChart";
import NtaiDateHistogramChart from "../NtaiDateHistogramChart";
import NtaiVerticalBarStackedEChart from "../../echarts/bar/NtaiVerticalBarStackedEChart";
import NtaiGanttChart from "./NtaiGanttChart";
const _ = require("lodash");

const barHorizontalData = [
  {
    status: "Completed",
    phase1: 2,
    phase2: 12,
    phase3: 2,
    phase4: 12,
  },
  {
    status: "Pending",
    phase1: 2,
    phase2: 10,
    phase3: 2,
    phase4: 5,
  },
  {
    status: "Reviewed",
    phase1: 1,
    phase2: 15,
    phase3: 2,
    phase4: 21,
  },
  {
    status: "Terminated",
    phase1: 8,
    phase2: 45,
    phase3: 21,
    phase4: 2,
  },
];

const ganttData = [
  {
    category: "drug1",
    type: "drug",
    start_date: "1594267200000",
    end_date: "1632974400000",
  },
  {
    category: "reac1",
    type: "reac",
    start_date: "1494267200000",
    end_date: "1632974400000",
  },
];

// const ganttData = [
//   {
//     category: "Module #1",
//     start_date: "2016-01-01",
//     end_date: "2016-01-14",
//     task: "Gathering requirements",
//   },
//   {
//     category: "Module #1",
//     start_date: "2016-01-16",
//     end_date: "2016-01-27",
//     task: "Producing specifications",
//   },
//   {
//     category: "Module #1",
//     start_date: "2016-02-05",
//     end_date: "2016-04-18",
//     task: "Development",
//   },
//   {
//     category: "Module #1",
//     start_date: "2016-04-18",
//     end_date: "2016-04-30",
//     task: "Testing and QA",
//   },
//   {
//     category: "Module #2",
//     start_date: "2016-01-08",
//     end_date: "2016-01-10",
//     task: "Gathering requirements",
//   },
//   {
//     category: "Module #2",
//     start_date: "2016-01-12",
//     end_date: "2016-01-15",
//     task: "Producing specifications",
//   },
//   {
//     category: "Module #2",
//     start_date: "2016-01-16",
//     end_date: "2016-02-05",
//     task: "Development",
//   },
// ];

// const ganttData = [
//   {
//     end_date: "2021-09-30",
//     start_date: "2020-07-09",
//     category: "Febrile neutropenia",
//     type: "reac",
//   },
//   {
//     end_date: "2021-09-03",
//     start_date: "2020-07-09",
//     category: "Febrile neutropenia",
//     type: "reac",
//   },
//   {
//     end_date: "2021-09-23",
//     start_date: "2020-07-09",
//     category: "Febrile neutropenia",
//     type: "reac",
//   },
//   {
//     end_date: "2021-07-27",
//     start_date: "2020-07-09",
//     category: "Tendon disorder",
//     type: "reac",
//   },
//   {
//     end_date: "2021-08-04",
//     start_date: "2020-07-09",
//     category: "Tendon ght",
//     type: "reac",
//   },
//   {
//     end_date: "2021-08-20",
//     start_date: "2020-07-09",
//     category: "Drug",
//     type: "drug",
//   },
//   {
//     end_date: "2021-09-23",
//     start_date: "2020-07-09",
//     category: "One more",
//     type: "drug",
//   },
// ];

const dateChart = [
  {
    date_started: 1487274664712,
    count: 12,
  },
  {
    date_started: 1687274664712,
    count: 4,
  },
];

const dateSeriesChart = [
  {
    date_started: 1487274664712,
    phase1: 1,
    phase2: 1,
    phase3: 33,
    phase4: 8,
  },
  {
    date_started: 1687274664712,
    phase1: 2,
    phase2: 12,
    phase3: 2,
    phase4: 5,
  },
];

const wordCloudData = [
  {
    tag: "Breaking News",
    weight: 60,
  },
  {
    tag: "Environment",
    weight: 80,
  },
  {
    tag: "Politics",
    weight: 90,
  },
  {
    tag: "Business",
    weight: 25,
  },
  {
    tag: "Lifestyle",
    weight: 30,
  },
  {
    tag: "World",
    weight: 45,
  },
  {
    tag: "Sports",
    weight: 160,
  },
  {
    tag: "Fashion",
    weight: 20,
  },
  {
    tag: "Education",
    weight: 78,
  },
];

const barData = {
  categories: [
    "Mon long long name and this",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ],
  data: [120, 200, 150, 80, 70, 110, 130],
};

export default function NtaiTestAmcharts2() {
  const theme = useTheme();

  function handleClick(t1, t2) {
    console.log("Clicked: ", t1, t2);
  }

  function handleClick1(params) {}

  function generateItems() {
    const items = [];
    // items.push(
    //   <Box key={1} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Tree map">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiBarHorizontalSeriesChart
    //           widgetId="123"
    //           data={barHorizontalData}
    //           categoryField="status"
    //           metadata={{
    //             subCategoryValues: [
    //               { value: "phase1", label: "Phase 1" },
    //               { value: "phase2", label: "Phase 2" },
    //               { value: "phase3", label: "Phase 3" },
    //               { value: "phase4", label: "Phase 4" },
    //             ],
    //           }}
    //         />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={2} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Tree map">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiBarVertical
    //           widgetId="345"
    //           data={barHorizontalData}
    //           categoryField="status"
    //           metadata={{
    //             subCategoryValues: [
    //               { value: "phase1", label: "Phase 1" },
    //               { value: "phase2", label: "Phase 2" },
    //               { value: "phase3", label: "Phase 3" },
    //               { value: "phase4", label: "Phase 4" },
    //             ],
    //           }}
    //         />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={3} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Bar Vert Stacked">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiBarVerticalStackedChart
    //           widgetId="1345"
    //           data={barHorizontalData}
    //           categoryField="status"
    //           metadata={{
    //             subCategoryValues: [
    //               { value: "phase1", label: "Phase 1" },
    //               { value: "phase2", label: "Phase 2" },
    //               { value: "phase3", label: "Phase 3" },
    //               { value: "phase4", label: "Phase 4" },
    //             ],
    //           }}
    //         />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={4} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Bar Vert Stacked">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiBarHorizontalStackedChart
    //           widgetId="1375"
    //           data={barHorizontalData}
    //           categoryField="status"
    //           metadata={{
    //             subCategoryValues: [
    //               { value: "phase1", label: "Phase 1" },
    //               { value: "phase2", label: "Phase 2" },
    //               { value: "phase3", label: "Phase 3" },
    //               { value: "phase4", label: "Phase 4" },
    //             ],
    //           }}
    //         />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={5} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Tree map">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiTestTooltip />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={1} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Date hist series">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiDateHistogramSeriesChart
    //           widgetId="1376"
    //           data={dateSeriesChart}
    //           categoryField="date_started"
    //           metadata={{
    //             subCategoryValues: [
    //               { value: "phase1", label: "Phase 1" },
    //               { value: "phase2", label: "Phase 2" },
    //               { value: "phase3", label: "Phase 3" },
    //               { value: "phase4", label: "Phase 4" },
    //             ],
    //           }}
    //         />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={2} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Word cloud">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiWordCloudChart
    //           widgetId="1376"
    //           data={wordCloudData}
    //           categoryField="tag"
    //           valueField="weight"
    //           handleClick={handleClick}
    //         />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={3} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Word cloud">
    //       <Box
    //         sx={{
    //           display: "flex",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         <NtaiDateHistogramChart
    //           widgetId="1376-date"
    //           data={dateChart}
    //           categoryField="date_started"
    //           valueField="count"
    //           handleClick={handleClick}
    //         />
    //       </Box>
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    // items.push(
    //   <Box key={4} sx={{ display: "flex", width: "100%", height: "100%" }}>
    //     <NtaiSourceWidget title="Apache eChart">
    //       <NtaiVerticalBarStackedEChart
    //         data={barData}
    //         handleClick={handleClick1}
    //       />
    //     </NtaiSourceWidget>
    //   </Box>
    // );

    items.push(
      <Box key={1} sx={{ display: "flex", width: "100%", height: "100%" }}>
        <NtaiSourceWidget title="Gantt chart">
          <NtaiGanttChart
            data={ganttData}
            handleClick={handleClick1}
            metadata={{
              categoryField: "category",
              subCategoryField: "type",
              startDateField: "start_date",
              endDateField: "end_date",
            }}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return items;
  }
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        padding: theme.spacing(1),
        background: grey[50],
      }}
    >
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
