import {
  Avatar,
  Box,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useState } from "react";
import History from "@ntai/@history/@history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFileChartColumn,
  faFolders,
  faObjectGroup,
} from "@fortawesome/pro-light-svg-icons";
import NtaiCompactCard2 from "@ntai/components/cards/NtaiCompactCard2";
import NtaiIcons from "utils/NtaiIcons";

const menuItems = [
  {
    id: "1",
    label: "Report Definitions",
    icon: <FontAwesomeIcon icon={faFileChartColumn} />,
  },
  {
    id: "2",
    label: "Report Categories",
    icon: <FontAwesomeIcon icon={faObjectGroup} />,
  },
  {
    id: "3",
    label: "Report Folders",
    icon: <FontAwesomeIcon icon={faFolders} />,
  },
];
export default function ReportAdmin() {
  const theme = useTheme();

  function handleSelect(menuId) {
    if (menuId === "1") History.push("/reports/definitions");
    if (menuId === "2") History.push("/reports/categories");
    if (menuId === "3") History.push("/reports/folders");
  }

  return (
    <NtaiPage title="Report Administration" back="/reports">
      <Box
        sx={{
          p: theme.spacing(2),
          display: "flex",
          height: "180px",
          width: "75%",
          justifyContent: "space-between",
          alignItems: "center",
          gap: theme.spacing(4),
        }}
      >
        {menuItems.map((mi, i) => (
          <NtaiCompactCard2
            key={`report-admin-${i}`}
            id={mi.id}
            avatar={<FontAwesomeIcon icon={NtaiIcons[mi.icon]} />}
            title={mi.label}
            hits="23k"
            handleClick={() => handleSelect(mi.id)}
            description="NLM sposnored medical research for cancer related trials. NLM sposnored
                  medical research for cancer related trials"
          />
        ))}
      </Box>
    </NtaiPage>
  );
}
