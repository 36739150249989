import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getRoleResources = createAsyncThunk(
  "roleResources/getRoleResources",
  async (values, { rejectWithValue }) => {
    try {
      const { roleId, params } = values;
      const response = await server.get(
        `/admin/roles/${roleId}/resources`,
        params && {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createRoleResource = createAsyncThunk(
  "roleResources/createRoleResource",
  async (values, { rejectWithValue }) => {
    try {
      const { roleId, formData } = values;
      const response = await server.post(
        `/admin/roles/${roleId}/resources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRoleResource = createAsyncThunk(
  "roleResources/deleteRoleResource",
  async (values, { rejectWithValue }) => {
    try {
      const { roleId, uuId } = values;
      const response = await server.delete(
        `/admin/roles/${roleId}/resources/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const roleResourcesSlice = createSlice({
  name: "roleResources",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getRoleResources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getRoleResources",
      };
    },
    [getRoleResources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getRoleResources",
        message: action.payload.message,
      };
    },

    [createRoleResource.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createRoleResource",
      };
      state.activeId = action.payload.uuId;
    },
    [createRoleResource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createRoleResource",
        message: action.payload.message,
      };
    },

    [deleteRoleResource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteRoleResource",
      };
    },
    [deleteRoleResource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteRoleResource",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = roleResourcesSlice.actions;

export default roleResourcesSlice.reducer;
