import { Box, FormControl, FormControlLabel, Slider } from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiInputErrorMessage from "./NtaiInputErrorMessage";

const NtaiMuiSlider = (props) => {
  const { label, name } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl>
      <FormControlLabel
        label={<Box sx={{ marginLeft: "8px" }}>{label}</Box>}
        control={<Slider id={name} {...props} />}
      />
      <NtaiInputErrorMessage name={name} errors={errors} />
    </FormControl>
  );
};

function NtaiSliderField(props) {
  const { control } = useFormContext();
  const { name } = props;
  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <NtaiMuiSlider onChange={(_, val) => onChange(val)} {...props} />
        )}
        control={control}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiSliderField;
