import { combineReducers } from "@reduxjs/toolkit";
import resourcesSlice from "../admin/resource/store/resourcesSlice";
import usersSlice from "../admin/user/store/usersSlice";
import userGroupsSlice from "../admin/user-group/store/userGroupsSlice";
import userGroupUsersSlice from "../admin/user-group/users/store/userGroupUsersSlice";
import userGroupResourcesSlice from "../admin/user-group/resources/store/userGroupResourcesSlice";
import rolesSlice from "../admin/role/store/rolesSlice";
import roleResourcesSlice from "../admin/role/resources/store/roleResourcesSlice";
import domainsSlice from "../cdo/domain/store/domainsSlice";
import objectsSlice from "../cdo/object/store/objectsSlice";
import objectInfoGeneralFieldsSlice from "../cdo/object/info/general/store/objectInfoGeneralFieldsSlice";
import objectInfoListingFieldsSlice from "../cdo/object/info/listing/store/objectInfoListingFieldsSlice";
import objectFieldsSlice from "../cdo/object/field/store/objectFieldsSlice";
import objectRelsSlice from "../cdo/object/relation/store/objectRelsSlice";
import objectTopicsSlice from "../cdo/object/topic/store/objectTopicsSlice";
import objectSourceViewsSlice from "../cdo/object/source-view/store/objectSourceViewsSlice";
import objectDashboardsSlice from "../cdo/object/dashboard/store/objectDashboardsSlice";
import objectFormsSlice from "../cdo/object/form/store/objectFormsSlice";
import objectFormBuilderTabsSlice from "../cdo/object/form/tab/store/objectFormBuilderTabsSlice";
import objectFormBuilderPanelsSlice from "../cdo/object/form/panel/store/objectFormBuilderPanelsSlice";
import clientDataTopicsSlice from "../cdo/topic/store/clientDataTopicsSlice";
import entitiesSlice from "../entity/store/entitiesSlice";
import entityTopicsSlice from "../entity/topic/store/entityTopicsSlice";
import entityParentSlice from "../entity/general/parent/store/entityParentSlice";
import entitySourcesSlice from "../entity/source/store/entitySourcesSlice";
import entityMinsSlice from "../entity/store/entityMinsSlice";
import codelistsSlice from "../admin/codelist/store/codelistsSlice";
import codelistCodesSlice from "../admin/codelist/code/store/codelistCodesSlice";
import topicsSlice from "../library/topic/store/topicsSlice";
import schedulersSlice from "../admin/scheduler/store/schedulersSlice";
import schedulerJobsSlice from "../admin/scheduler/jobs/store/schedulerJobsSlice";
import modelsReducer from "../models/store";
import widgetsSlice from "../admin/widget/store/widgetsSlice";
import thesaurusesSlice from "../library/thesaurus/store/thesaurusesSlice";
import thesaurusSemTypesSlice from "../library/thesaurus/semtype/store/thesaurusSemTypesSlice";
import thesaurusConceptsSlice from "../library/thesaurus/concept/store/thesaurusConceptsSlice";
import thesaurusConceptSynonymsSlice from "../library/thesaurus/concept/synonym/store/thesaurusConceptSynonymsSlice";
import thesaurusConceptRelationsSlice from "../library/thesaurus/concept/relation/store/thesaurusConceptRelationsSlice";
import thesaurusLoadSlice from "../library/thesaurus/load/store/thesaurusLoadSlice";
import thesaurusSourcesSlice from "../library/thesaurus/source/store/thesaurusSourcesSlice";
import contactsSlice from "../library/contact/store/contactsSlice";
import contactGroupsSlice from "../library/contact-group/store/contactGroupsSlice";
import distributionGroupsSlice from "../library/distribution-group/store/distributionGroupsSlice";
import distributionGroupObjectsSlice from "../library/distribution-group/objects/store/distributionGroupObjectsSlice";
import entityMenusSlice from "../entity/menu/store/entityMenusSlice";
import testAmchartsSlice from "@ntai/components/charts/amcharts/store/testAmchartsSlice";
import reportTemplatesSlice from "../templates/report/store/reportTemplatesSlice";
import dashboardViewsSlice from "../admin/dashboard/views/store/dashboardViewsSlice";
import dashboardSourceViewsSlice from "../admin/dashboard/widgets/source-widget/store/dashboardSourceViewsSlice";
import dashboardWidgetsSlice from "../admin/dashboard/widgets/store/dashboardWidgetsSlice";
import dashboardWidgetFieldsSlice from "../admin/dashboard/widgets/query-widget/fields/store/dashboardWidgetFieldsSlice";
import workflowDefsSlice from "../admin/workflow/store/workflowDefsSlice";
import workflowDefVersionsSlice from "../admin/workflow/versions/store/workflowDefVersionsSlice";
import workflowDefVersionActivitiesSlice from "../admin/workflow/versions/activities/store/workflowDefVersionActivitiesSlice";
import workflowDefVersionActivityRulesSlice from "../admin/workflow/versions/activities/rules/store/workflowDefVersionActivityRulesSlice";
import rulesSlice from "../admin/rule/store/rulesSlice";
import ruleConditionsSlice from "../admin/rule/conditions/store/ruleConditionsSlice";
import migrationsSlice from "../migration/store/migrationsSlice";
import dataAdaptersSlice from "../adapter/store/dataAdaptersSlice";
import dataAdapterJobsSlice from "../adapter/job/store/dataAdapterJobsSlice";
import awsClustersSlice from "../admin/aws/cluster/store/awsClustersSlice";
import awsClusterTemplatesSlice from "../admin/aws/template/store/awsClusterTemplatesSlice";
import awsClusterTemplateSourcesSlice from "../admin/aws/template/source/store/awsClusterTemplateSourcesSlice";
import awsClusterSessionsSlice from "../admin/aws/cluster/session/store/awsClusterSessionsSlice";
import userDatasetsSlice from "../library/user-dataset/store/userDatasetsSlice";
import userQueriesSlice from "../library/user-query/store/userQueriesSlice";
import userDatasetFilterSchemesSlice from "../library/user-dataset/filter-scheme/store/userDatasetFilterSchemesSlice";
import userDatasetSourceViewsSlice from "../library/user-dataset/source-view/store/userDatasetSourceViewsSlice";
import userDatasetRecordsSlice from "../library/user-dataset/records/store/userDatasetRecordsSlice";
import userChangePasswordSlice from "../account/change-password/store/userChangePasswordSlice";
import notificationDefsSlice from "../admin/notification-definition/store/notificationDefsSlice";
import datasourcesSlice from "../admin/datasource/store/datasourcesSlice";
import datasourceJdbcMetadataSlice from "../admin/datasource/metadata/store/datasourceJdbcMetadataSlice";
import sourceSeriesSlice from "../library/source-series/store/sourceSeriesSlice";
import settingsSlice from "../admin/setting/store/settingsSlice";
import userNotificationsSlice from "../user-notification/store/userNotificationsSlice";

const coreReducer = combineReducers({
  resourcesSlice,
  usersSlice,
  userGroupsSlice,
  userGroupUsersSlice,
  userGroupResourcesSlice,
  rolesSlice,
  roleResourcesSlice,
  domainsSlice,
  objectsSlice,
  objectFieldsSlice,
  objectRelsSlice,
  objectTopicsSlice,
  objectSourceViewsSlice,
  objectDashboardsSlice,
  objectFormsSlice,
  objectFormBuilderTabsSlice,
  objectFormBuilderPanelsSlice,
  objectInfoGeneralFieldsSlice,
  objectInfoListingFieldsSlice,
  clientDataTopicsSlice,
  entitiesSlice,
  entityParentSlice,
  entityTopicsSlice,
  entitySourcesSlice,
  entityMinsSlice,
  schedulersSlice,
  schedulerJobsSlice,
  codelistsSlice,
  codelistCodesSlice,
  topicsSlice,
  modelsReducer,
  widgetsSlice,
  thesaurusesSlice,
  thesaurusSemTypesSlice,
  thesaurusConceptsSlice,
  thesaurusConceptSynonymsSlice,
  thesaurusConceptRelationsSlice,
  thesaurusLoadSlice,
  thesaurusSourcesSlice,
  entityMenusSlice,
  testAmchartsSlice,
  reportTemplatesSlice,
  dashboardViewsSlice,
  dashboardWidgetsSlice,
  dashboardWidgetFieldsSlice,
  dashboardSourceViewsSlice,
  workflowDefsSlice,
  workflowDefVersionsSlice,
  workflowDefVersionActivitiesSlice,
  workflowDefVersionActivityRulesSlice,
  rulesSlice,
  ruleConditionsSlice,
  migrationsSlice,
  contactsSlice,
  contactGroupsSlice,
  distributionGroupsSlice,
  distributionGroupObjectsSlice,
  dataAdaptersSlice,
  dataAdapterJobsSlice,
  awsClustersSlice,
  awsClusterTemplatesSlice,
  awsClusterTemplateSourcesSlice,
  awsClusterSessionsSlice,
  userDatasetsSlice,
  userQueriesSlice,
  userDatasetFilterSchemesSlice,
  userDatasetSourceViewsSlice,
  userDatasetRecordsSlice,
  userChangePasswordSlice,
  notificationDefsSlice,
  datasourcesSlice,
  datasourceJdbcMetadataSlice,
  sourceSeriesSlice,
  settingsSlice,
  userNotificationsSlice,
});

export default coreReducer;
