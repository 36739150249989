import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearches, clearStatus, deleteSearch } from "./store/searchSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import history from "@ntai/@history";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faStar } from "@fortawesome/pro-solid-svg-icons";
import { faFilter, faSearch } from "@fortawesome/pro-regular-svg-icons";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { useSnackbar } from "notistack";
import {
  faArchive,
  faArrowRightFromBracket,
  faChartUser,
  faClock,
  faFilterList,
  faGrid,
  faList,
  faShare,
} from "@fortawesome/pro-light-svg-icons";
import { SearchListContext } from "./SearchListWrapper";
import SearchItemBox from "./SearchItemBox";
import { grey } from "@mui/material/colors";

const _ = require("lodash");

const FILTER_CODES = [
  {
    value: 1,
    label: "All",
    icon: faList,
  },
  {
    value: 2,
    label: "Shared",
    icon: faShare,
  },
  {
    value: 3,
    label: "Favorites",
    icon: faStar,
  },
  {
    value: 4,
    label: "Published",
    icon: faArrowRightFromBracket,
  },
];

function displayIcon(col) {
  if (col[0].colValue === 1 && col[0].colId === "lockedFg")
    return <FontAwesomeIcon icon={faLock} color="red" />;
  else if (col[0].colValue === 1 && col[0].colId === "favoriteFg")
    return <FontAwesomeIcon icon={faStar} color="orange" />;
  else if (col[0].colId === "typeCode") {
    if (col[0].colValue === 1) return <FontAwesomeIcon icon={faSearch} />;
    else if (col[0].colValue === 2) return <FontAwesomeIcon icon={faFilter} />;
    else if (col[0].colValue === 4)
      return <FontAwesomeIcon icon={faFilterList} />;
    else return <FontAwesomeIcon icon={faChartUser} />;
  }
}

function getSharedUsers(col) {
  const result = [];
  if (
    col[0].colValue &&
    _.isArray(col[0].colValue) &&
    col[0].colValue.length > 0
  ) {
    col[0].colValue.forEach((cv, i) => {
      result.push(
        <Avatar
          key={`search-shared-${i}`}
          sx={{ fontSize: 10, width: 24, height: 24 }}
        >
          {cv}
        </Avatar>
      );
    });
  }

  if (result.length > 0)
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>{result}</Box>
    );
  else return result;
}

function getSourceNames(col) {
  const result = [];
  if (
    col[0].colValue &&
    _.isArray(col[0].colValue) &&
    col[0].colValue.length > 0
  ) {
    col[0].colValue.forEach((cv, i) => {
      if (!result.includes(cv.abbrev)) {
        result.push(cv.abbrev);
      }
    });
  }

  if (result.length > 0)
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {result.map((o, i) => (
          <Chip
            size="small"
            variant="outlined"
            key={`search-sources-${i}`}
            label={o}
          />
        ))}
      </Box>
    );
  else return result;
}

const headCells = [
  {
    id: "uuId",
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: ["typeCode"],
    disablePadding: true,
    label: "T",
    width: "2%",
    transformFunc: displayIcon,
  },

  {
    id: ["lockedFg"],
    disablePadding: true,
    label: <FontAwesomeIcon icon={faLock} />,
    width: "2%",
    transformFunc: displayIcon,
  },

  {
    id: ["favoriteFg"],
    disablePadding: true,
    label: <FontAwesomeIcon icon={faStar} />,
    width: "2%",
    transformFunc: displayIcon,
  },

  {
    id: "name",
    disablePadding: true,
    label: "Name",
    width: "25%",
    fontWeight: 700,
  },
  {
    id: "criteria",
    disablePadding: true,
    label: "Criteria",
    width: "20%",
  },
  {
    id: ["sourceMins"],
    disablePadding: true,
    label: "Sources",
    width: "15%",
    transformFunc: getSourceNames,
    align: "left",
  },
  {
    id: ["sharedUsers"],
    disablePadding: true,
    label: "Shared",
    width: "10%",
    transformFunc: getSharedUsers,
    align: "left",
  },

  {
    id: "userModified",
    disablePadding: true,
    label: "Modified By",
    width: "10%",
  },
  {
    id: "dateModified",
    dataType: "date",
    disablePadding: true,
    label: "Last Modified",
    width: "10%",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

function filterListByFilterText(str, data) {
  let result = data;
  if (_.isArray(data) && data.length > 0) {
    if (str && str !== undefined && str.length > 0) {
      result = _.filter(data, function (o) {
        if (o["name"].includes(str)) return true;
        else return false;
      });
    }
  }

  return result;
}

export default function SearchList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState();
  const [loaded, setLoaded] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGridItems, setSelectedGridItems] = useState([]);

  const {
    viewMode,
    setViewMode,
    filterCode,
    setFilterCode,
    searchListFilterText,
  } = React.useContext(SearchListContext);

  const dataMap = useSelector((state) => state.search.searchSlice.data);
  const status = useSelector((state) => state.search.searchSlice.status);
  const allData = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];
  const data = filterListByFilterText(searchListFilterText, allData);

  function toggleFilter(fc) {
    setFilterCode(fc);
  }

  function handleToolbarAction(actionName, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (actionName === "archive") {
      setAction(actionName);
      confirmDelete();
    }
  }

  function handleToolbarOtherAction(actionName) {
    if (actionName === "freesolo") {
      setAction("add");
      history.push("/search-init");
    } else if (actionName === "manual") {
      setAction("manual");
      history.push("/search-manual-init");
    }
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setSelectedIds([]);
    setSelectedItems([]);
    setSelectedGridItems([]);
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "archive") {
        dispatch(deleteSearch(id));
      }
    });
    setCurrentId(null);
  }

  function handleCreateOrEditSearch(id) {
    if (id) {
      setAction("edit");
      history.push(`/search/${id}/edit`);
    }
  }

  function toggleViewMode() {
    if (viewMode === "table") setViewMode("grid");
    if (viewMode === "grid") setViewMode("table");
  }

  function handleEdit(id) {
    history.push(`/search/${id}/edit`);
  }

  function updateSelectedGridItems(item) {
    if (selectedGridItems.includes(item)) {
      const tmpSelectedGridItems = _.filter(selectedGridItems, function (o) {
        return o !== item ? true : false;
      });
      setSelectedGridItems(tmpSelectedGridItems);
    } else {
      setSelectedGridItems([...selectedGridItems, item]);
    }
  }

  useEffect(() => {
    if (
      status &&
      (status.result === "error" || status.result === "success") &&
      status.method === "getSearches"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSearch"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setSelectedGridItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(`"${selectedItems.join(", ")}" deleted successfully.`, {
          variant: "success",
        });
      }
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSearches({ filterCode: filterCode }));
  }, [filterCode]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            px: theme.spacing(2),
            display: "flex",
            height: "44px",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: theme.general.border1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1.5),
            }}
          >
            {FILTER_CODES.map((o, i) => (
              <Chip
                key={`search-filter-code-${i}`}
                // sx={{ px: theme.spacing(1), py: theme.spacing(1) }}
                // avatar={<FontAwesomeIcon icon={o["icon"]} size="xs" />}
                variant={o["value"] === filterCode ? "contained" : "outlined"}
                size="small"
                label={
                  o["value"] === filterCode ? (
                    <Typography
                      fontWeight="700"
                      color={o["value"] === filterCode ? grey[900] : null}
                      variant="subtitle2"
                    >
                      {o["label"]}
                    </Typography>
                  ) : (
                    o["label"]
                  )
                }
                onClick={() => toggleFilter(o["value"])}
              />
            ))}
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              color="inherit"
              sx={{ display: "flex", gap: theme.spacing(1), fontSize: "13px" }}
              onClick={() => toggleViewMode()}
            >
              <FontAwesomeIcon icon={viewMode === "table" ? faGrid : faList} />
              <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                {viewMode === "table" ? "Grid Layout" : "List Layout"}
              </Typography>
            </Button>
            {viewMode !== "table" && (
              <React.Fragment>
                <Divider orientation="vertical" flexItem />
                <Button
                  size="small"
                  color="inherit"
                  sx={{
                    display: "flex",
                    gap: theme.spacing(1),
                    fontSize: "13px",
                  }}
                  onClick={() =>
                    handleToolbarAction("archive", selectedGridItems)
                  }
                  disabled={selectedGridItems.length === 0 ? true : false}
                >
                  <FontAwesomeIcon icon={faArchive} />
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    Delete
                  </Typography>
                </Button>
              </React.Fragment>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: `calc(100% - 44px)`,
            width: "100%",
            px: theme.spacing(2),
          }}
        >
          {viewMode === "table" && loaded && data.length > 0 && (
            <NtaiSimpleTable
              rows={data}
              selectedIds={selectedIds}
              headCells={headCells}
              toolbarActions={[{ value: "archive", label: "Delete" }]}
              handleToolbarOtherAction={handleToolbarOtherAction}
              keyColumn="uuId"
              dialog={true}
              handleToolbarAction={handleToolbarAction}
              handleDialogAddEdit={handleCreateOrEditSearch}
              title={`Search Records (${data.length})`}
            />
          )}

          {viewMode === "grid" && loaded && data.length > 0 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                // gridTemplateRows: "auto",
                // flexWrap: "wrap",
                gap: theme.spacing(2),
                py: theme.spacing(2),
                alignContent: "start",
              }}
            >
              {data.map((o, i) => (
                <SearchItemBox
                  key={`search-item-${i}`}
                  id={i}
                  item={o}
                  itemSelected={
                    selectedGridItems.includes(o["uuId"]) ? true : false
                  }
                  handleEdit={handleEdit}
                  updateSelectedGridItems={updateSelectedGridItems}
                />
              ))}
            </Box>
          )}

          {loaded && data.length === 0 && (
            <NtaiEmptyMessage
              vAlign="center"
              header="No items available"
              subheader="Click on + NEW ANALYSIS button to create new analysis using various options available."
            />
          )}

          {!loaded && (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NtaiCircularProgress />
            </Box>
          )}
        </Box>
      </Box>

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
