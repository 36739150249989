import { faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
const _ = require("lodash");

export default function AlertDetailsBox(props) {
  const theme = useTheme();
  const { header, icon, width, bgcolor, color, textAlign, children } = props;
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        px: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          width: _.has(props, "width") ? props.width : null,
          p: theme.spacing(0.5),
          display: "flex",
          flexDirection: "column",
          background: _.has(props, "bgcolor") ? bgcolor : null,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: _.has(props, "textAlign") ? props.textAlign : null,
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            color={_.has(props, "color") ? color : "inherit"}
            fontWeight="700"
          >
            {children}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
            justifyContent: _.has(props, "textAlign") ? props.textAlign : null,
          }}
        >
          {icon && <FontAwesomeIcon size="xs" icon={icon} />}
          <Typography
            variant="subtitle2"
            color={_.has(props, "color") ? color : "inherit"}
            fontWeight="300"
          >
            {header}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
