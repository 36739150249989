import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
const _ = require("lodash");

function fetchInitialValues(val) {
  let result = [];
  if (val && val !== undefined && typeof val === "string" && val.length > 0) {
    const arrString = val.split(",");
    console.log("arrString: ", arrString);
    if (arrString.length > 0) {
      arrString.forEach((x, i) => {
        result.push(1 * x);
      });
    }
  }

  return result;
}
const NtaiCheckboxGroupField = (props) => {
  const { name, options, cbvalues } = props;
  const { setValue } = useFormContext();
  const [selectedItems, setSelectedItems] = useState([]);

  // we are handling the selection manually here
  const handleSelect = (val) => {
    const isPresent = selectedItems.indexOf(val);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== val);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, val]);
    }
  };

  useEffect(() => {
    if (
      _.isArray(selectedItems) &&
      selectedItems &&
      selectedItems.length > 0 &&
      !_.isEqual(selectedItems, cbvalues)
    ) {
      setValue(name, selectedItems);
    } else {
      setValue(name, []);
    }
  }, [selectedItems]);

  return (
    <>
      {options.map((option, i) => {
        return (
          <FormControlLabel
            key={`checkbox-${i}`}
            label={option.label}
            control={
              <Checkbox
                checked={
                  selectedItems.includes(option.value) ||
                  fetchInitialValues(cbvalues).includes(option.value)
                }
                onChange={() => handleSelect(option.value)}
              />
            }
          />
        );
      })}
    </>
  );
};

export const NtaiCheckboxGroupField1 = ({ name, label, options, width }) => {
  const { control } = useFormContext();
  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <Box
      sx={{ display: "flex", height: "100%", width: width ? width : "100%" }}
    >
      <FormControl size="small" variant="outlined">
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          control={control}
          name={name}
          render={({ field: { value } }) => {
            return (
              <>
                {fetchInitialValues(value) &&
                  _.isArray(fetchInitialValues(value)) && (
                    <NtaiCheckboxGroupField
                      name={name}
                      options={options}
                      cbvalues={value}
                    />
                  )}
              </>
            );
          }}
        />
      </FormControl>
    </Box>
  );
};
