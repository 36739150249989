import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../../../../../http/ntaiServer";
const _ = require("lodash");

export const getAlertDefinitions = createAsyncThunk(
  "alertDefinitions/getAlertDefinitions",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/monitor/definitions");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAlertDefinition = createAsyncThunk(
  "alertDefinitions/getAlertDefinition",
  async (alertDefinitionUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/monitor/definitions/${alertDefinitionUuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAlertDefinition = createAsyncThunk(
  "alertDefinitions/createAlertDefinition",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/monitor/definitions", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAlertDefinition = createAsyncThunk(
  "alertDefinitions/updateAlertDefinition",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/monitor/definitions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAlertDefinition = createAsyncThunk(
  "alertDefinitions/deleteAlertDefinition",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/monitor/definitions/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceCriteria = createAsyncThunk(
  "alertDefSources/updateSourceCriteria",
  async (values, { rejectWithValue }) => {
    try {
      const { definitionId, alertDefSourceId, formData } = values;
      const response = await server.patch(
        `/monitor/definitions/${definitionId}/criteria/${alertDefSourceId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAlertDefObjectTopic = createAsyncThunk(
  "alertDefinitions/createAlertDefObjectTopic",
  async (values, { rejectWithValue }) => {
    try {
      const { definitionId, alertDefSourceId, formData } = values;
      const response = await server.post(
        `/monitor/definitions/${definitionId}/sources/${alertDefSourceId}/objecttopics`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateSchedule = createAsyncThunk(
  "alertDefinitions/createOrUpdateSchedule",
  async (values, { rejectWithValue }) => {
    try {
      const { definitionId, alertDefSourceId, formData } = values;
      const response = await server.post(
        `/monitor/definitions/${definitionId}/schedule`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateWorkflow = createAsyncThunk(
  "alertDefinitions/createOrUpdateWorkflow",
  async (values, { rejectWithValue }) => {
    try {
      const { definitionId, alertDefSourceId, formData } = values;
      const response = await server.post(
        `/monitor/definitions/${definitionId}/workflow`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const executeAlertDefJob = createAsyncThunk(
  "alertDefinitions/executeAlertDefJob",
  async (values, { rejectWithValue }) => {
    try {
      const { definitionId, action } = values;
      const response = await server.get(
        `/monitor/definitions/${definitionId}/submit`,
        {
          params: {
            action: action,
          },
        }
      );
      return { action: action, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertDefinitionsSlice = createSlice({
  name: "alertDefinitions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertDefinitions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getAlertDefinitions" };
    },
    [getAlertDefinitions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertDefinitions",
        message: action.payload.message,
      };
    },
    [getAlertDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getAlertDefinition" };
    },
    [getAlertDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertDefinition",
        message: action.payload.message,
      };
    },
    [createAlertDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createAlertDefinition" };
    },
    [createAlertDefinition.pending]: (state, action) => {
      state.status = { result: "pending", method: "createAlertDefinition" };
    },

    [createAlertDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createAlertDefinition",
        message: action.payload.message,
      };
    },

    [updateSourceCriteria.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceCriteria" };
    },
    [updateSourceCriteria.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceCriteria",
        message: action.payload.message,
      };
    },

    [createAlertDefObjectTopic.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createAlertDefObjectTopic" };
    },
    [createAlertDefObjectTopic.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createAlertDefObjectTopic",
        message: action.payload.message,
      };
    },

    [updateAlertDefinition.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateAlertDefinition" };
    },
    [updateAlertDefinition.pending]: (state, action) => {
      state.status = { result: "pending", method: "updateAlertDefinition" };
    },

    [updateAlertDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateAlertDefinition",
        message: action.payload.message,
      };
    },
    [deleteAlertDefinition.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteAlertDefinition" };
    },
    [deleteAlertDefinition.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAlertDefinition",
        message: action.payload.message,
      };
    },

    [createOrUpdateSchedule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createOrUpdateSchedule" };
    },
    [createOrUpdateSchedule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateSchedule",
        message: action.payload.message,
      };
    },

    [createOrUpdateWorkflow.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createOrUpdateWorkflow" };
    },
    [createOrUpdateWorkflow.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateWorkflow",
        message: action.payload.message,
      };
    },

    [executeAlertDefJob.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.data.uuId]: action.payload.data,
      };
      state.status = {
        result: "success",
        method: "executeAlertDefJob",
        action: action.payload.action,
      };
    },
    [executeAlertDefJob.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "executeAlertDefJob",
        message: action.payload.message,
        action: action.payload.action,
      };
    },
  },
});

export const { clearStatus, setActiveId } = alertDefinitionsSlice.actions;

export default alertDefinitionsSlice.reducer;
