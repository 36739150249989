import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

export default function NtaiFormPanel(props) {
  const theme = useTheme();
  const {
    padding,
    header,
    headerActions,
    subheader,
    height,
    width,
    children,
    handleSave,
    saveLabel,
    saveDisabled,
    handleCancel,
    actionsJustifyContent,
    handleOtherActions,
  } = props;

  const { trigger } = useFormContext();

  const onHandleSubmit = async (e) => {
    // Trigger validations before submitting
    const isValid = await trigger();
    e.preventDefault();
    if (isValid) {
      handleSave();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: padding ? padding : theme.spacing(2),
        width: width ? width : "450px",
        height: height ? height : "100%",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      {(header || subheader) && (
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            gap: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              // flexBasis: headerActions ? "75%" : "100%",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
              // paddingBottom: theme.spacing(1),
            }}
          >
            <Typography variant="h4">{header}</Typography>

            {subheader && (
              <Typography variant="body2" color="textSecondary">
                {subheader}
              </Typography>
            )}
          </Box>

          {headerActions && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "start",
              }}
            >
              {headerActions}
            </Box>
          )}
        </Box>
      )}
      {children}

      <Box
        sx={{
          paddingTop: theme.spacing(1),
          display: "flex",
          gap: theme.spacing(1),
          justifyContent: actionsJustifyContent
            ? actionsJustifyContent
            : "start",
        }}
      >
        {handleSave && (
          <Button
            size="small"
            variant="contained"
            type="submit"
            onClick={(e) => onHandleSubmit(e)}
            disabled={
              props.hasOwnProperty("saveDisabled") && props.saveDisabled
                ? true
                : false
            }
          >
            {saveLabel ? saveLabel.toUpperCase() : "SAVE"}
          </Button>
        )}
        {handleOtherActions}
        {handleCancel && (
          <Button size="small" onClick={handleCancel}>
            CANCEL
          </Button>
        )}
      </Box>
    </Box>
  );
}
