import ContactCreate from "./ContactCreate";
import ContactEdit from "./ContactEdit";
import ContactList from "./ContactList";

const ContactConfig = {
  routes: [
    {
      path: "/library/contacts",
      exact: true,
      component: ContactList,
    },
    {
      path: "/library/contacts/create",
      exact: true,
      component: ContactCreate,
    },
    {
      path: "/library/contacts/:id/edit",
      exact: true,
      component: ContactEdit,
    },
  ],
};

export default ContactConfig;
