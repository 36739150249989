import React, { useEffect, useMemo, useState } from "react";
import { Box, lighten, useTheme } from "@mui/material";
import {
  gridConfig,
  legendConfig,
  tooltipConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import { grey } from "@mui/material/colors";
import NtaiEChartUtil, {
  CHART_LEFT_CLICK_ACTION,
} from "../util/NtaiEChartUtil";
import { xAxisLabelConfig, xAxisLineConfig } from "./barEChartConfig";
import NtaiBarEChartUtil, { barGridConfig } from "./util/NtaiBarEChartUtil";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

function formatToolTip(p) {
  console.log("tool: ", p);
}

const DEFAULT_OPTION = {
  grid: {
    containLabel: true,
    left: 20,
    top: 40,
    right: 20,
    bottom: 20,
  },
  legend: legendConfig,
  tooltip: {
    ...tooltipConfig,
    formatter: function (params) {
      return NtaiBarEChartUtil.getTooltipMultiSeries(params);
    },
  },
  xAxis: {
    type: "category",
    data: null,
    axisLabel: xAxisLabelConfig,
    axisLine: xAxisLineConfig,
  },
  yAxis: {
    type: "value",
    axisLabel: yAxisLabelConfig,
  },
  series: null,
};

export default function NtaiVerticalBarStackedEChart({
  widgetId,
  data,
  metadata,
  handleClick,
  handleContextMenu,
}) {
  const theme = useTheme();
  const [option, setOption] = useState(DEFAULT_OPTION);

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function getSourceWidgetFilterFields(action, params) {
    return metadataRef.current && !_.isEmpty(metadataRef.current)
      ? NtaiEChartUtil.getSourceWidgetFilterFieldsForCharts(
          [1, 2],
          params,
          metadataRef.current,
          action
        )
      : [];
  }

  function onChartClick(params) {
    handleClick(getSourceWidgetFilterFields(CHART_LEFT_CLICK_ACTION, params));
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(action, getSourceWidgetFilterFields(action, params));
  }

  function processValues(data, color) {
    let result = {};
    result["value"] = data["total"];
    result["_filterFg"] = metadata["filterFg"];
    result["_actual"] = metadata["filterFg"] ? data["value"] : data["total"];

    const revisedColor = data.hasOwnProperty("colorValue")
      ? NtaiEChartUtil.getItemStyleColor(
          "bg",
          data["colorValue"] && data["colorValue"].length > 0
            ? data["colorValue"]
            : data["value"],
          color,
          metadata
        )
      : color;

    result["_color"] = revisedColor;
    result["itemStyle"] = NtaiBarEChartUtil.getItemStyle(
      data,
      metadata,
      revisedColor
    );
    return result;
  }

  useEffect(() => {
    const tmpCategories = data["category"]["name"];
    const colors = NtaiEChartUtil.getColorPalette(
      theme,
      data["series"]["data"].length
    );
    const tmpSubCategories = data["series"]["data"].map((d, i) => {
      let subcats = [];
      data["data"].map((d1, j) => {
        const subcats1 = _.filter(d1["series"], function (v) {
          return v.name === d;
        });

        subcats.push(processValues(subcats1[0]["data"], colors[i], "v"));
      });

      return {
        name: d,
        type: "bar",
        stack: "one",
        data: subcats,
        cursor: "default",
        // label: {
        //   show: true,
        //   position: "inside",
        //   color: "#333",
        // },
      };
    });

    const copyOption = _.cloneDeep(option);
    copyOption["color"] = colors;
    copyOption["xAxis"]["data"] = tmpCategories;
    copyOption["series"] = tmpSubCategories;
    copyOption["series"]["label"] = {
      show: true,
      position: "inside",
      formatter: NtaiBarEChartUtil.getVBarLabelFormatter,
    };

    setOption(copyOption);
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"] && chart}
    </Box>
  );
}
