import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import {
  createAlertRecordAction,
  getAlertRecordActions,
  updateAlertRecordAction,
  clearStatus,
  deleteAlertRecordAction,
} from "./store/actionRecordActionsSlice";
import { AlertEditContext } from "../../../../AlertEdit";
import { useSelector, useDispatch } from "react-redux";
import AlertRecordActionForm from "./AlertRecordActionForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { useSnackbar } from "notistack";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiUtils from "@ntai/utils";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "action.title",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "25%",
  },
  {
    id: "action.statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action.owner",
    numeric: false,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "action.actualStartDate",
    numeric: false,
    disablePadding: false,
    label: "Actual Start",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "action.actualEndDate",
    numeric: false,
    disablePadding: false,
    label: "Actual End",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function AlertRecordActionList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const { selectedRecordId } = React.useContext(AlertEditContext);

  const alertId = useSelector((state) => state.monitor.alertsSlice.activeId);
  const alertDataMap = useSelector((state) => state.monitor.alertsSlice.data);
  const alertData = _.get(alertDataMap, alertId);
  const alertMasterId = _.get(alertData, "alertMasterUuId");

  const dispatchParams = {
    alertId: alertId,
    alertMasterId: alertMasterId,
    alertRecordId: selectedRecordId,
  };

  const dataMap = useSelector(
    (state) => state.monitor.alertRecordActionsSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  console.log("Data = ", data);
  const status = useSelector(
    (state) => state.monitor.alertRecordActionsSlice.status
  );

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "action.title"),
    ]);
    if (toolbarAction === "remove") confirmDelete();
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteAlertRecordAction({ ...dispatchParams, uuId: id }));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleSave(formData) {
    const params = {
      ...dispatchParams,
      formData: { action: formData },
    };

    if (action === "add") dispatch(createAlertRecordAction(params));
    else if (action === "edit")
      dispatch(
        updateAlertRecordAction({
          uuId: currentId,
          ...params,
        })
      );
  }

  useEffect(() => {
    if (
      status.result === "success" &&
      (status.method === "createAlertRecordAction" ||
        status.method === "updateAlertRecordAction")
    ) {
      enqueueSnackbar(`Action item created or updated`, {
        variant: "success",
      });
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteAlertRecordAction"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Action item(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getAlertRecordActions(dispatchParams));
    dispatch(getCodes(CODELIST_CODES.COM_ACTION_CATEGORY_CODE));
    dispatch(getCodes(CODELIST_CODES.COM_ACTION_STATUS_CODE));
    dispatch(getCodes(CODELIST_CODES.COM_ACTION_PRIORITY_CODE));
  }, []);

  return (
    <React.Fragment>
      {action === "list" && (
        <NtaiPanel
          width="100%"
          header="Actions"
          subheader="Action management involves tracking and monitoring action items for record(s). You can capture title, description, "
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            keyColumn="uuId"
            isDensed={true}
            dialog={true}
            toolbarActions={toolbarActions}
            handleDelete={confirmDelete}
            handleToolbarAction={handleToolbarAction}
            handleDialogAddEdit={handleDialogAddEdit}
            title="Action"
          />
        </NtaiPanel>
      )}
      {(action === "add" || action === "edit") && (
        <NtaiForm>
          <AlertRecordActionForm
            action={action}
            formData={
              action === "edit"
                ? _.get(_.get(dataMap, currentId), "action")
                : {}
            }
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "action.title"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
