import UserDatasetCreate from "./UserDatasetCreate";
import UserDatasetEdit from "./UserDatasetEdit";
import UserDatasetList from "./UserDatasetList";

const UserDatasetConfig = {
  routes: [
    {
      path: "/library/datasets",
      exact: true,
      component: UserDatasetList,
    },
    {
      path: "/library/datasets/create",
      exact: true,
      component: UserDatasetCreate,
    },
    {
      path: "/library/datasets/:id/edit",
      exact: true,
      component: UserDatasetEdit,
    },
  ],
};

export default UserDatasetConfig;
