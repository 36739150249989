import { Box } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React from "react";
import NtaiTestVBar2 from "./bar/NtaiTestVBar2";

export default function NtaiTestECharts2() {
  return (
    <NtaiTabs>
      <Box label="Vertical">
        <NtaiTestVBar2 />
      </Box>
    </NtaiTabs>
  );
}
