import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchSourceContext } from "../../../SearchSource";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import { SearchContext } from "../../../../SearchEdit";
import server from "app/http/ntaiServer";
import {
  createOrUpdateSearchSourceQueryBuilderFilter,
  clearStatus,
  deleteSearchSourceQueryBuilderFilter,
} from "../../../store/searchSourcesSlice";
import Scrollbar from "@ntai/components/Scrollbar";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
import { faCode } from "@fortawesome/pro-light-svg-icons";
import { grey } from "@mui/material/colors";

const _ = require("lodash");

export default function SearchSourceQueryBuilderFilter({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData, reload, setReload, setRefresh } =
    React.useContext(SearchSourceContext);
  const [sourceCriteria, setSourceCriteria] = useState();
  const [fieldsLoaded, setFieldsLoaded] = React.useState(false);
  const [esCriteria, setEsCriteria] = useState();
  const [fields, setFields] = useState();

  const sourceDefUuId = _.get(searchSourceData, "searchSourceDefinition.uuId");

  const sourceUuId = _.get(
    searchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const queryValue = _.get(
    searchSourceData,
    "searchSourceQueryBuilderFilter.criteria"
  );

  const searchSourceQueryBuilderFilterUuId = _.get(
    searchSourceData,
    "searchSourceQueryBuilderFilter.uuId"
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const status = useSelector((state) => state.search.searchSourcesSlice.status);

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  function buildQuery(tree, config, jsonTree, formats) {
    setSourceCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  function handleSave() {
    if (esCriteria) {
      dispatch(
        createOrUpdateSearchSourceQueryBuilderFilter({
          searchId: _.get(searchData, "uuId"),
          searchSourceId: _.get(searchSourceData, "uuId"),
          formData: {
            criteria: sourceCriteria,
            esCriteria: esCriteria,
          },
        })
      );
    } else handleReset();
  }

  function handleReset() {
    if (searchSourceQueryBuilderFilterUuId) {
      dispatch(
        deleteSearchSourceQueryBuilderFilter({
          searchId: _.get(searchData, "uuId"),
          searchSourceId: _.get(searchSourceData, "uuId"),
          uuId: searchSourceQueryBuilderFilterUuId,
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      [
        "createOrUpdateSearchSourceQueryBuilderFilter",
        "deleteSearchSourceQueryBuilderFilter",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      handleClose();
      setReload(reload + 1);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        sourceDefUuId,
        sourceUuId,
        mergedFields
      );

      setFields({ ...tmpFields });
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    dispatch(
      getSourceFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
    dispatch(
      getSourceDerivedFields({ sourceDefId: "undefined", sourceId: sourceUuId })
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "320px",
        width: "720px",
        flexDirection: "column",
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: theme.spacing(2),
          height: "64px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
        >
          <FontAwesomeIcon icon={faCode} />
          <Typography variant="h5" fontWeight="500" color={grey[700]}>
            Query
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <Button size="small" onClick={handleReset}>
            Reset Filters
          </Button>
          <Button size="small" variant="contained" onClick={handleSave}>
            Apply Filters
          </Button>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon size="xs" icon={faClose} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      {fieldsLoaded &&
        !_.isEmpty(fields) &&
        Object.keys(fields).length !== 0 && (
          <Box sx={{ display: "flex", height: `calc(100% - 64px)` }}>
            <Scrollbar>
              <NtaiQueryBuilder
                fields={fields}
                buildQuery={buildQuery}
                value={queryValue}
              />
            </Scrollbar>
          </Box>
        )}
    </Box>
  );
}
