import { Box, Button, useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSourceObject,
  deleteSourceObject,
  getSourceObjects,
  setActiveId,
  updateSourceObject,
  clearStatus,
} from "./store/sourceObjectsSlice";
import history from "@ntai/@history";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SourceObjectForm from "./SourceObjectForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

function transformFields(cols) {
  const result = [];
  if (_.isArray(cols)) {
    cols.forEach((colv, i) => {
      result.push(_.get(colv, "sourceField.label"));
    });
  }
  return _.join(result, ", ");
}

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "objectName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "objectLabel",
    numeric: false,
    disablePadding: false,
    label: "Label",
  },
  // {
  //   id: "sourceObjectFields",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Mapped Fields",
  //   transformFunc: transformFields,
  // },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function SourceObjectList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector((state) => state.sources.sourceObjectsSlice.data);
  const status = useSelector(
    (state) => state.sources.sourceObjectsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(
          deleteSourceObject({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceObject({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceObject({
            sourceDefId: sourceDefId,
            sourceId: sourceId,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "objectName"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceObjects" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceObject" ||
        status.method === "updateSourceObject")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceObject"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Object(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  function handleCancel() {
    history.push(`/sourcedefs/${sourceDefId}/smwrapper`);
  }

  useEffect(() => {
    dispatch(
      getSourceObjects({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        params: {
          sourceDefId: sourceDefId,
          sourceId: sourceId,
        },
      })
    );
  }, []);

  return (
    <>
      <NtaiPage
        padding={2}
        title={`${_.get(sourceDefData, "name")} / Objects`}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        {loaded && (
          <NtaiCrudTable
            helpText="Source to object mapping helps in the grouping of search, monitor and oversight items under object - topic hierarchy."
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleAddEdit}
            handleToolbarAction={handleToolbarAction}
            title="Source Object"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title={action === "add" ? "Assign Object" : "Edit Object"}
        size="sm"
      >
        <NtaiForm>
          <SourceObjectForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "objectName")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
