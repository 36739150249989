import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import {
  createOrDeleteSourcePipelineRefSourceDatasets,
  clearStatus,
  getSourcePipelineRefSourceDatasets,
} from "./store/sourcePipelineRefSourceDatasetsSlice";
import SourcePipelineRefSourceOutDatasetLookup from "./SourcePipelineRefSourceOutDatasetLookup";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function SourcePipelineRefSourceDatasetList({ handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const params = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourcePipelineId: sourcePipelineId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourcePipelineRefSourceDatasetsSlice.data
  );
  const data = Object.values(dataMap);
  const status = useSelector(
    (state) => state.sources.sourcePipelineRefSourceDatasetsSlice.status
  );

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function manage() {
    handleDialogOpen();
  }

  function handleFormSubmit(refSourceOutDatasets) {
    dispatch(
      createOrDeleteSourcePipelineRefSourceDatasets({
        ...params,
        formData: { refSourceOutDatasets: refSourceOutDatasets },
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourcePipelineRefSourceDatasets" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "createOrDeleteSourcePipelineRefSourceDatasets" &&
      status.result === "success"
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourcePipelineRefSourceDatasets(params));
  }, [sourcePipelineId]);

  return (
    <React.Fragment>
      <NtaiPanel
        width="50%"
        header="Reference Source Datasets"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      >
        {loaded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center",
                pb: theme.spacing(1),
              }}
            >
              <Button
                onClick={(e) => manage()}
                sx={{ fontSize: "11px" }}
                variant="contained"
                size="small"
              >
                MANAGE REF SOURCE DATASETS
              </Button>

              <Button sx={{ fontSize: "11px" }} size="small">
                REMOVE ALL
              </Button>
            </Box>
            <Typography variant="h6">
              Available Datasets ({data.length})
            </Typography>
            <Box
              sx={{
                display: "flex",
                height: "240px",
              }}
            >
              <Scrollbar>
                {data &&
                  data.length > 0 &&
                  data.map((rs, i) => (
                    <NtaiLabelValue
                      label={rs.refSourceDatasetName}
                      value={`${rs.refSourceMin.displayName} [${rs.refSourceOutStoreDecode}]`}
                      direction="row"
                    />
                  ))}
              </Scrollbar>
            </Box>
          </Box>
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPanel>

      <NtaiDialog
        title="Manage Reference Source Datasets"
        size="sm"
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
      >
        <SourcePipelineRefSourceOutDatasetLookup
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleDialogClose}
        />
      </NtaiDialog>
    </React.Fragment>
  );
}
