import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await server.get("/users", {
        params: params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getUser",
  async (userUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/users/${userUuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/users", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/users/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserRoles = createAsyncThunk(
  "users/updateUserRoles",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/users/${uuId}/roles`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/users/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "users/resetUserPassword",
  async (userUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/users/${userUuId}/reset-password`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    metadata: null,
    data: {},
    pageData: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    // [getUsers.fulfilled]: (state, action) => {
    //   state.metadata = action.payload.metadta;
    //   state.data = { ..._.mapKeys(action.payload.data, "uuId") };
    //   state.status = { result: "success", method: "getUsers" };
    // },
    [getUsers.fulfilled]: (state, action) => {
      // state.pageData = action.payload;
      state.metadata = action.payload.metadata;
      state.data = { ..._.mapKeys(action.payload.data, "uuId") };
      state.status = { result: "success", method: "getUsers" };
    },
    [getUsers.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUsers",
        message: action.payload.message,
      };
    },
    [getUser.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getUser" };
    },
    [getUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUser",
        message: action.payload.message,
      };
    },
    [createUser.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createUser" };
    },
    [createUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUser",
        message: action.payload.message,
      };
    },
    [updateUser.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateUser" };
    },
    [updateUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUser",
        message: action.payload.message,
      };
    },
    [updateUserRoles.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateUserRoles" };
    },
    [updateUserRoles.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUserRoles",
        message: action.payload.message,
      };
    },

    [deleteUser.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteUser" };
    },
    [deleteUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUser",
        message: action.payload.message,
      };
    },

    [resetUserPassword.fulfilled]: (state, action) => {
      state.status = { result: "success", method: "resetUserPassword" };
    },
    [resetUserPassword.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "resetUserPassword",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = usersSlice.actions;

export default usersSlice.reducer;
