import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
const _ = require("lodash");

export default function NotificationDefForm({ action, formData, handleSave }) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();

  //   const codes = useSelector((state) => state.core.codelistsSlice.codes);
  //   const objectCategoryOptions = _.get(
  //     codes,
  //     CODELIST_CODES.COR_META_OBJECT_CATEGORY_CODE
  //   );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("notificationDefForm")
    );

    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/admin/notification-defs`);
  }

  useEffect(() => {
    reset({ notificationDefForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiFormPanel
      width="75%"
      header={action === "add" ? "Add Notification" : `${formData["name"]}`}
      subheader={
        action === "add" ? "Description not available" : formData["description"]
      }
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexBasis: "50%",
            gap: theme.spacing(2),
          }}
        >
          <NtaiTextField
            name="notificationDefForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />

          <NtaiTextField
            name="notificationDefForm.description"
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Description"
            label="Description"
            rules={{ required: "Description is required" }}
          />
          <NtaiSelectField
            label="Priority Code"
            name="notificationDefForm.priorityCode"
            options={[]}
            rules={{ required: "Priority code is required" }}
          />
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
            }}
          >
            <NtaiSwitchField
              name="notificationDefForm.appDeliveryFg"
              label="Application"
            />
            <NtaiSwitchField
              name="notificationDefForm.emailDeliveryFg"
              label="Email"
            />
            <NtaiSwitchField
              name="notificationDefForm.textDeliveryFg"
              label="Text"
            />
          </Box>
          <NtaiSwitchField
            name="notificationDefForm.userDisableFg"
            label="Can user disable notification?"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            flexBasis: "50%",
            gap: theme.spacing(1),
          }}
        >
          <NtaiTextField
            name="notificationDefForm.titleTemplate"
            placeholder="Title Template"
            label="Title Template"
            rules={{ required: "Title template is required" }}
          />
          <NtaiTextField
            name="notificationDefForm.bodyTemplate"
            multiline
            minRows={10}
            maxRows={10}
            placeholder="Body Template"
            label="Body Template"
            rules={{ required: "Body template is required" }}
          />
        </Box>
      </Box>
    </NtaiFormPanel>
  );
}
