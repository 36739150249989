import React from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import GridLayout from "react-grid-layout";
import "../../../../../../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../../../../../../node_modules/react-resizable/css/styles.css";
// import classes from "./NtaiResizableGrid.module.css";
import { Resizable } from "react-resizable";
import { useEffect } from "react";
import { useState } from "react";

const ReactGridLayout = WidthProvider(RGL);

export default function SourceWidgetResizableGridLayout(props) {
  const { items, rowHeight, cols, handleLayoutChange } = props;

  const [layout, setLayout] = useState();
  const [defaultProps, setDefaultProps] = useState({
    className: "layout",
    // className: "flex w-full h-full",
    // items: 20,
    rowHeight: 100,
    // draggableHandle: ".header",
    onLayoutChange: function () {},
    cols: 12,
  });

  const [layoutChanged, setLayoutChanged] = useState(false);

  function generateDOM() {
    return items.map((item, i) => item);
  }
  useEffect(() => {
    const newLayout = _.map(new Array(items.length), function (item, i) {
      const p = props;
      const y = _.result(p, "y") || Math.ceil(Math.random() * 2) + 1;
      const coords = {
        x: (i * 2) % 10,
        y: Math.floor(i / 6) * y,
        w: 1,
        h: 1,
        i: i.toString(),
        minW: 2,
        minH: 2,
      };
      return coords;
    });

    setLayout(newLayout);
  }, []);

  useEffect(() => {
    setLayoutChanged(true);
  }, [layout]);

  return (
    <GridLayout
      layout={layout}
      draggableHandle=".widget-header-wrapper"
      onLayoutChange={handleLayoutChange}
      {...props}
    >
      {generateDOM()}
    </GridLayout>
  );
}
