import { useTheme } from "@mui/material";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlerts } from "../../inbox/store/alertsSlice";

function transformStatus(cols) {
  return <NtaiBadge textAlign="center" title={cols[0].colValue} />;
}

const headCells = [
  {
    id: "uuId",
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "definition.name",
    disablePadding: true,
    label: "Name",
    width: "15%",
  },
  {
    id: "definition.sourceDisplayName",
    disablePadding: true,
    label: "Source",
    width: "15%",
  },
  {
    id: "definition.typeDecode",
    disablePadding: true,
    label: "Type",
    width: "10%",
  },

  {
    id: "runStatusDecode",
    disablePadding: true,
    label: "Status",
    width: "15%",
  },

  {
    id: "message",
    disablePadding: true,
    label: "Message",
    width: "25%",
  },

  {
    id: "dateModified",
    dataType: "date",
    disablePadding: true,
    label: "Last Modified",
    width: "20%",
  },
];

export default function AlertTracker() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const alertsDataMap = useSelector((state) => state.monitor.alertsSlice.data);
  const data = Object.values(alertsDataMap);

  function handleView(id) {}

  useEffect(() => {
    dispatch(getAlerts());
  }, []);

  return (
    <NtaiPage title="Alert Tracker" back="/monitor/definitions">
      <NtaiSimpleTable
        rows={data}
        headCells={headCells}
        keyColumn="uuId"
        dialog={true}
        handleDialogAddEdit={handleView}
        title="Search"
      />
    </NtaiPage>
  );
}
