import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceWidgetTitle from "./SourceWidgetTitle";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import SourceWidgetFormPanel from "./SourceWidgetFormPanel";
const _ = require("lodash");

export default function SourceWidgetScriptedMetricForm({
  widget,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const [widgetFields, setWidgetFields] = useState(
    widget["sourceWidgetFields"]
  );

  function onHandleSave() {
    const widgetFormData = getValues("widgetForm");
    const metricScriptFormData = getValues("widgetForm.metricScript");
    const sanitizedFormData = NtaiUtils.sanitizeFormData(widgetFormData);

    sanitizedFormData["metricScript"] = metricScriptFormData;

    let revisedSourceWidgetFields = _.cloneDeep(widget["sourceWidgetFields"]);
    const sanitizedFormFieldsData = sanitizedFormData["sourceWidgetFields"];

    revisedSourceWidgetFields.forEach((f, i) => {
      const sanitizedFormFieldData = NtaiUtils.sanitizeFormData(
        _.filter(sanitizedFormFieldsData, {
          uuId: f["uuId"],
        })[0]
      );

      f["headerName"] = sanitizedFormFieldData["headerName"];
    });

    sanitizedFormData["sourceWidgetFields"] = revisedSourceWidgetFields;

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    let widgetCopy = _.cloneDeep(widget);
    reset({ widgetForm: widgetCopy });
  }, [widget]);

  return (
    <SourceWidgetFormPanel
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <NtaiTabs>
        <Box label="TITLE">
          <SourceWidgetTitle />
        </Box>
        <Box label="SCRIPT">
          <NtaiPanel
            subheader="Enter secondary text, init, map, combine and reduce script on the source database."
            width="100%"
          >
            <NtaiTextField
              sx={{ width: "75%" }}
              name="widgetForm.secondaryText"
              label="Secondary Text"
              placeholder="Secondary Text"
            />

            <NtaiTextField
              name="widgetForm.metricScript.initScript"
              label="Init Script*"
              multiline
              minRows={3}
              maxRows={3}
              placeholder="Init Script"
              rules={{ required: "Init script is required" }}
            />

            <NtaiTextField
              name="widgetForm.metricScript.mapScript"
              label="Map Script*"
              multiline
              minRows={3}
              maxRows={3}
              placeholder="Map Script"
              rules={{ required: "Map script is required" }}
            />

            <NtaiTextField
              name="widgetForm.metricScript.combineScript"
              label="Combine Script*"
              multiline
              minRows={3}
              maxRows={3}
              placeholder="Combine Script"
              rules={{ required: "Combine script is required" }}
            />

            <NtaiTextField
              name="widgetForm.metricScript.reduceScript"
              label="Reduce Script*"
              multiline
              minRows={3}
              maxRows={3}
              placeholder="Reduce Script"
              rules={{ required: "Reduce script is required" }}
            />
          </NtaiPanel>
        </Box>
      </NtaiTabs>
    </SourceWidgetFormPanel>
  );
}
