import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  createWorkflowDef,
  deleteWorkflowDef,
  getWorkflowDefs,
  updateWorkflowDef,
  clearStatus,
  setActiveId,
} from "./store/workflowDefsSlice";
import WorkflowDefForm from "./WorkflowDefForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "sourceDefUuIds",
    numeric: false,
    disablePadding: false,
    label: "# Sources",
    transformFunc: function (o) {
      return o && _.isArray(o) ? o.length : 0;
    },
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function WorkflowDefList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [action, setAction] = useState();
  const [loaded, setLoaded] = React.useState(false);

  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector((state) => state.core.workflowDefsSlice.data);
  const status = useSelector((state) => state.core.workflowDefsSlice.status);
  const activeId = useSelector(
    (state) => state.core.workflowDefsSlice.activeId
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteWorkflowDef(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(updateWorkflowDef({ uuId: currentId, formData: formData }))
      : dispatch(createWorkflowDef(formData));
  }

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (toolbarAction === "remove") confirmDelete();
  }

  function manageVersions(action, uuId) {
    dispatch(setActiveId(uuId));
    history.push(`/admin/workflows/${uuId}/versions`);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getWorkflowDefs" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      ["createWorkflowDef", "updateWorkflowDef"].includes(status.method)
    ) {

      handleDialogClose();
      dispatch(clearStatus());
      dispatch(setActiveId(null));
      setCurrentId(null);
      enqueueSnackbar(
        `Workflow definition "${_.get(
          dataMap[action === "add" ? activeId : currentId],
          "name"
        )}" saved successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteWorkflowDef"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Workflow definition(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getWorkflowDefs());
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Workflows">
        {loaded && (
          <NtaiCrudTable
            helpText="Workflow management encompasses the orchestration, automation, and optimization of operational processes within an organization. It involves defining, designing, and monitoring workflows to streamline tasks, improve efficiency, and facilitate collaboration across teams."
            rows={data}
            selectedIds={selectedIds}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleToolbarAction={handleToolbarAction}
            rowActions={[{ label: "Manage Versions", value: "versions" }]}
            handleRowAction={manageVersions}
            title="Workflow"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add Workflow Definition"
        size="sm"
      >
        <NtaiForm>
          <WorkflowDefForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
