import React from "react";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import history from "@ntai/@history";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { faChartTreeMap, faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  getSearchReportSearches,
  clearStatus,
} from "./store/searchReportInitSlice";
import SearchReportInit from "./SearchReportInit";
import SearchReportInitFilters from "./SearchReportInitFilters";
import SearchReportInitSearch from "./SearchReportInitSearch";
import SearchInitReportSelectTemplate from "./SearchInitReportSelectTemplate";
import { createSearch } from "../../store/searchSlice";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export const SearchReportInitContext = React.createContext();

export default function SearchReportInitWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedSearches, setSelectedSearches] = useState(new Set([]));
  const [selectedSourceUuIds, setSelectedSourceUuIds] = useState(new Set([]));
  const [selectedSearchDetails, setSelectedSearchDetails] = useState();
  const [queryText, setQueryText] = useState();
  const [
    selectedSourceMasterTemplateUuId,
    setSelectedSourceMasterTemplateUuId,
  ] = useState();

  const dataMap = useSelector(
    (state) => state.search.searchReportInitSlice.data
  );
  const statusSearch = useSelector((state) => state.search.searchSlice.status);

  const activeId = useSelector((state) => state.search.searchSlice.activeId);

  const status = useSelector(
    (state) => state.search.searchReportInitSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleSourceMasterTemplateChange(value) {
    setSelectedSourceMasterTemplateUuId(value);
  }

  function handleCreateSearch() {
    if (
      selectedSearches.size > 0 &&
      selectedSourceMasterTemplateUuId &&
      selectedSourceMasterTemplateUuId.length > 0
    ) {
      dispatch(
        createSearch({
          typeCode: 6,
          sourceMasterTemplateUuId: selectedSourceMasterTemplateUuId,
          childSearchUuIds: [...selectedSearches],
        })
      );
    } else {
      enqueueSnackbar(
        "At least one analysis and master template must be selected",
        {
          variant: "error",
        }
      );
    }
  }

  useEffect(() => {
    if (
      statusSearch &&
      statusSearch.result === "success" &&
      statusSearch.method === "createSearch"
    ) {
      history.push(`/search/${activeId}/edit`);
    }
  }, [statusSearch]);

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchReportSearches" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSearchReportSearches({ filterCode: 1 }));
  }, []);

  return (
    <SearchReportInitContext.Provider
      value={{
        selectedFilter,
        setSelectedFilter,
        selectedSearches,
        setSelectedSearches,
        selectedSearchDetails,
        setSelectedSearchDetails,
        queryText,
        setQueryText,
        selectedSourceUuIds,
        setSelectedSourceUuIds,
      }}
    >
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexBasis: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(2),
              flexBasis: "10%",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
              background: grey[50],
            }}
          >
            <NtaiButton
              onClick={() => history.push("/search")}
              size="small"
              icon="back"
            >
              Back to Listing
            </NtaiButton>
          </Box>

          {/* <SearchInitSourcesWrapper mode="adv" /> */}
          {loaded && _.isArray(data) && data.length > 0 && (
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottom: theme.general.border1,
                  background: grey[50],
                  pb: theme.spacing(2),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "60%",
                    flexDirection: "column",
                    justifyContent: "start",
                    gap: theme.spacing(1),
                  }}
                >
                  <Typography variant="h1" fontWeight="300">
                    Select Analysis
                  </Typography>
                  <Typography variant="body1" fontWeight="300">
                    Find analysis using search bar below
                  </Typography>
                  <NtaiForm>
                    <SearchReportInitSearch />
                  </NtaiForm>
                </Box>
              </Box>
              <Box
                sx={{
                  height: `calc(100% - 88px)`,
                  display: "flex",
                  width: "100%",
                }}
              >
                <SearchReportInitFilters />
                <SearchReportInit />
              </Box>

              <Box sx={{ height: "88px", borderTop: theme.general.border1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: theme.spacing(2),
                    gap: theme.spacing(1),
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {selectedSearchDetails && (
                      <React.Fragment>
                        <Typography variant="h6">{`Selected Analysis:`}</Typography>
                        <Typography variant="h6" fontWeight={700}>
                          {selectedSearchDetails["title"]}
                        </Typography>
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </React.Fragment>
                    )}
                  </Box>

                  <Box
                    sx={{
                      flexBasis: "30%",
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                      justifyContent: "end",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        gap: theme.spacing(2),
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                        }}
                      >
                        <NtaiForm>
                          <SearchInitReportSelectTemplate
                            handleChange={handleSourceMasterTemplateChange}
                          />
                        </NtaiForm>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Button
                          startIcon={<FontAwesomeIcon icon={faChartTreeMap} />}
                          variant="contained"
                          disabled={
                            selectedSearches.size > 0 &&
                            selectedSourceMasterTemplateUuId &&
                            selectedSourceMasterTemplateUuId.length > 0
                              ? false
                              : true
                          }
                          onClick={() => handleCreateSearch()}
                        >
                          CREATE REPORT
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          )}

          {loaded && _.isArray(data) && data.length === 0 && (
            <Box sx={{ flexBasis: "100%", display: "flex", width: "100%" }}>
              <NtaiEmptyMessage
                vAlign="center"
                header="No searches available"
                subheader="You do not have necessary permissions to view entities"
              />
            </Box>
          )}
          {!loaded && <NtaiCircularProgress />}
        </Box>
      </React.Fragment>
    </SearchReportInitContext.Provider>
  );
}
