import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import SearchSourceWidgetDrillPanel from "./SearchSourceWidgetDrillPanel";
import {
  clearStatus,
  createSearchSourceViewDrillDownWidget,
  deleteSearchSourceViewDrillWidget,
  getSearchSourceViewDrillUpWidget,
} from "../store/searchSourceViewWidgetsSlice";
import { SearchSourceWidgetDrillContext } from "../../SearchSourceView";
import { SearchContext } from "../../../../SearchEdit";
import { SearchSourceContext } from "../../../SearchSource";
import { useDispatch, useSelector } from "react-redux";
import useSearchSourceWidgetTempFilters from "../../../hooks/useSearchSourceWidgetTempFilters";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function SearchSourceWidgetDrill({ children }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    drillModeWidgetUuId,
    drillWidgetFieldIds,
    setDrillWidgetFieldIds,
    drillWidgetChartId,
    setDrillWidgetChartId,
  } = React.useContext(SearchSourceWidgetDrillContext);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceViewUuId = _.get(
    selectedSearchSourceData,
    "selectedSourceViewUuId"
  );

  const dataMap = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSourceViewWidgetsSlice.status
  );

  const widget =
    dataMap && !_.isEmpty(dataMap) ? dataMap[drillModeWidgetUuId] : null;

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  function handleDrillUp() {
    if (drillModeWidgetUuId !== widget["widgetUuId"]) {
      dispatch(
        getSearchSourceViewDrillUpWidget({
          searchId: searchId,
          searchSourceId: searchSourceId,
          sourceViewId: sourceViewUuId,
          sourceRootWidgetId: drillModeWidgetUuId,
          formData: {
            widgetUuId: widget["widgetUuId"],
            sourceWidgetUuId: widget["widgetUuId"],
            sourceViewUuId: sourceViewUuId,
            parentSourceWidgetUuId: widget["parentSourceWidgetUuId"],
            searchSourceWidgetTempFilters: searchSourceWidgetTempFilters,
          },
        })
      );
    }
  }

  function handleDrillDown() {
    if (
      _.isArray(drillWidgetFieldIds) &&
      drillWidgetFieldIds.length > 0 &&
      drillWidgetChartId &&
      drillWidgetChartId.length > 0
    ) {
      let widgetFields = [];
      let fieldNames = [];
      let c = 1;

      // if (Array.isArray(selectedFields) && selectedFields.length == 0) return;

      drillWidgetFieldIds.forEach((key) => {
        widgetFields.push({ sourceFieldUuId: key, fieldOrder: c });
        fieldNames.push(_.get(sourceFieldsDataMap[key], "name"));
        c = c + 1;
      });

      const widgetName = NtaiUtils.trunc(fieldNames.join("_"), 95);

      dispatch(
        createSearchSourceViewDrillDownWidget({
          searchId: searchId,
          searchSourceId: searchSourceId,
          sourceViewId: sourceViewUuId,
          sourceRootWidgetId: drillModeWidgetUuId,
          formData: {
            sourceViewUuId: sourceViewUuId,
            parentSourceWidgetUuId: widget["widgetUuId"],
            sourceWidget: {
              name: widgetName,
              widgetUuId: drillWidgetChartId,
              sourceWidgetFields: widgetFields,
            },
            searchSourceWidgetTempFilters: searchSourceWidgetTempFilters,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      [
        "createSearchSourceViewDrillDownWidget",
        "getSearchSourceViewDrillUpWidget",
        "deleteSearchSourceViewDrillWidget",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setDrillWidgetFieldIds([]);
      setDrillWidgetChartId(null);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <Box
      sx={{
        py: theme.spacing(1.5),
        pl: theme.spacing(2),
        pr: theme.spacing(1),
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Box sx={{ flexBasis: "77%", display: "flex" }}>{children}</Box>
      <Box sx={{ flexBasis: "23%", display: "flex" }}>
        <SearchSourceWidgetDrillPanel
          handleDrillUp={handleDrillUp}
          handleDrillDown={handleDrillDown}
        />
      </Box>
    </Box>
  );
}
