import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  updateSourceUserWidget,
} from "./store/sourceUserWidgetsSlice";
import SourceUserWidgetForm from "./SourceUserWidgetForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
const _ = require("lodash");

export default function SourceUserWidgetEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.status
  );
  const dataMap = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.data
  );
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(
      updateSourceUserWidget({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: id,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (
      status.method === "updateSourceUserWidget" &&
      status.result === "success"
    ) {
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`
      );
      dispatch(clearStatus());
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Edit ${_.get(dataMap[id], "name")}`}
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`}
    >
      <NtaiForm>
        <SourceUserWidgetForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
