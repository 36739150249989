import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceDefinitions,
  clearStatus as clearSourceDefinitionsSliceStatus,
} from "../../sourcedefinition/store/sourceDefinitionsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

function getSourceOptions(sourceDefDataMap) {
  const result = [];
  Object.values(sourceDefDataMap).forEach((o) => {
    if (_.isArray(o["sourceMins"])) {
      o["sourceMins"].forEach((p) => {
        result.push({ value: p["uuId"], label: p["displayName"] });
      });
    }
  });

  return result;
}

export default function SourceSeriesForm({ action, formData, handleSave }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const [sourcesLoaded, setSourcesLoaded] = React.useState(false);

  const sourceDefinitionsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefinitionsSliceStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const sourceOptions =
    sourceDefinitionsDataMap && !_.isEmpty(sourceDefinitionsDataMap)
      ? getSourceOptions(sourceDefinitionsDataMap)
      : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceSeriesForm")
    );

    console.log("Save this -> ", sanitizedFormData);

    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/library/source-series`);
  }

  useEffect(() => {
    if (
      sourceDefinitionsSliceStatus &&
      sourceDefinitionsSliceStatus.method === "getSourceDefinitions" &&
      sourceDefinitionsSliceStatus.result === "success"
    ) {
      setSourcesLoaded(true);
      dispatch(clearSourceDefinitionsSliceStatus());
    }
  }, [sourceDefinitionsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceDefinitions({
        activeFg: 1,
      })
    );
    reset({ sourceSeriesForm: action === "edit" ? formData : {} });
  }, [formData]);

  return (
    <NtaiTabs>
      <Box
        sx={{
          height: "100%",
          display: "flex",
        }}
        label="GENERAL"
      >
        {sourcesLoaded && (
          <NtaiFormPanel
            width="520px"
            header="General Details"
            subheader="Source series general information"
            handleSave={onHandleSave}
            handleCancel={handleCancel}
          >
            <NtaiTextField
              name="sourceSeriesForm.name"
              label="Name*"
              rules={{ required: "Name is required" }}
            />

            <NtaiTextField
              name="sourceSeriesForm.description"
              multiline
              minRows={2}
              maxRows={2}
              label="Description*"
              rules={{ required: "Description is required" }}
            />
            <NtaiSelectField
              name="sourceSeriesForm.sourceUuId"
              label="Source*"
              options={sourceOptions}
              rules={{ required: "Source is required" }}
              isDisabled={action === "edit" ? true : false}
            />
          </NtaiFormPanel>
        )}
        {!sourcesLoaded && <NtaiCircularProgress size={24} />}
      </Box>
    </NtaiTabs>
  );
}
