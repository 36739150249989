import CodelistCreate from "./CodelistCreate";
import CodelistEdit from "./CodelistEdit";
import CodelistList from "./CodelistList";

const CodelistConfig = {
  routes: [
    {
      path: "/admin/codelists",
      exact: true,
      component: CodelistList,
    },
    {
      path: "/admin/codelists/create",
      exact: true,
      component: CodelistCreate,
    },
    {
      path: "/admin/codelists/:id/edit",
      exact: true,
      component: CodelistEdit,
    },
  ],
};

export default CodelistConfig;
