import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import {
  CODELIST_CODES,
  SOURCE_FILE_FORMAT_TYPE_CODES,
} from "app/main/constants/NtaiCodelistConstants";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import { getDatasources } from "../../../admin/datasource/store/datasourcesSlice";
const _ = require("lodash");

export default function SourceInForm(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { format, action, handleDialogClose, formData, handleFormSubmit } =
    props;

  const { getValues, reset, watch } = useFormContext();
  const [datasetBucketFieldsEnabled, setDatasetBucketFieldsEnabled] =
    useState(false);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);
  const watchDatasteBucketField = watch("sourceInForm.datasetFg");

  const datasourcesDataMap = useSelector(
    (state) => state.core.datasourcesSlice.data
  );
  const datasourcesOptions =
    datasourcesDataMap && !_.isEmpty(datasourcesDataMap)
      ? NtaiUtils.getSelectOptions(
          Object.values(datasourcesDataMap),
          "uuId",
          "name"
        )
      : [];

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceInForm")
    );

    if (sanitizedFormData && !_.isEmpty(sanitizedFormData)) {
      sanitizedFormData["typeCode"] = format;
      handleFormSubmit(sanitizedFormData);
    }
  }

  useEffect(() => {
    setDatasetBucketFieldsEnabled(watchDatasteBucketField);
  }, [watchDatasteBucketField]);

  useEffect(() => {
    reset({ sourceInForm: action === "edit" ? formData : {} });
    if (
      [
        SOURCE_FILE_FORMAT_TYPE_CODES.AWSREDSHIFT,
        SOURCE_FILE_FORMAT_TYPE_CODES.SNOWFLAKE,
        SOURCE_FILE_FORMAT_TYPE_CODES.JDBC,
      ].includes(format)
    ) {
      dispatch(getDatasources());
    }
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <NtaiTextField
            name="sourceInForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />

          <NtaiTextField
            name="sourceInForm.description"
            label="Description"
            multiline
            minRows={3}
            maxRows={3}
          />

          {[
            SOURCE_FILE_FORMAT_TYPE_CODES.AWSREDSHIFT,
            SOURCE_FILE_FORMAT_TYPE_CODES.SNOWFLAKE,
            SOURCE_FILE_FORMAT_TYPE_CODES.JDBC,
          ].includes(format) && (
            <NtaiSelectField
              isDisabled={action === "edit" ? true : false}
              name="sourceInForm.datasourceUuId"
              options={datasourcesOptions}
              label="Datasource*"
              rules={{ required: "Datasource is required" }}
            />
          )}
        </Box>

        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          {format === SOURCE_FILE_FORMAT_TYPE_CODES.CSV && (
            <NtaiTextField label="Delimiter" name="sourceInForm.csvDelimiter" />
          )}

          {![
            SOURCE_FILE_FORMAT_TYPE_CODES.AWSREDSHIFT,
            SOURCE_FILE_FORMAT_TYPE_CODES.SNOWFLAKE,
            SOURCE_FILE_FORMAT_TYPE_CODES.JDBC,
          ].includes(format) && (
            <>
              <NtaiSwitchField
                name="sourceInForm.copyFromRemoteDirFg"
                label="Copy pilot files from remote dir?"
              />
              <NtaiTextField
                name="sourceInForm.remoteDir"
                label="Remote dir for migration"
                placeholder="Remote dir path"
              />
            </>
          )}

          {/* <NtaiSelectField
          label="Type"
          options={typeOptions}
          name="sourceInForm.typeCode"
        /> */}

          {![
            SOURCE_FILE_FORMAT_TYPE_CODES.PDFM,
            SOURCE_FILE_FORMAT_TYPE_CODES.PDFS,
            SOURCE_FILE_FORMAT_TYPE_CODES.IMG,
            SOURCE_FILE_FORMAT_TYPE_CODES.AWSREDSHIFT,
            SOURCE_FILE_FORMAT_TYPE_CODES.SNOWFLAKE,
            SOURCE_FILE_FORMAT_TYPE_CODES.JDBC,
          ].includes(format) && (
            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              <NtaiSwitchField
                label="Dataset Bucket?"
                name="sourceInForm.datasetFg"
              />
            </Box>
          )}

          {![
            SOURCE_FILE_FORMAT_TYPE_CODES.AWSREDSHIFT,
            SOURCE_FILE_FORMAT_TYPE_CODES.SNOWFLAKE,
            SOURCE_FILE_FORMAT_TYPE_CODES.JDBC,
          ].includes(format) && (
            <NtaiTextField
              disabled={!datasetBucketFieldsEnabled}
              label="Dataset Name"
              name="sourceInForm.datasetName"
              rules={
                {
                  // TODO: need validation
                  // required: "Dataset name is required",
                  // validate: NtaiUtils.isValidSparkDatasetName,
                }
              }
            />
          )}

          {format === SOURCE_FILE_FORMAT_TYPE_CODES.CSV && (
            <>
              <NtaiTextField
                disabled={!datasetBucketFieldsEnabled}
                label="Header"
                name="sourceInForm.csvHeader"
              />
            </>
          )}

          {format === SOURCE_FILE_FORMAT_TYPE_CODES.XML && (
            <>
              <NtaiTextField label="Root Tag" name="sourceInForm.xmlRootTag" />
              <NtaiTextField label="Row Tag" name="sourceInForm.xmlRowTag" />
            </>
          )}

          {[
            SOURCE_FILE_FORMAT_TYPE_CODES.AWSREDSHIFT,
            SOURCE_FILE_FORMAT_TYPE_CODES.SNOWFLAKE,
            SOURCE_FILE_FORMAT_TYPE_CODES.JDBC,
          ].includes(format) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <NtaiSwitchField
                label="Read real time from remote source?"
                name="sourceInForm.datasourceReadRealTimeFg"
              />
              <NtaiTextField
                label="Incremental Migration Condition"
                name="sourceInForm.datasourceIncMigCondition"
                multiline
                minRows={3}
                maxRows={3}
                helpText="Last run time = $LAST_RUN_TIME"
              />
            </Box>
          )}
        </Box>
      </Box>
    </NtaiDialogFormPanel>
  );
}
