import CdoConfig from "./cdo/CdoConfig";
import SourceDefConfig from "./sourcedefinition/SourceDefConfig";
import LibraryConfig from "./library/LibraryConfig";
import ModelsConfig from "./models/ModelsConfig";
import RoleConfig from "./admin/role/RoleConfig";
import WidgetConfig from "./admin/widget/WidgetConfig";
import UserConfig from "./admin/user/UserConfig";
import SchedulerConfig from "./admin/scheduler/SchedulerConfig";
import TemplatesConfig from "./templates/TemplatesConfig";
import DashboardMgmtConfig from "./admin/dashboard/DashboardMgmtConfig";
import WorkflowDefConfig from "./admin/workflow/WorkflowDefConfig";
import RuleConfig from "./admin/rule/RuleConfig";
import MigrationConfig from "./migration/MigrationConfig";
import DataAdapterConfig from "./adapter/DataAdapterConfig";
import UserGroupConfig from "./admin/user-group/UserGroupConfig";
import AwsConfig from "./admin/aws/AwsConfig";
import UserAccountConfig from "./account/UserAccountConfig";
import NotificationDefConfig from "./admin/notification-definition/NotificationDefConfig";
import DatasourceConfig from "./admin/datasource/DatasourceConfig";
import CodelistConfig from "./admin/codelist/CondelistConfig";
import EntityConfig from "./entity/EntityConfig";
import SettingConfig from "./admin/setting/SettingConfig";

const CoreConfig = {
  routes: [
    ...UserAccountConfig.routes,
    ...CodelistConfig.routes,
    ...EntityConfig.routes,
    ...AwsConfig.routes,
    ...CdoConfig.routes,
    ...SourceDefConfig.routes,
    ...MigrationConfig.routes,
    ...LibraryConfig.routes,
    ...ModelsConfig.routes,
    ...UserConfig.routes,
    ...UserGroupConfig.routes,
    ...RoleConfig.routes,
    ...WidgetConfig.routes,
    ...SchedulerConfig.routes,
    ...TemplatesConfig.routes,
    ...DashboardMgmtConfig.routes,
    ...WorkflowDefConfig.routes,
    ...RuleConfig.routes,
    ...DataAdapterConfig.routes,
    ...NotificationDefConfig.routes,
    ...DatasourceConfig.routes,
    ...SettingConfig.routes,
  ],
};

export default CoreConfig;
