import DataAdapterCreate from "./DataAdapterCreate";
import DataAdapterEdit from "./DataAdapterEdit";
import DataAdapterList from "./DataAdapterList";
import DataAdapterJobList from "./job/DataAdapterJobList";

const DataAdapterConfig = {
  routes: [
    {
      path: "/adapters",
      exact: true,
      component: DataAdapterList,
    },

    {
      path: "/adapters/create",
      exact: true,
      component: DataAdapterCreate,
    },
    {
      path: "/adapters/:id/edit",
      exact: true,
      component: DataAdapterEdit,
    },
    {
      path: "/adapters/:id/jobs",
      exact: true,
      component: DataAdapterJobList,
    },
  ],
};

export default DataAdapterConfig;
