import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import ContactForm from "./ContactForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setActiveId, updateContact, clearStatus } from "./store/contactsSlice";
import History from "@ntai/@history/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ContactEdit(props) {
  const [action, setAction] = React.useState();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const id = props.match.params.id;
  const status = useSelector((state) => state.core.contactsSlice.status);
  const dataMap = useSelector((state) => state.core.contactsSlice.data);
  const activeId = useSelector((state) => state.core.contactsSlice.activeId);
  const formData = dataMap[id];

  function handleFormSubmit(formData) {
    dispatch(updateContact({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "updateContact" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Contact "${_.get(
          dataMap[activeId],
          "firstName"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );
      dispatch(clearStatus());
      History.push(`/library/contacts`);
    }

    if (
      status &&
      status.method === "updateContact" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  return (
    <NtaiPage
      title={`Contacts / Edit ${
        formData["firstName"] + " " + formData["lastName"]
      }`}
      back="/library/contacts"
    >
      <NtaiForm>
        <ContactForm
          action="edit"
          handleFormSubmit={handleFormSubmit}
          formData={formData}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
