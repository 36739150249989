import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { faClose, faShapes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../SearchEdit";
import { SearchSourceContext } from "../SearchSource";
const _ = require("lodash");

export default function SearchDashboardGroupMenuItems({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [viewsLoaded, setViewsLoaded] = useState(false);

  const { searchData, setSelectedSearchObjectDashboardIndex } =
    React.useContext(SearchContext);

  const searchId = _.get(searchData, "uuId");

  //   const sourceDefId = _.get(searchSourceData, "searchSourceDefinition.uuId");
  //   const sourceId = _.get(searchSourceData, "searchSourceDefinition.sourceUuId");

  const data =
    searchData &&
    _.has(searchData, "searchObjectDashboards") &&
    _.isArray(searchData["searchObjectDashboards"])
      ? searchData["searchObjectDashboards"]
      : [];

  function selectSearchObjectDashboard(searchObjectDashboardIndex) {
    setSelectedSearchObjectDashboardIndex(searchObjectDashboardIndex);
    handleClose();
  }

  return (
    <Box
      sx={{
        height: "240px",
        width: "200px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faChartSimple} />
        <Typography variant="body2" fontWeight="700">
          Select Dashboard
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {data && data.length > 0 && (
              <MenuList dense sx={{ p: "2px", width: "100%" }}>
                {data.map((item, i) => {
                  return (
                    <MenuItem
                      key={item.objectDashboardUuId}
                      value={item.objectDashboardUuId}
                      divider={true}
                      onClick={() => selectSearchObjectDashboard(i)}
                    >
                      <ListItemText>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "500" }}
                        >
                          {item.objectDashboardName}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  );
                })}
              </MenuList>
            )}
          </Box>
        </Scrollbar>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
