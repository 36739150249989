import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  getSourceDerivedFieldElems,
  createSourceDerivedFieldElem,
  updateSourceDerivedFieldElem,
  deleteSourceDerivedFieldElem,
  clearStatus,
} from "./store/sourceDerivedFieldElemsSlice";

import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourceDerivedFieldElemForm from "./SourceDerivedFieldElemForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "fieldName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },

  {
    id: "metricDecode",
    numeric: false,
    disablePadding: false,
    label: "Agg Code",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "remove",
  },
];

export default function SourceDerivedFieldElemList({ sourceDerivedFieldId }) {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const dataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldElemsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceDerivedFieldElemsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceDerivedFieldId: sourceDerivedFieldId,
  };

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteSourceDerivedFieldElem({
            ...pathVariables,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceDerivedFieldElem({
            ...pathVariables,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceDerivedFieldElem({ ...pathVariables, formData: formData })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "remove") confirmDelete();
  }

  useEffect(() => {
    dispatch(getSourceDerivedFieldElems(pathVariables));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceDerivedFieldElem" ||
        status.method === "updateSourceDerivedFieldElem")
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceDerivedFieldElem"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <>
      <NtaiPanel
        width="100%"
        title="Elements"
        subheader="Add dependent source fields for aggregate script field"
      >
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          title="Field Element"
        />
      </NtaiPanel>

      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Add SourceDerivedFieldElem"
        size="sm"
      >
        <NtaiForm>
          <SourceDerivedFieldElemForm
            sourceDerivedFieldId={sourceDerivedFieldId}
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={action === "edit" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "fieldName")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
