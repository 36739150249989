import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReportContext } from "../../ReportMain";
import ReportParam from "./ReportParam";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function ReportParams() {
  const theme = useTheme();

  const { reportDefinition, reportFormData } = React.useContext(ReportContext);

  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <Box
        sx={{
          p: theme.spacing(2),
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
        }}
      >
        <Typography variant="h6">Report Criteria</Typography>
      </Box>
      <Scrollbar autoHide={true}>
        <Box
          sx={{
            mt: theme.spacing(0.5),
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {_.orderBy(
            reportDefinition.reportDefinitionParameters,
            ["paramOrder"],
            ["asc"]
          ).map((rp, i) => (
            <ReportParam key={`report-param-${i}`} field={rp} />
          ))}

          {(!reportDefinition.reportDefinitionParameters ||
            (_.isArray(reportDefinition.reportDefinitionParameters) &&
              reportDefinition.reportDefinitionParameters.length === 0)) && (
            <NtaiEmptyMessage
              vAlign="center"
              header="Report parameters not available"
            />
          )}
        </Box>
      </Scrollbar>
    </Box>
  );
}
