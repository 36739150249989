import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import {
  getAwsClusterSessions,
  clearStatus,
  terminateAwsClusterSession,
} from "./store/awsClusterSessionsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "livySessionId",
    numeric: false,
    disablePadding: false,
    label: "Session Id",
  },
  {
    id: "livySessionStateDecode",
    numeric: false,
    disablePadding: false,
    label: "State   ",
  },
  {
    id: "sourceDefinitionMin.abbrev",
    numeric: false,
    disablePadding: false,
    label: "Source",
  },
  {
    id: "dateStarted",
    numeric: false,
    disablePadding: false,
    label: "Started",
  },
  {
    id: "dateTerminated",
    numeric: false,
    disablePadding: false,
    label: "Terminated",
  },
];

const toolbarActions = [
  {
    label: "Terminate",
    icon: "remove",
    value: "remove",
  },
];

export default function AwsClusterSessionList() {
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const awsClusterId = useSelector(
    (state) => state.core.awsClustersSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.core.awsClusterSessionsSlice.data
  );
  const status = useSelector(
    (state) => state.core.awsClusterSessionsSlice.status
  );
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {}

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          terminateAwsClusterSession({
            awsClusterId: awsClusterId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "remove") confirmDelete();
  }

  useEffect(() => {
    dispatch(getAwsClusterSessions(awsClusterId));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "terminateAwsClusterSession"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPanel
      width="75%"
      header="Cluster Sessions"
      subheader="AWS cluster sessions for source pipelines, migrations and model computation tasks"
    >
      {data && data.length > 0 && (
        <NtaiSimpleTable
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          handleToolbarAction={handleToolbarAction}
          title={`Sessions (${data.length})`}
        />
      )}

      {!data ||
        (data && data.length === 0 && (
          <NtaiEmptyMessage header="No sessions available" />
        ))}

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "livySessionId"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </NtaiPanel>
  );
}
