import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiTitleItems from "@ntai/components/micros/NtaiTitleItems";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiListTable from "@ntai/components/tables/list/NtaiListTable";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateSourceThesauruses,
  getSourceThesauruses,
} from "./store/sourceThesaurusesSlice";
import { getThesauruses } from "../../../library/thesaurus/store/thesaurusesSlice";
import { clearStatus } from "../store/sourcesSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function SourceThesaurusList(props) {
  const theme = useTheme();
  // const sourceId = props.match.params.id;
  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const dataMap = useSelector(
    (state) => state.sources.sourceThesaurusesSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceThesaurusesSlice.status
  );

  const thesaurusDataMap = useSelector(
    (state) => state.core.thesaurusesSlice.data
  );

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function handleThesaurusSelection(actionName, selectedItems) {
    if (actionName === "cancel") handleDialogClose();
    else if (actionName === "save") {
      dispatch(
        createOrUpdateSourceThesauruses({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: {
            thesaurusUuIds: selectedItems,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "createOrUpdateSourceThesauruses"
    ) {
      dispatch(clearStatus());
      handleDialogClose();
    }
  }, [status]);

  useEffect(() => {
    dispatch(getThesauruses());
    dispatch(
      getSourceThesauruses({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <>
      <NtaiPage
        title="Manage Source Thesauruses"
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
      >
        <NtaiPanel
          header={`Thesauruses (${
            dataMap && !_.isEmpty(dataMap) ? Object.keys(dataMap).length : 0
          })`}
          width="50%"
          subheader="Assign and manage thesauruses for the source"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: theme.spacing(1),
            }}
          >
            <Button
              onClick={() => handleDialogOpen()}
              variant="contained"
              size="small"
            >
              Manage Thesauruses
            </Button>
            <NtaiTitleItems
              title="Assigned Thesauruses"
              items={Object.values(dataMap)}
              itemLabel="thesaurusName"
            />
          </Box>
        </NtaiPanel>
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Assign Thesauruses"
        size="sm"
      >
        <Box sx={{ p: theme.spacing(2) }}>
          <NtaiListTable
            handleAction={handleThesaurusSelection}
            actions={[
              { value: "cancel", label: "Cancel" },
              { value: "save", label: "Save", variant: "contained" },
            ]}
            keyField="uuId"
            primaryLabelField="name"
            secondaryLabelField="description"
            items={Object.values(thesaurusDataMap)}
            selectedItems={Object.keys(dataMap)}
            actionAlignment="end"
          />
        </Box>
      </NtaiDialog>
    </>
  );
}
