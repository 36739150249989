import { Box, useTheme } from "@mui/material";
import React from "react";
import NtaiDateCalendar from "./NtaiDateCalendar";
import NtaiMuiCalendar from "./NtaiMuiCalendar";

export default function TestDateCalendar() {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", p: theme.spacing(2), gap: theme.spacing(1) }}>
      <NtaiDateCalendar />
      <NtaiMuiCalendar />
      <NtaiMuiCalendar />
    </Box>
  );
}
