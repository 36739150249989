import { Box, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSourceRecordLabels } from "../label/store/sourceRecordLabelsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

export default function SourceMasterTemplateRecordAccess() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const accessOptions = codes
    ? _.get(codes, CODELIST_CODES.COR_ROLE_PRIV_GRANT_TYPE_CODE)
    : [];

  const sourceRecordLabelsDataMap = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.data
  );

  const sourceRecordLabelOptions =
    sourceRecordLabelsDataMap && !_.isEmpty(sourceRecordLabelsDataMap)
      ? [
          ...NtaiUtils.getSelectOptions(
            _.orderBy(
              Object.values(sourceRecordLabelsDataMap),
              ["name"],
              ["asc"]
            ),
            "uuId",
            "name"
          ),
          { value: "", label: "Select None" },
        ]
      : [];

  React.useEffect(() => {
    dispatch(
      getSourceRecordLabels({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <NtaiPanel
      width="50%"
      header="Record Level Access"
      subheader="Enter general information"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <NtaiSelectField
          name="sourceMasterTemplateForm.recordAssessmentCode"
          label="Record Assessment Access"
          options={accessOptions}
        />
        <NtaiSelectField
          name="sourceMasterTemplateForm.recordActionsCode"
          label="Record Actions Access"
          options={accessOptions}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <NtaiSelectField
          name="sourceMasterTemplateForm.recordNotesCode"
          label="Record Notes Access"
          options={accessOptions}
        />
        <NtaiSelectField
          name="sourceMasterTemplateForm.recordDocumentsCode"
          label="Record Documents Access"
          options={accessOptions}
        />
      </Box>
      <NtaiSelectField
        name="sourceMasterTemplateForm.sourceRecordLabelUuId"
        label="Record Label"
        options={sourceRecordLabelOptions}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <NtaiSwitchField
          name="sourceMasterTemplateForm.recordDistFg"
          label="Enable Record Distributions?"
        />
        <NtaiSwitchField
          name="sourceMasterTemplateForm.recordReportsFg"
          label="Enable Record Reports?"
        />
      </Box>
    </NtaiPanel>
  );
}
