import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useDispatch, useSelector } from "react-redux";
import { getFunctions } from "app/main/pages/core/models/function/store/functionsSlice";
import { MODEL_FUNCTION_TYPE_CODE } from "app/main/constants/NtaiCodelistConstants";

const _ = require("lodash");

export default function SourceOutDatasetScdPolicyForm({
  action,
  sourceOutDatasetId,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();

  const functionsDataMap = useSelector(
    (state) => state.models.functionsSlice.data
  );

  const functionsData =
    functionsDataMap && !_.isEmpty(functionsDataMap)
      ? Object.values(functionsDataMap)
      : [];

  const functionDataOptions = NtaiUtils.getSelectOptions(
    _.filter(functionsData, function (o) {
      if (o["typeCode"] === MODEL_FUNCTION_TYPE_CODE.SRC_SOURCE_SCD)
        return true;
      else return false;
    }),
    "uuId",
    "name"
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOutDatasetScdPolicyForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    if (action === "add") {
      setValue("sourceOutDatasetScdPolicyForm.name", null);
      setValue("sourceOutDatasetScdPolicyForm.description", null);
    } else reset({ sourceOutDatasetScdPolicyForm: formData });
  }, [formData]);

  useEffect(() => {
    dispatch(getFunctions());
    if (action === "add") {
      setValue("sourceOutDatasetScdPolicyForm.name", null);
      setValue("sourceOutDatasetScdPolicyForm.description", null);
      setValue("sourceOutDatasetScdPolicyForm.scdFunctionUuId", null);
    } else reset({ sourceOutDatasetScdPolicyForm: formData });
  }, []);

  return (
    <NtaiFormPanel
      width="50%"
      header="SCD Policy"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="sourceOutDatasetScdPolicyForm.name"
        label="Name*"
        placeholder="Name"
        rules={{ required: "Name is required" }}
      />
      <NtaiTextField
        name="sourceOutDatasetScdPolicyForm.description"
        label="Description"
        placeholder="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
      <NtaiSelectField
        name="sourceOutDatasetScdPolicyForm.scdFunctionUuId"
        label="SCD Function*"
        options={functionDataOptions}
        rules={{ required: "SCD function is required" }}
      />
    </NtaiFormPanel>
  );
}
