import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { SourceFilterSchemeContext } from "./SourceFilterSchemeFieldsWrapper";
import { updateSourceFilterSchemeField } from "./store/sourceFilterSchemeFieldsSlice";
const _ = require("lodash");

export default function SourceFilterSchemeFieldForm() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, reset, setValue } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const filterSchemeId = useSelector(
    (state) => state.sources.sourceFilterSchemesSlice.activeId
  );

  const { selectedField, mergedSourceFields } = React.useContext(
    SourceFilterSchemeContext
  );

  const sourceField = selectedField
    ? _.filter(mergedSourceFields, function (o) {
        if (selectedField["derivedFieldFg"] === 1) {
          return o["uuId"] === selectedField["sourceDerivedFieldUuId"]
            ? true
            : false;
        } else {
          return o["uuId"] === selectedField["sourceFieldUuId"] ? true : false;
        }
      })[0]
    : null;

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const filterFieldUITypeCodes = _.get(
    codelistData,
    CODELIST_CODES.SRC_SOURCE_TEMPLATE_FILTER_SCHEME_FIELD_TYPE_CODE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("filterSchemeFieldForm")
    );

    dispatch(
      updateSourceFilterSchemeField({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        filterSchemeId: filterSchemeId,
        uuId: _.get(sanitizedFormData, "uuId"),
        formData: sanitizedFormData,
      })
    );
  }

  useEffect(() => {
    reset({ filterSchemeFieldForm: selectedField });
    // setValue("filterSchemeFieldForm", selectedField);
    // if (selectedField) {
    //   console.log(
    //     "Set set value: ",
    //     selectedField,
    //     selectedField["fieldUiTypeCode"]
    //   );
    //   setValue(
    //     "filterSchemeFieldForm.fieldUiTypeCode",
    //     selectedField["fieldUiTypeCode"] ? selectedField["fieldUiTypeCode"] : ""
    //   );
    // }
  }, [selectedField]);

  return (
    <Box sx={{ flexBasis: "50%", display: "flex", flexDirection: "column" }}>
      {selectedField && !_.isEmpty(selectedField) && (
        <NtaiFormPanel
          header={_.get(selectedField, "fieldUiLabel")}
          subheader={_.get(sourceField, "name") || "Name is not available"}
          handleSave={onHandleSave}
        >
          <NtaiTextField
            name="filterSchemeFieldForm.fieldUiLabel"
            label="Label"
            placeholder="Label"
            rules={{
              required: "Label is required",
            }}
          />

          <NtaiSelectField
            name="filterSchemeFieldForm.fieldUiTypeCode"
            label="Filter UI Type"
            options={filterFieldUITypeCodes}
            rules={{
              required: "Filter UI type is required",
            }}
          />
        </NtaiFormPanel>
      )}
      {!selectedField && (
        <NtaiEmptyMessage
          header="No field selected"
          subheader="To update filter field, select field from the left panel"
        />
      )}
    </Box>
  );
}
