import { faHome, faPodcast, faShapes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme, Box, Typography, Breadcrumbs } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiCompactCard2 from "@ntai/components/cards/NtaiCompactCard2";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import React from "react";
import { useSelector } from "react-redux";
import { OversightContext } from "../../Oversight";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { OVERSIGHT_SECTIONS } from "../../constants/OversightConstants";
import { OversightStyledBreadcrumb } from "../shared/OversightUtil";
const _ = require("lodash");

export default function OversightObject() {
  const theme = useTheme();

  const { navNode, setNavNode, setSection } =
    React.useContext(OversightContext);

  const navigationData = useSelector(
    (state) => state.oversight.oversightNavigationSlice.data
  );

  function handleEntitySelect(node) {
    setSection(OVERSIGHT_SECTIONS.ENTITY);
    setNavNode(node);
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          p: theme.spacing(2),
          display: "flex",
          height: "72px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(0.5),
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h4" fontWeight="500">
              {`All ${navNode["name"]} (${navNode["children"].length})`}
            </Typography>
          </Box>

          <Breadcrumbs aria-label="breadcrumb">
            <OversightStyledBreadcrumb
              component="a"
              href="#"
              label={`All`}
              icon={<FontAwesomeIcon icon={faHome} />}
            />
            <OversightStyledBreadcrumb
              component="a"
              href="#"
              label={`${navNode["name"]}`}
              icon={<FontAwesomeIcon icon={faShapes} />}
            />
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 72px)`,
          width: "100%",
        }}
      >
        {navNode &&
          _.isArray(navNode["children"]) &&
          navNode["children"].length > 0 && (
            <Scrollbar>
              <Box
                sx={{
                  pt: theme.spacing(1),
                  px: theme.spacing(2),
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: theme.spacing(2),
                }}
              >
                {_.orderBy(navNode["children"], ["name"], ["asc"]).map(
                  (item, i) => (
                    <NtaiCompactCard2
                      id={`entity-${i}-${item["name"]}`}
                      avatar={<FontAwesomeIcon icon={faPodcast} />}
                      title={item["name"]}
                      description="NLM sposnored medical research for cancer related trials. NLM sposnored medical research for cancer related trials"
                      subTitles={[
                        {
                          icon: "tasks",
                          name: "Topics",
                          value: _.filter(item["children"], function (t) {
                            if (
                              ["OBJECT_TOPIC", "DATA_TOPIC"].includes(t["type"])
                            )
                              return true;
                          }).length,
                        },
                      ]}
                      handleClick={() => handleEntitySelect(item)}
                    />
                  )
                )}
              </Box>
            </Scrollbar>
          )}
        {navNode &&
          _.isArray(navNode["children"]) &&
          navNode["children"].length === 0 && (
            <NtaiEmptyMessage
              header="No records found"
              subheader="Create data objects from library"
            />
          )}
      </Box>
    </Box>
  );
}
