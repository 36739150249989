import { Box, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";

export default function SourceMasterTemplateGeneralForm() {
  const theme = useTheme();
  return (
    <NtaiPanel
      width="40%"
      header="General Information"
      subheader="Enter general information"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <NtaiTextField
          name="sourceMasterTemplateForm.name"
          label="Name*"
          rules={{ required: "Name is required" }}
        />
        <NtaiSwitchField
          name="sourceMasterTemplateForm.activeFg"
          label="Active?"
        />
      </Box>

      <NtaiTextField
        name="sourceMasterTemplateForm.description"
        label="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
    </NtaiPanel>
  );
}
