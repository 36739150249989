import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getUserDatasetFilterSchemes = createAsyncThunk(
  "userDatasetFilterSchemes/getUserDatasetFilterSchemes",
  async (userDatasetUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/library/datasets/${userDatasetUuId}/filter-schemes`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserDatasetFilterScheme = createAsyncThunk(
  "userDatasetFilterSchemes/getUserDatasetFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { userDatasetUuId, uuId } = values;
      const response = await server.get(
        `/library/datasets/${userDatasetUuId}/filter-schemes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrRemoveUserDatasetFilterScheme = createAsyncThunk(
  "userDatasetFilterSchemes/createOrRemoveUserDatasetFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { userDatasetUuId, formData } = values;
      const response = await server.post(
        `/library/datasets/${userDatasetUuId}/filter-schemes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserDatasetFilterScheme = createAsyncThunk(
  "userDatasetFilterSchemes/updateUserDatasetFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { userDatasetUuId, uuId, formData } = values;
      const response = await server.patch(
        `/library/datasets/${userDatasetUuId}/filter-schemes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserDatasetFilterScheme = createAsyncThunk(
  "userDatasetFilterSchemes/deleteUserDatasetFilterScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { userDatasetUuId, uuId } = values;
      const response = await server.delete(
        `/library/datasets/${userDatasetUuId}/filter-schemes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userDatasetFilterSchemesSlice = createSlice({
  name: "userDatasetFilterSchemes",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserDatasetFilterSchemes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getUserDatasetFilterSchemes",
      };
    },
    [getUserDatasetFilterSchemes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDatasetFilterSchemes",
        message: action.payload.message,
      };
    },
    [getUserDatasetFilterScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "getUserDatasetFilterScheme",
      };
    },
    [getUserDatasetFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserDatasetFilterScheme",
        message: action.payload.message,
      };
    },
    [createOrRemoveUserDatasetFilterScheme.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createUserDatasetFilterScheme",
      };
    },
    [createOrRemoveUserDatasetFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserDatasetFilterScheme",
        message: action.payload.message,
      };
    },
    [updateUserDatasetFilterScheme.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "updateUserDatasetFilterScheme",
      };
    },
    [updateUserDatasetFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateUserDatasetFilterScheme",
        message: action.payload.message,
      };
    },
    [deleteUserDatasetFilterScheme.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteUserDatasetFilterScheme",
      };
    },
    [deleteUserDatasetFilterScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUserDatasetFilterScheme",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  userDatasetFilterSchemesSlice.actions;

export default userDatasetFilterSchemesSlice.reducer;
