import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import OversightHeaderWidgetsWrapper from "./widgets/OversightHeaderWidgetsWrapper";

export default function OversightContentHeader() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexBasis: "64px",
        width: "100%",
        alignItems: "center",
        borderTop: theme.general.border1,
        borderBottom: theme.general.border1,
        boxShadow: theme.colors.shadows.cardSm,
        px: theme.spacing(2),
      }}
    >
      <OversightHeaderWidgetsWrapper />
    </Box>
  );
}
