import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiRadioGroupField from "@ntai/components/inputs/NtaiRadioGroupField";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import { getObjects } from "../../../cdo/object/store/objectsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getSourceFields } from "../store/sourcesSlice";
import Scrollbar from "@ntai/components/Scrollbar";
import { grey } from "@mui/material/colors";

const _ = require("lodash");

export default function SourceObjectForm(props) {
  const theme = useTheme();
  const { action, handleDialogClose, formData, handleFormSubmit } = props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  const objectsDataMap = useSelector((state) => state.core.objectsSlice.data);
  const objectsData = Object.values(objectsDataMap);
  const objectOptions = NtaiUtils.getSelectOptions(objectsData, "uuId", "name");

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);

  const fieldOrderOptions = _.get(
    codelistCodes,
    CODELIST_CODES.GL_FIELD_OPERATOR_CODE
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );
  const sourceFields = Object.values(sourceFieldsDataMap);
  const sourceFieldUuIds =
    action === "edit"
      ? _.get(formData, "sourceObjectFields").map((sof, i) =>
          _.get(_.get(sof, "sourceField"), "uuId")
        )
      : [];

  function generateSourceFields() {
    let result = [];

    if (Array.isArray(sourceFields) && sourceFields.length > 0) {
      let listItems = [];
      sourceFields.forEach((field, index) => {
        listItems.push(
          <React.Fragment key={`field-${index}`}>
            <ListItem
              sx={{
                background: sourceFieldUuIds.includes(field.uuId)
                  ? grey[200]
                  : null,
                "&:hover": { background: grey[100] },
              }}
              secondaryAction={
                <NtaiSwitchField
                  label={
                    sourceFieldUuIds.includes(field.uuId) ? "Selected" : null
                  }
                  name={`sourceObjectForm.sourceFields.${field.uuId}`}
                />
              }
            >
              <ListItemText
                primary={field["label"]}
                secondary={field["path"]}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      });

      result.push(
        <List
          key="source-field-list"
          component="nav"
          sx={{ padding: 0, height: "100%", width: "100%" }}
        >
          {listItems}
        </List>
      );
    }

    return result;
  }

  function handleSave() {
    const selectedFields = NtaiUtils.sanitizeFormData(
      getValues("sourceObjectForm.sourceFields")
    );

    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceObjectForm")
    );

    handleFormSubmit({
      objectUuId: sanitizedFormData.objectUuId,
      sourceObjectFields: Object.keys(
        _.pickBy(selectedFields, function (v, k) {
          return v === 1;
        })
      ).map((f, i) => ({
        sourceFieldUuId: f,
      })),
    });
  }

  useEffect(() => {
    dispatch(
      getObjects({
        domainId: "undefined",
        params: {
          categoryCode: 1,
        },
      })
    );

    dispatch(getCodes(CODELIST_CODES.GL_FIELD_OPERATOR_CODE));

    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    reset({
      sourceObjectForm: action === "edit" ? formData : {},
    });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Select object to be assigned to the source"
      handleSave={handleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiSelectField
          name="sourceObjectForm.objectUuId"
          label="Object*"
          options={objectOptions}
          rules={{ required: "Object is required" }}
        />

        {/* <NtaiSelectField
          name="sourceObjectForm.fieldOperatorCode"
          label="Field Operator"
          options={_.isArray(fieldOrderOptions) ? fieldOrderOptions : []}
          rules={{ required: "Field operator is required" }}
        /> */}

        {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1">Select Filter Fields</Typography>
          <Box
            sx={{
              borderRadius: theme.general.borderRadiusSm,
              border: theme.general.border1,
              height: `240px`,
            }}
          >
            <Scrollbar>{generateSourceFields()}</Scrollbar>
          </Box>
        </Box> */}
      </Box>
    </NtaiDialogFormPanel>
  );
}
