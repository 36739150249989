import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  ClickAwayListener,
  InputAdornment,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
const _ = require("lodash");

const defaultKeywords = ["and", "or", "not"];

const isKeyword = (o, keywords) => {
  return keywords.includes(o.toLowerCase().trim());
};

function SearchBasicInitSuggestBar(props) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const { control } = useFormContext();

  const {
    name,
    placeholder,
    label,
    options,
    optionLabelField,
    handleInputChange,
    handleChange,
    keywords,
    endAdornments,
    handleTagClick,
    terms,
  } = props;

  const optionKeywords =
    keywords && Array.isArray(keywords) && keywords.length > 0
      ? keywords
      : defaultKeywords;

  const {
    transform,
    formState: { errors },
  } = useFormContext();

  const openMenu = Array.isArray(options) && options.length > 0 ? true : false;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Controller
        control={control}
        name="item"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            onChange={(event, item) => {
              onChange(item);
              handleChange(item);
            }}
            multiple
            value={value}
            freeSolo
            fullWidth
            PaperComponent={({ children }) =>
              Array.isArray(options) && options.length > 0 ? (
                <Paper
                  style={{
                    borderRadius: openMenu ? "0px 0px 12px 12px" : "24px",
                    boxShadow: theme.colors.shadows.card,
                  }}
                >
                  {children}
                </Paper>
              ) : null
            }
            disableClearable
            // value={value}

            options={
              options && Array.isArray(options) && options.length > 0
                ? options
                : [{ id: 0, label: "Loading..." }]
            }
            onInputChange={(e, newInputValue) =>
              handleInputChange(newInputValue)
            }
            getOptionLabel={(item) =>
              item[optionLabelField] ? item[optionLabelField] : ""
            }
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;

              return (
                <Box
                  key={key}
                  component="li"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  }}
                  {...optionProps}
                >
                  <Chip size="small" label={option.thesaurusShortName} />[
                  {option.semTypes ? option.semTypes.join(", ") : ""}]{" "}
                  {option.term}
                </Box>
              );
            }}
            // isOptionEqualToValue={(option, value) =>
            //   value === undefined || value === "" || option.id === value.id
            // }
            getOptionSelected={(option, value) =>
              value === undefined || value === "" || option.id === value.id
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                if (typeof option === "string" && ["(", ")"].includes(option)) {
                  return (
                    <Typography
                      sx={{ px: "4px" }}
                      variant="h3"
                      color="secondary"
                    >
                      {option}
                    </Typography>
                  );
                } else {
                  return (
                    <Chip
                      onClick={() =>
                        handleTagClick(
                          index,
                          typeof option === "object" &&
                            _.has(option, optionLabelField)
                            ? option[optionLabelField]
                            : isKeyword(option, optionKeywords)
                            ? option.toUpperCase()
                            : option
                        )
                      }
                      sx={{
                        fontWeight: "700",
                        "& .MuiChip-deleteIcon": {
                          color: theme.colors.secondary.main,
                        },
                      }}
                      variant={
                        typeof option !== "object" &&
                        isKeyword(option, optionKeywords)
                          ? "outlined"
                          : "contained"
                      }
                      size="small"
                      label={
                        typeof option === "object" &&
                        _.has(option, optionLabelField)
                          ? option[optionLabelField]
                          : isKeyword(option, optionKeywords)
                          ? option.toUpperCase()
                          : option
                      }
                      avatar={
                        terms &&
                        _.filter(terms, function (t) {
                          if (
                            t["term"] === option["term"] &&
                            _.has(t, "synonyms") &&
                            t["synonyms"].length > 0
                          )
                            return true;
                        }).length > 0 ? (
                          <AddCircleOutlineOutlined
                            sx={{ color: theme.colors.secondary }}
                          />
                        ) : null
                      }
                      clickable={true}
                      {...getTagProps({ index })}
                    />
                  );
                }
              })
            }
            renderInput={(params) => {
              const { InputProps, ...rest } = params;

              const endAdornment = (
                <InputAdornment position="end">
                  {/* <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                      //   pr: "6px",
                    }}
                  >
                    {endAdornments}
                  </Box> */}
                </InputAdornment>
              );

              const revisedInputProps = {
                ...InputProps,
                endAdornment: endAdornment,
              };

              return (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <InputBase
                    placeholder={placeholder ? placeholder : null}
                    onFocus={handleClick}
                    sx={{
                      display: "flex",
                      gap: "8px",
                      py: "6px",
                      pl: theme.spacing(2),
                      pr: theme.spacing(1),
                      background: "white",
                      border: theme.general.border1,
                      borderRadius:
                        openMenu && open ? "12px 12px 0px 0px" : "24px",
                      alignItems: "center",
                      "&:hover": {
                        boxShadow: theme.colors.shadows.cardSm,
                      },
                    }}
                    {...params}
                    {...revisedInputProps}
                  />
                </ClickAwayListener>
              );
            }}
          />
        )}
        {...props}
      />
    </Box>
  );
}

export default SearchBasicInitSuggestBar;
