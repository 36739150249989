import React, { useEffect, useMemo, useState } from "react";
import { darken, lighten, useTheme } from "@mui/material";
import {
  tooltipConfig,
  xAxisLabelNoRotationConfig,
  yAxisLabelConfig,
} from "../chartConfig";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

const DEFAULT_OPTION = {
  tooltip: tooltipConfig,
  dataZoom: [
    {
      type: "slider",
      filterMode: "weakFilter",
      showDataShadow: false,
      top: 400,
      labelFormatter: "",
    },
    {
      type: "inside",
      filterMode: "weakFilter",
    },
  ],
  xAxis: {
    // min: startTime,
    scale: true,
    // axisLabel: {
    //   formatter: function (val) {
    //     return Math.max(0, val - startTime) + " ms";
    //   },
    // },
  },
  yAxis: {
    data: null,
  },
  series: [
    {
      type: "custom",
      // renderItem: renderItem,
      itemStyle: {
        opacity: 0.8,
      },
      encode: {
        x: [1, 2],
        y: 0,
      },
      // data: generateData(),
    },
  ],
};

export default function NtaiGanttEChart({ data, filtered }) {
  const theme = useTheme();
  const [option, setOption] = useState(DEFAULT_OPTION);

  function handleChartClick(params) {
    console.log("Clicked: ", params);
  }

  function handleContextMenu(action, params) {
    console.log("Clicked right: ", action, params);
  }

  useEffect(() => {
    const seriesName = data["name"];
    const xAxisData = data["xAxis"];
    const yAxisData = data["yAxis"];

    const curThemeName = localStorage.getItem("appTheme") || "NtaiBlueTheme";

    let result = [];
    let colorMin = lighten(theme.colors.primary.light, 0.9);
    let colorMax = darken(theme.colors.primary.main, 0.3);

    if (curThemeName === "NtaiDarkTheme") {
      colorMin = lighten(theme.colors.chart.light, 0.9);
      colorMax = darken(theme.colors.chart.main, 0.3);
    }

    const copyOption = _.cloneDeep(option);
    copyOption["xAxis"]["data"] = xAxisData;
    copyOption["yAxis"]["data"] = yAxisData;
    copyOption["series"][0]["name"] = seriesName;
    copyOption["series"][0]["data"] = data["values"];
    copyOption["visualMap"]["inRange"] = {
      color: [colorMin, colorMax],
    };

    setOption(copyOption);
  }, [filtered]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={handleChartClick}
        handleContextMenu={handleContextMenu}
      />
    ),
    [option]
  );

  return <>{chart}</>;
}
