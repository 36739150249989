import { faDownload, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import React, { useState } from "react";
import JSPDF from "jspdf";
import html2canvas from "html2canvas";
import { SearchSourceContext } from "../../SearchSource";
import { usePDF } from "react-to-pdf";
import generatePDF, { Resolution, Margin } from "react-to-pdf";

export default function SearchSourceViewExport() {
  const theme = useTheme();
  const [exportInProgress, setExportInProgress] = React.useState(false);
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const getTargetElement = () => document.getElementById("source-view");
  const { chartIds } = React.useContext(SearchSourceContext);

  //   function downloadView() {
  //     exportPdf(chartIds);
  //   }

  //   function exportPdf(images) {
  //     const pdf = new JSPDF();
  //     const canvas = mergeCanvas(images);
  //     const imageUrl = canvas.toDataURL("image/png", 1);
  //     pdf.text("Hello world!", 10, 10);
  //     pdf.addImage(imageUrl, "png", 0, 20);
  //     pdf.save("exports.pdf");
  //   }

  //   function mergeCanvas(images) {
  //     var canvas = document.createElement("canvas");
  //     var [maxH, maxW] = images.reduce(
  //       (ac, image) => {
  //         ac[0] = ac[0] + image.getHeight();
  //         if (image.getWidth() > ac[1]) ac[1] = image.getWidth();
  //         return ac;
  //       },
  //       [0, 0]
  //     );
  //     canvas.width = maxW;
  //     canvas.height = maxH;

  //     var context = canvas.getContext("2d");
  //     var top = 0,
  //       left = 0;
  //     images.forEach((image) => {
  //       const [w, h] = [image.getWidth(), image.getHeight()];
  //       context.drawImage(image.getRenderedCanvas(), left, top, w, h);
  //       top += h;
  //     });
  //     return canvas;
  //   }

  //   const generatePDF = () => {
  //     const report = new JSPDF("landscape", "mm", "a4");
  //     report.html(document.getElementById("source-view")).then(() => {
  //       report.save("report.pdf");
  //     });
  //   };

  //   const handleDownloadPDF = () => {
  //     const input = document.getElementById("source-view");
  //     // Specify the id of the element you want to convert to PDF
  //     html2canvas(input).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new JSPDF("landscape", "mm", "a4");
  //       pdf.addImage(imgData, "PNG", 0, 0);
  //       pdf.save("downloaded-file.pdf");
  //       // Specify the name of the downloaded PDF file
  //     });
  //   };

  const options = {
    // default is `save`
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        // compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        allowTaint: true,
        useCORS: true,
        logging: false,
        height: window.outerHeight + window.innerHeight,
        windowHeight: window.outerHeight + window.innerHeight,
      },
    },
  };

  function onHandleExportPDF() {
    setExportInProgress(true);
  }

  async function handleExportPDF() {
    const exportPromises = generatePDF(getTargetElement, options);
    const exportPromisesAll = await Promise.all([exportPromises]);
    setExportInProgress(false);
  }

  React.useEffect(() => {
    if (exportInProgress) handleExportPDF();
  }, [exportInProgress]);

  //   function exportPDF() {
  //     console.log("Setting export in progress: ");
  //     setExportInProgress(true);
  //     generatePDF(getTargetElement, options);
  //     setExportInProgress(false);
  //     console.log("Returning export in progress: ");
  //   }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="Export">
        <IconButton onClick={() => onHandleExportPDF()}>
          <FontAwesomeIcon
            size="2xs"
            spin={exportInProgress ? true : false}
            icon={exportInProgress ? faSpinner : faDownload}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
