import { Box, Tooltip, useTheme } from "@mui/material";
import React from "react";
import history from "@ntai/@history";
import {
  setLeftSidebarNav,
  setSelectedModule,
} from "app/store/ntai/navigationSlice";
import { useDispatch } from "react-redux";

export default function NtaiLogo() {
  const theme = useTheme();
  // const logoIcon = "/images/Logo2.4.png";

  const dispatch = useDispatch();
  const logoIcon =
    // process.env.REACT_APP_NTAI_WEB_BASE_URL + "/images/shrm_logo1.jpeg";
    process.env.REACT_APP_NTAI_WEB_BASE_URL + "/images/LOGO2.4.png";
  // Bristol_Myers_Squibb_logo
  // process.env.REACT_APP_NTAI_WEB_BASE_URL +
  // "/images/Bristol_Myers_Squibb_logo.png";

  function moveToHome() {
    dispatch(setLeftSidebarNav(false));
    dispatch(setSelectedModule(false));
    history.push("/dashboard");
  }
  // height: auto
  return (
    <Tooltip title="Home">
      <Box
        sx={{
          // p: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => moveToHome()}
      >
        <img height="auto" width="120px" src={logoIcon} />
      </Box>
    </Tooltip>
  );
}
