import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceObjects = createAsyncThunk(
  "sourceObjects/getSourceObjects",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, params } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceObject = createAsyncThunk(
  "sourceObjects/getSourceObject",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceObject = createAsyncThunk(
  "sourceObjects/createSourceObject",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceObject = createAsyncThunk(
  "sourceObjects/updateSourceObject",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceObject = createAsyncThunk(
  "sourceObjects/deleteSourceObject",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/objects/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceObjectsSlice = createSlice({
  name: "sourceObjects",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceObjects.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceObjects" };
    },
    [getSourceObjects.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceObjects",
        message: action.payload.message,
      };
    },

    [getSourceObject.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceObject" };
    },
    [getSourceObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceObject",
        message: action.payload.message,
      };
    },
    [createSourceObject.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSourceObject" };
    },
    [createSourceObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceObject",
        message: action.payload.message,
      };
    },
    [updateSourceObject.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSourceObject" };
    },
    [updateSourceObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceObject",
        message: action.payload.message,
      };
    },
    [deleteSourceObject.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSourceObject" };
    },
    [deleteSourceObject.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceObject",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceObjectsSlice.actions;

export default sourceObjectsSlice.reducer;
