import {
  faArrowUpRightFromSquare,
  faFileExcel,
  faFileLines,
  faFilePdf,
  faFileWord,
} from "@fortawesome/pro-light-svg-icons";
import { faShapes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPDFReportViewer from "@ntai/components/reports/NtaiPDFReportViewer";
import Scrollbar from "@ntai/components/Scrollbar";
import server from "app/http/ntaiServer";

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../SearchEdit";
import { SearchSourceContext } from "../../SearchSource";
import { getSearchSourceReportTemplates } from "./store/searchSourceReportTemplatesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { grey } from "@mui/material/colors";
import useSearchSourceWidgetTempFilters from "../../hooks/useSearchSourceWidgetTempFilters";
const _ = require("lodash");

function getReportFormatIcon(reportTemplate) {
  if (reportTemplate["documentFormatCode"] === 1) return faFilePdf;
  else if (reportTemplate["documentFormatCode"] === 1) return faFileWord;
  else if (reportTemplate["documentFormatCode"] === 3) return faFileExcel;
  else return faFileLines;
}

function getUrlResponseType(format) {
  if (format === 2) return "application/msword";
  else if (format === 3) return "application/vnd.ms-excel";
  else return "application/binary";
}

export default function SearchSourceReportTemplateItems({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [reportTemplateId, setReportTemplateId] = useState();
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [binaryFileLoaded, setBinaryFileLoaded] = useState(false);
  const [binaryFileUrl, setBinaryFileUrl] = useState();

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "uuId");

  const dataMap = useSelector(
    (state) => state.search.searchSourceReportTemplatesSlice.data
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(
          _.filter(Object.values(dataMap), { typeCode: 1 }),
          ["name"]["asc"]
        )
      : [];

  const status = useSelector(
    (state) => state.search.searchSourceReportTemplatesSlice.status
  );

  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  function generateReport(templateId) {
    setReportTemplateId(templateId);
    if (dataMap[templateId]["documentFormatCode"] === 1)
      handleReportDialogOpen();
    else
      handleSaveReport(templateId, dataMap[templateId]["documentFormatCode"]);
  }

  function handleSaveReport(templateId, format) {
    server
      .post(
        `/search/${searchId}/sources/${searchSourceId}/reports/${templateId}?recordId=`,
        {
          searchSourceWidgetFilters: searchSourceWidgetTempFilters,
        }
      )
      .then((response) => {
        const file = new Blob([response.data], {
          type: getUrlResponseType(format),
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // setFileUrl(fileURL);
        //Open the URL on new Window
        setBinaryFileUrl(fileURL);
        setBinaryFileLoaded(true);

        // setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleReportDialogOpen() {
    setOpen(true);
  }

  function handleReportDialogClose() {
    setOpen(false);
    handleClose();
  }

  useEffect(() => {
    if (binaryFileLoaded && binaryFileUrl && binaryFileUrl.length > 0)
      window.open(binaryFileUrl);
  }, [binaryFileLoaded]);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceReportTemplates"
    ) {
      setTemplatesLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSourceReportTemplates({
        searchId: searchId,
        searchSourceId: searchSourceId,
        params: {
          reportTemplateType: 1,
        },
      })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "340px",
          width: "400px",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
          p: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <FontAwesomeIcon icon={faFileLines} />
          <Typography variant="h5" fontWeight="500" color={grey[700]}>
            Select Report
          </Typography>
          <Divider />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: theme.general.border1,
            boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
          }}
        >
          {templatesLoaded && data.length === 0 && (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2">No reports found</Typography>
            </Box>
          )}
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              {templatesLoaded && (
                <List sx={{ p: "2px", width: "100%" }}>
                  {data &&
                    Array.isArray(data) &&
                    data.length > 0 &&
                    data.map((item, i) => {
                      return (
                        <ListItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: 0,
                            gap: theme.spacing(1),
                            "&:hover": {
                              background: grey[100],
                              cursor: "pointer",
                            },
                          }}
                          key={item.uuId}
                          value={item.uuId}
                          divider={true}
                          onClick={() => generateReport(item.uuId)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: theme.spacing(1.5),
                            }}
                          >
                            <FontAwesomeIcon icon={getReportFormatIcon(item)} />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  color="inherit"
                                  sx={{ fontWeight: "700" }}
                                >
                                  {NtaiUtils.trunc(item.name, 25)}
                                </Typography>
                              }
                              secondary={
                                <Typography variant="subtitle2">
                                  {item.description}
                                </Typography>
                              }
                            ></ListItemText>
                          </Box>

                          {item["extSourceTemplateFg"] === 1 && (
                            <FontAwesomeIcon
                              size="xs"
                              icon={faArrowUpRightFromSquare}
                            />
                          )}
                        </ListItem>
                      );
                    })}
                </List>
              )}
              {!templatesLoaded && <NtaiCircularProgress size={20} />}
            </Box>
          </Scrollbar>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{ fontSize: "10px" }}
          >
            CLOSE
          </Button>
        </Box>
      </Box>
      {reportTemplateId && reportTemplateId.length > 0 && (
        <NtaiDialog
          title={
            dataMap && !_.isEmpty(dataMap)
              ? dataMap[reportTemplateId]["name"]
              : "Report"
          }
          size="md"
          open={open}
          handleDialogClose={handleReportDialogClose}
        >
          <NtaiPDFReportViewer
            type={1}
            height="440px"
            params={searchSourceWidgetTempFilters}
            url={`/search/${searchId}/sources/${searchSourceId}/reports/${reportTemplateId}`}
          />
        </NtaiDialog>
      )}
    </>
  );
}
