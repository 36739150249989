import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReportDefinitionCategories = createAsyncThunk(
  "reportDefinitionCategories/getReportDefinitionCategories",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/reports/categories");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReportDefinitionCategory = createAsyncThunk(
  "reportDefinitionCategories/getReportDefinitionCategory",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/reports/categories/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createReportDefinitionCategory = createAsyncThunk(
  "reportDefinitionCategories/createReportDefinitionCategory",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/reports/categories", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReportDefinitionCategory = createAsyncThunk(
  "reportDefinitionCategories/updateReportDefinitionCategory",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/reports/categories/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReportDefinitionCategory = createAsyncThunk(
  "reportDefinitionCategories/deleteReportDefinitionCategory",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/reports/categories/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportDefinitionCategoriesSlice = createSlice({
  name: "reportDefinitionCategories",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReportDefinitionCategories.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getReportDefinitionCategories",
      };
    },
    [getReportDefinitionCategories.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportDefinitionCategories",
        message: action.payload.message,
      };
    },
    [getReportDefinitionCategory.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getReportDefinitionCategory",
      };
    },
    [getReportDefinitionCategory.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportDefinitionCategory",
        message: action.payload.message,
      };
    },
    [createReportDefinitionCategory.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createReportDefinitionCategory",
      };
    },
    [createReportDefinitionCategory.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createReportDefinitionCategory",
        message: action.payload.message,
      };
    },
    [updateReportDefinitionCategory.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateReportDefinitionCategory",
      };
    },
    [updateReportDefinitionCategory.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateReportDefinitionCategory",
        message: action.payload.message,
      };
    },
    [deleteReportDefinitionCategory.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteReportDefinitionCategory",
      };
    },
    [deleteReportDefinitionCategory.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteReportDefinitionCategory",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  reportDefinitionCategoriesSlice.actions;

export default reportDefinitionCategoriesSlice.reducer;
