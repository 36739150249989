import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import {
  getObjects,
  clearStatus as clearObjectsSliceStatus,
} from "app/main/pages/core/cdo/object/store/objectsSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import { Box, useTheme } from "@mui/material";
import SourceAdjudicationCreatePolicy from "./create-policy/SourceAdjudicationCreatePolicy";
import SourceAdjudicationImportCsvSchemeList from "./import-schemes/SourceAdjudicationImportCsvSchemeList";
const _ = require("lodash");

export default function SourceAdjudicationForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { getValues, watch, reset, setValue } = useFormContext();
  const [loaded, setLoaded] = React.useState(false);

  const sourceAdjudicationsDataMap = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.data
  );

  const adjudicationObjectsDataMap = useSelector(
    (state) => state.core.objectsSlice.data
  );

  const statusObjectsSlice = useSelector(
    (state) => state.core.objectsSlice.status
  );
  const statusSourceAdjudicationsSlice = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.status
  );

  const watchAdjObjectUuId = watch("sourceAdjudicationForm.adjObjectUuId");

  const adjObjectOptions = NtaiUtils.getSelectOptions(
    Object.values(adjudicationObjectsDataMap),
    "uuId",
    "name"
  );

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const sourceAdjRelationTypeOptions = _.has(
    codes,
    CODELIST_CODES.SRC_SOURCE_ADJUDICATION_RELATION_TYPE_CODE
  )
    ? _.get(codes, CODELIST_CODES.SRC_SOURCE_ADJUDICATION_RELATION_TYPE_CODE)
    : [];

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceAdjudicationForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    if (
      statusObjectsSlice &&
      statusObjectsSlice.result === "success" &&
      statusObjectsSlice.method === "getObjects"
    ) {
      setLoaded(true);
      dispatch(clearObjectsSliceStatus());
    }
  }, [statusObjectsSlice]);

  useEffect(() => {
    reset({ sourceAdjudicationForm: formData });
  }, [formData]);

  useEffect(() => {
    dispatch(
      getObjects({ domainId: "undefined", params: { categoryCode: 3 } })
    );
    dispatch(
      getCodes(CODELIST_CODES.SRC_SOURCE_ADJUDICATION_RELATION_TYPE_CODE)
    );
  }, []);

  return (
    <NtaiPanel
      width="75%"
      height="auto"
      header={
        action === "add"
          ? "Add Assessment Form"
          : `Edit ${formData["adjObjectShortName"]}`
      }
      subheader="Add or edit assessment form associated with the source. You can define relationship type, manage creation policy and define import schemes for assessment import from CSV file."
    >
      {loaded && adjObjectOptions && adjObjectOptions.length > 0 && (
        <NtaiTabs>
          <Box label="GENERAL">
            <NtaiFormPanel
              width="50%"
              height="auto"
              header="General Details"
              subheader="Add or edit assessment form associated with the source. You can define relationship type, manage creation policy and define import schemes for assessment import from CSV file."
              handleSave={onHandleSave}
              handleCancel={handleCancel}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
                }}
              >
                <NtaiSelectField
                  options={adjObjectOptions}
                  name="sourceAdjudicationForm.adjObjectUuId"
                  label="Assessment Form*"
                  rules={{ required: "Assessment form is required" }}
                />
                <NtaiSelectField
                  options={sourceAdjRelationTypeOptions}
                  name="sourceAdjudicationForm.relationTypeCode"
                  label="Relation Type with Source*"
                  rules={{ required: "Relation type is required" }}
                />
              </Box>
            </NtaiFormPanel>
          </Box>
          <Box disabled={action === "add" ? true : false} label="CREATE POLICY">
            <SourceAdjudicationCreatePolicy
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </Box>
          <Box
            disabled={action === "add" ? true : false}
            label="IMPORT SCHEMES"
          >
            <SourceAdjudicationImportCsvSchemeList
              handleCancel={handleCancel}
            />
          </Box>
        </NtaiTabs>
      )}
      {!loaded && <NtaiCircularProgress />}
    </NtaiPanel>
  );
}
