import {
  faArrowProgress,
  faTableColumns,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import React, { useEffect } from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import NtaiAccordion from "@ntai/components/accordions/NtaiAccordion";
import { getSourceStages } from "../../../store/sourceStagesSlice";
const _ = require("lodash");

export default function SourceDatasetSidePanelStages() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setOpenDrawer, setSidePanelMenuParam } = React.useContext(
    SourceDatasetManagerContext
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const sourceStagesDataMap = useSelector(
    (state) => state.sources.sourceStagesSlice.data
  );

  const sourceStagesData =
    sourceStagesDataMap && !_.isEmpty(sourceStagesDataMap)
      ? Object.values(sourceStagesDataMap)
      : [];

  function onHandleClick(pSourcePipelineId, sourceStageId) {
    setSidePanelMenuParam({
      menuId: 3,
      sourcePipelineId: pSourcePipelineId,
      uuId: sourceStageId,
    });

    setOpenDrawer(true);
  }

  useEffect(() => {
    dispatch(
      getSourceStages({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourcePipelineId: sourcePipelineId,
      })
    );
  }, []);

  return (
    <NtaiAccordion
      title="Stages"
      titleIcon="arrow-progress-regular"
      titleSecondary={
        <Chip
          variant="contained"
          size="small"
          label={
            <Typography variant="subtitle2" color="inherit">
              {sourceStagesData.length}
            </Typography>
          }
        />
      }
    >
      <Box
        sx={{
          borderTop: theme.general.border1,
          p: theme.spacing(1),
          display: "flex",
          height: "160px",
          width: "100%",
        }}
      >
        <Box
          sx={{ display: "flex", width: "100%", border: theme.general.border1 }}
        >
          {sourceStagesData &&
            _.isArray(sourceStagesData) &&
            sourceStagesData.length > 0 && (
              <Scrollbar autoHide={false}>
                <Box sx={{ pr: theme.spacing(1) }}>
                  <List sx={{ p: 0 }}>
                    {sourceStagesData.map((o, i) => (
                      <React.Fragment
                        key={`source-dataset-side-panel-stage-${i}`}
                      >
                        <ListItemButton
                          onClick={() =>
                            onHandleClick(sourcePipelineId, o["uuId"])
                          }
                        >
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  alignItems: "center",
                                }}
                              >
                                <FontAwesomeIcon icon={faArrowProgress} />
                                <Typography
                                  sx={{ fontSize: "13px" }}
                                  color="inherit"
                                >
                                  {o["name"]}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              </Scrollbar>
            )}
        </Box>
      </Box>
    </NtaiAccordion>
  );
}
