import { Box, Button, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SelectEntityParent from "./parent/SelectEntityParent";
import ObjectFormUtil from "../../cdo/object/form/util/ObjectFormUtil";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
// import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function generateEditFormData(fData) {
  let finalFormData = {};

  const fields = _.get(fData, "fields");
  fields.forEach((field, index) => {
    finalFormData[_.get(field, "fieldName")] = _.get(field, "fieldValue");
  });

  return finalFormData;
}

function generateFieldNumNameObj(obj) {
  let fieldNumNameObj = {};
  const fields = _.get(obj, "objectFields");
  fields.forEach((field, index) => {
    fieldNumNameObj[_.get(field, "fieldName")] = _.get(field, "fieldNum");
  });

  return fieldNumNameObj;
}

export default function EntityGeneral({
  object,
  handleSave,
  handleCancel,
  formData,
  action,
}) {
  const theme = useTheme();

  console.log("Form data: ", formData);

  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const objectUuId = useSelector(
    (state) => state.core.entitiesSlice.objectUuId
  );
  const fieldNumNameObj = generateFieldNumNameObj(object);

  const formPresent = object && !_.isEmpty(object["objectForm"]) ? true : false;

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("entityForm")
      );

      const sanitizedEntityRelFormData = NtaiUtils.sanitizeFormData(
        getValues("entityRelForm")
      );

      const transformedData = transformFormData(
        sanitizedFormData,
        formData,
        fieldNumNameObj
      );

      const finalSanitizedData = {
        ...transformedData,
        dataRelations: sanitizedEntityRelFormData
          ? [sanitizedEntityRelFormData]
          : [],
      };

      handleSave(finalSanitizedData);
    }
  }

  function transformFormData(
    sanitizedFormData,
    originalFormData,
    fieldNumNameObj
  ) {
    let objectData = {};
    let objectDataFields = [];
    Object.keys(sanitizedFormData).forEach((fieldName, index) => {
      objectDataFields.push({
        fieldName: fieldName,
        fieldValue: sanitizedFormData[fieldName],
        fieldNum: _.get(fieldNumNameObj, fieldName),
      });
    });

    objectData["uuId"] = _.get(originalFormData, "uuId");
    objectData["fields"] = objectDataFields;
    objectData["objectUuId"] =
      action === "edit" ? _.get(originalFormData, "objectUuId") : objectUuId;
    return objectData;
  }

  useEffect(() => {
    reset({
      entityForm: action === "edit" ? generateEditFormData(formData) : {},
    });
  }, []);

  return (
    <NtaiFormPanel
      actions
      width="75%"
      handleSave={formPresent ? onHandleSave : null}
      handleCancel={formPresent ? handleCancel : null}
    >
      <SelectEntityParent object={object} />
      {formPresent ? (
        ObjectFormUtil.generateForm(
          "entityForm",
          object["objectForm"],
          true,
          theme
        )
      ) : (
        <NtaiEmptyMessage
          header="Object form not configured"
          subheader="You can configure object form in Object > Form section"
        />
      )}
    </NtaiFormPanel>
  );
}
