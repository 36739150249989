import { Box, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useContext } from "react";
import { FunctionEditorContext } from "../FunctionEdit";
import FunctionEnvironmentSelection from "./environment/FunctionEnvironmentSelection";
import FunctionDatasetSelection from "./dataset/FunctionDatasetSelection";
import FunctionModelSelection from "./model/FunctionModelSelection";
import FunctionPythonFileSelection from "./pythonfile/FunctionPythonFileSelection";
import FunctionReturnTypeSelection from "./returntype/FunctionReturnTypeSelection";
import FunctionSourceScdDatasetSelection from "./source-scd-dataset/FunctionSourceScdDatasetSelection";
import { MODEL_FUNCTION_TYPE_CODE } from "app/main/constants/NtaiCodelistConstants";

const _ = require("lodash");

export default function FunctionSidepanelWrapper() {
  const theme = useTheme();

  const { showSettingsPanel, formData } = useContext(FunctionEditorContext);

  return (
    <Box
      sx={{
        flexBasis: showSettingsPanel ? "20%" : "0%",
        display: "flex",
        flexDirection: "column",
        borderLeft: theme.general.border1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "44px",
          alignItems: "center",
          px: theme.spacing(2),
          gap: theme.spacing(2),
          borderBottom: theme.general.border1,
        }}
      >
        <Typography variant="h6" fontWeight="700">
          Settings
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexBasis: `calc(100% - 44px)`,
          p: theme.spacing(1),
        }}
      >
        <Scrollbar autoHide={false}>
          <Box sx={{ border: theme.general.border1 }}>
            {!_.isEmpty(formData) &&
              formData["typeCode"] ===
                MODEL_FUNCTION_TYPE_CODE.SRC_SOURCE_SCD && (
                <FunctionSourceScdDatasetSelection />
              )}
            <FunctionReturnTypeSelection />
            <FunctionDatasetSelection />
            <FunctionModelSelection />
            <FunctionPythonFileSelection />
            <FunctionEnvironmentSelection />
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
}
