import { faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import server from "app/http/ntaiServer";
const _ = require("lodash");

class NtaiAppUtils {
  static getBreadcrumb(arr) {
    let result = [];
    arr.forEach((a, i) => {
      result.push(a);
    });

    return result.join(<FontAwesomeIcon icon={faAngleRight} />);
  }

  static getSourcesForSelectOption(sourceDefinitionsArr) {
    const result = [];
    _.orderBy(sourceDefinitionsArr, ["name"], ["asc"]).forEach(
      (sourceDefinition, index1) => {
        const name = _.get(sourceDefinition, "name");
        _.get(sourceDefinition, "sources").forEach((source, index2) => {
          result.push({
            value: source.uuId,
            label: name.concat(
              " (",
              source.majorVersion,
              ".",
              source.minorVersion,
              ")"
            ),
          });
        });
      }
    );

    return result;
  }

  static getSourceDefinitionsForSelectOption(sourceDefinitionsArr) {
    const result = [];
    _.orderBy(sourceDefinitionsArr, ["name"], ["asc"]).forEach(
      (sourceDefinition, index1) => {
        result.push({
          value: sourceDefinition.uuId,
          label: sourceDefinition.name.concat(
            "(",
            sourceDefinition.abbrev,
            ")"
          ),
        });
      }
    );

    return result;
  }

  static getQueryBuilderFieldType(dataTypeCode) {
    switch (dataTypeCode) {
      case 1:
      case 11:
        return "select";
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return "number";
      case 7:
      case 8:
        return "text";
      case 9:
        return "boolean";
      default:
        return "text";
    }
  }

  static getQueryBuilderFieldTypeFromEsDataType(esDataTypeCode) {
    switch (esDataTypeCode) {
      case 1:
      case 4:
      case 9:
        return "select";
      case 2:
        return "number";
      case 3:
        return "date";
      case 5:
        return "boolean";
      default:
        return "text";
    }
  }

  static generateQueryBuilderFields(sourceDefId, sourceId, fieldsArr) {
    let returnValue = {};
    if (Array.isArray(fieldsArr) && fieldsArr.length > 0) {
      fieldsArr.forEach((field, index) => {
        returnValue[_.get(field, "esFieldPath")] = {
          label: _.get(field, "label")
            ? _.get(field, "label")
            : _.get(field, "esFieldPath"),

          type: NtaiAppUtils.getQueryBuilderFieldType(
            _.get(field, "dataTypeCode")
          ),
          // type: "text",
          // valueSources: ["value"],
          // preferWidgets: ["text", "select"],
          fieldSettings: {
            asyncFetch: async (search, offset) => {
              const toSearch = _.isEmpty(search) ? "null" : search;
              const result = await server.get(
                `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields/${_.get(
                  field,
                  "uuId"
                )}/suggest`,
                {
                  params: { text: toSearch, size: 10, prefixFg: 1 },
                }
              );

              const values = result.data.map((item) => ({
                title: item,
                value: item,
              }));

              return {
                values: values,
                hasMore: false,
              };
            },
            useAsyncSearch: true,
          },
        };
      });
    }

    return returnValue;
  }

  static generateQueryBuilderFields1(sourceDefId, sourceId, fieldsArr) {
    let returnValue = {};
    if (Array.isArray(fieldsArr) && fieldsArr.length > 0) {
      fieldsArr.forEach((field, index) => {
        returnValue[_.get(field, "path")] = {
          label: _.get(field, "label")
            ? _.get(field, "label")
            : _.get(field, "name"),

          type: NtaiAppUtils.getQueryBuilderFieldTypeFromEsDataType(
            _.get(field, "esDataTypeCode")
          ),
          // type: "text",
          // valueSources: ["value"],
          // preferWidgets: ["text", "select"],
          fieldSettings: {
            asyncFetch: async (search, offset) => {
              const toSearch = _.isEmpty(search) ? "null" : search;
              const result = await server.get(
                `/sourcedefs/${sourceDefId}/sources/${sourceId}/fields/${_.get(
                  field,
                  "uuId"
                )}/suggest`,
                {
                  params: {
                    text: toSearch,
                    size: 10,
                    prefixFg: 1,
                    derivedFieldFg: field["type"] === 1 ? 0 : 1,
                  },
                }
              );

              const values = result.data.map((item) => ({
                title: item,
                value: item,
              }));

              return {
                values: values,
                hasMore: false,
              };
            },
            useAsyncSearch: true,
          },
        };
      });
    }

    return returnValue;
  }

  static mergeSourceFields(sourceFields, sourceDerivedFields) {
    let result = [];
    sourceFields.forEach((o, i) => {
      result.push({
        uuId: o["uuId"],
        name: o["name"],
        label: o["label"],
        path: o["esFieldPath"],
        type: 1,
        esDataTypeCode: o["esDataTypeCode"],
      });
    });

    sourceDerivedFields.forEach((o, i) => {
      result.push({
        uuId: o["uuId"],
        name: o["name"],
        label: o["label"],
        type: 2,
        path: o["name"],
        esDataTypeCode: o["fieldTypeCode"],
      });
    });

    return _.orderBy(result, ["label"], ["asc"]);
  }

  static getMergeSourceFieldSelectOptions(sourceFields, sourceDerivedFields) {
    let result = [];
    const tmpResult = this.mergeSourceFields(sourceFields, sourceDerivedFields);

    return _.orderBy(
      tmpResult.map((o) => {
        return {
          value: o["uuId"],
          label: o["label"],
        };
      }),
      ["label"],
      ["asc"]
    );
  }

  static getSourceRecordLabel(derivedFieldsObject, sourceRecordLabelTemplate) {
    let result = { primary: "Not Available", secondary: [], tags: [] };

    if (derivedFieldsObject) {
      const primaryLabelText =
        derivedFieldsObject[
          _.get(sourceRecordLabelTemplate, "sourceDerivedField.name")
        ];

      const secondaryLabels = _.map(
        sourceRecordLabelTemplate["secondaryLabels"],
        (sl, i) => {
          return {
            label: sl["sourceDerivedField"]["label"],
            name: sl["sourceDerivedField"]["name"],
            includeLabelFg: sl["includeFieldLabelFg"],
          };
        }
      );

      const secLabelText = this.generateSecondaryLabels(
        secondaryLabels,
        derivedFieldsObject
      );

      const tagFields = _.map(
        sourceRecordLabelTemplate["sourceRecordLabelTags"],
        (st, i) => {
          return {
            name: st["sourceRecordTag"]["sourceDerivedField"]["name"],
            fg: st["sourceRecordTag"]["fgColor"],
            bg: st["sourceRecordTag"]["bgColor"],
          };
        }
      );

      const tags = this.generateTags(tagFields, derivedFieldsObject);

      result = {
        primary: primaryLabelText,
        secondary: secLabelText,
        tags: tags,
      };
    }

    return result;
  }

  static generateSecondaryLabels(labels, derivedFields) {
    let secondaryArr = [];
    labels.forEach((label) => {
      secondaryArr.push(
        label.label.concat(": ").concat(derivedFields[label.name])
      );
    });
    return secondaryArr.join(" | ");
  }

  static generateTags(tags, derivedFields) {
    let tagsArr = [];
    tags.forEach((tag, i) => {
      tagsArr.push({ title: derivedFields[tag.name], fg: tag.fg, bg: tag.bg });
    });
    return tagsArr;
  }

  static getSourceDateFields(fields) {
    const result = _.orderBy(
      _.filter(Object.values(fields), { esDataTypeCode: 3 }),
      ["label"],
      ["asc"]
    ).map((f) => {
      return {
        value: f["uuId"],
        label: f["label"] + (f["type"] === 1 ? "" : " (d)"),
      };
    });

    return result;
  }
}

export default NtaiAppUtils;
