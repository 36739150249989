import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getSourceFields } from "../../../store/sourcesSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
const _ = require("lodash");

export default function SourceOutElasticLookupForm({
  action,
  formData,
  handleDialogClose,
  handleFormSubmit,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();

  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);

  const lookupMatchTypeOptions = _.get(
    codelistCodes,
    CODELIST_CODES.SRC_SOURCE_FIELD_LOOKUP_MATCH_TYPE_CODE
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceFieldsDatamap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceFieldsData = Object.values(sourceFieldsDatamap);

  const filteredTargetSourceFieldsData = _.filter(
    sourceFieldsData,
    (f) => f.sourceUuId !== sourceId
  );

  const filteredSourceFieldsData = _.filter(
    sourceFieldsData,
    (f) => f.sourceUuId === sourceId
  );

  const revisedSourceFieldsData = filteredSourceFieldsData.map((f) => {
    return { uuId: f.uuId, label: f.name };
  });

  // 9-Nov-23 : sourceMin removed and two fields added for sourceUuId and sourceName
  const revisedTargetSourceFieldsData = filteredTargetSourceFieldsData.map(
    (f) => {
      return { uuId: f.uuId, label: f.sourceName + " - " + f.name };
    }
  );

  const sourceFieldOptions = NtaiUtils.getSelectOptions(
    revisedSourceFieldsData,
    "uuId",
    "label"
  );

  const targetSourceFieldOptions = NtaiUtils.getSelectOptions(
    revisedTargetSourceFieldsData,
    "uuId",
    "label"
  );

  function onHandleSave() {
    const santitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceOutFieldLookupCreateForm")
    );

    if (!_.isEmpty(santitizedFormData)) {
      handleFormSubmit(santitizedFormData);
    }
  }

  useEffect(() => {
    dispatch(
      getSourceFields({
        sourceDefId: "*",
        sourceId: "*",
      })
    );
  }, []);

  useEffect(() => {
    reset({
      sourceOutFieldLookupCreateForm: action === "edit" ? formData : {},
    });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Lorem ipsum"
      handleSave={onHandleSave}
      handleCancel={handleDialogClose}
    >
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(1),
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <NtaiTextField
          width="50%"
          name="sourceOutFieldLookupCreateForm.name"
          label="Name*"
          placeholder="Name"
          rules={{ required: "Name is required" }}
        />
        <NtaiSwitchField
          name="sourceOutFieldLookupCreateForm.lookupSingleValueFg"
          label="Single Value Lookup?"
        />
      </Box>

      <NtaiTextField
        name="sourceOutFieldLookupCreateForm.description"
        label="Description"
        placeholder="Description"
        multiline
        minRows={3}
        maxRows={3}
      />

      <NtaiSelectField
        name="sourceOutFieldLookupCreateForm.lookupMatchTypeCode"
        label="Lookup Match Type*"
        options={lookupMatchTypeOptions}
        rules={{ required: "Lookup match type is required" }}
      />

      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiSelectField
          name="sourceOutFieldLookupCreateForm.sourceFieldUuId"
          label="Source Field*"
          options={sourceFieldOptions}
          rules={{ required: "Source field is required" }}
        />
        <NtaiSelectField
          name="sourceOutFieldLookupCreateForm.targetSourceFieldUuId"
          label="Target Source Field*"
          options={targetSourceFieldOptions}
          rules={{ required: "Target source field is required" }}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
