import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import UserList from "./UserList";
import UserList1 from "./UserList1";

const UserConfig = {
  routes: [
    {
      path: "/admin/users",
      exact: true,
      component: UserList,
    },
    {
      path: "/admin/users/create",
      exact: true,
      component: UserCreate,
    },
    {
      path: "/admin/users/:id/edit",
      exact: true,
      component: UserEdit,
    },
  ],
};

export default UserConfig;
