import { Box, Button, List, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import PackageFunctionsLookup from "./function/PackageFunctionsLookup";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { clearStatus, updatePackage } from "./store/packagesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

export default function PackageContentsWrapper({ formData }) {
  const theme = useTheme();
  const { getValues } = useFormContext();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);

  const status = useSelector((state) => state.models.packagesSlice.status);

  const packageActiveId = useSelector(
    (state) => state.models.packagesSlice.activeId
  );

  function onHandleFunctionsSave(selectedFunctionUuIds) {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("packageForm")
    );
    sanitizedFormData["functionUuIds"] = selectedFunctionUuIds;
    dispatch(
      updatePackage({ uuId: packageActiveId, formData: sanitizedFormData })
    );
  }

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "updatePackage"
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexBasis: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NtaiPanel
          width="100%"
          header="Manage Functions"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
        >
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => handleDialogOpen()}
            >
              Assign Functions
            </Button>
          </Box>
          <Typography variant="h6">
            Available Functions ({formData.functions.length})
          </Typography>
          <Box
            sx={{
              display: "flex",
              height: "320px",
              flexDirection: "column",
              border: theme.general.border1,
            }}
          >
            <Scrollbar>
              <Box
                sx={{ display: "flex", height: "100%", p: theme.spacing(2) }}
              >
                {formData.functions.map((f, i) => (
                  <Typography
                    key={`package-function-name=${i}`}
                  >{`${f.name}`}</Typography>
                ))}
              </Box>
            </Scrollbar>
          </Box>
        </NtaiPanel>
      </Box>
      <NtaiDialog
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
        title="Assign Functions"
        size="sm"
      >
        <PackageFunctionsLookup
          handleSave={onHandleFunctionsSave}
          handleCancel={handleDialogClose}
        />
      </NtaiDialog>
    </React.Fragment>
  );
}
