import React, { useState, useCallback } from "react";
import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";

// import {
//   Query,
//   Builder,
//   Utils as QbUtils,
// } from "@react-awesome-query-builder/mui";
// import { MuiConfig } from "@react-awesome-query-builder/mui";
// import "@react-awesome-query-builder/mui/css/styles.css";

// import "@react-awesome-query-builder/mui/css/styles.css";

import "react-awesome-query-builder/lib/css/styles.css";
// import "react-awesome-query-builder/lib/css/compact_styles.css";

export default function NtaiQueryBuilder(props) {
  const { fields, buildQuery, value } = props;

  const InitialConfig = MuiConfig;

  const queryValueDefault = { id: QbUtils.uuid(), type: "group" };
  const queryValue = value ? value : queryValueDefault;

  const config = {
    ...InitialConfig,
    fields: fields,
    customFieldSelectProps: {
      showSearch: true,
    },
  };

  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
  });

  const onChange = useCallback((immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState({ tree: immutableTree, config: config });

    const jsonTree = QbUtils.getTree(immutableTree);
    //console.log(jsonTree);
    const formats = {
      queryString: QbUtils.queryString(immutableTree, config),
      mongoDBFormat: QbUtils.mongodbFormat(immutableTree, config),
      sqlFormat: QbUtils.sqlFormat(immutableTree, config),
      esFormat: QbUtils.elasticSearchFormat(immutableTree, config),
    };

    buildQuery(state.tree, state.config, jsonTree, formats);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }, []);

  const renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container" style={{ padding: 0 }}>
        <div className="query-builder qb-lite" style={{ padding: 0 }}>
          <Builder {...props} style={{ width: "100%" }} />
        </div>
      </div>
    ),
    []
  );

  return (
    <div>
      <Query
        {...config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
    </div>
  );
}
