import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getPythonFiles = createAsyncThunk(
  "pythonFiles/getPythonFiles",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/models/python-files");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPythonFile = createAsyncThunk(
  "pythonFiles/getPythonFile",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/models/python-files/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createPythonFile = createAsyncThunk(
  "pythonFiles/createPythonFile",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/models/python-files", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePythonFile = createAsyncThunk(
  "pythonFiles/updatePythonFile",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/models/python-files/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePythonFile = createAsyncThunk(
  "pythonFiles/deletePythonFile",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/models/python-files/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const pythonFilesSlice = createSlice({
  name: "pythonFiles",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getPythonFiles.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getPythonFiles" };
    },
    [getPythonFiles.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getPythonFiles",
        message: action.payload.message,
      };
    },
    [getPythonFile.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getPythonFile" };
    },
    [getPythonFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getPythonFile",
        message: action.payload.message,
      };
    },
    [createPythonFile.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createPythonFile" };
    },
    [createPythonFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createPythonFile",
        message: action.payload.message,
      };
    },
    [updatePythonFile.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updatePythonFile" };
    },
    [updatePythonFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updatePythonFile",
        message: action.payload.message,
      };
    },
    [deletePythonFile.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deletePythonFile" };
    },
    [deletePythonFile.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deletePythonFile",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = pythonFilesSlice.actions;

export default pythonFilesSlice.reducer;
