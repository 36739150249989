import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightTopicSources = createAsyncThunk(
  "oversightTopicSources/getOversightTopicSources",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.get(`/oversight/topics/sources`, {
        params: values,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightTopicSourcesSlice = createSlice({
  name: "oversightTopicSources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightTopicSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "sourceUuId") };
      state.status = {
        result: "success",
        method: "getOversightTopicSources",
      };
    },
    [getOversightTopicSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightTopicSources",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = oversightTopicSourcesSlice.actions;

export default oversightTopicSourcesSlice.reducer;
