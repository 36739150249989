import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getReportSchedule = createAsyncThunk(
  "reportSchedule/getReportSchedule",
  async (reportId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/reports/${reportId}/schedules`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createReportSchedule = createAsyncThunk(
  "reportSchedule/createReportSchedule",
  async (values, { rejectWithValue }) => {
    try {
      const { reportId, formData } = values;
      const response = await server.post(
        `/reports/${reportId}/schedules`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReportSchedule = createAsyncThunk(
  "reportSchedule/updateReportSchedule",
  async (values, { rejectWithValue }) => {
    try {
      const { reportId, uuId, formData } = values;
      const response = await server.patch(
        `/reports/${reportId}/schedules/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReportSchedule = createAsyncThunk(
  "reportSchedule/deleteReportSchedule",
  async (values, { rejectWithValue }) => {
    try {
      const { reportId, uuId } = values;
      const response = await server.delete(
        `/reports/${reportId}/schedules/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitReportSchedule = createAsyncThunk(
  "reportSchedule/submitReportSchedule",
  async (values, { rejectWithValue }) => {
    try {
      const { reportId, uuId } = values;
      const response = await server.get(
        `/reports/${reportId}/schedules/${uuId}/submit`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const reportScheduleSlice = createSlice({
  name: "reportSchedule",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getReportSchedule.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getReportSchedule",
      };
    },
    [getReportSchedule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getReportSchedule",
        message: action.payload.message,
      };
    },
    [createReportSchedule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createReportSchedule",
      };
    },
    [createReportSchedule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createReportSchedule",
        message: action.payload.message,
      };
    },
    [updateReportSchedule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateReportSchedule",
      };
    },
    [updateReportSchedule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateReportSchedule",
        message: action.payload.message,
      };
    },
    [deleteReportSchedule.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteReportSchedule",
      };
    },
    [deleteReportSchedule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteReportSchedule",
        message: action.payload.message,
      };
    },
    [submitReportSchedule.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "submitReportSchedule",
      };
    },
    [submitReportSchedule.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "submitReportSchedule",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = reportScheduleSlice.actions;

export default reportScheduleSlice.reducer;
