import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordsCount = createAsyncThunk(
  "searchSourceRecordsCount/getSearchSourceRecordsCount",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/total-hits`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordsCountSlice = createSlice({
  name: "searchSourceRecordsCount",
  initialState: {
    status: {},
    totalHits: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
  },
  extraReducers: {
    [getSearchSourceRecordsCount.fulfilled]: (state, action) => {
      state.totalHits = action.payload["totalCount"];
      state.status = {
        result: "success",
        method: "getSearchSourceRecordsCount",
      };
    },
    [getSearchSourceRecordsCount.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordsCount",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordsCount.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSearchSourceRecordsCount",
      };
    },
  },
});

export const { clearStatus } = searchSourceRecordsCountSlice.actions;

export default searchSourceRecordsCountSlice.reducer;
