import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDefaultDomainRootObject = createAsyncThunk(
  "entityMenus/getDefaultDomainRootObject",
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/cdo/domains/${domainId}/objects`, {
        params: {
          domainDefaultFg: "1",
          rootFg: "1",
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const entityMenusSlice = createSlice({
  name: "entityMenus",
  initialState: {
    defaultDomainRootObject: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
  },
  extraReducers: {
    [getDefaultDomainRootObject.fulfilled]: (state, action) => {
      state.defaultDomainRootObject = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        method: "getDefaultDomainRootObject",
        result: "success",
      };
    },
    [getDefaultDomainRootObject.rejected]: (state, action) => {
      state.status = {
        method: "getDefaultDomainRootObject",
        result: "error",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus } = entityMenusSlice.actions;

export default entityMenusSlice.reducer;
