import { Box, Typography, useTheme } from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ReportContext } from "../../ReportMain";
import NtaiFilterMultiSelectList from "@ntai/components/inputs/filters/NtaiFilterMultiSelectList";
import NtaiFilterCheckboxGroup from "@ntai/components/inputs/filters/NtaiFilterCheckboxGroup";
import NtaiFilterRadioGroup from "@ntai/components/inputs/filters/NtaiFilterRadioGroup";
import NtaiFilterSlider from "@ntai/components/inputs/filters/NtaiFilterSlider";
import NtaiFilterRangeSlider from "@ntai/components/inputs/filters/NtaiFilterRangeSlider";
import NtaiFilterDateRangeSlider from "@ntai/components/inputs/filters/NtaiFilterDateRangeSlider";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiFilterBox from "@ntai/components/inputs/filters/NtaiFilterBox";
import { useFormContext } from "react-hook-form";
const _ = require("lodash");

export default function ReportParam({ field }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext();

  const { action, reportDefinition, reportFormData } =
    React.useContext(ReportContext);

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const multiOptionValues =
    [4, 5, 6, 7].includes(_.get(field, "typeCode")) &&
    _.get(field, "codelistCode")
      ? _.get(codelistData, _.get(field, "codelistCode"))
      : null;

  function getFieldName(field) {
    return "reportParamForm." + field.uuId;
  }

  useEffect(() => {
    if ([4, 5, 6, 7].includes(_.get(field, "typeCode"))) {
      dispatch(getCodes(_.get(field, "codelistCode")));
    }

    if (!_.isEmpty(reportFormData)) {
      const reportParameters = _.get(reportFormData, "reportParameters");

      const fieldParameter = _.filter(reportParameters, function (rp) {
        if (rp.reportDefinitionParameter.uuId === field.uuId) {
          return true;
        }
      });

      const parameterValues = _.get(fieldParameter[0], "reportParameterValues");

      switch (_.get(field, "typeCode")) {
        case 1:
        case 3:
          setValue(
            "reportParamForm." + field.uuId,
            _.get(parameterValues[0], "value").toString()
          );
          break;

        case 2:
        case 5:
          setValue(
            "reportParamForm." + field.uuId,
            _.get(parameterValues[0], "value")
          );

        case 4:
        case 6:
        case 7:
        case 8:
          setValue(
            "reportParamForm." + field.uuId,
            parameterValues.map((pv) => {
              return pv.value;
            })
          );
          break;

        default:
          break;
      }
    }
  }, [field]);

  return (
    <NtaiFilterBox>
      <Typography variant="subtitle2" fontWeight="700">
        {field.name}
      </Typography>
      {field.typeCode === 1 && <NtaiTextField name={getFieldName(field)} />}

      {field.typeCode === 2 && (
        <NtaiTextField type="number" name={getFieldName(field)} />
      )}

      {field.typeCode === 3 && (
        <NtaiDateField type="number" name={getFieldName(field)} />
      )}

      {field.typeCode === 4 && (
        <NtaiFilterMultiSelectList
          name={getFieldName(field)}
          label={_.get(field, "name")}
          options={_.isArray(multiOptionValues) ? multiOptionValues : []}
        />
      )}

      {field.typeCode === 5 && (
        <NtaiSelectField
          name={getFieldName(field)}
          options={_.isArray(multiOptionValues) ? multiOptionValues : []}
        />
      )}

      {field.typeCode === 6 && (
        <NtaiFilterCheckboxGroup
          name={getFieldName(field)}
          options={_.isArray(multiOptionValues) ? multiOptionValues : []}
        />
      )}

      {field.typeCode === 7 && (
        <NtaiFilterRadioGroup
          label={_.get(field, "name")}
          name={getFieldName(field)}
          column
          options={_.isArray(multiOptionValues) ? multiOptionValues : []}
        />
      )}

      {field.typeCode === 8 && (
        <NtaiFilterSlider
          name={getFieldName(field)}
          label={_.get(field, "name")}
          min={0}
          max={100}
          size="small"
        />
      )}

      {/* {field.typeCode === 9 && (
        <NtaiFilterRangeSlider
          name={getFieldName(field)}
          label={_.get(field, "name")}
          min={0}
          max={100}
          size="small"
        />
      )}

      {field.typeCode === 10 && (
        <NtaiFilterDateRangeSlider
          label={_.get(field, "name")}
          name={getFieldName(field)}
        />
      )} */}
    </NtaiFilterBox>
  );
}
