import { useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceOutStoreElasticForm from "./SourceOutStoreElasticForm";
import History from "@ntai/@history";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  clearStatus,
  createSourceOutDataset,
  getSourceOutDatasets,
} from "../store/sourceOutDatasetsSlice";
import { useEffect } from "react";
const _ = require("lodash");

export default function SourceOutStoreElastic() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = useSelector(
    (state) => state.sources.sourceOutStoresSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const data =
    Object.keys(dataMap).length > 0 ? Object.values(dataMap)[0] : null;

  const status = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.status
  );

  function handleDelete() {
    // console.log("Delete this dataset: ", data["uuId"]);
    // dispatch(
    //   deleteSourceOutDataset({
    //     sourceDefId: sourceDefId,
    //     sourceId: sourceId,
    //     sourceOutStoreId: sourceOutStoreId,
    //     uuId: data["uuId"],
    //   })
    // );
  }

  function handleFormSubmit(formData) {
    dispatch(
      createSourceOutDataset({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceOutStoreId: sourceOutStoreId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSourceOutDatasets" &&
      status.result === "success"
    ) {
      if (data) setAction("edit");
      else setAction("add");
      dispatch(clearStatus());
    }

    if (status && status.method === "createSourceOutDataset") {
      if (status.result === "success") {
        dispatch(clearStatus());
        setAction("edit");
      } else if (status.result === "error") {
        enqueueSnackbar("Error creating dataset: " + status.message, {
          variant: "error",
        });
        dispatch(clearStatus());
      }
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceOutDatasets({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceOutStoreId: sourceOutStoreId,
      })
    );
  }, []);

  function handleCancel() {
    History.push(`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`);
  }

  return (
    <NtaiPage
      title="Analytics Store"
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/outstores`}
      // headerActions={
      //   _.has(data, "uuId") && data["uuId"] ? (
      //     <Button size="small" onClick={() => handleDelete()}>
      //       DELETE
      //     </Button>
      //   ) : null
      // }
    >
      {action && (
        <NtaiForm>
          <SourceOutStoreElasticForm
            action={action}
            formData={data}
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
    </NtaiPage>
  );
}
