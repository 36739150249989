import ContactGroupCreate from "./ContactGroupCreate";
import ContactGroupEdit from "./ContactGroupEdit";
import ContactGroupList from "./ContactGroupList";

const ContactGroupConfig = {
  routes: [
    {
      path: "/library/contact-groups",
      exact: true,
      component: ContactGroupList,
    },
    {
      path: "/library/contact-groups/create",
      exact: true,
      component: ContactGroupCreate,
    },
    {
      path: "/library/contact-groups/:id/edit",
      exact: true,
      component: ContactGroupEdit,
    },
  ],
};

export default ContactGroupConfig;
