import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { blue, green, grey } from "@mui/material/colors";
import {
  tooltipConfig,
  gridConfigPadding0,
  xAxisLabelConfig,
} from "../chartConfig";
import { Box, getContrastRatio, hexToRgb, lighten } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import { rgbToHex } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: gridConfigPadding0,
  tooltip: {
    ...tooltipConfig,
    formatter: function (p) {
      console.log("P tooltip: ", p);
      return `${p.marker} ${p["data"]["name"]} <b>${
        p["data"]["filterFg"] ? p["data"]["actual"] : p["data"]["value"]
      }</b> ${
        p["data"]["filterFg"] ? " (<b>" + p["data"]["total"] + "</b>)" : ""
      }`;
    },
  },
  //   color: null,
  series: [
    {
      type: "treemap",
      cursor: "default",
      leafDepth: 1,
      //   visualDimension: 1,
      //   colorMappingBy: "value",
      data: [],
      label: {
        position: "insideTopLeft",
        backgroundColor: "transparent",
        // color: "#fff",
        fontSize: 13,
      },
      name: {
        fontSize: 12,
        color: "#fff",
      },
      // breadcrumb: {
      //   itemStyle: {
      //     color: "#fff",
      //   },
      // },
      levels: [
        {
          itemStyle: {
            gapWidth: 1,
          },
        },
        {
          itemStyle: {
            gapWidth: 1,
          },
        },
        {
          itemStyle: {
            gapWidth: 1,
          },
        },
      ],
    },
  ],
};

function getLabel(color) {
  return {
    padding: 10,
    position: "insideTopLeft",
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: 500,
    color: NtaiUtils.invertColor(rgbToHex(color)),
    formatter: function (params) {
      const valEquals =
        params["data"]["actual"] === params["data"]["total"] ? true : false;

      return (
        params["name"] +
        "\n" +
        params["data"]["actual"] +
        (!valEquals ? " (" + params["data"]["total"] + ")" : "")
      );
    },
  };
}

function getItemStyle(node, color) {
  return {
    cursor: "default",
    color: {
      type: "linear",
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: node["filterFg"]
        ? [
            {
              offset: 0,
              color: lighten(color, 0.5),
            },
            {
              offset: 1 - node["offset"],
              color: lighten(color, 0.5),
            },
            {
              offset: 1 - node["offset"],
              color: color,
            },
            { offset: 1, color: color },
          ]
        : [
            {
              offset: 0,
              color: color,
            },
            {
              offset: 1,
              color: color,
            },
          ],
    },
  };
}

function fetchTooltipValues(vals, metadata) {
  const result = [];
  if (!_.isEmpty(vals) && Object.keys(vals).length > 0) {
    Object.keys(vals).forEach((k) => {
      const field = _.filter(Object.values(metadata["fields"]), { uuId: k });
      if (field.length > 0)
        result.push({ label: field[0]["headerName"], value: vals[k] });
      else result.push({ label: "Value", value: vals[k] });
    });
  }

  return result;
}

export default function NtaiTreeMapEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;

  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function onChartClick(params) {}

  function processData(dat, theme) {
    const colors = NtaiEChartUtil.getColorPalette(theme, dat.length);
    let result = dat.map((d, i) => {
      let color = colors[i];

      if (
        metadataRef.current.hasOwnProperty("colorSchemes") &&
        _.isArray(metadataRef.current["colorSchemes"]) &&
        metadataRef.current["colorSchemes"].length > 0
      ) {
        const hexColor = NtaiEChartUtil.getColorByRules(
          d["value"],
          metadataRef.current["colorSchemes"]
        );
        color = hexToRgb(hexColor);
      }

      const revisedColor = d.hasOwnProperty("colorValue")
        ? NtaiEChartUtil.getItemStyleColor(
            "bg",
            d["colorValue"] &&
              (_.isNumber(d["colorValue"]) ||
                (_.isString(d["colorValue"]) && d["colorValue"].length > 0))
              ? d["colorValue"]
              : d["value"],
            color,
            metadata
          )
        : color;

      const val = {
        name: d["name"],
        value: d["total"],
        filterFg: d["filterFg"],
        total: d["total"],
        actual: d["value"],
        itemStyle: getItemStyle(d, revisedColor),
        label: getLabel(revisedColor),
        // tooltipValues: fetchTooltipValues(d["values"], metadataRef.current),
      };

      if (_.has(d, "children")) {
        val["children"] = processData(d["children"], theme);
      }

      return val;
    });

    return result;
  }

  function onHandleContextMenu(action, params) {
    let sourceWidgetFilterFields = [];
    if (_.has(params, "treeAncestors")) {
      params.treeAncestors.forEach((ta, i) => {
        if (i > 0)
          sourceWidgetFilterFields.push({
            sourceWidgetFieldUuId: NtaiEChartUtil.getSourceWidgetFieldUuId(
              metadataRef.current,
              i
            ),
            fieldOperatorCode: 1,
            fieldValue: ta["name"],
          });
      });

      handleContextMenu(action, sourceWidgetFilterFields);
    }
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);
    const colors = NtaiEChartUtil.getColorPalette(theme, data.length);
    if (data && _.isArray(data) && data.length > 0) {
      const revisedData = processData(data, theme);
      // console.log("Revised tree data: ", revisedData);
      optionCopy["series"][0]["data"] = revisedData;
      optionCopy["color"] = colors;
    }

    setOption(optionCopy);
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box
      id={widgetId}
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      {option["series"][0]["data"].length > 0 && chart}
      {option["series"][0]["data"].length === 0 && <NtaiChartEmptyMessage />}
    </Box>
  );
}
