import React, { useContext, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import NtaiFlatMenu from "@ntai/components/menus/NtaiFlatMenu";
import { useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchDashboardInitContext } from "../SearchDashboardInit";
const _ = require("lodash");

export default function SearchDashboardViewGroupFilters() {
  const theme = useTheme();
  const [filters, setFilters] = useState("All");

  const { selectedViewGroupFilter, setSelectedViewGroupFilter } =
    React.useContext(SearchDashboardInitContext);

  const dashboardGroupsDataMap = useSelector(
    (state) => state.search.searchDashboardInitSlice.dashboardGroups
  );

  const dashboardGroupsData =
    dashboardGroupsDataMap && !_.isEmpty(dashboardGroupsDataMap)
      ? Object.values(dashboardGroupsDataMap)
      : [];

  function handleSelect(item) {
    selectedViewGroupFilter(item);
  }

  useEffect(() => {
    setFilters({
      All: _.sum(Object.values(_.countBy(dashboardGroupsData, "typeCode"))),
      ...NtaiUtils.sortObjectOnKeys(
        _.countBy(dashboardGroupsData, "typeDecode")
      ),
    });
  }, [dashboardGroupsDataMap]);

  return (
    <Box sx={{ flexBasis: "15%", padding: theme.spacing(2) }}>
      <NtaiFlatMenu
        label="FILTER BY"
        data={filters}
        selected={selectedViewGroupFilter}
        handleSelect={handleSelect}
      />
    </Box>
  );
}
