import Oversight from "./Oversight";

const OversightConfig = {
  routes: [
    {
      path: "/oversight",
      exact: true,
      component: Oversight,
    },
  ],
};

export default OversightConfig;
