import {
  Box,
  Button,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import NtaiTestCrudTable from "./tables/crud/NtaiTestCrudTable";
import NtaiTabs from "./tabs/NtaiTabs";
import NtaiTestPanel from "./panels/NtaiTestPanel";
import NtaiTestMenus from "./menus/NtaiTestMenus";
import NtaiTestPopper from "./poppers/NtaiTestPopper";
import NtaiTestTabs from "./tabs/NtaiTestTabs";
import NtaiTestDrawers from "./drawers/NtaiTestDrawers";
import NtaiTestMouseDrawer from "./drawers/NtaiTestMouseDrawer";
import NtaiTestAccordions from "./accordions/NtaiTestAccordions";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiTestFormInputs from "@ntai/components/inputs/NtaiTestFormInputs";
import NtaiTestDialogs from "@ntai/components/dialogs/NtaiTestDialogs";
import NtaiTestMicros from "@ntai/components/micros/NtaiTestMicros";
import NtaiAceEditor from "./codeeditor/ace/NtaiAceEditor";
import NtaiTestCards from "./cards/NtaiTestCards";
import TestNtaiAutoSuggest from "./autosuggest/TestNtaiAutoSuggest";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiTestResizable from "./resizable/NtaiTestResizable";
import NtaiTestCharts from "./charts/NtaiTestCharts";
import NtaiTestCharts2 from "./charts/NtaiTestCharts2";
import NtaiQueryParser from "./parser/NtaiQueryParser";
import TestHorizontalScollingItems from "./scrollingmenus/TestHorizontalScollingItems";
import Scrollbar from "./Scrollbar";
import NtaiTestDnD from "./dnd/NtaiTestDnd";
import NtaiTestLists from "./lists/NtaiTestLists";
import NtaiTestTreeView from "./treeview/NtaiTestTreeView";
import NtaiTestTabs2 from "./tabs/NtaiTestTabs2";
import NtaiPlayground from "./playground/NtaiPlayground";
import TestNtaiQueryBuilder from "./querybuilder/TestNtaiQueryBuilder";
import NtaiTestPopper2 from "./poppers/NtaiTestPopper2";
import TestNtaiJsonToHtml from "./viewer/TestNtaiJsonToHtml";
import TestNtaiApexCharts from "./charts/apexcharts/TestNtaiApexCharts";
import TestNtaiTables from "./tables/TestNtaiTables";
import { useDispatch } from "react-redux";
import {
  setAppHeader,
  setLeftSidebarNav,
} from "app/store/ntai/navigationSlice";
import { useEffect } from "react";
import NtaiTestReportViewer from "./reports/NtaiTestReportViewer";
import TestNtaiInfiniteScroll from "./infinite-scroll/TestNtaiInfiniteScroll";
import NtaiTestMapCharts from "./charts/NtaiTestMapCharts";
import NtaiTestWidgetTables from "./tables/widgets/NtaiTestWidgetTables";
import NtaiTestD3 from "./charts/d3/NtaiTestD3";
import NtaiTestFilters from "./inputs/filters/NtaiTestFilters";
import NtaiTanStackTable from "./tables/tanstack/NtaiTanStackTable";
import NtaiXTable from "./tables/xtab/NtaiXTable";
import NtaiXTable1 from "./tables/xtab/NtaiXTabNested1";
import NtaiXTable0 from "./tables/xtab/NtaiXTable0";
import NtaiSplitPane from "./splitpane/NtaiSplitPane";
import NtaiDateCalendar from "./calendar/NtaiDateCalendar";
import TestDashboardWidgets from "./widgets/dashboard/templates/TestDashboardWidgets";
import NtaiChartPopup from "./charts/amcharts/NtaiChartPopup";
import TestDateCalendar from "./calendar/TestDateCalendar";
import NtaiImageArea from "./img-area/NtaiImageArea";
import TestNtaiImageArea from "./img-area/TestNtaiImageArea";
import NtaiTestAmcharts2 from "./charts/amcharts/v2/NtaiTestAmcharts2";
import { NtaiDnd2 } from "./dnd2/NtaiDnd2";
import { NtaiDnd21 } from "./dnd2/NtaiDnd2";
import TestNtaiDnd2 from "./dnd2/TestNtaiDnd2";
import NtaiTestGrid from "./grid/NtaiTestGrid";
import TestNtaiServerInputs from "./inputs/server/TestNtaiServerInputs";
import TestNtaiAmchartsFiltered from "./charts/amcharts/filtered/TestNtaiAmchartsFiltered";
import NtaiTestForceGraph from "./charts/force-graph/NtaiTestForceGraph";
import NtaiTestECharts from "./charts/echarts/NtaiTestECharts";
import TestNtaiSearchBar from "./searchbar/TestNtaiSearchBar";
import TestReactStates from "./states/TestReactStates";
import TestRBAC from "./rbac/TestRBAC";
import NtaiRichEditor from "./editor/NtaiRichEditor";
import TestRichEditor from "./editor/TestRichEditor";
import TestNtaiCronScheduler from "./cron/TestNtaiCronScheduler";
import TestNtaiDataTable from "./data-table/TestNtaiDataTable";
import TestNtaiMRTable from "./tables/material-react/TestNtaiMRTable";
import TestBPMNModeler from "./bpmn/TestBPMNModeler";
import NtaiTestDnd3 from "./dnd3/NtaiTestDnd3";
import NtaiTestGeoMap from "./charts/geo/NtaiTestGeoMap";
import TestNtaiColorPicker from "./color-picker/TestNtaiColorPicker";
import NtaiTestPdfReport from "./pdf-report/NtaiTestPdfReport";
import NtaiTestECharts2 from "./charts/echarts/NtaiTestECharts2";

const _ = require("lodash");

const components = [
  {
    id: "accordions",
    label: "Accordions",
    component: <NtaiTestAccordions />,
  },
  {
    id: "d3",
    label: "D3",
    component: <NtaiTestD3 />,
  },
  {
    id: "tables",
    label: "Tables",
    component: <NtaiTestCrudTable />,
  },
  {
    id: "tables2",
    label: "Tables2",
    component: <TestNtaiTables />,
  },
  {
    id: "export-pdf",
    label: "Export PDF",
    component: <NtaiTestPdfReport />,
  },

  {
    id: "xtab",
    label: "A Tables X Tab",
    component: <NtaiXTable0 />,
  },
  {
    id: "dashboard-widgets",
    label: "Dashboard Widgets",
    component: <TestDashboardWidgets />,
  },
  {
    id: "calendar",
    label: "Calendar",
    component: <TestDateCalendar />,
  },
  {
    id: "tanstackTable",
    label: "Tanstack Table",
    component: <NtaiTanStackTable />,
  },
  {
    id: "splitpane",
    label: "Split Pane",
    component: <NtaiSplitPane />,
  },
  {
    id: "imagearea",
    label: "Image Area",
    component: <TestNtaiImageArea />,
  },
  {
    id: "states",
    label: "React States",
    component: <TestReactStates />,
  },
  {
    id: "dialogs",
    label: "Dialogs",
    component: <NtaiTestDialogs />,
  },
  {
    id: "poppers",
    label: "Poppers",
    component: <NtaiTestPopper />,
  },
  {
    id: "poppers1",
    label: "Poppers2",
    component: <NtaiTestPopper2 />,
  },
  {
    id: "forminputs",
    label: "Form Inputs",
    component: (
      <NtaiForm>
        <NtaiTestFormInputs />
      </NtaiForm>
    ),
  },
  {
    id: "filterinputs",
    label: "Filter Inputs",
    component: (
      <NtaiForm>
        <NtaiTestFilters />
      </NtaiForm>
    ),
  },

  {
    id: "force-graph",
    label: "Force Graphs",
    component: <NtaiTestForceGraph />,
  },

  {
    id: "grid",
    label: "Grid",
    component: <NtaiTestGrid />,
  },

  {
    id: "jsonviewer",
    label: "Viewers",
    component: <TestNtaiJsonToHtml />,
  },

  {
    id: "panels",
    label: "Panels",
    component: <NtaiTestPanel />,
  },
  {
    id: "inputs-server",
    label: "Form Inputs Server",
    component: (
      <NtaiForm>
        <TestNtaiServerInputs />
      </NtaiForm>
    ),
  },

  {
    id: "cards",
    label: "Cards",
    component: <NtaiTestCards />,
  },
  {
    id: "charts",
    label: "Charts",
    component: (
      <NtaiForm>
        <NtaiTestCharts />
      </NtaiForm>
    ),
  },
  {
    id: "echarts",
    label: "Charts E",
    component: (
      <NtaiForm>
        <NtaiTestECharts />
      </NtaiForm>
    ),
  },

  {
    id: "chartstests",
    label: "Charts E2",
    component: (
      <NtaiForm>
        <NtaiTestECharts2 />
      </NtaiForm>
    ),
  },
  {
    id: "amcharts2",
    label: "Charts 2.0",
    component: <NtaiTestAmcharts2 />,
  },
  {
    id: "chartfilters",
    label: "Charts Filters",
    component: <TestNtaiAmchartsFiltered />,
  },

  {
    id: "tabs",
    label: "Tabs",
    component: <NtaiTestTabs />,
  },
  {
    id: "tabs2",
    label: "Tabs 2",
    component: (
      <NtaiForm>
        <NtaiTestTabs2 />
      </NtaiForm>
    ),
  },
  {
    id: "material-react-table",
    label: "MR Table",
    component: <TestNtaiMRTable />,
  },
  {
    id: "code",
    label: "Code",
    component: <NtaiAceEditor />,
  },
  {
    id: "dnd",
    label: "Drag and Drop",
    component: <NtaiTestDnD />,
  },
  {
    id: "rich-editor",
    label: "Rich Editor",
    component: <TestRichEditor />,
  },
  {
    id: "dnd2",
    label: "Drag and Drop 2",
    component: <TestNtaiDnd2 />,
  },
  {
    id: "rbac",
    label: "RBAC",
    component: <TestRBAC />,
  },

  {
    id: "resizeable",
    label: "Resizeable",
    component: <NtaiTestResizable />,
  },
  {
    id: "autosuggest",
    label: "Auto-suggest",
    component: (
      <NtaiForm>
        <TestNtaiAutoSuggest />
      </NtaiForm>
    ),
  },
  {
    id: "menus",
    label: "Menus",
    component: <NtaiTestMenus />,
  },
  {
    id: "drawers",
    label: "Drawers",
    component: <NtaiTestDrawers />,
  },
  {
    id: "mousedrawers",
    label: "Drawers (Mouse)",
    component: <NtaiTestMouseDrawer />,
  },
  {
    id: "horizontalscrolling",
    label: "Scrolling Menus",
    component: <TestHorizontalScollingItems />,
  },
  {
    id: "micros",
    label: "Micros",
    component: <NtaiTestMicros />,
  },
  {
    id: "lists",
    label: "Lists",
    component: <NtaiTestLists />,
  },
  {
    id: "treeview",
    label: "Tree View",
    component: <NtaiTestTreeView />,
  },

  {
    id: "queryparsers",
    label: "Query Parsers",
    component: (
      <NtaiForm>
        <NtaiQueryParser />
      </NtaiForm>
    ),
  },
  {
    id: "geomap",
    label: "Geo Map",
    component: <NtaiTestGeoMap />,
  },
  {
    id: "color-picker",
    label: "Color Picker",
    component: (
      <NtaiForm>
        <TestNtaiColorPicker />
      </NtaiForm>
    ),
  },

  {
    id: "chartpopup",
    label: "Chart Popup",
    component: <NtaiChartPopup />,
  },
  {
    id: "dnd3",
    label: "Dnd 3",
    component: <NtaiTestDnd3 />,
  },

  {
    id: "querybuilder",
    label: "Query Builder",
    component: (
      <NtaiForm>
        <TestNtaiQueryBuilder />
      </NtaiForm>
    ),
  },
  {
    id: "playground",
    label: "Playground",
    component: (
      <NtaiForm>
        <NtaiPlayground />
      </NtaiForm>
    ),
  },
  {
    id: "apexcharts",
    label: "Charts Apex",
    component: <TestNtaiApexCharts />,
  },
  {
    id: "cron",
    label: "Cron",
    component: <TestNtaiCronScheduler />,
  },
  {
    id: "reports",
    label: "Reports",
    component: <NtaiTestReportViewer />,
  },
  {
    id: "infinite-scroll",
    label: "Infinite Scroll",
    component: <TestNtaiInfiniteScroll />,
  },
  {
    id: "search-bar",
    label: "Search Bar",
    component: (
      <NtaiForm>
        <TestNtaiSearchBar />
      </NtaiForm>
    ),
  },
  {
    id: "data-table",
    label: "Data Table",
    component: <TestNtaiDataTable />,
  },
  {
    id: "bpmn-modeler",
    label: "BPMN Modeler",
    component: <TestBPMNModeler />,
  },
];

export default function Playground() {
  const theme = useTheme();
  const [content, setContent] = React.useState(components[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppHeader(true));
    setLeftSidebarNav(false);
    localStorage.setItem("leftSidebarNav", "false");
    localStorage.setItem("appHeader", "true");
  }, []);

  return (
    <NtaiPage title="UX Components">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flexBasis: "20%",
            display: "flex",
            height: "100%",
            borderRight: theme.general.border1,
          }}
        >
          <Scrollbar>
            <MenuList sx={{ width: "100%" }}>
              {_.sortBy(components, "label").map((c, index) => {
                return (
                  <MenuItem
                    key={`test-component-menu-${index}`}
                    onClick={() => setContent(c)}
                    selected={c.id === content.id}
                  >
                    <ListItemText>{c.label}</ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Scrollbar>
        </Box>
        <Box
          sx={{
            flexBasis: "80%",
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "44px",
              display: "flex",
              borderBottom: theme.general.border1,
              alignItems: "center",
              pl: theme.spacing(1),
            }}
          >
            <Typography variant="h4" color="text.secondary" fontWeight="500">
              {content.label}
            </Typography>
          </Box>
          <Box
            sx={{
              height: `calc(100% - 44px)`,
              display: "flex",
            }}
          >
            <Scrollbar>{content.component}</Scrollbar>
          </Box>
        </Box>
      </Box>
    </NtaiPage>
  );
}
