import { Tooltip, styled } from "@mui/material";
import { grey } from "@mui/material/colors";

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);
const NtaiTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: "#eeeeee",
  color: "white",
  border: "1px solid #dadde9",
}));

export default NtaiTooltip;
