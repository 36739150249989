import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  createObjectSourceView,
  deleteObjectSourceView,
  getObjectSourceViews,
  updateObjectSourceView,
  setActiveId,
} from "./store/objectSourceViewsSlice";
import ObjectSourceViewForm from "./ObjectSourceViewForm";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified by",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "delete",
  },
];

export default function ObjectSourceViewList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState("list");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const domainsDataMap = useSelector((state) => state.core.domainsSlice.data);
  const domainsData = Object.values(domainsDataMap);
  const domainId = _.get(domainsData[0], "uuId");

  const objectId = useSelector((state) => state.core.objectsSlice.activeId);

  const dataMap = useSelector(
    (state) => state.core.objectSourceViewsSlice.data
  );
  const status = useSelector(
    (state) => state.core.objectSourceViewsSlice.status
  );

  const activeId = useSelector(
    (state) => state.core.objectSourceViewsSlice.activeId
  );

  const data = Object.values(dataMap);

  function handleAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
      dispatch(setActiveId(id));
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
    setCurrentId(null);
    dispatch(setActiveId(null));
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteObjectSourceView({
          domainId: domainId,
          objectId: objectId,
          uuId: id,
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getObjectSourceViews" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createObjectSourceView"].includes(status.method) &&
      status.result === "success"
    ) {
      setAction("edit");
      setCurrentId(activeId);
      dispatch(clearStatus());

      enqueueSnackbar(
        `Oversight view "${
          dataMap[action === "add" ? activeId : currentId]["name"]
        }" created successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      ["updateObjectSourceView"].includes(status.method) &&
      status.result === "success"
    ) {
      dispatch(clearStatus());

      enqueueSnackbar(
        `Oversight view "${
          dataMap[action === "add" ? activeId : currentId]["name"]
        }" updated successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteObjectSourceView"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Oversight view(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  function handleSave(formData) {
    if (action === "add")
      dispatch(
        createObjectSourceView({
          domainId: domainId,
          objectId: objectId,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateObjectSourceView({
          domainId: domainId,
          objectId: objectId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  useEffect(() => {
    dispatch(
      getObjectSourceViews({
        domainId: domainId,
        objectId: objectId,
      })
    );
  }, []);

  return (
    <>
      {action === "list" && (
        <NtaiPanel
          width="75%"
          header="Oversight Views"
          subheader="Manage oversight views for the object - source relation"
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              selectedIds={selectedIds}
              toolbarActions={toolbarActions}
              keyColumn="uuId"
              isDensed={true}
              dialog={true}
              path={`/cdo/domains/${domainId}/objects/${objectId}/source-views`}
              handleDialogAddEdit={handleAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Source Views"
            />
          )}
          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      )}

      {(action === "add" || (action === "edit" && currentId)) && (
        <NtaiPanel
          width="75%"
          header={action === "add" ? "Add Source View" : "Edit Source View"}
          subheader="Enter the packages for the domain that you have selected"
        >
          <ObjectSourceViewForm
            action={action}
            formData={action === "add" ? null : dataMap[currentId]}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </NtaiPanel>
      )}

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
