import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

const logoIcon =
  process.env.REACT_APP_NTAI_WEB_BASE_URL + "/images/LOGO-Login2.png";

export default function LoginForm({ handleFormSubmit }) {
  const theme = useTheme();
  const {
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext();

  const status = useSelector((state) => state.auth.loginSlice.status);

  function onHandleFormSubmit() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("loginForm")
      );

      if (
        _.get(sanitizedFormData, "username") &&
        _.get(sanitizedFormData, "password")
      ) {
        handleFormSubmit(sanitizedFormData);
      }
    }
  }

  useEffect(() => {
    reset({ loginForm: {} });
  }, []);

  return (
    <Box
      sx={{
        p: theme.spacing(4),
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: theme.spacing(2),
        justifyContent: "start",
        alignContent: "center",
        alignItems: "center",
        background: theme.colors.secondary.lighter,
        borderRadius: theme.general.borderRadius,
      }}
    >
      <img height="auto" width="120px" src={logoIcon} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="loginForm.username"
          sx={{ background: "white" }}
          label="Username"
          rules={{ required: "Username is required" }}
        />
        <NtaiTextField
          type="password"
          sx={{ background: "white" }}
          name="loginForm.password"
          label="Password"
          rules={{ required: "Password is required" }}
        />
        {status && status.result === "error" && status.message && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 700 }}
              color="error"
            >
              {status.message === "Authentication error"
                ? "Invalid credentials. Please try again."
                : status.message}
              {/* Invalid credentials. Please try again. */}
            </Typography>
          </Box>
        )}

        {status && ["logout", "session-timeout"].includes(status.result) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 700 }}
              color={status.result === "logout" ? "success" : "error"}
            >
              {status.result === "logout"
                ? "You have been logged out"
                : "Your session has expired. Please log in again."}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Button
            onClick={() => onHandleFormSubmit()}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography sx={{ justifyContent: "end" }} variant="subtitle2">
              Forgot Password?
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
