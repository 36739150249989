import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, Typography, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

const _ = require("lodash");

export default function ThesaurusLoadForm(props) {
  const theme = useTheme();
  const { thesaurusId, action, handleDialogClose, formData, handleFormSubmit } =
    props;
  const { getValues, reset } = useFormContext();
  const dispatch = useDispatch();

  function handleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("thesaurusLoadForm")
    );
    handleFormSubmit({ ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ thesaurusLoadForm: action === "get" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={handleSave}
      handleSaveLabel="Start Import"
      handleCancel={handleDialogClose}
      title={
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faWarning} color="red" />
          <Typography
            variant="h6"
            fontWeight="700"
          >{`Thesaurus Id: ${thesaurusId}`}</Typography>
        </Box>
      }
      subheader="Ensure below mentioned files are placed under tenants/<TENANT_ID>/thesaurus/<THESAURUS_ID>/load/current folder on S3/HDFS"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <NtaiSelectField
          name="thesaurusLoadForm.loadTypeCode"
          label="Import Type"
          options={[
            { value: 1, label: "Drop and Recreate" },
            { value: 2, label: "Append" },
          ]}
          isDisabled={action === "get" ? true : false}
        />

        <NtaiTextField
          name="thesaurusLoadForm.semTypeFileName"
          label="Semantic Type File Name"
          defaultValue="sem_types.csv"
          rules={{ required: "Semantic file is required" }}
          disabled={action === "get" ? true : false}
        />

        <NtaiTextField
          name="thesaurusLoadForm.conceptFileName"
          label="Concept File Name"
          defaultValue="concepts.csv"
          rules={{ required: "Concept file is required" }}
          disabled={action === "get" ? true : false}
        />

        <NtaiTextField
          name="thesaurusLoadForm.conceptTermFileName"
          label="Concept Term File Name"
          defaultValue="concept_terms.csv"
          rules={{ required: "Concept term file is required" }}
          disabled={action === "get" ? true : false}
        />

        <NtaiTextField
          name="thesaurusLoadForm.conceptRelFileName"
          label="Concept Relation File Name"
          defaultValue="concept_relations.csv"
          disabled={action === "get" ? true : false}
        />
      </Box>
    </NtaiDialogFormPanel>
  );
}
