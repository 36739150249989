import NtaiDashboardMetric2Widget from "@ntai/components/widgets/dashboard/templates/NtaiDashboardMetric2Widget";
import React from "react";

export default function ModelsSummaryWidget({ data }) {
  return (
    <NtaiDashboardMetric2Widget
      id="model-summary"
      data={data}
      footer="Model summary showing # of models, datasets and functions"
    />
  );
}
