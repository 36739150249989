import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";

const _ = require("lodash");

export const initializeSearch = createAsyncThunk(
  "search/initializeSearch",
  async (params, { rejectWithValue }) => {
    try {
      const response = await server.get(`/search-init`, {
        params: params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const previewSearch = createAsyncThunk(
  "search/previewSearch",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/search-init/preview", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchInitSlice = createSlice({
  name: "searchInit",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [initializeSearch.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "initializeSearch" };
    },
    [initializeSearch.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "initializeSearch",
        message: action.payload.message,
      };
      state.data = {};
    },
    [previewSearch.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "previewSearch" };
    },
    [previewSearch.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "previewSearch",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchInitSlice.actions;

export default searchInitSlice.reducer;
