import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

class SourceWidgetUtil {
  static getActiveWidgetIds(
    selectedSourceFieldUuIds,
    sourceFieldsDataMap,
    sourceDerivedFieldsDataMap,
    widgets
  ) {
    const activeWidgetUuIds = [];

    let numFields = 0;
    let numCatFields = 0;
    let numNumFields = 0;
    let numDateFields = 0;

    numFields = selectedSourceFieldUuIds.length;

    selectedSourceFieldUuIds.forEach((f) => {
      const fieldTypeUuIdParts = f.split(",");

      const fieldType = fieldTypeUuIdParts[0];
      const fieldUuId = fieldTypeUuIdParts[1];

      if (fieldType === "1") {
        if (sourceFieldsDataMap[fieldUuId].esDataTypeCode === 4) numCatFields++;
      } else {
        if (
          [1, 4].includes(sourceDerivedFieldsDataMap[fieldUuId].fieldTypeCode)
        )
          numCatFields++;
      }
    });

    selectedSourceFieldUuIds.forEach((f) => {
      const fieldTypeUuIdParts = f.split(",");
      const fieldType = fieldTypeUuIdParts[0];
      const fieldUuId = fieldTypeUuIdParts[1];

      if (fieldType === "1") {
        if (sourceFieldsDataMap[fieldUuId].esDataTypeCode === 2) numNumFields++;
      } else {
        if (sourceDerivedFieldsDataMap[fieldUuId].fieldTypeCode === 2)
          numNumFields++;
      }
    });

    selectedSourceFieldUuIds.forEach((f) => {
      const fieldTypeUuIdParts = f.split(",");
      const fieldType = fieldTypeUuIdParts[0];
      const fieldUuId = fieldTypeUuIdParts[1];

      if (fieldType === "1") {
        if (sourceFieldsDataMap[fieldUuId].esDataTypeCode === 3)
          numDateFields++;
      } else {
        if (sourceDerivedFieldsDataMap[fieldUuId].fieldTypeCode === 3)
          numDateFields++;
      }
    });

    widgets.forEach((w) => {
      let activateWidget = false;

      switch (w.contentCode) {
        case CHART_CODES.DATA_GRID_I:
        case CHART_CODES.DATA_GRID_II:
          activateWidget = w.minFields <= numFields && w.maxFields >= numFields;
          break;

        case CHART_CODES.SCATTER:
          activateWidget =
            numNumFields === 2 && [numCatFields, numDateFields].includes(0);
          break;

        case CHART_CODES.SCRIPTED_METRIC:
          activateWidget = true;
          break;

        default:
          activateWidget =
            w.minCategoryFields <= numCatFields &&
            w.maxCategoryFields >= numCatFields &&
            w.minNumericFields <= numNumFields &&
            w.maxNumericFields >= numNumFields &&
            w.minDateFields <= numDateFields &&
            w.maxDateFields >= numDateFields;
          break;
      }

      if (activateWidget) {
        // console.log(
        //   "Pushing Widget: ",
        //   w,

        //   ", Added: ",
        //   activateWidget
        // );
        activeWidgetUuIds.push(w.uuId);
      }
    });

    return activeWidgetUuIds;
  }

  static isWidgetFieldDimension(f) {
    if (
      (f.derivedFieldFg &&
        f.derivedFieldFg === 1 &&
        [1, 4].includes(f.sourceFieldDataTypeCode)) ||
      (!f.drivedFieldFg && [1].includes(f.sourceFieldDataTypeCode))
    )
      return true;
    else return false;
  }

  static isWidgetFieldMeasure(f) {
    if (
      (f.derivedFieldFg &&
        f.derivedFieldFg === 1 &&
        [2].includes(f.sourceFieldDataTypeCode)) ||
      (!f.drivedFieldFg && [2, 3, 4, 5, 6].includes(f.sourceFieldDataTypeCode))
    )
      return true;
    else return false;
  }

  static isWidgetFieldDate(f) {
    if (
      (f.derivedFieldFg &&
        f.derivedFieldFg === 1 &&
        [3].includes(f.sourceFieldDataTypeCode)) ||
      (!f.drivedFieldFg && [7, 8].includes(f.sourceFieldDataTypeCode))
    )
      return true;
    else return false;
  }

  static getDimensionWidgetFields(widgetFields) {
    return _.filter(widgetFields, function (f) {
      if (
        (f.derivedFieldFg &&
          f.derivedFieldFg === 1 &&
          [1, 4].includes(f.sourceFieldDataTypeCode)) ||
        (!f.drivedFieldFg && [1].includes(f.sourceFieldDataTypeCode))
      )
        return true;
      else return false;
    });
  }

  static getMeasureWidgetFields(widgetFields) {
    return _.filter(widgetFields, function (f) {
      if (
        (f.derivedFieldFg &&
          f.derivedFieldFg === 1 &&
          [2].includes(f.sourceFieldDataTypeCode)) ||
        (!f.drivedFieldFg &&
          [2, 3, 4, 5, 6].includes(f.sourceFieldDataTypeCode))
      )
        return true;
      else return false;
    });
  }

  static getDateWidgetFields(widgetFields) {
    return _.filter(widgetFields, function (f) {
      if (
        (f.derivedFieldFg &&
          f.derivedFieldFg === 1 &&
          [3].includes(f.sourceFieldDataTypeCode)) ||
        (!f.drivedFieldFg && [7, 8].includes(f.sourceFieldDataTypeCode))
      )
        return true;
      else return false;
    });
  }

  static getMeasureSelectOptions(widgetFields) {
    const fields = this.getMeasureWidgetFields(widgetFields);

    if (fields.length > 0)
      return NtaiUtils.getSelectOptions(fields, "uuId", "fieldName");
    else return [];
  }

  static getWidgetFieldDataType(widgetField) {}
}

export default SourceWidgetUtil;
