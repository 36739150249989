import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAlertDefSourceObjectTopics = createAsyncThunk(
  "alertDefSourceObjectTopics/getAlertDefSourceObjectTopics",
  async (values, { rejectWithValue }) => {
    try {
      const { alertDefinitionId, alertDefSourceId } = values;
      const response = await server.get(
        `/monitor/definitions/${alertDefinitionId}/sources/${alertDefSourceId}/object-topics`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrDeleteAlertDefSourceObjectTopics = createAsyncThunk(
  "alertDefSourceObjectTopics/createOrDeleteAlertDefSourceObjectTopics",
  async (values, { rejectWithValue }) => {
    try {
      const { alertDefinitionId, alertDefSourceId, formData } = values;
      const response = await server.post(
        `/monitor/definitions/${alertDefinitionId}/sources/${alertDefSourceId}/object-topics`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAllAlertDefSourceObjectTopics = createAsyncThunk(
  "alertDefSourceObjectTopics/deleteAllAlertDefSourceObjectTopics",
  async (values, { rejectWithValue }) => {
    try {
      const { alertDefinitionId, alertDefSourceId } = values;
      const response = await server.delete(
        `/monitor/definitions/${alertDefinitionId}/sources/${alertDefSourceId}/object-topics`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertDefSourceObjectTopicsSlice = createSlice({
  name: "alertDefSourceObjectTopics",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAlertDefSourceObjectTopics.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getAlertDefSourceObjectTopics",
      };
    },
    [getAlertDefSourceObjectTopics.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAlertDefSourceObjectTopics",
        message: action.payload.message,
      };
    },
    [createOrDeleteAlertDefSourceObjectTopics.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createOrDeleteAlertDefSourceObjectTopics",
      };
    },
    [createOrDeleteAlertDefSourceObjectTopics.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrDeleteAlertDefSourceObjectTopics",
        message: action.payload.message,
      };
    },
    [deleteAllAlertDefSourceObjectTopics.fulfilled]: (state, action) => {
      state.data = {};
      state.status = {
        result: "success",
        method: "deleteAllAlertDefSourceObjectTopics",
      };
    },
    [deleteAllAlertDefSourceObjectTopics.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAllAlertDefSourceObjectTopics",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  alertDefSourceObjectTopicsSlice.actions;

export default alertDefSourceObjectTopicsSlice.reducer;
