import { Avatar, Box } from "@mui/material";
import React from "react";
import NtaiList from "./NtaiList";
import NtaiMenuList from "./NtaiMenuList";

const items = [
  {
    id: 1,
    avatar: <Avatar>M</Avatar>,
    primary: "United States",
    secondary: "Greatest country in the world?",
  },
  {
    id: 2,
    icon: "database",
    iconSize: "lg",
    primary: "India",
    secondary: "Greatest democracy in the world!",
    children: [
      {
        id: 3,
        avatar: <Avatar>F</Avatar>,
        primary: "Karnataka",
        secondary: "Greatest state in the India?",
      },
    ],
  },
];

const menuItems = [
  {
    id: 1,
    icon: "database",
    primary: "United States",
    badge: { title: "v.13" },
  },
  {
    id: 2,
    primary: "India",
    icon: "play",
    children: [
      {
        id: 3,
        primary: "Karnataka",
        children: [
          {
            id: 4,
            primary: "Blr",
          },
        ],
      },
    ],
  },
];

export default function NtaiTestLists() {
  function handleSelect(id) {
    console.log("Selected list item: ", id);
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <NtaiList items={items} handleSelect={handleSelect} />

      <NtaiMenuList items={menuItems} handleSelect={handleSelect} />
    </Box>
  );
}
