import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { faLock as faLockSolid } from "@fortawesome/pro-solid-svg-icons";
import { faLock, faUnlock } from "@fortawesome/pro-light-svg-icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
const _ = require("lodash");

export default function SearchLock({ handleLock }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const searchDataMap = useSelector((state) => state.search.searchSlice.data);
  const searchId = useSelector((state) => state.search.searchSlice.activeId);
  const searchData = _.get(searchDataMap, searchId);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function onHandleLock() {
    handleLock();
    handleClose();
  }

  return (
    <React.Fragment>
      <Tooltip title="Lock/Unlock">
        <IconButton onClick={() => handleOpen()}>
          {searchData["lockedFg"] && searchData["lockedFg"] === 1 ? (
            <FontAwesomeIcon size="2xs" icon={faLockSolid} color="red" />
          ) : (
            <FontAwesomeIcon size="2xs" icon={faUnlock} color="red" />
          )}
        </IconButton>
      </Tooltip>
      <NtaiDialog
        title={`Confirm ${
          searchData["lockedFg"] && searchData["lockedFg"] === 1
            ? "Unlock"
            : "Lock"
        }`}
        size="sm"
        open={open}
        handleDialogClose={handleClose}
      >
        <NtaiDialogFormPanel
          header={`Confirm ${
            searchData["lockedFg"] && searchData["lockedFg"] === 1
              ? "Unlock"
              : "Lock"
          }`}
          handleSave={onHandleLock}
          handleCancel={handleClose}
          handleSaveLabel="Confirm"
        >
          {`Are you sure you want to ${
            searchData["lockedFg"] && searchData["lockedFg"] === 1
              ? "unlock"
              : "lock"
          } this search?`}
        </NtaiDialogFormPanel>
      </NtaiDialog>
    </React.Fragment>
  );
}
