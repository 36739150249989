import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { grey } from "@mui/material/colors";
import {
  tooltipConfig,
  gridConfigPadding0,
  xAxisLabelConfig,
  gridConfig,
} from "../chartConfig";
import { lighten } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import { Box } from "@mui/material";
const _ = require("lodash");

const DEFAULT_OPTION = {
  grid: {
    ...gridConfig,
    containLabel: true,
  },
  tooltip: {
    ...tooltipConfig,
    formatter: function (p) {
      return `${p.marker} ${p["treeAncestors"]
        .map((t, i) => {
          if (t["name"].length > 0) return t["name"];
        })
        .join(".")} <b>${p["data"]["value"]}</b> ${
        p["data"]["filterFg"] ? " (<b>" + p["data"]["total"] + "</b>)" : ""
      }`;
    },
  },
  series: [
    {
      type: "tree",
      symbol: "emptyCircle",
      symbolSize: 7,
      initialTreeDepth: 2,
      data: [],
      animationDurationUpdate: 750,
      emphasis: {
        focus: "descendant",
      },
      label: {
        position: "left",
        verticalAlign: "middle",
        align: "right",
      },
      leaves: {
        label: {
          position: "right",
          verticalAlign: "middle",
          align: "left",
        },
      },
    },
  ],
};

export default function NtaiLinearTreeEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;
  const [option, setOption] = useState(DEFAULT_OPTION);
  //   const [chartInstance, setChartInstance] = useState();
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function onChartClick(params) {}

  function onHandleContextMenu(action, params) {
    if (_.has(params, "treeAncestors")) {
      let sourceWidgetFilterFields = [];
      params.treeAncestors.forEach((ta, i) => {
        if (i > 1) {
          sourceWidgetFilterFields.push({
            sourceFieldUuId: NtaiEChartUtil.getSourceFieldUuId(
              metadataRef.current,
              i - 1
            ),
            fieldOperatorCode: 1,
            fieldValue: ta["name"],
          });
        }
      });
      if (sourceWidgetFilterFields.length > 0)
        handleContextMenu(action, sourceWidgetFilterFields);
    }
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);
    // const colors = NtaiEChartUtil.getColorPalette(theme, data.length);
    optionCopy["series"][0]["data"] = [{ name: "ROOT", children: data }];
    // optionCopy["series"][0]["data"] = data;
    optionCopy["initialTreeDepth"] = Object.keys(metadata["fields"]).length;
    setOption(optionCopy);
  }, [data]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"][0]["data"].length > 0 && chart}
    </Box>
  );
}
