import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import "date-fns";
import NtaiInputErrorMessage from "./NtaiInputErrorMessage";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flight, OnlinePrediction } from "@mui/icons-material";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";

const NtaiMuiDateField = (props) => {
  const { label, name, field } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl>
      <DatePicker
        label={label}
        renderInput={(props1) => (
          <TextField size="small" {...props1} {...props} />
        )}
        {...props}
      />
      <NtaiInputErrorMessage name={name} errors={errors} />
    </FormControl>
  );
};

function NtaiDateField(props) {
  const { control, setValue } = useFormContext();
  const { name, label } = props;

  return (
    <React.Fragment>
      <Controller
        render={({ ref, field: { onChange, value, name } }) => (
          <NtaiMuiDateField
            name={name}
            value={value}
            onChange={(date) => setValue(name, date)}
            {...props}
          />
        )}
        control={control}
        defaultValue={null}
        setValue={setValue}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiDateField;
