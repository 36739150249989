import { Box, Button, CircularProgress, Drawer, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchHeader from "./header/SearchHeader";
import SearchSource from "./source/SearchSource";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { clearStatus, getSearch, setActiveId } from "../store/searchSlice";

import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import SearchSourceWrapper from "./source/SearchSourceWrapper";
import SearchAdvSourceWrapper from "./source/advanced/SearchAdvSourceWrapper";
import { clearAll } from "./source/view/widgets/store/searchSourceViewWidgetsSlice";
import SearchDashboardWrapper from "./source/dashboard/SearchDashboardWrapper";
const _ = require("lodash");

export const SearchContext = React.createContext();

function getShowAdvSearchRecordsPanel(
  searchData,
  selectedSearchObjectDashboardIndex
) {
  if (searchData["typeCode"] === 1) {
    return true;
  } else if ([2, 4, 5, 6].includes(searchData["typeCode"])) {
    return true;
  } else {
    return searchData["searchObjectDashboards"][
      selectedSearchObjectDashboardIndex
    ]["displayRecordsFg"] === 1
      ? true
      : false;
  }
}

function getShowAdvSearchFiltersPanel(
  searchData,
  selectedSearchObjectDashboardIndex
) {
  if (searchData["typeCode"] === 1) {
    return false;
  } else if ([2, 4, 5, 6].includes(searchData["typeCode"])) {
    return false;
  } else {
    return searchData["searchObjectDashboards"][
      selectedSearchObjectDashboardIndex
    ]["displayFiltersFg"] === 1
      ? false
      : false;
  }
}

export default function SearchEdit(props) {
  const dispatch = useDispatch();

  const [searchDashboardSourceViewId, setSearchDashboardSourceViewId] =
    useState();
  const [
    selectedSearchObjectDashboardIndex,
    setSelectedSearchObjectDashboardIndex,
  ] = useState(0);
  const searchId = props.match.params.id;
  const searchData = useSelector(
    (state) => state.search.searchSlice.searchEditData
  );

  const status = useSelector((state) => state.search.searchSlice.status);

  const [showSearchSourceRecords, setShowSearchSourceRecords] = useState();
  const [showAdvSearchFilterPanel, setShowAdvSearchFilterPanel] = useState();

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearch"
    ) {
      // setLoaded(true);
      setShowSearchSourceRecords(
        getShowAdvSearchRecordsPanel(
          searchData,
          selectedSearchObjectDashboardIndex
        )
      );
      setShowAdvSearchFilterPanel(
        getShowAdvSearchFiltersPanel(
          searchData,
          selectedSearchObjectDashboardIndex
        )
      );
      // dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSearch(searchId));
    dispatch(clearAll());
  }, []);

  return (
    <SearchContext.Provider
      value={{
        showAdvSearchFilterPanel,
        setShowAdvSearchFilterPanel,
        searchData,
        showSearchSourceRecords,
        setShowSearchSourceRecords,
        selectedSearchObjectDashboardIndex,
        setSelectedSearchObjectDashboardIndex,
        searchDashboardSourceViewId,
        setSearchDashboardSourceViewId,
      }}
    >
      <NtaiForm>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          {status &&
            ["success", "error"].includes(status.result) &&
            ["getSearch", "updateSearch"].includes(status.method) &&
            !_.isEmpty(searchData) && (
              <React.Fragment>
                <SearchHeader />
                {_.get(searchData, "typeCode") === 1 && <SearchSourceWrapper />}
                {[2, 4, 5, 6].includes(_.get(searchData, "typeCode")) && (
                  <SearchAdvSourceWrapper />
                )}
                {_.get(searchData, "typeCode") === 3 && (
                  <SearchDashboardWrapper />
                )}
              </React.Fragment>
            )}
          {status && status.result === "pending" && (
            <NtaiCircularProgress size={36} />
          )}
        </Box>
      </NtaiForm>
    </SearchContext.Provider>
  );
}
