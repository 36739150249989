import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  getSourceRecordTags,
  createSourceRecordTag,
  updateSourceRecordTag,
  deleteSourceRecordTag,
  clearStatus,
  setActiveId,
} from "./store/sourceRecordTagsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SourceRecordTagForm from "./SourceRecordTagForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useSnackbar } from "notistack";
import { Drawer } from "@mui/material";
import { getSourceDerivedFields } from "../../derivedfield/store/sourceDerivedFieldsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "sourceDerivedField.label",
    numeric: false,
    disablePadding: false,
    label: "Field",
  },

  {
    id: "maxLength",
    numeric: false,
    disablePadding: false,
    label: "Maximum Length",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Date Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

export default function SourceRecordTagList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loaded, setLoaded] = React.useState(false);

  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceIds = { sourceDefId: sourceDefId, sourceId: sourceId };

  const dataMap = useSelector(
    (state) => state.sources.sourceRecordTagsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceRecordTagsSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
      dispatch(setActiveId(id));
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDrawerOpen();
  }

  function handleDrawerOpen() {
    setOpenDrawer(true);
  }

  function handleDrawerClose() {
    setOpenDrawer(false);
    setAction(null);
    setCurrentId(null);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(
          deleteSourceRecordTag({
            ...sourceIds,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceRecordTag({
            ...sourceIds,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceRecordTag({
            ...sourceIds,
            formData: formData,
          })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  useEffect(() => {
    //TODO: should be loaded when derived fields are also loaded
    if (
      status &&
      status.method === "getSourceRecordTags" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      ["createSourceRecordTag", "updateSourceRecordTag"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      handleDrawerClose();
      dispatch(clearStatus());
      setAction(null);
      setCurrentId(null);
      dispatch(setActiveId(null));
    }

    if (
      status &&
      status.method === "deleteSourceRecordTag" &&
      status.result === "success"
    ) {
      setOpenConfirmDeleteDialog(false);
      dispatch(clearStatus());
      setSelectedIds([]);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSourceRecordTags({ ...sourceIds }));
    dispatch(getSourceDerivedFields({ ...sourceIds }));
  }, []);

  return (
    <>
      <NtaiPanel
        width="75%"
        header="Record Tags"
        subheader="Manage record tags for source record. Use source derived fields to perform association."
      >
        {loaded && (
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleToolbarAction={handleToolbarAction}
            title="Record Tag"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPanel>

      <Drawer
        open={openDrawer}
        onClose={handleDrawerClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            position: "absolute",
            top: 48, // 139
            height: `calc(100% - 48px)`,
          },
        }}
      >
        <NtaiForm>
          <SourceRecordTagForm
            action={action}
            formData={action === "edit" ? _.get(dataMap, currentId) : {}}
            handleSave={handleFormSubmit}
            handleCancel={handleDrawerClose}
          />
        </NtaiForm>
      </Drawer>

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
