import React, { useEffect, useState } from "react";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import {
  getSchedulers,
  createScheduler,
  updateScheduler,
  startScheduler,
  stopScheduler,
  clearStatus,
} from "./store/schedulersSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../codelist/store/codelistsSlice";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },

  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const toolbarActions = [
  {
    label: "Start",
    icon: "play",
    value: "start",
  },
  {
    label: "Stop",
    icon: "stop",
    value: "stop",
  },
];

export default function SchedulerList() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dataMap = useSelector((state) => state.core.schedulersSlice.data);
  const status = useSelector((state) => state.core.schedulersSlice.status);

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(updateScheduler({ uuId: currentId, formData: formData }))
      : dispatch(createScheduler(formData));
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
  }

  function handleRowAction(actionName, id) {
    if (actionName === "start") dispatch(startScheduler(id));
    else if (actionName === "stop") dispatch(stopScheduler(id));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getSchedulers" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSchedulers());
    dispatch(getCodes(CODELIST_CODES.COR_SCHEDULER_TYPE_CODE));
  }, []);

  return (
    <NtaiPage padding={2} title="Schedulers">
      {loaded && (
        <NtaiSimpleTable
          helpText="Monitor system scheduler statuses, jobs, logs and perform critical and routine actions including stop and restart of schedulers."
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          path={`/admin/schedulers`}
          handleToolbarAction={handleToolbarAction}
          rowActions={[
            {
              value: "start",
              label: "Start",
              variant: "contained",
            },
            {
              value: "stop",
              label: "Stop",
            },
          ]}
          handleRowAction={handleRowAction}
          title={`Schedulers (${data.length})`}
        />
      )}
      {!loaded && <NtaiCircularProgress />}
    </NtaiPage>
  );
}
