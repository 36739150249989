import { useTheme } from "@mui/material";
import NtaiRadioGroupField from "@ntai/components/inputs/NtaiRadioGroupField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React from "react";

export default function ReportDefinitionFolder({ handleCancel }) {
  const theme = useTheme();

  function handleSave() {}

  return (
    <NtaiDialogFormPanel
      subheader="New Folder"
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="reportDefinitionFolderForm.name"
        label="Name"
        placeholder="Name"
      />
      <NtaiTextField
        name="reportDefinitionFolderForm.deascription"
        label="Description"
        placeholder="Description"
        multiline
        minRows={2}
        maxRows={2}
      />

      <NtaiRadioGroupField
        label="Access"
        name="reportDefinitionFolderForm.accessCode"
        row
        options={[
          { value: "1", label: "Private" },
          { value: "2", label: "Public" },
          { value: "3", label: "Shared" },
        ]}
      />
    </NtaiDialogFormPanel>
  );
}
