import React from "react";
import AsyncSelect from "react-select/async";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";

import Scrollbar from "@ntai/components/Scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

const styles = {
  control: (base) => ({
    ...base,
    minHeight: 24,
    fontSize: "12px",
  }),
  option: (base) => ({
    ...base,
    padding: "8px",
    fontSize: "12px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: "12px",
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const CustomReactSelect = (props) => {
  const theme = useTheme();
  const { name, placeholder, defaultOptions, handleCallback, value } = props;

  const [options, setOptions] = useState([...value]);

  const { setValue } = useFormContext();

  function handleAddOption(val) {
    if (val)
      if (
        _.filter(options, function (o) {
          if (o.value === val.value) return true;
        }).length === 0
      ) {
        setOptions([...options, val]);
      }
  }

  function handleRemoveOption(val) {
    if (val) {
      const filteredOptionValues = _.filter(options, function (o) {
        if (o.value !== val) return true;
      });

      setOptions([...filteredOptionValues]);
    }
  }

  useEffect(() => {
    setValue(name, [...options]);
  }, [options]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: theme.spacing(1),
      }}
    >
      <AsyncSelect
        placeholder={placeholder ? placeholder : "Search..."}
        isClearable
        isSearchable
        styles={styles}
        cacheOptions
        defaultOptions={_.isArray(defaultOptions) ? [...defaultOptions] : []}
        loadOptions={handleCallback}
        onChange={(choice) => handleAddOption(choice)}
      />
      <Box
        sx={{
          border: theme.general.border1,
          borderRadius: theme.general.borderRadius,
          height: "120px",
          boxShadow: "inset 0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Box
          sx={{
            pt: "8px",
            pb: "8px",
            height: "100%",
            display: "flex",
            width: "100%",
          }}
        >
          <Scrollbar autoHide={true}>
            {_.isArray(options) && options.length > 0 && (
              <List sx={{ p: 0 }}>
                {options.map((o, i) => (
                  <ListItem
                    key={`custom-react-select-option-item-${i}`}
                    sx={{
                      py: 0,
                      px: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle2" color="inherit">
                        {o.label}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          pl: "4px",
                        }}
                      >
                        <IconButton
                          sx={{ width: "20px", height: "20px" }}
                          onClick={() => handleRemoveOption(o.value)}
                        >
                          <FontAwesomeIcon size="2xs" icon={faClose} />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            )}
            {_.isArray(options) && options.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle2">No values selected</Typography>
              </Box>
            )}
          </Scrollbar>
        </Box>
      </Box>
    </Box>
  );
};

function NtaiReactServerSelect(props) {
  const { control } = useFormContext();

  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CustomReactSelect
            value={value || []}
            onChange={onChange}
            {...props}
          />
        )}
        control={control}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiReactServerSelect;
