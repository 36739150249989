import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

export const UserPermissionStore = createContext({ userPermission: {} });

const UserPermissionProvider = (props) => {
  const { children } = props;
  const [userPermissions, setUserPermissions] = useState([]);

  const userInfo = useSelector((state) => state.auth.userDataSlice.data);

  useEffect(() => {
    if (userInfo && _.has(userInfo, "sectionResourceCodes"))
      setUserPermissions(userInfo["sectionResourceCodes"]);
    else setUserPermissions([]);
  }, [userInfo]);

  return (
    <UserPermissionStore.Provider value={{ userPermissions }}>
      {children}
    </UserPermissionStore.Provider>
  );
};

export default UserPermissionProvider;
