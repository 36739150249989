import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";
import { tooltipConfig } from "../chartConfigs";
import { Box, useTheme } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

function generateColors(color) {
  let colors = [];

  for (let i = 0; i < 100; i++) {
    const colRand = NtaiUtils.getRandomFloat(0.2, 0.6);
    colors.push(am4core.color(color).lighten(colRand));
  }

  return colors;
}

export default function NtaiDonutChart(props) {
  const {
    widgetId,
    data,
    valueField,
    categoryField,
    radius,
    innerRadius,
    grouperThreshold,
    handleClick,
    metadata,
  } = props;

  const theme = useTheme();

  useLayoutEffect(() => {
    let chart = am4core.create(
      "donutchart".concat("-", widgetId),
      am4charts.PieChart
    );

    chart.data = data;

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = valueField;
    pieSeries.dataFields.category = categoryField;

    // chart.radius = am4core.percent(50);
    chart.innerRadius = am4core.percent(50);

    // pieSeries.labels.template.dataItem = data;
    pieSeries.labels.template.fontSize = 10;
    pieSeries.labels.template.paddingTop = 0;
    pieSeries.labels.template.paddingLeft = 0;
    pieSeries.labels.template.paddingBottom = 0;
    pieSeries.labels.template.paddingRight = 0;
    pieSeries.labels.template.text = "{category}";

    // wrap or truncate labels
    pieSeries.labels.template.maxWidth = 130;
    pieSeries.labels.template.wrap = true;
    // pieSeries.alignLabels = false;
    // pieSeries.alignLabels = false;

    pieSeries.slices.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;

    // stroke
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;

    // tool tip
    pieSeries.tooltip.getFillFromObject = false;
    pieSeries.tooltip.autoTextColor = false;
    pieSeries.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    pieSeries.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    pieSeries.tooltip.background.fillOpacity =
      tooltipConfig.backgroundFillOpacity;
    pieSeries.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    pieSeries.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    // colors
    const colorSet = new am4core.ColorSet();
    colorSet.list = generateColors(am4core.color(theme.palette.chart.light));
    pieSeries.colors = colorSet;

    // grouper
    let grouper = pieSeries.plugins.push(
      new am4plugins_sliceGrouper.SliceGrouper()
    );
    grouper.threshold = grouperThreshold ? grouperThreshold : 4;
    grouper.groupName = "Other";
    grouper.clickBehavior = "break";

    pieSeries.slices.template.events.on(
      "hit",
      function (ev) {
        const sourceWidgetFilterFields = [
          {
            sourceFieldUuId: Object.keys(_.get(metadata, "fields"))[0],
            fieldOperatorCode: 1,
            fieldValue: _.get(ev.target.dataItem.dataContext, categoryField),
          },
        ];

        handleClick(sourceWidgetFilterFields, ev);
      },
      this
    );

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"donutchart".concat("-", widgetId)}
      sx={{ width: "100%", height: "100%" }}
    ></Box>
  );
}
