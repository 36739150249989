import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import SourceWidgetFieldSelection from "./SourceWidgetFieldSelection";
import SourceWidgetList from "./SourceWidgetList";
import SourceWidgetSelection from "./SourceWidgetSelection";
import { setActiveId } from "../store/sourceViewsSlice";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useFormContext } from "react-hook-form";
import { clearStatus, createSourceWidget } from "./store/sourceWidgetsSlice";
import { useState } from "react";
import { getSourceFields } from "../../store/sourcesSlice";
import { getSourceDerivedFields } from "../../derivedfield/store/sourceDerivedFieldsSlice";
const _ = require("lodash");

export const SourceWidgetManagerContext = React.createContext();

export default function SourceWidgetManager(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const [selectedFields, setSelectedFields] = useState([]);
  const [clearFields, setClearFields] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const viewId = props.viewId;

  const status = useSelector(
    (state) => state.sources.sourceWidgetsSlice.status
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );
  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  function resetFieldsSelection() {
    // reset({ viewForm: {} });
    setSelectedFields([]);
    setClearFields(true);
  }

  function handleAddWidget(type, widgetUuId, pWidgetName) {
    let widgetFields = [];
    let fieldNames = [];
    let c = 1;
    let widgetName = null;

    if (type === "std") {
      selectedFields.forEach((key) => {
        const typeUuIdParts = key.split(",");
        const fieldType = typeUuIdParts[0];
        const fieldUuId = typeUuIdParts[1];

        const fieldName =
          fieldType === "1"
            ? _.get(sourceFieldsDataMap[fieldUuId], "name")
            : _.get(sourceDerivedFieldsDataMap[fieldUuId], "name");

        widgetFields.push({ sourceFieldUuId: key, fieldOrder: c });
        fieldNames.push(fieldName);
        c = c + 1;
      });

      widgetName = NtaiUtils.trunc(fieldNames.join("_"), 95);
    } else {
      widgetName = pWidgetName;
    }

    dispatch(
      createSourceWidget({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        viewId: viewId,
        formData: {
          name: widgetName,
          widgetUuId: type === "std" ? widgetUuId : null,
          sourceUserWidgetUuId: type !== "std" ? widgetUuId : null,
          sourceWidgetFields: widgetFields,
        },
      })
    );
  }

  useEffect(() => {
    if (status.result === "success" && status.method === "deleteSourceWidget") {
      dispatch(clearStatus());
    }

    if (
      status.result === "success" &&
      (status.method === "createSourceWidget" ||
        status.method === "updateSourceWidget")
    ) {
      dispatch(clearStatus());
      resetFieldsSelection();
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(viewId));
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);
  return (
    <>
      <SourceWidgetManagerContext.Provider
        value={{
          selectedFields,
          setSelectedFields,
          clearFields,
          setClearFields,
        }}
      >
        <Box
          sx={{
            borderTop: theme.general.border1,
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              flexBasis: "20%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            <SourceWidgetFieldSelection
              resetFieldsSelection={resetFieldsSelection}
            />
          </Box>
          <Box
            sx={{
              flexBasis: "20%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            <SourceWidgetSelection handleAddWidget={handleAddWidget} />
          </Box>
          <Box
            sx={{
              flexBasis: "60%",
              flexDirection: "column",
            }}
          >
            <SourceWidgetList viewId={viewId} />
          </Box>
        </Box>
      </SourceWidgetManagerContext.Provider>
    </>
  );
}
