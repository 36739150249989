import { Box, Typography, useTheme } from "@mui/material";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import React from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function NotificationDefActions() {
  const theme = useTheme();
  const dataMap = useSelector((state) => state.core.notificationDefsSlice.data);
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          flexBasis: "50%",
          display: "flex",
          gap: theme.spacing(1),
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{`Enabled (${
          _.filter(data, { enabledFg: 1 }).length
        })`}</Typography>
      </Box>

      <Box
        sx={{
          flexBasis: "50%",
          display: "flex",
          gap: theme.spacing(1),
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <NtaiSimpleSearchBar
          width="50%"
          name="some"
          label="Search..."
          placeholder="Search..."
        />
      </Box>
    </Box>
  );
}
