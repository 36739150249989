import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  clearStatus,
  createRoleResource,
  deleteRoleResource,
  getRoleResources,
} from "../store/roleResourcesSlice";
import {
  getSourceViews,
  clearStatus as clearSourceViewsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/view/store/sourceViewsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects) && selectedObjects.length > 0) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.resourceDataUuId]["name"],
        secondary:
          allObjectsDataMap[f.resourceDataUuId]["sourceMin"]["displayName"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["name"], ["asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.resourceDataUuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function RoleSourceViews() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const roleId = useSelector((state) => state.core.rolesSlice.activeId);

  const sourceViewsDataMap = useSelector(
    (state) => state.sources.sourceViewsSlice.data
  );

  const sourceViewsSliceStatus = useSelector(
    (state) => state.sources.sourceViewsSlice.status
  );

  const dataMap = useSelector((state) => state.core.roleResourcesSlice.data);

  const status = useSelector((state) => state.core.roleResourcesSlice.status);

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const sourceViewsData =
    sourceViewsDataMap && !_.isEmpty(sourceViewsDataMap)
      ? Object.values(sourceViewsDataMap)
      : [];

  function handleAdd(uuIds) {
    dispatch(
      createRoleResource({
        roleId: roleId,
        formData: {
          typeCode: 3,
          resourceDataUuIds: [uuIds],
        },
      })
    );
  }

  function handleRemove(uuId) {
    dispatch(
      deleteRoleResource({
        roleId: roleId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      ["getRoleResources", "createRoleResource", "deleteRoleResource"].includes(
        status.method
      ) &&
      status.result === "success"
    ) {
      setAvailableObjects([
        ...transformAvailableObjects(data, sourceViewsData),
      ]);
      setSelectedObjects([
        ...transformSelectedObjects(data, sourceViewsDataMap),
      ]);
      dispatch(clearStatus());
      setLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    if (
      sourceViewsSliceStatus &&
      sourceViewsSliceStatus.result === "success" &&
      sourceViewsSliceStatus.method === "getSourceViews"
    ) {
      dispatch(
        getRoleResources({
          roleId: roleId,
          params: {
            typeCode: 3,
          },
        })
      );
      dispatch(clearSourceViewsSliceStatus());
    }
  }, [sourceViewsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceViews({
        sourceDefId: "*",
        sourceId: "*",
        params: {
          fetchMin: true,
        },
      })
    );
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Source View Permissions"
      subheader="Implement role-based filters to display only authorized source views based on each user's role."
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Source Views Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {loaded &&
              _.isArray(availableObjects) &&
              availableObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ p: 0 }}>
                    {_.orderBy(availableObjects, ["name"], ["asc"]).map(
                      (fd, i) => (
                        <React.Fragment key={`available-objects-${i}`}>
                          <ListItem
                            sx={{ borderRadius: 0 }}
                            secondaryAction={
                              <Button
                                sx={{ fontSize: "11px" }}
                                variant="outlined"
                                size="small"
                                onClick={() => handleAdd(fd.uuId)}
                              >
                                ADD
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {fd.name}
                                </Typography>
                              }
                              secondary={fd["sourceMin"]["displayName"]}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                  </List>
                </Scrollbar>
              )}
            {loaded &&
              _.isArray(availableObjects) &&
              availableObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No source views available"
                  subheader="Contact admin for source views configuration"
                />
              )}
            {!loaded && <NtaiCircularProgress size={24} />}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {loaded &&
              _.isArray(selectedObjects) &&
              selectedObjects.length > 0 && (
                <Scrollbar>
                  <List sx={{ width: "100%", p: 0 }}>
                    {selectedObjects &&
                      _.isArray(selectedObjects) &&
                      _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                        (so, i) => (
                          <React.Fragment key={`selected-objects-${i}`}>
                            <ListItem
                              secondaryAction={
                                <Button
                                  sx={{
                                    display: "flex",
                                    gap: theme.spacing(1),
                                    fontSize: "11px",
                                  }}
                                  onClick={() => handleRemove(so.uuId)}
                                >
                                  <FontAwesomeIcon icon={faArchive} />
                                  REMOVE
                                </Button>
                              }
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{ fontWeight: 700 }}
                                    variant="subtitle1"
                                  >
                                    {so.primary}
                                  </Typography>
                                }
                                secondary={so.secondary}
                              />
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        )
                      )}
                  </List>
                </Scrollbar>
              )}

            {loaded &&
              _.isArray(selectedObjects) &&
              selectedObjects.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No sources assigned"
                  subheader="Add sources from left panel to the role"
                />
              )}
            {!loaded && <NtaiCircularProgress size={24} />}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
