import { Box, useTheme } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React, { useEffect } from "react";
import ReactJson from "react-json-view";
import { useDispatch, useSelector } from "react-redux";
import { getSourceCatalogAsset } from "../store/sourceCatalogAssetsSlice";
import Scrollbar from "@ntai/components/Scrollbar";
import { useMeasure } from "react-use";
const _ = require("lodash");

export default function SourceCatalogAssetSchema({ asset }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [ref, { width, height }] = useMeasure();

  const dataMap = useSelector(
    (state) => state.sources.sourceCatalogAssetsSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceCatalogAssetsSlice.status
  );

  const data =
    dataMap && _.has(dataMap, asset["uuId"])
      ? dataMap[asset["uuId"]]["datasetSparkSchema"]
      : null;



  useEffect(() => {
    dispatch(
      getSourceCatalogAsset({
        assetUuId: asset["uuId"],
        typeCode: asset["typeCode"],
        requestTypeCode: 3,
      })
    );
  }, [asset]);

  return (
    <NtaiPanel
      width="100%"
      header="Schema"
      subheader="Spark struct schema representation of stored dataset"
    >
      <Box sx={{ display: "flex", height: "100%", width: "100%" }} ref={ref}>
        <Box sx={{ display: "flex", height: height, width: "100%" }}>
          <Scrollbar>
            {asset["typeCode"] === 3 && data && <ReactJson src={data} />}
          </Scrollbar>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
