import { useTheme } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchSourceRecordsDistributions,
  clearStatus,
} from "../../distribute/store/searchSourceRecordDistributionsSlice";
import { SearchSourceContext } from "../../../SearchSource";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "typeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "10%",
    fontWeight: 700,
  },
  {
    id: "recordIds",
    numeric: false,
    disablePadding: false,
    label: "# Records",
    transformFunc: function (o) {
      return o ? o.length : 0;
    },
    width: "10%",
  },

  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Distributed",
    transformFunc: NtaiUtils.formatDateCol,
    width: "10%",
  },
];

export default function SearchSourceRecordDistributionList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);
  const searchId = useSelector((state) => state.search.searchSlice.activeId);

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const recordId = selectedRecord ? selectedRecord["recordId"] : null;

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordDistributionsSlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordDistributionsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordsDistributions"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSourceRecordsDistributions({
        searchId: searchId,
        searchSourceId: searchSourceId,
        params: {
          recordId: recordId,
        },
      })
    );
  }, [recordId]);

  return (
    <NtaiPanel
      width="100%"
      header="Distributions"
      subheader="Distributions to external contacts and workflow are shown here"
    >
      {loaded && data && _.isArray(data) && data.length > 0 && (
        <NtaiSimpleTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          title={`# Records (${data.length})`}
        />
      )}
      {loaded && data && _.isArray(data) && data.length === 0 && (
        <NtaiEmptyMessage header="No distributions available" />
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </NtaiPanel>
  );
}
