import {
  faFilter,
  faSearch,
  faChartUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import { faDatabase as faDatabaseLight } from "@fortawesome/pro-light-svg-icons";
import { faDatabase as faDatabaseSolid } from "@fortawesome/pro-solid-svg-icons";

const types = [
  {
    id: 1,
    label: "Current Source",
    description: "Use current source to create individual record template",
    avatarIcon: <FontAwesomeIcon icon={faDatabaseSolid} />,
  },
  {
    id: 2,
    label: "Other Source",
    description: "Use other source to create individual record template",
    avatarIcon: <FontAwesomeIcon icon={faDatabaseLight} />,
  },
];

export default function SourceRecordTemplateTypeSelection({ handleSelect }) {
  const theme = useTheme();
  return (
    <List sx={{ display: "flex", flexDirection: "row", gap: theme.spacing(2) }}>
      {types.map((st, i) => (
        <ListItemButton
          key={`source-record-template-type-${i}`}
          sx={{
            p: theme.spacing(2),
            width: "120px",
            display: "flex",
            flexDirection: "column",
            borderRadius: theme.general.borderRadius,
            border: theme.general.border1,
            gap: theme.spacing(1),
            "&:hover": {
              background: grey[100],
            },
          }}
          onClick={() => handleSelect(st["id"])}
        >
          <Avatar>{st["avatarIcon"]}</Avatar>

          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: theme.general.borderRadius,
              gap: theme.spacing(1),
              justifyContent: "center",
            }}
            primary={
              <Typography
                sx={{ textAlign: "center" }}
                variant="h6"
                fontWeight="700"
              >
                {st["label"]}
              </Typography>
            }
            secondary={
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                {st["description"]}
              </Typography>
            }
          />
        </ListItemButton>
      ))}
    </List>
  );
}
