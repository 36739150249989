import { useTheme } from "@mui/material";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React, { useEffect } from "react";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import NtaiMultiSelectField from "@ntai/components/inputs/NtaiMultiSelectField";
import NtaiHelpText from "@ntai/components/helptext/NtaiHelpText";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useDispatch, useSelector } from "react-redux";
import { getDistributionGroups } from "app/main/pages/core/library/distribution-group/store/distributionGroupsSlice";
import { getContacts } from "app/main/pages/core/library/contact/store/contactsSlice";
import { getContactGroups } from "app/main/pages/core/library/contact-group/store/contactGroupsSlice";
import { useFormContext } from "react-hook-form";
import { getSourceReportTemplates } from "app/main/pages/core/sourcedefinition/source/template/report/store/sourceReportTemplatesSlice";
import { SearchSourceContext } from "../../../SearchSource";
import useSearchSourceWidgetTempFilters from "../../../hooks/useSearchSourceWidgetTempFilters";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { submitSearchSourceRecordsDistribution } from "../store/searchSourceRecordDistributionsSlice";
import { SearchContext } from "../../../../SearchEdit";
const _ = require("lodash");

function mergeObjects(contacts, contactGroups, distGroups) {
  let result = [];
  contacts.forEach((c) => {
    result.push({
      value: c["uuId"],
      label: c["firstName"] + " " + c["lastName"] + " (c)",
    });
  });

  contactGroups.forEach((c) => {
    result.push({
      value: c["uuId"],
      label: c["name"] + " (cg)",
    });
  });

  distGroups.forEach((c) => {
    result.push({
      value: c["uuId"],
      label: c["name"] + " (dg)",
    });
  });

  return _.orderBy(result, ["label"], ["asc"]);
}

export default function SearchSourceRecordsDistributeContacts({
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { reset, getValues } = useFormContext();

  const { selectedRecords } = React.useContext(SearchSourceRecordListContext);

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const { searchData } = React.useContext(SearchContext);

  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const sourceDefId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.uuId"
  );

  const sourceReportTemplatesDataMap = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.data
  );

  const sourceReportTemplatesData =
    sourceReportTemplatesDataMap && !_.isEmpty(sourceReportTemplatesDataMap)
      ? _.filter(Object.values(sourceReportTemplatesDataMap), { typeCode: 1 })
      : [];

  const sourceReportTemplateOptions = NtaiUtils.getSelectOptions(
    sourceReportTemplatesData,
    "uuId",
    "name"
  );

  const distGroupsDataMap = useSelector(
    (state) => state.core.distributionGroupsSlice.data
  );
  const contactGroupsDataMap = useSelector(
    (state) => state.core.contactGroupsSlice.data
  );
  const contactsDataMap = useSelector((state) => state.core.contactsSlice.data);

  const distGroupsData =
    distGroupsDataMap && !_.isEmpty(distGroupsDataMap)
      ? Object.values(distGroupsDataMap)
      : [];

  const contactGroupsData =
    contactGroupsDataMap && !_.isEmpty(contactGroupsDataMap)
      ? Object.values(contactGroupsDataMap)
      : [];

  const contactsData =
    contactsDataMap && !_.isEmpty(contactsDataMap)
      ? Object.values(contactsDataMap)
      : [];

  const recipients = mergeObjects(
    contactsData,
    contactGroupsData,
    distGroupsData
  );

  function getContactObject(contactObjectUuId) {
    let typeCode = null;

    if (_.has(contactsDataMap, contactObjectUuId)) {
      typeCode = 1;
    }
    if (_.has(contactGroupsDataMap, contactObjectUuId)) {
      typeCode = 2;
    }
    if (_.has(distGroupsDataMap, contactObjectUuId)) {
      typeCode = 3;
    }

    return {
      typeCode: typeCode,
      contactObjectUuId: contactObjectUuId,
    };
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchSourceDistContactForm")
    );

    if (
      sanitizedFormData["contactUuIds"] &&
      _.isArray(sanitizedFormData["contactUuIds"]) &&
      sanitizedFormData["contactUuIds"].length > 0 &&
      sanitizedFormData["title"] &&
      sanitizedFormData["title"].length > 0
    ) {
      const contactObjects = [];
      sanitizedFormData["contactUuIds"].forEach((o) => {
        contactObjects.push(getContactObject(o));
      });

      const finalFormData = {
        typeCode: 1,
        recordIds: [...selectedRecords],
        selectAllFg: selectedRecords.length === 0 ? 1 : 0,
        searchSourceWidgetFilters: searchSourceWidgetTempFilters,
        contact: {
          title: sanitizedFormData["title"],
          body: sanitizedFormData["body"],
          sourceReportTemplateUuId:
            sanitizedFormData["sourceReportTemplateUuId"],
          contacts: contactObjects,
        },
        workflow: null,
      };

      dispatch(
        submitSearchSourceRecordsDistribution({
          searchId: searchId,
          searchSourceId: searchSourceId,
          formData: finalFormData,
        })
      );
    }
  }

  useEffect(() => {
    dispatch(getDistributionGroups());
    dispatch(getContacts());
    dispatch(getContactGroups());
    dispatch(
      getSourceReportTemplates({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
    reset({ searchSourceDistContactForm: null });
  }, []);

  return (
    <NtaiDialogFormPanel
      handleSave={onHandleSave}
      handleCancel={handleCancel}
      handleSaveLabel="Distribute"
      // otherActions={currentStep === 2 && [{ value: "back", label: "Back" }]}
    >
      <NtaiHelpText header="Records selected: " direction="row">
        {selectedRecords.length === 0 ? "All" : selectedRecords.length}
      </NtaiHelpText>
      <NtaiHelpText direction="row" header="Criteria:">
        My criteria
      </NtaiHelpText>

      <NtaiMultiSelectField
        options={recipients}
        name="searchSourceDistContactForm.contactUuIds"
        label="Select recipients*"
      />

      <NtaiTextField
        name="searchSourceDistContactForm.title"
        label="Title*"
        rules={{ required: "Title is required" }}
      />

      <NtaiTextField
        name="searchSourceDistContactForm.body"
        multiline
        minRows={2}
        maxRows={2}
        label="Body"
      />

      <NtaiSelectField
        options={sourceReportTemplateOptions}
        name="searchSourceDistContactForm.sourceReportTemplateUuId"
        label="Select line listing report"
      />
    </NtaiDialogFormPanel>
  );
}
