import { Box, Button, Tooltip, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertEditContext } from "../../../AlertEdit";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiJsonToTable1 from "@ntai/components/viewer/NtaiJsonToTable1";
import { clearStatus, getAlertRecord } from "../../store/alertRecordsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import useSourceFields from "app/main/pages/core/sourcedefinition/source/hooks/useSourceFields";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { faCaretDown, faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertRecordMenuWrapper from "./record-menu/AlertRecordMenuWrapper";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function AlertRecordDisplay() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const {
    selectedRecordId,
    alertId,
    alertData,
    alertMasterId,
    activityId,
    refreshSelectedRecord,
  } = useContext(AlertEditContext);

  const dataMap = useSelector((state) => state.monitor.alertRecordsSlice.data);
  const status = useSelector((state) => state.monitor.alertRecordsSlice.status);

  const recordData = _.get(dataMap, selectedRecordId);
  console.log("Record display record data: ", recordData);

  const sourceId = _.get(alertData, "sourceUuId");

  const mergedSourceFields = useSourceFields({
    sourceDefId: "*",
    sourceId: sourceId,
  });

  useEffect(() => {
    if (
      mergedSourceFields &&
      _.isArray(mergedSourceFields) &&
      mergedSourceFields.length > 0
    ) {
      setFields([...mergedSourceFields]);
      setFieldsLoaded(true);
    }
  }, [mergedSourceFields]);

  useEffect(() => {
    if (
      status &&
      status.method === "getAlertRecord" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (selectedRecordId && selectedRecordId.length > 0) {
      dispatch(
        getAlertRecord({
          alertId: alertId,
          alertMasterId: alertMasterId,
          activityId: activityId,
          uuId: selectedRecordId,
        })
      );
    }
  }, [selectedRecordId, refreshSelectedRecord]);

  return (
    <React.Fragment>
      {loaded && fieldsLoaded && !_.isEmpty(recordData) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "44px",
              width: "100%",
              borderBottom: theme.general.border1,
              alignItems: "center",
              justifyContent: "end",
              gap: theme.spacing(1),
              px: theme.spacing(2),
            }}
          >
            <Tooltip title="REPORTS">
              <Button
                sx={{
                  fontSize: "11px",
                  gap: theme.spacing(1),
                  boxShadow: "1px 1px 1px 1px rgb(0 0 0 / 0.1)",
                  background: grey[50],
                }}
                size="small"
                color="inherit"
                // onClick={(e) => handleRecordViewMenuOpen(e)}
              >
                <FontAwesomeIcon size="xl" icon={faFilePdf} />
                REPORT
                <FontAwesomeIcon size="xl" icon={faCaretDown} />
              </Button>
            </Tooltip>
            <AlertRecordMenuWrapper />
          </Box>

          <Box
            sx={{
              display: "flex",
              height: "calc(100% - 44px)",
              width: "100%",
              background: grey[50],
            }}
          >
            <Scrollbar>
              {!_.isEmpty(recordData["record"]["data"]) && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <Box
                    sx={{
                      m: theme.spacing(2),
                      display: "flex",
                      height: "100%",
                      width: "100%",
                      p: theme.spacing(2),
                      border: theme.general.border1,
                      borderRadius: theme.general.borderRadiusLg,
                      boxShadow: theme.colors.shadows.cardSm,
                      background: "white",
                    }}
                  >
                    <NtaiJsonToTable1
                      json={recordData["record"]["data"]}
                      fields={fields}
                    />
                  </Box>
                </Box>
              )}
              {_.isEmpty(recordData["record"]["data"]) && (
                <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                  <NtaiEmptyMessage
                    vAlign="center"
                    header="No template available"
                    subheader="Configure source - record template in source management section"
                  />
                </Box>
              )}
            </Scrollbar>
          </Box>
        </Box>
      )}
      {!loaded && (
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <NtaiCircularProgress size={24} vAlign="middle" />
        </Box>
      )}
    </React.Fragment>
  );
}
