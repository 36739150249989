import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import {
  createDashboardWidget,
  clearStatus,
} from "./store/dashboardWidgetsSlice";
import DashboardQueryWidgetForm from "./query-widget/DashboardQueryWidgetForm";
import DashboardSourceWidgetForm from "./source-widget/DashboardSourceWidgetForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DashboardWidgetCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const widgetType = props.match.params.wt;

  const dataMap = useSelector((state) => state.core.dashboardWidgetsSlice.data);

  const activeId = useSelector(
    (state) => state.core.dashboardWidgetsSlice.activeId
  );
  const status = useSelector(
    (state) => state.core.dashboardWidgetsSlice.status
  );

  function handleFormSubmit(formData) {
    dispatch(createDashboardWidget(formData));
  }

  function handleCancel() {
    history.push("/admin/dashboards/widgets");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createDashboardWidget" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Dashboard widget "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/admin/dashboards/widgets/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Dashboard Widgets / Add Dashboard Widget"
      back="/admin/dashboards/widgets"
    >
      {widgetType === "query-widget" && (
        <NtaiForm>
          <DashboardQueryWidgetForm
            action="add"
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
      {widgetType === "source-widget" && (
        <NtaiForm>
          <DashboardSourceWidgetForm
            action="add"
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
    </NtaiPage>
  );
}
