import React from "react";
import ReportMain from "./main/ReportMain";
import { useSelector } from "react-redux";
import ReportScheduledReports from "./main/scheduled-reports/ReportScheduledReports";
import { setActiveId } from "app/main/pages/core/admin/user/store/usersSlice";
import { dispatch } from "d3";
const _ = require("lodash");

export default function ReportEdit(props) {
  const reportDefinitionId = props.match.params.reportdefinitionid;
  const reportId = props.match.params.id;

  const dataMap = useSelector((state) => state.reports.reportsSlice.data);

  const reportData = dataMap && !_.isEmpty(dataMap) ? dataMap[reportId] : null;

  return (
    <React.Fragment>
      {reportData && !reportData["scheduledFg"] && (
        <ReportMain
          action="edit"
          reportDefinitionId={reportDefinitionId}
          reportId={reportId}
        />
      )}
      {reportData &&
        reportData["scheduledFg"] &&
        reportData["scheduledFg"] === 1 && (
          <ReportScheduledReports
            reportDefinitionId={reportDefinitionId}
            reportId={reportId}
          />
        )}
    </React.Fragment>
  );
}
