import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const submitAlertRecordBatchAssessment = createAsyncThunk(
  "alertRecordBatchAssessments/submitAlertRecordBatchAssessment",
  async (values, { rejectWithValue }) => {
    try {
      const { alertId, alertMasterId, formData } = values;
      const response = await server.post(
        `/monitor/alerts/${alertId}/masters/${alertMasterId}/records/batch-adjudications`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const alertRecordBatchAssessmentsSlice = createSlice({
  name: "alertRecordBatchAssessments",
  initialState: {
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
  },
  extraReducers: {
    [submitAlertRecordBatchAssessment.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "submitAlertRecordBatchAssessment",
      };
    },
    [submitAlertRecordBatchAssessment.fulfilled]: (state, action) => {
      state.status = {
        result: "success",
        method: "submitAlertRecordBatchAssessment",
      };
    },
    [submitAlertRecordBatchAssessment.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "submitAlertRecordBatchAssessment",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus } = alertRecordBatchAssessmentsSlice.actions;

export default alertRecordBatchAssessmentsSlice.reducer;
