import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordListingFields = createAsyncThunk(
  "sourceRecordListingFieldss/getSourceRecordListingFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordListingId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceRecordListingField = createAsyncThunk(
  "sourceRecordListingFieldss/getSourceRecordListingField",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceRecordListingId,
        sourceRecordListingFieldId,
      } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${sourceRecordListingFieldId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordListingField = createAsyncThunk(
  "sourceRecordListingFieldss/createSourceRecordListingField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordListingId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordListingField = createAsyncThunk(
  "sourceRecordListingFieldss/updateSourceRecordListingField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordListingId, uuId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordListingField = createAsyncThunk(
  "sourceRecordListingFieldss/deleteSourceRecordListingField",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceRecordListingId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordListingFieldssSlice = createSlice({
  name: "sourceRecordListingFieldss",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordListingFields.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceRecordListingFields",
      };
    },
    [getSourceRecordListingFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordListingFields",
        message: action.payload.message,
      };
    },
    [getSourceRecordListingField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceRecordListingField",
      };
    },
    [getSourceRecordListingField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordListingField",
        message: action.payload.message,
      };
    },
    [createSourceRecordListingField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordListingField",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceRecordListingField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordListingField",
        message: action.payload.message,
      };
    },
    [updateSourceRecordListingField.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordListingField",
      };
    },
    [updateSourceRecordListingField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordListingField",
        message: action.payload.message,
      };
    },
    [deleteSourceRecordListingField.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordListingField",
      };
    },
    [deleteSourceRecordListingField.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordListingField",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceRecordListingFieldssSlice.actions;

export default sourceRecordListingFieldssSlice.reducer;
