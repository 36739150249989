import React, { useContext, useEffect, useState } from "react";
import { Box, useTheme, IconButton, Typography, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faEllipsisStrokeVertical,
  faList,
  faTable,
} from "@fortawesome/pro-light-svg-icons";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import { SearchSourceRecordListContext } from "./SearchSourceRecordListPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { SearchSourceContext } from "../SearchSource";
import { SearchContext } from "../../SearchEdit";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSearchSourceRecordsCount,
} from "./store/searchSourceRecordsCountSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import SearchSourceRecordListExpandWrapper from "./expand/SearchSourceRecordListExpandWrapper";
import useSearchSourceWidgetTempFilters from "../hooks/useSearchSourceWidgetTempFilters";
import SearchSourceRecordsBatchAssessmentWrapper from "./batch-assessment/SearchSourceRecordsBatchAssessmentWrapper";
import SearchSourceRecordsDistributeWrapper from "./distribute/SearchSourceRecordsDistributeWrapper";
import SearchSourceReportTemplateWrapper from "./reports/SearchSourceReportTemplateWrapper";
import { grey } from "@mui/material/colors";
import NtaiMenu from "@ntai/components/menus/NtaiMenu";
import SearchSourceRecordsMenu from "./menu/SearchSourceRecordsMenu";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SearchSourceRecordSeriesList from "./series/SearchSourceRecordSeriesList";
import SearchSourceRecordBulkDocumentList from "./document/SearchSourceRecordBulkDocumentList";
const _ = require("lodash");

export default function SearchSourceRecordListHeader() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const menuOpen = Boolean(anchorMenuEl);
  const [hits, setHits] = useState();
  const [seriesDialogOpen, setSeriesDialogOpen] = useState(false);
  const [bulkDocumentsDialogOpen, setBulkDocumentsDialogOpen] = useState(false);
  const [recordListExpandDialogOpen, setRecordListExpandDialogOpen] =
    useState(false);

  const { searchData } = React.useContext(SearchContext);
  const {
    searchSourceData,
    reload,
    recordsCount,
    setRecordsCount,
    tableView,
    setTableView,
    changeView,
  } = React.useContext(SearchSourceContext);

  const { setFrom, setRecordsSearchText } = React.useContext(
    SearchSourceRecordListContext
  );

  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const sourceAdjudicationObjs =
    _.isArray(
      searchSourceData["searchSourceDefinition"]["sourceAdjudications"]
    ) &&
    searchSourceData["searchSourceDefinition"]["sourceAdjudications"].length > 0
      ? true
      : false;

  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const { templateType, setTemplateType } = useContext(
    SearchSourceRecordListContext
  );

  const searchSourceRecordsCount = useSelector(
    (state) => state.search.searchSourceRecordsCountSlice.totalHits
  );

  const searchSourceRecordsCountStatus = useSelector(
    (state) => state.search.searchSourceRecordsCountSlice.status
  );

  const debouncedOnHandleSearch = _.debounce(handleSearch, 500);

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  function handleMenuItemSelect(menuAction) {
    if (menuAction === "series") setSeriesDialogOpen(true);
    if (menuAction === "documents") setBulkDocumentsDialogOpen(true);
  }

  function toggleTemplate() {
    if (templateType === 2) {
      setTemplateType(1);
      setTableView(true);
    } else {
      setTableView(false);
      setTemplateType(2);
    }
  }

  function handleSearch(searchText) {
    setRecordsSearchText(searchText.trim());
    setFrom(0);
  }

  useEffect(() => {
    if (
      searchSourceRecordsCountStatus &&
      searchSourceRecordsCountStatus.method === "getSearchSourceRecordsCount" &&
      searchSourceRecordsCountStatus.result === "success"
    ) {
      setRecordsCount(
        NtaiUtils.abbrevNumber(
          searchSourceRecordsCount && searchSourceRecordsCount > 0
            ? searchSourceRecordsCount
            : 0
        )
      );
      dispatch(clearStatus());
    }
  }, [searchSourceRecordsCountStatus]);

  useEffect(() => {
    dispatch(
      getSearchSourceRecordsCount({
        searchId: searchId,
        searchSourceId: searchSourceId,
        formData: {
          searchSourceWidgetFilters: searchSourceWidgetTempFilters,
        },
      })
    );
  }, [reload, changeView]);

  return (
    <React.Fragment>
      <Box
        id="content-header"
        sx={{
          height: "44px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
          justifyContent: "space-between",
          pl: theme.spacing(1),
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <Box sx={{ width: "30px" }}>
            <Typography variant="h6" fontWeight="700">
              {searchSourceRecordsCountStatus &&
              searchSourceRecordsCountStatus.result === "pending" ? (
                <Box sx={{ justifyContent: "start" }}>
                  <NtaiCircularProgress size={12} />
                </Box>
              ) : (
                recordsCount
              )}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(0.1),
            }}
          >
            {sourceAdjudicationObjs && (
              <SearchSourceRecordsBatchAssessmentWrapper />
            )}
            <SearchSourceReportTemplateWrapper />
            <SearchSourceRecordsDistributeWrapper />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: theme.spacing(0.5),
            pr: theme.spacing(1),
          }}
        >
          <NtaiSimpleSearchBar
            placeholder="Search..."
            height="28px"
            fontSize="12px"
            width="100%"
            background={grey[100]}
            handleChange={debouncedOnHandleSearch}
          />
          {/* <SearchSourceRecordListExpandWrapper /> */}

          <Tooltip title={templateType == 1 ? "List View" : "Table View"}>
            <IconButton onClick={() => toggleTemplate()}>
              {templateType === 1 && (
                <FontAwesomeIcon size="2xs" icon={faList} />
              )}
              {templateType === 2 && (
                <FontAwesomeIcon size="2xs" icon={faTable} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="More Actions">
            <IconButton onClick={handleMenuClick}>
              <FontAwesomeIcon size="2xs" icon={faEllipsisStrokeVertical} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <SearchSourceRecordsMenu
        open={menuOpen}
        anchorEl={anchorMenuEl}
        handleClose={handleMenuClose}
        items={[
          { value: "documents", label: "Documents", icon: "attachment" },
          { value: "series", label: "Series", icon: "series" },
        ]}
        handleMenuItemSelect={handleMenuItemSelect}
      />
      <NtaiDialog
        size="md"
        open={seriesDialogOpen}
        handleDialogClose={() => setSeriesDialogOpen(false)}
        title="Manage Series"
      >
        <SearchSourceRecordSeriesList
          handleCancel={() => setSeriesDialogOpen(false)}
        />
      </NtaiDialog>
      <NtaiDialog
        size="sm"
        open={bulkDocumentsDialogOpen}
        handleDialogClose={() => setBulkDocumentsDialogOpen(false)}
        title="Batch Documents"
      >
        <SearchSourceRecordBulkDocumentList
          handleCancel={() => setBulkDocumentsDialogOpen(false)}
        />
      </NtaiDialog>
    </React.Fragment>
  );
}
