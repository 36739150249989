import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function SourceReportTemplateGeneralForm({
  action,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourcesSlice.activeId
  );
  const sourceId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const templateId = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceReportTemplatesSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? dataMap[templateId] : null;

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("sourceReportTemplateGeneralForm")
      );
      handleFormSubmit(sanitizedFormData);
    }
  }

  useEffect(() => {
    reset({
      sourceReportTemplateGeneralForm: action === "edit" ? data : {},
    });
  }, []);

  return (
    <NtaiFormPanel
      width="75%"
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiTextField
          name="sourceReportTemplateGeneralForm.name"
          label="Name"
          rules={{ required: "Name is required" }}
        />
        <NtaiSwitchField
          name="sourceReportTemplateGeneralForm.activeFg"
          label="Active?"
        />
        <NtaiSwitchField
          name="sourceReportTemplateGeneralForm.defaultFg"
          label="Default?"
        />
      </Box>

      <NtaiTextField
        name="sourceReportTemplateGeneralForm.description"
        multiline
        minRows={2}
        maxRows={2}
        placeholder="Description"
        label="Description"
      />
    </NtaiFormPanel>
  );
}
