import React, { useState } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faShapes } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";

import NtaiUtils from "@ntai/utils/NtaiUtils";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

export default function SearchReportCompactBox(props) {
  const theme = useTheme();

  const {
    id,
    width,
    height,
    title,
    subheader,
    hits,
    avatar,
    description,
    handleSelect,
    selectedItems,
  } = props;

  const [selected, setSelected] = useState(false);

  function onHandleSelect() {
    setSelected(!selected);
    handleSelect(id);
  }

  return (
    <Box
      onClick={() => onHandleSelect()}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "240px",
        height: "150px",
        background: "white",
        border: selectedItems.has(id)
          ? theme.general.border2p
          : theme.general.border1,
        borderRadius: theme.general.borderRadiusSm,
        boxShadow: theme.colors.shadows.cardSm,
        "&:hover": {
          boxShadow: theme.colors.shadows.card,
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "70px",
          display: "flex",
          p: theme.spacing(1.5),
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
            <Box>
              <Typography variant="body2" fontWeight="700">
                {NtaiUtils.trunc(title, 46)}
              </Typography>

              <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ color: grey[500] }}
              >
                {/* {_.get(searchInitSource, "typeDecode")} */} {subheader}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <FontAwesomeIcon
            color="primary"
            icon={selectedItems.has(id) ? faCheckSquare : faSquare}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: "50px",
          display: "flex",
          py: "4px",
          px: theme.spacing(1.5),
        }}
      >
        <Typography variant="caption">
          {NtaiUtils.trunc(description, 70)}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "30px",
          display: "flex",
          alignItems: "center",
          borderTop: theme.general.border1,
          background: grey[50],
          borderRadius: theme.general.borderRadiusSm,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingLeft: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <FontAwesomeIcon size="2xs" icon={faShapes} />
            <Typography variant="caption" fontWeight="500" fontSize="10px">
              {subheader}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              paddingRight: theme.spacing(1),
            }}
          >
            <FontAwesomeIcon size="2xs" icon={faGlobe} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
