import {
  faArrowUpRightFromSquare,
  faFileLines,
  faFilePdf,
} from "@fortawesome/pro-light-svg-icons";
import { faShapes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import NtaiPDFReportViewer from "@ntai/components/reports/NtaiPDFReportViewer";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../../SearchEdit";
import { SearchSourceContext } from "../../../SearchSource";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { getSearchSourceReportTemplates } from "../../reports/store/searchSourceReportTemplatesSlice";
const _ = require("lodash");

export default function SearchSourceRecordReportMenu({ handleClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [reportTemplateId, setReportTemplateId] = useState();
  const [templatesLoaded, setTemplatesLoaded] = useState(false);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "uuId");

  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);

  const recordId = selectedRecord ? selectedRecord["recordId"] : null;

  const dataMap = useSelector(
    (state) => state.search.searchSourceReportTemplatesSlice.data
  );

  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(
          _.filter(Object.values(dataMap), { typeCode: 2 }),
          ["name"]["asc"]
        )
      : [];

  const status = useSelector(
    (state) => state.search.searchSourceReportTemplatesSlice.status
  );

  function generateReport(templateId) {
    setReportTemplateId(templateId);
    handleReportDialogOpen();
  }

  function handleReportDialogOpen() {
    setOpen(true);
  }

  function handleReportDialogClose() {
    setOpen(false);
    handleClose();
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceReportTemplates"
    ) {
      setTemplatesLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSearchSourceReportTemplates({
        searchId: searchId,
        searchSourceId: searchSourceId,
        params: {
          reportTemplateType: 2,
        },
      })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "240px",
          width: "200px",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
          p: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
        >
          <FontAwesomeIcon icon={faFileLines} />
          <Typography variant="body2" fontWeight="700">
            Select Report
          </Typography>
          <Divider />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            border: theme.general.border1,
            boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
          }}
        >
          {templatesLoaded && data.length === 0 && (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2">No reports found</Typography>
            </Box>
          )}
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              {templatesLoaded && (
                <MenuList dense sx={{ p: "2px", width: "100%" }}>
                  {data &&
                    Array.isArray(data) &&
                    data.length > 0 &&
                    data.map((item, i) => {
                      return (
                        <Tooltip
                          key={`menu-item-tooltip-key-${i}`}
                          title={item.name}
                        >
                          <MenuItem
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: 0,
                              justifyContent: "space-between",
                            }}
                            key={item.uuId}
                            value={item.uuId}
                            divider={true}
                            onClick={() => generateReport(item.uuId)}
                          >
                            <ListItemText>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: "500" }}
                              >
                                {NtaiUtils.trunc(item.name, 18)}
                              </Typography>
                            </ListItemText>
                            {item["extSourceTemplateFg"] === 1 && (
                              <FontAwesomeIcon
                                size="xs"
                                icon={faArrowUpRightFromSquare}
                              />
                            )}
                          </MenuItem>
                        </Tooltip>
                      );
                    })}
                </MenuList>
              )}
              {!templatesLoaded && <NtaiCircularProgress size={20} />}
            </Box>
          </Scrollbar>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{ fontSize: "10px" }}
          >
            CLOSE
          </Button>
        </Box>
      </Box>
      {reportTemplateId && reportTemplateId.length > 0 && (
        <NtaiDialog
          title={
            dataMap && !_.isEmpty(dataMap)
              ? dataMap[reportTemplateId]["name"]
              : "Report"
          }
          size="md"
          open={open}
          handleDialogClose={handleReportDialogClose}
          hideBackdrop={true}
        >
          <NtaiPDFReportViewer
            type={2}
            height="440px"
            url={`/search/${searchId}/sources/${searchSourceId}/reports/${reportTemplateId}?recordId=${recordId}`}
          />
        </NtaiDialog>
      )}
    </>
  );
}
