import {
  faArrowUpRightFromSquare,
  faChartSimple,
  faFileLines,
  faList,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { getSourceRecordTemplates } from "app/main/pages/core/sourcedefinition/source/template/record/store/sourceRecordTemplatesSlice";
import { AlertEditContext } from "../../../../AlertEdit";
import { getSourceViews } from "app/main/pages/core/sourcedefinition/source/view/store/sourceViewsSlice";
const _ = require("lodash");

function mergeData(sourceRecordTemplatesData, sourceRecordViewsData) {
  let result = [];
  sourceRecordTemplatesData.forEach((o) => {
    result.push({
      uuId: o["uuId"],
      name: o["name"],
      type: 1,
      externalFg: o["extTemplateFg"] === 1 ? 1 : 0,
    });
  });

  sourceRecordViewsData.forEach((o) => {
    result.push({
      uuId: o["uuId"],
      name: o["name"],
      type: 2,
      externalFg: 0,
    });
  });

  return _.orderBy(result, ["name"], ["asc"]);
}

export default function AlertRecordMenuItems({
  handleSelectView,
  handleClose,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { selectedRecordId, alertData } = React.useContext(AlertEditContext);
  const sourceId = _.get(alertData, "sourceUuId");

  const [sourceRecordTemplatesLoaded, setSourceRecordTemplatesLoaded] =
    useState(false);
  const [sourceRecordViewsLoaded, setSourceRecordViewsLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  const sourceRecordTemplatesDataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );
  const sourceRecordTemplatesSliceStatus = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.status
  );

  const sourceRecordTemplatesData =
    sourceRecordTemplatesDataMap && !_.isEmpty(sourceRecordTemplatesDataMap)
      ? Object.values(sourceRecordTemplatesDataMap)
      : [];

  const sourceRecordViewsDataMap = useSelector(
    (state) => state.sources.sourceViewsSlice.data
  );
  const sourceRecordViewsSliceStatus = useSelector(
    (state) => state.sources.sourceViewsSlice.status
  );

  const sourceRecordViewsData =
    sourceRecordViewsDataMap && !_.isEmpty(sourceRecordViewsDataMap)
      ? Object.values(sourceRecordViewsDataMap)
      : [];

  function selectView(uuId, type, name) {
    handleSelectView(uuId, type, name);
  }

  useEffect(() => {
    if (sourceRecordTemplatesLoaded && sourceRecordViewsLoaded) {
      const mergedData = mergeData(
        sourceRecordTemplatesData,
        sourceRecordViewsData
      );

      setData(mergedData);
      setLoaded(true);
    }
  }, [sourceRecordTemplatesLoaded, sourceRecordViewsLoaded]);

  useEffect(() => {
    if (
      sourceRecordTemplatesSliceStatus &&
      sourceRecordTemplatesSliceStatus.result === "success" &&
      sourceRecordTemplatesSliceStatus.method === "getSourceRecordTemplates"
    ) {
      setSourceRecordTemplatesLoaded(true);
    }
  }, [sourceRecordTemplatesSliceStatus]);

  useEffect(() => {
    if (
      sourceRecordViewsSliceStatus &&
      sourceRecordViewsSliceStatus.result === "success" &&
      sourceRecordViewsSliceStatus.method === "getSourceViews"
    ) {
      setSourceRecordViewsLoaded(true);
    }
  }, [sourceRecordViewsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceViews({
        sourceDefId: "*",
        sourceId: sourceId,
        params: {
          typeCode: 2,
          activeFg: 1,
          fetchMin: true,
        },
      })
    );

    dispatch(
      getSourceRecordTemplates({
        sourceDefId: "*",
        sourceId: sourceId,
      })
    );
  }, []);

  return (
    <Box
      sx={{
        height: "240px",
        width: "200px",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <Typography variant="body2" fontWeight="700">
          Select View
        </Typography>
        <Divider />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          border: theme.general.border1,
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        {loaded && (
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              <MenuList dense sx={{ p: "2px", width: "100%" }}>
                {data &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((item) => {
                    return (
                      <MenuItem
                        sx={{
                          px: theme.spacing(1),
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 0,
                          justifyContent: "space-between",
                        }}
                        key={item.uuId}
                        value={item.uuId}
                        divider={true}
                        onClick={() =>
                          selectView(item.uuId, item.type, item.name)
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: theme.spacing(1),
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              item["type"] === 1 ? faFileLines : faChartSimple
                            }
                          />
                          <ListItemText>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "500" }}
                            >
                              {item.name}
                            </Typography>
                          </ListItemText>
                        </Box>

                        {item["externalFg"] === 1 && (
                          <FontAwesomeIcon
                            size="xs"
                            icon={faArrowUpRightFromSquare}
                          />
                        )}
                      </MenuItem>
                    );
                  })}
              </MenuList>

              {/* {viewsLoaded && data.length === 0 && (
                <NtaiEmptyMessage
                  vAlign="center"
                  header="No views found"
                  subheader="Add individual views from source > views"
                />
              )} */}
            </Box>
          </Scrollbar>
        )}
        {!loaded && <NtaiCircularProgress size={20} />}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          sx={{ fontSize: "10px" }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
}
