import { Box, Button, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React from "react";
import ReportDefinitionFolder from "../folder/ReportDefinitionFolder";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportFolders } from "../../../admin/folder/store/reportFoldersSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ReportContext } from "../ReportMain";
import { PanoramaFishEye } from "@mui/icons-material";
const _ = require("lodash");

export default function ReportSaveForm({ handleFormSubmit, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();

  const {
    action,
    reportDefinition,
    reportFormData,
    sourceCriteria,
    esCriteria,
    selectedSourceSeriesId,
    selectedSourceDatasetId,
  } = React.useContext(ReportContext);

  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);

  const foldersDataMap = useSelector(
    (state) => state.reports.reportFoldersSlice.data
  );

  const foldersData =
    foldersDataMap && !_.isEmpty(foldersDataMap)
      ? Object.values(foldersDataMap)
      : [];

  const folderOptions = NtaiUtils.getSelectOptions(foldersData, "uuId", "name");

  function handleNewFolderDialogOpen() {
    setNewFolderDialogOpen(true);
  }

  function handleNewFolderDialogClose() {
    setNewFolderDialogOpen(false);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("reportSaveForm")
    );

    let finalFormData = { ...sanitizedFormData };
    let reportParameters = [];

    if (
      reportDefinition.typeCode === 2 ||
      (reportDefinition.typeCode === 1 &&
        reportDefinition.criteriaTypeCode === 1)
    ) {
      // Source filters

      const sourceFilterCriteria = NtaiUtils.sanitizeFormData(
        getValues("reportParamForm")
      );

      if (sourceFilterCriteria && !_.isEmpty(sourceFilterCriteria)) {
        Object.keys(sourceFilterCriteria).forEach((paramId, i) => {
          const tmpValues = _.get(sourceFilterCriteria, paramId);

          const sourceFilterCriteriaField = _.filter(
            reportDefinition["reportDefinitionParameters"],
            { uuId: paramId }
          )[0];

          let revisedValues = [];

          if (_.isArray(tmpValues)) {
            revisedValues = tmpValues.map((v, i) => {
              return {
                valueOrder: i + 1,
                value:
                  sourceFilterCriteriaField["typeCode"] !== 1
                    ? v
                    : _.get(v, "value"),
              };
            });
          } else if (tmpValues !== undefined) {
            revisedValues.push({
              valueOrder: 1,
              value: tmpValues,
            });
          }

          reportParameters.push({
            reportDefinitionParameterUuId: paramId,
            reportParameterValues: revisedValues,
          });
        });

        finalFormData["reportParameters"] = reportParameters;
      } else {
        finalFormData["reportParameters"] = [];
      }
      finalFormData["reportDefinitionUuId"] = _.get(reportDefinition, "uuId");
      handleFormSubmit(finalFormData);
    } else if (reportDefinition.typeCode === 3) {
      if (selectedSourceSeriesId && selectedSourceSeriesId.length > 0) {
        finalFormData["sourceSeriesUuId"] = selectedSourceSeriesId;
        finalFormData["reportDefinitionUuId"] = _.get(reportDefinition, "uuId");
        handleFormSubmit(finalFormData);
      }
    } else if (reportDefinition.typeCode === 4) {
      if (selectedSourceDatasetId && selectedSourceDatasetId.length > 0) {
        finalFormData["sourceDomainUuId"] = selectedSourceDatasetId;
        finalFormData["reportDefinitionUuId"] = _.get(reportDefinition, "uuId");
        handleFormSubmit(finalFormData);
      }
    } else {
      // QBE
      // console.log("Source and es criteria: ", sourceCriteria, esCriteria);
      finalFormData["sourceQbeCriteria"] = sourceCriteria;
      finalFormData["sourceQbeEsCriteria"] = esCriteria;
      finalFormData["reportDefinitionUuId"] = _.get(reportDefinition, "uuId");
      handleFormSubmit(finalFormData);
    }
  }

  useEffect(() => {
    dispatch(getReportFolders());
    setValue("reportSaveForm", action === "edit" ? reportFormData : {});
  }, []);

  return (
    <React.Fragment>
      <NtaiDialogFormPanel
        subheader="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo"
        handleSave={onHandleSave}
        handleCancel={handleCancel}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <NtaiTextField
            name="reportSaveForm.name"
            placeholder="Name"
            label="Name"
            rules={{ required: "Name is required" }}
          />

          <NtaiTextField
            name="reportSaveForm.description"
            placeholder="Description"
            label="Description"
            multiline
            minRows={2}
            maxRows={2}
          />

          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                flexBasis: "70%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <NtaiSelectField
                label="Report Folder"
                name="reportSaveForm.reportFolderUuId"
                options={folderOptions}
              />
            </Box>

            <Box
              sx={{
                flexBasis: "30%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                sx={{ fontSize: "11px" }}
                size="small"
                onClick={() => handleNewFolderDialogOpen()}
              >
                CREATE FOLDER
              </Button>
            </Box>
          </Box>
        </Box>
      </NtaiDialogFormPanel>

      <NtaiDialog
        title="New Folder"
        size="sm"
        open={newFolderDialogOpen}
        handleDialogClose={handleNewFolderDialogClose}
      >
        <ReportDefinitionFolder handleCancel={handleNewFolderDialogClose} />
      </NtaiDialog>
    </React.Fragment>
  );
}
