import React, { Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import { faCircle2 } from "@fortawesome/pro-solid-svg-icons";
import { getWidgets } from "app/main/pages/core/admin/widget/store/widgetsSlice";
import { useDispatch, useSelector } from "react-redux";
import { SourceWidgetManagerContext } from "./SourceWidgetManager";
import { useState } from "react";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import SourceWidgetUtil from "./util/SourceWidgetUtil";
import {
  faChartArea,
  faChartMixed,
  faChartUser,
} from "@fortawesome/pro-light-svg-icons";
import { getSourceUserWidgets } from "../../user-widgets/store/sourceUserWidgetsSlice";
import NtaiChartIcons from "utils/NtaiChartIcons";
const _ = require("lodash");

export default function SourceWidgetSelection({ handleAddWidget }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeWidgets, setActiveWidgets] = useState([]);
  const [fetchUserWidgets, setFetchUserWidgets] = useState(false);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const widgetsDataMap = useSelector((state) => state.core.widgetsSlice.data);
  const widgets = Object.values(widgetsDataMap);

  const sourceUserWidgetsDataMap = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.data
  );
  const sourceUserWidgets =
    sourceUserWidgetsDataMap && !_.isEmpty(sourceUserWidgetsDataMap)
      ? Object.values(sourceUserWidgetsDataMap)
      : [];

  const { selectedFields } = React.useContext(SourceWidgetManagerContext);

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  function onAddWidget(type, widgetId, widgetName) {
    handleAddWidget(type, widgetId, widgetName);
  }

  function generateWidgets() {
    let result = [];

    if (Array.isArray(widgets) && widgets.length > 0) {
      let listItems = [];
      _.orderBy(widgets, ["name"], ["asc"]).forEach((widget, index) => {
        listItems.push(
          <Fragment key={`widget-fragment-${index}`}>
            <ListItem
              sx={{
                display: "flex",
                gap: theme.spacing(2),
                "&:hover": { background: grey[100] },
              }}
              // secondaryAction={
              //   <Button
              //     onClick={() => onAddWidget("std", widget.uuId, null)}
              //     variant="contained"
              //     size="small"
              //     disabled={activeWidgets.includes(widget.uuId) ? false : true}
              //   >
              //     Add
              //   </Button>
              // }
              disabled={activeWidgets.includes(widget.uuId) ? false : true}
            >
              <FontAwesomeIcon
                size="lg"
                icon={
                  NtaiChartIcons.hasOwnProperty(widget["name"].toLowerCase())
                    ? NtaiChartIcons[widget["name"].toLowerCase()]
                    : faChartMixed
                }
              />
              <ListItemText
                primary={
                  <Typography
                    sx={{ fontWeight: 700, color: "inherit" }}
                    variant="subtitle1"
                  >
                    {widget["name"]}
                  </Typography>
                }
                secondary={widget["description"]}
              />
              <Button
                onClick={() => onAddWidget("std", widget.uuId, null)}
                variant="contained"
                size="small"
                disabled={activeWidgets.includes(widget.uuId) ? false : true}
              >
                Add
              </Button>
            </ListItem>
            <Divider />
          </Fragment>
        );
      });

      result.push(
        <List
          key="widget-select-id"
          component="nav"
          sx={{ p: 0, width: "100%" }}
        >
          {listItems}
        </List>
      );
    }

    return result;
  }

  function generateSourceUserWidgets() {
    let result = [];

    if (Array.isArray(sourceUserWidgets) && sourceUserWidgets.length > 0) {
      let listItems = [];
      _.orderBy(sourceUserWidgets, ["name"], ["asc"]).forEach(
        (widget, index) => {
          listItems.push(
            <Fragment key={`user-widget-fragment-${index}`}>
              <ListItem
                sx={{ "&:hover": { background: grey[100] } }}
                secondaryAction={
                  <Button
                    onClick={() =>
                      onAddWidget("user", widget.uuId, widget["name"])
                    }
                    variant="contained"
                    size="small"
                  >
                    Add
                  </Button>
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{ fontWeight: 700, color: "inherit" }}
                      variant="subtitle1"
                    >
                      {widget["name"]}
                    </Typography>
                  }
                  secondary={widget["description"]}
                />
              </ListItem>
              <Divider />
            </Fragment>
          );
        }
      );

      result.push(
        <List
          key="user-widget-select-id"
          component="nav"
          sx={{ p: 0, width: "100%" }}
        >
          {listItems}
        </List>
      );
    }

    return result;
  }

  useEffect(() => {
    const tmpActiveWidgetIds = SourceWidgetUtil.getActiveWidgetIds(
      selectedFields,
      sourceFieldsDataMap,
      sourceDerivedFieldsDataMap,
      widgets
    );

    setActiveWidgets([...tmpActiveWidgetIds]);
  }, [selectedFields]);

  useEffect(() => {
    if (fetchUserWidgets) {
      dispatch(
        getSourceUserWidgets({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
        })
      );
    }
  }, [fetchUserWidgets]);

  useEffect(() => {
    dispatch(getWidgets());
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
          px: theme.spacing(2),
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <FontAwesomeIcon size="lg" icon={faCircle2} color="secondary" />
            <Typography variant="h6">Select Widget</Typography>
          </Box>
          <IconButton onClick={() => setFetchUserWidgets(!fetchUserWidgets)}>
            <FontAwesomeIcon size="2xs" icon={faChartUser} />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: `calc(100% - 44px)`,
        }}
      >
        <Scrollbar>
          {!fetchUserWidgets && generateWidgets()}
          {fetchUserWidgets && generateSourceUserWidgets()}
        </Scrollbar>
      </Box>
    </>
  );
}
