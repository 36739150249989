import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchDateRange = createAsyncThunk(
  "searchDateRange/getSearchDateRange",
  async (searchId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/search/${searchId}/date-range`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchDateRange = createAsyncThunk(
  "searchDateRange/updateSearchDateRange",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/date-range`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchDateRangeSlice = createSlice({
  name: "searchDateRange",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchDateRange.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getSearchDateRange" };
    },
    [getSearchDateRange.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchDateRange",
        message: action.payload.message,
      };
    },
    [updateSearchDateRange.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = {
        result: "success",
        method: "updateSearchDateRange",
      };
    },
    [updateSearchDateRange.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchDateRange",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchDateRangeSlice.actions;

export default searchDateRangeSlice.reducer;
