import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightTopicActions = createAsyncThunk(
  "oversightTopicActions/getOversightTopicActions",
  async (oversightTopicId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/oversight/topics/${oversightTopicId}/actions`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOversightTopicAction = createAsyncThunk(
  "oversightTopicActions/getOversightTopicAction",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId } = values;
      const response = await server.get(
        `/oversight/topics/${oversightTopicId}/actions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOversightTopicAction = createAsyncThunk(
  "oversightTopicActions/createOversightTopicAction",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, formData } = values;
      const response = await server.post(
        `/oversight/topics/${oversightTopicId}/actions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateOversightTopicAction = createAsyncThunk(
  "oversightTopicActions/updateOversightTopicAction",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId, formData } = values;
      const response = await server.patch(
        `/oversight/topics/${oversightTopicId}/actions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOversightTopicAction = createAsyncThunk(
  "oversightTopicActions/deleteOversightTopicAction",
  async (values, { rejectWithValue }) => {
    try {
      const { oversightTopicId, uuId } = values;
      const response = await server.delete(
        `/oversight/topics/${oversightTopicId}/actions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightTopicActionsSlice = createSlice({
  name: "oversightTopicActions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightTopicActions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getOversightTopicActions" };
    },
    [getOversightTopicActions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightTopicActions",
        message: action.payload.message,
      };
    },
    [getOversightTopicAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getOversightTopicAction" };
    },
    [getOversightTopicAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightTopicAction",
        message: action.payload.message,
      };
    },
    [createOversightTopicAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "createOversightTopicAction",
      };
    },
    [createOversightTopicAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOversightTopicAction",
        message: action.payload.message,
      };
    },
    [updateOversightTopicAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "updateOversightTopicAction",
      };
    },
    [updateOversightTopicAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateOversightTopicAction",
        message: action.payload.message,
      };
    },
    [deleteOversightTopicAction.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteOversightTopicAction",
      };
    },
    [deleteOversightTopicAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteOversightTopicAction",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = oversightTopicActionsSlice.actions;

export default oversightTopicActionsSlice.reducer;
