import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEntity, clearStatus } from "./store/entitiesSlice";
import EntityForm from "./EntityForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { getObject } from "../cdo/object/store/objectsSlice";
import { useSnackbar } from "notistack";
import EntityUtils from "./util/EntityUtils";
const _ = require("lodash");

export default function EntityCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const domainId = props.match.params.domainId;
  const objectId = props.match.params.objectId;
  const status = useSelector((state) => state.core.entitiesSlice.status);
  const dataMap = useSelector((state) => state.core.entitiesSlice.data);
  const activeId = useSelector((state) => state.core.entitiesSlice.activeId);
  const entityObjects = useSelector(
    (state) => state.core.entitiesSlice.entityObjects
  );
  const entityObject = _.get(entityObjects, objectId);

  function handleSave(formData) {
    dispatch(createEntity({ objectId: objectId, formData: formData }));
  }

  useEffect(() => {
    dispatch(getObject({ domainId: domainId, uuId: objectId }));
  }, []);

  useEffect(() => {
    if (status.method === "createEntity" && status.result === "success") {
      enqueueSnackbar(
        `Entity "${EntityUtils.getPrimaryFieldValue(
          _.get(dataMap[objectId], activeId)
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(
        `/entities-library/domains/${domainId}/objects/${objectId}/entities/${activeId}/edit`
      );
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Add Entity"
      back={`/entities-library/domains/${domainId}/objects/${objectId}/entities`}
    >
      <NtaiForm>
        {entityObject && (
          <EntityForm
            action="add"
            object={entityObject}
            handleSave={handleSave}
          />
        )}
      </NtaiForm>
    </NtaiPage>
  );
}
