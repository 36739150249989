import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Box, lighten, rgbToHex, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

function generateColors(color) {
  let colors = [];

  for (let i = 0; i < 100; i++) {
    const colRand = NtaiUtils.getRandomFloat(0.2, 0.6);
    colors.push(am4core.color(color).lighten(colRand));
  }

  return colors;
}

export default function NtaiPackedCircleChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick } = props;
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  useLayoutEffect(() => {
    let chart = am4core.create(
      "packedCircleDiv".concat("-", widgetId),
      am4plugins_forceDirected.ForceDirectedTree
    );

    let networkSeries = chart.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    );

    chart.data = data;
    // networkSeries.colors.list = [
    //   //   am4core.color(theme.palette.secondary.main),

    //   am4core.color(theme.palette.secondary.light),
    //   //   am4core.color(theme.palette.secondary.dark),
    // ];

    // networkSeries.colors.reuse = true;

    networkSeries.dataFields.linkWith = "linkWith";
    networkSeries.dataFields.name = "name";
    networkSeries.dataFields.id = "name";
    networkSeries.dataFields.value = "value";
    networkSeries.dataFields.children = "children";
    networkSeries.links.template.distance = 1;
    networkSeries.nodes.template.tooltipText = "{name}";
    // networkSeries.nodes.template.fillOpacity = 1;
    networkSeries.nodes.template.outerCircle.scale = 1;

    networkSeries.nodes.template.label.text = "{name}\n{value}";
    networkSeries.fontSize = 11;
    networkSeries.nodes.template.label.hideOversized = true;
    networkSeries.nodes.template.label.truncate = true;
    // networkSeries.nodes.template.label.fill = grey[900];
    networkSeries.nodes.template.label.fill = NtaiUtils.invertColor(
      rgbToHex(lighten(theme.colors.chart.main, 0.5))
    );
    networkSeries.minRadius = am4core.percent(2);
    networkSeries.manyBodyStrength = -5;
    networkSeries.links.template.strokeOpacity = 0;

    networkSeries.maxLevels = 1;
    // networkSeries.colors.list = generateColors("#389B8C");

    networkSeries.colors.list = generateColors(
      // am4core.color(theme.palette.secondary.main)
      am4core.color(lighten(theme.colors.chart.main, 0.5)) //blue: 67AAF9 //green: 73Eb90
    );

    // tool tip
    networkSeries.tooltip.getFillFromObject = false;
    networkSeries.tooltip.autoTextColor = false;

    networkSeries.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    networkSeries.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    networkSeries.tooltip.background.fillOpacity =
      tooltipConfig.backgroundFillOpacity;
    networkSeries.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    networkSeries.tooltip.label.fontSize = tooltipConfig.labelFontSize;
    networkSeries.nodes.template.tooltipText = "{name}\n{value}";
    networkSeries.nodes.template.events.on(
      "hit",
      function (ev) {
        //handleClick(widgetId, ev.target.dataItem.dataContext);
        console.log("packed circle Clicked: ev, field id, datacontext", ev);

        // const sourceWidgetFilterFields = [
        //   {
        //     sourceFieldUuId: Object.keys(_.get(metadata, "fields"))[0],
        //     fieldOperatorCode: 1,
        //     fieldValue: _.get(ev.target.dataItem.dataContext, categoryField),
        //   },
        // ];

        // handleClick(widgetId, ev.target.dataItem.dataContext);
      },
      this
    );

    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <Box
      id={"packedCircleDiv".concat("-", widgetId)}
      style={{
        width: "100%",
        height: "100%",
      }}
    ></Box>
  );
}
