import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceAdjudicationImportCsvSchemes = createAsyncThunk(
  "sourceAdjudicationImportCsvSchemes/getSourceAdjudicationImportCsvSchemes",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceAdjudicationId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${sourceAdjudicationId}/adjudication-import-schemes`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceAdjudicationImportCsvScheme = createAsyncThunk(
  "sourceAdjudicationImportCsvSchemes/getSourceAdjudicationImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceAdjudicationId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${sourceAdjudicationId}/adjudication-import-schemes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceAdjudicationImportCsvScheme = createAsyncThunk(
  "sourceAdjudicationImportCsvSchemes/createSourceAdjudicationImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceAdjudicationId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${sourceAdjudicationId}/adjudication-import-schemes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceAdjudicationImportCsvScheme = createAsyncThunk(
  "sourceAdjudicationImportCsvSchemes/updateSourceAdjudicationImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceAdjudicationId, uuId, formData } =
        values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${sourceAdjudicationId}/adjudication-import-schemes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceAdjudicationImportCsvScheme = createAsyncThunk(
  "sourceAdjudicationImportCsvSchemes/deleteSourceAdjudicationImportCsvScheme",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceAdjudicationId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/adjudications/${sourceAdjudicationId}/adjudication-import-schemes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceAdjudicationImportCsvSchemesSlice = createSlice({
  name: "sourceAdjudicationImportCsvSchemes",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceAdjudicationImportCsvSchemes.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceAdjudicationImportCsvSchemes",
      };
    },
    [getSourceAdjudicationImportCsvSchemes.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceAdjudicationImportCsvSchemes",
        message: action.payload.message,
      };
    },
    [getSourceAdjudicationImportCsvScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceAdjudicationImportCsvScheme",
      };
    },
    [getSourceAdjudicationImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceAdjudicationImportCsvScheme",
        message: action.payload.message,
      };
    },
    [createSourceAdjudicationImportCsvScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceAdjudicationImportCsvScheme",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceAdjudicationImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceAdjudicationImportCsvScheme",
        message: action.payload.message,
      };
    },
    [updateSourceAdjudicationImportCsvScheme.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceAdjudicationImportCsvScheme",
      };
    },
    [updateSourceAdjudicationImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceAdjudicationImportCsvScheme",
        message: action.payload.message,
      };
    },
    [deleteSourceAdjudicationImportCsvScheme.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceAdjudicationImportCsvScheme",
      };
    },
    [deleteSourceAdjudicationImportCsvScheme.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceAdjudicationImportCsvScheme",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceAdjudicationImportCsvSchemesSlice.actions;

export default sourceAdjudicationImportCsvSchemesSlice.reducer;
