import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { clearStatus, createDatasource } from "./store/datasourcesSlice";
import DatasourceForm from "./DatasourceForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function DatasourceCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const dataMap = useSelector((state) => state.core.datasourcesSlice.data);
  const status = useSelector((state) => state.core.datasourcesSlice.status);
  const activeId = useSelector((state) => state.core.datasourcesSlice.activeId);
  const type = props.match.params.type;

  function handleSave(formData) {
    dispatch(createDatasource(formData));
  }

  function handleCancel() {
    history.push("/admin/datasources");
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createDatasource" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Datasource "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/admin/datasources/${activeId}/edit`);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "createDatasource" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Data source" back="/admin/datasources">
      <NtaiForm>
        <DatasourceForm
          action="add"
          type={type}
          handleSave={handleSave}
          handleCancel={handleCancel}
          formData={null}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
