import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import NtaiLabelValue from "./NtaiLabelValue";

export default function NtaiInfoPanel({ padding, border, title, children }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: padding && theme.spacing(1),
        border: border ? theme.general.border1 : null,
        borderRadius: border ? theme.general.borderRadius : null,
        alignItems: "start",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      }}
    >
      <Typography variant="h4">{title}</Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
      >
        {children}
      </Box>
    </Box>
  );
}
