import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import NtaiCircularProgress from "../progress/NtaiCircularProgress";
const _ = require("lodash");

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

export default function NtaiInfiniteScroll1({ data, height }) {
  const theme = useTheme();
  const [items, setItems] = useState([...data]);

  // console.log("AA: ", Array.from({ length: 20 }));
  function fetchMoreData() {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs

    setTimeout(() => {
      let itemsCopy = _.cloneDeep(items);
      itemsCopy = itemsCopy.concat(Array.from({ length: 20 }));
      setItems([...itemsCopy]);
    }, 1500);
  }

  useEffect(() => {
    console.log("Length of data in ntis: ", data.length);
    setItems([...data]);
  }, [data]);

  return (
    <div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={true}
        height={height}
        loader={
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              p: theme.spacing(1),
            }}
          >
            <CircularProgress size={18} />
          </Box>
        }
      >
        {items.map((i, index) => (
          <div style={style} key={index}>
            div - #{index}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}
