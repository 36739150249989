import React from "react";
import { Box, useTheme } from "@mui/material";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDimensions from "react-cool-dimensions";
import {
  getOversightEntityOverviewViewWidget,
  clearStatus,
} from "./store/oversightEntityOverviewViewWidgetsSlice";
import { OversightContext } from "../../../Oversight";
import NtaiWidgetMetric from "@ntai/components/charts/metrics/NtaiWidgetMetric";
import NtaiSankeyChart from "@ntai/components/charts/amcharts/NtaiSankeyChart";
import NtaiXTab from "@ntai/components/tables/xtab/NtaiXTab";
import NtaiPackedCircleChart from "@ntai/components/charts/amcharts/NtaiPackedCircleChart";
import NtaiDataGrid from "@ntai/components/tables/aggrid/NtaiDataGrid";
import NtaiMapChart3 from "@ntai/components/charts/maps/NtaiMapChart3";
import NtaiDashboardWidget from "@ntai/components/widgets/dashboard/NtaiDashboardWidget";
import NtaiHorizontalBarEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarEChart";
import NtaiHorizontalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarStackedEChart";
import NtaiHorizontalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiHorizontalBarSeriesEChart";
import NtaiVerticalBarEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarEChart";
import NtaiVerticalBarSeriesEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarSeriesEChart";
import NtaiVerticalBarStackedEChart from "@ntai/components/charts/echarts/bar/NtaiVerticalBarStackedEChart";
import NtaiPieEChart from "@ntai/components/charts/echarts/pie/NtaiPieEChart";
import NtaiDonutEChart from "@ntai/components/charts/echarts/pie/NtaiDonutEChart";
import NtaiDateHistogramEChart from "@ntai/components/charts/echarts/date-hist/NtaiDateHistogramEChart";
import NtaiTimeSeriesEChart from "@ntai/components/charts/echarts/time-series/NtaiTimeSeriesEChart";
import NtaiCircularDependencyEChart from "@ntai/components/charts/echarts/force-graph/NtaiCircularDependencyEChart";
import NtaiMuiDataGridWidget from "@ntai/components/grid/mui/NtaiMuiDataGridWidget";
import NtaiForceDirectedEChart from "@ntai/components/charts/echarts/force-graph/NtaiForceDirectedEChart";
import NtaiTreeMapEChart from "@ntai/components/charts/echarts/treemap/NtaiTreeMapEChart";
import NtaiBubbleEChart from "@ntai/components/charts/echarts/scatter/NtaiBubbleEChartOld";
import NtaiHeatmapEChart from "@ntai/components/charts/echarts/heatmap/NtaiHeatmapEChart";
import NtaiRadialTreeEChart from "@ntai/components/charts/echarts/tree/NtaiRadialTreeEChart";
import NtaiLinearTreeEChart from "@ntai/components/charts/echarts/tree/NtaiLinearTreeEChart";
import NtaiWordCloudChart from "@ntai/components/charts/amcharts/v2/NtaiWordCloudChart";
import NtaiScatterEChart from "@ntai/components/charts/echarts/scatter/NtaiScatterEChart";

const _ = require("lodash");

export default function OversightEntityOverviewViewWidget({
  viewId,
  initData,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const dimsGeo = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  const observeGeo = _.get(dimsGeo, "observe");
  const geoWidth = _.get(dimsGeo, "width");
  const geoHeight = _.get(dimsGeo, "height");

  const { navNode } = React.useContext(OversightContext);

  const dataUuId =
    _.has(navNode, "type") && _.get(navNode, "type") === "ENTITY"
      ? _.get(navNode, "uuId")
      : null;

  const widgetsDataMap = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewWidgetsSlice.data
  );

  const status = useSelector(
    (state) => state.oversight.oversightEntityOverviewViewWidgetsSlice.status
  );

  const widget = _.has(widgetsDataMap, initData.uuId)
    ? _.get(widgetsDataMap, initData.uuId)
    : null;

  const widgetMetadata = _.get(widget, "metadata");
  const fields = widget && _.get(_.get(widget, "metadata"), "fields");

  function handleClick(searchSourceWidgetFilterFields) {
    // handleFilter(
    //   SEARCH_SOURCE_WIDGET_MENU_ACTIONS.SELECT,
    //   _.get(widget, "widgetUuId"),
    //   searchSourceWidgetFilterFields
    // );
  }

  function handleContextMenu(actionCode, pSearchSourceWidgetFilterFields) {
    //handleFilter(_.get(widget, "widgetUuId"), searchSourceWidgetFilterFields);
    // if (actionCode === SEARCH_SOURCE_WIDGET_MENU_ACTIONS.NOTES) {
    //   setSearchSourceWidgetFilterFields(pSearchSourceWidgetFilterFields);
    //   handleNoteCreateDialogOpen();
    // } else {
    //   handleFilter(
    //     actionCode,
    //     _.get(widget, "widgetUuId"),
    //     pSearchSourceWidgetFilterFields
    //   );
    // }
  }

  function handleLinkClick(
    linkedSourceUuId,
    linkUuId,
    linkValue,
    sourceRecordId
  ) {
    // dispatch(
    //   handleLinkClickForLinkedSearchSource({
    //     searchId: searchId,
    //     parentSearchSourceId: searchSourceId,
    //     linkedSourceSourceUuId: linkedSourceUuId,
    //     linkUuId: linkUuId,
    //     linkValue: linkValue,
    //     sourceRecordId: sourceRecordId,
    //   })
    // );
  }

  function getFieldTypesFromMetadata() {
    let result = {};
    const catFields = _.filter(
      Object.values(widgetMetadata["fields"]),
      function (f) {
        if (f.field.dataTypeCode === 1) return true;
        else return false;
      }
    );

    result = {
      categoryFields: catFields.length,
    };

    return result;
  }

  useEffect(() => {
    if (
      status &&
      _.has(status, initData.uuId) &&
      _.get(_.get(status, initData.uuId), "result") === "success" &&
      _.get(_.get(status, initData.uuId), "method") ===
        "getOversightEntityOverviewViewWidget"
    ) {
      setLoaded(true);
      dispatch(clearStatus(initData.uuId));
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getOversightEntityOverviewViewWidget({
        objectUuId: _.get(navNode, "parentUuId"),
        dataUuId: dataUuId,
        viewId: viewId,
        uuId: _.get(initData, "uuId"),
      })
    );
  }, [initData.uuId]);

  return (
    <NtaiDashboardWidget title={initData.name} loading={!loaded}>
      {loaded && widget && _.has(widget, "uuId") && (
        <>
          {widget && widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL && (
            <NtaiHorizontalBarEChart
              widgetId={_.get(widget, "widgetUuId")}
              data={_.get(widget, "data")}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget &&
            widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL_SERIES && (
              <NtaiHorizontalBarSeriesEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                // categoryField={_.get(widgetMetadata, "categoryField")}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
                metadata={widgetMetadata}
              />
            )}

          {widget &&
            widget.widgetChartCode === CHART_CODES.BAR_HORIZONTAL_STACKED && (
              <NtaiHorizontalBarStackedEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
                metadata={widgetMetadata}
              />
            )}

          {widget && widget.widgetChartCode === CHART_CODES.BAR_VERTICAL && (
            <NtaiVerticalBarEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget &&
            widget.widgetChartCode === CHART_CODES.BAR_VERTICAL_SERIES && (
              <NtaiVerticalBarSeriesEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
                metadata={widgetMetadata}
              />
            )}

          {widget &&
            widget.widgetChartCode === CHART_CODES.BAR_VERTICAL_STACKED && (
              <NtaiVerticalBarStackedEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
                metadata={widgetMetadata}
              />
            )}

          {widget && widget.widgetChartCode === CHART_CODES.PIE && (
            <NtaiPieEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.DONUT && (
            <NtaiDonutEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.DATE_HISTOGRAM && (
            <NtaiDateHistogramEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.TIME_SERIES && (
            <NtaiTimeSeriesEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.CHORD && (
            <NtaiCircularDependencyEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.GEO && (
            <Box
              sx={{ display: "flex", width: "100%", height: "100%" }}
              ref={observeGeo}
            >
              <NtaiMapChart3
                widgetId={widget.widgetUuId}
                data={widget.data}
                handleClick={handleClick}
                metadata={widgetMetadata}
                width={geoWidth}
                height={geoHeight}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.DATA_GRID_I && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiDataGrid
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.DATA_GRID_II && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiMuiDataGridWidget
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                handleLinkClick={handleLinkClick}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.PACKED_CIRCLE && (
            <NtaiPackedCircleChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.FORCE_DIRECTED && (
            <NtaiForceDirectedEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              metadata={widgetMetadata}
              handleClick={handleClick}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.TREEMAP && (
            <NtaiTreeMapEChart
              widgetId={widget.widgetUuId}
              data={widget.data}
              handleClick={handleClick}
              handleContextMenu={handleContextMenu}
              metadata={widgetMetadata}
            />
          )}

          {widget && widget.widgetChartCode === CHART_CODES.PIVOT_GRID && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiXTab
                widgetId={widget.widgetUuId}
                rows={widget.data}
                metadata={widgetMetadata}
                handleClick={handleClick}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.SCATTER && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiScatterEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.BUBBLE && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiBubbleEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.SANKEY_PLOT && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiSankeyChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                fromField="from"
                toField="to"
                valueField="value"
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.HEATMAP && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiHeatmapEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                handleClick={handleClick}
                handleConetxtMenu={handleContextMenu}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.SCRIPTED_METRIC && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiWidgetMetric
                widgetId={widget.widgetUuId}
                data={widget.data[0]["value"]}
                metadata={widgetMetadata}
                handleClick={handleClick}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.WORD_CLOUD && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiWordCloudChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                categoryField={"intervention"}
                valueField={"value"}
                metadata={widgetMetadata}
                handleClick={handleClick}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.TREE_LINEAR && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiLinearTreeEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
              />
            </Box>
          )}

          {widget && widget.widgetChartCode === CHART_CODES.TREE_RADIAL && (
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <NtaiRadialTreeEChart
                widgetId={widget.widgetUuId}
                data={widget.data}
                metadata={widgetMetadata}
                handleClick={handleClick}
                handleContextMenu={handleContextMenu}
              />
            </Box>
          )}
        </>
      )}
    </NtaiDashboardWidget>
  );
}
