import { Box, useTheme } from "@mui/material";
import React from "react";
import OversightHeaderWidget from "./OversightHeaderWidget";
import { OVERSIGHT_SECTIONS } from "../../../../../constants/OversightConstants";
import {
  clearStatus,
  getOversightTopic,
} from "../../../../../store/oversightTopicsSlice";
import { OversightContext } from "../../../../../Oversight";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

export default function OversightHeaderWidgetsWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    oversightTopicId,
    reloadOversightTopicSummary,
    setReloadOversightTopicSummary,
  } = React.useContext(OversightContext);

  const oversightTopicDataMap = useSelector(
    (state) => state.oversight.oversightTopicsSlice.data
  );

  const status = useSelector(
    (state) => state.oversight.oversightTopicsSlice.status
  );

  const oversightData = _.get(oversightTopicDataMap, oversightTopicId);

  useEffect(() => {
    if (
      status &&
      status.method === "getOversightTopic" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      setReloadOversightTopicSummary(false);
    }
  }, [status]);

  useEffect(() => {
    if (
      (oversightTopicId && oversightTopicId !== "*") ||
      reloadOversightTopicSummary
    ) {
      dispatch(getOversightTopic(oversightTopicId));
    }
  }, [oversightTopicId, reloadOversightTopicSummary]);

  return (
    <Box
      sx={{
        flexBasis: "100%",
        display: "flex",
        gap: theme.spacing(1),
        alignItems: "center",
      }}
    >
      <OversightHeaderWidget
        id={OVERSIGHT_SECTIONS.ASSESSMENTS}
        icon="assessment"
        header={
          oversightData && _.get(oversightData, "numOfAssessments")
            ? _.get(oversightData, "numOfAssessments")
            : 0
        }
        subheader="Assessments"
      />

      <OversightHeaderWidget
        id={OVERSIGHT_SECTIONS.ACTIONS}
        icon="action-solid"
        header={
          oversightData && _.get(oversightData, "numOfActions")
            ? _.get(oversightData, "numOfActions")
            : 0
        }
        subheader="Actions"
      />

      <OversightHeaderWidget
        id={OVERSIGHT_SECTIONS.NOTES}
        icon="notes"
        header={
          oversightData && _.get(oversightData, "numOfNotes")
            ? _.get(oversightData, "numOfNotes")
            : 0
        }
        subheader="Notes"
      />

      <OversightHeaderWidget
        id={OVERSIGHT_SECTIONS.DOCUMENTS}
        icon="document"
        header={
          oversightData && _.get(oversightData, "numOfNotes")
            ? _.get(oversightData, "numOfNotes")
            : 0
        }
        subheader="Documents"
      />

      <OversightHeaderWidget
        id={OVERSIGHT_SECTIONS.SNAPSHOTS}
        icon="snapshot"
        header={
          oversightData && _.get(oversightData, "numOfSnapshots")
            ? _.get(oversightData, "numOfSnapshots")
            : 0
        }
        subheader="User Snapshots"
        badge={{
          label: "2 new",
          bg: theme.colors.secondary,
          fg: theme.colors.secondary.contrastText,
        }}
      />

      <OversightHeaderWidget
        id={OVERSIGHT_SECTIONS.SOURCE_RECORDS}
        icon="items"
        header={
          oversightData && _.get(oversightData, "numOfSourceRecords")
            ? _.get(oversightData, "numOfSourceRecords")
            : 0
        }
        subheader="Source Records"
        badge={{
          label: "2 new",
          bg: theme.colors.secondary,
          fg: theme.colors.secondary.contrastText,
        }}
      />
    </Box>
  );
}
