import SourceViewList from "./SourceViewList";
import SourceViewCriteriaWrapper from "./criteria/SourceViewCriteriaWrapper";
import SourceWidgetManagerWrapper from "./widgets/SourceWidgetManagerWrapper";

const SourceViewConfig = {
  routes: [
    {
      path: "/sourcedefs/:sourcedefid/sources/:id/views",
      exact: true,
      component: SourceViewList,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/views/:id/wm",
      exact: true,
      component: SourceWidgetManagerWrapper,
    },
    {
      path: "/sourcedefs/:sourcedefid/sources/:sourceid/views/:id/criteria",
      exact: true,
      component: SourceViewCriteriaWrapper,
    },
  ],
};

export default SourceViewConfig;
