import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getThesaurusConceptRelations = createAsyncThunk(
  "thesaurusConceptRelations/getThesaurusConceptRelations",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, params } = values;

      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/relations`,
        {
          params: params,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getThesaurusConceptRelation = createAsyncThunk(
  "thesaurusConceptRelations/getThesaurusConceptRelation",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, uuId } = values;
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/relations/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createThesaurusConceptRelation = createAsyncThunk(
  "thesaurusConceptRelations/createThesaurusConceptRelation",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, formData } = values;
      const response = await server.post(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/relations`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateThesaurusConceptRelation = createAsyncThunk(
  "thesaurusConceptRelations/updateThesaurusConceptRelation",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, uuId, formData } = values;
      const response = await server.patch(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/relations/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteThesaurusConceptRelation = createAsyncThunk(
  "thesaurusConceptRelations/deleteThesaurusConceptRelation",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, conceptId, uuId } = values;
      const response = await server.delete(
        `/library/thesauruses/${thesaurusId}/concepts/${conceptId}/relations/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const thesaurusConceptRelationsSlice = createSlice({
  name: "thesaurusConceptRelations",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getThesaurusConceptRelations.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getThesaurusConceptRelations",
      };
    },
    [getThesaurusConceptRelations.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusConceptRelations",
        message: action.payload.message,
      };
    },
    [getThesaurusConceptRelation.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "getThesaurusConceptRelation",
      };
    },
    [getThesaurusConceptRelation.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusConceptRelation",
        message: action.payload.message,
      };
    },
    [createThesaurusConceptRelation.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = {
        result: "success",
        method: "createThesaurusConceptRelation",
      };
    },
    [createThesaurusConceptRelation.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createThesaurusConceptRelation",
        message: action.payload.message,
      };
    },
    [updateThesaurusConceptRelation.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateThesaurusConceptRelation",
      };
    },
    [updateThesaurusConceptRelation.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateThesaurusConceptRelation",
        message: action.payload.message,
      };
    },
    [deleteThesaurusConceptRelation.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteThesaurusConceptRelation",
      };
    },
    [deleteThesaurusConceptRelation.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteThesaurusConceptRelation",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  thesaurusConceptRelationsSlice.actions;

export default thesaurusConceptRelationsSlice.reducer;
