import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export default function CoreItem({
  permissions,
  handleSelect,
  icon,
  label,
  item,
}) {
  return (
    <Box
      sx={{
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",

        "&:hover": {
          borderRadius: "10px",
          background: grey[100],
          cursor: "pointer",
        },
      }}
      onClick={() => handleSelect(item)}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>{icon}</Box>
      <Box csx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body2">{label}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
