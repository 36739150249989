import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import _ from "lodash";

export const getUserGroupUsers = createAsyncThunk(
  "sourceDatasets/getUserGroupUsers",
  async (userGroupId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/user-groups/${userGroupId}/users`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserGroupUser = createAsyncThunk(
  "userGroupUsers/createUserGroupUser",
  async (values, { rejectWithValue }) => {
    try {
      const { userGroupId, formData } = values;
      const response = await server.post(
        `/user-groups/${userGroupId}/users`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserGroupUser = createAsyncThunk(
  "userGroupUsers/deleteUserGroupUser",
  async (values, { rejectWithValue }) => {
    try {
      const { userGroupId, uuId } = values;
      const response = await server.delete(
        `/user-groups/${userGroupId}/users/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userGroupUsersSlice = createSlice({
  name: "userGroupUsers",
  initialState: {
    data: {},
    status: null,
    activeId: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = null;
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getUserGroupUsers.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getUserGroupUsers",
      };
    },
    [getUserGroupUsers.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getUserGroupUsers",
        message: action.payload.message,
      };
    },

    [createUserGroupUser.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createUserGroupUser",
      };
      state.activeId = action.payload.uuId;
    },
    [createUserGroupUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createUserGroupUser",
        message: action.payload.message,
      };
    },

    [deleteUserGroupUser.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteUserGroupUser",
      };
    },
    [deleteUserGroupUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteUserGroupUser",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = userGroupUsersSlice.actions;

export default userGroupUsersSlice.reducer;
