import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function NtaiFieldsPanel(props) {
  const theme = useTheme();
  const { label, children } = props;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      {children}
    </Box>
  );
}
