import React, { useEffect, useState } from "react";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";

import {
  getSourceLinkedSourceLinks,
  createSourceLinkedSourceLink,
  updateSourceLinkedSourceLink,
  deleteSourceLinkedSourceLink,
  clearStatus,
} from "./store/sourceLinkedSourceLinksSlice";
import { useDispatch, useSelector } from "react-redux";
import SourceLinkedSourceLinkForm from "./SourceLinkedSourceLinkForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
  },
];

const toolbarActions = [
  {
    label: "Delete",
    icon: "delete",
    value: "delete",
  },
];

export default function SourceLinkedSourceLink() {
  const [action, setAction] = useState("list");
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const linkedSourceId = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.activeId
  );
  const linkedSourceFieldId = useSelector(
    (state) => state.sources.sourceLinkedSourceFieldsSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    linkedSourceId: linkedSourceId,
    linkedSourceFieldId: linkedSourceFieldId,
  };

  const dataMap = useSelector(
    (state) => state.sources.sourceLinkedSourceLinksSlice.data
  );
  const status = useSelector(
    (state) => state.sources.sourceLinkedSourceLinksSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    const selectedIdsCopy = [...selectedIds];
    if (Array.isArray(selectedIdsCopy) && selectedIdsCopy.length > 0) {
      selectedIdsCopy.forEach((id) => {
        dispatch(deleteSourceLinkedSourceLink(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
  }

  function handleFormSubmit(formData) {
    action === "edit"
      ? dispatch(
          updateSourceLinkedSourceLink({
            ...pathVariables,
            uuId: currentId,
            formData: formData,
          })
        )
      : dispatch(
          createSourceLinkedSourceLink({ ...pathVariables, formData: formData })
        );
  }

  function handleToolbarAction(toolbarAction, selectedItems) {
    setSelectedIds(selectedItems);
    if (toolbarAction === "delete") confirmDelete();
  }

  function handleCancel() {
    setAction("list");
  }

  useEffect(() => {
    dispatch(getSourceLinkedSourceLinks(pathVariables));
  }, []);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceLinkedSourceLink" ||
        status.method === "updateSourceLinkedSourceLink")
    ) {
      setAction("list");
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceLinkedSourceLink"
    ) {
      setOpenConfirmDeleteDialog(false);
      setSelectedIds([]);
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <React.Fragment>
      {action === "list" && (
        <NtaiPanel
          width="100%"
          subheader="Create links on source fields and use them to navigate from source to target linked source"
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            handleToolbarAction={handleToolbarAction}
            title="Link"
          />
        </NtaiPanel>
      )}

      {["add", "edit"].includes(action) && (
        <SourceLinkedSourceLinkForm
          action={action}
          handleFormSubmit={handleFormSubmit}
          handleCancel={handleCancel}
          formData={action === "edit" ? dataMap[currentId] : null}
        />
      )}

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </React.Fragment>
  );
}
