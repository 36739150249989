import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getOversightSummaryAll = createAsyncThunk(
  "oversightSummaryAll/getOversightSummaryAll",
  async (thunkApi, { rejectWithValue }) => {
    try {
      const response = await server.get(`/oversight/summary/all`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const oversightSummaryAllSlice = createSlice({
  name: "oversightSummaryAll",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getOversightSummaryAll.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getOversightSummaryAll" };
    },
    [getOversightSummaryAll.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getOversightSummaryAll",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = oversightSummaryAllSlice.actions;

export default oversightSummaryAllSlice.reducer;
