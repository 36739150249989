const navigationConfig = {
  default: {
    id: "default",
    title: "Default",
    translate: "DEFAULT",
    type: "node",
    icon: "clock",
    children: [
      {
        id: "home",
        title: "Home",
        translate: "HOME",
        type: "item",
        icon: "home",
        url: "/dashboard",
      },
      {
        id: "search",
        title: "Search",
        translate: "SEARCH",
        type: "item",
        icon: "search",
        url: "/search",
      },
    ],
  },
  alerts: {
    id: "alerts",
    title: "Alerts",
    translate: "ALERTS",
    type: "node",
    icon: "clock",
    children: [
      {
        id: "definitions",
        title: "Definitions",
        translate: "DEFINITIONS",
        type: "item",
        icon: "bell",
        url: "/monitor/definitions",
      },
      {
        id: "tracker",
        title: "Tracker",
        translate: "TRACKER",
        type: "item",
        icon: "list-check",
        url: "/monitor/tracker",
      },
    ],
  },
  sources: {
    id: "sources",
    title: "Sources",
    translate: "SOURCES",
    type: "node",
    icon: "database",
    children: [
      {
        id: "sources",
        title: "Sources",
        translate: "SOURCES",
        type: "item",
        icon: "storage",
        url: "/sourcedefs",
      },
      {
        id: "migrations",
        title: "Migrations",
        translate: "MIGRATIONS",
        type: "item",
        icon: "book",
        url: "/migrations",
      },
    ],
  },
  migrations: {
    id: "migrations",
    title: "Migrations",
    translate: "MIGRATIONS",
    type: "node",
    icon: "database",
    children: [
      {
        id: "migrations",
        title: "Migrations",
        translate: "MIGRATIONS",
        type: "item",
        icon: "book",
        url: "/migrations",
      },
    ],
  },
  admin: {
    id: "admin",
    title: "Admin",
    translate: "ADMIN",
    type: "node",
    icon: "user-gear",
    children: [
      {
        id: "users",
        title: "Users",
        translate: "USERS",
        type: "item",
        icon: "user",
        url: "/admin/users",
      },
      {
        id: "roles",
        title: "Roles",
        translate: "ROLES",
        type: "item",
        icon: "user-role",
        url: "/admin/roles",
      },
      {
        id: "user-groups",
        title: "User Groups",
        translate: "USER GROUPS",
        type: "item",
        icon: "userGroup",
        url: "/admin/user-groups",
      },
      {
        id: "codelists",
        title: "Codelists",
        translate: "CODELISTS",
        type: "item",
        icon: "codelist",
        url: "/admin/codelists",
      },
      {
        id: "rules",
        title: "Rules",
        translate: "RULES",
        type: "item",
        icon: "rules",
        url: "/admin/rules",
      },
      {
        id: "workflow",
        title: "Workflows",
        translate: "WORKFLOWS",
        type: "item",
        icon: "process",
        url: "/admin/workflows",
      },
      {
        id: "widgets",
        title: "Widgets",
        translate: "WIDGETS",
        type: "item",
        icon: "widgetsLight",
        url: "/admin/widgets",
      },
      {
        id: "schedulers",
        title: "Schedulers",
        translate: "SCHEDULERS",
        type: "item",
        icon: "clock",
        url: "/admin/schedulers",
      },
      {
        id: "report-templates",
        title: "Reports",
        translate: "REPORTS",
        type: "item",
        icon: "file-chart-column",
        url: "/admin/templates/reports",
      },
      {
        id: "aws",
        title: "AWS",
        translate: "AWS",
        type: "node",
        icon: "aws",
        url: "#",
        children: [
          {
            id: "aws-clusters",
            title: "Clusters",
            translate: "CLUSTERS",
            type: "item",
            icon: "file-chart-column",
            url: "/aws-clusters",
          },
          {
            id: "aws-cluster-templates",
            title: "Cluster Templates",
            translate: "CLUSTER TEMPLATES",
            type: "item",
            icon: "file-chart-column",
            url: "/aws-cluster-templates",
          },
        ],
      },
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "DASHBOARD",
        type: "node",
        icon: "dashboard",
        url: "#",
        children: [
          {
            id: "dashboard-views",
            title: "Views",
            translate: "VIEWS",
            type: "item",
            icon: "file-chart-column",
            url: "/admin/dashboards/views",
          },
          {
            id: "dashboard-widgets",
            title: "Widgets",
            translate: "WIDGETS",
            type: "item",
            icon: "file-chart-column",
            url: "/admin/dashboards/widgets",
          },
        ],
      },
      {
        id: "notifications",
        title: "Notifications",
        translate: "NOTIFICATIONS",
        type: "item",
        icon: "notification",
        url: "/admin/notification-defs",
      },
      {
        id: "datasources",
        title: "Data sources",
        translate: "DATA SOURCES",
        type: "item",
        icon: "database",
        url: "/admin/datasources",
      },
      {
        id: "settings",
        title: "Settings",
        translate: "SETTINGS",
        type: "item",
        icon: "settings",
        url: "/admin/settings",
      },
    ],
  },
  models: {
    id: "models",
    title: "Models",
    translate: "MODELS",
    type: "node",
    icon: "ai",
    children: [
      {
        id: "datasets",
        title: "Datasets",
        translate: "DATASETS",
        type: "item",
        icon: "table",
        url: "/models/datasets",
      },
      {
        id: "models",
        title: "Models",
        translate: "MODELS",
        type: "item",
        icon: "shapes",
        url: "/models/models",
      },
      {
        id: "functions",
        title: "Functions",
        translate: "FUNCTIONS",
        type: "item",
        icon: "function",
        url: "/models/functions",
      },
      {
        id: "function-return-types",
        title: "Return types",
        translate: "FUNCTION RETURN TYPES",
        type: "item",
        icon: "turn-down-left",
        url: "/models/function-return-types",
      },
      {
        id: "packages",
        title: "Packages",
        translate: "PACKAGES",
        type: "item",
        icon: "tree",
        url: "/models/packages",
      },
      {
        id: "python-files",
        title: "Python Files",
        translate: "PYTHON FILES",
        type: "item",
        icon: "python",
        url: "/models/python-files",
      },
    ],
  },

  cdo: {
    id: "cdo",
    title: "Objects",
    translate: "OBJECTS",
    type: "node",
    icon: "database",
    children: [
      {
        id: "domains",
        title: "Domains",
        translate: "DOMAINS",
        type: "item",
        icon: "diagram-project",
        url: "/cdo/domains",
      },
      {
        id: "objects",
        title: "Objects",
        translate: "OBJECTS",
        type: "item",
        icon: "sitemap",
        url: "/cdo/objects",
      },
    ],
  },
  library: {
    id: "library",
    title: "Library",
    translate: "LIBRARY",
    type: "node",
    icon: "libraryRegular",
    children: [
      {
        id: "contacts",
        title: "Contacts",
        translate: "CONTACTS",
        type: "item",
        icon: "user",
        url: "/library/contacts",
      },
      {
        id: "contact-groups",
        title: "Contact Groups",
        translate: "CONTACTS GROUPS",
        type: "item",
        icon: "userGroup",
        url: "/library/contact-groups",
      },
      {
        id: "distribution-groups",
        title: "Distribution Groups",
        translate: "DISTRIBUTION GROUPS",
        type: "item",
        icon: "distributionList",
        url: "/library/distribution-groups",
      },
      {
        id: "topics",
        title: "Topics",
        translate: "TOPICS",
        type: "item",
        icon: "topic",
        url: "/library/topics",
      },
      {
        id: "source-series",
        title: "Series",
        translate: "SERIES",
        type: "item",
        icon: "list",
        url: "/library/source-series",
      },
      {
        id: "queries",
        title: "Rules",
        translate: "RULES",
        type: "item",
        icon: "rules",
        url: "/library/queries",
      },
      {
        id: "datasets",
        title: "Datasets",
        translate: "DATASETS",
        type: "item",
        icon: "table",
        url: "/library/datasets",
      },
      {
        id: "thesauruses",
        title: "Dictionaries",
        translate: "DICTIONARIES",
        type: "item",
        icon: "thesaurus",
        url: "/library/thesauruses",
      },
    ],
  },
  "entities-library": {
    id: "entities-library",
    title: "Entities",
    translate: "ENTITIES",
    type: "node",
    icon: "shapes",
    children: [],
  },
  tools: {
    id: "tools",
    title: "Tools",
    translate: "TOOLS",
    type: "node",
    icon: "ai",
    children: [
      {
        id: "import-assessments",
        title: "Import",
        translate: "IMPORT",
        type: "item",
        icon: "table",
        url: "/tools/import-assessments",
      },
      {
        id: "export-assessments",
        title: "Export",
        translate: "EXPORT",
        type: "item",
        icon: "shapes",
        url: "/tools/export-assessments",
      },
    ],
  },
};

export default navigationConfig;
