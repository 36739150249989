import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "../chartConfigs";
import { Box, useTheme } from "@mui/material";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import theme from "@amcharts/amcharts4/.internal/themes/animated";
import NtaiEChartUtil from "../../echarts/util/NtaiEChartUtil";
const _ = require("lodash");

am4core.useTheme(am4themes_animated);

function getColorObject(theme, subCategoryField, dat) {
  const firstElem = dat[0];
  let colorObject = {};
  if (_.has(firstElem, subCategoryField)) {
    const uniqueTypes = [...new Set(dat.map((item) => item[subCategoryField]))];
    const colors = NtaiEChartUtil.getColorPalette(
      theme.colors.chart.light,
      uniqueTypes.length
    );
    let colorObject = {};
    uniqueTypes.forEach((t, i) => {
      colorObject[t] = colors[i];
    });

    return colorObject;
  }
}

function processData(
  theme,
  colorObject,
  categoryField,
  subCategoryField,
  startDateField,
  endDateField,
  dat
) {
  return dat.map((d, i) => {
    let obj = {};
    obj[categoryField] = d[categoryField];
    obj[endDateField] = NtaiUtils.millisecondsToDate(Number(d[endDateField]));
    obj[startDateField] = NtaiUtils.millisecondsToDate(
      Number(d[startDateField])
    );
    obj[subCategoryField] = d[subCategoryField];
    obj["_color"] = !_.isEmpty(colorObject)
      ? colorObject[d[subCategoryField]]
      : theme.colors.chart.light;

    return obj;
  });
}

export default function NtaiGanttChart(props) {
  const theme = useTheme();
  const { widgetId, data, handleClick, metadata } = props;

  const { categoryField, subCategoryField, startDateField, endDateField } =
    metadata;

  console.log("Gantt data and metadata: ", data, metadata);

  useLayoutEffect(() => {
    let chart = am4core.create(
      "ganttchart".concat("-", widgetId),
      am4charts.XYChart
    );
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    const colorObject = getColorObject(theme, subCategoryField, data);

    chart.data = processData(
      theme,
      colorObject,
      categoryField,
      subCategoryField,
      startDateField,
      endDateField,
      data
    );

    console.log("chart data: ", chart.data);
    chart.cursor = new am4charts.XYCursor();
    chart.paddingRight = 30;
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = categoryField;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.cellStartLocation = 0.4;
    categoryAxis.renderer.cellEndLocation = 0.6;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 120;
    label.horizontalCenter = "right";
    label.verticalCenter = "middle";
    label.fontSize = 11;
    label.truncate = true;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd";
    dateAxis.renderer.minGridDistance = 10;
    dateAxis.baseInterval = { count: 1, timeUnit: "month" };
    //TODO: extract max date from data
    dateAxis.max = new Date(2023, 0, 1, 24, 0, 0, 0).getTime();
    dateAxis.strictMinMax = true;
    dateAxis.renderer.tooltipLocation = 0;

    let dateLabel = dateAxis.renderer.labels.template;
    dateLabel.wrap = true;
    dateLabel.rotation = 270;
    dateLabel.maxWidth = 120;
    dateLabel.horizontalCenter = "right";
    dateLabel.verticalCenter = "middle";
    dateLabel.fontSize = 10;
    // dateLabel.truncate = true;

    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(100);

    series1.columns.template.tooltipText = `{${categoryField}}: {openDateX} - {dateX}`;
    series1.dataFields.openDateX = startDateField;
    series1.dataFields.dateX = endDateField;
    series1.dataFields.categoryY = categoryField;
    series1.columns.template.propertyFields.fill = "_color"; // get color from data
    series1.columns.template.propertyFields.stroke = "_color";
    series1.columns.template.strokeOpacity = 1;

    // tool tip
    series1.tooltip.getFillFromObject = false;
    series1.tooltip.autoTextColor = false;
    series1.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    series1.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    series1.tooltip.background.fillOpacity =
      tooltipConfig.backgroundFillOpacity;
    series1.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    series1.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    let axisTooltip = categoryAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 10;
    categoryAxis.cursorTooltipEnabled = false;
    // categoryAxis.sortBySeries = series1;

    let valueAxisTooltip = dateAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#767676");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    valueAxisTooltip.dy = 5;
    valueAxisTooltip.dx = -20;
    valueAxisTooltip.label.fill = am4core.color("#fff");
    valueAxisTooltip.label.fontSize = 11;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box
      id={"ganttchart".concat("-", widgetId)}
      sx={{ display: "flex", width: "100%", height: "100%" }}
    ></Box>
  );
}
