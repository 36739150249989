import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordLabelSecondaryList = createAsyncThunk(
  "sourceRecordLabelSecondary/getSourceRecordLabelSecondaryList",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/secondary`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordLabelSecondary = createAsyncThunk(
  "sourceRecordLabelSecondary/createSourceRecordLabelSecondary",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/secondary`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordLabelSecondary = createAsyncThunk(
  "sourceRecordLabelSecondary/updateSourceRecordLabelSecondary",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/secondary/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordLabelSecondary = createAsyncThunk(
  "sourceRecordLabelSecondary/deleteSourceRecordLabelSecondary",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, labelId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/labels/${labelId}/secondary/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordLabelSecondarySlice = createSlice({
  name: "sourceRecordLabelSecondary",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordLabelSecondaryList.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceRecordLabelSecondaryList",
      };
    },
    [getSourceRecordLabelSecondaryList.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordLabelSecondaryList",
        message: action.payload.message,
      };
    },

    [createSourceRecordLabelSecondary.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordLabelSecondary",
      };
    },
    [createSourceRecordLabelSecondary.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordLabelSecondary",
        message: action.payload.message,
      };
    },

    [updateSourceRecordLabelSecondary.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordLabelSecondary",
      };
    },
    [updateSourceRecordLabelSecondary.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordLabelSecondary",
        message: action.payload.message,
      };
    },

    [deleteSourceRecordLabelSecondary.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordLabelSecondary",
      };
    },
    [deleteSourceRecordLabelSecondary.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordLabelSecondary",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceRecordLabelSecondarySlice.actions;

export default sourceRecordLabelSecondarySlice.reducer;
