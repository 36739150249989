import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Scrollbar from "../Scrollbar";
import NtaiCircularProgress from "../progress/NtaiCircularProgress";
const _ = require("lodash");

function NtaiFileUpload(props) {
  const { pending, handleFileUpload } = props;
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState();
  const theme = useTheme();

  const onDrop = useCallback((acceptedFiles) => {
    let uploadedFiles = [];
    let fd = new FormData();
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    acceptedFiles.forEach((file) => {
      uploadedFiles.push(file.name);
      const reader = new FileReader();

      reader.onabort = () => console.log("File reading was aborted");
      reader.onerror = () => console.log("File reading has failed");

      reader.onload = () => {
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
      fd.append("files", file);
    });

    setFiles(uploadedFiles);

    handleFileUpload(fd, config);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function displayFiles() {
    let fileBox = [];
    if (files)
      files.forEach((file, index) => {
        fileBox.push(<Box key={`uploaded-file-${index}`}>{file}</Box>);
      });

    return fileBox;
  }

  return (
    <Box
      sx={{
        display: "flex",
        padding: theme.spacing(4),
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <Box {...getRootProps()}>
        <Box
          sx={{
            display: "flex",
            border: "4px dashed #eee",
            borderRadius: "10px",
            padding: theme.spacing(2),
            justifyContent: "center",
          }}
        >
          <input {...getInputProps()} />

          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </Box>
      </Box>
      {pending && <NtaiCircularProgress />}
      {files && _.isArray(files) && files.length > 0 && (
        <Box
          sx={{
            height: "124px",
            border: "1px solid #eee",
            padding: theme.spacing(2),
          }}
        >
          <Scrollbar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {displayFiles()}
            </Box>
          </Scrollbar>
        </Box>
      )}
    </Box>
  );
}

export default NtaiFileUpload;
