import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  setActiveId,
  updateAwsCluster,
} from "./store/awsClustersSlice";
import AwsClusterForm from "./AwsClusterForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function AwsClusterEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.awsClustersSlice.status);
  const dataMap = useSelector((state) => state.core.awsClustersSlice.data);
  const activeId = useSelector((state) => state.core.awsClustersSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateAwsCluster({ uuId: id, formData: formData }));
  }
  function handleCancel() {
    history.push(`/aws-clusters`);
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, []);

  useEffect(() => {
    if (status.method === "updateAwsCluster" && status.result === "success") {
      enqueueSnackbar(
        `Aws cluster "${_.get(
          dataMap[activeId],
          "name"
        )}" updated successfully.`,
        {
          variant: "success",
        }
      );

      history.push(`/aws-clusters`);
      dispatch(clearStatus());
    }

    if (status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage title={`Edit ${_.get(dataMap[id], "name")}`} back="/aws-clusters">
      <NtaiForm>
        <AwsClusterForm
          action="edit"
          formData={formData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </NtaiForm>
    </NtaiPage>
  );
}
