import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import React from "react";

export default function UserNotificationSetting() {
  const theme = useTheme();
  return (
    <NtaiPanel header="Notifications" subheader="More information">
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          border: theme.general.border1,
        }}
      >
        <List sx={{ p: 0, width: "100%" }}>
          <ListItemButton sx={{ p: 0 }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="h6" fontWeight="700">
                    Search
                  </Typography>
                }
                secondary="Turn on or off notifications related to search settings"
              />
            </ListItem>
          </ListItemButton>
          <Divider />
        </List>
      </Box>
    </NtaiPanel>
  );
}
