import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import { SOURCE_FILE_FORMAT_TYPE_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import SourceInForm from "./SourceInForm";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";

const file_formats = [
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.CSV, label: "CSV" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.PARQUET, label: "Parquet" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.JSON, label: "JSON" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.XML, label: "XML" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.SAS74BT, label: "SAS7BDAT" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.PDFM, label: "PDF Machine" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.PDFS, label: "PDF Scanned" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.IMG, label: "Image" },
];

const data_sources = [
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.AWSREDSHIFT, label: "AWS Redshift" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.SNOWFLAKE, label: "Snowflake" },
  { value: SOURCE_FILE_FORMAT_TYPE_CODES.JDBC, label: "JDBC" },
];

export default function SourceInFormSelect({
  action,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const [format, setFormat] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);

  const status = useSelector((state) => state.sources.sourceInsSlice.status);

  function handleSelect(format) {
    setFormat(format);
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "createSourceIn"
    ) {
      handleDialogClose();
    }
  }, [status]);

  return (
    <React.Fragment>
      <NtaiPanel
        width="100%"
        title="Select Source Type"
        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
      >
        <Box
          sx={{
            display: "flex",
            height: "150px",
            flexDirection: "column",
            gap: theme.spacing(2),
            border: theme.general.border1,
          }}
        >
          <Scrollbar>
            <Box
              sx={{
                display: "flex",
                p: theme.spacing(2),
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <Typography variant="h6" color="secondary">
                File Types
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: theme.spacing(1),
                  gap: theme.spacing(1),
                }}
              >
                {file_formats.map((f, i) => (
                  <Button
                    key={`source-in-std-select-${i}`}
                    color="inherit"
                    size="small"
                    onClick={() => handleSelect(f.value)}
                  >
                    {f.label}
                  </Button>
                ))}
              </Box>

              <Divider />
              <Typography variant="h6" color="secondary">
                Data Sources
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: theme.spacing(1),
                  gap: theme.spacing(1),
                }}
              >
                {data_sources.map((f, i) => (
                  <Button
                    key={`source-in-datasource-select-${i}`}
                    color="inherit"
                    size="small"
                    onClick={() => handleSelect(f.value)}
                  >
                    {f.label}
                  </Button>
                ))}
              </Box>
            </Box>
          </Scrollbar>
        </Box>
      </NtaiPanel>
      <NtaiDialog
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
        title="Add Input Store"
        size="md"
      >
        <NtaiForm>
          <SourceInForm
            format={format}
            action={"add"}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            formData={{}}
          />
        </NtaiForm>
      </NtaiDialog>
    </React.Fragment>
  );
}
