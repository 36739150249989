import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, useTheme, List, Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSourceRecordListings } from "app/main/pages/core/sourcedefinition/source/template/listing/store/sourceRecordListingsSlice";
import { SearchSourceRecordListContext } from "./SearchSourceRecordListPanel";
import { SearchSourceContext } from "../SearchSource";
import {
  clearStatus,
  clearSearchSourceRecordsData,
  getSearchSourceRecords,
  getSearchSourceRecord,
  getSearchSourceRecordsSummary,
} from "./store/searchSourceRecordsSlice";
import { SearchContext } from "../../SearchEdit";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import SearchSourceRecordPanel from "./recordpanel/SearchSourceRecordPanel";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import SearchSourceRecordLookup from "./lookup/SearchSourceRecordLookup";
import { getSourceFields } from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import SearchSourceRecordListItem from "./templates/SearchSourceRecordListItem";
import SearchSourceRecordTableTemplate from "./templates/SearchSourceRecordTableTemplate";
import useSearchSourceWidgetTempFilters from "../hooks/useSearchSourceWidgetTempFilters";
import NtaiInfiniteScroll2 from "@ntai/components/infinite-scroll/NtaiInfiniteScroll2";
import { useMeasure } from "react-use";
const jp = require("jsonpath");
const _ = require("lodash");

export default function SearchSourceRecordList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [lookupDialogOpen, setLookupDialogOpen] = useState(false);
  const [lookupIds, setLookupIds] = useState({});
  const [items, setItems] = useState([]);

  const [fetchMoreDataFg, setFetchMoreDataFg] = useState(false);
  const [ref, { width, height }] = useMeasure();
  const [loaded, setLoaded] = useState(false);
  const [recordTemplatesData, setRecordTemplatesData] = useState([]);
  // const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters();

  const {
    from,
    setFrom,
    templateType,
    selectedRecord,
    setSelectedRecord,
    refreshSelectedRecord,
    setRefreshSelectedRecord,
    recordsSearchText,
  } = useContext(SearchSourceRecordListContext);

  const { searchData, showAdvSearchFilterPanel } =
    React.useContext(SearchContext);

  const {
    searchSourceData,
    reload,
    refresh,
    setRefresh,
    tableView,
    selectedWidgetRecordId,
    changeView,
  } = React.useContext(SearchSourceContext);
  const searchId = _.get(searchData, "uuId");

  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");
  const searchSourceWidgetTempFilters = useSearchSourceWidgetTempFilters({
    searchSourceId: searchSourceId,
  });

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const sourceDefId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.uuId"
  );

  const sourceFields = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordsSlice.data
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  // const searchSourceRecordsCount = useSelector(
  //   (state) => state.search.searchSourceRecordsCountSlice.totalHits
  // );

  // const hits = _.get(selectedSearchSourceData, "totalHits");
  const hits = useSelector(
    (state) => state.search.searchSourceRecordsCountSlice.totalHits
  );

  const sourceTemplateListingsDataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  // const records = NtaiUtils.getFieldArrayFromObjectArray(data, "minData");

  const recordTemplate =
    recordTemplatesData && _.isArray(recordTemplatesData)
      ? _.filter(recordTemplatesData, selectTemplate)[0]
      : null;

  function getRecordListingLabel(labels) {
    let label = null;
    if (
      recordTemplate &&
      _.isArray(labels) &&
      recordTemplate["sourceRecordLabel"]
    ) {
      const plabels = _.filter(labels, function (l) {
        if (l["uuId"] === recordTemplate["sourceRecordLabel"]["uuId"])
          return true;
        else return false;
      });

      if (plabels.length > 0) label = plabels[0];
    }

    return label;
  }

  function handleClose() {
    setOpen(false);
    setSelectedRecord(null);
  }

  function selectTemplate(item) {
    if (item.typeCode === 1 && templateType === 1) return true;
    if (item.typeCode === 2 && templateType === 2) return true;
  }

  function handleDisplayRecord(e, id) {
    if (e != null && !e.target.id.includes("record-link")) {
      if (id === _.get(selectedRecord, "recordId") && open) {
        handleClose();
        setSelectedRecord(null);
      } else {
        setSelectedRecord(_.filter(data, { recordId: id })[0]);
        setOpen(true);
      }
    } else {
      if (selectedWidgetRecordId && selectedWidgetRecordId.length > 0) {
        setSelectedRecord(_.filter(data, { recordId: id })[0]);
        setOpen(true);
      }
    }
  }

  function fetchField(placementCode, recordData) {
    const fields = _.get(recordTemplate, "sourceRecordListingFields");
    const field = _.filter(fields, { placementCode: placementCode })[0];

    if (field === undefined || !field) return null;

    // TODO : when field is nested, it will take all values from same field having same name

    const fieldValue =
      _.has(field, "sourceDerivedFieldFg") &&
      field["sourceDerivedFieldFg"] === 1
        ? _.get(recordData["fields"], field["sourceFieldName"])
        : _.get(field, "sourceField.path").includes(".")
        ? jp.query(
            recordData["minData"] || recordData["data"],
            `$..${_.get(field, "sourceField.path").split(".").pop()}`
          )
        : jp.query(
            recordData["minData"] || recordData["data"],
            `$..${_.get(field, "sourceField.path")}`
          );

    const lookupLinkUuId =
      _.isArray(_.get(field, "sourceField.lookupLinks")) &&
      _.get(field, "sourceField.lookupLinks").length > 0
        ? _.get(_.get(field, "sourceField.lookupLinks")[0], "uuId")
        : null;

    const returnValue = {
      value: fieldValue,
      lookupLinkUuId: lookupLinkUuId,
      recordId: _.get(recordData["minData"], "id"),
    };

    return returnValue;
  }

  function fetchMoreData() {
    setFetchMoreDataFg(true);
    setFrom(from + 25);
  }

  function handleLookup(e, lookupLinkUuId, lookupValue, recordId) {
    setLookupIds({
      lookupLinkId: lookupLinkUuId,
      lookupValue: lookupValue,
      lookupRecordId: recordId,
    });
  }

  function displayRecords() {
    if (status && status.result === "pending" && from === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NtaiCircularProgress size={20} />
        </Box>
      );
    } else {
      if (templateType === 1 && _.isArray(data) && data.length > 0) {
        return generateItems(data);
      }
      if (templateType === 2 && _.isArray(items) && items.length > 0) {
        return (
          <Box
            sx={{
              position: "relative",
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {/* {recordsList} */}
            <NtaiInfiniteScroll2
              height={height}
              data={items}
              hasMore={hits > items.length ? true : false}
              fetchMoreData={fetchMoreData}
              generateItems={generateItems}
            />
          </Box>
        );
      }

      if (_.isArray(data) && data.length === 0) {
        return (
          <NtaiEmptyMessage
            header="No records found"
            subheader="Revise search or filter criteria"
            vAlign="center"
          />
        );
      }
    }
  }

  function generateItems(recordsData) {
    if (templateType === 2 && recordsData.length > 0) {
      return (
        <List sx={{ width: "100%", padding: "0px" }} dense>
          {recordsData.map((recordData, index) => (
            <Box key={`search-source-record-list-item-${index}`}>
              <SearchSourceRecordListItem
                index={index}
                id={recordData["recordId"]}
                assessmentFg={recordData["assessmentFg"]}
                numOfActions={recordData["numOfActions"]}
                numOfDocuments={recordData["numOfDocuments"]}
                numOfNotes={recordData["numOfNotes"]}
                numOfWorkflows={recordData["numOfWorkflows"]}
                numOfContacts={recordData["numOfContacts"]}
                handleDisplayRecord={handleDisplayRecord}
                primaryText={fetchField(1, recordData)}
                secondaryText={fetchField(2, recordData)}
                // secondaryText={_.get(record, fetchField(2))}
                tertiaryText={fetchField(3, recordData)}
                label={
                  _.has(recordData, "labels") && _.isArray(recordData["labels"])
                    ? getRecordListingLabel(recordData["labels"])
                    : null
                }
                handleLookup={handleLookup}
                // imgUrl={_.get(record, fetchField(4))}
              />
            </Box>
          ))}
        </List>
      );
    }

    if (templateType === 1 && recordsData.length > 0) {
      return (
        <Box sx={{ display: "flex", width: "100%", height: "100%" }} ref={ref}>
          <SearchSourceRecordTableTemplate
            data={recordsData}
            height={height}
            width={width}
            handleDisplayRecord={handleDisplayRecord}
          />
        </Box>
      );
    }
  }

  useEffect(() => {
    if (selectedWidgetRecordId && selectedWidgetRecordId.length > 0) {
      handleDisplayRecord(null, selectedWidgetRecordId);
    }
  }, [selectedWidgetRecordId]);

  useEffect(() => {
    if (!_.isEmpty(lookupIds)) setLookupDialogOpen(true);
  }, [lookupIds]);

  useEffect(() => {
    setRecordTemplatesData(
      sourceTemplateListingsDataMap && !_.isEmpty(sourceTemplateListingsDataMap)
        ? Object.values(sourceTemplateListingsDataMap)
        : []
    );
  }, [sourceTemplateListingsDataMap]);

  useEffect(() => {
    dispatch(
      getSourceRecordListings({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [searchSourceId]);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecords"
    ) {
      setItems([...data]);
      // setLoaded(true);
      // setRefresh(false); // - commented 4th nov
      setFetchMoreDataFg(false);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecord"
    ) {
      setItems([...data]);
      dispatch(clearStatus());
      // if (!fetchMoreDataFg) setFrom(0); // commented 4th oct
      setFetchMoreDataFg(false);
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordsSummary"
    ) {
      setItems([...data]);
      dispatch(clearStatus());
      setRefreshSelectedRecord(null);
    }
  }, [status]);

  useEffect(() => {
    // setLoaded(false); // changing 12aug

    // todo: commented 12-jun-24
    // if (!fetchMoreDataFg) {
    //   setFrom(0);
    // }

    dispatch(
      getSearchSourceRecords({
        searchId: searchId,
        searchSourceId: searchSourceId,
        reload: reload,
        clear: refresh ? true : false,
        formData: {
          templateType: templateType,
          // from: fetchMoreDataFg ? from : 0, // commented 12-jun
          searchText: recordsSearchText, // Added: 16Jul24
          from: from,
          size: 25,
          searchSourceRecordsFetchFilter: "DEFAULT",
          searchSourceWidgetFilters: searchSourceWidgetTempFilters,
        },
      })
    );
  }, [reload, refresh, from, templateType, changeView, recordsSearchText]);

  useEffect(() => {
    if (refreshSelectedRecord && !_.isEmpty(refreshSelectedRecord)) {
      dispatch(
        getSearchSourceRecordsSummary({
          searchId: searchId,
          searchSourceId: searchSourceId,
          formData: {
            moduleCode: 1,
            summaryType: refreshSelectedRecord["summaryType"],
            recordIds: refreshSelectedRecord["recordIds"],
            sourceId: sourceId,
            adjObjectUuId: refreshSelectedRecord["adjObjectUuId"],
          },
        })
      );
    }
  }, [refreshSelectedRecord]);

  useEffect(() => {
    dispatch(
      getSourceFields({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
  }, [sourceId, sourceDefId]);

  useEffect(() => {
    dispatch(clearSearchSourceRecordsData());
  }, [searchSourceId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 88px)`,
          bottom: "44px",
          width: "100%",
          gap: theme.spacing(1),
          boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
          // p: theme.spacing(1),
        }}
        ref={ref}
      >
        {displayRecords()}
      </Box>

      {/* <SearchSourceRecordPanelWrapper open={open} handleClose={handleClose} /> */}
      <Drawer
        PaperProps={{
          style: {
            position: "absolute",
            right: tableView ? "0%" : showAdvSearchFilterPanel ? "35%" : "25%",
            top: 48, // 139
            height: `calc(100% - 48px)`,
            width: "820px",
          },
        }}
        open={open}
        onClose={handleClose}
        anchor="right"
        BackdropProps={{ invisible: true }}
      >
        <SearchSourceRecordPanel
          labels={_.get(selectedRecord, "labels")}
          assessmentFg={_.get(selectedRecord, "assessmentFg")}
          numOfActions={_.get(selectedRecord, "numOfActions")}
          numOfNotes={_.get(selectedRecord, "numOfNotes")}
          numOfDistributions={
            _.get(selectedRecord, "numOfWorkflows") +
            _.get(selectedRecord, "numOfContacts")
          }
          handleClose={handleClose}
          sourceFields={sourceFields}
        />
      </Drawer>
      <NtaiDialog
        title="Lookup"
        size="md"
        open={lookupDialogOpen}
        handleDialogClose={() => setLookupDialogOpen(false)}
      >
        {lookupIds && !_.isEmpty(lookupIds) && (
          <SearchSourceRecordLookup lookupIds={lookupIds} />
        )}
      </NtaiDialog>
    </>
  );
}
