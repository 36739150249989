import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceWidgetFieldFilterForm from "../filter/SourceWidgetFieldFilterForm";
import SourceWidgetFieldsForm from "../fields/SourceWidgetFieldsForm";
import SourceWidgetUtil from "../../util/SourceWidgetUtil";
import SourceWidgetFieldColorFormat from "../color-scheme/SourceWidgetFieldColorFormat";
import SourceWidgetTitle from "./SourceWidgetTitle";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import SourceWidgetFormPanel from "./SourceWidgetFormPanel";
import SourceWidgetReorderFields from "../reorder/SourceWidgetReorderFields";
const _ = require("lodash");

export default function SourceWidgetDefaultChartForm({
  widget,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const [widgetFields, setWidgetFields] = useState(
    widget["sourceWidgetFields"]
  );

  const chartCode = widget["widget"]["contentCode"];

  const dimensionFields =
    SourceWidgetUtil.getDimensionWidgetFields(widgetFields);

  const measureFields = SourceWidgetUtil.getMeasureWidgetFields(widgetFields);

  const dimensionFieldOptions =
    dimensionFields && _.isArray(dimensionFields) && dimensionFields.length > 0
      ? NtaiUtils.getSelectOptions(dimensionFields, "uuId", "fieldName")
      : [];

  const measureFieldOptions =
    measureFields && _.isArray(measureFields) && measureFields.length > 0
      ? NtaiUtils.getSelectOptions(measureFields, "uuId", "fieldName")
      : [];

  const revisedMeasureFieldOptions = [
    { value: "0", label: "Count" },
    ...measureFieldOptions,
  ];

  function handleReorderFieldsCallback(fields) {
    if (!_.isEqual(fields, widgetFields)) setWidgetFields([...fields]);
  }

  function onHandleSave() {
    const widgetFormData = getValues("widgetForm");

    const sanitizedFormData = NtaiUtils.sanitizeFormData(widgetFormData);

    let revisedSourceWidgetFields = _.cloneDeep(widget["sourceWidgetFields"]);
    const sanitizedFormFieldsData = sanitizedFormData["sourceWidgetFields"];

    revisedSourceWidgetFields.forEach((f, i) => {
      const sanitizedFormFieldData = NtaiUtils.sanitizeFormData(
        _.filter(sanitizedFormFieldsData, {
          uuId: f["uuId"],
        })[0]
      );

      f["headerName"] = sanitizedFormFieldData["headerName"];
      f["valueFormatterScript"] =
        sanitizedFormFieldData["valueFormatterScript"];

      if (
        sanitizedFormData["nonXYChartSizeFieldUuId"] &&
        sanitizedFormData["nonXYChartSizeFieldUuId"].length > 1
      ) {
        if (sanitizedFormData["nonXYChartSizeFieldUuId"] === f["uuId"])
          f["sizeFg"] = 1;
      } else f["sizeFg"] = null;

      if (SourceWidgetUtil.isWidgetFieldMeasure(f)) {
        f["metricCode"] = sanitizedFormFieldData["metricCode"];
        f["esAggFilterScript"] = sanitizedFormFieldData["esAggFilterScript"];
      }

      if (SourceWidgetUtil.isWidgetFieldDimension(f)) {
        let sourceWidgetFieldFiltersResult = [];
        if (_.isArray(sanitizedFormFieldData["sourceWidgetFieldFilters"])) {
          const sourceWidgetFieldFilters = _.get(
            sanitizedFormFieldData,
            "sourceWidgetFieldFilters"
          );

          if (
            _.isArray(sourceWidgetFieldFilters) &&
            sourceWidgetFieldFilters.length > 0
          ) {
            sourceWidgetFieldFilters.forEach((swf) => {
              if (swf["typeCode"] !== undefined && swf["typeCode"]["value"]) {
                sourceWidgetFieldFiltersResult.push({
                  value: swf["value"],
                  typeCode: swf["typeCode"]["value"],
                });
              }
            });
            f["sourceWidgetFieldFilters"] = sourceWidgetFieldFiltersResult;
          }
        }
      }
    });

    sanitizedFormData["sourceWidgetFields"] = revisedSourceWidgetFields;

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    let widgetCopy = _.cloneDeep(widget);
    reset({ widgetForm: widgetCopy });
  }, [widget]);

  return (
    <SourceWidgetFormPanel
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <NtaiTabs>
        <Box label="TITLE">
          <SourceWidgetTitle />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FIELDS">
          <SourceWidgetFieldsForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="SIZE">
          <NtaiPanel height="100%" subheader="Select field for size">
            {revisedMeasureFieldOptions &&
              revisedMeasureFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={revisedMeasureFieldOptions}
                  name="widgetForm.nonXYChartSizeFieldUuId"
                  label="Size"
                />
              )}
          </NtaiPanel>
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="ORDER">
          <SourceWidgetReorderFields
            data={_.orderBy(
              SourceWidgetUtil.getDimensionWidgetFields(widgetFields),
              ["fieldOrder"],
              ["asc"]
            )}
            widget={widget}
            handleCallback={handleReorderFieldsCallback}
          />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FILTERS">
          <SourceWidgetFieldFilterForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="COLOR">
          <SourceWidgetFieldColorFormat
            handleWidgetSave={handleSave}
            widget={widget}
          />
        </Box>
      </NtaiTabs>
    </SourceWidgetFormPanel>
  );
}
