import React from "react";
import ReportMain from "../ReportMain";

export default function ReportMainRedirect(props) {
  const reportDefinitionId = props.match.params.reportdefinitionid;
  const reportId = props.match.params.id;

  return (
    <ReportMain
      action="edit"
      reportDefinitionId={reportDefinitionId}
      reportId={reportId}
    />
  );
}
