import { useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import React from "react";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useEffect } from "react";

export default function SourceInStoreOcrPageForm({
  handleFormSubmit,
  handleDialogClose,
  formData,
  action,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();

  const onHandleSave = () => {
    const santitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceInStoreOcrPageForm")
    );
    handleFormSubmit(santitizedFormData);
  };

  useEffect(() => {
    reset({ sourceInStoreOcrPageForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiDialogFormPanel
      subheader="Add page specific properties"
      handleSave={onHandleSave}
      handleCancel={handleDialogClose}
    >
      <NtaiTextField
        name="sourceInStoreOcrPageForm.name"
        label="Name"
        placeholder="Name"
      />
      <NtaiTextField
        name="sourceInStoreOcrPageForm.description"
        label="Description"
        placeholder="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
    </NtaiDialogFormPanel>
  );
}
