import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRecordListingFieldColors = createAsyncThunk(
  "sourceRecordListingFieldColors/getSourceRecordListingFieldColors",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceRecordListingId,
        sourceRecordListingFieldId,
      } = values;

      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${sourceRecordListingFieldId}/color-schemes`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceRecordListingFieldColor = createAsyncThunk(
  "sourceRecordListingFieldColors/getSourceRecordListingFieldColor",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceRecordListingId,
        sourceRecordListingFieldId,
        uuId,
      } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${sourceRecordListingFieldId}/color-schemes/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceRecordListingFieldColor = createAsyncThunk(
  "sourceRecordListingFieldColors/createSourceRecordListingFieldColor",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceRecordListingId,
        sourceRecordListingFieldId,
        formData,
      } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${sourceRecordListingFieldId}/color-schemes`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceRecordListingFieldColor = createAsyncThunk(
  "sourceRecordListingFieldColors/updateSourceRecordListingFieldColor",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceRecordListingId,
        sourceRecordListingFieldId,
        uuId,
        formData,
      } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${sourceRecordListingFieldId}/color-schemes/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceRecordListingFieldColor = createAsyncThunk(
  "SourceRecordListingFieldColors/deleteSourceRecordListingFieldColor",
  async (values, { rejectWithValue }) => {
    try {
      const {
        sourceDefId,
        sourceId,
        sourceRecordListingId,
        sourceRecordListingFieldId,
        uuId,
      } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/records/listings/${sourceRecordListingId}/fields/${sourceRecordListingFieldId}/color-schemes/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRecordListingFieldColorsSlice = createSlice({
  name: "sourceRecordListingFieldColors",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRecordListingFieldColors.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourceRecordListingFieldColors",
      };
    },
    [getSourceRecordListingFieldColors.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordListingFieldColors",
        message: action.payload.message,
      };
    },
    [getSourceRecordListingFieldColor.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourceRecordListingFieldColor",
      };
    },
    [getSourceRecordListingFieldColor.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRecordListingFieldColor",
        message: action.payload.message,
      };
    },
    [createSourceRecordListingFieldColor.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceRecordListingFieldColor",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceRecordListingFieldColor.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceRecordListingFieldColor",
        message: action.payload.message,
      };
    },
    [updateSourceRecordListingFieldColor.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceRecordListingFieldColor",
      };
    },
    [updateSourceRecordListingFieldColor.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceRecordListingFieldColor",
        message: action.payload.message,
      };
    },
    [deleteSourceRecordListingFieldColor.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceRecordListingFieldColor",
      };
    },
    [deleteSourceRecordListingFieldColor.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceRecordListingFieldColor",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourceRecordListingFieldColorsSlice.actions;

export default sourceRecordListingFieldColorsSlice.reducer;
