import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import { faCircle1 } from "@fortawesome/pro-solid-svg-icons";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsSliceStatus,
} from "../../store/sourcesSlice";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { useFormContext } from "react-hook-form";
import { SourceWidgetManagerContext } from "./SourceWidgetManager";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useState } from "react";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "../../derivedfield/store/sourceDerivedFieldsSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

export default function SourceWidgetFieldSelection({ resetFieldsSelection }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const [sourceFieldsLoaded, setSourceFieldsLoaded] = useState(false);
  const [sourceDerivedFieldsLoaded, setSourceDerivedFieldsLoaded] =
    useState(false);

  const { selectedFields, setSelectedFields, clearFields, setClearFields } =
    React.useContext(SourceWidgetManagerContext);

  const [localSelectedFields, setLocalSelectedFields] = useState([
    ...selectedFields,
  ]);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const statusSourceFields = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const statusDerivedFields = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  // const sourceFields = Object.values(sourceFieldsDataMap);

  const sourceFields = Object.values(sourceFieldsDataMap).map((f) => {
    return {
      uuId: 1 + "," + f.uuId,
      name: f.name,
      label: f.label,
      type: 1,
      esDataTypeDecode: f.esDataTypeDecode,
      path: f.path,
    };
  });

  const sourceDerivedFields = Object.values(sourceDerivedFieldsDataMap).map(
    (f) => {
      return {
        uuId: 2 + "," + f.uuId,
        name: f.name,
        label: f.label,
        type: 2,
        esDataTypeDecode: f.fieldTypeDecode,
      };
    }
  );

  const allFieldsData = [...sourceFields, ...sourceDerivedFields];

  function handleFieldClick(fieldUuId) {
    let copySelectedFields = _.cloneDeep(localSelectedFields);

    if (copySelectedFields.includes(fieldUuId)) {
      copySelectedFields = copySelectedFields.filter((f) => f !== fieldUuId);
    } else copySelectedFields.push(fieldUuId);

    setLocalSelectedFields([...copySelectedFields]);
  }

  useEffect(() => {
    if (clearFields) {
      setLocalSelectedFields([]);
      setClearFields(false);
    }
  }, [clearFields]);

  useEffect(() => {
    setSelectedFields([...localSelectedFields]);
  }, [localSelectedFields]);

  function generateFields() {
    let result = [];

    if (Array.isArray(allFieldsData) && allFieldsData.length > 0) {
      let listItems = [];
      _.orderBy(allFieldsData, ["label"], ["asc"]).forEach((field, index) => {
        listItems.push(
          <React.Fragment key={`widgetfield-${index}`}>
            <ListItem
              selected={localSelectedFields.includes(field.uuId)}
              onClick={() => handleFieldClick(field.uuId)}
              sx={{
                display: "flex",
                width: "100%",
                pr: 0,
                justifyContent: "space-between",
                whiteSpace: "normal",
                "&:hover": { background: grey[100] },
                gap: theme.spacing(1),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                <NtaiBadge
                  title={
                    _.get(field, "esDataTypeDecode") &&
                    _.get(field, "esDataTypeDecode")
                      .substring(0, 1)
                      .toLowerCase()
                  }
                  fg={grey[700]}
                  bg={grey[100]}
                />

                <ListItemText
                  primary={
                    <div
                      style={{
                        display: "inline-flex",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        width: "100%",
                      }}
                    >
                      <Typography
                        color="inherit"
                        sx={{ fontWeight: 700, wordBreak: "break-word" }}
                        variant="subtitle1"
                      >
                        {field.type === 2
                          ? field["label"] + "*"
                          : field["label"]}
                      </Typography>
                    </div>
                  }
                  secondary={
                    <div
                      style={{
                        display: "inline-flex",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        width: "100%",
                      }}
                    >
                      <Typography
                        color="inherit"
                        sx={{ wordBreak: "break-word" }}
                        variant="subtitle2"
                      >
                        {field["type"] === 1 ? field["path"] : field["name"]}
                      </Typography>
                    </div>
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NtaiSwitchField
                  checked={localSelectedFields.includes(field.uuId)}
                  name={`viewForm.fields.${field.uuId}`}
                />
              </Box>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      });

      result.push(
        <List
          key="widget-list"
          component="nav"
          sx={{ padding: 0, height: "100%", width: "100%" }}
        >
          {listItems}
        </List>
      );
    }

    return result;
  }

  useEffect(() => {
    if (
      statusDerivedFields &&
      statusDerivedFields.result === "success" &&
      statusDerivedFields.method === "getSourceDerivedFields"
    ) {
      setSourceDerivedFieldsLoaded(true);
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [statusDerivedFields]);

  useEffect(() => {
    if (
      statusSourceFields &&
      statusSourceFields.result === "success" &&
      statusSourceFields.method === "getSourceFields"
    ) {
      setSourceFieldsLoaded(true);
      dispatch(clearSourceFieldsSliceStatus());
    }
  }, [statusSourceFields]);

  useEffect(() => {
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceDerivedFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
          px: theme.spacing(2),
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
        >
          <FontAwesomeIcon size="lg" icon={faCircle1} color="secondary" />
          <Typography variant="h6">Select Field</Typography>
        </Box>
        <NtaiButton onClick={() => resetFieldsSelection()} size="small">
          Reset
        </NtaiButton>
      </Box>
      <Box
        sx={{
          height: `calc(100% - 44px)`,
        }}
      >
        {sourceFieldsLoaded && sourceDerivedFieldsLoaded && (
          <Scrollbar>{generateFields()}</Scrollbar>
        )}
        {(!sourceFieldsLoaded || !sourceDerivedFieldsLoaded) && (
          <NtaiCircularProgress size={24} />
        )}
      </Box>
    </>
  );
}
