import { Box } from "@mui/material";
import React from "react";
import NtaiForceDirectedChart1 from "./NtaiForceDirectedChart1";

export default function NtaiTestForceGraph() {
  return (
    <Box>
      <NtaiForceDirectedChart1 />
    </Box>
  );
}
