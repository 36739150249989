import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceRefSources = createAsyncThunk(
  "sourceRefSources/getSourceRefSources",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/refs`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrDeleteSourceRefSource = createAsyncThunk(
  "sourceRefSources/createOrDeleteSourceRefSource",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/refs`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceRefSourcesSlice = createSlice({
  name: "sourceRefSources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceRefSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceRefSources" };
    },
    [getSourceRefSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceRefSources",
        message: action.payload.message,
      };
    },

    [createOrDeleteSourceRefSource.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createOrDeleteSourceRefSource",
      };
    },
    [createOrDeleteSourceRefSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrDeleteSourceRefSource",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceRefSourcesSlice.actions;

export default sourceRefSourcesSlice.reducer;
