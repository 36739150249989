import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { relativeRadiusToValue } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { isArray } from "@amcharts/amcharts4/core";
const _ = require("lodash");

const accessToken =
  "pk.eyJ1Ijoic3lkYmFyIiwiYSI6ImNrcnlkbXJrMDA3anIydW9lZjJxMjA5N2QifQ.5pobKT6pI4ApsgDZdV8DqA";

export default function NtaiGeoCoding2({ data }) {
  const theme = useTheme();
  const [places, setPlaces] = useState([]);
  const [places1, setPlaces1] = useState([]);
  const [loaded, setLoaded] = useState(false);

  console.log("Places = -", places);

  const geocodingClient = mbxGeocoding({
    accessToken: accessToken,
  });

  async function fetchCoordinates(dat) {
    const response = await geocodingClient
      .forwardGeocode(_.pick(dat, ["query", "limit"]))
      .send()
      .then((response) => {
        const match = response.body;
        const coordinates = match.features[0].geometry.coordinates;
        const placeName = match.features[0].place_name;
        const center = match.features[0].center;

        return {
          type: "Feature",
          center: center,
          geometry: {
            type: "Point",
            coordinates: coordinates,
          },
          properties: {
            ...dat,
          },
        };
      });

    const data = await response;

    return { ...data };
  }

  async function fetchMultipleCoordinates(data) {
    const coordinatesPromises = data.map(async (dat) => {
      try {
        return await fetchCoordinates(dat);
      } catch (error) {
        console.error(`Error fetching coordinates for ${dat}:`, error);
      }
    });

    const coordinatesList = await Promise.all(coordinatesPromises);
    setPlaces(coordinatesList);
  }

  useEffect(() => {
    fetchMultipleCoordinates(data);
  }, [data]);

  return (
    <Box sx={{ display: "flex" }}>
      {places &&
        _.isArray(places) &&
        places.length > 0 &&
        places.map((o, i) => (
          <Typography key={`${i}`}>{o.properties.query}</Typography>
        ))}
    </Box>
  );
}
