import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, setActiveId, updateWidget } from "./store/widgetsSlice";
import WidgetForm from "./WidgetForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import History from "@ntai/@history";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function WidgetEdit(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.widgetsSlice.status);
  const dataMap = useSelector((state) => state.core.widgetsSlice.data);
  const activeId = useSelector((state) => state.core.widgetsSlice.activeId);
  const id = props.match.params.id;
  const formData = _.get(dataMap, id);

  function handleSave(formData) {
    dispatch(updateWidget({ uuId: id, formData: formData }));
  }

  useEffect(() => {
    dispatch(setActiveId(id));
  }, [id]);

  useEffect(() => {
    if (status.method === "updateWidget" && status.result === "success") {
      enqueueSnackbar(
        `Widget "${_.get(dataMap[activeId], "name")}" updated successfully.`,
        {
          variant: "success",
        }
      );

      History.push(`/admin/widgets`);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "updateWidget" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
    }
  }, [status]);

  return (
    <NtaiPage
      title={`Widgets / ${_.get(dataMap[id], "name")}`}
      back="/admin/widgets"
    >
      <NtaiForm>
        <WidgetForm action="edit" formData={formData} handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
