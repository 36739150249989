import { faCalendarLinesPen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFieldsPanel from "@ntai/components/micros/NtaiFieldsPanel";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SearchContext } from "../../../SearchEdit";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  updateSearchDateRange,
  getSearchDateRange,
} from "./store/searchDateRangeSlice";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const dateFilterTypeCodeOptions = [
  {
    value: 1,
    label: "Upto search creation date",
  },
  {
    value: 2,
    label: "Always current",
  },
  {
    value: 3,
    label: "Upto user specified date",
  },
  {
    value: 4,
    label: "Between user specified date range",
  },
];
export default function SearchDateRangeForm({ handleSave, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const [dateFilterTypeCode, setDateFilterTypeCode] = useState(1);
  const { getValues, reset, watch } = useFormContext();

  const { searchData } = React.useContext(SearchContext);
  const searchId = _.get(searchData, "uuId");

  const watchDateFilterTypeCode = watch("searchDateRangeForm.typeCode");

  const data = useSelector((state) => state.search.searchDateRangeSlice.data);
  const status = useSelector(
    (state) => state.search.searchDateRangeSlice.status
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("searchDateRangeForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    if (watchDateFilterTypeCode && !_.isEmpty(watchDateFilterTypeCode)) {
      const value = watchDateFilterTypeCode["value"];
      setDateFilterTypeCode(value);
    }

    if (watchDateFilterTypeCode && _.isNumber(watchDateFilterTypeCode))
      setDateFilterTypeCode(watchDateFilterTypeCode);
  }, [watchDateFilterTypeCode]);

  useEffect(() => {
    if (
      status &&
      status.method === "getSearchDateRange" &&
      status.result === "success"
    ) {
      reset({ searchDateRangeForm: data });
      setLoaded(true);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getSearchDateRange(searchId));
  }, []);

  return (
    <React.Fragment>
      {loaded && (
        <NtaiDialogFormPanel
          subheader="Edit date range for the search results. The selected date filter is applied to all the source records included in the search"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiSelectField
            name="searchDateRangeForm.typeCode"
            label="Select date range criteria type"
            options={dateFilterTypeCodeOptions}
          />

          {[3, 4].includes(dateFilterTypeCode) && (
            <NtaiFieldsPanel
              label={
                dateFilterTypeCode === 3
                  ? "Up to user specified date"
                  : "User specified date range"
              }
            >
              <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
                {dateFilterTypeCode === 4 && (
                  <NtaiDateField name="searchDateRangeForm.fromDate" />
                )}
                <NtaiDateField name="searchDateRangeForm.toDate" />
              </Box>
            </NtaiFieldsPanel>
          )}
        </NtaiDialogFormPanel>
      )}
      {!loaded && <NtaiCircularProgress size={24} />}
    </React.Fragment>
  );
}
