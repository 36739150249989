import React from "react";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext, Controller, useForm } from "react-hook-form";
import NtaiInputErrorMessage from "./NtaiInputErrorMessage";

const NtaiMuiTextField = (props) => {
  const { name, type } = props;
  const {
    transform,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl fullWidth>
      <TextField
        inputProps={{
          autoComplete: "off",
        }}
        id={name}
        size="small"
        variant="outlined"
        {...props}
      />
      <NtaiInputErrorMessage errors={errors} name={name} />
    </FormControl>
  );
};

function NtaiTextField(props) {
  const { control } = useFormContext();

  const { name, label, type } = props;

  return (
    <React.Fragment>
      <Controller
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <NtaiMuiTextField
            value={value || ""}
            onChange={(e) =>
              type !== null && type === "number"
                ? onChange(parseInt(e.target.value, 10))
                : onChange(e.target.value)
            }
            inputRef={ref}
            {...props}
            label={
              props.hasOwnProperty("mandatory")
                ? label + " *"
                : props.hasOwnProperty("label")
                ? props.label
                : ""
            }
          />
        )}
        control={control}
        defaultValue=""
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiTextField;
