import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React, { useEffect } from "react";

export default function DatasourceAwsRedshftForm(props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiTextField
          name="datasourceForm.awsRedshift.host"
          label="Host*"
          placeholder="host name"
          rules={{ required: "Host is required" }}
        />
        <NtaiTextField
          type="number"
          name="datasourceForm.awsRedshift.port"
          label="Port*"
          rules={{ required: "Port is required" }}
        />
      </Box>

      <NtaiTextField
        name="datasourceForm.awsRedshift.url"
        label="Url*"
        rules={{ required: "Url is required" }}
      />
      <NtaiTextField
        name="datasourceForm.awsRedshift.userName"
        label="Aws Redshift User*"
        placeholder="awsuser"
        rules={{ required: "User name is required" }}
      />
      <NtaiTextField
        type="password"
        name="datasourceForm.awsRedshift.password"
        label="Aws Redshift password*"
        placeholder="*********"
        rules={{ required: "Password is required" }}
      />
      <NtaiTextField
        name="datasourceForm.awsRedshift.awsIamRole"
        label="Aws IAM Role*"
        placeholderAws
        IAM
        Role
        rules={{ required: "Aws IAM Role is required" }}
      />
      <NtaiTextField
        name="datasourceForm.awsRedshift.databaseName"
        label="Database*"
        placeholder="Database"
        rules={{ required: "Database is required" }}
      />
      <NtaiTextField
        name="datasourceForm.awsRedshift.tempDir"
        label="Temp Dir*"
        placeholder="Temp Dir"
      />
    </Box>
  );
}
