import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCronScheduler from "@ntai/components/cron/NtaiCronScheduler";
const _ = require("lodash");

export default function DataAdapterGeneralForm({
  action,
  formData,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const [localCronValue, setLocalCronValue] = React.useState();
  const [value, setValue] = React.useState();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const targetFormatTypeOptions = codes
    ? _.get(codes, CODELIST_CODES.SRC_SOURCE_FILE_TYPE_CODE)
    : [];

  function onChange(val) {
    setLocalCronValue(val);
  }

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("dataAdapterForm")
    );
    sanitizedFormData["cronExpression"] = localCronValue;
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ dataAdapterForm: action === "edit" ? formData : {} });

    if (
      formData &&
      _.has(formData, "cronExpression") &&
      formData["cronExpression"] &&
      formData["cronExpression"].length > 0
    ) {
      setValue(_.get(formData, "cronExpression"));
      setLocalCronValue(_.get(formData, "cronExpression"));
    } else setValue(null);
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <Box sx={{ flexBasis: "50%", display: "flex", width: "100%" }}>
        <NtaiFormPanel
          width="75%"
          height="100%"
          header="General Information"
          subheader="Enter general details about data adapter such as name, description, inbound/outbound type and python execution mode"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiTextField
            name="dataAdapterForm.name"
            label="Name*"
            rules={{ required: "Name is required" }}
          />
          <NtaiTextField
            name="dataAdapterForm.description"
            label="Description*"
            multiline
            minRows={2}
            maxRows={2}
            rules={{ required: "Description is required" }}
          />

          <NtaiSelectField
            name="dataAdapterForm.ioTypeCode"
            isDisabled={action === "edit" ? true : false}
            options={[
              { value: 1, label: "Inbound" },
              { value: 2, label: "Outbound" },
            ]}
            label="Inbound/Outbound*"
            rules={{ required: "Inbound/Outbound is required" }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <NtaiSelectField
              label="Module/Package*"
              isDisabled={action === "edit" ? true : false}
              name="dataAdapterForm.execTypeCode"
              options={[
                { value: 1, label: "Module" },
                { value: 2, label: "Package" },
              ]}
              rules={{ required: "Module/Package is required" }}
            />

            <NtaiSelectField
              label="Target Format*"
              name="dataAdapterForm.targetFormatCode"
              options={targetFormatTypeOptions}
              rules={{ required: "Target format is required" }}
            />
          </Box>

          <NtaiTextField
            name="dataAdapterForm.mainModuleName"
            label="Main Module*"
            rules={{ required: "Main module name is required" }}
          />

          <NtaiTextField
            name="dataAdapterForm.targetDir"
            label="Output directory*"
            rules={{ required: "Output directory is required" }}
          />
        </NtaiFormPanel>
      </Box>
      <Box sx={{ flexBasis: "50%", display: "flex", width: "100%" }}>
        {action === "edit" && formData["ioTypeCode"] === 1 && (
          <NtaiPanel
            width="75%"
            header="Run Frequency"
            subheader="Specify frequency for the data adapter. Data adapter runs will execute on specified frequency. For pilot source runs, you can set the frequency to run once."
          >
            <NtaiCronScheduler
              value={value}
              onChange={onChange}
              showResultText={true}
            />
            <NtaiSwitchField
              name="dataAdapterForm.runOnceFg"
              label="Run Once?"
            />
          </NtaiPanel>
        )}
        {action === "edit" && formData["ioTypeCode"] === 2 && (
          <NtaiPanel
            width="75%"
            header="Payload Options"
            subheader="Select record payload options for the outbound adapter. The options selected will be included while invoking the outbound adapter main method"
          >
            <NtaiSwitchField
              name="dataAdapterForm.inclRecordFg"
              label="Include Record Data?"
            />
            <NtaiSwitchField
              name="dataAdapterForm.inclRecordAssessmentFg"
              label="Include Record Assessment Data?"
            />
            <NtaiSwitchField
              name="dataAdapterForm.inclRecordActionFg"
              label="Include Record Actions Data?"
            />
            <NtaiSwitchField
              name="dataAdapterForm.inclRecordNoteFg"
              label="Include Record Notes Data?"
            />
          </NtaiPanel>
        )}
      </Box>
    </Box>
  );
}
