import { Box, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import ToolsAssessmentImportForm1 from "./ToolsAssessmentImportForm1";
import History from "@ntai/@history";
import ToolsAssessmentImportForm2 from "./ToolsAssessmentImportForm2";
import { useDispatch, useSelector } from "react-redux";
import {
  submitToolsAssessmentImport,
  validateToolsAssessmentImport,
} from "./store/toolsAssessmentImportSlice";
import ToolsAssessmentImportForm3 from "./ToolsAssessmentImportForm3";
import ToolsAssessmentImportFinal from "./ToolsAssessmentImportFinal";

export const ToolsAssessmentImportContext = React.createContext();

export default function ToolsAssessmentImportCreate() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [adjFormData, setAdjFormData] = useState();
  const [localFormData, setLocalFormData] = useState();
  const [submitFormData, setSubmitFormData] = useState();

  const status = useSelector(
    (state) => state.tools.toolsAssessmentImportSlice.status
  );

  function handleCancel() {
    History.push("/tools/assessment-import");
  }

  function handleContinue(s) {
    setStep(s);
  }

  function handleBack(s) {
    setStep(s);
  }

  function handleValidate(formData) {
    setStep(3);
    dispatch(validateToolsAssessmentImport(formData));
  }

  function handleFinalSubmit(formData) {
    setStep(4);
    dispatch(submitToolsAssessmentImport(formData));
  }

  useEffect(() => {
    if (
      status &&
      status.method === "validateToolsAssessmentImport" &&
      status.result === "success"
    ) {
    }

    if (
      status &&
      status.method === "submitToolsAssessmentImport" &&
      status.result === "success"
    ) {
    }
  }, [status]);

  return (
    <ToolsAssessmentImportContext.Provider
      value={{
        step,
        setStep,
        adjFormData,
        setAdjFormData,
        localFormData,
        setLocalFormData,
        submitFormData,
        setSubmitFormData,
      }}
    >
      <NtaiPage title={`Tools / Assessment Import`}>
        {step === 1 && (
          <NtaiForm>
            <ToolsAssessmentImportForm1
              handleContinue={handleContinue}
              handleCancel={handleCancel}
            />
          </NtaiForm>
        )}

        {step === 2 && (
          <NtaiForm>
            <ToolsAssessmentImportForm2
              handleValidate={handleValidate}
              handleBack={handleBack}
              handleCancel={handleCancel}
            />
          </NtaiForm>
        )}
        {step === 3 && (
          <NtaiForm>
            <ToolsAssessmentImportForm3
              handleFinalSubmit={handleFinalSubmit}
              handleBack={handleBack}
              handleCancel={handleCancel}
            />
          </NtaiForm>
        )}
        {step === 4 && (
          <ToolsAssessmentImportFinal handleCancel={handleCancel} />
        )}
      </NtaiPage>
    </ToolsAssessmentImportContext.Provider>
  );
}
