import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createSourceUserWidget,
} from "./store/sourceUserWidgetsSlice";
import SourceUserWidgetForm from "./SourceUserWidgetForm";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import history from "@ntai/@history";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";

export default function SourceUserWidgetCreate() {
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const status = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.status
  );
  const activeId = useSelector(
    (state) => state.sources.sourceUserWidgetsSlice.activeId
  );

  function handleSave(formData) {
    dispatch(
      createSourceUserWidget({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status.method === "createSourceUserWidget" &&
      status.result === "success"
    ) {
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`
      );
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage
      title="Create User Widget"
      back={`/sourcedefs/${sourceDefId}/sources/${sourceId}/user-widgets`}
    >
      <NtaiForm>
        <SourceUserWidgetForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
