import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
import { SRC_SOURCE_RECORD_SUMMARY } from "app/main/constants/NtaiCodelistConstants";
const _ = require("lodash");

// export const getSearchSourceRecords = createAsyncThunk(
//   "searchSourceRecords/getSearchSourceRecords",
//   async (values, { rejectWithValue }) => {
//     try {
//       const { searchId, searchSourceId, params, clear, reload } = values;
//       const response = await server.get(
//         `/search/${searchId}/sources/${searchSourceId}/records`,
//         {
//           params: params ? params : null,
//         }
//       );
//       return {
//         from: params.from,
//         reload: reload,
//         clear: clear,
//         data: response.data,
//       };
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const getSearchSourceRecords = createAsyncThunk(
  "searchSourceRecords/getSearchSourceRecords",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData, clear, reload } = values;
      const response = await server.put(
        `/search/${searchId}/sources/${searchSourceId}/records`,
        formData
      );
      return {
        from: formData["from"],
        reload: reload,
        clear: clear,
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordsForTable = createAsyncThunk(
  "searchSourceRecords/getSearchSourceRecordsForTable",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData, clear, reload } = values;
      const response = await server.put(
        `/search/${searchId}/sources/${searchSourceId}/records`,
        formData
      );
      return {
        from: formData["from"],
        reload: reload,
        clear: clear,
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecord = createAsyncThunk(
  "searchSourceRecords/getSearchSourceRecord",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, recordId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${recordId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordDetails = createAsyncThunk(
  "searchSourceRecords/getSearchSourceRecordDetails",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, recordId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${recordId}/details`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecord = createAsyncThunk(
  "searchSourceRecords/createSearchSourceRecord",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, recordId } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/records/${recordId}`,
        values
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecord = createAsyncThunk(
  "searchSourceRecords/updateSearchSourceRecord",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, recordId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/records/${recordId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordsSummary = createAsyncThunk(
  "searchSourceRecords/getSearchSourceRecordsSummary",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, formData } = values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/records-summary`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordsSlice = createSlice({
  name: "searchSourceRecords",
  initialState: {
    data: {},
    tableData: {},
    selectedRecord: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearSearchSourceRecordsData: (state, action) => {
      state.data = {};
    },
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
    clearSearchSourceSelectedRecord: (state, action) => {
      state.selectedRecord = {};
    },
  },
  extraReducers: {
    [getSearchSourceRecords.fulfilled]: (state, action) => {
      if (action.payload.from === 0)
        state.data = { ..._.mapKeys(action.payload.data, "recordId") };
      else
        state.data = {
          ...state.data,
          ..._.mapKeys(action.payload.data, "recordId"),
        };
      state.status = { result: "success", method: "getSearchSourceRecords" };
    },
    [getSearchSourceRecords.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecords",
        message: action.payload.message,
      };
    },

    [getSearchSourceRecords.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSearchSourceRecords",
      };
    },

    [getSearchSourceRecordsForTable.fulfilled]: (state, action) => {
      state.tableData = { ..._.mapKeys(action.payload.data, "recordId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordsForTable",
      };
    },

    [getSearchSourceRecordsForTable.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordsForTable",
        message: action.payload.message,
      };
    },

    [getSearchSourceRecordsForTable.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "getSearchSourceRecordsForTable",
      };
    },

    [getSearchSourceRecord.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.recordId]: action.payload,
      };
      state.status = { result: "success", method: "getSearchSourceRecord" };
    },
    [getSearchSourceRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecord",
        message: action.payload.message,
      };
    },

    [getSearchSourceRecordDetails.fulfilled]: (state, action) => {
      state.selectedRecord = action.payload;

      state.status = {
        result: "success",
        method: "getSearchSourceRecordDetails",
      };
    },
    [getSearchSourceRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordDetails",
        message: action.payload.message,
      };
    },

    [createSearchSourceRecord.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.recordId]: action.payload,
      };
      state.status = { result: "success", method: "createSearchSourceRecord" };
    },
    [createSearchSourceRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecord",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecord.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.recordId]: action.payload,
      };
      state.status = { result: "success", method: "updateSearchSourceRecord" };
    },
    [updateSearchSourceRecord.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecord",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordsSummary.fulfilled]: (state, action) => {
      _.forEach(action.payload, function (o) {
        const searchSourceRecords = _.filter(Object.values(state.data), {
          recordId: o["recordId"],
        });
        if (searchSourceRecords.length > 0) {
          const searchSourceRecord = searchSourceRecords[0];
          switch (o["summaryType"]) {
            case SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_ADJS:
              searchSourceRecord["assessmentFg"] =
                o["count"] > 0 ? true : false;
              break;

            case SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_DOCS:
              searchSourceRecord["numOfDocuments"] =
                o["count"] > 0 ? true : false;
              break;

            case SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_ACTIONS:
              searchSourceRecord["numOfActions"] = o["count"];
              break;

            case SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_NOTES:
              searchSourceRecord["numOfNotes"] = o["count"];
              break;

            case SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_DIST_CONTACTS:
              searchSourceRecord["numOfContacts"] = o["count"];
              break;

            case SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_DIST_WF:
              searchSourceRecord["numOfWorkflows"] = o["count"];
              break;

            default:
              break;
          }

          state.data = {
            ...state.data,
            [o["recordId"]]: searchSourceRecord,
          };
        }
      });

      state.status = {
        result: "success",
        method: "getSearchSourceRecordsSummary",
      };
    },
    [getSearchSourceRecordsSummary.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordsSummary",
        message: action.payload.message,
      };
    },
  },
});

export const {
  clearStatus,
  setActiveId,
  clearSearchSourceRecordsData,
  clearSearchSourceSelectedRecord,
} = searchSourceRecordsSlice.actions;

export default searchSourceRecordsSlice.reducer;
