import React, { useEffect, useState } from "react";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import mapboxgl from "mapbox-gl";

import { Box, Typography, useTheme } from "@mui/material";
const _ = require("lodash");

const accessToken =
  "pk.eyJ1Ijoic3lkYmFyIiwiYSI6ImNrcnlkbXJrMDA3anIydW9lZjJxMjA5N2QifQ.5pobKT6pI4ApsgDZdV8DqA";

export default function NtaiGeoCoding({ data }) {
  const theme = useTheme();
  const [places, setPlaces] = useState([]);
  const [places1, setPlaces1] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const mapboxGeocodingUrl =
    "https://api.mapbox.com/geocoding/v5/mapbox.places/";
  const locationNames = ["NEw York", "Delhi", "Sri Lanka"]; // Array of location names

  async function fetchCoordinates(locationName) {
    const response = await fetch(
      `${mapboxGeocodingUrl}${locationName}.json?access_token=${accessToken}`
    );
    const data = await response.json();
    const coordinates = data.features[0].center;
    return {
      locationName,
      latitude: coordinates[1],
      longitude: coordinates[0],
    };
  }

  async function fetchMultipleCoordinates() {
    const coordinatesPromises = locationNames.map(async (locationName) => {
      try {
        return await fetchCoordinates(locationName);
      } catch (error) {
        console.error(`Error fetching coordinates for ${locationName}:`, error);
      }
    });

    const coordinatesList = await Promise.all(coordinatesPromises);
    console.log("Coordinates for multiple locations:", coordinatesList);
  }

  useEffect(() => {
    fetchMultipleCoordinates();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {loaded &&
        places.map((o, i) => <Typography key={`${i}`}>{o}</Typography>)}
    </Box>
  );
}
