import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import history from "@ntai/@history";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import AwsClusterTemplateSourceList from "./source/AwsClusterTemplateSourceList";
const _ = require("lodash");

const awsEmrEc2InstanceOptions = [
  { value: "c5.large", label: "c5.large (4c-8g)" },
  { value: "c5.large", label: "c5.large (4c-8g)" },
  { value: "c5.large", label: "c5.large (4c-8g)" },
  { value: "c5.large", label: "c5.large (4c-8g)" },
];

export default function AwsClusterTemplateForm({
  action,
  formData,
  handleSave,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();

  //   const codes = useSelector((state) => state.core.codelistsSlice.codes);
  //   const awsClusterTemplateCategoryOptions = _.get(
  //     codes,
  //     CODELIST_CODES.COR_META_OBJECT_CATEGORY_CODE
  //   );

  //   const awsClusterTemplateOptions = NtaiUtils.getSelectOptions(
  //     filteredAwsClusterTemplateData,
  //     "uuId",
  //     "name"
  //   );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("awsClusterTemplateForm")
    );

    handleSave(sanitizedFormData);
  }

  function handleCancel() {
    history.push(`/aws-cluster-templates`);
  }

  useEffect(() => {
    reset({ awsClusterTemplateForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="100%"
          header="General Description"
          subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",
                gap: theme.spacing(2),
              }}
            >
              <NtaiTextField
                name="awsClusterTemplateForm.name"
                label="Name*"
                rules={{ required: "Name is required" }}
              />

              <NtaiSelectField
                name="awsClusterTemplateForm.typeCode"
                options={[
                  { value: 1, label: "Source" },
                  { value: 2, label: "Model" },
                  { value: 3, label: "Thesaurus" },
                ]}
                label="Type*"
                rules={{ required: "Type is required" }}
              />

              <NtaiTextField
                name="awsClusterTemplateForm.description"
                multiline
                minRows={2}
                maxRows={2}
                placeholder="Description"
                label="Description"
              />

              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                <NtaiTextField
                  name="awsClusterTemplateForm.autoTerminatePolicy"
                  label="Auto Terminate Interval (Mins)"
                  placeholder={60}
                  defaultValue={60}
                  type="number"
                />
                <NtaiTextField
                  name="awsClusterTemplateForm.bootstrapFileUrl"
                  label="Bootstrap File Url"
                  placeholder="Bootstrap File Url"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                <NtaiTextField
                  name="awsClusterTemplateForm.awsMasterInstanceType"
                  defaultValue="c5.xlarge"
                  label="Master Instance Type"
                />
                <NtaiTextField
                  name="awsClusterTemplateForm.awsWorkerInstanceType"
                  defaultValue="c5.xlarge"
                  label="Worker Instance Type"
                />
                <NtaiTextField
                  name="awsClusterTemplateForm.awsInstanceCount"
                  label="Instance Count"
                  placeholder="2"
                  type="number"
                  defaultValue={3}
                />

                <NtaiTextField
                  name="awsClusterTemplateForm.awsInstanceEbsSize"
                  label="Instance EBS Size (GB)"
                  placeholder="200"
                  type="number"
                  defaultValue={32}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                <NtaiTextField
                  name="awsClusterTemplateForm.sparkDriverCores"
                  label="# Driver Cores"
                  placeholder="4"
                  type="number"
                />
                <NtaiTextField
                  name="awsClusterTemplateForm.sparkExecutorCores"
                  label="# Executor Cores"
                  placeholder="4"
                  type="number"
                />
                <NtaiTextField
                  name="awsClusterTemplateForm.sparkNumExecutors"
                  label="# Executors"
                  placeholder="4"
                  type="number"
                />
                <NtaiTextField
                  name="awsClusterTemplateForm.sparkDriverMemoryGb"
                  label="Driver Memory (gb)"
                  placeholder="2"
                  type="number"
                />
                <NtaiTextField
                  name="awsClusterTemplateForm.sparkExecutorMemoryGb"
                  label="Executor Memory (gb)"
                  placeholder="2"
                  type="number"
                />
              </Box>

              {/* <NtaiSwitchField
                name="awsClusterTemplateForm.enableCreationFromSourceFg"
                label="Enable cluster creation from source pipeline?"
              /> */}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                height: "100%",
                flexBasis: "50%",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <NtaiTextField
                name="awsClusterTemplateForm.awsCfTemplate"
                multiline
                defaultValue="None"
                minRows={8}
                maxRows={8}
                placeholder="AWS Cloud Formation Template"
                label="AWS Cloud Formation Template"
              />
              <NtaiTextField
                name="awsClusterTemplateForm.bootstrapFileText"
                multiline
                minRows={8}
                maxRows={8}
                placeholder="Bootstrap File Content"
                label="Bootstrap file content"
                helperText="Only considerd when bootstrap url is not specified"
              />
            </Box>
          </Box>
        </NtaiFormPanel>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%" }}
        disabled={
          action === "edit" && formData["typeCode"] === 1 ? false : true
        }
        label="SOURCES"
      >
        <AwsClusterTemplateSourceList />
      </Box>
    </NtaiTabs>
  );
}
