import { useTheme } from "@mui/material";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import "./styles.css";

export default function NtaiColorPicker({ onChange }) {
  const theme = useTheme();
  const [color, setColor] = useState("#aabbcc");

  return <HexColorPicker color={color} onChange={onChange} />;
}
