import {
  faArrowLeft,
  faArrowRight,
  faBackward,
} from "@fortawesome/pro-regular-svg-icons";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Badge, Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "./hideScrollbar.css";

const getItems = () =>
  Array(10)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

function NtaiHorizontalScrollingItems(props) {
  const { data } = props;
  const theme = useTheme();
  const [items, setItems] = React.useState(getItems());
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  return (
    <Box
      sx={{
        width: "340px",
      }}
    >
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={onWheel}
      >
        {items.map(({ id }) => (
          <Card
            itemId={id} // NOTE: itemId is required for track items
            title={id}
            key={id}
            onClick={handleClick(id)}
            selected={isItemSelected(id)}
          />
        ))}
      </ScrollMenu>
    </Box>
  );
}

function Arrows() {
  return (
    <div className="arrows-container">
      <div className="content">Additional content</div>

      <div className="arrows">
        <LeftArrow />
        <RightArrow />
      </div>
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <IconButton
        size="small"
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </IconButton>
    </Box>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton
        size="small"
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </IconButton>
    </Box>
  );
}

function Card({ onClick, selected, title, itemId }) {
  const visibility = React.useContext(VisibilityContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        p: "4px",
        mx: "8px",
        border: theme.general.border1,
        borderRadius: theme.general.borderRadius,
        boxShadow: theme.colors.shadows.cardSm,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        "&:hover": {
          cursor: "pointer",
          boxShadow: theme.colors.shadows.cardMd,
          background: theme.colors.secondary.lighter,
          fontWeight: "700",
          color: "white",
        },
      }}
      onClick={() => onClick(visibility)}
      tabIndex={0}
    >
      <Typography variant="caption" fontWeight="500">
        Clinical Trials
      </Typography>

      {/* <Box sx={{ border: "1px solid #eee", m: "8px" }}>
        <div>{title}</div>
        <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        <div>selected: {JSON.stringify(!!selected)}</div>
      </Box> */}
    </Box>
  );
}

export default NtaiHorizontalScrollingItems;
