import React from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
// import classes from "./NtaiResizableGrid.module.css";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { Resizable } from "react-resizable";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";

const ReactGridLayout = WidthProvider(RGL);

export default class NtaiResizableGridRecord extends React.PureComponent {
  static defaultProps = {
    // className: "layout",
    // className: "flex w-full h-full",
    // items: 20,
    rowHeight: 100,
    // draggableHandle: ".header",
    onLayoutChange: function () {
      console.log("Layout changed..");
    },
    cols: 6,
  };

  constructor(props) {
    super(props);

    let layout = this.generateLayout();
    this.state = { layout };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      // do something here //TODO
    }
  }

  generateDOM() {
    return this.props.items.map(
      (item, i) => item
      // <div className="flex w-full h-full">{item}</div>
    );
  }

  generateLayout() {
    const p = this.props;
    return _.map(new Array(this.props.items.length), function (item, i) {
      const y = _.result(p, "y") || Math.ceil(Math.random() * 2) + 1;
      const coords = {
        x: (i * 2) % 10,
        y: Math.floor(i / 6) * y,
        w: 6,
        h: 3,
        i: i.toString(),
        minW: 6,
        minH: 2,
      };
      console.log("Item coords: ", coords);
      return coords;
    });
  }

  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
  }

  render() {
    return (
      <Box sx={{ width: "100%" }}>
        <ReactGridLayout
          autosize={true}
          layout={this.state.layout}
          draggableHandle=".widget-header-wrapper"
          onLayoutChange={this.onLayoutChange}
          {...this.props}
        >
          {this.generateDOM()}
        </ReactGridLayout>
      </Box>
    );
  }
}
