import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Scrollbar from "@ntai/components/Scrollbar";
import { faCircle1 } from "@fortawesome/pro-solid-svg-icons";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiButton from "@ntai/components/buttons/NtaiButton";
import { useDispatch, useSelector } from "react-redux";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { useState } from "react";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/store/sourcesSlice";
import { SearchSourceWidgetManagerContext } from "./SearchSourceWidgetManager";
import { SearchSourceContext } from "../../../SearchSource";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
const _ = require("lodash");

export default function SearchSourceWidgetManagerFieldSelection({
  resetFieldsSelection,
}) {
  const theme = useTheme();

  const { selectedFields, setSelectedFields, clearFields, setClearFields } =
    React.useContext(SearchSourceWidgetManagerContext);

  const [localSelectedFields, setLocalSelectedFields] = useState([
    ...selectedFields,
  ]);

  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  const { searchSourceData } = React.useContext(SearchSourceContext);

  const sourceDefId = _.get(searchSourceData, "searchSourceDefinition.uuId");
  const sourceId = _.get(searchSourceData, "searchSourceDefinition.sourceUuId");

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFields = Object.values(sourceFieldsDataMap).map((f) => {
    return {
      uuId: 1 + "," + f.uuId,
      name: f.name,
      label: f.label,
      type: 1,
      esDataTypeDecode: f.esDataTypeDecode,
      path: f.path,
    };
  });

  const sourceDerivedFields = Object.values(sourceDerivedFieldsDataMap).map(
    (f) => {
      return {
        uuId: 2 + "," + f.uuId,
        name: f.name,
        label: f.label,
        type: 2,
        esDataTypeDecode: f.fieldTypeDecode,
      };
    }
  );

  const allFieldsData = [...sourceFields, ...sourceDerivedFields];

  // console.log("Source fields: ", sourceFields);

  function handleFieldClick(fieldUuId) {
    let copySelectedFields = _.cloneDeep(localSelectedFields);

    if (copySelectedFields.includes(fieldUuId)) {
      copySelectedFields = copySelectedFields.filter((f) => f !== fieldUuId);
    } else copySelectedFields.push(fieldUuId);

    setLocalSelectedFields([...copySelectedFields]);
  }

  useEffect(() => {
    if (clearFields) {
      setLocalSelectedFields([]);
      setClearFields(false);
    }
  }, [clearFields]);

  useEffect(() => {
    setSelectedFields([...localSelectedFields]);
  }, [localSelectedFields]);

  function generateFields() {
    let result = [];

    if (Array.isArray(allFieldsData) && allFieldsData.length > 0) {
      let listItems = [];
      _.orderBy(allFieldsData, ["label"], ["asc"]).forEach((field, index) => {
        listItems.push(
          <React.Fragment key={`widgetfield-${index}`}>
            <ListItem
              selected={localSelectedFields.includes(field.uuId)}
              onClick={() => handleFieldClick(field.uuId)}
              sx={{
                display: "flex",
                gap: theme.spacing(1),
                justifyContent: "space-between",
                whiteSpace: "normal",
                "&:hover": { background: grey[100] },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(2),
                }}
              >
                <Chip
                  size="small"
                  label={
                    _.get(field, "esDataTypeDecode") &&
                    _.get(field, "esDataTypeDecode")
                      .substring(0, 1)
                      .toLowerCase()
                  }
                  sx={{ color: grey[700], background: grey[100] }}
                />
                <ListItemText
                  primary={
                    <Typography
                      color="inherit"
                      sx={{ wordBreak: "break-word", fontWeight: 700 }}
                      variant="subtitle1"
                    >
                      {field.type === 2 ? field["label"] + "*" : field["label"]}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      color="default"
                      sx={{ wordBreak: "break-word", fontWeight: 500 }}
                      variant="subtitle2"
                    >
                      {field["type"] === 1 ? field["path"] : field["name"]}
                    </Typography>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <NtaiSwitchField
                  checked={localSelectedFields.includes(field.uuId)}
                  name={`viewForm.fields.${field.uuId}`}
                />
              </Box>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      });

      result.push(
        <List
          key="widget-list"
          component="nav"
          sx={{ padding: 0, height: "100%", width: "100%" }}
        >
          {listItems}
        </List>
      );
    }

    return result;
  }

  return (
    <>
      <Box
        sx={{
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderBottom: theme.general.border1,
          px: theme.spacing(2),
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", gap: theme.spacing(1), alignItems: "center" }}
        >
          <FontAwesomeIcon size="lg" icon={faCircle1} color="secondary" />
          <Typography variant="h6">Select Field</Typography>
        </Box>
        <NtaiButton onClick={() => resetFieldsSelection()} size="small">
          Reset
        </NtaiButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 44px)`,
          width: "100%",
        }}
      >
        <Scrollbar>{generateFields()}</Scrollbar>
      </Box>
    </>
  );
}
