import NtaiFormLabel from "@ntai/components/inputs/NtaiFormLabel";
import { OBJECT_FORM_UI_FIELD_TYPE_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

export default function NtaiObjectFormReadOnlyField(props) {
  const dispatch = useDispatch();
  const { formName, fieldObj } = props;
  const fieldUiCode = _.get(fieldObj, "fieldUiCode");
  const codelistCode = fieldObj["fieldCodelistCode"];
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const options =
    [
      OBJECT_FORM_UI_FIELD_TYPE_CODES.DROP_DOWN,
      OBJECT_FORM_UI_FIELD_TYPE_CODES.MULTI_SELECT,
      OBJECT_FORM_UI_FIELD_TYPE_CODES.RADIO_GROUP,
      OBJECT_FORM_UI_FIELD_TYPE_CODES.CHECKBOX_GROUP,
    ].includes(fieldUiCode) &&
    codelistData &&
    _.has(codelistData, codelistCode)
      ? _.get(codelistData, codelistCode)
      : [];

  React.useEffect(() => {
    if (
      [
        OBJECT_FORM_UI_FIELD_TYPE_CODES.DROP_DOWN,
        OBJECT_FORM_UI_FIELD_TYPE_CODES.MULTI_SELECT,
        OBJECT_FORM_UI_FIELD_TYPE_CODES.RADIO_GROUP,
        OBJECT_FORM_UI_FIELD_TYPE_CODES.CHECKBOX_GROUP,
      ].includes(fieldUiCode)
    )
      dispatch(getCodes(codelistCode));
  }, [fieldObj]);

  return (
    <NtaiFormLabel
      name={`${formName}.${_.get(fieldObj, "fieldName")}`}
      label={_.get(fieldObj, "fieldLabel")}
      options={options}
    />
  );
}
