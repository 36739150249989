import { Box, useTheme } from "@mui/material";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import DashboardWidgetFieldList from "./fields/DashboardWidgetFieldList";
const _ = require("lodash");

export default function DashboardWidgetForm({
  action,
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const { getValues, reset } = useFormContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const queryTypeOptions = _.get(
    codelistData,
    CODELIST_CODES.COM_DASHBOARD_WIDGET_QUERY_TYPE_CODE
  );

  const displayTypeOptions = _.get(
    codelistData,
    CODELIST_CODES.COM_DASHBOARD_WIDGET_DISPLAY_TYPE_CODE
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("dashboardWidgetForm")
    );

    handleFormSubmit({ updateTypeFg: 1, ...sanitizedFormData });
  }

  useEffect(() => {
    reset({ dashboardWidgetForm: action === "edit" ? formData : {} });
  }, []);

  return (
    <NtaiTabs>
      <Box
        sx={{
          paddingTop: theme.spacing(0),
          display: "flex",
          justifyContent: "start",
        }}
        label="GENERAL"
      >
        <NtaiFormPanel
          width="520px"
          header="General Description"
          subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
              }}
            >
              <NtaiTextField
                name="dashboardWidgetForm.name"
                label="Name*"
                rules={{ required: "Name is required" }}
              />
              <NtaiTextField
                name="dashboardWidgetForm.label"
                label="Label*"
                rules={{ required: "Label is required" }}
              />
              <NtaiSwitchField
                name="dashboardWidgetForm.activeFg"
                label="Active?"
              />
            </Box>

            <NtaiTextField
              name="dashboardWidgetForm.description"
              label="Description"
              placeholder="Description"
              multiline
              minRows={2}
              maxRows={2}
            />

            <NtaiSelectField
              name="dashboardWidgetForm.queryTypeCode"
              label="Query Mode*"
              options={queryTypeOptions}
              rules={{ required: "Query mode is required" }}
            />

            <NtaiSelectField
              name="dashboardWidgetForm.displayTypeCode"
              label="Display Type*"
              options={displayTypeOptions}
              rules={{ required: "Display type is required" }}
            />

            <NtaiTextField
              name="dashboardWidgetForm.query"
              label="Query*"
              placeholder="Query"
              multiline
              minRows={3}
              maxRows={3}
              rules={{ required: "Query is required" }}
            />
          </Box>
        </NtaiFormPanel>
      </Box>

      <Box
        disabled={action === "add" ? true : false}
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
        label="FIELDS"
      >
        <DashboardWidgetFieldList />
      </Box>
      <Box
        disabled={action === "add" ? true : false}
        sx={{ display: "flex", height: "100%" }}
        label="PARAMS"
      ></Box>
    </NtaiTabs>
  );
}
