import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getThesaurusLoadList = createAsyncThunk(
  "thesaurusLoad/getThesaurusLoadList",
  async (thesaurusId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/load`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getThesaurusLoad = createAsyncThunk(
  "thesaurusLoad/getThesaurusLoad",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, uuId } = values;
      const response = await server.get(
        `/library/thesauruses/${thesaurusId}/load/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createThesaurusLoad = createAsyncThunk(
  "thesaurusLoad/createThesaurusLoad",
  async (values, { rejectWithValue }) => {
    try {
      const { thesaurusId, formData } = values;
      const response = await server.post(
        `/library/thesauruses/${thesaurusId}/load`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const thesaurusLoadSlice = createSlice({
  name: "thesaurusLoad",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getThesaurusLoadList.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getThesaurusLoadList" };
    },
    [getThesaurusLoadList.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusLoadList",
        mesesage: action.payload.mesesage,
      };
    },
    [getThesaurusLoad.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "getThesaurusLoad" };
    },
    [getThesaurusLoad.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getThesaurusLoad",
        mesesage: action.payload,
      };
    },
    [createThesaurusLoad.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createThesaurusLoad" };
      state.activeId = action.payload.uuId;
    },
    [createThesaurusLoad.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createThesaurusLoad",
        mesesage: action.payload.mesesage,
      };
    },
  },
});

export const { clearStatus, setActiveId } = thesaurusLoadSlice.actions;

export default thesaurusLoadSlice.reducer;
