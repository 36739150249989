import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceReportTemplates = createAsyncThunk(
  "sourceReportTemplates/getSourceReportTemplates",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/report-templates`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourceReportTemplate = createAsyncThunk(
  "sourceReportTemplates/getSourceReportTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/report-templates/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceReportTemplate = createAsyncThunk(
  "sourceReportTemplates/createSourceReportTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/report-templates`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceReportTemplate = createAsyncThunk(
  "sourceReportTemplates/updateSourceReportTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/report-templates/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSourceReportTemplateFields = createAsyncThunk(
  "sourceReportTemplates/updateSourceReportTemplateFields",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId, formData } = values;
      const response = await server.patch(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/report-templates/${uuId}/fields`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceReportTemplate = createAsyncThunk(
  "sourceReportTemplates/deleteSourceReportTemplate",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/report-templates/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceReportTemplatesSlice = createSlice({
  name: "sourceReportTemplates",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceReportTemplates.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceReportTemplates" };
    },
    [getSourceReportTemplates.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceReportTemplates",
        message: action.payload.message,
      };
    },
    [getSourceReportTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSourceReportTemplate" };
    },
    [getSourceReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceReportTemplate",
        message: action.payload.message,
      };
    },
    [createSourceReportTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceReportTemplate",
      };
      state.activeId = action.payload.uuId;
    },
    [createSourceReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceReportTemplate",
        message: action.payload.message,
      };
    },
    [updateSourceReportTemplate.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceReportTemplate",
      };
    },
    [updateSourceReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceReportTemplate",
        message: action.payload.message,
      };
    },

    [updateSourceReportTemplateFields.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSourceReportTemplateFields",
      };
    },
    [updateSourceReportTemplateFields.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSourceReportTemplateFields",
        message: action.payload.message,
      };
    },

    [deleteSourceReportTemplate.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceReportTemplate",
      };
    },
    [deleteSourceReportTemplate.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceReportTemplate",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceReportTemplatesSlice.actions;

export default sourceReportTemplatesSlice.reducer;
