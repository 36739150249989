import React, { useEffect, useState } from "react";
import { SourceDatasetManagerContext } from "../../../SourceDatasetManager";
import NtaiReactDataTable from "@ntai/components/data-table/NtaiReactDataTable";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NtaiErrorMessage from "@ntai/components/errors/NtaiErrorMessage";
import {
  clearStatus as clearFileStatus,
  getSourcePipelineInStoreFilePreview,
} from "../bottom-drawer/store/sourcePipelineInStoreFilesSlice";
import { useSnackbar } from "notistack";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { getGridDefaultColumnTypes } from "@mui/x-data-grid";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { getDataAdapter } from "app/main/pages/core/adapter/store/dataAdaptersSlice";
import {
  getSourcePipelineInStoreDatasetPreview,
  clearStatus as clearDatasetStatus,
} from "../bottom-drawer/store/sourcePipelineInStoreDatasetsSlice";
const _ = require("lodash");

const columns = [
  {
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: "Year",
    selector: (row) => row.year,
    sortable: true,
  },
];

const data = [
  {
    id: 1,
    title: "Beetlejuice",
    year: "1988",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
  {
    id: 3,
    title: "Ghostbusters",
    year: "1984",
  },
  {
    id: 4,
    title: "Ghostbusters",
    year: "1984",
  },
];

function getColumns(resultSet) {
  const row0 = resultSet[0];
  const columns = [];
  Object.keys(row0).forEach((c, i) => {
    columns.push({
      id: c,
      wrap: true,
      grow: true,
      name: c,
      selector: (row) => row[c],
      sortable: true,
    });
  });

  return columns;
}

function getRowData(rowData) {
  const result = [];
  rowData.forEach((rd, i) => {
    result.push({
      ...rd,
    });
  });

  return result;
}

export default function SourceDatasetInStoreBottomDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(false);
  const { sidePanelMenuParam } = React.useContext(SourceDatasetManagerContext);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const fileData = useSelector(
    (state) => state.sources.sourcePipelineInStoreFilesSlice.data
  );

  const fileStatus = useSelector(
    (state) => state.sources.sourcePipelineInStoreFilesSlice.status
  );

  const datasetData = useSelector(
    (state) => state.sources.sourcePipelineInStoreDatasetsSlice.data
  );

  const datasetStatus = useSelector(
    (state) => state.sources.sourcePipelineInStoreDatasetsSlice.status
  );

  const fileOrDataset =
    sidePanelMenuParam["itemTypeFg"] === 1
      ? "sourceInStoreFile"
      : "sourceInStoreDataset";

  const data = sidePanelMenuParam["itemTypeFg"] === 1 ? fileData : datasetData;

  useEffect(() => {
    if (
      datasetStatus &&
      datasetStatus.method === "getSourcePipelineInStoreDatasetPreview"
    ) {
      if (datasetStatus.result === "error") {
        enqueueSnackbar("Error fetching preview: " + datasetStatus["message"], {
          variant: "error",
        });
      }
      setLoaded(true);
      dispatch(clearDatasetStatus());
    }
  }, [datasetStatus]);

  useEffect(() => {
    if (
      fileStatus &&
      fileStatus.method === "getSourcePipelineInStoreFilePreview"
    ) {
      if (fileStatus.result === "error") {
        enqueueSnackbar("Error fetching preview: " + fileStatus["message"], {
          variant: "error",
        });
      }
      setLoaded(true);
      dispatch(clearFileStatus());
    }
  }, [fileStatus]);

  useEffect(() => {
    if (
      sidePanelMenuParam["menuId"] === 1 &&
      sidePanelMenuParam["itemTypeFg"] === 1
    ) {
      dispatch(
        getSourcePipelineInStoreFilePreview({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourcePipelineId: sourcePipelineId,
          sourceInStoreId: sidePanelMenuParam["sourceInStoreId"],
          sourceInStoreFileId: sidePanelMenuParam["sourceInStoreItemId"],
        })
      );
    } else if (
      sidePanelMenuParam["menuId"] === 1 &&
      sidePanelMenuParam["itemTypeFg"] === 2
    ) {
      dispatch(
        getSourcePipelineInStoreDatasetPreview({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          sourcePipelineId: sourcePipelineId,
          sourceInStoreId: sidePanelMenuParam["sourceInStoreId"],
          sourceInStoreDatasetId: sidePanelMenuParam["sourceInStoreItemId"],
        })
      );
    }
  }, [sidePanelMenuParam]);

  return (
    <React.Fragment>
      {loaded &&
        data[fileOrDataset] &&
        data[fileOrDataset]["preview"]["status"] === "success" &&
        _.isArray(data[fileOrDataset]["preview"]["result"]) &&
        data[fileOrDataset]["preview"]["result"].length > 0 && (
          <NtaiReactDataTable
            data={getRowData(data[fileOrDataset]["preview"]["result"])}
            columns={getColumns(data[fileOrDataset]["preview"]["result"])}
          />
        )}

      {loaded &&
        data[fileOrDataset] &&
        data[fileOrDataset]["preview"]["status"] === "success" &&
        _.isArray(data[fileOrDataset]["preview"]["result"]) &&
        data[fileOrDataset]["preview"]["result"].length === 0 && (
          <NtaiEmptyMessage header="No records available" />
        )}

      {loaded &&
        data[fileOrDataset] &&
        data[fileOrDataset]["preview"]["status"] === "error" && (
          <NtaiErrorMessage
            title="Error generating preview"
            message={data[fileOrDataset]["preview"]["message"]}
          />
        )}

      {!loaded && <NtaiCircularProgress vAlign="center" size={24} />}
    </React.Fragment>
  );
}
