import { Box, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import React, { useEffect } from "react";
import { AlertEditContext } from "../../AlertEdit";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { clearStatus } from "./store/alertRecordBatchAssessmentsSlice";
import AlertRecordsBatchSelectedRecords from "../shared/AlertRecordsBatchSelectedRecords";
const _ = require("lodash");

export default function AlertRecordBatchAssessment({
  adjObject,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues } = useFormContext();

  const { selectedRecords } = React.useContext(AlertEditContext);
  const recordsDataMap = useSelector(
    (state) => state.monitor.alertRecordsSlice.data
  );

  function onHandleSubmit() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("alertRecordBatchAssessmentForm")
    );
    const revisedAdjFormData = ObjectFormUtil.generateFormSubmissionData(
      sanitizedFormData,
      adjObject
    );

    const adjFormData = {
      moduleCode: 2,
      bulkAdjFg: 1,
      clientData: {
        objectUuId: _.get(adjObject, "uuId"),
        ...revisedAdjFormData,
      },
    };

    const finalFormData = {
      recordIds: [...selectedRecords],
      selectAllFg: selectedRecords.length === 0 ? 1 : 0,
      adjudication: adjFormData,
    };

    // console.log("Final form data: ", finalFormData);
    handleSave(finalFormData);
  }

  return (
    <NtaiDialogFormPanel
      handleSave={onHandleSubmit}
      handleCancel={handleCancel}
      handleSaveLabel="Submit"
    >
      <AlertRecordsBatchSelectedRecords
        header="Selected Records: "
        direction="column"
      >
        <Box
          sx={{
            display: "flex",
            height: "44px",
          }}
        >
          <Scrollbar autoHide={true}>
            {_.isArray(selectedRecords) && selectedRecords.length > 0
              ? selectedRecords
                  .map((o) => recordsDataMap[o]["recordId"])
                  .join(", ")
              : "All"}
          </Scrollbar>
        </Box>
        {/* {selectedRecords.length === 0 ? "All" : selectedRecords.length} */}
      </AlertRecordsBatchSelectedRecords>

      <Box
        sx={{
          display: "flex",
          height: "240px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        {adjObject && !_.isEmpty(adjObject["objectForm"]) && (
          <Scrollbar autoHide={false}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: theme.spacing(2),
                pr: theme.spacing(2),
              }}
            >
              {ObjectFormUtil.generateForm(
                "alertRecordBatchAssessmentForm",
                adjObject["objectForm"],
                true,
                theme
              )}
            </Box>
          </Scrollbar>
        )}
        {(!adjObject || !adjObject["objectForm"]) && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <NtaiEmptyMessage
              header="Assessment form not available"
              subheader="No assessment form has been configured"
            />
          </Box>
        )}
      </Box>
    </NtaiDialogFormPanel>
  );
}
