import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getFunctions = createAsyncThunk(
  "functions/getFunctions",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get(`/models/functions`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFunction = createAsyncThunk(
  "functions/getFunction",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/models/functions/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const syncAwsFunctions = createAsyncThunk(
  "functions/syncAwsFunctions",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.put(`/models/functions`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFunction = createAsyncThunk(
  "functions/createFunction",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/models/functions", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFunction = createAsyncThunk(
  "functions/updateFunction",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/models/functions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFunctionCode = createAsyncThunk(
  "functions/updateFunctionCode",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/models/functions/${uuId}/code`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFunctionDataset = createAsyncThunk(
  "functions/updateFunctionDataset",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/models/functions/${uuId}/datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFunctionReturnType = createAsyncThunk(
  "functions/updateFunctionReturnType",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/models/functions/${uuId}/return-type`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFunctionModel = createAsyncThunk(
  "functions/updateFunctionModel",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/models/functions/${uuId}/models`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFunction = createAsyncThunk(
  "functions/deleteFunction",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/models/functions/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const runFunction = createAsyncThunk(
  "functions/runFunction",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.post(
        `/models/functions/${uuId}/run`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const functionsSlice = createSlice({
  name: "functions",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getFunctions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getFunctions" };
    },
    [getFunctions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getFunctions",
        message: action.payload.message,
      };
    },
    [getFunction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getFunction" };
    },
    [getFunction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getFunction",
        message: action.payload.message,
      };
    },
    [syncAwsFunctions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "syncAwsFunctions" };
    },
    [syncAwsFunctions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "syncAwsFunctions",
        message: action.payload.message,
      };
    },
    [syncAwsFunctions.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "syncAwsFunctions",
      };
    },

    [createFunction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createFunction" };
    },
    [createFunction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createFunction",
        message: action.payload.message,
      };
    },
    [updateFunction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateFunction" };
    },
    [updateFunction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateFunction",
        message: action.payload.message,
      };
    },

    [updateFunction.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateFunction",
      };
    },

    [updateFunctionCode.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateFunctionCode" };
    },
    [updateFunctionCode.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateFunctionCode",
        message: action.payload.message,
      };
    },

    [updateFunctionCode.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateFunctionCode",
      };
    },

    [updateFunctionDataset.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateFunctionDataset" };
    },

    [updateFunctionDataset.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateFunctionDataset",
        message: action.payload.message,
      };
    },

    [updateFunctionDataset.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateFunctionDataset",
      };
    },

    [updateFunctionReturnType.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateFunctionReturnType" };
    },

    [updateFunctionReturnType.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateFunctionReturnType",
        message: action.payload.message,
      };
    },

    [updateFunctionReturnType.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateFunctionReturnType",
      };
    },

    [deleteFunction.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteFunction" };
    },
    [deleteFunction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteFunction",
        message: action.payload.message,
      };
    },
    [runFunction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "runFunction" };
    },
    [runFunction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "runFunction",
        message: action.payload.message,
      };
    },
    [runFunction.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "runFunction",
      };
    },
  },
});

export const { clearStatus, setActiveId } = functionsSlice.actions;

export default functionsSlice.reducer;
