import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createThesaurusConceptSynonym,
  deleteThesaurusConceptSynonym,
  getThesaurusConceptSynonyms,
  updateThesaurusConceptSynonym,
  setActiveId,
} from "./store/thesaurusConceptSynonymsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import ThesaurusConceptSynonymForm from "./ThesaurusConceptSynonymForm";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },

  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "30%",
    fontWeight: 700,
  },
  {
    id: "termId",
    numeric: false,
    disablePadding: false,
    label: "Term Id",
    width: "30%",
  },
  {
    id: "preferredFg",
    numeric: false,
    disablePadding: false,
    label: "Preferred?",
    transformFunc: function (o) {
      return o === "1" ? "Y" : "";
    },
  },
];

const toolbarActions = [
  {
    value: "archive",
    label: "Delete",
    icon: "archive",
  },
];

export default function ThesaurusConceptSynonymList(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { conceptId } = props;
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const thesaurusId = useSelector(
    (state) => state.core.thesaurusesSlice.activeId
  );

  const conceptDataMap = useSelector(
    (state) => state.core.thesaurusConceptsSlice.data
  );

  const conceptData =
    conceptDataMap && !_.isEmpty(conceptDataMap)
      ? conceptDataMap[conceptId]
      : null;

  const dataMap = useSelector(
    (state) => state.core.thesaurusConceptSynonymsSlice.data
  );
  const activeId = useSelector(
    (state) => state.core.thesaurusConceptSynonymsSlice.activeId
  );
  const status = useSelector(
    (state) => state.core.thesaurusConceptSynonymsSlice.status
  );
  const data = Object.values(dataMap);

  function handleAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleFormSubmit(formData) {
    if (action === "add") {
      dispatch(
        createThesaurusConceptSynonym({
          thesaurusId: thesaurusId,
          conceptId: conceptId,
          formData: { ...formData, semTypeId: conceptData["semTypeId"] },
        })
      );
    } else if (action === "edit") {
      dispatch(
        updateThesaurusConceptSynonym({
          thesaurusId: thesaurusId,
          conceptId: conceptId,
          uuId: currentId,
          formData: formData,
        })
      );
    }
  }

  function handleCancel() {
    setAction(null);
    setCurrentId(null);
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "archive") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(
        deleteThesaurusConceptSynonym({
          thesaurusId: thesaurusId,
          conceptId: conceptId,
          termId: id,
          params: {
            semTypeId: conceptData["semTypeId"],
          },
        })
      );
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getThesaurusConceptSynonyms" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      (status.method === "createThesaurusConceptSynonym" ||
        status.method === "updateThesaurusConceptSynonym")
    ) {


      setAction(null);
      setCurrentId(null);
      dispatch(setActiveId(null));
      dispatch(clearStatus());

      enqueueSnackbar(
        `Synonym "${
          dataMap[action === "add" ? activeId : currentId]["name"]
        }" saved successfully.`,
        {
          variant: "success",
        }
      );
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteThesaurusConceptSynonym"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Synonym(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getThesaurusConceptSynonyms({
        thesaurusId: thesaurusId,
        conceptId: conceptId,
        semTypeId: conceptData["semTypeId"],
      })
    );

    dispatch(
      getCodes(CODELIST_CODES.LIB_THESAURUS_CONCEPT_SYNONYM_TYPE_CODELIST)
    );
  }, []);

  return (
    <>
      {!["add", "edit"].includes(action) && (
        <NtaiPanel
          width="100%"
          header="Synonyms"
          subheader="Concept synonyms are related terms for a given concept."
        >
          {loaded && (
            <NtaiCrudTable
              rows={data}
              headCells={headCells}
              selectedIds={selectedIds}
              toolbarActions={toolbarActions}
              keyColumn="termId"
              dialog={true}
              handleDialogAddEdit={handleAddEdit}
              handleToolbarAction={handleToolbarAction}
              title="Synonym"
            />
          )}
          {!loaded && <NtaiCircularProgress />}
        </NtaiPanel>
      )}

      {((action === "edit" && currentId) || action === "add") && (
        <NtaiForm>
          <ThesaurusConceptSynonymForm
            action={action}
            formData={action === "edit" ? _.get(dataMap, currentId) : {}}
            handleSave={handleFormSubmit}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}

      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
