import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { grey } from "@mui/material/colors";
import {
  tooltipConfig,
  gridConfig,
  xAxisLabelConfig,
  yAxisLabelConfig,
  xAxisLabelNoRotationConfig,
} from "../chartConfig";
import { Box, lighten } from "@mui/material";
import NtaiReactEChart from "../NtaiReactEChart";
import NtaiEChartUtil from "../util/NtaiEChartUtil";
import NtaiScatterEChartUtil from "./util/NtaiScatterEChartUtil";
import NtaiChartUtil from "../../util/NtaiChartUtil";
import NtaiChartEmptyMessage from "../../NtaiChartEmptyMessage";
const _ = require("lodash");

export default function NtaiScatterEChart(props) {
  const theme = useTheme();
  const { widgetId, data, metadata, handleClick, handleContextMenu } = props;

  const DEFAULT_OPTION = {
    toolbox: {
      feature: {
        dataZoom: {
          xAxisIndex: "none",
        },
      },
    },

    grid: {
      containLabel: true,
      left: 20,
      top: 20,
      right: 20,
      bottom: 20,
    },

    tooltip: {
      ...tooltipConfig,
      formatter: function (params) {
        return NtaiEChartUtil.getTooltipContent({
          values: [
            {
              key: params.marker,
              value: NtaiScatterEChartUtil.getTooltipValues(
                params["data"]["value"][2],
                metadataRef.current
              ),
            },
          ],
        });
        // return "X";
      },
    },
    xAxis: {
      axisLabel: xAxisLabelNoRotationConfig,
      axisLine: {
        lineStyle: {
          width: 0.1,
        },
      },
      axisTick: {
        lineStyle: {
          width: 0.2,
        },
      },
      name: "X-Axis",
      nameLocation: "middle",
      nameGap: 30,
      nameTextStyle: {
        fontSize: 11,
        fontWeight: "bold",
      },
    },
    yAxis: {
      axisLabel: yAxisLabelConfig,
      axisLine: {
        lineStyle: {
          width: 0.1,
        },
      },
      axisTick: {
        lineStyle: {
          width: 0.2,
        },
      },
      name: "Y-Axis",
      nameLocation: "middle",
      nameGap: 30,
      nameTextStyle: {
        fontSize: 11,
        fontWeight: "bold",
      },
      // min: "dataMin",
      // max: "dataMax",
      // type: "log",
      // logBase: 10,
    },
    series: [],
  };

  const [option, setOption] = useState(DEFAULT_OPTION);
  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;

  function onChartClick(params) {
    handleClick(
      NtaiScatterEChartUtil.getSourceWidgetFilterFields(
        params,
        metadataRef.current
      )
    );
  }

  function onHandleContextMenu(action, params) {
    handleContextMenu(
      action,
      NtaiScatterEChartUtil.getSourceWidgetFilterFields(
        params,
        metadataRef.current
      )
    );
  }

  useEffect(() => {
    const optionCopy = _.cloneDeep(option);
    const colors = NtaiEChartUtil.getColorPalette(theme, 1);

    const symbols = [
      "circle",
      "rect",
      "roundRect",
      "triangle",
      "diamond",
      "pin",
      "arrow",
    ];

    if (data && _.isArray(data) && data.length > 1) {
      optionCopy["xAxis"]["name"] =
        NtaiEChartUtil.getSourceFieldByAxisFg(metadata, 1)["field"]["label"] ||
        "X";
      optionCopy["yAxis"]["name"] =
        NtaiEChartUtil.getSourceFieldByAxisFg(metadata, 2)["field"]["label"] ||
        "Y";
      optionCopy["series"].push({
        type: "scatter",
        data: data.map((d, i) => {
          return {
            value: [d["x"], d["y"], d["values"]],
            itemStyle: {
              color: d.hasOwnProperty("colorValue")
                ? NtaiEChartUtil.getItemStyleColor(
                    "bg",
                    d["colorValue"],
                    colors[0],
                    metadata
                  )
                : colors[0],
            },
            symbolSize: 6,
          };
        }),
        // color: theme.colors.chart.light,

        symbol: symbols[0] ? symbols[0] : "circle",
      });
    }

    setOption(optionCopy);
  }, [data, metadata]);

  const chart = useMemo(
    () => (
      <NtaiReactEChart
        option={option}
        handleChartClick={onChartClick}
        handleContextMenu={onHandleContextMenu}
      />
    ),
    [option]
  );

  return (
    <Box id={widgetId} sx={{ display: "flex", height: "100%", width: "100%" }}>
      {option["series"].length > 0 && chart}
      {option["series"].length === 0 && <NtaiChartEmptyMessage />}
    </Box>
  );
}
