import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material";
import {
  getToolsAssessmentImports,
  clearStatus,
  deleteToolsAssessmentImport,
} from "./store/toolsAssessmentImportSlice";
import ToolsPage from "../ToolsPage";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
  },
  {
    id: "object.name",
    numeric: false,
    disablePadding: false,
    label: "Assessment Form",
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    label: "File",
  },
  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "noOfRecords",
    numeric: false,
    disablePadding: false,
    label: "# Updated",
  },
  {
    id: "noOfRejectedRecords",
    numeric: false,
    disablePadding: false,
    label: "# Rejected",
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: false,
    label: "Created",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userCreated",
    numeric: false,
    disablePadding: false,
    label: "Created by",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function ToolsAssessmentImportList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dataMap = useSelector(
    (state) => state.tools.toolsAssessmentImportSlice.data
  );
  const status = useSelector(
    (state) => state.tools.toolsAssessmentImportSlice.status
  );
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["dateModified"], ["desc"])
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteToolsAssessmentImport(id));
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getToolsAssessmentImports" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteToolsAssessmentImport" &&
      status.result === "success"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Assessment Import(s) "${selectedItems.join(
            ", "
          )}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getToolsAssessmentImports());
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Tools / Assessment Import">
        {loaded && (
          <NtaiCrudTable
            helpText="Assessment import feature allows you to create assessment for imported records present in the CSV file. Before you create assessment for imported records, ensure that you have assessment import scheme already defined in Source - Assessment Forms section."
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/tools/assessment-import`}
            handleToolbarAction={handleToolbarAction}
            title="Assessment Import"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
