import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  executeAlertDefJob,
  clearStatus,
} from "../store/alertDefinitionsSlice";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { useSnackbar } from "notistack";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiDialogPanel from "@ntai/components/panels/NtaiDialogPanel";
const _ = require("lodash");

function validateAlertDef(alertDefData) {
  console.log("Validate Alert Def: ", alertDefData);
  let status = "success";
  let message = [];

  if (!alertDefData["alertDefSource"]) {
    status = "error";
    message.push("Source is missing for the alert");
  }

  if (
    alertDefData["alertDefSource"] &&
    !_.isEmpty(alertDefData["alertDefSource"])
  ) {
    if (
      _.isArray(alertDefData["alertDefSource"]["alertDefObjectTopics"]) &&
      _.isArray(alertDefData["alertDefSource"]["alertDefDataTopics"]) &&
      alertDefData["alertDefSource"]["alertDefObjectTopics"].length === 0 &&
      alertDefData["alertDefSource"]["alertDefDataTopics"].length === 0
    ) {
      status = "error";
      message.push("Object or data topic(s) are missing for the alert");
    }
  }

  if (
    !alertDefData["alertDefWorkflow"] ||
    _.isEmpty(
      alertDefData["alertDefWorkflow"] ||
        !_.has(alertDefData["alertDefWorkflow"], "uuId")
    )
  ) {
    status = "error";
    message.push("Alert workflow not selected");
  }

  return {
    status: status,
    message: "Error processing request: [" + message.join(", ") + "]",
  };
}

export default function AlertDefinitionActions(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = props;

  const [action, setAction] = useState();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const dataMap = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.data
  );
  const definitionId = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.activeId
  );

  const alertDefinitionData = _.get(dataMap, id);

  const status = useSelector(
    (state) => state.monitor.alertDefinitionsSlice.status
  );

  function handleSubmitAction() {
    const validationResult = validateAlertDef(alertDefinitionData);
    console.log("validationResult: ", validationResult);

    if (validationResult["status"] === "success") {
      dispatch(
        executeAlertDefJob({ definitionId: definitionId, action: action })
      );
    } else {
      enqueueSnackbar(validationResult.message, {
        variant: "error",
      });
    }
  }

  function onHandleAction(action) {
    setAction(action);
  }

  function handleDialogOpen() {
    setConfirmDialogOpen(true);
  }

  function handleDialogClose() {
    setConfirmDialogOpen(false);
    setAction(null);
  }

  useEffect(() => {
    if (["start", "stop"].includes(action)) {
      handleDialogOpen();
    }
  }, [action]);

  useEffect(() => {
    if (
      status &&
      status.method === "executeAlertDefJob" &&
      status.result === "success"
    ) {
      enqueueSnackbar(
        `Alert "${alertDefinitionData["name"]}" ${
          status.action === "start" ? "submitted" : "terminated"
        } successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
      handleDialogClose();
      setAction(null);
    }

    if (
      status &&
      status.method === "executeAlertDefJob" &&
      status.result === "error"
    ) {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
      setAction(null);
    }
  }, [status]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        <Tooltip title="Submit Alert">
          <span>
            <Button
              sx={{ fontSize: "11px" }}
              disabled={
                [1,4, 5].includes(_.get(alertDefinitionData, "statusCode"))
                  ? false
                  : true
              }
              size="small"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faCircleCheck} />}
              onClick={() => onHandleAction("start")}
            >
              SUBMIT
            </Button>
          </span>
        </Tooltip>

        <Tooltip title="Terminate Alert">
          <span>
            <Button
              sx={{ fontSize: "11px" }}
              size="small"
              disabled={
                _.get(alertDefinitionData, "statusCode") === 2 ? false : true
              }
              color="primary"
              startIcon={<FontAwesomeIcon icon={faCircleXmark} />}
              onClick={() => onHandleAction("stop")}
            >
              TERMINATE
            </Button>
          </span>
        </Tooltip>
      </Box>

      <NtaiDialog
        title={`Confirm ${action === "start" ? "submit" : "termination"}`}
        size="sm"
        open={confirmDialogOpen}
        handleDialogClose={handleDialogClose}
      >
        <NtaiDialogPanel
          handleSave={handleSubmitAction}
          handleCancel={handleDialogClose}
          handleSaveLabel="Confirm"
        >
          {`Are you sure you want to ${
            action === "start" ? "submit" : "terminate"
          } "${alertDefinitionData["name"]}" alert?`}
        </NtaiDialogPanel>
      </NtaiDialog>
    </React.Fragment>
  );
}
