import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSchedulers = createAsyncThunk(
  "schedulers/getSchedulers",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/admin/schedulers");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getScheduler = createAsyncThunk(
  "schedulers/getScheduler",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/schedulers/${uuId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createScheduler = createAsyncThunk(
  "schedulers/createScheduler",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/admin/schedulers", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateScheduler = createAsyncThunk(
  "schedulers/updateScheduler",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(
        `/admin/schedulers/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteScheduler = createAsyncThunk(
  "schedulers/deleteScheduler",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/admin/schedulers/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const startScheduler = createAsyncThunk(
  "schedulers/startScheduler",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/schedulers/${uuId}/start`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const stopScheduler = createAsyncThunk(
  "schedulers/stopScheduler",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/admin/schedulers/${uuId}/stop`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const schedulersSlice = createSlice({
  name: "schedulers",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSchedulers.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSchedulers" };
    },
    [getSchedulers.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSchedulers",
        message: action.payload.message,
      };
    },
    [getScheduler.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getScheduler" };
    },
    [getScheduler.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getScheduler",
        message: action.payload.message,
      };
    },
    [createScheduler.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createScheduler" };
    },
    [createScheduler.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createScheduler",
        message: action.payload.message,
      };
    },
    [updateScheduler.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateScheduler" };
    },
    [updateScheduler.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateScheduler",
        message: action.payload.message,
      };
    },
    [deleteScheduler.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteScheduler" };
    },
    [deleteScheduler.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteScheduler",
        message: action.payload.message,
      };
    },
    [startScheduler.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "startScheduler" };
    },
    [startScheduler.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "startScheduler",
        message: action.payload.message,
      };
    },

    [stopScheduler.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "stopScheduler" };
    },
    [stopScheduler.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "stopScheduler",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = schedulersSlice.actions;

export default schedulersSlice.reducer;
