import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SourceLinkedSourceFieldList from "./SourceLinkedSourceFieldList";
import {
  getLinkedSourceFields,
  getSourceLinkedSourceFields,
} from "./store/sourceLinkedSourceFieldsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

export default function SourceLinkedSourceFieldWrapper({ linkedSourceId }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const linkedSourceDataMap = useSelector(
    (state) => state.sources.sourceLinkedSourcesSlice.data
  );

  const linkedSourceFieldsDataMap = useSelector(
    (state) => state.sources.sourceLinkedSourceFieldsSlice.data
  );

  const linkedSourceData = _.get(linkedSourceDataMap, linkedSourceId);
  const linkedSourceUuId = _.get(linkedSourceData, "linkedSourceUuId");
  const linkedSourceDefUuId = _.get(linkedSourceData, "linkedSourceDefUuId");

  useEffect(() => {
    if (linkedSourceUuId) {
      dispatch(
        getLinkedSourceFields({
          sourceDefId: linkedSourceDefUuId,
          sourceId: linkedSourceUuId,
        })
      );

      dispatch(
        getSourceLinkedSourceFields({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          linkedSourceId: linkedSourceId,
        })
      );
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexBasis: "50%",
          borderLeft: theme.general.border1,
          height: "100%",
          flexDirection: "column",
        }}
      >
        <NtaiPanel
          header="Manage Fields"
          subheader="Map source fields to target linked source fields and specify link type from the selection provided."
          width="100%"
          height="90px"
        ></NtaiPanel>
        <SourceLinkedSourceFieldList />
      </Box>
    </>
  );
}
