const _ = require("lodash");
const lc = require("lodash-checkit");

class NtaiValidator {
  static isPythonFileName(val) {
    const re = /^[a-zA-Z_][a-zA-Z0-9_]*\.(py|PY)$/;
    if (!re.test(val)) return "Please provide a valid python file name";
  }

  static isEmail(val) {
    if (!lc.isEmail(val)) return "Please provide a valid email address";
  }

  static isUserName(val) {
    const re = /^[a-zA-Z0-9_\-]+$/;
    if (!re.test(val))
      return "Sorry, only letters (a-z), numbers (0-9), hyphens (-), and underscores (_) are allowed.";
  }

  static isPassword(val) {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!re.test(val))
      return "Provide min 8 letter password, with at least a symbol, upper and lower case letters and a number";
  }

  static isPersonName(val) {
    const re = /^[a-z ]+$/i;
    if (!re.test(val)) return "Provide a valid name";
  }
}

export default NtaiValidator;
