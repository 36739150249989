import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createThesaurusLoad,
  getThesaurusLoad,
  getThesaurusLoadList,
  clearStatus,
} from "./store/thesaurusLoadSlice";
import ThesaurusLoadForm from "./ThesaurusLoadForm";
import ThesaurusAwsSession from "./session/ThesaurusAwsSession";

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "loadTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "dateStarted",
    numeric: false,
    disablePadding: false,
    label: "Started",
  },

  {
    id: "dateCompleted",
    numeric: false,
    disablePadding: false,
    label: "Completed",
  },
  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: "Message",
  },
];

export default function ThesaurusLoadList(props) {
  const thesaurusId = props.match.params.thesaurusId;

  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const dataMap = useSelector((state) => state.core.thesaurusLoadSlice.data);
  const status = useSelector((state) => state.core.thesaurusLoadSlice.status);
  const data = Object.values(dataMap);

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("get");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function handleFormSubmit(formData) {
    action === "get"
      ? dispatch(
          getThesaurusLoad({
            thesaurusId: thesaurusId,
            uuId: currentId,
          })
        )
      : dispatch(
          createThesaurusLoad({
            thesaurusId: thesaurusId,
            formData: formData,
          })
        );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "createThesaurusLoad"
    ) {
      handleDialogClose();
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getThesaurusLoadList(thesaurusId));
  }, []);

  return (
    <>
      <NtaiPage
        padding={2}
        title="Thesaurus Import"
        headerActions={<ThesaurusAwsSession />}
      >
        <NtaiCrudTable
          helpText="There is always something about domains"
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          title="Thesaurus Load"
        />
      </NtaiPage>
      <NtaiDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
        title="Import Thesaurus"
        size="sm"
      >
        <NtaiForm>
          <ThesaurusLoadForm
            action={action}
            handleDialogClose={handleDialogClose}
            handleFormSubmit={handleFormSubmit}
            thesaurusId={thesaurusId}
            formData={action === "get" ? dataMap[currentId] : {}}
          />
        </NtaiForm>
      </NtaiDialog>
    </>
  );
}
