import { Box, useTheme } from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
const _ = require("lodash");

export default function SourceRecordListingGeneralForm({
  action,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const sourceDefId = useSelector(
    (state) => state.sources.sourcesSlice.activeId
  );
  const sourceId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const codelistCodes = useSelector((state) => state.core.codelistsSlice.codes);
  const listingTypeOptions = _.get(
    codelistCodes,
    CODELIST_CODES.SRC_SOURCE_RECORD_LISTING_TYPE_CODE
  );

  const listingId = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.activeId
  );

  const listingDataMap = useSelector(
    (state) => state.sources.sourceRecordListingsSlice.data
  );

  const listingData = _.get(listingDataMap, listingId);

  const listingTypeCode = _.get(listingData, "typeCode");

  function onHandleSave() {
    if (_.isEmpty(errors)) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("sourceRecordListingGeneralForm")
      );

      handleFormSubmit(sanitizedFormData);
    }
  }

  useEffect(() => {
    reset({
      sourceRecordListingGeneralForm: action === "edit" ? listingData : {},
    });
  }, []);

  return (
    <NtaiFormPanel
      subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          gap: theme.spacing(2),
        }}
      >
        <NtaiTextField
          name="sourceRecordListingGeneralForm.name"
          label="Name*"
          rules={{ required: "Name is required" }}
        />
        <NtaiTextField
          name="sourceRecordListingGeneralForm.description"
          multiline
          minRows={2}
          maxRows={2}
          placeholder="Description"
          label="Description"
        />
        <NtaiSelectField
          isDisabled={action === "edit"}
          label="Type*"
          name="sourceRecordListingGeneralForm.typeCode"
          options={listingTypeOptions}
          rules={{ required: "Type is required" }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NtaiSwitchField
            name="sourceRecordListingGeneralForm.defaultFg"
            label="Default?"
          />
          <NtaiSwitchField
            name="sourceRecordListingGeneralForm.activeFg"
            label="Active?"
          />
        </Box>
      </Box>
    </NtaiFormPanel>
  );
}
