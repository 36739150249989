import NtaiEChartUtil from "../../util/NtaiEChartUtil";

const _ = require("lodash");

class NtaiScatterEChartUtil {
  static getSourceWidgetFilterFields(params, metadata) {
    let sourceWidgetFilterFields = [];

    if (metadata["fields"] && !_.isEmpty(metadata["fields"])) {
      const values = params["data"]["value"];

      const catFields = _.filter(
        Object.values(metadata["fields"]),
        function (o) {
          if (
            (!o["xAxisFg"] || o["xAxisFg"] === 0) &&
            (o["yAxisFg"] === 0 || !o["yAxisFg"])
          )
            return true;
          else return false;
        }
      );

      if (catFields.length > 0) {
        catFields.forEach((o) => {
          const sourceWidgetFieldUuId = o["uuId"];
          const sourceWidgetFieldValue = _.get(values, o["uuId"]);
          sourceWidgetFilterFields.push({
            sourceWidgetFieldUuId: sourceWidgetFieldUuId,
            fieldOperatorCode: 1,
            fieldValue: sourceWidgetFieldValue,
          });
        });
      }
    }

    return sourceWidgetFilterFields;
  }

  static getTooltipValues(values, metadata) {
    let result = [];

    const catFields = _.orderBy(
      _.filter(Object.values(metadata["fields"]), function (o) {
        if (o["xAxisFg"] === 1 || o["yAxisFg"] === 1) return false;
        else return true;
      }),
      ["fieldOrder"],
      ["asc"]
    );

    if (catFields.length > 0) {
      catFields.forEach((o) => {
        const label = o["headerName"];
        const value = _.get(values, o["uuId"]);
        result.push("<strong>" + label + "</strong>: " + value);
      });
    }

    const xFields = _.filter(Object.values(metadata["fields"]), function (o) {
      if (o["xAxisFg"] === 1) return true;
      else return false;
    });

    const yFields = _.filter(Object.values(metadata["fields"]), function (o) {
      if (o["yAxisFg"] === 1) return true;
      else return false;
    });

    if (xFields.length > 0) {
      xFields.forEach((o) => {
        const label = o["headerName"];
        const value = _.get(values, o["uuId"]);
        result.push("<strong>" + label + "</strong>: " + value);
      });
    }

    if (yFields.length > 0) {
      yFields.forEach((o) => {
        const label = o["headerName"];
        const value = _.get(values, o["uuId"]);
        result.push("<strong>" + label + "</strong>: " + value);
      });
    }

    return result.join("<br/>");
  }

  static getMinMax(data, metadata) {
    let min = 0;
    let max = 0;

    const sizeField = NtaiEChartUtil.getSourceFieldBySizeFg(metadata);

    if (sizeField) {
      for (let i = 0; i < data.length; i++) {
        const value = data[i]["values"][sizeField["uuId"]];
        if (i === 0) min = value;
        if (value >= max) max = value;
        if (value <= min) min = value;
      }
    }

    return [min, max];
  }

  static interpolate(old_value, old_min, old_max, new_min, new_max) {
    return (
      ((old_value - old_min) / (old_max - old_min)) * (new_max - new_min) +
      new_min
    );
  }
}

export default NtaiScatterEChartUtil;
