import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  lighten,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AlertEditContext } from "../AlertEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTick } from "@fortawesome/pro-light-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import AlertRecordAdjudication from "./sidepanels/manage/adjudication/AlertRecordAdjudication";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import AlertRecordDisplay from "./sidepanels/record/AlertRecordDisplay";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import AlertRecordActionList from "./sidepanels/manage/action/AlertRecordActionList";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  getObject,
  clearStatus as clearObjectsSliceStatus,
} from "app/main/pages/core/cdo/object/store/objectsSlice";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import AlertRecordDocumentList from "./sidepanels/manage/document/AlertRecordDocumentList";
import {
  faCheckSquare,
  faUser,
  faClose,
  faUserCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  assignUsersToAlertRecords,
  assignUserToSingleAlertRecord,
  clearStatus,
} from "./store/alertRecordsSlice";
import { use } from "cytoscape";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import AlertRecordAssignment from "./sidepanels/manage/assignment/AlertRecordAssignment";
const _ = require("lodash");

export default function AlertRecordPanel({
  handleUserAssignment,
  handleDrawerClose,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [adjObjectsLoaded, setAdjObjectsLoaded] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [assignmentDialogOpen, setAssignmentDialogOpen] = useState(false);

  const {
    gUuId,
    alertId,
    alertMasterId,
    activityId,
    expandSidePanel,
    setExpandSidePanel,
    sidePanelContext,
    selectedRecordId,
    refreshAllRecords,
    setRefreshAllRecords,
    refreshSelectedRecord,
    setRefreshSelectedRecord,
  } = useContext(AlertEditContext);

  const alertDataMap = useSelector((state) => state.monitor.alertsSlice.data);

  const alertData = _.get(alertDataMap, gUuId);

  const objectsSliceStatus = useSelector(
    (state) => state.core.objectsSlice.status
  );

  const objectsDataMap = useSelector((state) => state.core.objectsSlice.data);

  const sourceUuId = _.get(alertData, "sourceUuId");

  const recordsDataMap = useSelector(
    (state) => state.monitor.alertRecordsSlice.data
  );

  const status = useSelector((state) => state.monitor.alertRecordsSlice.status);

  const recordData = _.get(recordsDataMap, selectedRecordId);

  const sourceFields = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourcePrimaryField = _.pickBy(sourceFields, function (v, k) {
    return v.primaryFieldFg === 1;
  });

  const sourcePrimaryFieldPath =
    !_.isEmpty(sourcePrimaryField) &&
    Object.values(sourcePrimaryField).length > 0
      ? Object.values(sourcePrimaryField)[0].path
      : "recordId";

  const sourceRecordLabelsDataMap = useSelector(
    (state) => state.sources.sourceRecordLabelsSlice.data
  );

  const sourceRecordLabelsData = Object.values(sourceRecordLabelsDataMap);

  function toggle() {
    setExpandSidePanel(!expandSidePanel);
  }

  function displayLabel() {
    let primaryField = "Not Available";
    let labels = {};
    if (
      _.isArray(sourceRecordLabelsData) &&
      _.filter(sourceRecordLabelsData, { defaultFg: 1 }).length > 0
    ) {
      const sourceRecordDefaultLabel = _.filter(sourceRecordLabelsData, {
        defaultFg: 1,
      })[0];

      if (!_.isEmpty(sourceRecordDefaultLabel))
        labels = NtaiAppUtils.getSourceRecordLabel(
          _.get(recordData, `record.fields`),
          sourceRecordDefaultLabel
        );
    }

    const tmpPrimaryField = _.get(
      recordData,
      `sourceRecord.${sourcePrimaryFieldPath}`
    );
    if (tmpPrimaryField) primaryField = tmpPrimaryField;

    return (
      <React.Fragment>
        <Typography
          variant="h2"
          sx={{
            wordBreak: "break-word",
          }}
          color="secondary.dark"
          fontWeight="300"
        >
          {!_.isEmpty(labels) && labels["primary"]
            ? labels.primary
            : primaryField}
        </Typography>

        {!_.isEmpty(labels) && labels["secondary"] && (
          <Typography
            sx={{
              wordBreak: "break-word",
            }}
            variant="subtitle2"
          >
            {labels["secondary"]}
          </Typography>
        )}

        {!_.isEmpty(labels) &&
          _.isArray(labels["tags"]) &&
          labels["tags"].length > 0 && (
            <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
              {_.map(labels["tags"], (lt, i) =>
                lt.title.length > 0 ? (
                  <NtaiBadge
                    title={NtaiUtils.trunc(lt.title, 18)}
                    fg={lt.fg}
                    bg={lt.bg}
                  />
                ) : (
                  <div></div>
                )
              )}
            </Box>
          )}
      </React.Fragment>
    );
  }

  function generateTabs() {
    let adjTabs = [];
    const adjObjectUuIds = _.get(alertData, "adjObjectUuIds");
    console.log("adjObjectUuIds: ", adjObjectUuIds);
    if (
      adjObjectUuIds &&
      _.isArray(adjObjectUuIds) &&
      adjObjectUuIds.length > 0
    ) {
      adjObjectUuIds.forEach((o, i) => {
        const adjForm = objectsDataMap[o]["objectForm"];
        if (adjForm && !_.isEmpty(adjForm)) {
          adjTabs.push(
            <Box sx={{ height: "100%" }} label={adjForm["label"].toUpperCase()}>
              <NtaiForm>
                <AlertRecordAdjudication
                  adjObjectUuId={o}
                  handleCancel={handleDrawerClose}
                />
              </NtaiForm>
            </Box>
          );
        }
      });
    }

    return (
      <NtaiTabs width={750}>
        <Box sx={{ height: "100%" }} label="RECORD">
          <AlertRecordDisplay />
        </Box>
        {adjTabs}
        <Box sx={{ height: "100%" }} label="ACTIONS">
          <AlertRecordActionList />
        </Box>
        {/* <Box sx={{ height: "100%" }} label="NOTES">
          <AlertRecordNoteList />
        </Box>  */}
        <Box sx={{ height: "100%" }} label="DOCUMENTS">
          <AlertRecordDocumentList />
        </Box>
      </NtaiTabs>
    );
  }

  function onHandleUserAssignment() {
    console.log("Handle assignment: ", recordData);
    if (recordData && !recordData["userAssigned"]) {
      dispatch(
        assignUserToSingleAlertRecord({
          alertId: alertId,
          alertMasterId: alertMasterId,
          activityId: activityId,
          formData: {
            alertRecordUuIds: [selectedRecordId],
            userUuId: null,
            singleRecordFg: 1,
          },
        })
      );
    }
  }

  function onHandleReassigUnassign() {
    setAssignmentDialogOpen(true);
  }

  function handleAssignmentDialogClose() {
    setAssignmentDialogOpen(false);
  }

  useEffect(() => {
    if (
      objectsSliceStatus &&
      objectsSliceStatus.method === "getObject" &&
      objectsSliceStatus.result === "success"
    ) {
      dispatch(clearObjectsSliceStatus());
    }
  }, [objectsSliceStatus]);

  useEffect(() => {}, [expandSidePanel, sidePanelContext, selectedRecordId]);

  useEffect(() => {
    const adjObjectUuIds = _.get(alertData, "adjObjectUuIds");
    const objectKeys = Object.keys(objectsDataMap);
    if (_.difference(adjObjectUuIds, objectKeys).length === 0) {
      setAdjObjectsLoaded(true);
    }
  }, [objectsDataMap]);

  useEffect(() => {
    const adjObjectUuIds = _.get(alertData, "adjObjectUuIds");
    if (adjObjectUuIds && _.isArray(adjObjectUuIds)) {
      adjObjectUuIds.forEach((o) => {
        dispatch(
          getObject({
            domainId: "*",
            uuId: o,
          })
        );
      });
    }
  }, [alertData]);

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "assignUserToSingleAlertRecord"
    ) {
      dispatch(clearStatus());
      // TODO: have separate data for selected record
      // setRefreshSelectedRecord(refreshSelectedRecord + 1);
      // setRefreshAllRecords(refreshAllRecords + 1);
    }
  }, [status]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "820px",
          flexDirection: "column",
          height: "100%",
          borderLeft: theme.general.border1,
          boxShadow: theme.colors.shadows.card,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(2),
            borderBottom: theme.general.border1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            {selectedRecordId && displayLabel()}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: theme.spacing(2),
              alignItems: "center",
            }}
          >
            {/* <IconButton size="small">
                <FontAwesomeIcon icon={faExternalLink} size="sm" />
              </IconButton> */}
            <Tooltip
              title={
                recordData && !_.isEmpty(recordData["userAssigned"])
                  ? recordData["userAssigned"]["userName"]
                  : "Assign"
              }
            >
              {recordData && _.isEmpty(recordData["userAssigned"]) && (
                <IconButton onClick={() => onHandleUserAssignment()}>
                  <FontAwesomeIcon size="xs" icon={faUserCheck} />
                </IconButton>
              )}
              {recordData && !_.isEmpty(recordData["userAssigned"]) && (
                <Chip
                  sx={{
                    bgcolor: lighten(theme.colors.secondary.main, 0.8),
                    color: "black",
                  }}
                  size="small"
                  label={
                    <Typography
                      variant="subtitle1"
                      fontWeight={500}
                      color="inherit"
                    >
                      <span>Assgned to: </span>
                      {recordData["userAssigned"]["userName"]}
                    </Typography>
                  }
                  onClick={() => onHandleReassigUnassign()}
                />
                // <Avatar
                //   sx={{
                //     height: 28,
                //     width: 28,
                //     fontSize: 12,
                //     fontWeight: 700,
                //     color: "white",
                //     bgcolor: theme.colors.secondary.main,
                //   }}
                // >
                //   {NtaiUtils.getUserInitials(
                //     recordData["userAssigned"]["firstName"],
                //     recordData["userAssigned"]["lastName"]
                //   )}
                // </Avatar>
              )}
            </Tooltip>

            <IconButton onClick={() => handleDrawerClose()}>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            // height: `calc(100% - 64px)`,
          }}
        >
          {adjObjectsLoaded && generateTabs()}
          {!adjObjectsLoaded && <NtaiCircularProgress size={24} />}
        </Box>
      </Box>
      <NtaiDialog
        title="Unassign or Reassign Item"
        size="sm"
        open={assignmentDialogOpen}
        handleDialogClose={handleAssignmentDialogClose}
      >
        <NtaiForm>
          <AlertRecordAssignment handleCancel={handleAssignmentDialogClose} />
        </NtaiForm>
      </NtaiDialog>
    </>
  );
}
