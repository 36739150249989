import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordActions = createAsyncThunk(
  "searchSourceRecordActions/getSearchSourceRecordActions",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.put(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/actions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchSourceRecordAction = createAsyncThunk(
  "searchSourceRecordActions/getSearchSourceRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/actions/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchSourceRecordAction = createAsyncThunk(
  "searchSourceRecordActions/createSearchSourceRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, formData } =
        values;
      const response = await server.post(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/actions`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchSourceRecordAction = createAsyncThunk(
  "searchSourceRecordActions/updateSearchSourceRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId, formData } =
        values;
      const response = await server.patch(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/actions/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchSourceRecordAction = createAsyncThunk(
  "searchSourceRecordActions/deleteSearchSourceRecordAction",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, searchSourceRecordId, uuId } = values;
      const response = await server.delete(
        `/search/${searchId}/sources/${searchSourceId}/records/${searchSourceRecordId}/actions/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordActionsSlice = createSlice({
  name: "searchSourceRecordActions",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchSourceRecordActions.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "action.uuId") };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordActions",
      };
    },
    [getSearchSourceRecordActions.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordActions",
        message: action.payload.message,
      };
    },
    [getSearchSourceRecordAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.action.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSearchSourceRecordAction",
      };
    },
    [getSearchSourceRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchSourceRecordAction",
        message: action.payload.message,
      };
    },
    [createSearchSourceRecordAction.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "createSearchSourceRecordAction",
      };
    },
    [createSearchSourceRecordAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.action.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSearchSourceRecordAction",
      };
    },
    [createSearchSourceRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchSourceRecordAction",
        message: action.payload.message,
      };
    },
    [updateSearchSourceRecordAction.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.action.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateSearchSourceRecordAction",
      };
    },
    [updateSearchSourceRecordAction.pending]: (state, action) => {
      state.status = {
        result: "pending",
        method: "updateSearchSourceRecordAction",
      };
    },

    [updateSearchSourceRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchSourceRecordAction",
        message: action.payload.message,
      };
    },
    [deleteSearchSourceRecordAction.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSearchSourceRecordAction",
      };
    },
    [deleteSearchSourceRecordAction.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchSourceRecordAction",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceRecordActionsSlice.actions;

export default searchSourceRecordActionsSlice.reducer;
