import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getDashboardWidgets,
  clearStatus as clearDashboardWidgetsSliceStatus,
} from "../../widgets/store/dashboardWidgetsSlice";
import { clearStatus } from "../store/dashboardViewsSlice";
import { DashboardViewContext } from "../DashboardViewForm";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import DashboardViewLayout from "./DashboardViewLayoutDialog";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["name"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.uuId]["name"],
        secondary: allObjectsDataMap[f.uuId]["description"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["name"], ["asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.uuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function DashboardViewWidgets() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);
  const [layoutDialogOpen, setLayoutDialogOpen] = useState(false);

  const { handleFormSubmit } = React.useContext(DashboardViewContext);

  const dashboardId = useSelector(
    (state) => state.core.dashboardViewsSlice.activeId
  );

  const widgetsDataMap = useSelector(
    (state) => state.core.dashboardWidgetsSlice.data
  );

  const widgetsStatus = useSelector(
    (state) => state.core.dashboardWidgetsSlice.status
  );

  const dataMap = useSelector((state) => state.core.dashboardViewsSlice.data);
  const status = useSelector((state) => state.core.dashboardViewsSlice.status);

  const dashboardData =
    dataMap && !_.isEmpty(dataMap) ? dataMap[dashboardId] : null;

  const dashboardWidgetsData =
    dashboardData &&
    !_.isEmpty(dashboardData) &&
    _.has(dashboardData, "dashboardWidgets")
      ? Object.values(dashboardData["dashboardWidgets"])
      : [];

  const widgetsData =
    widgetsDataMap && !_.isEmpty(widgetsDataMap)
      ? Object.values(widgetsDataMap)
      : [];

  function handleAdd(uuId) {
    if (_.filter(dashboardWidgetsData, { uuId: uuId }).length === 0) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("dashboardViewForm")
      );

      const revisedFormData = {
        ...sanitizedFormData,
        dashboardWidgetUuIds: [
          ...dashboardWidgetsData.map((o) => o.uuId),
          uuId,
        ],
      };

      handleFormSubmit(revisedFormData);
    }
  }

  function handleRemove(uuId) {
    if (_.filter(dashboardWidgetsData, { uuId: uuId }).length > 0) {
      const sanitizedFormData = NtaiUtils.sanitizeFormData(
        getValues("dashboardViewForm")
      );

      const revisedFormData = {
        ...sanitizedFormData,
        dashboardWidgetUuIds: _.filter(
          dashboardWidgetsData.map((o) => o.uuId),
          function (o) {
            return o === uuId ? false : true;
          }
        ),
      };

      handleFormSubmit(revisedFormData);
    }
  }

  function handleAdjustLayout() {}

  function handleAddAll() {
    const availableResourceUuIds = availableObjects.map((o, i) => o["uuId"]);
    if (availableResourceUuIds.length > 0) {
      //   dispatch(
      //     createRoleResource({
      //       roleId: roleId,
      //       formData: {
      //         typeCode: 1,
      //         resourceDataUuIds: [...availableResourceUuIds],
      //       },
      //     })
      //   );
    }
  }

  function handleRemoveAll() {
    // data.forEach((o, i) => {
    //   dispatch(
    //     deleteRoleResource({
    //       roleId: roleId,
    //       uuId: o["uuId"],
    //     })
    //   );
    // });
  }

  useEffect(() => {
    if (
      (widgetsStatus &&
        widgetsStatus.method === "getDashboardWidgets" &&
        widgetsStatus.result === "success") ||
      (status &&
        ["updateDashboardView"].includes(status.method) &&
        status.result === "success")
    ) {
      setAvailableObjects([
        ...transformAvailableObjects(dashboardWidgetsData, widgetsData),
      ]);
      setSelectedObjects([
        ...transformSelectedObjects(dashboardWidgetsData, widgetsDataMap),
      ]);
      dispatch(clearStatus());
      dispatch(clearDashboardWidgetsSliceStatus());
    }
  }, [status, widgetsStatus]);

  useEffect(() => {
    dispatch(getDashboardWidgets());
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Dashboard Widgets"
      subheader="You can assign available sources to the user group. User belonging to the user group will have all privileges pertaining to the source. Use user group source privilege in conjunction with user role to establish the source governance"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Widgets Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            {/* <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleRemoveAll()}
            >
              REMOVE ALL
            </Button>
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleAddAll()}
            >
              ADD ALL
            </Button> */}
            <DashboardViewLayout />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["name"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                                color="inherit"
                              >
                                {fd.name}
                              </Typography>
                            }
                            secondary={fd.description}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No widgets available"
                subheader="You have assigned all widgets"
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                  color="inherit"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No widgets assigned"
                subheader="Add widgets from left panel to the role"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
