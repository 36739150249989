import {
  faClose,
  faDownload,
  faFilePdf,
  faPrint,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCaretDown,
  faChartSimple,
  faFiles,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import React, { useContext } from "react";
import { useState } from "react";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import SearchSourceRecordViewMenuItems from "./SearchSourceRecordViewMenuItems";
import SearchSourceRecordSourceView from "./source-view/SearchSourceRecordSourceView";
import { useEffect } from "react";
import { SearchContext } from "../../../../SearchEdit";
import { SearchSourceContext } from "../../../SearchSource";
import { useDispatch, useSelector } from "react-redux";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import SearchSourceRecordSourceDocsMenu from "./source-doc/SearchSourceRecordSourceDocsMenu";
import SearchSourceRecordSourceDoc from "./source-doc/SearchSourceRecordSourceDoc";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import SearchSourceRecordReportButton from "../report/SearchSourceRecordReportButton";
import SearchSourceRecordSourceRecordView from "./source-record/SearchSourceRecordSourceRecordView";
import {
  getSourceRecordTemplates,
  clearStatus as clearSourceRecordTemplatesSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/template/record/store/sourceRecordTemplatesSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { getSearchSourceRecord } from "../../store/searchSourceRecordsSlice";
const _ = require("lodash");

export default function SearchSourceRecordView({ sourceFields }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [mode, setMode] = useState("source-record");
  const [viewId, setViewId] = useState(null);
  const [viewName, setViewName] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [sourceRecordTemplatesLoaded, setSourceRecordTemplatesLoaded] =
    useState(false);
  const [recordViewMenuOpen, setRecordViewMenuOpen] = useState(false);
  const [anchorElRecordViewMenu, setAnchorElRecordViewMenu] = useState(null);
  const [sourceDocsMenuOpen, setSourceDocsMenuOpen] = useState(false);
  const [anchorElSourceDocsMenu, setAnchorElSourceDocsMenu] = useState(null);

  const { searchData } = React.useContext(SearchContext);
  const { searchSourceData } = React.useContext(SearchSourceContext);
  const { selectedRecord } = React.useContext(SearchSourceRecordListContext);

  const searchId = _.get(searchData, "uuId");
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const sourceDefId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.uuId"
  );

  const recordId = selectedRecord ? selectedRecord["recordId"] : null;

  const sourceRecordTemplatesDataMap = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.data
  );

  const sourceRecordTemplatesSliceStatus = useSelector(
    (state) => state.sources.sourceRecordTemplatesSlice.status
  );

  const handleRecordViewMenuOpen = (event) => {
    setRecordViewMenuOpen(true);
    setAnchorElRecordViewMenu(event.currentTarget);
  };
  const handleRecordViewMenuClose = () => {
    setRecordViewMenuOpen(false);
    setAnchorElRecordViewMenu(null);
  };

  const handleSourceDocsMenuOpen = (event) => {
    setSourceDocsMenuOpen(true);
    setAnchorElSourceDocsMenu(event.currentTarget);
  };
  const handleSourceDocsMenuClose = () => {
    setSourceDocsMenuOpen(false);
    setAnchorElSourceDocsMenu(null);
  };

  const clickAwayHandlerRecordViewMenu = () => {
    setRecordViewMenuOpen(false);
  };

  const clickAwayHandlerSourceDocsMenu = () => {
    setSourceDocsMenuOpen(false);
  };

  function handleSelectView(tmpUuId, type, name) {
    setMode(type === 1 ? "source-record" : "source-view");
    setViewId(tmpUuId);
    setViewName(name);
    setRecordViewMenuOpen(false);
  }

  function handleSelectSourceDoc(sourceFieldUuId) {
    setMode("source-doc");
    setViewId(sourceFieldUuId);
    setSourceDocsMenuOpen(false);
  }

  useEffect(() => {
    if (
      sourceRecordTemplatesDataMap &&
      !_.isEmpty(sourceRecordTemplatesDataMap)
    ) {
      setViewId(Object.values(sourceRecordTemplatesDataMap)[0]["uuId"]);
      setViewName(Object.values(sourceRecordTemplatesDataMap)[0]["name"]);
    }
    setLoaded(true);
  }, [sourceRecordTemplatesLoaded]);

  useEffect(() => {
    if (
      sourceRecordTemplatesSliceStatus &&
      sourceRecordTemplatesSliceStatus.method === "getSourceRecordTemplates" &&
      sourceRecordTemplatesSliceStatus.result === "success"
    ) {
      setSourceRecordTemplatesLoaded(true);
      dispatch(clearSourceRecordTemplatesSliceStatus());
    }
  }, [sourceRecordTemplatesSliceStatus]);

  useEffect(() => {
    if (selectedRecord && !_.isEmpty(selectedRecord) && recordId) {
      dispatch(
        getSourceRecordTemplates({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
        })
      );
    }
  }, [selectedRecord]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            px: theme.spacing(2),
            height: "44px",
            display: "flex",
            alignItems: "center",
            borderBottom: theme.general.border1,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            {/* {_.get(selectedRecord, "_recordId")} */}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: theme.spacing(0.5),
            }}
          >
            {/* <Tooltip title="Reports">
              <IconButton>
                <FontAwesomeIcon size="2xs" icon={faFilePdf} />
              </IconButton>
            </Tooltip> */}

            <SearchSourceRecordReportButton />

            {/* <Tooltip title="Source Documents">
              <IconButton onClick={(e) => handleSourceDocsMenuOpen(e)}>
                <FontAwesomeIcon size="2xs" icon={faFiles} />
              </IconButton>
            </Tooltip> */}

            <Tooltip
              title={
                viewId && viewName
                  ? NtaiUtils.trunc(viewName.toUpperCase(), 125)
                  : "TEMPLATE NOT AVAILABLE"
              }
            >
              <Button
                sx={{
                  fontSize: "11px",
                  gap: theme.spacing(1),
                }}
                size="small"
                color="inherit"
                onClick={(e) => handleRecordViewMenuOpen(e)}
              >
                {viewId && viewName
                  ? NtaiUtils.trunc(viewName.toUpperCase(), 125)
                  : "TEMPLATE NOT AVAILABLE"}
                <FontAwesomeIcon size="xl" icon={faCaretDown} />
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            height: `calc(100% - 44px)`,
            display: "flex",
            width: "100%",
          }}
        >
          {mode === "source-record" && loaded && viewId && (
            <SearchSourceRecordSourceRecordView
              sourceRecordTemplateId={viewId}
            />
          )}

          {mode === "source-view" && viewId && (
            <SearchSourceRecordSourceView sourceViewUuId={viewId} />
          )}
          {mode === "source-doc" && (
            <SearchSourceRecordSourceDoc sourceFieldUuId={viewId} />
          )}
          {!loaded && <NtaiCircularProgress size={24} />}
          {sourceRecordTemplatesLoaded && (!viewId || !viewName) && (
            <NtaiEmptyMessage
              vAlign="center"
              header="No template available"
              subheader="Create or assign source record template in the source - templates section"
            />
          )}
        </Box>
      </Box>
      {recordViewMenuOpen && (
        <NtaiPopover
          open={recordViewMenuOpen}
          anchorEl={anchorElRecordViewMenu}
          handleClose={handleRecordViewMenuClose}
          clickAwayHandler={clickAwayHandlerRecordViewMenu}
        >
          <SearchSourceRecordViewMenuItems
            handleSelectView={handleSelectView}
            handleClose={handleRecordViewMenuClose}
          />
        </NtaiPopover>
      )}

      {sourceDocsMenuOpen && (
        <NtaiPopover
          open={sourceDocsMenuOpen}
          anchorEl={anchorElSourceDocsMenu}
          handleClose={handleSourceDocsMenuClose}
          clickAwayHandler={clickAwayHandlerSourceDocsMenu}
        >
          <SearchSourceRecordSourceDocsMenu
            handleSelectSourceDoc={handleSelectSourceDoc}
            handleClose={handleSourceDocsMenuClose}
          />
        </NtaiPopover>
      )}
    </React.Fragment>
  );
}
