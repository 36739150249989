import { faAdd, faArchive } from "@fortawesome/pro-light-svg-icons";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import NtaiPopper from "@ntai/components/poppers/NtaiPopper";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    height: "42px",
    // flexBasis: "10%",
    alignItems: "center",
    padding: "8px",
    // borderBottom: "1px solid #eee",
    "&:hover": {
      cursor: "grab",
    },
    borderBottom: "1px solid #eee",
    justifyContent: "space-between",
  },
  headerTitle: {
    display: "flex",
    flexBasis: "50%",
    paddingLeft: "4px",
  },
  headerActions: {
    display: "flex",
    flexBasis: "50%",
    paddingRight: "4px",
    justifyContent: "end",
    alignItems: "center",
    gap: "4px",
  },
}));

export default function NtaiSourceWidgetNoteList(props) {
  const theme = useTheme();
  const classes = useStyles();

  const { height, open, anchorEl, handleClose, clickAwayHandler } = props;

  return (
    <NtaiPopper
      open={open}
      anchorEl={anchorEl}
      handleClose={handleClose}
      clickAwayHandler={clickAwayHandler}
      placement="right-start"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 4 }}
    >
      <Box
        sx={{
          display: "flex",
          width: "220px",
          height: height,
          borderTop: open ? theme.general.border2p : null,
          flexDirection: "column",
        }}
      >
        <Box className={classes.header}>
          <Box
            sx={{
              flexBasis: "90%",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Typography>Notes</Typography>
            <IconButton size="small">
              <FontAwesomeIcon size="xs" icon={faAdd} />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexBasis: "10%",
              display: "flex",
              gap: theme.spacing(1),
              justifyContent: "end",
            }}
          >
            <IconButton size="small" onClick={handleClose}>
              <FontAwesomeIcon size="xs" icon={faClose} />
            </IconButton>
          </Box>
        </Box>
        <List sx={{ p: 0 }}>
          <ListItemButton>
            <Box sx={{ display: "flex", pr: "12px" }}>
              <Avatar sx={{ height: 28, width: 28, bgcolor: deepOrange[500] }}>
                S
              </Avatar>
            </Box>
            <ListItemText
              primary="My comments"
              secondary="Lorem Ipsum dreoem etxt always shine"
            />
          </ListItemButton>

          <Divider />
        </List>
      </Box>
    </NtaiPopper>
  );
}
