import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getDashboardUser = createAsyncThunk(
  "dashboardUsers/getDashboardUser",
  async (values, { rejectWithValue }) => {
    try {
      const { dashboardId } = values;
      const response = await server.get(
        `/admin/dashboards/${dashboardId}/users`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrUpdateDashboardUser = createAsyncThunk(
  "dashboardUsers/createOrUpdateDashboardUser",
  async (values, { rejectWithValue }) => {
    try {
      const { dashboardId, formData } = values;
      const response = await server.post(
        `/admin/dashboards/${dashboardId}/users`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const dashboardUsersSlice = createSlice({
  name: "dashboardUsers",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getDashboardUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = { result: "success", method: "getDashboardUser" };
    },
    [getDashboardUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getDashboardUser",
        message: action.payload.message,
      };
    },

    [createOrUpdateDashboardUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = {
        result: "success",
        method: "createOrUpdateDashboardUser",
      };
    },
    [createOrUpdateDashboardUser.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createOrUpdateDashboardUser",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = dashboardUsersSlice.actions;

export default dashboardUsersSlice.reducer;
