import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiDnd from "@ntai/components/dnd/NtaiDnd";
import React from "react";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsStatus,
} from "../../../store/sourcesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { grey } from "@mui/material/colors";

import {
  createSourceOutDatasetScdield,
  getSourceOutDatasetScdFields,
  clearStatus,
  deleteSourceOutDatasetScdField,
  createSourceOutDatasetScdField,
} from "./store/sourceOutDatasetScdFieldsSlice";
import { getSourceDatasetFields } from "../../../pl/stage/dataset/store/sourceFieldsSlice";
const _ = require("lodash");

function transformSelectedFields(selectedFields, allFieldsDataMap) {
  let result = [];
  if (_.isArray(selectedFields)) {
    _.orderBy(selectedFields, ["fieldOrder"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.sourceFieldUuId,
        text: allFieldsDataMap[f.sourceFieldUuId]["label"],
      });
    });
  }

  return result;
}

function transformAvailableFields(allFields, selectedFields) {
  const revisedFields = _.filter(
    _.orderBy(allFields, ["label"], ["asc"]),
    function (f) {
      if (_.filter(selectedFields, { uuId: f.uuId }).length === 0) return true;
    }
  );

  return revisedFields;
}

export default function SourceOutDatasetScdFields({
  sourceDatasetUuId,
  sourceOutDatasetUuId,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [sourceFieldsLoaded, setSourceFieldsLoaded] = useState(false);
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceOutStoreId = useSelector(
    (state) => state.sources.sourceOutStoresSlice.activeId
  );

  const sourceOutStoreDataMap = useSelector(
    (state) => state.sources.sourceOutStoresSlice.data
  );

  const sourceOutStoreData =
    sourceOutStoreDataMap && !_.isEmpty(sourceOutStoreDataMap)
      ? _.filter(Object.values(sourceOutStoreDataMap), {
          uuId: sourceOutStoreId,
        })[0]
      : {};

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceOutStoreId: sourceOutStoreId,
    sourceOutDatasetId: sourceOutDatasetUuId,
  };

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourceFieldsSlice.data
  );

  const sourceOutDatasetScdFieldsDataMap = useSelector(
    (state) => state.sources.sourceOutDatasetScdFieldsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceOutDatasetScdFieldsSlice.status
  );

  const sourceOutDatasetScdFields =
    sourceOutDatasetScdFieldsDataMap &&
    !_.isEmpty(sourceOutDatasetScdFieldsDataMap)
      ? Object.values(sourceOutDatasetScdFieldsDataMap)
      : [];

  const sourceFields =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  function handleReorder(items) {
    // const reorderedSourceRecordTemplateFields = items.map((f, i) => {
    //   return {
    //     fieldUuId: f.id,
    //     fieldOrder: i + 1,
    //   };
    // });
    // const selectedSourceRecordTemplateFields = selectedFields.map((f, i) => {
    //   return {
    //     fieldUuId: f.uuId,
    //     fieldOrder: i + 1,
    //   };
    // });
    // if (
    //   !_.isEqual(
    //     reorderedSourceRecordTemplateFields,
    //     selectedSourceRecordTemplateFields
    //   )
    // ) {
    //   handleUpdate(reorderedSourceRecordTemplateFields);
    // }
  }

  function handleAdd(sourceFieldUuId) {
    dispatch(
      createSourceOutDatasetScdField({
        ...pathVariables,
        formData: {
          sourceFieldUuId: sourceFieldUuId,
          fieldOrder:
            selectedFields && _.isArray(selectedFields)
              ? selectedFields.length + 1
              : 1,
        },
      })
    );
  }

  function handleRemove(sourceFieldUuId) {
    const filteredFields = _.filter(
      Object.values(sourceOutDatasetScdFieldsDataMap),
      function (f) {
        if (f.sourceFieldUuId === sourceFieldUuId) return true;
      }
    );

    dispatch(
      deleteSourceOutDatasetScdField({
        ...pathVariables,
        uuId: filteredFields[0]["uuId"],
      })
    );
  }

  function handleRemove1(sourceFieldUuId) {
    const filteredFields = _.filter(selectedFields, function (f) {
      if (f.uuId !== sourceFieldUuId) return true;
    });

    const finalSelectedFields = filteredFields.map((f, i) => {
      return {
        sourceFieldUuId: f.uuId,
        fieldOrder: i + 1,
      };
    });

    dispatch(
      deleteSourceOutDatasetScdField({
        ...pathVariables,
        formData: finalSelectedFields,
      })
    );
  }

  //   function handleUpdate(sourceRecordTemplateFields) {
  //     console.log("Update this: ", sourceRecordTemplateFields);
  //     dispatch(
  //       updateSourceRecordTemplateFields({
  //         sourceDefId: sourceDefId,
  //         sourceId: sourceId,
  //         uuId: templateId,
  //         formData: {
  //           sourceRecordTemplateFields: sourceRecordTemplateFields,
  //         },
  //       })
  //     );
  //   }

  // useEffect(() => {
  //   const tmpSelectedFields = transformSelectedFields(
  //     _.get(data, "sourceRecordTemplateFields")
  //   );

  //   setSelectedFields([...tmpSelectedFields]);
  // }, [data]);

  useEffect(() => {
    dispatch(
      getSourceOutDatasetScdFields({
        ...pathVariables,
      })
    );
  }, [sourceOutDatasetUuId]);

  useEffect(() => {
    if (
      sourceFields.length > 0 &&
      status &&
      status.result === "success" &&
      [
        "getSourceOutDatasetScdFields",
        "createSourceOutDatasetScdField",
        "deleteSourceOutDatasetScdField",
      ].includes(status.method)
    ) {
      const tmpSelectedFields = transformSelectedFields(
        sourceOutDatasetScdFields,
        sourceFieldsDataMap
      );

      const tmpAvailableFields = transformAvailableFields(
        sourceFields,
        tmpSelectedFields
      );

      setSelectedFields([...tmpSelectedFields]);
      setAvailableFields([...tmpAvailableFields]);
      dispatch(clearStatus());
      setFieldsLoaded(true);
    }
  }, [status]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "54px",
          gap: theme.spacing(1),
        }}
      >
        <Button sx={{ fontSize: "12px" }} size="small">
          REMOVE ALL
        </Button>
        <Button sx={{ fontSize: "12px" }} variant="contained" size="small">
          ADD ALL
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: `calc(100% - 54px)`,
          border: theme.general.border1,
        }}
      >
        <Box
          sx={{
            flexBasis: "30%",
            display: "flex",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          {fieldsLoaded &&
            _.isArray(availableFields) &&
            availableFields.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableFields, ["label"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`field-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "12px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <Box sx={{ pr: theme.spacing(1) }}>
                            <NtaiBadge
                              title={
                                _.get(fd, "esDataTypeDecode") &&
                                _.get(fd, "esDataTypeDecode")
                                  .substring(0, 1)
                                  .toLowerCase()
                              }
                              fg={grey[700]}
                              bg={grey[100]}
                            />
                          </Box>

                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                                color="inherit"
                              >
                                {fd.label}
                              </Typography>
                            }
                            secondary={fd.name}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
        </Box>
        <Box
          sx={{
            flexBasis: "70%",
            display: "flex",
          }}
        >
          <Scrollbar>
            <Box sx={{ p: theme.spacing(2) }}>
              {selectedFields &&
                _.isArray(selectedFields) &&
                selectedFields.length > 0 && (
                  <NtaiDnd
                    idField="uuId"
                    labelField="text"
                    data={selectedFields}
                    handleCallback={handleReorder}
                    direction="row"
                    flexWrap="wrap"
                    secondaryAction="close"
                    handleSecondaryAction={handleRemove}
                  />
                )}
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </Box>
  );
}
