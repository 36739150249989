import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourceCustomFieldElems = createAsyncThunk(
  "sourceCustomFieldElems/getSourceCustomFieldElems",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceCustomFieldId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/custom-fields/${sourceCustomFieldId}/elems`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSourceCustomFieldElem = createAsyncThunk(
  "sourceCustomFieldElems/createSourceCustomFieldElem",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceCustomFieldId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/custom-fields/${sourceCustomFieldId}/elems`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSourceCustomFieldElem = createAsyncThunk(
  "sourceCustomFieldElems/deleteSourceCustomFieldElem",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourceCustomFieldId, uuId } = values;
      const response = await server.delete(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/custom-fields/${sourceCustomFieldId}/elems/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourceCustomFieldElemsSlice = createSlice({
  name: "sourceCustomFieldElems",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourceCustomFieldElems.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSourceCustomFieldElems" };
    },
    [getSourceCustomFieldElems.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourceCustomFieldElems",
        message: action.payload.message,
      };
    },

    [createSourceCustomFieldElem.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createSourceCustomFieldElem",
      };
    },
    [createSourceCustomFieldElem.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSourceCustomFieldElem",
        message: action.payload.message,
      };
    },

    [deleteSourceCustomFieldElem.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteSourceCustomFieldElem",
      };
    },
    [deleteSourceCustomFieldElem.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSourceCustomFieldElem",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = sourceCustomFieldElemsSlice.actions;

export default sourceCustomFieldElemsSlice.reducer;
