import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  createObjectInfoListingField,
  clearStatus,
  deleteObjectInfoListingField,
  getObjectInfoListingFields,
} from "./store/objectInfoListingFieldsSlice";
import {
  getObjectFields,
  clearStatus as clearObjectFieldsSliceStatus,
} from "../../field/store/objectFieldsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["fieldLabel"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        name: allObjectsDataMap[f.fieldUuId]["fieldName"],
        label: allObjectsDataMap[f.fieldUuId]["fieldLabel"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["fieldLabel"], ["asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.fieldUuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function ObjectInfoListingFields() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const domainId = useSelector((state) => state.core.domainsSlice.activeId);
  const objectId = useSelector((state) => state.core.objectsSlice.activeId);



  const objectFieldsSliceStatus = useSelector(
    (state) => state.core.objectFieldsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.core.objectInfoListingFieldsSlice.data
  );

  const status = useSelector(
    (state) => state.core.objectInfoListingFieldsSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const fieldsDataMap = useSelector(
    (state) => state.core.objectFieldsSlice.data
  );
  
  const fieldsData =
    fieldsDataMap && !_.isEmpty(fieldsDataMap)
      ? Object.values(fieldsDataMap)
      : [];

  function handleAdd(uuId) {
    if (_.filter(data, { fieldUuId: uuId }).length === 0) {
      dispatch(
        createObjectInfoListingField({
          domainId: domainId,
          objectId: objectId,
          formData: {
            fieldUuId: uuId,
          },
        })
      );
    }
  }

  function handleRemove(uuId) {
    if (_.filter(data, { uuId: uuId }).length > 0) {
      dispatch(
        deleteObjectInfoListingField({
          domainId: domainId,
          objectId: objectId,
          uuId: uuId,
        })
      );
    }
  }

  useEffect(() => {
    if (
      status &&
      [
        "getObjectInfoListingFields",
        "createObjectInfoListingField",
        "deleteObjectInfoListingField",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setAvailableObjects([...transformAvailableObjects(data, fieldsData)]);
      setSelectedObjects([...transformSelectedObjects(data, fieldsDataMap)]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      objectFieldsSliceStatus &&
      objectFieldsSliceStatus.result === "success" &&
      objectFieldsSliceStatus.method === "getObjectFields"
    ) {
      dispatch(
        getObjectInfoListingFields({
          domainId: domainId,
          objectId: objectId,
        })
      );
      dispatch(clearObjectFieldsSliceStatus());
    }
  }, [objectFieldsSliceStatus]);

  useEffect(() => {
    dispatch(
      getObjectFields({
        domainId: domainId,
        objectId: objectId,
      })
    );
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Object Listing Fields"
      subheader="You can assign available sources to the user group. User belonging to the user group will have all privileges pertaining to the source. Use user group source privilege in conjunction with user role to establish the source governance"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "320px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Fields Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              height: "54px",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REORDER FIELDS
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["fieldName"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.fieldName}
                              </Typography>
                            }
                            secondary={fd.fieldLabel}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No fields available"
                subheader="You have assigned all fields"
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["fieldOrder"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.name}
                                </Typography>
                              }
                              secondary={so.label}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No fields assigned"
                subheader="Add fields from left panel to the role"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
