import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import React from "react";

export default function NtaiIconButton(props) {
  const { icon, handleClick, disabled } = props;
  return (
    <IconButton
      id={props.hasOwnProperty("id") ? props.id : "ntai-icon-button"}
      sx={{ height: "24px", width: "24px", borderRadius: "6px" }}
      onClick={(e) => handleClick(e)}
      disabled={disabled ? disabled : false}
    >
      <FontAwesomeIcon size="2xs" icon={icon} />
    </IconButton>
  );
}
