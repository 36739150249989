import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { faArchive } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import {
  getUsers,
  clearStatus as clearUsersSliceStatus,
} from "../../../admin/user/store/usersSlice";
import {
  getContacts,
  clearStatus as clearContactsSliceStatus,
} from "../../contact/store/contactsSlice";
import {
  getDistributionGroupObjects,
  clearStatus,
  createDistributionGroupObjects,
  deleteDistributionGroupObject,
} from "./store/distributionGroupObjectsSlice";
import { id } from "date-fns/locale";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function getTypeDecode(typeCode) {
  if (typeCode === 1) return "U";
  if (typeCode === 2) return "UG";
  if (typeCode === 3) return "C";
  if (typeCode === 4) return "CG";
}

function fetchAllObjectsArray(usersData, contactsData) {
  let result = [];
  usersData.forEach((o, i) => {
    result.push({
      uuId: o["uuId"],
      name: o["userName"],
      typeCode: 1,
    });
  });

  contactsData.forEach((o, i) => {
    result.push({
      uuId: o["uuId"],
      name: o["firstName"] + " " + o["lastName"],
      typeCode: 3,
    });
  });

  return result;
}

function fetchAllObjectsMap(usersDataMap, contactsDataMap) {
  let result = {};

  if (usersDataMap && !_.isEmpty(usersDataMap)) {
    Object.keys(usersDataMap).forEach((o, i) => {
      result[o] = {
        uuId: usersDataMap[o]["uuId"],
        name: usersDataMap[o]["userName"],
        typeCode: 1,
      };
    });
  }

  if (contactsDataMap && !_.isEmpty(contactsDataMap)) {
    Object.keys(contactsDataMap).forEach((o, i) => {
      result[o] = {
        uuId: contactsDataMap[o]["uuId"],
        name: contactsDataMap[o]["firstName"],
        typeCode: 3,
      };
    });
  }

  return result;
}

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f["uuId"],
        typeCode: f["typeCode"],
        primary: allObjectsDataMap[f.objectUuId]["name"],
        secondary: allObjectsDataMap[f.objectUuId]["name"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["name"], ["asc"]),
    function (f) {
      if (
        _.filter(selectedObjects, function (g) {
          if (g.objectUuId === f.uuId) return true;
          else return false;
        }).length === 0
      )
        return true;
    }
  );

  return result;
}

export default function DistributionGroupObjects() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [allObjectsArr, setAllObjectsArr] = useState([]);
  const [allObjectsMap, setAllObjectsMap] = useState({});
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [contactsLoaded, setContactsLoaded] = useState(false);

  const distributionGroupId = useSelector(
    (state) => state.core.distributionGroupsSlice.activeId
  );

  const usersDataMap = useSelector((state) => state.core.usersSlice.data);
  const usersSliceStatus = useSelector((state) => state.core.usersSlice.status);
  const usersData =
    usersDataMap && !_.isEmpty(usersDataMap) ? Object.values(usersDataMap) : [];

  const contactsDataMap = useSelector((state) => state.core.contactsSlice.data);
  const contactsSliceStatus = useSelector(
    (state) => state.core.contactsSlice.status
  );
  const contactsData =
    contactsDataMap && !_.isEmpty(contactsDataMap)
      ? Object.values(contactsDataMap)
      : [];

  const dataMap = useSelector(
    (state) => state.core.distributionGroupObjectsSlice.data
  );
  const status = useSelector(
    (state) => state.core.distributionGroupObjectsSlice.status
  );
  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  function handleAdd(uuId, typeCode) {
    if (_.filter(data, { objectUuId: uuId }).length === 0) {
      dispatch(
        createDistributionGroupObjects({
          distributionGroupId: distributionGroupId,
          formData: {
            items: [
              {
                typeCode: typeCode,
                objectUuId: uuId,
              },
            ],
          },
        })
      );
    }
  }

  function handleRemove(uuId) {
    if (_.filter(data, { uuId: uuId }).length > 0) {
      dispatch(
        deleteDistributionGroupObject({
          distributionGroupId: distributionGroupId,
          uuId: uuId,
        })
      );
    }
  }

  function handleAddAll() {
    // const availableResourceUuIds = availableObjects.map((o, i) => o["uuId"]);
    // if (availableResourceUuIds.length > 0) {
    //   dispatch(
    //     createContactGroup({
    //       distributionGroupId: distributionGroupId,
    //       formData: {
    //         typeCode: 1,
    //         resourceDataUuIds: [...availableResourceUuIds],
    //       },
    //     })
    //   );
    // }
  }

  useEffect(() => {
    if (
      status &&
      [
        "getDistributionGroupObjects",
        "createDistributionGroupObjects",
        "deleteDistributionGroupObject",
      ].includes(status.method) &&
      status.result === "success" &&
      allObjectsArr.length > 0 &&
      !_.isEmpty(allObjectsMap)
    ) {
      setAvailableObjects([
        ...transformAvailableObjects(
          data,
          fetchAllObjectsArray(usersData, contactsData)
        ),
      ]);
      setSelectedObjects([
        ...transformSelectedObjects(
          data,
          fetchAllObjectsMap(usersDataMap, contactsDataMap)
        ),
      ]);
      dispatch(clearStatus());
    }
  }, [status, allObjectsArr, allObjectsMap]);

  useEffect(() => {
    if (usersLoaded && contactsLoaded) {
      dispatch(getDistributionGroupObjects(distributionGroupId));
      setUsersLoaded(false);
      setContactsLoaded(false);
      setAllObjectsArr(fetchAllObjectsArray(usersData, contactsData));
      setAllObjectsMap(fetchAllObjectsMap(usersDataMap, contactsDataMap));
    }
  }, [usersLoaded, contactsLoaded]);

  useEffect(() => {
    if (
      contactsSliceStatus &&
      contactsSliceStatus.result === "success" &&
      contactsSliceStatus.method === "getContacts"
    ) {
      setContactsLoaded(true);
      dispatch(clearContactsSliceStatus());
    }
  }, [usersSliceStatus]);

  useEffect(() => {
    if (
      usersSliceStatus &&
      usersSliceStatus.result === "success" &&
      usersSliceStatus.method === "getUsers"
    ) {
      setUsersLoaded(true);
      dispatch(clearUsersSliceStatus());
    }
  }, [usersSliceStatus]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getContacts());
  }, []);

  return (
    <NtaiPanel
      width="80%"
      height="100%"
      header="Manage Contacts"
      subheader="You can assign available contacts to the distribution group"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Contacts Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              // onClick={() => handleRemoveAll()}
            >
              REMOVE ALL
            </Button>
            <Button
              sx={{ fontSize: "12px" }}
              size="small"
              onClick={() => handleAddAll()}
            >
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["name"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: theme.spacing(2),
                            borderRadius: 0,
                          }}
                          secondaryAction={
                            <Button
                              sx={{ fontSize: "11px" }}
                              variant="outlined"
                              size="small"
                              onClick={() => handleAdd(fd.uuId, fd.typeCode)}
                            >
                              ADD
                            </Button>
                          }
                        >
                          <Chip
                            sx={{ fontSize: "11px" }}
                            size="small"
                            label={getTypeDecode(fd["typeCode"])}
                          />
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.name}
                              </Typography>
                            }
                            secondary={fd.name}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No contacts available"
                subheader="You have assigned all contacts"
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: theme.spacing(2),
                              borderRadius: 0,
                            }}
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <Chip
                              sx={{ fontSize: "11px" }}
                              size="small"
                              label={getTypeDecode(so["typeCode"])}
                            />
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No contacts assigned"
                subheader="Add contacts from left panel to the distribution group"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
