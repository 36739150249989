import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchActionShares = createAsyncThunk(
  "searchShares/getSearchActionShares",
  async (searchId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/search/${searchId}/share`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchActionShare = createAsyncThunk(
  "searchShares/getSearchActionShare",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchShareId } = values;
      const response = await server.get(
        `/search/${searchId}/share/${searchShareId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSearchActionShare = createAsyncThunk(
  "searchShares/createSearchActionShare",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, formData } = values;
      const response = await server.post(`/search/${searchId}/share`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSearchActionShare = createAsyncThunk(
  "searchShares/updateSearchActionShare",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, uuId, formData } = values;
      const response = await server.patch(
        `/search/${searchId}/share/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSearchActionShare = createAsyncThunk(
  "searchShares/deleteSearchActionShare",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, uuId } = values;
      const response = await server.delete(`/search/${searchId}/share/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSharesSlice = createSlice({
  name: "searchShares",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSearchActionShares.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getSearchActionShares" };
    },
    [getSearchActionShares.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchActionShares",
        message: action.payload.message,
      };
    },
    [getSearchActionShare.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getSearchActionShare" };
    },
    [getSearchActionShare.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSearchActionShare",
        message: action.payload.message,
      };
    },
    [createSearchActionShare.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createSearchActionShare" };
    },
    [createSearchActionShare.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createSearchActionShare",
        message: action.payload.message,
      };
    },
    [updateSearchActionShare.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateSearchActionShare" };
    },
    [updateSearchActionShare.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateSearchActionShare",
        message: action.payload.message,
      };
    },
    [deleteSearchActionShare.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteSearchActionShare" };
    },
    [deleteSearchActionShare.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteSearchActionShare",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = searchSharesSlice.actions;

export default searchSharesSlice.reducer;
