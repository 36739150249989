import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CHART_CODES } from "app/main/constants/NtaiCodelistConstants";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import SourceWidgetFieldFilterForm from "../filter/SourceWidgetFieldFilterForm";
import SourceWidgetFieldsForm from "../fields/SourceWidgetFieldsForm";
import SourceWidgetUtil from "../../util/SourceWidgetUtil";
import SourceWidgetFieldColorFormat from "../color-scheme/SourceWidgetFieldColorFormat";
import SourceWidgetTitle from "./SourceWidgetTitle";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import SourceWidgetFormPanel from "./SourceWidgetFormPanel";
const _ = require("lodash");

const BAR_VERTICAL_CHART = 1;
const BAR_HORIZONTAL_CHART = 2;
const DATE_HIST_CHART = 3;
const TIMESERIES_CHART = 4;

function getChartCode(widget) {
  if (
    [
      CHART_CODES.BAR_VERTICAL,
      CHART_CODES.BAR_VERTICAL_SERIES,
      CHART_CODES.BAR_VERTICAL_STACKED,
    ].includes(widget["widget"]["contentCode"])
  )
    return BAR_VERTICAL_CHART;
  else if (
    [
      CHART_CODES.BAR_HORIZONTAL,
      CHART_CODES.BAR_HORIZONTAL_SERIES,
      CHART_CODES.BAR_HORIZONTAL_STACKED,
    ].includes(widget["widget"]["contentCode"])
  )
    return BAR_HORIZONTAL_CHART;
  else if (
    [CHART_CODES.DATE_HISTOGRAM].includes(widget["widget"]["contentCode"])
  )
    return DATE_HIST_CHART;
  else if ([CHART_CODES.TIME_SERIES].includes(widget["widget"]["contentCode"]))
    return TIMESERIES_CHART;
}

export default function SourceWidgetXYChartForm({
  widget,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const { getValues, reset, setValue } = useFormContext();
  const [widgetFields, setWidgetFields] = useState(
    widget["sourceWidgetFields"]
  );

  const chartCode = getChartCode(widget);

  const dimensionFields =
    SourceWidgetUtil.getDimensionWidgetFields(widgetFields);

  const measureFields = SourceWidgetUtil.getMeasureWidgetFields(widgetFields);

  const dateFields = SourceWidgetUtil.getDateWidgetFields(widgetFields);

  const dimensionFieldOptions =
    dimensionFields && _.isArray(dimensionFields) && dimensionFields.length > 0
      ? NtaiUtils.getSelectOptions(dimensionFields, "uuId", "fieldName")
      : [];

  const measureFieldOptions =
    measureFields && _.isArray(measureFields) && measureFields.length > 0
      ? NtaiUtils.getSelectOptions(measureFields, "uuId", "fieldName")
      : [];

  const dateFieldOptions =
    dateFields && _.isArray(dateFields) && dateFields.length > 0
      ? NtaiUtils.getSelectOptions(dateFields, "uuId", "fieldName")
      : [];

  const revisedMeasureFieldOptions = [
    { value: "0", label: "Count" },
    ...measureFieldOptions,
  ];

  function onHandleSave() {
    const widgetFormData = getValues("widgetForm");

    const sanitizedFormData = NtaiUtils.sanitizeFormData(widgetFormData);

    let revisedSourceWidgetFields = _.cloneDeep(widget["sourceWidgetFields"]);
    const sanitizedFormFieldsData = sanitizedFormData["sourceWidgetFields"];

    revisedSourceWidgetFields.forEach((f, i) => {
      const sanitizedFormFieldData = NtaiUtils.sanitizeFormData(
        _.filter(sanitizedFormFieldsData, {
          uuId: f["uuId"],
        })[0]
      );

      f["headerName"] = sanitizedFormFieldData["headerName"];

      if (
        [BAR_VERTICAL_CHART, DATE_HIST_CHART, TIMESERIES_CHART].includes(
          chartCode
        )
      ) {
        if (
          sanitizedFormData["yAxisFieldUuId"] &&
          sanitizedFormData["yAxisFieldUuId"].length > 1 &&
          f["uuId"] === sanitizedFormData["yAxisFieldUuId"]
        )
          f["sizeFg"] = 1;
        else f["sizeFg"] = null;
      } else if (BAR_HORIZONTAL_CHART === chartCode) {
        if (
          sanitizedFormData["xAxisFieldUuId"] &&
          sanitizedFormData["xAxisFieldUuId"].length > 1 &&
          f["uuId"] === sanitizedFormData["xAxisFieldUuId"]
        )
          f["sizeFg"] = 1;
        else f["sizeFg"] = null;
      }

      if (SourceWidgetUtil.isWidgetFieldMeasure(f)) {
        f["metricCode"] = sanitizedFormFieldData["metricCode"];
        f["esAggFilterScript"] = sanitizedFormFieldData["esAggFilterScript"];
      }

      if (SourceWidgetUtil.isWidgetFieldDate(f)) {
        f["dateIntervalCode"] = sanitizedFormFieldData["dateIntervalCode"];
      }

      if (SourceWidgetUtil.isWidgetFieldDimension(f)) {
        let sourceWidgetFieldFiltersResult = [];
        if (_.isArray(sanitizedFormFieldData["sourceWidgetFieldFilters"])) {
          const sourceWidgetFieldFilters = _.get(
            sanitizedFormFieldData,
            "sourceWidgetFieldFilters"
          );

          if (
            _.isArray(sourceWidgetFieldFilters) &&
            sourceWidgetFieldFilters.length > 0
          ) {
            sourceWidgetFieldFilters.forEach((swf) => {
              if (swf["typeCode"] !== undefined && swf["typeCode"]["value"]) {
                sourceWidgetFieldFiltersResult.push({
                  value: swf["value"],
                  typeCode: swf["typeCode"]["value"],
                });
              }
            });
            f["sourceWidgetFieldFilters"] = sourceWidgetFieldFiltersResult;
          }
        }
      }
    });

    sanitizedFormData["sourceWidgetFields"] = revisedSourceWidgetFields;

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    let widgetCopy = _.cloneDeep(widget);
    reset({ widgetForm: widgetCopy });
  }, [widget]);

  return (
    <SourceWidgetFormPanel
      handleCancel={handleCancel}
      handleSave={onHandleSave}
    >
      <NtaiTabs>
        <Box label="TITLE">
          <SourceWidgetTitle />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FIELDS">
          <SourceWidgetFieldsForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="AXIS">
          <NtaiPanel
            height="100%"
            subheader="Provide meaningful name for the widget. Use other fields to specify measures and other values for the selected field"
          >
            {[BAR_VERTICAL_CHART, BAR_HORIZONTAL_CHART].includes(chartCode) &&
              dimensionFieldOptions &&
              dimensionFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={dimensionFieldOptions}
                  name={`widgetForm.${
                    chartCode === BAR_VERTICAL_CHART
                      ? "xAxisFieldUuId"
                      : "yAxisFieldUuId"
                  }`}
                  label={
                    chartCode === BAR_VERTICAL_CHART
                      ? "X-Axis Field"
                      : "Y-Axis Field"
                  }
                />
              )}
            {chartCode === DATE_HIST_CHART &&
              dateFieldOptions &&
              dateFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={dateFieldOptions}
                  name="widgetForm.xAxisFieldUuId"
                  label="X-Axis Field"
                />
              )}
            {revisedMeasureFieldOptions &&
              revisedMeasureFieldOptions.length > 0 && (
                <NtaiSelectField
                  options={revisedMeasureFieldOptions}
                  name={`widgetForm.${
                    [
                      BAR_VERTICAL_CHART,
                      DATE_HIST_CHART,
                      TIMESERIES_CHART,
                    ].includes(chartCode)
                      ? "yAxisFieldUuId"
                      : "xAxisFieldUuId"
                  }`}
                  label={
                    [
                      BAR_VERTICAL_CHART,
                      DATE_HIST_CHART,
                      TIMESERIES_CHART,
                    ].includes(chartCode)
                      ? "Y-Axis Field"
                      : "X-Axis Field"
                  }
                />
              )}
          </NtaiPanel>
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="FILTERS">
          <SourceWidgetFieldFilterForm widget={widget} />
        </Box>
        <Box sx={{ height: "auto", display: "flex" }} label="COLOR">
          <SourceWidgetFieldColorFormat
            handleWidgetSave={handleSave}
            widget={widget}
          />
        </Box>
      </NtaiTabs>
    </SourceWidgetFormPanel>
  );
}
