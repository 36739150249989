import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Hidden, IconButton, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import History from "@ntai/@history";
import React from "react";
const _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  pageHeaderContentStyle: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    marginLeft: theme.spacing(0),
  },
  pageHeaderContentTitleStyle: {
    display: "flex",
    flexBasis: "60%",
    height: "100%",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    gap: theme.spacing(1),
    // background: "lime",
  },
  pageHeaderContentActionStyle: {
    display: "flex",
    flexBasis: "40%",
    height: "100%",
    justifyContent: "end",
    alignItems: "center",
    paddingRight: theme.spacing(2),
  },
}));

export default function NtaiPageHeader({
  startAction,
  back,
  title,
  titleExtras,
  titleHelpText,
  headerActions,
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.pageHeaderContentStyle}>
      <Box className={classes.pageHeaderContentTitleStyle}>
        {startAction}
        {back && (
          <IconButton
            onClick={() => (_.isString(back) ? History.push(back) : back())}
          >
            <FontAwesomeIcon size="xs" icon={faArrowLeft} />
          </IconButton>
        )}

        <Typography variant="h5" fontWeight={700}>
          {title}
        </Typography>
        {titleExtras}
      </Box>
      <Box className={classes.pageHeaderContentActionStyle}>
        {headerActions ? headerActions : null}
      </Box>
    </Box>
  );
}
