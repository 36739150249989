import RuleCreate from "./RuleCreate";
import RuleEdit from "./RuleEdit";
import RuleList from "./RuleList";

const RuleConfig = {
  routes: [
    {
      path: "/admin/rules",
      exact: true,
      component: RuleList,
    },
    {
      path: "/admin/rules/create",
      exact: true,
      component: RuleCreate,
    },
    {
      path: "/admin/rules/:id/edit",
      exact: true,
      component: RuleEdit,
    },
  ],
};

export default RuleConfig;
