import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEntity,
  getEntityObject,
  getEntities,
  setObjectUuId,
  clearStatus,
} from "./store/entitiesSlice";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useTheme } from "@mui/material";
import history from "@ntai/@history";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import EntityUtils from "./util/EntityUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const commonFields = [
  {
    id: "dateModified",
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  { id: "userModified", label: "Modified By" },
];

function getEntityNames(selectedIds, dataMap) {
  let result = [];

  selectedIds.forEach((id) => {
    if (_.has(dataMap, id)) {
      const entity = dataMap[id];
      result.push(EntityUtils.getPrimaryFieldValue(entity));
    }
  });

  return result;
}

function processData(objectData) {
  let result = [];

  objectData.forEach((entity, index) => {
    const objUuId = _.get(entity, "uuId");
    const objFields = _.get(entity, "fields");

    const obj = {};
    obj["uuId"] = objUuId;

    objFields.forEach((field, index) => {
      const objKey = _.get(field, "fieldName");
      const objValue =
        _.get(field, "fieldCodelistCode") &&
        _.get(field, "fieldCodelistCode") > 0
          ? _.get(field, "fieldCodeDecodeValue")
          : _.get(field, "fieldValue");
      obj[objKey] = objValue;
    });

    commonFields.forEach((commonField) => {
      obj[commonField.id] = _.get(entity, commonField.id);
    });

    result.push(obj);
  });

  return result;
}

function buildHeadCells(obj) {
  let headers = [];

  headers.push({
    id: "uuId",
    numeric: false,
    disablePadding: false,
    ignore: true,
    label: "UUID",
  });

  const fields = _.get(obj, "objectFields");
  fields.forEach((field, i) => {
    if (_.get(field, "fieldUiListingFg") === 1) {
      headers.push({
        id: _.get(field, "fieldName"),
        numeric: _.get(field, "fieldTypeCode") === 2 ? true : false,
        disablePadding: false,
        label: _.get(field, "fieldLabel"),
        fontWeight: i === 0 ? 700 : null,
        // fontWeight: _.get(field, "primaryFieldFg") === 1 ? "700" : null,
      });
    }
  });

  commonFields.forEach((commonField) => {
    headers.push({
      id: commonField.id,
      numeric: false,
      disablePadding: false,
      ignore: false,
      label: commonField.label,
      transformFunc: _.has(commonField, "transformFunc")
        ? commonField["transformFunc"]
        : null,
    });
  });

  headers.push({
    id: "uuId",
    numeric: false,
    disablePadding: false,
    ignore: true,
    label: "UUID",
  });

  return headers;
}

const toolbarActions = [
  {
    label: "Delete",
    icon: "archive",
    value: "delete",
  },
];

export default function EntityList(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [currentId, setCurrentId] = useState();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [action, setAction] = useState();
  const [loaded, setLoaded] = useState(false);
  const [selectedIds, setSelectedIds] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const objectId = props.match.params.objectId;
  const domainId = props.match.params.domainId;

  const status = useSelector((state) => state.core.entitiesSlice.status);

  const objectEntitiesDataMaps = useSelector(
    (state) => state.core.entitiesSlice.data
  );

  const objectEntitiesDataMap = _.get(objectEntitiesDataMaps, objectId);

  const objectEntitiesData =
    objectEntitiesDataMap && Object.values(objectEntitiesDataMap);

  const data = Array.isArray(objectEntitiesData)
    ? processData(objectEntitiesData)
    : [];

  const entityObjectsDataMap = useSelector(
    (state) => state.core.entitiesSlice.entityObjects
  );

  const entityObjectData =
    entityObjectsDataMap &&
    _.has(entityObjectsDataMap, objectId) &&
    _.get(entityObjectsDataMap, objectId);

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems(getEntityNames(selected, objectEntitiesDataMap));
    if (action === "delete") confirmDelete();
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(
          deleteEntity({ domainId: domainId, objectId: objectId, uuId: id })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function addRecord() {
    history.push(
      `/entities-library/domains/${domainId}/objects/${objectId}/entities/create`
    );
  }

  useEffect(() => {
    if (status.result === "success" && status.method === "getEntities") {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteEntity"
    ) {
      console.log("Remaining: ", selectedIds, objectEntitiesDataMap);
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(objectEntitiesDataMap).includes(o) ? true : false;
      });

      console.log("remainingIds: ", remainingIds, selectedItems);

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Entities "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }
  }, [status]);

  useEffect(() => {
    dispatch(getEntities({ domainId: domainId, objectId: objectId }));
    dispatch(setObjectUuId(objectId));
    dispatch(getEntityObject({ domainId: domainId, objectId: objectId }));
  }, [objectId]);

  return (
    <>
      <NtaiPage padding={2} title={_.get(entityObjectData, "name")}>
        {loaded && entityObjectData && (
          <NtaiCrudTable
            helpText="You can manage entities for the object you have selected in this section. Entity fields are inherited from the object definitions"
            rows={data}
            headCells={buildHeadCells(entityObjectData)}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/entities-library/domains/${domainId}/objects/${objectId}/entities`}
            handleToolbarAction={handleToolbarAction}
            handleDelete={confirmDelete}
            title={_.get(entityObjectData, "name")}
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          getEntityNames(selectedIds, objectEntitiesDataMap)
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
