import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import {
  getSourceDefinitions,
  clearStatus as clearSourceDefsSliceStatus,
} from "app/main/pages/core/sourcedefinition/store/sourceDefinitionsSlice";
import { faArchive, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUserGroupResources,
  clearStatus,
  createUserGroupResource,
  deleteUserGroupResource,
} from "../store/userGroupResourcesSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function transformSelectedObjects(selectedObjects, allObjectsDataMap) {
  let result = [];
  if (_.isArray(selectedObjects)) {
    _.orderBy(selectedObjects, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f.uuId,
        primary: allObjectsDataMap[f.resourceDataUuId]["abbrev"],
        secondary: allObjectsDataMap[f.resourceDataUuId]["name"],
      });
    });
  }

  return result;
}

function transformAvailableObjects(selectedObjects, allObjects) {
  const result = _.filter(
    _.orderBy(allObjects, ["abbrev"], ["asc"]),
    function (f) {
      if (_.filter(selectedObjects, { resourceDataUuId: f.uuId }).length === 0)
        return true;
    }
  );

  return result;
}

export default function UserGroupSources() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [sourcesLoaded, setSourcesLoaded] = useState(false);
  const [userGroupSourcesLoaded, setUserGroupSourcesLoaded] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [availableObjects, setAvailableObjects] = useState([]);

  const userGroupId = useSelector(
    (state) => state.core.userGroupsSlice.activeId
  );

  const sourceDefsDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefsSliceStatus = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.status
  );

  const dataMap = useSelector(
    (state) => state.core.userGroupResourcesSlice.data
  );

  const status = useSelector(
    (state) => state.core.userGroupResourcesSlice.status
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const sourceDefsData =
    sourceDefsDataMap && !_.isEmpty(sourceDefsDataMap)
      ? Object.values(sourceDefsDataMap)
      : [];

  function handleAdd(sourceFieldUuId) {
    dispatch(
      createUserGroupResource({
        userGroupId: userGroupId,
        formData: {
          typeCode: 1,
          resoureDataUuIds: [sourceFieldUuId],
        },
      })
    );
  }

  function handleRemove(uuId) {
    dispatch(
      deleteUserGroupResource({
        userGroupId: userGroupId,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      [
        "getUserGroupResources",
        "createUserGroupResource",
        "deleteUserGroupResource",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setAvailableObjects([...transformAvailableObjects(data, sourceDefsData)]);
      setSelectedObjects([
        ...transformSelectedObjects(data, sourceDefsDataMap),
      ]);
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      sourceDefsSliceStatus &&
      sourceDefsSliceStatus.result === "success" &&
      sourceDefsSliceStatus.method === "getSourceDefinitions"
    ) {
      dispatch(
        getUserGroupResources({
          userGroupId: userGroupId,
          params: {
            typeCode: 1,
          },
        })
      );
      dispatch(clearSourceDefsSliceStatus());
    }
  }, [sourceDefsSliceStatus]);

  useEffect(() => {
    dispatch(
      getSourceDefinitions({
        activeFg: 1,
      })
    );
  }, []);

  return (
    <NtaiPanel
      width="75%"
      height="100%"
      header="Manage User Group Sources"
      subheader="You can assign available sources to the user group. User belonging to the user group will have all privileges pertaining to the source. Use user group source privilege in conjunction with user role to establish the source governance"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "420px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            height: "54px",
            gap: theme.spacing(1),
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Sources Assigned (
            {_.isArray(selectedObjects) ? selectedObjects.length : 0})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <Button sx={{ fontSize: "12px" }} size="small">
              REMOVE ALL
            </Button>
            <Button sx={{ fontSize: "12px" }} size="small">
              ADD ALL
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: `calc(100% - 54px)`,
            border: theme.general.border1,
          }}
        >
          <Box
            sx={{
              flexBasis: "40%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRight: theme.general.border1,
            }}
          >
            {_.isArray(availableObjects) && availableObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ p: 0 }}>
                  {_.orderBy(availableObjects, ["abbrev"], ["asc"]).map(
                    (fd, i) => (
                      <React.Fragment key={`available-objects-${i}`}>
                        <ListItem
                          sx={{ borderRadius: 0 }}
                          secondaryAction={
                            <Button
                              sx={{
                                display: "flex",
                                gap: theme.spacing(1),
                                fontSize: "11px",
                              }}
                              onClick={() => handleAdd(fd.uuId)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                              ADD
                            </Button>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 700 }}
                                variant="subtitle1"
                              >
                                {fd.abbrev}
                              </Typography>
                            }
                            secondary={fd.name}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </List>
              </Scrollbar>
            )}
            {_.isArray(availableObjects) && availableObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No sources available"
                subheader="You can configure sources from Admin - Sources."
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: "60%",
              display: "flex",
              height: "100%",
            }}
          >
            {_.isArray(selectedObjects) && selectedObjects.length > 0 && (
              <Scrollbar>
                <List sx={{ width: "100%", p: 0 }}>
                  {selectedObjects &&
                    _.isArray(selectedObjects) &&
                    _.orderBy(selectedObjects, ["primary"], ["asc"]).map(
                      (so, i) => (
                        <React.Fragment key={`selected-objects-${i}`}>
                          <ListItem
                            secondaryAction={
                              <Button
                                sx={{
                                  display: "flex",
                                  gap: theme.spacing(1),
                                  fontSize: "11px",
                                }}
                                onClick={() => handleRemove(so.uuId)}
                              >
                                <FontAwesomeIcon icon={faArchive} />
                                REMOVE
                              </Button>
                            }
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ fontWeight: 700 }}
                                  variant="subtitle1"
                                >
                                  {so.primary}
                                </Typography>
                              }
                              secondary={so.secondary}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                </List>
              </Scrollbar>
            )}

            {_.isArray(selectedObjects) && selectedObjects.length === 0 && (
              <NtaiEmptyMessage
                vAlign="center"
                header="No sources assigned"
                subheader="Add sources from left panel to the user group"
              />
            )}
          </Box>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
