import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getModels = createAsyncThunk(
  "models/getModels",
  async (thunkAPI, { rejectWithValue }) => {
    try {
      const response = await server.get("/models/models");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createModel = createAsyncThunk(
  "models/createModel",
  async (values, { rejectWithValue }) => {
    try {
      const response = await server.post("/models/models", values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadModel = createAsyncThunk(
  "models/uploadModel",
  async (values, { rejectWithValue }) => {
    const { itemId, formData, config } = values;
    try {
      const response = await server.post(
        `/models/models/${itemId}/upload`,
        formData,
        config
      );
      return { uuId: itemId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateModel = createAsyncThunk(
  "models/updateModel",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.patch(`/models/models/${uuId}`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteModel = createAsyncThunk(
  "models/deleteModel",
  async (uuId, { rejectWithValue }) => {
    try {
      const response = await server.delete(`/models/models/${uuId}`);
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const runModel = createAsyncThunk(
  "models/runModel",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, formData } = values;
      const response = await server.post(
        `/models/models/${uuId}/run`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const modelsSlice = createSlice({
  name: "models",
  initialState: {
    data: {},
    activeId: null,
    status: null,
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getModels.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getModels" };
    },
    [getModels.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getModels",
        message: action.payload.message,
      };
    },
    [createModel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.activeId = action.payload.uuId;
      state.status = { result: "success", method: "createModel" };
    },
    [createModel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createModel",
        message: action.payload.message,
      };
    },
    [updateModel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateModel" };
    },
    [updateModel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateModel",
        message: action.payload.message,
      };
    },
    [uploadModel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "uploadModel" };
    },
    [uploadModel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "uploadModel",
        message: action.payload.message,
      };
    },

    [deleteModel.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteModel" };
    },
    [deleteModel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteModel",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = modelsSlice.actions;

export default modelsSlice.reducer;
