import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React, { useEffect } from "react";
import SettingLabelValue from "./SettingLabelValue";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { getDomains } from "../../cdo/domain/store/domainsSlice";
import { useFormContext } from "react-hook-form";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
const _ = require("lodash");

export default function SettingForm({ formData, handleFormSubmit }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();

  const domainsDataMap = useSelector((state) => state.core.domainsSlice.data);
  const domainsData =
    domainsDataMap && !_.isEmpty(domainsDataMap)
      ? Object.values(domainsDataMap)
      : [];
  const domainOptions = NtaiUtils.getSelectOptions(domainsData, "uuId", "name");

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("settingsForm")
    );

    const revisedFormData = _.map(Object.keys(sanitizedFormData), function (o) {
      return {
        name: o,
        value: sanitizedFormData[o],
      };
    });

    if (!_.isEmpty(revisedFormData)) {
      handleFormSubmit({ settings: revisedFormData });
    }
  }

  useEffect(() => {
    const settingsObject = {};

    if (formData && _.isArray(formData)) {
      formData.forEach((s, i) => {
        settingsObject[s["name"]] = s["value"];
      });
      reset({ settingsForm: settingsObject });
    }
  }, [formData]);

  useEffect(() => {
    dispatch(getDomains());
  }, []);

  return (
    <NtaiFormPanel
      header="Settings"
      subheader="Global settings for all system and user operations"
      handleSave={onHandleSave}
    >
      <SettingLabelValue label="Default Domain">
        <NtaiSelectField
          name="settingsForm.defaultDomainUuId"
          options={domainOptions}
        />
      </SettingLabelValue>
      <SettingLabelValue label="Session Timeout (mins)">
        <NtaiTextField type="number" name="settingsForm.sessionTimeoutMins" />
      </SettingLabelValue>
      <SettingLabelValue label="Password expiry (days)">
        <NtaiTextField type="number" name="settingsForm.passwordExpiryDays" />
      </SettingLabelValue>
    </NtaiFormPanel>
  );
}
