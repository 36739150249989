import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiFieldsPanel from "@ntai/components/micros/NtaiFieldsPanel";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

function getDateFields(fields) {
  const result = _.orderBy(
    _.filter(Object.values(fields), { esDataTypeCode: 3 }),
    ["label"],
    ["asc"]
  ).map((f) => {
    return {
      value: f["uuId"],
      label: f["label"] + (f["type"] === 1 ? "" : " (d)"),
    };
  });

  return result;
}

export default function SourceViewCriteria({
  sourceViewId,
  fields,
  qbeFields,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue, watch } = useFormContext();
  const [dateFilterType, setDateFilterType] = useState();
  const [criteria, setCriteria] = useState();
  const [esCriteria, setEsCriteria] = useState();

  const codelistData = useSelector((state) => state.core.codelistsSlice.codes);

  const dateCriteriaTypeOptions = _.has(
    codelistData,
    CODELIST_CODES.GL_DATE_CRITERIA_TYPE_CODE
  )
    ? _.get(codelistData, CODELIST_CODES.GL_DATE_CRITERIA_TYPE_CODE)
    : [];

  const dateCriteriaPeriodOptions = _.has(
    codelistData,
    CODELIST_CODES.GL_DATE_CRITERIA_PERIOD_CODE
  )
    ? _.filter(
        _.get(codelistData, CODELIST_CODES.GL_DATE_CRITERIA_PERIOD_CODE),
        function (o) {
          return o["value"] !== 1 ? true : false;
        }
      )
    : [];

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceViewDataMap = useSelector(
    (state) => state.sources.sourceViewsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);
  const sourceViewData =
    sourceViewDataMap && !_.isEmpty(sourceViewDataMap)
      ? sourceViewDataMap[sourceViewId]
      : null;

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const watchDateFilter = watch("sourceViewCriteriaForm.dateCriteriaTypeCode");

  const dateFields = getDateFields(fields);

  const queryValue = sourceViewData["criteria"];

  function onHandleResetDateCriteria() {
    const sourceViewDataCopy = _.cloneDeep(sourceViewData);
    sourceViewDataCopy["dateCriteriaFieldUuId"] = null;
    sourceViewDataCopy["dateCriteriaFrom"] = null;
    sourceViewDataCopy["dateCriteriaTo"] = null;
    sourceViewDataCopy["dateCriteriaTypeCode"] = null;
    sourceViewDataCopy["dateCriteriaPeriodCode"] = null;
    sourceViewDataCopy["dateCriteriaNumOfPeriods"] = null;
    handleSave(sourceViewDataCopy);
  }

  function onHandleSave() {
    let sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("sourceViewCriteriaForm")
    );

    if (!sanitizedFormData) sanitizedFormData = {};

    if (!_.isEmpty(criteria)) {
      sanitizedFormData["criteria"] = criteria;
      sanitizedFormData["esCriteria"] = esCriteria;
    } else {
      sanitizedFormData["criteria"] = null;
      sanitizedFormData["esCriteria"] = null;
    }

    if (
      sanitizedFormData["dateCriteriaFieldUuId"] &&
      sanitizedFormData["dateCriteriaFieldUuId"].length > 0
    ) {
      if (sanitizedFormData["dateCriteriaFrom"]) {
        sanitizedFormData["dateCriteriaFrom"] = NtaiUtils.strToDate(
          sanitizedFormData["dateCriteriaFrom"],
          "yyyy-MM-dd"
        );
      }

      if (sanitizedFormData["dateCriteriaTo"]) {
        sanitizedFormData["dateCriteriaTo"] = NtaiUtils.strToDate(
          sanitizedFormData["dateCriteriaTo"],
          "yyyy-MM-dd"
        );
      }

      const selectedDateField = _.filter(fields, {
        uuId: sanitizedFormData["dateCriteriaFieldUuId"],
      })[0];

      sanitizedFormData["dateCriteriaSourceDerivedFieldFg"] =
        selectedDateField["type"] === 1 ? 0 : 1;
    }

    handleSave(sanitizedFormData);
  }

  function buildQuery(tree, config, jsonTree, formats) {
    setCriteria(jsonTree);
    setEsCriteria(_.get(formats, "esFormat"));
  }

  useEffect(() => {
    if (watchDateFilter && !_.isEmpty(watchDateFilter)) {
      const value = watchDateFilter["value"];
      setDateFilterType(value);
    }

    if (watchDateFilter && _.isNumber(watchDateFilter))
      setDateFilterType(watchDateFilter);
  }, [watchDateFilter]);

  useEffect(() => {
    // reset({ sourceViewCriteriaForm: sourceViewData });
    setCriteria(sourceViewData["criteria"]);
    setEsCriteria(sourceViewData["esCriteria"]);
    setValue(
      "sourceViewCriteriaForm.dateCriteriaTypeCode",
      sourceViewData["dateCriteriaTypeCode"]
    );
    setValue(
      "sourceViewCriteriaForm.dateCriteriaPeriodCode",
      sourceViewData["dateCriteriaPeriodCode"]
    );
    setValue(
      "sourceViewCriteriaForm.dateCriteriaNumOfPeriods",
      sourceViewData["dateCriteriaNumOfPeriods"]
    );
    setValue(
      "sourceViewCriteriaForm.dateCriteriaFrom",
      sourceViewData["dateCriteriaFrom"]
    );
    setValue(
      "sourceViewCriteriaForm.dateCriteriaTo",
      sourceViewData["dateCriteriaTo"]
    );
    setValue(
      "sourceViewCriteriaForm.dateCriteriaFieldUuId",
      sourceViewData["dateCriteriaFieldUuId"]
    );
    setValue(
      "sourceViewCriteriaForm.dateCriteriaSourceFieldUuId",
      sourceViewData["dateCriteriaSourceFieldUuId"]
    );
    setValue(
      "sourceViewCriteriaForm.dateCriteriaSourceDerivedFieldUuId",
      sourceViewData["dateCriteriaSourceDerivedFieldUuId"]
    );
  }, [sourceViewData]);

  useEffect(() => {
    dispatch(getCodes(CODELIST_CODES.GL_DATE_CRITERIA_TYPE_CODE));
    dispatch(getCodes(CODELIST_CODES.GL_DATE_CRITERIA_PERIOD_CODE));
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box sx={{ flexBasis: "60%", display: "flex" }}>
        <NtaiFormPanel
          header="Query Criteria"
          width="100%"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea"
          handleSave={onHandleSave}
          handleCancel={handleCancel}
        >
          <NtaiQueryBuilder
            fields={qbeFields}
            buildQuery={buildQuery}
            value={queryValue}
          />
        </NtaiFormPanel>
      </Box>
      <Box sx={{ flexBasis: "40%", display: "flex" }}>
        <NtaiPanel
          header="Date Criteria"
          subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore"
          handleOtherActions={
            <Button
              onClick={() => onHandleResetDateCriteria()}
              variant="outlined"
              size="small"
            >
              Reset Date Criteria
            </Button>
          }
        >
          {dateFields && _.isArray(dateFields) && dateFields.length > 0 && (
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
                flexDirection: "column",
              }}
            >
              <NtaiSelectField
                options={dateFields}
                label="Select date field"
                name="sourceViewCriteriaForm.dateCriteriaFieldUuId"
              />

              <NtaiSelectField
                options={dateCriteriaTypeOptions}
                label="Select date filter"
                name="sourceViewCriteriaForm.dateCriteriaTypeCode"
              />

              {[2, 3].includes(dateFilterType) && (
                <NtaiFieldsPanel label="Period">
                  <NtaiSelectField
                    name="sourceViewCriteriaForm.dateCriteriaPeriodCode"
                    options={dateCriteriaPeriodOptions}
                  />
                </NtaiFieldsPanel>
              )}

              {dateFilterType === 4 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  }}
                >
                  <Typography>Last</Typography>
                  <NtaiTextField
                    label="Number of periods"
                    width="40%"
                    name="sourceViewCriteriaForm.dateCriteriaNumOfPeriods"
                    type="number"
                  />
                  <NtaiSelectField
                    options={dateCriteriaPeriodOptions}
                    name="sourceViewCriteriaForm.dateCriteriaPeriodCode"
                  />
                </Box>
              )}

              {dateFilterType === 5 && (
                <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
                  <NtaiDateField
                    name="sourceViewCriteriaForm.dateCriteriaFrom"
                    label="From"
                  />
                  <NtaiDateField
                    name="sourceViewCriteriaForm.dateCriteriaTo"
                    label="To"
                  />
                </Box>
              )}
            </Box>
          )}
          {(!dateFields ||
            (_.isArray(dateFields) && dateFields.length === 0)) && (
            <NtaiEmptyMessage header="No date fields available" subheader="" />
          )}
        </NtaiPanel>
      </Box>
    </Box>
  );
}
