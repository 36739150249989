import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getAwsClusterTemplateSources = createAsyncThunk(
  "awsClusterTemplateSources/getAwsClusterTemplateSources",
  async (awsClusterTemplateUuId, { rejectWithValue }) => {
    try {
      const response = await server.get(
        `/aws-emr-cluster-templates/${awsClusterTemplateUuId}/sources`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAwsClusterTemplateSource = createAsyncThunk(
  "awsClusterTemplateSources/getAwsClusterTemplateSource",
  async (values, { rejectWithValue }) => {
    try {
      const { awsClusterTemplateUuId, uuId } = values;
      const response = await server.get(
        `/aws-emr-cluster-templates/${awsClusterTemplateUuId}/sources/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAwsClusterTemplateSource = createAsyncThunk(
  "awsClusterTemplateSources/createAwsClusterTemplateSource",
  async (values, { rejectWithValue }) => {
    try {
      const { awsClusterTemplateUuId, formData } = values;
      const response = await server.post(
        `/aws-emr-cluster-templates/${awsClusterTemplateUuId}/sources`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAwsClusterTemplateSource = createAsyncThunk(
  "awsClusterTemplateSources/updateAwsClusterTemplateSource",
  async (values, { rejectWithValue }) => {
    try {
      const { awsClusterTemplateUuId, uuId, formData } = values;
      const response = await server.patch(
        `/aws-emr-cluster-templates/${awsClusterTemplateUuId}/sources/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAwsClusterTemplateSource = createAsyncThunk(
  "awsClusterTemplateSources/deleteAwsClusterTemplateSource",
  async (values, { rejectWithValue }) => {
    try {
      const { awsClusterTemplateUuId, uuId } = values;

      const response = await server.delete(
        `/aws-emr-cluster-templates/${awsClusterTemplateUuId}/sources/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const awsClusterTemplateSourcesSlice = createSlice({
  name: "awsClusterTemplateSources",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getAwsClusterTemplateSources.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getAwsClusterTemplateSources",
      };
    },
    [getAwsClusterTemplateSources.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAwsClusterTemplateSources",
        message: action.payload.message,
      };
    },
    [getAwsClusterTemplateSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getAwsClusterTemplateSource",
      };
    },
    [getAwsClusterTemplateSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getAwsClusterTemplateSource",
        message: action.payload.message,
      };
    },
    [createAwsClusterTemplateSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "createAwsClusterTemplateSource",
      };
    },
    [createAwsClusterTemplateSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createAwsClusterTemplateSource",
        message: action.payload.message,
      };
    },
    [updateAwsClusterTemplateSource.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "updateAwsClusterTemplateSource",
      };
    },
    [updateAwsClusterTemplateSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateAwsClusterTemplateSource",
        message: action.payload.message,
      };
    },
    [deleteAwsClusterTemplateSource.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = {
        result: "success",
        method: "deleteAwsClusterTemplateSource",
      };
    },
    [deleteAwsClusterTemplateSource.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteAwsClusterTemplateSource",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  awsClusterTemplateSourcesSlice.actions;

export default awsClusterTemplateSourcesSlice.reducer;
