import { Box, Button } from "@mui/material";
import React from "react";
import NtaiDialog from "../dialogs/NtaiDialog";
import NtaiTabs from "../tabs/NtaiTabs";
import NtaiPDFReportViewer from "./NtaiPDFReportViewer";

export default function NtaiTestReportViewer() {
  const [open, setOpen] = React.useState(false);

  function handleDialogClose() {
    setOpen(false);
  }
  function handleDialogOpen() {
    setOpen(true);
  }

  return (
    <>
      <Box sx={{ display: "flex", p: "8px", height: "100%", width: "100%" }}>
        <NtaiTabs>
          <Box label="PDF">
            <Button onClick={handleDialogOpen}>Open Report</Button>
            {/* <PDFReportViewer /> */}
          </Box>
          <Box label="WORD"></Box>
        </NtaiTabs>
      </Box>
      <NtaiDialog
        title="Report XYZ"
        size="md"
        open={open}
        handleDialogClose={handleDialogClose}
      >
        <NtaiPDFReportViewer url="/reports/c429a6a8-9c58-4621-95d8-37639d82e99c" />
      </NtaiDialog>
    </>
  );
}
