import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { SourceOutJobContext } from "../SourceOutJobForm";

export default function SourceOutJobFrequencyBox({
  mode,
  header,
  subheader,
  children,
}) {
  const theme = useTheme();
  const { selectedRunMode, setSelectedRunMode } =
    React.useContext(SourceOutJobContext);

  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "auto",
        gap: theme.spacing(2),
        borderRadius: theme.general.borderRadiusSm,
        border:
          mode === selectedRunMode
            ? theme.general.border2p
            : theme.general.border1,
        "&:hover": {
          cursor: "pointer",
          boxShadow:
            mode === selectedRunMode ? theme.colors.shadows.cardSm : null,
        },
      }}
      onClick={() => setSelectedRunMode(mode)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h5">{header}</Typography>
          <Typography variant="subtitle2">{subheader}</Typography>
        </Box>
        <FontAwesomeIcon
          size="lg"
          icon={mode === selectedRunMode ? faCheckSquare : faSquare}
        />
      </Box>

      {children}
    </Box>
  );
}
