import {
  faCommentAlt,
  faCommentPen,
  faNote,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, IconButton, Tooltip, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  getSearchNotesIcon,
  setReloadKey,
} from "./store/searchNotesIconSlice";
const _ = require("lodash");

export default function SearchNotesIcon(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const {
    levelCode,
    searchId,
    searchSourceId,
    sourceViewId,
    sourceWidgetId,
    handleNotesDrawerOpen,
  } = props;

  const key = [searchId, searchSourceId, sourceViewId, sourceWidgetId].join(
    ","
  );

  const dataMap = useSelector(
    (state) => state.search.searchNotesIconSlice.data
  );
  const status = useSelector(
    (state) => state.search.searchNotesIconSlice.status
  );

  const reloadKey = useSelector(
    (state) => state.search.searchNotesIconSlice.reloadKey
  );

  useEffect(() => {
    if (
      status &&
      _.has(status, key) &&
      ["success", "error"].includes(status[key]["result"])
    ) {
      setCount(dataMap[key]["value"]);
      dispatch(clearStatus(key));
      dispatch(setReloadKey(null));
    }
  }, [status]);

  useEffect(() => {
    if (reloadKey === key)
      dispatch(
        getSearchNotesIcon({
          searchId: searchId,
          key: key,
          formData: {
            searchId: searchId,
            searchSourceId: searchSourceId,
            sourceViewId: sourceViewId,
            sourceWidgetId: sourceWidgetId,
            levelCode: levelCode,
          },
        })
      );
  }, [reloadKey]);

  useEffect(() => {
    dispatch(
      getSearchNotesIcon({
        searchId: searchId,
        key: key,
        formData: {
          searchId: searchId,
          searchSourceId: searchSourceId,
          sourceViewId: sourceViewId,
          sourceWidgetId: sourceWidgetId,
          levelCode: levelCode,
        },
      })
    );
  }, []);

  return (
    <React.Fragment>
      <Tooltip title="Notes">
        <IconButton
          sx={{ cursor: "default" }}
          size="small"
          onClick={(e) => handleNotesDrawerOpen(levelCode)}
        >
          <Badge
            badgeContent={count && count > 0 ? count : 0}
            size="small"
            variant="dot"
            color="secondary"
          >
            <FontAwesomeIcon size="2xs" icon={faCommentPen} />
          </Badge>
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}
