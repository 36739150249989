import ReportDefinitionCategoryList from "./ReportDefinitionCategoryList";

const ReportDefinitionCategoryConfig = {
  routes: [
    {
      path: "/reports/categories",
      exact: true,
      component: ReportDefinitionCategoryList,
    },
  ],
};

export default ReportDefinitionCategoryConfig;
