import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiScatterEChart from "./NtaiScatterEChart";
import NtaiBubbleEChart from "./NtaiBubbleEChart";

const bubbleData = [
  {
    name: "United States",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 9,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 9,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 5,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 5,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 5,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 5,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 4,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 4,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "80",
    y: "800",
    value: "80",
    colorValue: 4,
    total: "80",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "80",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "800",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 4,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "21",
    y: "210",
    value: "21",
    colorValue: 3,
    total: "21",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "21",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "210",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 3,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "68",
    y: "680",
    value: "68",
    colorValue: 3,
    total: "68",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "68",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "680",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 3,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "4000",
    y: "40000",
    value: "4000",
    colorValue: 3,
    total: "4000",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "4000",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "40000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "10",
    y: "100",
    value: "10",
    colorValue: 2,
    total: "10",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "10",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "100",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "17",
    y: "170",
    value: "17",
    colorValue: 2,
    total: "17",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "17",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "170",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "22",
    y: "220",
    value: "22",
    colorValue: 2,
    total: "22",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "22",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "220",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "29",
    y: "290",
    value: "29",
    colorValue: 2,
    total: "29",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "29",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "290",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 2,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "33",
    y: "330",
    value: "33",
    colorValue: 2,
    total: "33",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "33",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "330",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "48",
    y: "480",
    value: "48",
    colorValue: 2,
    total: "48",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "48",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "480",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 2,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "72",
    y: "720",
    value: "72",
    colorValue: 2,
    total: "72",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "72",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "720",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "75",
    y: "750",
    value: "75",
    colorValue: 2,
    total: "75",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "75",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "750",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "84",
    y: "840",
    value: "84",
    colorValue: 2,
    total: "84",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "84",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "840",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "102",
    y: "1020",
    value: "102",
    colorValue: 2,
    total: "102",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "102",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1020",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "125",
    y: "1250",
    value: "125",
    colorValue: 2,
    total: "125",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "125",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1250",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "128",
    y: "1280",
    value: "128",
    colorValue: 2,
    total: "128",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "128",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1280",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "United States",
    x: "160",
    y: "1600",
    value: "160",
    colorValue: 2,
    total: "160",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "160",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United States",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 8,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 8,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 4,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 4,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 3,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 3,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 3,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "150",
    y: "1500",
    value: "150",
    colorValue: 3,
    total: "150",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "150",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "180",
    y: "1800",
    value: "180",
    colorValue: 3,
    total: "180",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "180",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1800",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "25",
    y: "250",
    value: "25",
    colorValue: 2,
    total: "25",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "25",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "250",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "27",
    y: "270",
    value: "27",
    colorValue: 2,
    total: "27",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "27",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "270",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "36",
    y: "360",
    value: "36",
    colorValue: 2,
    total: "36",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "36",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "360",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "57",
    y: "570",
    value: "57",
    colorValue: 2,
    total: "57",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "57",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "570",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 2,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "84",
    y: "840",
    value: "84",
    colorValue: 2,
    total: "84",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "84",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "840",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "110",
    y: "1100",
    value: "110",
    colorValue: 2,
    total: "110",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "110",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1100",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "135",
    y: "1350",
    value: "135",
    colorValue: 2,
    total: "135",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "135",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1350",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "200",
    y: "2000",
    value: "200",
    colorValue: 2,
    total: "200",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "200",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "336",
    y: "3360",
    value: "336",
    colorValue: 2,
    total: "336",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "336",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "3360",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "2",
    y: "20",
    value: "2",
    colorValue: 1,
    total: "2",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "2",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "20",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "8",
    y: "80",
    value: "8",
    colorValue: 1,
    total: "8",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "8",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "80",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "23",
    y: "230",
    value: "23",
    colorValue: 1,
    total: "23",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "23",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "230",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "24",
    y: "240",
    value: "24",
    colorValue: 1,
    total: "24",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "24",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "240",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "28",
    y: "280",
    value: "28",
    colorValue: 1,
    total: "28",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "28",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "280",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "32",
    y: "320",
    value: "32",
    colorValue: 1,
    total: "32",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "32",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "320",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "",
    x: "34",
    y: "340",
    value: "34",
    colorValue: 1,
    total: "34",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "34",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "340",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "200",
    y: "2000",
    value: "200",
    colorValue: 4,
    total: "200",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "200",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 4,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 3,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 3,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 3,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 2,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 2,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "80",
    y: "800",
    value: "80",
    colorValue: 2,
    total: "80",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "80",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "800",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "99",
    y: "990",
    value: "99",
    colorValue: 2,
    total: "99",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "99",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "990",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "15",
    y: "150",
    value: "15",
    colorValue: 1,
    total: "15",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "15",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "150",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "22",
    y: "220",
    value: "22",
    colorValue: 1,
    total: "22",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "22",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "220",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "25",
    y: "250",
    value: "25",
    colorValue: 1,
    total: "25",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "25",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "250",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "26",
    y: "260",
    value: "26",
    colorValue: 1,
    total: "26",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "26",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "260",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "28",
    y: "280",
    value: "28",
    colorValue: 1,
    total: "28",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "28",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "280",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 1,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "36",
    y: "360",
    value: "36",
    colorValue: 1,
    total: "36",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "36",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "360",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "56",
    y: "560",
    value: "56",
    colorValue: 1,
    total: "56",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "56",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "560",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "65",
    y: "650",
    value: "65",
    colorValue: 1,
    total: "65",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "65",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "650",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "69",
    y: "690",
    value: "69",
    colorValue: 1,
    total: "69",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "69",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "690",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "70",
    y: "700",
    value: "70",
    colorValue: 1,
    total: "70",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "70",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "700",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "75",
    y: "750",
    value: "75",
    colorValue: 1,
    total: "75",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "75",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "750",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "91",
    y: "910",
    value: "91",
    colorValue: 1,
    total: "91",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "91",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "910",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "94",
    y: "940",
    value: "94",
    colorValue: 1,
    total: "94",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "94",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "940",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "95",
    y: "950",
    value: "95",
    colorValue: 1,
    total: "95",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "95",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "950",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "France",
    x: "110",
    y: "1100",
    value: "110",
    colorValue: 1,
    total: "110",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "110",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1100",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "France",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 3,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 2,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "10",
    y: "100",
    value: "10",
    colorValue: 2,
    total: "10",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "10",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "100",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 2,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "48",
    y: "480",
    value: "48",
    colorValue: 1,
    total: "48",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "48",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "480",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 1,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "52",
    y: "520",
    value: "52",
    colorValue: 1,
    total: "52",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "52",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "520",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "54",
    y: "540",
    value: "54",
    colorValue: 1,
    total: "54",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "54",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "540",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "58",
    y: "580",
    value: "58",
    colorValue: 1,
    total: "58",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "58",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "580",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "63",
    y: "630",
    value: "63",
    colorValue: 1,
    total: "63",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "63",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "630",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "64",
    y: "640",
    value: "64",
    colorValue: 1,
    total: "64",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "64",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "640",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "76",
    y: "760",
    value: "76",
    colorValue: 1,
    total: "76",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "76",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "760",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "80",
    y: "800",
    value: "80",
    colorValue: 1,
    total: "80",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "80",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "800",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "82",
    y: "820",
    value: "82",
    colorValue: 1,
    total: "82",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "82",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "820",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "84",
    y: "840",
    value: "84",
    colorValue: 1,
    total: "84",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "84",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "840",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "92",
    y: "920",
    value: "92",
    colorValue: 1,
    total: "92",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "92",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "920",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 1,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "144",
    y: "1440",
    value: "144",
    colorValue: 1,
    total: "144",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "144",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1440",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "165",
    y: "1650",
    value: "165",
    colorValue: 1,
    total: "165",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "165",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1650",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "288",
    y: "2880",
    value: "288",
    colorValue: 1,
    total: "288",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "288",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2880",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "1102",
    y: "11020",
    value: "1102",
    colorValue: 1,
    total: "1102",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "1102",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "11020",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Korea, Republic of",
    x: "2435",
    y: "24350",
    value: "2435",
    colorValue: 1,
    total: "2435",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "2435",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "24350",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Korea, Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 3,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 3,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 2,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 1,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "18",
    y: "180",
    value: "18",
    colorValue: 1,
    total: "18",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "18",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "180",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "38",
    y: "380",
    value: "38",
    colorValue: 1,
    total: "38",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "38",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "380",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "42",
    y: "420",
    value: "42",
    colorValue: 1,
    total: "42",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "42",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "420",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "80",
    y: "800",
    value: "80",
    colorValue: 1,
    total: "80",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "80",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "800",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "280",
    y: "2800",
    value: "280",
    colorValue: 1,
    total: "280",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "280",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2800",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "300",
    y: "3000",
    value: "300",
    colorValue: 1,
    total: "300",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "300",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "3000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "761",
    y: "7610",
    value: "761",
    colorValue: 1,
    total: "761",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "761",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "7610",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Canada",
    x: "5000",
    y: "50000",
    value: "5000",
    colorValue: 1,
    total: "5000",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "5000",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "50000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Canada",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 2,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 2,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "10",
    y: "100",
    value: "10",
    colorValue: 1,
    total: "10",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "10",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "100",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "21",
    y: "210",
    value: "21",
    colorValue: 1,
    total: "21",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "21",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "210",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 1,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "47",
    y: "470",
    value: "47",
    colorValue: 1,
    total: "47",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "47",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "470",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "54",
    y: "540",
    value: "54",
    colorValue: 1,
    total: "54",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "54",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "540",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "62",
    y: "620",
    value: "62",
    colorValue: 1,
    total: "62",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "62",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "620",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "64",
    y: "640",
    value: "64",
    colorValue: 1,
    total: "64",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "64",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "640",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "77",
    y: "770",
    value: "77",
    colorValue: 1,
    total: "77",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "77",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "770",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "78",
    y: "780",
    value: "78",
    colorValue: 1,
    total: "78",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "78",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "780",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "79",
    y: "790",
    value: "79",
    colorValue: 1,
    total: "79",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "79",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "790",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "101",
    y: "1010",
    value: "101",
    colorValue: 1,
    total: "101",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "101",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1010",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "320",
    y: "3200",
    value: "320",
    colorValue: 1,
    total: "320",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "320",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "3200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Spain",
    x: "3765",
    y: "37650",
    value: "3765",
    colorValue: 1,
    total: "3765",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "3765",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "37650",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Spain",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 2,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 2,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "15",
    y: "150",
    value: "15",
    colorValue: 1,
    total: "15",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "15",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "150",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "26",
    y: "260",
    value: "26",
    colorValue: 1,
    total: "26",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "26",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "260",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "28",
    y: "280",
    value: "28",
    colorValue: 1,
    total: "28",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "28",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "280",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "38",
    y: "380",
    value: "38",
    colorValue: 1,
    total: "38",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "38",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "380",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 1,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "54",
    y: "540",
    value: "54",
    colorValue: 1,
    total: "54",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "54",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "540",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "78",
    y: "780",
    value: "78",
    colorValue: 1,
    total: "78",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "78",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "780",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "148",
    y: "1480",
    value: "148",
    colorValue: 1,
    total: "148",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "148",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1480",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "414",
    y: "4140",
    value: "414",
    colorValue: 1,
    total: "414",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "414",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "4140",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Brazil",
    x: "450",
    y: "4500",
    value: "450",
    colorValue: 1,
    total: "450",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "450",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "4500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Brazil",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 4,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 4,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 2,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "26",
    y: "260",
    value: "26",
    colorValue: 1,
    total: "26",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "26",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "260",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "44",
    y: "440",
    value: "44",
    colorValue: 1,
    total: "44",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "44",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "440",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "48",
    y: "480",
    value: "48",
    colorValue: 1,
    total: "48",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "48",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "480",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "97",
    y: "970",
    value: "97",
    colorValue: 1,
    total: "97",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "97",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "970",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 1,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "144",
    y: "1440",
    value: "144",
    colorValue: 1,
    total: "144",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "144",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1440",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "200",
    y: "2000",
    value: "200",
    colorValue: 1,
    total: "200",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "200",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "210",
    y: "2100",
    value: "210",
    colorValue: 1,
    total: "210",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "210",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2100",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "212",
    y: "2120",
    value: "212",
    colorValue: 1,
    total: "212",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "212",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2120",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Egypt",
    x: "400",
    y: "4000",
    value: "400",
    colorValue: 1,
    total: "400",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "400",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "4000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Egypt",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "150",
    y: "1500",
    value: "150",
    colorValue: 2,
    total: "150",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "150",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "25",
    y: "250",
    value: "25",
    colorValue: 1,
    total: "25",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "25",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "250",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 1,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 1,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "90",
    y: "900",
    value: "90",
    colorValue: 1,
    total: "90",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "90",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "900",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 1,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "108",
    y: "1080",
    value: "108",
    colorValue: 1,
    total: "108",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "108",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1080",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 1,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "140",
    y: "1400",
    value: "140",
    colorValue: 1,
    total: "140",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "140",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "200",
    y: "2000",
    value: "200",
    colorValue: 1,
    total: "200",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "200",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Denmark",
    x: "425",
    y: "4250",
    value: "425",
    colorValue: 1,
    total: "425",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "425",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "4250",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Denmark",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "75",
    y: "750",
    value: "75",
    colorValue: 2,
    total: "75",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "75",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "750",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 1,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "6",
    y: "60",
    value: "6",
    colorValue: 1,
    total: "6",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "6",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "60",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "29",
    y: "290",
    value: "29",
    colorValue: 1,
    total: "29",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "29",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "290",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "46",
    y: "460",
    value: "46",
    colorValue: 1,
    total: "46",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "46",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "460",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "125",
    y: "1250",
    value: "125",
    colorValue: 1,
    total: "125",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "125",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1250",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "433",
    y: "4330",
    value: "433",
    colorValue: 1,
    total: "433",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "433",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "4330",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Belgium",
    x: "1453",
    y: "14530",
    value: "1453",
    colorValue: 1,
    total: "1453",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "1453",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "14530",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Belgium",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "China",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 3,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "China",
      count: 3,
    },
    filterFg: false,
  },
  {
    name: "China",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 2,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "China",
      count: 2,
    },
    filterFg: false,
  },
  {
    name: "China",
    x: "27",
    y: "270",
    value: "27",
    colorValue: 1,
    total: "27",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "27",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "270",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "China",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "China",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "China",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "China",
    x: "76",
    y: "760",
    value: "76",
    colorValue: 1,
    total: "76",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "76",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "760",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "China",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "China",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 1,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "China",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "China",
    x: "7000",
    y: "70000",
    value: "7000",
    colorValue: 1,
    total: "7000",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "7000",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "70000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "China",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Israel",
    x: "15",
    y: "150",
    value: "15",
    colorValue: 1,
    total: "15",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "15",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "150",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Israel",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Israel",
    x: "37",
    y: "370",
    value: "37",
    colorValue: 1,
    total: "37",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "37",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "370",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Israel",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Israel",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Israel",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Israel",
    x: "85",
    y: "850",
    value: "85",
    colorValue: 1,
    total: "85",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "85",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "850",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Israel",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Israel",
    x: "90",
    y: "900",
    value: "90",
    colorValue: 1,
    total: "90",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "90",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "900",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Israel",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Israel",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 1,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Israel",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Israel",
    x: "214",
    y: "2140",
    value: "214",
    colorValue: 1,
    total: "214",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "214",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2140",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Israel",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "United Kingdom",
    x: "2",
    y: "20",
    value: "2",
    colorValue: 1,
    total: "2",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "2",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "20",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United Kingdom",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "United Kingdom",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United Kingdom",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "United Kingdom",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 1,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United Kingdom",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "United Kingdom",
    x: "144",
    y: "1440",
    value: "144",
    colorValue: 1,
    total: "144",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "144",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1440",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United Kingdom",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "United Kingdom",
    x: "1100",
    y: "11000",
    value: "1100",
    colorValue: 1,
    total: "1100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "1100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "11000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United Kingdom",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "United Kingdom",
    x: "1915",
    y: "19150",
    value: "1915",
    colorValue: 1,
    total: "1915",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "1915",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "19150",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United Kingdom",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "United Kingdom",
    x: "4000",
    y: "40000",
    value: "4000",
    colorValue: 1,
    total: "4000",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "4000",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "40000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "United Kingdom",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Norway",
    x: "15",
    y: "150",
    value: "15",
    colorValue: 1,
    total: "15",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "15",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "150",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Norway",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Norway",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Norway",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Norway",
    x: "30",
    y: "300",
    value: "30",
    colorValue: 1,
    total: "30",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "30",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Norway",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Norway",
    x: "57",
    y: "570",
    value: "57",
    colorValue: 1,
    total: "57",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "57",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "570",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Norway",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Norway",
    x: "82",
    y: "820",
    value: "82",
    colorValue: 1,
    total: "82",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "82",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "820",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Norway",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Norway",
    x: "88",
    y: "880",
    value: "88",
    colorValue: 1,
    total: "88",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "88",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "880",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Norway",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Sweden",
    x: "2",
    y: "20",
    value: "2",
    colorValue: 1,
    total: "2",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "2",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "20",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Sweden",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Sweden",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 1,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Sweden",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Sweden",
    x: "91",
    y: "910",
    value: "91",
    colorValue: 1,
    total: "91",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "91",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "910",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Sweden",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Sweden",
    x: "115",
    y: "1150",
    value: "115",
    colorValue: 1,
    total: "115",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "115",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1150",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Sweden",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Sweden",
    x: "126",
    y: "1260",
    value: "126",
    colorValue: 1,
    total: "126",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "126",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1260",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Sweden",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Turkey",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Turkey",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Turkey",
    x: "35",
    y: "350",
    value: "35",
    colorValue: 1,
    total: "35",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "35",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "350",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Turkey",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Turkey",
    x: "75",
    y: "750",
    value: "75",
    colorValue: 1,
    total: "75",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "75",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "750",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Turkey",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Turkey",
    x: "100",
    y: "1000",
    value: "100",
    colorValue: 1,
    total: "100",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "100",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Turkey",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Turkey",
    x: "141",
    y: "1410",
    value: "141",
    colorValue: 1,
    total: "141",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "141",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1410",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Turkey",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Germany",
    x: "4",
    y: "40",
    value: "4",
    colorValue: 1,
    total: "4",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "4",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "40",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Germany",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Germany",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 1,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Germany",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Germany",
    x: "80",
    y: "800",
    value: "80",
    colorValue: 1,
    total: "80",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "80",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "800",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Germany",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Germany",
    x: "250000",
    y: "2500000",
    value: "250000",
    colorValue: 1,
    total: "250000",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "250000",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2500000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Germany",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Greece",
    x: "40",
    y: "400",
    value: "40",
    colorValue: 1,
    total: "40",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "40",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "400",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Greece",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Greece",
    x: "55",
    y: "550",
    value: "55",
    colorValue: 1,
    total: "55",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "55",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "550",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Greece",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Greece",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 1,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Greece",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Greece",
    x: "76",
    y: "760",
    value: "76",
    colorValue: 1,
    total: "76",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "76",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "760",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Greece",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "India",
    x: "70",
    y: "700",
    value: "70",
    colorValue: 1,
    total: "70",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "70",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "700",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "India",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "India",
    x: "96",
    y: "960",
    value: "96",
    colorValue: 1,
    total: "96",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "96",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "960",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "India",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "India",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 1,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "India",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "India",
    x: "330",
    y: "3300",
    value: "330",
    colorValue: 1,
    total: "330",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "330",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "3300",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "India",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Iran, Islamic Republic of",
    x: "12",
    y: "120",
    value: "12",
    colorValue: 1,
    total: "12",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "12",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "120",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Iran, Islamic Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Iran, Islamic Republic of",
    x: "220",
    y: "2200",
    value: "220",
    colorValue: 1,
    total: "220",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "220",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Iran, Islamic Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Iran, Islamic Republic of",
    x: "232",
    y: "2320",
    value: "232",
    colorValue: 1,
    total: "232",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "232",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2320",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Iran, Islamic Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Iran, Islamic Republic of",
    x: "1004",
    y: "10040",
    value: "1004",
    colorValue: 1,
    total: "1004",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "1004",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "10040",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Iran, Islamic Republic of",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Netherlands",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 1,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Netherlands",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Netherlands",
    x: "13",
    y: "130",
    value: "13",
    colorValue: 1,
    total: "13",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "13",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "130",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Netherlands",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Netherlands",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Netherlands",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Netherlands",
    x: "216",
    y: "2160",
    value: "216",
    colorValue: 1,
    total: "216",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "216",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2160",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Netherlands",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Portugal",
    x: "20",
    y: "200",
    value: "20",
    colorValue: 1,
    total: "20",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "20",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Portugal",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Portugal",
    x: "60",
    y: "600",
    value: "60",
    colorValue: 1,
    total: "60",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "60",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "600",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Portugal",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Portugal",
    x: "90",
    y: "900",
    value: "90",
    colorValue: 1,
    total: "90",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "90",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "900",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Portugal",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Portugal",
    x: "150",
    y: "1500",
    value: "150",
    colorValue: 1,
    total: "150",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "150",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Portugal",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Italy",
    x: "50",
    y: "500",
    value: "50",
    colorValue: 1,
    total: "50",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "50",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "500",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Italy",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Italy",
    x: "120",
    y: "1200",
    value: "120",
    colorValue: 1,
    total: "120",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "120",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1200",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Italy",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Italy",
    x: "144",
    y: "1440",
    value: "144",
    colorValue: 1,
    total: "144",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "144",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1440",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Italy",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Poland",
    x: "58",
    y: "580",
    value: "58",
    colorValue: 1,
    total: "58",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "58",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "580",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Poland",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Poland",
    x: "104",
    y: "1040",
    value: "104",
    colorValue: 1,
    total: "104",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "104",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1040",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Poland",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Poland",
    x: "200",
    y: "2000",
    value: "200",
    colorValue: 1,
    total: "200",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "200",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "2000",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Poland",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Switzerland",
    x: "0",
    y: "0",
    value: "0",
    colorValue: 1,
    total: "0",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "0",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "0",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Switzerland",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Switzerland",
    x: "8",
    y: "80",
    value: "8",
    colorValue: 1,
    total: "8",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "8",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "80",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Switzerland",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "Switzerland",
    x: "175",
    y: "1750",
    value: "175",
    colorValue: 1,
    total: "175",
    values: {
      "6f944837-5413-4992-8184-b9f4c062d342": "175",
      "b2aac1be-a844-49b3-837b-4150ebc8a725": "1750",
      "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": "Switzerland",
      count: 1,
    },
    filterFg: false,
  },
];

const bubbleMetadata = {
  widget: {
    uuId: "b6ee3506-830b-436f-b59c-8aa2a97f7669",
    name: "Bubble",
    description:
      "Bubble chart with single category and two to three numeric fields",
    secondaryText: null,
    geoMapCircleRadiusSteps: null,
    geoMapCircleColorSteps: null,
    nonAggregateResultFg: 1,
    xAxisFieldUuId: "6f944837-5413-4992-8184-b9f4c062d342",
    yAxisFieldUuId: "b2aac1be-a844-49b3-837b-4150ebc8a725",
    symbolSizeFieldUuId: "6f944837-5413-4992-8184-b9f4c062d342",
    geoLocFieldUuId: null,
    contentCode: 28,
    contentDecode: "Bubble ",
    aggregationCode: 9,
    aggregationDecode: null,
    minFields: 0,
    maxFields: 0,
    minCategoryFields: 1,
    maxCategoryFields: 5,
    minNumericFields: 2,
    maxNumericFields: 4,
    minDateFields: 0,
    maxDateFields: 0,
    searchFg: 0,
    paginationFg: 0,
    size: 25,
  },
  filterFg: false,
  fields: {
    "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3": {
      uuId: "1e58d7a2-0d10-4a6a-a7f4-d77267bb08c3",
      derivedFieldFg: null,
      fieldOrder: 1,
      xAxisFg: null,
      yAxisFg: null,
      rowGroupFg: null,
      colGroupFg: null,
      rowGroupOrder: null,
      colGroupOrder: null,
      headerName: "country",
      rowSummaryFg: null,
      colSummaryFg: null,
      metricCode: null,
      metricDecode: null,
      numericInterval: null,
      useValueFg: null,
      yAxisIndexFg: null,
      sizeFg: null,
      colorFg: null,
      geoLocFg: null,
      compositeSubChartCode: null,
      numBoolMetricSumFg: null,
      colorUseCatTextValueFg: null,
      summaryTabLinkEnabledFg: null,
      summaryTabLinkAddValue: null,
      field: {
        uuId: "fd13a3f6-e166-4d16-9d4b-e74e78bca7c9",
        name: "country",
        label: "country",
        fieldOrder: 8,
        path: "country",
        typeCode: 1,
        dataTypeCode: 1,
        esDataTypeCode: 4,
        esFieldPath: "country.keyword",
        dataLength: null,
        dataPrecision: null,
        dataScale: null,
        sourceLinkedSourceLinks: [],
      },
      derivedField: null,
      colorSchemes: [],
    },
    "6f944837-5413-4992-8184-b9f4c062d342": {
      uuId: "6f944837-5413-4992-8184-b9f4c062d342",
      derivedFieldFg: null,
      fieldOrder: 2,
      xAxisFg: 1,
      yAxisFg: null,
      rowGroupFg: null,
      colGroupFg: null,
      rowGroupOrder: null,
      colGroupOrder: null,
      headerName: "enrollment",
      rowSummaryFg: null,
      colSummaryFg: null,
      metricCode: 1,
      metricDecode: "None",
      numericInterval: null,
      useValueFg: null,
      yAxisIndexFg: null,
      sizeFg: 1,
      colorFg: null,
      geoLocFg: null,
      compositeSubChartCode: null,
      numBoolMetricSumFg: null,
      colorUseCatTextValueFg: null,
      summaryTabLinkEnabledFg: null,
      summaryTabLinkAddValue: null,
      field: {
        uuId: "93651e47-4457-4e89-9adf-164e0174de68",
        name: "enrollment",
        label: "enrollment",
        fieldOrder: 7,
        path: "enrollment",
        typeCode: 1,
        dataTypeCode: 2,
        esDataTypeCode: 2,
        esFieldPath: "enrollment",
        dataLength: null,
        dataPrecision: null,
        dataScale: null,
        sourceLinkedSourceLinks: [],
      },
      derivedField: null,
      colorSchemes: [],
    },
    "b2aac1be-a844-49b3-837b-4150ebc8a725": {
      uuId: "b2aac1be-a844-49b3-837b-4150ebc8a725",
      derivedFieldFg: null,
      fieldOrder: 3,
      xAxisFg: null,
      yAxisFg: 1,
      rowGroupFg: null,
      colGroupFg: null,
      rowGroupOrder: null,
      colGroupOrder: null,
      headerName: "enrollment_2",
      rowSummaryFg: null,
      colSummaryFg: null,
      metricCode: 1,
      metricDecode: "None",
      numericInterval: null,
      useValueFg: null,
      yAxisIndexFg: null,
      sizeFg: null,
      colorFg: null,
      geoLocFg: null,
      compositeSubChartCode: null,
      numBoolMetricSumFg: null,
      colorUseCatTextValueFg: null,
      summaryTabLinkEnabledFg: null,
      summaryTabLinkAddValue: null,
      field: {
        uuId: "33d79b71-959c-4ceb-837e-8b30ff57436a",
        name: "enrollment_2",
        label: "enrollment_2",
        fieldOrder: 13,
        path: "enrollment_2",
        typeCode: 1,
        dataTypeCode: 2,
        esDataTypeCode: 2,
        esFieldPath: "enrollment_2",
        dataLength: null,
        dataPrecision: null,
        dataScale: null,
        sourceLinkedSourceLinks: [],
      },
      derivedField: null,
      colorSchemes: [],
    },
  },
};

const data = [
  {
    name: "Australia",
    x: 3,
    y: 4,
    value: 2.4,
    total: 12,
    colorValue: 1,
    values: {
      fielduuid1: "Australia",
      fielduuid2: 3,
      fielduuid3: 4,
      fielduuid4: 1,
    },
  },
  {
    name: "India",
    x: -13,
    y: 24,
    value: 5.4,
    total: 16,
    colorValue: 10,
    values: {
      fielduuid1: "India",
      fielduuid2: 4,
      fielduuid3: 4,
      fielduuid4: 10,
    },
  },
  {
    name: "France",
    x: 45,
    y: 12,
    value: 10.4,
    total: 10,
    colorValue: 3,
    values: {
      fielduuid1: "France",
      fielduuid2: 3,
      fielduuid3: 4,
      fielduuid4: 3,
    },
  },

  {
    name: "Ghana",
    x: -2,
    y: 0,
    value: 2.4,
    total: 12,
    colorValue: 5,
    values: {
      fielduuid1: "Ghana",
      fielduuid2: 3,
      fielduuid3: 4,
      fielduuid4: 5,
    },
  },
  {
    name: "Namibia",
    x: 10,
    y: 10,
    value: 5.4,
    total: 16,
    colorValue: 9,
    values: {
      fielduuid1: "Namibia",
      fielduuid2: 3,
      fielduuid3: 4,
      fielduuid4: 9,
    },
  },
  {
    name: "Japan",
    x: 23,
    y: 10,
    value: 10.4,
    total: 10,
    colorValue: 10,
    values: {
      fielduuid1: "Japan",
      fielduuid2: 3,
      fielduuid3: 4,
      fielduuid4: 10,
    },
  },
];

const scatterData = [
  {
    name: "2015-026565",
    x: "16",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "16",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2015-026565",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2016-094863",
    x: "12",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "12",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2016-094863",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2017-047286",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2017-047286",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2020-105856",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2020-105856",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2021-071388",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2021-071388",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2021-090134",
    x: "5",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "5",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2021-090134",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-001164",
    x: "29",
    y: "13",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "29",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "13",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-001164",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-005172",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-005172",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-013143",
    x: "9",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "9",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-013143",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-021493",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-021493",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-022379",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-022379",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-025937",
    x: "13",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "13",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-025937",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-026016",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-026016",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-031551",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-031551",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-051680",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-051680",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-053039",
    x: "22",
    y: "6",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "22",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "6",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-053039",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-053734",
    x: "15",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "15",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-053734",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-066124",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-066124",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-074316",
    x: "13",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "13",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-074316",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-085716",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-085716",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-115514",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-115514",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-118047",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-118047",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-123324",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-123324",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-128204",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-128204",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-129661",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-129661",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-133007",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-133007",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-140509",
    x: "27",
    y: "11",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "27",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "11",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-140509",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-149780",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-149780",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-152491",
    x: "13",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "13",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-152491",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-152986",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-152986",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2022-158789",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2022-158789",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-002258",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-002258",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-009615",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-009615",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-011117",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-011117",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-015172",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-015172",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-018664",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-018664",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-025141",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-025141",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-029747",
    x: "11",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "11",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-029747",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-030897",
    x: "11",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "11",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-030897",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-035802",
    x: "15",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "15",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-035802",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-036508",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-036508",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-036572",
    x: "23",
    y: "7",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "23",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "7",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-036572",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-037964",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-037964",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-038255",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-038255",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-038301",
    x: "15",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "15",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-038301",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-039099",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-039099",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-039412",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-039412",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-041346",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-041346",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-041472",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-041472",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-041892",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-041892",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-042390",
    x: "13",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "13",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-042390",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-043353",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-043353",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-043472",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-043472",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-045523",
    x: "25",
    y: "9",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "25",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "9",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-045523",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-045628",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-045628",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-049277",
    x: "7",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "7",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-049277",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-051608",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-051608",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-052830",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-052830",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-053166",
    x: "16",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "16",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-053166",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-054341",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-054341",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-055735",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-055735",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-057084",
    x: "16",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "16",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-057084",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-059549",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-059549",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-060588",
    x: "11",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "11",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-060588",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-061850",
    x: "16",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "16",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-061850",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-062582",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-062582",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-062859",
    x: "22",
    y: "6",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "22",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "6",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-062859",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-065744",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-065744",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-066326",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-066326",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-067951",
    x: "22",
    y: "6",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "22",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "6",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-067951",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-068737",
    x: "15",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "15",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-068737",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-068790",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-068790",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-073904",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-073904",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-075433",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-075433",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-076241",
    x: "18",
    y: "2",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "18",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "2",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-076241",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-076589",
    x: "10",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "10",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-076589",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-078215",
    x: "22",
    y: "6",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "22",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "6",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-078215",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-079892",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-079892",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-081330",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-081330",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-083008",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-083008",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-083462",
    x: "16",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "16",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-083462",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-083771",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-083771",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-083805",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-083805",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-084392",
    x: "22",
    y: "6",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "22",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "6",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-084392",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-084697",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-084697",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-085594",
    x: "5",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "5",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-085594",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-085785",
    x: "13",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "13",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-085785",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-085840",
    x: "23",
    y: "7",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "23",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "7",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-085840",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-087418",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-087418",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-087970",
    x: "13",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "13",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-087970",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-089124",
    x: "16",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "16",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-089124",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-089171",
    x: "14",
    y: "0",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "14",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "0",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-089171",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-089264",
    x: "26",
    y: "10",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "26",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "10",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-089264",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-091056",
    x: "27",
    y: "11",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "27",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "11",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-091056",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-091894",
    x: "21",
    y: "5",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "21",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "5",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-091894",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-092550",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-092550",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-092702",
    x: "19",
    y: "3",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "19",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "3",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-092702",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-092979",
    x: "20",
    y: "4",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "20",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "4",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-092979",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-093473",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-093473",
      count: 1,
    },
    filterFg: false,
  },
  {
    name: "2023-093602",
    x: "17",
    y: "1",
    value: 1,
    colorValue: 1,
    total: 1,
    values: {
      "6e79a0d2-acbe-45fb-82c2-01768964610e": "17",
      "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": "1",
      "a49a430b-ec26-4ddb-8072-99f2850e4341": "2023-093602",
      count: 1,
    },
    filterFg: false,
  },
];

const scatterMetadata = {
  widget: {
    uuId: "b019c264-0eea-492e-b303-a1e3b8c35f21",
    name: "Scatter plot",
    description: "Scatter plot with two numerical fields",
    secondaryText: null,
    geoMapCircleRadiusSteps: null,
    geoMapCircleColorSteps: null,
    nonAggregateResultFg: 1,
    xAxisFieldUuId: "6e79a0d2-acbe-45fb-82c2-01768964610e",
    yAxisFieldUuId: "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6",
    symbolSizeFieldUuId: null,
    geoLocFieldUuId: null,
    contentCode: 12,
    contentDecode: "Scatter plot",
    aggregationCode: 9,
    aggregationDecode: null,
    minFields: 0,
    maxFields: 0,
    minCategoryFields: 0,
    maxCategoryFields: 5,
    minNumericFields: 2,
    maxNumericFields: 4,
    minDateFields: 0,
    maxDateFields: 0,
    searchFg: 0,
    paginationFg: 0,
    size: 100,
  },
  filterFg: false,
  fields: {
    "a49a430b-ec26-4ddb-8072-99f2850e4341": {
      uuId: "a49a430b-ec26-4ddb-8072-99f2850e4341",
      derivedFieldFg: null,
      fieldOrder: 1,
      xAxisFg: null,
      yAxisFg: null,
      rowGroupFg: null,
      colGroupFg: null,
      rowGroupOrder: null,
      colGroupOrder: null,
      headerName: "aer_no",
      rowSummaryFg: null,
      colSummaryFg: null,
      metricCode: null,
      metricDecode: null,
      numericInterval: null,
      useValueFg: null,
      yAxisIndexFg: null,
      sizeFg: null,
      colorFg: null,
      geoLocFg: null,
      compositeSubChartCode: null,
      numBoolMetricSumFg: null,
      colorUseCatTextValueFg: null,
      summaryTabLinkEnabledFg: null,
      summaryTabLinkAddValue: null,
      field: {
        uuId: "64fcb6b3-77f9-4ac7-9ac5-203fb532b546",
        name: "aer_no",
        label: "aer_no",
        fieldOrder: 2,
        path: "aer_no",
        typeCode: 1,
        dataTypeCode: 1,
        esDataTypeCode: 4,
        esFieldPath: "aer_no.keyword",
        dataLength: null,
        dataPrecision: null,
        dataScale: null,
        sourceLinkedSourceLinks: [],
      },
      derivedField: null,
      colorSchemes: [],
    },
    "6e79a0d2-acbe-45fb-82c2-01768964610e": {
      uuId: "6e79a0d2-acbe-45fb-82c2-01768964610e",
      derivedFieldFg: null,
      fieldOrder: 2,
      xAxisFg: 1,
      yAxisFg: null,
      rowGroupFg: null,
      colGroupFg: null,
      rowGroupOrder: null,
      colGroupOrder: null,
      headerName: "wf_actual_num_of_days",
      rowSummaryFg: null,
      colSummaryFg: null,
      metricCode: null,
      metricDecode: null,
      numericInterval: null,
      useValueFg: null,
      yAxisIndexFg: null,
      sizeFg: null,
      colorFg: null,
      geoLocFg: null,
      compositeSubChartCode: null,
      numBoolMetricSumFg: null,
      colorUseCatTextValueFg: null,
      summaryTabLinkEnabledFg: null,
      summaryTabLinkAddValue: null,
      field: {
        uuId: "80cd090b-3ce7-42b3-a62b-02e6e0695a97",
        name: "wf_actual_num_of_days",
        label: "wf_actual_num_of_days",
        fieldOrder: 23,
        path: "wf_actual_num_of_days",
        typeCode: 1,
        dataTypeCode: 3,
        esDataTypeCode: 2,
        esFieldPath: "wf_actual_num_of_days",
        dataLength: null,
        dataPrecision: null,
        dataScale: null,
        sourceLinkedSourceLinks: [],
      },
      derivedField: null,
      colorSchemes: [],
    },
    "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6": {
      uuId: "e76bb645-85bc-4d34-bd91-81ef5c2cc1d6",
      derivedFieldFg: null,
      fieldOrder: 3,
      xAxisFg: null,
      yAxisFg: 1,
      rowGroupFg: null,
      colGroupFg: null,
      rowGroupOrder: null,
      colGroupOrder: null,
      headerName: "wf_excess_num_of_days",
      rowSummaryFg: null,
      colSummaryFg: null,
      metricCode: null,
      metricDecode: null,
      numericInterval: null,
      useValueFg: null,
      yAxisIndexFg: null,
      sizeFg: null,
      colorFg: null,
      geoLocFg: null,
      compositeSubChartCode: null,
      numBoolMetricSumFg: null,
      colorUseCatTextValueFg: null,
      summaryTabLinkEnabledFg: null,
      summaryTabLinkAddValue: null,
      field: {
        uuId: "63a361dc-059b-49cc-b953-9959a52d42c0",
        name: "wf_excess_num_of_days",
        label: "wf_excess_num_of_days",
        fieldOrder: 24,
        path: "wf_excess_num_of_days",
        typeCode: 1,
        dataTypeCode: 3,
        esDataTypeCode: 2,
        esFieldPath: "wf_excess_num_of_days",
        dataLength: null,
        dataPrecision: null,
        dataScale: null,
        sourceLinkedSourceLinks: [],
      },
      derivedField: null,
      colorSchemes: [],
    },
  },
};

export default function NtaiTestScatter() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  const metadata = {
    filterFg: filtered,
    fields: {
      fielduuid1: {
        fieldOrder: 1,
        field: {
          uuId: "fielduuid1",
          label: "Country",
        },
        xAxisFg: 0,
        yAxisFg: 0,
        sizeFg: 0,
      },
      fielduuid2: {
        fieldOrder: 2,
        field: {
          uuId: "fielduuid2",
          label: "Number of days in workflow",
        },
        xAxisFg: 1,
        yAxisFg: 0,
        sizeFg: 0,
      },
      fielduuid3: {
        fieldOrder: 3,
        field: {
          uuId: "fielduuid3",
          label: "Lateness days",
        },
        xAxisFg: 0,
        yAxisFg: 1,
        sizeFg: 0,
      },
      fielduuid4: {
        fieldOrder: 4,
        field: {
          uuId: "fielduuid4",
          label: "Compliance %",
        },
        xAxisFg: 0,
        yAxisFg: 0,
        sizeFg: 1,
        colorSchemes: [
          {
            ruleGtValue: 1,
            ruleLtValue: 5,
            hexBgCode: "#0000FF",
            hexFgCode: "#cccccc",
          },
          {
            ruleGtValue: 6,
            ruleLtValue: 10,
            hexBgCode: "#FFFF00",
            hexFgCode: "#cccccc",
          },
        ],
      },
    },
  };

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Scatter chart">
          <NtaiScatterEChart
            widgetId="scatter"
            data={scatterData}
            metadata={scatterMetadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    result.push(
      <Box key={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Bubble chart">
          <NtaiBubbleEChart
            widgetId="bubble-chart"
            data={bubbleData}
            metadata={bubbleMetadata}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => setFiltered(!filtered)}>Apply Filter</Button>

      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
