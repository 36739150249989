import {
  faAlignSlash,
  faCalendarLinesPen,
  faFilter,
  faFilterSlash,
  faList,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import { SearchContext } from "../../SearchEdit";
import SearchActionMore from "./more/SearchActionMore";
import SearchActionShare from "./share/SearchActionShare";
import SearchTopicWrapper from "./topic/SearchTopicWrapper";
import SearchSummaryWrapper from "./summary/SearchSummaryWrapper";
import { faSend } from "@fortawesome/pro-light-svg-icons";
import SearchDahboardGroupsMenu from "../../source/dashboard/SearchDashboardGroupsMenu";
import SearchDateRangeWrapper from "./daterange/SearchDateRangeWrapper";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import SearchScheduleWrapper from "./schedule/SearchScheduleWrapper";
const _ = require("lodash");

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 8,
  },
}));

export default function SearchActionPanelWrapper() {
  const theme = useTheme();
  const {
    searchData,
    showAdvSearchFilterPanel,
    setShowAdvSearchFilterPanel,
    showSearchSourceRecords,
    setShowSearchSourceRecords,
  } = React.useContext(SearchContext);

  return (
    <Box
      sx={{
        flexBasis: _.get(searchData, "typeCode") === 1 ? "40%" : "75%",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        pr: theme.spacing(2),
        gap: theme.spacing(1.5),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: theme.spacing(1),
        }}
      >
        {searchData.typeCode === 3 && <SearchDahboardGroupsMenu />}
        <SearchTopicWrapper />
        <SearchSummaryWrapper />
        <SearchActionShare />

        {/* <IconButton size="small">
          <FontAwesomeIcon icon={faMailForward} size="xs" />
        </IconButton> */}

        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          <Divider orientation="vertical" flexItem />

          <SearchDateRangeWrapper />
          <SearchScheduleWrapper />

          <Divider orientation="vertical" flexItem />

          <Tooltip title="Show/Hide Records">
            <IconButton
              onClick={() =>
                setShowSearchSourceRecords(!showSearchSourceRecords)
              }
            >
              <FontAwesomeIcon
                size="2xs"
                icon={showSearchSourceRecords ? faAlignSlash : faAlignSlash}
              />
            </IconButton>
          </Tooltip>

          {[2, 3, 4, 5, 6].includes(searchData["typeCode"]) && (
            <Tooltip title="Show/Hide Filters">
              <IconButton
                onClick={() =>
                  setShowAdvSearchFilterPanel(!showAdvSearchFilterPanel)
                }
              >
                <FontAwesomeIcon
                  size="2xs"
                  icon={showAdvSearchFilterPanel ? faFilterSlash : faFilter}
                />
              </IconButton>
            </Tooltip>
          )}

          <SearchActionMore />
        </Box>
      </Box>
    </Box>
  );
}
