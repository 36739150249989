import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import {
  deleteWorkflowDefVersion,
  getWorkflowDefVersions,
} from "./store/workflowDefVersionsSlice";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { Button } from "@mui/material";

const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "actVersion",
    numeric: false,
    disablePadding: false,
    label: "Version",
    fontWeight: 700,
    transformFunc: function (o) {
      return o ? o : "Not deployed";
    },
  },
  {
    id: "totalDuration",
    numeric: false,
    disablePadding: false,
    label: "Duration",
    transformFunc: function (o) {
      return o ? o + " days" : "";
    },
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    label: "Process File",
  },
  {
    id: "statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "User Modified",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function WorkflowDefVersionList(props) {
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const workflowDefId = props.match.params.id;
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const wfDefDataMap = useSelector(
    (state) => state.core.workflowDefsSlice.data
  );

  const wfDefData = _.get(wfDefDataMap, workflowDefId);

  const dataMap = useSelector(
    (state) => state.core.workflowDefVersionsSlice.data
  );
  const status = useSelector(
    (state) => state.core.workflowDefVersionsSlice.status
  );
  const data = Object.values(dataMap);

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    if (action === "delete") {
      setAction("delete");
      confirmDelete();
    }
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      if (action === "delete") {
        dispatch(
          deleteWorkflowDefVersion({ workflowDefId: workflowDefId, uuId: id })
        );
      }
    });
    setCurrentId(null);
    setSelectedIds([]);
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
  }

  useEffect(() => {
    dispatch(getWorkflowDefVersions(workflowDefId));
  }, []);

  return (
    <>
      <NtaiPage
        padding={2}
        title={`${wfDefData["name"]} Versions`}
        back={`/admin/workflows`}
      >
        <NtaiCrudTable
          helpText="Workflow versions help you manage and track changes in workflow definitions effectively. Before creating and deploying new version of a workflow, ensure that items in old workflow versions are completed or temrinated."
          rows={data}
          headCells={headCells}
          toolbarActions={toolbarActions}
          keyColumn="uuId"
          isDensed={true}
          path={`/admin/workflows/${workflowDefId}/versions`}
          handleToolbarAction={handleToolbarAction}
          title="Workflow Version"
        />
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "actVersion")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
