import { Home } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import NtaiNavigation from "@ntai/core/NtaiNavigation/NtaiNavigation";
import { Box, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationConfig from "app/configs/navigationConfig";
import NtaiIcons from "utils/NtaiIcons";
const _ = require("lodash");

export default function LeftNavigation() {
  const theme = useTheme();
  const activeNavigation = useSelector(
    (state) => state.ntai.navigation.activeNavigation
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "250px",
        borderRight: "1px solid #eee",
      }}
    >
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            height: "53px",
            alignItems: "center",
            borderBottom: "1px solid #eee",
          }}
        >
          {/* <Home color="secondary" fontSize="large" /> */}
          <Box
            sx={{
              paddingLeft: theme.spacing(2),
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexBasis: "100%",
              gap: theme.spacing(2),
            }}
          >
            <FontAwesomeIcon
              color="secondary"
              icon={
                NtaiIcons[_.get(navigationConfig[activeNavigation], "icon")]
              }
            />
            <Typography variant="h4">
              {_.get(navigationConfig[activeNavigation], "title")}
            </Typography>
          </Box>
        </Box>

        <NtaiNavigation activeNavigation={activeNavigation} />
      </Box>
    </Box>
  );
}
