import { MoreVert, Settings } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useRef, useState } from "react";

export default function NtaiActionCard(props) {
  const {
    cardMaxWidth,
    cardElevation,
    headerAvatar,
    headerTitle,
    headerTitleDescription,
    headerActions,
    handleHeaderActionMenu,
    children,
    cardActions,
  } = props;

  const theme = useTheme();

  const [openPopover, setOpenPopover] = useState(false);
  const ref = useRef(null);

  function handleOpen() {
    setOpenPopover(true);
  }

  function handleClose() {
    setOpenPopover(false);
  }

  function generateActions() {
    let actions = [];

    headerActions &&
      headerActions.forEach((actionName, index) => {
        actions.push(
          <MenuItem onClick={() => handleHeaderActionMenu(actionName)}>
            {actionName}
          </MenuItem>
        );
      });

    return actions;
  }

  return (
    <>
      <Card
        elevation={cardElevation ? cardElevation : 0}
        sx={{
          maxWidth: cardMaxWidth ? cardMaxWidth : 350,
          border: `1px solid ${theme.colors.alpha.black[20]}`,
        }}
      >
        <CardHeader
          avatar={headerAvatar ? headerAvatar : null}
          title={headerTitle}
          subheader={headerTitleDescription ? headerTitleDescription : null}
          action={
            headerActions && (
              <IconButton ref={ref} onClick={handleOpen} size="small">
                <MoreVert />
              </IconButton>
            )
          }
        ></CardHeader>
        <CardContent>{children}</CardContent>
        {cardActions && (
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "start",
              borderTop: `1px solid ${theme.colors.alpha.black[20]}`,
            }}
          >
            {cardActions}
          </CardActions>
        )}
      </Card>
      <Menu
        id="card-menu"
        anchorEl={ref.current}
        open={openPopover}
        onClose={handleClose}
      >
        {generateActions()}
      </Menu>
    </>
  );
}
