import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiDnd from "@ntai/components/dnd/NtaiDnd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import { grey } from "@mui/material/colors";
import {
  clearStatus,
  createSourceCustomFieldElem,
  deleteSourceCustomFieldElem,
  getSourceCustomFieldElems,
} from "./store/sourceCustomFieldElemsSlice";
import {
  getSourceFields,
  clearStatus as clearSourceFieldsSliceStatus,
} from "../../store/sourcesSlice";
import {
  getSourceCustomFields,
  clearStatus as clearSourceCustomFieldsSliceStatus,
} from "../store/sourceCustomFieldsSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faPlus } from "@fortawesome/pro-regular-svg-icons";
const _ = require("lodash");

function transformSelectedFields(
  selectedFields,
  sourceFieldsDataMap,
  sourceCustomFieldsDataMap
) {
  let result = [];
  if (_.isArray(selectedFields)) {
    _.orderBy(selectedFields, ["dateModified"], ["asc"]).forEach((f, i) => {
      result.push({
        uuId: f["uuId"],
        typeCode: f["typeCode"],
        fieldUuId: f["elemFieldUuId"],
        primary:
          f["typeCode"] === 1
            ? sourceFieldsDataMap[f["elemFieldUuId"]]["name"]
            : sourceCustomFieldsDataMap[f["elemFieldUuId"]]["name"],
        // primary: f["typeCode"] === 1,
        // secondary: f["typeCode"] === 1 ? "Standard Field" : "Custom Field",
      });
    });
  }

  return result;
}

function transformAvailableFields(
  sourceCustomFieldId,
  selectedFields,
  sourceFields,
  sourceCustomFields
) {
  const mergedSourceFields = [
    ..._.filter(sourceFields, function (o) {
      return o["customFieldFg"] === 1 ? false : true;
    }).map((o, i) => {
      return {
        uuId: o["uuId"],
        typeCode: 1,
        name: o["name"],
        description: o["description"],
      };
    }),
    ..._.filter(sourceCustomFields, function (g) {
      return g["uuId"] === sourceCustomFieldId ? false : true;
    }).map((o, i) => {
      return {
        uuId: o["uuId"],
        typeCode: 2,
        name: o["name"],
        description: o["description"],
      };
    }),
  ];
  const revisedFields = _.filter(
    _.orderBy(mergedSourceFields, ["name"], ["asc"]),
    function (f) {
      if (_.filter(selectedFields, { elemFieldUuId: f.uuId }).length === 0)
        return true;
    }
  );

  return revisedFields;
}

export default function SourceCustomFieldElems() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { getValues, setValue, reset } = useFormContext();
  const [sourceFieldsLoaded, setSourceFieldsLoaded] = useState(false);
  const [sourceCustomFieldsLoaded, setSourceCustomFieldsLoaded] =
    useState(false);
  const [sourceCustomFieldElemsLoaded, setSourceCustomFieldElemsLoaded] =
    useState(false);

  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);

  console.log("Selected fields: ", selectedFields);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceCustomFieldId = useSelector(
    (state) => state.sources.sourceCustomFieldsSlice.activeId
  );

  const pathVariables = {
    sourceDefId: sourceDefId,
    sourceId: sourceId,
    sourceCustomFieldId: sourceCustomFieldId,
  };

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceCustomFieldsDataMap = useSelector(
    (state) => state.sources.sourceCustomFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceCustomFieldsStatus = useSelector(
    (state) => state.sources.sourceCustomFieldsSlice.status
  );

  const sourceCustomFieldElemsDataMap = useSelector(
    (state) => state.sources.sourceCustomFieldElemsSlice.data
  );

  const status = useSelector(
    (state) => state.sources.sourceCustomFieldElemsSlice.status
  );

  const sourceFields =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceCustomFields =
    sourceCustomFieldsDataMap && !_.isEmpty(sourceCustomFieldsDataMap)
      ? Object.values(sourceCustomFieldsDataMap)
      : [];

  const sourceCustomFieldElems =
    sourceCustomFieldElemsDataMap && !_.isEmpty(sourceCustomFieldElemsDataMap)
      ? Object.values(sourceCustomFieldElemsDataMap)
      : [];

  function handleAdd(elemTypeCode, elemFieldUuId) {
    dispatch(
      createSourceCustomFieldElem({
        ...pathVariables,
        formData: {
          elemTypeCode: elemTypeCode,
          elemFieldUuId: elemFieldUuId,
        },
      })
    );
  }

  function handleRemove(uuId) {
    dispatch(
      deleteSourceCustomFieldElem({
        ...pathVariables,
        uuId: uuId,
      })
    );
  }

  useEffect(() => {
    if (
      (status &&
        status.result === "success" &&
        ["createSourceCustomFieldElem", "deleteSourceCustomFieldElem"].includes(
          status.method
        )) ||
      (sourceFieldsLoaded &&
        sourceCustomFieldsLoaded &&
        status &&
        status.result === "success" &&
        ["getSourceCustomFieldElems"].includes(status.method))
    ) {
      const tmpSelectedFields = transformSelectedFields(
        sourceCustomFieldElems,
        sourceFieldsDataMap,
        sourceCustomFieldsDataMap
      );

      const tmpAvailableFields = transformAvailableFields(
        sourceCustomFieldId,
        sourceCustomFieldElems,
        sourceFields,
        sourceCustomFields
      );

      setSelectedFields([...tmpSelectedFields]);
      setAvailableFields([...tmpAvailableFields]);
      dispatch(clearStatus());
      setFieldsLoaded(true);
      setSourceFieldsLoaded(false);
      setSourceCustomFieldsLoaded(false);
    }
  }, [status, sourceFieldsLoaded, sourceCustomFieldsLoaded]);

  useEffect(() => {
    if (
      sourceCustomFieldsStatus &&
      sourceCustomFieldsStatus.method === "getSourceCustomFields" &&
      sourceCustomFieldsStatus.result === "success"
    ) {
      setSourceCustomFieldsLoaded(true);
      dispatch(clearSourceCustomFieldsSliceStatus());
    }
  }, [sourceCustomFieldsStatus]);

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceFieldsStatus.result === "success"
    ) {
      setSourceFieldsLoaded(true);
      dispatch(clearSourceFieldsSliceStatus());
    }
  }, [sourceFieldsStatus]);

  useEffect(() => {
    dispatch(getSourceCustomFieldElems(pathVariables));
    dispatch(getSourceFields({ sourceDefId: sourceDefId, sourceId: sourceId }));
    dispatch(
      getSourceCustomFields({ sourceDefId: sourceDefId, sourceId: sourceId })
    );
  }, [sourceCustomFieldId]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "420px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          height: "54px",
          gap: theme.spacing(1),
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          Fields Assigned (
          {_.isArray(selectedFields) ? selectedFields.length : 0})
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: `calc(100% - 54px)`,
          border: theme.general.border1,
        }}
      >
        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            height: "100%",
            flexDirection: "column",
            borderRight: theme.general.border1,
          }}
        >
          {_.isArray(availableFields) && availableFields.length > 0 && (
            <Scrollbar>
              <List sx={{ p: 0 }}>
                {_.orderBy(availableFields, ["name"], ["asc"]).map((fd, i) => (
                  <React.Fragment key={`available-objects-${i}`}>
                    <ListItem
                      sx={{
                        display: "flex",
                        borderRadius: 0,
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: theme.spacing(2),
                        }}
                      >
                        <Chip
                          sx={{
                            height: "24px",
                            width: "24px",
                            fontSize: "10px",
                            fontWeight: "700",
                          }}
                          size="small"
                          label={fd["typeCode"] === 1 ? "S" : "C"}
                        />
                        <ListItemText
                          primary={
                            <Typography
                              sx={{ fontWeight: 700 }}
                              variant="subtitle1"
                            >
                              {fd.name}
                            </Typography>
                          }
                          secondary={fd["description"]}
                        />
                      </Box>
                      <Button
                        sx={{
                          fontSize: "11px",
                          display: "flex",
                          gap: theme.spacing(1),
                        }}
                        size="small"
                        onClick={() => handleAdd(fd.typeCode, fd.uuId)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        ADD
                      </Button>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Scrollbar>
          )}
          {_.isArray(availableFields) && availableFields.length === 0 && (
            <NtaiEmptyMessage
              vAlign="center"
              header="No fields available"
              subheader="You have assigned all fields"
            />
          )}
        </Box>
        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            height: "100%",
          }}
        >
          {_.isArray(selectedFields) && selectedFields.length > 0 && (
            <Scrollbar>
              <List sx={{ width: "100%", p: 0 }}>
                {selectedFields &&
                  _.isArray(selectedFields) &&
                  _.orderBy(selectedFields, ["primary"], ["asc"]).map(
                    (so, i) => (
                      <React.Fragment key={`selected-objects-${i}`}>
                        <ListItem
                          sx={{
                            width: "100%",
                            display: "flex",
                            gap: theme.spacing(2),
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: theme.spacing(2),
                              }}
                            >
                              <Chip
                                size="small"
                                sx={{
                                  height: "24px",
                                  width: "24px",
                                  fontSize: "10px",
                                  fontWeight: "700",
                                }}
                                label={so["typeCode"] === 1 ? "S" : "C"}
                              />
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      wordBreak: "break-word",
                                    }}
                                    variant="subtitle1"
                                  >
                                    {so.primary}
                                  </Typography>
                                }
                                secondary={so.secondary}
                              />
                            </Box>
                            <Button
                              size="small"
                              sx={{
                                display: "flex",
                                gap: theme.spacing(1),
                                fontSize: "11px",
                              }}
                              onClick={() => handleRemove(so.uuId)}
                            >
                              <FontAwesomeIcon icon={faArchive} />
                              REMOVE
                            </Button>
                          </Box>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
              </List>
            </Scrollbar>
          )}

          {_.isArray(selectedFields) && selectedFields.length === 0 && (
            <NtaiEmptyMessage
              vAlign="center"
              header="No fields assigned"
              subheader="Add fields from left panel to the group field"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
