import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function NtaiInputErrorMessage(props) {
  const theme = useTheme();
  const { errors, name } = props;
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Box
          sx={{
            marginTop: "2px",
            marginLeft: "0px",
            padding: "2px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(0.75),
              fontSize: "12px",
            }}
            color="error"
          >
            <FontAwesomeIcon icon={faCircleExclamation} color="red" />
            {message}
          </Typography>
        </Box>
      )}
    />
  );
}
