import React from "react";
import { Link, useTheme } from "@mui/material";
import { blue } from "@mui/material/colors";
import SearchSourceSummaryTable from "./SearchSourceSummaryTable";
import NtaiEChartUtil from "@ntai/components/charts/echarts/util/NtaiEChartUtil";
const _ = require("lodash");

function handleFilter(t1, t2) {
  // console.log("T1 and t2: ", t1, t2);
}

export default function SearchSourceSummaryTabWidget({
  widgetId,
  data,
  metadata,
  handleClick,
}) {
  const theme = useTheme();

  // hack to always have up to date metadata
  const metadataRef = React.useRef();
  metadataRef.current = metadata;
  const fieldArray = _.orderBy(
    Object.values(_.get(metadata, "fields")),
    ["fieldOrder"],
    ["asc"]
  );

  function evalValue(value, valScript) {
    return eval(valScript);
  }

  function generateColumnDefs(fieldArr) {
    const colDefs = [];

    fieldArr.forEach((widgetField, i) => {
      const derivedFieldFg =
        _.get(widgetField, "derivedFieldFg") &&
        _.get(widgetField, "derivedFieldFg") === 1
          ? true
          : false;

      colDefs.push({
        size: 2,
        minSize: 2,
        primary: i === 0 ? true : false,
        accessorKey: derivedFieldFg
          ? widgetField["derivedField"]["name"]
          : widgetField["field"]["path"],
        header: widgetField["headerName"],
        fieldUuId: widgetField["uuId"],
        link: widgetField.summaryTabLinkEnabledFg === 1 ? true : false,
        colorSchemes: widgetField["colorSchemes"],
        Cell: ({ cell, row, column }) =>
          widgetField.summaryTabLinkEnabledFg === 1 ? (
            <Link
              underline="always"
              color="inherit"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={(e) => onHandleClick(cell, column)}
            >
              {widgetField["valueFormatterScript"] &&
              widgetField["valueFormatterScript"].length > 0
                ? evalValue(
                    cell.getValue(),
                    widgetField["valueFormatterScript"]
                  )
                : cell.getValue()}
            </Link>
          ) : (
            <React.Fragment>
              {widgetField["valueFormatterScript"] &&
              widgetField["valueFormatterScript"].length > 0
                ? evalValue(
                    cell.getValue(),
                    widgetField["valueFormatterScript"]
                  )
                : cell.getValue()}
            </React.Fragment>
          ),
      });
    });

    return colDefs;
  }

  const columns = generateColumnDefs(fieldArray);

  function onHandleClick(cell, column) {
    const catFieldNames = _.filter(
      Object.keys(cell.row.original),
      function (o) {
        if (_.isString(cell.row.original[o])) return true;
        else return false;
      }
    );

    const sumTabLinkAddValueFields = _.filter(
      Object.values(metadataRef.current.fields),
      function (o) {
        if (
          o["uuId"] === column.columnDef.fieldUuId &&
          o["summaryTabLinkAddValue"] &&
          o["summaryTabLinkAddValue"].length > 0
        )
          return true;
        else return false;
      }
    );

    let result = [];
    catFieldNames.forEach((catFieldName) => {
      const catField = _.filter(
        Object.values(metadataRef.current.fields),
        function (o) {
          if (o["derivedFieldFg"] && o["derivedFieldFg"] === 1) {
            if (o["derivedField"]["name"] === catFieldName) return true;
            else return false;
          } else {
            if (o["field"]["path"] === catFieldName) return true;
            else return false;
          }
        }
      )[0];

      result.push({
        sourceWidgetFieldUuId: catField["uuId"],
        fieldOperatorCode: 1,
        fieldValue: cell.row.original[catFieldName],
      });
    });

    sumTabLinkAddValueFields.forEach((sumTabLinkAddValueField) => {
      result.push({
        sourceWidgetFieldUuId: sumTabLinkAddValueField["uuId"],
        fieldOperatorCode: 1,
        fieldValue: sumTabLinkAddValueField["summaryTabLinkAddValue"],
      });
    });

    if (result.length > 0) handleClick(result);
  }

  function onHandleRowClick(id) {}

  return (
    <SearchSourceSummaryTable
      enableRowClick={false}
      enableRowSelection={false}
      data={data}
      columns={columns}
      handleRowClick={onHandleRowClick}
    />
  );
}
