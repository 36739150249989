import { Box, useTheme, Typography, Chip } from "@mui/material";
import NtaiAddRecordCard from "@ntai/components/cards/NtaiAddRecordCard";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiActionsPanel from "@ntai/components/panels/NtaiActionsPanel";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  createSourceIn,
  deleteSourceIn,
  getSourceIns,
  setActiveId,
  updateSourceIn,
} from "./store/sourceInsSlice";
import history from "@ntai/@history";
import SourceInForm from "./SourceInForm";
import { getCodes } from "../../../admin/codelist/store/codelistsSlice";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import SourceInFormSelect from "./SourceInFormSelect";
import { useSnackbar } from "notistack";
import SourceAwsLivySessionDialog from "../session/SourceAwsLivySessionDialog";
import { SourceContext } from "../SourceManager";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
const _ = require("lodash");

function getSourceInTypeValue(typeCode) {
  if (typeCode < 8 || typeCode === 11) return "manage-files";
  if (typeCode >= 8) return "manage-datasets";
}

export default function SourceInList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectFormatDialogOpen, setSelectFormatDialogOpen] = useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [action, setAction] = useState();
  const [currentId, setCurrentId] = useState();

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const sourceDefDataMap = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.data
  );

  const sourceDefData = _.get(sourceDefDataMap, sourceDefId);

  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);
  const dataMap = useSelector((state) => state.sources.sourceInsSlice.data);
  const data = Object.values(dataMap);
  const status = useSelector((state) => state.sources.sourceInsSlice.status);

  function handleFormDialogOpen() {
    setFormDialogOpen(true);
  }

  function handleFormDialogClose() {
    setFormDialogOpen(false);
  }

  function handleSelectFormatDialogOpen() {
    setSelectFormatDialogOpen(true);
  }

  function handleSelectFormatDialogClose() {
    setSelectFormatDialogOpen(false);
  }

  function handleAdd() {
    setAction("add");
    handleSelectFormatDialogOpen();
  }

  function handleDelete() {
    dispatch(
      deleteSourceIn({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        uuId: currentId,
      })
    );
  }

  function handleCancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setCurrentId(null);
    setAction(null);
  }

  function handleHeaderAction(actionName, id) {
    if (actionName === "edit") {
      setCurrentId(id);
      setAction("edit");
      handleFormDialogOpen();
    } else if (actionName === "delete") {
      setAction("delete");
      setCurrentId(id);
      setOpenConfirmDeleteDialog(true);
    }
  }

  function manageInStore(actionName, id) {
    console.log("Manage in store: ", actionName, id);
    dispatch(setActiveId(id));
    if (actionName === "manage-files")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${id}/files`
      );
    else if (actionName === "manage-datasets")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${id}/datasets`
      );
    else if (actionName === "ocr")
      history.push(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/ins/${id}/ocr-pages`
      );
  }

  function handleFormSubmit(formData) {
    if (action === "add")
      dispatch(
        createSourceIn({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          formData: formData,
        })
      );
    else if (action === "edit")
      dispatch(
        updateSourceIn({
          sourceDefId: sourceDefId,
          sourceId: sourceId,
          uuId: currentId,
          formData: formData,
        })
      );
  }

  function renderSourceInItems() {
    let result = [];
    if (Array.isArray(data) && data.length > 0) {
      _.orderBy(data, ["name"], ["asc"]).forEach((item, index) => {
        result.push(
          <NtaiActionsPanel
            key={index}
            id={item.uuId}
            title={item.name}
            subheader={item.typeDecode}
            headerActions={[
              { value: "edit", label: "Edit" },
              { value: "delete", label: "Delete" },
            ]}
            handleHeaderAction={handleHeaderAction}
            width="320px"
            actions={[
              {
                value: getSourceInTypeValue(item.typeCode),
                label:
                  item.typeCode < 8 || item.typeCode === 11
                    ? `Manage Files`
                    : `Manage Datasets`,
              },
              item.typeCode === 6 && { value: "ocr", label: "OCR Plan" },
            ]}
            handleAction={manageInStore}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(1),
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Chip size="small" label={item.statusDecode} />
              </Box>
            </Box>
          </NtaiActionsPanel>
        );
      });

      return result;
    }
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      (status.method === "createSourceIn" || status.method === "updateSourceIn")
    ) {
      if (status.method === "createSourceIn") handleSelectFormatDialogClose();

      handleFormDialogClose();
      setCurrentId(null);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSourceIn"
    ) {
      setOpenConfirmDeleteDialog(false);
      setCurrentId(null);
      dispatch(clearStatus());
    }

    if (status && status.result === "error") {
      enqueueSnackbar("Error: " + status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(
      getSourceIns({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
      })
    );
    dispatch(getCodes(CODELIST_CODES.SRC_SOURCE_FILE_TYPE_CODE));
  }, []);

  return (
    <React.Fragment>
      <NtaiPage
        title={`${_.get(sourceDefData, "name")} / Input Stores`}
        back={`/sourcedefs/${sourceDefId}/smwrapper`}
        headerActions={<SourceAwsLivySessionDialog />}
      >
        <NtaiPanel
          width="100%"
          header="Manage Input Stores"
          subheader="Source ins provide easy to use templates to render source data in user specified templates"
        >
          <Box
            sx={{
              paddingTop: theme.spacing(1),
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: theme.spacing(1),
            }}
          >
            <NtaiAddRecordCard
              handleClick={handleAdd}
              border
              width="320px"
              height="auto"
            >
              Add new store
            </NtaiAddRecordCard>
            {renderSourceInItems()}
          </Box>
        </NtaiPanel>
        {/* </NtaiPage> */}
        <NtaiDialog
          open={selectFormatDialogOpen}
          handleDialogClose={handleSelectFormatDialogClose}
          title="Add Input Store"
          size="sm"
        >
          <SourceInFormSelect
            action={action}
            handleFormSubmit={handleFormSubmit}
            handleCancel={handleSelectFormatDialogClose}
          />
        </NtaiDialog>
        <NtaiDialog
          open={formDialogOpen}
          handleDialogClose={handleFormDialogClose}
          title="Edit Input Store"
          size="md"
        >
          <NtaiForm>
            <SourceInForm
              format={_.get(dataMap[currentId], "typeCode")}
              action={action}
              handleDialogClose={handleFormDialogClose}
              handleFormSubmit={handleFormSubmit}
              formData={action === "edit" ? dataMap[currentId] : {}}
            />
          </NtaiForm>
        </NtaiDialog>

        <NtaiConfirmDeleteDialog
          items={[_.get(dataMap[currentId], "name")]}
          open={openConfirmDeleteDialog}
          handleConfirmDelete={handleDelete}
          handleCancelDelete={handleCancelDelete}
        />
      </NtaiPage>
    </React.Fragment>
  );
}
