import { Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  setAppHeader,
  setLeftSidebarNav,
} from "app/store/ntai/navigationSlice";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import { persistor } from "../../../store";
import { submitLogin } from "app/auth/store/loginSlice";
const _ = require("lodash");

export default function LoginPage() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.auth.loginSlice.status);

  const domainName = window.location.hostname;

  function handleFormSubmit(formData) {
    dispatch(submitLogin(formData));
  }

  useEffect(() => {
    dispatch(setLeftSidebarNav(false));
    dispatch(setAppHeader(false));
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
  }, []);

  useEffect(() => {
    if (domainName) {
      const domainParts = domainName.split(".");
      if (_.isArray(domainParts) && domainParts.length > 0) {
        console.log("TENANT ID: ", domainParts[0]);
        if (domainParts[0].startsWith("tenant"))
          localStorage.setItem("TENANT_ID", domainParts[0]);
        else localStorage.setItem("TENANT_ID", "tenant1");
      }
      console.log("Set TENANT_ID to ", localStorage.getItem("TENANT_ID"));
    }
  }, [domainName]);

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          flexBasis: "90%",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "50%",
            justifyContent: "space-between",
            width: "70%",
            alignItems: "center",
            gap: theme.spacing(10),
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexBasis: "60%",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography fontSize="44px">Welcome to NextTrial AI</Typography>
                <Divider />
                <Typography
                  sx={{ pt: theme.spacing(1) }}
                  fontSize="20px"
                ></Typography>
                <Typography sx={{ textAlign: "justify" }} fontSize="14px">
                  NextTrial AI offers a collaborative, AI-powered, cloud-based
                  smart data platform with the goal of accelerating drug
                  development research and bringing innovative medicines with
                  improved efficacy and safety to patients faster. It achieves
                  this by putting relevant, process-oriented, insight-rich data
                  in the hands of life sciences stakeholders for critical
                  scientific research, improving operational efficiency,
                  informed decision making and gaining complete oversight from
                  inception to commercialization with focus on patient safety
                  and regulatory compliance.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(2),
                }}
              >
                <Chip variant="outlined" label="Smart Data" />
                <Chip variant="outlined" label="Intelligence" />
                <Chip variant="outlined" label="AI/ML" />
                <Chip variant="outlined" label="Collaborative" />
              </Box>
            </Box>
          </Box>
          <Box sx={{ flexBasis: "40%" }}>
            <NtaiForm>
              <LoginForm handleFormSubmit={handleFormSubmit} />
            </NtaiForm>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flexBasis: "10%",
          display: "flex",
          alignItems: "end",
          pb: theme.spacing(2),
        }}
      >
        <Typography sx={{ fontSize: "11px" }}>
          &#169;
          {` ${new Date().getFullYear()} NextTrial AI LLC. All rights reserved.`}
        </Typography>
      </Box>
    </Box>
  );
}
