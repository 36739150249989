import { Box } from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CodelistCodeForm from "./CodelistCodeForm";
import {
  createCodelistCode,
  deleteCodelistCode,
  getCodelistCodes,
  updateCodelistCode,
  clearStatus,
} from "./store/codelistCodesSlice";
import NtaiAddRecordCard from "@ntai/components/cards/NtaiAddRecordCard";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "25%",
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Code",
    width: "15%",
  },

  {
    id: "abbrev",
    numeric: false,
    disablePadding: false,
    label: "Abbrev",
    fontWeight: 700,
    width: "15%",
  },

  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "archive",
  },
];

export default function CodelistCodeList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [codeDialog, setCodeDialog] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [action, setAction] = useState();
  const dataMap = useSelector((state) => state.core.codelistCodesSlice.data);
  const codelistId = useSelector((state) => state.core.codelistsSlice.activeId);
  const status = useSelector((state) => state.core.codelistCodesSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["name"], ["asc"])
      : [];

  function handleAddRecord() {
    handleDialogOpen();
    setAction("add");
  }

  function handleDialogAddEdit(id) {
    if (id) {
      setCurrentId(id);
      setAction("edit");
    } else {
      setAction("add");
      setCurrentId(null);
    }
    handleDialogOpen();
  }

  function handleDialogOpen() {
    setCodeDialog(true);
  }
  function handleDialogClose() {
    setCodeDialog(false);
  }

  function handleSave(formData) {
    if (action === "add")
      dispatch(createCodelistCode({ parentUuId: codelistId, ...formData }));
    else
      dispatch(
        updateCodelistCode({
          uuId: _.get(formData, "uuId"),
          formData: { ...formData },
        })
      );
  }

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function confirmDelete(selected) {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(deleteCodelistCode(id));
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function renderEmptyPage() {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NtaiAddRecordCard handleClick={handleAddRecord} />
      </Box>
    );
  }

  useEffect(() => {
    if (
      status.result === "success" &&
      (status.method === "createCodelistCode" ||
        status.method === "updateCodelistCode")
    ) {
      dispatch(clearStatus());
      handleDialogClose();
      enqueueSnackbar("Successfully saved codelist code", {
        variant: "success",
      });
    }

    if (status && status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });

      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getCodelistCodes(codelistId));
  }, [codelistId]);

  return (
    <>
      <NtaiPanel
        width="100%"
        header="Codelist Codes"
        subheader="Manage codes for the codelist"
      >
        <NtaiCrudTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          isDensed={true}
          dialog={true}
          handleDialogAddEdit={handleDialogAddEdit}
          toolbarActions={toolbarActions}
          handleToolbarAction={handleToolbarAction}
          title="Codelist Code"
        />
      </NtaiPanel>

      {(action === "add" || (action === "edit" && currentId)) && (
        <NtaiDialog
          title={
            action === "add" ? "Add Code" : `Edit ${dataMap[currentId]["name"]}`
          }
          open={codeDialog}
          size="sm"
          handleDialogClose={handleDialogClose}
        >
          <NtaiForm>
            <CodelistCodeForm
              handleDialogClose={handleDialogClose}
              action={action}
              formData={currentId && dataMap[currentId]}
              handleSave={handleSave}
            />
          </NtaiForm>
        </NtaiDialog>
      )}
      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
