import * as React from "react";
import { JSONObjectType } from "./JsonToTableUtils";
import JSONToTableUtils from "./JsonToTableUtils";
import {
  Box,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import NtaiAccordion from "../accordions/NtaiAccordion";
// import styles from "./NtaiJsonToTable.module.css";

export default class NtaiJsonToTable extends React.Component {
  // constructor
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Box>
        <Table key={`__j2t_root_table`}>
          <TableBody key={`__j2t_root_tbody`}>
            {this.renderObject(this.props.json, undefined, 0)}
          </TableBody>
        </Table>
      </Box>
    );
  }

  renderObject = (obj, header, idx) => {
    const phrase = [];
    let tmp;
    if (header) {
      phrase.push(this.renderRowHeader(header));
    }

    const objType = JSONToTableUtils.getObjectType(obj);

    switch (objType) {
      case JSONObjectType.ObjectWithNonNumericKeys:
        tmp = header ? (
          <Table key={`__j2t_tableObj${idx}`}>
            <TableBody key={`__j2t_bObj${idx}`}>
              {this.renderRows(obj)}
            </TableBody>
          </Table>
        ) : (
          this.renderRows(obj)
        );
        break;
      case JSONObjectType.Array:
        tmp = header ? (
          <NtaiAccordion
            sx={{ p: 0 }}
            titleIcon={<Icon>star</Icon>}
            title={header}
          >
            <Table key={`__j2t_tableArr${idx}`}>
              <TableBody key={`__j2t_bArr${idx}`}>
                {this.parseArray(obj)}
              </TableBody>
            </Table>
          </NtaiAccordion>
        ) : (
          this.parseArray(obj)
        );
        break;
    }
    phrase.push(tmp);
    const retval = phrase.map((p) => p);
    return header ? (
      <TableRow key={`__j2t_trObj${idx}`}>
        {this.renderCell({ content: retval, colspan: 2 })}
      </TableRow>
    ) : (
      retval
    );
  };

  getCellValue = (content) => {
    const valueDisplay =
      content === true || content === false ? content.toString() : content;
    return valueDisplay;
  };

  renderCell = (params) => {
    const { content, colspan, isHeader } = params;
    const valueDisplay = isHeader ? (
      <strong>{this.getCellValue(content)}</strong>
    ) : (
      this.getCellValue(content)
    );
    return (
      <TableCell
        colSpan={colspan ? colspan : 0}
        key={`__j2t_trObj${valueDisplay}`}
      >
        {valueDisplay}
      </TableCell>
    );
  };

  renderHeader = (labels) => {
    return (
      <TableRow key={`__j2t_trHeader`}>
        {labels.map((v) => {
          return this.renderCell({ content: v });
        })}
      </TableRow>
    );
  };

  renderValues = (values) => {
    return (
      <TableRow key={`__j2t_trArrString`}>
        {values.map((k) => {
          return this.renderCell({ content: k });
        })}
      </TableRow>
    );
  };

  renderRowValues = (anArray, labels) => {
    return anArray.map((item, idx) => {
      return (
        <TableRow key={`__j2t_Arr${idx.toString()}`}>
          {labels.map((k) => {
            const isValuePrimitive =
              JSONToTableUtils.getObjectType(item[k]) ===
              JSONObjectType.Primitive;
            return isValuePrimitive
              ? this.renderCell({ content: item[k] })
              : this.renderObject(item[k], k, idx);
          })}
        </TableRow>
      );
    });
  };

  parseArray = (anArray) => {
    const phrase = [];
    const labels = JSONToTableUtils.getUniqueObjectKeys(anArray);
    if (JSONToTableUtils.checkLabelTypes(labels.labels) !== "number") {
      phrase.push(this.renderHeader(labels.labels));
      phrase.push(this.renderRowValues(anArray, labels.labels));
    } else {
      phrase.push(this.renderValues(anArray));
    }
    return phrase;
  };

  renderRow = (k, v, idx) => {
    return (
      <TableRow key={`__j2t_tr${idx}`}>
        <TableCell key={`__j2t_tdk${idx}`}>
          <strong>{k}</strong>
        </TableCell>
        <TableCell key={`__j2t_tdv${idx}`}>{v}</TableCell>
      </TableRow>
    );
  };

  renderRowHeader = (label) => {
    return (
      <Box key={`__j2t_rw${label}`}>
        <strong>{label}</strong>
      </Box>
    );
  };

  renderRows = (obj, labelKey) => {
    return Object.keys(obj).map((k, idx) => {
      const value = obj[k];
      const isValuePrimitive =
        JSONToTableUtils.getObjectType(value) === JSONObjectType.Primitive;
      // render row when value is primitive otherwise inspect the value and make the key as header
      const retval = isValuePrimitive
        ? this.renderRow(k, value, idx)
        : this.renderObject(value, k, idx);

      return retval;
    });
  };
}
