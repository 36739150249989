import {
  faBars,
  faListCheck,
  faPie,
  faPieChart,
  faShareAlt,
  faText,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import NtaiDialog from "@ntai/components/dialogs/NtaiDialog";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../SearchEdit";
import NtaiMuiBadge from "@ntai/components/badges/NtaiMuiBadge";
import SearchSummary from "./SearchSummary";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
const _ = require("lodash");

export default function SearchSummaryWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchData } = React.useContext(SearchContext);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const searchId = _.get(searchData, "uuId");

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  return (
    <React.Fragment>
      <NtaiMuiBadge
        badgeContent={searchData["searchSummaryRecordsCount"]}
        color="secondary"
        top={8}
        right={2}
      >
        <Tooltip title="View Summary">
          <IconButton onClick={() => handleDialogOpen()}>
            <FontAwesomeIcon size="2xs" icon={faListCheck} />
          </IconButton>
        </Tooltip>
      </NtaiMuiBadge>

      <NtaiDialog
        title="Summary"
        size="md"
        open={dialogOpen}
        handleDialogClose={handleDialogClose}
      >
        {searchData["searchSummaryRecordsCount"] > 0 && <SearchSummary />}
        {searchData["searchSummaryRecordsCount"] === 0 && (
          <NtaiEmptyMessage header="No data available" />
        )}
      </NtaiDialog>
    </React.Fragment>
  );
}
