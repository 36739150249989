import { faDog } from "@fortawesome/pro-light-svg-icons";
import { faWheatAwnCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { setLeftSidebarNav } from "app/store/ntai/navigationSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function NtaiHelpDocs() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const leftSideBarNav = useSelector(
    (state) => state.ntai.navigation.leftSidebarNav
  );

  React.useEffect(() => {
    dispatch(setLeftSidebarNav(false));
  }, []);

  return (
    <>
      {!leftSideBarNav && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          {/* <iframe
            style={{ height: "100%", width: "100%" }}
            src="http://localhost:3001/"
            title="description"
          ></iframe> */}
          <FontAwesomeIcon size="2xl" icon={faDog} />
          <Typography variant="subtitle1">Awaiting nuggets</Typography>
        </Box>
      )}
      {leftSideBarNav && <NtaiCircularProgress size={24} />}
    </>
  );
}
