import React from "react";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import pagesConfigs from "../main/pagesConfigs";
import { Redirect } from "react-router-dom";
import TestNtaiPage from "@ntai/core/NtaiPage/TestNtaiPage";
import TestSidebar from "../layouts/Sidebar/TestSidebar";
import Playground from "@ntai/components/Playground";

const routeConfigs = [...pagesConfigs];

const routes = [
  ...NtaiUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "/playground",
    exact: true,
    component: () => <Playground />,
  },
  {
    path: "/test/page",
    exact: true,
    component: () => <TestNtaiPage />,
  },
  {
    path: "/test/sidebar",
    exact: true,
    component: () => <TestSidebar />,
  },
];

export default routes;
