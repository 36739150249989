import { Box, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";

export default function ObjectFormGeneral({ handleSave, handleCancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();

  const objectId = useSelector((state) => state.core.objectsSlice.activeId);
  const objectFormData = useSelector(
    (state) => state.core.objectFormsSlice.data
  );

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("objectGeneralForm")
    );

    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ objectGeneralForm: objectFormData ? objectFormData : null });
  }, []);

  return (
    <NtaiFormPanel
      width="50%"
      header="General Information"
      subheader="Enter name, label, description and optionally enable or disable tab/panel display"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <NtaiTextField
        name="objectGeneralForm.name"
        label="Name*"
        rules={{ required: "Name is required" }}
      />
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <NtaiSwitchField
          name="objectGeneralForm.hideFormLabelsFg"
          label="Hide form label?"
        />
        <NtaiSwitchField
          name="objectGeneralForm.hideTabsFg"
          label="Hide tabs?"
        />
      </Box>

      <NtaiTextField
        name="objectGeneralForm.label"
        label="Label*"
        rules={{ required: "Label is required" }}
      />
      <NtaiTextField
        name="objectGeneralForm.description"
        label="Description"
        multiline
        minRows={3}
        maxRows={3}
      />
    </NtaiFormPanel>
  );
}
