import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "@ntai/@history";
import { getDefaultDomainRootObject } from "./menu/store/entityMenusSlice";
const _ = require("lodash");

export default function RedirectToEntityList() {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.core.entityMenusSlice.status);

  const defaultDomainRootObject = useSelector(
    (state) => state.core.entityMenusSlice.defaultDomainRootObject
  );

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getDefaultDomainRootObject"
    ) {
      const objectId = Object.keys(defaultDomainRootObject)[0];
      const domainId = _.get(
        Object.values(defaultDomainRootObject)[0],
        "domainUuId"
      );
      history.push(
        `/entities-library/domains/${domainId}/objects/${objectId}/entities`
      );
    }
  }, [status]);

  useEffect(() => {
    dispatch(getDefaultDomainRootObject("undefined"));
  }, []);

  return <>{status !== "success" && <NtaiCircularProgress />}</>;
}
