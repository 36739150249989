import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import { useSelector, useDispatch } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  clearStatus,
  createSearchSourceRecordAction,
  deleteSearchSourceRecordAction,
  getSearchSourceRecordActions,
  updateSearchSourceRecordAction,
} from "./store/searchSourceRecordActionsSlice";
import { SearchSourceRecordListContext } from "../../SearchSourceRecordListPanel";
import { SearchSourceContext } from "../../../SearchSource";
import SearchSourceRecordActionForm from "./SearchSourceRecordActionForm";
import {
  CODELIST_CODES,
  SRC_SOURCE_RECORD_SUMMARY,
} from "app/main/constants/NtaiCodelistConstants";
import { getCodes } from "app/main/pages/core/admin/codelist/store/codelistsSlice";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { useSnackbar } from "notistack";
import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
const _ = require("lodash");

const toolbarActions = [
  {
    label: "Delete",
    icon: "remove",
    value: "delete",
  },
];

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "action.title",
    numeric: false,
    disablePadding: false,
    label: "Name",
    fontWeight: 700,
    width: "25%",
  },
  {
    id: "action.statusDecode",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action.owner",
    numeric: false,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "action.actualStartDate",
    numeric: false,
    disablePadding: false,
    label: "Actual Start",
    transformFunc: NtaiUtils.formatDateCol,
  },
  {
    id: "action.actualEndDate",
    numeric: false,
    disablePadding: false,
    label: "Actual End",
    transformFunc: NtaiUtils.formatDateCol,
  },
];

export default function SearchSourceRecordActionList() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [action, setAction] = useState("list");
  const [loaded, setLoaded] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const { selectedRecord, setRefreshSelectedRecord } = React.useContext(
    SearchSourceRecordListContext
  );

  const { searchSourceData } = React.useContext(SearchSourceContext);
  const searchSourceId = _.get(searchSourceData, "selectedSearchSourceUuId");

  const selectedSearchSourceData =
    _.get(searchSourceData, "selectedFg") === 1
      ? searchSourceData
      : _.get(_.get(searchSourceData, "mapChildSearchSources"), searchSourceId);

  const sourceId = _.get(
    selectedSearchSourceData,
    "searchSourceDefinition.sourceUuId"
  );

  const searchId = useSelector((state) => state.search.searchSlice.activeId);

  const recordId = _.get(selectedRecord, "recordId");

  const selectedSearchSourceRecordUuId =
    selectedRecord &&
    selectedRecord["uuId"] &&
    selectedRecord["uuId"].length > 0
      ? _.get(selectedRecord, "uuId")
      : "*";

  const dataMap = useSelector(
    (state) => state.search.searchSourceRecordActionsSlice.data
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const status = useSelector(
    (state) => state.search.searchSourceRecordActionsSlice.status
  );

  function handleToolbarAction(toolbarAction, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "action.title"),
    ]);
    if (toolbarAction === "delete") confirmDelete();
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function handleDelete() {
    if (Array.isArray(selectedIds) && selectedIds.length > 0) {
      selectedIds.forEach((id) => {
        dispatch(
          deleteSearchSourceRecordAction({
            searchId: searchId,
            searchSourceId: searchSourceId,
            searchSourceRecordId: selectedSearchSourceRecordUuId,
            uuId: id,
          })
        );
      });
    }
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  function handleDialogAddEdit(id) {
    if (id) {
      setAction("edit");
      setCurrentId(id);
    } else {
      setAction("add");
      setCurrentId(null);
    }
  }

  function handleCancel() {
    setAction("list");
  }

  function handleRefreshSelectedRecord() {
    setRefreshSelectedRecord({
      summaryType: SRC_SOURCE_RECORD_SUMMARY.SRC_SOURCE_RECORD_SUMMARY_ACTIONS,
      recordIds: [selectedRecord["recordId"]],
    });
  }

  function handleSave(formData) {
    if (action === "add") {
      dispatch(
        createSearchSourceRecordAction({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          formData: {
            action: { ...formData, recordIds: [recordId] },
            recordId: recordId,
          },
        })
      );
    } else if (action === "edit")
      dispatch(
        updateSearchSourceRecordAction({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          uuId: currentId,
          formData: {
            action: { ...formData, recordIds: [recordId] },
            recordId: recordId,
          },
        })
      );
  }

  useEffect(() => {
    if (
      status &&
      status.result === "success" &&
      status.method === "getSearchSourceRecordActions"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      [
        "createSearchSourceRecordAction",
        "updateSearchSourceRecordAction",
      ].includes(status.method) &&
      status.result === "success"
    ) {
      setAction("list");
      dispatch(clearStatus());
      // setRefreshSelectedRecord(true);
      enqueueSnackbar(`Action saved successfully.`, {
        variant: "success",
      });
      handleRefreshSelectedRecord();
    }

    if (
      status &&
      status.result === "success" &&
      status.method === "deleteSearchSourceRecordAction"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Action(s) "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }

      handleRefreshSelectedRecord();
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (recordId) {
      dispatch(
        getSearchSourceRecordActions({
          searchId: searchId,
          searchSourceId: searchSourceId,
          searchSourceRecordId: selectedSearchSourceRecordUuId,
          formData: {
            sourceId: sourceId,
            recordId: recordId,
          },
        })
      );
      dispatch(getCodes(CODELIST_CODES.COM_ACTION_CATEGORY_CODE));
      dispatch(getCodes(CODELIST_CODES.COM_ACTION_STATUS_CODE));
      dispatch(getCodes(CODELIST_CODES.COM_ACTION_PRIORITY_CODE));
    }
  }, [recordId]);

  return (
    <>
      {action === "list" && loaded && (
        <NtaiPanel
          width="100%"
          header="Actions"
          subheader="Action Items are generally understood as tasks or actions. In simple words, the Action Items provide tiny insights on what, how, and when the tasks or actions are expected to be completed."
        >
          <NtaiCrudTable
            rows={data}
            headCells={headCells}
            keyColumn="action.uuId"
            toolbarActions={toolbarActions}
            selectedIds={selectedIds}
            handleToolbarAction={handleToolbarAction}
            isDensed={true}
            dialog={true}
            handleDialogAddEdit={handleDialogAddEdit}
            title="Action"
          />
        </NtaiPanel>
      )}
      {(action === "add" || action === "edit") && (
        <NtaiForm>
          <SearchSourceRecordActionForm
            action={action}
            formData={
              action === "edit"
                ? _.get(_.get(dataMap, currentId), "action")
                : null
            }
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </NtaiForm>
      )}
      {!loaded && action === "list" && <NtaiCircularProgress />}

      <NtaiConfirmDeleteDialog
        // items={_.get(dataMap[currentId], "name")}
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(
            selectedIds,
            dataMap,
            "action.title"
          )
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
