import {
  Box,
  Button,
  Divider,
  lighten,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiUtils from "@ntai/utils";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToolsAssessmentImportContext } from "./ToolsAssessmentImportCreate";
import { getObject } from "app/main/pages/core/cdo/object/store/objectsSlice";
import ObjectFormUtil from "app/main/pages/core/cdo/object/form/util/ObjectFormUtil";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import Scrollbar from "@ntai/components/Scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/pro-regular-svg-icons";
import NtaiDashboardMetric1Widget from "@ntai/components/widgets/dashboard/templates/NtaiDashboardMetric1Widget";
import { green, grey, red } from "@mui/material/colors";
import NtaiLabelValue from "@ntai/components/micros/NtaiLabelValue";
import NtaiLabelValue1 from "@ntai/components/micros/NtaiLabelValue1";
const _ = require("lodash");

export default function ToolsAssessmentImportForm3(props) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();
  const { localFormData, adjFormData, submitFormData } = React.useContext(
    ToolsAssessmentImportContext
  );

  const { handleFinalSubmit, handleBack, handleCancel } = props;

  const [loaded, setLoaded] = useState(false);

  const data = useSelector(
    (state) => state.tools.toolsAssessmentImportSlice.data
  );

  console.log("Data: ", data);

  const status = useSelector(
    (state) => state.tools.toolsAssessmentImportSlice.status
  );

  function onHandleFinalSubmit() {
    handleFinalSubmit(submitFormData);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "validateToolsAssessmentImport" &&
      status.result === "success"
    ) {
      reset({
        toolsAssesmentImportAdjForm3: ObjectFormUtil.populateFormData(
          _.get(data, "adjudication.clientData")
        ),
      });
      setLoaded(true);
    }
  }, [status]);

  return (
    <React.Fragment>
      {loaded && (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "calc(100% - 54px)",
              bottom: "54px",
              width: "100%",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexBasis: "50%",
                height: "100%",
                flexDirection: "column",
                borderRight: theme.general.border1,
              }}
            >
              <Scrollbar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: theme.spacing(2),
                    p: theme.spacing(2),
                  }}
                >
                  {ObjectFormUtil.generateForm(
                    "toolsAssesmentImportAdjForm3",
                    adjFormData,
                    false,
                    theme
                  )}
                </Box>
              </Scrollbar>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexBasis: "50%",
                p: theme.spacing(2),
              }}
            >
              <NtaiFormPanel
                padding="0px"
                width="100%"
                header="Review and Submit"
                subheader="Reiview assessment data, name, description, records matched and rejected and submit."
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    flexDirection: "column",
                    gap: theme.spacing(2),
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: theme.spacing(1),
                    }}
                  >
                    <NtaiDashboardMetric1Widget
                      header="Matched"
                      subheader={data.noOfRecords}
                      footer="Matched Entities"
                      bg={green[300]}
                      fg="white"
                    />
                    <NtaiDashboardMetric1Widget
                      header="Rejected"
                      subheader={data.noOfRejectedRecords}
                      footer="Rejected Records"
                      bg={red[300]}
                      fg="white"
                    />
                    <NtaiDashboardMetric1Widget
                      header="Total"
                      subheader={data.noOfRejectedRecords + data.noOfRecords}
                      footer="Total Records"
                      bg={grey[500]}
                      fg="white"
                    />
                  </Box>
                  <NtaiLabelValue1 label="Name" value={data["name"]} />
                  <NtaiLabelValue1
                    label="Description"
                    value={data["description"]}
                  />

                  <NtaiLabelValue1
                    label="Matched Records"
                    value={_.map(data["recordsMatched"], function (o) {
                      return o["recordId"];
                    }).join(", ")}
                  />
                  <NtaiLabelValue1
                    label="Rejected Records"
                    value={
                      data["recordsRejected"].length > 0
                        ? _.map(data["recordsRejected"], function (o) {
                            return o["recordId"];
                          }).join(", ")
                        : "None"
                    }
                  />
                </Box>
              </NtaiFormPanel>
            </Box>
          </Box>
          <Box
            sx={{
              borderTop: theme.general.border1,
              px: theme.spacing(2),
              display: "flex",
              height: "54px",
              alignItems: "center",
              justifyContent: "end",
              gap: theme.spacing(1),
            }}
          >
            <Button size="small" onClick={() => handleCancel()}>
              CANCEL
            </Button>
            <Button size="small" onClick={() => handleBack(1)}>
              BACK
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => onHandleFinalSubmit()}
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      )}
      {!loaded && <NtaiCircularProgress />}
    </React.Fragment>
  );
}
