import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import history from "@ntai/@history";
import { clearStatus, createThesaurus } from "./store/thesaurusesSlice";
import ThesaurusForm from "./ThesaurusForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function ThesaurusCreate(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.core.thesaurusesSlice.status);
  const dataMap = useSelector((state) => state.core.thesaurusesSlice.data);
  const activeId = useSelector((state) => state.core.thesaurusesSlice.activeId);
  const domainId = useSelector(
    (state) => state.core.thesaurusesSlice.defaultDomainId
  );

  function handleSave(formData) {
    formData["domainId"] = domainId;
    dispatch(createThesaurus(formData));
  }

  useEffect(() => {
    if (status.method === "createThesaurus" && status.result === "success") {
      enqueueSnackbar(
        `Thesaurus  "${_.get(
          dataMap[activeId],
          "name"
        )}" created successfully.`,
        {
          variant: "success",
        }
      );

      dispatch(clearStatus());
      history.push(`/library/thesauruses/${activeId}/edit`);
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  return (
    <NtaiPage title="Add Thesaurus" back={`/library/thesauruses`}>
      <NtaiForm>
        <ThesaurusForm action="add" handleSave={handleSave} />
      </NtaiForm>
    </NtaiPage>
  );
}
