import { Box, Divider, useTheme } from "@mui/material";
import React from "react";

export default function NtaiFilterBox(props) {
  const theme = useTheme();
  const { children } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          px: theme.spacing(2),
          py: "8px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        {children}
      </Box>
      {/* <Divider /> */}
    </Box>
  );
}
