import { Box, useTheme } from "@mui/material";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceFields,
  clearStatus as clearSourcesSliceStatus,
} from "../../../../sourcedefinition/source/store/sourcesSlice";
import { useState } from "react";
import NtaiAppUtils from "app/main/shared/utils/NtaiAppUtils";
import NtaiQueryBuilder from "@ntai/components/querybuilder/NtaiQueryBuilder";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import {
  getSourceDerivedFields,
  clearStatus as clearSourceDerivedFieldsSliceStatus,
} from "app/main/pages/core/sourcedefinition/source/derivedfield/store/sourceDerivedFieldsSlice";
const _ = require("lodash");

export default function DashboardSourceWidgetFormCriteria({
  formData,
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [fields, setFields] = useState();
  const [sourceCriteria, setSourceCriteria] = useState(
    _.get(formData, "sourceCriteria") ? _.get(formData, "sourceCriteria") : null
  );
  const [sourceEsCriteria, setSourceEsCriteria] = useState(
    _.get(formData, "sourceEsCriteria")
      ? _.get(formData, "sourceEsCriteria")
      : null
  );

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourcesSlice.fields
  );

  const sourceDerivedFieldsDataMap = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.data
  );

  const sourceFieldsStatus = useSelector(
    (state) => state.sources.sourcesSlice.status
  );

  const sourceDerivedFieldsStatus = useSelector(
    (state) => state.sources.sourceDerivedFieldsSlice.status
  );

  const sourceFieldsData =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? Object.values(sourceFieldsDataMap)
      : [];

  const sourceDerivedFieldsData =
    sourceDerivedFieldsDataMap && !_.isEmpty(sourceDerivedFieldsDataMap)
      ? Object.values(sourceDerivedFieldsDataMap)
      : [];

  const queryValue = _.get(formData, "sourceCriteria");

  function onHandleSave() {
    const revisedFormData = _.cloneDeep(formData);
    if (
      sourceCriteria &&
      !_.isEqual(sourceCriteria, _.get(formData, "sourceCriteria"))
    ) {
      revisedFormData["sourceCriteria"] = sourceCriteria;
      revisedFormData["sourceEsCriteria"] = sourceEsCriteria;
      revisedFormData["updateTypeFg"] = 2;
      handleFormSubmit(revisedFormData);
    }
  }

  function buildQuery(tree, config, jsonTree, formats) {
    setSourceCriteria(jsonTree);
    setSourceEsCriteria(_.get(formats, "esFormat"));
  }

  useEffect(() => {
    if (
      sourceFieldsStatus &&
      sourceDerivedFieldsStatus &&
      sourceFieldsStatus.method === "getSourceFields" &&
      sourceDerivedFieldsStatus.method === "getSourceDerivedFields" &&
      sourceFieldsStatus.result === "success" &&
      sourceDerivedFieldsStatus.result === "success"
    ) {
      const mergedFields = NtaiAppUtils.mergeSourceFields(
        sourceFieldsData,
        sourceDerivedFieldsData
      );

      const tmpFields = NtaiAppUtils.generateQueryBuilderFields1(
        "*",
        _.get(formData, "sourceUuId"),
        mergedFields
      );

      setFields({ ...tmpFields });
      setFieldsLoaded(true);
      dispatch(clearSourcesSliceStatus());
      dispatch(clearSourceDerivedFieldsSliceStatus());
    }
  }, [sourceFieldsStatus, sourceDerivedFieldsStatus]);

  useEffect(() => {
    dispatch(
      getSourceFields({
        sourceDefId: "undefined",
        sourceId: _.get(formData, "sourceUuId"),
      })
    );

    dispatch(
      getSourceDerivedFields({
        sourceDefId: "undefined",
        sourceId: _.get(formData, "sourceUuId"),
      })
    );
  }, []);

  return (
    <NtaiFormPanel
      width="75%"
      header="Criteria"
      subheader="Lorum ipsum dave and then comes jumping fox to the center of the hemispphere"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          paddingBottom: theme.spacing(1),
        }}
      >
        {fieldsLoaded && fields && Object.keys(fields).length !== 0 && (
          <NtaiQueryBuilder
            fields={fields}
            buildQuery={buildQuery}
            value={queryValue}
          />
        )}
        {!fieldsLoaded && <NtaiCircularProgress />}
      </Box>
    </NtaiFormPanel>
  );
}
