import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSourcePipelineRefSourceDatasets = createAsyncThunk(
  "sourcePipelineRefSourceDatasets/getSourcePipelineRefSourceDatasets",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ref-datasets`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSourcePipelineRefSourceDatasetPreview = createAsyncThunk(
  "sourcePipelineRefSourceDatasets/getSourcePipelineRefSourceDatasetPreview",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, uuId } = values;
      const response = await server.get(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ref-datasets/${uuId}/preview`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createOrDeleteSourcePipelineRefSourceDatasets = createAsyncThunk(
  "sourcePipelineRefSourceDatasets/createOrDeleteSourcePipelineRefSourceDatasets",
  async (values, { rejectWithValue }) => {
    try {
      const { sourceDefId, sourceId, sourcePipelineId, formData } = values;
      const response = await server.post(
        `/sourcedefs/${sourceDefId}/sources/${sourceId}/pipelines/${sourcePipelineId}/ref-datasets`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sourcePipelineRefSourceDatasetsSlice = createSlice({
  name: "sourcePipelineRefSourceDatasets",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getSourcePipelineRefSourceDatasets.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "getSourcePipelineRefSourceDatasets",
      };
    },
    [getSourcePipelineRefSourceDatasets.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelineRefSourceDatasets",
        message: action.payload.message,
      };
    },

    [getSourcePipelineRefSourceDatasetPreview.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = {
        result: "success",
        method: "getSourcePipelineRefSourceDatasetPreview",
      };
    },
    [getSourcePipelineRefSourceDatasetPreview.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getSourcePipelineRefSourceDatasetPreview",
        message: action.payload.message,
      };
    },

    [createOrDeleteSourcePipelineRefSourceDatasets.fulfilled]: (
      state,
      action
    ) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = {
        result: "success",
        method: "createOrDeleteSourcePipelineRefSourceDatasets",
      };
    },
    [createOrDeleteSourcePipelineRefSourceDatasets.rejected]: (
      state,
      action
    ) => {
      state.status = {
        result: "error",
        method: "createOrDeleteSourcePipelineRefSourceDatasets",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  sourcePipelineRefSourceDatasetsSlice.actions;

export default sourcePipelineRefSourceDatasetsSlice.reducer;
