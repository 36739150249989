import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";

import React from "react";

function getHeaderColumns(data) {
  const result = [];
  const row1 = data[0];
  Object.keys(row1).forEach((col, i) => {
    result.push(col);
  });

  return result;
}

export default function NtaiDashboardTableWidget(props) {
  const theme = useTheme();
  const { data, headCells } = props;

  function generateHeadCells() {
    const result = [];

    headCells.forEach((hc, i) => {
      result.push(
        <TableCell
          sx={{
            width: hc.width ? hc.width : null,
          }}
        >
          {hc.label}
        </TableCell>
      );
    });

    return result;
  }

  function generateTableData() {
    const result = [];
    data.forEach((row, i) => {
      const rowCells = [];
      headCells.forEach((hc, j) => {
        if (hc.transformFunc)
          rowCells.push(
            <TableCell sx={{ px: "4px", fontSize: 12 }}>
              <Typography fontSize="12px" fontWeight={j === 0 ? 700 : 500}>
                {hc.transformFunc(row[hc.id])}
              </Typography>
            </TableCell>
          );
        else
          rowCells.push(
            <TableCell sx={{ px: "4px", fontSize: 12 }}>
              <Typography fontSize="12px" fontWeight={j === 0 ? 700 : 500}>
                {row[hc.id]}
              </Typography>
            </TableCell>
          );
      });
      result.push(<TableRow>{rowCells}</TableRow>);
    });

    return result;
  }

  return (
    <Box sx={{ p: theme.spacing(1), width: "100%", height: "100%" }}>
      <Scrollbar autoHide={true}>
        <Table>
          <TableHead>
            <TableRow>{generateHeadCells()}</TableRow>
          </TableHead>
          <TableBody>{generateTableData()}</TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
}
