import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import NtaiBadge from "@ntai/components/badges/NtaiBadge";
import React from "react";
import NtaiIcons from "utils/NtaiIcons";
import { OversightContext } from "../../../../../Oversight";
import { OversightTopicContext } from "../../content/OversightTopicContent";

export default function OversightHeaderWidget(props) {
  const theme = useTheme();
  const { id, icon, header, subheader, badge } = props;
  const { section, setSection } = React.useContext(OversightContext);
  const { topicSection, setTopicSection } = React.useContext(
    OversightTopicContext
  );

  return (
    <Box
      sx={{
        width: "180px",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        px: theme.spacing(2),
        py: "4px",
        borderRadius: theme.general.borderRadius,
        "&:hover": {
          background: grey[200],
          cursor: "pointer",
        },
        background: topicSection === id ? grey[200] : null,
      }}
      onClick={() => setTopicSection(id)}
    >
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(1),
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={NtaiIcons[icon]}
          size="md"
          color={theme.colors.secondary.main}
        />
        <Typography variant="h6" fontWeight="700">
          {header}
        </Typography>
        {badge && <NtaiBadge title={badge.label} fg={badge.fg} bg={badge.bg} />}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="caption" color="inherit">
          {subheader}
        </Typography>
      </Box>
    </Box>
  );
}
