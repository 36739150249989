import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, Divider, IconButton, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiAutoSuggestIB from "@ntai/components/inputs/NtaiAutoSuggestIB";
import { faBars, faExpand } from "@fortawesome/pro-light-svg-icons";
import { useSelector } from "react-redux";
import { SearchDashboardInitContext } from "../SearchDashboardInit";

const _ = require("lodash");

export default function SearchDashboardEntitySearch() {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("RTY");
  const [openExpandDialog, setOpenExpandDialog] = useState(false);
  const [options, setOptions] = useState([]);
  const { getValues, reset } = useFormContext();

  const { setEntitiesQueryText } = React.useContext(SearchDashboardInitContext);

  const codelistDataMap = useSelector(
    (state) => state.core.codelistsSlice.data
  );

  const data =
    codelistDataMap && !_.isEmpty(codelistDataMap)
      ? Object.values(codelistDataMap)
      : [];

  const debouncedOnHandleChange = _.debounce(handleInputChange, 500);

  function handleInputChange(str) {
    // setInputValue(str);
    setEntitiesQueryText(str);
    // setDashboardsQueryText(str);
  }

  function handleChange(qTerms) {
    // setSearchQueryTerms(qTerms);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          width: "100%",
        }}
      >
        <NtaiAutoSuggestIB
          options={options}
          name="searchInitForm.query"
          optionLabelField="term"
          defaultValue={[]}
          placeholder="Search for entity..."
          label="Search"
          handleChange={handleChange}
          handleInputChange={debouncedOnHandleChange}
          endAdornments={
            <>
              <>
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faExpand} />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton>
                  <FontAwesomeIcon size="2xs" icon={faBars} />
                </IconButton>
              </>
            </>
          }
        />
      </Box>
    </>
  );
}
