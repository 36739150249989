import React, { useContext, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import NtaiTextField from "@ntai/components/inputs/NtaiTextField";
import Scrollbar from "@ntai/components/Scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBackward } from "@fortawesome/pro-regular-svg-icons";
import NtaiDateField from "@ntai/components/inputs/NtaiDateField";
import NtaiSelectField from "@ntai/components/inputs/NtaiSelectField";
import { useFormContext } from "react-hook-form";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import { CODELIST_CODES } from "app/main/constants/NtaiCodelistConstants";
import { useSelector } from "react-redux";
import NtaiFormPanel from "@ntai/components/panels/NtaiFormPanel";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { faSave, faSpinner } from "@fortawesome/pro-light-svg-icons";
const _ = require("lodash");

export default function SearchSourceRecordActionForm({
  action,
  handleSave,
  handleCancel,
  formData,
}) {
  const theme = useTheme();
  const { getValues, reset } = useFormContext();

  const codes = useSelector((state) => state.core.codelistsSlice.codes);
  const actionCategoryOptions = _.get(
    codes,
    CODELIST_CODES.COM_ACTION_CATEGORY_CODE
  );

  const actionStatusOptions = _.get(
    codes,
    CODELIST_CODES.COM_ACTION_STATUS_CODE
  );

  const actionPriorityOptions = _.get(
    codes,
    CODELIST_CODES.COM_ACTION_PRIORITY_CODE
  );

  const status = useSelector(
    (state) => state.search.searchSourceRecordActionsSlice.status
  );

  const statusPending =
    status &&
    status.result === "pending" &&
    [
      "updateSearchSourceRecordAction",
      "createSearchSourceRecordAction",
    ].includes(status.method)
      ? true
      : false;

  function onHandleSave() {
    const sanitizedFormData = NtaiUtils.sanitizeFormData(
      getValues("actionForm")
    );
    handleSave(sanitizedFormData);
  }

  useEffect(() => {
    reset({ actionForm: action === "edit" ? formData : {} });
  }, []);
  return (
    <NtaiPanel
      width="100%"
      header="Actions"
      subheader="Define actions to be taken pertaining to the source record"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          gap: theme.spacing(2),
        }}
      >
        <Box sx={{ width: "75%" }}>
          <NtaiTextField
            name="actionForm.title"
            label="Title"
            placeholder="Title"
          />
        </Box>

        <NtaiTextField
          name="actionForm.description"
          label="Description"
          minRows={2}
          maxRows={2}
          multiline
          placeholder="Description"
        />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: theme.spacing(1),
          }}
        >
          <Box sx={{ display: "flex", width: "40%", flexDirection: "column" }}>
            <NtaiSelectField
              name="actionForm.categoryCode"
              label="Category"
              options={actionCategoryOptions}
            />
          </Box>

          <Box sx={{ display: "flex", width: "30%", flexDirection: "column" }}>
            <NtaiSelectField
              name="actionForm.priorityCode"
              label="Priority"
              options={actionPriorityOptions}
            />
          </Box>

          <Box sx={{ display: "flex", width: "30%", flexDirection: "column" }}>
            <NtaiSelectField
              name="actionForm.statusCode"
              label="Status"
              options={actionStatusOptions}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          <NtaiDateField
            label="Planned Start"
            name="actionForm.plannedStartDate"
          />
          <NtaiDateField label="Planned End" name="actionForm.plannedEndDate" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            pb: theme.spacing(2),
          }}
        >
          <NtaiDateField
            label="Actual Start"
            name="actionForm.actualStartDate"
          />
          <NtaiDateField label="Actual End" name="actionForm.actualEndDate" />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            pb: theme.spacing(2),
            justifyContent: "end",
          }}
        >
          <Button size="small" onClick={() => handleCancel()}>
            CANCEL
          </Button>
          <Button
            onClick={() => onHandleSave()}
            size="small"
            variant="contained"
            startIcon={
              <FontAwesomeIcon
                spin={statusPending ? true : false}
                icon={statusPending ? faSpinner : faSave}
              />
            }
            disabled={statusPending ? true : false}
          >
            SAVE
          </Button>
        </Box>
      </Box>
    </NtaiPanel>
  );
}
