import NtaiConfirmDeleteDialog from "@ntai/components/dialogs/NtaiConfirmDeleteDialog";
import NtaiCrudTable from "@ntai/components/tables/crud/NtaiCrudTable";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiUtils from "@ntai/utils/NtaiUtils";
import {
  clearStatus,
  deleteThesaurus,
  getThesauruses,
  setThesaurusDefaultDomainId,
} from "./store/thesaurusesSlice";
import {
  getSetting,
  clearStatus as clearSettingStatus,
} from "../../admin/setting/store/settingsSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiCircularProgress from "@ntai/components/progress/NtaiCircularProgress";
import { useSnackbar } from "notistack";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "shortName",
    numeric: false,
    disablePadding: false,
    label: "Short Name",
    fontWeight: 700,
    width: "15%",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "20%",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    width: "20%",
  },
  {
    id: "dateModified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    transformFunc: NtaiUtils.formatDateCol,
    width: "15%",
  },
  {
    id: "userModified",
    numeric: false,
    disablePadding: false,
    label: "Modified by",
    width: "15%",
  },
];

const toolbarActions = [
  {
    value: "delete",
    label: "Delete",
    icon: "delete",
  },
];

export default function ThesaurusList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = React.useState(false);
  const [action, setAction] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [defaultDomainUuId, setDefaultDomainUuId] = useState();

  const dataMap = useSelector((state) => state.core.thesaurusesSlice.data);
  const status = useSelector((state) => state.core.thesaurusesSlice.status);
  const data =
    dataMap && !_.isEmpty(dataMap)
      ? _.orderBy(Object.values(dataMap), ["shortName"], ["asc"])
      : [];

  const settingsDataMap = useSelector((state) => state.core.settingsSlice.data);
  const settingsStatus = useSelector(
    (state) => state.core.settingsSlice.status
  );
  const settings =
    settingsDataMap && !_.isEmpty(settingsDataMap)
      ? Object.values(settingsDataMap)
      : [];

  function handleToolbarAction(action, selected) {
    setSelectedIds(selected);
    setSelectedItems([
      ...NtaiUtils.getFieldArrayFromObject(selected, dataMap, "name"),
    ]);
    if (action === "delete") confirmDelete();
  }

  function handleDelete() {
    selectedIds.forEach((id) => {
      dispatch(deleteThesaurus(id));
    });
  }

  function confirmDelete() {
    setOpenConfirmDeleteDialog(true);
  }

  function cancelDelete() {
    setOpenConfirmDeleteDialog(false);
    setSelectedIds([]);
    setSelectedItems([]);
  }

  useEffect(() => {
    if (
      status &&
      status.method === "getThesauruses" &&
      status.result === "success"
    ) {
      setLoaded(true);
      dispatch(clearStatus());
    }

    if (
      status &&
      status.method === "deleteThesaurus" &&
      status.result === "success"
    ) {
      const remainingIds = _.filter(selectedIds, function (o) {
        return Object.keys(dataMap).includes(o) ? true : false;
      });

      if (remainingIds.length === 0) {
        setSelectedIds([]);
        setSelectedItems([]);
        setOpenConfirmDeleteDialog(false);
        dispatch(clearStatus());
        enqueueSnackbar(
          `Thesaurus "${selectedItems.join(", ")}" deleted successfully.`,
          {
            variant: "success",
          }
        );
      }
    }

    if (status && status.result === "error") {
      enqueueSnackbar(status.message, {
        variant: "error",
      });
      dispatch(clearStatus());
    }
  }, [status]);

  useEffect(() => {
    if (
      settingsStatus &&
      settingsStatus.method === "getSetting" &&
      settingsStatus.result === "success"
    ) {
      if (settings && _.isArray(settings)) {
        const defaultDomain = _.filter(settings, { name: "defaultDomainUuId" });
        if (defaultDomain.length > 0) {
          const defaultDomainId = defaultDomain[0]["value"];
          if (defaultDomainId != null && defaultDomainId.length > 0) {
            dispatch(getThesauruses(defaultDomainId));
            dispatch(setThesaurusDefaultDomainId(defaultDomainId));
            setDefaultDomainUuId(defaultDomainId);
          } else {
            setDefaultDomainUuId(null);
          }
        }
      }

      dispatch(clearSettingStatus());
    }
  }, [settingsStatus]);

  useEffect(() => {
    dispatch(getSetting("defaultDomainUuId"));
  }, []);

  return (
    <>
      <NtaiPage padding={2} title="Thesaurus">
        {loaded && defaultDomainUuId && defaultDomainUuId.length > 0 && (
          <NtaiCrudTable
            helpText="Thesauruses assist in the management of industry or company defined controlled vocabularies. You can further use thesauruses in refining searches across multiple sources"
            rows={data}
            headCells={headCells}
            selectedIds={selectedIds}
            toolbarActions={toolbarActions}
            keyColumn="uuId"
            isDensed={true}
            path={`/library/thesauruses`}
            handleToolbarAction={handleToolbarAction}
            title="Thesaurus"
          />
        )}
        {loaded && !defaultDomainUuId && (
          <NtaiEmptyMessage
            header="Default domain not selected"
            subheader="Select default domain from Admin - Settings section"
          />
        )}
        {!loaded && <NtaiCircularProgress />}
      </NtaiPage>
      <NtaiConfirmDeleteDialog
        items={
          selectedIds &&
          selectedIds.length &&
          NtaiUtils.getFieldArrayFromObject(selectedIds, dataMap, "name")
        }
        open={openConfirmDeleteDialog}
        handleConfirmDelete={handleDelete}
        handleCancelDelete={cancelDelete}
      />
    </>
  );
}
