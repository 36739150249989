import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import NtaiChartEmptyMessage from "../NtaiChartEmptyMessage";
import { grey } from "@mui/material/colors";
import { useMeasure } from "react-use";
import NtaiEChartUtil from "../echarts/util/NtaiEChartUtil";
import NtaiChartUtil from "../util/NtaiChartUtil";
import NtaiUtils from "@ntai/utils/NtaiUtils";
const _ = require("lodash");

function getColorValue(data, metadata) {
  let result = data["value"];
  const fieldWithColors = _.filter(
    Object.values(metadata["fields"]),
    function (o) {
      if (o["colorSchemes"] && o["colorSchemes"].length > 0) return true;
      else return false;
    }
  );

  if (fieldWithColors.length > 0) {
    const fieldWithColor = fieldWithColors[0];
    if (
      fieldWithColor["colorUseCatTextValueFg"] &&
      fieldWithColor["colorUseCatTextValueFg"] === 1
    ) {
      result = data["name"];
    }
  }

  return result;
}

function formatValue(value, metadata) {
  const valueFormatterScriptField = _.filter(
    Object.values(metadata["fields"]),
    function (o) {
      if (o["valueFormatterScript"] && o["valueFormatterScript"].length > 0)
        return true;
      else return false;
    }
  );

  if (valueFormatterScriptField.length > 0) {
    const revisedValue = NtaiEChartUtil.evalValue(
      value,
      valueFormatterScriptField[0]["valueFormatterScript"]
    );

    return revisedValue;
  } else {
    return value;
  }
}

export default function NtaiWidgetMetricList(props) {
  const theme = useTheme();

  const { data, secondaryText, metadata } = props;

  return (
    <Box
      sx={{
        p: theme.spacing(1),
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        gap: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {data && _.isArray(data) && data.length > 0 && (
        <Scrollbar autoHide={true}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <List
              sx={{
                width: "100%",
                height: "100%",
                p: 0,
              }}
            >
              {_.orderBy(data, ["value"], ["desc"]).map((o, i) => (
                <React.Fragment>
                  <ListItem
                    key={`source-widget-list-${i}`}
                    sx={{
                      p: 0,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          flexBasis: "75%",
                          display: "flex",
                          height: "100%",
                          p: theme.spacing(1),
                        }}
                      >
                        <Typography variant="body1" fontWeight="400">
                          {o["name"]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexBasis: "25%",
                          p: theme.spacing(1),
                          height: "100%",
                          justifyContent: "center",
                          background: NtaiEChartUtil.getItemStyleColor(
                            "bg",
                            getColorValue(o, metadata),
                            "white",
                            metadata
                          ),
                        }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="700"
                          color={NtaiEChartUtil.getItemStyleColor(
                            "fg",
                            getColorValue(o, metadata),
                            grey[900],
                            metadata
                          )}
                        >
                          {NtaiUtils.abbrevNumber(
                            formatValue(o["value"], metadata)
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Scrollbar>
      )}
      {(!data || (_.isArray(data) && data.length === 0)) && (
        <NtaiChartEmptyMessage />
      )}
    </Box>
  );
}
