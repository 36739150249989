import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import NtaiCheckboxField from "@ntai/components/inputs/NtaiCheckboxField";
import NtaiDialogFormPanel from "@ntai/components/panels/NtaiDialogFormPanel";
import Scrollbar from "@ntai/components/Scrollbar";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getSourceDefinitions } from "../../../store/sourceDefinitionsSlice";
import { getSourceOutDatasets } from "../../out/store/sourceOutDatasetsSlice";
import NtaiEmptyMessage from "@ntai/components/micros/NtaiEmptyMessage";
import NtaiSwitchField from "@ntai/components/inputs/NtaiSwitchField";
const _ = require("lodash");

function extractSources(sourceDefinitionsData) {
  const result = [];
  if (_.isArray(sourceDefinitionsData) && sourceDefinitionsData.length > 0) {
    sourceDefinitionsData.forEach((sd, i) => {
      const sources = _.get(sd, "sourceMins");
      if (_.isArray(sources) && sources.length > 0) {
        sources.forEach((s, i) => {
          result.push(s);
        });
      }
    });
  }

  return result;
}

function processSourceOutDatasets(sourcePipelineId, sourceOutDatasetsData) {
  const result = [];
  sourceOutDatasetsData.forEach((o, i) => {
    if (o["sourcePipelineUuId"] !== sourcePipelineId) result.push(o);
  });

  return result;
}

export default function SourcePipelineRefSourceOutDatasetLookup({
  handleFormSubmit,
  handleCancel,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getValues, reset, setValue } = useFormContext();

  const dataMap = useSelector(
    (state) => state.sources.sourcePipelineRefSourceDatasetsSlice.data
  );

  const sourcePipelineId = useSelector(
    (state) => state.sources.sourcePipelinesSlice.activeId
  );

  const data = dataMap && !_.isEmpty(dataMap) ? Object.values(dataMap) : [];

  const sourceOutDatasetsDataMap = useSelector(
    (state) => state.sources.sourceOutDatasetsSlice.data
  );

  const sourceOutDatasetsData =
    sourceOutDatasetsDataMap && !_.isEmpty(sourceOutDatasetsDataMap)
      ? Object.values(sourceOutDatasetsDataMap)
      : [];

  const sourceOutDatasets = processSourceOutDatasets(
    sourcePipelineId,
    sourceOutDatasetsData
  );

  function onHandleSave() {
    const sourcePipelineRefSourceOutDatasets = getValues(
      "sourcePipelineRefSourceOutDatasetForm.refSourceOutDatasetUuIds"
    );

    if (
      sourcePipelineRefSourceOutDatasets &&
      !_.isEmpty(sourcePipelineRefSourceOutDatasets)
    ) {
      const refSourceOutDatasets = [];

      Object.keys(sourcePipelineRefSourceOutDatasets).forEach((key) => {
        const refSourceOutDataset = sourcePipelineRefSourceOutDatasets[key];
        if (refSourceOutDataset["selected"]) {
          refSourceOutDatasets.push({
            refSourceOutDatasetUuId: key,
            mandatoryFg: refSourceOutDataset["mandatoryFg"] ? 1 : null,
          });
        }
      });

      //console.log("Submit this - > ", refSourceOutDatasets);
      handleFormSubmit(refSourceOutDatasets);
    }
  }

  useEffect(() => {
    const refSourceOutDatasets = {};
    if (_.isArray(data) && data.length > 0) {
      data.forEach((refSourceOutDataset, i) => {
        const refSourceOutDatasetUuId =
          refSourceOutDataset["refSourceOutDatasetUuId"];
        refSourceOutDatasets[refSourceOutDatasetUuId] = {
          selected: true,
          mandatoryFg: refSourceOutDataset["mandatoryFg"] === 1 ? true : false,
        };
      });
    }

    setValue(
      "sourcePipelineRefSourceOutDatasetForm.refSourceOutDatasetUuIds",
      refSourceOutDatasets
    );

    dispatch(
      getSourceOutDatasets({
        sourceDefId: "*",
        sourceId: "*",
        sourceOutStoreId: "*",
      })
    );
  }, [sourcePipelineId]);

  return (
    <NtaiDialogFormPanel
      subheader="You can add external datasets from other sources as reference datasets. Added datasets can be used in the source pipeline"
      handleSave={onHandleSave}
      handleCancel={handleCancel}
    >
      <Box
        sx={{
          border: theme.general.border1,
          borderRadius: theme.general.borderRadius,
          display: "flex",
          height: "240px",
        }}
      >
        {sourceOutDatasets &&
          _.isArray(sourceOutDatasets) &&
          sourceOutDatasets.length > 0 && (
            <Scrollbar>
              <List sx={{ p: 0 }}>
                {sourceOutDatasets.map((s, i) => (
                  <React.Fragment key={`source-ref-source-out-dataset-${i}`}>
                    <ListItemButton sx={{ p: 0 }}>
                      <ListItem
                        secondaryAction={
                          <Box
                            sx={{
                              display: "flex",
                              gap: theme.spacing(1),
                              alignItems: "center",
                            }}
                          >
                            <NtaiSwitchField
                              label="Mandatory?"
                              name={`sourcePipelineRefSourceOutDatasetForm.refSourceOutDatasetUuIds.${s.uuId}.mandatoryFg`}
                            />
                            <NtaiCheckboxField
                              name={`sourcePipelineRefSourceOutDatasetForm.refSourceOutDatasetUuIds.${s.uuId}.selected`}
                            />
                          </Box>
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography fontWeight="700">
                              {s.datasetName}
                            </Typography>
                          }
                          secondary={`${s.sourceMin.displayName} [${s.sourceOutStoreDecode}]`}
                        />
                      </ListItem>
                    </ListItemButton>

                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Scrollbar>
          )}

        {sourceOutDatasets &&
          _.isArray(sourceOutDatasets) &&
          sourceOutDatasets.length === 0 && (
            <NtaiEmptyMessage
              header="No datasets available"
              subheader="Configure reference source pipelines to include datasets"
            />
          )}
      </Box>
    </NtaiDialogFormPanel>
  );
}
