import {
  Box,
  Divider,
  FormControl,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import NtaiFilterBox from "./NtaiFilterBox";
const _ = require("lodash");

const CustomSelect = withStyles({})(Select);

const MuiMultipleSelect = (props) => {
  const theme = useTheme();
  const { label, name, options, value, handleCallback } = props;

  const [selectedItems, setSelectedItems] = React.useState([...value]);
  const [revisedOptions, setRevisedOptions] = React.useState(options);
  const { setValue } = useFormContext();

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    console.log("event: ", event);
    let values = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }

    setSelectedItems(values);
  };

  function clicked(v) {
    console.log("Clicked: ", v);
    // let selectedItemsCopy = _.cloneDeep(selectedItems);
    // // const filteredItems = [];
    // // if (copySelectedItems.includes(v))
    // //   filteredItems = _.filter(copySelectedItems, function (item) {
    // //     return item !== v;
    // //   });
    // // else filteredItems = [...copySelectedItems, value];
    // const index = selectedItemsCopy.indexOf(v);
    // if (index !== -1) selectedItemsCopy.splice(index, 1);
    // else selectedItemsCopy = [...selectedItemsCopy, value];

    // setSelectedItems([...selectedItemsCopy]);
  }

  const handleTextfieldChange = (searchText) => {
    const filteredOptions = options.filter((option) =>
      _.get(option, "label").includes(searchText)
    );
    setRevisedOptions(searchText.length > 0 ? filteredOptions : options);
  };

  const handleSearchCallback = (searchText) => {
    handleCallback(searchText);
  };

  function renderSelect() {
    return (
      <CustomSelect
        id={name}
        multiple
        native
        size="small"
        onChange={handleChangeMultiple}
        inputProps={{
          style: {
            fontSize: "12px",
          },
        }}
        value={
          selectedItems && selectedItems.length > 0 ? selectedItems : [...value]
        }
      >
        {revisedOptions.sort().map((item) => {
          return (
            <option
              key={item.value}
              value={item.value}
              onClick={(e) => clicked(item.value)}
            >
              {item.label}
            </option>
          );
        })}
      </CustomSelect>
    );
  }

  useEffect(() => {
    if (selectedItems && _.isArray(selectedItems) && selectedItems.length > 0)
      setValue(name, selectedItems);
    else setValue(name, []);
  }, [selectedItems]);

  return (
    <FormControl>
      <NtaiFilterBox>
        <Typography variant="subtitle2" fontWeight="700">
          {label}
        </Typography>
        <TextField
          onChange={(e) => handleTextfieldChange(e.target.value)}
          name="tmp"
          placeholder="Search..."
          size="small"
          autoComplete="off"
        />
        {renderSelect()}
      </NtaiFilterBox>
    </FormControl>
  );
};

function NtaiFilterMultiSelectList(props) {
  const { control } = useFormContext();

  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MuiMultipleSelect
            value={value || []}
            onChange={onChange}
            {...props}
          />
        )}
        control={control}
        {...props}
      />
    </React.Fragment>
  );
}

export default NtaiFilterMultiSelectList;
