import { faCaretDown, faCodeBranch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
import React, { useEffect, useState } from "react";
import SourceVersonSelectorMenu from "./SourceVersionSelectorMenu";
import { useDispatch, useSelector } from "react-redux";
import { selectSourceVersion, clearStatus } from "../../store/sourcesSlice";
import { useSnackbar } from "notistack";
import history from "@ntai/@history";

export default function SourceVersionSelectorWrapper() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );

  const status = useSelector((state) => state.sources.sourcesSlice.status);
  const activeId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const handleMenuOpen = (event) => {
    setOpenMenu(true);
    setAnchorElMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
    setAnchorElMenu(null);
  };

  const clickAwayHandler = () => {
    setOpenMenu(false);
  };

  function handleSelect(selectSourceUuId) {
    dispatch(
      selectSourceVersion({
        sourceDefId: sourceDefId,
        sourceId: selectSourceUuId,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "selectSourceVersion" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      handleMenuClose();
      history.push(`/sourcedefs/${sourceDefId}/sources/${activeId}/manage`);
      enqueueSnackbar("Source version selected successfully", {
        variant: "success",
      });
    }
  }, [status]);

  return (
    <React.Fragment>
      <Tooltip title="Select Version">
        <Button
          size="small"
          sx={{
            px: theme.spacing(2),
            background: grey[100],
            fontSize: "11px",
            fontWeight: 700,
          }}
          color="inherit"
          startIcon={<FontAwesomeIcon size="xs" icon={faCodeBranch} />}
          endIcon={<FontAwesomeIcon size="xs" icon={faCaretDown} />}
          onClick={(e) => handleMenuOpen(e)}
        >
          VERSION 1.0
        </Button>
      </Tooltip>

      {openMenu && (
        <NtaiPopover
          open={openMenu}
          anchorEl={anchorElMenu}
          handleClose={handleMenuClose}
          clickAwayHandler={clickAwayHandler}
        >
          <SourceVersonSelectorMenu
            handleSelect={handleSelect}
            handleClose={handleMenuClose}
          />
        </NtaiPopover>
      )}
    </React.Fragment>
  );
}
