import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getSearchSourceRecordsSummary = createAsyncThunk(
  "searchSourceRecordsSummary/getSearchSourceRecordsSummary",
  async (values, { rejectWithValue }) => {
    try {
      const { searchId, searchSourceId, params } = values;
      const response = await server.get(
        `/search/${searchId}/sources/${searchSourceId}/records-summary`,
        {
          params: params,
        }
      );
      return { summaryType: params.summaryType, count: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchSourceRecordsSummarySlice = createSlice({
  name: "searchSourceRecordsSummary",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },

  reducers: {
    clearStatus: (state, action) => {
      state.status[action.payload] = {};
    },
  },
  extraReducers: {
    [getSearchSourceRecordsSummary.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.summaryType]: action.payload.count,
      };

      state.status = {
        ...state.status,
        [action.payload.summaryType]: {
          result: "success",
          method: "getSearchSourceRecordsSummary",
        },
      };
    },
    [getSearchSourceRecordsSummary.rejected]: (state, action) => {
      state.status = {
        ...state.status,
        [action.payload.summaryType]: {
          result: "error",
          method: "getSearchSourceRecordsSummary",
        },
      };
    },
  },
});

export const { clearStatus, setActiveId } =
  searchSourceRecordsSummarySlice.actions;

export default searchSourceRecordsSummarySlice.reducer;
