import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import NtaiResizableGrid from "@ntai/components/resizable/NtaiResizableGrid";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";
import NtaiTimeSeriesEChart from "./NtaiTimeSeriesEChart";

const data = {
  series: ["Series 1"],
  data: [
    {
      name: "Series 1",
      data: [
        {
          time: new Date(2000, 1, 12),
          value: {
            total: 24,
            value: 12,
            offset: 0.5,
          },
        },
        {
          time: new Date(2001, 5, 6),
          value: {
            total: 10,
            value: 2,
            offset: 0.2,
          },
        },
        {
          time: new Date(2004, 7, 8),
          value: {
            total: 10,
            value: 5,
            offset: 0.5,
          },
        },
        {
          time: new Date(2009, 5, 6),
          value: {
            total: 18,
            value: 6,
            offset: 0.3,
          },
        },
      ],
    },
  ],
};

const seriesData = {
  series: ["Series 1", "Series 2"],
  data: [
    {
      name: "Series 1",
      data: [
        {
          time: 946702800000,
          value: {
            total: 24,
            value: 12,
            offset: 0.5,
          },
        },
        {
          time: 1325394000000,
          value: {
            total: 10,
            value: 2,
            offset: 0.2,
          },
        },
      ],
    },
    {
      name: "Series 2",
      data: [
        {
          time: 946702800000,
          value: {
            total: 36,
            value: 8,
            offset: 0.25,
          },
        },
        {
          time: 1325394000000,
          value: {
            total: 10,
            value: 2,
            offset: 0.2,
          },
        },
      ],
    },
  ],
};

export default function NtaiTestTimeSeries() {
  const theme = useTheme();
  const [filtered, setFiltered] = useState(false);

  function handleClick(sourceWidgetFields) {
    console.log("Handle left Click: ", sourceWidgetFields);
  }

  function handleContextMenu(action, params) {
    console.log("Handle right Click: ", action, params);
  }

  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Time chart">
          <NtaiTimeSeriesEChart
            widgetId="time-series-chart"
            data={data}
            metadata={{
              filterFg: filtered,
              fields: {
                fieldUuId1: {
                  name: "date",
                  fieldOrder: 1,
                  field: { uuId: "dateUuId" },
                },
              },
            }}
            handleClick={handleClick}
            handleContextMenu={handleContextMenu}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "450px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <NtaiResizableGrid items={generateItems()} />
    </Box>
  );
}
