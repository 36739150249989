import { Box, Button, Typography, useTheme } from "@mui/material";
import NtaiPage from "@ntai/core/NtaiPage/NtaiPage";
import React, { useState } from "react";
import SearchList from "./SearchList";
import history from "@ntai/@history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NtaiSimpleSearchBar from "@ntai/components/searchbar/simple/NtaiSimpleSearchBar";
import { faSquarePlus } from "@fortawesome/pro-solid-svg-icons";
import SearchSelectTypeForm1 from "./SearchSelectTypeForm1";
import NtaiPopover from "@ntai/components/popover/NtaiPopover";
const _ = require("lodash");

export const SearchListContext = React.createContext();

export default function SearchListWrapper() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [searchListFilterText, setSearchListFilterText] = React.useState("");
  const [viewMode, setViewMode] = React.useState("grid");
  const [filterCode, setFilterCode] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleOpen = (event) => {
    setMenuOpen(true);
    setAnchorElMenu(event.currentTarget);
  };
  const handleClose = () => {
    setMenuOpen(false);
    setAnchorElMenu(null);
  };

  const clickAwayHandler = () => {
    setMenuOpen(false);
  };

  const debouncedOnHandleSearch = _.debounce(handleSearch, 300);

  function handleSearchSelect(searchType) {
    if (searchType === "search") history.push("/search-basic-init");
    else if (searchType === "analytics") history.push("/search-adv-init");
    else if (searchType === "dashboard") history.push("/search-dashboard-init");
    else if (searchType === "reports") history.push("/search-report-init");
    else if (searchType === "import-series")
      history.push("/search-series-import");
  }

  function handleSearch(str) {
    setSearchListFilterText(str);
  }

  return (
    <React.Fragment>
      <SearchListContext.Provider
        value={{
          viewMode,
          setViewMode,
          filterCode,
          setFilterCode,
          searchListFilterText,
          setSearchListFilterText,
        }}
      >
        <NtaiPage
          title="Workspace"
          padding="0px"
          titleExtras={
            <Box
              sx={{
                ml: theme.spacing(10),
                display: "flex",
                alignItems: "center",
              }}
            >
              <NtaiSimpleSearchBar
                width="540px"
                placeholder="Search..."
                handleChange={handleSearch}
              />
            </Box>
          }
          headerActions={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(1),
              }}
            >
              <Button
                size="small"
                startIcon={<FontAwesomeIcon icon={faSquarePlus} />}
                // onClick={() => history.push("/search-basic-init")}
                // onClick={() => handleDialogOpen()}
                onClick={(e) => handleOpen(e)}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  NEW ANALYSIS
                </Typography>
              </Button>
            </Box>
          }
        >
          <SearchList filterCode={1} />
        </NtaiPage>
        {menuOpen && (
          <NtaiPopover
            open={menuOpen}
            anchorEl={anchorElMenu}
            handleClose={handleClose}
            clickAwayHandler={clickAwayHandler}
          >
            {/* <SearchSourceViewMenuItems handleClose={handleSourcViewMenuClose} /> */}
            <SearchSelectTypeForm1
              handleSearchSelect={handleSearchSelect}
              handleClose={handleClose}
            />
          </NtaiPopover>
        )}
        {/* <NtaiDialog
          title="Select mode"
          open={open}
          handleDialogClose={handleDialogClose}
          size="sm"
        >
          <SearchSelectTypeForm handleSearchSelect={handleSearchSelect} />
        </NtaiDialog> */}
      </SearchListContext.Provider>
    </React.Fragment>
  );
}
