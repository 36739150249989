import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import server from "app/http/ntaiServer";
const _ = require("lodash");

export const getObjectRels = createAsyncThunk(
  "objectRels/getObjectRels",
  async (objectId, { rejectWithValue }) => {
    try {
      const response = await server.get(`/cdo/objects/${objectId}/relations`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectRel = createAsyncThunk(
  "objectRels/getObjectRel",
  async (values, { rejectWithValue }) => {
    try {
      const { objectId, uuId } = values;
      const response = await server.get(
        `/cdo/objects/${objectId}/relations/${uuId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createObjectRel = createAsyncThunk(
  "objectRels/createObjectRel",
  async (values, { rejectWithValue }) => {
    try {
      const { objectId, formData } = values;
      const response = await server.post(
        `/cdo/objects/${objectId}/relations`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateObjectRel = createAsyncThunk(
  "objectRels/updateObjectRel",
  async (values, { rejectWithValue }) => {
    try {
      const { objectId, uuId, formData } = values;
      const response = await server.patch(
        `/cdo/objects/${objectId}/relations/${uuId}`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteObjectRel = createAsyncThunk(
  "objectRels/deleteObjectRel",
  async (values, { rejectWithValue }) => {
    try {
      const { objectId, uuId } = values;
      const response = await server.delete(
        `/cdo/objects/${objectId}/relations/${uuId}`
      );
      return { uuId: uuId, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getObjectsForRel = createAsyncThunk(
  "objectRels/getObjectsForRel",
  async (values, { rejectWithValue }) => {
    try {
      const { uuId, params } = values;
      const response = await server.get(`/cdo/objects/${uuId}`, {
        params: {
          params: params,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const objectRelsSlice = createSlice({
  name: "objectRels",
  initialState: {
    data: {},
    activeId: null,
    status: {},
  },
  reducers: {
    clearStatus: (state, action) => {
      state.status = {};
    },
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
  extraReducers: {
    [getObjectRels.fulfilled]: (state, action) => {
      state.data = { ..._.mapKeys(action.payload, "uuId") };
      state.status = { result: "success", method: "getObjectRels" };
    },
    [getObjectRels.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectRels",
        message: action.payload.message,
      };
    },
    [getObjectRel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "getObjectRel" };
    },
    [getObjectRel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "getObjectRel",
        message: action.payload.message,
      };
    },
    [createObjectRel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "createObjectRel" };
    },
    [createObjectRel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "createObjectRel",
        message: action.payload.message,
      };
    },
    [updateObjectRel.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.uuId]: action.payload,
      };
      state.status = { result: "success", method: "updateObjectRel" };
    },
    [updateObjectRel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "updateObjectRel",
        message: action.payload.message,
      };
    },
    [deleteObjectRel.fulfilled]: (state, action) => {
      state.data = _.omit(state.data, action.payload.uuId);
      state.status = { result: "success", method: "deleteObjectRel" };
    },
    [deleteObjectRel.rejected]: (state, action) => {
      state.status = {
        result: "error",
        method: "deleteObjectRel",
        message: action.payload.message,
      };
    },
  },
});

export const { clearStatus, setActiveId } = objectRelsSlice.actions;

export default objectRelsSlice.reducer;
