import React from "react";
import NtaiWidgetMetric from "./NtaiWidgetMetric";
import { Box, useTheme } from "@mui/material";
import NtaiSourceWidget from "@ntai/components/widgets/source/NtaiSourceWidget";

export default function NtaiTestWidgetMetrics() {
  const theme = useTheme();
  function generateItems() {
    let result = [];

    result.push(
      <Box key={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NtaiSourceWidget title="Gantt chart">
          <NtaiWidgetMetric
            data={234}
            metadata={{
              widget: {
                secondaryText: "Sample text",
              },
            }}
          />
        </NtaiSourceWidget>
      </Box>
    );

    return result;
  }

  return (
    <Box
      sx={{
        p: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      }}
    >
      {generateItems()}
    </Box>
  );
}
