import { Box, useTheme } from "@mui/material";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import NtaiReadOnlyTable from "@ntai/components/tables/read-only/NtaiReadOnlyTable";
import NtaiSimpleTable from "@ntai/components/tables/simple/NtaiSimpleTable";
import React from "react";
import { useSelector } from "react-redux";
const _ = require("lodash");

const headCells = [
  {
    id: "uuId",
    numeric: false,
    ignore: true,
    disablePadding: true,
    label: "UUID",
  },
  {
    id: "label",
    numeric: false,
    disablePadding: false,
    label: "Label",
    fontWeight: 700,
  },

  {
    id: "esDataTypeDecode",
    numeric: false,
    disablePadding: false,
    label: "Field Type",
  },
];

export default function SourceOutElasticFieldMapping() {
  const theme = useTheme();

  const sourceFieldsDataMap = useSelector(
    (state) => state.sources.sourceFieldsSlice.data
  );

  const data =
    sourceFieldsDataMap && !_.isEmpty(sourceFieldsDataMap)
      ? _.orderBy(
          _.filter(Object.values(sourceFieldsDataMap), function (o) {
            return o["name"].startsWith("sys_") ? false : true;
          }),
          ["label"],
          ["asc"]
        )
      : [];

  function handleRowClick(v) {}

  return (
    <Box sx={{ width: "520px" }}>
      <NtaiPanel
        header="Fields Mapping"
        subheader="Display elastic search field mapping"
        width="100%"
      >
        <NtaiReadOnlyTable
          rows={data}
          headCells={headCells}
          keyColumn="uuId"
          title={`Fields (${data.length})`}
          handleRowClick={handleRowClick}
          pageSize={10}
        />
      </NtaiPanel>
    </Box>
  );
}
