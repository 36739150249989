import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NtaiForm from "@ntai/components/forms/NtaiForm";
import {
  createSourceAdjudicationImportCsvScheme,
  setActiveId,
  clearStatus,
} from "./store/sourceAdjudicationImportCsvSchemesSlice";
import SourceAdjudicationImportCsvSchemeForm from "./SourceAdjudicationImportCsvSchemeForm";
import { useSnackbar } from "notistack";
const _ = require("lodash");

export default function SourceAdjudicationImportCsvSchemeCreate({
  handleCancel,
  redirectToEdit,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.status
  );

  const sourceDefId = useSelector(
    (state) => state.sources.sourceDefinitionsSlice.activeId
  );
  const sourceId = useSelector((state) => state.sources.sourcesSlice.activeId);

  const sourceAdjudicationId = useSelector(
    (state) => state.sources.sourceAdjudicationsSlice.activeId
  );

  const activeId = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.activeId
  );

  const dataMap = useSelector(
    (state) => state.sources.sourceAdjudicationImportCsvSchemesSlice.data
  );

  function handleFormSubmit(formData) {
    dispatch(
      createSourceAdjudicationImportCsvScheme({
        sourceDefId: sourceDefId,
        sourceId: sourceId,
        sourceAdjudicationId: sourceAdjudicationId,
        formData: formData,
      })
    );
  }

  useEffect(() => {
    if (
      status &&
      status.method === "createSourceAdjudicationImportCsvScheme" &&
      status.result === "success"
    ) {
      dispatch(clearStatus());
      enqueueSnackbar(
        `Source adjudication scheme "${dataMap[activeId]["name"]}" created successfully`,
        {
          variant: "success",
        }
      );
      redirectToEdit(activeId);
    }
  }, [status]);

  useEffect(() => {
    dispatch(setActiveId(null));
  }, []);

  return (
    <NtaiForm>
      <SourceAdjudicationImportCsvSchemeForm
        action="add"
        handleFormSubmit={handleFormSubmit}
        handleCancel={handleCancel}
      />
    </NtaiForm>
  );
}
