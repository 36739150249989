import { Box, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export default function NtaiSimpleTableHelpText(props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: theme.spacing(1),
        display: "flex",
        background: grey[100],
        borderRadius: "4px",
        marginBottom: theme.spacing(2),
      }}
    >
      <Typography variant="body2">{props.content}</Typography>
    </Box>
  );
}
