import { Box, Link, useTheme } from "@mui/material";
import React from "react";
import NtaiMRTable from "./NtaiMRTable";
import NtaiTabs from "@ntai/components/tabs/NtaiTabs";
import NtaiMRHookTable from "./NtaiMRHookTable";
import {
  faPlus,
  faSquarePlus,
  faShare,
} from "@fortawesome/pro-solid-svg-icons";
import NtaiPanel from "@ntai/components/panels/NtaiPanel";
import { blue } from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faArchive } from "@fortawesome/pro-light-svg-icons";
import NtaiTable from "../main/NtaiTable";
const _ = require("lodash");

function getColumns(data) {
  const result = [];
  if (_.isArray(data) && data.length > 0) {
    const row1 = data[0];
    Object.keys(row1).forEach((o) => {
      result.push({
        accessorKey: o,
        header: o,
      });
    });
  }

  return result;
}

const agg_data = [
  {
    id: 1,
    delta_l: 23,
    on_time: 5,
    delta: 3,
    total: 100,
  },
  {
    id: 2,
    delta_l: 23,
    on_time: 4,
    delta: 3,
    total: 120,
  },
  {
    id: 3,
    delta_l: 23,
    on_time: 6,
    delta: 3,
    total: 100,
  },
];

const agg_columns = [
  {
    accessorKey: "delta_l", //access nested data with dot notation
    header: "Delta L",
  },
  {
    accessorKey: "on_time", //access nested data with dot notation
    header: "On time",
  },
  {
    accessorKey: "delta", //access nested data with dot notation
    header: "Delta",
  },
  {
    accessorKey: "total", //access nested data with dot notation
    header: "Total",
  },
  {
    header: "Compliance %",
    accessorFn: (row) =>
      Math.round((row.on_time * 100) / row.total).toString() + "%",
  },
];

//nested data is ok, see accessorKeys in ColumnDef below
const data = [
  {
    uuId: "r1",
    name: {
      firstName: "Sharad",
      lastName: "Prakash",
    },

    address: "261 Erdman Ford 261 Erdman Ford261 Ford ",
    city: "East Daphne",
    state: "Kentucky",
  },
  {
    uuId: "r2",
    name: {
      firstName: "Tony",
      lastName: "Gill",
    },

    address: "261 Erdman Ford",
    city: "East Daphne",
    state: "Austin",
  },
  {
    uuId: "r3",
    name: {
      firstName: "James",
      lastName: "John",
    },

    address: "261 Herman Drive",
    city: "East Daphne",
    state: "Kentucky",
  },
  {
    uuId: "r4",
    name: {
      firstName: "Jane",
      lastName: "Doe",
    },

    address: "45 Brown Ave",
    city: "Hanover Lane",
    state: "JErsey City",
  },
  {
    uuId: "r5",
    name: {
      firstName: "Mini",
      lastName: "Jrome",
    },

    address: "1 Elcamino Real",
    city: "Frankfurt",
    state: "San Jose",
  },
];

const columns = [
  {
    accessorKey: "name.firstName", //access nested data with dot notation
    header: "First Name",
    primary: true,
  },
  {
    accessorKey: "name.lastName",
    header: "Last Name",
  },
  {
    accessorKey: "address", //normal accessorKey
    header: "Address",
  },
  {
    accessorKey: "city",
    header: "City",
  },
  {
    accessorKey: "state",
    header: "State",
  },
];

function handleClick(rowID) {
  console.log("rowID", rowID);
}

function handleTopToolbarLeftAction(actionName, rows) {
  console.log("Selected clicked action = ", actionName, rows);
}

function handleRowAction(action, id) {
  console.log("Handle row action: ", action, id);
}

function handleRowClick(id) {
  console.log("Manual handle row clicked: ", id);
}

export default function TestNtaiMRTable() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: "12px",
      }}
    >
      <NtaiTabs>
        <Box label="HOOK Table">
          <NtaiPanel
            width="100%"
            header="User Listing"
            subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur"
          >
            <NtaiTable
              data={data}
              columns={columns}
              id="uuId"
              rowClickRedirect={false}
              path="/something/"
              exportOption={true}
              // enableSearchText={false}
              // enableToggleFilter={false}
              // enableShowHideColumn={false}
              // enableShowHideColumn={true}
              handleRowClick={handleRowClick}
              tableTitle="Pending Users (3)"
              rowTitle="User"
              rowTitlePlural="Users"
              crud={true}
              // topToolbarLeftActions={[
              //   {
              //     value: "create",
              //     label: "Create User",
              //     icon: faSquarePlus,
              //     variant: "contained",
              //     size: "small",
              //     textCase: "upper",
              //   },
              //   {
              //     value: "delete",
              //     label: "Delete",
              //     icon: faArchive,
              //     size: "small",
              //     textCase: "upper",
              //     rowSelectionEnable: true,
              //     confirmAction: true,
              //   },
              //   {
              //     value: "route",
              //     label: "ROUTE",
              //     icon: faShare,
              //     size: "small",
              //     textCase: "upper",
              //     rowSelectionEnable: true,
              //     confirmAction: true,
              //   },
              // ]}
              handleTopToolbarLeftAction={handleTopToolbarLeftAction}
              rowActionsType="icon"
              rowButtonActions={[
                { value: "edit", label: "Edit", icon: faEdit },
                { value: "delete", label: "Delete", icon: faArchive },
              ]}
              handleRowAction={handleRowAction}
            />
          </NtaiPanel>
        </Box>
        <Box sx={{ p: theme.spacing(2) }} label="PROPS TABLE">
          <NtaiMRTable
            data={data}
            columns={columns}
            sortColumnId="state"
            enableColumnResizing={true}
            enableGlobalFilter={false}
          />
        </Box>
        {/* <Box sx={{ p: theme.spacing(2) }} label="HOOK Table w/o columns">
          <NtaiMRHookTable data={data} columns={getColumns(data)} />
        </Box> */}
        <Box label="Aggregation Table">
          <NtaiPanel
            padding={0}
            width="100%"
            header="User Listing"
            subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur"
          >
            <NtaiMRHookTable data={agg_data} columns={agg_columns} id="id" />
          </NtaiPanel>
        </Box>
      </NtaiTabs>
    </Box>
  );
}
