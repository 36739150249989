import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { tooltipConfig } from "./chartConfigs";
import { Box, useTheme, lighten } from "@mui/material";

am4core.useTheme(am4themes_animated);

export default function NtaiDumbellChart(props) {
  const theme = useTheme();
  const { widgetId, data, categoryField, lowField, highField, handleClick } =
    props;

  useLayoutEffect(() => {
    let chart = am4core.create(
      "dumbellchart".concat("-", widgetId),
      am4charts.XYChart
    );

    chart.data = data;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = categoryField;
    categoryAxis.renderer.minGridDistance = 15;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.renderer.labels.template.horizontalCenter = "left";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.inside = false;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    let valueAxisLabel = valueAxis.renderer.labels.template;
    valueAxisLabel.fontSize = 10;
    valueAxisLabel.rotation = 270;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = categoryField;
    series.dataFields.openValueY = lowField;
    series.dataFields.valueY = highField;
    series.tooltipText = `${lowField}: {openValueY.value}\n${highField}: {valueY.value}`;
    series.sequencedInterpolation = true;
    series.fillOpacity = 0;
    series.strokeOpacity = 1;
    series.stroke = am4core.color(lighten(theme.colors.chart.lighter, 0.1));
    series.columns.template.width = 0.01;
    series.tooltip.pointerOrientation = "horizontal";

    // tool tip
    chart.tooltip.getFillFromObject = false;

    // tool tip
    series.tooltip.getFillFromObject = false;
    series.tooltip.autoTextColor = false;
    series.tooltip.background.cornerRadius =
      tooltipConfig.backgroundCornerRadius;
    series.tooltip.background.fill = am4core.color(
      tooltipConfig.backgroundFill
    );
    series.tooltip.background.fillOpacity = tooltipConfig.backgroundFillOpacity;
    series.tooltip.label.fill = am4core.color(tooltipConfig.labelFill);
    series.tooltip.label.fontSize = tooltipConfig.labelFontSize;

    let axisTooltip = categoryAxis.tooltip;
    axisTooltip.background.fill = am4core.color("#767676");
    axisTooltip.background.strokeWidth = 0;
    axisTooltip.background.cornerRadius = 3;
    axisTooltip.background.pointerLength = 0;
    axisTooltip.dy = 5;
    axisTooltip.label.fill = am4core.color("#fff");
    axisTooltip.label.fontSize = 10;
    categoryAxis.cursorTooltipEnabled = true;
    categoryAxis.sortBySeries = series;

    let openBullet = series.bullets.create(am4charts.CircleBullet);
    openBullet.locationY = 1;
    openBullet.circle.radius = 3;
    openBullet.fill = am4core.color(lighten(theme.colors.primary.lighter, 0.1)); //chart.colors.getIndex(4);
    openBullet.stroke = am4core.color(
      lighten(theme.colors.primary.lighter, 0.1)
    );
    let closeBullet = series.bullets.create(am4charts.CircleBullet);

    closeBullet.fill = am4core.color(
      lighten(theme.colors.primary.lighter, 0.1)
    ); //chart.colors.getIndex(4);
    closeBullet.stroke = am4core.color(
      lighten(theme.colors.primary.lighter, 0.1)
    );
    closeBullet.circle.radius = 3;

    chart.cursor = new am4charts.XYCursor();

    series.columns.template.events.on(
      "hit",
      function (ev) {
        handleClick(widgetId, ev.target.dataItem.dataContext);
      },
      this
    );
    // series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <Box
      id={"dumbellchart".concat("-", widgetId)}
      style={{ width: "100%", height: "100%" }}
    ></Box>
  );
}
